/**
 * Get the click/touch event point
 * @param {Event|TouchEvent} e
 * @returns {{ clientX: number, clientY: number, target: object } | { clientX: number, clientY: number, target: object, clientX1: number, clientY1: number, target1: object }}
 */
export const getClickPoint = e => {
  const { clientX, clientY, target, ctrlKey } = e;
  if (e.touches) {
    // TODO: use middle point as touch point for 2 touch points situation
    if (e.touches.length > 0) {
      const { clientX, clientY, target } = e.touches[0];
      if (e.touches.length > 1) {
        const { clientX: clientX1, clientY: clientY1, target: target1 } = e.touches[1];
        return { clientX, clientY, target, clientX1, clientY1, target1 };
      }
      return { clientX, clientY, target };
    }
  }
  return { clientX, clientY, target, ctrlKey };
};

export const isLeftClick = e => {
  if (e.touches) {
    return e.touches.length === 1;
  } else {
    return e.button === 0;
  }
};

export const isRightClick = e => {
  if (e.touches) {
    return e.touches.length === 2;
  } else {
    return e.button === 2;
  }
};

/**
 * isIpad: https://stackoverflow.com/questions/56578799/tell-ipados-from-macos-on-the-web
 */
export const isTouchDevice = () => {
  const ua = window.navigator.userAgent;
  if (/(iPhone|iPad|iPod|iOS)/i.test(ua)) {
    return true;
  }

  // 判断设备是 ipad 还是 mac
  if (ua.indexOf('Macintosh') > -1 && 'ontouchend' in document) {
    return true;
  }

  if (/(Android)/i.test(ua)) {
    return true;
  }

  return false;
};

export const isSafari = () => {
  const ua = window.navigator.userAgent;
  if (/Safari/.test(ua) && !/Chrome/.test(ua)) {
    return true;
  } else {
    return false;
  }
};

// HACK: a hack solution
/**
 * Used on Safari auto focus
 * @param {HTMLElement} el
 * @param {number} timeout
 */
export function focusAndOpenKeyboard(el, timeout) {
  if (!timeout) {
    timeout = 100;
  }

  if (el) {
    // Align temp input element approx. to be where the input element is
    var __tempEl__ = document.createElement('textarea');
    __tempEl__.style.position = 'absolute';
    __tempEl__.style.top = el.offsetTop + 7 + 'px';
    __tempEl__.style.left = el.offsetLeft + 'px';
    __tempEl__.style.height = 0;
    __tempEl__.style.opacity = 0;
    // Put this temp element as a child of the page <body> and focus on it
    document.body.appendChild(__tempEl__);
    __tempEl__.focus();

    // The keyboard is open. Now do a delayed focus on the target element
    setTimeout(function() {
      el.focus();
      // el.click();
      // Remove the temp element
      document.body.removeChild(__tempEl__);
    }, timeout);
  }
}
