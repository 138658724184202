import CreateElement from '@src/actions/CreateElements';
import CreateSelectElement from '@src/actions/CreateSelectElements';
import Grid from '@src/components/Grid/Grid';
import Map from '@src/shapes/Map/Map';
import React from 'react';
import { connect } from 'react-redux';
import './CanvasSvg.css';

class CanvasSvg extends React.Component {
  svg = React.createRef();

  componentDidMount() {
    let svg = this.svg.current;
    if (svg) {
      // see: https://wiki.inkscape.org/wiki/index.php/Inkscape_SVG_vs._plain_SVG
      svg.setAttribute('xmlns:inkscape', 'http://www.inkscape.org/namespaces/inkscape');
      // TODO: legacy and not quite sure about the purpose
      svg.setAttribute('xmlns:sodipodi', 'http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd');
    }
  }

  render() {
    const {
      onMouseDown,
      onMouseMove,
      // onMouseOut,
      onMouseUp,
      onWheelHandle,
      // props pass down to shapes/selectors
      objList,
      showPropsMenu,
      shapeClick,
      shapeMouseDown,
      // shapeMouseMove,
      selectClick,
      selectMouseDown,
      selectMouseMove,
      selectMouseUp,
      className, // ant-dropdown-trigger
      onClick,
      onContextMenu,
      // onTouchStart,
      canvasHeight,
      appWidth,
      matrixInitialized,
      matrix,
    } = this.props;

    let viewportProps = {};
    if (matrixInitialized) {
      viewportProps.transform = `matrix(${matrix.join(',')})`;
    }

    return (
      <svg
        ref={this.svg}
        id="svg"
        width="100%"
        height="100%"
        version="1.1"
        idshapenext="1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        // onMouseOut={onMouseOut}
        onMouseUp={onMouseUp}
        onWheel={onWheelHandle}
        onContextMenu={onContextMenu}
        onClick={onClick}
        // onTouchStart={onTouchStart}
        // onTouchMove={onMouseMove}
        // onTouchCancel={onMouseOut}
        // onTouchEnd={onMouseUp}
        className={className}
        style={{ cursor: this.props.cursor }}
      >
        <g id="host">
          <Map ttn-role="map" />
          <g id="viewport" {...viewportProps}>
            <Grid />
            <g id="canvas-svg" strokeLinecap="round" strokeLinejoin="round">
              <g id="all-shapes">
                {CreateElement({
                  objList,
                  showPropsMenu,
                  shapeClick,
                  shapeMouseDown,
                  // shapeMouseMove,
                })}
              </g>
            </g>
            <g id="selector-layer">
              {CreateSelectElement({
                objList,
                selectClick,
                selectMouseDown,
                selectMouseMove,
                selectMouseUp,
              })}
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

const mapStateToProps = state => ({
  matrix: state.transform.matrix,
  matrixInitialized: state.transform.matrixInitialized,
  cursor: state.drawingStatus.cursor,
});

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(CanvasSvg);
