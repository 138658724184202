import * as React from 'react';

function SvgIconUndo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fillRule="nonzero" fill="none">
        <path d="M0 0h23.97v24H0z" />
        <path
          d="M11.985 1C5.928 1 .999 5.935.999 12c0 6.066 4.929 11 10.986 11s10.986-4.934 10.986-11c0-6.065-4.929-11-10.986-11zm0 20c-4.957 0-8.989-4.037-8.989-9s4.032-9 8.989-9c4.957 0 8.989 4.037 8.989 9s-4.032 9-8.989 9z"
          fill="currentColor"
        />
        <path
          d="M14.115 9.059H9.477l2.023-2.02-1.43-1.427-4.465 4.456 4.465 4.457 1.43-1.427-2.023-2.02h4.638c1.117 0 2.023.907 2.023 2.02s-.906 2.019-2.023 2.019h-1.011v2.019h1.011a4.047 4.047 0 004.046-4.038 4.047 4.047 0 00-4.046-4.04z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SvgIconUndo;
