import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import * as constant from '@src/constant';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import Utility from '@src/data/Utility';
import { GetCenterInTwoPoints, pointTransform } from '@src/data/CommonFunc';
import { TransformMoveShapes } from '@src/actions/TransformHandle';
import { CopyPoint } from '@src/data/BusinessFun';
import { devicePtToUserPt2 } from '@src/data/CommonFunction';
import _Street from '@src/data/_Street';
import { createLane, createStripe, createLine } from '@src/data/ShapeOperationData';
import { HandlePointRange } from '@src/actions/HandlePoint';

const {
  ARROW_SHOW_TYPE,
  COLOR_TYPE,
  DASH_ARRAY_TYPE,
  FONT_STYLE_TYPE,
  FONT_WEIGHT_TYPE,
  FUNCTION_TYPE,
  LENGTH_TYPE,
  PATTERN_TYPE,
  STREET_SPACE,
  TEXT_BOX_TYPE,
  TEXT_POSITION_TYPE,
  STREET_DIVIDER_TYPE,
  STREET_DEFAULT_SIZE,
  STREET_TYPE,
  ROAD_LAYER_VALUES,
  CANVAS_MENU_TYPE,
} = constant;

let useData = [];
export let operateLastid = 0;
// track the status of the diagram data so that we know if there's any unsaved changes
// let _hasUnsavedChanges = false;

export const generateOperateId = function() {
  var operateId = '';
  operateLastid = operateLastid + 1;
  operateId = 's' + String(operateLastid);
  return operateId;
};

// let _isBrowsingSnapshot = false;

/**
 * the snapshot version user is viewing by undo/redo functionalities.
 * the snapshot of `_snapshots.length - 1` is not always the same as `useData` as
 * `useData` may has unsaved changes
 */
// let _snapshotIndex = 0;

// TODO: max allowed history versions.
/**
 * @typedef {{map: object | undefined, shapes: array}} Snapshot
 */
/**
 * all versions of snapshots - different versions of use data
 * push initial version of useData to it directly
 * @type {Snapshot[]}
 */
// let _snapshots;

/**
 *
 * @param {Snapshot} snapshot
 */
// export const initialSnapshots = snapshot => {
//   if (snapshot) {
//     _snapshots = [snapshot];
//   } else {
//     _snapshots = [
//       {
//         map: undefined,
//         shapes: [],
//       },
//     ];
//   }
//   window._snapshots = _snapshots;
// };

/**
 * Trim versions that come after the current browsing version, just like we're cutting off the old branch and starting a new one
 * @param {object} map
 */
// export const takeSnapshot = map => {
//   // console.log('_isBrowsingSnapshot', _isBrowsingSnapshot);
//   if (_isBrowsingSnapshot) {
//     _snapshots.splice(_snapshotIndex + 1);
//   }
//   _snapshots.push({ map, shapes: _.cloneDeep(useData) });
//   _snapshotIndex = _snapshots.length - 1;
//   _isBrowsingSnapshot = false;
//   // FIXME: comment out before release
//   window._snapshots = _snapshots;
//   _hasUnsavedChanges = true;
// };

// TODO: Can move to canvas reducer?
// const _resumeSnapshot = () => {
//   _isBrowsingSnapshot = _snapshotIndex !== _snapshots.length - 1;
//   // useData should not contain any unsaved changes now
//   // safely get a copy from snapshots
//   useData = _.cloneDeep(_snapshots[_snapshotIndex].shapes);
//   _hasUnsavedChanges = true;
// };

// export const isDirty = () => _hasUnsavedChanges;

// export const setDiagramSaved = () => (_hasUnsavedChanges = false);

// export const getSnapshots = () => {
//   return _snapshots;
// }
// export const getMapState = () => {
//   // _snapshotIndex 为 0 时，清空地图
//   // FIXME: what if Crash Designer loaded map info from Ethos?
//   if (_snapshotIndex === 0) {
//     return null;
//   } else {
//     return _snapshots[_snapshotIndex].map;
//   }
// };

// export const undo = () => {
//   _snapshotIndex = Math.max(0, _snapshotIndex - 1);
//   _resumeSnapshot();
// };

// export const redo = () => {
//   _snapshotIndex = Math.min(_snapshots.length - 1, _snapshotIndex + 1);
//   _resumeSnapshot();
// };

export const clearUseData = function() {
  //operateLastid = 0;
  useData.length = 0;
};

/**
 *
 * @param {object} data
 * @param {array} data.useData
 */
// export const setUseData = function(data) {
//   // Symbol的originsvg采用编码解析
//   data.useData = data.useData.map(item => {
//     if (item.functype === 'Symbol') {
//       item.originsvg = decodeURIComponent(item.originsvg);
//     }
//     return item;
//   });
//   useData = data.useData;
// };

/**
 * 获取当前操作的 data，即数组最后一个
 * @returns {{functype: string, operateid: string}}
 */
export const getCurrentOperateObject = function() {
  let len = useData.length;
  // for (let i = len - 1; i > 0; i++) {
  //   console.log(useData[i]);
  //   if (useData[i].functype !== FUNCTION_TYPE.CURBRETURN && useData[i].functype !== FUNCTION_TYPE.STREETSURFACE) {
  //     return useData[i];
  //   }
  // }
  return useData[len - 1];
};

/**
 * @returns {{functype: string, operateid: string}[]}
 */
export const getUseData = function() {
  return useData;
};

export const resumeUseData = function(newUseData) {
  useData = newUseData;
};

/**
 * 根据 function type 修改 obj 初始化数据
 * @param {object} obj - useData item
 * @param {FUNCTION_TYPE} funcType - FUNCTION_TYPE
 * @param {STREET_TYPE} [streetType] - STREET_TYPE 当 StreetNew 时，才存在该参数
 */
function _updateSpecData(obj, funcType, streetType) {
  switch (funcType) {
    case FUNCTION_TYPE.STREETSURFACE:
      // obj.style.fill = COLOR_TYPE.TRANSPARENT;
      // obj.style.fill = COLOR_TYPE.MEDGRAY;
      obj.style.fill = COLOR_TYPE.WHITE;
      obj.style.strokewidth = 0;
      break;
    case FUNCTION_TYPE.STREETNEW:
      // obj.style.fill = COLOR_TYPE.TRANSPARENT;
      // obj.style.fill = COLOR_TYPE.MEDGRAY;
      obj.style.fill = COLOR_TYPE.WHITE;
      obj.streetType = streetType;
      obj.roadLayer = ROAD_LAYER_VALUES.GROUND;
      break;
    case FUNCTION_TYPE.CIRCLE:
    case FUNCTION_TYPE.ELLIPSE:
    case FUNCTION_TYPE.SQUARE:
    case FUNCTION_TYPE.RECTANGLE:
    case FUNCTION_TYPE.CLOSEDSHAPE:
      obj.style.fill = COLOR_TYPE.WHITE;
      break;
    case FUNCTION_TYPE.TEXTBOX:
      obj.style.fill = COLOR_TYPE.TRANSPARENT;
      obj.style.stroke = COLOR_TYPE.GREEN;
      break;
    case FUNCTION_TYPE.ARC:
      // TODO: recreate Arc component to include sweepFlag, largeArcFlag and r
      // obj.sweepFlag = false;
      // obj.largeArcFlag = false;
      break;
    case FUNCTION_TYPE.SYMBOL:
      // obj.style.stroke = COLOR_TYPE.LIGHTGRAY;
      // obj.style.fill = COLOR_TYPE.BLACK;
      // obj.style.strokewidth = 1;
      obj.style.stroke = undefined;
      obj.style.fill = undefined;
      obj.style.opacity = 1;
      obj.shadow = {
        originHandlePoint: null,
        originRotateAngle: 0,
        shapes: [],
        isShadow: false,
        isActiveShadowMode: false,
      };
      break;
    case FUNCTION_TYPE.STATIONLINE:
    case FUNCTION_TYPE.XYMEASUREMENT:
    case FUNCTION_TYPE.TRIANGULATIONNETWORK: {
      obj.text = {
        ...obj.text,
        color: COLOR_TYPE.BLACK,
      };
      break;
    }
    case FUNCTION_TYPE.DIRT:
    case FUNCTION_TYPE.GRAVEL:
    case FUNCTION_TYPE.STREET: {
      obj.streetModel = 0; //0正常模式，1 拖拉模式
      obj.streetacrossdata = [];
      break;
    }
    default:
      break;
  }
}

// TODO: factory pattern to create different shapes?
// FIXME: not recommended to include 'streetType' param here
/**
 * 添加数据
 * @param {FUNCTION_TYPE} functype - FUNCTION_TYPE
 * @param {string} [objId] - operateId
 * @param {string} [streetType] - STREET_TYPE 当 StreetNew 时，才存在该参数
 */
export const addData = function(functype, objId, streetType) {
  //添加数据前，其他的数据必须为完成状态 (Before adding data, other data must be completed)
  if (functype !== FUNCTION_TYPE.CURBRETURN) {
    // 在拖动 street 过程中会产生 curb return，如果此时取消选择所有对象，会导致问题
    for (let i in useData) {
      useData[i].selectflag = false;
    }
  }
  const operateId = objId || generateOperateId();
  const obj = {
    operateid: operateId,
    functype: functype,
    selectflag: false,
    isgroup: false,
    groupId: '',
    groupdata: [],
    position: { x1: 0, y1: 0, x2: 0, y2: 0 },
    handlepoint: [],
    rotateangle: 0,
    // streetlanes: 0,
    lanewidth: STREET_SPACE.HEIGHT,
    surfaceType: 'paved',
    streetoffarc: false,
    offarccenter: 1 / 2,
    offarcheight: STREET_SPACE.OFFSETARCSPACE,

    streetdividetype: 'None',
    clickpoint: [],
    // selectlane: -1,
    // resizeHandleType: 'all',
    showResizeHandles: true,
    text: {
      text: '',
      textdefid: functype + operateId,
      color: COLOR_TYPE.BLACK,
      bold: FONT_WEIGHT_TYPE.NORMAL,
      italic: FONT_STYLE_TYPE.NORMAL,
      // font size in point
      size: 50,
      position: TEXT_POSITION_TYPE.MIDIUMTOP,
      textboxtype: TEXT_BOX_TYPE.BOX,
      point: [],
      islength: false,
    },
    style: {
      strokepattern: 'solid',
      strokedasharray: DASH_ARRAY_TYPE.solid,
      pattern: PATTERN_TYPE.singleSolid,
      stroke: COLOR_TYPE.BLACK,
      fill: COLOR_TYPE.WHITE,
      strokewidth: 1,
      strokeopacity: 1,
    },
    arrow: {
      type: ARROW_SHOW_TYPE.NONE,
      position: ARROW_SHOW_TYPE.NONE,
      width: 24, //默认
      height: 24,
    },
    marks: {
      length: 12, // intervalWidth
      height: 10,
      isexist: false,
    },
    // shapename: '',
  };
  _updateSpecData(obj, functype, streetType);
  // console.log('add obj', obj);
  // console.log('streetType', streetType);
  useData.push(obj);
  // userDataPush(operateJson);
  return obj;
};

/**
 * 添加 StreetNew 操作对象
 * @param {[number, number][] | {type:'line'}[]} data
 * @param {STREET_TYPE} [streetType]
 * @param {object} [options]
 * @param {boolean} options.isShowDividerBackground
 * @param {boolean} options.isShowShoulderBackground
 * @param {STREET_DEFAULT_SIZE} options.lanewidth
 */
export const addStreetData = function(data, streetType = STREET_TYPE.STRAIGHT, options) {
  if (!hasStreet('paved')) {
    const obj = addData(FUNCTION_TYPE.STREETSURFACE);
    obj.surfaceType = 'paved';
    obj.sfills = [];
    obj.crfills = [];
  }

  const operateId = generateOperateId();
  const functype = FUNCTION_TYPE.STREETNEW;
  const obj = {
    operateid: operateId,
    functype,
    selectflag: false,
    isgroup: false,
    groupId: '',
    groupdata: [],
    position: { x1: 0, y1: 0, x2: 0, y2: 0 },
    handlepoint: [],
    isShowDividerBackground: (options && options.isShowDividerBackground) || false,
    isShowShoulderBackground: (options && options.isShowShoulderBackground) || false,
    rotateangle: 0,
    // streetlanes: 0,
    lanewidth: STREET_SPACE.HEIGHT,
    surfaceType: 'paved',
    streetoffarc: false,
    offarccenter: 1 / 2,
    offarcheight: STREET_SPACE.OFFSETARCSPACE,
    lanewidth: (options && options.lanewidth) || STREET_DEFAULT_SIZE.WIDE_LANE_WIDTH,
    streetdividetype: 'None',
    clickpoint: [],
    // selectlane: -1,
    // resizeHandleType: 'all',
    showResizeHandles: true,
    text: {
      text: '',
      textdefid: functype + operateId,
      color: COLOR_TYPE.BLACK,
      bold: FONT_WEIGHT_TYPE.NORMAL,
      italic: FONT_STYLE_TYPE.NORMAL,
      // font size in point
      size: 50,
      position: TEXT_POSITION_TYPE.MIDIUMTOP,
      textboxtype: TEXT_BOX_TYPE.BOX,
      point: [],
      islength: false,
    },
    style: {
      strokepattern: 'solid',
      strokedasharray: DASH_ARRAY_TYPE.solid,
      pattern: PATTERN_TYPE.singleSolid,
      stroke: COLOR_TYPE.BLACK,
      fill: COLOR_TYPE.WHITE,
      strokewidth: 4,
      strokeopacity: 1,
    },
    arrow: {
      type: ARROW_SHOW_TYPE.NONE,
      position: ARROW_SHOW_TYPE.NONE,
      width: 24, //默认
      height: 24,
    },
    marks: {
      length: 12, // intervalWidth
      height: 10,
      isexist: false,
    },
    // shapename: '',
    isSketchRoad: (options && options.isSketchRoad) || false,
  };
  _updateSpecData(obj, functype, streetType);

  let segments;
  if (STREET_TYPE.CURVED) {
    segments = data;
  } else {
    const [startPt, endPt] = data;
    const ptStart = { x: startPt[0], y: startPt[1] };
    const ptStop = { x: endPt[0], y: endPt[1] };
    segments = [createLine({ ptStart, ptStop })];
    obj.handlepoint = [[startPt[0], startPt[1]], [endPt[0], endPt[1]]];
  }

  obj.segments = segments;
  obj.components = [
    createStripe(1, segments),
    createLane({ key: 2 }),
    createStripe(3, segments, 'singledash', true),
    createLane({ key: 4 }),
    createStripe(5, segments),
  ];
  obj.strokePattern = 'solid';
  obj.key = 1;
  obj.nextKey = 6;
  for (let i = 0, len = obj.groupdata.length; i < len; i++) {
    obj.groupdata[i].strokewidth = 4;
  }

  useData.push(obj);

  return obj;
};

/**
 * Get streets of same surface type and road layer
 * @param {object} [options]
 * @param {string} options.surfaceType
 * @param {ROAD_LAYER_VALUES} options.roadLayer
 * @param {boolean} options.isSketchRoad
 * @returns {{functype: 'StreetNew'}[]}
 */
export const getStreets = function(options) {
  let streets = useData.filter(s => s.functype === FUNCTION_TYPE.STREETNEW);
  if (options) {
    if (options.surfaceType) {
      streets = streets.filter(s => s.surfaceType === options.surfaceType);
    }
    if (options.roadLayer) {
      streets = streets.filter(s => s.roadLayer === options.roadLayer);
    }
    if (options.isSketchRoad) {
      streets = streets.filter(s => s.isSketchRoad === options.isSketchRoad);
    }
  }
  return streets;
};

export function deleteSketchRoads() {
  const sketchRoads = getStreets({ surfaceType: 'paved', isSketchRoad: true });
  sketchRoads.forEach(road => {
    deleteObject(road.operateid);
  });
}

/**
 * As SVG renders bottom elements first, we'll sometime need to sort the order again after changing some use data like road layer, etc.
 * Rules are: 1. StreetSurface is the
 */
export const sortUseData = () => {
  if (useData.length > 0 && useData.some(n => n.functype === FUNCTION_TYPE.STREETSURFACE)) {
    let indexes = [];
    let useDataSorted = useData
      .filter((n, index) => {
        if (n.functype === FUNCTION_TYPE.STREETNEW) {
          indexes.push(index);
          return true;
        }
        return false;
      })
      .sort((a, b) => a.roadLayer - b.roadLayer);
    for (let i = 0; i < indexes.length; i++) {
      useData[indexes[i]] = useDataSorted[i];
    }
  }
};

/**
 *
 * @param {*} data
 */
export const addCopyDataFront = function(data) {
  var jsonData = _.cloneDeep(data);
  jsonData.operateid = generateOperateId();
  useData = [jsonData].concat(useData);
};

/**
 *
 * @param {*} operateJson
 */
export const useDataPush = function(operateJson) {
  if (operateJson.functype === 'Symbol') {
    useData.push(operateJson);
  } else {
    // const index = useData.findIndex(u => u.functype === 'Symbol');
    // if (index > -1) {
    //   // useData = [operateJson].concat(useData);
    //   const tempArr = _.chunk(useData, index);
    //   useData = tempArr[0].concat([operateJson]).concat(tempArr[1]);
    // } else {
    //   useData.push(operateJson);
    // }
    const symbols = useData.filter(u => u.functype === 'Symbol');
    symbols.forEach(item => {
      this.deleteObject(item.operateid);
    });
    useData.push(operateJson);
    symbols.forEach(item => {
      this.addCopyDataFront(item);
    });
  }
};

/**
 *
 * @param {object} selectData - useData
 * @param {number[][]} handlePoint
 */
export const addGroupData = function(selectData, handlePoint) {
  var operateId = generateOperateId();
  var groupData = [];
  setGroupForObjects(selectData);
  for (let i = 0; i < selectData.length; i++) {
    var groupChildData = _.cloneDeep(selectData[i]);
    groupChildData.isgroupchild = true;
    groupData.push(groupChildData);
  }
  var functype = FUNCTION_TYPE.GROUP;
  var operateJson = {
    operateid: operateId,
    functype: functype,
    selectflag: true,
    isgroupchild: false,
    groupdata: groupData.concat(),
    position: { x1: 0, y1: 0, x2: 0, y2: 0 },
    handlepoint: handlePoint.concat(),
    rotateangle: 0,
    streetnum: 0,
    divider: STREET_DIVIDER_TYPE.NONE,
    text: {
      text: '',
      textdefid: functype + operateId,
      color: COLOR_TYPE.BLACK,
      bold: FONT_WEIGHT_TYPE.NORMAL,
      italic: FONT_STYLE_TYPE.NORMAL,
      size: 50,
      position: TEXT_POSITION_TYPE.MIDIUMTOP,
      textboxtype: TEXT_BOX_TYPE.BOX,
      point: [],
      islength: false,
    },
    // style: {
    //   strokepattern: 'solid',
    //   strokedasharray: DASH_ARRAY_TYPE.solid,
    //   stroke: COLOR_TYPE.BLACK,
    //   fill: COLOR_TYPE.WHITE,
    //   strokewidth: 1,
    //   strokeopacity: 1,
    // },
    arrow: {
      type: ARROW_SHOW_TYPE.NONE,
      position: ARROW_SHOW_TYPE.NONE,
      width: 24, //默认
      height: 24,
    },
    marks: {
      length: 12, // intervalWidth
      height: 10,
      isexist: false,
    },
  };
  useData.push(operateJson);
};

/**
 *
 * @param {object} position
 * @param {number} position.x1
 * @param {number} position.y1
 * @param {number} position.x2
 * @param {number} position.y2
 */
export const setCurrentObjPosition = function(position) {
  var index = useData.length - 1;
  useData[index].position = position;
};

/**
 *
 * @param {object} position
 * @param {number} position.x1
 * @param {number} position.y1
 * @param {number} position.x2
 * @param {number} position.y2
 */
function generateHandlePoint(position) {
  var handlePoint = [];
  // 生成原点初始形状 (Generating initial shape)

  var angle = [0, 0];
  if (position.x1 > position.x2) {
    angle = [position.x1 + LENGTH_TYPE.ANGLELENGTH, (position.y1 + position.y2) / 2];
  } else {
    angle = [position.x2 + LENGTH_TYPE.ANGLELENGTH, (position.y1 + position.y2) / 2];
  }
  handlePoint = [
    [position.x1, position.y1],
    [position.x2, position.y1],
    [position.x2, position.y2],
    [position.x1, position.y2],
    [(position.x1 + position.x2) / 2, position.y1],
    [(position.x1 + position.x2) / 2, position.y2],
    [position.x1, (position.y1 + position.y2) / 2],
    [position.x2, (position.y1 + position.y2) / 2],
    [angle[0], angle[1]],
    [angle[0], position.y1],
    [angle[0], position.y2],
  ];
  return handlePoint;
}

/**
 * 设置完Position后只能调用放大或者缩小 (After setting Position, you can only call zoom-in or zoom-out.)
 * @param {string} operateid
 * @param {object} position
 * @param {number} position.x1
 * @param {number} position.y1
 * @param {number} position.x2
 * @param {number} position.y2
 */
export const setObjPosition = function(operateid, position) {
  if (!operateid) return;
  for (var i in useData) {
    if (useData[i].operateid == operateid) {
      useData[i].position = position;
      if (
        useData[i].functype == FUNCTION_TYPE.LINE ||
        useData[i].functype == FUNCTION_TYPE.ARC ||
        useData[i].functype === FUNCTION_TYPE.STRIPE
      ) {
        useData[i].handlepoint = [[position.x1, position.y1], [position.x2, position.y2]];
      } else if (useData[i].functype == FUNCTION_TYPE.DIMENSIONLINE) {
        useData[i].handlepoint = [
          [position.x1, position.y1],
          [position.x2, position.y2],
          [(position.x1 + position.x2) / 2, (position.y1 + position.y2) / 2],
        ];
      } else if (
        useData[i].functype == FUNCTION_TYPE.RECTANGLE ||
        useData[i].functype == FUNCTION_TYPE.ELLIPSE ||
        useData[i].functype == FUNCTION_TYPE.SQUARE ||
        useData[i].functype == FUNCTION_TYPE.CIRCLE ||
        useData[i].functype == FUNCTION_TYPE.TEXTBOX ||
        useData[i].functype == FUNCTION_TYPE.LASSOAREA ||
        useData[i].functype == FUNCTION_TYPE.ZOOMSELECT ||
        useData[i].functype == FUNCTION_TYPE.SELECTION
      ) {
        //对应  LEFTTOP: "lefttop", RIGHTTOP: "righttop",
        // RGIHTBOTTOM: "rightbottom",LEFTBOTTOM: "leftbottom",
        // TOPMEDIUM: "topmedium", BOTTOMMEDIUM:"bottommedium",
        // LEFTMEDIUM:"leftmedium", RIGHTMEDIUM:"rightmedium",
        // ANGLE:"angle",
        useData[i].handlepoint = generateHandlePoint(position);
        useData[i].rotateorigin = [(position.x1 + position.x2) / 2, (position.y1 + position.y2) / 2];
      }
    }
  }
  return;
};

export const getObjIndexById = operateId => {
  return useData.findIndex(obj => obj.operateid === operateId);
};

/**
 *
 * @param  {...any} args
 */
export const setObj = function(...args) {
  let operateId, obj;
  if (args.length === 1) {
    obj = args[0];
    operateId = (obj && obj.operateid) || undefined;
  } else if (args.length === 2) {
    [operateId, obj] = args;
  }
  if (operateId && obj) {
    const index = getObjIndexById(operateId);
    if (index > -1) {
      const object = _.cloneDeep(obj);
      useData[index] = object;
      emitter.emit(EVENT_EMIT_TYPE.OBJECT_UPDATED, object);
    }
  } else if (__DEV__) {
    console.error(new Error(`calling setObj function incorrectly`));
    console.trace();
  }
};

/* Unused in anywhere
export const setObjSelctFlag = function(operateId, selectflag) {
  if (!operateId) return;
  for (var i in useData) {
    if (useData[i].operateid == operateId) {
      useData[i].selectflag = selectflag;
      if (!useData[i].selectflag && useData[i].functype == FUNCTION_TYPE.STREETNEW) useData[i].idSelectedComponent = -1;
    }
  }
};
*/

/**
 *
 * @param {boolean} selectflag
 */
export const setAllObjectFlag = function(selectflag) {
  for (var i in useData) {
    if (useData[i].functype !== FUNCTION_TYPE.STREETSURFACE) {
      useData[i].selectflag = selectflag;
    }
  }
};

/**
 *
 * @param {string} streetId
 */
export const deleteStreetAcrossObject = function(streetId) {
  delStreetAcrossObject(streetId);
};

/**
 *
 * @param {string} streetId
 */
function delStreetAcrossObject(streetId) {
  for (let i = useData.length - 1; i >= 0; i--) {
    if (useData[i].functype === FUNCTION_TYPE.STREETACROSS) {
      if (!(typeof useData[i].acrossid !== 'undefined' && useData[i].acrossid !== null)) continue;
      let ids = useData[i].acrossid.split('||');
      if (streetId == ids[0] || streetId == ids[1]) {
        delStreetAcrossData(streetId);
        useData.splice(i, 1);
      }
    }
  }
}

function delStreetAcrossData() {
  for (let i = useData.length - 1; i >= 0; i--) {
    if (useData[i].functype === FUNCTION_TYPE.STREET) {
      if (useData[i].isstreetacross === false) continue;
      let acrossData = useData[i].streetacrossdata;
      for (let j = acrossData.length - 1; j >= 4; j--) {
        acrossData.splice(j, 1);
      }
      useData[i].streetacrossdata = acrossData;
    }
  }
}

/**
 * 删除选中的对象
 */
export const deleteSelectObject = function() {
  let iCount = useData.length;
  let streets = [];
  for (let i = iCount - 1; i >= 0; i--) {
    if (useData[i].selectflag == true) {
      if (useData[i].functype === FUNCTION_TYPE.STREET) {
        streets.push(useData[i].operateid);
      }
      useData.splice(i, 1);
    }
  }
  for (let i = 0, il = streets.length; i < il; i++) {
    delStreetAcrossObject(streets[i]);
  }
};

/**
 * 删除指定的一个对象或多个对象
 * @param {string} operateId
 */
export const deleteObject = function(param) {
  let streets = [];

  if (param instanceof Array) {
    let operateIdArr = param;
    operateIdArr.forEach(operateId => {
      for (let i = useData.length - 1; i >= 0; i--) {
        if (useData[i].operateid == operateId) {
          if (useData[i].functype === FUNCTION_TYPE.STREET) {
            streets.push(useData[i].operateid);
          }
          useData.splice(i, 1);
          break;
        }
      }
    });
  } else if (typeof param === 'string') {
    let operateId = param;
    for (let i = useData.length - 1; i >= 0; i--) {
      if (useData[i].operateid == operateId) {
        if (useData[i].functype === FUNCTION_TYPE.STREET) {
          streets.push(useData[i].operateid);
        }
        useData.splice(i, 1);
        break;
      }
    }
  }

  for (let i = 0, il = streets.length; i < il; i++) {
    delStreetAcrossObject(streets[i]);
  }
};

/**
 *
 * @param {string} operateId
 * @returns {object | undefined}
 */
export const getObject = function(operateId) {
  return useData.find(data => data.operateid === operateId);
};

/**
 *
 * @param {string} operateId
 * @returns {object | undefined}
 */
export const getObjectOrGroupObject = operateId => {
  if (!operateId) {
    return null;
  }
  for (let i in useData) {
    if (useData[i].operateid === operateId) {
      return useData[i];
    }
    if (useData[i].functype === FUNCTION_TYPE.GROUP) {
      for (let j in useData[i].groupdata) {
        if (useData[i].groupdata[j].operateid === operateId) {
          return useData[i].groupdata[j];
        }
      }
    }
  }
  return null;
};

/**
 *
 * @param {string} operateId
 * @param {boolean} selectFlag
 */
export const setObjectSelectFlag = function(operateId, selectFlag) {
  if (!operateId) return;
  for (var i in useData) {
    if (useData[i].operateid == operateId) {
      useData[i].selectflag = selectFlag;
      if (useData[i].functype === FUNCTION_TYPE.STRUCTURE) {
        for (let j = 0; j < useData[i].handlepoint.length - 1; j++) {
          useData[i].handlepoint[j][3].nearline = -1;
          useData[i].handlepoint[j][3].nearx = -1;
          useData[i].handlepoint[j][3].neary = -1;
        }
      }
      if (!useData[i].selectflag && useData[i].functype == FUNCTION_TYPE.STREETNEW) useData[i].idSelectedComponent = -1;
    }
  }
};

export const cancelSelectAllObjects = function() {
  //结束所有元素的状态 (End the state of all elements)
  for (var i in useData) {
    useData[i].selectflag = false;
    if (useData[i].functype === FUNCTION_TYPE.STRUCTURE) {
      useData[i].marks.islength = false;
      for (let j = 0; j < useData[i].handlepoint.length - 1; j++) {
        useData[i].handlepoint[j][3].selectflag = false;
        useData[i].handlepoint[j][3].nearline = -1;
        useData[i].handlepoint[j][3].nearx = -1;
        useData[i].handlepoint[j][3].neary = -1;
      }
    } else if (useData[i].functype === FUNCTION_TYPE.STREET) {
      for (let j = 0; j < useData[i].groupdata.length; j++) {
        useData[i].groupdata[j].selectline = false;
        // useData[i].groupdata[j].selectlane = -1;
      }
    } else if (useData[i].functype === FUNCTION_TYPE.STREETACROSS) {
      for (let j = 0; j < useData[i].handlepoint.length; j++) {
        if (useData[i].handlepoint[j].length < 3) continue;
        useData[i].handlepoint[j][3].selectflag = false;
      }
    }
    if (!useData[i].selectflag && useData[i].functype == FUNCTION_TYPE.STREETNEW) useData[i].idSelectedComponent = -1;
  }
};

/**
 *
 * @param {string} operateId - s1, s2, etc.
 * @param {PATTERN_TYPE} strokePattern
 * @param {DASH_ARRAY_TYPE} lineType
 */
export const setObjectStyleLine = function(operateId, strokePattern, lineType) {
  if (!operateId) return;
  for (var i in useData) {
    if (useData[i].operateid == operateId) {
      useData[i].style.strokepattern = strokePattern;
      useData[i].style.strokedasharray = lineType;
    }
  }
};

/**
 *
 * @param {string} operateId - s1, s2, etc.
 * @param {COLOR_TYPE} colorType
 */
export const setObjectStyleLineColor = function(operateId, colorType) {
  if (!operateId) return;
  for (var i in useData) {
    if (useData[i].operateid == operateId) {
      useData[i].style.stroke = colorType;
    }
  }
};

/**
 *
 * @param {string} operateId - s1, s2, etc.
 * @param {COLOR_TYPE} colorType
 */
export const setObjectStyleFillColor = function(operateId, colorType) {
  // console.log(operateId, colorType);
  if (!operateId) return;
  for (var i in useData) {
    if (useData[i].operateid == operateId) {
      useData[i].style.fill = colorType;
    }
  }
};

/**
 *
 * @param {number[][]} handlePoint
 * @param {string} src - base64
 */
export const addImageData = (handlePoint, src) => {
  var operateid = generateOperateId();
  var functype = FUNCTION_TYPE.IMAGE;
  var operateJson = {
    operateid: operateid,
    functype: functype,
    selectflag: true,
    position: { x1: 0, y1: 0, x2: 0, y2: 0 },
    imagesrc: src,
    handlepoint: handlePoint.concat(),
    rotateangle: 0,
    text: {
      text: '',
      textdefid: functype + operateid,
      color: COLOR_TYPE.BLACK,
      bold: FONT_WEIGHT_TYPE.NORMAL,
      italic: FONT_STYLE_TYPE.NORMAL,
      size: 50,
      position: TEXT_POSITION_TYPE.MIDIUMTOP,
      textboxtype: TEXT_BOX_TYPE.BOX,
      point: [],
      islength: false,
    },
    style: {
      strokepattern: 'solid',
      strokedasharray: DASH_ARRAY_TYPE.solid,
      stroke: COLOR_TYPE.BLACK,
      fill: COLOR_TYPE.WHITE,
      strokewidth: 1,
      strokeopacity: 1,
    },
    arrow: {
      type: ARROW_SHOW_TYPE.NONE,
      position: ARROW_SHOW_TYPE.NONE,
      width: 24, //默认
      height: 24,
    },
    marks: {
      length: 12, // intervalWidth
      height: 10,
      isexist: false,
    },
  };
  useData.push(operateJson);
};

export const setObjectStylelineWidth = function(operateId, width) {
  if (!operateId) return;
  for (var i in useData) {
    if (useData[i].operateid == operateId) {
      useData[i].style.strokewidth = width;
    }
  }
};
export const setObjectStyleArrow = function(operateId, arrow) {
  if (!operateId) return;
  for (var i in useData) {
    if (useData[i].operateid == operateId) {
      useData[i].style.arrow = arrow;
    }
  }
};

export const isLocked = arg => {
  let obj;
  if (typeof arg === 'string') {
    obj = getObject(arg);
  } else {
    obj = arg;
  }
  let locked = false;
  switch (obj.functype) {
    case FUNCTION_TYPE.STREETNEW:
    case FUNCTION_TYPE.DIRT:
    case FUNCTION_TYPE.GRAVEL:
    case FUNCTION_TYPE.CROSSWALK:
    case FUNCTION_TYPE.PARKINGSTALLS:
    case FUNCTION_TYPE.CURBRETURN: {
      locked = window.appState.canvas.isLockingRoads;
      break;
    }
    default:
      break;
  }
  return locked;
};

export const setObjectFlagByRange = function(point1, point2) {
  let beginX = 0;
  let beginY = 0;
  let endX = 0;
  let endY = 0;

  if (!point1 || !point2) {
    return;
  }

  if (point1[0] < point2[0]) {
    beginX = point1[0];
    endX = point2[0];
  } else {
    beginX = point2[0];
    endX = point1[0];
  }

  if (point1[1] < point2[1]) {
    beginY = point1[1];
    endY = point2[1];
  } else {
    beginY = point2[1];
    endY = point1[1];
  }

  for (let i in useData) {
    const obj = useData[i];
    let handlePoint = obj.handlepoint.concat();
    let iCount = 0; // 用于判断区域内的锚点数量
    // 判断是否所有的锚点都在区域内
    for (let j in handlePoint) {
      if (
        handlePoint[j][0] > beginX &&
        handlePoint[j][0] < endX &&
        handlePoint[j][1] > beginY &&
        handlePoint[j][1] < endY
      ) {
        iCount += 1;
      }
    }

    // do not select the street surface
    if (iCount == handlePoint.length && obj.functype !== FUNCTION_TYPE.STREETSURFACE) {
      obj.selectflag = !isLocked(obj.operateid);
    }
  }
};

export const getDataCount = function() {
  return useData.length;
};

export const hasData = () => {
  return useData.length > 0;
};

/**
 * get selected objects
 * @returns {{functype: string, operateid: string}[]}
 */
export const getSelectObjects = function() {
  let selectUserDataset = [];
  for (let i = 0, il = useData.length; i < il; i++) {
    const data = useData[i];
    if (data.selectflag === true) {
      let jsondata = _.cloneDeep(useData[i]);
      selectUserDataset.push(jsondata);
    }
  }
  return selectUserDataset;
};

/**
 *
 * @param {{functype: string, operateid: string}} object
 */
export const addDataObject = function(object) {
  useData.push(object);
};

/**
 * 在某一个对象后面插入数据
 * @param {string} objid - 被插入的对象 id
 * @param {{functype: string, operateid: string}} object
 */
export const addDataAfterObject = function(objid, object) {
  let bFind = false;
  let index = 0;
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].operateid === objid) {
      bFind = true;
      index = i + 1;
    }
  }
  if (!bFind) index = useData.length;
  useData.splice(index, 0, object);
};

/**
 * 在某一个对象前面插入数据
 * @param {string} objid - 被插入的对象 id
 * @param {{functype: string, operateid: string}} object
 */
export const addDataBeforeObject = function(objid, object) {
  let bFind = false;
  let index = 0;
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].operateid === objid) {
      bFind = true;
      index = i;
    }
  }
  if (!bFind) index = useData.length;
  if (index < 0) index = 0;
  useData.splice(index, 0, object);
};

/**
 * 判断两个 id数据插入前后  与 界面相反
 * @param {string} beforeId
 * @param {string} afterId
 * @returns {boolean}
 */
export const IsBeforeObject = function(beforeId, afterId) {
  let bFind = false;
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].operateid === beforeId) {
      bFind = true;
    }
    if (bFind && useData[i].operateid === afterId) {
      return true;
    }
  }
  return false;
};

/**
 *
 * @param {string} objtype
 * @returns {number}
 */
export const getLastObject = function(objtype) {
  let dirtIndex = useData.length;
  let gravelIndex = useData.length;
  let pavedStreetSurfaceIndex = useData.length;
  let pavedIndex = useData.length;
  let curbReturnIndex = useData.length;
  let crossWalkIndex = useData.length;
  let structureIndex = useData.length;
  let symbolIndex = useData.length;
  let defaultIndex = useData.length;

  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.DIRT) {
      dirtIndex = i;
      break;
    }
  }

  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.GRAVEL) {
      gravelIndex = i;
      break;
    }
  }

  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.STREET) {
      pavedIndex = i;
      break;
    }
  }
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.STREETSURFACE) {
      pavedStreetSurfaceIndex = i;
      break;
    }
  }
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.STREETNEW) {
      pavedIndex = i;
      break;
    }
  }
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.CURBRETURN) {
      curbReturnIndex = i;
      break;
    }
  }
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.CROSSWALK) {
      crossWalkIndex = i;
      break;
    }
  }
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.STRUCTURE) {
      structureIndex = i;
      break;
    }
  }

  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.SYMBOL) {
      symbolIndex = i;
      break;
    }
  }
  for (let i = 0; i < useData.length; i++) {
    if (
      useData[i].functype != FUNCTION_TYPE.DIRT &&
      useData[i].functype != FUNCTION_TYPE.GRAVEL &&
      useData[i].functype != FUNCTION_TYPE.STREET &&
      useData[i].functype != FUNCTION_TYPE.STREETSURFACE &&
      useData[i].functype != FUNCTION_TYPE.STREETNEW &&
      useData[i].functype != FUNCTION_TYPE.CURBRETURN &&
      useData[i].functype != FUNCTION_TYPE.STRUCTURE &&
      useData[i].functype != FUNCTION_TYPE.CROSSWALK
    ) {
      defaultIndex = i;
      break;
    }
  }
  if (objtype == FUNCTION_TYPE.DIRT) {
    if (gravelIndex < useData.length) return gravelIndex;
    else if (pavedStreetSurfaceIndex < useData.length) return pavedStreetSurfaceIndex;
    else if (structureIndex < useData.length) return structureIndex;
    else if (defaultIndex < useData.length) return defaultIndex;
    else return useData.length;
  } else if (objtype == FUNCTION_TYPE.GRAVEL) {
    if (pavedStreetSurfaceIndex < useData.length) return pavedStreetSurfaceIndex;
    else if (structureIndex < useData.length) return structureIndex;
    else if (defaultIndex < useData.length) return defaultIndex;
    else return useData.length;
  } else if (objtype == FUNCTION_TYPE.STREET) {
    if (structureIndex < useData.length) return structureIndex;
    else if (defaultIndex < useData.length) return defaultIndex;
    else return useData.length;
  } else if (objtype == FUNCTION_TYPE.STREETSURFACE) {
    if (pavedIndex < useData.length) return pavedIndex;
    else if (structureIndex < useData.length) return structureIndex;
    else if (defaultIndex < useData.length) return defaultIndex;
    else return useData.length;
  } else if (objtype == FUNCTION_TYPE.STREETNEW) {
    if (curbReturnIndex < useData.length) return curbReturnIndex;
    else if (structureIndex < useData.length) return structureIndex;
    else if (defaultIndex < useData.length) return defaultIndex;
    else return useData.length;
  } else if (objtype == FUNCTION_TYPE.CURBRETURN) {
    if (structureIndex < useData.length) return structureIndex;
    else if (defaultIndex < useData.length) return defaultIndex;
    else return useData.length;
  } else if (objtype == FUNCTION_TYPE.STRUCTURE) {
    // if (crossWalkIndex < useData.length) return crossWalkIndex;
    if (defaultIndex < useData.length) return defaultIndex;
    else return useData.length;
  } else {
    // if (crossWalkIndex < useData.length) return crossWalkIndex;
    return useData.length;
  }
};

/**
 *
 * @param {number} index
 * @param {{functype: string, operateid: string}} object - one item in workData(useData)
 */
export const insertObject = function(index, object) {
  if (index != useData.length) {
    useData.splice(index, 0, object);
  }
};

/**
 *
 * @param {string} surfaceType
 * @returns {boolean}
 */
export const hasStreet = function(surfaceType) {
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.STREETSURFACE) {
      if (useData[i].surfaceType == surfaceType) {
        return true;
      }
    }
  }
  return false;
};

/**
 *
 * @param {number} opacity
 * @returns {boolean}
 */
export const setStreetOpacity = function(opacity) {
  for (let i = 0; i < useData.length; i++) {
    if (useData[i].functype == FUNCTION_TYPE.STREETSURFACE) {
      useData[i].style.style = { opacity: opacity };
    }
  }
  return false;
};

/**
 *
 * @param {number} index
 * @param {{functype: string, operateid: string}} object
 */
export const sliceObject = function(index, object) {
  useData.splice(index, 0, object);
};

/**
 *
 * @param {*} data
 * @param {*} config - extra param for data or replace param to data
 * @return {string} operateId
 */
export const addCopyData = function(data, config) {
  // don't create a 2nd Street Surface
  if (
    data.functype === FUNCTION_TYPE.STREETSURFACE &&
    _.findIndex(useData, n => n.functype === FUNCTION_TYPE.STREETSURFACE) > -1
  ) {
    return;
  }
  var jsondata = _.cloneDeep(data);
  jsondata.operateid = generateOperateId();
  jsondata.isgroupchild = false;
  jsondata.selectflag = true;
  if (typeof config !== 'undefined') {
    jsondata = { ...jsondata, ...config };
  }
  useData.push(jsondata);
  return jsondata;
};

export const copyUserData = function() {
  const copyData = [];
  var tempUseData = useData.slice(0);
  for (let i = 0; i < tempUseData.length; i++) {
    if (tempUseData[i].selectflag == true) {
      var jsondata = _.cloneDeep(tempUseData[i]);
      copyData.push(jsondata);
    }
  }
  return copyData;
};

export const getCopyUserData = function() {
  var tempCopydata = copyData.slice(0);
  return tempCopydata;
};

/**
 *
 * @param {*} objects
 */
export const setGroupForObjects = function(objects) {
  const groupId = uuid();
  objects.forEach(object => {
    if (FUNCTION_TYPE.STREETSURFACE !== object.functype) {
      object.isgroup = true;
      object.groupId = groupId;
    }
  });
  return objects;
};

/**
 *
 * @param {*} data
 */
export const hasOnlyOneObject = function(data) {
  const checkedData = data || useData;
  return 1 === checkedData.filter(data => FUNCTION_TYPE.STREETSURFACE !== data.functype).length;
};

/**
 *
 * @param {*} data
 */
export const addMarkData = data => {
  const allMarkerNumber = useData
    .filter(data => data.functype === FUNCTION_TYPE.MARKER)
    .map(data => parseInt(data.text.text.slice(1)));
  addData(FUNCTION_TYPE.MARKER);
  const addedData = useData[useData.length - 1];
  // 获取原来图形上 handlepoint 最右上的点 作为 marker 的中心点 ([Max(...x), Max(...y)])
  data.handlepoint.forEach(point => {
    const handlePointNotExist = addedData.handlepoint.length === 0;
    const handlePointNotMaxPoint = !handlePointNotExist && addedData.handlepoint[0] < point[0];
    const haddlePointNotTopRight =
      !handlePointNotExist && addedData.handlepoint[0] === point[0] && addedData.handlepoint[1] < point[1];
    if (handlePointNotExist || handlePointNotMaxPoint || haddlePointNotTopRight) {
      addedData.handlepoint = point;
    }
  });
  addedData.handlepoint = [
    [addedData.handlepoint[0] - LENGTH_TYPE.INFORANGLESPACE, addedData.handlepoint[1] + LENGTH_TYPE.INFORANGLESPACE],
  ];
  const nextMarkerNumber = 1 + Math.max(...allMarkerNumber, 0);
  addedData.text.text = `#${nextMarkerNumber}`;
};

/**
 * select object
 * @param {string} operateId
 */
export const selectObject = operateId => {
  useData.map(d => (d.selectflag = typeof d.selectflag !== 'undefined' ? false : undefined));
  if (operateId) {
    const obj = getObject(operateId);
    if (typeof obj !== 'undefined') {
      obj.selectflag = true;
    }
  }
};

// export function CutUserData() {
//   var userdata = copyUserData();
//   for (let i = 0; i < userdata.length; i++) {
//     deleteObject(userdata[i].operateid);
//   }
// }

// FIXME: do not paste STREET SURFACE
/**
 *
 * @param {{functype:string, operateid:string}} copyData
 * @param {'cut' | 'copy'} status
 * @param {[number, number]} point
 */
export function pasteData(copyData, status, point) {
  cancelSelectAllObjects();
  // paste data away from original shapes so user can handle the pasted shapes easily
  const [[xmin, ymax], [xmax, ymin]] = HandlePointRange(copyData);
  let move = [0, 0];
  if (!point) {
    if (status === 'cut') {
      // 剪切 粘贴到原位置
      move = [0, 0];
    } else if (status === 'copy') {
      // 复制 粘贴到原位置右侧
      move = [xmax - xmin + 30, 0];
    }
  } else {
    // 没位置传入 设置一个默认位置
    const [x, y] = devicePtToUserPt2(...point);
    move = [x - (xmin + xmax) / 2, y - (ymin + ymax) / 2];
  }
  for (let i = 0; i < copyData.length; i++) {
    let handlePoint = copyData[i].handlepoint.concat();
    for (let j in handlePoint) {
      let tempPoint = pointTransform([1, 0, 0, 1, move[0], move[1]], handlePoint[j]);
      CopyPoint(handlePoint[j], tempPoint);
    }
    copyData[i].handlepoint = handlePoint.concat();
    if (copyData[i].functype === 'StreetNew') {
      let street = copyData[i];
      if (Utility.isNonEmptyArray(street.segments)) {
        for (let j = 0; j < street.segments.length; j++) {
          let tempPoint = pointTransform(
            [1, 0, 0, 1, move[0], move[1]],
            [street.segments[j].ptStart.x, street.segments[j].ptStart.y]
          );
          street.segments[j].ptStart = { x: tempPoint[0], y: tempPoint[1] };
          tempPoint = pointTransform(
            [1, 0, 0, 1, move[0], move[1]],
            [street.segments[j].ptStop.x, street.segments[j].ptStop.y]
          );
          street.segments[j].ptStop = { x: tempPoint[0], y: tempPoint[1] };
        }
      }
      let _s = new _Street();
      let lw = _s.alignComponentsToStreetAxis(street);
      _s.computeStreets(street);
    }
  }
  for (let k in copyData) {
    // setObjectSelectFlag(copyData[k].operateid, false);
    addCopyData(copyData[k]);
  }
}

export function BringFront() {
  let selectUserData = getSelectObjects();
  for (let i = 0, il = selectUserData.length; i < il; i++) {
    deleteObject(selectUserData[i].operateid);
    addCopyData(selectUserData[i]);
  }
}

export function BringBack() {
  let selectUserData = getSelectObjects();
  for (let i = 0, il = selectUserData.length; i < il; i++) {
    deleteObject(selectUserData[i].operateid);
    addCopyDataFront(selectUserData[i]);
  }
}

/**
 *
 * @param {object[]} [useData]
 * @returns {boolean}
 */
export function GroupShapes(useData = null) {
  var selectData = useData || getSelectObjects();
  var bbox = getContainingBBox(selectData);
  var pointRange = [[bbox.xMin, bbox.yMax], [bbox.xMax, bbox.yMin]];
  var handlepoint = [];
  (function() {
    handlepoint[0] = pointRange[0];
    handlepoint[1] = [pointRange[1][0], pointRange[0][1]];
    handlepoint[2] = pointRange[1];
    handlepoint[3] = [pointRange[0][0], pointRange[1][1]];
    handlepoint[4] = GetCenterInTwoPoints(handlepoint[0], handlepoint[1]);
    handlepoint[5] = GetCenterInTwoPoints(handlepoint[2], handlepoint[3]);
    handlepoint[6] = GetCenterInTwoPoints(handlepoint[0], handlepoint[3]);
    handlepoint[7] = GetCenterInTwoPoints(handlepoint[1], handlepoint[2]);
    handlepoint[8] = [handlepoint[7][0] + LENGTH_TYPE.ANGLELENGTH, handlepoint[7][1]];
    handlepoint[9] = [handlepoint[1][0] + LENGTH_TYPE.ANGLELENGTH, handlepoint[1][1]];
    handlepoint[10] = [handlepoint[2][0] + LENGTH_TYPE.ANGLELENGTH, handlepoint[2][1]];
  })(handlepoint);
  (function addPaddingForGroupShape(handlepoint) {
    const groupPadding = 20;
    handlepoint[0] = [handlepoint[0][0] - groupPadding, handlepoint[0][1] + groupPadding];
    handlepoint[1] = [handlepoint[1][0] + groupPadding, handlepoint[1][1] + groupPadding];
    handlepoint[2] = [handlepoint[2][0] + groupPadding, handlepoint[2][1] - groupPadding];
    handlepoint[3] = [handlepoint[3][0] - groupPadding, handlepoint[3][1] - groupPadding];
    handlepoint[4] = [handlepoint[4][0], handlepoint[4][1] + groupPadding];
    handlepoint[5] = [handlepoint[5][0], handlepoint[5][1] - groupPadding];
    handlepoint[6] = [handlepoint[6][0] - groupPadding, handlepoint[6][1]];
    handlepoint[7] = [handlepoint[7][0] + groupPadding, handlepoint[6][1]];
  })(handlepoint);

  //加入到group节点
  // console.log('selectData', selectData);
  // console.log('handlepoint', handlepoint);
  addGroupData(selectData, handlepoint);
  //删除原来的节点
  for (let i = 0, len = selectData.length; i < len; i++) {
    deleteObject(selectData[i].operateid);
  }
  return true;
}

/**
 * @returns {boolean}
 */
export function UnGroupShapes() {
  var selectData = getSelectObjects();
  if (selectData.length != 1) return false;
  if (selectData[0].selectflag != true || selectData[0].functype != FUNCTION_TYPE.GROUP) return false;
  for (var i in selectData[0].groupdata) {
    addCopyData(selectData[0].groupdata[i]);
  }
  deleteObject(selectData[0].operateid);
  return true;
}

/**
 * get BBox that will wrap the given objects, or selected objects by default
 * @param {{functype: string, operateid: string}[]} objList
 * @returns {{x: number, y: number, width: number, height: number, xMin: number, yMin: number, xMax: number, yMax: number}}
 */
function getContainingBBox(objList) {
  const items = objList || getSelectObjects();
  const points = items.map(obj => obj.handlepoint).reduce((arr, points) => arr.concat(points), []);
  const xMin = _.minBy(points, p => p[0])[0];
  const xMax = _.maxBy(points, p => p[0])[0];
  const yMin = _.minBy(points, p => p[1])[1];
  const yMax = _.maxBy(points, p => p[1])[1];
  return {
    x: xMin,
    y: yMin,
    width: xMax - xMin,
    height: yMax - yMin,
    xMin,
    yMin,
    xMax,
    yMax,
  };
}

/**
 *
 * @param {CANVAS_MENU_TYPE} direction
 */
export function arrangeSelection(direction) {
  const selectedData = getSelectObjects().filter(n => n.functype !== FUNCTION_TYPE.STREETSURFACE);
  if (selectedData.length < 2) {
    return;
  }
  const { x, y, width, height, xMax, yMax } = getContainingBBox();

  const [xMid, yMid] = [x + width / 2.0, y + height / 2.0];
  let translateX = 0;
  let translateY = 0;
  selectedData.forEach(obj => {
    const getObjectBBox = obj => getContainingBBox([obj]);
    const bbox = getObjectBBox(obj);
    // console.log(direction, x, y, width, height, xMax, yMax)
    switch (direction) {
      case CANVAS_MENU_TYPE.LEFT:
        translateX = x - bbox.xMin;
        break;
      case CANVAS_MENU_TYPE.RIGHT:
        translateX = xMax - bbox.xMax;
        break;
      case CANVAS_MENU_TYPE.CENTER:
        translateX = xMid - (bbox.xMin + bbox.xMax) / 2.0;
        break;
      case CANVAS_MENU_TYPE.TOP:
        translateY = yMax - bbox.yMax;
        break;
      case CANVAS_MENU_TYPE.BOTTOM:
        translateY = y - bbox.yMin;
        break;
      case CANVAS_MENU_TYPE.MIDDLE:
        translateY = yMid - (bbox.yMin + bbox.yMax) / 2.0;
        break;
      default:
        break;
    }
    setObj(TransformMoveShapes(obj, [0, 0], [translateX, translateY]));
  });
}
