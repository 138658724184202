import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, InputNumber, notification, Table, Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import Utility from '@src/data/Utility';
import * as workData from '@src/data/WorkData';
import { setCSVPreviewOpen, setCSVData } from '@src/action/propMenu';
import ModalContainer from './components/ModalContainer';
import PointModal from './components/PointModal';
import EditableRowOperation from './components/EditableRowOperation';
import EditableTableContainer from './components/EditableTableContainer';
import CSVPreview from './components/CSVPreview';
import { EditableContext } from './context/EditableContext';
import { message } from '@src/components/Message';

function CSVConfirm({ visibleImportModal, t, handleImportOk, handleImportCancel }) {
  return (
    <Modal
      title={t('property.measurementTab.importPointsWarningTitle')}
      visible={visibleImportModal}
      onOk={handleImportOk}
      onCancel={handleImportCancel}
    >
      <p>{t('property.measurementTab.importPointsWarningHint1')}</p>
      <p>{t('property.measurementTab.importPointsWarningHint2')}</p>
    </Modal>
  );
}

class EditableCell extends React.Component {
  handleDistanceValidator = (rule, val, callback) => {
    if (!val) {
      callback();
    }

    let validateResult = true; // 自定义规则
    let message = '';

    const value = typeof val !== 'number' ? val.replace("'", '').replace('"', '') : val;

    // HACK: what is 1320?
    if (value < -1320) {
      validateResult = false;
      message = this.props.t('property.measurementTab.distanceSmallerErr');
    } else if (value > 1320) {
      validateResult = false;
      message = this.props.t('property.measurementTab.distanceLargerErr');
    }

    if (!validateResult) {
      callback(message);
    }

    callback();
  };

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, onFocus, ...restProps } = this.props;

    // reset the numeric value to be edited.
    // if (editing === true && record) {
    //   record.imperialX = record.x;
    //   record.imperialY = record.y;
    // }

    const focusInput = event => {
      onFocus(event.currentTarget.id);
    };

    // TODO: Translate the message bottom of the modal
    const getInputForm = () => {
      if (dataIndex === 'x' || dataIndex === 'y') {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
                {
                  validator: this.handleDistanceValidator,
                },
              ],
              initialValue: record[dataIndex],
            })(
              <InputNumber
                // formatter={value => Utility.format(value)}
                // parser={value => value.replace('"', '')}
                onFocus={focusInput}
                size="small"
              />
            )}
          </Form.Item>
        );
      } else {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: false,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(<Input onFocus={focusInput} size="small" />)}
          </Form.Item>
        );
      }
    };

    return <td {...restProps}>{editing ? getInputForm() : children}</td>;
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

/**
 * XY-Measurement points table
 */
class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    const { pointList } = this.props;

    // if (pointList && pointList.length > 0) {
    //   pointList.forEach(item => {
    //     item.imperialX = this.getImperial(item.x);
    //     item.imperialY = this.getImperial(item.y);
    //   });
    // }

    this.state = {
      data: pointList,
      editingKey: '',
      inputTitle: '',
    };

    this.columns = [
      {
        title: this.props.t('property.measurementTab.id'),
        dataIndex: 'id',
        width: '6%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.description'),
        dataIndex: 'description',
        width: '28%',
        editable: true,
      },
      {
        title: 'X',
        dataIndex: 'x',
        width: '15%',
        editable: true,
      },
      {
        title: 'Y',
        dataIndex: 'y',
        width: '15%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.operation'),
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <EditableRowOperation
              editable={editable}
              keyValue={record.key}
              editingKey={editingKey}
              save={this.save}
              cancel={this.cancel}
              edit={this.edit}
              handleDelete={this.handleDelete}
              t={this.props.t}
            />
          );
        },
      },
    ];
  }

  /**
   *
   * @param {object} record
   * @param {string} record.key
   * @returns {boolean}
   */
  isEditing = record => record.key === this.state.editingKey;

  getImperial = value => {
    let imperialValue = '';
    let tempVal = 0;

    if (value) {
      tempVal = Number(value);

      if (!isNaN(tempVal)) {
        imperialValue = Utility.format(tempVal);
      }
    } else if (value === 0) {
      imperialValue = '0';
    }

    return imperialValue;
  };

  cancel = key => {
    // const newData = [...this.state.data];
    // const index = newData.findIndex(item => key === item.key);
    // const item = newData[index];

    // if (!isNaN(row.imperialX)) {
    //   let imperialX = this.getImperial(row.imperialX);
    //   item.x = row.imperialX;
    //   item.imperialX = imperialX;
    // }

    // if (!isNaN(row.imperialY)) {
    //   let imperialY = this.getImperial(row.imperialY);
    //   item.y = row.imperialY;
    //   item.imperialY = imperialY;
    // }

    this.setState({ editingKey: '', inputTitle: '' });
  };

  save = (form, key) => {
    const { handleChangePoints } = this.props;
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        // // set imperial value to be display, and numeric value to save
        // if (typeof row.imperialX === 'number') {
        //   let imperialX = this.getImperial(row.imperialX);
        //   item.x = row.imperialX;
        //   item.imperialX = imperialX;
        // }

        // // set imperial value to be display, and numeric value to save
        // if (typeof row.imperialY === 'number') {
        //   let imperialY = this.getImperial(row.imperialY);
        //   item.y = row.imperialY;
        //   item.imperialY = imperialY;
        // }

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }
      // console.log(newData);

      // this.setState({ data: newData, editingKey: '' });
      handleChangePoints(newData);
    });
  };

  edit = key => {
    this.setState({ editingKey: key });
  };

  handleDelete = key => {
    const { handleChangePoints } = this.props;

    const data = [...this.state.data];
    const currentData = data.filter(item => item.key !== key);

    this.setState({ data: currentData });

    handleChangePoints(currentData);
  };

  handleAdd = () => {
    const { data } = this.state;
    const { handleChangePoints } = this.props;

    let maxKey = 0;

    data.map(item => {
      let key = Number(item.key);
      if (key > maxKey) {
        maxKey = key;
      }
    });

    let count = maxKey + 1;

    const newData = {
      key: count,
      id: count,
      description: '',
      x: 0,
      y: 0,
    };

    data.push(newData);

    this.setState({
      data: data,
      editingKey: count,
    });

    // handleChangePoints(data);
  };

  handleFocusInput = columnIndex => {
    let inputTitle = '';

    switch (columnIndex) {
      case 'id':
        inputTitle = this.props.t('property.measurementTab.idInputTitle');
        break;
      case 'description':
        inputTitle = this.props.t('property.measurementTab.descriptionInputTitle');
        break;
      case 'x':
        inputTitle = this.props.t('property.measurementTab.imperialXInputTitle');
        break;
      case 'y':
        inputTitle = this.props.t('property.measurementTab.imperialYInputTitle');
        break;
    }

    this.setState({
      inputTitle: inputTitle,
    });
  };

  render() {
    const { data, inputTitle } = this.state;

    const components = {
      table: EditableTableContainer,
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      const handleOnCell = (record, rowIndex) => {
        return {
          onFocus: this.handleFocusInput,
          record,
          inputType: col.dataIndex === 'x' || col.dataIndex === 'y' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        };
      };

      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: handleOnCell,
      };
    });

    const header = (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <div style={{ marginRight: 10 }}>
          <input
            type="file"
            id="lncd-csv-file"
            name="files"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={this.props.handleFileSelect}
            style={{ display: 'none' }}
          />
          <Button className="lncd-button" size="small" onClick={this.props.handleWillSelectFile}>
            {this.props.t('property.measurementTab.importPointsButton')}
          </Button>
        </div>
        <Button className="lncd-button" size="small" onClick={this.handleAdd}>
          {this.props.t('property.measurementTab.addPointButtonText')}
        </Button>
      </div>
    );

    const footer = () => {
      return inputTitle;
    };

    return (
      <EditableContext.Provider value={this.props.form}>
        {header}
        <Table
          id="lncd-measurement-table"
          components={components}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          footer={footer}
          size="small"
        />
      </EditableContext.Provider>
    );
  }
}

class XYMeasurementModal extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;

    this.state = {
      object: object,
      visibleImportModal: false,
    };
  }

  handleFileSelect = e => {
    let file = document.getElementById('lncd-csv-file').files[0];

    if (file) {
      Papa.parse(file, {
        complete: results => {
          // console.log(results.data);
          // validate
          if (results.data.every(row => row.length < 3)) {
            message.error('The data less than 3 column');
            return;
          } else if (results.data.some(row => row.length > 5)) {
            message.warning('Read only 4 columns of data');
          }
          this.props.setCSVData(results.data);
          this.props.setCSVPreviewOpen(true);
          this.setState({
            visibleImportModal: false,
          });
        },
      });
    }
  };

  handleChangePoints = data => {
    const { object } = this.state;

    const pointList = data.filter(item => item.id && typeof item.x === 'number' && typeof item.y === 'number');

    object.pointList = pointList;

    workData.setObj(object.operateid, object);

    this.setState({ object: object });

    this.props.handle();
  };

  selectImportFile = () => {
    document.getElementById('lncd-csv-file').click();
  };

  handleWillSelectFile = e => {
    const { object } = this.state;

    if (object.pointList && object.pointList.length > 0) {
      this.setState({
        visibleImportModal: true,
      });
    } else {
      this.selectImportFile();
    }
  };

  handleImportOk = e => {
    this.selectImportFile();
    // this.setState({
    //   visibleImportModal: false,
    // });
  };

  handleImportCancel = e => {
    this.setState({
      visibleImportModal: false,
    });
  };

  handleSelectDifferentFile = e => {
    this.selectImportFile();
  };

  render() {
    const { object, visibleImportModal } = this.state;
    const { pointList = [] } = object;

    const EditableFormTable = Form.create({
      // onValuesChange: this.handleValuesChange,
      // mapPropsToFields(props) {
      //   // console.log('mapPropsToFields', props);
      //   return {
      //     id: Form.createFormField({
      //       ...props.id,
      //       value: props.id.value,
      //     }),
      //     description: Form.createFormField({
      //       ...props.description,
      //       value: props.description.value,
      //     }),
      //     x: Form.createFormField({
      //       ...props.x,
      //       value: props.x.value,
      //     }),
      //     y: Form.createFormField({
      //       ...props.y,
      //       value: props.y.value,
      //     }),
      //   };
      // },
    })(EditableTable);

    return (
      <ModalContainer
        object={object}
        handle={this.props.handle}
        renderPointModal={() => {
          return (
            <>
              <PointModal>
                <EditableFormTable
                  pointList={pointList}
                  handleChangePoints={this.handleChangePoints}
                  handleFileSelect={this.handleFileSelect}
                  handleWillSelectFile={this.handleWillSelectFile}
                  t={this.props.t}
                />
              </PointModal>
              {this.props.csvPreview ? (
                <CSVPreview
                  handleChangePoints={this.handleChangePoints}
                  handleSelectDifferentFile={this.handleSelectDifferentFile}
                  t={this.props.t}
                />
              ) : null}
              <CSVConfirm
                visibleImportModal={visibleImportModal}
                t={this.props.t}
                handleImportOk={this.handleImportOk}
                handleImportCancel={this.handleImportCancel}
              />
            </>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  csvPreview: state.propMenu.csvPreview,
});

const mapDispatchToProps = {
  setCSVPreviewOpen,
  setCSVData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(XYMeasurementModal));
