import * as React from 'react';

function SvgIconLane(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .002h24v24H0z" />
        <path stroke="currentColor" strokeWidth={2} d="M5 24V0M19 24V0" />
      </g>
    </svg>
  );
}

export default SvgIconLane;
