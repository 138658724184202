import React from 'react';
import { Tabs, Icon, Input } from 'antd';
import {
  GetAngleArc,
  GetAngleDegree,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  LengthBetweenPoints,
} from '@src/data/CommonFunc';
import { TransformRotateAngle, TransformScaleHeight, TransformScaleWidth } from '@src/actions/TransformHandle';
import { convertToFeet, convertToStandard } from '@src/data/CommonFunction';
import TabItem from '@src/components/PropMenu/TabItem';
import emitter from '@src/data/Event';
import { ROAD_CONSTANT } from '@src/type/event';
import utilitySvg from '@src/data/UtilitySvg';
import { LIMIT_VALUE, FONT_WEIGHT_TYPE, FONT_STYLE_TYPE, DASH_ARRAY_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';

export default class SquareModal extends React.Component {
  // Modal menu and items
  menu = {
    style: ['line', 'width', 'lineColor', 'fillColor'],
    text: ['text', 'fontSize', 'color', 'style'],
    size: ['width', 'rotation'],
  };

  state = {
    object: this.props.object,
    width: this.calculateWidth(this.props.object),
    height: this.calculateHeight(this.props.object),
    // angle: GetAngleDegree(
    //   getAngleBy2Points(
    //     GetCenterInTwoPoints(this.props.object.handlepoint[0], this.props.object.handlepoint[2]),
    //     this.props.object.handlepoint[8]
    //   ),
    //   0
    // ),
    tabKey: 'style',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
      width: this.calculateWidth(this.props.object),
      height: this.calculateHeight(this.props.object),
    });
  }

  calculateWidth(object) {
    let width = convertToFeet(LengthBetweenPoints(object.handlepoint[0], object.handlepoint[1]));
    width = Math.floor(width * 1000) / 1000;
    return width;
  }

  calculateHeight(object) {
    let height = convertToFeet(LengthBetweenPoints(object.handlepoint[0], object.handlepoint[3]));
    height = Math.floor(height * 1000) / 1000;
    return height;
  }

  /**
   *
   * @param {DASH_ARRAY_TYPE} strokePattern
   * @param {*} strokeDash
   */
  handleStrokeDashArray = (strokePattern, strokeDash) => {
    workData.setObjectStyleLine(this.state.object.operateid, strokePattern, strokeDash);
    var obj = this.state.object;
    obj.style.strokepattern = strokePattern;
    obj.style.strokedasharray = utilitySvg.getStrokePattern(obj.style.strokepattern, obj.style.strokewidth);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStrokeWidth = width => {
    var obj = this.state.object;
    obj.style.strokewidth = width;
    // obj.style.strokedasharray = utilitySvg.getStrokePattern(obj.style.strokepattern, obj.style.strokewidth);
    obj.marks.height = width * 2;
    if (obj.arrow.width < obj.style.strokewidth * 2) {
      obj.arrow.width = obj.style.strokewidth * 2;
      obj.arrow.height = obj.arrow.width;
    }

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStroke = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleLineColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.stroke = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };
  handleFill = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleFillColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.fill = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };
  // textHandle = e => {
  //   var obj = this.state.object;
  //   obj.text.text = e.target.value;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textSizeHandle = size => {
  //   var obj = this.state.object;
  //   obj.text.size = size;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textBoldHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.bold == FONT_WEIGHT_TYPE.NORMAL) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.BOLD;
  //   } else if (obj.text.bold == FONT_WEIGHT_TYPE.BOLD) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textItalicHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.italic == FONT_STYLE_TYPE.NORMAL) {
  //     obj.text.italic = FONT_STYLE_TYPE.ITALIC;
  //   } else if (obj.text.italic == FONT_STYLE_TYPE.ITALIC) {
  //     obj.text.italic = FONT_STYLE_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textColorHandle = e => {
  //   var colortype = e.currentTarget.id;
  //   var obj = this.state.object;
  //   obj.text.color = colortype;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  handleWidth = width => {
    // var scale = (width * 12) / this.state.width;
    var scale = width / this.state.width;
    var obj = this.state.object;
    // obj.handlepoint = TransformSquareScaleWidth(obj.handlepoint, scale);
    obj.handlepoint = TransformScaleWidth(obj.handlepoint, scale);
    obj.width = convertToStandard(width);
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj, width: width });
    // this.props.handle();
    this.handleHeight(width);
  };
  handleHeight = height => {
    var scale = height / this.state.height;
    var obj = this.state.object;
    obj.handlepoint = TransformScaleHeight(obj.handlepoint, scale);
    obj.height = convertToStandard(height);
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj, height: height });
    this.props.handle();
  };

  // handleAngle = angleValue => {
  //   const { object, angle } = this.state;
  //   const angleArc = GetAngleArc(angleValue - angle, 5);

  //   object.handlepoint = TransformRotateAngle(object.handlepoint, angleArc);

  //   workData.setObj(object.operateid, object);
  //   if (angleValue < 360) {
  //     this.setState({ object: object, angle: angleValue });
  //   } else {
  //     this.setState({ object: object, angle: 0 });
  //   }
  //   this.props.handle();
  // };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                width={this.state.width}
                handlewidth={this.handleWidth}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
