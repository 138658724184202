import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GetRectHandle, SquareHandlePoint } from '@src/data/AnchorsPoint';
import { getUserUnitByPixel, distance } from '@src/data/CommonFunction';
import Utility from '@src/data/Utility';
import { getHandlePointSize, getDefaultFontSize } from '@src/utils';
import { CANVAS, COLOR_TYPE } from '@src/constant';
import { connect } from 'react-redux';

// HACK: fill, stroke, opacity don't use inline style
function LncdTriangulationShadedLayer({ referencePoints }) {
  const [p2, setP2] = useState([0, 0]);
  const [p3, setP3] = useState([0, 0]);
  const width = CANVAS.CANVASWIDTH;
  const height = CANVAS.CANVASHEIGHT;

  useEffect(() => {
    let canvasXMin = -width;
    let canvasXMax = height;
    let p0 = { x: referencePoints[0][0], y: referencePoints[0][1] };
    let p1 = { x: referencePoints[1][0], y: referencePoints[1][1] };
    let dx = p0.x - p1.x;
    let dy = p0.y - p1.y;
    let k = dy / dx;
    let b = p0.y - k * p0.x;
    setP2([canvasXMin, canvasXMin * k + b]);
    setP3([canvasXMax, canvasXMax * k + b]);
  }, []);

  return (
    <>
      <clipPath id="lncd-triangulation-shaded-layer-clip-path">
        <path
          d={`
            M${p2[0]},${p2[1]}
            L${p3[0]},${p3[1]}
            L${width},${-height}
            L${-width},${-height}
            Z
          `}
        />
      </clipPath>
      <path
        stroke={COLOR_TYPE.GREEN}
        strokeOpacity=".5"
        fill={COLOR_TYPE.GREEN}
        fillOpacity=".15"
        d={`
          M${-width},${-height}
          L${width},${-height}
          L${width},${height}
          L${-width},${height}
          Z
        `}
        pointerEvents="none"
        clipPath="url(#lncd-triangulation-shaded-layer-clip-path)"
      />
    </>
  );
}

class SelectTriangulationNetwork extends React.Component {
  getPath = (x1, y1, x2, y2, index) => {
    let getMarkPathD = (x1, y1, x2, y2) => {
      return (
        'M' +
        x1.toString() +
        ' ' +
        y1.toString() +
        ' ' +
        'L' +
        x2.toString() +
        ' ' +
        y2.toString() +
        ' ' +
        'L' +
        x2.toString() +
        ' ' +
        y2.toString() +
        ' ' +
        'L' +
        x1.toString() +
        ' ' +
        y1.toString() +
        ' ' +
        'Z'
      );
    };
    return <path key={index} d={getMarkPathD(x1, y1, x2, y2)} />;
  };

  getText = (x1, y1, x2, y2, index) => {
    let measurement = distance(x1, y1, x2, y2);
    let feet = Utility.format(Math.floor(measurement / 12));
    let ptMid = [(x1 + x2) / 2, (y1 + y2) / 2];
    return (
      <text key={index} textAnchor="middle" x={ptMid[0]} y={-ptMid[1]}>
        {feet}
      </text>
    );
  };

  render() {
    const { object, pointModal } = this.props;

    let pointNumber = object.referencePoints.length;
    if (pointNumber < 1) {
      return null;
    }

    let r = getHandlePointSize();
    let fontsize = getDefaultFontSize();
    let strokewidth = getUserUnitByPixel();
    // make the click range slightly bigger than visual
    let tolerance = r * 2.5;
    let shapes = [];

    let idx = pointNumber - 1;

    for (let i = 0; i < pointNumber; i++) {
      // determine which is the focused point
      if (distance(...object.clickpoint, ...object.referencePoints[i]) <= tolerance) {
        const id = `TransformReferencePosition-${i}`;
        shapes.push(<SquareHandlePoint key={id} id={id} point={object.referencePoints[i]} />);
        idx = i;
        break;
      }
    }

    let paths = [];
    for (let i = 0; i < pointNumber; i++) {
      if (i == idx) continue;
      paths.push(
        this.getPath(
          object.referencePoints[i][0],
          object.referencePoints[i][1],
          object.referencePoints[idx][0],
          object.referencePoints[idx][1],
          i
        )
      );
    }

    let texts = [];
    for (let i = 0; i < pointNumber; i++) {
      if (i == idx) continue;
      texts.push(
        this.getText(
          object.referencePoints[i][0],
          object.referencePoints[i][1],
          object.referencePoints[idx][0],
          object.referencePoints[idx][1],
          i
        )
      );
    }

    const shapesNumber = shapes.length;

    shapes.push(
      <g stroke={object.style.stroke} key={shapesNumber} strokeDasharray="none">
        <g transform="scale(1,-1)" fontSize={fontsize} strokeWidth={strokewidth}>
          {texts}
        </g>
        {paths}
      </g>
    );
    return (
      <g id="select-TriangulationNetwork">
        {shapes}
        {pointModal && <LncdTriangulationShadedLayer referencePoints={object.referencePoints} />}
      </g>
    );
  }
}

SelectTriangulationNetwork.propTypes = {
  object: PropTypes.shape({
    referencePoints: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
    clickpoint: PropTypes.arrayOf(PropTypes.number).isRequired,
    style: PropTypes.shape({
      stroke: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  // redux
  pointModal: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  pointModal: state.propMenu.pointModal,
});

export default connect(
  mapStateToProps,
  null
)(SelectTriangulationNetwork);
