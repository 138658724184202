import * as React from 'react';

function SvgIconDrawRoad(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M10.227 11.09h10.91V22h-10.91z" />
        <path
          d="M19.691 12.536A1.817 1.817 0 0018.397 12c-.488 0-.948.19-1.293.536l-5.186 5.185s0 .002-.002.002a.58.58 0 00-.118.21l-.648 2.592a.455.455 0 00.552.552l2.593-.648a.575.575 0 00.21-.119l5.187-5.186c.345-.346.536-.805.535-1.294 0-.489-.19-.948-.535-1.294zm-2.913 1.613l1.301 1.301-3.894 3.895-1.302-1.301 3.895-3.895zm-4.562 5.863l.27-1.08.81.81-1.08.27zm6.834-6.833a.944.944 0 010 1.302l-.327.327-1.303-1.302.328-.327a.943.943 0 011.302 0zM15.682 2a.91.91 0 01.887.712l1.64 7.375.022.103c-.544.027-.95.105-1.222.234-.118.056-.29.184-.505.367l-1.551-6.973h-2.908v3.637h-1.818V3.818H7.32L4.088 18.364l5.69-.001c-.187.47-.399 1.076-.634 1.817l-6.19.002a.911.911 0 01-.887-1.107L5.704 2.712A.91.91 0 016.59 2zm-3.637 12.727v.686c-.838.92-1.489 1.641-1.654 1.8-.05.047-.105.117-.164.209v-2.695h1.818z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path fill="currentColor" fillRule="nonzero" d="M10.227 9.273h1.818v3.636h-1.818z" />
      </g>
    </svg>
  );
}

export default SvgIconDrawRoad;
