import { COLOR_TYPE, TEXT_BOX_TYPE, getDashArray } from '@src/constant';
import { getArrowType } from '@src/data/BusinessFun';
import { GetShapeStyle } from '@src/data/CommonProps';
import { fillOtherProps, getTextUnderline, getTextContainerBox } from '@src/utils';
import React from 'react';
import ArrowMarker from '../ArrowMarker';
import LncdText from '../LncdText';

const Callout = ({ object }) => {
  const {
    handlepoint: anchors,
    style: { strokepattern, strokewidth },
    rotateangle,
  } = object;
  // this hard-coded value is to head up the origin box size of the default text 'Enter text here...'
  const { textboxtype, width = 375, height = 67.5 } = object.text;

  // TODO: need better logic
  if (anchors.length < 6) {
    return null;
  }

  const x1 = anchors[0][0];
  const x2 = anchors[1][0];
  const y1 = anchors[0][1];
  const y2 = anchors[1][1];

  let strokeDasharray = getDashArray(strokepattern, strokewidth);

  const getTextBox = () => {
    if (textboxtype === TEXT_BOX_TYPE.BARE) {
      return null;
    }

    const [x, y] = anchors[1];

    let box;
    if (textboxtype === TEXT_BOX_TYPE.BOX) {
      box = getTextContainerBox({
        x,
        y,
        width,
        height,
        rotateAngle: (rotateangle * 180) / Math.PI,
      });
    } else if (textboxtype === TEXT_BOX_TYPE.UNDERLINE) {
      box = getTextUnderline({
        x,
        y,
        width,
        height,
        rotateAngle: (rotateangle * 180) / Math.PI,
      });
    }

    return (
      <g key="text-box-style" fill={COLOR_TYPE.WHITE} {...fillOtherProps(COLOR_TYPE.WHITE)}>
        {box}
      </g>
    );
  };

  return (
    <g id={object.functype} strokeDasharray={strokeDasharray}>
      <ArrowMarker
        id={object.operateid}
        width={object.arrow.width}
        height={object.arrow.height}
        type={object.arrow.type}
        fill={object.style.stroke}
      />
      <line x1={x1} y1={y1} x2={x2} y2={y2} {...GetShapeStyle().LINEWHITESTYLE} />
      <line x1={x1} y1={y1} x2={x2} y2={y2} markerStart={getArrowType(object.operateid, object.arrow.position, 0)} />
      {getTextBox(object)}
      <LncdText rad={object.rotateangle} {...object.text} point={anchors[1]} objectId={object.operateid} />
    </g>
  );
};

export default Callout;
