import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Input, Select, Icon, InputNumber, Divider } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as workData from '@src/data/WorkData';
import { message } from '@src/components/Message';
import { useLeafletMap } from '@src/hooks';
import { setGlobalLoading } from '@src/action/app';
import { getMatrixPos, getMatrixScale } from '@src/action/transform';
import { saveTemplateAsPDF, saveTemplateAsPNG, saveTemplateAsSVG } from '@src/utils';
import { FUNCTION_TYPE } from '@src/constant';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import LncdCheckbox from '../Modal/Element/LncdCheckbox';
import { filterMeasurement } from './PrintDialog';
import './ExportDialog.css';

const DEFAULT_FILENAME = 'download-file';

function ExportDialog(props) {
  const { onCancel, leafletMap, setGlobalLoading } = props;

  const { t } = useTranslation();
  const { getMapHref } = useLeafletMap(leafletMap);

  const [filename, setFilename] = useState(DEFAULT_FILENAME);
  const [exportType, setExportType] = useState('svg');
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(600);
  const [showMeasurement, setShowMeasurement] = useState(false);

  const filenameRef = useRef();

  useEffect(() => {
    // FIXME: check
    if (filenameRef.current) filenameRef.current.focus();
  }, [filenameRef]);

  const handleExportImage = async () => {
    setGlobalLoading(true);
    try {
      const { map } = props;
      // FIXME: determine data to be restored
      const mapData = {
        service: map.service,
        zoom: map.zoom,
        opacity: map.opacity,
        currentLocation: map.currentLocation,
        // selectOptions: map.selectOptions,
        // keyword: map.keyword,
        // mapTabKey: map.mapTabKey,
      };
      const fileName = `${filename}.${exportType}`;
      const href = await getMapHref();
      const position = props.getMatrixPos();
      const image = {
        scale: props.getMatrixScale(),
        x: position[0],
        y: position[1],
      };
      switch (exportType) {
        case 'png':
          saveTemplateAsPNG(fileName, {
            width: width,
            height: height,
            href: href,
            image,
          });
          break;
        case 'svg':
          saveTemplateAsSVG(fileName, {
            map: mapData,
            href: href,
            image,
          });
          break;
        case 'pdf':
          saveTemplateAsPDF(fileName, {
            href: href,
            image,
          });
          break;
        default:
          return message.error(t('menu.top.exportExtensionNotExist'));
      }
      onCancel();
    } catch (error) {
      console.error(error);
    } finally {
      setGlobalLoading(false);
    }
  };

  const onExport = () => {
    if (showMeasurement) {
      handleExportImage();
    } else {
      filterMeasurement(handleExportImage, 0);
    }
  };

  return (
    <Modal title={t('menu.exportModal.title')} centered={true} visible={true} onOk={onExport} onCancel={onCancel}>
      <div className="export-container">
        <div className="item">
          <span style={{ marginBottom: 3, fontWeight: 'bold' }}>{t('menu.exportModal.saveLabel')}</span>
          <span style={{ marginBottom: 3 }}>
            <span style={{ display: 'inline-block', width: '60px' }}>{t('menu.exportModal.nameLabel')}</span>
            <Input
              className="export-input"
              value={filename}
              onChange={e => setFilename(e.target.value)}
              placeholder={DEFAULT_FILENAME}
              ref={filenameRef}
            />
            {/* FIXME: use addon */}
            <span> .{exportType}</span>
          </span>
          <span style={{ marginBottom: 3 }}>
            <span style={{ display: 'inline-block', width: '60px' }}>{t('menu.exportModal.formatLabel')}</span>
            <Select
              id="type"
              defaultValue="png"
              value={exportType}
              onChange={value => setExportType(value)}
              style={{ width: '120px' }}
              className="custom-select-add"
              suffixIcon={<Icon type="down" className="custom-night-mode-text" />}
            >
              <Select.Option value="png">PNG</Select.Option>
              <Select.Option value="svg">SVG</Select.Option>
              <Select.Option value="pdf">PDF</Select.Option>
            </Select>
          </span>
        </div>
        {'png' === exportType && (
          <div className="item">
            <span style={{ fontWeight: 'bold' }}>{t('menu.exportModal.sizeLabel')}:</span>
            <span style={{ marginBottom: 3 }}>
              <span style={{ display: 'inline-block', width: '110px' }}>{t('menu.exportModal.widthLabel')}:</span>
              <InputNumber
                id="width"
                min={1}
                max={9999}
                value={width}
                onChange={value => setWidth(value)}
                onPressEnter={value => setWidth(value)}
              />
            </span>
            <span style={{ marginBottom: 3 }}>
              <span style={{ display: 'inline-block', width: '110px' }}>{t('menu.exportModal.heightLabel')}:</span>
              <InputNumber
                id="height"
                min={1}
                max={9999}
                value={height}
                onChange={value => setHeight(value)}
                onPressEnter={value => setHeight(value)}
              />
            </span>
          </div>
        )}
        {/*{'PDF' === state.exportType.type && (
        <div className="item">
          <span style={{ fontWeight: 'bold' }}>Size:</span>
          <span>The image will be save as 8 * 11 size in one pdf document page.</span>
        </div>
      )}*/}
        <Divider type="vertical" style={{ height: 'auto' }} />
        <div className="item">
          <div style={{ display: 'flex' }}>
            <span>{t('menu.exportModal.includeMeasurementLabel')} </span>
            <LncdCheckbox checked={showMeasurement} onClick={() => setShowMeasurement(!showMeasurement)} size={16} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = state => ({
  map: state.map,
});

const mapDispatchToProps = {
  setGlobalLoading,
  getMatrixPos,
  getMatrixScale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportDialog);
