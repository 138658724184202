import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomArc from '@src/data/GeomArc';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomPathOffset from './GeomPathOffset';

class StreetBreakLine {
  /**
   *
   * @param {number} breakLineType
   * @param {GeomPathOffset | number} streetAxisOffset
   * @param {boolean} posIsVisible
   * @param {string} bfec - operateId
   * @param {GeomPoint} bfed - street border point
   * @param {number} bfee
   * @param {GeomPoint} bfef - GeomPolyArc.getPointClosest
   * @param {string} bff0 - streetStripe.keyStripe
   * @param {string} bff1 - streetBorder.stripeKey
   */
  constructor(breakLineType, streetAxisOffset, posIsVisible, bfec, bfed, bfee, bfef, bff0, bff1) {
    this.breakLineType = breakLineType;
    this.streetAxisOffset = streetAxisOffset;
    this.posIsVisible = posIsVisible;
    if (bff0 < bff1) {
      this.breakLine = new GeomLine(bfed, bfef);
      this.curbReturnKeyBorder0 = bfec;
      this.curbReturnKeyBorder1 = bfee;
    } else {
      this.breakLine = new GeomLine(bfef, bfed);
      this.curbReturnKeyBorder1 = bfec;
      this.curbReturnKeyBorder0 = bfee;
    }
  }

  cloneLine() {
    return this.breakLine.clone();
  }
}

export default StreetBreakLine;
