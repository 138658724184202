import React from 'react';
import { Layout, ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { transferOperation } from '@src/action/canvas';
import { setOpacity } from '@src/action/map';
import { getMatrixPos } from '@src/action/transform';
import { initialSnapshots, takeSnapshot } from '@src/action/snapshots';
import { setCurrentState } from '@src/action/drawingStatus';
import SVGRoot from '../Content/SVGRoot/SVGRoot';
import MapContainer from '@src/components/Map/Container/MapContainer';
import Header from '../Header/Header';
import { OPERATIONS, FUNCTION_TYPE, APP_ROOT_EL_ID, MANAGE_FUNC_TYPE } from '@src/constant';
import { onWindowResize, setCollapsed, setAppDevice } from '@src/action/app';
import * as workData from '@src/data/WorkData';
import * as transformHandle from '@src/actions/TransformHandle';
import { i18n } from '@src/components/DataProvider/DataProvider';
import { message } from '@src/components/Message';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { resetCanvas, toggleGrid, resetAroundCenterOffset } from '@src/action/canvas';
import classNames from 'classnames';
import { isSafari, isTouchDevice } from '@src/utils';
import LncdSider from '@src/components/LncdSider/LncdSider';
import { SymbolContext } from '@src/contexts';
import { DraggableModalProvider } from 'ant-design-draggable-modal';
import LncdSpin from '@src/components/LncdSpin';
import 'ant-design-draggable-modal/dist/index.css';

const { Content } = Layout;

const {
  SELECT_ALL,
  DELETE,
  MOVE_LEFT,
  MOVE_RIGHT,
  MOVE_UP,
  MOVE_DOWN,
  GROUP,
  UNDO,
  REDO,
  FLIP_HORIZONTAL,
  FLIP_VERTICAL,
  CUT,
} = OPERATIONS;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      slideMenuId: 'base-layer',

      drawerShow: false,
      svgVisible: false,
      // FIXME: is this still in use?
      objList: [],
      isSafari: isSafari(),
    };
    this.canvas = React.createRef();
  }

  componentDidMount() {
    // 禁用浏览器动作（disable browser actions）
    document.body.addEventListener(
      'touchmove',
      e => {
        e.preventDefault();
        document.body.scrollTop = 0;
      },
      { passive: false }
    );

    // FIXME: add resize listerner to App root with size-me
    window.addEventListener('resize', this.setAppSize);
    this.setAppSize();

    emitter.addListener(EVENT_EMIT_TYPE.MANAGEFUNC, this.handleManageFunc);

    document.getElementById(APP_ROOT_EL_ID).addEventListener('contextmenu', event => {
      event.preventDefault();
    });

    this.props.initialSnapshots();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setAppSize);
    emitter.removeAllListeners(EVENT_EMIT_TYPE.MANAGEFUNC);
  }

  handleManageFunc = type => {
    const { isShowGrid, initTransform, clearMap } = this.props;
    const { resetCanvas, toggleGrid, resetAroundCenterOffset } = this.props; // redux
    switch (type) {
      case MANAGE_FUNC_TYPE.START_OVER: {
        clearMap();
        isShowGrid && toggleGrid();
        initTransform();
        resetCanvas();
        resetAroundCenterOffset();
        break;
      }
      default:
        break;
    }
  };

  setAppSize = () => {
    const { innerWidth, innerHeight } = window;
    // debugger;
    this.props.onWindowResize({
      width: innerWidth,
      height: innerHeight,
    });
    if (isTouchDevice()) {
      this.props.setAppDevice('touch');
    } else {
      this.props.setAppDevice('untouch');
    }
  };

  toggleCollapsed = () => {
    const { svgVisible } = this.state;
    this.setState({ svgVisible: !svgVisible });
    this.props.setCollapsed(!this.props.collapsed);
  };

  // Drawer
  onDrawerToggle = () => {
    const { drawerShow } = this.state;
    this.setState({ drawerShow: !drawerShow });
    this.props.setCollapsed(true);
  };

  handleDrawerToggle = () => {
    this.setState(state => ({
      mobileOpen: !state.mobileOpen,
    }));
  };

  handleSelectSlideMenu = id => {
    this.setState({
      slideMenuId: id,
    });
  };

  /**
   *
   * @param {string} operation - hotkey
   */
  handleCanvasOperation = operation => {
    const { transferOperation, takeSnapshot, onChange, resumeMap, getMatrixPos } = this.props;
    // console.log(`request canvas operation: ${operation}`);
    // TODO: don't know why calling this.
    onChange(true);
    let translateX = 0;
    let translateY = 0;
    let shouldTakeSnapshot = false;
    let selectedUseData;
    switch (operation) {
      case SELECT_ALL: {
        workData.setAllObjectFlag(true);
        this.props.setCurrentState(2);
        break;
      }
      case DELETE: {
        this.canvas.current && this.canvas.current.removeWorkData();
        shouldTakeSnapshot = true;
        // FIXME: bound work data length directly to collapse component
        // if (workData.getDataCount() === 0) {
        //   this.props.setCollapsed(true);
        // }
        break;
      }
      case MOVE_UP: {
        translateY = 1;
        break;
      }
      case MOVE_DOWN: {
        translateY = -1;
        break;
      }
      case MOVE_LEFT: {
        translateX = -1;
        break;
      }
      case MOVE_RIGHT: {
        translateX = 1;
        break;
      }
      case GROUP: {
        selectedUseData = workData.getSelectObjects();
        // TODO: Translate
        if (0 === selectedUseData.length) {
          return message.error('Please select shapes to group!');
        }
        if (selectedUseData.some(data => FUNCTION_TYPE.GROUP === data.functype)) {
          return message.error('There is at least one group in your selected items, please ungroup them first!');
        }
        if (1 === selectedUseData.length && FUNCTION_TYPE.GROUP === selectedUseData[0].functype) {
          return message.error('The selected shape is already in a group!');
        }
        transferOperation(operation);
        shouldTakeSnapshot = true;
        break;
      }
      case UNDO:
      case REDO: {
        transferOperation(operation);
        resumeMap();
        break;
      }
      case FLIP_HORIZONTAL: {
        selectedUseData = workData.getUseData().filter(data => data.selectflag)[0];
        selectedUseData.horizontal = -selectedUseData.horizontal;
        break;
      }
      case FLIP_VERTICAL: {
        selectedUseData = workData.getUseData().filter(data => data.selectflag)[0];
        selectedUseData.vertical = -selectedUseData.vertical;
        break;
      }
      // FIXME: set shouldTakeSnapshot?
      case CUT: {
        selectedUseData = workData.getSelectObjects();
        if (selectedUseData.length > 0) {
          transferOperation(operation);
        }
        break;
      }
      default: {
        transferOperation(operation);
        break;
      }
    }
    if (translateX || translateY) {
      // HACK: a hack number
      const distance = 50;
      const selectUserData = workData.getSelectObjects();
      if (selectUserData.length > 0) {
        const pos = getMatrixPos();
        selectUserData.forEach(obj => {
          workData.setObj(
            transformHandle.TransformMoveShapes(
              obj,
              [0, 0],
              [translateX * distance + pos[0], -translateY * distance + pos[1]]
            )
          );
        });
        shouldTakeSnapshot = true;
      } else {
        this.props.pan(distance * translateX, distance * -translateY);
      }
    }
    if (shouldTakeSnapshot) {
      takeSnapshot();
    }
    this.canvas.current && this.canvas.current.setAncyObjList();
  };

  render() {
    // console.log('render app');
    const { onChange, onSvgImage, imageData, existingImage, onExit, isNightMode, logger } = this.props;
    // reducer
    const { collapsed, nightMode, siderWidth, canvasHeight, isGlobalLoading } = this.props;
    // withTransformManager
    const { pan, zoom } = this.props;
    // withMap
    const {
      setMapLatLng,
      clearMap,
      handleZoom,
      leafletMap,
      resumeMap,
      moveMap,
      generateSketch,
      cancelGenerateSketch,
    } = this.props;

    return (
      <ConfigProvider
        getPopupContainer={() => document.getElementById(APP_ROOT_EL_ID)}
        locale={i18n.antdLanguage['en-US']}
      >
        <DraggableModalProvider>
          <div
            className={`normal`}
            id={APP_ROOT_EL_ID}
            data-theme={nightMode ? 'night-mode' : 'day-mode'}
            style={{ height: '100%', display: 'flex' }}
          >
            <Layout>
              {/* TODO: remove map related props if not used */}
              <Header
                onSvgImage={onSvgImage}
                imageData={imageData}
                existingImage={existingImage}
                onChange={onChange}
                onExit={onExit}
                isNightMode={isNightMode}
                handleCanvasOperation={this.handleCanvasOperation}
                resumeMap={resumeMap}
                clearMap={clearMap}
                handleZoom={handleZoom}
                leafletMap={leafletMap}
              />
              <Layout>
                {/* TODO: use Drawer component with mask or even manage collapse actions by SymbolCategoryMenu itself */}
                <LncdSider toggleCollapsed={this.toggleCollapsed} />
                <Content>
                  <SymbolContext.Consumer>
                    {({ symbols }) => (
                      <SVGRoot
                        clearMap={clearMap}
                        leafletMap={leafletMap}
                        logger={logger}
                        svgVisible={this.state.svgVisible}
                        onChange={onChange}
                        handleZoom={handleZoom}
                        moveMap={moveMap}
                        pan={pan}
                        handleCanvasOperation={this.handleCanvasOperation}
                        ref={this.canvas}
                        symbols={symbols}
                      />
                    )}
                  </SymbolContext.Consumer>
                </Content>
              </Layout>
            </Layout>
            <MapContainer
              resumeMap={resumeMap}
              clearMap={clearMap}
              setMapLatLng={setMapLatLng}
              handleZoom={handleZoom}
              generateSketch={generateSketch}
            />
            {isGlobalLoading && <LncdSpin global center size={72} onCancel={cancelGenerateSketch} />}
          </div>
        </DraggableModalProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = state => ({
  nightMode: state.app.nightMode,
  collapsed: state.app.collapsed,
  currentLocation: state.map.currentLocation,
  isShowGrid: state.canvas.isShowGrid,
  isGlobalLoading: state.app.loading,
});

const mapDispatchToProps = {
  onWindowResize,
  transferOperation,
  takeSnapshot,
  setCollapsed,
  setOpacity,
  toggleGrid,
  resetCanvas,
  getMatrixPos,
  setAppDevice,
  initialSnapshots,
  setCurrentState,
  resetAroundCenterOffset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
