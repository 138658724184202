import { ANCHORS_TYPE } from '@src/constant';
import { getCircleHandlePoint, SquareRectHandlePoint } from '@src/data/AnchorsPoint';
import { ByTime, FindCircle, GetCenterInTwoPoints, LengthBetweenPoints } from '@src/data/CommonFunc';
import React from 'react';

class SelectArc extends React.Component {
  render() {
    const anchors = this.props.anchors;

    if (!anchors || anchors.length === 0) {
      return null;
    }

    const [x1, y1] = anchors[0];
    const [x2, y2] = anchors[1];
    const cirlcle = FindCircle(anchors[0], anchors[1], anchors[2]);
    const r = cirlcle[2];

    const getIsUnder = () => {
      if (ByTime(anchors[0], anchors[2], anchors[1]) == 1) {
        return '1';
      }
      return '0';
    };

    const getIsBigArc = () => {
      const center = GetCenterInTwoPoints(anchors[0], anchors[1]);
      const length = LengthBetweenPoints(center, anchors[2]);

      if (length > r) {
        return '1';
      }
      return '0';
    };

    const getDPath = () => {
      const D = [];
      D.push(`M${x1.toString()},${y1.toString()}`);
      D.push(`A${r.toString()},${r.toString()}`);
      D.push('0');
      D.push(`${getIsBigArc()},${getIsUnder()}`);
      D.push(`${x2.toString()},${y2.toString()}`);

      return D;
    };
    const pathshape = (
      <g>
        <path d={getDPath().join(' ')} />
        <SquareRectHandlePoint id={ANCHORS_TYPE.ARCBEGIN} point={anchors[0]} />
        <SquareRectHandlePoint id={ANCHORS_TYPE.ARCEND} point={anchors[1]} />
        {getCircleHandlePoint(ANCHORS_TYPE.ARCANGLE, anchors[2])}
      </g>
    );
    return <g>{pathshape}</g>;
  }
}

export default SelectArc;
