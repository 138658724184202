import { COLOR_TYPE, PARK_STALL_PATTERN_TYPE } from '@src/constant';
import {
  FindCircle,
  getAngleBy2Points,
  GetArcAngle,
  GetCenter,
  IsArcDirection,
  IsBigArc,
  IsByTime,
  IsZero,
  LengthArc,
  pointTransform,
  RotatePoint,
} from '@src/data/CommonFunc';
import { GetShapeStyle } from '@src/data/CommonProps';
import GeomArc from '@src/data/GeomArc';
import GeomLine from '@src/data/GeomLine';
import GeomPoint from '@src/data/GeomPoint';
import { strokeOtherProps } from '@src/utils';
import React from 'react';
import Stall from './Stall';

// FIXME: rename as ParkingStall
class ParkStall extends React.Component {
  getRect = (point1, point2, point3, point4, topR, r, isBigArc, isDirection) => {
    var D = [];
    D.push('M' + point1[0].toString() + ',' + point1[1].toString());
    D.push('L' + point2[0].toString() + ',' + point2[1].toString());
    D.push('A' + r.toString() + ',' + r.toString());
    D.push('0');
    D.push(isBigArc + ',' + isDirection);
    D.push(point3[0].toString() + ',' + point4[1].toString());
    D.push('L' + point4[0].toString() + ',' + point4[1].toString());
    let rebackDirection = isDirection == '0' ? '1' : '0';
    D.push('A' + topR.toString() + ',' + topR.toString());
    D.push('0');
    D.push(isBigArc + ',' + rebackDirection);
    D.push(point1[0].toString() + ',' + point1[1].toString());
    return <path d={D.join(' ')} {...GetShapeStyle().LINEWHITESTYLE} fill={COLOR_TYPE.WHITE} />;
  };

  render() {
    const { props } = this;
    const { object } = props;

    if (object.handlepoint.length < 3) {
      return null;
    }

    const { height, length: width } = object.marks;
    const [ptBegin, ptEnd, ptCenter] = object.handlepoint.slice(0, 3);
    const [x1, y1] = ptBegin;
    // This is the handle point, may not be the end point of the actual parking stalls
    const [x2, y2] = ptEnd;

    let circle = FindCircle(ptBegin, ptEnd, ptCenter);
    let totalWidth = LengthArc(ptBegin, ptEnd, ptCenter);
    let stallCount = Math.floor(totalWidth / width);
    const [cx, cy, r] = circle;
    let isBigArc = IsBigArc(ptBegin, ptEnd, ptCenter, r);
    let sweepFlag = IsArcDirection(ptBegin, ptCenter, ptEnd);
    const ga = new GeomArc(new GeomPoint(...ptBegin), new GeomPoint(...ptEnd), r, +isBigArc, +sweepFlag);
    var D = [];

    var gp = new GeomPoint(x1 + height, y1);
    gp.rotateRad(Math.PI / 2, new GeomPoint(x1, y1));
    const [tx, ty] = [gp.x - x1, gp.y - y1];
    /*let endPoint = TowPointVerticalLengthByStatus(
      begin,
      end,
      begin,
      SPACE,
      false
    );*/
    let moveMatrix = [1, 0, 0, 1, tx, ty];
    let point0 = pointTransform(moveMatrix, ptBegin);
    // The other side point
    let point1 = pointTransform(moveMatrix, ptEnd);
    let ra = getAngleBy2Points(point0, point1);

    let stalls = []; //车场容器
    if (IsZero(r)) {
      // line
      const gl = new GeomLine(new GeomPoint(ptBegin), new GeomPoint(ptEnd));
      const stallTotalWidth = width * stallCount;
      const gpStallEnd = gl.getPointAtLength(stallTotalWidth);
      D.push(gl.getPartialSvgPathData(0, stallTotalWidth, true));
      const ptStallEnd = [gpStallEnd.x, gpStallEnd.y];
      point1 = pointTransform(moveMatrix, ptStallEnd);
      for (let i = 0; i <= stallCount; i++) {
        let pointTop = GetCenter(point0, point1, i / stallCount);
        let pointBottom = GetCenter(ptBegin, ptStallEnd, i / stallCount);
        pointTop = RotatePoint(pointTop, ra + object.rotateangle, pointBottom);
        if (
          object.style.pattern === PARK_STALL_PATTERN_TYPE.STANDARD ||
          object.style.pattern === PARK_STALL_PATTERN_TYPE.PARALLELONE
        ) {
          D.push('M' + String(pointTop[0]) + ',' + String(pointTop[1]));
          D.push('L' + String(pointBottom[0]) + ',' + String(pointBottom[1]));
        } else if (object.style.pattern === PARK_STALL_PATTERN_TYPE.PARALLELTWO) {
          let stallAngle = getAngleBy2Points(pointBottom, pointTop);
          stalls.push(<Stall key={i} x={pointTop[0]} y={pointTop[1]} angle={stallAngle} />);
        }
      }
    } else {
      // arc
      const stallRad = Math.asin(width / 2.0 / r) * 2;
      // const gaStallBottom = ga.clone();
      // gaStallBottom.trim(stallTotalWidth);
      // const ptStallEnd = [gaStallBottom.ptStop.x, gaStallBottom.ptStop.y];
      // radius of the top/outter circle
      const rTop = sweepFlag == '0' ? r + height : r - height;
      let clockwise = IsByTime(ptBegin, ptCenter, ptEnd);
      // the rad of the whole arc
      const totalRad = GetArcAngle(circle, ptBegin, ptEnd, clockwise);
      const stallCount = Math.floor(totalRad / stallRad);
      const stallTotalWidth = stallRad * stallCount * r;
      D.push(ga.getPartialSvgPathData(0, stallTotalWidth, true));

      let radStartLine = getAngleBy2Points(circle, ptBegin);
      for (let i = 0; i <= stallCount; i++) {
        let rad = 0;
        if (clockwise) {
          rad = radStartLine + i * stallRad;
        } else {
          rad = radStartLine - i * stallRad;
        }
        let topx = cx + rTop * Math.cos(rad);
        let topy = cy + rTop * Math.sin(rad);
        let bottomx = cx + r * Math.cos(rad);
        let bottomy = cy + r * Math.sin(rad);
        let pointTop = RotatePoint([topx, topy], object.rotateangle, [bottomx, bottomy]);
        if (
          object.style.pattern === PARK_STALL_PATTERN_TYPE.STANDARD ||
          object.style.pattern === PARK_STALL_PATTERN_TYPE.PARALLELONE
        ) {
          D.push('M' + String(pointTop[0]) + ',' + String(pointTop[1]));
          D.push('L' + String(bottomx) + ',' + String(bottomy));
        } else if (object.style.pattern === PARK_STALL_PATTERN_TYPE.PARALLELTWO) {
          let stallAngle = getAngleBy2Points([bottomx, bottomy], pointTop);
          stalls.push(<Stall key={i} x={pointTop[0]} y={pointTop[1]} angle={stallAngle} />);
        }
      }
    }

    return (
      <g id={object.functype}>
        <path d={D.join(' ')} style={GetShapeStyle().LINEWHITESTYLE} />
        <path d={D.join(' ')} fill="none" stroke={object.style.stroke} {...strokeOtherProps(object.style.stroke)} />
        {stalls}
      </g>
    );
  }
}

export default ParkStall;
