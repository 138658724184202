import * as React from 'react';

function SvgIconBuilding(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.001.001h23.04v23.041H.001z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M10 7.999H3c-.552 0-1 .449-1 1v12a1 1 0 001 1h7a1 1 0 001-1v-12a1 1 0 00-1-1zm-1 12H8v-2H5v2H4v-4h2v-2H4v-1h3v-2H4v-1h5v10zM21 1.999h-7c-.552 0-1 .449-1 1v18a1 1 0 001 1h7a1 1 0 001-1v-18a1 1 0 00-1-1zm-1 18h-1v-2h-3v2h-1v-7h2v-2h-2v-1h3v-2h-3v-1h4v-2h-4v-1h5v16z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconBuilding;
