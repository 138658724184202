import produce from 'immer';
import { UPDATE_AXIS_RAD } from '@src/type/street';
import { SET_MEASUREMENT_POINTS_OPEN, SET_CSV_PREVIEW_OPEN, SET_CSV_DATA } from '@src/type/propMenu';

/**
 * the radian limit to consider the street as being horizontal
 */
const HORIZONTAL_TOLERANCE = Math.PI / 10;

const initialState = {
  /**
   * is the selected stree considered horizontal
   */
  streetHorizontal: true,
  pointModal: false,
  csvPreview: false,
  csvData: [],
};

const propMenuReducer = produce((draft, action) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case UPDATE_AXIS_RAD: {
      const { rad } = action;
      // the degree is less than 18 or greater than 162
      draft.streetHorizontal = rad < HORIZONTAL_TOLERANCE || rad > Math.PI - HORIZONTAL_TOLERANCE;
      break;
    }
    case SET_MEASUREMENT_POINTS_OPEN: {
      draft.pointModal = action.value;
      break;
    }
    case SET_CSV_PREVIEW_OPEN: {
      draft.csvPreview = action.value;
      break;
    }
    case SET_CSV_DATA: {
      draft.csvData = action.data;
      break;
    }
    default:
      break;
  }
});

export default propMenuReducer;
