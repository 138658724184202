import React from 'react';
import LncdText from '../LncdText';
import { GetCenterInTwoPoints } from '@src/data/CommonFunc';

export default class Mark extends React.Component {
  render() {
    const { object } = this.props;
    return (
      <LncdText
        {...object.text}
        rad={object.rotateangle}
        objectId={object.operateid}
        point={object.handlepoint[0]}
        textAnchor="left"
      />
    );
  }
}
