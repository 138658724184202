import axios from 'axios';
import symbolData from './symbol/symbol';

export const getSymbolCategoryOrderMap = () => {
  const orderMap = new Map();
  symbolData.categories.forEach(c => {
    c.subCategories.forEach(sc => {
      const key = `${c.key}/${sc.key}`;
      if (!orderMap.get(key)) orderMap.set(key, orderMap.size);
    });
  });
  return orderMap;
};

export const getSymbolData = async () => {
  const symbols = [];
  const loadIconPromises = [];
  symbolData.categories.forEach(category => {
    category.subCategories.forEach(subCategory => {
      subCategory.symbols.forEach(symbol => {
        symbols.push({
          ...symbol,
          categoryKey: category.key,
          categoryName: category.name,
          subCategoryKey: subCategory.key,
          subCategoryIcon: subCategory.icon,
          subCategoryName: subCategory.name,
          functype: subCategory.functype || '',
        });
        const path = require(`../../../assets/symbols/${symbol.key}.svg`);
        const promise = axios.get(path);
        loadIconPromises.push(promise);
      });
    });
  });
  const responses = await Promise.all(loadIconPromises);
  responses.forEach((response, index) => {
    if (response && response.status === 200) {
      symbols[index].image = response.data;
    }
  });
  return symbols;
};

// some templates were temporarily removed from the application
const templateFiles = [
  '3-lane-2-way',
  'short-on-ramp',
  'short-off-ramp',
  'extended-on-ramp',
  'extended-off-ramp',
  'straight-off-ramp',
  'y',
  '4-lane-y',
  't-intersection',
  '4-way',
  '5-way',
  '5-lane-4-way',
  'turn-bay-1',
  'turn-bay-2',
  '4-way-offset-1',
  '4-way-offset-2',
  '4-way-with-turn-lanes',
  'roundabout',
  'divided-with-crossover',
  // '30-30',
  // '30-40',
  '30-50',
  // '14-50',
  // '2-rooms',
  // '3-rooms',
  // '4-rooms',
  '36-60-1',
  // '36-60-2',
];

export const getTemplateData = async () => {
  const templates = [];
  const loadSrcPromises = [];
  templateFiles.forEach(file => {
    const { type, name, key, useData } = require(`./template/${file}/data.json`);
    templates.push({
      type,
      name,
      key,
      useData: JSON.stringify(useData),
      contentType: 'lncdSvg',
      source: 'default',
    });
    const path = require(`./template/${file}/source.svg`);
    const promise = axios.get(path);
    loadSrcPromises.push(promise);
  });
  const responses = await Promise.all(loadSrcPromises);
  responses.forEach((response, index) => {
    if (response && response.status === 200) {
      templates[index].src = response.data;
    }
  });
  return templates;
};

const languageCodes = ['en-US', 'zh-CN'];

export const getTranslationData = () => {
  return languageCodes.map(lang => require(`./language/${lang}.json`));
};
