import * as React from 'react';

function SvgIconText(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19 2H5a1 1 0 00-1 1v3h2V4h5v16H8v2h8v-2h-3V4h5v2h2V3a1 1 0 00-1-1z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconText;
