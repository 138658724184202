let locationApiKey;

export const setLocationApiKey = key => {
  locationApiKey = key;
};

export const getLocationQueryUrl = query =>
  `https://dev.virtualearth.net/REST/v1/Locations?q=${encodeURIComponent(query)}&maxResults=10&key=${locationApiKey}`;

/**
 * 获取地图的 URL
 * @param {'Location' | 'ESRI' | 'Google'} schema
 * @param {object} [config]
 * @param {string} [config.url]
 * @param {string} [config.type]
 * @param {string} [config.key]
 * @returns {string}
 */
export const getMapUrl = (schema, config) => {
  let url = '';
  // FIXME: create schema enum
  switch (schema) {
    // FIXME: bing latlng? create dedicated method for this
    case 'LocationByLatLng': {
      url = `https://dev.virtualearth.net/REST/v1/LocationRecog/${config.point}?key=${config.key}`;
      break;
    }
    case 'ESRI': {
      url = `https://services.arcgisonline.com/ArcGIS/rest/services/${config.type}/MapServer/tile/{z}/{y}/{x}`;
      break;
    }
    case 'Google': {
      url = `https://{s}.google.com/vt/lyrs=${config.type}&x={x}&y={y}&z={z}`;
      break;
    }
    default:
      break;
  }
  return url;
};

export const getDefaultMapType = (mapConfigs, provider, nightMode, defaultType) => {
  const service = mapConfigs.find(n => n.provider === provider);
  defaultType = defaultType || service.types[0].value;
  if (!nightMode) {
    return defaultType;
  } else {
    if (service.provider === 'Bing' && service.types.find(n => n.value === 'CanvasDark')) {
      return 'CanvasDark';
    } else {
      return defaultType;
    }
  }
};

export const isNightMapType = (provider, type) => {
  return provider === 'Bing' && type === 'CanvasDark';
};
