export const INCHES_PER_FOOT = 12;
export const POINTS_PER_FOOT = 72;
export const METER_PER_FOOT = 0.3048;

export const convertPointToInch = point => (point / POINTS_PER_FOOT) * INCHES_PER_FOOT;
export const convertToFeet = (v) => v / INCHES_PER_FOOT;
export const convertStandardToMeter = (v) => convertToFeet(v) * METER_PER_FOOT;
export const convertToStandard = (v) => v * INCHES_PER_FOOT;

export function round(n, p) {
  var v = Math.pow(10, p);
  return Math.round(n * v) / v;
}

export function roundStandardUnit(value) {
  var v = value < 1 ? 1 : 0;
  return round(value, v);
}

/**
 * 标准图形单位的像素值
 * @returns {number} scale
 */
export function getPixelByUserUnit() {
  const m = document.getElementById('viewport').getCTM();
  return m.a;
}

// TODO: we have stored the matrix to redux, so basiclly we're able to remove these utilities and get values from there, same as getPixelByUserUnit
/**
 * 像素的标准图形单位值
 * @returns {number} 1 / scale
 */
export function getUserUnitByPixel() {
  return 1 / getPixelByUserUnit();
}

/**
 * 设备点到标准图形单位的换算，svg 的左上角为原点 (0,0)
 * @param {number} x
 * @param {number} y
 * @returns {[number, number]}
 */
export function devicePtToUserPt(x, y) {
  const svg = document.getElementById('svg');
  let p = svg.createSVGPoint();
  p.x = x;
  p.y = y;
  const viewport = document.getElementById('viewport');
  const m = viewport.getCTM();
  p = p.matrixTransform(m.inverse());
  return [p.x, p.y];
}

// FIXME: replace devicePtToUserPt
// fixed version of devicePtToUserPt which correctly map to user point from mouse position
/**
 *
 * @param {number} clientX
 * @param {number} clientY
 * @returns {[number, number]}
 */
export function devicePtToUserPt2(clientX, clientY) {
  var svg = document.getElementById('svg');
  var p = svg.createSVGPoint();
  p.x = clientX;
  p.y = clientY;
  var viewport = document.getElementById('viewport');
  var m = viewport.getScreenCTM();
  p = p.matrixTransform(m.inverse());
  return [p.x, p.y];
}

/**
 * 标准图形单位到设备点的换算，即svg的左上角为0,0
 * @param {number} x
 * @param {number} y
 * @return {number[]}
 */
export function userPtToDevicePt(x, y) {
  var svg = document.getElementById('svg');
  // console.log(svg);
  var p = svg.createSVGPoint();
  p.x = x;
  p.y = y;
  var viewport = document.getElementById('viewport');
  var m = viewport.getCTM();
  p = p.matrixTransform(m);
  return [p.x, p.y];
}

/**
 * 屏幕点到设备点的换算，即svg的左上角为canvas的l,t
 * @param {number} x
 * @param {number} y
 * @returns {number[]}
 */
export function clientPtToDevicePt(x, y) {
  var svg = document.getElementById('svg');
  var r = svg.parentNode.getBoundingClientRect();
  return [x - r.left, y - r.top];
}

/**
 * @description 屏幕点到标准图形单位点的换算，即svg的左上角为canvas的l,t
 * 例如将鼠标位置转换SVG上的位置
 * @param {number} x
 * @param {number} y
 * @returns {number[]}
 */
export function clientPtToUserPt(x, y) {
  var p = clientPtToDevicePt(x, y);
  return devicePtToUserPt(p[0], p[1]);
}

/**
 * @description 标准图形单位到屏幕点的换算，即svg的左上角为canvas的l,t
 * @param {number} x
 * @param {number} y
 * @returns {[number, number]}
 */
export function userPtToClientPt(x, y) {
  var p = userPtToDevicePt(x, y);
  var svg = document.getElementById('svg');
  var r = svg.parentNode.getBoundingClientRect();
  return [r.left + p[0], r.top + p[1]];
}

/**
 * calculate the shortest distance between two points
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {number}
 */
export function distance(x1, y1, x2, y2) {
  var tX = x1 - x2;
  var tY = y1 - y2;
  return Math.sqrt(tX * tX + tY * tY);
}

export function offset(x, y, cx, cy) {
  x += cx;
  y += cy;
  return [x, y];
}

/**
 * offsetToward
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} distance
 * @returns {[number, number]}
 */
export function offsetToward(x1, y1, x2, y2, distance) {
  if (y1 == y2) {
    if (x1 < x2) {
      x1 += distance;
    } else {
      x1 -= distance;
    }
  } else {
    if (x1 == x2) {
      if (y1 < y2) {
        y1 += distance;
      } else {
        y1 -= distance;
      }
    } else {
      var v = [x2 - x1, y2 - y1];
      var magnitude = Math.sqrt(v[0] * v[0] + v[1] * v[1]);
      var scale = distance / magnitude;
      v = offset(v[0] * scale, v[1] * scale);
      x1 = v[0];
      y1 = v[1];
    }
  }
  return [x1, y1];
}

export function isEqual(x1, y1, x2, y2) {
  return x1 == x2 && y1 == y2;
}

export function radianBetweenTwoPoints(cx, cy, x1, y1, x2, y2) {
  var v1 = [x1 - cx, y1 - cy];
  var v2 = [x2 - cx, y2 - cy];
  return Math.acos(v1.angleCos(v2));
}

export function normalize(v) {
  var vx = 0,
    vy = 0;
  var magnitude = Math.sqrt(v[0] * v[0] + v[1] * v[1]);
  if (magnitude != 0) {
    vx /= magnitude;
    vy /= magnitude;
  }
}

export function angleCos(v1, v2) {
  normalize(v1);
  normalize(v2);
  var dot = v1[0] * v2[0] + v1[1] * v2[1];
  if (dot > 1) {
    dot = 1;
  } else {
    if (dot < -1) {
      dot = -1;
    }
  }
  return dot;
}

export function AngleToHorizontal(x1, y1, x2, y2) {
  var angle = 0;
  if (isEqual(x1, y1, x2, y2)) {
    return 0;
  }
  if (y1 == y2) {
    if (x2 > x1) {
      angle = 0;
    } else {
      angle = Math.PI;
    }
  } else {
    var a = Math.abs(x2 - x1) + Math.abs(y2 - y1);
    var pt = offset(x1, y1, a, 0);
    angle = Math.abs(radianBetweenTwoPoints(x1, y1, x2, y2, pt[0], pt[1]));
    if (y2 > y1) {
      angle = Math.PI * 2 - angle;
    }
  }
  return angle;
}

export function AngleFromHorizontal(x1, y1, x2, y2) {
  var angle = AngleToHorizontal(x1, y1, x2, y2);
  if (angle == 0) {
    return 0;
  }
  return Math.PI * 2 - AngleToHorizontal(x1, y1, x2, y2);
}

//叉积
export function multiply(p0, p1, p2) {
  if (!p0 || !p1 || !p2) return 0;
  return (p1.x - p0.x) * (p2.y - p0.y) - (p2.x - p0.x) * (p1.y - p0.y);
}

//未开方的两点之间的矩离
export function distance_no_sqrt(p1, p2) {
  //return(sqrt((p1.x-p2.x)*(p1.x-p2.x)+(p1.y-p2.y)*(p1.y-p2.y)));
  return (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y);
}

//pointSet:[{x:a,y:b}...]
//ch: final sort array
//n: inital pointSet length
export function graham_scan(pointSet, ch, n) {
  // 这里会修改pointSet
  var i,
    j,
    k = 0,
    top = 2;
  var tmp = new Object();
  // 找到一个基点，基本就是保证最下面最左面的点
  for (i = 1; i < n; i++) {
    if (pointSet[i].y < pointSet[k].y || (pointSet[i].y == pointSet[k].y && pointSet[i].x < pointSet[k].x)) {
      k = i;
    }
  }

  tmp = pointSet[0];
  pointSet[0] = pointSet[k];
  pointSet[k] = tmp;

  let use = n;
  for (i = 1; i < use - 1; i++) {
    k = i;
    for (j = i + 1; j < use; j++) {
      var direct = multiply(pointSet[0], pointSet[k], pointSet[j]);
      if (direct > 0) {
        k = j;
      } else if (direct == 0) {
        // k j 同方向
        var dis = distance_no_sqrt(pointSet[0], pointSet[j]) - distance_no_sqrt(pointSet[0], pointSet[k]);
        use--; // 也就是不要了
        if (dis > 0) {
          // 保留j
          // 把 k 就不要了
          pointSet[k] = pointSet[j];
          pointSet[j] = pointSet[use];
          j--;
        } else {
          tmp = pointSet[use];
          pointSet[use] = pointSet[j];
          pointSet[j] = tmp;
        }
      }
    }
    tmp = pointSet[i];
    pointSet[i] = pointSet[k];
    pointSet[k] = tmp;
  }

  ch.push(pointSet[0]);
  ch.push(pointSet[1]);
  ch.push(pointSet[2]);
  for (i = 3; i < use; i++) {
    while (!(multiply(pointSet[i], ch[top - 1], ch[top]) < 0)) {
      top--;
      ch.pop();
    }
    top++;
    ch.push(pointSet[i]);
  }
}

//true: clockwise
export function checkClockwise(v1, v2) {
  const det = v1.x * v2.y - v1.y * v2.x;
  return det < 0;
}

export function getCenterOfPoints(points) {
  let x = 0,
    y = 0,
    _len = 0;
  for (var i = 0; i < points.length; i++) {
    if (!points[i] || points[i].length == 0) continue;
    x += points[i][0];
    y += points[i][1];
    _len++;
  }

  return {
    x: x / _len,
    y: y / _len,
  };
}

//计算单位向量
export function getUnitVector(vector) {
  let len = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
  return {
    x: vector.x / len,
    y: vector.y / len,
  };
}

//K为缩放因子，vn为单位向量，vector为单位向量上的一点
export function scaleVector(k, vn, vector) {
  let mul = (k - 1) * vn.x * vn.y;
  return {
    x: (1 + (k - 1) * vn.x * vn.x) * vector.x + mul * vector.y,
    y: mul * vector.x + (1 + (k - 1) * vn.y * vn.y) * vector.y,
  };
}
