import React from 'react';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomPoint from '@src/data/GeomPoint';
import { getSquareHandlePoint, getCircleHandlePoint } from '@src/data/AnchorsPoint';
import GeomArc from '@src/data/GeomArc';

export default function SelectRoundabout({ object }) {
  const getPathData = () => {
    const gpa = new GeomPolyArc(object.segments);
    const pathData = gpa.getSvgPathData();
    return { pathData };
  };

  const getPoint = () => {
    const result = [];
    const len = object.segments.length;
    for (let i = 0; i < len; i++) {
      const segment = object.segments[i];
      const ptStart = new GeomPoint(segment.ptStart.x, segment.ptStart.y);
      const ptStop = new GeomPoint(segment.ptStop.x, segment.ptStop.y);
      let ptCenter;
      if (segment.type === 'arc') {
        const { r, largeArcFlag, sweepFlag } = segment;
        const ptArc = new GeomArc(ptStart, ptStop, r, largeArcFlag, sweepFlag);
        ptCenter = ptArc.getMidPoint();
      } else if (segment.type === 'line') {
        ptCenter = ptStart.midPoint(ptStop);
      }
      result.push({ ptStart, ptStop, ptCenter });
    }
    return result;
  };

  const getShapes = points => {
    const shapes = [];
    points.forEach((p, index) => {
      shapes.push(getSquareHandlePoint(`RoundaboutConnect-${index}`, [p.ptStart.x, p.ptStart.y]));
      shapes.push(getCircleHandlePoint(`RoundaboutCenter-${index}`, [p.ptCenter.x, p.ptCenter.y]));
    });
    return { shapes };
  };

  const { pathData } = getPathData();
  const points = getPoint();
  const { shapes } = getShapes(points);
  return (
    <g lncd-role="street-selector">
      <path d={pathData} key="stripe-main" />
      {shapes}
    </g>
  );
}
