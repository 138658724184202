import React from 'react';
import _, { indexOf } from 'lodash';
import {
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetMatrixString,
  GetRotateMatrix,
  LengthBetweenPoints,
  matrixMultiply,
  RotatePoint,
} from '@src/data/CommonFunc';
import Text from '../Text';
import { TEXT_ANCHORS_TYPE, SNAP_TO_STREET_STYLE } from '@src/constant';

/**
 *
 * @param {object} argments
 * @param {Element} argments.svg
 * @param {object} argments.object
 */
function SymbolShape({ svg, object, ...restProps }) {
  if (!svg) return null;

  /**
   *
   * @param {number[]} matrix
   * @param {number} cx
   * @param {number} cy
   * @param {number} anchors
   * @param {number} width
   * @param {number} height
   * @return {number[]}
   */
  function calcMatrix(matrix, cx, cy, anchors, width, height) {
    let cr = [(anchors[1][0] + anchors[2][0]) / 2, (anchors[1][1] + anchors[2][1]) / 2];
    let angle = getAngleBy2Points([cx, cy], cr);
    let matrix1 = [1, 0, 0, 1, -cx, -cy];
    //1 rotate to 0 degrees
    // let matrix2 = GetRotateMatrix(-angle);
    //2 scale up
    let matrix3 = [
      (width / object.originwidth) * object.horizontal,
      0,
      0,
      (height / object.originheight) * object.vertical,
      0,
      0,
    ];
    //3 rotate back
    let matrix4 = GetRotateMatrix(angle);
    //4 move back
    let matrix5 = [1, 0, 0, 1, cx, cy];
    //5 get matrix
    matrix = matrixMultiply(matrix5, matrix4);
    matrix = matrixMultiply(matrix, matrix3);
    // matrix = matrixMultiply(matrix, matrix2);
    matrix = matrixMultiply(matrix, matrix1);

    matrix = [matrix[0], matrix[1], matrix[2], matrix[3], cx, cy];
    return matrix;
  }

  /**
   * @returns {string}
   */
  function setMatrix() {
    let matrix = [object.horizontal, 0, 0, object.vertical, 0, 0];
    let anchors = object.handlepoint;
    let cx = (anchors[0][0] + anchors[2][0]) / 2;
    let cy = (anchors[0][1] + anchors[2][1]) / 2;
    let width = LengthBetweenPoints(anchors[0], anchors[1]);
    let height = LengthBetweenPoints(anchors[1], anchors[2]);
    matrix = calcMatrix(matrix, cx, cy, anchors, width, height);
    //let matrix=[width/5000*Math.cos(angle),height/5000*Math.sin(angle),-height/5000*Math.sin(angle),width/5000*Math.cos(angle),llx,lly];
    //let operateId = generateOperateId();

    return GetMatrixString(matrix);
  }

  function createMarkup() {
    return { __html: svg.outerHTML };
  }

  return <g transform={setMatrix()} dangerouslySetInnerHTML={createMarkup()} {...restProps} pointerEvents="all" />;
}

class Symbol extends React.Component {
  getText = () => {
    const { object } = this.props;
    let shapeCenter = GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[8]);
    let angle = getAngleBy2Points(shapeCenter, object.handlepoint[8]);
    return (
      <Text
        angle={angle}
        x={shapeCenter[0]}
        y={shapeCenter[1] - object.text.size}
        textAnchor={TEXT_ANCHORS_TYPE.MIDDLE}
        text={object.text}
        content={object.text.text}
      />
    );
  };

  /**
   * handle custom attributes on SVG files
   * @param {*} object
   */
  calcSvg(object) {
    if (object.originsvg) {
      let div = document.createElement('div');
      div.innerHTML = object.originsvg;

      // "symbol-hook" is a custom attribute be implanted when generated SVG elements
      //  for catch SVG elements by DOM.
      let svg = div.querySelector('g[lncd-role="symbol-hook"]');
      if (svg) {
        let fillPaths = svg.querySelectorAll('*[lncd-role="symbol-fill"]');
        if (typeof object.style.fill !== 'undefined') {
          if (fillPaths && fillPaths.length > 0) {
            fillPaths.forEach(pathElem => {
              pathElem.setAttribute('fill', object.style.fill);
            });
          } else {
            let pathElem = svg.querySelector('path');
            if (pathElem && pathElem.getAttribute('fill') && pathElem.getAttribute('fill').indexOf('url') == -1) {
              pathElem.setAttribute('fill', object.style.fill);
            } else {
              let pathElements = svg.querySelectorAll('path');
              pathElements.forEach(pathElem => {
                pathElem.setAttribute('fill', object.style.fill);
              });
            }
          }
        }
        return svg;
      }
    }
  }

  render() {
    const { object } = this.props;

    return (
      <g id={object.functype}>
        {/* selectable */}
        <rect
          x={object.handlepoint[0][0]}
          y={object.handlepoint[0][1]}
          width={object.originwidth}
          height={object.originheight}
          fill="#FFFFFF"
          fillOpacity="0"
        />
        <SymbolShape object={object} svg={this.calcSvg(object)} style={{ opacity: object.style.opacity }} />
        {object.text.text && this.getText()}
      </g>
    );
  }
}

export default Symbol;
