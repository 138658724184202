import { Button } from 'antd';
import styled from 'styled-components';

const ShowPropsMenuBtn = styled(Button)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 36px;
  height: 36px;
  transition: unset;

  &[disabled],
  &[disabled]:hover {
    color: var(--color-prop-menu-btn-disabled);
    background: var(--color-disabled);
  }

  & {
    color: var(--color-prop-menu);
    background: var(--color-prop-menu-bg);
    border: 1px solid rgba(124, 124, 124, 0.25);
    border-radius: 0 !important;
    &:hover {
      color: var(--color-prop-menu);
      background: var(--color-prop-menu-bg);
      box-shadow: -1px -1px 3px rgba(124, 124, 124, 0.8);
    }
  }
`;

export default ShowPropsMenuBtn;
