import * as React from 'react';

function SvgIconResetColor(props) {
  return (
    <svg
      className="icon-reset-color_svg__icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <style />
      </defs>
      <path d="M808 602.9c-23.6 164.2-181.8 285.5-358.7 248.3C336.9 827.6 245.6 736.8 222 624.4c-40.3-192 92-361.8 290.4-361.8v99.2l248-148.8-248-148.8v99.2c-248 0-438 222.4-388.6 476.5 30.1 154.7 155 279.4 309.7 309.5C668 995 875.6 833.9 906.2 616.1c4.2-29.6-19.7-55.8-49.5-55.8h.1c-24.7 0-45.3 18.2-48.8 42.6z" />
    </svg>
  );
}

export default SvgIconResetColor;
