import React from 'react';
import { GetShapeStyle } from '@src/data/CommonProps';
import { getAngleBy2Points } from '@src/data/CommonFunc';
import { getSquareHandlePoint, SquareHandlePoint, TriangleHandlePoint } from '@src/data/AnchorsPoint';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import utility from '@src/data/Utility';
import { ANCHORS_TYPE } from '@src/constant';

class SelectCrossWalk extends React.Component {
  render() {
    const { object } = this.props;
    let commonProps = GetShapeStyle().ANCHORSSTYPE;
    let anchors = object.handlepoint;
    if (anchors.length < 9) return null;
    let getPathD = anchors => {
      return (
        'M' +
        anchors[0][0].toString() +
        ' ' +
        anchors[0][1].toString() +
        ' ' +
        'L' +
        anchors[6][0].toString() +
        ' ' +
        anchors[6][1].toString() +
        ' ' +
        'M' +
        anchors[4][0].toString() +
        ' ' +
        anchors[4][1].toString() +
        ' ' +
        'L' +
        anchors[5][0].toString() +
        ' ' +
        anchors[5][1].toString() +
        ' '
      );
    };
    let angleLeft = getAngleBy2Points(object.handlepoint[6], object.handlepoint[0]);
    let angleRight = angleLeft + Math.PI;
    let angleTopMiddle = getAngleBy2Points(object.handlepoint[3], object.handlepoint[4]);
    let angleBottomMiddle = angleTopMiddle + Math.PI;
    return (
      <g>
        <path d={getPathD(anchors)}></path>
        <SquareHandlePoint id={ANCHORS_TYPE.CROSSWALKBEGIN} point={anchors[0]} />
        <SquareHandlePoint id={ANCHORS_TYPE.CROSSWALKEND} point={anchors[6]} />
        <TriangleHandlePoint id={ANCHORS_TYPE.CROSSWALKMIDDLETOP} point={anchors[4]} angle={angleTopMiddle} {...commonProps} />
        <TriangleHandlePoint
          id={ANCHORS_TYPE.CROSSWALKMIDDLEBOTTOM}
          point={anchors[5]}
          angle={angleBottomMiddle}
          {...commonProps}
        />
        <TriangleHandlePoint id={ANCHORS_TYPE.CROSSWALKLEFTTOP} point={anchors[1]} angle={angleLeft} {...commonProps} />
        <TriangleHandlePoint id={ANCHORS_TYPE.CROSSWALKRIGHTTOP} point={anchors[7]} angle={angleRight} {...commonProps} />
        <TriangleHandlePoint id={ANCHORS_TYPE.CROSSWALKLEFTBOTTOM} point={anchors[2]} angle={angleLeft} {...commonProps} />
        <TriangleHandlePoint id={ANCHORS_TYPE.CROSSWALKRIGHTBOTTOM} point={anchors[8]} angle={angleRight} {...commonProps} />
      </g>
    );
  }
}

export default SelectCrossWalk;
