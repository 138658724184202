/**
 * Provide custom comparison for withSize HOC componnet.
 * The reason behind this is that the withSize HOC will always return new `size` object each time it renders, breaks the shallow compare process for React.memo
 * @param {*} prevProps
 * @param {*} nextProps
 */
export const areEqualHeight = (prevProps, nextProps) => {
  for (let key in prevProps) {
    if (key === 'size' && prevProps.size.height !== nextProps.size.height) {
      return false;
    } else if (prevProps[key] !== nextProps[key]) {
      return false;
    }
  }
  return true;
};
