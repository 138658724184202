import * as React from 'react';

function SvgIconRoad(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M17.976 2.783A1 1 0 0017 2H7a1 1 0 00-.976.783l-4 18A1 1 0 003 22h18a1.002 1.002 0 00.976-1.217l-4-18zM13 20v-4h-2v4H4.247L7.802 4H11v4h2V4h3.198l3.556 16H13z" />
          <path d="M11 10h2v4h-2z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconRoad;
