/* eslint-disable no-useless-escape */
import UtilityMath from '@src/data/UtilityMath';
import L from 'leaflet';
import axios from 'axios';

/**
 * 获取 Zoom 等级
 * @param {number} latitude
 * @param {number} longitude
 * @param {number} uupp
 */
export function getZoomLevel(latitude, longitude, uupp) {
  // console.log('latitude', latitude);
  // console.log('longitude', longitude);
  // console.log('uupp', uupp);
  let utilityMath = new UtilityMath();
  let lr = utilityMath.toRadians(latitude);
  function log2(x) {
    return Math.log(x) / Math.LN2;
  }
  return Math.ceil(log2((156543.04 * Math.cos(lr)) / uupp));
}

/**
 * 计算地图精度
 * @param {number} latitude
 * @param {number} longitude
 * @param {number} zoom - 1-19
 */
export function getResolution(latitude, longitude, zoom) {
  // console.log('latitude', latitude);
  // console.log('longitude', longitude);
  // console.log('zoom', zoom);
  var maxZoom = Math.floor(zoom);
  let utilityMath = new UtilityMath();
  var lr = utilityMath.toRadians(latitude);
  // console.log('maxZoom', maxZoom);
  // console.log('lr', lr);

  // 公式
  return (156543.04 * Math.cos(lr)) / Math.pow(2, maxZoom);
}

/**
 * 在地图上平移的位置换算为经纬度
 * @description 地球是一个椭球，Datum是一组用于描述这个椭球的数据集合。最常用的一个Datum是WGS84（World Geodetic System 1984）
 * 椭球截面长轴为a=6378137米
 * 椭球截面短轴为b=6356752.3142米，可选参数
 * @param {number} latitude
 * @param {number} longitude
 * @param {number} dY
 * @param {number} dX
 */
export function offsetPosition(latitude, longitude, dY, dX) {
  // console.log('latitude', latitude);
  // console.log('longitude', longitude);
  // console.log('dY', dY);
  // console.log('dX', dX);
  let utilityMath = new UtilityMath();
  let y = utilityMath.toDegrees(dY / 6378137);
  let lr = utilityMath.toRadians(latitude);
  let x = utilityMath.toDegrees(dX / (6378137 * Math.cos(lr)));
  return {
    latitude: latitude + x,
    longitude: longitude + y,
  };
}

/**
 * 是否为经纬度格式
 * @deprecated
 * @description 例如 47.610679194331169,-122.10788659751415、47.61067, -122.10788
 * 小数位：16
 * 经度：[-90, +90]
 * 纬度：[-180, +180]
 * @param {string} url
 * @return {boolean}
 */
export function isLatLngFormat(url) {
  let arr = url.split(',');
  if (arr.length === 2 && arr.every(v => v.length > 0)) {
    arr = arr.map(v => v.trim());
    if (arr.every(v => Number.isFinite(+v))) {
      let lngRegex = /^[-\+]?((1[0-7]\d{1}|0?\d{1,2})\.\d{1,16}|180\.0{1,16})$/;
      let latRegex = /^[-\+]?([0-8]?\d{1}\.\d{1,16}|90\.0{1,16})$/;
      if (arr[0].match(latRegex) && arr[1].match(lngRegex)) {
        return true;
      }
    }
  }
  return false;
}

/**
 * @description // https://wiki.openstreetmap.org/wiki/API_v0.6
 * @async
 * @param {Array<[number, number]>} bbox
 * @returns {any[]}
 */
export async function getElementsFromMap(bbox) {
  let response = await axios
    .get(`https://api.openstreetmap.org/api/0.6/map.json?bbox=${bbox.join(',')}`, {
      timeout: 8000,
    })
    .catch(err => {
      throw new Error(err);
    });
  if (response.data) {
    return response.data.elements;
  } else {
    return [];
  }
}
