import GeomPoint from '@src/data/GeomPoint';
import GeomLine from '@src/data/GeomLine';
import GeomArc from '@src/data/GeomArc';
import GeomQ from '@src/data/GeomQ';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomOffsetArc from '@src/data/GeomOffsetArc';
import GeomStripe from '@src/data/GeomStripe';
import Utility from '@src/data/Utility';
import UtilityGeom from '@src/data/UtilityGeom';
import {
  getAngle,
  GetCenterInTwoPoints,
  ScalePoint,
  RotatePoint,
  LengthBetweenPoints,
  pointTransform,
  IsUnderLine,
  FindCircle,
  GetCircleCenter,
  GetSmallCircleCenter,
  GetBigCircleCenter,
  ArrayCopyLength,
  ByTime,
  IsOneLine,
  TowPointVertical,
  getAngleBy2Points,
  FindCircleLinePoint,
  IsBigArc,
  GetCircleCenterew,
  GetCircleArcCenter2,
  toFixed,
  GetLineKB,
  FindCircleLinePointK,
  TowPointBeginVertical,
  TowPointLinePoint,
  TowPointVerticalLength,
  TowPointVerticalAcross,
  PointCircleState,
  IsZero,
  GetCenter,
  GetTowPointsN,
  GetCircleArcCenter,
  TowPointDestPointLength,
  GetRotateMatrix,
  matrixMultiply,
  GetMoveMatrix,
  TowPointVerticalLengthByStatus,
  GetTowPointRange,
  FindCircleByR,
  MovePoint,
  GetCirclePoint,
  IsPointInRange,
  IsPointInRange1,
  IsArcRangePoint,
} from '@src/data/CommonFunc';
import {
  CopyPoint,
  GetCLoseShapeAngle,
  GetCLoseShapeAnglePoint,
  GetCloseShapeCenter,
  GetHandlePointRange,
  GetStreetArcBottomPoint,
  GetStreetArcSideOutPoint,
  GetStreetArcSidePoint,
  GetStreetArcTopPoint,
  GetStreetBottomPoint,
  GetStreetPointIstop,
  GetStreetSideOutPoint,
  GetStreetSidePoint,
  GetStreetTopPoint,
  TowLineAcrossPoint,
  IsRangePoint,
  GetLineHeightPoints,
} from '@src/data/BusinessFun';
import {
  GetMaxHeight,
  GetStreetMaxPoints,
  GetStreetAreaBegin,
  GetStreetLineBegin,
  GetStreetLineEnd,
} from './StreetFunHandle';
import { distance, offset, AngleFromHorizontal, devicePtToUserPt } from '@src/data/CommonFunction';
import SVGPathSeg from '@src/data/SVGPathSeg';
import diagramManager from '@src/data/DiagramManager';
import _Street from '@src/data/_Street';
import { StationLineDirections } from '@src/data/ShapeDataList';
import {
  ANCHORS_TYPE,
  FUNCTION_TYPE,
  THREE_POINT_STATUS_TYPE,
  CIRCLE_SIZE_TYPE,
  CROSS_WALK_SPACE,
  LENGTH_TYPE,
  COUNTER_TOP_PARAM_TYPE,
  STRUCTURE_PARAM_TYPE,
} from '@src/constant';
import _ from 'lodash';
import * as workData from '@src/data/WorkData';
import { updateTrack } from '@src/utils/shadow';
import { createArc, createLine } from '@src/data/ShapeOperationData';

const INFOANCHORSLENGTH = 20;
const SCALERECTINDEX = [2, 3, 0, 1, 5, 4, 7, 6, 8, 9];

const SCALEREPOSITION = [
  (ANCHORS_TYPE.LEFTTOP, 2),
  (ANCHORS_TYPE.RIGHTTOP, 3),
  (ANCHORS_TYPE.RIGHTBOTTOM, 0),
  (ANCHORS_TYPE.LEFTBOTTOM, 1),
  (ANCHORS_TYPE.TOPMEDIUM, 5),
  (ANCHORS_TYPE.BOTTOMMEDIUM, 4),
  (ANCHORS_TYPE.LEFTMEDIUM, 7),
  (ANCHORS_TYPE.RIGHTMEDIUM, 6),
];

//对点统一进行 放大 平移  旋转操作 再显示 (For point to do: Enlargement, translation, rotation operation and re-display)
export function TransformScaleObject(obj, drawhandlepoint, point) {
  var handlePoint = [];
  // 生成原点初始形状 (Generating initial shape)
  handlePoint = drawhandlepoint.concat();
  //放大 (enlarge)
  var originindex = SCALERECTINDEX[obj.scaleindex];
  var origin = [handlePoint[originindex][0], handlePoint[originindex][1]];
  var newLine = LengthBetweenPoints(point, origin);
  var oldLine = LengthBetweenPoints(handlePoint[obj.scaleindex], origin);
  var scale = newLine / oldLine;

  obj.transform.scale = [scale, scale];

  for (var i in handlePoint) {
    handlePoint[i] = ScalePoint(handlePoint[i], obj.transform.scale[0], obj.transform.scale[1], origin);
  }

  var infoLine = LengthBetweenPoints(handlePoint[7], handlePoint[8]);
  handlePoint[8] = ScalePoint(
    handlePoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    handlePoint[7]
  );
  obj.width = LengthBetweenPoints(handlePoint[0], handlePoint[1]);
  obj.height = LengthBetweenPoints(handlePoint[0], handlePoint[3]);
  obj.handlepoint = handlePoint;
  return obj;
}

function TransformScaleSegments(segments, scaleX, scaleY, center) {
  segments.forEach(segment => {
    const { ptStart, ptStop } = segment;
    [ptStart.x, ptStart.y] = ScalePoint([ptStart.x, ptStart.y], scaleX, scaleY, center);
    [ptStop.x, ptStop.y] = ScalePoint([ptStop.x, ptStop.y], scaleX, scaleY, center);
  });
}

function TransformScaleGroupObject(groupObject, scaleX, scaleY, center) {
  const { handlepoint, segments, components, text, ptApex, position } = groupObject;
  if (handlepoint && handlepoint.length > 0) {
    handlepoint.forEach((point, index) => {
      handlepoint[index] = ScalePoint(point, scaleX, scaleY, center);
    });
  }
  // if (segments && segments.length > 0) {
  //   TransformScaleSegments(segments, scaleX, scaleY, center);
  // }
  // if (components && components.length > 0) {
  //   components.forEach(component => {
  //     if (component.segments && component.segments.length > 0) {
  //       TransformScaleSegments(component.segments, scaleX, scaleY, center);
  //     }
  //     if (component.width) {
  //       component.width *= scaleX;
  //     }
  //   });
  // }
  // if (text) {
  //   text.point = ScalePoint(text.point, scaleX, scaleY, center);
  // }
  // if (ptApex) {
  //   [ptApex.x, ptApex.y] = ScalePoint([ptApex.x, ptApex.y], scaleX, scaleY, center);
  // }
  // if (position) {
  //   if (position.x1 && position.y1) {
  //     [position.x1, position.y1] = ScalePoint([position.x1, position.y1], scaleX, scaleY, center);
  //   }
  //   if (position.x2 && position.y2) {
  //     [position.x2, position.y2] = ScalePoint([position.x2, position.y2], scaleX, scaleY, center);
  //   }
  // }
}

export function TransformScaleLeftTop(obj, point) {
  var oldLength = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[2]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[2]);
  var scale = [newLength / oldLength, newLength / oldLength];
  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = ScalePoint(obj.handlepoint[i], scale[0], scale[1], obj.handlepoint[2]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);

  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleGroupObject(data, scale[0], scale[1], obj.handlepoint[2]);
    });
  }
  return obj;
}

export function TransformScaleRightTop(obj, point) {
  var oldLength = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[3]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[3]);
  var scale = [newLength / oldLength, newLength / oldLength];
  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = ScalePoint(obj.handlepoint[i], scale[0], scale[1], obj.handlepoint[3]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);

  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleGroupObject(data, scale[0], scale[1], obj.handlepoint[3]);
    });
  }
  return obj;
}

export function TransformScaleRightBottom(obj, point) {
  var oldLength = LengthBetweenPoints(obj.handlepoint[2], obj.handlepoint[0]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[0]);
  var scale = [newLength / oldLength, newLength / oldLength];
  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = ScalePoint(obj.handlepoint[i], scale[0], scale[1], obj.handlepoint[0]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);

  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleGroupObject(data, scale[0], scale[1], obj.handlepoint[0]);
    });
  }
  return obj;
}

/**
 *
 * @param {*} obj
 * @param {[number, number]} point
 * @returns {*} obj
 */
export function TransformScaleLeftBottom(obj, point) {
  var oldLength = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[3]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[1]);
  var scale = [newLength / oldLength, newLength / oldLength];
  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = ScalePoint(obj.handlepoint[i], scale[0], scale[1], obj.handlepoint[1]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);
  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleGroupObject(data, scale[0], scale[1], obj.handlepoint[1]);
    });
  }
  return obj;
}

function TransformScaleMediumSegments(segments, matrix) {
  segments.forEach(segment => {
    const { ptStart, ptStop } = segment;
    [ptStart.x, ptStart.y] = pointTransform(matrix, [ptStart.x, ptStart.y]);
    [ptStop.x, ptStop.y] = pointTransform(matrix, [ptStop.x, ptStop.y]);
  });
}

function TransformScaleMediumGroupObject(groupObject, matrix) {
  const { handlepoint, segments, components, text, ptApex, position } = groupObject;
  if (handlepoint && handlepoint.length > 0) {
    handlepoint.forEach((point, index) => {
      handlepoint[index] = pointTransform(matrix, point);
    });
  }
  // if (segments && segments.length > 0) {
  //   TransformScaleMediumSegments(segments, matrix);
  // }
  // if (components && components.length > 0) {
  //   components.forEach(component => {
  //     if (component.segments && component.segments.length > 0) {
  //       TransformScaleMediumSegments(component.segments, matrix);
  //     }
  //     if (component.width) {
  //       component.width *= matrix[0];
  //     }
  //   });
  // }
  // if (text) {
  //   text.point = pointTransform(matrix, text.point);
  // }
  // if (ptApex) {
  //   [ptApex.x, ptApex.y] = pointTransform(matrix, [ptApex.x, ptApex.y]);
  // }
  // if (position) {
  //   if (position.x1 && position.y1) {
  //     [position.x1, position.y1] = pointTransform(matrix, [position.x1, position.y1]);
  //   }
  //   if (position.x2 && position.y2) {
  //     [position.x2, position.y2] = pointTransform(matrix, [position.x2, position.y2]);
  //   }
  // }
}

export function TransformScaleTopMedium(obj, point) {
  //1 移动
  let shapeCenter = GetCenterInTwoPoints(obj.handlepoint[1], obj.handlepoint);
  var matrix1 = [1, 0, 0, 1, -obj.handlepoint[5][0], -obj.handlepoint[5][1]];
  //1 先旋转正
  var matrix2 = GetRotateMatrix(-obj.rotateangle);
  //2 负y向放大
  var oldLength = LengthBetweenPoints(obj.handlepoint[4], obj.handlepoint[5]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[5]);
  var scale = [1, newLength / oldLength];
  var matrix3 = [scale[0], 0, 0, scale[1], 0, 0];
  //3 在旋转回去
  var matrix4 = GetRotateMatrix(obj.rotateangle);
  //4 在移动回去
  var matrix5 = [1, 0, 0, 1, obj.handlepoint[5][0], obj.handlepoint[5][1]];
  //4 得到matrix
  var matrix = matrixMultiply(matrix5, matrix4);
  matrix = matrixMultiply(matrix, matrix3);
  matrix = matrixMultiply(matrix, matrix2);
  matrix = matrixMultiply(matrix, matrix1);
  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = pointTransform(matrix, obj.handlepoint[i]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);
  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleMediumGroupObject(data, matrix);
    });
  }
  return obj;
}

export function TransformScaleBottomMedium(obj, point) {
  //1 移动
  var matrix1 = [1, 0, 0, 1, -obj.handlepoint[4][0], -obj.handlepoint[4][1]];
  //1 先旋转正
  var matrix2 = GetRotateMatrix(-obj.rotateangle);
  //2 负y向放大
  var oldLength = LengthBetweenPoints(obj.handlepoint[4], obj.handlepoint[5]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[4]);
  var scale = [1, newLength / oldLength];
  var matrix3 = [scale[0], 0, 0, scale[1], 0, 0];
  //3 在旋转回去
  var matrix4 = GetRotateMatrix(obj.rotateangle);
  //4 在移动回去
  var matrix5 = [1, 0, 0, 1, obj.handlepoint[4][0], obj.handlepoint[4][1]];
  //4 得到matrix
  var matrix = matrixMultiply(matrix5, matrix4);
  matrix = matrixMultiply(matrix, matrix3);
  matrix = matrixMultiply(matrix, matrix2);
  matrix = matrixMultiply(matrix, matrix1);

  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = pointTransform(matrix, obj.handlepoint[i]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);
  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleMediumGroupObject(data, matrix);
    });
  }
  return obj;
}

/**
 *
 * @param {*} obj
 * @param {[number, number]} point
 * @returns {*} obj
 */
export function TransformScaleLeftMedium(obj, point) {
  //1 移动
  var matrix1 = [1, 0, 0, 1, -obj.handlepoint[7][0], -obj.handlepoint[7][1]];
  //1 先旋转正
  var matrix2 = GetRotateMatrix(-obj.rotateangle);
  //2 负y向放大
  var oldlength = LengthBetweenPoints(obj.handlepoint[6], obj.handlepoint[7]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[7]);
  var scale = [newLength / oldlength, 1];
  var matrix3 = [scale[0], 0, 0, scale[1], 0, 0];
  //3 在旋转回去
  var matrix4 = GetRotateMatrix(obj.rotateangle);
  //4 在移动回去
  var matrix5 = [1, 0, 0, 1, obj.handlepoint[7][0], obj.handlepoint[7][1]];
  //4 得到matrix
  var matrix = matrixMultiply(matrix5, matrix4);
  matrix = matrixMultiply(matrix, matrix3);
  matrix = matrixMultiply(matrix, matrix2);
  matrix = matrixMultiply(matrix, matrix1);
  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = pointTransform(matrix, obj.handlepoint[i]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);
  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleMediumGroupObject(data, matrix);
    });
  }
  return obj;
}

export function TransformScaleRightMedium(obj, point) {
  //1 移动
  var matrix1 = [1, 0, 0, 1, -obj.handlepoint[6][0], -obj.handlepoint[6][1]];
  //1 先旋转正
  var matrix2 = GetRotateMatrix(-obj.rotateangle);
  //2 负y向放大
  var oldlength = LengthBetweenPoints(obj.handlepoint[6], obj.handlepoint[7]);
  var newLength = LengthBetweenPoints(point, obj.handlepoint[6]);
  var scale = [newLength / oldlength, 1];
  var matrix3 = [scale[0], 0, 0, scale[1], 0, 0];
  //3 在旋转回去
  var matrix4 = GetRotateMatrix(obj.rotateangle);
  //4 在移动回去
  var matrix5 = [1, 0, 0, 1, obj.handlepoint[6][0], obj.handlepoint[6][1]];
  //4 得到matrix
  var matrix = matrixMultiply(matrix5, matrix4);
  matrix = matrixMultiply(matrix, matrix3);
  matrix = matrixMultiply(matrix, matrix2);
  matrix = matrixMultiply(matrix, matrix1);
  for (var i in obj.handlepoint) {
    obj.handlepoint[i] = pointTransform(matrix, obj.handlepoint[i]);
  }
  var infoLine = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[8]);
  obj.handlepoint[8] = ScalePoint(
    obj.handlepoint[8],
    INFOANCHORSLENGTH / infoLine,
    INFOANCHORSLENGTH / infoLine,
    obj.handlepoint[7]
  );
  obj.width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.height = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[3]);
  if (obj.functype === FUNCTION_TYPE.GROUP) {
    obj.groupdata.forEach(data => {
      TransformScaleMediumGroupObject(data, matrix);
    });
  }
  return obj;
}

export function TransformScaleWidth(drawhandlepoint, scalex) {
  var handlePoint = [];
  // 生成原点初始形状 (Generating initial shape)
  handlePoint = drawhandlepoint.concat();
  //放大 (enlarge)
  handlePoint[1] = ScalePoint(handlePoint[1], scalex, scalex, handlePoint[0]);
  handlePoint[2] = ScalePoint(handlePoint[2], scalex, scalex, handlePoint[3]);
  handlePoint[4] = ScalePoint(handlePoint[4], scalex, scalex, handlePoint[0]);
  handlePoint[5] = ScalePoint(handlePoint[5], scalex, scalex, handlePoint[3]);
  handlePoint[7] = ScalePoint(handlePoint[7], scalex, scalex, handlePoint[6]);
  handlePoint[9] = ScalePoint(handlePoint[9], scalex, scalex, handlePoint[0]);
  handlePoint[10] = ScalePoint(handlePoint[10], scalex, scalex, handlePoint[3]);
  var infoAnchors = GetCenterInTwoPoints(handlePoint[9], handlePoint[10]);
  var infoLine = LengthBetweenPoints(handlePoint[7], infoAnchors);
  handlePoint[8] = ScalePoint(infoAnchors, INFOANCHORSLENGTH / infoLine, 1, handlePoint[7]);
  return handlePoint;
}

export function TransformSquareScaleWidth(drawhandlepoint, scalex) {
  var handlePoint = [];
  // 生成原点初始形状
  handlePoint = drawhandlepoint.concat();
  //放大
  var center = GetCenterInTwoPoints(handlePoint[0], handlePoint[2]);
  for (let i in handlePoint) {
    handlePoint[i] = ScalePoint(handlePoint[i], scalex, scalex, center);
  }
  var infoAnchors = GetCenterInTwoPoints(handlePoint[9], handlePoint[10]);
  var infoLine = LengthBetweenPoints(handlePoint[7], infoAnchors);
  handlePoint[8] = ScalePoint(infoAnchors, INFOANCHORSLENGTH / infoLine, 1, handlePoint[7]);
  return handlePoint;
}

export function TransformCalloutScaleWidth(drawhandlepoint, nowLength, scaleHeight) {
  if (scaleHeight == null) {
    scaleHeight = 1;
  }
  var handlePoint = [];
  // 生成原点初始形状
  handlePoint = drawhandlepoint.concat();
  var center = GetCenterInTwoPoints(drawhandlepoint[2], drawhandlepoint[4]);
  var oldLength = LengthBetweenPoints(drawhandlepoint[2], drawhandlepoint[3]);
  var scale = nowLength / oldLength;
  //放大
  handlePoint[2] = ScalePoint(drawhandlepoint[2], scale, scaleHeight, center);
  handlePoint[3] = ScalePoint(drawhandlepoint[3], scale, scaleHeight, center);
  handlePoint[4] = ScalePoint(drawhandlepoint[4], scale, scaleHeight, center);
  handlePoint[5] = ScalePoint(drawhandlepoint[5], scale, scaleHeight, center);
  handlePoint[6] = ScalePoint(drawhandlepoint[6], scale, scaleHeight, center);
  handlePoint[7] = ScalePoint(drawhandlepoint[7], scale, scaleHeight, center);
  handlePoint[8] = ScalePoint(drawhandlepoint[8], scale, scaleHeight, center);

  var center34 = GetCenterInTwoPoints(handlePoint[3], handlePoint[4]);
  var infoLength = LengthBetweenPoints(center, center34) + LENGTH_TYPE.ANGLELENGTH;
  var infoAnchors = GetCenterInTwoPoints(handlePoint[7], handlePoint[8]);
  var infoLine = LengthBetweenPoints(center, infoAnchors);
  handlePoint[6] = ScalePoint(infoAnchors, infoLength / infoLine, infoLength / infoLine, center);
  return handlePoint;
}

export function TransformScaleHeight(drawhandlepoint, scaley) {
  var handlePoint = [];
  // 生成原点初始形状 (Generating initial shape)
  handlePoint = drawhandlepoint.concat();
  handlePoint[0] = ScalePoint(handlePoint[0], scaley, scaley, handlePoint[3]);
  handlePoint[1] = ScalePoint(handlePoint[1], scaley, scaley, handlePoint[2]);
  handlePoint[4] = ScalePoint(handlePoint[4], scaley, scaley, handlePoint[5]);
  handlePoint[6] = ScalePoint(handlePoint[6], scaley, scaley, handlePoint[3]);
  handlePoint[7] = ScalePoint(handlePoint[7], scaley, scaley, handlePoint[2]);
  handlePoint[8] = ScalePoint(handlePoint[8], scaley, scaley, handlePoint[10]);
  handlePoint[9] = ScalePoint(handlePoint[9], scaley, scaley, handlePoint[10]);
  var infoAnchors = GetCenterInTwoPoints(handlePoint[9], handlePoint[10]);
  var infoLine = LengthBetweenPoints(handlePoint[7], infoAnchors);
  handlePoint[8] = ScalePoint(infoAnchors, INFOANCHORSLENGTH / infoLine, 1, handlePoint[7]);
  return handlePoint;
}

export function TransformRotateAngle(drawhandlepoint, drawangle) {
  var handlePoint = [];
  // 生成原点初始形状 (Generating initial shape)
  handlePoint = drawhandlepoint.concat();
  var center = GetCenterInTwoPoints(handlePoint[0], handlePoint[2]);
  for (var i in handlePoint) {
    handlePoint[i] = RotatePoint(handlePoint[i], drawangle, center);
  }
  return handlePoint;
}

export function TransformRotateAngleCloseShape(drawhandlepoint, drawangle) {
  var handlePoint = [];
  // 生成原点初始形状
  handlePoint = drawhandlepoint.concat();
  var center = GetCloseShapeCenter(drawhandlepoint);
  for (var i in handlePoint) {
    var tempPoint = RotatePoint(handlePoint[i], drawangle, center);
    handlePoint[i][0] = tempPoint[0];
    handlePoint[i][1] = tempPoint[1];
  }
  return handlePoint;
}

/**
 *
 * @param {GeomLine[]|GeomArc[]|GeomQ[]} segments
 * @param {number} radianAngle
 * @param {[number, number]} rotateCenter
 */
function rotateSegmentPoints(segments, radianAngle, rotateCenter) {
  segments.forEach(segmentPoint => {
    const { ptStart, ptStop, pt = null } = segmentPoint;
    [ptStart.x, ptStart.y] = RotatePoint([ptStart.x, ptStart.y], radianAngle, rotateCenter);
    [ptStop.x, ptStop.y] = RotatePoint([ptStop.x, ptStop.y], radianAngle, rotateCenter);
    if (pt) {
      [pt.x, pt.y] = RotatePoint([pt.x, pt.y], radianAngle, rotateCenter);
    }
  });
}

/**
 *
 * @param {*} groupObject
 * @param {number} radianAngle
 * @param {[number, number]} rotateCenter
 */
function TransformRotateGroupObject(groupObject, radianAngle, rotateCenter) {
  const { segments, components, text, ptApex, position, handlepoint } = groupObject;
  if (handlepoint && handlepoint.length > 0) {
    handlepoint.forEach((point, index) => {
      handlepoint[index] = RotatePoint(point, radianAngle, rotateCenter);
    });
  }
  if (segments && segments.length > 0) {
    rotateSegmentPoints(segments, radianAngle, rotateCenter);
  }
  if (components && components.length > 0) {
    components.forEach(component => {
      if (component.segments && component.segments.length > 0) {
        rotateSegmentPoints(component.segments, radianAngle, rotateCenter);
      }
    });
  }
  if (text && text.point && text.point.length > 0) {
    text.point = RotatePoint(text.point, radianAngle, rotateCenter);
  }
  if (ptApex) {
    [ptApex.x, ptApex.y] = RotatePoint([ptApex.x, ptApex.y], radianAngle, rotateCenter);
  }
  if (position) {
    if (position.x1 && position.y1) {
      [position.x1, position.y1] = RotatePoint([position.x1, position.y1], radianAngle, rotateCenter);
    }
    if (position.x2 && position.y2) {
      [position.x2, position.y2] = RotatePoint([position.x2, position.y2], radianAngle, rotateCenter);
    }
  }
}

/**
 * rotation angle is determined by the new rotate handle position
 * @param {*} obj
 * @param {[number, number]} point
 * @returns {*} obj
 */
export function TransformRotateObject(obj, point) {
  // 生成原点初始形状 (Generating initial shape)
  if (
    obj.functype == FUNCTION_TYPE.STATIONLINE ||
    obj.functype == FUNCTION_TYPE.TRIANGULATIONNETWORK ||
    obj.functype == FUNCTION_TYPE.XYMEASUREMENT
  ) {
    const { type } = obj;
    // TODO: 和 initMeasurementCoords 里面的逻辑有部分重复
    let baseRotation = Math.PI / 2;
    switch (type) {
      case StationLineDirections.EAST:
        baseRotation = 0;
        break;
      case StationLineDirections.WEST:
        baseRotation = Math.PI;
        break;
      case StationLineDirections.SOUTH:
        baseRotation = (Math.PI * 3) / 2;
        break;
      default:
        break;
    }
    obj.rotation = getAngleBy2Points([obj.position.x1, obj.position.y1], point) - baseRotation;
  } else {
    // 获取对角线的中点，即 Selector 的中点
    var center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[2]);
    var oldRad = getAngleBy2Points(center, obj.handlepoint[8]);
    let newRad = getAngleBy2Points(center, point);
    //旋转 (rotate)
    obj.rotateangle = newRad;
    const rad = newRad - oldRad;
    for (var i in obj.handlepoint) {
      obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], rad, center);
    }
    if (obj.functype === FUNCTION_TYPE.GROUP) {
      obj.groupdata.forEach(data => {
        TransformRotateGroupObject(data, rad, center);
      });
    }
  }
  return obj;
}

// TODO: remove this one
export function TransformRotateCalloutObject(obj, point) {
  var handlePoint = [];
  // 生成原点初始形状 (Generating initial shape)
  handlePoint = obj.handlepoint;
  var center = handlePoint[1];
  //旋转 (rotate)
  let angle = getAngle(center[0], center[1], point[0], point[1]);
  handlePoint[2] = RotatePoint(handlePoint[2], angle - obj.rotateangle, center);
  handlePoint[3] = RotatePoint(handlePoint[3], angle - obj.rotateangle, center);
  handlePoint[4] = RotatePoint(handlePoint[4], angle - obj.rotateangle, center);
  handlePoint[5] = RotatePoint(handlePoint[5], angle - obj.rotateangle, center);
  handlePoint[6] = RotatePoint(handlePoint[6], angle - obj.rotateangle, center);
  obj.rotateangle = angle;
  obj.handlepoint = handlePoint;
  return obj;
}

/**
 *
 * @param {*} obj
 * @param {*} acrossDataIndex   streetacrossdata 第N个值
 * @param {*} pointIndex    0 maxdata  2 objdata
 * @param {*} point
 */
export function TransformStreetAcrossPoint(obj, acrossDataIndex, pointIndex, point) {
  let acrossDataId = obj.handlepoint[acrossDataIndex][3].acrossobjid;
  let dataObjId = acrossDataId.split('||')[2];
  let dataLine = Number(acrossDataId.split('||')[3]);
  let line = Number(acrossDataId.split('||')[1]);
  let nowObjId = acrossDataId.split('||')[0];

  let dataObj = workData.getObject(dataObjId);
  let dataMaxHeight = GetMaxHeight(dataObj);
  let maxDataTopPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, true);
  let maxDataBottomPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, false);
  let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
  let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
  let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
  let dataBeginLine = GetStreetLineBegin(dataObj.groupdata);
  let dataEndLine = GetStreetLineEnd(dataObj.groupdata);

  let nowObj = workData.getObject(nowObjId);
  let maxheight = GetMaxHeight(nowObj);
  let maxTopPoints = GetStreetMaxPoints(nowObj, maxheight / 2, true);
  let maxBottomPoints = GetStreetMaxPoints(nowObj, maxheight / 2, false);
  let beginIndex = GetStreetAreaBegin(maxTopPoints);
  let centerIndex = maxTopPoints[beginIndex][3].rightindex;
  let endIndex = maxTopPoints[centerIndex][3].rightindex;
  let beginLine = GetStreetLineBegin(nowObj.groupdata);
  let endLine = GetStreetLineEnd(nowObj.groupdata);
  let nearIndex = obj.handlepoint[acrossDataIndex][pointIndex][3];
  let nowPoint = [];
  if (pointIndex === 0) {
    //dataPoint
    if (dataLine == dataBeginLine) {
      let circle = FindCircle(
        maxDataTopPoints[dataBeginIndex],
        maxDataTopPoints[dataCenterIndex],
        maxDataTopPoints[dataEndIndex]
      );
      if (IsZero(circle[2])) {
        nowPoint = TowPointVerticalAcross(maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataEndIndex], point);
        let pointRange = GetTowPointRange(maxDataTopPoints[nearIndex], obj.handlepoint[acrossDataIndex][1]);
        if (!IsPointInRange1(nowPoint, pointRange)) {
          return workData.getObject(obj.operateid);
        }
      } else {
        nowPoint = FindCircleLinePoint(circle, point);
        let isBytime =
          ByTime(
            maxDataTopPoints[nearIndex],
            obj.handlepoint[acrossDataIndex][pointIndex],
            obj.handlepoint[acrossDataIndex][1]
          ) === THREE_POINT_STATUS_TYPE.BYTIME;
        if (
          !IsArcRangePoint(maxDataTopPoints[nearIndex], obj.handlepoint[acrossDataIndex][1], circle, isBytime, nowPoint)
        ) {
          return workData.getObject(obj.operateid);
        }
      }
    } else {
      let circle = FindCircle(
        maxDataBottomPoints[dataBeginIndex],
        maxDataBottomPoints[dataCenterIndex],
        maxDataBottomPoints[dataEndIndex]
      );
      if (IsZero(circle[2])) {
        nowPoint = TowPointVerticalAcross(
          maxDataBottomPoints[dataBeginIndex],
          maxDataBottomPoints[dataCenterIndex],
          point
        );
        let pointRange = GetTowPointRange(maxDataBottomPoints[nearIndex], obj.handlepoint[acrossDataIndex][1]);
        if (!IsPointInRange1(nowPoint, pointRange)) {
          return workData.getObject(obj.operateid);
        }
      } else {
        nowPoint = FindCircleLinePoint(circle, point);
        let isBytime =
          ByTime(
            maxDataBottomPoints[nearIndex],
            obj.handlepoint[acrossDataIndex][pointIndex],
            obj.handlepoint[acrossDataIndex][1]
          ) === THREE_POINT_STATUS_TYPE.BYTIME;
        if (
          !IsArcRangePoint(
            maxDataBottomPoints[nearIndex],
            obj.streetacrossdata[acrossDataIndex][1],
            circle,
            isBytime,
            nowPoint
          )
        ) {
          return workData.getObject(obj.operateid);
        }
      }
    }
  } else {
    if (line === beginLine) {
      let circle = FindCircle(maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]);
      if (IsZero(circle[2])) {
        nowPoint = TowPointVerticalAcross(maxTopPoints[beginIndex], maxTopPoints[centerIndex], point);
        let pointRange = GetTowPointRange(maxTopPoints[nearIndex], obj.handlepoint[acrossDataIndex][1]);
        if (!IsPointInRange1(nowPoint, pointRange)) {
          return workData.getObject(obj.operateid);
        }
      } else {
        nowPoint = FindCircleLinePoint(circle, point);
        let isBytime =
          ByTime(
            maxTopPoints[nearIndex],
            obj.handlepoint[acrossDataIndex][pointIndex],
            obj.handlepoint[acrossDataIndex][1]
          ) === THREE_POINT_STATUS_TYPE.BYTIME;
        if (
          !IsArcRangePoint(maxTopPoints[nearIndex], obj.handlepoint[acrossDataIndex][1], circle, isBytime, nowPoint)
        ) {
          return workData.getObject(obj.operateid);
        }
      }
    } else {
      let cirlce = FindCircle(maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]);
      if (IsZero(cirlce[2])) {
        nowPoint = TowPointVerticalAcross(maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], point);
        let pointRange = GetTowPointRange(maxBottomPoints[nearIndex], obj.handlepoint[acrossDataIndex][1]);
        if (!IsPointInRange1(nowPoint, pointRange)) {
          return workData.getObject(obj.operateid);
        }
      } else {
        nowPoint = FindCircleLinePoint(cirlce, point);
        let isBytime =
          ByTime(
            maxBottomPoints[nearIndex],
            obj.handlepoint[acrossDataIndex][pointIndex],
            obj.handlepoint[acrossDataIndex][1]
          ) === THREE_POINT_STATUS_TYPE.BYTIME;
        if (
          !IsArcRangePoint(maxBottomPoints[nearIndex], obj.handlepoint[acrossDataIndex][1], cirlce, isBytime, nowPoint)
        ) {
          return workData.getObject(obj.operateid);
        }
      }
    }
  }
  CopyPoint(obj.handlepoint[acrossDataIndex][pointIndex], nowPoint);
  obj.handlepoint[acrossDataIndex][pointIndex][2] = LengthBetweenPoints(nowPoint, obj.handlepoint[acrossDataIndex][1]);
  return obj;
}

/**
 * 平移 (translation)
 * @param {number[][]} handlepoint
 * @param {number[]} moveBeginPoint
 * @param {number[]} endPoint
 * @return {number[][]}
 */
export function TransformMoveObject(handlepoint, moveBeginPoint, endPoint) {
  // console.log('transform move');
  var afterHandlePoints = [];
  afterHandlePoints = handlepoint.concat();
  var moveX = endPoint[0] - moveBeginPoint[0];
  var moveY = endPoint[1] - moveBeginPoint[1];
  for (let i = 0, il = afterHandlePoints.length; i < il; i++) {
    let afterPoint = MovePoint(afterHandlePoints[i], moveX, moveY);
    CopyPoint(afterHandlePoints[i], afterPoint);
  }

  return afterHandlePoints;
}

/**
 *  平移segment
 * @param {{ptStart:{x: number, y: number}, ptStop:{x: number, y: number}, type:string}[]} segments
 * @param {number} moveY
 * @param {number} moveX
 */
function TransformMoveSegments(segments, moveX, moveY) {
  segments.forEach(segment => {
    [segment.ptStart.x, segment.ptStart.y] = MovePoint([segment.ptStart.x, segment.ptStart.y], moveX, moveY);
    [segment.ptStop.x, segment.ptStop.y] = MovePoint([segment.ptStop.x, segment.ptStop.y], moveX, moveY);
  });
}

/**
 * 平移group
 * @param {*} obj
 * @param {number[]} moveBeginPoint
 * @param {number[]} endPoint
 * @returns {*} - obj
 */
export function TransformMoveGroup(obj, moveBeginPoint, endPoint) {
  obj.handlepoint = TransformMoveObject(obj.handlepoint, moveBeginPoint, endPoint);
  const moveX = endPoint[0] - moveBeginPoint[0];
  const moveY = endPoint[1] - moveBeginPoint[1];
  for (let i = 0, il = obj.groupdata.length; i < il; i++) {
    // console.log(obj.groupdata[i]);
    obj.groupdata[i].handlepoint = TransformMoveObject(obj.groupdata[i].handlepoint, moveBeginPoint, endPoint);
    if (obj.groupdata[i].segments && obj.groupdata[i].segments.length > 0) {
      TransformMoveSegments(obj.groupdata[i].segments, moveX, moveY);
    }
    if (obj.groupdata[i].components && obj.groupdata[i].components.length > 0) {
      obj.groupdata[i].components.forEach(component => {
        if (component.type === 'stripe') {
          let stripe = component;
          if (Utility.isNonEmptyArray(stripe.offsetArcSegments)) {
            for (let j = 0, jl = stripe.offsetArcSegments.length; j < jl; j++) {
              if (stripe.offsetArcSegments[j].type == 'line') {
                let pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStart.x, stripe.offsetArcSegments[j].ptStart.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStart = { x: pt[0], y: pt[1] };
                pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStop.x, stripe.offsetArcSegments[j].ptStop.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStop = { x: pt[0], y: pt[1] };
              } else if (stripe.offsetArcSegments[j].type == 'arc') {
                let pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStart.x, stripe.offsetArcSegments[j].ptStart.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStart = { x: pt[0], y: pt[1] };
                pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStop.x, stripe.offsetArcSegments[j].ptStop.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStop = { x: pt[0], y: pt[1] };
              }
            }
          }
          if (Utility.isNonEmptyArray(stripe.segments)) {
            TransformMoveSegments(stripe.segments, moveX, moveY);
          }
        }
      });
    }
    if (obj.groupdata[i].text && obj.groupdata[i].text.point && obj.groupdata[i].text.point.length > 0) {
      obj.groupdata[i].text.point = MovePoint(obj.groupdata[i].text.point, moveX, moveY);
    }
    if (obj.groupdata[i].ptApex && obj.groupdata[i].ptApex.length > 0) {
      obj.groupdata[i].ptApex = MovePoint(obj.groupdata[i].ptApex, moveX, moveY);
    }
    if (obj.groupdata[i].position) {
      const { position } = obj.groupdata[i];
      if (position.x1 && position.y1) {
        [position.x1, position.y1] = MovePoint([position.x2, position.y2], moveX, moveY);
      }
      if (position.x2 && position.y2) {
        [position.x2, position.y2] = MovePoint([position.x2, position.y2], moveX, moveY);
      }
    }
  }
  return obj;
}

/**
 * get crosswalks which snap to given street
 * @param {string} streetId work data operation id of the street
 */
export function getAttachedCrosswalks(streetId) {
  return _.filter(
    workData.getUseData(),
    obj => obj.functype === FUNCTION_TYPE.CROSSWALK && obj.snapToStreet && obj.streetId === streetId
  );
}

// TODO: need refactor
/**
 * 平移图形
 * @param {object} obj
 * @param {number[]} moveBeginPoint
 * @param {number[]} stopPoint
 * @returns {object} - useData item
 */
export function TransformMoveShapes(obj, moveBeginPoint, stopPoint) {
  let endPoint = devicePtToUserPt(...stopPoint);
  const moveX = endPoint[0] - moveBeginPoint[0];
  const moveY = endPoint[1] - moveBeginPoint[1];
  if (obj.functype === FUNCTION_TYPE.STREETACROSS) return obj;
  if (obj.functype != FUNCTION_TYPE.GROUP) {
    if (obj.functype === FUNCTION_TYPE.STREETNEW) {
      for (let j = 0, jl = obj.segments.length; j < jl; j++) {
        if (obj.segments[j].type == 'line') {
          let pt = MovePoint([obj.segments[j].ptStart.x, obj.segments[j].ptStart.y], moveX, moveY);
          obj.segments[j].ptStart = { x: pt[0], y: pt[1] };
          pt = MovePoint([obj.segments[j].ptStop.x, obj.segments[j].ptStop.y], moveX, moveY);
          obj.segments[j].ptStop = { x: pt[0], y: pt[1] };
        } else if (obj.segments[j].type == 'arc') {
          let pt = MovePoint([obj.segments[j].ptStart.x, obj.segments[j].ptStart.y], moveX, moveY);
          obj.segments[j].ptStart = { x: pt[0], y: pt[1] };
          pt = MovePoint([obj.segments[j].ptStop.x, obj.segments[j].ptStop.y], moveX, moveY);
          obj.segments[j].ptStop = { x: pt[0], y: pt[1] };
        }
      }
      if (obj.offsetArcPath) {
        for (let j = 0; j < obj.offsetArcPath.segments.length; j++) {
          if (obj.offsetArcPath.segments[j].type == 'line') {
            let pt = MovePoint(
              [obj.offsetArcPath.segments[j].ptStart.x, obj.offsetArcPath.segments[j].ptStart.y],
              moveX,
              moveY
            );
            obj.offsetArcPath.segments[j].ptStart = { x: pt[0], y: pt[1] };
            pt = MovePoint(
              [obj.offsetArcPath.segments[j].ptStop.x, obj.offsetArcPath.segments[j].ptStop.y],
              moveX,
              moveY
            );
            obj.offsetArcPath.segments[j].ptStop = { x: pt[0], y: pt[1] };
          } else if (obj.offsetArcPath.segments[j].type == 'arc') {
            let pt = MovePoint(
              [obj.offsetArcPath.segments[j].ptStart.x, obj.offsetArcPath.segments[j].ptStart.y],
              moveX,
              moveY
            );
            obj.offsetArcPath.segments[j].ptStart = { x: pt[0], y: pt[1] };
            pt = MovePoint(
              [obj.offsetArcPath.segments[j].ptStop.x, obj.offsetArcPath.segments[j].ptStop.y],
              moveX,
              moveY
            );
            obj.offsetArcPath.segments[j].ptStop = { x: pt[0], y: pt[1] };
          }
        }
      }
      for (let i = 0, il = obj.components.length; i < il; i++) {
        if (obj.components[i].type === 'stripe') {
          let stripe = obj.components[i];
          if (Utility.isNonEmptyArray(stripe.offsetArcSegments)) {
            for (let j = 0, jl = stripe.offsetArcSegments.length; j < jl; j++) {
              if (stripe.offsetArcSegments[j].type == 'line') {
                let pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStart.x, stripe.offsetArcSegments[j].ptStart.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStart = { x: pt[0], y: pt[1] };
                pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStop.x, stripe.offsetArcSegments[j].ptStop.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStop = { x: pt[0], y: pt[1] };
              } else if (stripe.offsetArcSegments[j].type == 'arc') {
                let pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStart.x, stripe.offsetArcSegments[j].ptStart.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStart = { x: pt[0], y: pt[1] };
                pt = MovePoint(
                  [stripe.offsetArcSegments[j].ptStop.x, stripe.offsetArcSegments[j].ptStop.y],
                  moveX,
                  moveY
                );
                stripe.offsetArcSegments[j].ptStop = { x: pt[0], y: pt[1] };
              }
            }
          }
          if (Utility.isNonEmptyArray(stripe.segments)) {
            TransformMoveSegments(stripe.segments, moveX, moveY);
          }
        }
      }

      // TODO: we'll use more complicated logic to decide the text position of street
      if (obj.text.point.length > 0) {
        obj.text.point = MovePoint(obj.text.point, moveX, moveY);
      }

      let _s = new _Street();
      let lw = _s.alignComponentsToStreetAxis(obj);
      _s.computeStreets(obj);

      const crosswalks = getAttachedCrosswalks(obj.operateid);
      crosswalks.forEach(cw => (cw.handlepoint = TransformMoveObject(cw.handlepoint, moveBeginPoint, endPoint)));
      return obj;
    }
    obj.handlepoint = TransformMoveObject(obj.handlepoint, moveBeginPoint, endPoint);
    if (obj.functype === FUNCTION_TYPE.STREET) {
      if (obj.text.point.length != 0) {
        obj.text.point = MovePoint([obj.text.point[0], obj.text.point[1]], moveX, moveY);
      }
    } else if (obj.functype === FUNCTION_TYPE.STATIONLINE || obj.functype === FUNCTION_TYPE.XYMEASUREMENT) {
      var pt = MovePoint([obj.position.x1, obj.position.y1], moveX, moveY);
      obj.position.x1 = pt[0];
      obj.position.y1 = pt[1];
    } else if (obj.functype === FUNCTION_TYPE.STRUCTURE) {
      //structure door move
      let anchors = obj.handlepoint;
      for (let i = 0; i < anchors.length; i++) {
        if (anchors[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
          for (let j = 4; j < anchors[i].length; j++) {
            //用于门选择拖动的时候显示
            let doorMoveEndPoint = MovePoint([anchors[i][j].x, anchors[i][j].y], moveX, moveY);
            anchors[i][j].x = doorMoveEndPoint[0];
            anchors[i][j].y = doorMoveEndPoint[1];
          }
        }
      }
    } else if (obj.functype === FUNCTION_TYPE.SYMBOL) {
      // console.log('transform move shapes and symbol');
      if (obj.shadow.isActiveShadowMode) {
        updateTrack(stopPoint);
      }
    }
  } else {
    obj = TransformMoveGroup(obj, moveBeginPoint, endPoint);
  }
  return obj;
}

//connectshape对象半
/**
 *
 * @param {*} obj
 * @param {*} index
 * @param {*} point
 */
export function TransformCounterBeginObject(obj, index, point) {
  //先旧
  let height = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[2]);
  // 后新
  let angle = getAngleBy2Points(obj.handlepoint[6], point);
  let width = LengthBetweenPoints(point, obj.handlepoint[6]);
  var origin = obj.handlepoint[6];
  obj.handlepoint[0] = [origin[0] + width, origin[1], ANCHORS_TYPE.COUNTERBEIGIN];
  obj.handlepoint[1] = [origin[0] + width, origin[1] + height / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[2] = [origin[0] + width, origin[1] - height / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[3] = [origin[0] + width / 2, origin[1], ANCHORS_TYPE.COUNTERMIDDLE];
  obj.handlepoint[4] = [origin[0] + width / 2, origin[1] + height / 2, ANCHORS_TYPE.COUNTERTHREE];
  obj.handlepoint[5] = [origin[0] + width / 2, origin[1] - height / 2, ANCHORS_TYPE.COUNTERTHREE];
  obj.handlepoint[7] = [origin[0], origin[1] + height / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[8] = [origin[0], origin[1] - height / 2, ANCHORS_TYPE.COUNTERHELP];
  for (let i = 0; i <= 8; i++) {
    var tempPoint = RotatePoint(obj.handlepoint[i], angle, origin);
    obj.handlepoint[i][0] = tempPoint[0];
    obj.handlepoint[i][1] = tempPoint[1];
  }
  return obj;
}

//connectshape对象半
export function TransformCounterEndObject(obj, index, point) {
  //先旧
  let oldAngle = GetCLoseShapeAngle(obj.handlepoint);
  let height = LengthBetweenPoints(obj.handlepoint[index + 1], obj.handlepoint[index + 2]);
  // 后新
  var origin = obj.handlepoint[index - 8];
  let angle = getAngleBy2Points(origin, point);
  let width = LengthBetweenPoints(point, origin);
  obj.handlepoint[index + 2] = [origin[0] + width, origin[1] + height / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index + 1] = [origin[0] + width, origin[1] - height / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index] = [origin[0] + width, origin[1], ANCHORS_TYPE.COUNTEREND];
  obj.handlepoint[index - 1] = [origin[0] + width / 2, origin[1] + height / 2, ANCHORS_TYPE.COUNTERTHREE];
  obj.handlepoint[index - 2] = [origin[0] + width / 2, origin[1] - height / 2, ANCHORS_TYPE.COUNTERTHREE];
  obj.handlepoint[index - 3] = [origin[0] + width / 2, origin[1], ANCHORS_TYPE.COUNTERMIDDLE];
  obj.handlepoint[index - 4] = [origin[0], origin[1] + height / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index - 5] = [origin[0], origin[1] - height / 2, ANCHORS_TYPE.COUNTERHELP];
  for (let i = index - 5; i <= index + 2; i++) {
    var tempPoint = RotatePoint(obj.handlepoint[i], angle, origin);
    obj.handlepoint[i][0] = tempPoint[0];
    obj.handlepoint[i][1] = tempPoint[1];
  }
  obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, oldAngle);
  return obj;
}

//connectshape对象半
export function TransformCounterNormalObject(obj, index, point) {
  let tempPoint;
  //先旧
  let oldAngle = GetCLoseShapeAngle(obj.handlepoint);
  let heightLeft = LengthBetweenPoints(obj.handlepoint[index + 1], obj.handlepoint[index + 2]);
  let heightRight = LengthBetweenPoints(obj.handlepoint[index + 3], obj.handlepoint[index + 4]);
  // 后新
  //左边
  var originLeftIndex = index - 8;
  if (originLeftIndex < 0) originLeftIndex = 0;
  var originLeft = obj.handlepoint[originLeftIndex];
  let angleLeft = getAngleBy2Points(originLeft, point);
  let widthLeft = LengthBetweenPoints(point, originLeft);
  obj.handlepoint[index + 2] = [originLeft[0] + widthLeft, originLeft[1] + heightLeft / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index + 1] = [originLeft[0] + widthLeft, originLeft[1] - heightLeft / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index] = [originLeft[0] + widthLeft, originLeft[1], ANCHORS_TYPE.COUNTERNORMAL];
  obj.handlepoint[index - 1] = [
    originLeft[0] + widthLeft / 2,
    originLeft[1] + heightLeft / 2,
    ANCHORS_TYPE.COUNTERTHREE,
  ];
  obj.handlepoint[index - 2] = [
    originLeft[0] + widthLeft / 2,
    originLeft[1] - heightLeft / 2,
    ANCHORS_TYPE.COUNTERTHREE,
  ];
  obj.handlepoint[index - 3] = [originLeft[0] + widthLeft / 2, originLeft[1], ANCHORS_TYPE.COUNTERMIDDLE];
  obj.handlepoint[index - 4] = [originLeft[0], originLeft[1] + heightLeft / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index - 5] = [originLeft[0], originLeft[1] - heightLeft / 2, ANCHORS_TYPE.COUNTERHELP];
  for (let i = index - 5; i <= index + 2; i++) {
    tempPoint = RotatePoint(obj.handlepoint[i], angleLeft, originLeft);
    obj.handlepoint[i][0] = tempPoint[0];
    obj.handlepoint[i][1] = tempPoint[1];
  }
  //右边
  var originRight = obj.handlepoint[index + 8];
  let angleRight = getAngleBy2Points(originRight, point);
  let widthRight = LengthBetweenPoints(point, originRight);
  obj.handlepoint[index + 10] = [originRight[0], originRight[1] - heightRight / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index + 9] = [originRight[0], originRight[1] + heightRight / 2, ANCHORS_TYPE.COUNTERHELP];
  obj.handlepoint[index + 7] = [
    originRight[0] + widthRight / 2,
    originRight[1] - heightRight / 2,
    ANCHORS_TYPE.COUNTERTHREE,
  ];
  obj.handlepoint[index + 6] = [
    originRight[0] + widthRight / 2,
    originRight[1] + heightRight / 2,
    ANCHORS_TYPE.COUNTERTHREE,
  ];
  obj.handlepoint[index + 5] = [originRight[0] + widthRight / 2, originRight[1], ANCHORS_TYPE.COUNTERMIDDLE];
  obj.handlepoint[index + 4] = [
    originRight[0] + widthRight,
    originRight[1] - heightRight / 2,
    ANCHORS_TYPE.COUNTERHELP,
  ];
  obj.handlepoint[index + 3] = [
    originRight[0] + widthRight,
    originRight[1] + heightRight / 2,
    ANCHORS_TYPE.COUNTERHELP,
  ];
  for (let i = index + 3; i <= index + 10; i++) {
    tempPoint = RotatePoint(obj.handlepoint[i], angleRight, originRight);
    obj.handlepoint[i][0] = tempPoint[0];
    obj.handlepoint[i][1] = tempPoint[1];
  }
  obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, oldAngle);
  return obj;
}

//connectshape对象半
export function TransformCounterMiddleObject(obj, index, point) {
  const min = COUNTER_TOP_PARAM_TYPE.COUNTERMINMIDDLESPACE;
  const max = COUNTER_TOP_PARAM_TYPE.COUNTERMAXMIDDLESPCACE;
  //先旧
  let oldAngle = GetCLoseShapeAngle(obj.handlepoint);
  let origin = obj.handlepoint[index];
  let height = 0;
  var moveEndPoint = [];
  let center45;
  let tempPoint0;
  let tempPoint6;
  if (index % 8 == 4) {
    //top
    if (
      IsUnderLine(obj.handlepoint[index - 2], obj.handlepoint[index + 4], point) ==
      IsUnderLine(obj.handlepoint[index - 2], obj.handlepoint[index + 4], origin)
    ) {
      height = TowPointDestPointLength(obj.handlepoint[index - 2], obj.handlepoint[index + 4], point);
    } else height = min;
    if (height > max) height = max;
    moveEndPoint = TowPointVerticalLength(
      obj.handlepoint[index - 2],
      obj.handlepoint[index + 4],
      origin,
      height,
      origin
    );
    let moveMatrix = [1, 0, 0, 1, moveEndPoint[0] - origin[0], moveEndPoint[1] - origin[1]];
    let point1 = pointTransform(moveMatrix, origin);
    obj.handlepoint[index][0] = point1[0];
    obj.handlepoint[index][1] = point1[1];
    let point2 = pointTransform(moveMatrix, obj.handlepoint[index - 3]);
    obj.handlepoint[index - 3][0] = point2[0];
    obj.handlepoint[index - 3][1] = point2[1];
    let point3 = pointTransform(moveMatrix, obj.handlepoint[index + 3]);
    obj.handlepoint[index + 3][0] = point3[0];
    obj.handlepoint[index + 3][1] = point3[1];
    center45 = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[index + 1]);
    obj.handlepoint[index - 1][0] = center45[0];
    obj.handlepoint[index - 1][1] = center45[1];
    let point0 = GetCenterInTwoPoints(obj.handlepoint[index - 3], obj.handlepoint[index - 2]);
    if (index == 4) {
      obj.handlepoint[0][0] = point0[0];
      obj.handlepoint[0][1] = point0[1];
    } else {
      tempPoint0 = TowLineAcrossPoint(
        [obj.handlepoint[index - 12], obj.handlepoint[index - 9]],
        [point0, obj.handlepoint[index - 1]]
      );
      obj.handlepoint[index - 6][0] = tempPoint0[0];
      obj.handlepoint[index - 6][1] = tempPoint0[1];
    }
    let point6 = GetCenterInTwoPoints(obj.handlepoint[index + 3], obj.handlepoint[index + 4]);
    if (obj.handlepoint[index + 2][2] === ANCHORS_TYPE.COUNTEREND) {
      obj.handlepoint[index + 2][0] = point6[0];
      obj.handlepoint[index + 2][1] = point6[1];
    } else {
      tempPoint6 = TowLineAcrossPoint([center45, point6], [obj.handlepoint[index + 7], obj.handlepoint[index + 10]]);
      obj.handlepoint[index + 2][0] = tempPoint6[0];
      obj.handlepoint[index + 2][1] = tempPoint6[1];
    }
  } else {
    //bottom
    height = TowPointDestPointLength(obj.handlepoint[index - 4], obj.handlepoint[index + 2], point);
    if (
      IsUnderLine(obj.handlepoint[index - 4], obj.handlepoint[index + 2], point) ==
      IsUnderLine(obj.handlepoint[index - 4], obj.handlepoint[index + 2], origin)
    ) {
      height = TowPointDestPointLength(obj.handlepoint[index - 4], obj.handlepoint[index + 2], point);
    } else height = min;
    if (height > max) height = max;
    moveEndPoint = TowPointVerticalLength(
      obj.handlepoint[index - 4],
      obj.handlepoint[index + 2],
      origin,
      height,
      origin
    );
    let moveMatrix = [1, 0, 0, 1, moveEndPoint[0] - origin[0], moveEndPoint[1] - origin[1]];
    let point1 = pointTransform(moveMatrix, origin);
    obj.handlepoint[index][0] = point1[0];
    obj.handlepoint[index][1] = point1[1];
    let point2 = pointTransform(moveMatrix, obj.handlepoint[index - 3]);
    obj.handlepoint[index - 3][0] = point2[0];
    obj.handlepoint[index - 3][1] = point2[1];
    let point3 = pointTransform(moveMatrix, obj.handlepoint[index + 3]);
    obj.handlepoint[index + 3][0] = point3[0];
    obj.handlepoint[index + 3][1] = point3[1];
    center45 = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[index - 1]);
    obj.handlepoint[index - 2][0] = center45[0];
    obj.handlepoint[index - 2][1] = center45[1];
    let point0 = GetCenterInTwoPoints(obj.handlepoint[index - 4], obj.handlepoint[index - 3]);
    if (index == 5) {
      obj.handlepoint[0][0] = point0[0];
      obj.handlepoint[0][1] = point0[1];
    } else {
      tempPoint0 = TowLineAcrossPoint(
        [obj.handlepoint[index - 13], obj.handlepoint[index - 10]],
        [point0, obj.handlepoint[index - 2]]
      );
      obj.handlepoint[index - 7][0] = tempPoint0[0];
      obj.handlepoint[index - 7][1] = tempPoint0[1];
    }
    let point6 = GetCenterInTwoPoints(obj.handlepoint[index + 2], obj.handlepoint[index + 3]);
    if (obj.handlepoint[index + 1][2] === ANCHORS_TYPE.COUNTEREND) {
      obj.handlepoint[index + 1][0] = point6[0];
      obj.handlepoint[index + 1][1] = point6[1];
    } else {
      tempPoint6 = TowLineAcrossPoint([center45, point6], [obj.handlepoint[index + 6], obj.handlepoint[index + 9]]);
      obj.handlepoint[index + 1][0] = tempPoint6[0];
      obj.handlepoint[index + 1][1] = tempPoint6[1];
    }
  }
  obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, oldAngle);
  return obj;
}

//connectshape对象半
export function TransformCounterMoveLeftObject(obj, index, point, capturePoint) {
  let oldAngle = GetCLoseShapeAngle(obj.handlepoint);
  let moveBegin = [0, 0],
    moveEndPoint = [0, 0];
  if (index == 6) {
    moveBegin = TowPointVerticalAcross(obj.handlepoint[0], obj.handlepoint[6], capturePoint);
    moveEndPoint = TowPointVerticalAcross(obj.handlepoint[0], obj.handlepoint[6], point);
  } else {
    moveBegin = TowPointVerticalAcross(obj.handlepoint[index - 8], obj.handlepoint[index], capturePoint);
    moveEndPoint = TowPointVerticalAcross(obj.handlepoint[index - 8], obj.handlepoint[index], point);
  }
  let workObj = workData.getObject(obj.operateid);
  if (
    IsUnderLine(workObj.handlepoint[index - 2], workObj.handlepoint[index - 1], workObj.handlepoint[index]) !=
    IsUnderLine(workObj.handlepoint[index - 2], workObj.handlepoint[index - 1], point)
  ) {
    CopyPoint(moveEndPoint, workObj.handlepoint[index - 3]);
  }
  let matrixMove = GetMoveMatrix(moveBegin, moveEndPoint);
  for (let i = index; i < obj.handlepoint.length; i++) {
    let tempPoint = pointTransform(matrixMove, obj.handlepoint[i]);
    CopyPoint(obj.handlepoint[i], tempPoint);
  }
  if (index == 6) {
    let point4 = GetCenterInTwoPoints(obj.handlepoint[1], obj.handlepoint[7]);
    CopyPoint(obj.handlepoint[4], point4);
    let point3 = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[6]);
    CopyPoint(obj.handlepoint[3], point3);
    let point5 = GetCenterInTwoPoints(obj.handlepoint[2], obj.handlepoint[8]);
    CopyPoint(obj.handlepoint[5], point5);
  } else {
    let point4 = GetCenterInTwoPoints(obj.handlepoint[index - 5], obj.handlepoint[index + 1]);
    CopyPoint(obj.handlepoint[index - 2], point4);
    let point3 = GetCenterInTwoPoints(obj.handlepoint[index - 8], obj.handlepoint[index]);
    CopyPoint(obj.handlepoint[index - 3], point3);
    let point5 = GetCenterInTwoPoints(obj.handlepoint[index - 4], obj.handlepoint[index + 2]);
    CopyPoint(obj.handlepoint[index - 1], point5);
  }
  obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, oldAngle);
  return obj;
}

//connectshape对象半
export function TransformCounterMoveRightObject(obj, index, point, capturePoint) {
  let oldAngle = GetCLoseShapeAngle(obj.handlepoint);
  let moveBegin = [0, 0],
    moveEndPoint = [0, 0];
  moveBegin = TowPointVerticalAcross(obj.handlepoint[index + 8], obj.handlepoint[index], capturePoint);
  moveEndPoint = TowPointVerticalAcross(obj.handlepoint[index + 8], obj.handlepoint[index], point);

  let workObj = workData.getObject(obj.operateid);
  if (
    IsUnderLine(workObj.handlepoint[index + 6], workObj.handlepoint[index + 7], workObj.handlepoint[index]) !=
    IsUnderLine(workObj.handlepoint[index + 6], workObj.handlepoint[index + 7], point)
  ) {
    CopyPoint(moveEndPoint, workObj.handlepoint[index + 5]);
  }
  let matrixMove = GetMoveMatrix(moveBegin, moveEndPoint);
  for (let i = 0; i <= index + 4; i++) {
    let tempPoint = pointTransform(matrixMove, obj.handlepoint[i]);
    CopyPoint(obj.handlepoint[i], tempPoint);
  }
  let point4 = GetCenterInTwoPoints(obj.handlepoint[index + 3], obj.handlepoint[index + 9]);
  CopyPoint(obj.handlepoint[index + 6], point4);
  let point3 = GetCenterInTwoPoints(obj.handlepoint[index + 8], obj.handlepoint[index]);
  CopyPoint(obj.handlepoint[index + 5], point3);
  let point5 = GetCenterInTwoPoints(obj.handlepoint[index + 4], obj.handlepoint[index + 10]);
  CopyPoint(obj.handlepoint[index + 7], point5);
  obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, oldAngle);
  return obj;
}

/**
 * connectshape对象半
 * @param {*} obj
 * @param {number} index
 * @param {[number, number]} point
 * @returns {*} obj
 */
export function TransformConnectedBeginObject(obj, index, point) {
  //先旧
  var length = TowPointDestPointLength(obj.handlepoint[index], obj.handlepoint[index + 2], obj.handlepoint[index + 1]);
  var oldCloseShapeAngle = 0,
    oldLastLength = 0,
    iCount = obj.handlepoint.length;
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    oldCloseShapeAngle = GetCLoseShapeAngle(obj.handlepoint);
    oldLastLength = TowPointDestPointLength(
      obj.handlepoint[index],
      obj.handlepoint[iCount - 3],
      obj.handlepoint[iCount - 2]
    );
  }
  //后新
  obj.handlepoint[index][0] = point[0];
  obj.handlepoint[index][1] = point[1];
  var center = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[index + 2]);
  var workObj = workData.getObject(obj.operateid);
  var pointCenter = TowPointVerticalLength(
    obj.handlepoint[index],
    obj.handlepoint[index + 2],
    center,
    length,
    workObj.handlepoint[index + 1]
  );
  obj.handlepoint[index + 1][0] = pointCenter[0];
  obj.handlepoint[index + 1][1] = pointCenter[1];
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    var centerLast = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[iCount - 3]);
    var pointLastCenter = TowPointVerticalLength(
      obj.handlepoint[0],
      obj.handlepoint[iCount - 3],
      obj.handlepoint[iCount - 2],
      oldLastLength,
      workObj.handlepoint[iCount - 2]
    );
    obj.handlepoint[iCount - 2] = [pointLastCenter[0], pointLastCenter[1], ANCHORS_TYPE.CONNECTCANGLE];
    obj.handlepoint[iCount - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, oldCloseShapeAngle);
  }
  return obj;
}

/**
 * connectshape对象半
 * @param {*} obj
 * @param {number} index
 * @param {[number, number]} point
 * @returns {*} obj
 */
export function TransformConnectedEndObject(obj, index, point) {
  var leftIndex = index - 2;
  var rightIndex = index + 2;
  if (rightIndex >= obj.handlepoint.length - 1) {
    //最后一个点肯定超出为0
    rightIndex = 0;
  }
  //先旧
  var lengthLeft = TowPointDestPointLength(
    obj.handlepoint[index],
    obj.handlepoint[leftIndex],
    obj.handlepoint[index - 1]
  );
  var lengthRight = TowPointDestPointLength(
    obj.handlepoint[index],
    obj.handlepoint[rightIndex],
    obj.handlepoint[index + 1]
  );
  var shapeAngle = 0;
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    shapeAngle = GetCLoseShapeAngle(obj.handlepoint);
  }
  //后新
  obj.handlepoint[index][0] = point[0];
  obj.handlepoint[index][1] = point[1];
  var centerLeft = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[leftIndex]);
  var centerRight = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[rightIndex]);

  var workObj = workData.getObject(obj.operateid);
  var pointCenterLeft = TowPointVerticalLength(
    obj.handlepoint[index],
    obj.handlepoint[leftIndex],
    centerLeft,
    lengthLeft,
    workObj.handlepoint[index - 1]
  );
  var pointCenterRight = TowPointVerticalLength(
    obj.handlepoint[index],
    obj.handlepoint[rightIndex],
    centerRight,
    lengthRight,
    workObj.handlepoint[index + 1]
  );
  obj.handlepoint[index - 1][0] = pointCenterLeft[0];
  obj.handlepoint[index - 1][1] = pointCenterLeft[1];
  obj.handlepoint[index + 1][0] = pointCenterRight[0];
  obj.handlepoint[index + 1][1] = pointCenterRight[1];
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, shapeAngle);
  }
  return obj;
}

/**
 * connectshape对象半
 * @param {*} obj
 * @param {number} index
 * @param {[number, number]} point
 * @returns {*} obj
 */
export function TransformConnectedNormalObject(obj, index, point) {
  // console.log('index', index);
  // console.log('handlepoint', obj.handlepoint);
  //先旧
  var lengthLeft = TowPointDestPointLength(
    obj.handlepoint[index],
    obj.handlepoint[index - 2],
    obj.handlepoint[index - 1]
  );
  var lengthRight = TowPointDestPointLength(
    obj.handlepoint[index],
    obj.handlepoint[index + 2],
    obj.handlepoint[index + 1]
  );
  var shapeAngle = 0;
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    shapeAngle = GetCLoseShapeAngle(obj.handlepoint);
  }
  //后新
  obj.handlepoint[index][0] = point[0];
  obj.handlepoint[index][1] = point[1];
  var centerLeft = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[index - 2]);
  var centerRight = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[index + 2]);

  var workObj = workData.getObject(obj.operateid);
  var pointCenterLeft = TowPointVerticalLength(
    obj.handlepoint[index],
    obj.handlepoint[index - 2],
    centerLeft,
    lengthLeft,
    workObj.handlepoint[index - 1]
  );
  var pointCenterRight = TowPointVerticalLength(
    obj.handlepoint[index],
    obj.handlepoint[index + 2],
    centerRight,
    lengthRight,
    workObj.handlepoint[index + 1]
  );
  obj.handlepoint[index - 1][0] = pointCenterLeft[0];
  obj.handlepoint[index - 1][1] = pointCenterLeft[1];
  obj.handlepoint[index + 1][0] = pointCenterRight[0];
  obj.handlepoint[index + 1][1] = pointCenterRight[1];
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, shapeAngle);
  }
  return obj;
}

/**
 * connectshape对象半
 * @param {*} obj
 * @param {number} index
 * @param {[number, number]} point
 * @returns {*} obj
 */
export function TransformConnectedAngleObject(obj, index, point) {
  var leftIndex = index - 1;
  var rightIndex = index + 1;
  if (rightIndex >= obj.handlepoint.length - 1) {
    rightIndex = 0;
  }
  //先算要多长
  var length = TowPointDestPointLength(obj.handlepoint[leftIndex], obj.handlepoint[rightIndex], point);
  var shapeAngle = 0;
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    shapeAngle = GetCLoseShapeAngle(obj.handlepoint);
  }
  //后新
  var center = GetCenterInTwoPoints(obj.handlepoint[leftIndex], obj.handlepoint[rightIndex]);
  var centerPoint = TowPointVerticalLength(
    obj.handlepoint[leftIndex],
    obj.handlepoint[rightIndex],
    center,
    length,
    point
  );
  obj.handlepoint[index][0] = centerPoint[0];
  obj.handlepoint[index][1] = centerPoint[1];
  if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
    obj.handlepoint[obj.handlepoint.length - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, shapeAngle);
  }
  return obj;
}

export function TransformCloseShapeAngleObject(obj, point) {
  var oldAngle = GetCLoseShapeAngle(obj.handlepoint);
  var center = GetCloseShapeCenter(obj.handlepoint);
  var angle = getAngleBy2Points(center, point);
  for (let i = 0; i < obj.handlepoint.length; i++) {
    var tempPoint = RotatePoint(obj.handlepoint[i], angle - oldAngle, center);
    obj.handlepoint[i][0] = tempPoint[0];
    obj.handlepoint[i][1] = tempPoint[1];
  }
  return obj;
}

//Arc对象半径
export function TransformStairsBeginObject(obj, point) {
  if (IsOneLine(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]) === THREE_POINT_STATUS_TYPE.ONLINE) {
    let origin = obj.handlepoint[6];
    let height = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[2]);
    let width = LengthBetweenPoints(point, origin);
    obj.handlepoint[0] = [origin[0] + width, origin[1]];
    obj.handlepoint[1] = [origin[0] + width, origin[1] + height / 2];
    obj.handlepoint[2] = [origin[0] + width, origin[1] - height / 2];
    obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
    obj.handlepoint[4] = [origin[0] + width / 2, origin[1] + height / 2];
    obj.handlepoint[5] = [origin[0] + width / 2, origin[1] - height / 2];
    obj.handlepoint[7] = [origin[0], origin[1] + height / 2];
    obj.handlepoint[8] = [origin[0], origin[1] - height / 2];
    let angle = getAngleBy2Points(origin, point);
    for (let i = 0; i < obj.handlepoint.length; i++) {
      obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
    }
    return obj;
  }
  let length = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[3]);
  let height = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[0]);
  var workObj = workData.getObject(obj.operateid);
  obj.handlepoint[0] = point;
  let center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[6]);
  obj.handlepoint[3] = TowPointVerticalLength(
    obj.handlepoint[0],
    obj.handlepoint[6],
    center,
    length,
    workObj.handlepoint[3]
  );
  let circle = FindCircle(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]);
  let circleOut = [circle[0], circle[1], circle[2] + height];
  let circleInner = [circle[0], circle[1], circle[2] - height];
  let isBigArc =
    IsBigArc(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[3], circle[2]) === CIRCLE_SIZE_TYPE.BIGARC;
  if (ByTime(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]) === THREE_POINT_STATUS_TYPE.BYTIME) {
    //147为远心端则为外园

    obj.handlepoint[1] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleOut[2], isBigArc, true);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleInner[2], isBigArc, true);
  } else {
    obj.handlepoint[1] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleInner[2], isBigArc, false);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleOut[2], isBigArc, false);
  }
  return obj;
}

//Arc对象半径
export function TransformStairsEndObject(obj, point) {
  if (IsOneLine(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]) === THREE_POINT_STATUS_TYPE.ONLINE) {
    let origin = obj.handlepoint[0];
    let height = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[2]);
    let width = LengthBetweenPoints(point, origin);
    obj.handlepoint[1] = [origin[0], origin[1] - height / 2];
    obj.handlepoint[2] = [origin[0], origin[1] + height / 2];
    obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
    obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - height / 2];
    obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + height / 2];
    obj.handlepoint[6] = [origin[0] + width, origin[1]];
    obj.handlepoint[7] = [origin[0] + width, origin[1] - height / 2];
    obj.handlepoint[8] = [origin[0] + width, origin[1] + height / 2];
    let angle = getAngleBy2Points(origin, point);
    for (let i = 0; i < obj.handlepoint.length; i++) {
      obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
    }
    return obj;
  }
  let length = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[3]);
  let height = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[0]);
  var workObj = workData.getObject(obj.operateid);
  obj.handlepoint[6] = point;
  let center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[6]);
  obj.handlepoint[3] = TowPointVerticalLength(
    obj.handlepoint[0],
    obj.handlepoint[6],
    center,
    length,
    workObj.handlepoint[3]
  );
  let circle = FindCircle(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]);
  let circleOut = [circle[0], circle[1], circle[2] + height];
  let circleInner = [circle[0], circle[1], circle[2] - height];
  let isBigArc =
    IsBigArc(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[3], circle[2]) === CIRCLE_SIZE_TYPE.BIGARC;
  if (ByTime(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]) === THREE_POINT_STATUS_TYPE.BYTIME) {
    //147为远心端则为外园

    obj.handlepoint[1] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleOut[2], isBigArc, true);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleInner[2], isBigArc, true);
  } else {
    obj.handlepoint[1] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleInner[2], isBigArc, false);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleOut[2], isBigArc, false);
  }
  return obj;
}

//stair对象半径
export function TransformStairsMiddleObject(obj, point) {
  var center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[6]);
  var length = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], point);
  let height = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[0]);
  let pointStatus = IsUnderLine(obj.handlepoint[0], obj.handlepoint[6], point);
  obj.handlepoint[3] = TowPointVerticalLengthByStatus(
    obj.handlepoint[0],
    obj.handlepoint[6],
    center,
    length,
    pointStatus
  );
  if (IsOneLine(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]) === THREE_POINT_STATUS_TYPE.ONLINE) {
    obj.handlepoint[3] = center;
    let height = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[0]);
    obj.handlepoint[1] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[0],
      height,
      true
    );
    obj.handlepoint[7] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[6],
      height,
      true
    );
    obj.handlepoint[2] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[0],
      height,
      false
    );
    obj.handlepoint[8] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[6],
      height,
      false
    );
    obj.handlepoint[4] = GetCenterInTwoPoints(obj.handlepoint[1], obj.handlepoint[7]);
    obj.handlepoint[5] = GetCenterInTwoPoints(obj.handlepoint[2], obj.handlepoint[8]);
    return obj;
  }
  let circle = FindCircle(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]);
  let circleOut = [circle[0], circle[1], circle[2] + height];
  let circleInner = [circle[0], circle[1], circle[2] - height];
  let isByTIme = ByTime(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]);
  let isBigArc =
    IsBigArc(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[3], circle[2]) === CIRCLE_SIZE_TYPE.BIGARC;
  if (isByTIme === THREE_POINT_STATUS_TYPE.BYTIME) {
    //147为远心端则为外园
    obj.handlepoint[1] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleOut[2], isBigArc, true);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleInner[2], isBigArc, true);
  } else {
    obj.handlepoint[1] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleInner[2], isBigArc, false);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleOut[2], isBigArc, false);
  }
  return obj;
}

//stair对象半径
export function TransformStairsWidthObject(obj, height) {
  var center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[6]);
  if (IsOneLine(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]) === THREE_POINT_STATUS_TYPE.ONLINE) {
    obj.handlepoint[1] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[0],
      height,
      true
    );
    obj.handlepoint[7] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[6],
      height,
      true
    );
    obj.handlepoint[2] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[0],
      height,
      false
    );
    obj.handlepoint[8] = TowPointVerticalLengthByStatus(
      obj.handlepoint[0],
      obj.handlepoint[6],
      obj.handlepoint[6],
      height,
      false
    );
    obj.handlepoint[4] = GetCenterInTwoPoints(obj.handlepoint[1], obj.handlepoint[7]);
    obj.handlepoint[5] = GetCenterInTwoPoints(obj.handlepoint[2], obj.handlepoint[8]);
    return obj;
  }
  let circle = FindCircle(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]);
  let circleOut = [circle[0], circle[1], circle[2] + height / 2];
  let circleInner = [circle[0], circle[1], circle[2] - height / 2];
  let isByTIme = ByTime(obj.handlepoint[0], obj.handlepoint[3], obj.handlepoint[6]);
  let isBigArc =
    IsBigArc(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[3], circle[2]) === CIRCLE_SIZE_TYPE.BIGARC;
  if (isByTIme === THREE_POINT_STATUS_TYPE.BYTIME) {
    //147为远心端则为外园
    obj.handlepoint[1] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleOut[2], isBigArc, true);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleInner[2], isBigArc, true);
  } else {
    obj.handlepoint[1] = FindCircleLinePoint(circleInner, obj.handlepoint[0]);
    obj.handlepoint[7] = FindCircleLinePoint(circleInner, obj.handlepoint[6]);
    obj.handlepoint[2] = FindCircleLinePoint(circleOut, obj.handlepoint[0]);
    obj.handlepoint[8] = FindCircleLinePoint(circleOut, obj.handlepoint[6]);
    obj.handlepoint[4] = GetCircleArcCenter2(obj.handlepoint[1], obj.handlepoint[7], circleInner[2], isBigArc, false);
    obj.handlepoint[5] = GetCircleArcCenter2(obj.handlepoint[2], obj.handlepoint[8], circleOut[2], isBigArc, false);
  }
  return obj;
}

//Arc对象半径
export function TransformArcCenterObject(obj, point) {
  var center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
  var length = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[1], point);
  if (length < diagramManager.getUserUnitsPerPixel() * 7) length = 0;
  if (IsZero(length)) {
    obj.handlepoint[2] = center;
    return obj;
  }
  obj.handlepoint[2] = TowPointVerticalLength(obj.handlepoint[0], obj.handlepoint[1], center, length, point);
  return obj;
}

//Arc对象半径 (Arc object radius)
export function TransformArcBeginObject(obj, point) {
  if (IsOneLine(obj.handlepoint[0], obj.handlepoint[2], obj.handlepoint[1]) === THREE_POINT_STATUS_TYPE.ONLINE) {
    obj.handlepoint[0] = point;
    obj.handlepoint[2] = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
    var length = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
    if (obj.text.islength) {
      length = Math.round((length * 1000 + 0.5) / 1000);
      obj.text.text = Utility.format(length / 12);
    }
    return obj;
  }
  var oldCircle = FindCircle(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]);
  var isBigArc =
    IsBigArc(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2], oldCircle[2]) === CIRCLE_SIZE_TYPE.BIGARC;
  var isShun = ByTime(obj.handlepoint[0], obj.handlepoint[2], obj.handlepoint[1]) === THREE_POINT_STATUS_TYPE.BYTIME;
  var lenth12 = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  var newLenth12 = LengthBetweenPoints(point, obj.handlepoint[1]);
  if (obj.text.islength) {
    newLenth12 = Math.round((newLenth12 * 1000 + 0.5) / 1000);
    obj.text.text = Utility.format(newLenth12 / 12);
  }
  var r = (oldCircle[2] * newLenth12) / lenth12;
  r = toFixed(r, 3);
  obj.handlepoint[0] = point;
  obj.handlepoint[2] = GetCircleArcCenter2(obj.handlepoint[0], obj.handlepoint[1], r, isBigArc, isShun);
  return obj;
}

/**
 * move handle point at the end point of arc/parking stall object
 * @param {*} obj
 * @param {*} point
 */
export function TransformArcEndObject(obj, point) {
  if (IsOneLine(obj.handlepoint[0], obj.handlepoint[2], obj.handlepoint[1]) === THREE_POINT_STATUS_TYPE.ONLINE) {
    obj.handlepoint[1] = point;
    obj.handlepoint[2] = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
    var length = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
    if (obj.text.islength) {
      length = Math.round((length * 1000 + 0.5) / 1000);
      obj.text.text = Utility.format(length / 12);
    }
    return obj;
  }
  var oldCircle = FindCircle(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]);
  var isBigArc =
    IsBigArc(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2], oldCircle[2]) === CIRCLE_SIZE_TYPE.BIGARC;
  var isShun = ByTime(obj.handlepoint[0], obj.handlepoint[2], obj.handlepoint[1]) === THREE_POINT_STATUS_TYPE.BYTIME;
  var lenth12 = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  var newLenth12 = LengthBetweenPoints(obj.handlepoint[0], point);
  if (obj.text.islength) {
    newLenth12 = Math.round((newLenth12 * 1000 + 0.5) / 1000);
    obj.text.text = Utility.format(newLenth12 / 12);
  }
  var r = (oldCircle[2] * newLenth12) / lenth12;
  r = toFixed(r, 3);
  obj.handlepoint[1] = point;
  obj.handlepoint[2] = GetCircleArcCenter2(obj.handlepoint[0], obj.handlepoint[1], r, isBigArc, isShun);
  return obj;
}

export function TransformParkingStallAngle(obj, point) {
  const [hpBegin, hpEnd, hpCenter] = obj.handlepoint.slice(0, 3);
  const ptBegin = new GeomPoint(...hpBegin);
  const ptEnd = new GeomPoint(...hpEnd);
  const ptCenter = new GeomPoint(...hpCenter);
  const v1 = ptBegin.getVector(ptEnd);
  const v2 = ptCenter.getVector(new GeomPoint(...point));
  obj.rotateangle = (v1.getRotationRad(v2) - Math.PI / 2 + Math.PI * 2) % (Math.PI * 2);
  return obj;
}

export function TransformCrossWalkBegin(obj, point) {
  obj.handlepoint[0] = point;
  let origin = obj.handlepoint[0];
  let width = LengthBetweenPoints(obj.handlepoint[6], point);
  let height = TowPointDestPointLength(obj.handlepoint[1], obj.handlepoint[7], obj.handlepoint[5]) / 2; // LengthBetweenPoints(obj.handlepoint[4], obj.handlepoint[3]);
  var angle = getAngleBy2Points(obj.handlepoint[0], obj.handlepoint[6]);
  obj.handlepoint[1] = [origin[0], origin[1] - height];
  obj.handlepoint[2] = [origin[0], origin[1] + height];
  obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
  obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - height];
  obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + height];
  obj.handlepoint[6] = [origin[0] + width, origin[1]];
  obj.handlepoint[7] = [origin[0] + width, origin[1] - height];
  obj.handlepoint[8] = [origin[0] + width, origin[1] + height];
  for (let i = 0; i < obj.handlepoint.length; i++) {
    obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
  }
  return obj;
}

export function TransformCrossWalkEnd(obj, point) {
  obj.handlepoint[6] = point;
  let origin = obj.handlepoint[0];
  let width = LengthBetweenPoints(obj.handlepoint[0], point);
  let height = TowPointDestPointLength(obj.handlepoint[1], obj.handlepoint[7], obj.handlepoint[5]) / 2; // LengthBetweenPoints(obj.handlepoint[4], obj.handlepoint[3]);
  var angle = getAngleBy2Points(obj.handlepoint[0], obj.handlepoint[6]);
  obj.handlepoint[1] = [origin[0], origin[1] - height];
  obj.handlepoint[2] = [origin[0], origin[1] + height];
  obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
  obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - height];
  obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + height];
  obj.handlepoint[6] = [origin[0] + width, origin[1]];
  obj.handlepoint[7] = [origin[0] + width, origin[1] - height];
  obj.handlepoint[8] = [origin[0] + width, origin[1] + height];
  for (let i = 0; i < obj.handlepoint.length; i++) {
    obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
  }
  return obj;
}

export function TransformCrossWalkLeftTop(obj, point) {
  let height = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[1]);
  let pointLeftop = TowPointVerticalLengthByStatus(obj.handlepoint[0], obj.handlepoint[6], point, height, false);
  if (!IsUnderLine(obj.handlepoint[4], obj.handlepoint[5], pointLeftop)) {
    CopyPoint(pointLeftop, obj.handlepoint[4]);
  }
  CopyPoint(obj.handlepoint[1], pointLeftop);
  return obj;
}

export function TransformCrossWalkLeftBottom(obj, point) {
  let height = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[2]);
  let pointLeftop = TowPointVerticalLengthByStatus(obj.handlepoint[0], obj.handlepoint[6], point, height, true);
  if (!IsUnderLine(obj.handlepoint[4], obj.handlepoint[5], pointLeftop)) {
    CopyPoint(pointLeftop, obj.handlepoint[5]);
  }
  CopyPoint(obj.handlepoint[2], pointLeftop);
  return obj;
}

export function TransformCrossWalkRightTop(obj, point) {
  let height = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[7]);
  let pointTemp = TowPointVerticalLengthByStatus(obj.handlepoint[0], obj.handlepoint[6], point, height, false);
  if (IsUnderLine(obj.handlepoint[4], obj.handlepoint[5], pointTemp)) {
    CopyPoint(pointTemp, obj.handlepoint[4]);
  }
  CopyPoint(obj.handlepoint[7], pointTemp);
  return obj;
}

export function TransformCrossWalkRightBottom(obj, point) {
  let height = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], obj.handlepoint[8]);
  let pointTemp = TowPointVerticalLengthByStatus(obj.handlepoint[0], obj.handlepoint[6], point, height, true);
  if (IsUnderLine(obj.handlepoint[4], obj.handlepoint[5], pointTemp)) {
    CopyPoint(pointTemp, obj.handlepoint[5]);
  }
  CopyPoint(obj.handlepoint[8], pointTemp);
  return obj;
}

export function TransformCrossWalkMiddleTop(obj, point) {
  var angle = getAngleBy2Points(obj.handlepoint[0], obj.handlepoint[6]);
  let width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[6]);
  let height = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], point);
  let length1 = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[4]);
  let length2 = LengthBetweenPoints(obj.handlepoint[2], obj.handlepoint[5]);
  let length7 = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[4]);
  let length8 = LengthBetweenPoints(obj.handlepoint[8], obj.handlepoint[5]);
  if (height < CROSS_WALK_SPACE.HEIGHTMIN || IsUnderLine(obj.handlepoint[0], obj.handlepoint[6], point)) {
    height = CROSS_WALK_SPACE.HEIGHTMIN;
  }

  let origin = obj.handlepoint[0];

  obj.handlepoint[1] = [origin[0] + width / 2 - length1, origin[1] - height];
  obj.handlepoint[2] = [origin[0] + width / 2 - length2, origin[1] + height];
  obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
  obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - height];
  obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + height];
  obj.handlepoint[6] = [origin[0] + width, origin[1]];
  obj.handlepoint[7] = [origin[0] + width / 2 + length7, origin[1] - height];
  obj.handlepoint[8] = [origin[0] + width / 2 + length8, origin[1] + height];
  for (let i = 0; i < obj.handlepoint.length; i++) {
    obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
  }
  return obj;
}

export function TransformCrossWalkMiddleBottom(obj, point) {
  var angle = getAngleBy2Points(obj.handlepoint[0], obj.handlepoint[6]);
  let width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[6]);
  let height = TowPointDestPointLength(obj.handlepoint[0], obj.handlepoint[6], point);
  let length1 = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[4]);
  let length2 = LengthBetweenPoints(obj.handlepoint[2], obj.handlepoint[5]);
  let length7 = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[4]);
  let length8 = LengthBetweenPoints(obj.handlepoint[8], obj.handlepoint[5]);
  if (height < CROSS_WALK_SPACE.HEIGHTMIN || !IsUnderLine(obj.handlepoint[0], obj.handlepoint[6], point)) {
    height = CROSS_WALK_SPACE.HEIGHTMIN;
  }
  let origin = obj.handlepoint[0];
  obj.handlepoint[1] = [origin[0] + width / 2 - length1, origin[1] - height];
  obj.handlepoint[2] = [origin[0] + width / 2 - length2, origin[1] + height];
  obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
  obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - height];
  obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + height];
  obj.handlepoint[6] = [origin[0] + width, origin[1]];
  obj.handlepoint[7] = [origin[0] + width / 2 + length7, origin[1] - height];
  obj.handlepoint[8] = [origin[0] + width / 2 + length8, origin[1] + height];
  for (let i = 0; i < obj.handlepoint.length; i++) {
    obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
  }
  return obj;
}

export function TransformCrossWalkWidth(obj, nowHeight) {
  var angle = getAngleBy2Points(obj.handlepoint[0], obj.handlepoint[6]);
  let width = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[6]);
  let height = nowHeight / 2;
  let length1 = LengthBetweenPoints(obj.handlepoint[1], obj.handlepoint[4]);
  let length2 = LengthBetweenPoints(obj.handlepoint[2], obj.handlepoint[5]);
  let length7 = LengthBetweenPoints(obj.handlepoint[7], obj.handlepoint[4]);
  let length8 = LengthBetweenPoints(obj.handlepoint[8], obj.handlepoint[5]);
  let origin = obj.handlepoint[0];
  obj.handlepoint[1] = [origin[0] + width / 2 - length1, origin[1] - height];
  obj.handlepoint[2] = [origin[0] + width / 2 - length2, origin[1] + height];
  obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
  obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - height];
  obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + height];
  obj.handlepoint[6] = [origin[0] + width, origin[1]];
  obj.handlepoint[7] = [origin[0] + width / 2 + length7, origin[1] - height];
  obj.handlepoint[8] = [origin[0] + width / 2 + length8, origin[1] + height];
  for (let i = 0; i < obj.handlepoint.length; i++) {
    obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
  }
  return obj;
}

// export function TransformStructure(obj, point) {}

/**
 * 移动StructureLine线
 * @param {*} obj
 * @param {number} index
 * @param {[number, number]} point
 * @param {[number, number]} capturepoint
 */
export function TransformStructureLine(obj, index, point, capturepoint) {
  let matrix = [1, 0, 0, 1, point[0] - capturepoint[0], point[1] - capturepoint[1]];
  let lineLeftPoint = pointTransform(matrix, obj.handlepoint[index]);
  let lineRightPoint = pointTransform(matrix, obj.handlepoint[index + 1]);
  CopyPoint(obj.handlepoint[index], lineLeftPoint);
  CopyPoint(obj.handlepoint[index + 1], lineRightPoint);
  let leftIndex = obj.handlepoint[index][3].index;
  //判断是否是
  if (leftIndex != -1) {
    let leftInexPoint = pointTransform(matrix, obj.handlepoint[leftIndex]);
    CopyPoint(obj.handlepoint[leftIndex], leftInexPoint);
  }
  let rightIndex = obj.handlepoint[index + 1][3].index;
  if (rightIndex != -1) {
    let rightIndexPoint = pointTransform(matrix, obj.handlepoint[rightIndex]);
    CopyPoint(obj.handlepoint[rightIndex], rightIndexPoint);
  }
  // door move
  let beginPoint = obj.handlepoint[index];
  if (beginPoint[2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
    for (let j = 4; j < beginPoint.length; j++) {
      //用于门选择拖动的时候显示
      let doorMoveEnd = pointTransform(matrix, [beginPoint[j].x, beginPoint[j].y]);
      beginPoint[j].x = doorMoveEnd[0];
      beginPoint[j].y = doorMoveEnd[1];
    }
  }
  return obj;
}

/**
 * 移动StructureBeign点
 * @param {*} obj
 * @param {number} index
 * @param {[number, number]} point
 */
export function TransformStructureBegin(obj, index, point) {
  //求出距离x最近的平行x轴点
  let closeAngle = GetCLoseShapeAngle(obj.handlepoint);
  const GUIDSPACE = STRUCTURE_PARAM_TYPE.GUIDSPACE; //精度
  CopyPoint(obj.handlepoint[index], point);
  obj.handlepoint[index][3].index = -1;
  let minXindex = -1,
    minXlength = -1;
  let minYindex = -1,
    minYlength = -1;
  let minLineIndex = -1;
  let indexPoint = point;
  let beginIndex = obj.handlepoint[index][3].index;
  let endIndex = obj.handlepoint[index + 1][3].index;
  for (
    let i = 0;
    i < obj.handlepoint.length - 1;
    i++ //排除旋转点
  ) {
    if (i == index || i == index + 1 || i == beginIndex || i == endIndex) continue;
    let lengthx = Math.abs(obj.handlepoint[i][0] - point[0]);
    let lengthy = Math.abs(obj.handlepoint[i][1] - point[1]);
    if (minXlength === -1 || lengthx < minXlength) {
      minXlength = lengthx;
      if (lengthx <= GUIDSPACE) {
        minXindex = i;
        indexPoint[0] = obj.handlepoint[i][0];
      }
    }
    if (minYlength === -1 || lengthy < minYlength) {
      minYlength = lengthy;
      if (lengthy <= GUIDSPACE) {
        minYindex = i;
        indexPoint[1] = obj.handlepoint[i][1];
      }
    }
  }
  for (
    let i = 0;
    i < obj.handlepoint.length - 1;
    i++ //排除旋转点
  ) {
    if (i == index) continue;
    if (obj.handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
      let lengthLine = TowPointDestPointLength(obj.handlepoint[i], obj.handlepoint[i + 1], point);
      if (lengthLine <= GUIDSPACE) {
        indexPoint = TowPointVerticalAcross(obj.handlepoint[i], obj.handlepoint[i + 1], point);
        let pointRange = GetTowPointRange(obj.handlepoint[i], obj.handlepoint[i + 1]);
        if (
          pointRange[0][0] < indexPoint[0] &&
          pointRange[1][0] > indexPoint[0] &&
          pointRange[0][1] < indexPoint[1] &&
          pointRange[1][1] > indexPoint[1]
        ) {
          //线上的点必须在两点的范围内
          minLineIndex = i;
        }
      }
    }
  }
  obj.handlepoint[index][3].nearx = minXindex;
  obj.handlepoint[index][3].neary = minYindex;
  obj.handlepoint[index][3].nearline = minLineIndex;
  if (minXindex != -1) {
    obj.handlepoint[index][0] = indexPoint[0];
  }
  if (minYindex != -1) {
    obj.handlepoint[index][1] = indexPoint[1];
  }
  if (minLineIndex != -1) {
    CopyPoint(obj.handlepoint[index], indexPoint);
  }
  let iCount = obj.handlepoint.length;
  obj.handlepoint[iCount - 1] = GetCLoseShapeAnglePoint(obj.handlepoint, closeAngle);
  //door
  let beginPoint = obj.handlepoint[index];
  if (beginPoint[2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
    for (let j = 4; j < beginPoint.length; j++) {
      let doorLength = LengthBetweenPoints([beginPoint[j].x, beginPoint[j].y], obj.handlepoint[index + 1]);
      if (doorLength > LengthBetweenPoints(beginPoint, obj.handlepoint[index + 1])) {
        doorLength = LengthBetweenPoints(beginPoint, obj.handlepoint[index + 1]);
      }
      let doorAngle = getAngleBy2Points(obj.handlepoint[index + 1], beginPoint);
      let doorCricle = [];
      CopyPoint(doorCricle, obj.handlepoint[index + 1]);
      doorCricle[2] = doorLength;
      let doorMoveEnd = GetCirclePoint(doorCricle, doorAngle);
      beginPoint[j].x = doorMoveEnd[0];
      beginPoint[j].y = doorMoveEnd[1];
    }
  }
  return obj;
}

/**
 * 移动StructureEnd点
 * @param {*} obj
 * @param {number} index
 * @param {[number, number]} point
 */
export function TransformStructureEnd(obj, index, point) {
  const GUIDSPACE = STRUCTURE_PARAM_TYPE.GUIDSPACE; //精度
  CopyPoint(obj.handlepoint[index], point);
  obj.handlepoint[index][3].index = -1;
  let minXindex = -1,
    minXlength = -1;
  let minYindex = -1,
    minYlength = -1;
  let minLineIndex = -1;
  let indexPoint = point;
  let beginIndex = obj.handlepoint[index - 1][3].index;
  let endIndex = obj.handlepoint[index][3].index;
  for (
    let i = 0;
    i < obj.handlepoint.length - 1;
    i++ //排除旋转点
  ) {
    if (i == index || i == index - 1 || i == beginIndex || i == endIndex) continue;
    let lengthx = Math.abs(obj.handlepoint[i][0] - point[0]);
    let lengthy = Math.abs(obj.handlepoint[i][1] - point[1]);
    if (minXlength === -1 || lengthx < minXlength) {
      minXlength = lengthx;
      if (lengthx <= GUIDSPACE) {
        minXindex = i;
        indexPoint[0] = obj.handlepoint[i][0];
      }
    }
    if (minYlength === -1 || lengthy < minYlength) {
      minYlength = lengthy;
      if (lengthy <= GUIDSPACE) {
        minYindex = i;
        indexPoint[1] = obj.handlepoint[i][1];
      }
    }
  }
  for (
    let i = 0;
    i < obj.handlepoint.length - 1;
    i++ //排除旋转点
  ) {
    if (i == index) continue;
    if (obj.handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN && i + 1 != index) {
      let lengthLine = TowPointDestPointLength(obj.handlepoint[i], obj.handlepoint[i + 1], point);
      if (lengthLine <= GUIDSPACE) {
        indexPoint = TowPointVerticalAcross(obj.handlepoint[i], obj.handlepoint[i + 1], point);
        let pointRange = GetTowPointRange(obj.handlepoint[i], obj.handlepoint[i + 1]);
        if (
          pointRange[0][0] < indexPoint[0] &&
          pointRange[1][0] > indexPoint[0] &&
          pointRange[0][1] < indexPoint[1] &&
          pointRange[1][1] > indexPoint[1]
        ) {
          //线上的点必须在两点的范围内
          minLineIndex = i;
        }
      }
    }
  }
  obj.handlepoint[index][3].nearx = minXindex;
  obj.handlepoint[index][3].neary = minYindex;
  obj.handlepoint[index][3].nearline = minLineIndex;
  if (minXindex != -1) {
    obj.handlepoint[index][0] = indexPoint[0];
  }
  if (minYindex != -1) {
    obj.handlepoint[index][1] = indexPoint[1];
  }
  if (minLineIndex != -1) {
    CopyPoint(obj.handlepoint[index], indexPoint);
  }
  //door
  let beginPoint = obj.handlepoint[index - 1];
  if (beginPoint[2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
    for (let j = 4; j < beginPoint.length; j++) {
      let doorLength = LengthBetweenPoints([beginPoint[j].x, beginPoint[j].y], beginPoint);
      if (doorLength > LengthBetweenPoints(beginPoint, obj.handlepoint[index])) {
        doorLength = LengthBetweenPoints(beginPoint, obj.handlepoint[index]);
      }
      let doorAngle = getAngleBy2Points(beginPoint, obj.handlepoint[index]);
      let doorCricle = [];
      CopyPoint(doorCricle, beginPoint);
      doorCricle[2] = doorLength;
      let doorMoveEnd = GetCirclePoint(doorCricle, doorAngle);
      beginPoint[j].x = doorMoveEnd[0];
      beginPoint[j].y = doorMoveEnd[1];
    }
  }
  return obj;
}

export function TransformPosition(obj, point) {
  obj.position.x1 = point[0];
  obj.position.y1 = point[1];
  if (obj.handlepoint.length > 0) {
    obj.handlepoint[0] = [point[0], point[1]];
  }
  return obj;
}

export function TransformRotationPosition(obj, point) {
  var pt = [obj.position.x1, obj.position.y1];
  pt = offset(pt[0], pt[1], obj.lenZeroHead * 0.75, 0);
  var a = AngleFromHorizontal(obj.position.x1, obj.position.y1, pt[0], pt[1]);
  return obj;
}

export function TransformHeadPosition(obj, point) {
  obj.lenZeroHead = distance(obj.position.x1, obj.position.y1, point[0], point[1]);
  return obj;
}

export function TransformTailPosition(obj, point) {
  obj.lenZeroTail = distance(obj.position.x1, obj.position.y1, point[0], point[1]);
  return obj;
}

export function TransformReferencePosition(obj, point, pointIndex) {
  if (pointIndex !== -1) {
    obj.referencePoints[pointIndex] = [...point];
  }
  return obj;
}

/**
 * 拖拽streetnew的begin point图形的变化
 * @param {*} obj
 * @param {[number, number]} point
 * @return {*}
 */
export function TransformStreetNewBeginObject(obj, point) {
  if (obj.segments && (Array.isArray(obj.segments) && obj.segments.length > 0)) {
    let r, ptAlign;
    if (obj.segments[0].type == 'line') {
      var gl = new GeomLine(
        new GeomPoint(obj.segments[0].ptStart.x, obj.segments[0].ptStart.y),
        new GeomPoint(obj.segments[0].ptStop.x, obj.segments[0].ptStop.y)
      );
      r = Utility.getHandleRadius() * 4 * diagramManager.getUserUnitsPerPixel();
      ptAlign = gl.getAlignmentAtLength(-r, 0).ptAlign;
      ptAlign.offset(point[0] - ptAlign.x, point[1] - ptAlign.y);
      ptAlign.offsetToward(gl.getPointStop(), r);
      gl.setStart(ptAlign);
      obj.segments[0] = {
        type: 'line',
        ptStart: { x: gl.ptStart.x, y: gl.ptStart.y },
        ptStop: { x: gl.ptStop.x, y: gl.ptStop.y },
      };
    } else if (obj.segments[0].type == 'arc') {
      var ga = new GeomArc(
        new GeomPoint(obj.segments[0].ptStart.x, obj.segments[0].ptStart.y),
        new GeomPoint(obj.segments[0].ptStop.x, obj.segments[0].ptStop.y),
        obj.segments[0].r,
        obj.segments[0].largeArcFlag,
        obj.segments[0].sweepFlag
      );
      r = Utility.getHandleRadius() * 4 * diagramManager.getUserUnitsPerPixel();
      ptAlign = ga.getAlignmentAtLength(-r, 0).ptAlign;
      var ptStart = ga.getPointStart();
      ptAlign.offset(point[0] - ptAlign.x, point[1] - ptAlign.y);
      ga.setStart(ptAlign);
      ptAlign = ga.getPointAtLength(r);
      ga.setStart(ptAlign);
      obj.segments[0] = {
        type: 'arc',
        ptStart: { x: ga.ptStart.x, y: ga.ptStart.y },
        ptStop: { x: ga.ptStop.x, y: ga.ptStop.y },
        r: ga.r,
        largeArcFlag: ga.largeArcFlag,
        sweepFlag: ga.sweepFlag,
      };
    }
    //obj.segments[0].ptStart = { x: point[0], y: point[1] };
    if (obj.offsetArcPath) updatePath(obj);
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    _s.computeStreets(obj);
  }
  return obj;
}

/**
 * 拖拽streetnew的end point图形的变化
 * @param {*} obj
 * @param {[number, number]} point
 * @return {*}
 */
export function TransformStreetNewEndObject(obj, point) {
  if (obj.segments && (Array.isArray(obj.segments) && obj.segments.length > 0)) {
    let r, ptAlign;
    if (obj.segments[obj.segments.length - 1].type == 'line') {
      var gl = new GeomLine(
        new GeomPoint(obj.segments[obj.segments.length - 1].ptStart.x, obj.segments[obj.segments.length - 1].ptStart.y),
        new GeomPoint(obj.segments[obj.segments.length - 1].ptStop.x, obj.segments[obj.segments.length - 1].ptStop.y)
      );
      r = Utility.getHandleRadius() * 4 * diagramManager.getUserUnitsPerPixel();
      ptAlign = gl.getAlignmentAtLength(r + gl.getLength(), 0).ptAlign;
      ptAlign.offset(point[0] - ptAlign.x, point[1] - ptAlign.y);

      // 增加一个偏移，防止鼠标点落在point上
      ptAlign.offsetToward(gl.getPointStart(), r);
      gl.setStop(ptAlign);
      obj.segments[obj.segments.length - 1] = {
        type: 'line',
        ptStart: { x: gl.ptStart.x, y: gl.ptStart.y },
        ptStop: { x: gl.ptStop.x, y: gl.ptStop.y },
      };
    } else if (obj.segments[obj.segments.length - 1].type == 'arc') {
      var ga = new GeomArc(
        new GeomPoint(obj.segments[obj.segments.length - 1].ptStart.x, obj.segments[obj.segments.length - 1].ptStart.y),
        new GeomPoint(obj.segments[obj.segments.length - 1].ptStop.x, obj.segments[obj.segments.length - 1].ptStop.y),
        obj.segments[obj.segments.length - 1].r,
        obj.segments[obj.segments.length - 1].largeArcFlag,
        obj.segments[obj.segments.length - 1].sweepFlag
      );
      r = Utility.getHandleRadius() * 4 * diagramManager.getUserUnitsPerPixel();
      ptAlign = ga.getAlignmentAtLength(r + ga.getLength(), 0).ptAlign;
      var ptStart = ga.getPointStart();
      ptAlign.offset(point[0] - ptAlign.x, point[1] - ptAlign.y);
      ga.setStop(ptAlign);
      ptAlign = ga.getPointAtLength(ga.getLength() - r);
      ga.setStop(ptAlign);
      obj.segments[obj.segments.length - 1] = {
        type: 'arc',
        ptStart: { x: ga.ptStart.x, y: ga.ptStart.y },
        ptStop: { x: ga.ptStop.x, y: ga.ptStop.y },
        r: ga.r,
        largeArcFlag: ga.largeArcFlag,
        sweepFlag: ga.sweepFlag,
      };
    }
    //obj.segments[obj.segments.length - 1].ptStop = { x: point[0], y: point[1] };
    if (obj.offsetArcPath) updatePath(obj);
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    _s.computeStreets(obj);
  }
  return obj;
}

/**
 * 拖拽streetnew的connect point图形的变化
 * @param {*} obj
 * @param {[number, number]} point
 * @param {number} i - 当前连接点的下标，对于roundabout值介于0到3之间
 * @return {*}
 */
export function TransformRoundaboutConnectObject(obj, point, i) {
  if (obj.segments && (Array.isArray(obj.segments) && obj.segments.length > 0)) {
    const len = obj.segments.length - 1;
    // i j 代表两个相邻的segments的下标
    const j = i === 0 ? len : i - 1;
    [i, j].forEach(index => {
      let segment = obj.segments[index];
      if (segment.type == 'line') {
        const gl = new GeomLine(
          new GeomPoint(segment.ptStart.x, segment.ptStart.y),
          new GeomPoint(segment.ptStop.x, segment.ptStop.y)
        );
        const ptAlign = gl.getAlignmentAtLength(0, 0).ptAlign;
        ptAlign.offset(point[0] - ptAlign.x, point[1] - ptAlign.y);
        ptAlign.offsetToward(gl.getPointStop(), 0);
        if (index === i) {
          gl.setStart(ptAlign);
        } else {
          gl.setStop(ptAlign);
        }
        obj.segments[index] = {
          type: 'line',
          ptStart: { x: gl.ptStart.x, y: gl.ptStart.y },
          ptStop: { x: gl.ptStop.x, y: gl.ptStop.y },
        };
      } else if (segment.type == 'arc') {
        const ga = new GeomArc(
          new GeomPoint(segment.ptStart.x, segment.ptStart.y),
          new GeomPoint(segment.ptStop.x, segment.ptStop.y),
          segment.r,
          segment.largeArcFlag,
          segment.sweepFlag
        );
        const ptAlign = ga.getAlignmentAtLength(0, 0).ptAlign;
        ptAlign.offset(point[0] - ptAlign.x, point[1] - ptAlign.y);
        if (index === i) {
          ga.setStart(ptAlign);
        } else {
          ga.setStop(ptAlign);
        }
        obj.segments[index] = {
          type: 'arc',
          ptStart: { x: ga.ptStart.x, y: ga.ptStart.y },
          ptStop: { x: ga.ptStop.x, y: ga.ptStop.y },
          r: ga.r,
          largeArcFlag: ga.largeArcFlag,
          sweepFlag: ga.sweepFlag,
        };
      }
    });
    //obj.segments[0].ptStart = { x: point[0], y: point[1] };
    if (obj.offsetArcPath) updatePath(obj);
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    _s.computeStreets(obj);
  }
  return obj;
}

/**
 * 拖拽roundabout的center point图形的变化
 * @param {*} obj
 * @param {[number, number]} point
 * @param {number} i - 当前连接点的下标，对于roundabout值介于0到3之间
 * @return {*}
 */
export function TransformRoundaboutCenterObject(obj, point, i) {
  if (obj.segments && (Array.isArray(obj.segments) && obj.segments.length > 0)) {
    let segment = obj.segments[i];
    var ptStart = new GeomPoint(segment.ptStart.x, segment.ptStart.y);
    var ptStop = new GeomPoint(segment.ptStop.x, segment.ptStop.y);
    var ga;
    if (segment.type == 'line') {
      ga = new GeomArc(ptStart, ptStop, 0, false, false);
    } else {
      ga = new GeomArc(ptStart, ptStop, segment.r, segment.largeArcFlag, segment.sweepFlag);
    }
    var ptMid = new GeomPoint(point[0], point[1]);
    if (ga.setMidPoint(ptMid)) {
      if (ga.getMidPoint().distance(ga.ptStart.midPoint(ga.ptStop)) > diagramManager.getUserUnitsPerPixel() * 7) {
        obj.segments[i] = {
          type: 'arc',
          ptStart: { x: ptStart.x, y: ptStart.y },
          ptStop: { x: ptStop.x, y: ptStop.y },
          r: ga.r,
          largeArcFlag: ga.largeArcFlag,
          sweepFlag: ga.sweepFlag,
        };
      } else {
        obj.segments[i] = {
          type: 'line',
          ptStart: { x: ptStart.x, y: ptStart.y },
          ptStop: { x: ptStop.x, y: ptStop.y },
        };
      }
    }
    if (obj.offsetArcPath) updatePath(obj);
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    _s.computeStreets(obj);
  }
  return obj;
}

/**
 * 拖拽streetnew的center point图形的变化
 * @param {*} obj
 * @param {[number, number]} point
 * @param {number} [i] - 当前连接点的下标
 * @return {*}
 */
export function TransformStreetNewCenterObject(obj, point, index = 0) {
  if (obj.segments && (Array.isArray(obj.segments) && obj.segments.length > 0)) {
    const segment = obj.segments[index];
    var ptStart = new GeomPoint(segment.ptStart.x, segment.ptStart.y);
    var ptStop = new GeomPoint(segment.ptStop.x, segment.ptStop.y);
    var ga;
    if (obj.segments[index].type == 'line') {
      ga = new GeomArc(ptStart, ptStop, 0, false, false);
    } else {
      ga = new GeomArc(ptStart, ptStop, segment.r, segment.largeArcFlag, segment.sweepFlag);
    }
    var ptMid = new GeomPoint(point[0], point[1]);
    if (ga.setMidPoint(ptMid)) {
      if (ga.getMidPoint().distance(ga.ptStart.midPoint(ga.ptStop)) > diagramManager.getUserUnitsPerPixel() * 7) {
        // TODO: Can use GeomArc\GeomLine replace these?
        obj.segments[index] = createArc({
          ptStart: { x: ptStart.x, y: ptStart.y },
          ptStop: { x: ptStop.x, y: ptStop.y },
          r: ga.r,
          largeArcFlag: ga.largeArcFlag,
          sweepFlag: ga.sweepFlag,
        });
      } else {
        obj.segments[index] = createLine({
          ptStart: { x: ptStart.x, y: ptStart.y },
          ptStop: { x: ptStop.x, y: ptStop.y },
        });
      }
    }
    if (obj.offsetArcPath) updatePath(obj);
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    _s.computeStreets(obj);
  }
  return obj;
}

function updatePath(object) {
  var goa = new GeomOffsetArc(
    object.segments,
    object.transitionRadius,
    object.transitionLengthFactor,
    object.transitionPercentFromStart,
    object.stopOffset
  );
  goa.calculateOffsetPath();
  object.stopOffset = goa.stopOffset;
  object.transitionPercentFromStart = goa.transitionPercentFromStart;
  object.transitionLengthFactor = goa.transitionLengthFactor;
  object.transitionRadius = goa.transitionRadius;
  //this.setTransitionMidPoint(b040.transitionMidPoint);
  var offsetArcSegments = [];
  for (let i = 0; i < goa.offsetArcSegments.length; i++) {
    const ptStart = {
      x: goa.offsetArcSegments[i].getPointStart().x,
      y: goa.offsetArcSegments[i].getPointStart().y,
    };
    const ptStop = {
      x: goa.offsetArcSegments[i].getPointStop().x,
      y: goa.offsetArcSegments[i].getPointStop().y,
    };
    if (goa.offsetArcSegments[i].declaredClass == 'GeomLine') {
      offsetArcSegments.push(
        createLine({
          ptStart,
          ptStop,
        })
      );
    } else {
      offsetArcSegments.push(
        createArc({
          ptStart,
          ptStop,
          r: goa.offsetArcSegments[i].r,
          largeArcFlag: goa.offsetArcSegments[i].largeArcFlag,
          sweepFlag: goa.offsetArcSegments[i].sweepFlag,
        })
      );
    }
  }
  object.offsetArcPath = { segments: offsetArcSegments };
}

/**
 * 拖动 Street 时，弧线部分的 start 处理
 * @param {object} obj
 * @param {string} stripeKey
 * @param {array} point
 */
export function dragStreetOffsetArcPathStartHandle(obj, stripeKey, point) {
  for (let i = 0; i < obj.components.length; i++) {
    if (obj.components[i].key.toString() === stripeKey) {
      var stripe = obj.components[i];
      var gpa;
      var goa;
      var stopOffsetStart = 0;
      if (stripe.axis) {
        gpa = new GeomPolyArc(stripe.segments);
        goa = new GeomOffsetArc([stripe.segments[0]], 30, stripe.trLenStart, stripe.trPctStart, stripe.stopOffsetStart);
        if (gpa.segments.length === 1 && stripe.stopOffsetStart === stripe.arcOffsetStart) {
          stopOffsetStart = 0;
          goa.transitionPercentFromStart = 0.66;
          stripe.axis = undefined;
        } else {
          stopOffsetStart = Utility.isNumeric(stripe.stopOffsetStart) ? stripe.stopOffsetStart : 0;
        }
      } else {
        gpa = new GeomPolyArc(stripe.segments);
        stopOffsetStart = Utility.isNumeric(stripe.stopOffsetStart) ? stripe.stopOffsetStart : 0;
        goa = new GeomOffsetArc([stripe.segments[0]], 30, 1.33, 0.66, stopOffsetStart);
      }
      var pt = gpa.pointOffsetNormal(gpa.getPointStart(), -stopOffsetStart);
      var pt1 = pt.clone();
      let tgpa;
      if (stripe.axis) {
        tgpa = new GeomPolyArc(stripe.offsetArcSegments);
        pt.offset(point[0] - tgpa.getPointStart().x, point[1] - tgpa.getPointStart().y);
      } else {
        tgpa = new GeomPolyArc(stripe.segments);
        pt.offset(point[0] - tgpa.getPointStart().x, point[1] - tgpa.getPointStart().y);
      }
      var multiSegment = gpa.segments.length > 1;
      var b74;
      if (gpa.segments[0].declaredClass === 'GeomLine') {
        var b72 = gpa.segments[0].getOffsetsToPoint(pt1);
        var b73 = gpa.segments[0].getOffsetsToPoint(pt);
        b74 = b73.normal - b72.normal;
      } else {
        var b75 = gpa.segments[0].getCircleCenter();
        var b76 = gpa.segments[0].sweepFlag;
        var b77 = pt1.distance(b75);
        var b78 = pt.distance(b75);
        if (b76) {
          b74 = b77 - b78;
        } else {
          b74 = b78 - b77;
        }
      }
      var b79 = stopOffsetStart - b74 / 2;
      goa.inflateAxis(-b79);
      goa.stopOffset = b79;
      goa.calculateOffsetPath();
      if (goa.stopOffset !== b79) {
        goa.inflateAxis(b79 - goa.stopOffset);
        goa.calculateOffsetPath();
      }
      let turnbaySpec, gs;
      if (goa.stopOffset === 0) {
        //stripe.offsetArcSegments = new UtilityGeom().getSegments(goa.getSvgPathData());
        if (stripe.stopOffsetStop) {
          turnbaySpec = {
            transitionPercentFromStart: goa.transitionPercentFromStart,
            stopOffset: goa.stopOffset,
            multiSegment: multiSegment,
            atEnd: false,
          };
          gs = new GeomStripe(gpa, 0, stripe.axis.patterns, [], obj.style.strokewidth, turnbaySpec);
          stripe.axis.patterns = gs.patterns;
        } else {
          stripe.axis = undefined;
        }
        stripe.trLenStart = undefined;
        stripe.trPctStart = undefined;
        stripe.stopOffsetStart = undefined;
        stripe.arcOffsetStart = undefined;
      } else {
        if (!stripe.axis) {
          stripe.axis = { profile: stripe.axis ? stripe.axis.profile : stripe.segments };
          //stripe.offsetArcSegments = new UtilityGeom().getSegments(goa.getSvgPathData());
        }
        if (!stripe.axis.patterns) {
          stripe.axis.patterns = [
            { pattern: 'singlesolid', startPct: 0, paintable: true },
            { pattern: 'invisible', startPct: 0, paintable: false },
          ];
        }
        turnbaySpec = {
          transitionPercentFromStart: goa.transitionPercentFromStart,
          stopOffset: goa.stopOffset,
          multiSegment: multiSegment,
          atEnd: false,
        };
        gs = new GeomStripe(gpa, 0, stripe.axis.patterns, [], obj.style.strokewidth, turnbaySpec);
        stripe.axis.patterns = gs.patterns;
        stripe.trLenStart = goa.transitionLengthFactor;
        stripe.trPctStart = goa.transitionPercentFromStart;
        stripe.stopOffsetStart = goa.stopOffset;
        stripe.arcOffsetStart = -stripe.stopOffsetStart;
      }
      if (multiSegment) {
        gpa = new GeomPolyArc(stripe.segments);
        var endl = gpa.getOffsetsToPoint(goa.getPointStart()).fromStart;
        gpa.copyPartialShape(0, endl, gpa.getLength());
        goa.offsetArcSegments = goa.offsetArcSegments.concat(gpa.segments);
      }
      stripe.offsetArcSegments = [];
      for (let j = 0; j < goa.offsetArcSegments.length; j++) {
        if (goa.offsetArcSegments[j].declaredClass == 'GeomLine') {
          stripe.offsetArcSegments.push({
            type: 'line',
            ptStart: {
              x: goa.offsetArcSegments[j].getPointStart().x,
              y: goa.offsetArcSegments[j].getPointStart().y,
            },
            ptStop: {
              x: goa.offsetArcSegments[j].getPointStop().x,
              y: goa.offsetArcSegments[j].getPointStop().y,
            },
          });
        } else {
          stripe.offsetArcSegments.push({
            type: 'arc',
            ptStart: {
              x: goa.offsetArcSegments[j].getPointStart().x,
              y: goa.offsetArcSegments[j].getPointStart().y,
            },
            ptStop: {
              x: goa.offsetArcSegments[j].getPointStop().x,
              y: goa.offsetArcSegments[j].getPointStop().y,
            },
            r: goa.offsetArcSegments[j].r,
            largeArcFlag: goa.offsetArcSegments[j].largeArcFlag,
            sweepFlag: goa.offsetArcSegments[j].sweepFlag,
          });
        }
      }
      //if (multiSegment && stripe.axis) {
      //  stripe.offsetArcSegments = stripe.offsetArcSegments.concat(stripe.axis.profile.slice(1));
      //}
      workData.setObj(obj.operateid, obj);
    }
  }
  let _s = new _Street();
  let lw = _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);
  return obj;
}

/**
 * 拖动 Street 时，弧线部分的 stop 处理
 * @param {object} obj
 * @param {string} stripeKey
 * @param {array} point
 */
export function dragStreetOffsetArcPathStopHandle(obj, stripeKey, point) {
  for (let i = 0, il = obj.components.length; i < il; i++) {
    if (obj.components[i].key.toString() === stripeKey) {
      let stripe = obj.components[i];
      let gpa = new GeomPolyArc(stripe.segments);
      let goa;
      let stopOffsetStop = 0; // 控制点被移动时的起始位置
      let multiSegment = gpa.segments.length > 1;
      if (stripe.axis) {
        goa = new GeomOffsetArc(
          [stripe.segments[stripe.segments.length - 1]],
          30,
          stripe.trLenStop,
          stripe.trPctStop,
          stripe.stopOffsetStop
        );
        if (multiSegment) {
          stopOffsetStop = Utility.isNumeric(stripe.stopOffsetStop) ? stripe.stopOffsetStop : 0;
        } else {
          if (Utility.isNumeric(stripe.stopOffsetStart) && stripe.stopOffsetStart === stripe.arcOffsetStart) {
            stopOffsetStop = stripe.arcOffsetStart;
          } else {
            if (gpa.segments.length === 1) {
              stopOffsetStop = 0;
              goa.transitionPercentFromStart = 0.33;
              stripe.axis = undefined;
            }
          }
        }
      } else {
        if (multiSegment) {
          stopOffsetStop = Utility.isNumeric(stripe.stopOffsetStop) ? stripe.stopOffsetStop : 0;
        } else {
          stopOffsetStop = Utility.isNumeric(stripe.stopOffsetStart) ? stripe.stopOffsetStart : 0;
        }
        goa = new GeomOffsetArc([stripe.segments[stripe.segments.length - 1]], 30, 1.33, 0.33, stopOffsetStop);
      }
      var pt = gpa.pointOffsetNormal(gpa.getPointStop(), stopOffsetStop);
      var ptClone = pt.clone();
      let tgpa = stripe.axis ? new GeomPolyArc(stripe.offsetArcSegments) : new GeomPolyArc(stripe.segments);
      pt.offset(point[0] - tgpa.getPointStop().x, point[1] - tgpa.getPointStop().y);
      var distance;
      let lastSegment = gpa.segments[gpa.segments.length - 1];
      if (lastSegment.declaredClass == 'GeomLine') {
        var pathOffset1 = lastSegment.getOffsetsToPoint(ptClone);
        var pathOffset2 = lastSegment.getOffsetsToPoint(pt);
        distance = pathOffset2.normal - pathOffset1.normal;
      } else {
        var centerPoint = lastSegment.getCircleCenter();
        var sweepFlag = lastSegment.sweepFlag;
        var distanceBetweenPtCloneToCenter = ptClone.distance(centerPoint);
        var distanceBetweenPtToCenter = pt.distance(centerPoint);
        distance = sweepFlag
          ? distanceBetweenPtCloneToCenter - distanceBetweenPtToCenter
          : distanceBetweenPtToCenter - distanceBetweenPtCloneToCenter;
      }
      var n = stopOffsetStop + distance / 2;
      goa.inflateAxis(n);
      goa.stopOffset = n;
      goa.calculateOffsetPath();
      if (goa.stopOffset !== n) {
        goa.inflateAxis(goa.stopOffset - n);
        goa.calculateOffsetPath();
      }

      //
      let turnbaySpec, gs;
      if (goa.stopOffset === 0 && gpa.segments.length === 1) {
        //stripe.offsetArcSegments = new UtilityGeom().getSegments(goa.getSvgPathData());
        stripe.axis = undefined;
        stripe.trLenStart = undefined;
        stripe.trPctStart = undefined;
        stripe.stopOffsetStart = undefined;
        stripe.arcOffsetStart = undefined;
      } else {
        if (goa.stopOffset === 0) {
          if (stripe.stopOffsetStart) {
            turnbaySpec = {
              transitionPercentFromStart: goa.transitionPercentFromStart,
              stopOffset: goa.stopOffset,
              multiSegment: multiSegment,
              atEnd: true,
            };
            gs = new GeomStripe(gpa, 0, stripe.axis.patterns, [], obj.style.strokewidth, turnbaySpec);
            stripe.axis.patterns = gs.arrPatterns;
          } else {
            stripe.axis = undefined;
          }
          stripe.trLenStop = undefined;
          stripe.trPctStop = undefined;
          stripe.stopOffsetStop = undefined;
          stripe.arcOffsetStop = undefined;
        } else {
          if (!stripe.axis) {
            stripe.axis = { profile: stripe.axis ? stripe.axis.profile : stripe.segments };
            //stripe.offsetArcSegments = new UtilityGeom().getSegments(goa.getSvgPathData());
          }
          if (!stripe.axis.patterns) {
            stripe.axis.patterns = [
              { pattern: 'invisible', startPct: 0, paintable: false },
              { pattern: 'singlesolid', startPct: 0, paintable: true },
            ];
          }
          turnbaySpec = {
            transitionPercentFromStart: goa.transitionPercentFromStart,
            stopOffset: goa.stopOffset,
            multiSegment: multiSegment,
            atEnd: true,
          };
          gs = new GeomStripe(gpa, 0, stripe.axis.patterns, [], obj.style.strokewidth, turnbaySpec);
          stripe.axis.patterns = gs.patterns;
          if (multiSegment) {
            stripe.trLenStop = goa.transitionLengthFactor;
            stripe.trPctStop = goa.transitionPercentFromStart;
            stripe.stopOffsetStop = goa.stopOffset;
            stripe.arcOffsetStop = stripe.stopOffsetStop;
          } else {
            stripe.trLenStart = goa.transitionLengthFactor;
            stripe.trPctStart = goa.transitionPercentFromStart;
            stripe.stopOffsetStart = goa.stopOffset;
            stripe.arcOffsetStart = stripe.stopOffsetStart;
          }
        }
      }
      if (multiSegment) {
        gpa = new GeomPolyArc(stripe.segments);
        var endl = gpa.getOffsetsToPoint(goa.getPointStart()).fromStart;
        var arcs = gpa.copyPartialShape(0, endl);
        if (arcs instanceof Array) {
          goa.offsetArcSegments = arcs.concat(goa.offsetArcSegments);
        }
      }
      /*if (multiSegment && stripe.axis) {
  	stripe.offsetArcSegments = stripe.axis.profile;
      stripe.offsetArcSegments.splice(stripe.offsetArcSegments.length - 1, 1);
      } else*/
      stripe.offsetArcSegments = [];
      for (let j = 0, jl = goa.offsetArcSegments.length; j < jl; j++) {
        if (goa.offsetArcSegments[j].declaredClass == 'GeomLine') {
          stripe.offsetArcSegments.push({
            type: 'line',
            ptStart: {
              x: goa.offsetArcSegments[j].getPointStart().x,
              y: goa.offsetArcSegments[j].getPointStart().y,
            },
            ptStop: {
              x: goa.offsetArcSegments[j].getPointStop().x,
              y: goa.offsetArcSegments[j].getPointStop().y,
            },
          });
        } else {
          stripe.offsetArcSegments.push({
            type: 'arc',
            ptStart: {
              x: goa.offsetArcSegments[j].getPointStart().x,
              y: goa.offsetArcSegments[j].getPointStart().y,
            },
            ptStop: {
              x: goa.offsetArcSegments[j].getPointStop().x,
              y: goa.offsetArcSegments[j].getPointStop().y,
            },
            r: goa.offsetArcSegments[j].r,
            largeArcFlag: goa.offsetArcSegments[j].largeArcFlag,
            sweepFlag: goa.offsetArcSegments[j].sweepFlag,
          });
        }
      }
      workData.setObj(obj.operateid, obj);
    }
  }
  let _s = new _Street();
  let lw = _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);
  return obj;
}

/**
 * 拖动 Street 时，弧线部分的 transition 处理
 * @param {object} obj
 * @param {string} stripeKey
 * @param {number} dragIndex
 * @param {array} point
 */
export function dragStreetOffsetArcPathTransitionHandle(obj, stripeKey, dragIndex, point) {
  for (let i = 0, il = obj.components.length; i < il; i++) {
    if (obj.components[i].key.toString() === stripeKey) {
      let stripe = obj.components[i];
      let goa, gpa, pt1, ba0, gs;
      if (dragIndex === '1') {
        let multiSegment = stripe.segments.length > 1;
        goa = new GeomOffsetArc([stripe.segments[0]], 30, stripe.trLenStart, stripe.trPctStart, stripe.stopOffsetStart);
        goa.inflateAxis(stripe.arcOffsetStart);
        goa.calculateOffsetPath();
        gpa = new GeomPolyArc(goa.axisSegments);
        pt1 = gpa.getPointAtLength(gpa.getLength() * stripe.trPctStart);
        pt1.offset(point[0] - goa.transitionMidPoint.x, point[1] - goa.transitionMidPoint.y);
        goa.transitionPercentFromStart = gpa.getOffsetsToPoint(pt1).fromStartPercent;
        goa.calculateOffsetPath();
        ba0 = {
          transitionPercentFromStart: goa.transitionPercentFromStart,
          stopOffset: goa.stopOffset,
          multiSegment: multiSegment,
          atEnd: stripe.stopOffsetStart == stripe.arcOffsetStart,
        };
        gs = new GeomStripe(new GeomPolyArc(stripe.segments), 0, stripe.axis.patterns, [], obj.style.strokewidth, ba0);
        //stripe.primary.patterns = gs.patterns;
        stripe.trLenStart = goa.transitionLengthFactor;
        stripe.trPctStart = goa.transitionPercentFromStart;
        stripe.offsetArcSegments = [];
        for (let j = 0, jl = goa.offsetArcSegments.length; j < jl; j++) {
          if (goa.offsetArcSegments[j].declaredClass == 'GeomLine') {
            stripe.offsetArcSegments.push({
              type: 'line',
              ptStart: {
                x: goa.offsetArcSegments[j].getPointStart().x,
                y: goa.offsetArcSegments[j].getPointStart().y,
              },
              ptStop: {
                x: goa.offsetArcSegments[j].getPointStop().x,
                y: goa.offsetArcSegments[j].getPointStop().y,
              },
            });
          } else {
            stripe.offsetArcSegments.push({
              type: 'arc',
              ptStart: {
                x: goa.offsetArcSegments[j].getPointStart().x,
                y: goa.offsetArcSegments[j].getPointStart().y,
              },
              ptStop: {
                x: goa.offsetArcSegments[j].getPointStop().x,
                y: goa.offsetArcSegments[j].getPointStop().y,
              },
              r: goa.offsetArcSegments[j].r,
              largeArcFlag: goa.offsetArcSegments[j].largeArcFlag,
              sweepFlag: goa.offsetArcSegments[j].sweepFlag,
            });
          }
        }
      } else if (dragIndex === '2') {
        goa = new GeomOffsetArc(
          [stripe.segments[stripe.segments.length - 1]],
          30,
          stripe.trLenStop,
          stripe.trPctStop,
          stripe.stopOffsetStop
        );
        gpa = new GeomPolyArc(goa.axisSegments);
        goa.inflateAxis(stripe.arcOffsetStop);
        goa.calculateOffsetPath();
        pt1 = gpa.getPointAtLength(gpa.getLength() * stripe.trPctStop);
        pt1.offset(point[0] - goa.transitionMidPoint.x, point[1] - goa.transitionMidPoint.y);
        goa.transitionPercentFromStart = gpa.getOffsetsToPoint(pt1).fromStartPercent;
        goa.calculateOffsetPath();
        ba0 = {
          transitionPercentFromStart: goa.transitionPercentFromStart,
          stopOffset: goa.stopOffset,
          multiSegment: true,
          atEnd: true,
        };
        gs = new GeomStripe(new GeomPolyArc(stripe.segments), 0, stripe.axis.patterns, [], obj.style.strokewidth, ba0);
        //stripe.axis.patterns = gs.patterns;
        stripe.trLenStop = goa.transitionLengthFactor;
        stripe.trPctStop = goa.transitionPercentFromStart;
        stripe.offsetArcSegments = [];
        for (let j = 0, jl = goa.offsetArcSegments.length; j < jl; j++) {
          if (goa.offsetArcSegments[j].declaredClass == 'GeomLine') {
            stripe.offsetArcSegments.push({
              type: 'line',
              ptStart: {
                x: goa.offsetArcSegments[j].getPointStart().x,
                y: goa.offsetArcSegments[j].getPointStart().y,
              },
              ptStop: {
                x: goa.offsetArcSegments[j].getPointStop().x,
                y: goa.offsetArcSegments[j].getPointStop().y,
              },
            });
          } else {
            stripe.offsetArcSegments.push({
              type: 'arc',
              ptStart: {
                x: goa.offsetArcSegments[j].getPointStart().x,
                y: goa.offsetArcSegments[j].getPointStart().y,
              },
              ptStop: {
                x: goa.offsetArcSegments[j].getPointStop().x,
                y: goa.offsetArcSegments[j].getPointStop().y,
              },
              r: goa.offsetArcSegments[j].r,
              largeArcFlag: goa.offsetArcSegments[j].largeArcFlag,
              sweepFlag: goa.offsetArcSegments[j].sweepFlag,
            });
          }
        }
        // console.log('stripe', stripe);
      }
    }
  }
  let _s = new _Street();
  let lw = _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);
  return obj;
}

export function dragOffsetOffsetArcPathStartHandle(obj, point) {
  var gpa = new GeomPolyArc(obj.segments);
  var len = gpa.getLength();
  var b05e = (len * obj.transitionPercentFromStart) / 4;
  var b05f = gpa.getPointAtLength(b05e);
  var b055 = new GeomPolyArc(obj.offsetArcPath.segments);
  var pt = b055.pointOffsetNormal(b05f, -obj.stopOffset);
  var pt1 = pt.clone();
  pt.offset(point[0] - pt.x, point[1] - pt.y);
  var b05a = 0;
  if (gpa.isStraight()) {
    var b058 = gpa.getOffsetsToPoint(pt1);
    var b059 = gpa.getOffsetsToPoint(pt);
    b05a = b059.normal - b058.normal;
  } else {
    var b05b = gpa.getCircleCenter();
    var sweep = gpa.segments[0].sweepFlag;
    var b05c = pt1.distance(b05b);
    var b05d = pt.distance(b05b);
    if (sweep) {
      b05a = b05c - b05d;
    } else {
      b05a = b05d - b05c;
    }
  }
  var b053 = obj.stopOffset - b05a / 2;
  if (Math.abs(b053) < 1) {
    b053 = 0;
  }
  obj.stopOffset = b053;
  obj.segments = [];
  for (let j = 0; j < gpa.segments.length; j++) {
    if (gpa.segments[j].declaredClass == 'GeomLine') {
      obj.segments.push({
        type: 'line',
        ptStart: {
          x: gpa.segments[j].getPointStart().x,
          y: gpa.segments[j].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[j].getPointStop().x,
          y: gpa.segments[j].getPointStop().y,
        },
      });
    } else {
      obj.segments.push({
        type: 'arc',
        ptStart: {
          x: gpa.segments[j].getPointStart().x,
          y: gpa.segments[j].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[j].getPointStop().x,
          y: gpa.segments[j].getPointStop().y,
        },
        r: gpa.segments[j].r,
        largeArcFlag: gpa.segments[j].largeArcFlag,
        sweepFlag: gpa.segments[j].sweepFlag,
      });
    }
  }
  updatePath(obj);
  let _s = new _Street();
  let lw = _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);
  return obj;
}

export function dragOffsetOffsetArcPathStopHandle(obj, point) {
  var gpa = new GeomPolyArc(obj.segments);
  var len = gpa.getLength();
  var b05e = len - len * ((1 - obj.transitionPercentFromStart) / 4);
  var b05f = gpa.getPointAtLength(b05e);
  var b055 = new GeomPolyArc(obj.offsetArcPath.segments);
  var pt = gpa.pointOffsetNormal(b05f, obj.stopOffset);
  var pt1 = pt.clone();
  pt.offset(point[0] - pt.x, point[1] - pt.y);
  var b05a = 0;
  if (gpa.isStraight()) {
    var b058 = gpa.getOffsetsToPoint(pt1);
    var b059 = gpa.getOffsetsToPoint(pt);
    b05a = b059.normal - b058.normal;
  } else {
    var b05b = gpa.getCircleCenter();
    var sweep = gpa.segments[0].sweepFlag;
    var b05c = pt1.distance(b05b);
    var b05d = pt.distance(b05b);
    if (sweep) {
      b05a = b05c - b05d;
    } else {
      b05a = b05d - b05c;
    }
  }
  var b053 = obj.stopOffset + b05a / 2;
  if (Math.abs(b053) < 1) {
    b053 = 0;
  }
  obj.stopOffset = b053;
  obj.segments = [];
  for (let j = 0; j < gpa.segments.length; j++) {
    if (gpa.segments[j].declaredClass == 'GeomLine') {
      obj.segments.push({
        type: 'line',
        ptStart: {
          x: gpa.segments[j].getPointStart().x,
          y: gpa.segments[j].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[j].getPointStop().x,
          y: gpa.segments[j].getPointStop().y,
        },
      });
    } else {
      obj.segments.push({
        type: 'arc',
        ptStart: {
          x: gpa.segments[j].getPointStart().x,
          y: gpa.segments[j].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[j].getPointStop().x,
          y: gpa.segments[j].getPointStop().y,
        },
        r: gpa.segments[j].r,
        largeArcFlag: gpa.segments[j].largeArcFlag,
        sweepFlag: gpa.segments[j].sweepFlag,
      });
    }
  }
  updatePath(obj);
  let _s = new _Street();
  let lw = _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);
  return obj;
}

export function dragOffsetOffsetArcPathTransitionHandle(obj, point) {
  var gpa = new GeomPolyArc(obj.segments);
  var pt = gpa.getPointAtLength(gpa.getLength() * obj.transitionPercentFromStart);
  pt.offset(point[0] - pt.x, point[1] - pt.y);
  obj.transitionPercentFromStart = gpa.getOffsetsToPoint(pt).fromStartPercent;
  obj.segments = [];
  for (let j = 0; j < gpa.segments.length; j++) {
    if (gpa.segments[j].declaredClass == 'GeomLine') {
      obj.segments.push({
        type: 'line',
        ptStart: {
          x: gpa.segments[j].getPointStart().x,
          y: gpa.segments[j].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[j].getPointStop().x,
          y: gpa.segments[j].getPointStop().y,
        },
      });
    } else {
      obj.segments.push({
        type: 'arc',
        ptStart: {
          x: gpa.segments[j].getPointStart().x,
          y: gpa.segments[j].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[j].getPointStop().x,
          y: gpa.segments[j].getPointStop().y,
        },
        r: gpa.segments[j].r,
        largeArcFlag: gpa.segments[j].largeArcFlag,
        sweepFlag: gpa.segments[j].sweepFlag,
      });
    }
  }
  updatePath(obj);
  let _s = new _Street();
  let lw = _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);
  return obj;
}

/**
 *
 * @param {*} obj
 * @param {string} stripeKey '1', '2', etc
 * @param {string} dragIndex
 * @param {number[]} point
 */
export function dragPatternBreakHandle(obj, stripeKey, dragIndex, point) {
  for (let i = 0; i < obj.components.length; i++) {
    if (obj.components[i].key.toString() === stripeKey) {
      let stripe = obj.components[i];
      var gpa = new GeomPolyArc(stripe.segments);
      var bac = gpa.getPointAtLength(stripe.primary.patterns[parseInt(dragIndex)].startPct * gpa.getLength());
      bac.offset(point[0] - bac.x, point[1] - bac.y);
      var startPct = gpa.getOffsetsToPoint(bac).fromStartPercent;
      stripe.primary.patterns[parseInt(dragIndex)].startPct = startPct;
    }
  }
  return obj;
}
