import GeomPoint from './GeomPoint';
import GeomAlign from './GeomAlign';
import GeomPathOffset from './GeomPathOffset';
import UtilityMath from './UtilityMath';

class GeomLine {
  /**
   *
   * @param {GeomPoint} ptStart
   * @param {GeomPoint} ptStop
   */
  constructor(ptStart, ptStop) {
    this.declaredClass = 'GeomLine';
    this.ptStart = ptStart.clone();
    this.ptStop = ptStop.clone();
  }

  clone() {
    return new GeomLine(this.ptStart, this.ptStop);
  }

  /**
   *
   * @param {number} offsetX
   * @param {number} offsetY
   * @returns {GeomAlign}
   */
  getAlignmentAtLength(offsetX, offsetY) {
    let spt = this.getPointStart(),
      pt = new GeomPoint(spt.x + offsetX, spt.y + offsetY),
      angle = spt.AngleFromHorizontal(this.getPointStop());
    if (0 !== angle) {
      pt.rotateRad(angle, spt);
    }
    let um = new UtilityMath();
    let rotateDeg = um.normalizeAngleDeg(um.toDegrees(angle));
    return new GeomAlign(pt, rotateDeg);
  }

  getLength() {
    return this.ptStart.distance(this.ptStop);
  }

  getMidPoint() {
    return this.ptStart.midPoint(this.ptStop);
  }

  /**
   * Get point offset from the line
   * @param {GeomPoint} pt
   * @returns {GeomPathOffset}
   */
  getOffsetsToPoint(pt) {
    var tpt = pt.clone(),
      length = this.getLength(),
      spt = this.getPointStart(),
      a = spt.AngleToHorizontal(this.getPointStop());
    if (0 !== a) {
      tpt.rotateRad(a, spt);
    }
    var fromStart = tpt.x - spt.x;
    var fromStartPercent = 0;
    if (0 != length) {
      fromStartPercent = fromStart / length;
    }
    return new GeomPathOffset(fromStart, fromStartPercent, tpt.y - spt.y);
  }

  getPartialSvgPathData(startLen, toLen, start) {
    var pathData = '',
      spt,
      ptTo = toLen < 0 ? this.ptStop : this.getPointAtLength(toLen);
    if (startLen > 0 || start) {
      spt = this.getPointAtLength(startLen);
    }
    if (spt) {
      pathData = 'M' + spt.toString();
      pathData += ' L' + ptTo.toString();
    } else {
      pathData = 'L' + ptTo.toString();
    }
    return pathData;
  }

  /**
   * 由开始点沿Line路径返回给定距离的点
   * @param {number} length 距离
   * @returns {GeomPoint}
   */
  getPointAtLength(length) {
    var pt = this.getPointStart();
    pt.offsetToward(this.getPointStop(), length);
    return pt;
  }

  getPointClosest(pt) {
    var gpo = this.getOffsetsToPoint(pt);
    if (gpo.fromStart < 0) {
      return this.getPointStart();
    } else {
      if (gpo.fromStart > this.getLength()) {
        return this.getPointStop();
      } else {
        return this.getPointAtLength(gpo.fromStart);
      }
    }
  }

  /**
   * get the start point
   * @returns {GeomPoint}
   */
  getPointStart() {
    return this.ptStart.clone();
  }

  /**
   * get the stop point
   * @returns {GeomPoint}
   */
  getPointStop() {
    return this.ptStop.clone();
  }

  /**
   * 获取 segment 路径数据
   * @param {boolean} isMove
   * @returns {string}
   */
  getSegmentPathData(isMove) {
    var pathData = '';
    if (isMove) {
      pathData = 'M' + this.ptStart.toString();
    }
    if (pathData.length > 0) {
      pathData += ' ';
    }
    pathData += 'L' + this.ptStop.toString();
    return pathData;
  }

  getSvgPathData() {
    return this.getSegmentPathData(true);
  }

  inflate(distance) {
    if (this.ptStart.isNotEqual(this.ptStop)) {
      this.ptStart.offsetToward(this.ptStop, -distance);
      this.ptStop.offsetToward(this.ptStart, -distance);
    }
  }

  isEqual(ln) {
    return this.ptStart.isEqual(ln.ptStart) && this.ptStop.isEqual(ln.ptStop);
  }

  isHorizontal(align) {
    if (this.ptStart.y === this.ptStop.y) {
      if (align) {
        align.scalar = this.ptStart.y;
      }
      return true;
    }
    return false;
  }

  isVertical(align) {
    if (this.ptStart.x === this.ptStop.x) {
      if (align) {
        align.scalar = this.ptStart.x;
      }
      return true;
    }
    return false;
  }

  /**
   *
   * @param {GeomLine} other
   * @returns {GeomPoint | undefined}
   */
  getPointIntersect(other) {
    // console.log('this', this);
    // console.log('other', other);
    var P,
      P1,
      P2,
      P3,
      P4,
      A,
      B,
      C,
      crossProductBC,
      crossProductAB,
      crossProductAC,
      a485 = {
        scalar: undefined,
      },
      a486 = {
        scalar: undefined,
      };
    if (
      Math.min(this.ptStart.x, this.ptStop.x) > Math.max(other.ptStart.x, other.ptStop.x) ||
      Math.max(this.ptStart.x, this.ptStop.x) < Math.min(other.ptStart.x, other.ptStop.x) ||
      Math.min(this.ptStart.y, this.ptStop.y) > Math.max(other.ptStart.y, other.ptStop.y) ||
      Math.max(this.ptStart.y, this.ptStop.y) < Math.min(other.ptStart.y, other.ptStop.y)
    ) {
      // console.log('1');
      return undefined;
    }
    var pt = new GeomPoint(0, 0);
    P1 = pt.getVector(this.ptStart);
    P2 = pt.getVector(this.ptStop);
    P3 = pt.getVector(other.ptStart);
    P4 = pt.getVector(other.ptStop);
    A = P2.subtract(P1);
    B = P3.subtract(P4);
    C = P1.subtract(P3);
    crossProductBC = B.vy * C.vx - B.vx * C.vy;
    crossProductAB = A.vy * B.vx - A.vx * B.vy;
    if (0 === crossProductAB) {
      // console.log('2');
      return undefined;
    }
    if (crossProductAB > 0) {
      if (crossProductBC < 0 || crossProductBC > crossProductAB) {
        // console.log('3');
        return undefined;
      }
    } else {
      if (crossProductBC > 0 || crossProductBC < crossProductAB) {
        // console.log('4');
        return undefined;
      }
    }
    crossProductAC = A.vx * C.vy - A.vy * C.vx;
    // console.log('P1', P1);
    // console.log('P2', P2);
    // console.log('A', A);
    // console.log('C', C);
    // console.log('normal crossProductAC', crossProductAC);
    if (crossProductAB > 0) {
      if (crossProductAC < 0 || crossProductAC > crossProductAB) {
        // console.log('5');
        return undefined;
      }
    } else {
      if (crossProductAC > 0 || crossProductAC < crossProductAB) {
        // console.log('6');
        return undefined;
      }
    }
    if (this.isHorizontal(a486)) {
      if (!other.isVertical(a485)) {
        a485.scalar = a487().vx;
      }
    } else {
      if (this.isVertical(a485)) {
        if (!other.isHorizontal(a486)) {
          a486.scalar = a487().vy;
        }
      } else {
        P = a487();
        a485.scalar = P.vx;
        a486.scalar = P.vy;
      }
    }
    /** @returns {GeomVector} */
    function a487() {
      A.scale(crossProductBC / crossProductAB);
      return P1.add(A);
    }
    return new GeomPoint(a485.scalar, a486.scalar);
  }

  offset(cx, cy) {
    this.ptStart.offset(cx, cy);
    this.ptStop.offset(cx, cy);
  }

  /**
   *
   * @param {number} distance
   */
  offsetNormal(distance) {
    if (!this.ptStart.isEqual(this.ptStop)) {
      if (this.isHorizontal()) {
        this.offset(0, distance * (this.ptStart.x < this.ptStop.x ? 1 : -1));
      } else {
        if (this.isVertical()) {
          this.offset(distance * (this.ptStart.y < this.ptStop.y ? -1 : 1), 0);
        } else {
          var ga = this.getAlignmentAtLength(0, distance);
          if (ga) {
            var pt = ga.ptAlign.subtract(this.ptStart);
            if (pt) {
              this.offset(pt.cx, pt.cy);
            }
          }
        }
      }
    }
  }

  /**
   * 获取这条线的法线上的点
   * @param {GeomPoint} pt
   * @param {number} offset 到达该线的距离
   * @returns {GeomPoint}
   */
  pointOffsetNormal(pt, offset) {
    var ptClone = pt.clone(),
      vector;
    if (this.isHorizontal()) {
      ptClone.y += offset * (this.ptStart.x < this.ptStop.x ? 1 : -1);
    } else {
      if (this.isVertical()) {
        ptClone.x += offset * (this.ptStart.y > this.ptStop.y ? 1 : -1);
      } else {
        vector = this.getPointStart()
          .getVector(this.getPointStop())
          .normal();
        vector.scale(offset / vector.magnitude());
        ptClone.offset(vector.vx, vector.vy);
      }
    }
    return ptClone;
  }

  length() {
    return this.ptStart.distance(this.ptStop);
  }

  rotate(angle, ptPin) {
    if (!this.ptStart.isEqual(ptPin)) {
      this.ptStart.rotate(angle, ptPin);
    }
    if (!this.ptStop.isEqual(ptPin)) {
      this.ptStop.rotate(angle, ptPin);
    }
  }

  /**
   * 偏移
   * @param {number} cx
   * @param {number} cy
   */
  offset(cx, cy) {
    this.ptStart.offset(cx, cy);
    this.ptStop.offset(cx, cy);
  }

  /**
   * 极性
   * @param {GeomPoint} ptPin
   * @param {number} angle
   * @param {number} length
   * @returns {GeomPoint}
   */
  polar(ptPin, angle, length) {
    var pt = ptPin.clone();
    var sp = this.ptStart;
    sp = sp.clone();
    var a = sp.angle(this.ptStop);
    if (a != 0) {
      pt.rotate(a, sp);
    }
    pt.x = pt.x + Math.cos(angle) * length;
    pt.y = pt.y + Math.sin(angle) * length;
    if (a != 0) {
      pt.rotate(-a, sp);
    }
    return pt;
  }

  reverse() {
    var pt = this.ptStart;
    this.ptStart = this.ptStop;
    this.ptStop = pt;
  }

  setStart(ptStart) {
    this.ptStart = ptStart.clone();
  }

  setStop(ptStop) {
    this.ptStop = ptStop.clone();
  }

  startPoint() {
    return this.ptStart.clone();
  }

  stopPoint() {
    return this.ptStop.clone();
  }

  transform(m) {
    this.ptStart.transform(m);
    this.ptStop.transform(m);
  }
  trim(a49e, a49f) {
    if (a49e < 0) {
      a49e = 0;
    }
    if (a49f < 0) {
      a49f = 0;
    }
    if (a49e === 0 && a49f === 0) {
      return;
    }
    if (a49e + a49f > this.getLength()) {
      return;
    }
    if (this.ptStart.isNotEqual(this.ptStop)) {
      if (a49e > 0) {
        this.ptStart.offsetToward(this.ptStop, a49e);
      }
      if (a49f > 0) {
        this.ptStop.offsetToward(this.ptStart, a49f);
      }
    }
  }
}

export default GeomLine;
