import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';

function TooltipWrapper({ children, device, ...tooltipProps }) {
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (device === 'touch') {
      setShow(false);
    } else if (device === 'untouch') {
      setShow(true);
    }
  }, [device]);
  return show ? (
    <Tooltip destroyTooltipOnHide {...tooltipProps}>
      {children}
    </Tooltip>
  ) : (
    children
  );
}

export default connect(
  state => ({
    device: state.app.device,
  }),
  null
)(TooltipWrapper);
