import React, { useState, useEffect } from 'react';
import {
  FindCircle,
  FindCircleLinePoint,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetCenter,
  GetCirclePointK,
  IsArcDirection,
  IsBigArc,
  IsEqualPoint,
  LengthArc,
  LengthBetweenPoints,
  LinePointsLength,
  ScalePoint,
  TowPointVerticalLengthByStatus,
} from '@src/data/CommonFunc';
import { GetShapeStyle } from '@src/data/CommonProps';
import { DASH_ARRAY_TYPE } from '@src/constant';

const GUIDE_LINE_SPACE = 100;

function GuideLine({ p1, p2 }) {
  const [D, setD] = useState([]);

  useEffect(() => {
    render();
  }, []);

  const render = () => {
    let d = [...D];
    if (p2 == null || IsEqualPoint(p1, p2)) {
      let pointLeft = [p1[0] - GUIDE_LINE_SPACE, p1[1]];
      let poingRight = [p1[0] + GUIDE_LINE_SPACE, p1[1]];
      let pointTop = [p1[0], p1[1] - GUIDE_LINE_SPACE];
      let pointBottom = [p1[0], p1[1] + GUIDE_LINE_SPACE];
      d.push('M' + String(pointLeft[0]) + ',' + String(pointLeft[1]));
      d.push('L' + String(poingRight[0]) + ',' + String(poingRight[1]));
      d.push('M' + String(pointTop[0]) + ',' + String(pointTop[1]));
      d.push('L' + String(pointBottom[0]) + ',' + String(pointBottom[1]));
    } else {
      const length = LengthBetweenPoints(p1, p2);
      let scale = (length + GUIDE_LINE_SPACE) / length;
      let pointLeft = ScalePoint(p1, scale, scale, p2);
      let pointRight = ScalePoint(p2, scale, scale, p1);
      d.push('M' + String(pointLeft[0]) + ',' + String(pointLeft[1]));
      d.push('L' + String(pointRight[0]) + ',' + String(pointRight[1]));
    }
    setD(d);
  };

  return (
    <g id="guideLine">
      <path d={D.join(' ')} {...GetShapeStyle().GUIDELINE} strokeDasharray={DASH_ARRAY_TYPE.dashdot} />
    </g>
  );
}

export default GuideLine;
