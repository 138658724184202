import React from 'react';
import { message as antdMessage, Icon } from 'antd';
import './index.scss';

const popup = (value, callee, icon) => {
  callee({
    content: (
      <span className="lncd-message-content" onClick={antdMessage.destroy}>
        {value}
      </span>
    ),
    icon: <Icon className="lncd-message-icon" type={icon} onClick={antdMessage.destroy} />,
  });
};

export const message = {
  success: value => popup(value, antdMessage.success, 'check-circle'),
  error: value => popup(value, antdMessage.error, 'close-circle'),
  warning: value => popup(value, antdMessage.warning, 'info-circle'),
  destroy: antdMessage.destroy,
};
