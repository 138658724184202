export const EVENT_EMIT_TYPE = {
  HOMEFUNC: 'homefunction',
  MANAGEFUNC: 'manageFunction',
  TOPMENUFUNC: 'topmenufunction',
  SLIDERSHAPEFUNC: 'slidershapefunction',
  SLIDERSYMBOLFUNC: 'slidersymbolfunction',
  STARTFUNC: 'startfunction',
  USING_TEMPLATE: 'usingTemplate',
  HOME_BUTTON_STATE: 'setHomeButtonState',
  STREET: 'street',
  UPDATE_DIAGRAM: 'updateDiagram',
  OBJECT_UPDATED: 'objectupdated',
  ZOOM: 'zoom',
};

export const ROAD_CONSTANT = {
  SELECT_SYMBOL_ITEM: 'selectSymbolItem',
};
