import * as React from 'react';

function SvgIconTree(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19.843 17.376L17.143 14h.419a.999.999 0 00.819-1.573l-2.449-3.498a1.001 1.001 0 00.449-1.502l-3.5-5c-.374-.535-1.265-.535-1.639 0l-3.5 5a1 1 0 00.449 1.502l-2.449 3.498A1 1 0 006.563 14h.419l-2.7 3.376c-.24.301-.287.711-.121 1.058.167.345.517.566.902.566h6v5h2v-5h6c.384 0 .735-.221.901-.566a1.002 1.002 0 00-.121-1.058zM7.143 17l2.7-3.375c.24-.3.287-.712.121-1.059A1.002 1.002 0 009.063 12h-.58l2.399-3.427a1 1 0 00-.449-1.502l1.629-2.327 1.629 2.327a1 1 0 00-.449 1.502l2.4 3.427h-.58a1.001 1.001 0 00-.78 1.625l2.7 3.375H7.143z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconTree;
