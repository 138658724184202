import * as React from 'react';

function SvgImageArrowPositionLeft(props) {
  return (
    <svg width={30} height={10} {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M9 3h21v3H9z" />
        <path d="M0 4.295L15 0v9.288z" />
      </g>
    </svg>
  );
}

export default SvgImageArrowPositionLeft;
