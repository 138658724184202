import { CURSOR } from '@src/constant';

export const setCurrentState = state => ({
  type: 'SET_CURRENT_STATE',
  state,
});

export const setAnchorsType = anchor => ({
  type: 'SET_ANCHORS_TYPE',
  anchor,
});

/**
 *
 * @param {CURSOR} cursor
 */
export const setCursor = cursor => ({
  type: 'SET_CURSOR',
  cursor,
});
