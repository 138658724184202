import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import GeomPolyArc from '@src/data/GeomPolyArc';
import * as workData from '@src/data/WorkData';

function CurbReturn({ object }) {
  useEffect(() => {
    let street = workData.getObject(object.streetStripes[0].idStreet);
    if (street) {
      object.roadLayer = street.roadLayer;
    }
  }, []);

  const getStreetStripes = useCallback(() => {
    let streetStripeList = [];
    for (let stripe of object.streetStripes) {
      streetStripeList.push(
        <stripe
          key={stripe.key}
          nidstreet={stripe.idStreet}
          nkeystripe={stripe.keyStripe}
          sptsetback={stripe.ptSetback.toString()}
          napexoffset={stripe.apexOffset}
          nsetbackoffset={stripe.setbackOffset}
          nsetbackpref={stripe.setbackPref}
        ></stripe>
      );
    }
    return streetStripeList;
  }, [object.streetStripes]);

  let hp = [];
  let getPathD = () => {
    var gpa = new GeomPolyArc(object.segments);
    hp.push([gpa.getPointStart().x, gpa.getPointStart().y]);
    hp.push([gpa.getPointStop().x, gpa.getPointStop().y]);
    hp.push([gpa.getPointStart().x, gpa.getPointStart().y]);
    hp.push([gpa.getPointStop().x, gpa.getPointStop().y]);
    object.handlepoint = hp;
    let path = gpa.getSvgPathData();
    return path;
  };

  return (
    <g id={object.functype}>
      <metadata ssurfacetype={object.surfaceType} sptapex={object.ptApex.toString()} sstrokepattern="solid">
        <streetstripes> {getStreetStripes()} </streetstripes>
      </metadata>
      <path
        ttn-role="core"
        ttn-agent="polyArc"
        d={getPathD()}
        strokeOpacity="0"
        strokeDasharray="none"
        strokeLinecap="butt"
        fill="none"
      ></path>
      <path ttn-role="visibleCore" ttn-agent="polyArc" d={getPathD()} fill="none"></path>
    </g>
  );
}

export default CurbReturn;
