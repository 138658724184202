const ns = '[App] ';

export const SET_HEADER_HEIGHT = `${ns}set header height`;
export const ON_WINDOW_RESIZE = `${ns}on window resize`;
export const SET_PROPS_MENU_OPEN = `${ns}set props menu open status`;
export const SET_COLLAPSED = `${ns}on collapsed`;
export const SET_TEMPLATES_STATUS = `${ns}set templates status`;
export const SET_SYMBOLS_STATUS = `${ns}set symbols status`;
export const TOGGLE_NIGHT_MODE = `${ns}toggle day/night mode`;
export const SET_LOADING = `${ns}set loadding`;
export const SET_APP_DEVICE = `${ns}set app device`;
export const SET_SHOW_PROGRESS = `${ns}whether to show progress bar`;
export const SET_PROGRESS_TOTAL = `${ns}set progress total value`;
export const SET_PROGRESS_CURRENT = `${ns}set progress current value`;
