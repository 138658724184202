import * as React from 'react';

function SvgIconPathSquare(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M21 8a1 1 0 001-1V3a1 1 0 00-1-1h-4a1 1 0 00-1 1v1H8V3a1 1 0 00-1-1H3a1 1 0 00-1 1v4a1 1 0 001 1h1v8H3a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-1h8v1a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1h-1V8h1zm-3-4h2v2h-2V4zM4 4h2v2H4V4zm2 16H4v-2h2v2zm14 0h-2v-2h2v2zm-2-4h-1a1 1 0 00-1 1v1H8v-1a1 1 0 00-1-1H6V8h1a1 1 0 001-1V6h8v1a1 1 0 001 1h1v8z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconPathSquare;
