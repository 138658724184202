import { ANCHORS_TYPE } from '@src/constant';
import { getCircleHandlePoint, getSquareHandlePoint } from '@src/data/AnchorsPoint';
import { initMeasurementCoords } from '@src/utils';
import React from 'react';
import PropTypes from 'prop-types';

function SelectMeasurement(props) {
  const { object } = props;

  const { endpoints, pc } = initMeasurementCoords(object);

  const head = endpoints.get('head');
  const rotationPoint = pc.clone();
  rotationPoint.offsetToward(head.point, head.length * 0.6);
  let rotationAngle = 0;

  return (
    <g id="select-StationLine">
      {getSquareHandlePoint('TransformHeadPosition', endpoints.get('head').point, rotationAngle)}
      {getSquareHandlePoint('TransformTailPosition', endpoints.get('tail').point, rotationAngle)}
      {getCircleHandlePoint(ANCHORS_TYPE.ANGLE, rotationPoint)}
    </g>
  );
}

SelectMeasurement.propTypes = {
  object: PropTypes.shape({
    functype: PropTypes.string.isRequired,
    lenZeroHead: PropTypes.number.isRequired,
    lenZeroTail: PropTypes.number.isRequired,
    handlepoint: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number.isRequired).isRequired).isRequired,
    rotation: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default SelectMeasurement;
