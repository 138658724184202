import { convertToFeet, convertToStandard, INCHES_PER_FOOT } from '@src/data/CommonFunction';
import { convertToUserPoint } from '@src/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// TODO: add fn as value to determine if the line will render a text
// key is inches per cell
const rulerConfig = new Map([
  [1],
  [6],
  [1],
  [10 * INCHES_PER_FOOT],
  [20 * INCHES_PER_FOOT],
  [50 * INCHES_PER_FOOT],
  [100 * INCHES_PER_FOOT],
  [200 * INCHES_PER_FOOT],
  [500 * INCHES_PER_FOOT],
  [1000 * INCHES_PER_FOOT],
  [5000 * INCHES_PER_FOOT],
]);

const GridLayer = styled.g`
  pointer-events: 'none';
`;

const Grid = props => {
  const { isShowGrid, appWidth, canvasHeight, matrix } = props;

  // TODO: constant?
  let fill = 'rgb(119,119,119)';
  let stroke = 'rgb(179,179,179)';

  const [minp, setMinp] = useState([]);
  const [maxp, setMaxp] = useState([]);
  const [spaceSize, setSpaceSize] = useState(Number.POSITIVE_INFINITY);

  const unitPerPixel = useMemo(() => {
    return 1.0 / matrix[0];
  }, [matrix]);

  useEffect(() => {
    setMinp(convertToUserPoint(matrix, 0, 0));
    setMaxp(convertToUserPoint(matrix, appWidth, canvasHeight));

    // update measurement text spaces while scale changes
    let newSpaceSize = 0;
    // pixel per inch
    const pxPerFt = matrix[0] * INCHES_PER_FOOT;
    if (pxPerFt > 5) {
      newSpaceSize = 5;
    } else if (pxPerFt > 15) {
      newSpaceSize = 1;
    } else if (pxPerFt > 120) {
      newSpaceSize = 1 / 12;
    } else {
      let ss = [10, 20, 50, 100, 200, 500, 1000, 5000];
      for (let i = 0; i < ss.length; i++) {
        if (unitPerPixel * 25 <= convertToStandard(ss[i])) {
          newSpaceSize = ss[i];
          break;
        }
      }
    }
    setSpaceSize(newSpaceSize);
  }, [appWidth, canvasHeight, matrix]);

  const getGrid = useCallback(
    gridType => {
      const [xmin, ymax] = minp;
      const [xmax, ymin] = maxp;
      const fontSize = unitPerPixel * 10;
      const stripeSize = (fontSize * 3) / 2;

      if (gridType === 'x') {
        let xcount = Math.ceil((appWidth * unitPerPixel) / convertToStandard(spaceSize)) + 1;
        const xStart = Math.floor(convertToFeet(xmin) / spaceSize) * spaceSize;
        if (!Number.isNaN(xStart)) {
          let D = '';
          let texts = [];
          for (let i = 0; i < xcount; i++) {
            let x = convertToStandard(xStart + i * spaceSize);
            // console.log('xStart', xStart);
            // console.log('count', i * spaceSize);
            // console.log('x', x);
            D += `M${x},${ymax.toString()} L${x},${ymin.toString()}`;
            let degreeText = Math.abs(x);
            degreeText = Math.round(convertToFeet(degreeText)).toString();
            if (degreeText == 0) degreeText += ' ft';
            if (x + convertToStandard(spaceSize / 2) <= xmax) {
              texts.push({
                x,
                y: -ymax + fontSize,
                degreeText,
              });
            }
          }

          return (
            <g className="grid-layer-x">
              <line
                x1={xmin}
                y1={ymax - stripeSize / 2}
                x2={xmax}
                y2={ymax - stripeSize / 2}
                strokeWidth={stripeSize}
                stroke="var(--color-canvas-bg)"
              />
              <path d={D} strokeWidth={unitPerPixel * 0.5} stroke={stroke} />
              <g className="grid-layer-x-text" transform="scale(1, -1)">
                {texts.map((t, i) => (
                  <text key={i} x={t.x} y={t.y} fill={fill} fontSize={fontSize} textAnchor="start">
                    {t.degreeText}
                  </text>
                ))}
              </g>
            </g>
          );
        }
      } else if (gridType === 'y') {
        let ycount = Math.ceil((canvasHeight * unitPerPixel) / convertToStandard(spaceSize)) + 1;
        const yStart = Math.floor(convertToFeet(ymin) / spaceSize) * spaceSize;
        if (!Number.isNaN(yStart)) {
          let D = '';
          let texts = [];
          for (let j = 0; j < ycount; j++) {
            let y = convertToStandard(yStart + j * spaceSize);
            D += `M${xmin.toString()},${y} L${xmax.toString()},${y}`;
            let degreeText = Math.abs(y);
            degreeText = Math.round(convertToFeet(degreeText)).toString();
            if (degreeText == 0) degreeText += ' ft';
            if (y + convertToStandard(spaceSize / 2) <= ymax) {
              texts.push({
                x: xmax,
                y,
                degreeText,
              });
            }
          }

          return (
            <g key="grid-y" className="grid-layer-y">
              <line
                x1={xmax - stripeSize / 2}
                y1={ymax}
                x2={xmax - stripeSize / 2}
                y2={ymin}
                strokeWidth={stripeSize}
                stroke="var(--color-canvas-bg)"
              />
              <path d={D} strokeWidth={unitPerPixel * 0.5} stroke={stroke} />
              <g className="grid-layer-y-text">
                {texts.map((t, i) => (
                  <text
                    key={i}
                    x={-t.x}
                    y={t.y}
                    fill={fill}
                    fontSize={fontSize}
                    textAnchor="end"
                    transform={`rotate(180, ${t.x}, ${t.y}) scale(-1, 1)`}
                  >
                    {t.degreeText}
                  </text>
                ))}
              </g>
            </g>
          );
        }
      }
      return null;
    },
    [minp, maxp, spaceSize, matrix]
  );

  // FIXME: enable the edges check
  const calcPos = () => {
    // if (xmax > CANVAS.CANVASWIDTH) {
    //   xmax = CANVAS.CANVASWIDTH;
    // }
    // if (xmin < -CANVAS.CANVASWIDTH) {
    //   xmin = -CANVAS.CANVASWIDTH;
    // }
    // if (ymin > CANVAS.CANVASHEIGHT) {
    //   ymin = CANVAS.CANVASHEIGHT;
    // }
    // if (ymax < -CANVAS.CANVASHEIGHT) {
    //   ymax = -CANVAS.CANVASHEIGHT;
    // }
    // return {
    //   left: convertToFeet(xmin),
    //   top: convertToFeet(ymin),
    // };
  };

  if (!isShowGrid) return null;
  if (!appWidth || !canvasHeight || minp.length === 0 || maxp.length === 0) return null;

  const [xmin, ymax] = minp;
  const [xmax, ymin] = maxp;

  return (
    <GridLayer id="grid-layer">
      {getGrid('x')}
      {getGrid('y')}
      <React.Fragment>
        <line x1={0} y1={ymax} x2={0} y2={ymin} strokeWidth={1 * unitPerPixel} stroke="#999" />
        <line x1={xmin} y1={0} x2={xmax} y2={0} strokeWidth={1 * unitPerPixel} stroke="#999" />
        <circle x={0} y={0} r={2.5 * unitPerPixel} fill="#999" />
      </React.Fragment>
    </GridLayer>
  );
};

const mapStateToProps = state => ({
  isShowGrid: state.canvas.isShowGrid,
  appWidth: state.app.appWidth,
  canvasHeight: state.app.canvasHeight,
  matrix: state.transform.matrix,
});

export default connect(
  mapStateToProps,
  null
)(Grid);
