import React from 'react';
import {
  LengthBetweenPoints,
  IsReverse,
  IsUnderLine,
  ByTime,
  FindCircleByR,
  FindCircle,
  IsZero,
  IsBigArc,
} from '@src/data/CommonFunc';
import {
  GetShapes,
  GetPathArcD,
  GetPathArcD1,
  GetLineHeightPoints,
  IsPointUnderPoints,
  isAcrossDataUseful,
  sortStreetCrossPointsToClockwise,
} from '@src/data/BusinessFun';
import {
  GetBikeLaneCount,
  GetMaxHeight,
  GetStreetLineBegin,
  GetStreetLinePoints,
  GetStreetMaxPoints,
} from '@src/actions/StreetFunHandle';
import {
  GetOffArcCenterLinePoints,
  StreetOffArcLanesShape,
  GetOffarcBEPoints,
  offArcPoints,
  GetoffArcHeightPoints,
  GetOffArcLinePath,
  GetOffArcAreaPath,
} from '@src/actions/StreetOffArcFunHandle';
import { GetShapeStyle } from '@src/data/CommonProps';
import StreetOffsetLine from './StreetOffsetLine';
import {
  GetShapePath,
  GetShapeArc,
  GetShapeLine,
  GetShapeThreeTriangle,
  GetShapeTowBesselPath,
  GetShapeClosePath,
} from '../ShapeFun';
import { GetStreetText } from '@src/actions/ShapeTextHandle';
import { ANCHORS_TYPE, COLOR_TYPE, STREET_LINE_TYPE, STREET_SPACE } from '@src/constant';

class StreetOffset extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps({ object }) {
    this.init(object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    let maxheight = GetMaxHeight(object);
    let maxTopPoints = GetStreetMaxPoints(object, maxheight / 2, true);
    let linepoints = [];
    let beginStreetLine = GetStreetLineBegin(object.groupdata);
    linepoints[beginStreetLine] = maxTopPoints;
    let i = beginStreetLine;
    let lineData = object.groupdata;
    while (i >= 0 && i < lineData.length) {
      let laneHeight = object.lanewidth;
      if (i < object.lanes.length) {
        laneHeight = object.lanes[i].width;
      }
      let bottomLine = lineData[i].bottomline;
      if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.TWOWAY)
      ) {
        laneHeight = laneHeight + STREET_SPACE.TWOWAY;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.PAINTED)
      ) {
        laneHeight = laneHeight + STREET_SPACE.PAINTED;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.WIDE)
      ) {
        laneHeight = laneHeight + STREET_SPACE.WIDE;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, false);
        }
      }
      if (bottomLine != -1) linepoints[bottomLine] = GetStreetLinePoints(linepoints[i], laneHeight, false);
      i = bottomLine;
    }
    let arcPoints = offArcPoints(object.handlepoint, object.offarcheight, object.offarccenter);
    if (isFirst) {
      this.state = {
        object: object,
        maxheight: maxheight,
        linepoints: linepoints, //主要用到该变量 用于 每个道路的 锚点
        arcpoints: arcPoints,
      };
    } else {
      this.setState({
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
        arcpoints: arcPoints,
      });
    }
  };

  getRect = shapes => {
    let fillcolor = COLOR_TYPE.TRANSPARENT;
    if (this.state.object.selectflag) fillcolor = COLOR_TYPE.LIGHTGRAY;
    let topLine = GetStreetLineBegin(this.state.object.groupdata);
    let D = [];
    GetOffArcAreaPath(
      D,
      this.state.linepoints[topLine],
      this.state.linepoints[1],
      this.state.arcpoints,
      this.state.object.offarcheight
    );
    shapes.push(
      GetShapePath(D, 'streetOffarc-backgroud', true, {
        stroke: fillcolor,
        strokeWidth: 1,
        fill: fillcolor,
      })
    );
  };

  getLaneShape = shapes => {
    let lineData = this.state.object.groupdata;
    let obj = this.state.object;
    let i = GetStreetLineBegin(lineData);
    let archeight = this.state.object.offarcheight;
    let arccenter = this.state.object.offarccenter;
    while (i >= 0 && i < lineData.length) {
      let isUnder = IsPointUnderPoints(
        obj.handlepoint[0],
        obj.handlepoint[1],
        obj.handlepoint[2],
        this.state.linepoints[i][1]
      );
      let lineHeight = LengthBetweenPoints(this.state.linepoints[i][0], obj.handlepoint[0]);
      let arcPoints = GetLineHeightPoints(
        this.state.arcpoints[0],
        this.state.arcpoints[1],
        this.state.arcpoints[2],
        lineHeight,
        !isUnder
      );
      let commonPropsSelect = {
        stroke: lineData[i].selectline ? COLOR_TYPE.GREEN : COLOR_TYPE.NONE,
        strokeWidth: lineData[i].strokewidth + 5,
        strokeDasharray: DASH_ARRAY_TYPE.solid,
        fill: COLOR_TYPE.NONE,
      };
      let D = [];
      GetOffArcLinePath(D, obj.offarcheight, this.state.linepoints[i], arcPoints);
      shapes.push(<path d={D.join(' ')} {...commonPropsSelect} key={'lineselect' + String(i)} />);

      if (lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY) {
        let linepontTop = lineData[i].specialtop;
        let linepontBottom = lineData[i].specialbottom;
        let topLineHeight = LengthBetweenPoints(this.state.linepoints[linepontTop][0], obj.handlepoint[0]);
        let topIsUnder = IsPointUnderPoints(
          obj.handlepoint[0],
          obj.handlepoint[1],
          obj.handlepoint[2],
          this.state.linepoints[linepontTop][0]
        );
        let bottomLineHeight = LengthBetweenPoints(this.state.linepoints[linepontBottom][0], obj.handlepoint[0]);
        let bottomIsUnder = IsPointUnderPoints(
          obj.handlepoint[0],
          obj.handlepoint[1],
          obj.handlepoint[2],
          this.state.linepoints[linepontBottom][0]
        );
        let topArcPoints = GetLineHeightPoints(
          this.state.arcpoints[0],
          this.state.arcpoints[1],
          this.state.arcpoints[2],
          topLineHeight,
          !topIsUnder
        );
        let bottomArcPoints = GetLineHeightPoints(
          this.state.arcpoints[0],
          this.state.arcpoints[1],
          this.state.arcpoints[2],
          bottomLineHeight,
          !bottomIsUnder
        );
        shapes.push(
          <StreetOffsetLine
            points={this.state.linepoints[linepontTop]}
            linedata={lineData[linepontTop]}
            arcpoints={topArcPoints}
            lineheight={topLineHeight}
            istop={!topIsUnder}
            key={'lanetowaytop+' + String(i)}
            archeight={archeight}
            arccenter={arccenter}
          />
        );
        shapes.push(
          <StreetOffsetLine
            points={this.state.linepoints[linepontBottom]}
            linedata={lineData[linepontBottom]}
            arcpoints={bottomArcPoints}
            lineheight={bottomLineHeight}
            istop={!bottomIsUnder}
            key={'lanetowaybottom+' + String(i)}
            archeight={archeight}
            arccenter={arccenter}
          />
        );
      } else if (lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED) {
        let linepontTop = lineData[i].specialtop;
        let linepontBottom = lineData[i].specialbottom;
        let topLineHeight = LengthBetweenPoints(this.state.linepoints[linepontTop][0], obj.handlepoint[0]);
        let topIsUnder = IsPointUnderPoints(
          obj.handlepoint[0],
          obj.handlepoint[1],
          obj.handlepoint[2],
          this.state.linepoints[linepontTop][0]
        );
        let bottomLineHeight = LengthBetweenPoints(this.state.linepoints[linepontBottom][0], obj.handlepoint[0]);
        let bottomIsUnder = IsPointUnderPoints(
          obj.handlepoint[0],
          obj.handlepoint[1],
          obj.handlepoint[2],
          this.state.linepoints[linepontBottom][0]
        );
        let topArcPoints = GetLineHeightPoints(
          this.state.arcpoints[0],
          this.state.arcpoints[1],
          this.state.arcpoints[2],
          topLineHeight,
          !topIsUnder
        );
        let bottomArcPoints = GetLineHeightPoints(
          this.state.arcpoints[0],
          this.state.arcpoints[1],
          this.state.arcpoints[2],
          bottomLineHeight,
          !bottomIsUnder
        );
        shapes.push(
          <StreetOffsetLine
            points={this.state.linepoints[linepontTop]}
            linedata={lineData[linepontTop]}
            arcpoints={topArcPoints}
            lineheight={topLineHeight}
            istop={!topIsUnder}
            key={'lanetowaytop+' + String(i)}
            archeight={archeight}
            arccenter={arccenter}
          />
        );
        shapes.push(
          <StreetOffsetLine
            points={this.state.linepoints[linepontBottom]}
            linedata={lineData[linepontBottom]}
            arcpoints={bottomArcPoints}
            lineheight={bottomLineHeight}
            istop={!bottomIsUnder}
            key={'lanetowaybottom+' + String(i)}
            archeight={archeight}
            arccenter={arccenter}
          />
        );
      } else if (lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE) {
        let linepontTop = lineData[i].specialtop;
        let linepontBottom = lineData[i].specialbottom;
        let topLineHeight = LengthBetweenPoints(this.state.linepoints[linepontTop][0], obj.handlepoint[0]);
        let topIsUnder = IsPointUnderPoints(
          obj.handlepoint[0],
          obj.handlepoint[1],
          obj.handlepoint[2],
          this.state.linepoints[linepontTop][0]
        );
        let bottomLineHeight = LengthBetweenPoints(this.state.linepoints[linepontBottom][0], obj.handlepoint[0]);
        let bottomIsUnder = IsPointUnderPoints(
          obj.handlepoint[0],
          obj.handlepoint[1],
          obj.handlepoint[2],
          this.state.linepoints[linepontBottom][0]
        );
        let topArcPoints = GetLineHeightPoints(
          this.state.arcpoints[0],
          this.state.arcpoints[1],
          this.state.arcpoints[2],
          topLineHeight,
          !topIsUnder
        );
        let bottomArcPoints = GetLineHeightPoints(
          this.state.arcpoints[0],
          this.state.arcpoints[1],
          this.state.arcpoints[2],
          bottomLineHeight,
          !bottomIsUnder
        );
        shapes.push(
          <StreetOffsetLine
            points={this.state.linepoints[linepontTop]}
            linedata={lineData[linepontTop]}
            arcpoints={topArcPoints}
            lineheight={topLineHeight}
            istop={!topIsUnder}
            key={'lanetowaytop+' + String(i)}
            archeight={archeight}
            arccenter={arccenter}
          />
        );
        shapes.push(
          <StreetOffsetLine
            points={this.state.linepoints[linepontBottom]}
            linedata={lineData[linepontBottom]}
            arcpoints={bottomArcPoints}
            lineheight={bottomLineHeight}
            istop={!bottomIsUnder}
            key={'lanetowaybottom+' + String(i)}
            archeight={archeight}
            arccenter={arccenter}
          />
        );
      } else {
        shapes.push(
          <StreetOffsetLine
            points={this.state.linepoints[i]}
            linedata={lineData[i]}
            arcpoints={arcPoints}
            lineheight={lineHeight}
            istop={!isUnder}
            key={'line+' + String(i)}
            archeight={archeight}
            arccenter={arccenter}
          />
        );
      }
      i = lineData[i].bottomline;
    }
  };
  getOffArcCenter = shapes => {
    let D = [];
    let commonProps = {
      stroke: COLOR_TYPE.BLACK,
      strokeWidth: 1,
      strokeDasharray: DASH_ARRAY_TYPE.solid,
      fill: COLOR_TYPE.NONE,
    };
    GetOffArcCenterLinePoints(
      D,
      this.state.object.handlepoint,
      this.state.object.offarcheight,
      this.state.object.offarccenter
    );
    shapes.push(<path d={D.join(' ')} {...commonProps} key={'lane+'} />);
  };
  getLaneHideShape = (shapes, object) => {
    //只能拖拉模式进入
    if (object.streetmodel != 1) return;
    let laneData = object.groupdata;
    let beginStreetLine = GetStreetLineBegin(laneData);
    let i = beginStreetLine;
    while (i >= 0 && i < laneData.length) {
      let bottomLine = laneData[i].bottomline;
      let D = [];
      let id = object.functype + 'line' + '||' + object.operateid + '||' + String(i);
      let style = GetShapeStyle().STREETLANESELECT;
      if (object.selectflag) style.stroke = COLOR_TYPE.LIGHTGRAY;
      style.strokeWidth = object.lanewidth;
      let isUnder = IsUnderLine(object.handlepoint[0], object.handlepoint[2], this.state.linepoints[i][0]);
      let lineHeight = LengthBetweenPoints(this.state.linepoints[i][0], object.handlepoint[0]);
      let arcPoints = GetLineHeightPoints(
        this.state.arcpoints[0],
        this.state.arcpoints[1],
        this.state.arcpoints[2],
        lineHeight,
        !isUnder
      );
      let arcR = LengthBetweenPoints(arcPoints[0], this.state.arcpoints[2]) / 2;
      GetOffArcLinePath(D, object.offarcheight, this.state.linepoints[i], arcPoints, arcR);
      shapes.push(
        GetShapePath(D, id, true, {
          stroke: style.stroke,
          strokewidth: style.strokeWidth,
          fill: style.fill,
          strokedasharray: style.strokeDasharray,
        })
      );
      i = bottomLine;
    }
  };
  getAcrossShape(shapes) {
    let acrossData = this.state.object.streetacrossdata;
    let D = [];
    let closePoints = [];

    for (let i = 0; i < acrossData.length; i++) {
      if (acrossData[i].length < 2) continue;
      let closeBezier = GetShapeThreeTriangle(
        acrossData[i],
        String(i) + '||' + this.state.object.operateid + '||' + ANCHORS_TYPE.STREETACROSSAREA,
        true,
        {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.TRANSPARENT,
          strokewidth: 8,
        }
      );
      shapes.push(closeBezier);
      let shapeTowBessel = GetShapeTowBesselPath(acrossData[i], 'bezier' + String(i), true, {
        stroke: COLOR_TYPE.BLACK,
        fill: COLOR_TYPE.TRANSPARENT,
        strokewidth: 4,
      });
      shapes.push(shapeTowBessel);
      closePoints.push(acrossData[i][1]);
    }
    if (closePoints.length > 0) {
      let shapeCloseHide = GetShapeClosePath(closePoints, 'hideCloseShape', true, {
        stroke: COLOR_TYPE.TRANSPARENT,
        fill: COLOR_TYPE.TRANSPARENT,
        strokewidth: 3,
      });
      // if (closePoints.length === 4) {
      //   let circle = GetCenter1(closePoints);
      //   circle[2] = LengthBetweenPoints(closePoints[0], closePoints[2]) / 2;
      //   shapeCloseHide = GetShapeCircle(circle, 'hideCloseShape', true, {
      //     stroke: COLOR_TYPE.TRANSPARENT,
      //     fill: COLOR_TYPE.TRANSPARENT,
      //     strokewidth: 3,
      //   });
      // }
      shapes.push(shapeCloseHide);
    }
  }
  render() {
    if (this.state == null) return null;
    const { object } = this.state;
    if (object == null || object.handlepoint.length < 3) return null;
    let shapes = [];
    this.getRect(shapes);
    this.getLaneHideShape(shapes, this.state.object);
    shapes.push(GetStreetText(object));
    this.getLaneShape(shapes);
    // this.getAcrossShape(shapes);
    return <g id={object.functype}> {GetShapes(shapes)} </g>;
  }
}

export default StreetOffset;
