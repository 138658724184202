import * as React from 'react';

function SvgImageLineStyleDots(props) {
  return (
    <svg {...props}>
      <path strokeDasharray="5,5" d="M5 10h210" fill="none" stroke="currentColor" strokeWidth={4} />
    </svg>
  );
}

export default SvgImageLineStyleDots;
