import React from 'react';
import _ from 'lodash';
import GeomPoint from '@src/data/GeomPoint';
import GeomLine from '@src/data/GeomLine';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomOffsetArc from '@src/data/GeomOffsetArc';
import GeomStripe from '@src/data/GeomStripe';
import Utility from '@src/data/Utility';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import _Street from '@src/data/_Street';
import {
  getCircleHandlePoint,
  CircleHandlePoint,
  StripeSplitter,
  getSquareHandlePoint,
  ImageHandlePoint,
} from '@src/data/AnchorsPoint';
import { ANCHORS_TYPE } from '@src/constant';
import diagramManager from '@src/data/DiagramManager';
import Images from '@src/assets/Images';
import { STREET_DEFAULT_SIZE } from '@src/constant/index';
import GeomArc from '@src/data/GeomArc';
import { StreetService } from '@src/services/StreetService';
import { getTextContainerBox } from '@src/utils';

function SelectStreetNew({ object }) {
  const shapes = [];
  const offsetColor = '#22507a';

  const getTextRect = () => {
    const {
      text: { size, text, width, height },
      rotateangle,
    } = object;
    if (!text) return;

    // text point coord, i.e. bottom-middle of the rect/text
    const [x, y] = new StreetService(object).getTextPosition();
    return (
      <g>
        {getTextContainerBox({
          x,
          y,
          width,
          height,
          rotateAngle: (rotateangle * 180) / Math.PI,
          id: ANCHORS_TYPE.STREETTEXTRECT,
          fill: 'transparent',
          cursor: 'move',
        })}
      </g>
    );
  };

  const createTerminators = axial => {
    let terminators = [];
    let uupp = diagramManager.getUserUnitsPerPixel();
    let width = 7 * uupp;
    let height = width * 0.5;
    for (let j = 0, jl = axial.terminators.length; j < jl; j++) {
      const { x, y } = axial.terminators[j].pt;
      terminators.push(
        <path
          key={j}
          d={`M${x},${y} L${x + width / 2}, ${y + height} L${x + width / 2} ${y + height} L${x} ${y}`}
        ></path>
      );
    }
  };

  const createMarkingLine = (key, axial) => {
    let uupp = diagramManager.getUserUnitsPerPixel();
    let al = new GeomLine(
      new GeomPoint(axial.ptStart.x, axial.ptStart.y),
      new GeomPoint(axial.ptStop.x, axial.ptStop.y)
    );
    let tl = al.getLength();
    let tp = new GeomPoint(axial.ptStart.x, axial.ptStart.y).midPoint(new GeomPoint(axial.ptStop.x, axial.ptStop.y));
    let tc = Utility.format(tl / 12);
    let ga = al.getAlignmentAtLength(tl / 2, -(1 / 2 + 13 / 2 + 0.17));
    let gaa = ga.ptAlign.clone();
    gaa.rotate((-ga.rotateDeg * Math.PI) / 180, new GeomPoint(0, 0));

    shapes.push(
      <g key={`${key}-${shapes.length}-1`} stroke={offsetColor} fill={offsetColor} strokeDasharray="none">
        <line x1={axial.ptStart.x} y1={axial.ptStart.y} x2={axial.ptStop.x} y2={axial.ptStop.y}></line>
        <g fill={offsetColor} strokeOpacity="0" strokeWidth="0" fontSize={13 * uupp} transform="scale(1, -1)">
          <text x={tp.x} y={-tp.y} textAnchor="middle">
            {tc}
          </text>
        </g>
      </g>
    );
  };

  const calcArcOffsetStart = (stripe, angle, street) => {
    const pointKey = 'StreetOffsetArcPathTransition' + '-' + object.operateid + '-' + stripe.key + '-1-h';
    const segments = [stripe.segments[0]];
    const goa = new GeomOffsetArc(segments, 30, stripe.trLenStart, stripe.trPctStart, stripe.stopOffsetStart);
    goa.inflateAxis(stripe.arcOffsetStart);
    goa.calculateOffsetPath();
    shapes.push(getSquareHandlePoint(pointKey, [goa.transitionMidPoint.x, goa.transitionMidPoint.y], angle));

    let dli = 1,
      spt,
      ept;
    spt = goa.offsetArcSegments[0].getPointStart();
    ept = new GeomPoint(stripe.segments[0].ptStart.x, stripe.segments[0].ptStart.y);
    if (spt.distance(ept) > 0.5) dli = 1;
    spt = goa.offsetArcSegments[goa.offsetArcSegments.length - 1].getPointStop();
    ept = new GeomPoint(stripe.segments[0].ptStop.x, stripe.segments[0].ptStop.y);
    if (spt.distance(ept) > 0.5) dli = 2;
    // let dli = 1;
    const axial = street.appendTurnbayDimensionLine(object, stripe, dli);
    if (Utility.isValid(axial)) {
      // createTerminators(axial);
      createMarkingLine(stripe.key, axial);
    }
  };

  const calcArcOffsetStop = (stripe, angle, street) => {
    const pointKey = 'StreetOffsetArcPathTransition' + '-' + object.operateid + '-' + stripe.key + '-2-h';
    const segments = [stripe.segments[stripe.segments.length - 1]];
    const goa = new GeomOffsetArc(segments, 30, stripe.trLenStop, stripe.trPctStop, stripe.stopOffsetStop);
    goa.inflateAxis(stripe.arcOffsetStop);
    goa.calculateOffsetPath();
    shapes.push(getSquareHandlePoint(pointKey, [goa.transitionMidPoint.x, goa.transitionMidPoint.y], angle));

    let dli = 2;
    const axial = street.appendTurnbayDimensionLine(object, stripe, dli);
    if (Utility.isValid(axial)) {
      // createTerminators(axial);
      createMarkingLine(stripe.key, axial);
    }
  };

  /**
   * 添加 offsetArc 属性非空的图形，目前只用于 Ramp
   */
  const addOffsetArcPathShape = () => {
    const gpa = new GeomPolyArc(object.segments);
    const len = gpa.getLength();
    const transitionPt = gpa.getPointAtLength(len * object.transitionPercentFromStart);
    shapes.push(
      getSquareHandlePoint(
        'OffsetOffsetArcPathTransition' + '-' + object.operateid + '-0-h',
        [transitionPt.x, transitionPt.y],
        0
      )
    );

    let distance;
    if (object.transitionLengthFactor > 0) {
      distance = gpa.getPointStart().distance(gpa.getPointStop()) / 4;
      distance /= object.transitionLengthFactor;
    }

    let l1, l2;
    l1 = (len * object.transitionPercentFromStart) / 4;
    l2 = gpa.getPointAtLength(l1);
    const startPt = gpa.pointOffsetNormal(l2, -object.stopOffset);
    shapes.push(
      getSquareHandlePoint('OffsetOffsetArcPathStart' + '-' + object.operateid + '-1-h', [startPt.x, startPt.y], -90)
    );
    const gl = new GeomLine(gpa.pointOffsetNormal(l2, distance), gpa.pointOffsetNormal(l2, -distance));
    l1 = len - len * ((1 - object.transitionPercentFromStart) / 4);
    l2 = gpa.getPointAtLength(l1);
    const stopPt = gpa.pointOffsetNormal(l2, object.stopOffset);
    shapes.push(
      getSquareHandlePoint('OffsetOffsetArcPathStop' + '-' + object.operateid + '-2-h', [stopPt.x, stopPt.y], -90)
    );
  };

  const addMidPoint = () => {
    for (let i = 0, il = object.segments.length; i < il; i++) {
      const segment = object.segments[i];
      let start = new GeomPoint(segment.ptStart.x, segment.ptStart.y);
      let stop = new GeomPoint(segment.ptStop.x, segment.ptStop.y);
      let mid;
      if (segment.type === 'arc') {
        let arc = new GeomArc(start, stop, segment.r, segment.largeArcFlag, segment.sweepFlag);
        mid = arc.getMidPoint();
      } else if (segment.type === 'line') {
        mid = start.midPoint(stop);
      }
      shapes.push(getCircleHandlePoint(`StreetNewCenter-${i}`, [mid.x, mid.y]));
    }
  };

  const addConnectPoint = () => {
    for (let i = 1, il = object.segments.length; i < il; i++) {
      const segment = object.segments[i];
      shapes.push(getSquareHandlePoint(`StreetNewConnect-${i}`, [segment.ptStart.x, segment.ptStart.y]));
    }
  };

  const getShapes = () => {
    let street = new _Street();
    let streetWidth = street.getStreetWidth(object);
    let r = getUserUnitByPixel() * Utility.getHandleRadius();
    let gpa = new GeomPolyArc(object.segments);
    if (object.offsetArcPath) {
      addOffsetArcPathShape(shapes);
    }
    addMidPoint(shapes);
    if (object.segments.length > 1) {
      addConnectPoint(shapes);
    }

    // TODO: too much repeated code as in StreetNew component
    for (let i = 0, il = object.components.length; i < il; i++) {
      // TODO: is this checking the street style is Paved Unstripped?
      // if (laneCount === 2 && i !== 0 && i !== object.components.length - 1) continue;
      if (object.components[i].type === 'stripe') {
        let stripe = object.components[i];
        const patterns = stripe.primary.patterns;
        if (patterns.length === 1 && patterns[0].pattern === 'invisible') {
          continue;
        }

        // Stripe Splitter
        let gpa = new GeomPolyArc(stripe.segments);
        let angle = gpa.getPointStart().AngleFromHorizontal(gpa.getPointStop());
        angle = (angle * 180) / Math.PI;
        if (stripe.axis) {
          gpa = new GeomPolyArc(stripe.offsetArcSegments);
        } else {
          gpa = new GeomPolyArc(stripe.segments);
        }
        const gs = new GeomStripe(gpa, 0, stripe.primary.patterns, stripe.hiddenSegs, object.style.strokewidth);
        gs.segments = stripe.segments;
        for (let j = 0, jl = stripe.primary.patterns.length; j < jl; j++) {
          if (stripe.primary.patterns[j].startPct != 0) {
            let length = gpa.getLength();
            let sp = gpa.getPointAtLength(length * stripe.primary.patterns[j].startPct);
            const key = `PatternBreak-${object.operateid}-${stripe.key}-${j}-b`;
            shapes.push(StripeSplitter(key, [sp.x, sp.y], angle));
          }
        }

        // TODO:添加 StreetNew 上的6个方形控制点
        shapes.push(
          getSquareHandlePoint(
            'StreetOffsetArcPathStart' + '-' + object.operateid + '-' + stripe.key + '-0-h',
            [gpa.getPointStart().x, gpa.getPointStart().y],
            90 + angle
          )
        );
        shapes.push(
          getSquareHandlePoint(
            'StreetOffsetArcPathStop' + '-' + object.operateid + '-' + stripe.key + '-0-h',
            [gpa.getPointStop().x, gpa.getPointStop().y],
            90 + angle
          )
        );

        // 当 StreetNew 的 stripe 通过拖拽控制点进行变形
        if (stripe.axis) {
          if (Utility.isNumeric(stripe.arcOffsetStart) && stripe.arcOffsetStart !== 0) {
            calcArcOffsetStart(stripe, angle, street);
          }
          if (Utility.isNumeric(stripe.arcOffsetStop) && stripe.arcOffsetStop !== 0) {
            calcArcOffsetStop(stripe, angle, street);
          }
        }

        // if (stripe.axis) {
        //   for (i = 1; i < stripe.axis.patterns.length; i++) {
        //     if (stripe.axis.patterns[i - 1].paintable && stripe.axis.patterns[i].paintable) {
        //       let pt = gpa.getPointAtLength(stripe.axis.patterns[i].startPct * gpa.getLength());
        //     }
        //   }
        // }
      }
    }

    //let fillPathData = gpa.getSvgPathData();
    //<path d={fillPathData} strokeWidth={streetWidth} strokeOpacity="0.4" strokeLinecap="butt" strokeDasharray="none" stroke="#808080" />
  };

  const getPathData = () => {
    const gpa = new GeomPolyArc(object.segments);
    const pathData = gpa.getSvgPathData();
    return { pathData };
  };

  const getPoint = () => {
    const gpa = new GeomPolyArc(object.segments);
    const size = 5 * 4 * getUserUnitByPixel();
    const IMAGE_SIZE = 5 * 4 * getUserUnitByPixel();
    const ptStart = gpa.getAlignmentAtLength(-size, 0).ptAlign;
    const ptStop = gpa.getAlignmentAtLength(gpa.getLength() + size, 0).ptAlign;
    const ptImageStart = gpa.getAlignmentAtLength(-size - IMAGE_SIZE, 0).ptAlign;
    const ptImageEnd = gpa.getAlignmentAtLength(gpa.getLength() + size + IMAGE_SIZE, 0).ptAlign;
    return { ptStart, ptStop, ptImageStart, ptImageEnd };
  };

  getShapes();
  const { pathData } = getPathData();
  const { ptStart, ptStop, ptImageStart, ptImageEnd } = getPoint();

  return (
    <g lncd-role="street-selector">
      <path d={pathData} key="stripe-main" />
      <CircleHandlePoint key="stripe-begin" id="StreetNewBegin" point={ptStart} />
      <CircleHandlePoint key="stripe-end" id="StreetNewEnd" point={ptStop} />
      {!object.offsetArcPath && (
        <>
          <ImageHandlePoint
            key={ANCHORS_TYPE.ADD_SECTION_BEGIN}
            id={ANCHORS_TYPE.ADD_SECTION_BEGIN}
            src={Images.IconPlus}
            point={ptImageStart}
          />
          <ImageHandlePoint
            key={ANCHORS_TYPE.ADD_SECTION_END}
            id={ANCHORS_TYPE.ADD_SECTION_END}
            src={Images.IconPlus}
            point={ptImageEnd}
          />
        </>
      )}
      {shapes}
      {getTextRect()}
    </g>
  );
}

export default SelectStreetNew;
