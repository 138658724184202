import round from 'lodash/round';
import { HANDLE_RADIUS } from '../constant';
import { INCHES_PER_FOOT } from './CommonFunction';

export default {
  /**
   * @deprecated to get handle size, use getHandlePointSize instead
   */
  getHandleRadius: function() {
    return HANDLE_RADIUS;
  },

  /**
   * 验证非空值
   * @param {*} arg
   */
  isValid: function(arg) {
    return typeof arg !== 'undefined' && arg !== null;
  },
  isNonEmptyArray: function(arg) {
    return Array.isArray(arg) && arg.length > 0;
  },

  /**
   * 验证非空字符串
   * @param {*} arg
   */
  isNonEmptyString: function(arg) {
    return typeof arg === 'string' && arg.length > 0;
  },
  isNumeric: function(arg) {
    return (typeof arg === 'number' || (typeof arg === 'string' && arg.length > 0)) && !isNaN(arg);
  },
  isTypeofNumber: function(arg) {
    return 'number' === typeof arg && !isNaN(arg);
  },
  toFixed: function(nbr, dec) {
    var str;
    if (!this.isTypeofNumber(dec)) {
      dec = 4;
    }
    str = nbr.toFixed(dec);
    str = str.replace(/0+$/, '');
    str = str.replace(/\.$/, '');
    return str;
  },
  /**
   * inch(in) convert to feet(ft)
   * @param {number} value
   * @returns {string}
   */
  format(value) {
    let feet;
    let a;
    let result;
    let prec;

    feet = Math.floor(value);
    a = INCHES_PER_FOOT * (value - feet);

    // Avoid returning "NaN", when entering backspace and del.
    if (value === '' || isNaN(a)) {
      return value;
    }

    if (feet > 0 || a >= 1) {
      prec = 0;
    } else {
      if (round(a, 2) < 0.1) {
        prec = 2;
      } else {
        prec = 1;
      }
    }
    a = round(a, prec);
    if (a === INCHES_PER_FOOT) {
      feet++;
      a = 0;
    }
    if (feet === 0 && a === 0) {
      result = '0' + "'";
    } else {
      if (feet === 0) {
        result = '';
      } else {
        result = feet + "'";
      }
      if (a !== 0) {
        if (result !== '') {
          result += ' ';
        }
        result += a + '"';
      }
    }
    return result;
  },
  /**
   * feet(ft) convert to inch(in)
   * @param {string} value
   * @returns {number}
   */
  parse(value) {
    let result;
    let feet;
    let b;
    let regex = new RegExp(
      '^\\s*(?:([\\d.,]+)|(?:([\\d.,]+)\\s?(?:\'|ft|feet))?(?:\\s*([\\d.,]+)\\s?(?:"|in|inch|inches)?)?)\\s*$',
      'i'
    );
    let parts = regex.exec(value);
    if (!parts) return;
    if (parts[1]) {
      result = parts[1];
    } else {
      feet = 0;
      if (parts[2]) {
        b = Number(parts[2]); // 整数部分
        feet += b;
      }
      if (parts[3]) {
        b = Number(parts[3]) ; // 小数部分
        feet += b / Math.pow(10, parts[3].length);
      }
      result = feet;
    }
    return result;
  },
};
