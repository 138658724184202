import * as React from 'react';

function SvgIconCut(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19 14.434c-1.561 0-2.901.971-3.56 2.37l-2.099-3.454c1.65-2.83 3.747-6.478 6.497-11.182L18.161 1 12 11.143 5.838 1.001 4.162 2.17c2.75 4.704 4.847 8.351 6.497 11.182L8.56 16.805c-.659-1.4-1.999-2.371-3.56-2.371-2.206 0-4 1.92-4 4.283C1 21.079 2.794 23 5 23c2.213 0 3.125-.823 7-7.364C15.875 22.176 16.787 23 19 23c2.206 0 4-1.921 4-4.283s-1.794-4.283-4-4.283zM6.414 20.23c-.378.405-.88.627-1.414.627-1.103 0-2-.96-2-2.141 0-1.18.897-2.142 2-2.142s2 .962 2 2.142c0 .573-.208 1.11-.586 1.514zM19 20.858a1.922 1.922 0 01-1.414-.627A2.204 2.204 0 0117 18.717c0-1.18.897-2.142 2-2.142s2 .962 2 2.142c0 1.181-.897 2.141-2 2.141z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconCut;
