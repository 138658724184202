//street对象半径
import {
  ByTime,
  FindCircle,
  FindCircleLinePoint,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  IsOneLine,
  IsUnderLine,
  IsZero,
  LengthArc,
  LengthArc1,
  LengthBetweenPoints,
  MovePoint,
  TowPointDestPointLength,
  TowPointVerticalAcross,
  TowPointVerticalLength,
  TowPointVerticalLengthByStatus,
  GetCenter,
  GetCircleArcCenter,
  IsBigArc,
  IsPointInRange,
  GetTowPointRange,
  IsPointInRange1,
  IsArcRangePoint,
  GetCircleLineAcrossPoint,
  GetLineKB,
  TowCircleAcrossPoints,
} from '@src/data/CommonFunc';
import {
  CopyPoint,
  GetStreetArcBottomPoint,
  GetStreetArcSideOutPoint,
  GetStreetArcSidePoint,
  GetStreetArcTopPoint,
  GetStreetBottomPoint,
  GetStreetLaneCenter,
  GetStreetMovePoint,
  GetStreetPointIstop,
  GetStreetSideOutPoint,
  GetStreetSidePoint,
  GetStreetTopPoint,
  GetLineHeightPoints,
} from '@src/data/BusinessFun';
import {
  GetStreetAreaBegin,
  GetStreetLinePoints,
  GetStreetAreaEnd,
  GetMaxHeight,
  GetStreetMaxPoints,
} from './StreetFunHandle';
import { offArcPoints } from './StreetOffArcFunHandle';
import diagramManager from '@src/data/DiagramManager';
import { THREE_POINT_STATUS_TYPE, STREET_SPACE } from '@src/constant';

import * as workData from '@src/data/WorkData';

export function TransformStreetBeginObject(obj, point) {
  let anchors = obj.handlepoint;
  let beginIndex = GetStreetAreaBegin(anchors);
  let centerIndex = anchors[beginIndex][3].rightindex;
  let center = anchors[centerIndex];
  let endIndex = center[3].rightindex;
  let endPoint = anchors[endIndex];
  let circle = FindCircle(anchors[beginIndex], center, endPoint);
  let r = circle[2];
  if (IsZero(r)) {
    //为直线的情况
    let point3 = point;
    let point0 = GetStreetSidePoint(point, endPoint);
    let point1 = GetCenterInTwoPoints(point0, endPoint);
    if (endPoint[3].rightindex === -1) {
      let point4 = GetStreetSideOutPoint(endPoint, point0);
      CopyPoint(anchors[4], point4);
    }
    CopyPoint(anchors[beginIndex], point0);
    CopyPoint(center, point1);
    CopyPoint(anchors[3], point3);
  } else {
    //此时可知 3，2，和r
    let isBytime = ByTime(anchors[beginIndex], center, endPoint) == THREE_POINT_STATUS_TYPE.BYTIME;
    var length = TowPointDestPointLength(anchors[beginIndex], endPoint, center);
    let point0 = GetStreetArcSidePoint(point, anchors[3], anchors[beginIndex]);
    let center01 = GetCenterInTwoPoints(point0, endPoint);
    let point1 = TowPointVerticalLengthByStatus(point0, endPoint, center01, length, !isBytime);
    CopyPoint(anchors[3], point);
    if (endPoint[3].rightindex === -1) {
      let newCircle = FindCircle(point0, point1, endPoint);
      let point4 = GetStreetArcSideOutPoint(newCircle, endPoint, !isBytime);
      CopyPoint(anchors[4], point4);
    }
    CopyPoint(anchors[beginIndex], point0);
    CopyPoint(center, point1);
  }
  return obj;
}

//street对象半径
export function TransformStreetEndObject(obj, point) {
  let anchors = obj.handlepoint;
  let endIndex = GetStreetAreaEnd(anchors);
  let centerIndex = anchors[endIndex][3].leftIndex;
  let center = anchors[centerIndex];
  let beginIndex = center[3].leftIndex;
  let endPoint = anchors[endIndex];
  let beginPoint = anchors[beginIndex];
  let circle = FindCircle(beginPoint, center, endPoint);
  let r = circle[2];
  if (IsZero(r)) {
    //为直线的情况
    /*let bd = LengthBetweenPoints(point, beginPoint);
	let ed = LengthBetweenPoints(point, endPoint);
	if (bd < ed) {
		let cp = GetCenterInTwoPoints(point, endPoint);
		CopyPoint(anchors[centerIndex], cp);
		CopyPoint(anchors[beginIndex], point);
	}
	else {
		let cp = GetCenterInTwoPoints(point, beginPoint);
		CopyPoint(anchors[centerIndex], cp);
		CopyPoint(anchors[endIndex], point);
	}*/
    let point4 = point;
    let point2 = GetStreetSidePoint(point, beginPoint);
    let point1 = GetCenterInTwoPoints(point2, beginPoint);
    if (beginPoint[3].leftIndex === -1) {
      let point3 = GetStreetSideOutPoint(beginPoint, point2);
      CopyPoint(anchors[3], point3);
    }
    CopyPoint(endPoint, point2);
    CopyPoint(center, point1);
    CopyPoint(anchors[4], point4);
  } else {
    //此时可知 3，2，和r
    let isBytime = ByTime(beginPoint, center, endPoint) == THREE_POINT_STATUS_TYPE.BYTIME;
    var length = TowPointDestPointLength(beginPoint, endPoint, center);
    let point2 = GetStreetArcSidePoint(point, anchors[4], endPoint);
    let center01 = GetCenterInTwoPoints(point2, beginPoint);
    let point1 = TowPointVerticalLengthByStatus(point2, beginPoint, center01, length, isBytime);
    CopyPoint(anchors[4], point);
    if (beginPoint[3].leftIndex === -1) {
      let newCircle = FindCircle(point2, point1, beginPoint);
      let point3 = GetStreetArcSideOutPoint(newCircle, beginPoint, isBytime);
      CopyPoint(anchors[3], point3);
    }
    CopyPoint(endPoint, point2);
    CopyPoint(center, point1);
  }
  return obj;
}

//street中点跨
export function TransformStreetCenterObject(obj, index, point) {
  let anchors = obj.handlepoint;
  let leftIndex = anchors[index][3].leftIndex;
  let rightindex = anchors[index][3].rightindex;
  var center = GetCenterInTwoPoints(anchors[leftIndex], anchors[rightindex]);
  var length = TowPointDestPointLength(anchors[leftIndex], anchors[rightindex], point);
  if (length < diagramManager.getUserUnitsPerPixel() * 7) length = 0;
  if (IsZero(length)) {
    CopyPoint(anchors[index], center);
    if (anchors[leftIndex][3].leftIndex === -1) {
      let point3 = GetStreetSideOutPoint(anchors[rightindex], anchors[leftIndex]);
      CopyPoint(anchors[3], point3);
    }
    if (anchors[rightindex][3].rightindex === -1) {
      let point4 = GetStreetSideOutPoint(anchors[leftIndex], obj.handlepoint[rightindex]);
      CopyPoint(anchors[4], point4);
    }
    return obj;
  }

  let indexPoint = TowPointVerticalLength(anchors[leftIndex], anchors[rightindex], center, length, point);
  CopyPoint(anchors[index], indexPoint);

  let circle = FindCircle(anchors[leftIndex], anchors[index], anchors[rightindex]);
  let isBytime = ByTime(anchors[leftIndex], anchors[index], anchors[rightindex]) === THREE_POINT_STATUS_TYPE.BYTIME;
  if (anchors[leftIndex][3].leftIndex === -1) {
    let point3 = GetStreetArcSideOutPoint(circle, anchors[leftIndex], isBytime);
    CopyPoint(anchors[3], point3);
  }
  if (anchors[rightindex][3].rightindex === -1) {
    let point4 = GetStreetArcSideOutPoint(circle, anchors[rightindex], !isBytime);
    CopyPoint(anchors[4], point4);
  }
  return obj;
}

//street左上点
export function TransformStreetLeftTopObject(obj, lineIndex, beginIndex, lineHeight, isUnderLine, point) {
  let anchors = obj.handlepoint;
  let centerIndex = anchors[beginIndex][3].rightindex;
  let endIndex = anchors[centerIndex][3].rightindex;
  let linePoints = GetStreetLinePoints(anchors, lineHeight, !isUnderLine);
  let pointAcross = TowPointVerticalAcross(anchors[beginIndex], linePoints[beginIndex], point);
  let lengthEnd = 0;
  let circle = FindCircle(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
  if (IsZero(circle[2])) {
    lengthEnd = LengthBetweenPoints(linePoints[beginIndex], linePoints[endIndex]) * obj.groupdata[lineIndex].pathend;
  } else {
    let isBytime =
      ByTime(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
    lengthEnd =
      LengthArc1(linePoints[beginIndex], linePoints[endIndex], circle, isBytime) * obj.groupdata[lineIndex].pathend;
  }
  let length = LengthBetweenPoints(pointAcross, linePoints[beginIndex]);
  if (length >= lengthEnd / 2) length = lengthEnd / 2;
  else if (length > STREET_SPACE.RMAXHEIGHT) length = STREET_SPACE.RMAXHEIGHT;
  //判断点是否向上拖
  obj.groupdata[lineIndex].topleftr = length;
  obj.groupdata[lineIndex].isUnderLine = isUnderLine;
  let isUnder = IsUnderLine(linePoints[beginIndex], linePoints[centerIndex], pointAcross);
  obj.groupdata[lineIndex].upstate = !isUnder;
  if (linePoints[endIndex][3].rightindex === -1) obj.groupdata[lineIndex].toprightr = 0;
  return obj;
}
//street右上点
export function TransformStreetRightTopObject(obj, lineIndex, endIndex, lineHeight, isUnderLine, point) {
  let anchors = obj.handlepoint;
  let centerIndex = anchors[endIndex][3].leftIndex;
  let beginIndex = anchors[centerIndex][3].leftIndex;
  let linePoints = GetStreetLinePoints(anchors, lineHeight, !isUnderLine);
  let pointAcross = TowPointVerticalAcross(anchors[endIndex], linePoints[endIndex], point);
  let lengthEnd = 0;
  let circle = FindCircle(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
  if (IsZero(circle[2])) {
    lengthEnd =
      LengthBetweenPoints(linePoints[beginIndex], linePoints[endIndex]) * (1 - obj.groupdata[lineIndex].pathrightend);
  } else {
    let isBytime =
      ByTime(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
    lengthEnd =
      LengthArc1(linePoints[beginIndex], linePoints[endIndex], circle, isBytime) *
      (1 - obj.groupdata[lineIndex].pathrightend);
  }
  let length = LengthBetweenPoints(pointAcross, linePoints[endIndex]);
  if (length >= lengthEnd / 2) length = lengthEnd / 2;
  else if (length > STREET_SPACE.RMAXHEIGHT) length = STREET_SPACE.RMAXHEIGHT;
  //判断点是否向上拖
  obj.groupdata[lineIndex].toprightr = length;
  obj.groupdata[lineIndex].isUnderLine = isUnderLine;
  let isUnder = IsUnderLine(linePoints[beginIndex], linePoints[endIndex], pointAcross);
  obj.groupdata[lineIndex].uprightstate = !isUnder;
  if (linePoints[beginIndex][3].leftIndex === -1) obj.groupdata[lineIndex].topleftr = 0;
  return obj;
}
export function TransformStreetArcCenterTop(obj, point) {
  let circle = FindCircle(obj.handlepoint[5], obj.handlepoint[6], obj.handlepoint[7]);
  let isByTime = ByTime(obj.handlepoint[5], obj.handlepoint[6], obj.handlepoint[7]) == THREE_POINT_STATUS_TYPE.BYTIME;
  let length = LengthArc(obj.handlepoint[5], obj.handlepoint[7], obj.handlepoint[6]);
  if (!IsZero(obj.handlepoint[5][2].topleftr)) {
    let minR = Math.sqrt((3 * Math.pow(obj.handlepoint[5][2].topleftr, 2)) / 4);
    let movePoint = GetStreetMovePoint(
      obj.handlepoint[5],
      obj.handlepoint[8],
      obj.handlepoint[5][2].topleftr,
      obj.handlepoint[5][2].upstate
    );
    let center = GetCenterInTwoPoints(movePoint, obj.handlepoint[5]);
    if (IsZero(circle[2])) {
      //左边
      let centerAcross = TowPointVerticalAcross(obj.handlepoint[5], obj.handlepoint[7], point);
      if (
        IsUnderLine(obj.handlepoint[5], obj.handlepoint[8], centerAcross) ||
        LengthBetweenPoints(centerAcross, obj.handlepoint[5]) < minR
      ) {
        obj.handlepoint[5][2].pathend = (minR * 2) / length;
      } else if (
        IsUnderLine(obj.handlepoint[2], obj.handlepoint[7], centerAcross) ||
        LengthBetweenPoints(centerAcross, obj.handlepoint[5]) > length - minR
      ) {
        obj.handlepoint[5][2].pathend = 1;
      } else {
        obj.handlepoint[5][2].pathend = (LengthBetweenPoints(obj.handlepoint[5], centerAcross) + minR) / length;
      }
    } else {
      let centerAcross = FindCircleLinePoint(circle, point);
      let arcLength = LengthArc1(obj.handlepoint[5], centerAcross, circle, isByTime);
      if (IsUnderLine(obj.handlepoint[5], obj.handlepoint[8], centerAcross) || arcLength < minR) {
        obj.handlepoint[5][2].pathend = (minR * 2) / length;
      } else if (IsUnderLine(obj.handlepoint[10], obj.handlepoint[7], centerAcross) || arcLength > length - minR) {
        obj.handlepoint[5][2].pathend = 1;
      } else {
        obj.handlepoint[5][2].pathend = (arcLength + minR) / length;
      }
    }
  } else {
    let minR = Math.sqrt((3 * Math.pow(obj.handlepoint[7][2].topleftr, 2)) / 4);
    let movePoint = GetStreetMovePoint(
      obj.handlepoint[7],
      obj.handlepoint[10],
      obj.handlepoint[7][2].topleftr,
      obj.handlepoint[7][2].upstate
    );
    if (IsZero(circle[2])) {
      //右边
      let centerAcross = TowPointVerticalAcross(obj.handlepoint[5], obj.handlepoint[7], point);
      if (
        IsUnderLine(obj.handlepoint[10], obj.handlepoint[7], centerAcross) ||
        LengthBetweenPoints(centerAcross, obj.handlepoint[7]) < minR
      ) {
        minR = minR + 10;
        obj.handlepoint[7][2].pathend = (minR * 2) / length;
      } else if (
        IsUnderLine(obj.handlepoint[5], obj.handlepoint[8], centerAcross) ||
        LengthBetweenPoints(centerAcross, obj.handlepoint[7]) > length - minR
      ) {
        obj.handlepoint[7][2].pathend = 1;
      } else {
        obj.handlepoint[7][2].pathend = (LengthBetweenPoints(obj.handlepoint[7], centerAcross) + minR) / length;
      }
    } else {
      let centerAcross = FindCircleLinePoint(circle, point);
      let arcLength = LengthArc1(obj.handlepoint[7], centerAcross, circle, !isByTime);
      if (IsUnderLine(obj.handlepoint[5], obj.handlepoint[8], centerAcross) || arcLength > length - minR) {
        obj.handlepoint[7][2].pathend = 1;
      } else if (IsUnderLine(obj.handlepoint[10], obj.handlepoint[7], centerAcross) || arcLength < minR) {
        obj.handlepoint[7][2].pathend = (minR * 2) / length;
      } else {
        obj.handlepoint[7][2].pathend = (arcLength + minR) / length;
      }
    }
  }

  return obj;
}
export function TransformStreetTopCenterObject(obj, drawhandlepoint, point) {
  return obj;
}
export function TransformStreetNormalObject(obj, index, point) {
  let anchors = obj.handlepoint;
  let leftCenterIndex = anchors[index][3].leftIndex;
  let leftIndex = anchors[leftCenterIndex][3].leftIndex;
  let leftCircle = FindCircle(anchors[leftIndex], anchors[leftCenterIndex], anchors[index]);
  if (IsZero(leftCircle[2])) {
    let center = GetCenterInTwoPoints(anchors[leftIndex], point);
    CopyPoint(anchors[leftCenterIndex], center);
  } else {
    let isBytime =
      ByTime(anchors[leftIndex], anchors[leftCenterIndex], anchors[index]) == THREE_POINT_STATUS_TYPE.BYTIME;
    let length = TowPointDestPointLength(anchors[leftIndex], anchors[index], anchors[leftCenterIndex]);
    let center = GetCenterInTwoPoints(anchors[leftIndex], point);
    let pointCenter = TowPointVerticalLengthByStatus(anchors[leftIndex], point, center, length, !isBytime);
    CopyPoint(anchors[leftCenterIndex], pointCenter);
  }
  let rightCenterIndex = anchors[index][3].rightindex;
  let rightIndex = anchors[rightCenterIndex][3].rightindex;
  let rightCircle = FindCircle(anchors[index], anchors[rightCenterIndex], anchors[rightIndex]);
  if (IsZero(rightCircle[2])) {
    let center = GetCenterInTwoPoints(anchors[rightIndex], point);
    CopyPoint(anchors[rightCenterIndex], center);
  } else {
    let isBytime =
      ByTime(anchors[index], anchors[rightCenterIndex], anchors[rightIndex]) == THREE_POINT_STATUS_TYPE.BYTIME;
    let length = TowPointDestPointLength(anchors[rightIndex], anchors[index], anchors[rightCenterIndex]);
    let center = GetCenterInTwoPoints(anchors[rightIndex], point);
    let pointCenter = TowPointVerticalLengthByStatus(point, anchors[rightIndex], center, length, !isBytime);
    CopyPoint(anchors[rightCenterIndex], pointCenter);
  }
  CopyPoint(anchors[index], point);
  return obj;
}

// FIXME: what is capturepoint
export function TransformStreetTextRect(obj, point, start) {
  if (obj.text.point.length == 0) {
    obj.text.point = point;
  } else {
    obj.text.point = MovePoint(obj.text.point, point[0] - start[0], point[1] - start[1]);
  }
  return obj;
}

export function TransformOffsetLeft(obj, lineHeight, isUnderLine) {
  /*let anchors = obj.handlepoint;
    let linePoints=GetStreetLinePoints(anchors,lineHeight,!isUnderLine);
    let pointAcross = TowPointVerticalAcross(anchors[beginIndex], linePoints[beginIndex], point);
    let lengthEnd=0;
    let circle=FindCircle(linePoints[beginIndex],linePoints[centerIndex],linePoints[endIndex]);
    if(IsZero(circle[2])){
        lengthEnd=LengthBetweenPoints(linePoints[beginIndex],linePoints[endIndex])*obj.groupdata[lineIndex].pathend;
    }
    else {
        let isBytime=ByTime(linePoints[beginIndex],linePoints[centerIndex],linePoints[endIndex])===THREE_POINT_STATUS_TYPE.BYTIME;
        lengthEnd=LengthArc1(linePoints[beginIndex],linePoints[endIndex],circle,isBytime)*obj.groupdata[lineIndex].pathend;
    }
    let length = LengthBetweenPoints(pointAcross, linePoints[beginIndex]);
    if(length >=lengthEnd/2)
        length=lengthEnd/2;
    else if (length > STREET_SPACE.RMAXHEIGHT)
        length = STREET_SPACE.RMAXHEIGHT;
    //判断点是否向上拖
    obj.groupdata[lineIndex].topleftr = length;
    let isUnder=IsUnderLine(linePoints[beginIndex],linePoints[centerIndex],pointAcross);
    obj.groupdata[lineIndex].upstate = !isUnder;
    if(linePoints[endIndex][3].rightindex===-1)
         obj.groupdata[lineIndex].toprightr=0;*/
}

export function TransformOffsetUpObject(obj, point) {
  let anchors = obj.handlepoint;
  let offArcHeight = obj.offarcheight;
  let bottomPoints = GetLineHeightPoints(anchors[0], anchors[1], anchors[2], offArcHeight, false);
  let topPoints = GetLineHeightPoints(anchors[0], anchors[1], anchors[2], offArcHeight, true);
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let length = 0;
  let point3 = [],
    point4 = [],
    afterAnchors = [];
  if (IsZero(circle[2])) {
    length = TowPointDestPointLength(topPoints[0], topPoints[2], point);
    let isUnder = IsUnderLine(topPoints[0], topPoints[2], point);
    if (!isUnder) length = -length;
    afterAnchors = GetLineHeightPoints(topPoints[0], topPoints[1], topPoints[2], length / 2, false);
    point3 = GetStreetSideOutPoint(afterAnchors[0], afterAnchors[2]);
    point4 = GetStreetSideOutPoint(afterAnchors[2], afterAnchors[0]);
  } else {
    let lengthCircle = LengthBetweenPoints(circle, point);
    let lengthTop = LengthBetweenPoints(topPoints[0], circle);
    length = lengthTop - lengthCircle;
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) == THREE_POINT_STATUS_TYPE.BYTIME;
    afterAnchors = GetLineHeightPoints(topPoints[0], topPoints[1], topPoints[2], length / 2, false);
    let newCircle = FindCircle(afterAnchors[0], afterAnchors[1], afterAnchors[2]);
    point3 = GetStreetArcSideOutPoint(newCircle, afterAnchors[0], isBytime);
    point4 = GetStreetArcSideOutPoint(newCircle, afterAnchors[2], !isBytime);
  }
  CopyPoint(anchors[0], afterAnchors[0]);
  CopyPoint(anchors[1], afterAnchors[1]);
  CopyPoint(anchors[2], afterAnchors[2]);
  CopyPoint(anchors[3], point3);
  CopyPoint(anchors[4], point4);
  obj.offarcheight = length / 2;
  let arcPoints = offArcPoints(anchors, obj.offarcheight, obj.offarccenter);
  if (IsZero(circle[2])) {
    let rangePoints = GetTowPointRange(anchors[0], anchors[2]);
    let leftPoint = TowPointVerticalAcross(anchors[0], anchors[2], arcPoints[0]);
    let rightPoint = TowPointVerticalAcross(anchors[0], anchors[2], arcPoints[2]);
    if (
      LengthBetweenPoints(leftPoint, anchors[0]) < STREET_SPACE.OFFSETMOVESPACE ||
      LengthBetweenPoints(rightPoint, anchors[2]) < STREET_SPACE.OFFSETMOVESPACE
    ) {
      return workData.getObject(obj.operateid);
    } else if (IsPointInRange1(leftPoint, rangePoints) && IsPointInRange1(rightPoint, rangePoints)) return obj;
    else return workData.getObject(obj.operateid);
  } else {
    let newCircle = FindCircle(anchors[0], anchors[1], anchors[2]);
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) == THREE_POINT_STATUS_TYPE.BYTIME;
    let leftPoint = FindCircleLinePoint(newCircle, arcPoints[0]);
    let rightPoint = FindCircleLinePoint(newCircle, arcPoints[2]);
    if (
      LengthArc1(anchors[0], leftPoint, newCircle, isBytime) < STREET_SPACE.OFFSETMOVESPACE ||
      LengthArc1(anchors[2], rightPoint, newCircle, !isBytime) < STREET_SPACE.OFFSETMOVESPACE
    ) {
      return workData.getObject(obj.operateid);
    } else if (
      IsArcRangePoint(anchors[0], anchors[2], newCircle, isBytime, leftPoint) &&
      IsArcRangePoint(anchors[0], anchors[2], newCircle, isBytime, rightPoint)
    ) {
      return obj;
    } else return workData.getObject(obj.operateid);
  }
  return obj;
}

export function TransformOffsetDownObject(obj, point) {
  let anchors = obj.handlepoint;
  let offArcHeight = obj.offarcheight;
  let bottomPoints = GetLineHeightPoints(anchors[0], anchors[1], anchors[2], offArcHeight, false);
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let length = 0;
  let point3 = [],
    point4 = [],
    afterAnchors = [];
  if (IsZero(circle[2])) {
    length = TowPointDestPointLength(bottomPoints[0], bottomPoints[2], point);
    let isUnder = IsUnderLine(bottomPoints[0], bottomPoints[2], point);
    if (isUnder) length = -length;
    afterAnchors = GetLineHeightPoints(bottomPoints[0], bottomPoints[1], bottomPoints[2], length / 2, true);
    point3 = GetStreetSideOutPoint(afterAnchors[0], afterAnchors[2]);
    point4 = GetStreetSideOutPoint(afterAnchors[2], afterAnchors[0]);
  } else {
    let lengthCircle = LengthBetweenPoints(circle, point);
    let lengthTop = LengthBetweenPoints(bottomPoints[0], circle);
    length = lengthCircle - lengthTop;
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) == THREE_POINT_STATUS_TYPE.BYTIME;
    afterAnchors = GetLineHeightPoints(bottomPoints[0], bottomPoints[1], bottomPoints[2], length / 2, true);
    let newCircle = FindCircle(afterAnchors[0], afterAnchors[1], afterAnchors[2]);
    point3 = GetStreetArcSideOutPoint(newCircle, afterAnchors[0], isBytime);
    point4 = GetStreetArcSideOutPoint(newCircle, afterAnchors[2], !isBytime);
  }
  CopyPoint(anchors[0], afterAnchors[0]);
  CopyPoint(anchors[1], afterAnchors[1]);
  CopyPoint(anchors[2], afterAnchors[2]);
  CopyPoint(anchors[3], point3);
  CopyPoint(anchors[4], point4);
  obj.offarcheight = length / 2;
  let arcPoints = offArcPoints(anchors, obj.offarcheight, obj.offarccenter);
  if (IsZero(circle[2])) {
    let rangePoints = GetTowPointRange(anchors[0], anchors[2]);
    let leftPoint = TowPointVerticalAcross(anchors[0], anchors[2], arcPoints[0]);
    let rightPoint = TowPointVerticalAcross(anchors[0], anchors[2], arcPoints[2]);
    if (
      LengthBetweenPoints(leftPoint, anchors[0]) < STREET_SPACE.OFFSETMOVESPACE ||
      LengthBetweenPoints(rightPoint, anchors[2]) < STREET_SPACE.OFFSETMOVESPACE
    ) {
      return workData.getObject(obj.operateid);
    } else if (IsPointInRange1(leftPoint, rangePoints) && IsPointInRange1(rightPoint, rangePoints)) return obj;
    else return workData.getObject(obj.operateid);
  } else {
    let newCircle = FindCircle(anchors[0], anchors[1], anchors[2]);
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) == THREE_POINT_STATUS_TYPE.BYTIME;
    let leftPoint = FindCircleLinePoint(newCircle, arcPoints[0]);
    let rightPoint = FindCircleLinePoint(newCircle, arcPoints[2]);
    if (
      LengthArc1(anchors[0], leftPoint, newCircle, isBytime) < STREET_SPACE.OFFSETMOVESPACE ||
      LengthArc1(anchors[2], rightPoint, newCircle, !isBytime) < STREET_SPACE.OFFSETMOVESPACE
    ) {
      return workData.getObject(obj.operateid);
    } else if (
      IsArcRangePoint(anchors[0], anchors[2], newCircle, isBytime, leftPoint) &&
      IsArcRangePoint(anchors[0], anchors[2], newCircle, isBytime, rightPoint)
    ) {
      return obj;
    } else return workData.getObject(obj.operateid);
  }
}

export function TransformOffsetCenterObject(obj, point) {
  let anchors = obj.handlepoint;
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let Center = 0;
  if (IsZero(circle[2])) {
    let verticalPoint = TowPointVerticalAcross(anchors[0], anchors[2], point);
    Center = LengthBetweenPoints(anchors[0], verticalPoint) / LengthBetweenPoints(anchors[0], anchors[2]);
  } else {
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let verticalPoint = FindCircleLinePoint(circle, point);
    let lengthArc = LengthArc(anchors[0], anchors[2], anchors[1]);
    let lenthVertical = LengthArc1(anchors[0], verticalPoint, circle, isBytime);
    Center = lenthVertical / lengthArc;
  }
  obj.offarccenter = Center;
  let arcPoints = offArcPoints(anchors, obj.offarcheight, obj.offarccenter);
  if (IsZero(circle[2])) {
    let rangePoints = GetTowPointRange(anchors[0], anchors[2]);
    let leftPoint = TowPointVerticalAcross(anchors[0], anchors[2], arcPoints[0]);
    let rightPoint = TowPointVerticalAcross(anchors[0], anchors[2], arcPoints[2]);
    if (
      LengthBetweenPoints(leftPoint, anchors[0]) < STREET_SPACE.OFFSETMOVESPACE ||
      LengthBetweenPoints(rightPoint, anchors[2]) < STREET_SPACE.OFFSETMOVESPACE
    ) {
      return workData.getObject(obj.operateid);
    } else if (IsPointInRange1(leftPoint, rangePoints) && IsPointInRange1(rightPoint, rangePoints)) return obj;
    else return workData.getObject(obj.operateid);
  } else {
    let newCircle = FindCircle(anchors[0], anchors[1], anchors[2]);
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) == THREE_POINT_STATUS_TYPE.BYTIME;
    let leftPoint = FindCircleLinePoint(newCircle, arcPoints[0]);
    let rightPoint = FindCircleLinePoint(newCircle, arcPoints[2]);
    if (
      LengthArc1(anchors[0], leftPoint, newCircle, isBytime) < STREET_SPACE.OFFSETMOVESPACE ||
      LengthArc1(anchors[2], rightPoint, newCircle, !isBytime) < STREET_SPACE.OFFSETMOVESPACE
    ) {
      return workData.getObject(obj.operateid);
    } else if (
      IsArcRangePoint(anchors[0], anchors[2], newCircle, isBytime, leftPoint) &&
      IsArcRangePoint(anchors[0], anchors[2], newCircle, isBytime, rightPoint)
    ) {
      return obj;
    } else return workData.getObject(obj.operateid);
  }
}

export function TransformStreetArcLeftMove(obj, lineIndex, point) {
  let beginIndex = GetStreetAreaBegin(obj.handlepoint);
  let centerIndex = obj.handlepoint[beginIndex][3].rightindex;
  let endIndex = obj.handlepoint[centerIndex][3].rightindex;
  let circle = FindCircle(obj.handlepoint[beginIndex], obj.handlepoint[centerIndex], obj.handlepoint[endIndex]);
  let lineData = obj.groupdata[lineIndex];
  if (IsZero(circle[2])) {
    let acrossPoint = TowPointVerticalAcross(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], point);
    let arcCircle = [acrossPoint[0], acrossPoint[1], lineData.topleftr];
    let arcAcrossPoint = GetCircleLineAcrossPoint(
      arcCircle,
      GetLineKB(obj.handlepoint[beginIndex], obj.handlepoint[endIndex])
    );
    let poingRange = GetTowPointRange(obj.handlepoint[beginIndex], obj.handlepoint[endIndex]);
    if (!IsPointInRange1(arcAcrossPoint[0], poingRange) || !IsPointInRange1(arcAcrossPoint[1], poingRange)) {
      return workData.getObject(obj.operateid);
    }

    if (
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[0]) <
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[1])
    ) {
      lineData.pathend =
        LengthBetweenPoints(arcAcrossPoint[1], obj.handlepoint[beginIndex]) /
        LengthBetweenPoints(obj.handlepoint[beginIndex], obj.handlepoint[endIndex]);
    } else {
      lineData.pathend =
        LengthBetweenPoints(arcAcrossPoint[0], obj.handlepoint[beginIndex]) /
        LengthBetweenPoints(obj.handlepoint[beginIndex], obj.handlepoint[endIndex]);
    }
  } else {
    let isBytime =
      ByTime(obj.handlepoint[beginIndex], obj.handlepoint[centerIndex], obj.handlepoint[endIndex]) ===
      THREE_POINT_STATUS_TYPE.BYTIME;
    let acrossPoint = FindCircleLinePoint(circle, point);
    let arcCircle = [acrossPoint[0], acrossPoint[1], lineData.topleftr];
    let arcAcrossPoint = TowCircleAcrossPoints(circle, arcCircle);
    if (
      !IsArcRangePoint(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], circle, isBytime, arcAcrossPoint[0]) ||
      !IsArcRangePoint(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], circle, isBytime, arcAcrossPoint[1])
    ) {
      return workData.getObject(obj.operateid);
    }
    let length = LengthArc(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], obj.handlepoint[centerIndex]);
    if (
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[0]) <
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[1])
    ) {
      let lengthBegin = LengthArc1(obj.handlepoint[beginIndex], arcAcrossPoint[1], circle, isBytime);
      lineData.pathend = lengthBegin / length;
    } else {
      let lengthBegin = LengthArc1(obj.handlepoint[beginIndex], arcAcrossPoint[0], circle, isBytime);
      lineData.pathend = lengthBegin / length;
    }
  }
  return obj;
}

export function TransformStreetArcRightMove(obj, lineIndex, point) {
  let endIndex = GetStreetAreaEnd(obj.handlepoint);
  let centerIndex = obj.handlepoint[endIndex][3].leftIndex;
  let beginIndex = obj.handlepoint[centerIndex][3].leftIndex;
  let circle = FindCircle(obj.handlepoint[beginIndex], obj.handlepoint[centerIndex], obj.handlepoint[endIndex]);
  let lineData = obj.groupdata[lineIndex];
  if (IsZero(circle[2])) {
    let acrossPoint = TowPointVerticalAcross(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], point);
    let arcCircle = [acrossPoint[0], acrossPoint[1], lineData.toprightr];
    let arcAcrossPoint = GetCircleLineAcrossPoint(
      arcCircle,
      GetLineKB(obj.handlepoint[beginIndex], obj.handlepoint[endIndex])
    );
    let poingRange = GetTowPointRange(obj.handlepoint[beginIndex], obj.handlepoint[endIndex]);
    if (!IsPointInRange1(arcAcrossPoint[0], poingRange) || !IsPointInRange1(arcAcrossPoint[1], poingRange)) {
      return workData.getObject(obj.operateid);
    }

    if (
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[0]) <
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[1])
    ) {
      lineData.pathrightend =
        LengthBetweenPoints(arcAcrossPoint[0], obj.handlepoint[beginIndex]) /
        LengthBetweenPoints(obj.handlepoint[beginIndex], obj.handlepoint[endIndex]);
    } else {
      lineData.pathrightend =
        LengthBetweenPoints(arcAcrossPoint[1], obj.handlepoint[beginIndex]) /
        LengthBetweenPoints(obj.handlepoint[beginIndex], obj.handlepoint[endIndex]);
    }
  } else {
    let isBytime =
      ByTime(obj.handlepoint[beginIndex], obj.handlepoint[centerIndex], obj.handlepoint[endIndex]) ===
      THREE_POINT_STATUS_TYPE.BYTIME;
    let acrossPoint = FindCircleLinePoint(circle, point);
    let arcCircle = [acrossPoint[0], acrossPoint[1], lineData.toprightr];
    let arcAcrossPoint = TowCircleAcrossPoints(circle, arcCircle);
    if (
      !IsArcRangePoint(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], circle, isBytime, arcAcrossPoint[0]) ||
      !IsArcRangePoint(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], circle, isBytime, arcAcrossPoint[1])
    ) {
      return workData.getObject(obj.operateid);
    }
    let length = LengthArc(obj.handlepoint[beginIndex], obj.handlepoint[endIndex], obj.handlepoint[centerIndex]);
    if (
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[0]) <
      LengthBetweenPoints(obj.handlepoint[beginIndex], arcAcrossPoint[1])
    ) {
      let lengthBegin = LengthArc1(obj.handlepoint[beginIndex], arcAcrossPoint[0], circle, isBytime);
      lineData.pathrightend = lengthBegin / length;
    } else {
      let lengthBegin = LengthArc1(obj.handlepoint[beginIndex], arcAcrossPoint[1], circle, isBytime);
      lineData.pathrightend = lengthBegin / length;
    }
  }
  return obj;
}

export function TransformStreetSplitPattem(obj, lineIndex, dataIndex, point) {
  let anchors = obj.handlepoint;
  let beginIndex = GetStreetAreaBegin(anchors);
  let centerIndex = anchors[beginIndex][3].rightindex;
  let endIndex = anchors[centerIndex][3].rightindex;
  let circle = FindCircle(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]);
  let originData = obj.groupdata[lineIndex].splitpattem[dataIndex];
  let bSuc = false;
  let scale = 0;
  if (IsZero(circle[2])) {
    let pointRange = GetTowPointRange(anchors[beginIndex], anchors[endIndex]);
    let acrossPoint = TowPointVerticalAcross(anchors[beginIndex], anchors[endIndex], point);
    if (IsPointInRange1(acrossPoint, pointRange)) {
      scale =
        LengthBetweenPoints(anchors[beginIndex], acrossPoint) /
        LengthBetweenPoints(anchors[beginIndex], anchors[endIndex]);
      bSuc = true;
    }
  } else {
    let byTime =
      ByTime(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let acrossPoint = FindCircleLinePoint(circle, point);
    if (IsArcRangePoint(anchors[beginIndex], anchors[endIndex], circle, byTime, acrossPoint)) {
      let length = LengthArc1(anchors[beginIndex], anchors[endIndex], circle, byTime);
      let leftLength = LengthArc1(anchors[beginIndex], acrossPoint, circle, byTime);
      scale = leftLength / length;
      bSuc = true;
    }
  }
  if (bSuc) {
    for (let i = 0; i < obj.groupdata[lineIndex].splitpattem.length; i++) {
      if (
        //originData > obj.groupdata[lineIndex].splitpattem[i] &&
        scale < obj.groupdata[lineIndex].splitpattem[i].startPct
      ) {
        obj.groupdata[lineIndex].splitpattem[i].startPct = scale;
      } else if (
        //originData < obj.groupdata[lineIndex].splitpattem[i] &&
        scale > obj.groupdata[lineIndex].splitpattem[i].startPct
      ) {
        obj.groupdata[lineIndex].splitpattem[i].startPct = scale;
      }
    }
    //obj.groupdata[lineIndex].splitpattem.splice(dataIndex, 1, scale);
  }
  return obj;
}
