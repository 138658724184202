import * as React from 'react';

function SvgIconTriangulation(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M20.333 16h-.813l-5.665-8.092A1.662 1.662 0 0015 6.334V3.667A1.67 1.67 0 0013.333 2h-2.666A1.67 1.67 0 009 3.667v2.667c0 .735.482 1.354 1.144 1.574L4.479 16h-.812A1.67 1.67 0 002 17.667v2.667C2 21.253 2.748 22 3.667 22h2.666C7.252 22 8 21.253 8 20.334V20h8v.334c0 .919.748 1.666 1.667 1.666h2.666c.919 0 1.667-.747 1.667-1.666v-2.667A1.67 1.67 0 0020.333 16zM11 4h2v2h-2V4zM6 20H4v-2h2v2zm2-2v-.333c0-.735-.482-1.353-1.144-1.574L12 8.744l5.144 7.349A1.664 1.664 0 0016 17.667V18H8zm12 2h-2v-2h2v2z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconTriangulation;
