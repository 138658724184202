import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Input, Select, Table, Form, InputNumber } from 'antd';
import { TriangulationNetworkDropDownList } from '@src/data/ShapeDataList';
import ModalContainer from './components/ModalContainer';
import Utility from '@src/data/Utility';
import * as workData from '@src/data/WorkData';
import { withTranslation } from 'react-i18next';
import { convertToFeet } from '@src/data/CommonFunction';
import { intersectTwoCircles } from '@src/utils';
import PointModal from './components/PointModal';
import EditableRowOperation from './components/EditableRowOperation';
import EditableTableContainer from './components/EditableTableContainer';
import { EditableContext } from './context/EditableContext';

class EditableCell extends React.Component {
  handlePointValidator = (rule, val, callback) => {
    if (!val) {
      callback();
    }

    let validateResult = true; // 自定义规则
    let message = '';

    if (this.props.record.p1 === this.props.record.p2) {
      validateResult = false;
      message = this.props.t('property.measurementTab.samePointErr');
    }

    if (!validateResult) {
      callback(message);
    }

    callback();
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      onFocus,
      direction,
      onSelectChange,
      referencePointsText,
      t,
      ...restProps
    } = this.props;

    const focusInput = event => {
      onFocus(event.currentTarget.id);
    };

    // TODO: Translate the message bottom of the modal
    const getInputForm = () => {
      if (dataIndex === 'p1' || dataIndex === 'p2') {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Select ${title}!`,
                },
                {
                  validator: this.handlePointValidator,
                },
              ],
              initialValue: record[dataIndex],
            })(
              <Select
                onChange={(value, component) => onSelectChange(value, component, { id: record.id, dataIndex })}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} />}
                size="small"
                style={{ width: '100%' }}
              >
                {referencePointsText.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        );
      } else if (dataIndex === 'direction') {
        const dropDownList = Object.keys(TriangulationNetworkDropDownList).map(v => ({
          name: v.toLowerCase(),
          title: v,
        }));

        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Select ${title}!`,
                },
              ],
              initialValue: record['dropDirectionTitle'],
            })(
              <Select
                onChange={(value, component) => onSelectChange(value, component, { id: record.id, dataIndex })}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} />}
                size="small"
                style={{ width: '100%' }}
              >
                {dropDownList.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.name}>
                      {t(`property.measurementTab.${item.name}Direction`)}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        );
      } else if (dataIndex === 'distance1' || dataIndex === 'distance2') {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(
              <InputNumber
                // formatter={value => Utility.format(value)}
                // parser={value => value.replace('"', '')}
                onFocus={focusInput}
                size="small"
              />
            )}
          </Form.Item>
        );
      } else {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: false,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(<Input onFocus={focusInput} size="small" />)}
          </Form.Item>
        );
      }
    };

    return <td {...restProps}>{editing ? getInputForm() : children}</td>;
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    const { pointList } = this.props;

    this.state = {
      data: pointList,
      editingKey: '',
      inputTitle: '',
    };

    this.columns = [
      {
        title: this.props.t('property.measurementTab.id'),
        dataIndex: 'id',
        width: '6%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.description'),
        dataIndex: 'description',
        width: '22%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.p1'),
        dataIndex: 'p1',
        width: '10%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.distance1'),
        dataIndex: 'distance1',
        width: '15%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.p2'),
        dataIndex: 'p2',
        width: '10%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.distance2'),
        dataIndex: 'distance2',
        width: '15%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.direction'),
        dataIndex: 'direction',
        width: '20%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.operation'),
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <EditableRowOperation
              editable={editable}
              keyValue={record.key}
              editingKey={editingKey}
              save={this.save}
              cancel={this.cancel}
              edit={this.edit}
              handleDelete={this.handleDelete}
              t={this.props.t}
            />
          );
        },
      },
    ];
  }

  /**
   *
   * @param {object} record
   * @param {string} record.key
   * @returns {boolean}
   */
  isEditing = record => record.key === this.state.editingKey;

  cancel = key => {
    this.setState({ editingKey: '', inputTitle: '' });
  };

  save = (form, key) => {
    const { handleChangePoints } = this.props;
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      this.handleTriangulationValidator(form, row);

      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }

      // this.setState({ data: newData, editingKey: '' });
      handleChangePoints(newData);
    });
  };

  edit = key => {
    this.setState({ editingKey: key });
  };

  handleDelete = key => {
    const { handleChangePoints } = this.props;

    const data = [...this.state.data];
    const currentData = data.filter(item => item.key !== key);

    this.setState({ data: currentData });

    handleChangePoints(currentData);
  };

  handleAdd = () => {
    const { data } = this.state;
    const { handleChangePoints } = this.props;

    let maxKey = 0;

    data.map(item => {
      let key = Number(item.key);
      if (key > maxKey) {
        maxKey = key;
      }
    });

    let count = maxKey + 1;
    const defaultDropDownValue = 'unshaded';

    const newData = {
      key: count,
      id: count,
      description: '',
      coords: [],
      p1: 'A',
      distance1: 0,
      p2: 'B',
      distance2: 0,
      dropDirection: defaultDropDownValue,
      dropDirectionTitle: TriangulationNetworkDropDownList[defaultDropDownValue],
    };

    data.push(newData);

    this.setState({
      data: data,
      editingKey: count,
    });

    // handleChangePoints(data);
  };

  /**
   *
   * @param {string} val
   * @param {React.ReactElement} node
   * @param {*} record
   */
  onSelectChange = (val, node, record) => {
    const { data } = this.state;
    const { value, children } = node.props;

    for (const item of data) {
      if (item.id === record.id) {
        if (record.dataIndex === 'direction') {
          item.dropDirection = value;
          item.dropDirectionTitle = children;
        } else if (record.dataIndex === 'p1') {
          item.p1 = val;
        } else if (record.dataIndex === 'p2') {
          item.p2 = val;
        }
        break;
      }
    }

    this.setState({ data });
    // this.props.handleChangePoints(data);
  };

  handleTriangulationValidator(form, row) {
    if (!this.props.hasIntersects({ distance1: row.distance1, distance2: row.distance2 })) {
      let o =
        row.distance1 > row.distance2
          ? { key: 'distance2', value: row.distance2 }
          : { key: 'distance1', value: row.distance1 };
      form.setFields({
        [o.key]: {
          value: o.value,
          errors: [new Error(this.props.t('property.measurementTab.pointDistanceErr'))],
        },
      });
      return;
    }
  }

  handleFocusInput = columnIndex => {
    let inputTitle = '';

    switch (columnIndex) {
      case 'id':
        inputTitle = this.props.t('property.measurementTab.idInputTitle');
        break;
      case 'description':
        inputTitle = this.props.t('property.measurementTab.descriptionInputTitle');
        break;
      case 'p1':
      case 'p2':
        inputTitle = this.props.t('property.measurementTab.pointInputTitle');
        break;
      case 'distance1':
      case 'distance2':
        inputTitle = this.props.t('property.measurementTab.distanceInputTitle');
        break;
      case 'direction':
        inputTitle = this.props.t('property.measurementTab.directionInputTitle');
        break;
    }

    this.setState({
      inputTitle: inputTitle,
    });
  };

  render() {
    const { data, inputTitle } = this.state;

    const components = {
      table: EditableTableContainer,
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      const handleOnCell = (record, rowIndex) => {
        return {
          onFocus: this.handleFocusInput,
          record,
          inputType: col.dataIndex === 'distance1' || col.dataIndex === 'distance2' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          onSelectChange: this.onSelectChange,
          direction: this.props.direction,
          referencePointsText: this.props.referencePointsText,
          t: this.props.t,
        };
      };

      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: handleOnCell,
      };
    });

    const header = (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <Button className="lncd-button" size="small" onClick={this.handleAdd}>
          {this.props.t('property.measurementTab.addPointButtonText')}
        </Button>
      </div>
    );

    const footer = () => {
      return inputTitle;
    };

    return (
      <EditableContext.Provider value={this.props.form}>
        {header}
        <Table
          id="lncd-measurement-table"
          components={components}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          footer={footer}
          size="small"
        />
      </EditableContext.Provider>
    );
  }
}

class TriangulationNetworkModal extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;

    this.state = {
      object: object,
      distance1: 0,
      distance2: 0,
    };
  }

  handleChangePoints = data => {
    const { object } = this.state;
    const pointList = data.filter(item => item.id && typeof item.p1 === 'string' && typeof item.p2 === 'string');

    object.pointList = pointList;
    workData.setObj(object.operateid, object);
    this.setState({ object: object });
    this.props.handle();
  };

  /**
   * 判断两圆存在交点与不存在交点
   * @param {*} item
   * @returns {[number[], number[]] | []} 两种情况，两圆存在交点与不存在交点
   */
  hasIntersects = item => {
    const {
      object: { referencePoints },
    } = this.props;
    const [x1, y1, x2, y2] = referencePoints.flat().map(p => convertToFeet(p));
    let intersects = intersectTwoCircles(x1, y1, item.distance1, x2, y2, item.distance2);
    return intersects.length > 0;
  };

  render() {
    const { object } = this.state;
    const { pointList = [], referencePointsText } = object;

    const EditableFormTable = Form.create({
      // onValuesChange: this.handleValuesChange,
      // mapPropsToFields(props) {
      //   // console.log('mapPropsToFields', props);
      //   return {
      //     id: Form.createFormField({
      //       ...props.id,
      //       value: props.id.value,
      //     }),
      //     description: Form.createFormField({
      //       ...props.description,
      //       value: props.description.value,
      //     }),
      //   };
      // },
    })(EditableTable);

    return (
      <ModalContainer
        object={object}
        handle={this.props.handle}
        renderPointModal={() => (
          <PointModal>
            <EditableFormTable
              pointList={pointList}
              handleChangePoints={this.handleChangePoints}
              referencePointsText={referencePointsText}
              hasIntersects={this.hasIntersects}
              t={this.props.t}
            />
          </PointModal>
        )}
      />
    );
  }
}

export default withTranslation()(TriangulationNetworkModal);
