import React, { createRef } from 'react';
import { Select, Icon, Divider, Input, Modal, Button } from 'antd';
import { message } from '@src/components/Message';
import { withTranslation } from 'react-i18next';

class TemplateGroup extends React.Component {
  inputRef = createRef();
  state = {
    isAddingItem: false,
    itemName: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isAddingItem !== prevState.isAddingItem) {
      if (this.state.isAddingItem) {
        if (this.inputRef && this.inputRef.current) {
          const { input } = this.inputRef.current;
          input.focus();
          this.setState({ itemName: '' });
        }
      }
    }
  }

  addItem = () => this.setState({ isAddingItem: true });
  changeItem = event => this.setState({ itemName: event.target.value });
  handleCancel = () => this.setState({ isAddingItem: false });
  handleOk = () => {
    if (!this.state.itemName) {
      return message.error(this.props.t('template.groupModal.nameRequired'));
    }
    if (this.props.options.includes(this.state.itemName)) {
      return message.error(this.props.t('template.groupModal.nameAlreadyExist'));
    }
    this.props.addOption(this.state.itemName);
    this.setState({
      itemName: '',
      isAddingItem: false,
    });
  };

  renderGroupName = groupName => {
    if ('Untitled Group' === groupName) {
      return this.props.t('template.listModal.unTitledGroupName');
    }
    return groupName;
  };

  render() {
    return (
      <div>
        <Select
          value={this.props.value || []}
          style={{ width: '100%', height: '32px' }}
          className="custom-select-add"
          onSelect={this.props.onSelect}
          disabled={this.props.disabled}
          suffixIcon={<Icon type="caret-down" className="custom-night-mode-text lncd-select-suffix" />}
          placeholder={this.props.placeholder}
          dropdownRender={menu => (
            <div>
              {menu}
              <div
                style={{ padding: '5px 12px', cursor: 'pointer' }}
                onMouseDown={e => e.preventDefault()}
                onClick={this.addItem}
                className="custom-night-mode-text"
              >
                <Icon type="plus" />
                <span style={{ marginLeft: '5px' }}>{this.props.t('template.groupModal.addGroupText')}</span>
              </div>
            </div>
          )}
        >
          {this.props.options.map(item => (
            <Select.Option key={item}>{this.renderGroupName(item)}</Select.Option>
          ))}
        </Select>
        <Modal
          title={this.props.t('template.groupModal.title')}
          visible={this.state.isAddingItem}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Input
            ref={this.inputRef}
            value={this.state.itemName}
            onChange={this.changeItem}
            autoFocus
            style={{ border: '1px solid gray' }}
          />
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(TemplateGroup);
