import React from 'react';
import {
  GetMatrixRotate,
  GetShapes,
  isAcrossDataUseful,
  sortStreetCrossPointsToClockwise,
  CopyPoint,
  GetLineHeightPoints,
  GetPathArcD,
  GetPathArcDL,
} from '@src/data/BusinessFun';
import {
  GetMatrixString,
  TowPointVerticalLengthByStatus,
  IsUnderLine,
  IsPointValid,
  IsZero,
  FindCircle,
  IsByTime,
  IsArcRangePoint,
  LengthBetweenPoints,
  ByTime,
} from '@src/data/CommonFunc';
import {
  GetShapeClosePath,
  GetShapeTowBesselPath,
  GetShapeThreeTriangle,
  GetShapeLine,
  GetShapeArc1,
  GetShapeArc,
  GetShapeTowBesselPath1,
  GetShapePath,
  GetShapeStreetAcross0,
  GetShapeRect,
  GetShapeStreetAcross1,
} from '../ShapeFun';
import {
  GetStreetLineBegin,
  GetStreetLineEnd,
  GetStreetLineArcLeftPoints,
  GetMaxHeight,
  GetStreetMaxPoints,
  GetStreetAreaBegin,
  GetStreetLineArcRightPoints,
  GetOffsetLinePoints,
} from '@src/actions/StreetFunHandle';
import { offArcPoints } from '@src/actions/StreetOffArcFunHandle';
import UtilityMath from '@src/data/UtilityMath';
import GeomPoint from '@src/data/GeomPoint';
import { ANCHORS_TYPE, COLOR_TYPE, FUNCTION_TYPE } from '@src/constant';

import * as workData from '@src/data/WorkData';

class StreetAcross extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps({ object }) {
    this.init(object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    if (isFirst) {
      this.state = {
        object: object,
      };
    } else {
      this.setState({
        object: object,
      });
    }
  };
  getStreetHideArcShape(shapes, linePoint, lineData, acrossData, index) {
    let bRet = false;
    if (!IsZero(lineData.topleftr)) {
      let objPoints = GetStreetLineArcLeftPoints(linePoint, lineData);
      let circleLeft = FindCircle(objPoints[2], objPoints[3], objPoints[4]);
      let circleRight = FindCircle(objPoints[4], objPoints[5], objPoints[6]);
      let isBytime = IsByTime(objPoints[2], objPoints[3], objPoints[4]);
      let points = acrossData[index];
      if (
        IsArcRangePoint(objPoints[2], objPoints[4], circleLeft, isBytime, points) &&
        IsZero(LengthBetweenPoints(points, circleLeft) - circleLeft[2], true, true)
      ) {
        let hidePoints = acrossData.slice();
        this.getHideBezierShape(shapes, hidePoints, index, [objPoints[2], objPoints[3], objPoints[4]], i);
        bRet = true;
      }
      if (
        IsArcRangePoint(objPoints[4], objPoints[6], circleRight, !isBytime, points) &&
        IsZero(LengthBetweenPoints(points, circleRight) - circleRight[2], true, true)
      ) {
        let hidePoints = acrossData.slice();
        this.getHideBezierShape(shapes, hidePoints, index, [objPoints[4], objPoints[5], objPoints[6]], i);
        bRet = true;
      }
    } else if (!IsZero(lineData.toprightr)) {
      let objPoints = GetStreetLineArcRightPoints(linePoint, lineData);
      let circleLeft = FindCircle(objPoints[2], objPoints[3], objPoints[4]);
      let circleRight = FindCircle(objPoints[4], objPoints[5], objPoints[6]);
      let isBytime = IsByTime(objPoints[2], objPoints[3], objPoints[4]);
      let points = acrossData[index];
      if (
        IsArcRangePoint(objPoints[2], objPoints[4], circleLeft, isBytime, points) &&
        IsZero(LengthBetweenPoints(points, circleLeft) - circleLeft[2], true, true)
      ) {
        let hidePoints = acrossData.slice();
        this.getHideBezierShape(shapes, hidePoints, index, [objPoints[2], objPoints[3], objPoints[4]], i);
        bRet = true;
      } else if (
        IsArcRangePoint(objPoints[4], objPoints[6], circleRight, !isBytime, points) &&
        IsZero(LengthBetweenPoints(points, circleRight) - circleRight[2], true, true)
      ) {
        let hidePoints = acrossData.slice();
        this.getHideBezierShape(shapes, hidePoints, index, [objPoints[4], objPoints[5], objPoints[6]], i);
        bRet = true;
      }
    }
    return bRet;
  }
  getHideBezierShape(shapes, acrossData, index, linePoints, i) {
    let hidePoints = acrossData.slice();
    //tt tb bb bt
    //0   1  2  3

    if (index === 0) {
      // // console.log("dfdf",index,acrossData);
      // if (IsByTime(hidePoints[0], hidePoints[1], hidePoints[2]) != IsByTime(linePoints[0], linePoints[1], linePoints[2]))
      //     CopyPoint(hidePoints[index], linePoints[0]);
      // else
      //  CopyPoint(hidePoints[index], linePoints[2]);
      if (i === 0) {
        CopyPoint(hidePoints[index], linePoints[2]);
      } else if (i === 1) {
        CopyPoint(hidePoints[index], linePoints[2]);
      } else if (i === 2) {
        CopyPoint(hidePoints[index], linePoints[0]);
      } else if (i === 3) {
        CopyPoint(hidePoints[index], linePoints[0]);
      }
    } else if (index === 2) {
      // if (IsByTime(hidePoints[0], hidePoints[1], hidePoints[2]) === IsByTime(linePoints[0], linePoints[1], linePoints[2]))
      //     CopyPoint(hidePoints[index], linePoints[2]);
      // else
      //     CopyPoint(hidePoints[index], linePoints[0]);
      if (i === 0) {
        CopyPoint(hidePoints[index], linePoints[0]);
      } else if (i === 1) {
        CopyPoint(hidePoints[index], linePoints[0]);
      } else if (i === 2) {
        CopyPoint(hidePoints[index], linePoints[2]);
      } else if (i === 3) {
        CopyPoint(hidePoints[index], linePoints[2]);
      }
    }
    let shapeTowBessel0 = GetShapeTowBesselPath(linePoints, 'hidebezier0' + JSON.stringify(linePoints), true, {
      stroke: COLOR_TYPE.TRANSPARENT,
      fill: COLOR_TYPE.TRANSPARENT,
      strokewidth: 4,
    });
    shapes.push(shapeTowBessel0);
    let shapeTowBessel1 = GetShapeTowBesselPath(hidePoints, 'hidebezier1' + JSON.stringify(hidePoints), true, {
      stroke: COLOR_TYPE.BLACK,
      fill: COLOR_TYPE.TRANSPARENT,
      strokewidth: 4,
    });
    shapes.push(shapeTowBessel1);
  }
  getOffsetArcAccrossShape(shapes, lineArcPoint, linePoints, offarcHeight, acrossData, index, i) {
    let offSetPoints = GetOffsetLinePoints(lineArcPoint, linePoints, offarcHeight);
    let bRet = false;
    let isBytime = IsByTime(offSetPoints[2], offSetPoints[3], offSetPoints[4]);
    let circleLeft = FindCircle(offSetPoints[2], offSetPoints[3], offSetPoints[4]);
    let circleRight = FindCircle(offSetPoints[4], offSetPoints[5], offSetPoints[6]);
    let points = acrossData[index];

    if (IsArcRangePoint(offSetPoints[2], offSetPoints[4], circleLeft, isBytime, points)) {
      this.getHideBezierShape(shapes, acrossData, index, [offSetPoints[2], offSetPoints[3], offSetPoints[4]], i);
      bRet = true;
    } else if (IsArcRangePoint(offSetPoints[4], offSetPoints[6], circleRight, !isBytime, points)) {
      this.getHideBezierShape(shapes, acrossData, index, [offSetPoints[4], offSetPoints[5], offSetPoints[6]], i);
      bRet = true;
    }
    return bRet;
  }
  //tt tb bb bt
  //0   1  2  3
  getHideObjArcLine(shapes, acrossData, i) {
    let bRet = false;
    if (!IsPointValid(acrossData)) return bRet;
    let acrossId = acrossData[3].acrossobjid;
    let nowObjId = acrossId.split('||')[0];
    let nowLine = Number(acrossId.split('||')[1]);
    let dataObjId = acrossId.split('||')[2];
    let dataLine = Number(acrossId.split('||')[3]);

    //求操作对象
    let nowObj = workData.getObject(nowObjId);
    let maxheight = GetMaxHeight(nowObj);
    let maxTopPoints = GetStreetMaxPoints(nowObj, maxheight / 2, true);
    let maxBottomPoints = GetStreetMaxPoints(nowObj, maxheight / 2, false);
    let beginIndex = GetStreetAreaBegin(maxTopPoints);
    let centerIndex = maxTopPoints[beginIndex][3].rightindex;
    let endIndex = maxTopPoints[centerIndex][3].rightindex;
    let beginLine = GetStreetLineBegin(nowObj.groupdata);
    let endLine = GetStreetLineEnd(nowObj.groupdata);

    let dataObj = workData.getObject(dataObjId);
    let dataMaxHeight = GetMaxHeight(dataObj);
    let maxDataTopPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, true);
    let maxDataBottomPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, false);
    let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
    let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
    let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
    let dataBeginLine = GetStreetLineBegin(dataObj.groupdata);
    let dataEndLine = GetStreetLineEnd(dataObj.groupdata);

    // tt tb bb bt

    if (!nowObj.streetoffarc) {
      if (nowLine === beginLine) {
        bRet = this.getStreetHideArcShape(shapes, maxTopPoints, nowObj.groupdata[nowLine], acrossData, 2, i);
      } else if (nowLine === endLine) {
        bRet = this.getStreetHideArcShape(shapes, maxBottomPoints, nowObj.groupdata[endLine], acrossData, 2, i);
      }
    } else {
      let arcPoints = offArcPoints(nowObj.handlepoint, nowObj.offarcheight, nowObj.offarccenter);
      let topLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], maxheight / 2, true);
      let bottomLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], maxheight / 2, false);
      if (nowLine === beginLine) {
        bRet = this.getOffsetArcAccrossShape(
          shapes,
          topLineArcPoints,
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          nowObj.offarcheight,
          acrossData,
          2,
          i
        );
      } else if (nowLine === endLine) {
        bRet = this.getOffsetArcAccrossShape(
          shapes,
          bottomLineArcPoints,
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          nowObj.offarcheight,
          acrossData,
          2,
          i
        );
      }
    }
    if (!dataObj.streetoffarc) {
      if (dataLine === dataBeginLine) {
        bRet = this.getStreetHideArcShape(shapes, maxDataTopPoints, dataObj.groupdata[dataLine], acrossData, i);
      } else if (dataLine === dataEndLine) {
        bRet = this.getStreetHideArcShape(shapes, maxDataBottomPoints, dataObj.groupdata[dataLine], acrossData, i);
      }
    } else {
      let arcPoints = offArcPoints(dataObj.handlepoint, dataObj.offarcheight, dataObj.offarccenter);
      let topLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], dataMaxHeight / 2, true);
      let bottomLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], dataMaxHeight / 2, false);
      if (dataLine === dataBeginLine) {
        bRet = this.getOffsetArcAccrossShape(
          shapes,
          topLineArcPoints,
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          dataObj.offarcheight,
          acrossData,
          0,
          i
        );
      } else if (dataLine === dataEndLine) {
        bRet = this.getOffsetArcAccrossShape(
          shapes,
          bottomLineArcPoints,
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          dataObj.offarcheight,
          acrossData,
          0,
          i
        );
      }
    }

    return bRet;
  }
  getAcrossShape(shapes) {
    let acrossData = this.state.object.handlepoint;
    let closePoints = [];

    //sort all points in across data by AntiClockwise
    closePoints = sortStreetCrossPointsToClockwise(acrossData);
    let shapeCloseHide = GetShapeClosePath(closePoints, 'hideCloseShape', true, {
      stroke: COLOR_TYPE.TRANSPARENT,
      fill: COLOR_TYPE.TRANSPARENT,
      strokewidth: 0,
    });

    shapeCloseHide && shapes.push(shapeCloseHide);

    closePoints = sortStreetCrossPointsToClockwise(acrossData, false);

    let squareClosePath = GetShapeClosePath(closePoints, 'hideAcrossCloseShape', true, {
      stroke: COLOR_TYPE.TRANSPARENT,
      fill: COLOR_TYPE.TRANSPARENT,
      strokewidth: 5,
    });

    squareClosePath && shapes.push(squareClosePath);

    let utilityMath = new UtilityMath();
    for (let i = 0; i < acrossData.length; i++) {
      if (acrossData[i].length < 2) continue;
      let cc = false;
      let objList = workData.getUseData();
      for (let j = 0; j < objList.length; j++) {
        if (objList[j].functype === FUNCTION_TYPE.STREETACROSS) {
          let r = sortStreetCrossPointsToClockwise(objList[j].handlepoint);
          let p = [];
          for (let k = 0; k < r.length; k++) {
            p.push(new GeomPoint(r[k][0], r[k][1]));
          }
          let c1 = false;
          for (let k = 0; k < p.length; k++) {
            if (new GeomPoint(acrossData[i][0][0], acrossData[i][0][1]).distance(p[k]) < 0.5) {
              c1 = true;
              break;
            }
          }
          if (c1) {
            break;
          }
          if (utilityMath.isPointInPolygon(new GeomPoint(acrossData[i][0][0], acrossData[i][0][1]), p)) {
            cc = true;
            break;
          }
        }
      }
      if (cc) {
        continue;
      }
      //贝塞尔曲线所在区域的连线，是为了遮挡原图形的相同位置的区域
      let closeBezier = GetShapeThreeTriangle(
        acrossData[i],
        String(i) + '||' + this.state.object.operateid + '||' + ANCHORS_TYPE.STREETACROSSAREA,
        true,
        {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.TRANSPARENT,
          strokewidth: 5,
        }
      );
      closeBezier && shapes.push(closeBezier);

      //贝塞尔曲线本身
      let shapeTowBessel = GetShapeTowBesselPath(acrossData[i], 'bezier' + String(i), true, {
        stroke: COLOR_TYPE.BLACK,
        // fill: COLOR_TYPE.DARKTAN,
        strokewidth: 4,
      });
      shapeTowBessel && shapes.push(shapeTowBessel);
    }
  }
  hideCloseShape(shapes, acrossData, closePoints) {
    let acrossId = this.state.object.acrossid;

    let nowObjId = acrossId.split('||')[0];
    let dataObjId = acrossId.split('||')[1];
    let nowObj = workData.getObject(nowObjId);
    let dataObj = workData.getObject(dataObjId);

    if (!(typeof nowObj == 'object' && typeof dataObj == 'object')) return;
    if (dataObj.streetoffarc) return;
    //求操作对象
    let maxheight = GetMaxHeight(nowObj);
    let maxTopPoints = GetStreetMaxPoints(nowObj, maxheight / 2, true);
    let maxBottomPoints = GetStreetMaxPoints(nowObj, maxheight / 2, false);
    let beginIndex = GetStreetAreaBegin(maxTopPoints);
    let centerIndex = maxTopPoints[beginIndex][3].rightindex;
    let endIndex = maxTopPoints[centerIndex][3].rightindex;

    let dataMaxHeight = GetMaxHeight(dataObj);
    let maxDataTopPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, true);
    let maxDataBottomPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, false);
    let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
    let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
    let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
    let dataBeginLine = GetStreetLineBegin(dataObj.groupdata);
    let dataEndLine = GetStreetLineEnd(dataObj.groupdata);

    let isBytime = IsByTime(maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]);
    let circleTop = FindCircle(maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]);
    let circleBottom = FindCircle(maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]);
    //tt tb bb bt
    if (closePoints.length > 3) {
      if (IsZero(circleTop[2])) {
        let shapeCloseHide1 = GetShapeClosePath(
          [acrossData[0][0], acrossData[1][0], acrossData[2][0], acrossData[3][0]],
          'hideCloseShape1',
          true,
          {
            stroke: COLOR_TYPE.NONE,
            fill: COLOR_TYPE.TRANSPARENT,
            strokewidth: 4,
          }
        );
        shapes.push(shapeCloseHide1);
        let shapeCloseHide2 = GetShapeClosePath(
          [acrossData[0][2], acrossData[1][2], acrossData[2][2], acrossData[3][2]],
          'hideCloseShape2',
          true,
          {
            stroke: COLOR_TYPE.NONE,
            fill: COLOR_TYPE.TRANSPARENT,
            strokewidth: 4,
          }
        );
        shapes.push(shapeCloseHide2);
      } else {
        let isByime1 = IsByTime(maxTopPoints[beginIndex], acrossData[1][1], acrossData[0][1]);
        let D = [];

        if (isBytime === isByime1) {
          let shapeCloseHide2 = GetShapeStreetAcross0(
            acrossData[0][1],
            acrossData[1][1],
            acrossData[2][1],
            acrossData[3][1],
            isBytime,
            circleTop[2],
            circleBottom[2],
            'hideCloseShape2',
            true,
            {
              stroke: COLOR_TYPE.NONE,
              fill: COLOR_TYPE.TRANSPARENT,
              strokewidth: 4,
            }
          );
          shapes.push(shapeCloseHide2);
        } else {
          let shapeCloseHide2 = GetShapeStreetAcross0(
            acrossData[0][1],
            acrossData[1][1],
            acrossData[2][1],
            acrossData[3][1],
            isBytime,
            circleTop[2],
            circleBottom[2],
            'hideCloseShape2',
            true,
            {
              stroke: COLOR_TYPE.NONE,
              fill: COLOR_TYPE.TRANSPARENT,
              strokewidth: 4,
            }
          );
          shapes.push(shapeCloseHide2);
        }
      }
    } else if (closePoints.length === 2 || closePoints.length === 3) {
      if (IsPointValid(acrossData[0]) && IsPointValid(acrossData[3])) {
        let closeIndex = beginIndex;
        if (
          LengthBetweenPoints(acrossData[0][1], maxTopPoints[beginIndex]) <
          LengthBetweenPoints(acrossData[0][1], maxBottomPoints[endIndex])
        ) {
          closeIndex = beginIndex;
        } else {
          closeIndex = endIndex;
        }

        let shapeCloseHide1 = GetShapeStreetAcross1(
          acrossData[0][1],
          maxTopPoints[closeIndex],
          maxBottomPoints[closeIndex],
          acrossData[3][1],
          circleTop[2],
          circleBottom[2],
          'hideCloseShape1',
          true,
          {
            stroke: COLOR_TYPE.TRANSPARENT,
            fill: COLOR_TYPE.TRANSPARENT,
            strokewidth: 4,
          }
        );
        shapes.push(shapeCloseHide1);
      } else if (IsPointValid(acrossData[1]) && IsPointValid(acrossData[2])) {
        let closeIndex = beginIndex;
        if (
          LengthBetweenPoints(acrossData[1][1], maxTopPoints[beginIndex]) <
          LengthBetweenPoints(acrossData[1][1], maxBottomPoints[endIndex])
        ) {
          closeIndex = beginIndex;
        } else {
          closeIndex = endIndex;
        }

        let shapeCloseHide1 = GetShapeStreetAcross1(
          acrossData[1][1],
          maxTopPoints[closeIndex],
          maxBottomPoints[closeIndex],
          acrossData[2][1],
          circleTop[2],
          circleBottom[2],
          'hideCloseShape1',
          true,
          {
            stroke: COLOR_TYPE.TRANSPARENT,
            fill: COLOR_TYPE.TRANSPARENT,
            strokewidth: 4,
          }
        );
        shapes.push(shapeCloseHide1);
      }
    }
  }
  getHideCloseLine(shapes, point1, point2) {
    if (!(IsPointValid(point1) && IsPointValid(point2))) return;
    let acrossId = point1[3].acrossobjid;
    let nowObjId = acrossId.split('||')[0];
    let nowLine = Number(acrossId.split('||')[1]);
    let dataObjId = acrossId.split('||')[2];
    let dataLine = Number(acrossId.split('||')[3]);

    let acrossId2 = point2[3].acrossobjid;
    let nowLine2 = Number(acrossId2.split('||')[1]);
    let dataLine2 = Number(acrossId2.split('||')[3]);

    //求操作对象
    let nowObj = workData.getObject(nowObjId);
    let maxheight = GetMaxHeight(nowObj);
    let maxTopPoints = GetStreetMaxPoints(nowObj, maxheight / 2, true);
    let maxBottomPoints = GetStreetMaxPoints(nowObj, maxheight / 2, false);
    let beginIndex = GetStreetAreaBegin(maxTopPoints);
    let centerIndex = maxTopPoints[beginIndex][3].rightindex;
    let endIndex = maxTopPoints[centerIndex][3].rightindex;
    let beginLine = GetStreetLineBegin(nowObj.groupdata);
    let endLine = GetStreetLineEnd(nowObj.groupdata);

    let dataObj = workData.getObject(dataObjId);
    let dataMaxHeight = GetMaxHeight(dataObj);
    let maxDataTopPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, true);
    let maxDataBottomPoints = GetStreetMaxPoints(dataObj, dataMaxHeight / 2, false);
    let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
    let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
    let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
    let dataBeginLine = GetStreetLineBegin(dataObj.groupdata);
    let dataEndLine = GetStreetLineEnd(dataObj.groupdata);
    //tt - bt
    if (nowLine === beginLine && dataLine === dataBeginLine && nowLine2 === endLine && dataLine2 === dataBeginLine) {
      let circle = FindCircle(
        maxDataTopPoints[dataBeginIndex],
        maxDataTopPoints[dataCenterIndex],
        maxDataTopPoints[dataEndIndex]
      );
      shapes.push(
        GetShapeArc(point1[1], point2[1], circle[2], false, true, 'closeHide1' + String(point1[0] + point2[0]), true, {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.NONE,
          strokewidth: 8,
        })
      );
      shapes.push(
        GetShapeArc(point1[1], point2[1], circle[2], false, false, 'closeHide2' + String(point1[0] + point2[0]), true, {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.NONE,
          strokewidth: 8,
        })
      );
    }
    //tt-tb
    else if (
      nowLine === beginLine &&
      dataLine === dataBeginLine &&
      nowLine2 === beginLine &&
      dataLine2 === dataEndLine
    ) {
      let circle = FindCircle(maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]);
      shapes.push(
        GetShapeArc(point1[1], point2[1], circle[2], false, true, 'closeHide1' + String(point1[0] + point2[0]), true, {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.NONE,
          strokewidth: 8,
        })
      );
      shapes.push(
        GetShapeArc(point1[1], point2[1], circle[2], false, false, 'closeHide2' + String(point1[0] + point2[0]), true, {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.NONE,
          strokewidth: 8,
        })
      );
    }
    // tb - bb
    else if (nowLine === beginLine && dataLine === dataEndLine && nowLine2 === endLine && dataLine2 === dataEndLine) {
      let circle = FindCircle(
        maxDataBottomPoints[dataBeginIndex],
        maxDataBottomPoints[dataCenterIndex],
        maxDataBottomPoints[dataEndIndex]
      );
      shapes.push(
        GetShapeArc(point1[1], point2[1], circle[2], false, false, 'closeHide1' + String(point1[0] + point2[0]), true, {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.NONE,
          strokewidth: 8,
        })
      );
      shapes.push(
        GetShapeArc(point1[1], point2[1], circle[2], false, true, 'closeHide2' + String(point1[0] + point2[0]), true, {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.NONE,
          strokewidth: 8,
        })
      );
    }
    //bb-bt
    else if (nowLine === endLine && dataLine === dataEndLine && nowLine2 === endLine && dataLine2 === dataBeginLine) {
      let circle = FindCircle(maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]);
      shapes.push(
        GetShapeArc(
          point1[1],
          point2[1],
          circle[2],
          false,
          true,
          '0',
          'closeHide1' + String(point1[0] + point2[0]),
          true,
          {
            stroke: COLOR_TYPE.TRANSPARENT,
            fill: COLOR_TYPE.NONE,
            strokewidth: 8,
          }
        )
      );
      shapes.push(
        GetShapeArc(point1[1], point2[1], circle[2], false, '1', 'closeHide2' + String(point1[0] + point2[0]), true, {
          stroke: COLOR_TYPE.TRANSPARENT,
          fill: COLOR_TYPE.NONE,
          strokewidth: 8,
        })
      );
    }
  }

  getStreetAcross(shapes, obj) {
    let acrossData = obj.streetacrossdata;
    for (let i = 0; i < acrossData.length; i++) {
      let tempData = acrossData[i];
      //id||objid  (相交图形Id||要计算的道路图形objid)
      if (typeof tempData === 'string') {
        let acrossObj = workData.getObject(tempData.split('||')[0]);
        if (typeof acrossObj === 'object' && acrossObj != null) {
          for (let j = 0; j < acrossObj.handlepoint.length; j++) {
            if (acrossObj.handlepoint[j].length < 2) continue;
            let acrossId = acrossObj.handlepoint[j][3].acrossobjid;
            let nowObjId = acrossId.split('||')[0];
            let dataObjId = acrossId.split('||')[2];
            let nowObj = workData.getObject(nowObjId);
            let dataObj = workData.getObject(dataObjId);
            if (!(typeof nowObj == 'object' && typeof dataObj == 'object')) continue;
            let closeBezier = GetShapeThreeTriangle(
              acrossObj.handlepoint[j],
              String(j) + '||' + acrossObj.operateid + '||' + ANCHORS_TYPE.STREETACROSSAREA,
              true,
              {
                stroke: COLOR_TYPE.WHITE,
                fill: COLOR_TYPE.WHITE,
                strokewidth: 4,
              }
            );
            shapes.push(closeBezier);
            let shapeTowBessel = GetShapeTowBesselPath(
              acrossObj.handlepoint[j],
              'bezier333' + String(i * 10 + j),
              true,
              {
                stroke: COLOR_TYPE.BLACK,
                fill: COLOR_TYPE.NONE,
                strokewidth: 4,
              }
            );
            shapes.push(shapeTowBessel);
          }
        }
      }
    }
  }
  render() {
    const { props } = this;
    const { object } = props;
    if (object == null || object.handlepoint.length < 3) return null;
    var shapes = [];
    this.getAcrossShape(shapes);
    //this.getStreetAcross(shapes, object);
    return <g id={object.functype}> {GetShapes(shapes)} </g>;
  }
}

export default StreetAcross;
