import * as React from 'react';

function SvgIconBikeLane(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .002h24v24H0z" />
        <path
          d="M14.843 11.768l-.281-3.377a.313.313 0 00-.312-.287H13a.313.313 0 000 .625h.963l.121 1.459h-2.166l-.417-.626h.666a.313.313 0 000-.624h-2.084a.313.313 0 000 .624h.667l.416.626h-.25a.313.313 0 00-.286.189l-.606 1.414a1.631 1.631 0 00-.357-.041c-.92 0-1.667.748-1.667 1.667 0 .918.748 1.666 1.667 1.666s1.666-.748 1.666-1.666c0-.216-.044-.42-.119-.61l2.934-1.857.072.868A1.665 1.665 0 0013 13.417c0 .918.748 1.666 1.667 1.666s1.666-.748 1.666-1.666c0-.859-.655-1.56-1.49-1.65zM10.5 13.417a.834.834 0 11-.833-.834l.016.002-.303.708a.313.313 0 00.454.387l.651-.412a.833.833 0 01.015.149zm.379-1.137a1.663 1.663 0 00-.28-.245l.524-1.223h2.075l-2.32 1.468zm3.788 1.97a.832.832 0 01-.375-1.573l.063.766a.312.312 0 10.623-.052l-.064-.765a.83.83 0 01-.247 1.624z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path stroke="currentColor" strokeWidth={2} d="M5 24V0M19 24V0" />
      </g>
    </svg>
  );
}

export default SvgIconBikeLane;
