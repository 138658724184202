import React from 'react';
import { ByTime, getAngleBy2Points, GetCirclePoint, TowPointVerticalAcross } from '@src/data/CommonFunc';
import { GetPathLineD, GetPathArcD, GetPathLineD1 } from '@src/data/BusinessFun';
import { GetShapeLine, GetShapeArc, GetShapePath } from '@src/shapes/ShapeFun';
import {
  STRUCTURE_DOOR_TYPE,
  STRUCTURE_DOOR_WIDTH,
  COLOR_TYPE,
  DASH_ARRAY_TYPE,
  THREE_POINT_STATUS_TYPE,
} from '@src/constant';

export function AddStrutueDoorData() {
  let data = {
    doortype: STRUCTURE_DOOR_TYPE.door32,
    selectflag: false,
    isfinish: false,
    x: -1,
    y: -1,
    angle: (Math.PI * 3) / 2,
    door72angle: (Math.PI * 3) / 2,
    r: STRUCTURE_DOOR_WIDTH.door32,
  };
  return data;
}

/**
 *
 * @param {*} obj
 * @param {*} index
 * @param {*} pointMove
 * @param {*} doortype
 */
export function StructureDoorMove(obj, index, pointMove, doortype) {
  //生成 door 拖动的时候数据
  let iCount = obj.handlepoint[index].length;
  obj.selectflag = true;
  let point = TowPointVerticalAcross(obj.handlepoint[index], obj.handlepoint[index + 1], pointMove);
  if (
    (obj.handlepoint[index][iCount - 1].doortype === STRUCTURE_DOOR_TYPE.door32 ||
      obj.handlepoint[index][iCount - 1].doortype === STRUCTURE_DOOR_TYPE.door72 ||
      obj.handlepoint[index][iCount - 1].doortype === STRUCTURE_DOOR_TYPE.doubledoor16) &&
    obj.handlepoint[index][iCount - 1].isfinish === false
  ) {
    obj.handlepoint[index][iCount - 1].x = point[0];
    obj.handlepoint[index][iCount - 1].y = point[1];
    obj.handlepoint[index][iCount - 1].selectflag = true;
  } else {
    obj.handlepoint[index][iCount] = AddStrutueDoorData();
    obj.handlepoint[index][iCount].doortype = doortype;
    if (doortype === STRUCTURE_DOOR_TYPE.door72) obj.handlepoint[index][iCount].r = STRUCTURE_DOOR_WIDTH.door72;
    else if (doortype === STRUCTURE_DOOR_TYPE.doubledoor16)
      obj.handlepoint[index][iCount].r = STRUCTURE_DOOR_WIDTH.doubledoor16;
    obj.handlepoint[index][iCount].x = point[0];
    obj.handlepoint[index][iCount].y = point[1];
  }
}
export function StructureDoorFinish(obj, index, pointMove) {
  //生成 door完成拖动的时候数据
  let iCount = obj.handlepoint[index].length;
  obj.selectflag = true;
  let point = TowPointVerticalAcross(obj.handlepoint[index], obj.handlepoint[index + 1], pointMove);
  if (
    obj.handlepoint[index][iCount - 1].doortype === STRUCTURE_DOOR_TYPE.door32 ||
    obj.handlepoint[index][iCount - 1].doortype === STRUCTURE_DOOR_TYPE.door72 ||
    obj.handlepoint[index][iCount - 1].doortype === STRUCTURE_DOOR_TYPE.doubledoor16
  ) {
    obj.handlepoint[index][iCount - 1].x = point[0];
    obj.handlepoint[index][iCount - 1].y = point[1];
    obj.handlepoint[index][iCount - 1].selectflag = false;
    obj.handlepoint[index][iCount - 1].isfinish = true;
  }
}
export function StructureSelectDoorShape(beginPoint, endPoint, index, doorType) {
  //生成图形
  let angle = getAngleBy2Points(beginPoint, endPoint);
  let doordata = beginPoint[index];
  let doorCircle = [doordata.x, doordata.y, doordata.r];
  let shape = null;
  let D = [];
  if (doorType === STRUCTURE_DOOR_TYPE.door32) {
    let doorEndPoint = GetCirclePoint(doorCircle, angle);
    GetPathLineD(D, [doordata.x, doordata.y], doorEndPoint);
    shape = (
      <path
        d={D.join(' ')}
        strokeWidth={beginPoint[3].strokewidth * 2}
        stroke={COLOR_TYPE.TAN}
        strokeDasharray={DASH_ARRAY_TYPE.LINE}
        key={JSON.stringify(doordata)}
      />
    );
  } else if (doorType === STRUCTURE_DOOR_TYPE.door72 || doorType === STRUCTURE_DOOR_TYPE.doubledoor16) {
    let doorEndPoint = GetCirclePoint(doorCircle, angle);
    let doorBeginPoint = GetCirclePoint(doorCircle, angle + Math.PI);
    GetPathLineD(D, doorBeginPoint, doorEndPoint);
  }
  shape = (
    <path
      d={D.join(' ')}
      strokeWidth={beginPoint[3].strokewidth * 2}
      stroke={COLOR_TYPE.TAN}
      strokeDasharray={DASH_ARRAY_TYPE.LINE}
      key={JSON.stringify(doordata)}
    />
  );
  return shape;
}

export function StructureDoor32Shape(beginPoint, endPoint, index) {
  //生成图形
  let angle = getAngleBy2Points(beginPoint, endPoint);
  let doordata = beginPoint[index];
  let doorCircle = [doordata.x, doordata.y, doordata.r];
  let doorEndPoint = GetCirclePoint(doorCircle, angle);
  let doorTopPoint = GetCirclePoint(doorCircle, angle + beginPoint[index].angle);
  let doorTopLine = GetShapeLine([doordata.x, doordata.y], doorTopPoint, 'top', true, {
    stroke: COLOR_TYPE.LIGHTGRAY,
    strokewidth: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });
  let doorEndLine = GetShapeLine([doordata.x, doordata.y], doorEndPoint, 'end', true, {
    stroke: COLOR_TYPE.WHITE,
    strokewidth: beginPoint[3].strokewidth + 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });
  let isBytime = ByTime(beginPoint, doorTopPoint, endPoint) === THREE_POINT_STATUS_TYPE.BYTIME;
  let doorArc = GetShapeArc(doorTopPoint, doorEndPoint, beginPoint[index].r, false, isBytime, 'arc', true, {
    stroke: COLOR_TYPE.LIGHTGRAY,
    fill: COLOR_TYPE.NONE,
    strokewidth: 0.5,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });

  return (
    <g id="32door" key={JSON.stringify(doordata) + String(index)}>
      {doorTopLine}
      {doorEndLine}
      {doorArc}
    </g>
  );
}

export function StructureDoor72Shape(beginPoint, endPoint, index) {
  //生成图形
  let angle = getAngleBy2Points(beginPoint, endPoint);
  let doordata = beginPoint[index];
  let doorCircle = [doordata.x, doordata.y, doordata.r];
  let doorEndPoint = GetCirclePoint(doorCircle, angle);
  let doorBeginPoint = GetCirclePoint(doorCircle, angle + Math.PI);
  let doorBeginCircle = [doorBeginPoint[0], doorBeginPoint[1], doorCircle[2]];
  let doorBeginTopPoint = GetCirclePoint(doorBeginCircle, angle + beginPoint[index].angle);
  let doorEndCircle = [doorEndPoint[0], doorEndPoint[1], doorCircle[2]];
  let doorEndTopPoint = GetCirclePoint(doorEndCircle, angle + beginPoint[index].door72angle);
  let doorBeginTopLine = GetShapeLine(doorBeginPoint, doorBeginTopPoint, 'begintop', true, {
    stroke: COLOR_TYPE.LIGHTGRAY,
    strokewidth: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });
  let doorEndTopLine = GetShapeLine(doorEndPoint, doorEndTopPoint, 'endtop', true, {
    stroke: COLOR_TYPE.LIGHTGRAY,
    strokewidth: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });
  let doorEndLine = GetShapeLine(doorBeginPoint, doorEndPoint, 'end', true, {
    stroke: COLOR_TYPE.WHITE + 1,
    strokewidth: beginPoint[3].strokewidth,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });

  let isBytime = ByTime(beginPoint, doorBeginTopPoint, endPoint) === THREE_POINT_STATUS_TYPE.BYTIME;
  let doorBeginArc = GetShapeArc(
    doorBeginTopPoint,
    [doordata.x, doordata.y],
    beginPoint[index].r,
    false,
    isBytime,
    'beginarc',
    true,
    {
      stroke: COLOR_TYPE.LIGHTGRAY,
      fill: COLOR_TYPE.NONE,
      strokewidth: 0.5,
      strokeDasharray: DASH_ARRAY_TYPE.solid,
    }
  );
  let isBytimeEnd = ByTime(endPoint, doorBeginTopPoint, beginPoint) === THREE_POINT_STATUS_TYPE.BYTIME;
  let doorEndArc = GetShapeArc(
    doorEndTopPoint,
    [doordata.x, doordata.y],
    beginPoint[index].r,
    false,
    isBytimeEnd,
    'endarc',
    true,
    {
      stroke: COLOR_TYPE.LIGHTGRAY,
      fill: COLOR_TYPE.NONE,
      strokewidth: 0.5,
      strokeDasharray: DASH_ARRAY_TYPE.solid,
    }
  );
  return (
    <g id="72door" key={JSON.stringify(doordata) + String(index)}>
      {doorBeginTopLine}
      {doorEndTopLine}
      {doorEndLine}
      {doorBeginArc}
      {doorEndArc}
    </g>
  );
}

export function StructureDoubleDoor16Shape(beginPoint, endPoint, index) {
  //生成图形
  let angle = getAngleBy2Points(beginPoint, endPoint);
  let doordata = beginPoint[index];
  let doorCircle = [doordata.x, doordata.y, doordata.r];
  let doorBeginPoint = GetCirclePoint(doorCircle, angle + Math.PI);
  let doorEndPoint = GetCirclePoint(doorCircle, angle);

  let doorBeginCircle = [doorBeginPoint[0], doorBeginPoint[1], doorCircle[2]];
  let doorBeginTopPoint = GetCirclePoint(doorBeginCircle, angle + beginPoint[index].angle);
  let doorEndCircle = [doorEndPoint[0], doorEndPoint[1], doorCircle[2]];
  let doorEndTopPoint = GetCirclePoint(doorEndCircle, angle + beginPoint[index].door72angle);

  let doorEndLine = GetShapeLine(doorBeginPoint, doorEndPoint, 'end', true, {
    stroke: COLOR_TYPE.WHITE,
    strokewidth: beginPoint[3].strokewidth + 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });

  let doorEndRealyLine = GetShapeLine(doorBeginPoint, doorEndPoint, 'endrealy', true, {
    stroke: COLOR_TYPE.LIGHTGRAY,
    strokewidth: 2,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  });
  let D = [];
  GetPathLineD(D, doorBeginTopPoint, doorBeginPoint);
  GetPathLineD1(D, doorEndTopPoint);
  GetPathLineD1(D, doorEndPoint);
  GetPathLineD1(D, doorBeginTopPoint);
  GetPathLineD1(D, doorEndTopPoint);
  let doorShape = GetShapePath(D, '16doubledoor', true, {
    stroke: COLOR_TYPE.LIGHTGRAY,
    strokewidth: 0.5,
    strokeDasharray: DASH_ARRAY_TYPE.Dashes,
  });
  return (
    <g id="16doubledoor" key={JSON.stringify(doordata) + String(index)}>
      {doorEndLine}
      {doorEndRealyLine}
      {doorShape}
    </g>
  );
}
