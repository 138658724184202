import Utility from './Utility';
import GeomPoint from './GeomPoint';

class GeomQ {
  /** @type {GeomPoint} */
  ptStart;

  /** @type {GeomPoint} */
  ptStop;

  /** @type {GeomPoint} */
  pt;

  /**
   *
   * @param {GeomPoint} ptStart
   * @param {GeomPoint} ptStop
   * @param {GeomPoint} pt
   */
  constructor(ptStart, ptStop, pt) {
    this.declaredClass = 'GeomQ';
    this.ptStart = ptStart.clone();
    this.ptStop = ptStop.clone();
    this.pt = pt.clone();
  }

  /**
   * get the start point
   * @returns {GeomPoint}
   */
  getPointStart() {
    return this.ptStart.clone();
  }

  /**
   * get the stop point
   * @returns {GeomPoint}
   */
  getPointStop() {
    return this.ptStop.clone();
  }

  /**
   * 获取 segment 路径数据
   * @param {boolean} isMove
   * @returns {string}
   */
  getSegmentPathData(isMove) {
    var pathData = '';
    if (isMove) {
      pathData = 'M' + this.ptStart.toString();
    }
    if (pathData.length > 0) {
      pathData += ' ';
    }
    pathData += 'Q' + this.pt.toString();
    pathData += ' ' + this.ptStop.toString();
    return pathData;
  }
}

export default GeomQ;
