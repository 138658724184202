import React, { Component } from 'react';
import { Dropdown, Menu, Icon } from 'antd';
import { IconDown } from '@src/components/UI/Icons';
import {
  ImageDashLine,
  ImageDashSolid,
  ImageSolidLine,
  ImageSolidDash,
  ImageDoubleSolid,
  ImageDoubleDash,
  ImageDottedLine,
} from '@src/components/UI/Images';
import { stripeStyles } from '@src/data/ShapeDataList';
import { setStripeStyle } from '@src/actions/HandlePoint';
import { STREET_STRIPES_TYPE } from '@src/constant';
import { withTranslation } from 'react-i18next';

class StripeStyleSelect extends Component {
  onMenuClick = ({ key }) => {
    const { streetId, stripeIndex } = this.props;
    setStripeStyle({
      streetId,
      stripeIndex,
      stripeType: key,
    });
  };

  getStripeSnapshot = stripeType => {
    switch (stripeType) {
      case STREET_STRIPES_TYPE.solid:
        return <ImageSolidLine />;
      case STREET_STRIPES_TYPE.dash:
        return <ImageDashLine />;
      case STREET_STRIPES_TYPE.doublesolid:
        return <ImageDoubleSolid />;
      case STREET_STRIPES_TYPE.doubledash:
        return <ImageDoubleDash />;
      case STREET_STRIPES_TYPE.soliddash:
        return <ImageSolidDash />;
      case STREET_STRIPES_TYPE.dashsolid:
        return <ImageDashSolid />;
      case STREET_STRIPES_TYPE.reflectors:
        return <ImageDottedLine />;
      case STREET_STRIPES_TYPE.hide:
        return this.props.t('property.addLane.none');
      default:
        return null;
    }
  };

  getOverlay = () => {
    return (
      <Menu className="stripe-style-select-overlay" onClick={this.onMenuClick}>
        <Menu.Item key={STREET_STRIPES_TYPE.solid}>{this.getStripeSnapshot(STREET_STRIPES_TYPE.solid)}</Menu.Item>
        <Menu.Item key={STREET_STRIPES_TYPE.dash}>{this.getStripeSnapshot(STREET_STRIPES_TYPE.dash)}</Menu.Item>
        <Menu.Item key={STREET_STRIPES_TYPE.doublesolid}>
          {this.getStripeSnapshot(STREET_STRIPES_TYPE.doublesolid)}
        </Menu.Item>
        <Menu.Item key={STREET_STRIPES_TYPE.doubledash}>
          {this.getStripeSnapshot(STREET_STRIPES_TYPE.doubledash)}
        </Menu.Item>
        <Menu.Item key={STREET_STRIPES_TYPE.soliddash}>
          {this.getStripeSnapshot(STREET_STRIPES_TYPE.soliddash)}
        </Menu.Item>
        <Menu.Item key={STREET_STRIPES_TYPE.dashsolid}>
          {this.getStripeSnapshot(STREET_STRIPES_TYPE.dashsolid)}
        </Menu.Item>
        <Menu.Item key={STREET_STRIPES_TYPE.reflectors}>
          {this.getStripeSnapshot(STREET_STRIPES_TYPE.reflectors)}
        </Menu.Item>
        <Menu.Item key={STREET_STRIPES_TYPE.hide}>{this.getStripeSnapshot(STREET_STRIPES_TYPE.hide)}</Menu.Item>
      </Menu>
    );
  };

  render() {
    const { stripePattern } = this.props;
    const stripeType = stripeStyles.find(n => n.pattern === stripePattern).type;
    return (
      <Dropdown className="custom-dropdown stripe-style-select" overlay={this.getOverlay()} trigger={['click']}>
        <div className="custom-dropdown-item">
          <span>{this.getStripeSnapshot(stripeType)}</span>
          <Icon component={IconDown} />
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(StripeStyleSelect);
