import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import { IconClose } from '@src/components/UI/Icons';
import { addStreetLane } from '@src/actions/HandlePoint';

export class RemoveStripeButton extends Component {
  removeStripe = () => {
    const { streetId, stripeIndex } = this.props;
    // TODO: rename this function or move the 'remove' action somewhere else
    addStreetLane({
      streetId,
      stripeIndex,
      name: 'Remove',
    });
  };

  render() {
    return (
      <Button onClick={this.removeStripe}>
        <Icon component={IconClose} />
      </Button>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveStripeButton);
