class SVGPathSeg {
  constructor() {
    this.currentIndex = 0;
    this.endIndex = 0;
    this.string = '';
  }

  /**
   *
   * @param {string} d - svg path
   * @returns {array}
   */
  parsePath(d) {
    if (!d || d.length == 0) {
      return [];
    }
    this.string = d;
    this.currentIndex = 0;
    this.endIndex = this.string.length;
    if (!this.hasMoreData()) {
      return [];
    }
    let c = this.string[this.currentIndex];
    let pathSegList = [];
    while (this.hasMoreData()) {
      let s = this.parseSegment();
      if (!s) {
        return [];
      }
      pathSegList.push(s);
    }
    return pathSegList;
  }

  hasMoreData() {
    return this.currentIndex < this.endIndex;
  }

  parseSegment() {
    let c = this.string[this.currentIndex];
    this.currentIndex++;
    switch (c) {
      case 'M':
        return { type: 'M', point: [this.parseNumber(), this.parseNumber()] };
      case 'L':
        return { type: 'L', point: [this.parseNumber(), this.parseNumber()] };
      case 'Z':
        this.skip();
        return { type: 'Z' };
      case 'Q':
        return { type: 'Q', point: [this.parseNumber(), this.parseNumber()] };
      case 'A':
        return {
          type: 'A',
          radius: [this.parseNumber(), this.parseNumber()],
          arcAngle: this.parseNumber(),
          arcLarge: this.parseArcFlag(),
          arcSweep: this.parseArcFlag(),
          point: [this.parseNumber(), this.parseNumber()],
        };
    }
  }

  parseNumber = function() {
    let integral = 0;
    let fractional = 0;
    let sign = 1;
    this.skip();
    if (this.currentIndex < this.endIndex && this.string.charAt(this.currentIndex) == '-') {
      this.currentIndex++;
      sign = -1;
    }
    if (
      this.currentIndex == this.endIndex ||
      ((this.string.charAt(this.currentIndex) < '0' || this.string.charAt(this.currentIndex) > '9') &&
        this.string.charAt(this.currentIndex) != '.')
    ) {
      return undefined;
    }
    let s = this.currentIndex;
    while (
      this.currentIndex < this.endIndex &&
      this.string.charAt(this.currentIndex) >= '0' &&
      this.string.charAt(this.currentIndex) <= '9'
    ) {
      this.currentIndex++;
    }
    if (this.currentIndex != s) {
      let e = this.currentIndex - 1;
      let i = 1;
      while (e >= s) {
        integral += i * (this.string.charAt(e--) - '0');
        i *= 10;
      }
    }
    if (this.currentIndex < this.endIndex && this.string.charAt(this.currentIndex) == '.') {
      this.currentIndex++;
      if (
        this.currentIndex >= this.endIndex ||
        this.string.charAt(this.currentIndex) < '0' ||
        this.string.charAt(this.currentIndex) > '9'
      ) {
        return undefined;
      }
      let f = 1;
      while (
        this.currentIndex < this.endIndex &&
        this.string.charAt(this.currentIndex) >= '0' &&
        this.string.charAt(this.currentIndex) <= '9'
      ) {
        f *= 10;
        fractional += (this.string.charAt(this.currentIndex) - '0') / f;
        this.currentIndex += 1;
      }
    }
    let result = integral + fractional;
    result *= sign;
    this.skip();
    return result;
  };
  parseArcFlag = function() {
    if (this.currentIndex >= this.endIndex) {
      return undefined;
    }
    let flag = false;
    let c = this.string.charAt(this.currentIndex++);
    if (c == '0') {
      flag = false;
    } else {
      if (c == '1') {
        flag = true;
      } else {
        return undefined;
      }
    }
    this.skip();
    return flag;
  };

  skip() {
    if (
      this.currentIndex < this.endIndex &&
      this.string.charAt(this.currentIndex) != ' ' &&
      this.string.charAt(this.currentIndex) != ','
    ) {
      return false;
    }
    while (this.currentIndex < this.endIndex && this.string.charAt(this.currentIndex) == ' ') {
      this.currentIndex++;
    }
    if (this.currentIndex < this.endIndex && this.string.charAt(this.currentIndex) == ',') {
      this.currentIndex++;
      while (this.currentIndex < this.endIndex && this.string.charAt(this.currentIndex) == ' ') {
        this.currentIndex++;
      }
    }
    return this.currentIndex < this.endIndex;
  }
}

export default SVGPathSeg;
