import * as React from 'react';

function SvgIconGrassDivider(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 12 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-6 .002h24v24H-6z" />
        <path
          d="M7.732.5H4.268l-.667 1 1 1.5-1 1.5 1 1.5-1 1.5 1 1.5-1 1.5 1 1.5-1 1.5 1 1.5-1 1.5 1 1.5-1 1.5 1 1.5-1 1.5.667 1h3.464l.667-1-1-1.5 1-1.5-1-1.5 1-1.5-1-1.5 1-1.5-1-1.5 1-1.5-1-1.5 1-1.5-1-1.5 1-1.5-1-1.5 1-1.5-.667-1z"
          stroke="#58724E"
          fill="#ACB9A7"
          opacity={0.502}
        />
        <path stroke="currentColor" strokeWidth={2} d="M11 24V0M1 24V0" />
      </g>
    </svg>
  );
}

export default SvgIconGrassDivider;
