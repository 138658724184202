import { setDrawingShape } from '@src/action/canvas';
import { setCurrentState, setCursor } from '@src/action/drawingStatus';
import { barSizeChanged, openExpand, selectSymbol, toggleExpand } from '@src/action/symbolBar';
import { getSnapToStreetData } from '@src/actions/StreetFunHandle';
import { addUseTimestamp, isFrequentlyUsed } from '@src/components/DataProvider/DataProvider';
import { message } from '@src/components/Message';
import TouchScroll from '@src/components/TouchDevice/TouchScroll';
import { APP_ROOT_EL_ID, CURSOR, SNAP_TO_STREET_STYLE } from '@src/constant';
import { clientPtToUserPt } from '@src/data/CommonFunction';
import emitter from '@src/data/Event';
import GeomPoint from '@src/data/GeomPoint';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { getClickPoint } from '@src/utils';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import db, { FAVORITE_STORE } from '../../DataProvider/database';
import { ExpandToggler } from './ExpandToggler';
import { SymbolAvatar } from './SymbolAvatar';
import SymbolBarItem from './SymbolBarItem';
import StyleVariables from './symbol-bar.scss';

class SymbolBar extends Component {
  constructor(props) {
    super(props);
    this.appRoot = undefined;
    this.svgRoot = undefined;
    this.scrollAreaComp = React.createRef();
  }

  state = {
    avatarRotationAngle: 0,
  };

  componentDidMount() {
    // TODO: use ref
    this.appRoot = document.getElementById(APP_ROOT_EL_ID);
    this.svgRoot = document.getElementById('svg');
    if (this.appRoot) {
      this.appRoot.addEventListener('click', evt => {
        this.onClick(evt);
      });
    }
    if (this.svgRoot) {
      this.svgRoot.addEventListener('mousemove', this.onMouseMove);
    }
    // this.initializeFavorite().then(result => result);

    // console.log(this.props.symbols);
  }

  componentWillUnmount() {
    if (this.appRoot) {
      this.appRoot.removeEventListener('click', this.onClick);
    }
    if (this.svgRoot) {
      this.svgRoot.removeEventListener('mousemove', this.onMouseMove);
    }
  }

  /**
   * Clicking the canvas to create the symbol
   * @param {*} e
   */
  onClick = e => {
    const { clientX, clientY } = getClickPoint(e);
    const { selectedSymbolKey: symbolKey, selectSymbol, isMouseOverCanvas } = this.props;

    if (symbolKey && isMouseOverCanvas) {
      // check if created object should be attached to any street
      const { streetId, angle = 0 } = this.getSnapToStreetData({ clientX, clientY }) || {};
      const usedSymbol = this.props.symbols.find(symbol => symbol.key === this.props.selectedSymbolKey);
      if (usedSymbol) {
        this.increaseUseCount(usedSymbol);
      }
      emitter.emit(EVENT_EMIT_TYPE.SLIDERSYMBOLFUNC, {
        symbolKey,
        clientX: clientX,
        clientY: clientY,
        streetId,
        angle: 360 - angle,
      });

      // create object or cancel, each scenario we'll cancel the symbol selection
    }

    // no need to reset symbol selection if no symbol is selected
    if (symbolKey) {
      selectSymbol();
    }
  };

  /**
   * @description if on top of a street, rotate the avatar based on the direction of the street,
   * and the nature of the symbol: car will follow the street lane direction and crosswalk will perpendicular to it
   * @param {*} symbolKey string
   * @returns {0 | 1 | undefined} PERPENDICULAR=0, ALONG_WITH=1
   */
  getSnapToStreetStyle = symbolKey => {
    const vehicleSymbolKeys = this.props.symbols
      .filter(symbol => 'vehicles' === symbol.categoryKey)
      .map(symbol => symbol.key);
    if (symbolKey === 'RoadCrosswalk') {
      return SNAP_TO_STREET_STYLE.PERPENDICULAR;
    } else if (vehicleSymbolKeys.indexOf(symbolKey) > -1) {
      return SNAP_TO_STREET_STYLE.ALONG_WITH;
    } else {
      return undefined;
    }
  };

  /**
   *
   * @param {object} param0
   * @param {number} param0.clientX
   * @param {number} param0.clientY
   */
  getSnapToStreetData = ({ clientX, clientY }) => {
    const { selectedSymbolKey } = this.props;
    const snapStyle = this.getSnapToStreetStyle(selectedSymbolKey);
    if (snapStyle !== undefined) {
      const point = new GeomPoint(...clientPtToUserPt(clientX, clientY));
      return getSnapToStreetData(point, snapStyle);
    }
    return undefined;
  };

  onMouseMove = e => {
    const { selectedSymbolKey } = this.props;
    if (selectedSymbolKey) {
      const { clientX, clientY } = getClickPoint(e);
      const snapData = this.getSnapToStreetData({
        clientX,
        clientY,
      });
      if (snapData) {
        this.setState({
          avatarRotationAngle: snapData.angle,
        });
      }
    }
  };

  onSelectSymbol = (symbolKey, event) => {
    const { selectSymbol, expandable, expanded, openExpand } = this.props;

    // When symbol selected, symbol bar expand automatically.
    if (expandable && !expanded) openExpand();

    // When symbol selected, symbol will scroll into view.
    if (expandable) {
      if (event.target && event.target.parentElement instanceof Element) {
        event.preventDefault();
        event.stopPropagation();
        event.target.parentElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }
    }

    selectSymbol(symbolKey);

    // unselect drawingShape
    if (this.props.drawingShape) {
      this.props.setDrawingShape();
      this.props.setCurrentState(2);
      this.props.setCursor(CURSOR.DEFAULT);
    }

    this.setState({
      avatarRotationAngle: 0,
    });
  };

  onSize = size => {
    const baseHeight = parseFloat(StyleVariables.baseBarHeight);
    const { barSizeChanged } = this.props;
    const expandable = size.height > baseHeight;
    const overlay = size.height > 2 * baseHeight;
    barSizeChanged(expandable, overlay);
  };

  toggleFavorite = async symbol => {
    const { key } = symbol;
    const { favorites, t } = this.props;
    const row = favorites.find(n => n.key === key);
    if (row) {
      await db[FAVORITE_STORE].delete(row.key);
      message.success(t('menu.symbol.removeFavorite'));
    } else {
      await db[FAVORITE_STORE].add({
        ...symbol,
        favorite: true,
        useCount: 0,
        useTimestamps: [],
      });
      message.success(t('menu.symbol.addFavorite'));
    }
  };

  increaseUseCount = async symbol => {
    const { favorites } = this.props;
    const row = favorites.find(n => n.key === symbol.key);
    if (row) {
      const useTimestamps = addUseTimestamp(row.useTimestamps);
      await db[FAVORITE_STORE].put({
        ...row,
        useCount: ++row.useCount,
        useTimestamps,
        favorite: row.favorite || isFrequentlyUsed(useTimestamps),
      });
    } else {
      await db[FAVORITE_STORE].add({
        ...symbol,
        favorite: false,
        useCount: 1,
        useTimestamps: [new Date().getTime()],
      });
    }
  };

  render() {
    const { avatarRotationAngle } = this.state;

    const {
      expandable,
      expanded,
      toggleExpand,
      symbols,
      selectedSymbolKey,
      overlay,
      isMouseOverCanvas,
      mousePosition,
    } = this.props;
    const containerClass = classNames('symbol-bar', {
      'symbol-bar-expanded': expanded,
    });
    // symbol props respect src/assets/staticData definition
    const items = _.sortBy(symbols, ['importance']).map((symbol, i) => {
      const selected = symbol.key === selectedSymbolKey;
      return (
        <SymbolBarItem
          key={symbol.key}
          symbol={symbol}
          symbolSubCategory={symbol.subCategoryKey}
          shouldAdaptIcon={symbol.shouldAdaptIcon}
          // isHeightAdaptive={symbol.isHeightAdaptive}
          selected={selected}
          onClick={this.onSelectSymbol}
          favorite={this.props.favorites.some(
            item => item.categoryKey === symbol.categoryKey && item.key === symbol.key && item.favorite
          )}
          toggleFavorite={this.toggleFavorite}
        />
      );
    });

    let symbolAvatar = null;
    if (this.props.device === 'untouch') {
      if (selectedSymbolKey && isMouseOverCanvas) {
        const symbolImage = this.props.symbols.find(symbol => symbol.key === selectedSymbolKey).image;
        symbolAvatar = (
          <SymbolAvatar
            {...mousePosition}
            symbolKey={selectedSymbolKey}
            symbolImage={symbolImage}
            rotationAngle={avatarRotationAngle}
          />
        );
      }
    }

    return (
      <div className={containerClass}>
        <TouchScroll className="symbol-bar-inner" onSize={this.onSize}>
          {items}
        </TouchScroll>
        {(expandable || expanded) && <ExpandToggler expanded={expanded} onClick={toggleExpand} />}
        {expanded && overlay && <div className="overlay" />}
        {symbolAvatar}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  expandable: state.symbolBar.expandable,
  expanded: state.symbolBar.expanded,
  symbols: state.symbolBar.symbols,
  selectedSymbolKey: state.symbolBar.selectedSymbolKey,
  overlay: state.symbolBar.overlay,
  drawingShape: state.canvas.drawingShape,
  isMouseOverCanvas: state.pointer.isMouseOverCanvas,
  mousePosition: {
    clientX: state.pointer.clientX,
    clientY: state.pointer.clientY,
  },
  device: state.app.device,
  snapshots: state.snapshots,
});

const mapDispatchToProps = {
  toggleExpand,
  openExpand,
  selectSymbol,
  barSizeChanged,
  setDrawingShape,
  setCurrentState,
  setCursor,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SymbolBar));
