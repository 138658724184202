const Images = {

  // header menu bar
  Infull: require('./header/infull.svg'),
  IconDown: require('./header/icons_down.svg'),
  IconDownBlack: require('./header/icons_down-black.svg'),
  Menu: require('./header/menu.svg'),

  // map controls
  IconSketchMap: require('./props/icons_sketch_map.svg'),
  IconRemoveMap: require('./props/icons_remove_map.svg'),
  IconPlus: require('./props/icons_blue_plus.svg'),
  IconMinus: require('./props/icons_blue_minus.svg'),

  // prop menu
  IconFlipDown: require('./props/icons_grey_flip-down.svg'),
  IconFlipUp: require('./props/icons_grey_flip-up.svg'),
  IconFlipLeft: require('./props/icons_grey_flip-left.svg'),
  IconFlipRight: require('./props/icons_grey_flip-right.svg'),
  IconBold: require('./props/icons_grey_bold.svg'),
  IconItalic: require('./props/icons_grey_italic.svg'),
  IconUnderline: require('./props/icons_grey_underline.svg'),
  IconText: require('./props/icons_grey_text.svg'),
  IconCheckBoxRight: require('./props/icons_checkbox_right.svg'),

  // canvas
  IconProps: require('./canvas/cog-box.svg'),
  IconRotate: require('./canvas/rotate.svg'),
  IconPencil: require('./canvas/pencil.svg'),
};

export default Images;
