export const namespace = ns => {
  if (typeof ns === 'string') {
    ns.split('.').reduce((obj, part) => {
      if (!obj[part]) {
        obj[part] = {};
      }
      return obj[part];
    }, window);
  }
};

// TODO: Object.values()
