import * as React from 'react';

function SvgIconChalkboard(props) {
  return (
    <svg className="icon-chalkboard_svg__icon" viewBox="0 0 1280 1024" width="1em" height="1em" {...props}>
      <defs>
        <style />
      </defs>
      <path
        d="M192 128h896v704h128V80c0-44.12-35.88-80-80-80H144C99.88 0 64 35.88 64 80v752h128V128zm1056 768H960V768H576v128H32c-17.68 0-32 14.32-32 32v64c0 17.68 14.32 32 32 32h1216c17.68 0 32-14.32 32-32v-64c0-17.68-14.32-32-32-32z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconChalkboard;
