import * as React from 'react';

function SvgIconGrid(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.056 0h24v24h-24z" />
        <path
          d="M22.056 7V5h-3V2h-2v3h-4V2h-2v3h-4V2h-2v3h-3v2h3v4h-3v2h3v4h-3v2h3v3h2v-3h4v3h2v-3h4v3h2v-3h3v-2h-3v-4h3v-2h-3V7h3zm-15 0h4v4h-4V7zm0 10v-4h4v4h-4zm10 0h-4v-4h4v4zm0-6h-4V7h4v4z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconGrid;
