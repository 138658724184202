import * as React from 'react';

function SvgIconPathText(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M15.667 6.5H8.333a.917.917 0 00-.916.917V9.25H9.25v-.917h1.833v7.334H9.25V17.5h5.5v-1.833h-1.833V8.333h1.833v.917h1.833V7.417a.917.917 0 00-.916-.917z" />
          <path d="M22.083 6.5c.506 0 .917-.41.917-.917V1.917A.917.917 0 0022.083 1h-3.666a.917.917 0 00-.917.917v.916h-11v-.916A.917.917 0 005.583 1H1.917A.917.917 0 001 1.917v3.666c0 .506.41.917.917.917h.916v11h-.916a.917.917 0 00-.917.917v3.666c0 .506.41.917.917.917h3.666c.506 0 .917-.41.917-.917v-.916h11v.916c0 .506.41.917.917.917h3.666c.506 0 .917-.41.917-.917v-3.666a.917.917 0 00-.917-.917h-.916v-11h.916zm-2.75-3.667h1.834v1.834h-1.834V2.833zm-16.5 0h1.834v1.834H2.833V2.833zm1.834 18.334H2.833v-1.834h1.834v1.834zm16.5 0h-1.834v-1.834h1.834v1.834zM19.333 17.5h-.916a.917.917 0 00-.917.917v.916h-11v-.916a.917.917 0 00-.917-.917h-.916v-11h.916c.506 0 .917-.41.917-.917v-.916h11v.916c0 .506.41.917.917.917h.916v11z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconPathText;
