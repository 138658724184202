import GeomPoint from '@src/data/GeomPoint';
import GeomLine from '@src/data/GeomLine';
import GeomArc from '@src/data/GeomArc';
import GeomQ from '@src/data/GeomQ';
import GeomAlign from '@src/data/GeomAlign';
import Utility from './Utility';
import UtilityGeom from './UtilityGeom';
import GeomPathOffset from './GeomPathOffset';

/**
 * Multiple arcs
 */
class GeomPolyArc {
  /**
   * @type {(GeomLine[]|GeomArc[])}
   */
  segments;

  /**
   *
   * @param {GeomLine[]|GeomArc[]} [segments]
   */
  constructor(segments = []) {
    this.segments = [];
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].type == 'line') {
        var gl = new GeomLine(
          new GeomPoint(segments[i].ptStart.x, segments[i].ptStart.y),
          new GeomPoint(segments[i].ptStop.x, segments[i].ptStop.y)
        );
        this.segments.push(gl);
      } else if (segments[i].type == 'arc') {
        var ga = new GeomArc(
          new GeomPoint(segments[i].ptStart.x, segments[i].ptStart.y),
          new GeomPoint(segments[i].ptStop.x, segments[i].ptStop.y),
          segments[i].r,
          segments[i].largeArcFlag,
          segments[i].sweepFlag
        );
        this.segments.push(ga);
      } else if (segments[i].type == 'q') {
        var gq = new GeomQ(
          new GeomPoint(segments[i].ptStart.x, segments[i].ptStart.y),
          new GeomPoint(segments[i].ptStop.x, segments[i].ptStop.y),
          new GeomPoint(segments[i].pt.x, segments[i].pt.y)
        );
        this.segments.push(gq);
      }
    }
  }

  clone() {
    var ss = [];
    for (let i = 0; i < this.segments.length; i++) {
      if (this.segments[i].declaredClass == 'GeomLine') {
        ss.push({
          type: 'line',
          ptStart: {
            x: this.segments[i].ptStart.x,
            y: this.segments[i].ptStart.y,
          },
          ptStop: {
            x: this.segments[i].ptStop.x,
            y: this.segments[i].ptStop.y,
          },
        });
      } else if (this.segments[i].declaredClass == 'GeomArc') {
        ss.push({
          type: 'arc',
          ptStart: {
            x: this.segments[i].ptStart.x,
            y: this.segments[i].ptStart.y,
          },
          ptStop: {
            x: this.segments[i].ptStop.x,
            y: this.segments[i].ptStop.y,
          },
          r: this.segments[i].r,
          largeArcFlag: this.segments[i].largeArcFlag,
          sweepFlag: this.segments[i].sweepFlag,
        });
      }
    }
    return new GeomPolyArc(ss);
  }

  /**
   * 复制一部分形状
   * @param {number} x
   * @param {number} y
   */
  copyPartialShape(x, y) {
    var length = this.getLength();
    if (x < y) {
      return this.copyTrimmedShape(x, length - y);
    } else if (x > y) {
      return this.copyTrimmedShape(y, length - x);
    }
  }

  /**
   * 复制修剪后的形状
   * @param {*} a6dd
   * @param {*} a6de
   */
  copyTrimmedShape(a6dd, a6de) {
    var length = this.getLength(),
      seg,
      nSeg,
      a6e2;
    if (a6dd < 0) {
      a6dd = 0;
    }
    if (a6de < 0) {
      a6de = 0;
    }
    if (a6dd + a6de > length) {
      return undefined;
    }
    var ss = [];
    Array.prototype.push.apply(ss, this.segments);
    if (!Utility.isNonEmptyArray(ss)) {
      return undefined;
    }
    for (nSeg = 0; nSeg < ss.length && a6dd > 0; nSeg++) {
      seg = ss[nSeg];
      a6e2 = seg.getLength();
      if (a6dd < a6e2) {
        seg.trim(a6dd, 0);
        a6dd = 0;
      } else {
        ss[nSeg] = undefined;
        a6dd -= a6e2;
      }
    }
    for (nSeg = ss.length - 1; nSeg >= 0 && a6de > 0; nSeg--) {
      seg = ss[nSeg];
      if (seg) {
        a6e2 = seg.getLength();
        if (a6de < a6e2) {
          seg.trim(0, a6de);
          a6de = 0;
        } else {
          ss[nSeg] = undefined;
          a6de -= a6e2;
        }
      }
    }
    var sp = undefined;
    var rss = [];
    ss.forEach(function(seg) {
      var a6e3;
      if (seg) {
        sp = seg.getPointStart();
        a6e3 = seg.getPointStop();
        if ('r' in seg) {
          rss.push(
            new GeomArc(
              new GeomPoint(sp.x, sp.y),
              new GeomPoint(a6e3.x, a6e3.y),
              seg.r,
              seg.largeArcFlag,
              seg.sweepFlag
            )
          );
        } else {
          rss.push(new GeomLine(new GeomPoint(sp.x, sp.y), new GeomPoint(a6e3.x, a6e3.y)));
        }
      }
    }, this);
    return rss;
  }

  /**
   *
   * @param {number} offsetX
   * @param {number} offsetY
   * @returns {GeomAlign}
   */
  getAlignmentAtLength(offsetX, offsetY) {
    let count = 0,
      align = undefined;
    for (let i = 0, il = this.segments.length; i < il - 1; i++) {
      let s = this.segments[i];
      if (offsetX - count <= s.getLength()) {
        align = s.getAlignmentAtLength(offsetX - count, offsetY);
        break;
      }
      count += s.getLength();
    }
    if (!align && this.segments.length >= 1) {
      let s = this.segments[this.segments.length - 1];
      align = s.getAlignmentAtLength(offsetX - count, offsetY);
    }
    return align;
  }

  getCircleCenter() {
    if (this.segments.length === 1 && this.segments[0].declaredClass === 'GeomArc') {
      return this.segments[0].getCircleCenter();
    }
    return undefined;
  }

  /**
   * 获取最接近点的 segment 的索引
   * @param {GeomLine[]|GeomArc[]} segments
   * @param {GeomPoint} pt
   * @returns {number}
   */
  getIndexOfSegmentClosestToPt(segments, pt) {
    var index = 0;
    var min = Number.POSITIVE_INFINITY;
    if (Utility.isNonEmptyArray(segments)) {
      if (1 === segments.length) {
        return 0;
      }
      for (let i = 0, il = segments.length; i < il; i++) {
        var aae3 = pt.distanceSquared(segments[i].getPointClosest(pt));
        if (aae3 < min) {
          min = aae3;
          index = i;
        }
      }
    }
    return index;
  }

  /**
   * 获取相交点
   * @param {GeomPolyArc} gpa
   * @returns {{ pt: GeomPoint, offset0: number, offset1: number }[]}
   */
  getIntersection(gpa) {
    var pts = [],
      isects = [];
    for (let i = 0, il = this.segments.length; i < il; i++) {
      for (let j = 0, jl = gpa.segments.length; j < jl; j++) {
        const utilityGeom = new UtilityGeom();
        const intersectPoints = utilityGeom.getIntersectPoints(this.segments[i], gpa.segments[j]);
        // console.log('intersectPoints length', intersectPoints.length);
        intersectPoints.forEach(function(pt) {
          if (Utility.isValid(pt)) {
            pts.push(pt);
          }
        });
      }
    }
    for (let i = 0, il = pts.length; i < il; i++) {
      isects.push({
        pt: pts[i],
        offset0: this.getOffsetsToPoint(pts[i]).fromStart,
        offset1: gpa.getOffsetsToPoint(pts[i]).fromStart,
      });
    }
    return isects;
  }

  /**
   * 获取segments的总长度
   * @return {number}
   */
  getLength() {
    let length = 0;
    for (let i = 0, il = this.segments.length; i < il; i++) {
      // length = length + this.segments[i].getLength();
      let segmentLen = this.segments[i].getLength();
      if (!isNaN(segmentLen)) {
        length = length + segmentLen;
      }
    }
    return length;
  }

  getMidPoint() {
    return this.getPointAtLength(this.getLength() / 2);
  }

  /**
   *
   * @param {GeomPoint} pt
   * @returns {GeomPathOffset}
   */
  getOffsetsToPoint(pt) {
    var gpo;
    if (Utility.isNonEmptyArray(this.segments)) {
      if (1 === this.segments.length) {
        return this.segments[0].getOffsetsToPoint(pt);
      }
      var index = this.getIndexOfSegmentClosestToPt(this.segments, pt);
      gpo = this.segments[index].getOffsetsToPoint(pt);
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          gpo.fromStart += this.segments[i].getLength();
        }
      }
      gpo.fromStartPercent = gpo.fromStart / this.getLength();
    }
    return gpo;
  }

  /**
   * 获取一部分 path 数据
   * @param {number} startLen - 起始长度
   * @param {number} toLen - 结尾长度
   * @returns {string} path
   */
  getPartialSvgPathData(startLen, toLen) {
    var path = '';
    if (1 === this.segments.length) {
      path = this.segments[0].getPartialSvgPathData(startLen, toLen, true);
    } else {
      for (let i = 0, il = this.segments.length; i < il; i++) {
        var length = this.segments[i].getLength();
        if (startLen < length) {
          var spd = this.segments[i].getPartialSvgPathData(startLen, toLen > length ? -1 : toLen, '' === path);
          if (spd.length > 0) {
            if (0 === path.length) {
              path = spd;
            } else {
              path += ' ' + spd;
            }
          }
        }
        if (toLen > 0) {
          toLen -= length;
          if (toLen <= 0) {
            break;
          }
        }
        startLen = Math.max(0, startLen - length);
      }
    }
    return path;
  }

  /**
   *
   * @param {number} length
   * @returns {GeomPoint}
   */
  getPointAtLength(length) {
    var pt;
    for (let i = 0; i < this.segments.length - 1 && !pt; i++) {
      var ll = this.segments[i].getLength();
      if (length <= ll) {
        pt = this.segments[i].getPointAtLength(length);
        return pt;
      } else {
        length -= ll;
      }
    }
    if (!pt) {
      pt = this.segments[this.segments.length - 1].getPointAtLength(length);
    }
    return pt;
  }

  /**
   *
   * @param {GeomPoint} pt
   * @returns {GeomPoint | null}
   */
  getPointClosest(pt) {
    if (Utility.isNonEmptyArray(this.segments)) {
      var i = this.getIndexOfSegmentClosestToPt(this.segments, pt);
      return this.segments[i].getPointClosest(pt);
    }
    return null;
  }

  /**
   * get the start point
   * @returns {GeomPoint}
   */
  getPointStart() {
    return this.segments[0].getPointStart();
  }

  /**
   * get the stop point
   * @returns {GeomPoint}
   */
  getPointStop() {
    return this.segments[this.segments.length - 1].getPointStop();
  }

  /**
   * 获取 segment 相交点
   * @param {GeomLine} seg
   * @returns {{pt: GeomPoint, offset0: number, offset1: number}[]}
   */
  getSegmentIntersection(seg) {
    var result = [];
    for (var i = 0; i < this.segments.length; i++) {
      var intersectPoints = new UtilityGeom().getIntersectPoints(this.segments[i], seg);
      intersectPoints.forEach(pt => {
        result.push({
          pt: pt,
          offset0: this.getOffsetsToPoint(pt).fromStart,
          offset1: seg.getOffsetsToPoint(pt).fromStart,
        });
      });
    }
    return result;
  }

  /**
   * 获取 SVG 路径数据
   * @returns {string}
   */
  getSvgPathData() {
    let path = '';
    for (let i = 0; i < this.segments.length; i++) {
      const segmentPath = this.segments[i].getSegmentPathData(0 === i);
      if (segmentPath.length > 0) {
        if (0 === path.length) {
          path = segmentPath;
        } else {
          path += ' ' + segmentPath;
        }
      }
    }
    return path;
  }

  isStraight() {
    return this.segments[0].declaredClass === 'GeomLine';
  }

  /**
   * 偏移
   * @param {number} cx
   * @param {number} cy
   */
  offset(cx, cy) {
    this.segments.forEach(function(seg) {
      if (seg.declaredClass === 'line') {
        seg.offset(cx, cy);
      }
    });
  }

  /**
   * 沿法线偏移
   * @param {number} distance
   * @param {boolean} isClosed - 首位闭合
   */
  offsetNormal(distance, isClosed = false) {
    let aaeb, // GeomPoint
      aaec = [], // GeomPoint[]
      end = [], // GeomArc[] - 最后一段 stripe，对 roundabout 来说是最内侧的圆
      result = [], // GeomArc[]
      ptStart,
      ptStop,
      ptMid,
      sign;

    /**
     *
     * @param {GeomArc | GeomLine} arc1
     * @param {GeomArc | GeomLine} arc2
     * @param {GeomArc | GeomLine} arc3
     * @param {number} distance
     * @returns {GeomArc}
     */
    function getGeomArc(arc1, arc2, arc3, distance) {
      return new GeomArc(
        arc1.getPointStop(),
        arc2.getPointStart(),
        arc1.getPointStop().distance(arc3.getPointStop()),
        false,
        distance < 0
      );
    }

    if (distance !== 0) {
      for (let i = 0, il = this.segments.length; i < il; i++) {
        if (i > 0 && this.segments[i].declaredClass === 'GeomArc') {
          if (Math.abs(distance) > this.segments[i].r) {
            if ((this.segments[i].sweepFlag && distance > 0) || (!this.segments[i].sweepFlag && distance < 0)) {
              continue;
            }
          }
        }
        const segment = this.segments[i].clone();
        segment._sourceIdx = i;
        switch (this.segments[i].declaredClass) {
          case 'GeomLine':
            segment.offsetNormal(distance);
            break;
          case 'GeomArc':
            sign = 1;
            if (this.segments[i].sweepFlag) {
              sign = -1;
            }
            segment.inflate(sign * distance);
            break;
          default:
            break;
        }
        end.push(segment);
      }

      // TODO: need optimize
      let len = isClosed ? end.length : end.length - 1;
      for (let i = 0; i < len; i++) {
        const j = i === end.length - 1 ? 0 : i + 1;
        if (end[i].declaredClass === 'GeomLine' && end[j].declaredClass === 'GeomLine') {
          aaeb = end[i].getPointIntersect(end[j]);
          if (aaeb) {
            end[i].setStop(aaeb);
            end[j].setStart(aaeb);
            result.push(end[i]);
          } else {
            result.push(end[i]);
            ptStop = end[i].getPointStop();
            ptStart = end[j].getPointStart();
            if (ptStart.distance(ptStop) < 0.1) {
              ptMid = ptStart.midPoint(ptStop);
              end[i].setStop(ptMid);
              end[j].setStart(ptMid);
            } else {
              result.push(getGeomArc(end[i], end[j], this.segments[i], distance));
            }
          }
        } else if (end[i].declaredClass === 'GeomArc' && end[j].declaredClass === 'GeomLine') {
          aaec = new UtilityGeom().getPointsArcIntersectingLine(end[i], end[j], 0).filter(function(p) {
            return end[i].getLength() - end[i].getArcLengthToPoint(p) < Math.abs(distance) * 3;
          });
          if (aaec.length > 0) {
            if (aaec.length == 2) {
              aaeb = aaec[0].distance(end[i].ptStop) < aaec[1].distance(end[i].ptStop) ? aaec[0] : aaec[1];
            } else {
              aaeb = aaec[0];
            }
            end[i].trim(0, end[i].getLength() - end[i].getArcLengthToPoint(aaeb));
            end[j].setStart(aaeb);
            result.push(end[i]);
          } else {
            result.push(end[i]);
            ptStop = end[i].getPointStop();
            ptStart = end[j].getPointStart();
            if (ptStart.distance(ptStop) < 0.1) {
              ptMid = ptStart.midPoint(ptStop);
              end[i].setStop(ptMid);
              end[j].setStart(ptMid);
            } else {
              result.push(getGeomArc(end[i], end[j], this.segments[i], distance));
            }
          }
        } else if (end[i].declaredClass === 'GeomLine' && end[j].declaredClass === 'GeomArc') {
          aaec = new UtilityGeom().getPointsArcIntersectingLine(end[j], end[i], 0).filter(function(p) {
            return end[j].getArcLengthToPoint(p) < Math.abs(distance) * 3;
          });
          if (aaec.length > 0) {
            if (aaec.length == 2) {
              aaeb = aaec[0].distance(end[j].ptStart) < aaec[1].distance(end[j].ptStart) ? aaec[0] : aaec[1];
            } else {
              aaeb = aaec[0];
            }
            end[i].setStop(aaeb);
            end[j].trim(end[j].getArcLengthToPoint(aaeb), 0);
            result.push(end[i]);
          } else {
            result.push(end[i]);
            ptStop = end[i].getPointStop();
            ptStart = end[j].getPointStart();
            if (ptStart.distance(ptStop) < 0.1) {
              ptMid = ptStart.midPoint(ptStop);
              end[i].setStop(ptMid);
              end[j].setStart(ptMid);
            } else {
              result.push(getGeomArc(end[i], end[j], this.segments[i], distance));
            }
          }
        } else if (end[i].declaredClass === 'GeomArc' && end[j].declaredClass === 'GeomArc') {
          aaec = new UtilityGeom().getPointsArcIntersectingArc(end[i], end[j]).filter(function(p) {
            return end[j].getArcLengthToPoint(p) < Math.abs(distance) * 2;
          });
          if (aaec.length > 0) {
            if (aaec.length == 2) {
              aaeb = aaec[0].distance(end[j].ptStart) < aaec[1].distance(end[j].ptStart) ? aaec[0] : aaec[1];
            } else {
              aaeb = aaec[0];
            }
            end[j].trim(end[j].getArcLengthToPoint(aaeb), 0);
            end[i].trim(0, end[i].getLength() - end[i].getArcLengthToPoint(aaeb));
            result.push(end[i]);
          } else {
            result.push(end[i]);
            ptStop = end[i].getPointStop();
            ptStart = end[j].getPointStart();
            if (ptStart.distance(ptStop) < 0.1) {
              ptMid = ptStart.midPoint(ptStop);
              end[i].setStop(ptMid);
              end[j].setStart(ptMid);
            } else {
              result.push(getGeomArc(end[i], end[j], this.segments[i], distance));
            }
          }
        }
      }

      if (!isClosed) result.push(end[end.length - 1]);
      this.segments = result;
    }
  }

  /**
   * 获取这条线的法线上的点
   * @param {GeomPoint} pt
   * @param {number} offset 距离该线的距离
   * @returns {GeomPoint}
   */
  pointOffsetNormal(pt, offset) {
    let result;
    if (Utility.isNonEmptyArray(this.segments)) {
      if (1 === this.segments.length) {
        return this.segments[0].pointOffsetNormal(pt, offset);
      }
      let index = this.getIndexOfSegmentClosestToPt(this.segments, pt);
      result = this.segments[index].pointOffsetNormal(pt, offset);
    }
    return result;
  }

  startPoint() {
    return this.arrSegments[0].startPoint();
  }

  stopPoint() {
    return this.arrSegments[this.arrSegments.length - 1].stopPoint();
  }

  reverse() {
    if (!Utility.isNonEmptyArray(this.segments)) {
      return;
    }
    var ss = [];
    for (let i = 0; i < this.segments.length; i++) {
      this.segments[i].reverse();
      ss.unshift(this.segments[i]);
    }
    this.segments = ss;
  }
}

export default GeomPolyArc;
