import { ANCHORS_TYPE } from '@src/constant';
import { SquareRectHandlePoint, CircleHandlePoint } from '@src/data/AnchorsPoint';
import { FindCircle, IsArcDirection, IsBigArc } from '@src/data/CommonFunc';
import React from 'react';

class SelectStairs extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props } = this;
    const { anchors } = props;
    if (anchors.length < 9) return null;
    const cirlcle = FindCircle(anchors[0], anchors[3], anchors[6]);
    const r = cirlcle[2];
    let getDPath = () => {
      var D = [];
      D.push('M' + anchors[0][0].toString() + ',' + anchors[0][1].toString());
      D.push('A' + r.toString() + ',' + r.toString());
      D.push('0');
      D.push(
        IsBigArc(anchors[0], anchors[6], anchors[3], r) + ',' + IsArcDirection(anchors[0], anchors[3], anchors[6])
      );
      D.push(anchors[6][0].toString() + ',' + anchors[6][1].toString());

      return D;
    };
    var pathshape = (
      <g>
        <path d={getDPath().join(' ')} />
        <SquareRectHandlePoint id={ANCHORS_TYPE.STAIRSBEGIN} key={ANCHORS_TYPE.STAIRSBEGIN} point={anchors[0]} />
        <SquareRectHandlePoint id={ANCHORS_TYPE.STAIRSEND} key={ANCHORS_TYPE.STAIRSEND} point={anchors[6]} />
        <CircleHandlePoint id={ANCHORS_TYPE.STAIRSMIDDLE} key={ANCHORS_TYPE.STAIRSMIDDLE} point={anchors[3]} />
      </g>
    );
    return <g>{pathshape}</g>;
  }
}

export default SelectStairs;
