import * as React from 'react';

function SvgIconPaintBucket(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M6.195 1.634l-5.5 9.526a2.98 2.98 0 00-.299 2.276 2.977 2.977 0 001.398 1.821l5.196 3a3.003 3.003 0 004.098-1.098l3.349-5.8 4.636-2.292L6.195 1.634zm6.812 8.202L9.355 16.16c-.265.46-.899.635-1.366.366l-5.196-3a.994.994 0 01-.465-.607.993.993 0 01.1-.759l4.5-7.793 7.908 4.564-1.829.905zM19.454 11.121c-.754 1.202-3.546 5.463-3.546 7.333A3.55 3.55 0 0019.454 22 3.55 3.55 0 0023 18.454c0-1.864-2.771-6.098-3.546-7.333zm0 8.879a1.547 1.547 0 01-1.546-1.546c0-.503.704-2.002 1.546-3.498.842 1.496 1.546 2.995 1.546 3.498 0 .853-.693 1.546-1.546 1.546z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconPaintBucket;
