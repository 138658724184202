import React, { Component } from 'react';
import {
  ByTime,
  FindCircle,
  FindCircleLinePoint,
  getAngleBy2Points,
  GetArcCenter,
  GetCenter,
  IsArcDirection,
  IsBigArc,
  IsZero,
  LengthArc1,
  GetCirclePoint,
  LengthBetweenPoints,
  RotatePoint,
  TowPointVerticalAcross,
  FindCircleByR,
  GetCenterInTwoPoints,
  GetCircleLineAcrossPoint,
  TowCircleAcrossPoints,
  IsArcRangePoint,
  GetLineKB,
  GetTowPointRange,
  IsPointInRange,
  IsUnderLine,
} from '@src/data/CommonFunc';
import { GetStreetArcTopPoint, getStreetLaneHeight, getStreetLanePoint, GetOffsetLinePoints } from './StreetFunHandle';
import {
  GetPathArcD,
  GetPathArcD1,
  TowLineAcrossPoint,
  GetLineHeightPoints,
  GetPathArcDL,
  IsRangePoint,
  GetPathBesselS,
  GetPathLineD,
  GetPathLineD1,
  GetPathTowBessel,
  GetPathTowBessel1,
  GetPathBesselS1,
} from '@src/data/BusinessFun';
import { THREE_POINT_STATUS_TYPE, CIRCLE_SIZE_TYPE, STREET_SPACE } from '@src/constant';

export function GetOffArcR(offarcHeight) {
  let offArcR = Math.sqrt(3) * offarcHeight;
  return offArcR;
}

export function GetOffarcPoints(anchors, R) {
  let offLength = (Math.sqrt(3) * R) / 2;
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let point = [],
    pointRight = [];
  if (IsZero(circle[2])) {
    let lengthMoveArc5 = LengthBetweenPoints(anchors[0], anchors[5]);
    let scale = offLength / lengthMoveArc5;
    point = GetCenter(anchors[5], anchors[0], scale);
    let lengthMove52 = LengthBetweenPoints(anchors[5], anchors[2]);
    let scale52 = offLength / lengthMove52;
    pointRight = GetCenter(anchors[5], anchors[2], scale52);
  } else {
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let lengthMoveArc5 = LengthArc1(anchors[0], anchors[5], circle, isBytime);
    let scale = offLength / lengthMoveArc5;
    point = GetArcCenter(circle, anchors[5], anchors[0], scale, !isBytime);
    let lengthMove52 = LengthArc1(anchors[5], anchors[2], circle, isBytime);
    let scale52 = offLength / lengthMove52;
    pointRight = GetArcCenter(circle, anchors[5], anchors[2], scale52, isBytime);
  }
  return [point, pointRight];
}

//求取道路线上得三个点
export function getOffArcPoint(lanePoints, offArcHeight, isTopLane) {
  let circle = FindCircle(lanePoints[0], lanePoints[1], lanePoints[2]);
  var laneBegin = [],
    laneCenter = [],
    laneEnd = [];
  //中线下面得道路高度相反
  if (isTopLane) offArcHeight = -offArcHeight;
  if (IsZero(circle[2])) {
    let angle = getAngleBy2Points(lanePoints[0], lanePoints[2]);
    let length = LengthBetweenPoints(lanePoints[0], lanePoints[2]);
    laneBegin = [lanePoints[0][0], lanePoints[1][1] + offArcHeight];
    laneCenter = [lanePoints[0][0] + length / 2, lanePoints[0][1] + offArcHeight];
    laneEnd = [lanePoints[0][0] + length, lanePoints[0][1] + offArcHeight];
    laneBegin = RotatePoint(laneBegin, angle, lanePoints[0]);
    laneCenter = RotatePoint(laneCenter, angle, lanePoints[0]);
    laneEnd = RotatePoint(laneEnd, angle, lanePoints[0]);
  } else {
    let isBytime = ByTime(lanePoints[0], lanePoints[1], lanePoints[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let arcPoints = GetStreetArcTopPoint(circle, -offArcHeight, lanePoints[0], lanePoints[1], lanePoints[2], isBytime);
    laneBegin = arcPoints[0];
    laneCenter = arcPoints[1];
    laneEnd = arcPoints[2];
  }
  return [laneBegin, laneCenter, laneEnd];
}

//得到过中心的点
export function GetOffArcCenterLinePoints(D, anchors, offArcHeight, offArcCenter, maxR) {
  if (maxR == null) maxR = 0;
  let bottomPoints = getStreetLanePoint(anchors, 0, offArcHeight, false);
  let topPoints = getStreetLanePoint(anchors, 0, offArcHeight, true);
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let bottomR = 0,
    topR = 0;
  let offArcCenterPoint = [],
    offArcBeginPoint = [],
    offArcEndPoint = [];
  if (IsZero(circle[2])) {
    offArcCenterPoint = GetCenter(anchors[0], anchors[2], offArcCenter);
  } else {
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    offArcCenterPoint = GetArcCenter(circle, anchors[0], anchors[2], offArcCenter, isBytime);
  }

  let arcPoints = GetOffarcBEPoints(anchors, offArcCenterPoint, offArcHeight);
  if (IsZero(circle[2])) {
    offArcBeginPoint = TowPointVerticalAcross(bottomPoints[0], bottomPoints[2], arcPoints[0]);
    offArcEndPoint = TowPointVerticalAcross(topPoints[0], topPoints[2], arcPoints[1]);
  } else {
    bottomR = LengthBetweenPoints(circle, bottomPoints[0]);
    topR = LengthBetweenPoints(circle, topPoints[0]);
    offArcBeginPoint = FindCircleLinePoint([circle[0], circle[1], bottomR], arcPoints[0]);
    offArcEndPoint = FindCircleLinePoint([circle[0], circle[1], topR], arcPoints[1]);
  }
  let isDirction = IsArcDirection(anchors[0], anchors[1], anchors[2]);
  maxR = 0; //LengthBetweenPoints(offArcBeginPoint,offArcEndPoint);
  offArcCenterPoint = GetCenterInTwoPoints(offArcBeginPoint, offArcEndPoint);
  GetPathArcD(D, bottomPoints[0], offArcBeginPoint, bottomR, CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
  GetPathArcD1(D, offArcCenterPoint, maxR, CIRCLE_SIZE_TYPE.SMALLARC, '0');
  GetPathArcD1(D, offArcEndPoint, maxR, CIRCLE_SIZE_TYPE.SMALLARC, '1');
  GetPathArcD1(D, topPoints[2], topR, CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
}
//得到过中心的点
export function offSetArcAcrossPoint(points1, arcPoints, isByTime) {
  let circle = FindCircle(points1[0], points1[1], points1[2]);
  let arcR = LengthBetweenPoints(arcPoints[0], arcPoints[2]) / 2;
  let leftArcCircle = FindCircleByR(arcPoints[0], arcPoints[1], arcR, isByTime);
  let rightArcCircle = FindCircleByR(arcPoints[1], arcPoints[2], arcR, !isByTime);
  let acrossLeftPoints = [],
    acrossRightPoints = [];
  if (IsZero(circle[2])) {
    let lineKB2 = GetLineKB(points1[0], points1[2]);
    acrossLeftPoints = GetCircleLineAcrossPoint(leftArcCircle, lineKB2);
    acrossRightPoints = GetCircleLineAcrossPoint(rightArcCircle, lineKB2);
  } else {
    acrossLeftPoints = TowCircleAcrossPoints(circle, leftArcCircle);
    acrossRightPoints = TowCircleAcrossPoints(circle, rightArcCircle);
  }
  if (IsArcRangePoint(arcPoints[0], arcPoints[1], leftArcCircle, isByTime, acrossLeftPoints[0]))
    return acrossLeftPoints[0];
  else if (IsArcRangePoint(arcPoints[1], arcPoints[2], leftArcCircle, !isByTime, acrossLeftPoints[1]))
    return acrossLeftPoints[1];
  else if (IsArcRangePoint(arcPoints[0], arcPoints[1], leftArcCircle, isByTime, acrossRightPoints[0]))
    return acrossRightPoints[0];
  else if (IsArcRangePoint(arcPoints[1], arcPoints[2], leftArcCircle, !isByTime, acrossRightPoints[1]))
    return acrossRightPoints[1];
}
export function GetOffArcLinePath(D, offArcHeight, anchors, arcPoints, R) {
  let offSetArcPoints = GetOffsetLinePoints(arcPoints, anchors, offArcHeight);
  let leftCircle = FindCircle(offSetArcPoints[0], offSetArcPoints[1], offSetArcPoints[2]);
  let rightCircle = FindCircle(offSetArcPoints[6], offSetArcPoints[7], offSetArcPoints[8]);
  let isDirction = IsArcDirection(anchors[0], anchors[1], anchors[2]);
  GetPathArcD(D, offSetArcPoints[0], offSetArcPoints[2], leftCircle[2], CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
  GetPathBesselS1(D, offSetArcPoints[3], offSetArcPoints[4], offSetArcPoints[5], offSetArcPoints[6]);
  GetPathArcD1(D, offSetArcPoints[8], rightCircle[2], CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
}
export function GetOffArcAreaPath(D, topLinePoints, bottomLinePoints, arcPoints, offArcHeight) {
  let bottomTopLinePoints = GetLineHeightPoints(
    topLinePoints[0],
    topLinePoints[1],
    topLinePoints[2],
    offArcHeight,
    false
  );
  let topTopLinePoints = GetLineHeightPoints(topLinePoints[0], topLinePoints[1], topLinePoints[2], offArcHeight, true);

  let bottomBottomLinePoints = GetLineHeightPoints(
    bottomLinePoints[0],
    bottomLinePoints[1],
    bottomLinePoints[2],
    offArcHeight,
    false
  );
  let topBottomLinePoints = GetLineHeightPoints(
    bottomLinePoints[0],
    bottomLinePoints[1],
    bottomLinePoints[2],
    offArcHeight,
    true
  );

  let circle = FindCircle(topLinePoints[0], topLinePoints[1], topLinePoints[2]);
  let bottomTopLineR = 0,
    topTopLineR = 0;
  let bottomBottomLineR = 0,
    topBottomLineR = 0;

  let topLineHeight = LengthBetweenPoints(topLinePoints[0], bottomLinePoints[0]) / 2;
  let topLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], topLineHeight, true);
  let bottomLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], topLineHeight, false);
  let btLeftPoint = [],
    btRightPoint = [],
    tpLeftPoint = [],
    tpRightPoint = [];
  if (IsZero(circle[2])) {
    btLeftPoint = TowLineAcrossPoint(
      [bottomBottomLinePoints[0], bottomBottomLinePoints[2]],
      [bottomLineArcPoints[0], bottomLineArcPoints[2]]
    );
    btRightPoint = TowLineAcrossPoint(
      [topBottomLinePoints[0], topBottomLinePoints[[2]]],
      [bottomLineArcPoints[0], bottomLineArcPoints[2]]
    );
    tpLeftPoint = TowLineAcrossPoint(
      [bottomTopLinePoints[0], bottomTopLinePoints[2]],
      [topLineArcPoints[0], topLineArcPoints[2]]
    );
    tpRightPoint = TowLineAcrossPoint(
      [topTopLinePoints[0], topTopLinePoints[[2]]],
      [topLineArcPoints[0], topLineArcPoints[2]]
    );
  } else {
    bottomTopLineR = LengthBetweenPoints(circle, bottomTopLinePoints[0]);
    topTopLineR = LengthBetweenPoints(circle, topTopLinePoints[0]);
    bottomBottomLineR = LengthBetweenPoints(circle, bottomBottomLinePoints[0]);
    topBottomLineR = LengthBetweenPoints(circle, topBottomLinePoints[0]);
    let bbLineKB = GetLineKB(bottomLineArcPoints[0], bottomLineArcPoints[2]);
    let tpLineKB = GetLineKB(topLineArcPoints[0], topLineArcPoints[2]);
    let btLeftPointTemp = GetCircleLineAcrossPoint([circle[0], circle[1], bottomBottomLineR], bbLineKB);
    if (
      LengthBetweenPoints(btLeftPointTemp[0], bottomLineArcPoints[0]) >
      LengthBetweenPoints(btLeftPointTemp[1], bottomLineArcPoints[0])
    ) {
      btLeftPoint = btLeftPointTemp[1];
    } else btLeftPoint = btLeftPointTemp[0];
    let btRightPointTemp = GetCircleLineAcrossPoint([circle[0], circle[1], topBottomLineR], bbLineKB);
    if (
      LengthBetweenPoints(btRightPointTemp[0], bottomLineArcPoints[2]) >
      LengthBetweenPoints(btRightPointTemp[1], bottomLineArcPoints[2])
    )
      btRightPoint = btRightPointTemp[1];
    else btRightPoint = btRightPointTemp[0];
    let tpLeftPointTemp = GetCircleLineAcrossPoint([circle[0], circle[1], bottomTopLineR], tpLineKB);
    if (
      LengthBetweenPoints(tpLeftPointTemp[0], topLineArcPoints[0]) >
      LengthBetweenPoints(tpLeftPointTemp[1], topLineArcPoints[0])
    )
      tpLeftPoint = tpLeftPointTemp[1];
    else tpLeftPoint = tpLeftPointTemp[0];
    let topRightPointTemp = GetCircleLineAcrossPoint([circle[0], circle[1], topTopLineR], tpLineKB);
    if (
      LengthBetweenPoints(topRightPointTemp[0], topLineArcPoints[2]) >
      LengthBetweenPoints(topRightPointTemp[1], topLineArcPoints[2])
    )
      tpRightPoint = topRightPointTemp[1];
    else tpRightPoint = topRightPointTemp[0];
  }

  let bottomArcCenter = GetCenterInTwoPoints(btLeftPoint, btRightPoint);
  let topArcCenter = GetCenterInTwoPoints(tpLeftPoint, tpRightPoint);
  let arcAngle = -STREET_SPACE.OFFSETARCANGLE;
  if (offArcHeight < 0) arcAngle = STREET_SPACE.OFFSETARCANGLE;
  let btLeftPoint1 = RotatePoint(GetCenterInTwoPoints(bottomArcCenter, btLeftPoint), arcAngle, bottomArcCenter);
  let btRightPoint1 = RotatePoint(GetCenterInTwoPoints(bottomArcCenter, btRightPoint), arcAngle, bottomArcCenter);
  let tpLeftPoint1 = RotatePoint(GetCenterInTwoPoints(topArcCenter, tpLeftPoint), arcAngle, topArcCenter);
  let tpRightPoint1 = RotatePoint(GetCenterInTwoPoints(topArcCenter, tpRightPoint), arcAngle, topArcCenter);
  let isDirction = IsArcDirection(bottomTopLinePoints[0], bottomTopLinePoints[1], bottomTopLinePoints[2]);
  GetPathArcD(D, bottomTopLinePoints[0], tpLeftPoint, bottomTopLineR, CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
  GetPathBesselS(D, tpLeftPoint, tpLeftPoint1, tpLeftPoint1, topArcCenter, tpRightPoint1, tpRightPoint);
  GetPathArcD1(D, topTopLinePoints[2], topTopLineR, CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
  GetPathArcDL(
    D,
    topBottomLinePoints[2],
    btRightPoint,
    topBottomLineR,
    CIRCLE_SIZE_TYPE.SMALLARC,
    isDirction == '0' ? '1' : '0'
  );
  GetPathBesselS(D, btRightPoint, btRightPoint1, btRightPoint1, bottomArcCenter, btLeftPoint1, btLeftPoint);
  GetPathArcD1(
    D,
    bottomBottomLinePoints[0],
    bottomBottomLineR,
    CIRCLE_SIZE_TYPE.SMALLARC,
    isDirction == '0' ? '1' : '0'
  );
  D.push('Z');
}
export function GetOffarcBEPoints(anchors, arcCenter, offArcHeight) {
  let offLength = Math.abs(offArcHeight) * 2;
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let pointLeft = [],
    pointRight = [];
  if (IsZero(circle[2])) {
    let length = LengthBetweenPoints(anchors[0], anchors[2]);
    let lengthArcCenter = LengthBetweenPoints(anchors[0], arcCenter);
    pointLeft = GetCenter(anchors[0], anchors[2], (lengthArcCenter - offLength) / length);
    pointRight = GetCenter(anchors[0], anchors[2], (lengthArcCenter + offLength) / length);
  } else {
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let length = LengthArc1(anchors[0], anchors[2], circle, isBytime);
    let lengthArcCenter = LengthArc1(anchors[0], arcCenter, circle, isBytime);
    pointLeft = GetArcCenter(circle, anchors[0], anchors[2], (lengthArcCenter - offLength) / length, isBytime);
    pointRight = GetArcCenter(circle, anchors[0], anchors[2], (lengthArcCenter + offLength) / length, isBytime);
  }
  return [pointLeft, pointRight];
}
//offHeght 0和6的距离
export function StreetOffArcLanesShape(D, anchors, offArcHeight, offArcCenter, laneHeight, isTop) {
  let bottomPoints = getStreetLanePoint(anchors, 0, offArcHeight, false);
  let topPoints = getStreetLanePoint(anchors, 0, offArcHeight, true);
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let bottomR = 0,
    topR = 0;
  let offArcCenterPoint = [],
    offArcBeginPoint = [],
    offArcEndPoint = [];
  if (IsZero(circle[2])) {
    offArcCenterPoint = GetCenter(anchors[0], anchors[2], offArcCenter);
  } else {
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    offArcCenterPoint = GetArcCenter(circle, anchors[0], anchors[2], offArcCenter, isBytime);
  }

  let arcPoints = GetOffarcBEPoints(anchors, offArcCenterPoint, offArcHeight);
  if (IsZero(circle[2])) {
    offArcBeginPoint = TowPointVerticalAcross(bottomPoints[0], bottomPoints[2], arcPoints[0]);
    offArcEndPoint = TowPointVerticalAcross(topPoints[0], topPoints[2], arcPoints[1]);
  } else {
    bottomR = LengthBetweenPoints(circle, bottomPoints[0]);
    topR = LengthBetweenPoints(circle, topPoints[0]);
    offArcBeginPoint = FindCircleLinePoint([circle[0], circle[1], bottomR], arcPoints[0]);
    offArcEndPoint = FindCircleLinePoint([circle[0], circle[1], topR], arcPoints[1]);
  }
  let isDirction = IsArcDirection(anchors[0], anchors[1], anchors[2]);
  GetPathArcD(D, bottomPoints[0], offArcBeginPoint, bottomR, CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
  let leftR = offArcHeight * 2,
    rightR = offArcHeight * 2;
  if (isTop) {
    leftR = leftR + laneHeight;
    rightR = rightR + laneHeight;
  } else {
    leftR = leftR + laneHeight;
    rightR = rightR + laneHeight;
  }
  GetPathArcD1(D, offArcCenterPoint, leftR, CIRCLE_SIZE_TYPE.SMALLARC, '0');
  GetPathArcD1(D, offArcEndPoint, rightR, CIRCLE_SIZE_TYPE.SMALLARC, '1');
  GetPathArcD1(D, topPoints[2], topR, CIRCLE_SIZE_TYPE.SMALLARC, isDirction);
}
export function OffArcLineShape(D, offHeight, offarccenter, linePoints) {
  let circle = FindCircle(linePoints[0], linePoints[1], linePoints[2]);
  let arcCenter = [];
  if (IsZero(circle[2])) {
    arcCenter = GetCenter(linePoints[0], linePoints[2], offarccenter);
  }
}
export function offArcPoints(anchors, offHeight, offarccenter) {
  let bottomPoints = getStreetLanePoint(anchors, 0, offHeight, false);
  let topPoints = getStreetLanePoint(anchors, 0, offHeight, true);
  let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
  let offArcCenterPoint = [],
    offArcBeginPoint = [],
    offArcEndPoint = [];
  if (IsZero(circle[2])) {
    offArcCenterPoint = GetCenter(anchors[0], anchors[2], offarccenter);
  } else {
    let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    offArcCenterPoint = GetArcCenter(circle, anchors[0], anchors[2], offarccenter, isBytime);
  }
  let arcPoints = GetOffarcBEPoints(anchors, offArcCenterPoint, offHeight);
  if (IsZero(circle[2])) {
    offArcBeginPoint = TowPointVerticalAcross(bottomPoints[0], bottomPoints[2], arcPoints[0]);
    offArcEndPoint = TowPointVerticalAcross(topPoints[0], topPoints[2], arcPoints[1]);
  } else {
    let bottomR = LengthBetweenPoints(circle, bottomPoints[0]);
    let topR = LengthBetweenPoints(circle, topPoints[0]);
    offArcBeginPoint = FindCircleLinePoint([circle[0], circle[1], bottomR], arcPoints[0]);
    offArcEndPoint = FindCircleLinePoint([circle[0], circle[1], topR], arcPoints[1]);
  }
  offArcCenterPoint = GetCenterInTwoPoints(offArcBeginPoint, offArcEndPoint);
  return [offArcBeginPoint, offArcCenterPoint, offArcEndPoint];
}
export function GetArcTopPoint(circle, height, p1, p2, isByTime) {
  let bigCircle = [circle[0], circle[1], circle[2] + height];
  let smallCircle = [circle[0], circle[1], circle[2] - height];
  let topCircle = isByTime ? bigCircle : smallCircle;
  let angle1 = getAngleBy2Points(topCircle, p1);
  let point0 = GetCirclePoint(topCircle, angle1);
  let angle2 = getAngleBy2Points(topCircle, p2);
  let point1 = GetCirclePoint(topCircle, angle2);
  return [point0, point1];
}
export function GetoffArcHeightPoints(arcPoints, r, isBytime, offheight, isTop) {
  let leftcircle = FindCircleByR(arcPoints[0], arcPoints[1], r, isBytime);
  let rightCircle = FindCircleByR(arcPoints[1], arcPoints[2], r, !isBytime);
  //中线下面得道路高度相反
  let streetHeight = offheight;
  if (isTop) streetHeight = -offheight;
  let leftArcPoints = GetArcTopPoint(leftcircle, -streetHeight, arcPoints[0], arcPoints[1], isBytime);
  let rightArcPoints = GetArcTopPoint(rightCircle, -streetHeight, arcPoints[1], arcPoints[2], !isBytime);
  return [leftArcPoints[0], rightArcPoints[0], rightArcPoints[1]];
}
