import React, { Component } from 'react';
import { Button, Icon } from 'antd';

export class ExpandToggler extends Component {
  render() {
    const { expanded, onClick } = this.props;
    const iconType = expanded ? 'up' : 'down';

    return (
      <Button type="primary" className="expand-toggler" onClick={onClick}>
        <Icon type={iconType} />
      </Button>
    );
  }
}

export default ExpandToggler;
