import React from 'react';
import { FUNCTION_TYPE } from '@src/constant';
import LineModal from './Modal/LineModal';
import SquareModal from './Modal/SquareModal';
import RectModal from './Modal/RectModal';
import CircleModal from './Modal/CircleModal';
import ArcModal from './Modal/ArcModal';
import ConnectLineModal from './Modal/ConnectLineModal';
import EllipseModal from './Modal/EllipseModal';
import CloseShapeModal from './Modal/CloseShapeModal';
import StructureModal from './Modal/StructureModal';
import StairsModal from './Modal/StairsModal';
import DimensionLineModal from './Modal/DimensionLineModal';
import CalloutModal from './Modal/CalloutModal';
import TextBoxModal from './Modal/TextBoxModal';
import ParkStallModal from './Modal/ParkStallModal';
import CrossWalkModal from './Modal/CrossWalkModal';
import CurbReturnModal from './Modal/CurbReturnModal';
import SymbolModal from './Modal/SymbolModal';
import StreetModal from './Modal/StreetModal';
import MarkerModal from './Modal/MarkerModal';
import ImageModal from './Modal/ImageModal';
import XYMeasurementModal from './Modal/MeasurementModal/XYMeasurementModal';
import StationLineModal from './Modal/MeasurementModal/StationLineModal';
import TriangulationNetworkModal from './Modal/MeasurementModal/TriangulationNetworkModal';

/**
 * a bottom props menu for selected object
 * @param {*} object deep copy of work data object
 * @param {Function} updateView method to trigger SVG re-render
 */
export function createPropsMenu({ object, updateView, hidePropsMenu }) {
  const { functype } = object;
  let ModalComponent = undefined;

  switch (functype) {
    case FUNCTION_TYPE.LINE: {
      ModalComponent = LineModal;
      break;
    }
    case FUNCTION_TYPE.RECTANGLE: {
      ModalComponent = RectModal;
      break;
    }
    case FUNCTION_TYPE.ARC: {
      ModalComponent = ArcModal;
      break;
    }
    case FUNCTION_TYPE.CONNECTEDLINES: {
      ModalComponent = ConnectLineModal;
      break;
    }
    case FUNCTION_TYPE.CLOSEDSHAPE: {
      ModalComponent = CloseShapeModal;
      break;
    }
    case FUNCTION_TYPE.ELLIPSE: {
      ModalComponent = EllipseModal;
      break;
    }
    case FUNCTION_TYPE.SQUARE: {
      ModalComponent = SquareModal;
      break;
    }
    case FUNCTION_TYPE.CIRCLE: {
      ModalComponent = CircleModal;
      break;
    }
    case FUNCTION_TYPE.DIMENSIONLINE: {
      ModalComponent = DimensionLineModal;
      break;
    }
    case FUNCTION_TYPE.CALLOUT: {
      ModalComponent = CalloutModal;
      break;
    }
    case FUNCTION_TYPE.TEXTBOX: {
      ModalComponent = TextBoxModal;
      break;
    }
    case FUNCTION_TYPE.STAIRS: {
      ModalComponent = StairsModal;
      break;
    }
    case FUNCTION_TYPE.PARKINGSTALLS: {
      ModalComponent = ParkStallModal;

      break;
    }
    case FUNCTION_TYPE.CROSSWALK: {
      ModalComponent = CrossWalkModal;
      break;
    }
    case FUNCTION_TYPE.STRUCTURE: {
      ModalComponent = StructureModal;
      break;
    }
    case FUNCTION_TYPE.STREET:
    case FUNCTION_TYPE.DIRT:
    case FUNCTION_TYPE.GRAVEL:
    case FUNCTION_TYPE.STREETNEW: {
      ModalComponent = StreetModal;
      break;
    }
    case FUNCTION_TYPE.SYMBOL: {
      ModalComponent = SymbolModal;
      break;
    }
    case FUNCTION_TYPE.CURBRETURN: {
      ModalComponent = CurbReturnModal;
      break;
    }
    case FUNCTION_TYPE.MARKER: {
      ModalComponent = MarkerModal;
      break;
    }
    case FUNCTION_TYPE.STATIONLINE: {
      ModalComponent = StationLineModal;
      break;
    }
    case FUNCTION_TYPE.XYMEASUREMENT: {
      ModalComponent = XYMeasurementModal;
      break;
    }
    case FUNCTION_TYPE.TRIANGULATIONNETWORK: {
      ModalComponent = TriangulationNetworkModal;
      break;
    }
    case FUNCTION_TYPE.IMAGE: {
      ModalComponent = ImageModal;
      break;
    }
    default:
      break;
  }

  return ModalComponent ? <ModalComponent object={object} handle={updateView} hidePropsMenu={hidePropsMenu} /> : null;
}
