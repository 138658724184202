import React from 'react';
import ArrowMarker from '../ArrowMarker';
import { getArrowType } from '@src/data/BusinessFun';
import { GetShapeStyle } from '@src/data/CommonProps';
import {
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetCenter,
  LengthBetweenPoints,
  TowPointVerticalLength,
  TowPointVerticalLengthPoints,
} from '@src/data/CommonFunc';
import Text from '../Text';
import GeomPoint from '@src/data/GeomPoint';
import GeomLine from '@src/data/GeomLine';
import { TEXT_POSITION_TYPE, TEXT_ANCHORS_TYPE } from '@src/constant';
import Utility from '@src/data/Utility';

function Line({ object }) {
  if (object.handlepoint.length < 2) return null;
  const x1 = object.handlepoint[0][0];
  const x2 = object.handlepoint[1][0];
  const y1 = object.handlepoint[0][1];
  const y2 = object.handlepoint[1][1];

  const getMarks = object => {
    if (!object.marks.isexist || object.handlepoint.length < 2) return null;
    let length = LengthBetweenPoints(object.handlepoint[0], object.handlepoint[1]);
    let n = Math.round(length / object.marks.length);
    var D = [];
    for (let i = 0; i <= n; i++) {
      let center = GetCenter(object.handlepoint[0], object.handlepoint[1], i / n);
      let points = TowPointVerticalLengthPoints(
        object.handlepoint[0],
        object.handlepoint[1],
        center,
        object.marks.height
      );
      D.push('M' + String(points[0][0]) + ',' + String(points[0][1]));
      D.push('L' + String(points[1][0]) + ',' + String(points[1][1]));
    }
    return <path d={D.join(' ')} strokeWidth={1} />;
  };

  const getText = object => {
    let text = '';
    if (object.text.islength) {
      let length = LengthBetweenPoints(object.handlepoint[0], object.handlepoint[1]);
      length = Math.round((length * 1000 + 0.5) / 1000);
      text = Utility.format(length / 12);
    } else {
      text = object.text.text;
    }
    let textShape = null;
    // keeps horizontal
    let angle = 0;
    // var angle = getAngleBy2Points(object.handlepoint[0], object.handlepoint[1]);
    let center = GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[1]);
    let gl = new GeomLine(
      new GeomPoint(object.handlepoint[0][0], object.handlepoint[0][1]),
      new GeomPoint(object.handlepoint[1][0], object.handlepoint[1][1])
    );

    const infos = [
      {
        position: TEXT_POSITION_TYPE.MIDIUMTOP,
        point: center,
        initialUplength: object.style.strokewidth,
        angle: Math.PI / 2,
        type: TEXT_ANCHORS_TYPE.MIDDLE,
      },
      {
        position: TEXT_POSITION_TYPE.RIGHTTOP,
        point: object.handlepoint[1],
        initialUplength: object.style.strokewidth,
        angle: Math.PI / 2,
        type: TEXT_ANCHORS_TYPE.END,
      },
      {
        position: TEXT_POSITION_TYPE.RIGHTBOTTOM,
        point: object.handlepoint[1],
        initialUplength: object.style.strokewidth + object.text.size,
        angle: -Math.PI / 2,
        type: TEXT_ANCHORS_TYPE.END,
      },
      {
        position: TEXT_POSITION_TYPE.MIDIUMBOTTOM,
        point: center,
        initialUplength: object.style.strokewidth + object.text.size,
        angle: -Math.PI / 2,
        type: TEXT_ANCHORS_TYPE.MIDDLE,
      },
      {
        position: TEXT_POSITION_TYPE.LEFTBOTTOM,
        point: object.handlepoint[0],
        initialUplength: object.style.strokewidth + object.text.size,
        angle: -Math.PI / 2,
        type: TEXT_ANCHORS_TYPE.START,
      },
      {
        position: TEXT_POSITION_TYPE.LEFTTOP,
        point: object.handlepoint[0],
        initialUplength: object.style.strokewidth,
        angle: Math.PI / 2,
        type: TEXT_ANCHORS_TYPE.START,
      },
    ];

    const getPoint = (point, initialUplength, angle) => {
      let p = [point[0] - object.style.strokewidth, point[1] - object.style.strokewidth];
      let uplength = initialUplength;
      if (object.marks.isexist) {
        uplength = uplength + object.marks.height;
      }
      p = TowPointVerticalLength(object.handlepoint[0], object.handlepoint[1], point, uplength, p);
      let tp = gl.polar(new GeomPoint(point[0], point[1]), angle, uplength);
      p = [tp.x, tp.y];
      return p;
    };
    const getType = (type = TEXT_ANCHORS_TYPE.MIDDLE) => type;

    let info = infos.find(x => object.text.position === x.position) || infos[0];
    let textPoint = getPoint(info.point, info.initialUplength, info.angle);
    let anchorsType = getType(info.type);

    textShape = (
      <Text
        angle={angle}
        x={textPoint[0]}
        y={textPoint[1]}
        textAnchor={anchorsType}
        text={object.text}
        content={text}
      />
    );

    return textShape;
  };

  let marks = getMarks(object);
  let text = getText(object);

  return (
    <g>
      <metadata
        sstrokepattern={object.style.strokepattern}
        sterminatortype={object.arrow.position}
        sterminatorstyle={object.arrow.type}
      />
      <ArrowMarker
        id={object.operateid}
        width={object.arrow.width}
        height={object.arrow.height}
        type={object.arrow.type}
        fill={object.style.stroke}
      />
      <line x1={x1} y1={y1} x2={x2} y2={y2} {...GetShapeStyle().LINEWHITESTYLE} />
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        markerStart={getArrowType(object.operateid, object.arrow.position, 0)}
        markerEnd={getArrowType(object.operateid, object.arrow.position, 1)}
      />
      {marks}
      {text}
    </g>
  );
}

export default Line;
