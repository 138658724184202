const ns = '[Map] ';

// set the latlng or zoom level info
export const SET_MAP_VIEW = `${ns}set map lat lng zoom`;

// change map provider or layer type
export const SET_MAP_TYPE = `${ns}set map type`;
export const SET_OPACITY = `${ns}set opacity`;
export const SET_CURRENT_LOCATION = `${ns}set current location`;
export const SET_SELECT_OPTIONS = `${ns}set select options`;
export const TOGGLE_MAP_CONTAINER = `${ns}toggle map container`;
export const SWITCH_LOCATION_LOADING = `${ns}switch location loading`;
export const SWITCH_MAP_TAB = `${ns}switch map tab`;
export const SET_KEYWORD = `${ns}set keyword`;
export const RESUME_MAP_STATE = `${ns}resume map state`;
export const SET_NIGHT_MODE = `${ns}set night mode status`;
export const SET_MAP_CONFIGS = `${ns}set map configurations`;
