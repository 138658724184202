import { useEffect } from 'react';
import { Modal } from 'antd';
import * as constant from '@src/constant';
import { withTranslation } from 'react-i18next';
import { destroyShadowShape } from '@src/utils';
import * as workData from '@src/data/WorkData';

const { APP_ROOT_EL_ID } = constant;

function ShadowConfirm({ obj, setVisible, t }) {
  const handleOk = () => {
    obj.shadow.isActiveShadowMode = false;
    workData.setObj(obj);
    setVisible(false);
  };

  const handleCancel = () => {
    obj.handlepoint = obj.shadow.originHandlePoint;
    obj.rotateangle = obj.shadow.originRotateAngle;
    destroyShadowShape(obj);
    workData.setObj(obj);
    setVisible(false);
  };

  useEffect(() => {
    Modal.confirm({
      title: t('menu.shadow.confirmAddShadow'),
      okText: t('menu.shadow.keepButtonText'),
      cancelText: t('menu.shadow.redoButtonText'),
      getContainer: () => document.getElementById(APP_ROOT_EL_ID),
      onOk: handleOk,
      onCancel: handleCancel,
    });
  }, []);

  return null;
}

export default withTranslation()(ShadowConfirm);
