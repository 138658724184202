import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomAlign from './GeomAlign';
import GeomPathOffset from './GeomPathOffset';
import Utility from './Utility';
import UtilityMath from './UtilityMath';

class GeomArc {
  /**
   * @type {GeomPoint}
   */
  ptStart;

  /**
   * @type {GeomPoint}
   */
  ptStop;

  /**
   *
   * @param {GeomPoint} ptStart 弧线起点
   * @param {GeomPoint} ptStop  弧线终点
   * @param {number} r 半径
   * @param {boolean} largeArcFlag Major arc if true, else minor arc
   * @param {boolean} sweepFlag 弧线是从 ptStart 绕着圆心逆时针旋转至 ptStop 形成则为 true. 由于画布上下倒置，所以画布上显示的 sweepFlag 的值，与定义的 sweepFlag 的值相反
   */
  constructor(ptStart, ptStop, r, largeArcFlag, sweepFlag) {
    this.declaredClass = 'GeomArc';
    this.ptStart = ptStart.clone();
    this.ptStop = ptStop.clone();
    this.r = r;
    this.largeArcFlag = largeArcFlag;
    this.sweepFlag = sweepFlag;

    // 弧线圆心
    this._ptCircleCenter = new GeomPoint(0, 0);
    // 弧线中点
    this._ptArcMid = new GeomPoint(0, 0);
    this._arcLength = undefined;

    // TODO: what is real purpose of this? to make the 'cached' values like _ptCircleCenter are valid?
    this._snapshot = undefined;
  }

  static fromPoints(points) {
    const [ptStart, pt, ptStop] = points;
    // TODO: finish this method so that we could init GeomArc with Arc/SelectArc handlepoint prop
  }

  _compute() {
    var a = this.ptStart.AngleFromHorizontal(this.ptStop);
    var spt = this.ptStart.clone();
    var ept = this.ptStop.clone();
    if (0 !== a) {
      ept.rotateRad(-a, this.ptStart);
    }
    var ox = -((spt.x + ept.x) / 2);
    var oy = -this.ptStart.y;
    spt.offset(ox, oy);
    ept.offset(ox, oy);
    var pt = new GeomPoint(0, 0);
    // ept.x is basically half the distance of spt and ept
    if (this.r > ept.x) {
      try {
        pt.y = Math.sqrt(this.r * this.r - ept.x * ept.x);
      } catch (e) {
        pt.y = 0;
      }
    }
    // large arc means major arc

    // 圆心位于水平线之下的两种情况
    if (this.largeArcFlag && this.sweepFlag) {
      pt.y *= -1;
    } else {
      if (!this.largeArcFlag && !this.sweepFlag) {
        pt.y *= -1;
      }
    }
    var ptMid = pt.clone();
    if (this.sweepFlag) {
      ptMid.y -= this.r;
    } else {
      ptMid.y += this.r;
    }
    pt.offset(-ox, -oy);
    ptMid.offset(-ox, -oy);
    if (0 !== a) {
      pt.rotateRad(a, this.ptStart);
      ptMid.rotateRad(a, this.ptStart);
    }
    // 得到弧线的圆心位置和中点
    this._ptCircleCenter = pt;
    this._ptArcMid = ptMid;
    a = pt.radianBetweenTwoPoints(this.ptStart, this.ptStop);
    if (this.largeArcFlag) {
      this._arcLength = this.r * (2 * Math.PI - a);
    } else {
      this._arcLength = this.r * a;
    }
    this._snapshot = {
      ptStart: this.ptStart.clone(),
      ptStop: this.ptStop.clone(),
      r: this.r,
      largeArcFlag: this.largeArcFlag,
      sweepFlag: this.sweepFlag,
    };
  }

  _ensureComputedValues() {
    if (!this._snapshot) {
      this._compute();
    } else {
      if (
        !this.ptStart.isEqual(this._snapshot.ptStart) ||
        !this.ptStop.isEqual(this._snapshot.ptStop) ||
        this.r != this._snapshot.r ||
        this.largeArcFlag != this._snapshot.largeArcFlag ||
        this.sweepFlag != this._snapshot.sweepFlag
      ) {
        this._compute();
      }
    }
  }

  _resetComputedValues() {
    this._ptCircleCenter = null;
    this._ptArcMid = null;
    this._arcLength = null;
    this._snapshot = null;
  }

  clone() {
    return new GeomArc(this.ptStart.clone(), this.ptStop.clone(), this.r, this.largeArcFlag, this.sweepFlag);
  }

  /**
   *
   * @param {GeomPoint} point
   * @returns {boolean}
   */
  containsPoint(point) {
    this._ensureComputedValues();
    if (this.getLength() < 0.1) {
      var gl = new GeomLine(this.getPointStart(), this.getPointStop());
      var gpo = gl.getOffsetsToPoint(point);
      return gpo.fromStartPercent >= 0 && gpo.fromStartPercent <= 1 && Math.abs(gpo.normal) < 0.01;
    }
    var isClockwise = new UtilityMath().isClockwise(this.ptStart, this._ptArcMid, this.ptStop);
    if (isClockwise && new UtilityMath().isClockwise(this.ptStart, point, this.ptStop)) {
      return true;
    }
    return !isClockwise && new UtilityMath().isCounterClockwise(this.ptStart, point, this.ptStop);
  }

  /**
   *
   * @param {number} fromStart
   * @param {number} distance
   * @returns {GeomAlign}
   */
  getAlignmentAtLength(fromStart, distance) {
    var spt = this.ptStart.clone(),
      sign,
      rad;
    this._ensureComputedValues();
    sign = this.sweepFlag ? 1 : -1;
    // move towards to the normal direction side (right-hand travelling)
    spt.offsetToward(this._ptCircleCenter, distance * sign);
    rad = (sign * fromStart) / this.r;
    spt.rotateRad(rad, this._ptCircleCenter);
    let um = new UtilityMath();
    var rotateDeg = 0;
    if (this.sweepFlag) {
      rotateDeg = um.normalizeAngleDeg(90 + um.toDegrees(this._ptCircleCenter.AngleFromHorizontal(spt)));
    } else {
      rotateDeg = um.normalizeAngleDeg(270 + um.toDegrees(this._ptCircleCenter.AngleFromHorizontal(spt)));
    }
    return new GeomAlign(spt, rotateDeg);
  }

  /**
   * 求弧线到点的距离
   * @param {GeomPoint} pt
   * @returns {number}
   */
  getArcLengthToPoint(pt) {
    if (pt.isEqual(this.ptStart)) {
      return 0;
    }
    this._ensureComputedValues();
    if (this.getLength() < 0.1) {
      var gl = new GeomLine(this.getPointStart(), this.getPointStop());
      return gl.getOffsetsToPoint(pt).fromStart;
    }
    var isClockwise = new UtilityMath().isClockwise(this.ptStart, this._ptArcMid, this.ptStop),
      angle = 0;
    if (isClockwise === new UtilityMath().isClockwise(this.ptStart, pt, this._ptCircleCenter)) {
      angle = this._ptCircleCenter.radianBetweenTwoPoints(this.ptStart, pt);
    } else {
      angle = Math.PI * 2 - this._ptCircleCenter.radianBetweenTwoPoints(this.ptStart, pt);
    }
    return angle * this.r;
  }

  getChordAngleFromHorizontal() {
    return this.ptStart.AngleFromHorizontal(this.ptStop);
  }

  getCircleCenter() {
    this._ensureComputedValues();
    return this._ptCircleCenter.clone();
  }

  /**
   * 获取变形系数
   * @returns {number}
   */
  getDeflectionFactor() {
    if (this.ptStart.isEqual(this.ptStop)) {
      return 0;
    }
    return this._getMiddleOrdinateLength() / this.ptStart.distance(this.ptStop);
  }

  getLength() {
    this._ensureComputedValues();
    return this._arcLength;
  }

  _getMiddleOrdinateLength() {
    var ptMid = this.ptStart.midPoint(this.ptStop);
    this._ensureComputedValues();
    return ptMid.distance(this._ptArcMid);
  }

  getMidPoint() {
    this._ensureComputedValues();
    return this._ptArcMid.clone();
  }

  /**
   *
   * @param {GeomPoint} pt
   * @returns {GeomPathOffset}
   */
  getOffsetsToPoint(pt) {
    var cpt = this.getPointClosest(pt),
      fromStart = this.getArcLengthToPoint(cpt),
      fromStartPercent,
      gpo,
      a54c,
      a54d;
    this._ensureComputedValues();
    if (0 == this._arcLength) {
      return {
        fromStart: 0,
        fromStartPercent: 0,
        normal: 0,
      };
    }
    fromStartPercent = fromStart / this._arcLength;
    gpo = new GeomPathOffset(fromStart, fromStartPercent, cpt.distance(pt));
    (a54c = this.getAlignmentAtLength(fromStart, gpo.normal)),
      (a54d = this.getAlignmentAtLength(fromStart, -gpo.normal));
    if (pt.distanceSquared(a54c.ptAlign) > pt.distanceSquared(a54d.ptAlign)) {
      gpo.normal *= -1;
    }
    return gpo;
  }

  /**
   * Get the path data of partial arc
   * @param {number} startLen
   * @param {number} toLen
   * @param {boolean} standalone if true, return the complete SVG path data; otherwise, only return a piece of SVG path data - to the arc end point
   * @returns {string} path
   */
  getPartialSvgPathData(startLen, toLen, standalone) {
    if (0 === startLen && toLen < 0) {
      return this.getSegmentPathData(standalone);
    }
    let arc = this.clone();
    arc.trim(startLen, toLen < 0 ? 0 : arc.getLength() - toLen);
    return arc.getSegmentPathData(standalone || startLen > 0);
  }

  /**
   *
   * @param {number} length
   * @returns {GeomPoint}
   */
  getPointAtLength(length) {
    var spt = this.ptStart.clone();
    if (length > 0) {
      this._ensureComputedValues();
      var sign = this.sweepFlag ? 1 : -1;
      var a = (sign * length) / this.r;
      spt.rotateRad(a, this._ptCircleCenter);
    }
    return spt;
  }

  /**
   * Get the point on the arc which is closest to the given point
   * @param {GeomPoint} pt
   * @returns {GeomPoint}
   */
  getPointClosest(pt) {
    var vec, vecClone, centerClone;
    if (pt.isEqual(this.ptStart)) {
      return this.ptStart;
    }
    this._ensureComputedValues();
    if (this.ptStart.isEqual(this.ptStop)) {
      return this.ptStart.clone();
    }
    if (pt.isEqual(this._ptCircleCenter)) {
      return this._ptArcMid.clone();
    }
    // vector center -> pt
    vec = this._ptCircleCenter.getVector(pt);
    vecClone = vec.clone();
    vecClone.scale(this.r / vec.magnitude());
    centerClone = this._ptCircleCenter.clone();
    // point in circle
    centerClone.offset(vecClone.vx, vecClone.vy);
    var isClockwise = new UtilityMath().isClockwise(this.ptStart, this._ptArcMid, this.ptStop);
    if ((isClockwise ^ new UtilityMath().isClockwise(this.ptStart, centerClone, this.ptStop)) === 0) {
      return centerClone;
    }
    // cheap version to compare distance
    if (centerClone.distanceSquared(this.ptStart) <= centerClone.distanceSquared(this.ptStop)) {
      return this.ptStart.clone();
    } else {
      return this.ptStop.clone();
    }
  }

  /**
   * get the start point
   * @returns {GeomPoint}
   */
  getPointStart() {
    return this.ptStart.clone();
  }

  /**
   * get the stop point
   * @returns {GeomPoint}
   */
  getPointStop() {
    return this.ptStop.clone();
  }

  /**
   * Get the path data
   * @param {boolean} standalone if true, return the complete SVG path data; otherwise, only return a piece of SVG path data - to the arc end point
   * @returns {string} SVG path data/partial data
   */
  getSegmentPathData(standalone) {
    var path = '';
    if (standalone) {
      path = 'M' + this.ptStart.toString();
    }
    if (this.ptStart.isNotEqual(this.ptStop)) {
      if (path.length > 0) {
        path += ' ';
      }
      let r = this.r ? this.r : 0;
      var radius = Utility.toFixed(r, 8);
      path +=
        'A' +
        radius +
        ' ' +
        radius +
        ' 0 ' +
        (this.largeArcFlag ? '1' : '0') +
        ' ' +
        (this.sweepFlag ? '1' : '0') +
        ' ' +
        this.ptStop.toString();
    }
    return path;
  }

  getSvgPathData() {
    return this.getSegmentPathData(true);
  }

  /**
   * 膨胀弧，向外弯曲的弧形
   * @param {number} distance
   */
  inflate(distance) {
    this._ensureComputedValues();
    this.ptStart.offsetToward(this._ptCircleCenter, -distance);
    this.ptStop.offsetToward(this._ptCircleCenter, -distance);
    this.r = this.ptStart.distance(this._ptCircleCenter);
    this._resetComputedValues();
  }

  /**
   * 获取这条线的法线上的点
   * @param {GeomPoint} pt
   * @param {number} offset
   * @returns {GeomPoint}
   */
  pointOffsetNormal(pt, offset) {
    var ptClone = pt.clone(),
      line,
      sign;
    this._ensureComputedValues();
    sign = this.sweepFlag ? 1 : -1;
    if (ptClone.isEqual(this._ptCircleCenter)) {
      line = new GeomLine(this.getPointStart(), this.getPointStop());
      return line.pointOffsetNormal(ptClone, offset * sign);
    } else {
      ptClone.offsetToward(this._ptCircleCenter, offset * sign);
      return ptClone;
    }
  }

  reverse() {
    var pt = this.ptStart;
    this.ptStart = this.ptStop;
    this.ptStop = pt;
    this.sweepFlag = !this.sweepFlag;
    this._resetComputedValues();
  }

  setDeflectionFactor(a55a) {
    if (a55a <= 0) {
      return;
    }
    var a = this.getChordAngleFromHorizontal();
    var a55c = this.ptStop.clone();
    if (0 !== a) {
      a55c.rotateRad(-a, this.ptStart);
    }
    var a55d = this.ptStart.distance(this.ptStop) * a55a;
    var a55e = this.ptStart.midPoint(a55c);
    if (this.sweepFlag) {
      a55e.y -= a55d;
    } else {
      a55e.y += a55d;
    }
    if (0 !== a) {
      a55e.rotateRad(a, this.ptStart);
    }
    this.setMidPoint(a55e);
  }

  /**
   *
   * @param {GeomPoint} pt
   * @returns {boolean}
   */
  setMidPoint(pt) {
    var a55f = pt.clone(),
      a560 = this.ptStop.clone(),
      theta,
      a561,
      a562,
      a563;
    var a = this.getChordAngleFromHorizontal();
    if (0 !== a) {
      a55f.rotateRad(-a, this.ptStart);
      a560.rotateRad(-a, this.ptStart);
    }
    a55f.x = this.ptStart.midPoint(a560).x;
    theta = Math.abs(a55f.AngleToHorizontal(this.ptStart) - a55f.AngleToHorizontal(a560)) / 2;
    a561 = this.ptStart.distance(a55f) / 2;
    a562 = Math.cos(theta);
    if (Math.abs(a562) > 0.000001) {
      this.r = a561 / a562;
      a563 = this.ptStart.midPoint(a560);
      this.largeArcFlag = a563.distance(a55f) > a563.distance(this.ptStart);
      this.sweepFlag = a55f.y < this.ptStart.y;
      this._resetComputedValues();
      return true;
    }
    return false;
  }

  setStart(ptStart) {
    var a566 = this.getDeflectionFactor();
    if (a566 > 0) {
      this.ptStart = ptStart.clone();
      this._resetComputedValues();
      this.setDeflectionFactor(a566);
    }
  }

  setStop(ptStop) {
    var a568 = this.getDeflectionFactor();
    if (a568 > 0) {
      this.ptStop = ptStop.clone();
      this._resetComputedValues();
      this.setDeflectionFactor(a568);
    }
  }

  startPoint() {
    return this.ptStart.clone();
  }

  stopPoint() {
    return this.ptStop.clone();
  }

  /**
   *
   * @param {number} startLen
   * @param {number} toLen
   * @returns {boolean}
   */
  trim(startLen, toLen) {
    if (startLen < 0) {
      startLen = 0;
    }
    if (toLen < 0) {
      toLen = 0;
    }
    if (startLen === 0 && toLen === 0) {
      return true;
    }
    this._ensureComputedValues();
    if (startLen + toLen > this._arcLength) {
      return false;
    }

    // 计算切线
    var sign = this.sweepFlag ? 1 : -1;
    var a = undefined;
    if (startLen > 0) {
      a = (sign * startLen) / this.r;
      this.ptStart.rotateRad(a, this._ptCircleCenter);
      this._ptArcMid.rotateRad(0.5 * a, this._ptCircleCenter);
    }
    if (toLen > 0) {
      a = (-sign * toLen) / this.r;
      this.ptStop.rotateRad(a, this._ptCircleCenter);
      this._ptArcMid.rotateRad(0.5 * a, this._ptCircleCenter);
    }
    return this.setMidPoint(this._ptArcMid);
  }
}

export default GeomArc;
