import * as React from 'react';

function SvgIconPinFill(props) {
  return (
    <svg className="icon-pin-fill_svg__icon" viewBox="0 0 1024 1024" width="1em" height="1em" {...props}>
      <defs>
        <style />
      </defs>
      <path d="M956.553 366.486L657.514 67.447c-7.888-7.888-18.204-11.772-28.52-11.772s-20.632 3.884-28.52 11.772L404.958 263.085c-14.806-1.7-29.734-2.428-44.662-2.428-88.837 0-177.675 29.249-250.614 87.746-18.69 14.927-20.146 42.962-3.277 59.953l220.516 220.517L65.506 890.046c-3.156 3.155-5.219 7.403-5.583 11.893l-4.126 45.147c-1.093 11.408 8.01 21.117 19.296 21.117.607 0 1.214 0 1.82-.121l45.148-4.126c4.49-.364 8.738-2.428 11.893-5.583L395.37 696.957l220.517 220.517c7.888 7.888 18.204 11.772 28.52 11.772 11.772 0 23.423-5.097 31.433-15.05 68.327-85.317 96.726-192.117 85.197-295.397l195.516-195.515c15.655-15.535 15.655-41.021 0-56.798z" />
    </svg>
  );
}

export default SvgIconPinFill;
