import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Icon } from 'antd';
import { generateOperateId } from '@src/data/CommonFunc';
import { COLOR_TYPE } from '@src/constant';
import { IconDown, IconPencil, IconPaintBucket, IconText, IconResetColor } from '@src/components/UI/Icons';
import './color-selector.scss';

const PaletteItem = ({ colorType, onClick }) => {
  return <div className="palette-item" id={colorType} style={{ backgroundColor: colorType }} onClick={onClick} />;
};

const PaletteReset = ({ colorType, onClick }) => {
  return <Icon className="palette-reset" id={colorType} component={IconResetColor} onClick={onClick} />;
};

class ColorSelect extends React.Component {
  constructor(props) {
    super(props);

    this.colors = {
      color1: [
        { name: 'white', key: '1', colorType: COLOR_TYPE.WHITE },
        { name: 'black', key: '2', colorType: COLOR_TYPE.BLACK },
        { name: 'blue', key: '3', colorType: COLOR_TYPE.BLUE },
        {
          name: 'blue gray',
          key: '4',
          colorType: COLOR_TYPE.BLUEGRAY,
        },
        { name: 'cream', key: '5', colorType: COLOR_TYPE.CREAM },
      ],
      color2: [
        { name: 'cyan', key: '6', colorType: COLOR_TYPE.CYAN },
        {
          name: 'dark blue',
          key: '7',
          colorType: COLOR_TYPE.DARKBLUE,
        },
        {
          name: 'dark brown',
          key: '8',
          colorType: COLOR_TYPE.DARKBROWN,
        },
        {
          name: 'dark cyan',
          key: '9',
          colorType: COLOR_TYPE.DARKCYAN,
        },
        {
          name: 'dark gray',
          key: '10',
          colorType: COLOR_TYPE.DARKGRAY,
        },
      ],
      color3: [
        {
          name: 'dark green',
          key: '11',
          colorType: COLOR_TYPE.DARKGREEN,
        },
        {
          name: 'dark magenta',
          key: '12',
          colorType: COLOR_TYPE.DARKMAGENTA,
        },
        {
          name: 'dark red',
          key: '13',
          colorType: COLOR_TYPE.DARKRED,
        },
        {
          name: 'dark tan',
          key: '14',
          colorType: COLOR_TYPE.DARKTAN,
        },
        { name: 'flame', key: '15', colorType: COLOR_TYPE.FLAME },
      ],
      color4: [
        {
          name: 'forest green',
          key: '16',
          colorType: COLOR_TYPE.FORESTGREEN,
        },
        { name: 'gold', key: '17', colorType: COLOR_TYPE.GOLD },
        { name: 'green', key: '18', colorType: COLOR_TYPE.GREEN },
        {
          name: 'light blue',
          key: '19',
          colorType: COLOR_TYPE.LIGHTBLUE,
        },
        {
          name: 'light brown',
          key: '20',
          colorType: COLOR_TYPE.LIGHTBROWN,
        },
      ],
      color5: [
        {
          name: 'light gray',
          key: '21',
          colorType: COLOR_TYPE.LIGHTGRAY,
        },
        {
          name: 'light pink',
          key: '22',
          colorType: COLOR_TYPE.LIGHTPINK,
        },
        {
          name: 'light tan',
          key: '23',
          colorType: COLOR_TYPE.LIGHTTAN,
        },
        {
          name: 'magenta',
          key: '24',
          colorType: COLOR_TYPE.MAGENTA,
        },
        {
          name: 'med gray',
          key: '25',
          colorType: COLOR_TYPE.MEDGRAY,
        },
      ],
      color6: [
        {
          name: 'navyblue',
          key: '26',
          colorType: COLOR_TYPE.NAVYBLUE,
        },
        {
          name: 'off white',
          key: '27',
          colorType: COLOR_TYPE.OFFWHITE,
        },
        { name: 'olive', key: '28', colorType: COLOR_TYPE.OLIVE },
        {
          name: 'orange',
          key: '29',
          colorType: COLOR_TYPE.ORANGE,
        },
        {
          name: 'pale green',
          key: '30',
          colorType: COLOR_TYPE.PALEGREEN,
        },
      ],
      color7: [
        {
          name: 'purple',
          key: '31',
          colorType: COLOR_TYPE.PURPLE,
        },
        { name: 'red', key: '32', colorType: COLOR_TYPE.RED },
        { name: 'tan', key: '33', colorType: COLOR_TYPE.TAN },
        {
          name: 'very dark gray',
          key: '34',
          colorType: COLOR_TYPE.VERYDARKGRAY,
        },
        {
          name: 'very light gray',
          key: '35',
          colorType: COLOR_TYPE.VERYLIGHTGRAY,
        },
      ],
      color8: [
        {
          name: 'violet',
          key: '36',
          colorType: COLOR_TYPE.VIOLET,
        },
        {
          name: 'yellow',
          key: '37',
          colorType: COLOR_TYPE.YELLOW,
        },
        {
          name: 'reset',
          key: '38',
          colorType: 'none',
        },
      ],
    };
  }

  componentDidMount() {
    this.originColorStyle = this.props.colorHex;
  }

  render() {
    // colorHex: 传入的颜色值
    const { colorHex, handleColorHex, iconType = 'line' } = this.props;

    // 对黑暗模式的“黑色”进行调整
    let selectedColorStyle;
    if (typeof colorHex === 'undefined') {
      if (iconType === 'line') {
        selectedColorStyle = {
          backgroundColor: COLOR_TYPE.LIGHTGRAY,
        };
      } else if (iconType === 'fill') {
        selectedColorStyle = {
          backgroundColor: COLOR_TYPE.BLACK,
        };
      }
    } else {
      selectedColorStyle = {
        backgroundColor: colorHex,
      };
    }

    let PrimaryIcon;
    switch (iconType) {
      case 'line':
        PrimaryIcon = IconPencil;
        break;
      case 'fill':
        PrimaryIcon = IconPaintBucket;
        break;
      default:
        PrimaryIcon = IconText;
        break;
    }

    const createPalette = () => {
      const operateId = generateOperateId();

      return (
        <div className="palette-container-body" key={operateId}>
          {Object.keys(this.colors).map((key, index) => {
            const column = this.colors[key];
            return (
              <div className="ant-tabs-content-item" key={`paletteSubs_${operateId}_${index}`}>
                {column.map(item => {
                  if (item.name === 'reset') {
                    return <PaletteReset key={item.key} colorType={this.originColorStyle} onClick={handleColorHex} />;
                  } else {
                    return <PaletteItem key={item.key} colorType={item.colorType} onClick={handleColorHex} />;
                  }
                })}
              </div>
            );
          })}
        </div>
      );
    };
    const menuColors = (
      <Menu>
        <Menu.Item key="1" size="small">
          {createPalette()}
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown
        className="ant-btn-sm palette-outer color-dropdown custom-dropdown-no-border"
        overlay={menuColors}
        overlayClassName="custom-dropdown-no-hover"
        trigger={['click']}
      >
        <div className="lncd-palette-select">
          <div className="palette-selected-wrap">
            <Icon component={PrimaryIcon} />
            <div className="palette-selected" style={selectedColorStyle} />
          </div>
          <Icon component={IconDown} className="dropdown-indicator" />
        </div>
      </Dropdown>
    );
  }
}

ColorSelect.propType = {
  colorHex: PropTypes.string.isRequired, // 颜色值
  handleColorHex: PropTypes.func.isRequired, // 修改颜色值的方法, handleColorHex通过抓取组件的id属性获取修改后的值
  iconType: PropTypes.string,
};

export default ColorSelect;
