import axios from 'axios';
import _ from 'lodash';
import {
  HandlePointCrosswalk,
  HandlePointParkStall,
  HandlePointRect,
  HandlePointSquareCircle,
  HandlePointStreet,
  HandlePointStreetByNumWidth,
  HandlePointStructure,
  HandlePointMeasurement,
  HandlePointTriangulationNetwork,
} from './HandlePoint';
import {
  IndicatorsArrowsData,
  LabelData,
  LaneMarksData,
  LanesShoulderData,
  StructureData,
  MeasurementData,
  StripeData,
} from '@src/data/ShapeDataList';
import { AddStructureData, CopyPoint } from '@src/data/BusinessFun';
import {
  SetOffSetArcStreet,
  AcrosswalkStreet,
  handleStreetAcross,
  handleDirtAcross,
  handleGravelAcross,
  handleOffsetAcross,
  GetStreetAreaBegin,
} from './StreetFunHandle';
import { StructureDoorMove, StructureDoorFinish } from './StructureFunHandle';
import {
  IsZero,
  FindCircle,
  GetTowPointRange,
  TowPointVerticalAcross,
  IsPointInRange1,
  LengthBetweenPoints,
  GetCenter,
  ByTime,
  IsArcRangePoint,
  GetCircleLineAcrossPoint,
  GetCirclePoint,
  FindCircleLinePoint,
  LengthArc1,
  GetArcCenter,
  getAngleBy2Points,
  IsByTime,
} from '@src/data/CommonFunc';
import { convertToFeet, convertToStandard } from '@src/data/CommonFunction';
import utility from '@src/data/Utility';
import _Street from '@src/data/_Street';
import { createStripe, createLane, createArc } from '@src/data/ShapeOperationData';
import {
  ANCHORS_TYPE,
  COLOR_TYPE,
  CROSS_WALK_PATTERN,
  FUNCTION_TYPE,
  PARK_STALL_PATTERN_TYPE,
  PARK_STALL_SPACE,
  STREET_SPACE,
  STRUCTURE_DOOR_TYPE,
  THREE_POINT_STATUS_TYPE,
  STREET_TYPE,
  STREET_DEFAULT_SIZE,
  MEASUREMENT_TEXT_SIZE,
  STATION_SHORT,
  STATION_LONG,
} from '@src/constant';

import * as workData from '@src/data/WorkData';

const { STREET_LEN, LANE_WIDTH } = STREET_DEFAULT_SIZE;

/**
 *
 * Unused in anywhere
 * @param {string} functype - url
 * @param {[number, number]} afterxy
 * @returns {boolean}
 */
export function SliderSymbolHandle(functype, afterxy) {
  workData.addData(FUNCTION_TYPE.SYMBOL);
  let obj = workData.getCurrentOperateObject();
  const RADIUS = 12;
  obj.handlepoint[0] = [afterxy[0] - RADIUS, afterxy[1] - RADIUS, ANCHORS_TYPE.STRUCTUREBEGIN, AddStructureData()];
  obj.handlepoint[1] = [afterxy[0] - RADIUS, afterxy[1] - RADIUS, ANCHORS_TYPE.STRUCTUREEND, AddStructureData()];
  obj.style.stroke = COLOR_TYPE.BLACK;
  obj.style.strokewidth = 3;
  obj.marks.islength = true;
  obj.selectflag = true;
  obj = HandlePointStructure(obj, [afterxy[0] + RADIUS, afterxy[1] - RADIUS], 1);
  obj = HandlePointStructure(obj, [afterxy[0] + RADIUS, afterxy[1] + RADIUS], 1);
  obj = HandlePointStructure(obj, [afterxy[0] - RADIUS, afterxy[1] + RADIUS], 1);
  obj = HandlePointStructure(obj, [afterxy[0] - RADIUS, afterxy[1] - RADIUS], 1);
  obj = HandlePointStructure(obj, [afterxy[0] - RADIUS, afterxy[1] - RADIUS], 2);
  var matrix = [1, 0, 0, -1, afterxy[0], afterxy[1]];
  // 异步获取SVG
  axios.get(functype).then(res => {
    const data = res.data;
    // const WIDTH = 1200;
    // const HEIGHT = 1370;

    let div = document.createElement('div');
    div.innerHTML = data;

    let svg = div.querySelector('svg');
    let shapes = [];
    let svgItems = Array.prototype.slice.call(svg.children, 0);

    svgItems.forEach(item => {
      shapes.push(item);
      obj.shapes = shapes;
    });
  });

  return false;
}

export function LabelShapeData(functype, afterxy) {
  if (functype.startsWith(LabelData[0].name)) {
    let objId = functype.split('||')[3];
    let streetObj = workData.getObject(objId);
    if (streetObj != null) {
      streetObj.text.point = [afterxy[0], -afterxy[1]];
      streetObj.text.text = 'Main Street';
      streetObj.text.size = 50;
      return true;
    }
  } else if (functype.startsWith(LabelData[1].name)) {
    let objId = functype.split('||')[3];
    let streetObj = workData.getObject(objId);
    if (streetObj != null) {
      streetObj.text.point = [afterxy[0], -afterxy[1]];
      streetObj.text.text = 'Second Street';
      streetObj.text.size = 50;
      return true;
    }
  }
  return false;
}

export function StripeShapeData(functype, afterxy) {
  if (functype.startsWith(StripeData[8].name + 'move')) {
    let objId = functype.split('||')[3];
    let streetLine = functype.split('||')[4];
    let streetObj = workData.getObject(objId);
    if (streetObj == undefined) return false;
    let anchors = streetObj.handlepoint;
    let beginIndex = GetStreetAreaBegin(anchors);
    let centerIndex = anchors[beginIndex][3].rightindex;
    let endIndex = anchors[centerIndex][3].rightindex;
    streetObj.selectflag = true;
    let circle = FindCircle(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]);
    if (IsZero(circle[2])) {
      let pointRange = GetTowPointRange(anchors[beginIndex], anchors[endIndex]);
      let acrossPoint = TowPointVerticalAcross(anchors[beginIndex], anchors[endIndex], afterxy);
      if (IsPointInRange1(acrossPoint, pointRange)) {
        let scalesplit =
          LengthBetweenPoints(anchors[beginIndex], acrossPoint) /
          LengthBetweenPoints(anchors[beginIndex], anchors[endIndex]);
        let angle = getAngleBy2Points(anchors[beginIndex], anchors[endIndex]);
        //streetObj.streetsplitpoint = [
        //  streetLine,
        //  scalesplit,
        //  angle + Math.PI / 2,
        //];
        streetObj.groupdata[streetLine].splitpattem = [{ pattern: 'solid', startPct: scalesplit }];
      } else {
        streetObj.groupdata[streetLine].splitpattem = [];
      }
    } else {
      let byTime =
        ByTime(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
      let acrossPoint = FindCircleLinePoint(circle, afterxy);
      if (IsArcRangePoint(anchors[beginIndex], anchors[endIndex], circle, byTime, acrossPoint)) {
        let length = LengthArc1(anchors[beginIndex], anchors[endIndex], circle, byTime);
        let leftLength = LengthArc1(anchors[beginIndex], acrossPoint, circle, byTime);
        let scalesplit = leftLength / length;
        let angle = getAngleBy2Points(circle, acrossPoint);
        //streetObj.streetsplitpoint = [streetLine, scalesplit, angle];
        streetObj.groupdata[streetLine].splitpattem = [{ pattern: 'solid', startPct: scalesplit }];
      } else {
        streetObj.groupdata[streetLine].splitpattem = [];
      }
    }
    return true;
  } else if (functype.startsWith(StripeData[8].name + 'up')) {
    let objId = functype.split('||')[3];
    let streetLine = functype.split('||')[4];
    let streetObj = workData.getObject(objId);
    if (streetObj == undefined) return false;
    let anchors = streetObj.handlepoint;
    let beginIndex = GetStreetAreaBegin(anchors);
    let centerIndex = anchors[beginIndex][3].rightindex;
    let endIndex = anchors[centerIndex][3].rightindex;
    streetObj.selectflag = true;
    let circle = FindCircle(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]);
    if (IsZero(circle[2])) {
      let pointRange = GetTowPointRange(anchors[beginIndex], anchors[endIndex]);
      let acrossPoint = TowPointVerticalAcross(anchors[beginIndex], anchors[endIndex], afterxy);
      if (IsPointInRange1(acrossPoint, pointRange)) {
        streetObj.groupdata[streetLine].splitpattem = [
          {
            pattern: 'solid',
            startPct:
              LengthBetweenPoints(anchors[beginIndex], acrossPoint) /
              LengthBetweenPoints(anchors[beginIndex], anchors[endIndex]),
          },
        ];
      } else {
        streetObj.groupdata[streetLine].splitpattem = [];
      }
    } else {
      let byTime =
        ByTime(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
      let acrossPoint = FindCircleLinePoint(circle, afterxy);
      if (IsArcRangePoint(anchors[beginIndex], anchors[endIndex], circle, byTime, acrossPoint)) {
        let length = LengthArc1(anchors[beginIndex], anchors[endIndex], circle, byTime);
        let leftLength = LengthArc1(anchors[beginIndex], acrossPoint, circle, byTime);
        streetObj.groupdata[streetLine].splitpattem = [{ pattern: 'solid', startPct: leftLength / length }];
      } else {
        streetObj.groupdata[streetLine].splitpattem = [];
      }
    }
    return true;
  } else if (
    functype.startsWith(StripeData[0].name + 'move') ||
    functype.startsWith(StripeData[1].name + 'move') ||
    functype.startsWith(StripeData[2].name + 'move') ||
    functype.startsWith(StripeData[3].name + 'move') ||
    functype.startsWith(StripeData[4].name + 'move') ||
    functype.startsWith(StripeData[5].name + 'move') ||
    functype.startsWith(StripeData[6].name + 'move') ||
    functype.startsWith(StripeData[7].name + 'move')
  ) {
    let objId = functype.split('||')[3];
    let streetLine = functype.split('||')[4];
    let streetObj = workData.getObject(objId);
    if (streetObj == undefined) return false;
    let anchors = streetObj.handlepoint;
    let beginIndex = GetStreetAreaBegin(anchors);
    let centerIndex = anchors[beginIndex][3].rightindex;
    let endIndex = anchors[centerIndex][3].rightindex;
    let circle = FindCircle(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]);
    if (IsZero(circle[2])) {
      let pointRange = GetTowPointRange(anchors[beginIndex], anchors[endIndex]);
      let acrossPoint = TowPointVerticalAcross(anchors[beginIndex], anchors[endIndex], afterxy);
      if (IsPointInRange1(acrossPoint, pointRange)) {
        let splitPoint = GetCenter(
          anchors[beginIndex],
          anchors[endIndex],
          streetObj.groupdata[streetLine].splitpattem[0].startPct
        );
        let leftPointRange = GetTowPointRange(anchors[beginIndex], splitPoint);
        if (IsPointInRange1(acrossPoint, leftPointRange)) {
          streetObj.groupdata[streetLine].scalesplitleft = true;
        } else {
          streetObj.groupdata[streetLine].scalesplitleft = false;
        }
        return true;
      }
    } else {
      let byTime =
        ByTime(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
      let acrossPoint = FindCircleLinePoint(circle, afterxy);
      if (IsArcRangePoint(anchors[beginIndex], anchors[endIndex], circle, byTime, acrossPoint)) {
        let circle = FindCircle(anchors[beginIndex], anchors[endIndex], anchors[centerIndex]);
        let isByTime = IsByTime(anchors[beginIndex], anchors[centerIndex], anchors[endIndex]);
        let splitPoint = GetArcCenter(
          circle,
          anchors[beginIndex],
          anchors[endIndex],
          streetObj.groupdata[streetLine].splitpattem[0].startPct,
          isByTime
        );
        if (IsArcRangePoint(anchors[beginIndex], splitPoint, circle, byTime, acrossPoint)) {
          streetObj.groupdata[streetLine].scalesplitleft = true;
        } else {
          streetObj.groupdata[streetLine].scalesplitleft = false;
        }
        return true;
      }
    }
  }
  /*
  else if (
    functype.startsWith(StripeData[0].name + 'up') ||
    functype.startsWith(StripeData[1].name + 'up') ||
    functype.startsWith(StripeData[2].name + 'up') ||
    functype.startsWith(StripeData[3].name + 'up') ||
    functype.startsWith(StripeData[4].name + 'up') ||
    functype.startsWith(StripeData[5].name + 'up') ||
    functype.startsWith(StripeData[6].name + 'up') ||
    functype.startsWith(StripeData[7].name + 'up')
  ) {
  }
  */
  return false;
}

/**
 *
 * @deprecated
 * @param {*} functype
 * @param {*} afterxy
 */
export function StructureShapeData(functype, afterxy) {
  if (functype.startsWith(StructureData[0].name)) {
    workData.addData(FUNCTION_TYPE.STRUCTURE);
    let obj = workData.getCurrentOperateObject();
    const SPACE = 10; //原先 10*12
    obj.handlepoint[0] = [afterxy[0] - SPACE, afterxy[1] - SPACE, ANCHORS_TYPE.STRUCTUREBEGIN, AddStructureData()];
    obj.handlepoint[1] = [afterxy[0] - SPACE, afterxy[1] - SPACE, ANCHORS_TYPE.STRUCTUREEND, AddStructureData()];
    obj.style.stroke = COLOR_TYPE.BLACK;
    obj.style.strokewidth = 3;
    obj.marks.islength = true;
    obj.selectflag = true;
    obj = HandlePointStructure(obj, [afterxy[0] + SPACE, afterxy[1] - SPACE], 1);
    obj = HandlePointStructure(obj, [afterxy[0] + SPACE, afterxy[1] + SPACE], 1);
    obj = HandlePointStructure(obj, [afterxy[0] - SPACE, afterxy[1] + SPACE], 1);
    obj = HandlePointStructure(obj, [afterxy[0] - SPACE, afterxy[1] - SPACE], 1);
    obj = HandlePointStructure(obj, [afterxy[0] - SPACE, afterxy[1] - SPACE], 2);
    return true;
  } else if (functype.startsWith(StructureData[1].name)) {
    workData.addData(FUNCTION_TYPE.STRUCTURE);
    let obj = workData.getCurrentOperateObject();
    let index = workData.getLastObject(FUNCTION_TYPE.STRUCTURE);
    if (index < workData.getUseData().length - 1) {
      workData.deleteObject(obj.operateid);
      workData.insertObject(index, obj);
    }
    //30*40英尺
    const WIDTH = convertToStandard(30) / 2;
    const HEIGHT = convertToStandard(40) / 2;
    obj.handlepoint[0] = [afterxy[0] - WIDTH, afterxy[1] - HEIGHT, ANCHORS_TYPE.STRUCTUREBEGIN, AddStructureData()];
    obj.handlepoint[1] = [afterxy[0] + WIDTH, afterxy[1] - HEIGHT, ANCHORS_TYPE.STRUCTUREEND, AddStructureData()];
    obj.style.stroke = COLOR_TYPE.BLACK;
    obj.style.strokewidth = 3;
    obj.marks.islength = true;
    obj.selectflag = true;
    obj = HandlePointStructure(obj, [afterxy[0] + WIDTH, afterxy[1] - HEIGHT], 1);
    obj = HandlePointStructure(obj, [afterxy[0] + WIDTH, afterxy[1] + HEIGHT], 1);
    obj = HandlePointStructure(obj, [afterxy[0] - WIDTH, afterxy[1] + HEIGHT], 1);
    obj = HandlePointStructure(obj, [afterxy[0] - WIDTH, afterxy[1] - HEIGHT], 1);
    obj = HandlePointStructure(obj, [afterxy[0] - WIDTH, afterxy[1] - HEIGHT], 2);
    return true;
  } else if (functype.startsWith(StructureData[2].name + 'move')) {
    let objid = functype.split('||')[3];
    let lindex = Number(functype.split('||')[4]);
    let obj = workData.getObject(objid);
    StructureDoorMove(obj, lindex, afterxy, STRUCTURE_DOOR_TYPE.door32);
    return true;
  } else if (functype.startsWith(StructureData[3].name + 'move')) {
    let objid = functype.split('||')[3];
    let lindex = Number(functype.split('||')[4]);
    let obj = workData.getObject(objid);
    StructureDoorMove(obj, lindex, afterxy, STRUCTURE_DOOR_TYPE.door72);
    return true;
  } else if (functype.startsWith(StructureData[4].name + 'move')) {
    let objid = functype.split('||')[3];
    let lindex = Number(functype.split('||')[4]);
    let obj = workData.getObject(objid);
    StructureDoorMove(obj, lindex, afterxy, STRUCTURE_DOOR_TYPE.doubledoor16);
    return true;
  } else if (
    functype.startsWith(StructureData[2].name + 'finish') ||
    functype.startsWith(StructureData[3].name + 'finish') ||
    functype.startsWith(StructureData[4].name + 'finish')
  ) {
    let objid = functype.split('||')[3];
    let lindex = Number(functype.split('||')[4]);
    let obj = workData.getObject(objid);
    StructureDoorFinish(obj, lindex, afterxy);
    return true;
  }
  return false;
}

/**
 *
 * @param {string} functype
 * @param {[number, number]} afterxy
 */
export function LaneShoulderShapeData(functype, afterxy) {
  if (functype.startsWith(LanesShoulderData[7].name)) {
    //sidewalk
    workData.addData(FUNCTION_TYPE.SIDEWALK);
    let obj = workData.getCurrentOperateObject();
    obj.handlepoint[0] = [afterxy[0] - LANE_WIDTH, afterxy[1]];
    obj.handlepoint[1] = [afterxy[0] + LANE_WIDTH, afterxy[1]];
    obj.handlepoint[2] = afterxy;
    obj.selectflag = true;
    return true;
  }
  return false;
}

export function LaneMarkersShapeData(functype, afterxy) {
  if (functype.startsWith(LaneMarksData[0].name)) {
    //sidewalk
    workData.addData(FUNCTION_TYPE.LANEMARKER);
    let obj = workData.getCurrentOperateObject();
    obj.position.x1 = afterxy[0] - 61.98 / 2;
    obj.position.y1 = afterxy[1] - 42.77 / 2;
    obj.position.x2 = afterxy[0] + 61.98 / 2;
    obj.position.y2 = afterxy[1] + 42.77 / 2;
    HandlePointRect(obj);
    obj.style.fill = COLOR_TYPE.LIGHTTAN;
    obj.isleft = true;
    obj.selectflag = true;
    return true;
  }
  if (functype.startsWith(LaneMarksData[1].name)) {
    //sidewalk
    workData.addData(FUNCTION_TYPE.LANEMARKER);
    let obj = workData.getCurrentOperateObject();
    obj.position.x1 = afterxy[0] - 62.96 / 2;
    obj.position.y1 = afterxy[1] - 44.22 / 2;
    obj.position.x2 = afterxy[0] + 62.96 / 2;
    obj.position.y2 = afterxy[1] + 44.22 / 2;
    HandlePointRect(obj);
    obj.style.fill = COLOR_TYPE.LIGHTTAN;
    obj.isleft = false;
    obj.selectflag = true;
    return true;
  }
  return false;
}

export function IndicatorsShapeData(functype, afterxy) {
  if (functype.startsWith(IndicatorsArrowsData[0].name)) {
    //indicator arrow
    workData.addData(FUNCTION_TYPE.INDICATOR);
    let obj = workData.getCurrentOperateObject();
    obj.position.x1 = afterxy[0] - 145.01 / 2;
    obj.position.y1 = afterxy[1] - 145.01 / 2;
    obj.position.x2 = afterxy[0] + 145.01 / 2;
    obj.position.y2 = afterxy[1] + 145.01 / 2;
    HandlePointSquareCircle(obj);
    obj.style.fill = COLOR_TYPE.LIGHTTAN;
    obj.selectflag = true;
    obj.text.text = 'N';
    return true;
  }
  return false;
}

/**
 *
 * @param {*} data - one item in workData(useData)
 * @param {[number, number]} afterxy
 */
function initStreetNewData(data, afterxy) {
  if (!workData.hasStreet('paved')) {
    workData.addData('StreetSurface');
    let obj = workData.getCurrentOperateObject();
    let index = workData.getLastObject('StreetSurface');
    if (index != workData.getUseData().length) {
      workData.deleteObject(obj.operateid);
      workData.insertObject(index, obj);
    }
    obj.surfaceType = 'paved';
    obj.sfills = [];
    obj.crfills = [];
  }
  workData.addData('StreetNew');
  let obj = workData.getCurrentOperateObject();
  let index = workData.getLastObject('StreetNew');
  if (index != workData.getUseData().length) {
    workData.deleteObject(obj.operateid);
    workData.insertObject(index, obj);
  }
  obj.streetoffarc = data.offsetArcPath || obj.streetoffarc;
  obj.transitionLengthFactor = data.transitionLengthFactor || obj.transitionLengthFactor;
  if (data.stopOffset) obj.stopOffset = data.stopOffset;
  obj.transitionPercentFromStart = data.transitionPercentFromStart || obj.transitionPercentFromStart;
  obj.transitionRadius = data.transitionRadius || obj.transitionRadius;
  obj.segments = data.segments || obj.segments;
  if (data.offsetArcPath) obj.offsetArcPath = data.offsetArcPath;
  if (data.streetType) obj.streetType = data.streetType;
  obj.isShowDividerBackground = true;
  obj.isShowShoulderBackground = true;

  obj.components = data.components(obj);
  obj.strokePattern = 'solid';
  obj.key = 1;
  obj.nextKey = 6;
  obj.lanewidth = LANE_WIDTH;
  obj.handlepoint = [afterxy[0], afterxy[1]];
  obj.style.strokewidth = 4;
  obj.selectflag = true;
  for (let idx = 0; idx < obj.groupdata.length; idx++) {
    obj.groupdata[idx].strokewidth = 4;
  }
  if (utility.isNonEmptyArray(obj.segments)) {
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    _s.computeStreets(obj);
  }
}

function initCrosswalkData({ position }) {
  workData.addData(FUNCTION_TYPE.CROSSWALK);
  let obj = workData.getCurrentOperateObject();
  obj.style.pattern = CROSS_WALK_PATTERN.VERTICAL;
  obj.style.strokewidth = 8;
  obj.position = position;
  obj = HandlePointCrosswalk(obj);
  obj.selectflag = true;
}

/**
 *
 * @param {*} data
 * @param {*} afterxy
 */
function initDirtData(data, afterxy) {
  workData.addData(FUNCTION_TYPE.DIRT);
  let obj = workData.getCurrentOperateObject();
  let index = workData.getLastObject(FUNCTION_TYPE.DIRT);
  if (index != workData.getUseData().length) {
    workData.deleteObject(obj.operateid);
    workData.insertObject(index, obj);
  }
  obj.position = data.position;
  obj = HandlePointStreet(obj);
  obj.groupdata[0].stroke = COLOR_TYPE.DARKTAN;
  obj.groupdata[0].strokewidth = 2;
  obj.groupdata[1].stroke = COLOR_TYPE.DARKTAN;
  obj.groupdata[1].strokewidth = 2;
  obj.lanes = [{ width: LANE_WIDTH, travelDir: 'normal' }, { width: LANE_WIDTH, travelDir: 'normal' }];
  obj.stripes = [
    { stripe: { patterns: [{ pattern: 'irregular' }] } },
    { stripe: { patterns: [{ pattern: 'invisible' }] } },
    { stripe: { patterns: [{ pattern: 'irregular' }] } },
  ];
  obj.lanewidth = STREET_SPACE.HEIGHT;
  obj.surfaceType = 'dirt';
  obj.selectflag = true;
  obj.style.strokewidth = 4;
  for (let idx = 0; idx < obj.groupdata.length; idx++) {
    obj.groupdata[idx].strokewidth = 4;
  }
  HandlePointStreetByNumWidth(obj);
  handleDirtAcross(obj.operateid);
}

function initGravelData(data, afterxy) {
  workData.addData(FUNCTION_TYPE.GRAVEL);
  let obj = workData.getCurrentOperateObject();
  let index = workData.getLastObject(FUNCTION_TYPE.GRAVEL);
  if (index != workData.getUseData().length) {
    workData.deleteObject(obj.operateid);
    workData.insertObject(index, obj);
  }
  obj.position = data.position;
  obj = HandlePointStreet(obj);
  obj.groupdata[0].stroke = COLOR_TYPE.MEDGRAY;
  obj.groupdata[0].strokewidth = 2;
  obj.groupdata[1].stroke = COLOR_TYPE.MEDGRAY;
  obj.groupdata[1].strokewidth = 2;
  obj.lanes = [{ width: LANE_WIDTH, travelDir: 'normal' }, { width: LANE_WIDTH, travelDir: 'normal' }];
  obj.stripes = [
    { stripe: { patterns: [{ pattern: 'irregular' }] } },
    { stripe: { patterns: [{ pattern: 'invisible' }] } },
    { stripe: { patterns: [{ pattern: 'irregular' }] } },
  ];
  obj.lanewidth = STREET_SPACE.HEIGHT;
  obj.surfaceType = 'gravel';
  obj.selectflag = true;
  obj.style.strokewidth = 4;
  for (let idx = 0; idx < obj.groupdata.length; idx++) {
    obj.groupdata[idx].strokewidth = 4;
  }
  HandlePointStreetByNumWidth(obj);
  handleGravelAcross(obj.operateid);
}

function initParkingStallData(data) {
  workData.addData(FUNCTION_TYPE.PARKINGSTALLS);
  let obj = workData.getCurrentOperateObject();
  obj.marks.length = PARK_STALL_SPACE.PARKWIDTH;
  obj.marks.height = PARK_STALL_SPACE.HEIGHT;
  obj.style.pattern = PARK_STALL_PATTERN_TYPE.STANDARD;
  obj.style.strokewidth = 4;
  obj.rotateangle = 0;
  obj.position = data.position;
  obj.selectflag = true;
  HandlePointParkStall(obj);
}

// @side-effect
/**
 * @description 初始化 Street 相关 shape, 包含 StreetNew, CrossWalk, Dirts, Gravels, ParkingStalls
 * init street with symbol (instead of drawing)
 * @param {string} symbolKey - selectedSymbolKey in symbolBar reducer
 * @param {[number, number]} point
 * @param {string} streetId - such as 's1','s2',etc.
 */
export function initStreet(symbolKey, point, streetId) {
  let streetNewsData = [
    {
      //name: streetSymbols[0].name,
      segments: [
        {
          type: 'line',
          ptStart: { x: point[0], y: point[1] - STREET_LEN / 2 },
          ptStop: { x: point[0], y: point[1] + STREET_LEN / 2 },
        },
      ],
      components: obj => {
        const segments = obj.segments;
        return [
          createStripe(1, segments),
          createLane({ key: 2 }),
          createStripe(3, segments, 'singledash', true),
          createLane({ key: 4 }),
          createStripe(5, segments),
        ];
      },
      streetType: STREET_TYPE.STRAIGHT,
      symbolKey: 'PavedNS',
    },
    {
      //name: streetSymbols[1].name,
      segments: [
        {
          type: 'line',
          ptStart: { x: point[0] - STREET_LEN / 2, y: point[1] },
          ptStop: { x: point[0] + STREET_LEN / 2, y: point[1] },
        },
      ],
      components: obj => {
        const segments = obj.segments;
        return [
          createStripe(1, segments),
          createLane({ key: 2 }),
          createStripe(3, segments, 'singledash', true),
          createLane({ key: 4 }),
          createStripe(5, segments),
        ];
      },
      streetType: STREET_TYPE.STRAIGHT,
      symbolKey: 'PavedEW',
    },
    {
      //name: streetSymbols[2].name,
      segments: [
        {
          type: 'line',
          ptStart: { x: point[0], y: point[1] - STREET_LEN / 2 },
          ptStop: { x: point[0], y: point[1] + STREET_LEN / 2 },
        },
      ],
      components: obj => {
        const segments = obj.segments;
        return [
          createStripe(1, segments),
          createLane({ key: 2 }),
          createStripe(3, segments, 'invisible', true),
          createLane({ key: 4 }),
          createStripe(5, segments),
        ];
      },
      streetType: STREET_TYPE.STRAIGHT,
      symbolKey: 'PavedUnstripedNS',
    },
    {
      //name: streetSymbols[3].name,
      segments: [
        {
          type: 'line',
          ptStart: { x: point[0] - STREET_LEN / 2, y: point[1] },
          ptStop: { x: point[0] + STREET_LEN / 2, y: point[1] },
        },
      ],
      components: obj => {
        const segments = obj.segments;
        return [
          createStripe(1, segments),
          createLane({ key: 2 }),
          createStripe(3, segments, 'invisible', true),
          createLane({ key: 4 }),
          createStripe(5, segments),
        ];
      },
      streetType: STREET_TYPE.STRAIGHT,
      symbolKey: 'PavedUnstripedEW',
    },
    {
      //name: streetSymbols[4].name,
      streetoffarc: true,
      transitionLengthFactor: 1.33,
      stopOffset: 77.88,
      transitionPercentFromStart: 0.42,
      transitionRadius: 207.15,
      segments: [
        {
          type: 'line',
          ptStart: { x: point[0] - 438, y: point[1] },
          ptStop: { x: point[0] + 438.02, y: point[1] },
        },
      ],
      offsetArcPath: {
        segments: [
          {
            type: 'line',
            ptStart: { x: point[0] - 438, y: point[1] - 77.87 },
            ptStop: { x: point[0] - 228.94, y: point[1] - 77.87 },
          },
          {
            type: 'arc',
            ptStart: { x: point[0] - 228.94, y: point[1] - 77.87 },
            ptStop: { x: point[0] - 67.09, y: point[1] },
            r: 207.15,
            largeArcFlag: false,
            sweepFlag: true,
          },
          {
            type: 'arc',
            ptStart: { x: point[0] - 67.09, y: point[1] },
            ptStop: { x: point[0] + 94.77, y: point[1] + 77.87 },
            r: 207.15,
            largeArcFlag: false,
            sweepFlag: false,
          },
          {
            type: 'line',
            ptStart: { x: point[0] + 94.77, y: point[1] + 77.87 },
            ptStop: { x: point[0] + 438.02, y: point[1] + 77.87 },
          },
        ],
      },
      // offsetArcPath: obj => {
      //   const segments = obj.segments;
      //   return [
      //     createLine(1)
      //   ]
      // },
      components: obj => {
        const segments = obj.segments;
        return [
          createStripe(1, segments),
          createLane({ key: 2 }),
          createStripe(3, segments, 'singledash', true),
          createLane({ key: 4 }),
          createStripe(5, segments),
        ];
      },
      streetType: STREET_TYPE.STRAIGHT,
      symbolKey: 'Ramp',
    },
    {
      // name: streetSymbols[5].name,
      segments: [
        createArc({
          ptStart: { x: point[0], y: point[1] },
          ptStop: { x: point[0] + STREET_LEN / 2, y: point[1] - STREET_LEN / 2 },
        }),
        createArc({
          ptStart: { x: point[0] + STREET_LEN / 2, y: point[1] - STREET_LEN / 2 },
          ptStop: { x: point[0], y: point[1] - STREET_LEN },
        }),
        createArc({
          ptStart: { x: point[0], y: point[1] - STREET_LEN },
          ptStop: { x: point[0] - STREET_LEN / 2, y: point[1] - STREET_LEN / 2 },
        }),
        createArc({
          ptStart: { x: point[0] - STREET_LEN / 2, y: point[1] - STREET_LEN / 2 },
          ptStop: { x: point[0], y: point[1] },
        }),
      ],
      components: obj => {
        const segments = obj.segments;
        return [
          createStripe(1, segments),
          createLane({ key: 2 }),
          createStripe(3, segments, 'singledash', true),
          createLane({ key: 4 }),
          createStripe(5, segments),
        ];
      },
      streetType: STREET_TYPE.ROUNDABOUT,
      symbolKey: 'Roundabout',
    },
  ];
  let crosswalksData = [
    {
      //name: streetSymbols[5].name,
      position: {
        x1: point[0] - LANE_WIDTH,
        y1: point[1],
        x2: point[0] + LANE_WIDTH,
        y2: point[1],
      },
    },
  ];
  let dirtsData = [
    {
      //name: streetSymbols[6].name,
      position: {
        x1: point[0],
        y1: point[1] - STREET_LEN / 2,
        x2: point[0],
        y2: point[1] + STREET_LEN / 2,
      },
    },
    {
      //name: streetSymbols[7].name,
      position: {
        x1: point[0] - STREET_LEN / 2,
        y1: point[1],
        x2: point[0] + STREET_LEN / 2,
        y2: point[1],
      },
    },
  ];
  let gravelsData = [
    {
      //name: streetSymbols[8].name,
      position: {
        x1: point[0],
        y1: point[1] - STREET_LEN / 2,
        x2: point[0],
        y2: point[1] + STREET_LEN / 2,
      },
    },
    {
      //name: streetSymbols[9].name,
      position: {
        x1: point[0] - STREET_LEN / 2,
        y1: point[1],
        x2: point[0] + STREET_LEN / 2,
        y2: point[1],
      },
    },
  ];
  let parkingStallsData = [
    {
      //name: streetSymbols[10].name,
      position: {
        x1: point[0] - STREET_LEN / 2,
        y1: point[1],
        x2: point[0] + STREET_LEN / 2,
        y2: point[1],
      },
    },
  ];

  // TODO: 此处能否获取菜单项的属性，对 shape 类型进行判断渲染？
  const streetTmpl = streetNewsData.find(n => n.symbolKey === symbolKey);
  if (streetTmpl) {
    initStreetNewData(streetTmpl, point);
  } else if (symbolKey === 'RoadCrosswalk') {
    if (streetId) {
      // crosswalk across a street
      AcrosswalkStreet(streetId, point);
    } else {
      initCrosswalkData(crosswalksData[0], point);
    }
  } else if (symbolKey === 'DirtNS') {
    initDirtData(dirtsData[0], point);
  } else if (symbolKey === 'DirtEW') {
    initDirtData(dirtsData[1], point);
  } else if (symbolKey === 'GravelNS') {
    initGravelData(gravelsData[0], point);
  } else if (symbolKey === 'GravelEW') {
    initGravelData(gravelsData[1], point);
  } else if (symbolKey === 'ParkingStalls') {
    initParkingStallData(parkingStallsData[0], point);
  }
}

// TODO: may move logics to work data adapter?
/**
 * 用来根据 Measurements 分组下的 Symbol 创建对象
 * 原先方法名称为 `MeasurementShapeData`
 * @param {object} param0
 * @param {string} param0.functype
 * @param {string} param0.type
 * @param {[number, number]} point
 */
export function initMeasurementData({ functype, type }, point) {
  // const [x, y] = point;
  switch (functype) {
    case FUNCTION_TYPE.STATIONLINE: {
      workData.addData(FUNCTION_TYPE.STATIONLINE);
      let obj = workData.getCurrentOperateObject();
      obj.type = type;
      obj.position.x1 = point[0];
      obj.position.y1 = point[1];
      obj.position.x2 = point[0];
      obj.position.y2 = point[1];
      HandlePointMeasurement(obj);
      obj.style.stroke = COLOR_TYPE.LNCD_MAIN_COLOR;
      obj.style.fill = COLOR_TYPE.LNCD_MAIN_COLOR;
      obj.style.strokewidth = 3;
      obj.text.size = MEASUREMENT_TEXT_SIZE;
      obj.text.color = COLOR_TYPE.BLACK;
      obj.selectflag = true;
      obj.lenZeroHead = STATION_LONG;
      obj.lenZeroTail = STATION_SHORT;
      obj.rotation = 0;
      break;
    }
    case FUNCTION_TYPE.XYMEASUREMENT: {
      workData.addData(FUNCTION_TYPE.XYMEASUREMENT);
      let obj = workData.getCurrentOperateObject();
      obj.type = type;
      obj.position.x1 = point[0];
      obj.position.y1 = point[1];
      obj.position.x2 = point[0];
      obj.position.y2 = point[1];
      HandlePointMeasurement(obj);
      obj.style.stroke = COLOR_TYPE.LNCD_MAIN_COLOR;
      obj.style.fill = COLOR_TYPE.LNCD_MAIN_COLOR;
      obj.style.strokewidth = 3;
      obj.text.size = MEASUREMENT_TEXT_SIZE;
      obj.text.color = COLOR_TYPE.BLACK;
      obj.selectflag = true;
      obj.lenZeroHead = STATION_LONG;
      obj.lenZeroTail = STATION_SHORT;
      obj.rotation = 0;
      break;
    }
    case FUNCTION_TYPE.TRIANGULATIONNETWORK: {
      //Triangulation Network
      let obj = null;
      var tn = document.getElementById('TriangulationNetwork');
      if (tn == null) {
        workData.addData(FUNCTION_TYPE.TRIANGULATIONNETWORK);
        obj = workData.getCurrentOperateObject();
        obj.referencePoints = [[point[0], point[1]]];
        obj.referencePointsText = ['A'];
      } else {
        obj = workData.getObject(tn.parentNode.id);
        obj.referencePoints.push([point[0], point[1]]);
        obj.referencePointsText.push(String.fromCharCode(65 + obj.referencePoints.length - 1));
      }
      obj.position.x1 = point[0];
      obj.position.y1 = point[1];
      obj.position.x2 = point[0];
      obj.position.y2 = point[1];
      HandlePointTriangulationNetwork(obj);
      obj.style.stroke = COLOR_TYPE.LNCD_MAIN_COLOR;
      obj.selectflag = true;
      break;
    }
    default:
      break;
  }
}
