import React from 'react';
import {
  LengthBetweenPoints,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  TowPointVerticalLengthByStatus,
  RotatePoint,
} from '@src/data/CommonFunc';
import Text from '../Text';
import { GetPathArcD, GetPathArcD1, GetPathLineD, GetPathLineD1, GetShapes } from '@src/data/BusinessFun';
import { GetShapeCircle } from '../ShapeFun';
import { COLOR_TYPE, CIRCLE_SIZE_TYPE, TEXT_ANCHORS_TYPE } from '@src/constant';

class Indicator extends React.Component {
  constructor(props) {
    super(props);
  }
  getText = (shapes, object, size) => {
    var textShape = null;
    let anchors = object.handlepoint;
    let width = LengthBetweenPoints(anchors[0], anchors[3]);
    var shapecenter = TowPointVerticalLengthByStatus(anchors[3], anchors[2], anchors[5], width / 2 - size / 2, false); //GetCenterInTwoPoints(object.handlepoint[0],object.handlepoint[2]);
    let center = GetCenterInTwoPoints(anchors[0], anchors[2]);
    var angle = 0;
    object.text.size = size;
    textShape = (
      <Text
        angle={angle}
        key={'text'}
        x={center[0]}
        y={center[1] + size / 2}
        textAnchor={TEXT_ANCHORS_TYPE.MIDDLE}
        text={object.text}
        content={object.text.text}
      />
    );
    shapes.push(
      GetShapeCircle([center[0], center[1], size / 2], 'textcircle', true, {
        stroke: COLOR_TYPE.WHITE,
        fill: COLOR_TYPE.WHITE,
      })
    );
    shapes.push(textShape);
  };
  getArrowHead = (D, point, length, angle) => {
    var top = [point[0], point[1] - length];
    var left = [point[0] - length, point[1] + length];
    var right = [point[0] + length, point[1] + length];
    top = RotatePoint(top, angle, point);
    left = RotatePoint(left, angle, point);
    right = RotatePoint(right, angle, point);
    GetPathLineD(D, top, left);
    GetPathArcD1(D, right, 31.17, 0, 1);
    GetPathLineD1(D, right);
    D.push('Z');
  };
  getArrowBody = (D, p1, p2, width) => {
    let firstPoint = TowPointVerticalLengthByStatus(p1, p2, p1, width / 2, false);
    let secondPoint = TowPointVerticalLengthByStatus(p1, p2, p2, width / 2, false);
    let thirdPoint = TowPointVerticalLengthByStatus(p1, p2, p2, width / 2, true);
    let fourPoint = TowPointVerticalLengthByStatus(p1, p2, p1, width / 2, true);
    GetPathLineD(D, firstPoint, secondPoint);
    GetPathLineD1(D, thirdPoint);
    GetPathLineD1(D, fourPoint);
    D.push('Z');
  };
  getShape = (shapes, object) => {
    let anchors = object.handlepoint;
    const rx = LengthBetweenPoints(anchors[6], anchors[7]) / 2;
    const ry = LengthBetweenPoints(anchors[4], anchors[5]) / 2;
    var D = [];
    GetPathArcD(D, anchors[6], anchors[7], rx, CIRCLE_SIZE_TYPE.SMALLARC, '1');
    GetPathArcD1(D, anchors[6], rx, CIRCLE_SIZE_TYPE.SMALLARC, '1');
    shapes.push(<path d={D.join(' ')} key={'circle'} fill={COLOR_TYPE.WHITE} />);
    let bottomLength = (rx * 2 * 1) / 20;
    let firstPoint = TowPointVerticalLengthByStatus(anchors[3], anchors[2], anchors[5], bottomLength, false);
    let topLength = (rx * 2 * 3) / 4;
    let secondPoint = TowPointVerticalLengthByStatus(anchors[3], anchors[2], anchors[5], topLength, false);
    let triangleLength = (rx * 2 * 1) / 9;
    let triangleCenterLength = topLength + triangleLength;
    let thirdPoint = TowPointVerticalLengthByStatus(anchors[3], anchors[2], anchors[5], triangleCenterLength, false);
    let D2 = [];
    let center = GetCenterInTwoPoints(anchors[0], anchors[2]);
    let angle = getAngleBy2Points(center, anchors[8]);
    // this.getArrowHead(D2, thirdPoint, triangleLength, angle);
    // this.getArrowBody(D2, firstPoint, secondPoint, (rx * 2 * 1) / 32);
    this.getArrowHead(D2, thirdPoint, triangleLength, angle);
    this.getArrowBody(D2, firstPoint, secondPoint, (rx * 2 * 1) / 32);
    shapes.push(<path d={D2.join(' ')} key={'arrow'} />);
    this.getText(shapes, object, (rx * 2 * 1) / 4);
  };
  render() {
    const { props } = this;
    const { object } = props;
    if (object.handlepoint.length < 8) return null;
    var anchors = object.handlepoint;

    let shapes = [];
    this.getShape(shapes, object);

    return <g id={object.functype}>{GetShapes(shapes)}</g>;
  }
}
export default Indicator;
