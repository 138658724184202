import * as React from 'react';

function SvgIconPathRect(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 -1)" stroke="currentColor" strokeWidth={2}>
          <rect x={3} y={15} width={4} height={4} rx={1} />
          <rect x={3} y={5} width={4} height={4} rx={1} />
          <rect x={17} y={15} width={4} height={4} rx={1} />
          <rect x={17} y={5} width={4} height={4} rx={1} />
          <path d="M19 10v4m-3 3H8m-3-3v-4m2-3h9" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconPathRect;
