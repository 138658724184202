import React from 'react';
import { LengthBetweenPoints, getAngleBy2Points, GetCenterInTwoPoints } from '@src/data/CommonFunc';
import Text from '../Text';
import { TEXT_ANCHORS_TYPE } from '@src/constant';

class Circle extends React.Component {
  constructor(props) {
    super(props);
  }
  getText = object => {
    var textShape = null;
    var shapecenter = GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[2]);
    var textPoint = [shapecenter[0], shapecenter[1] + object.text.size / 2];
    var angle = (object.rotateangle / Math.PI) * 180;
    textShape = (
      <Text
        angle={angle}
        x={textPoint[0]}
        y={textPoint[1]}
        textAnchor={TEXT_ANCHORS_TYPE.MIDDLE}
        text={object.text}
        content={object.text.text}
      />
    );
    return textShape;
  };
  render() {
    const { props } = this;
    const { object } = props;

    if (object.handlepoint.length < 8) return null;
    var anchors = object.handlepoint;
    const cx = (anchors[0][0] + anchors[2][0]) / 2;
    const cy = (anchors[0][1] + anchors[2][1]) / 2;
    const rx = LengthBetweenPoints(anchors[6], anchors[7]) / 2;
    const ry = LengthBetweenPoints(anchors[4], anchors[5]) / 2;
    let getDPath1 = (anchors, cx, cy, rx, ry) => {
      var D = [];
      var angleD = getAngleBy2Points(anchors[6], anchors[7]);
      angleD = (angleD * 180) / Math.PI;
      D.push('M' + anchors[6][0].toString() + ',' + anchors[6][1].toString());
      D.push('A' + rx.toString() + ',' + ry.toString());
      D.push(angleD.toString());
      D.push('0,0');
      D.push(anchors[7][0].toString() + ',' + anchors[7][1].toString());
      D.push('A' + rx.toString() + ',' + ry.toString());
      D.push(angleD.toString());
      D.push('0,0');
      D.push(anchors[6][0].toString() + ',' + anchors[6][1].toString());
      return D;
    };
    let getDPath2 = (anchors, cx, cy, rx, ry) => {
      var D = [];
      var angleD = getAngleBy2Points(anchors[6], anchors[7]);
      angleD = (angleD * 180) / Math.PI;
      D.push('M' + anchors[6][0].toString() + ',' + anchors[6][1].toString());
      D.push('A' + rx.toString() + ',' + ry.toString());
      D.push(angleD.toString());
      D.push('0,1');
      D.push(anchors[7][0].toString() + ',' + anchors[7][1].toString());

      return D;
    };
    return (
      <g lncd-role={object.functype}>
        <metadata sstrokepattern={object.style.strokepattern} />
        <path d={getDPath1(anchors, cx, cy, rx, ry).join(' ')} />
        {this.getText(object)}
      </g>
    );
  }
}
export default Circle;
