import React, { Component } from 'react';
import { Icon, Menu, Dropdown } from 'antd';
import { IconDown, IconGrassDivider, IconCementDivider } from '@src/components/UI/Icons';
import { IconTwoWayLeft, IconWideLane, IconPaintedDivider } from '@src/components/UI/Icons';
import { STREET_DIVIDER_TYPE } from '@src/constant';
import { withTranslation } from 'react-i18next';

class StreetDividerSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.t(`property.laneDivider.${props.type}`),
    };
    this.menuItems = [
      {
        key: STREET_DIVIDER_TYPE.NONE,
        value: this.props.t(`property.laneDivider.${STREET_DIVIDER_TYPE.NONE}`),
      },
      {
        key: STREET_DIVIDER_TYPE.TWO_WAY,
        value: this.props.t(`property.laneDivider.${STREET_DIVIDER_TYPE.TWO_WAY}`),
        icon: <Icon component={IconTwoWayLeft} />,
      },
      {
        key: STREET_DIVIDER_TYPE.WIDE,
        value: this.props.t(`property.laneDivider.${STREET_DIVIDER_TYPE.WIDE}`),
        icon: <Icon component={IconWideLane} />,
      },
      {
        key: STREET_DIVIDER_TYPE.PAINTED,
        value: this.props.t(`property.laneDivider.${STREET_DIVIDER_TYPE.PAINTED}`),
        icon: <Icon component={IconPaintedDivider} />,
      },
      {
        key: STREET_DIVIDER_TYPE.GRASS,
        value: this.props.t(`property.laneDivider.${STREET_DIVIDER_TYPE.GRASS}`),
        icon: <Icon component={IconGrassDivider} />,
      },
      {
        key: STREET_DIVIDER_TYPE.CEMENT,
        value: this.props.t(`property.laneDivider.${STREET_DIVIDER_TYPE.CEMENT}`),
        icon: <Icon component={IconCementDivider} />,
      },
    ];
  }

  handleClick = e => {
    if (this.props.handle) {
      this.props.handle(e.key);
    }
    this.setState({ type: e.item.props.value });
  };

  render() {
    const { disabled = false } = this.props;

    const shapes = (
      <Menu className="reg-no-select prop-menu-select-overlay" onClick={this.handleClick.bind(this)}>
        {this.menuItems.map(o => (
          <Menu.Item key={o.key} value={o.value}>
            {o.icon}
            {o.value}
          </Menu.Item>
        ))}
      </Menu>
    );
    return (
      <Dropdown className="custom-dropdown" overlay={shapes} trigger={['click']} disabled={disabled}>
        <div className="custom-dropdown-item">
          <span>{this.state.type}</span>
          <Icon component={IconDown} />
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(StreetDividerSelect);
