import { getUserUnitByPixel } from '../data/CommonFunction';
import { HANDLE_RADIUS, FUNCTION_TYPE, COLOR_TYPE, FONT_SIZE, SPOT_WIDTH } from '../constant';
import { unifyPoint } from './shape';
import * as workData from '@src/data/WorkData';
import { svgAsDataUri, saveSvgAsPng, svgAsPngUri } from 'save-svg-as-png';
import styles from '@src/styles/palette.scss';
import * as jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { RotatePoint } from '@src/data/CommonFunc';
import GeomPoint from '@src/data/GeomPoint';
import { applyToPoint, inverse } from 'transformation-matrix';

/**
 * @typedef shapeMapping
 * @property {FUNCTION_TYPE[]} draw - 简单图形的类型, 包含circle, line, square等.
 * @property {FUNCTION_TYPE[]} street - 道路的类型，包含street surface, street new等.
 * @property {FUNCTION_TYPE[]} textbox - textbox的类型, 包含textbox, callout.
 * @property {FUNCTION_TYPE[]} symbol - symbol 的类型
 * @property {FUNCTION_TYPE[]} image - image 的类型
 * @property {FUNCTION_TYPE[]} marker - marker 的类型
 * @property {FUNCTION_TYPE[]} measurement - measurement 的类型，包含station line, triangulation network等.
 */
/** @type {shapeMapping} */
export const shapeMapping = {
  draw: [
    FUNCTION_TYPE.STRUCTURE,
    FUNCTION_TYPE.CIRCLE,
    FUNCTION_TYPE.LINE,
    FUNCTION_TYPE.SQUARE,
    FUNCTION_TYPE.RECTANGLE,
    FUNCTION_TYPE.CLOSEDSHAPE,
    FUNCTION_TYPE.ARC,
    FUNCTION_TYPE.CONNECTEDLINES,
    FUNCTION_TYPE.ELLIPSE,
    FUNCTION_TYPE.STAIRS,
    FUNCTION_TYPE.DIMENSIONLINE,
  ],
  street: [
    FUNCTION_TYPE.STREETSURFACE,
    FUNCTION_TYPE.STREETNEW,
    FUNCTION_TYPE.PARKINGSTALLS,
    FUNCTION_TYPE.CROSSWALK,
    FUNCTION_TYPE.DIRT,
    FUNCTION_TYPE.GRAVEL,
    FUNCTION_TYPE.CURBRETURN,
    FUNCTION_TYPE.STRIPE,
  ],
  textbox: [FUNCTION_TYPE.TEXTBOX, FUNCTION_TYPE.CALLOUT],
  symbol: [FUNCTION_TYPE.SYMBOL],
  // group: [FUNCTION_TYPE.GROUP],
  image: [FUNCTION_TYPE.IMAGE],
  marker: [FUNCTION_TYPE.MARKER],
  measurement: [FUNCTION_TYPE.STATIONLINE, FUNCTION_TYPE.TRIANGULATIONNETWORK, FUNCTION_TYPE.XYMEASUREMENT],
};
export const allShapeCategories = Object.keys(shapeMapping);

function selectShapeFromCategory(categories = allShapeCategories) {
  let shapes = [];
  categories.forEach(category => {
    shapes.push(...shapeMapping[category]);
  });
  return shapes;
}

/**
 * returns path data joining passed points
 * @param {Array} points an array of points
 */
export function getPolygonPathData(points) {
  const arr = [];
  for (let i = 0; i < points.length; i++) {
    const [x, y] = points[i];
    const v = i === 0 ? 'M' : 'L';
    arr.push(`${v}${x},${y}`);
  }
  return `${arr.join(' ')}Z`;
}

/**
 *
 * @param {GeomPoint} point1
 * @param {GeomPoint} point2
 * @returns {string}
 */
export function getLinePathData(point1, point2) {
  return `M${unifyPoint(point1).join(',')} L${unifyPoint(point2).join(',')}`;
}

/**
 * @returns {number} size as SVG length, half of rect handle point length
 */
export function getHandlePointSize() {
  return getUserUnitByPixel() * HANDLE_RADIUS;
}

/**
 * @returns {number} default font size for measurement label and point text, etc.
 */
export const getDefaultFontSize = () => getUserUnitByPixel() * FONT_SIZE;

/**
 * 加号的单边宽度
 * @returns {number} half of the cross width to mark a spot for measurement.
 */
export const getSpotCrossWidth = () => getUserUnitByPixel() * SPOT_WIDTH;

/**
 * @param {GeomPoint|number[]} point
 * @returns {string} SVG path data
 */
export const getSpotCrossPathData = point => {
  const [x, y] = unifyPoint(point);
  const center = new GeomPoint(x, y);
  const size = getSpotCrossWidth();

  const a = center.clone();
  a.x -= size;
  const b = center.clone();
  b.x += size;
  const c = center.clone();
  c.y += size;
  const d = center.clone();
  d.y -= size;

  return `${getLinePathData(a, b)} ${getLinePathData(c, d)}`;
};

/**
 * @returns {number} offset of a rect handle point shape to the coords position
 */
export function getHandlePointOffset() {
  return Math.sqrt(2) * getHandlePointSize();
}

/**
 *
 * @param {array} data
 * @param {string} key - operateId
 */
const findIndexByKey = (data, key) => data.findIndex(dataItem => dataItem.key === key);
const findLastTypeIndex = (data, type) => {
  let index = -1;
  data.forEach((item, itemIndex) => {
    if (item.functype === type) {
      index = itemIndex;
    }
  });
  return index;
};

/**
 * re generate operateId param for useData items
 * @param {[]} addData - useData
 */
export function reGenerateOperateIdForUseData(addData) {
  if (0 === addData.length) {
    return;
  }
  const addDataStreetSurfaceIndex = findLastTypeIndex(addData, 'StreetSurface');
  // Generate new operate id for each newly added data item
  addData.forEach(addItem => {
    const newOperateId = workData.generateOperateId();
    let sfillsIndex, crfillsIndex;
    switch (addItem.functype) {
      case FUNCTION_TYPE.STREETNEW: {
        // Update related curbreturn streetid
        addData.forEach(data => {
          if (FUNCTION_TYPE.CURBRETURN === data.functype && data.streetStripes && data.streetStripes.length > 0) {
            data.streetStripes.forEach(street => {
              if (street.idStreet === addItem.operateid) {
                street.idStreet = newOperateId;
              }
            });
          }
        });
        const sfills = addData[addDataStreetSurfaceIndex].sfills;
        if (sfills instanceof Array && sfills.length > 0) {
          sfillsIndex = findIndexByKey(sfills, addItem.operateid);
          if (sfillsIndex !== -1) {
            sfills[sfillsIndex].key = newOperateId;
          }
        }
        break;
      }
      case FUNCTION_TYPE.CURBRETURN: {
        const crfills = addData[addDataStreetSurfaceIndex].crfills;
        if (crfills instanceof Array && crfills.length > 0) {
          crfillsIndex = findIndexByKey(addData[addDataStreetSurfaceIndex].crfills, addItem.operateid);
          if (crfillsIndex !== -1) {
            crfills[crfillsIndex].key = newOperateId;
          }
        }
        break;
      }
    }
    addItem.operateid = newOperateId;
  });
}

/**
 *
 * @param {[]} addData - useData
 */
export function crossAddUseDataIntoWorkData(addData) {
  addData.forEach(addItem => {
    const useData = workData.getUseData();
    let itemLastIndexInWorkData = findLastTypeIndex(useData, addItem.functype);
    switch (addItem.functype) {
      case FUNCTION_TYPE.STREETSURFACE: {
        if (-1 === itemLastIndexInWorkData) {
          workData.sliceObject(0, addItem);
        } else {
          useData[itemLastIndexInWorkData].sfills.push(...addItem.sfills);
          useData[itemLastIndexInWorkData].crfills.push(...addItem.crfills);
        }
        break;
      }
      case FUNCTION_TYPE.STREETNEW: {
        itemLastIndexInWorkData = itemLastIndexInWorkData === -1 ? 0 : itemLastIndexInWorkData;
        if (typeof addItem.roadLayer === 'undefined') {
          addItem.roadLayer = 0;
        }
        workData.sliceObject(itemLastIndexInWorkData + 1, addItem);
        break;
      }
      default: {
        workData.addDataObject(addItem);
        break;
      }
    }
  });
}

// export function reGenerateOperateIdAndCrossAddUseData(addData) {
//   reGenerateOperateIdForUseData(addData);
//   crossAddUseDataIntoWorkData(addData);
// }

/**
 * 生成 viewBox
 * @param {SVGSVGElement} node
 * @param {string} [mode]
 */
export function generateViewBox(node, mode = 'adapt') {
  const viewBoxPadding = 10;
  const allShapesRect = node.querySelector('#all-shapes').getBoundingClientRect();
  const nodeRect = node.getBoundingClientRect();
  let viewBoxLeft, viewBoxTop, viewBoxWidth, viewBoxHeight;
  switch (mode) {
    case 'adapt': {
      viewBoxLeft = parseInt(allShapesRect.x - viewBoxPadding, 10);
      viewBoxTop = parseInt(allShapesRect.y - nodeRect.y - viewBoxPadding, 10);
      viewBoxWidth = parseInt(allShapesRect.width + 2 * viewBoxPadding, 10);
      viewBoxHeight = parseInt(allShapesRect.height + 2 * viewBoxPadding, 10);
      return `${viewBoxLeft} ${viewBoxTop} ${viewBoxWidth} ${viewBoxHeight}`;
    }
    case 'limitZoom': {
      // TODO: 限制放大比例，以免导出的图片被过度放大。这需要增加一个参数，来表示显示时候的容器尺寸，也就是导出图片的尺寸
      let bx, by;
      return `0 0 ${node.clientWidth} ${node.clientHeight}`;
    }
    default:
      return `0 0 ${node.clientWidth} ${node.clientHeight}`;
  }
}

/**
 * Remove child element from the root node
 * @param {Element} rootNode
 * @param {string} selector
 */
export const removeNode = (rootNode, selector) => {
  const node = rootNode.querySelector(selector);
  if (node) {
    // ChildNode.remove() has compatibility issue in IE 9
    // https://developer.mozilla.org/zh-CN/docs/Web/API/ChildNode/remove
    node.parentNode.removeChild(node);
  }
  return rootNode;
};

/**
 * 生成元素节点
 * @param {string[]} categories
 * @param {string} [href] - base64
 */
export function generateNode(categories, href) {
  /** @type {SVGSVGElement} */
  const node = document.querySelector('#svg');
  const copiedNode = node.cloneNode(true);

  // Remove select layer html
  removeNode(copiedNode, '#selector-layer');

  // Remove mapLayerContainer
  removeNode(copiedNode, '.lncd-map-layer-container');
  removeNode(copiedNode, '#grid-layer');

  if (typeof href === 'string' && href.length > 0) {
    // Map image
    const host = copiedNode.querySelector('#host');
    const viewport = host.querySelector('#viewport');
    const image = document.createElement('image');
    image.setAttribute('href', href);
    host.insertBefore(image, viewport);
  }

  // Compress css style text
  // const styleNode = copiedNode.querySelector('style');
  // styleNode.innerHTML = styleNode.innerHTML.replace(/\s+/g, '');
  // TODO: check if there's better way
  // Compress svg content
  copiedNode.innerHTML = copiedNode.innerHTML.replace(/>\s+</g, '><');
  // Keep only needed shapes left on the node
  // const usedIds = generateData(categories).map(data => data.operateid);
  // copiedNode.querySelector('#all-shapes').childNodes.forEach((childNode, index) => {
  //   if (!usedIds.includes(childNode.id)) {
  //     childNode.innerHTML = '';
  //     node.querySelector('#all-shapes').childNodes[index].classList.add('temp-hide-element');
  //   }
  // });
  const usedIds = generateData(categories).map(data => ({
    operateId: data.operateid,
    groupDataIds: data.groupdata.map(groupDataItem => groupDataItem.operateid),
    functype: data.functype,
  }));
  copiedNode.querySelector('#all-shapes').childNodes.forEach((childNode, index) => {
    const currentObject = usedIds.find(idItem => idItem.operateId === childNode.id);
    if (!currentObject) {
      childNode.innerHTML = '';
      node.querySelector('#all-shapes').childNodes[index].classList.add('temp-hide-element');
    } else if (FUNCTION_TYPE.GROUP === currentObject.functype) {
      const middleChildNode = node.querySelector('#all-shapes').childNodes[index].childNodes;
      const copiedMiddleChildNode = copiedNode.querySelector('#all-shapes').childNodes[index].childNodes;
      copiedMiddleChildNode.forEach((grandchildNode, grandchildIndex) => {
        const currentGrandchild = currentObject.groupDataIds.includes(grandchildNode.id);
        if (!currentGrandchild) {
          grandchildNode.innerHTML = '';
          middleChildNode[grandchildIndex].classList.add('temp-hide-element');
        }
      });
    }
  });
  // hide elements not in given categories to get the correct boundry
  const tempHideNodes = node.querySelectorAll('.temp-hide-element');
  tempHideNodes.forEach(nodeItem => {
    nodeItem.style.display = 'none';
  });

  let shapeBBox;
  if (typeof href === 'string' && href.length > 0) {
    shapeBBox = document.querySelector('#lncd-map-layer').getBoundingClientRect();
    copiedNode.setAttribute('viewBox', `${0} ${0} ${shapeBBox.width} ${shapeBBox.height}`);
    copiedNode.setAttribute('shapebox', `${shapeBBox.x} ${shapeBBox.y} ${shapeBBox.width} ${shapeBBox.height}`);

    let clipPath = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
    clipPath.setAttribute('id', 'shapeClip');
    let rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    rect.setAttribute('x', 0);
    rect.setAttribute('y', 0);
    rect.setAttribute('width', shapeBBox.width);
    rect.setAttribute('height', shapeBBox.height);
    clipPath.appendChild(rect);
    copiedNode.appendChild(clipPath);
    let host = copiedNode.querySelector('#host');
    host.setAttributeNS('http://www.w3.org/2000/svg', 'clip-path', `url(${'#shapeClip'})`);
  } else {
    // Save view box data
    // TODO: if node is the SVG, why pass it around?
    copiedNode.setAttribute('viewBox', generateViewBox(node, 'adapt'));

    // Save bbox data for caculating shape center
    shapeBBox = document.querySelector('#all-shapes').getBBox();
    copiedNode.setAttribute('shapebox', `${shapeBBox.x} ${shapeBBox.y} ${shapeBBox.width} ${shapeBBox.height}`);
  }

  tempHideNodes.forEach(nodeItem => {
    nodeItem.style.display = 'block';
    nodeItem.classList.remove('temp-hide-element');
  });
  // console.log(copiedNode.outerHTML, '\n');
  // console.log(JSON.stringify(workData.getUseData()));
  return copiedNode;
}

/**
 *
 * @param {string[]} [categories]
 */
export function generateData(categories = allShapeCategories) {
  const shapes = selectShapeFromCategory(categories);
  // return workData
  //   .getUseData()
  //   .filter(data => 0 === shapes.length || shapes.includes(data.functype))
  //   .map(data => ({
  //     ...data,
  //     selectflag: false,
  //   }));
  return workData
    .getUseData()
    .filter(data => {
      return 0 === shapes.length || shapes.includes(data.functype) || FUNCTION_TYPE.GROUP === data.functype;
    })
    .map(data => {
      const { functype } = data;
      if ([FUNCTION_TYPE.GRAVEL, FUNCTION_TYPE.DIRT].indexOf(functype) > -1) {
        return { ...data, selectflag: false };
      } else {
        return {
          ...data,
          selectflag: false,
          groupdata: (data.groupdata || [])
            .filter(groupDataItem => {
              return 0 === shapes.length || shapes.includes(groupDataItem.functype);
            })
            .map(groupDataItem => {
              return {
                ...groupDataItem,
                selectflag: false,
              };
            }),
        };
      }
    });
}

export function unGroupGeneratedData(useData) {
  let result = [...useData.filter(data => FUNCTION_TYPE.GROUP !== data.functype)];
  const groupData = useData.filter(data => FUNCTION_TYPE.GROUP === data.functype).map(data => data.groupdata);
  if (groupData.length) {
    groupData.forEach(groupChildData => {
      groupChildData.forEach(data => {
        if (FUNCTION_TYPE.STREETSURFACE === data.functype) {
          const streetSuface = result.find(data => FUNCTION_TYPE.STREETSURFACE === data.functype);
          if (streetSuface) {
            streetSuface.sfills.push(...data.sfills);
            streetSuface.crfills.push(...data.crfills);
          } else {
            result.push(data);
          }
        } else {
          result.push(data);
        }
      });
    });
  }
  return result;
}

/**
 * 从 Html 中移除 map layer
 * @param {string} html - id=svg
 * @returns {string}
 */
function removeMapNodeFromHtml(html) {
  let a, b;
  if (html.match(/<foreignObject[^>]+>/) !== null) {
    a = html.split('<foreignObject')[0];
    b = html.split('/foreignObject>')[1];
    html = a + b;
  }
  return html;
}

/**
 * 从 HTMLElement 解析样式
 * @param {HTMLElement} html
 * @param {string} mode
 * @returns {string}
 */
export function parseStyleFromHtml(html, mode) {
  const strokeColor = styles[`${mode}-strokeColor`];
  const canvasBGColor = styles[`${mode}-canvasBGColor`];
  html = removeMapNodeFromHtml(html);
  return html
    .replace('var(--color-stripe-path)', strokeColor)
    .replace('var(--color-crosswalk-line)', strokeColor)
    .replace('var(--color-stripe-path)', strokeColor)
    .replace('var(--color-canvas-bg)', canvasBGColor)
    .replace('var(--color-street-fill-selected)', 'none');
}

/**
 * 从 Node 解析样式
 * @param {Node} node
 * @param {string} mode
 */
export function parseStyleFromNode(node, mode) {
  node.innerHTML = parseStyleFromHtml(node.innerHTML, mode);
  return node;
}

/**
 * 生成 DataURI
 * @param {Node} node
 * @param {string} mode
 * @param {*} callee
 * @returns {string} - dataURL
 */
function generateURL(node, mode, callee = svgAsDataUri) {
  parseStyleFromNode(node, mode);
  /** @type {number[]} */
  const [left, top, width, height] = node.getAttribute('viewBox').split(/\s+/);
  return callee(node, { left, top, width, height });
}

/**
 *
 * @param {string} [mode=day]
 * @param {string[]} categories
 */
export function generateURLFromNode(mode = 'day', categories = allShapeCategories) {
  const node = generateNode(categories);
  return generateURL(node, mode);
}

/**
 *
 * @param {*} html
 */
function generateNodeFromHtml(html) {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.querySelector('svg');
}

/**
 *
 * @param {*} html
 * @param {*} mode
 */
export function generateURLFromHtml(html, mode = 'day') {
  const node = generateNodeFromHtml(html);
  return generateURL(node, mode);
}

/**
 *
 * @param {HTMLElement} node
 * @param {object} [extra]
 * @param {object} extra.map - map reducer
 */
function saveMetaDataInNode(node, extra) {
  const data = {
    useData: workData.getUseData(),
    // TODO: 冗余数据
    operateLastid: workData.operateLastid,
    map: extra && extra.map,
  };
  if (extra) {
    data.map = extra.map;
    data.image = extra.image;
  }
  const defs = document.createElement('defs');
  const desc = document.createElement('desc');
  desc.id = 'shapes-data';
  defs.appendChild(desc);
  desc.innerHTML = JSON.stringify(data, (key, value) => {
    if (key === 'originsvg') {
      return encodeURIComponent(value);
    } else if (key === 'shapes') {
      return '[]';
    }
    return value;
  });
  // TODO: otherwise?
  if (node.firstChild.tagName !== defs.tagName) {
    node.insertBefore(defs, node.firstChild);
  }
}

/**
 *
 * @param {string} fileName
 * @param {object} [extra]
 * @param {{
 *  service: {
 *    latitude: number,
 *    longitude: number,
 *    schema: string,
 *    type: string,
 *  },
 *  zoom: number,
 *  opacity: number,
 *  currentLocation: string,
 *  selectOptions: string[],
 *  keyword: string,
 *  mapTabKey: number
 * }} extra.map
 * @param {string} extra.href
 * @param {object} extra.image
 */
export function getSVGFile(fileName, extra) {
  let node;
  // TODO: limit the boundry of the diagram so that it will not be zoomed in too much
  if (extra) {
    node = generateNode([], extra.href);
    // parseStyleFromNode(node, 'day');
    saveMetaDataInNode(node, extra);
  } else {
    node = generateNode([]);
    // parseStyleFromNode(node, 'day');
    saveMetaDataInNode(node);
  }
  const fileContent = `<?xml version="1.0" encoding="utf-8"?>\n${node.outerHTML}`;
  const fileType = { type: 'image/svg+xml;charset=utf-8' };
  return new File([fileContent], fileName, fileType);
}

/**
 *
 * @param {string} fileName
 * @param {object} extra
 * @param {object} extra.map - map reducer
 * @param {string} extra.href
 * @param {object} extra.image
 */
export function saveTemplateAsSVG(fileName, extra) {
  const file = getSVGFile(fileName, extra);
  // console.log(file);
  saveAs(file);
}

/**
 *
 * @param {string} fileName
 * @param {object} extra
 * @param {number} extra.width
 * @param {number} extra.height
 * @param {string} extra.href
 * @param {object} extra.image
 */
export function saveTemplateAsPNG(fileName, extra) {
  const node = generateNode([], extra.href);
  parseStyleFromNode(node, 'day');
  let [left, top, width, height] = node.getAttribute('viewBox').split(/\s+/);
  let scale, fit;
  if (extra.width / extra.height > width / height) {
    scale = extra.height / height / 2;
    fit = (extra.width * height) / extra.height;
    left -= (fit - width) / 2;
    width = fit;
  } else {
    scale = extra.width / width / 2;
    fit = (extra.height * width) / extra.width;
    top -= (fit - height) / 2;
    height = fit;
  }
  saveSvgAsPng(node, fileName, { left, top, width, height, scale });
}

/**
 *
 * @param {string} fileName
 * @param {object} extra
 * @param {string} extra.href
 * @param {object} extra.image
 */
export function saveTemplateAsPDF(fileName, extra) {
  // TODO svg -> pdf
  const node = generateNode([], extra.href);
  return generateURL(node, 'day', svgAsPngUri).then(url => {
    const pdf = new jsPDF({
      compress: true,
      unit: 'pt',
    });
    const pdfPadding = 10;
    const imageProps = pdf.getImageProperties(url);
    const imageRatio = imageProps.width / imageProps.height;
    const docWidth = pdf.internal.pageSize.getWidth() - 2 * pdfPadding;
    const docHeight = pdf.internal.pageSize.getHeight() - 2 * pdfPadding;
    const docRatio = docWidth / docHeight;
    let pdfWidth, pdfHeight, docPadding;
    if (imageRatio > docRatio) {
      pdfHeight = (imageProps.height * docWidth) / imageProps.width;
      pdf.addImage(url, 'png', pdfPadding, pdfPadding, docWidth, pdfHeight);
    } else {
      pdfWidth = (imageProps.width * docHeight) / imageProps.height;
      docPadding = (docWidth - pdfWidth) / 2;
      pdf.addImage(url, 'png', docPadding, pdfPadding, pdfWidth, docHeight);
    }
    return pdf.save(fileName, { returnPromise: true });
  });
}

/**
 * Used to rotateDataPoint method
 * @param {array} segments
 * @param {number} radianAngle
 * @param {[number, number]} rotateCenter
 */
function rotateSegmentPoints(segments, radianAngle, rotateCenter) {
  segments.forEach(segmentPoint => {
    const { ptStart, ptStop, pt = null } = segmentPoint;
    [ptStart.x, ptStart.y] = RotatePoint([ptStart.x, ptStart.y], radianAngle, rotateCenter);
    [ptStop.x, ptStop.y] = RotatePoint([ptStop.x, ptStop.y], radianAngle, rotateCenter);
    if (pt) {
      [pt.x, pt.y] = RotatePoint([pt.x, pt.y], radianAngle, rotateCenter);
    }
  });
}

/**
 *
 * @param {array} useData
 * @param {number} radianAngle
 * @param {[number, number]} rotateCenter
 */
export function rotateDataPoint(useData, radianAngle, rotateCenter) {
  if (0 !== radianAngle && 0 !== useData.length) {
    useData.forEach(data => {
      if (data.segments && data.segments.length > 0) {
        rotateSegmentPoints(data.segments, radianAngle, rotateCenter);
      }
      if (data.components && data.components.length > 0) {
        data.components.forEach(component => {
          if (component.segments && component.segments.length > 0) {
            rotateSegmentPoints(component.segments, radianAngle, rotateCenter);
          }
        });
      }
      if (data.text && data.text.point && data.text.point.length > 0) {
        data.text.point = RotatePoint(data.text.point, radianAngle, rotateCenter);
      }
      if (data.ptApex) {
        [data.ptApex.x, data.ptApex.y] = RotatePoint([data.ptApex.x, data.ptApex.y], radianAngle, rotateCenter);
      }
      if (data.position) {
        if (data.position.x1 && data.position.y1) {
          [data.position.x1, data.position.y1] = RotatePoint(
            [data.position.x1, data.position.y1],
            radianAngle,
            rotateCenter
          );
        }
        if (data.position.x2 && data.position.y2) {
          [data.position.x2, data.position.y2] = RotatePoint(
            [data.position.x2, data.position.y2],
            radianAngle,
            rotateCenter
          );
        }
      }
      if (data.handlepoint && data.handlepoint.length > 0) {
        data.handlepoint.forEach((point, index) => {
          data.handlepoint[index] = RotatePoint(point, radianAngle, rotateCenter);
        });
      }
      if (data.offsetArcPath && data.offsetArcPath.segments && data.offsetArcPath.segments.length > 0) {
        rotateSegmentPoints(data.offsetArcPath.segments, radianAngle, rotateCenter);
      }
      if (data.streetoffarc && data.streetoffarc.segments && data.streetoffarc.segments.length > 0) {
        rotateSegmentPoints(data.streetoffarc.segments, radianAngle, rotateCenter);
      }
    });
  }
}

/**
 *
 * @param {string} html - html src
 */
export function getShapeBoxCenterFromHtml(html) {
  const node = generateNodeFromHtml(html);
  const [left, top, width, height] = node.getAttribute('shapebox').split(/\s+/);
  const rotateCenterX = parseFloat(left, 10) + parseFloat(width, 10) / 2;
  const rotateCenterY = parseFloat(top, 10) + parseFloat(height, 10) / 2;
  return [rotateCenterX, rotateCenterY];
}

/**
 *
 * @param {COLOR_TYPE} color
 */
export function strokeOtherProps(color) {
  const result = {};
  if (COLOR_TYPE.BLACK === color) {
    result['lncd-night-mode-stroke-adapt'] = 'true';
  }
  return result;
}

/**
 *
 * @param {COLOR_TYPE} color
 */
export function textOtherProps(color) {
  const result = {};
  if (COLOR_TYPE.BLACK === color) {
    result['lncd-night-mode-text-adapt'] = 'true';
  }
  return result;
}

/**
 *
 * @param {COLOR_TYPE} color
 */
export function fillOtherProps(color) {
  const result = {};
  if (COLOR_TYPE.WHITE === color) {
    result['lncd-night-mode-fill-adapt'] = 'true';
  }
  return result;
}

// TODO: Can use getBBoxData?
/**
 * 添加一层 g 标签赋予自定义属性，并根据 g 获取真实的 BBox
 * @param {SVGSVGElement} svg
 * @param {SVGGElement} [layer]
 * @param {function} [callback]
 */
export function getBBox(svg, layer, callback) {
  let g;
  if (typeof layer === 'undefined') {
    g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  } else {
    g = layer;
  }
  g.innerHTML = svg.innerHTML;
  callback && callback(g);

  svg.innerHTML = '';
  svg.appendChild(g);

  document.body.appendChild(svg);
  const layerBBox = g.getBBox();
  let { width, height } = layerBBox;
  svg.remove();

  return { width, height };
}

/**
 * 根据 SVG 字符串创建 DOM 对象
 * @param {string} svgStr
 */
export function createSVGElement(svgStr) {
  let oParser = new DOMParser();
  let oDOM = oParser.parseFromString(svgStr, 'image/svg+xml');
  return oDOM.documentElement;
}

/**
 *
 * @param {number[]} matrix
 * @param {number} x
 * @param {number} y
 */
export const convertToUserPoint = (matrix, x, y) => {
  const [a, b, c, d, e, f] = matrix;
  const point = applyToPoint(inverse({ a, b, c, d, e, f }), { x, y });
  return [point.x, point.y];
};
