import React from 'react';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomRect from '@src/data/GeomRect';
import { getLinePathData } from "@src/utils";
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import utility from '@src/data/Utility';
import { SquareRectHandlePoint } from '@src/data/AnchorsPoint';

class SelectCurbReturn extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props } = this;
    const { object } = props;
    let r = getUserUnitByPixel() * utility.getHandleRadius();
    let shapes = [];

    var gpa = new GeomPolyArc(object.segments);
    shapes.push(
      <path
        key="1"
        ttn-role="core"
        ttn-agent="polyArc"
        d={gpa.getSvgPathData()}
        strokeLinecap="butt"
        fill="none"
      ></path>
    );
    shapes.push(
      <path key="2" d={getLinePathData(gpa.getPointStart(), gpa.getPointStop())} />
    );
    var rApex = new GeomRect(object.ptApex.x, object.ptApex.y, object.ptApex.x, object.ptApex.y);
    rApex.inflateRect(r, r);
    shapes.push(
      <path key="3" ttn-role="apex" d={rApex.getBisectorsSvgPathData()} strokeDasharray="none" stroke="#0000FF"></path>
    );
    shapes.push(<SquareRectHandlePoint key="curb-start" id={object.operateid + '-s'} point={gpa.getPointStart()} />)
    shapes.push(<SquareRectHandlePoint key="curb-end" id={object.operateid + '-e'} point={gpa.getPointStop()} />)
    return <g lncd-role="curb-selector">{shapes}</g>;
  }
}

export default SelectCurbReturn;
