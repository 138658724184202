import React from 'react';
import { connect } from 'react-redux';
import { parseStyleFromHtml } from '@src/utils';

class TemplatePreview extends React.Component {
  render() {
    return 'image' === this.props.contentType ? (
      <div
        style={{
          backgroundImage: `url(${this.props.src})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '100%',
          height: '100%',
        }}
      ></div>
    ) : (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          borderRadius: '2px',
        }}
        // FIXME: should use better way than dangerouslySetInnerHTML
        // also, need to update cursor style from default to point as well
        dangerouslySetInnerHTML={{
          __html: parseStyleFromHtml(this.props.src, this.props.nightMode ? 'night' : 'day'),
        }}
      ></div>
    );
  }
}

export default connect(
  state => ({ nightMode: state.app.nightMode }),
  null
)(TemplatePreview);
