import React from 'react';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledStopDrawing = styled(Button)`
  position: absolute;
  right: 50px;
  bottom: 50px;
  user-select: none;
`;

function StopDrawing({ clickEvent, t }) {
  return (
    <StyledStopDrawing type="primary" onClick={clickEvent}>
      {t('menu.drawShape.stopDrawingButton')}
    </StyledStopDrawing>
  );
}

export default withTranslation()(StopDrawing);
