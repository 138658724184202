import React from 'react';
import { Menu, Dropdown, Icon } from 'antd';
import { IconDown } from '@src/components/UI/Icons';
import { PARK_STALL_PATTERN_TYPE } from '@src/constant';
import { withTranslation } from 'react-i18next';

class ParkStallSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.t(`property.parkStall.${props.type}`),
    };
  }

  handleClick = e => {
    const { handle } = this.props;
    handle && handle(e.key);
    this.setState({ type: e.item.props.value });
  };

  render() {
    const { disabled = false } = this.props;

    const shapes = (
      <Menu className="reg-no-select" onClick={this.handleClick.bind(this)}>
        <Menu.Item
          key={PARK_STALL_PATTERN_TYPE.STANDARD}
          value={this.props.t(`property.parkStall.${PARK_STALL_PATTERN_TYPE.STANDARD}`)}
        >
          {this.props.t(`property.parkStall.${PARK_STALL_PATTERN_TYPE.STANDARD}`)}
        </Menu.Item>
        <Menu.Item
          key={PARK_STALL_PATTERN_TYPE.PARALLELONE}
          value={this.props.t(`property.parkStall.${PARK_STALL_PATTERN_TYPE.PARALLELONE}`)}
        >
          {this.props.t(`property.parkStall.${PARK_STALL_PATTERN_TYPE.PARALLELONE}`)}
        </Menu.Item>
        <Menu.Item
          key={PARK_STALL_PATTERN_TYPE.PARALLELTWO}
          value={this.props.t(`property.parkStall.${PARK_STALL_PATTERN_TYPE.PARALLELTWO}`)}
        >
          {this.props.t(`property.parkStall.${PARK_STALL_PATTERN_TYPE.PARALLELTWO}`)}
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown className="custom-dropdown" overlay={shapes} trigger={['click']} disabled={disabled}>
        <div className="custom-dropdown-item">
          <span>{this.state.type}</span>
          <Icon component={IconDown} alt="down" />
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(ParkStallSelect);
