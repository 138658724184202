import * as React from 'react';

function SvgImageArrowTriangleLine(props) {
  return (
    <svg width={15} height={10} {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M0 3h5v3H0z" />
        <path d="M13 4.295L4 0v9.288zM13 0h2v9h-2z" />
      </g>
    </svg>
  );
}

export default SvgImageArrowTriangleLine;
