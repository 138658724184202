import TouchScroll from '@src/components/TouchDevice/TouchScroll';
import { ROAD_LAYER } from '@src/constant';
import { StreetService } from '@src/services/StreetService';
import { Radio } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import AddLaneSelect from '../Element/AddLaneSelect';
import AddLaneSelectGroup from '../Element/AddLaneSelectGroup';
import AddSplitterButton from '../Element/AddSplitterButton';
import CustomInputNumber from '../Element/CustomInputNumber';
import LncdCheckbox from '../Element/LncdCheckbox';
import { RemoveStripeButton } from '../Element/RemoveStripeButton';
import StreetDividerSelect from '../Element/StreetDividerSelect';
import StripeStyleSelect from '../Element/StripeStyleSelect';

class LaneMenu extends React.Component {
  render() {
    const t = this.props.t;
    const object = this.props.stateObject;

    const stripeIndex = object.idSelectedComponent;
    let stripePattern;
    let stripe;
    if (stripeIndex > -1) {
      stripe = object.components[stripeIndex];
      const index = stripe.selectedPatternIndex || 0;
      if (stripe.primary.patterns[index]) {
        stripePattern = stripe.primary.patterns[index].pattern;
      }
    }

    const ss = new StreetService(object);
    const laneCount = ss.vehicleLaneCount;
    const enableDivider = laneCount > 1;

    const item = this.props.item;
    return (
      <TouchScroll className="props-container scrollbar">
        {item.map(element => {
          switch (element) {
            case 'stallWidth':
              return (
                <div className="container-item" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.stallWidthLabel')}:</span>
                  <CustomInputNumber
                    value={object.marks.length / 12}
                    min={1}
                    max={1000}
                    formatter={''}
                    parser={''}
                    onChangeValue={this.props.stallWidthHandle}
                  />
                </div>
              );
            case 'stallLength':
              return (
                <div className="container-item" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.stallLengthLabel')}:</span>
                  <CustomInputNumber
                    value={object.marks.height / 12}
                    min={1}
                    max={1000}
                    formatter={''}
                    parser={''}
                    onChangeValue={this.props.stallLength}
                  />
                </div>
              );
            case 'lane':
              return (
                <div className="container-item" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.laneLabel')}:</span>
                  <CustomInputNumber
                    min={1}
                    max={20}
                    onChangeValue={this.props.handleLanes}
                    formatter={''}
                    parser={''}
                    value={laneCount}
                  />
                </div>
              );
            case 'width':
              return (
                <div className="container-item" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.widthLabel')}:</span>
                  <CustomInputNumber
                    min={1}
                    max={100}
                    onChangeValue={this.props.handleStreetWidth}
                    formatter={"'"}
                    parser={"'"}
                    value={object.lanewidth / 12}
                  />
                </div>
              );
            case 'divider':
              return (
                <div className="container-item" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.dividerLabel')}:</span>
                  <StreetDividerSelect
                    type={object.streetdividetype}
                    handle={this.props.handleDividerType}
                    style={{ paddingRight: 0 }}
                    disabled={!enableDivider}
                  />
                </div>
              );
            case 'roadLayer':
              return (
                <div className="container-item" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.roadLayer')}:</span>
                  <div>
                    <Radio.Group value={this.props.stateObject.roadLayer || 0} buttonStyle="solid">
                      {Object.values(ROAD_LAYER).map(({ value, label }) => {
                        return (
                          <Radio.Button key={value} value={value} onChange={() => this.props.changeRoadLayer(value)}>
                            {t(`option.roadLayer.${label}`)}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </div>
                </div>
              );
            case 'addLane': {
              /**
               * 1. 当 stripe 不存在，不显示额外 addLane 选项
               * 2. 当 stripe 为 street 两边时，不显示额外 addLane 选项
               */
              if (stripeIndex > -1 && !(stripeIndex === 0 || stripeIndex === object.components.length - 1)) {
                return (
                  <React.Fragment key={element}>
                    <div className="container-item">
                      <span className="item-title">{this.props.t('property.laneTab.addLaneLabel')}:</span>
                      <AddLaneSelect streetId={this.props.stateObject.operateid} stripeIndex={stripeIndex} />
                    </div>
                    <div key="stripe-style-select" className="container-item">
                      <span className="item-title">{this.props.t('property.laneTab.laneStripeLabel')}:</span>
                      <StripeStyleSelect
                        streetId={object.operateid}
                        stripeIndex={stripeIndex}
                        stripePattern={stripePattern}
                      />
                    </div>
                    <div key="add-split" className="container-item">
                      <span className="item-title">{this.props.t('property.laneTab.addSplitterLabel')}:</span>
                      <AddSplitterButton streetId={this.props.stateObject.operateid} stripeIndex={stripeIndex} />
                    </div>
                    <div key="remove" className="container-item">
                      <span className="item-title">{this.props.t('property.laneTab.removeLabel')}:</span>
                      <RemoveStripeButton streetId={this.props.stateObject.operateid} stripeIndex={stripeIndex} />
                    </div>
                  </React.Fragment>
                );
              } else {
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.laneTab.addLaneLabel')}</span>
                    <AddLaneSelectGroup streetId={this.props.stateObject.operateid} />
                  </div>
                );
              }
            }
            case 'hideDivider':
              return (
                <div className="container-item hide-divider" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.hideDividerLabel')}:</span>
                  <LncdCheckbox
                    checked={this.props.stateObject.isShowDividerBackground}
                    onClick={this.props.toggleDividerBackground}
                  />
                </div>
              );
            case 'hideShoulder':
              return (
                <div className="container-item hide-shoulder" key={element}>
                  <span className="item-title">{this.props.t('property.laneTab.hideShoulderLabel')}:</span>
                  <LncdCheckbox
                    checked={this.props.stateObject.isShowShoulderBackground}
                    onClick={this.props.toggleShoulderBackground}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </TouchScroll>
    );
  }
}

export default withTranslation()(LaneMenu);
