import React, { useEffect, useState } from 'react';
import './map-side-bar.scss';
import Images from '@src/assets/Images';
import MapSideBarBtn from './MapSideBarBtn';
import { Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import { takeSnapshot } from '@src/action/snapshots';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const MapControl = styled.div`
  display: flex;
  position: absolute;
  top: 180px;
  right: ${props => `${props.isShowGrid ? 18 : 5}px`};
  flex-direction: column;
  align-items: flex-end;
`;

function MapSideBar(props) {
  // redux
  const { zoom, takeSnapshot, isShowGrid, type, schema } = props;
  const { onZoomIn, onZoomOut, onSelectMapType, mapConfigs, onClear, generateSketch } = props;
  const [mapTypeItems, setMapTypeItems] = useState([]);

  useEffect(() => {
    for (const obj of mapConfigs) {
      if (obj.provider === schema) {
        setMapTypeItems(obj.types);
        break;
      }
    }
  }, [schema]);

  // TODO: useCallback
  // TODO: use domEvent to prevent potential event propagation
  function onClick({ key, domEvent }) {
    const provider = mapConfigs.find(n => n.provider === schema);
    if (provider) {
      const type = provider.types.find(n => n.id === parseInt(key));
      if (type) {
        onSelectMapType(type.value);
        takeSnapshot();
      }
    }
  }

  const { t } = useTranslation();

  const overlay = (
    <Menu className="reg-no-select" onClick={onClick}>
      {mapTypeItems.map(item => {
        return <Menu.Item key={item.id}>{t(`map.mapType.${item.value}`)}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <MapControl className="map-sidebar reg-no-select" isShowGrid={isShowGrid}>
      <MapSideBarBtn src={Images.IconSketchMap} title={t('map.sideBar.sketchTitle')} onClick={generateSketch} />
      {/* TODO: create MapControlBtn to replace this and remove map-side-bar.scss */}
      <MapSideBarBtn src={Images.IconRemoveMap} title={t('map.sideBar.removeTitle')} onClick={onClear} />
      <MapSideBarBtn
        src={Images.IconPlus}
        title={t('map.sideBar.zoomInTitle')}
        disabled={zoom >= 25}
        onClick={onZoomIn}
      />
      <MapSideBarBtn
        src={Images.IconMinus}
        title={t('map.sideBar.zoomOutTitle')}
        disabled={zoom <= 15}
        onClick={onZoomOut}
      />
      <Dropdown trigger={['click']} className="custom-dropdown map-side-bar" overlay={overlay}>
        <div className="custom-dropdown-item">
          <span>{t(`map.mapType.${type}`)}</span>
          <img src={Images.IconDown} alt="" />
        </div>
      </Dropdown>
    </MapControl>
  );
}

export default connect(
  state => ({
    zoom: state.map.zoom,
    type: state.map.service.type,
    schema: state.map.service.schema,
    isShowGrid: state.canvas.isShowGrid,
    mapConfigs: state.map.mapConfigs,
  }),
  {
    takeSnapshot,
  }
)(MapSideBar);
