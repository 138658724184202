import {
  AddStreet2WayLine,
  AddStreetLine,
  AddStreetPaintedLine,
  AddStreetWideLine,
  RemoveStreetLine,
  ReplaceStreetNoneLine,
  AddStreetGrassLane,
  AddStreetCementLane,
} from '@src/actions/HandlePoint';
import { GetSpecialLineIndex, GetStreetLineBegin } from '@src/actions/StreetFunHandle';
import TabItem from '@src/components/PropMenu/TabItem';
import {
  FONT_STYLE_TYPE,
  FONT_WEIGHT_TYPE,
  FUNCTION_TYPE,
  STREET_DIVIDER_TYPE,
  STREET_DEFAULT_SIZE,
  LANE_TYPE,
  ROAD_LAYER_VALUES,
  ROAD_LAYER,
} from '@src/constant';
import * as workData from '@src/data/WorkData';
import _Street from '@src/data/_Street';
import { Icon, Tabs } from 'antd';
import _ from 'lodash';
import React from 'react';
import ArrowMenu from '../Menu/ArrowMenu';
import LaneMenu from '../Menu/LaneMenu';
import SizeMenu from '../Menu/SizeMenu';
import StyleMenu from '../Menu/StyleMenu';
import TextMenu from '../Menu/TextMenu';
import { createDivider, createStripe, createLane } from '@src/data/ShapeOperationData';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { StreetService, isVehicleLane } from '@src/services/StreetService';

const { LANE_WIDTH } = STREET_DEFAULT_SIZE;

export default class StreetModal extends React.Component {
  // Modal menu and items
  menu;

  constructor(props) {
    super(props);
    this.state = {
      tabKey: 'lane',
    };
    this.setMenu(this.props.object);
    if (this.props.object.functype === FUNCTION_TYPE.STREETNEW) {
      this.showDividerCheckbox();
      this.showShoulderCheckbox();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setMenu(nextProps.object);
    if (nextProps.object.functype === FUNCTION_TYPE.STREETNEW) {
      this.showDividerCheckbox();
      this.showShoulderCheckbox();
    }
  }

  setMenu(object) {
    if (object.functype === FUNCTION_TYPE.STREETNEW) {
      this.menu = {
        lane: ['lane', 'width', 'divider', 'roadLayer', 'addLane'],
        style: ['lineColor'],
        text: ['text', 'fontSize', 'color', 'style'],
      };
    } else {
      this.menu = {
        lane: ['width'],
        text: ['text', 'fontSize', 'color', 'style'],
      };
    }
  }

  updateObject = obj => {
    workData.setObj(obj);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };

  handleStrokeDashArray = (strokepattern, strokeDash) => {
    workData.setObjectStyleLine(this.props.object.operateid, strokepattern, strokeDash);
    const obj = _.cloneDeep(this.props.object);
    obj.style.strokepattern = strokepattern;
    obj.style.strokedasharray = strokeDash;
    this.updateObject(obj);
  };

  handleStrokeWidth = width => {
    const obj = _.cloneDeep(this.props.object);
    obj.style.strokewidth = width;
    obj.marks.height = width * 2;
    if (obj.arrow.width < obj.style.strokewidth * 2) {
      obj.arrow.width = obj.style.strokewidth * 2;
      obj.arrow.height = obj.arrow.width;
    }

    this.updateObject(obj);
  };

  handleStroke = evt => {
    var colortype = evt.currentTarget.id;
    const obj = _.cloneDeep(this.props.object);
    const stripeIndex = obj.idSelectedComponent;
    if (stripeIndex > -1) {
      // when select stripe
      let stripe = obj.components[stripeIndex];
      stripe.stroke = colortype;
    } else {
      // when select street, street's stroke will cover stroke's stroke
      obj.components.map(c => {
        if (c.type === 'stripe') {
          c.stroke = undefined;
        }
      });
      obj.style.stroke = colortype;
      for (let i = 0; i < obj.groupdata.length; i++) {
        obj.groupdata[i].stroke = colortype;
      }
    }
    this.updateObject(obj);
  };
  handleFill = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleFillColor(this.props.object.operateid, colortype);
    const obj = _.cloneDeep(this.props.object);
    obj.style.fill = colortype;
    this.updateObject(obj);
  };
  // textHandle = e => {
  //   const obj = _.cloneDeep(this.props.object);
  //   obj.text.text = e.target.value;
  //   this.updateObject(obj);
  // };
  // textSizeHandle = size => {
  //   const obj = _.cloneDeep(this.props.object);
  //   obj.text.size = size;
  //   this.updateObject(obj);
  // };
  // textBoldHandle = () => {
  //   // FIXME: what? bold is a string...
  //   const obj = _.cloneDeep(this.props.object);
  //   if (obj.text.bold == FONT_WEIGHT_TYPE.NORMAL) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.BOLD;
  //   } else if (obj.text.bold == FONT_WEIGHT_TYPE.BOLD) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.NORMAL;
  //   }
  //   this.updateObject(obj);
  // };
  // textItalicHandle = () => {
  //   const obj = _.cloneDeep(this.props.object);
  //   obj.text.italic = obj.text.italic === FONT_STYLE_TYPE.ITALIC ? FONT_STYLE_TYPE.NORMAL : FONT_STYLE_TYPE.ITALIC;
  //   this.updateObject(obj);
  // };
  // textColorHandle = e => {
  //   var colortype = e.currentTarget.id;
  //   const obj = _.cloneDeep(this.props.object);
  //   obj.text.color = colortype;
  //   this.updateObject(obj);
  // };
  handleStreetWidth = width => {
    // hack: do not copy object as we need to change the object passed by props directly as the SVGRoot state relies on it
    // const obj = _.cloneDeep(this.props.object);
    const obj = this.props.object;
    obj.lanewidth = width * 12;
    if (obj.functype == 'StreetNew') {
      for (let i = 0; i < obj.components.length; i++) {
        if (obj.components[i].type == 'lane' && obj.components[i].laneType == LANE_TYPE.VEHICLE_LANE) {
          obj.components[i].width = width * 12;
        }
      }
      var s = new _Street();
      let lw = s.alignComponentsToStreetAxis(obj);
      s.computeStreets(obj);
    } else {
      for (let i = 0; i < obj.lanes.length; i++) {
        obj.lanes[i].width = width * 12;
      }
    }
    // obj = HandlePointStreetByNumWidth(obj);
    this.updateObject(obj);
  };

  handleLanes = num => {
    // FIXME: this operation directly updates the drawingStatus.drawingState in SVGRoot, so we cannot use the 'right way' to update work data
    let obj = this.props.object;
    obj.idSelectedComponent = -1;
    if (obj.functype == FUNCTION_TYPE.STREETNEW) {
      const street = new StreetService(obj);
      const dividerIdx = street.dividerIndex;
      const hasDivider = dividerIdx !== -1;
      let medianIdx = hasDivider ? dividerIdx : obj.components.findIndex(n => n.type === 'stripe' && n.median);

      let insIdx;
      let delIdx;
      let createMedian = false;
      let lanesBefore = 0;
      let lanesAfter = 0;
      let lastLaneIdx = _.findLastIndex(obj.components, isVehicleLane);
      if (medianIdx === -1) {
        // no divider/median, menas there's only one vehicle lane and we'll need to create a stripe as median
        createMedian = true;
        insIdx = lastLaneIdx + 1;
      } else {
        lanesBefore = obj.components.slice(0, medianIdx).filter(isVehicleLane).length;
        lanesAfter = obj.components.slice(medianIdx).filter(isVehicleLane).length;
        if (lanesBefore <= lanesAfter) {
          insIdx = hasDivider ? medianIdx - 1 : medianIdx;
          delIdx = hasDivider ? medianIdx + 2 : medianIdx + 1;
        } else {
          insIdx = hasDivider ? medianIdx + 2 : medianIdx + 1;
          delIdx = hasDivider ? medianIdx - 3 : medianIdx - 2;
        }
      }

      const laneCount = street.vehicleLaneCount;
      if (num > laneCount) {
        let laneWidth;
        if (obj.components[1] && obj.components[1].type === 'lane') {
          laneWidth = obj.components[1].width;
        }
        let laneItems = [
          createStripe(obj.nextKey, obj.segments, 'singledash', createMedian),
          createLane({ key: obj.nextKey + 1, width: laneWidth }),
        ];
        obj.components.splice(insIdx, 0, ...(insIdx <= medianIdx || createMedian ? laneItems : _.reverse(laneItems)));
        obj.nextKey += 2;
      } else {
        // determine if we could remove lanes
        if ((hasDivider && laneCount > 2) || (!hasDivider && laneCount > 1)) {
          // there's should be at least one vehicle lane on each side of the divider
          obj.components.splice(delIdx, 2);

          const stripe = obj.components[delIdx - 1];
          if (delIdx === lastLaneIdx && stripe.median) {
            //
            stripe.median = false;
            stripe.primary.patterns[0].pattern = 'singlesolid';
          }
        }
      }
      const s = new _Street(obj.components);
      s.alignComponentsToStreetAxis(obj);
      s.computeStreets(obj);
    } else {
      let beginLine = GetStreetLineBegin(obj.groupdata);
      if (num > obj.lanes.length) {
        if (num % 2) {
          obj = AddStreetLine(obj, obj.groupdata[beginLine].bottomline);
        } else {
          obj = AddStreetLine(obj, 1);
        }
      } else {
        if (num % 2) {
          obj = RemoveStreetLine(obj, obj.groupdata[beginLine].bottomline);
        } else {
          obj = RemoveStreetLine(obj, obj.groupdata[1].topline);
        }
      }
    }
    //obj = HandlePointStreetByNumWidth(obj);
    this.updateObject(obj);
  };

  handleDividerType = type => {
    let obj = this.props.object;
    if (obj.functype == FUNCTION_TYPE.STREETNEW) {
      const ss = new StreetService(obj);
      if (ss.vehicleLaneCount < 2) {
        // should not add divider when there's only one vehicle lane
        return;
      }
      var s = new _Street(obj.components);
      var dividerIndex = s._getDividerIndex();
      let delCount;
      if (dividerIndex === -1) {
        dividerIndex = s._getMedianIndex();
        delCount = 1;
      } else {
        dividerIndex -= 1;
        delCount = 3;
      }

      const segments = obj.offsetArcPath ? obj.offsetArcPath.segments : obj.segments;
      const dividerItems = createDivider({ type, key: obj.nextKey, segments });
      obj.components.splice(dividerIndex, delCount, ...dividerItems);
      obj.streetdividetype = type;
      obj.nextKey = obj.nextKey + dividerItems.length;
      let _s = new _Street();
      let lw = _s.alignComponentsToStreetAxis(obj);
      _s.computeStreets(obj);
    } else {
      let specialLine = GetSpecialLineIndex(obj.groupdata);
      if (specialLine === -1) specialLine = 2;
      if (type === STREET_DIVIDER_TYPE.TWO_WAY) AddStreet2WayLine(obj, specialLine);
      else if (type === STREET_DIVIDER_TYPE.WIDE) AddStreetWideLine(obj, specialLine);
      else if (type === STREET_DIVIDER_TYPE.PAINTED) AddStreetPaintedLine(obj, specialLine);
      // else if (type === STREET_DIVIDER_TYPE.GRASS) AddStreetGrassLane(obj, specialLine);
      // else if (type === STREET_DIVIDER_TYPE.CEMENT) AddStreetCementLane(obj, specialLine);
      else if (type === STREET_DIVIDER_TYPE.NONE) ReplaceStreetNoneLine(obj);
    }
    this.updateObject(obj);
  };

  changeRoadLayer = value => {
    const { object } = this.props;
    const origLayer = object.roadLayer;
    object.roadLayer = value;
    workData.sortUseData();
    workData.setObj(object);

    let _s = new _Street();
    _s.alignComponentsToStreetAxis(object);
    _s.computeStreets(object);

    // 重新计算当前street层下的所有street
    const streets = workData.getStreets({
      surfaceType: object.surfaceType,
      roadLayer: origLayer,
    });
    // 重新计算与当前street进行交叉的street
    streets.forEach(street => {
      _s.alignComponentsToStreetAxis(street);
      _s.computeStreets(street);
    });
    this.props.handle();
  };

  toggleDividerBackground = () => {
    const obj = _.cloneDeep(this.props.object);
    obj.isShowDividerBackground = !obj.isShowDividerBackground;
    for (let i = 0; i < obj.groupdata.length; i++) {
      obj.groupdata[i].isShowDividerBackground = !obj.isShowDividerBackground;
    }
    this.updateObject(obj);
  };

  toggleShoulderBackground = () => {
    const obj = _.cloneDeep(this.props.object);
    obj.isShowShoulderBackground = !obj.isShowShoulderBackground;
    for (let i = 0; i < obj.groupdata.length; i++) {
      obj.groupdata[i].isShowShoulderBackground = !obj.isShowShoulderBackground;
    }
    this.updateObject(obj);
  };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  showDividerCheckbox = () => {
    const isCementOrGrass = o => o.type === 'divider' && ['cement', 'grass'].some(v => v === o.dividerType);
    if (this.props.object.components) {
      if (this.props.object.components.some(o => isCementOrGrass(o))) {
        if (!this.menu.lane.includes('hideDivider')) {
          this.menu.lane.push('hideDivider');
        }
      } else {
        this.menu.lane = this.menu.lane.filter(i => i !== 'hideDivider');
      }
    }
  };

  showShoulderCheckbox = () => {
    const isShoulder = o => o.type === 'shoulder';
    if (this.props.object.components) {
      if (this.props.object.components.some(o => isShoulder(o))) {
        if (!this.menu.lane.includes('hideShoulder')) {
          this.menu.lane.push('hideShoulder');
        }
      } else {
        this.menu.lane = this.menu.lane.filter(i => i !== 'hideShoulder');
      }
    }
  };

  render() {
    // FIXME: lots of state property not defined
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'lane' in this.menu && (
            <Tabs.TabPane tab="lane" key="lane">
              <LaneMenu
                item={this.menu.lane}
                stateObject={this.props.object}
                stallWidthHandle={this.stallWidthHandle}
                stallLength={this.stallLength}
                handleLanes={this.handleLanes}
                handleStreetWidth={this.handleStreetWidth}
                changeRoadLayer={this.changeRoadLayer}
                handleDividerType={this.handleDividerType}
                toggleDividerBackground={this.toggleDividerBackground}
                toggleShoulderBackground={this.toggleShoulderBackground}
                showDividerCheckbox={this.showDividerCheckbox}
                showShoulderCheckbox={this.showShoulderCheckbox}
              />
            </Tabs.TabPane>
          )}
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.props.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
                wallWidth={this.state.strokewidth}
                handleDirection={this.showDirection}
                handleTextbox={this.handleTextbox}
                patternHandle={this.patternHandle}
                handleSnapToStreet={this.handleSnapToStreet}
                handlehide={this.handlehide}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.props.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                // width={this.state.width}
                // height={this.state.height}
                // radius={this.state.radius}
                // angle={this.state.angle}
                // handlewidth={this.handlewidth}
                // handleheight={this.handleheight}
                // handleradius={this.handleradius}
                // handleAngle={this.handleAngle}
                // handlemarkslength={this.handlemarkslength}
                // pointWidth={this.state.pointwidth}
                // handlePointWidth={this.handlePointWidth}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
