import { useEffect, useState } from 'react';
import _ from 'lodash';
import emitter from '@src/data/Event';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { TransformRotateAngle, TransformScaleWidth, TransformScaleHeight } from '@src/actions/TransformHandle';
import { GetAngleArc, LengthBetweenPoints, ScalePoint } from '@src/data/CommonFunc';
import { FUNCTION_TYPE, FONT_WEIGHT_TYPE, FONT_STYLE_TYPE, ARROW_SHOW_TYPE } from '@src/constant';
import { convertToFeet, convertToStandard } from '@src/data/CommonFunction';
import { getTextPosition } from '@src/data/BusinessFun';

const updateObject = object => {
  workData.setObj(object);
  emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
};

const bindProperty = (object, propertyName) => {
  let onChange, getValue;
  switch (propertyName) {
    // include: CircleModal, EllipseModal, RectModal, SquareModal, SymbolModal
    // exclude: CloseShapeModal, StructureModal
    case 'sizeAngle': {
      getValue = targetObject => {
        const result = _.get(targetObject, 'rotateangle', 0);
        return _.round((result * 180) / Math.PI, 0);
      };
      onChange = angle => {
        const rad = GetAngleArc(angle % 360, 5);
        if (FUNCTION_TYPE.MARKER !== object.functype) {
          object.handlepoint = TransformRotateAngle(object.handlepoint, rad - object.rotateangle);
        }
        object.rotateangle = rad;
        workData.setObj(object);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: EllipseModal, RectModal, SymbolModal
    // exclude: SquareModal, StairsModal
    case 'sizeWidth': {
      getValue = targetObject => {
        let width = convertToFeet(LengthBetweenPoints(targetObject.handlepoint[0], targetObject.handlepoint[1]));
        width = Math.round(width * 1000) / 1000;
        return width || 0;
      };
      onChange = width => {
        const oldWidth = getValue(object);
        const scale = width / oldWidth;
        object.handlepoint = TransformScaleWidth(object.handlepoint, scale);
        object.width = convertToStandard(width);
        workData.setObj(object);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: EllipseModal, RectModal, SymbolModal
    case 'sizeHeight': {
      getValue = targetObject => {
        let height = convertToFeet(LengthBetweenPoints(targetObject.handlepoint[0], targetObject.handlepoint[3]));
        height = Math.round(height * 1000) / 1000;
        return height || 0;
      };
      onChange = height => {
        const oldHeight = getValue(object);
        const scale = height / oldHeight;
        object.handlepoint = TransformScaleHeight(object.handlepoint, scale);
        object.height = height;
        workData.setObj(object);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: DimensionLineModal, LineModal
    case 'sizeLength': {
      getValue = targetObject => {
        return LengthBetweenPoints(targetObject.handlepoint[0], targetObject.handlepoint[1]) || 0;
      };
      onChange = length => {
        const oldLength = getValue(object);
        const newLength = convertToStandard(length);
        const scale = newLength / oldLength;
        object.handlepoint[1] = ScalePoint(object.handlepoint[1], scale, scale, object.handlepoint[0]);
        workData.setObj(object);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    case 'arrowPosition': {
      const path = 'arrow.position';
      getValue = targetObject => _.get(targetObject, path, ARROW_SHOW_TYPE.NONE);
      onChange = position => {
        _.set(object, path, position);
        if (
          position !== ARROW_SHOW_TYPE.NONE &&
          _.get(object, 'arrow.type', ARROW_SHOW_TYPE.NONE) === ARROW_SHOW_TYPE.NONE
        ) {
          _.set(object, 'arrow.type', ARROW_SHOW_TYPE.ArrowSimple);
        } else if (position === ARROW_SHOW_TYPE.NONE) {
          _.set(object, 'arrow.type', ARROW_SHOW_TYPE.NONE);
        }
        workData.setObj(object);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    case 'arrowStyle': {
      const path = 'arrow.type';
      getValue = targetObject => _.get(targetObject, path, ARROW_SHOW_TYPE.NONE);
      onChange = style => {
        _.set(object, path, style);
        workData.setObj(object);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    case 'arrowSize': {
      getValue = targetObject => _.get(targetObject, 'arrow.width', 0);
      onChange = value => {
        _.set(object, 'arrow.width', value);
        _.set(object, 'arrow.height', value);
        workData.setObj(object);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: *
    case 'textText': {
      getValue = targetObject => targetObject.text.text;
      onChange = value => {
        let o = workData.getObject(object.operateid);
        o.text.text = value;
        workData.setObj(o);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: *
    case 'textSize': {
      getValue = targetObject => targetObject.text.size;
      onChange = size => {
        let o = workData.getObject(object.operateid);
        o.text.size = size;
        workData.setObj(o);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: *
    case 'textColor': {
      getValue = targetObject => targetObject.text.color;
      onChange = color => {
        let o = workData.getObject(object.operateid);
        o.text.color = color;
        workData.setObj(o);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: *
    case 'textBold': {
      getValue = targetObject => targetObject.text.bold;
      onChange = () => {
        let o = workData.getObject(object.operateid);
        if (o.text.bold === FONT_WEIGHT_TYPE.NORMAL) {
          o.text.bold = FONT_WEIGHT_TYPE.BOLD;
        } else if (o.text.bold === FONT_WEIGHT_TYPE.BOLD) {
          o.text.bold = FONT_WEIGHT_TYPE.NORMAL;
        }
        workData.setObj(o);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: *
    case 'textItalic': {
      getValue = targetObject => targetObject.text.italic;
      onChange = () => {
        let o = workData.getObject(object.operateid);
        if (o.text.italic === FONT_STYLE_TYPE.NORMAL) {
          o.text.italic = FONT_STYLE_TYPE.ITALIC;
        } else if (o.text.italic === FONT_STYLE_TYPE.ITALIC) {
          o.text.italic = FONT_STYLE_TYPE.NORMAL;
        }
        workData.setObj(o);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    // include: *
    case 'textPosition': {
      getValue = targetObject => null;
      onChange = () => {
        let o = workData.getObject(object.operateid);
        o.text.position = getTextPosition(o.text.position);
        workData.setObj(o);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      };
      break;
    }
    default:
      break;
  }
  return { onChange, getValue };
};

const useObjectProperty = (objectId, propertyName, listener = true) => {
  const object = workData.getObject(objectId);
  const { onChange, getValue } = bindProperty(object, propertyName);
  const initialValue = getValue(object);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const handleObjectUpdate = updateObject => {
      if (updateObject.operateid !== objectId) return;
      // no need to set default value now?
      const newValue = getValue(updateObject);
      setValue(newValue);
    };

    if (listener) {
      emitter.addListener(EVENT_EMIT_TYPE.OBJECT_UPDATED, handleObjectUpdate);
    }
    /**
     * 修复问题：当两个图形打开TextModal时，更换选中的图形，TextModal面板不会更新
     * 描述：一直到useState之前的initialValue还是正确的值，useState调用后，返回的value值是错误的
     * 原因：发现objectId更新后，没触发setValue
     */
    if (objectId) {
      handleObjectUpdate(object);
    }
    return () => {
      emitter.removeListener(EVENT_EMIT_TYPE.OBJECT_UPDATED, handleObjectUpdate);
    };
  }, [objectId, propertyName]);

  if (!objectId) {
    return [];
  } else {
    return [value, onChange];
  }
};

export default useObjectProperty;
