import { CHANGE_CATEGORY, TOGGLE_PIN, SET_PIN } from '@src/type/sidebar';

const initialState = {
  selectedCategory: undefined,
  pin: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.key,
      };
    }
    case SET_PIN: {
      return {
        ...state,
        pin: action.pin,
      };
    }
    case TOGGLE_PIN: {
      return {
        ...state,
        pin: !state.pin,
      };
    }
    default:
      return state;
  }
};

export default sidebarReducer;
