import { ARROW_SHOW_TYPE } from '@src/constant';
import { getArrowType } from '@src/data/BusinessFun';
import { convertToStandard, getPixelByUserUnit } from '@src/data/CommonFunction';
import {
  getDefaultFontSize,
  getLinePathData,
  getSpotCrossPathData,
  getSpotCrossWidth,
  initMeasurementCoords,
} from '@src/utils';
import React from 'react';
import PropTypes from 'prop-types';
import ArrowMarker from '../ArrowMarker';
import LncdText from '../LncdText';

function Measurement(props) {
  const { object } = props;
  const { operateid, pointList = [] } = object;
  const unitLength = 1 / getPixelByUserUnit();
  const originRadius = 6;
  const textProps = {
    ...object.text,
    size: getDefaultFontSize(),
  };

  const { endpoints, pc } = initMeasurementCoords(object);
  const head = endpoints.get('head');
  const tail = endpoints.get('tail');
  const left = endpoints.get('left');
  const right = endpoints.get('right');

  const sides = [left, right];
  const { x: cx, y: cy } = pc;

  let points;
  if (object.functype === 'StationLine') {
    points = pointList.map(item => {
      const { id, direction, distance, station } = item;

      const ptStation = pc.clone();
      ptStation.offsetToward(head.point, convertToStandard(station));

      const endpoint = sides.find(n => n.direction === direction);
      const ptDistance = pc.clone();
      ptDistance.offsetToward(endpoint.point, convertToStandard(distance));

      const vecDistance = pc.getVector(ptDistance);
      const vecStation = pc.getVector(ptStation);

      const move = vecDistance.add(vecStation);

      // the center point of station point
      const point = pc.clone();
      point.offset(move.vx, move.vy);

      const textPoint = point.clone();
      const offset = getSpotCrossWidth() * 2;
      textPoint.offset(offset, offset);

      return (
        <React.Fragment key={id}>
          <path d={getSpotCrossPathData(point)} />
          <LncdText {...textProps} point={textPoint} text={id} textAnchor={'left'} />
        </React.Fragment>
      );
    });
  } else if (object.functype === 'XYMeasurement') {
    points = pointList.map(item => {
      const { x, y, id } = item;

      const ptX = pc.clone();
      ptX.offsetToward(head.point, convertToStandard(x));

      const endpoint = sides[1];
      const ptY = pc.clone();
      ptY.offsetToward(endpoint.point, convertToStandard(y));

      const vecX = pc.getVector(ptX);
      const vecY = pc.getVector(ptY);
      const move = vecX.add(vecY);

      // the center point of station point
      const point = pc.clone();
      point.offset(move.vy, move.vx);

      const textPoint = point.clone();
      const offset = getSpotCrossWidth() * 2;
      textPoint.offset(offset, offset);

      return (
        <React.Fragment key={id}>
          <path d={getSpotCrossPathData(point)} />
          <LncdText {...textProps} point={textPoint} text={id} textAnchor={'left'} />
        </React.Fragment>
      );
    });
  }

  return (
    <g lncd-role={object.functype} pointerEvents="bounding-box" strokeWidth={unitLength}>
      <ArrowMarker
        id={operateid}
        width={unitLength * 6}
        height={unitLength * 6}
        type={ARROW_SHOW_TYPE.ArrowSimple}
        fill={object.style.stroke}
      />
      <path
        d={getLinePathData(head.point, tail.point)}
        markerStart={getArrowType(operateid, ARROW_SHOW_TYPE.StartOnly, 0)}
      />
      <path d={getLinePathData(left.point, right.point)} />
      <circle cx={cx} cy={cy} r={originRadius} fill="none" />
      {[head, tail, left, right].map(({ text, textPoint }) => (
        <LncdText key={text} {...textProps} point={textPoint} text={text} />
      ))}
      {points}
    </g>
  );
}

export default Measurement;
