import GeomPoint from '@src/data/GeomPoint';
import GeomLine from '@src/data/GeomLine';
import GeomArc from '@src/data/GeomArc';
import GeomPolyArc from '@src/data/GeomPolyArc';
import Utility from './Utility';
import UtilityGeom from './UtilityGeom';
import UtilityMath from './UtilityMath';

class GeomOffsetArc {
  /**
   *
   * @param {array} segments
   * @param {number} transitionRadius
   * @param {number} transitionLengthFactor
   * @param {number} transitionPercentFromStart
   * @param {number} stopOffset
   */
  constructor(segments, transitionRadius, transitionLengthFactor, transitionPercentFromStart, stopOffset) {
    this.axisSegments = segments || [];
    this.offsetArcSegments = [];
    this.transitionRadius = 30;
    this.transitionLengthFactor = 1.33;
    this.transitionPercentFromStart = 0.5;
    this.stopOffset = 0;
    this.transitionMidPoint = null;
    if (Utility.isTypeofNumber(transitionRadius)) {
      this.transitionRadius = transitionRadius;
    }
    if (Utility.isTypeofNumber(transitionLengthFactor)) {
      this.transitionLengthFactor = transitionLengthFactor;
    }
    if (Utility.isTypeofNumber(transitionPercentFromStart)) {
      this.transitionPercentFromStart = transitionPercentFromStart;
    }
    if (Utility.isTypeofNumber(stopOffset)) {
      this.stopOffset = stopOffset;
    }
  }

  /**
   *
   * @param {GeomPolyArc} gpa
   */
  _adjustPathParams(gpa) {
    var halfLen = gpa.getPointStart().distance(gpa.getPointStop()) / 2,
      len = gpa.getLength(),
      a0ee,
      a0ef,
      a0f0,
      quarterLen = halfLen / 2;
    if (this.transitionLengthFactor !== 0) {
      quarterLen /= this.transitionLengthFactor;
    }
    if (Math.abs(this.stopOffset) < 1) {
      this.stopOffset = 0;
    }
    if (Math.abs(this.stopOffset) > quarterLen) {
      a0ee = quarterLen;
      if (this.stopOffset > 0) {
        this.stopOffset = a0ee;
      } else {
        this.stopOffset = -a0ee;
      }
    }
    if (this.stopOffset !== 0 && this.transitionRadius > halfLen) {
      a0ee = halfLen / (this.transitionLengthFactor * 2);
      if (Math.abs(this.stopOffset) > a0ee) {
        if (this.stopOffset > 0) {
          this.stopOffset = a0ee;
        } else {
          this.stopOffset = -a0ee;
        }
      }
      this.transitionRadius = Math.abs(this.transitionLengthFactor * this.stopOffset * 2);
    }
    if (len === 0) {
      return;
    }
    a0ef = this.transitionRadius / len;
    a0f0 = 1 - a0ef;
    if (this.transitionPercentFromStart < a0ef) {
      this.transitionPercentFromStart = a0ef;
    } else {
      if (this.transitionPercentFromStart > a0f0) {
        this.transitionPercentFromStart = a0f0;
      }
    }
  }

  calculateOffsetPath() {
    let ptAlignStart, ptAlignEnd;
    let gpa = new GeomPolyArc(this.axisSegments);
    let len = gpa.getLength();
    let offset1, offset2, offset3;
    let centerPt;
    let point1, point2, point3, point4;
    let arcs = [];
    let arc1, arc2, arc3, arc4;
    let align1, align2, align3, align4;
    let r;
    let stripe1, stripe2;
    let pt1, pt2;
    let arcSweepFlag, arcNotSweepFlag, intersections, intersection, closePt, curvedCenter, isCCW;

    this.transitionRadius = Math.abs(this.transitionLengthFactor * this.stopOffset * 2);
    this.transitionMidPoint = gpa.getMidPoint();
    this._adjustPathParams(gpa);
    if (this.stopOffset === 0) {
      this.offsetArcSegments = gpa.clone().segments;
      return;
    }
    r = this.transitionRadius;
    align1 = gpa.getAlignmentAtLength(-r, 0).ptAlign;
    align2 = gpa.getAlignmentAtLength(len + r, 0).ptAlign;
    var isStraight = gpa.isStraight();
    centerPt = isStraight ? new GeomPoint(0, 0) : gpa.segments[0].getCircleCenter();
    curvedCenter = gpa.getPointAtLength(len / 2);
    isCCW = new UtilityMath().isCounterClockwise(gpa.getPointStart(), curvedCenter, gpa.getPointStop());
    offset1 = -this.stopOffset;
    offset2 = this.stopOffset;
    offset3 = -this.stopOffset;
    ptAlignStart = gpa.getAlignmentAtLength(0, -this.stopOffset).ptAlign;
    ptAlignEnd = gpa.getAlignmentAtLength(len, this.stopOffset).ptAlign;
    if (this.stopOffset < 0) {
      offset1 -= this.transitionRadius;
      offset2 += this.transitionRadius;
    } else {
      offset1 += this.transitionRadius;
      offset2 -= this.transitionRadius;
    }

    //
    align3 = gpa.getAlignmentAtLength(len, offset3).ptAlign;
    if (isStraight) {
      arc3 = new GeomLine(ptAlignStart, align3);
    } else {
      arc3 = new GeomArc(
        ptAlignStart,
        align3,
        ptAlignStart.distance(centerPt),
        new UtilityMath().isCounterClockwise(ptAlignStart, align3, centerPt) !== isCCW,
        isCCW
      );
    }

    //
    align4 = gpa.getAlignmentAtLength(0, this.stopOffset).ptAlign;
    if (isStraight) {
      arc4 = new GeomLine(align4, ptAlignEnd);
    } else {
      arc4 = new GeomArc(
        align4,
        ptAlignEnd,
        ptAlignEnd.distance(centerPt),
        new UtilityMath().isCounterClockwise(align4, ptAlignEnd, centerPt) !== isCCW,
        isCCW
      );
    }

    //
    point1 = gpa.pointOffsetNormal(align1, offset1);
    point2 = gpa.pointOffsetNormal(align2, offset1);
    if (isStraight) {
      stripe1 = new GeomLine(point1, point2);
    } else {
      stripe1 = new GeomArc(
        point1,
        point2,
        point1.distance(centerPt),
        new UtilityMath().isCounterClockwise(point1, point2, centerPt) !== isCCW,
        isCCW
      );
    }

    //
    point3 = gpa.pointOffsetNormal(align1, offset2);
    point4 = gpa.pointOffsetNormal(align2, offset2);
    if (isStraight) {
      stripe2 = new GeomLine(point3, point4);
    } else {
      stripe2 = new GeomArc(
        point3,
        point4,
        point3.distance(centerPt),
        new UtilityMath().isCounterClockwise(point3, point4, centerPt) !== isCCW,
        isCCW
      );
    }

    //
    this.transitionMidPoint = this._getTransitionMidPoint(gpa, stripe1, stripe2, offset1, offset2);
    // console.log('transitionMidPoint', this.transitionMidPoint);
    pt1 = gpa.pointOffsetNormal(this.transitionMidPoint, this.transitionRadius);
    pt2 = gpa.pointOffsetNormal(this.transitionMidPoint, -this.transitionRadius);
    arcSweepFlag = new GeomArc(
      pt1,
      pt2,
      pt1.distance(this.transitionMidPoint),
      !new UtilityMath().isCounterClockwise(pt1, pt2, this.transitionMidPoint),
      true
    );
    arcNotSweepFlag = new GeomArc(
      pt1,
      pt2,
      pt1.distance(this.transitionMidPoint),
      new UtilityMath().isCounterClockwise(pt1, pt2, this.transitionMidPoint),
      false
    );

    //
    if (isStraight) {
      intersections = new UtilityGeom().getPointsArcIntersectingLine(arcSweepFlag, stripe1, len);
    } else {
      intersections = new UtilityGeom().getPointsArcIntersectingArc(stripe1, arcSweepFlag);
    }
    intersection = intersections[0];
    if (!intersection) {
      this.offsetArcSegments = gpa.clone().segments;
      return;
    }
    closePt = arc3.getPointClosest(intersection);
    if (!closePt.isEqual(ptAlignStart)) {
      if (isStraight) {
        arcs.push(new GeomLine(ptAlignStart, closePt));
      } else {
        arcs.push(
          new GeomArc(
            ptAlignStart,
            closePt,
            centerPt.distance(ptAlignStart),
            new UtilityMath().isCounterClockwise(ptAlignStart, closePt, centerPt) !== isCCW,
            isCCW
          )
        );
      }
    }

    //
    arc1 = new GeomArc(
      closePt,
      this.transitionMidPoint,
      this.transitionRadius,
      new UtilityMath().isCounterClockwise(closePt, this.transitionMidPoint, intersection) !== this.stopOffset >= 0,
      this.stopOffset >= 0
    );
    arcs.push(arc1.clone());

    //
    if (isStraight) {
      intersections = new UtilityGeom().getPointsArcIntersectingLine(arcNotSweepFlag, stripe2, len);
    } else {
      intersections = new UtilityGeom().getPointsArcIntersectingArc(stripe2, arcNotSweepFlag);
    }
    intersection = intersections[0];
    if (!intersection) {
      this.offsetArcSegments = gpa.clone().segments;
      return;
    }

    //
    closePt = arc4.getPointClosest(intersection);
    arc2 = new GeomArc(
      this.transitionMidPoint,
      closePt,
      this.transitionRadius,
      new UtilityMath().isCounterClockwise(this.transitionMidPoint, closePt, intersection) !== this.stopOffset < 0,
      this.stopOffset < 0
    );
    arcs.push(arc2.clone());

    //
    if (!closePt.isEqual(ptAlignEnd)) {
      if (isStraight) {
        arcs.push(new GeomLine(closePt, ptAlignEnd));
      } else {
        arcs.push(
          new GeomArc(
            closePt,
            ptAlignEnd,
            centerPt.distance(ptAlignEnd),
            new UtilityMath().isCounterClockwise(closePt, ptAlignEnd, centerPt) !== isCCW,
            isCCW
          )
        );
      }
    }
    this.offsetArcSegments = arcs;
    return;
  }

  /**
   * 获取变换线段的中点
   * @param {GeomPolyArc} gpa
   * @param {GeomLine | GeomArc} stripe1
   * @param {GeomLine | GeomArc} stripe2
   * @param {number} offset1
   * @param {number} offset2
   */
  _getTransitionMidPoint(gpa, stripe1, stripe2, offset1, offset2) {
    let point, transitionDiameter, minRadius, maxRadius, c1, c2, theta, normalPt1, normalPt2, midPt;
    point = gpa.getPointAtLength(gpa.getLength() * this.transitionPercentFromStart);
    if (gpa.isStraight() || this.stopOffset === 0) {
      return point;
    }
    transitionDiameter = this.transitionRadius * 2;
    minRadius = Math.min(stripe1.r, stripe2.r);
    maxRadius = Math.max(stripe1.r, stripe2.r);
    c1 = (Math.pow(maxRadius, 2) + Math.pow(transitionDiameter, 2) - Math.pow(minRadius, 2)) / (maxRadius * 2);
    c2 = maxRadius - c1;
    theta = Math.acos(c2 / minRadius);
    normalPt1 = gpa.pointOffsetNormal(point, offset1);
    normalPt2 = gpa.pointOffsetNormal(point, offset2);
    midPt = gpa.getMidPoint();
    normalPt1.rotate(theta / 2, midPt);
    normalPt2.rotate(-theta / 2, midPt);
    return normalPt1.midPoint(normalPt2);
  }

  getPointStart() {
    return this.offsetArcSegments[0].getPointStart();
  }

  getPointStop() {
    return this.offsetArcSegments[this.offsetArcSegments.length - 1].getPointStop();
  }

  getSvgPathData() {
    var path = '';
    for (let i = 0; i < this.offsetArcSegments.length; i++) {
      var spd = this.offsetArcSegments[i].getSegmentPathData(0 === i);
      if (spd.length > 0) {
        if (0 === path.length) {
          path = spd;
        } else {
          path += ' ' + spd;
        }
      }
    }
    return path;
  }

  /**
   * 膨胀弧，向外弯曲的弧形
   * @param {number} distance
   */
  inflateAxis(distance) {
    let gpa = new GeomPolyArc(this.axisSegments);
    if (gpa.segments[0].declaredClass === 'GeomLine') {
      gpa.segments[0].offsetNormal(distance);
      this.axisSegments[0] = {
        type: 'line',
        ptStart: {
          x: gpa.segments[0].getPointStart().x,
          y: gpa.segments[0].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[0].getPointStop().x,
          y: gpa.segments[0].getPointStop().y,
        },
      };
    } else {
      let align = gpa.segments[0].getAlignmentAtLength(gpa.getLength() / 2, distance);
      let num = align.ptAlign.distance(gpa.segments[0].getCircleCenter());
      num -= gpa.segments[0].r;
      gpa.segments[0].inflate(num);
      this.axisSegments[0] = {
        type: 'arc',
        ptStart: {
          x: gpa.segments[0].getPointStart().x,
          y: gpa.segments[0].getPointStart().y,
        },
        ptStop: {
          x: gpa.segments[0].getPointStop().x,
          y: gpa.segments[0].getPointStop().y,
        },
        r: gpa.segments[0].r,
        largeArcFlag: gpa.segments[0].largeArcFlag,
        sweepFlag: gpa.segments[0].sweepFlag,
      };
    }
  }
}

export default GeomOffsetArc;
