import React from 'react';
import {
  ByTime,
  FindCircle,
  FindCircleLinePoint,
  getAngleBy2Points,
  GetArcAngle,
  GetArcCenter,
  GetArcPointsAngle,
  GetCenterInTwoPoints,
  GetCenter,
  GetCircleArcCenter2,
  GetCircleLineAcrossPoint,
  GetCirclePoint,
  GetCirclePointK,
  GetLineKB,
  GetRotateMatrix,
  IsArcDirection,
  IsBigArc,
  IsBigArc1,
  IsEqual,
  IsOneLine,
  IsUnderLine,
  IsZero,
  LengthArc,
  LengthBetweenPoints,
  matrixMultiply,
  pointTransform,
  RotatePoint,
  ScalePoint,
  toFixed,
  TowCircleAcrossPoints,
  TowPointVerticalAcross,
  TowPointVerticalLength,
  TowPointVerticalLengthByStatus,
  TowPointVerticalLengthPoints,
  GetCircleLineLength,
  IsArcRangePoint,
} from './CommonFunc';
import { userPtToClientPt, getUnitVector, scaleVector, checkClockwise, graham_scan } from './CommonFunction';
import ArrowLength from '@src/shapes/ArrowLength';
import { GetShapeStyle } from './CommonProps';
import { GetStreetArcTopPoint, GetStreetAreaBegin } from '@src/actions/StreetFunHandle';
import {
  ARROW_SHOW_TYPE,
  TEXT_POSITION_TYPE,
  THREE_POINT_STATUS_TYPE,
  TEXT_ANCHORS_TYPE,
  ANCHORS_TYPE,
  LENGTH_TYPE,
  DASH_ARRAY_TYPE,
  COLOR_TYPE,
  STREET_LINE_POSITION_TYPE,
  STREET_LINE_TYPE,
  STREET_STRIPES_TYPE,
  STREET_SPACE,
} from '@src/constant';

/**
 * get arrow type of shape
 * @param {string} id - operateId
 * @param {string} type - shape's type
 * @param {number} position 0开头 1 结尾 2中点
 * @returns {string}
 */
export function getArrowType(id, type, position) {
  var lastType = type;
  if (type === ARROW_SHOW_TYPE.StopOnly && position == 1) {
    lastType = ARROW_SHOW_TYPE.StopOnly + id;
  } else if (type === ARROW_SHOW_TYPE.StartOnly && position == 0) {
    lastType = ARROW_SHOW_TYPE.StartOnly + id;
  } else if (type == ARROW_SHOW_TYPE.BOTH) {
    if (position == 0) {
      lastType = ARROW_SHOW_TYPE.StartOnly + id;
    } else if (position == 1) {
      lastType = ARROW_SHOW_TYPE.StopOnly + id;
    }
  }
  lastType = 'url(#' + lastType + ')';
  return lastType;
}

/**
 * get text position
 * @param {TEXT_POSITION_TYPE} currentPosition
 * @returns {TEXT_POSITION_TYPE}
 */
export function getTextPosition(currentPosition) {
  const textPositions = [
    TEXT_POSITION_TYPE.MIDIUMTOP,
    TEXT_POSITION_TYPE.RIGHTTOP,
    TEXT_POSITION_TYPE.RIGHTBOTTOM,
    TEXT_POSITION_TYPE.MIDIUMBOTTOM,
    TEXT_POSITION_TYPE.LEFTBOTTOM,
    TEXT_POSITION_TYPE.LEFTTOP,
  ];
  var nowPosition = '';
  for (let i = 0; i < textPositions.length; i++) {
    if (textPositions[i] == currentPosition) {
      if (i >= textPositions.length - 1) {
        nowPosition = textPositions[0];
        break;
      } else {
        nowPosition = textPositions[i + 1];
        break;
      }
    }
  }
  return nowPosition;
}

/**
 * get stair's text position
 * @param {TEXT_POSITION_TYPE} currentPosition
 * @returns {TEXT_POSITION_TYPE}
 */
export function getStairTextPosition(currentPosition) {
  const textPositions = [
    TEXT_POSITION_TYPE.STAIRDIRECTION,
    TEXT_POSITION_TYPE.LEFTTOP,
    TEXT_POSITION_TYPE.MIDIUMTOP,
    TEXT_POSITION_TYPE.RIGHTTOP,
    TEXT_POSITION_TYPE.RIGHTBOTTOM,
    TEXT_POSITION_TYPE.MIDIUMBOTTOM,
    TEXT_POSITION_TYPE.LEFTBOTTOM,
  ];
  var nowPosition = '';
  for (let i = 0; i < textPositions.length; i++) {
    if (textPositions[i] == currentPosition) {
      if (i >= textPositions.length - 1) {
        nowPosition = textPositions[0];
        break;
      } else {
        nowPosition = textPositions[i + 1];
        break;
      }
    }
  }
  return nowPosition;
}

/**
 * 旋转矩阵
 * @param {[number, number]} origin
 * @param {number} angle
 */
export function GetMatrixRotate(origin, angle) {
  //1 先平移到原点
  var matrix1 = [1, 0, 0, 1, -origin[0], -origin[1]];
  //2 旋转
  var matrix2 = GetRotateMatrix(angle);
  //3 在移动回去
  var matrix3 = [1, 0, 0, 1, origin[0], origin[1]];
  //得到最后的矩阵
  var matrix = matrixMultiply(matrix3, matrix2);
  matrix = matrixMultiply(matrix, matrix1);
  return matrix;
}

export function GetArcCenterBy(point0, point1, point2, point) {
  /*
  var arcCenterPoint = [];
  var oldCircle = FindCircle(
    obj.handlepoint[0],
    obj.handlepoint[1],
    obj.handlepoint[2]
  );
  var isBigArc =
    IsBigArc(
      obj.handlepoint[0],
      obj.handlepoint[1],
      obj.handlepoint[2],
      oldCircle[2]
    ) === CIRCLE_SIZE_TYPE.BIGARC;
  var isShun =
    ByTime(obj.handlepoint[0], obj.handlepoint[2], obj.handlepoint[1]) ===
    THREE_POINT_STATUS_TYPE.BYTIME;
  var lenth12 = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  var newLenth12 = LengthBetweenPoints(point, obj.handlepoint[1]);
  var r = (oldCircle[2] * newLenth12) / lenth12;
  r = toFixed(r, 3);
  obj.handlepoint[0] = point;
  obj.handlepoint[2] = GetCircleArcCenter2(
    obj.handlepoint[0],
    obj.handlepoint[1],
    r,
    isBigArc,
    isShun
  );
  return obj;
  */
}

export function GetPathDPoints(point1, point2, arcCenter, markLength, markHeight) {
  var D = [];
  if (IsOneLine(point1, arcCenter, point2) === THREE_POINT_STATUS_TYPE.ONLINE) {
    let length = LengthBetweenPoints(point1, point2);
    let n = Math.round(length / markLength);
    for (let i = 0; i <= n; i++) {
      let center = GetCenter(point1, point2, i / n);
      let points = TowPointVerticalLengthPoints(point1, point2, center, markHeight);
      D.push('M' + String(points[0][0]) + ',' + String(points[0][1]));
      D.push('L' + String(points[1][0]) + ',' + String(points[1][1]));
    }
  } else {
    var circle = FindCircle(point1, point2, arcCenter);
    var outCircle = [circle[0], circle[1], circle[2] + markHeight];
    var inerCircle = [circle[0], circle[1], circle[2] - markHeight];
    var beginAngle = getAngleBy2Points(circle, point1);
    var endAngle = getAngleBy2Points(circle, point2);
    let isBigArc = IsBigArc(point1, point2, arcCenter, circle[2]);
    var angleAll = GetArcPointsAngle(point1, point2, circle, isBigArc);
    let arclength = LengthArc(point1, point2, arcCenter);
    var n = Math.round(arclength / markLength);
    var angle = angleAll / n;
    for (let i = 1; i <= angleAll; i = i + angle) {
      var arcAngle = beginAngle + i;
      let outPoint = [
        outCircle[0] + outCircle[2] * Math.cos(arcAngle),
        outCircle[1] + outCircle[2] * Math.sin(arcAngle),
      ];
      let inPoint = [
        inerCircle[0] + inerCircle[2] * Math.cos(arcAngle),
        inerCircle[1] + inerCircle[2] * Math.sin(arcAngle),
      ];
      D.push('M' + String(outPoint[0]) + ',' + String(outPoint[1]));
      D.push('L' + String(inPoint[0]) + ',' + String(inPoint[1]));
    }
  }
  return D;
}

export function GetLengthConnectLine(handlepoint) {
  var allLength = 0;
  for (let i = 0; i < handlepoint.length; i++) {
    if (handlepoint[i][2] === ANCHORS_TYPE.CONNECTNORMAL || handlepoint[i][2] === ANCHORS_TYPE.CLOSEEND) {
      var length = LengthArc(handlepoint[i - 2], handlepoint[i], handlepoint[i - 1]);
      allLength = allLength + length;
    }
  }
  return allLength;
}

export function GetHandlePointRange(handlePoint) {
  var xMin = 0,
    yMin = 0,
    xMax = 0,
    yMax = 0;
  for (let i = 0; i < handlePoint.length; i++) {
    if (handlePoint[i][0] < xMin || xMin == 0) {
      xMin = handlePoint[i][0];
    }
    if (handlePoint[i][0] > xMax || xMax == 0) {
      xMax = handlePoint[i][0];
    }
    if (handlePoint[i][1] < yMin || yMin == 0) {
      yMin = handlePoint[i][1];
    }
    if (handlePoint[i][1] > yMax || yMax == 0) {
      yMax = handlePoint[i][1];
    }
  }
  return [[xMin, yMin], [xMax, yMax]];
}

/**
 * 获取 handlepoint 的范围，即最小的xy坐标到最大的xy坐标
 * @param {number[]} handlePoint
 * @returns {[[number, number], [number, number]]}
 */
export function GetCloseShapeHandlePointRange(handlePoint) {
  var xMin = 0,
    yMin = 0,
    xMax = 0,
    yMax = 0;
  for (let i = 0; i < handlePoint.length; i++) {
    if (handlePoint[i][2] === ANCHORS_TYPE.CLOSEANGLE) continue;
    if (handlePoint[i][0] < xMin || xMin == 0) {
      xMin = handlePoint[i][0];
    }
    if (handlePoint[i][0] > xMax || xMax == 0) {
      xMax = handlePoint[i][0];
    }
    if (handlePoint[i][1] < yMin || yMin == 0) {
      yMin = handlePoint[i][1];
    }
    if (handlePoint[i][1] > yMax || yMax == 0) {
      yMax = handlePoint[i][1];
    }
  }
  return [[xMin, yMin], [xMax, yMax]];
}

export function GetClosedShapeCenterPoint(handlePoint) {
  let x = 0;
  let y = 0;
  let pointNumber = 0;

  for (let point of handlePoint) {
    if (point[2] === ANCHORS_TYPE.CONNECTCANGLE) {
      pointNumber = pointNumber + 1;
      x = x + point[0];
      y = y + point[1];
    }
  }
  const centerX = x / pointNumber;
  const centerY = y / pointNumber;
  return [centerX, centerY];
}

export function GetCloseShapeCenter(handlePoint) {
  var pointRange = GetCloseShapeHandlePointRange(handlePoint);
  var center = GetCenterInTwoPoints(pointRange[0], pointRange[1]);
  return center;
}

export function GetCLoseShapeAngle(handlePoint) {
  var length = handlePoint.length;
  if (handlePoint[length - 1][2] != ANCHORS_TYPE.CLOSEANGLE) return 0;
  var center = GetCloseShapeCenter(handlePoint);
  var angle = getAngleBy2Points(center, handlePoint[length - 1]);
  return angle;
}

/**
 * 获取 close shape 的旋转点
 * @param {number[]} handlePoint
 * @param {number} angle
 * @returns {[number, number]}
 */
export function GetCLoseShapeAnglePoint(handlePoint, angle) {
  var pointRange = GetCloseShapeHandlePointRange(handlePoint);
  var anglePoint = [pointRange[1][0] + LENGTH_TYPE.ANGLELENGTH, (pointRange[0][1] + pointRange[1][1]) / 2];
  var center = GetCenterInTwoPoints(pointRange[0], pointRange[1]);
  anglePoint = RotatePoint(anglePoint, angle, center);
  anglePoint[2] = ANCHORS_TYPE.CLOSEANGLE;
  return anglePoint;
}

export function GetClosedShapeAngle(handlePoint) {
  let length = handlePoint.length;

  if (handlePoint[length - 1][2] !== ANCHORS_TYPE.CLOSEANGLE) {
    return 0;
  }

  const center = GetClosedShapeCenterPoint(handlePoint);
  const angle = getAngleBy2Points(center, handlePoint[length - 1]);

  return angle;
}

export function GetClosedShapeAnglePoint(handlePoint, angle) {
  var pointRange = GetCloseShapeHandlePointRange(handlePoint);
  var anglePoint = [pointRange[1][0] + LENGTH_TYPE.ANGLELENGTH, (pointRange[0][1] + pointRange[1][1]) / 2];
  var center = GetCenterInTwoPoints(pointRange[0], pointRange[1]);
  anglePoint = RotatePoint(anglePoint, angle, center);
  anglePoint[2] = ANCHORS_TYPE.CLOSEANGLE;

  return anglePoint;
}

export function GetConnectText(handlepoint, textPosition, marksHeight, marksIsExist, strokewidth, textsize) {
  var textPoint = [];
  var angletext = 0;
  var textanchor = '';
  var uplength = strokewidth + textsize;
  if (marksIsExist) {
    uplength = uplength + marksHeight;
  }
  if (textPosition === TEXT_POSITION_TYPE.MIDIUMTOP) {
    var index = Math.round(handlepoint.length / 2);
    textanchor = TEXT_ANCHORS_TYPE.MIDDLE;
    if (handlepoint[index][2] != ANCHORS_TYPE.CONNECTNORMAL) {
      index--;
      if (
        IsOneLine(handlepoint[index - 2], handlepoint[index], handlepoint[index - 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        textPoint = [center[0] - strokewidth, center[1] - strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index - 1], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index - 1], handlepoint[index]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index - 2], handlepoint[index - 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        var textPoint = FindCircleLinePoint(outCircle, center);
        var k = GetCirclePointK(outCircle, textPoint);
        angletext = Math.atan(k);
      }
    } else {
      if (
        IsOneLine(handlepoint[index - 2], handlepoint[index], handlepoint[index - 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = handlepoint[index - 1];
        textPoint = [center[0] - strokewidth, center[1] - strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index - 2], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index - 2], handlepoint[index]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index - 2], handlepoint[index - 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = handlepoint[index - 1];
        var textPoint = FindCircleLinePoint(outCircle, center);
        var k = GetCirclePointK(outCircle, textPoint);
        angletext = Math.atan(k);
      }
    }
  } else if (textPosition === TEXT_POSITION_TYPE.RIGHTTOP) {
    var index = handlepoint.length - 2;
    textanchor = TEXT_ANCHORS_TYPE.MIDDLE;
    if (handlepoint[index][2] === ANCHORS_TYPE.CLOSEEND) {
      if (
        IsOneLine(handlepoint[index - 2], handlepoint[index], handlepoint[index - 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        textPoint = [center[0] - strokewidth, center[1] - strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index - 2], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index - 2], handlepoint[index]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index - 2], handlepoint[index - 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        var textPoint = FindCircleLinePoint(outCircle, center);
        var k = GetCirclePointK(outCircle, textPoint);
        angletext = Math.atan(k);
      }
    }
  } else if (textPosition === TEXT_POSITION_TYPE.RIGHTBOTTOM) {
    var index = handlepoint.length - 2;
    textanchor = TEXT_ANCHORS_TYPE.MIDDLE;
    if (handlepoint[index][2] === ANCHORS_TYPE.CLOSEEND) {
      if (
        IsOneLine(handlepoint[index - 2], handlepoint[index], handlepoint[index - 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        textPoint = [center[0] + strokewidth, center[1] + strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index - 2], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index - 2], handlepoint[index]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index - 2], handlepoint[index - 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        var textPoint = FindCircleLinePoint(inerCircle, center);
        var k = GetCirclePointK(inerCircle, textPoint);
        angletext = Math.atan(k);
      }
    }
  } else if (textPosition === TEXT_POSITION_TYPE.MIDIUMBOTTOM) {
    var index = Math.round(handlepoint.length / 2);
    textanchor = TEXT_ANCHORS_TYPE.MIDDLE;
    if (handlepoint[index][2] != ANCHORS_TYPE.CONNECTNORMAL) {
      index--;
      if (
        IsOneLine(handlepoint[index - 2], handlepoint[index], handlepoint[index - 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        textPoint = [center[0] + strokewidth, center[1] + strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index - 1], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index - 1], handlepoint[index]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index - 2], handlepoint[index - 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index - 1]);
        var textPoint = FindCircleLinePoint(inerCircle, center);
        var k = GetCirclePointK(inerCircle, textPoint);
        angletext = Math.atan(k);
      }
    } else {
      if (
        IsOneLine(handlepoint[index - 2], handlepoint[index], handlepoint[index - 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = handlepoint[index - 1];
        textPoint = [center[0] + strokewidth, center[1] + strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index - 2], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index - 2], handlepoint[index]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index - 2], handlepoint[index - 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = handlepoint[index - 1];
        var textPoint = FindCircleLinePoint(inerCircle, center);
        var k = GetCirclePointK(inerCircle, textPoint);
        angletext = Math.atan(k);
      }
    }
  } else if (textPosition === TEXT_POSITION_TYPE.LEFTBOTTOM) {
    var index = 0;
    textanchor = TEXT_ANCHORS_TYPE.MIDDLE;
    if (handlepoint[index][2] === ANCHORS_TYPE.CONNECTBEGIN) {
      if (
        IsOneLine(handlepoint[index + 2], handlepoint[index], handlepoint[index + 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index + 1]);
        textPoint = [center[0] + strokewidth, center[1] + strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index + 2], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index], handlepoint[index + 2]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index + 2], handlepoint[index + 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index + 1]);
        var textPoint = FindCircleLinePoint(inerCircle, center);
        var k = GetCirclePointK(inerCircle, textPoint);
        angletext = Math.atan(k);
      }
    }
  } else if (textPosition === TEXT_POSITION_TYPE.LEFTTOP) {
    var index = 0;
    textanchor = TEXT_ANCHORS_TYPE.MIDDLE;
    if (handlepoint[index][2] === ANCHORS_TYPE.CONNECTBEGIN) {
      if (
        IsOneLine(handlepoint[index + 2], handlepoint[index], handlepoint[index + 1]) === THREE_POINT_STATUS_TYPE.ONLINE
      ) {
        let center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index + 1]);
        textPoint = [center[0] - strokewidth, center[1] - strokewidth];
        textPoint = TowPointVerticalLength(handlepoint[index], handlepoint[index + 2], center, uplength, textPoint);
        angletext = getAngleBy2Points(handlepoint[index], handlepoint[index + 2]);
      } else {
        var circle = FindCircle(handlepoint[index], handlepoint[index + 2], handlepoint[index + 1]);
        var outCircle = [circle[0], circle[1], circle[2] + strokewidth];
        var inerCircle = [circle[0], circle[1], circle[2] - textsize];
        if (marksIsExist) {
          outCircle[2] = outCircle[2] + marksHeight;
          inerCircle[2] = inerCircle[2] - marksHeight;
        }
        var center = GetCenterInTwoPoints(handlepoint[index], handlepoint[index + 1]);
        var textPoint = FindCircleLinePoint(outCircle, center);
        var k = GetCirclePointK(outCircle, textPoint);
        angletext = Math.atan(k);
      }
    }
  }
  return {
    textpoint: textPoint,
    angletext: angletext,
    textanchor: textanchor,
  };
}

export function GetClosedText(handlepoint, textPosition, marksHeight, marksIsExist, strokewidth, textsize) {
  let textPoint = [];
  let angleText = 0;
  let uplength = strokewidth + textsize;

  if (marksIsExist) {
    uplength = uplength + marksHeight;
  }

  let index = Math.round(handlepoint.length / 2);
  let textAnchor = TEXT_ANCHORS_TYPE.MIDDLE;

  index--;

  const circle = FindCircle(handlepoint[index], handlepoint[index - 2], handlepoint[index - 1]);
  const outCircle = [circle[0], circle[1], circle[2] + strokewidth];
  const inerCircle = [circle[0], circle[1], circle[2] - textsize];

  if (marksIsExist) {
    outCircle[2] = outCircle[2] + marksHeight;
    inerCircle[2] = inerCircle[2] - marksHeight;
  }

  const polygonPoints = [];
  const polygonOriginalPoints = [];

  handlepoint.forEach(item => {
    if (item[2] !== ANCHORS_TYPE.CONNECTNORMAL) {
      polygonPoints.push({
        latitude: item[0],
        longitude: item[1],
      });

      polygonOriginalPoints.push(item);
    }
  });

  if (polygonPoints.length < 3) {
    const beginPoint = handlepoint.find(item => {
      return item[2] === ANCHORS_TYPE.CONNECTBEGIN;
    });

    polygonPoints.shift({
      latitude: beginPoint[0],
      longitude: beginPoint[1],
    });
  }

  const centerOfGravity = getCenterOfGravityPoint(polygonPoints);
  const centerPoints = [centerOfGravity.latitude, centerOfGravity.longitude];

  textPoint = [centerPoints[0] - strokewidth, centerPoints[1] - strokewidth];
  angleText = getAngleBy2Points(handlepoint[index - 1], handlepoint[index]);

  return {
    textpoint: textPoint,
    angletext: angleText,
    textanchor: textAnchor,
  };
}

//两条线相交点
export function TowLineAcrossPoint(linePoints1, linePoints2) {
  var kb1 = GetLineKB(linePoints1[0], linePoints1[1]);
  var kb2 = GetLineKB(linePoints2[0], linePoints2[1]);
  if (IsZero(kb1[0]) && kb2[0] === 1) {
    return [linePoints2[0][0], linePoints1[0][1]];
  } else if (kb1[0] === 1 && IsZero(kb2[0])) {
    return [kb1[1], kb2[1]];
  } else if (IsZero(kb1[0]) && kb2[0] === 1) {
    return [kb2[1], kb1[1]];
  } else if (IsEqual(kb1[0], kb2[0])) {
    var center1 = GetCenterInTwoPoints(linePoints1[0], linePoints1[1]);
    var center2 = GetCenterInTwoPoints(linePoints2[0], linePoints2[1]);
    return GetCenterInTwoPoints(center1, center2);
  } else {
    var x = (kb1[1] - kb2[1]) / (kb2[0] - kb1[0]);
    var y = kb1[0] * x + kb1[1];
    if (kb1[0] === 1) {
      x = kb1[1];
      y = kb2[0] * x + kb2[1];
    } else if (kb2[0] === 1) {
      x = kb2[1];
      y = kb1[0] * x + kb1[1];
    }

    x = toFixed(x);
    y = toFixed(y);
    return [x, y];
  }
}
export function TowLineAcrossPoint1(kb1, kb2) {
  if ((IsZero(kb1[0]) && IsZero(kb2[0])) || (kb1[0] === 1 && kb1[0] === 1)) {
    //
    return [kb1[1], kb1[1]];
  } else if (kb1[0] === 1 && IsZero(kb2[0])) {
    return [kb1[1], kb2[1]];
  } else if (IsZero(kb1[0]) && kb2[0] === 1) {
    return [kb2[1], kb1[1]];
  } else {
    var x = (kb1[1] - kb2[1]) / (kb2[0] - kb1[0]);
    var y = kb1[0] * x + kb1[1];
    x = toFixed(x);
    y = toFixed(y);
    return [x, y];
  }
}

/**
 *
 * @param {number[]} pointDest
 * @param {number[]} pointSrc
 */
export function CopyPoint(pointDest, pointSrc) {
  pointDest[0] = pointSrc[0];
  pointDest[1] = pointSrc[1];
}

export function AddStructureData() {
  return {
    strokedasharray: DASH_ARRAY_TYPE.solid,
    stroke: COLOR_TYPE.BLACK,
    strokewidth: 6,
    selectflag: false,
    nearline: -1,
    nearx: -1, //横向最近
    neary: -1, //纵向最近
    index: -1, //
  };
}

export function GetPathArcD(D, p1, p2, r, isBigArc, isArcDirection) {
  D.push('M' + String(p1[0]) + ',' + String(p1[1]));
  D.push('A' + String(r) + ',' + String(r));
  D.push('0');
  D.push(isBigArc + ',' + isArcDirection);
  D.push(String(p2[0]) + ',' + String(p2[1]));
}
export function GetPathLineD(D, p1, p2) {
  D.push('M' + String(p1[0]) + ',' + String(p1[1]));
  D.push('L' + String(p2[0]) + ',' + String(p2[1]));
}
export function GetPathLineD1(D, p1) {
  D.push('L' + String(p1[0]) + ',' + String(p1[1]));
}

export function GetPathArcD1(D, p2, r, isBigArc, isArcDirection) {
  D.push('A' + String(r) + ',' + String(r));
  D.push('0');
  D.push(isBigArc + ',' + isArcDirection);
  D.push(String(p2[0]) + ',' + String(p2[1]));
}

export function GetPathArcDL(D, p1, p2, r, isBigArc, isArcDirection) {
  D.push('L' + String(p1[0]) + ',' + String(p1[1]));
  D.push('A' + String(r) + ',' + String(r));
  D.push('0');
  D.push(isBigArc + ',' + isArcDirection);
  D.push(String(p2[0]) + ',' + String(p2[1]));
}
export function GetPathTowBessel(D, p1, p2, p3) {
  D.push('M' + String(p1[0]) + ',' + String(p1[1]));
  D.push('Q' + String(p2[0]) + ',' + String(p2[1]));
  D.push(String(p3[0]) + ',' + String(p3[1]));
}
export function GetPathTowBessel1(D, p2, p3) {
  D.push('Q' + String(p2[0]) + ',' + String(p2[1]));
  D.push(String(p3[0]) + ',' + String(p3[1]));
}
export function GetPathTowBesselL(D, p1, p2, p3) {
  D.push('L' + String(p1[0]) + ',' + String(p1[1]));
  D.push('Q' + String(p2[0]) + ',' + String(p2[1]));
  D.push(String(p3[0]) + ',' + String(p3[1]));
}
export function GetPathBesselS(D, mP, cP1, cP2, cP3, sP1, sP2) {
  //D.push("M" + String(mP[0]) + "," + String(mP[1]));
  D.push('Q' + String(cP1[0]) + ',' + String(cP1[1]));
  // D.push(String(cP2[0]) + "," + String(cP2[1]));
  D.push(String(cP3[0]) + ',' + String(cP3[1]));
  D.push('S' + String(sP1[0]) + ',' + String(sP1[1]));
  D.push(String(sP2[0]) + ',' + String(sP2[1]));
}
export function GetPathBesselS1(D, cP1, cP3, sP1, sP2) {
  D.push('Q' + String(cP1[0]) + ',' + String(cP1[1]));
  D.push(String(cP3[0]) + ',' + String(cP3[1]));
  D.push('S' + String(sP1[0]) + ',' + String(sP1[1]));
  D.push(String(sP2[0]) + ',' + String(sP2[1]));
}
export function AddStreetData(leftIndex, rightIndex) {
  if (leftIndex == null) leftIndex = -1;
  if (rightIndex === null) rightIndex = -1;
  var data = {
    leftIndex: leftIndex,
    rightindex: rightIndex,
  };
  return data;
}
export function AddStreetLineData() {
  var data = {
    stroke: COLOR_TYPE.BLACK,
    strokedasharray: '96,48',
    strokewidth: 4,
    stripetype: DASH_ARRAY_TYPE.solid, //用于设置道路线的类型
    position: STREET_LINE_POSITION_TYPE.CETNRE, //
    isUnderLine: true,
    topleftr: 0, //辅道上拉距离
    toprightr: 0, //右侧辅道上拉距离
    pathend: 5 / 8, //左侧辅道终点
    pathrightend: 5 / 8, //右侧辅道起点
    upstate: false, //左侧辅道上下
    uprightstate: false,
    // selectlane: -1,
    selectline: false,
    streetlinetype: STREET_LINE_TYPE.NONE, //到路线的类型 直线 toway 等等
    topline: -5, //道路线上面 索引
    bottomline: -5, //道路线下面索引
    specialtop: -3, //如果是 2way painted wide
    specialbottom: -3, //如果是 2way painted wide
    splitpattem: [],
    scalesplitleft: true,
    splitstripettype: STREET_STRIPES_TYPE.splitpattem,
  };
  return data;
}

export function GetShapes(shapes) {
  let shpapes = shapes.map(obj => {
    return obj;
  });
  return shpapes;
}

// destPoint 为streetBegin或者streetEnd点，origin点为对向 中点线点
export function GetStreetSidePoint(destPoint, origin) {
  let width = LengthBetweenPoints(destPoint, origin);
  let scale = (width - STREET_SPACE.SIDEANCHROSSPACE) / width;
  let point = ScalePoint(destPoint, scale, scale, origin);
  return point;
}

// destPoint 为streetBegin或者streetEnd点，origin点为对向 中点线点
export function GetStreetArcSidePoint(newPoint, outSidePoint, sidePoint) {
  let moveMatrix = [1, 0, 0, 1, newPoint[0] - outSidePoint[0], newPoint[1] - outSidePoint[1]];
  var point = pointTransform(moveMatrix, sidePoint);
  return point;
}

//destPoint，origin为中点线起始点或者终点 求得 origin 一侧的streetBeigin或者streetEnd点
export function GetStreetSideOutPoint(destPoint, origin) {
  let width = LengthBetweenPoints(destPoint, origin);
  let scale = (width + STREET_SPACE.SIDEANCHROSSPACE) / width;
  let point = ScalePoint(destPoint, scale, scale, origin);
  return point;
}

//曲线下
export function GetStreetArcSideOutPoint(circle, sidePoint, isBytime) {
  let sideAngle = getAngleBy2Points(circle, sidePoint);
  let offAngle = STREET_SPACE.SIDEANCHROSSPACE / circle[2];
  let angle = isBytime ? sideAngle - offAngle : sideAngle + offAngle;
  var point = GetCirclePoint(circle, angle);
  return point;
}

//直线下得到streettop 三个点
export function GetStreetTopPoint(origin, width, height, angle) {
  let point0 = [origin[0], origin[1] - height];
  point0 = RotatePoint(point0, angle, origin);
  let point1 = [origin[0] + width / 2, origin[1] - height];
  point1 = RotatePoint(point1, angle, origin);
  let point2 = [origin[0] + width, origin[1] - height];
  point2 = RotatePoint(point2, angle, origin);
  return [point0, point1, point2];
}

//直线下streetbootm三个点
export function GetStreetBottomPoint(origin, width, height, angle) {
  let point0 = [origin[0], origin[1] + height];
  point0 = RotatePoint(point0, angle, origin);
  let point1 = [origin[0] + width / 2, origin[1] + height];
  point1 = RotatePoint(point1, angle, origin);
  let point2 = [origin[0] + width, origin[1] + height];
  point2 = RotatePoint(point2, angle, origin);
  return [point0, point1, point2];
}

//曲线下得到steetbottom三个点
export function GetStreetArcBottomPoint(circle, height, p1, p2, p3, isByTime) {
  let bigCircle = [circle[0], circle[1], circle[2] + height];
  let smallCircle = [circle[0], circle[1], circle[2] - height];
  let topCircle = isByTime ? smallCircle : bigCircle;
  let angle1 = getAngleBy2Points(topCircle, p1);
  let point0 = GetCirclePoint(topCircle, angle1);
  let angle2 = getAngleBy2Points(topCircle, p2);
  let point1 = GetCirclePoint(topCircle, angle2);
  let angle3 = getAngleBy2Points(topCircle, p3);
  let point2 = GetCirclePoint(topCircle, angle3);
  return [point0, point1, point2];
}

export function GetStructureAngle(handlePoint) {
  let length = handlePoint.length;

  if (handlePoint[length - 1][2] !== ANCHORS_TYPE.CLOSEANGLE) {
    return 0;
  }

  const center = GetStructureCenterPoint(handlePoint);
  const angle = getAngleBy2Points(center, handlePoint[length - 1]);

  return angle;
}

export function GetStructureCenterPoint(handlePoint) {
  let x = 0;
  let y = 0;
  const points = [];

  handlePoint.forEach((point, index) => {
    if (index === 0 && point[2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
      points.push(point);
    } else if (index > 0 && point[2] === ANCHORS_TYPE.STRUCTUREEND) {
      points.push(point);
    }
  });

  points.forEach(point => {
    x = x + point[0];
    y = y + point[1];
  });

  const pointNumber = points.length;

  const centerX = x / pointNumber;
  const centerY = y / pointNumber;

  return [centerX, centerY];
}

export function RotateStreetOutSidePoint(circle, oldBeginPoint, newBeginPoint, origin) {
  let oldAngle = getAngleBy2Points(origin, oldBeginPoint);
  let newAngle = getAngleBy2Points(origin, newBeginPoint);
  let afterPoint = RotatePoint(circle, newAngle - oldAngle, origin);
  var newcircle = [afterPoint[0], afterPoint[1], circle[2]];
  return newcircle;
}

export function GetStreetMovePoint(topPoint, origin, topleftr, upstate) {
  var destPoint = [];
  let height = LengthBetweenPoints(topPoint, origin);
  if (upstate) {
    let scale = (topleftr + height) / height;
    destPoint = ScalePoint(topPoint, scale, scale, origin);
  } else {
    let scale = topleftr / height;
    destPoint = ScalePoint(origin, scale, scale, topPoint);
  }
  return destPoint;
}

//判断一个点是否在上方，p2->p1方向 为上， p1->p2方向为下
export function GetStreetPointIstop(topPoint, p1, p2) {
  //以p1为起始点
  let angle1 = getAngleBy2Points(p1, topPoint);
  let angle2 = getAngleBy2Points(p1, p2);
  if (IsEqual(angle1, angle2)) return false;
  else return true;
}

export function CreateArrowLength(shapes, p1, p2, key) {
  if (IsEqual(p1[0], p2[0]) && IsEqual(p1[1], p2[1])) {
    return null;
  }
  const space = 12;
  let left = TowPointVerticalLengthByStatus(p1, p2, p1, space, false);
  let right = TowPointVerticalLengthByStatus(p1, p2, p2, space, false);
  shapes.push(<ArrowLength key={String(key) + 'streetarrowlength'} p1={left} p2={right} istop={true} />);
}

//circle为0，1，2的圆不为 操作点
export function GetStreetLaneCenter(R, circle, p1, p2, n, isBytime) {
  let point = [];
  if (IsZero(R)) {
    point = GetCenter(p1, p2, n);
  } else {
    let pointCircle = [circle[0], circle[1], R];
    point = GetArcCenter(pointCircle, p1, p2, n, isBytime);
  }
  return point;
}

//R不是move点坐标
export function GetStreetMoveCenterLeft(srcPoint, topR, R, circle, p1, p2, n, isBytime, upstate) {
  let endPoint = GetStreetLaneCenter(R, circle, p1, p2, n, isBytime);
  let centerPoint = [0, 0];
  if (IsZero(R)) {
    let topPoint = TowPointVerticalLengthByStatus(p1, p2, endPoint, topR, !upstate);
    let topCircle = [topPoint[0], topPoint[1], topR];
    let centerSrc = GetCenterInTwoPoints(srcPoint, p1);
    let lineKB = GetLineKB(p1, p2);
    lineKB[1] = centerSrc[1] - lineKB[0] * centerSrc[0];
    let points = GetCircleLineAcrossPoint(topCircle, lineKB);
    if (LengthBetweenPoints(centerSrc, points[0]) < LengthBetweenPoints(centerSrc, points[1])) {
      centerPoint = points[0];
    } else {
      centerPoint = points[1];
    }
  } else {
    let topCircle = [circle[0], circle[1], LengthBetweenPoints(srcPoint, circle)];
    let topPoint = FindCircleLinePoint(topCircle, endPoint);
    let centerLength = Math.sqrt((3 * topR * topR) / 4);
    let isUnder = upstate ? true : false;
    centerPoint = TowPointVerticalLengthByStatus(
      topPoint,
      endPoint,
      GetCenterInTwoPoints(topPoint, endPoint),
      centerLength,
      isUnder
    );
    let centerSrc = GetCenterInTwoPoints(srcPoint, p1);
    let centerCircle = [circle[0], circle[1], LengthBetweenPoints(centerSrc, circle)];
    centerPoint = FindCircleLinePoint(centerCircle, centerPoint);
  }
  return centerPoint;
}
//street获取 辅道 图形
export function CreateStreetMoveShapeLeft(shapes, srcPoint, R, circle, p1, p2, isBytime, isBigArc, style, key) {
  let endPoint = GetStreetLaneCenter(R, circle, p1, p2, p1[2].pathend, isBytime);
  let centerPoint = GetStreetMoveCenterLeft(
    srcPoint,
    p1[2].topleftr,
    R,
    circle,
    p1,
    p2,
    p1[2].pathend,
    isBytime,
    p1[2].upstate
  );
  let beginPoint = RotatePoint(endPoint, Math.PI, centerPoint);

  let D = [];
  let topPointR = R == 0 ? 0 : LengthBetweenPoints(circle, srcPoint);
  let hidePoint = TowPointVerticalAcross(p1, p2, beginPoint);
  if (!IsZero(R)) {
    beginPoint = FindCircleLinePoint([circle[0], circle[1], topPointR], beginPoint);
    hidePoint = FindCircleLinePoint([circle[0], circle[1], R], beginPoint);
  }
  let direction = isBytime ? '1' : '0';
  let isDreciton = p1[2].upstate ? '1' : '0';
  let isBeginBigArc = IsBigArc1(srcPoint, beginPoint, isBytime, [circle[0], circle[1], topPointR]);
  GetPathArcD(D, srcPoint, beginPoint, topPointR, isBeginBigArc, direction);
  GetPathArcD1(D, centerPoint, p1[2].topleftr, '0', isDreciton);
  GetPathArcD1(D, endPoint, p1[2].topleftr, '0', isDreciton === '1' ? '0' : '1');
  let hideD = [];
  GetPathArcD(hideD, hidePoint, endPoint, R, '0', direction);
  shapes.push(<path d={hideD.join(' ')} key={String(key * 2) + 'hidepath'} {...GetShapeStyle().STREETWHITESTYELE} />);
  shapes.push(<path d={D.join(' ')} key={key} {...style} />);
}
//R不是move点坐标  p1 应该和 srcPoint对应的点
export function GetStreetMoveCenterRight(srcPoint, topR, R, circle, p1, p2, n, isBytime, upstate) {
  let endPoint = GetStreetLaneCenter(R, circle, p1, p2, n, !isBytime);
  let centerPoint = [0, 0];
  if (IsZero(R)) {
    let topPoint = TowPointVerticalLengthByStatus(p1, p2, endPoint, topR, !upstate);
    let topCircle = [topPoint[0], topPoint[1], topR];
    let centerSrc = GetCenterInTwoPoints(srcPoint, p1);
    let lineKB = GetLineKB(p1, p2);
    lineKB[1] = centerSrc[1] - lineKB[0] * centerSrc[0];
    let points = GetCircleLineAcrossPoint(topCircle, lineKB);
    if (LengthBetweenPoints(centerSrc, points[0]) < LengthBetweenPoints(centerSrc, points[1])) {
      centerPoint = points[0];
    } else {
      centerPoint = points[1];
    }
  } else {
    let topCircle = [circle[0], circle[1], LengthBetweenPoints(srcPoint, circle)];
    let topPoint = FindCircleLinePoint(topCircle, endPoint);
    let centerLength = Math.sqrt((3 * topR * topR) / 4);
    let isUnder = upstate ? false : true;
    centerPoint = TowPointVerticalLengthByStatus(
      topPoint,
      endPoint,
      GetCenterInTwoPoints(topPoint, endPoint),
      centerLength,
      isUnder
    );
    let centerSrc = GetCenterInTwoPoints(srcPoint, p1);
    let centerCircle = [circle[0], circle[1], LengthBetweenPoints(centerSrc, circle)];
    centerPoint = FindCircleLinePoint(centerCircle, centerPoint);
  }
  return centerPoint;
}
//street获取 辅道 图形 p1 应该和 srcPoint对应的点
export function CreateStreetMoveShapeRight(shapes, srcPoint, R, circle, p1, p2, isBytime, isBigArc, style, key) {
  let endPoint = GetStreetLaneCenter(R, circle, p1, p2, p1[2].pathend, !isBytime);
  let centerPoint = GetStreetMoveCenterRight(
    srcPoint,
    p1[2].topleftr,
    R,
    circle,
    p1,
    p2,
    p1[2].pathend,
    isBytime,
    p1[2].upstate
  );
  let beginPoint = RotatePoint(endPoint, Math.PI, centerPoint);

  let D = [];
  let topPointR = R == 0 ? 0 : LengthBetweenPoints(circle, srcPoint);
  let hidePoint = TowPointVerticalAcross(p1, p2, beginPoint);
  if (!IsZero(R)) {
    beginPoint = FindCircleLinePoint([circle[0], circle[1], topPointR], beginPoint);
    hidePoint = FindCircleLinePoint([circle[0], circle[1], R], beginPoint);
  }
  let direction = isBytime ? '0' : '1';
  let isDreciton = p1[2].upstate ? '0' : '1';
  let isBeginBigArc = IsBigArc1(srcPoint, beginPoint, isBytime, [circle[0], circle[1], topPointR]);
  GetPathArcD(D, srcPoint, beginPoint, topPointR, isBeginBigArc, direction);
  GetPathArcD1(D, centerPoint, p1[2].topleftr, '0', isDreciton);
  GetPathArcD1(D, endPoint, p1[2].topleftr, '0', isDreciton === '1' ? '0' : '1');
  let hideD = [];
  GetPathArcD(hideD, hidePoint, endPoint, R, '0', direction);
  shapes.push(<path d={hideD.join(' ')} key={String(key * 2) + 'hidepath'} {...GetShapeStyle().STREETWHITESTYELE} />);
  shapes.push(<path d={D.join(' ')} key={key} {...style} />);
}
export function GetStreetPathD(D, anchors, index) {
  if (index == null) index = 0;
  if (index >= anchors.length - 1) return;
  let begin = anchors[index];
  let centerindex = anchors[index][3].rightindex;
  let center = anchors[centerindex];
  let endindex = center[3].rightindex;
  let endpoint = anchors[endindex];
  let circle = FindCircle(begin, center, endpoint);
  let isBigArc = IsBigArc(begin, endpoint, center, circle[2]);
  let isArcDirection = IsArcDirection(begin, center, endpoint);
  let areaBeginIndex = GetStreetAreaBegin(anchors);
  if (index == areaBeginIndex) GetPathArcD(D, begin, endpoint, circle[2], isBigArc, isArcDirection);
  else GetPathArcD1(D, endpoint, circle[2], isBigArc, isArcDirection);
  //到头了直接返回
  if (endpoint[3].rightindex === -1) return;
  else GetStreetPathD(D, anchors, endindex);
}

//求取三个点距离一定距离位置的 另外三个点
export function GetLineHeightPoints(p1, p2, p3, height, isTopLine) {
  let circle = FindCircle(p1, p2, p3);
  var laneBegin = [],
    laneCenter = [],
    laneEnd = [];
  //中线下面得道路高度相反
  let streetHeight = height;
  if (isTopLine) streetHeight = -height;
  if (IsZero(circle[2])) {
    let angle = getAngleBy2Points(p1, p3);
    let length = LengthBetweenPoints(p1, p3);
    laneBegin = [p1[0], p1[1] + streetHeight];
    laneCenter = [p1[0] + length / 2, p1[1] + streetHeight];
    laneEnd = [p1[0] + length, p1[1] + streetHeight];
    laneBegin = RotatePoint(laneBegin, angle, p1);
    laneCenter = RotatePoint(laneCenter, angle, p1);
    laneEnd = RotatePoint(laneEnd, angle, p1);
  } else {
    let isBytime = ByTime(p1, p2, p3) === THREE_POINT_STATUS_TYPE.BYTIME;
    let arcPoints = GetStreetArcTopPoint(circle, -streetHeight, p1, p2, p3, isBytime);
    laneBegin = arcPoints[0];
    laneCenter = arcPoints[1];
    laneEnd = arcPoints[2];
  }
  return [laneBegin, laneCenter, laneEnd];
}
/**
 *
 * 两条线段 是否 有相交
 * @export
 * @param {*} linePoints1  [p1,p2]
 * @param {*} linePoints2
 * @returns
 */
export function IsRangePoint(linePoints1, linePoints2) {
  // double u,v,w,z;//分别记录两个向量
  //a linePoints1[0]
  //b linePoints1[1]
  //c linePoints2[0]
  //d linePoints2[1]
  // u=(c.x-a.x)*(b.y-a.y)-(b.x-a.x)*(c.y-a.y);

  // v=(d.x-a.x)*(b.y-a.y)-(b.x-a.x)*(d.y-a.y);

  // w=(a.x-c.x)*(d.y-c.y)-(d.x-c.x)*(a.y-c.y);

  // z=(b.x-c.x)*(d.y-c.y)-(d.x-c.x)*(b.y-c.y);

  // return (u*v<=0.00000001 && w*z<=0.00000001);
  let u =
    (linePoints2[0][0] - linePoints1[0][0]) * (linePoints1[1][1] - linePoints1[0][1]) -
    (linePoints1[1][0] - linePoints1[0][0]) * (linePoints2[0][1] - linePoints1[0][1]);
  let v =
    (linePoints2[1][0] - linePoints1[0][0]) * (linePoints1[1][1] - linePoints1[0][1]) -
    (linePoints1[1][0] - linePoints1[0][0]) * (linePoints2[1][1] - linePoints1[0][1]);
  let w =
    (linePoints1[0][0] - linePoints2[0][0]) * (linePoints2[1][1] - linePoints2[0][1]) -
    (linePoints2[1][0] - linePoints2[0][0]) * (linePoints1[0][1] - linePoints2[0][1]);
  let z =
    (linePoints1[1][0] - linePoints2[0][0]) * (linePoints2[1][1] - linePoints2[0][1]) -
    (linePoints2[1][0] - linePoints2[0][0]) * (linePoints1[1][1] - linePoints2[0][1]);
  return u * v <= 0.001 && w * z <= 0.001;
}

/**
 * 三个点组成的线 判断两条这样的线 是否相交
 *
 * @export
 * @param {*} points1
 * @param {*} points2
 * @returns
 */
export function IsPoyLineAcross(points1, points2) {
  let circle1 = FindCircle(points1[0], points1[1], points1[2]);
  let circle2 = FindCircle(points2[0], points2[1], points2[2]);
  if (IsZero(circle1[2]) && IsZero(circle2[2])) {
    if (IsRangePoint([points1[0], points[2]], [points2[0], points2[2]])) return true;
    else return false;
  } else if (!IsZero(circle1[2]) && IsZero(circle2[2])) {
    let lineKB2 = GetLineKB(points2[0], points2[2]);
    if (GetCircleLineLength(circle1, lineKB2) < circle1[2]) {
      let acrossPoints = GetCircleLineAcrossPoint(circle1, lineKB2);
    }
  }
}

/**
 * 目标点是在上面还是下面, 判断 三个点
 * @export
 * @param {*} p1
 * @param {*} p2
 * @param {*} p3
 * @param {*} pDest
 * @returns
 */
export function IsPointUnderPoints(p1, p2, p3, pDest) {
  let circle = FindCircle(p1, p2, p3);
  let isUnder = true;
  if (IsZero(circle[2])) {
    isUnder = IsUnderLine(p1, p3, pDest);
  } else {
    let isBytime = ByTime(p1, p2, p3) === THREE_POINT_STATUS_TYPE.BYTIME;
    if (isBytime) {
      if (LengthBetweenPoints(pDest, circle) > circle[2]) isUnder = false;
      else isUnder = true;
      // // console.log("dd",LengthBetweenPoints(pDest, circle) , circle[2])
    } else {
      if (LengthBetweenPoints(pDest, circle) > circle[2]) isUnder = true;
      else isUnder = false;
    }
  }
  return isUnder;
}

//get angle between 2 lines
/*
const angle = getAngleOfTwoLines(
  {
    x: x1 - x0,
    y: y1 - y0,
  },
  {
    x: x2 - x0,
    y: y2 - y0,
  }
);
*/

/**
 * 拉伸十字路口4个相交点，从另外两个相交点中线上延长即可
 * @export
 * @param {*} crossData
 * @returns
 */
export function strechAcrossData(crossData) {
  let cx,
    cy,
    vn,
    newArr,
    newAcrossData = [],
    scale = 1.07,
    scaledVector,
    points = crossData.concat();
  for (var i = 0; i < points.length; i++) {
    if (!points[i] || points[i].length == 0) continue;
    //求中点
    cx = (points[i][0][0] + points[i][2][0]) / 2;
    cy = (points[i][0][1] + points[i][2][1]) / 2;
    //求单位向量
    vn = getUnitVector({
      x: points[i][1][0] - cx,
      y: points[i][1][1] - cy,
    });

    newArr = points[i].concat();

    scaledVector = scaleVector(scale, vn, {
      x: newArr[1][0] - cx,
      y: newArr[1][1] - cy,
    });
    //在单位向量方向上缩放1.1倍
    newArr[1][0] = scaledVector.x + cx;
    newArr[1][1] = scaledVector.y + cy;

    //        // console.log(newArr)
    newAcrossData.push(newArr.concat());
  }
  points = newArr = undefined;
  return newAcrossData;
}

/**
 * sort street cross points to clock wise without handle point
 * @export
 * @param {*} points
 * @param {boolean} [withHandles=true]
 * @returns
 */
export function sortStreetCrossPointsToClockwise(points, withHandles = true) {
  let tmpArr = [],
    _points = [],
    _ptOfScreen,
    _x,
    _y,
    _x0,
    _y0,
    _x1,
    _y1,
    n = 0;

  //将每个相交点的3个贝塞尔控制交点按照顺时针排列
  for (var i = 0; i < points.length; i++) {
    if (!points[i] || typeof points[i] != 'object' || points[i].length == 0) continue;

    //把图形坐标转成屏幕坐标，并保存交叉路口的3个控制点坐标
    _ptOfScreen = userPtToClientPt(points[i][1][0], points[i][1][1]);
    _x = _ptOfScreen[0];
    _y = _ptOfScreen[1];

    _points.push({
      x: _x,
      y: _y,
      _p: points[i][1],
    });

    if (withHandles) {
      _ptOfScreen = userPtToClientPt(points[i][0][0], points[i][0][1]);
      _x0 = _ptOfScreen[0];
      _y0 = _ptOfScreen[1];

      _ptOfScreen = userPtToClientPt(points[i][2][0], points[i][2][1]);
      _x1 = _ptOfScreen[0];
      _y1 = _ptOfScreen[1];

      _points.push({
        x: _x0,
        y: _y0,
        _p: points[i][0],
      });

      _points.push({
        x: _x1,
        y: _y1,
        _p: points[i][2],
      });

      n += 3;
    } else {
      n += 1;
    }
  }

  if (_points.length > 0) {
    //使用凸包算法，得到按逆时针排列的相交的点集
    var desArr = [];
    graham_scan(_points, desArr, n);

    _points = [];

    //把原坐标复制到_points
    for (var j = 0; j < desArr.length; j++) {
      if (!desArr[j]) continue;
      _points.push(desArr[j]._p.concat());
    }
    //使用凸包算法继续处理3个控制点排序
    // for (var j = 0; j < desArr.length; j++) {
    //   //  if (!_points[j] || _points[j].length == 0) continue;
    //   tmpArr = [];
    //   graham_scan(desArr[j].crossArr, tmpArr, 3);
    //   for (var k = 0; k < tmpArr.length; k++) {
    //     _points.push(tmpArr[k]._p.concat());
    //   }
    // }

    desArr = tmpArr = undefined;
    _points.push(_points[0]);
    return _points;
  }

  return [];
}

export function isAcrossDataUseful(acrossData) {
  for (var m = 0; m < acrossData.length; m++) {
    if (acrossData[m] && typeof acrossData[m] === 'object' && acrossData[m].length > 0) return true;
  }
  return false;
}

/**
 * 获取不规则多边形重心
 * @param [{latitude, longitude}] mPoints
 * @see https://blog.csdn.net/u010010664/article/details/78685171
 * @return
 */
export function getCenterOfGravityPoint(mPoints) {
  let area = 0.0; // 多边形面积
  let Gx = 0.0; // 重心的 x
  let Gy = 0.0; // 重心的 y
  for (let i = 1; i <= mPoints.length; i++) {
    let iLat = mPoints[i % mPoints.length].latitude;
    let iLng = mPoints[i % mPoints.length].longitude;
    let nextLat = mPoints[i - 1].latitude;
    let nextLng = mPoints[i - 1].longitude;
    let temp = (iLat * nextLng - iLng * nextLat) / 2.0;
    area += temp;
    Gx += (temp * (iLat + nextLat)) / 3.0;
    Gy += (temp * (iLng + nextLng)) / 3.0;
  }
  Gx = Gx / area;
  Gy = Gy / area;
  return {
    latitude: Gx,
    longitude: Gy,
  };
}
