import Utility from './Utility';

class GeomPathOffset {
  /** @type {number} */
  fromStart;

  /** @type {number} */
  fromStartPercent;

  /** @type {number} */
  normal;

  /**
   *
   * @param {number} fromStart
   * @param {number} fromStartPercent
   * @param {number} normal
   */
  constructor(fromStart, fromStartPercent, normal) {
    this.fromStart = 0;
    this.fromStartPercent = 0;
    this.normal = 0;
    if (Utility.isTypeofNumber(fromStart)) {
      this.fromStart = fromStart;
    }
    if (Utility.isTypeofNumber(fromStartPercent)) {
      this.fromStartPercent = fromStartPercent;
    }
    if (Utility.isTypeofNumber(normal)) {
      this.normal = normal;
    }
  }
}

export default GeomPathOffset;
