import React from 'react';
import {
  GetLineHeightPoints,
  GetPathArcD,
  GetPathArcD1,
  GetShapes,
  TowLineAcrossPoint,
  GetPathBesselS1,
} from '@src/data/BusinessFun';
import {
  FindCircle,
  IsArcDirection,
  IsBigArc,
  IsZero,
  GetCenter,
  getAngleBy2Points,
  GetCirclePoint,
  FindCircleLinePointK,
  GetArcCenter,
  ByTime,
  GetCirclePointK,
  LengthBetweenPoints,
  IsBigArc1,
  RotatePoint,
  GetCenterInTwoPoints,
  GetLineKB,
  GetCircleLineAcrossPoint,
  ScalePoint,
} from '@src/data/CommonFunc';
import {
  GetStreetAreaBegin,
  GetStreetBeginTopPoint,
  GetStreetEndTopPoint,
  GetStreetAreaEnd,
  getStreetLanePoint,
} from '@src/actions/StreetFunHandle';
import { GetShapePath } from '../ShapeFun';
import { GetOffArcCenterLinePoints, GetOffArcLinePath } from '@src/actions/StreetOffArcFunHandle';
import {
  CIRCLE_SIZE_TYPE,
  COLOR_TYPE,
  DASH_ARRAY_TYPE,
  STREET_SPACE,
  STREET_STRIPES_TYPE,
  THREE_POINT_STATUS_TYPE,
} from '@src/constant';

const HEIGHT = 1;

class StreetOffsetLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: props.points,
      linedata: props.linedata,
      archeight: props.archeight,
      arccenter: props.arccenter,
      arcpoints: props.arcpoints,
      lineheight: props.lineheight,
      istop: props.istop,
      leftArcPoint: [],
      rightArcPoint: [],
    };
    // this.setArcSidePoint();
  }
  UNSAFE_componentWillReceiveProps({ points, linedata, archeight, arccenter, arcpoints, lineheight, istop }) {
    this.setState({
      points: points,
      linedata: linedata,
      archeight: archeight,
      arccenter: arccenter,
      arcpoints: arcpoints,
      lineheight: lineheight,
      istop: istop,
    });
    this.setArcSidePoint();
  }
  setArcSidePoint() {
    let leftPoint = []; // TowLineAcrossPoint([bottomPoints[0],bottomPoints[1]],[arcPoints[0],arcPoints[2]]);
    let rightPoint = []; // TowLineAcrossPoint([arcPoints[0],arcPoints[2]],[topPoints[0],topPoints[2]]);
    let points = this.state.points;
    let topPoints = GetLineHeightPoints(points[0], points[1], points[2], this.state.archeight, true);
    let bottomPoints = GetLineHeightPoints(points[0], points[1], points[2], this.state.archeight, false);
    let arcPoints = this.state.arcpoints;
    let circle = FindCircle(points[0], points[1], points[2]);
    if (IsZero(circle[2])) {
      leftPoint = TowLineAcrossPoint([bottomPoints[0], bottomPoints[1]], [arcPoints[0], arcPoints[2]]);
      rightPoint = TowLineAcrossPoint([arcPoints[0], arcPoints[2]], [topPoints[0], topPoints[2]]);
    } else {
      let bottomR = LengthBetweenPoints(circle, bottomPoints[0]);
      let topR = LengthBetweenPoints(circle, topPoints[2]);
      let lineKB = GetLineKB(arcPoints[0], arcPoints[2]);
      let acrossLeft = GetCircleLineAcrossPoint([circle[0], circle[1], bottomR], lineKB);
      if (LengthBetweenPoints(acrossLeft[0], bottomPoints[1]) > LengthBetweenPoints(acrossLeft[1], bottomPoints[1]))
        leftPoint = acrossLeft[1];
      else leftPoint = acrossLeft[0];
      let acrossRight = GetCircleLineAcrossPoint([circle[0], circle[1], topR], lineKB);
      if (LengthBetweenPoints(acrossRight[0], topPoints[1]) > LengthBetweenPoints(acrossRight[1], topPoints[1]))
        rightPoint = acrossRight[1];
      else rightPoint = acrossRight[0];
    }
    this.setState({ leftArcPoint: leftPoint, rightArcPoint: rightPoint });
  }
  getArcPathD(D, points) {
    let circle = FindCircle(points[0], points[1], points[2]);
    let bottomPoints = GetLineHeightPoints(points[0], points[1], points[2], this.state.archeight, false);
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [];
    let isBytime = ByTime(points[2], points[1], points[0]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let beginTopPoint = GetStreetBeginTopPoint(this.state.linedata, bottomPoints[0], bottomPoints[1], bottomPoints[2]);
    let beginTopR = 0;
    let isArcDirection = '0';
    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[0], points[2]);
      arcEndPoint = GetCenter(bottomPoints[0], this.state.leftArcPoint, this.state.linedata.pathend);
      arcBeginPoint = GetCirclePoint(
        [arcEndPoint[0], arcEndPoint[1], this.state.linedata.topleftr * 2],
        angle + Math.PI
      );
      let arcTopAngle = this.state.linedata.upstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.topleftr],
        arcTopAngle
      );
    } else {
      isArcDirection = IsArcDirection(points[0], points[1], points[2]);
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      let circleBottom = FindCircle(bottomPoints[0], bottomPoints[1], bottomPoints[2]);
      arcEndPoint = GetArcCenter(
        circleBottom,
        bottomPoints[0],
        this.state.leftArcPoint,
        this.state.linedata.pathend,
        !isBytime
      );
      let angleArcEnd = getAngleBy2Points(circleBottom, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (isBytime
          ? (this.state.linedata.topleftr * 2) / circleBottom[2]
          : (-this.state.linedata.topleftr * 2) / circleBottom[2]);
      arcBeginPoint = GetCirclePoint(circleBottom, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circleBottom[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circleBottom);
    }
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);
  }
  beginArcLine(shapes, beginIndex) {
    let points = this.state.points;
    let centerIndex = points[beginIndex][3].rightindex;
    let endIndex = points[centerIndex][3].rightindex;
    let circle = FindCircle(points[beginIndex], points[centerIndex], points[endIndex]);
    let bottomPoints = GetLineHeightPoints(
      points[beginIndex],
      points[centerIndex],
      points[endIndex],
      this.state.archeight,
      false
    );
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [];
    let isBytime = ByTime(points[2], points[1], points[0]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let beginTopPoint = GetStreetBeginTopPoint(this.state.linedata, bottomPoints[0], bottomPoints[1], bottomPoints[2]);
    let beginTopR = 0;
    let isArcDirection = '0';
    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[beginIndex], points[endIndex]);
      arcEndPoint = GetCenter(bottomPoints[0], this.state.leftArcPoint, this.state.linedata.pathend);
      arcBeginPoint = GetCirclePoint(
        [arcEndPoint[0], arcEndPoint[1], this.state.linedata.topleftr * 2],
        angle + Math.PI
      );
      let arcTopAngle = this.state.linedata.upstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.topleftr],
        arcTopAngle
      );
    } else {
      isArcDirection = IsArcDirection(points[0], points[1], points[2]);
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      let circleBottom = FindCircle(bottomPoints[0], bottomPoints[1], bottomPoints[2]);
      arcEndPoint = GetArcCenter(
        circleBottom,
        bottomPoints[0],
        this.state.leftArcPoint,
        this.state.linedata.pathend,
        !isBytime
      );
      let angleArcEnd = getAngleBy2Points(circleBottom, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (isBytime
          ? (this.state.linedata.topleftr * 2) / circleBottom[2]
          : (-this.state.linedata.topleftr * 2) / circleBottom[2]);
      arcBeginPoint = GetCirclePoint(circleBottom, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circleBottom[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circleBottom);
    }
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let D = [],
      Dtop = [],
      DBottom = [];
    let DHide = [];
    GetPathArcD(DHide, arcBeginPoint, arcEndPoint, circle[2], CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    shapes.push(
      GetShapePath(DHide, JSON.stringify(this.linedata) + 'beginArcHide', true, {
        stroke: COLOR_TYPE.TRANSPARENT,
        strokewidth: this.state.linedata.strokewidth + 3,
        fill: COLOR_TYPE.NONE,
      })
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);
    let topPointsBike = GetLineHeightPoints(points[beginIndex], points[centerIndex], points[endIndex], HEIGHT, true);
    let bottomPointsBike = GetLineHeightPoints(
      points[beginIndex],
      points[centerIndex],
      points[endIndex],
      HEIGHT,
      false
    );
    this.getArcPathD(Dtop, topPointsBike);
    this.getArcPathD(DBottom, bottomPointsBike);
    this.getLineShape(shapes, D, Dtop, DBottom, JSON.stringify(this.linedata) + 'beginarc');
  }
  getEndArcPathD(D, points) {
    let circle = FindCircle(points[0], points[1], points[2]);
    let topPoints = GetLineHeightPoints(points[0], points[1], points[2], this.state.archeight, true);
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [];
    let isBytime = ByTime(points[2], points[1], points[0]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let beginTopPoint = GetStreetEndTopPoint(this.state.linedata, topPoints[0], topPoints[1], topPoints[2]);
    let beginTopR = 0;
    let isArcDirection = '0';
    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[0], points[2]);
      arcEndPoint = GetCenter(topPoints[2], this.state.rightArcPoint, this.state.linedata.pathrightend);
      arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], this.state.linedata.toprightr * 2], angle);
      let arcTopAngle = this.state.linedata.uprightstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.toprightr],
        arcTopAngle
      );
    } else {
      isArcDirection = IsArcDirection(points[2], points[1], points[0]);
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      let circleTop = FindCircle(topPoints[0], topPoints[1], topPoints[2]);
      arcEndPoint = GetArcCenter(
        circleTop,
        topPoints[2],
        this.state.rightArcPoint,
        this.state.linedata.pathrightend,
        isBytime
      );
      let angleArcEnd = getAngleBy2Points(circleTop, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (!isBytime
          ? (this.state.linedata.toprightr * 2) / circleTop[2]
          : (-this.state.linedata.toprightr * 2) / circleTop[2]);
      arcBeginPoint = GetCirclePoint(circleTop, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circleTop[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circleTop);
    }
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);
  }
  endArcLine(shapes, endIndex) {
    let points = this.state.points;
    let circle = FindCircle(points[0], points[1], points[2]);
    let topPoints = GetLineHeightPoints(points[0], points[1], points[2], this.state.archeight, true);
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [];
    let isBytime = ByTime(points[2], points[1], points[0]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let beginTopPoint = GetStreetEndTopPoint(this.state.linedata, topPoints[0], topPoints[1], topPoints[2]);
    let beginTopR = 0;
    let isArcDirection = '0';
    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[0], points[2]);
      arcEndPoint = GetCenter(topPoints[2], this.state.rightArcPoint, this.state.linedata.pathrightend);
      arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], this.state.linedata.toprightr * 2], angle);
      let arcTopAngle = this.state.linedata.uprightstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.toprightr],
        arcTopAngle
      );
    } else {
      isArcDirection = IsArcDirection(points[2], points[1], points[0]);
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      let circleTop = FindCircle(topPoints[0], topPoints[1], topPoints[2]);
      arcEndPoint = GetArcCenter(
        circleTop,
        topPoints[2],
        this.state.rightArcPoint,
        this.state.linedata.pathrightend,
        isBytime
      );
      let angleArcEnd = getAngleBy2Points(circleTop, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (!isBytime
          ? (this.state.linedata.toprightr * 2) / circleTop[2]
          : (-this.state.linedata.toprightr * 2) / circleTop[2]);
      arcBeginPoint = GetCirclePoint(circleTop, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circleTop[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circleTop);
    }
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let D = [],
      Dtop = [],
      DBottom = [];
    let DHide = [];
    GetPathArcD(DHide, arcBeginPoint, arcEndPoint, circle[2], CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    shapes.push(
      GetShapePath(DHide, JSON.stringify(this.linedata) + 'beginArcHide', true, {
        stroke: COLOR_TYPE.TRANSPARENT,
        strokewidth: this.state.linedata.strokewidth + 3,
        fill: COLOR_TYPE.NONE,
      })
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);
    let topPointsBike = GetLineHeightPoints(points[0], points[1], points[2], HEIGHT, true);
    let bottomPointsBike = GetLineHeightPoints(points[0], points[1], points[2], HEIGHT, false);
    this.getEndArcPathD(Dtop, topPointsBike);
    this.getEndArcPathD(DBottom, bottomPointsBike);
    this.getLineShape(shapes, D, Dtop, DBottom, JSON.stringify(this.linedata) + 'endArc');
  }
  shapes = shapes => {
    let points = this.state.points;
    let Dtop = [],
      DBottom = [],
      D = [];

    let beginIndex = GetStreetAreaBegin(points);
    let begin = points[beginIndex];
    let center = points[begin[3].rightindex];
    let endpoint = points[center[3].rightindex];
    let topPoints = GetLineHeightPoints(begin, center, endpoint, HEIGHT, true);

    let bottomPoints = GetLineHeightPoints(begin, center, endpoint, HEIGHT, false);
    let arcPoints = this.state.arcpoints;
    let bottomArcPoints = GetLineHeightPoints(
      this.state.arcpoints[0],
      this.state.arcpoints[1],
      this.state.arcpoints[2],
      HEIGHT,
      false
    );
    let TopArcPoints = GetLineHeightPoints(
      this.state.arcpoints[0],
      this.state.arcpoints[1],
      this.state.arcpoints[2],
      HEIGHT,
      true
    );

    let arcR = LengthBetweenPoints(this.state.arcpoints[0], this.state.arcpoints[2]) / 2;
    GetOffArcLinePath(D, this.state.archeight, points, arcPoints, arcR);
    GetOffArcLinePath(DBottom, this.state.archeight, bottomPoints, bottomArcPoints, arcR - HEIGHT);
    GetOffArcLinePath(Dtop, this.state.archeight, topPoints, TopArcPoints, arcR + HEIGHT);
    this.getLineShape(shapes, D, Dtop, DBottom, JSON.stringify(this.state.linedata));
    let endIndex = GetStreetAreaEnd(points);
    if (!IsZero(this.state.linedata.topleftr)) this.beginArcLine(shapes, beginIndex);
    if (!IsZero(this.state.linedata.toprightr)) this.endArcLine(shapes, endIndex);
  };
  getLineShape(shapes, D, Dtop, DBottom, key) {
    let stripetype = this.state.linedata.stripetype;
    if (stripetype === STREET_STRIPES_TYPE.dash) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeDasharray={DASH_ARRAY_TYPE.dashdot} />);
    } else if (stripetype === STREET_STRIPES_TYPE.solid) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeDasharray={DASH_ARRAY_TYPE.LINE} />);
    } else if (stripetype === STREET_STRIPES_TYPE.doublesolid) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray={DASH_ARRAY_TYPE.LINE} />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
    } else if (stripetype === STREET_STRIPES_TYPE.doubledash) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray={DASH_ARRAY_TYPE.dashdot} />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray={DASH_ARRAY_TYPE.dashdot} />);
    } else if (stripetype === STREET_STRIPES_TYPE.dashsolid) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray={DASH_ARRAY_TYPE.dashdot} />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
    } else if (stripetype === STREET_STRIPES_TYPE.soliddash) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray={DASH_ARRAY_TYPE.dashdot} />);
    } else if (stripetype === STREET_STRIPES_TYPE.reflectors) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeDasharray={DASH_ARRAY_TYPE.Dash2} />);
    } else if (stripetype === STREET_STRIPES_TYPE.hide) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} stroke={COLOR_TYPE.TRANSPARENT} />);
    } else if (stripetype === STREET_STRIPES_TYPE.splitpattem) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
    } else {
      shapes.push(<path d={D.join(' ')} key={'streetline' + key} />);
    }
  }

  render() {
    let commonProps = {
      stroke: this.state.linedata.stroke,
      strokeWidth: this.state.linedata.strokewidth,
      strokeDasharray: this.state.linedata.strokedasharray,
      fill: COLOR_TYPE.NONE,
    };
    let shape = [];
    this.shapes(shape);
    return (
      <g id={'streetoffsetline'} {...commonProps}>
        {GetShapes(shape)}
      </g>
    );
  }
}

export default StreetOffsetLine;
