import React from 'react';
import { FindCircle, IsArcDirection, IsBigArc } from '@src/data/CommonFunc';
import { ANCHORS_TYPE } from '@src/constant';
import { getHandles } from "../ConnectedLines/SelectConnectedLines";

class SelectCloseShapes extends React.Component {
  constructor(props) {
    super(props);
  }
  getPathShape = object => {
    if (object.handlepoint.length < 3) return null;
    var D = [];
    for (let i = 0; i < object.handlepoint.length; i++) {
      if (object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTBEGIN) {
        D.push('M' + String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      } else if (object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTNORMAL) {
        var cirlcleLeft = FindCircle(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1]);
        D.push('A' + String(cirlcleLeft[2]) + ',' + String(cirlcleLeft[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1], cirlcleLeft[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i - 2], object.handlepoint[i - 1], object.handlepoint[i])
        );
        D.push(String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      } else if (object.handlepoint[i][2] === ANCHORS_TYPE.CLOSEEND) {
        var cirlcleLeft = FindCircle(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1]);
        D.push('A' + String(cirlcleLeft[2]) + ',' + String(cirlcleLeft[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1], cirlcleLeft[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i - 2], object.handlepoint[i - 1], object.handlepoint[i])
        );
        D.push(String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));

        var cirlcleRight = FindCircle(object.handlepoint[0], object.handlepoint[i], object.handlepoint[i + 1]);
        D.push('A' + String(cirlcleRight[2]) + ',' + String(cirlcleRight[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[0], object.handlepoint[i], object.handlepoint[i + 1], cirlcleRight[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i], object.handlepoint[i + 1], object.handlepoint[0])
        );
        D.push(String(object.handlepoint[0][0]) + ',' + String(object.handlepoint[0][1]));
      }
    }
    return <path d={D.join(' ')} />;
  };

  render() {
    const { props } = this;
    return (
      <g>
        {this.getPathShape(props.object)}
        {getHandles(props.object.handlepoint)}
      </g>
    );
  }
}

export default SelectCloseShapes;
