import React from 'react';
import _ from 'lodash';
import GeomPolyArc from '@src/data/GeomPolyArc';
import { clientPtToUserPt } from '@src/data/CommonFunction';
import GeomStripe from '@src/data/GeomStripe';
import _Street from '@src/data/_Street';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import FreeStripe from '@src/data/FreeStripe';
import GeomPoint from '@src/data/GeomPoint';
import * as workData from '@src/data/WorkData';
import { STRIPE_TOUCH_RANGE, THREE_POINT_STATUS_TYPE, STREET_TYPE } from '@src/constant';
import LncdText from '@src/shapes/LncdText';
import { StreetService } from '@src/services/StreetService';
import { createArc, createLine } from '@src/data/ShapeOperationData';
import GeomArc from '@src/data/GeomArc';

// const stripeHighlightStyle = {
//   fill: 'none',
//   stroke: 'var(--color-stripe-path-highlight)',
//   strokeDasharray: 'none',
//   strokeWidth: 20,
//   strokeOpacity: 0.5,
// };

class StreetNew extends React.Component {
  // TODO: meta data structures should be placed in util or work data module
  getMetaDataForComponents = () => {
    const { object } = this.props;
    const { components } = object;

    // components metadata
    let componentList = [];

    components.forEach(item => {
      if (item.type === 'stripe') {
        let patternList = [];
        let key = 0;
        item.primary.patterns.forEach(pattern => {
          key = key + 1;
          patternList.push(<pattern key={key} spattern={pattern.pattern} nstartpct={pattern.startPct} />);
        });
        componentList.push(
          <stripe key={item.key} nkey={item.key} sprofile={item.profile}>
            <primary>
              <patterns>{patternList}</patterns>
            </primary>
          </stripe>
        );
      } else if (item.type === 'lane') {
        componentList.push(<lane key={item.key} nkey={item.key} nwidth={item.width} nlanetype={item.laneType} />);
      } else if (item.type === 'divider') {
        componentList.push(<divider key={item.key} nkey={item.key} nwidth={item.width} />);
      } else if (item.type === 'shoulder') {
        componentList.push(<shoulder key={item.key} nkey={item.key} nwidth={item.width} />);
      }
    });
    return componentList;
  };

  getStreetFill = () => {
    const { object } = this.props;
    const { components } = object;

    let s = new _Street();
    let path = s.getFillPathData(object);
    let hp = [];

    // Find two street borders
    let border1 = components[0];
    if (border1 && (border1.segments || border1.offsetArcSegments)) {
      // FIXME: the operation of calculating handle points should be placed in workData
      if (border1.offsetArcSegments) {
        hp.push([border1.offsetArcSegments[0].ptStart.x, border1.offsetArcSegments[0].ptStart.y]);
        hp.push([
          border1.offsetArcSegments[border1.offsetArcSegments.length - 1].ptStop.x,
          border1.offsetArcSegments[border1.offsetArcSegments.length - 1].ptStop.y,
        ]);
      } else if (border1.segments) {
        hp.push([border1.segments[0].ptStart.x, border1.segments[0].ptStart.y]);
        hp.push([border1.segments[0].ptStop.x, border1.segments[0].ptStop.y]);
      }
    }
    let border2 = components[components.length - 1];
    if (border2 && (border2.segments || border2.offsetArcSegments)) {
      if (border2.offsetArcSegments) {
        hp.push([border2.offsetArcSegments[0].ptStart.x, border2.offsetArcSegments[0].ptStart.y]);
        hp.push([
          border2.offsetArcSegments[border2.offsetArcSegments.length - 1].ptStop.x,
          border2.offsetArcSegments[border2.offsetArcSegments.length - 1].ptStop.y,
        ]);
      } else if (border2.segments) {
        hp.push([border2.segments[0].ptStop.x, border2.segments[0].ptStop.y]);
        hp.push([border2.segments[0].ptStart.x, border2.segments[0].ptStart.y]);
      }
    }

    if (border1 && (border1.segments || border1.offsetArcSegments)) {
      object.handlepoint = hp;
      return <path ttn-role="streetFill" strokeWidth="0" d={path}></path>;
    }
  };

  updateStripeHighlight = (stripeIndex, e) => {
    const { object } = this.props;
    const { clientX, clientY } = e;
    const point = clientPtToUserPt(clientX, clientY);
    const stripe = object.components[stripeIndex];
    const stripePatternIndex = FreeStripe.getStripePatternIndex(stripe, new GeomPoint(...point));

    switch (e.type) {
      case 'mouseenter': {
        object.stripeFocused = stripeIndex;
        object.stripePatternFocused = stripePatternIndex;
        break;
      }
      case 'mouseleave': {
        object.stripeFocused = -1;
        object.stripePatternFocused = -1;
        break;
      }
      // TODO: touch?
      case 'click': {
        object.components.forEach(n => {
          if (n.selectedPatternIndex) {
            delete n.selectedPatternIndex;
          }
        });
        // May 07 latest way to highlight a stripe segment
        stripe.selectedPatternIndex = stripePatternIndex;
        object.stripeFocused = -1;
        object.stripePatternFocused = -1;
        object.idSelectedComponent = stripeIndex;
        object.selectflag = true;
        break;
      }
      default:
        break;
    }

    // FIXME: dispatch action to show props menu after this component is connected to redux
    emitter.emit(EVENT_EMIT_TYPE.STREET, 'refresh', object);
  };

  getHighlightedStripeData = () => {
    const { object } = this.props;
    let stripeIndex = -1;
    let patternIndex = -1;
    // don't highlight stripe if locked
    if (!workData.isLocked(object)) {
      if (object.stripeFocused > -1 && object.stripePatternFocused > -1) {
        // some stripe pattern is focused
        stripeIndex = object.stripeFocused;
        patternIndex = object.stripePatternFocused;
      } else if (object.idSelectedComponent > -1) {
        stripeIndex = object.idSelectedComponent;
        const stripe = object.components[stripeIndex];
        patternIndex = stripe.selectedPatternIndex;
      }
    }
    return { stripeIndex, patternIndex };
  };

  // TODO: 考虑结合 TransformRoundaboutCenterObject
  computeSpecialSegments({ prevSegment, nextSegment }) {
    let prevPtStart = new GeomPoint(prevSegment.ptStart);
    let prevPtStop = new GeomPoint(prevSegment.ptStop);
    let nextPtStart = new GeomPoint(nextSegment.ptStart);
    let nextPtStop = new GeomPoint(nextSegment.ptStop);
    let prevGa, nextGa;
    if (prevSegment.type == 'line') {
      prevGa = new GeomArc(prevPtStart, prevPtStop, 0, false, false);
      nextGa = new GeomArc(nextPtStart, nextPtStop, 0, false, false);
    } else {
      prevGa = new GeomArc(prevPtStart, prevPtStop, prevSegment.r, prevSegment.largeArcFlag, prevSegment.sweepFlag);
      nextGa = new GeomArc(nextPtStart, nextPtStop, nextSegment.r, nextSegment.largeArcFlag, nextSegment.sweepFlag);
    }
    let distance = prevGa.getMidPoint().distance(nextGa.getMidPoint());
    // 判断是往哪一侧弯曲道路
    let r = 0;
    if (prevSegment.r) {
      if (prevSegment.sweepFlag) {
        // 往外测弯曲
        r = prevSegment.r - distance / 2;
      } else {
        // 往内测弯曲
        r = prevSegment.r + distance / 2;
      }
    }

    let segment;
    if (!r) {
      segment = createLine({
        ptStart: prevPtStart.midPoint(nextPtStart),
        ptStop: prevPtStop.midPoint(nextPtStop),
      });
    } else {
      segment = createArc({
        ...prevSegment,
        ptStart: prevPtStart.midPoint(nextPtStart),
        ptStop: prevPtStop.midPoint(nextPtStop),
        r,
      });
    }
    return segment;
  }

  renderStripes = () => {
    const { object } = this.props;
    const { components } = object;

    let componentList = [];
    const highlighted = this.getHighlightedStripeData();

    // TODO: Maybe can use array to save these properties?
    let strokeLinecap, strokeLinejoin;
    switch (object.streetType) {
      case STREET_TYPE.ROUNDABOUT: {
        strokeLinecap = 'inherit';
        break;
      }
      case STREET_TYPE.STRAIGHT:
      case STREET_TYPE.CURVED: {
        strokeLinecap = 'butt';
        strokeLinejoin = 'round';
        break;
      }
      default:
        break;
    }

    // TODO: need refactor
    // TODO: shouldn't modify object directly
    const computeSpecialPath = i => {
      const component = components[i];
      let prevStripe = components[i + 1];
      let nextStripe = components[i - 1];

      if (components.length % 2 === 1 && i === Math.floor(components.length / 2)) {
        component.segments = object.segments;
      } else {
        // 判断 Street 是否为多段组合的
        let segments = [];
        if (prevStripe.segments.length > 1) {
          let len =
            prevStripe.segments.length < nextStripe.segments.length
              ? prevStripe.segments.length
              : nextStripe.segments.length;
          for (let j = 0; j < len; j++) {
            let segment = this.computeSpecialSegments({
              prevSegment: prevStripe.segments[j],
              nextSegment: nextStripe.segments[j],
            });
            // console.log('prev', prevStripe.segments[j]);
            // console.log('next', nextStripe.segments[j]);
            segments.push(segment);
          }
        } else {
          segments.push(
            this.computeSpecialSegments({
              prevSegment: prevStripe.segments[0],
              nextSegment: nextStripe.segments[0],
            })
          );
        }

        component.segments = segments;
        component.hiddenSegs = prevStripe.hiddenSegs;
      }
      let gpa = new GeomPolyArc(component.segments);
      let gs = new GeomStripe(gpa, 0, component.primary.patterns, component.hiddenSegs, component.width);
      gs.segments = component.segments;
      return { path: gs.computeRenderPath() };
    };

    const handlers = [
      {
        type: 'stripe',
        computePath: (i, highlightPathData) => {
          const component = components[i];
          let gpa = new GeomPolyArc(component.segments);
          let gs = new GeomStripe(
            gpa,
            0,
            component.primary.patterns,
            component.hiddenSegs,
            object.style.strokewidth,
            undefined,
            highlighted.patternIndex
          );
          gs.segments = component.segments;
          if (i === highlighted.stripeIndex) {
            highlightPathData = gs.computeRenderPath(true);
          }
          return { path: gs.computeRenderPath(), highlightPathData };
        },
      },
      {
        type: 'shoulder',
        computePath: i => computeSpecialPath(i),
      },
      {
        type: 'grass',
        computePath: i => computeSpecialPath(i),
      },
      {
        type: 'cement',
        computePath: i => computeSpecialPath(i),
      },
    ];

    for (let i = 0; i < components.length; i++) {
      let component = components[i];
      const { key } = component;
      let pathList = [];
      let path;
      let type = component.dividerType || component.type;
      if (handlers.map(item => item.type).includes(type)) {
        const componentId = `${component.type}||${object.operateid}||${i}`;
        const handler = handlers.find(handler => handler.type === type);

        // stripe segment is highlighted when
        // 1. current stripe is hovered
        // 2. no stripe is hovered and current stripe is selected
        let highlightPathData;
        if (component.axis) {
          let gpa = new GeomPolyArc(component.offsetArcSegments);
          let gs = new GeomStripe(gpa, 0, component.primary.patterns, component.hiddenSegs, object.style.strokewidth);
          gs.segments = component.offsetArcSegments;
          path = gs.computeRenderPath();
          pathList.push(<path key="13" id={componentId + '1'} ttn-role="visibleCore" fill="none" d={path}></path>);
          gpa = new GeomPolyArc(component.segments);
          gs = new GeomStripe(gpa, 0, component.axis.patterns, component.hiddenSegs, object.style.strokewidth);
          gs.segments = component.segments;
          path = gs.computeRenderPath();
        } else {
          path = handler.computePath(i).path;
          highlightPathData = handler.computePath(i).highlightPathData;
        }

        switch (handler.type) {
          case 'stripe': {
            componentList.push(
              <g
                ttn-agent="stripe"
                key={key}
                onClick={e => this.updateStripeHighlight(i, e)}
                onMouseEnter={e => this.updateStripeHighlight(i, e)}
                onMouseLeave={e => this.updateStripeHighlight(i, e)}
              >
                {pathList}
                <path
                  key={`${key}-phantom`}
                  id={`${componentId}||phantom`}
                  lncd-stripe-id={componentId}
                  lncd-role="stripe-phantom"
                  strokeOpacity={0}
                  fill="none"
                  d={path}
                  strokeWidth={STRIPE_TOUCH_RANGE}
                />
                {highlightPathData && (
                  <path key="stripe-highlight" d={highlightPathData} lncd-role="stripe-highlight" />
                )}
                <path id={componentId} fill="none" stroke={component.stroke} d={path} />
              </g>
            );
            break;
          }
          case 'shoulder': {
            componentList.unshift(
              <path
                key={key}
                id={componentId}
                fill="none"
                d={path}
                strokeWidth={component.width}
                stroke={object.isShowShoulderBackground ? component.backgroundColor : 'none'}
                strokeLinecap={strokeLinecap}
                strokeLinejoin={strokeLinejoin}
                strokeDasharray="none"
              />
            );
            break;
          }
          case 'grass':
          case 'cement': {
            componentList.unshift(
              <path
                key={key}
                id={componentId}
                fill="none"
                d={path}
                strokeWidth={component.width}
                stroke={object.isShowDividerBackground ? component.backgroundColor : 'none'}
                strokeLinecap={strokeLinecap}
                strokeLinejoin={strokeLinejoin}
                strokeDasharray="none"
              />
            );
            break;
          }
        }
      }
    }

    return componentList;
  };

  getText = () => {
    const { object } = this.props;
    const id = object.operateid;
    const point = new StreetService(object).getTextPosition();
    return <LncdText id={`street-text-${id}`} objectId={id} {...object.text} point={point} />;
  };

  render() {
    const {
      object: { shapes, selectflag, segments, functype, lanewidth, surfaceType, strokePattern, key, nextKey, text },
    } = this.props;

    let gpa = new GeomPolyArc(segments);
    let pathD = gpa.getSvgPathData();
    //if (selectflag === true) {
    //  shapes.push(<path key="2" d={pathD} strokeWidth={s.getStreetWidth(object)} strokeOpacity="0.4" strokeLinecap="butt" strokeDasharray="none" stroke="#808080"></path>);
    //}

    if (segments && (Array.isArray(segments) && segments.length > 0)) {
      return (
        <g id={functype} lncd-role="stripe" className={selectflag ? 'selected' : ''}>
          <metadata
            nlanewidth={lanewidth}
            ssurfacetype={surfaceType}
            sstrokepattern={strokePattern}
            nkey={key}
            nnextkey={nextKey}
          >
            <components> {this.getMetaDataForComponents()} </components>
          </metadata>
          <path
            d={pathD}
            fill="none"
            strokeWidth="0"
            strokeOpacity="0"
            className="ttn-selection-line"
            strokeLinecap="butt"
          />
          {this.getStreetFill()}
          {shapes}
          {this.renderStripes()}
          {text.text && this.getText()}
        </g>
      );
    } else {
      return null;
    }
  }
}

export default StreetNew;
