import React from 'react';
import { TowLineAcrossPoint } from '@src/data/BusinessFun';
import { ANCHORS_TYPE } from '@src/constant';

class CounterTop extends React.Component {
  constructor(props) {
    super(props);
  }

  getShapes = object => {
    var D = [];
    for (let i = 0; i < object.handlepoint.length; i++) {
      if (object.handlepoint[i][2] === ANCHORS_TYPE.COUNTERNORMAL) {
        if (i - 13 > 0) {
          var topAcrossLeft = TowLineAcrossPoint(
            [object.handlepoint[i - 13], object.handlepoint[i - 10]],
            [object.handlepoint[i - 2], object.handlepoint[i + 1]]
          );
          var bottomAcrossLeft = TowLineAcrossPoint(
            [object.handlepoint[i - 12], object.handlepoint[i - 9]],
            [object.handlepoint[i - 1], object.handlepoint[i + 2]]
          );
          var topAcrossRight = TowLineAcrossPoint(
            [object.handlepoint[i - 5], object.handlepoint[i - 2]],
            [object.handlepoint[i + 6], object.handlepoint[i + 9]]
          );
          var bottomAcrossRight = TowLineAcrossPoint(
            [object.handlepoint[i - 4], object.handlepoint[i - 1]],
            [object.handlepoint[i + 7], object.handlepoint[i + 10]]
          );
          D.push('M' + String(topAcrossLeft[0]) + ',' + String(topAcrossLeft[1]));
          D.push('L' + String(bottomAcrossLeft[0]) + ',' + String(bottomAcrossLeft[1]));
          D.push('L' + String(bottomAcrossRight[0]) + ',' + String(bottomAcrossRight[1]));
          D.push('L' + String(topAcrossRight[0]) + ',' + String(topAcrossRight[1]));
          D.push('Z');
        } else {
          var topAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 5], object.handlepoint[i - 2]],
            [object.handlepoint[i + 6], object.handlepoint[i + 9]]
          );
          var bottomAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 4], object.handlepoint[i - 1]],
            [object.handlepoint[i + 7], object.handlepoint[i + 10]]
          );
          D.push('M' + String(object.handlepoint[i - 5][0]) + ',' + String(object.handlepoint[i - 5][1]));
          D.push('L' + String(topAcross[0]) + ',' + String(topAcross[1]));
          D.push('L' + String(bottomAcross[0]) + ',' + String(bottomAcross[1]));
          D.push('L' + String(object.handlepoint[i - 4][0]) + ',' + String(object.handlepoint[i - 4][1]));
          D.push('Z');
        }
      } else if (object.handlepoint[i][2] == ANCHORS_TYPE.COUNTEREND) {
        if (object.handlepoint.length == 9) {
          D.push('M' + String(object.handlepoint[1][0]) + ',' + String(object.handlepoint[1][1]));
          D.push('L' + String(object.handlepoint[7][0]) + ',' + String(object.handlepoint[7][1]));
          D.push('L' + String(object.handlepoint[8][0]) + ',' + String(object.handlepoint[8][1]));
          D.push('L' + String(object.handlepoint[2][0]) + ',' + String(object.handlepoint[2][1]));
          D.push('Z');
        } else {
          var topAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 13], object.handlepoint[i - 10]],
            [object.handlepoint[i - 2], object.handlepoint[i + 1]]
          );
          var bottomAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 12], object.handlepoint[i - 9]],
            [object.handlepoint[i - 1], object.handlepoint[i + 2]]
          );
          D.push('M' + String(topAcross[0]) + ',' + String(topAcross[1]));
          D.push('L' + String(bottomAcross[0]) + ',' + String(bottomAcross[1]));
          D.push('L' + String(object.handlepoint[i + 2][0]) + ',' + String(object.handlepoint[i + 2][1]));
          D.push('L' + String(object.handlepoint[i + 1][0]) + ',' + String(object.handlepoint[i + 1][1]));
          D.push('Z');
        }
      }
    }
    return <path d={D.join(' ')} stroke={object.style.fill} />;
  };

  render() {
    const { props } = this;
    const { object } = props;
    if (object.handlepoint.length < 6) return null;
    return <g id={object.functype}>{this.getShapes(object)}</g>;
  }
}

export default CounterTop;
