import { OPERATIONS } from '@src/constant';
import types, {
  composeActionType,
  TOGGLE_GRID,
  SET_AROUND_CENTER_OFFSET,
  RESET_AROUND_CENTER_OFFSET,
} from '@src/type/canvas';
import { UNDO, REDO } from '@src/type/snapshots';
import _ from 'lodash';

const actionTypes = Object.values(OPERATIONS);
let tempPastePos = [0, 0];

export const transferOperation = (operation, ...restArgs) => (dispatch, getState) => {
  const { map, pointer } = getState();
  const obj = {
    service: map.service,
    opacity: map.opacity,
  };
  if (actionTypes.indexOf(operation) > -1) {
    let pt = null;
    switch (operation) {
      case 'paste': {
        /**
         * XXX: Some situation need to think
         * 1. When copy something and paste to a position, then copy another to same position, what will happen?
         */
        // the paste location should be the last blank position user clicked - means user wants to paste there
        if (pointer.isMouseClickCanvas) {
          pt = [pointer.lastClickPosX, pointer.lastClickPosY];

          /**
           * If point equals previous point, point values null.
           * The paste shape will side the previous shape.
           * If not equal, this point will record.
           */
          if (_.isEqual(pt, tempPastePos)) {
            pt = null;
          } else {
            tempPastePos = pt;
          }
        }
        dispatch({
          type: composeActionType(operation),
          map: obj,
          pt,
          ...restArgs,
        });
        break;
      }
      case 'redo': {
        dispatch({
          type: REDO,
        });
        break;
      }
      case 'undo': {
        dispatch({
          type: UNDO,
        });
        break;
      }
      default: {
        dispatch({
          type: composeActionType(operation),
          map: obj,
          ...restArgs,
        });
        break;
      }
    }
  }
};

export const setDrawingShape = (name, streetType) => ({
  type: types.SET_DRAWING_SHAPE,
  name,
  streetType,
});

export const flushWorkData = () => ({
  type: types.FLUSH_WORK_DATA,
});

export const toggleLockAllRoads = () => ({
  type: types.TOGGLE_LOCK_ALL_ROADS,
});

export const toggleLockRoad = streetId => ({
  type: types.TOGGLE_LOCK_ROAD,
  streetId,
});

export const resetCanvas = () => dispatch => {
  // dispatch reset action so other components should be noticed
  dispatch({ type: types.RESET });
  // TODO: dispatch actions to other components
};

export const toggleGrid = () => ({
  type: TOGGLE_GRID,
});

export const setAroundCenterOffset = (offsetX, offsetY) => (dispatch, getState) => {
  const { canvas } = getState();
  dispatch({
    type: SET_AROUND_CENTER_OFFSET,
    offsetX: canvas.offsetXToCenter + offsetX,
    offsetY: canvas.offsetYToCenter + offsetY,
  });
};

export const resetAroundCenterOffset = () => ({
  type: RESET_AROUND_CENTER_OFFSET,
});
