//px,py 中心点  mx,my 相对点 (Px, py central point mx, my relative point)
//相对于svg坐标x轴为0 (Relative to SVG coordinate, X axis is 0)
import React from 'react';
import { CopyPoint, TowLineAcrossPoint1 } from './BusinessFun';
import {
  getUserUnitByPixel,
  clientPtToUserPt,
  devicePtToUserPt,
  userPtToClientPt,
  clientPtToDevicePt,
} from '@src/data/CommonFunction';
import utility from '@src/data/Utility';
import {
  LENGTH_TYPE,
  THREE_POINT_STATUS_TYPE,
  CIRCLE_SIZE_TYPE,
  POINT_CIRCLE_STATE,
  STREET_DEFAULT_SIZE,
} from '@src/constant';
import { unifyPoint, getBBox, createSVGElement } from '@src/utils';

const { STREET_WIDTH, LANE_WIDTH } = STREET_DEFAULT_SIZE;

// FIXME: what is this scale used for?
const DEFAULT_SCALE = 0.4;

/**
 * toFixed 修复
 * @param {number} num
 * @param {number} s
 */
export function toFixed(num, s = 4) {
  var times = Math.pow(10, s);
  var des = Math.round(num * times);
  des = des / times;
  return des;
}

/**
 * 获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角 (Get the line between the center of the character and the coordinates of the mouse, and the angle between the positive and half axes of the Y axis)
 * @param {point} px
 * @param {array} py
 * @param {array} mx
 * @param {array} my
 */
export function getAngle(px, py, mx, my) {
  //获得人物中心和鼠标坐标连线，与y轴正半轴之间的夹角
  var x = Math.abs(px - mx);
  var y = Math.abs(py - my);
  var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  var cos = y / z;
  var radian = Math.asin(cos); //用反三角函数求弧度 (Calculating Radius with Inverse Triangular Function)
  var angle = radian;
  if (mx > px && my == py) {
    angle = 0;
  }
  // if (mx > px && my > py) {
  //   //鼠标在第一象限 (Mouse in the first quadrant)
  //   angle = angle;
  // }
  if (mx == px && my > py) {
    //y负半轴视觉效果在上面 (Y negative half-axis visual effect on top)
    angle = (Math.PI * 1) / 2;
  }
  if (mx < px && my > py) {
    // 第二象限  视觉左下方 (2 Quadrant, Visual at lower left)
    angle = Math.PI - angle;
  }
  if (mx < px && my == py) {
    //x轴负半轴 视觉在左边 (X-axis negative half-axis, Visual at left)
    angle = Math.PI;
  }
  if (mx < px && my < py) {
    // 视觉左上方 (Visual at upper left)
    angle = Math.PI + angle;
  }
  if (mx == px && my < py) {
    // y 负半轴 视觉是在上方 (Y positive half-axis, Visual at top)
    angle = (Math.PI * 3) / 2;
  }
  if (mx > px && my < py) {
    //视觉在右上方 (Visual at upper right)
    angle = Math.PI * 2 - angle;
  }
  return angle; //toFixed(angle, 3);
}

/**
 * 通过两点获取两点连线和Y轴正半轴的夹角
 * @param {array} cpoint
 * @param {array} mpoint
 * @returns {number}
 */
export function getAngleBy2Points(cpoint, mpoint) {
  if (!cpoint || !mpoint) return 0;
  if (cpoint[0] == mpoint[0] && cpoint[1] == mpoint[1]) return 0;
  var angle = getAngle(cpoint[0], cpoint[1], mpoint[0], mpoint[1]);
  return angle;
}

/**
 * clockwise，已经考虑画布反转，即返回值为 true，表示画布上显示的是顺时针效果，而实际上在画布坐标系中是逆时针
 * @param {number[]} p1
 * @param {number[]} p2
 * @param {number[]} p3
 */
export function ByTime(p1, p2, p3) {
  if (!p1 || !p2 || !p3) return -1;
  var x1 = p1[0],
    y1 = p1[1];
  var x2 = p2[0],
    y2 = p2[1];
  var x3 = p3[0],
    y3 = p3[1];
  var ans = (x2 - x1) * (y3 - y1) - (y2 - y1) * (x3 - x1); //表示向量AB与AC的叉积的结果
  if (ans == 0) return THREE_POINT_STATUS_TYPE.ONLINE; //共线
  if (Math.abs(Math.round(ans * 100) / 100) == 0.0) return THREE_POINT_STATUS_TYPE.ONLINE; //共线
  if (ans > 0) return THREE_POINT_STATUS_TYPE.BYTIME; //顺时针
  if (ans < 0) return THREE_POINT_STATUS_TYPE.DEFYTIME; //逆时针
}

/**
 * If the line/arc travels the 3 points in clockwise direction. Notice that the clockwise is visually clockwise, which means it is counter-clockwise direction in the raw SVG coordinate system as we transform the SVG upside-down.
 * @param {number[]} p1
 * @param {number[]} p2
 * @param {number[]} p3
 * @returns {boolean}
 */
export function IsByTime(p1, p2, p3) {
  return ByTime(p1, p2, p3) === THREE_POINT_STATUS_TYPE.BYTIME;
}

/**
 * 矩阵相乘
 * 0 2 4      a=a0*b0+a2*b1  c=a0*b2+a2*b3  e=a0*b4+a2*b5+a4
 * 1 3 5      b=a1*b0+a3*b1  d=a1*b2+a3*b3  f=a1*b4+a3*b5+a5
 * 0 0 1
 * @param {number[]} a - [a,b,c,d,e,f]
 * @param {number[]} b - [x,y]
 * @returns {number[]}
 */
export function matrixMultiply(a, b) {
  return [
    a[0] * b[0] + a[2] * b[1],
    a[1] * b[0] + a[3] * b[1],
    a[0] * b[2] + a[2] * b[3],
    a[1] * b[2] + a[3] * b[3],
    a[0] * b[4] + a[2] * b[5] + a[4],
    a[1] * b[4] + a[3] * b[5] + a[5],
  ];
}

/**
 * 用于矩阵变化 (For Matrix Change)
 * 0 2 4      x=m0*x+m2*y+m4
 * 1 3 5      y=m1*x+m3*y+m5
 * 0 0 1
 * @param {number[]} matrix - [a,b,c,d,e,f]
 * @param {[number, number]} point - [x, y]
 * @returns {[number, number]} point after transform
 */
export function pointTransform(matrix, point) {
  const [a, b, c, d, e, f] = matrix;
  let [x0, y0] = unifyPoint(point);
  let x = a * x0 + c * y0 + e;
  x = toFixed(x, 4);
  let y = b * x0 + d * y0 + f;
  y = toFixed(y, 4);
  return [x, y];
}

/**
 * 旋转矩阵
 *  cos -sin 4
 *  sin  cos 5
 *   0    0  1
 * @param {number} angle
 */
export function GetRotateMatrix(angle) {
  var cos = Math.cos(angle),
    sin = Math.sin(angle);
  return [cos, sin, -sin, cos, 0, 0];
}

/**
 * 平移矩阵
 *  0 2 start
 *  1 3 end
 *  0 0 1
 * @param {array} StartPoint
 * @param {array} EndPoint
 */
export function GetMoveMatrix(StartPoint, EndPoint) {
  return [1, 0, 0, 1, EndPoint[0] - StartPoint[0], EndPoint[1] - StartPoint[1]];
}

/**
 * 数组赋值长度 (Array assignment length)
 * @param {string} src
 * @param {string} dest
 * @param {number} length
 */
export function ArrayCopyLength(src, dest, length) {
  // m = [a,b,c,d,e,f], p = [x,y]
  var handlePoint = src.concat();
  if (length <= 0) return handlePoint;
  if (length > dest.length) {
    handlePoint = dest.concat();
    return handlePoint;
  }
  for (var i = 0; i < length; i++) {
    handlePoint[i] = dest[i];
  }
  return handlePoint;
}

/**
 * 缩放坐标点
 * @param {array} p - [x,y]
 * @param {number} scaleFactorX - 0-1 缩小<zoom in>，>1放大<zoom out>
 * @param {number} scaleFactorY - 0-1 缩小<zoom in>，>1放大<zoom out>
 * @param {array} origin - [x,y] 原点
 */
export function ScalePoint(p, scaleFactorX, scaleFactorY, origin) {
  // 平移到原点放大后 在移动回原点
  var afterPoint = pointTransform([1, 0, 0, 1, -origin[0], -origin[1]], p);
  afterPoint = pointTransform([scaleFactorX, 0, 0, scaleFactorY, 0, 0], afterPoint);
  afterPoint = pointTransform([1, 0, 0, 1, origin[0], origin[1]], afterPoint);
  return afterPoint;
}

/**
 * 平移坐标点到另一个坐标
 * @param {number[]} p
 * @param {number} moveX
 * @param {number} moveY
 * @returns {number[]}
 */
export function MovePoint(p, moveX, moveY) {
  // p = [x,y] ，angle （0-360 ）origin= [x,y] 原点 <origin>
  //平移到 (Translation to)
  var afterPoint = pointTransform([1, 0, 0, 1, moveX, moveY], p);
  return afterPoint;
}

/**
 * 用于旋转angle为与x轴正轴到y轴的夹角 (For rotation)
 * @description 平移到原点放大后 在移动回原点 (Move to the origin, zoom in, and then move back to the origin.)
 * @param {[number, number]} p
 * @param {number} angle - 0-360
 * @param {[number, number]} origin - 原点
 * @returns {[number, number]}
 */
export function RotatePoint(p, angle, origin) {
  var afterPoint = pointTransform([1, 0, 0, 1, -origin[0], -origin[1]], p);
  let cos = Math.cos(angle),
    sin = Math.sin(angle);
  afterPoint = pointTransform([cos, sin, -sin, cos, 0, 0], afterPoint);
  afterPoint = pointTransform([1, 0, 0, 1, origin[0], origin[1]], afterPoint);
  // CopyPoint(p,afterPoint);
  return afterPoint;
}

// TODO: GeomPoint distance
/**
 * 用于旋转 (For rotation)
 * @param {[number, number]} point1
 * @param {[number, number]} point2
 * @return {number} length
 */
export function LengthBetweenPoints(point1, point2) {
  if (point1 && point2) {
    // p = [x,y] ，angle （0-360 ）origin= [x,y] 原点 <origin>
    //平移到原点放大后 在移动回原点 (Move to the origin, zoom in, and then move back to the origin.)
    let length = Math.sqrt(Math.pow(point1[1] - point2[1], 2) + Math.pow(point1[0] - point2[0], 2));
    // length = toFixed(length, 3);
    return length;
  }
}

//用于旋转
export function LengthArc(point1, point2, arcCenter) {
  // p = [x,y] ，angle （0-360 ）origin= [x,y] 原点
  //平移到原点放大后 在移动回原点
  if (IsOneLine(point1, arcCenter, point2) === THREE_POINT_STATUS_TYPE.ONLINE) {
    return LengthBetweenPoints(point1, point2);
  }
  var circle = FindCircle(point1, point2, arcCenter);
  let isByTime = IsByTime(point1, arcCenter, point2);
  var lengthArc = GetArcAngle(circle, point1, point2, isByTime) * circle[2];
  return toFixed(lengthArc);
}

export function LengthArc1(point1, point2, circle, isBytime) {
  // p = [x,y] ，angle （0-360 ）origin= [x,y] 原点
  var lengthArc = GetArcAngle(circle, point1, point2, isBytime) * circle[2];
  return toFixed(lengthArc);
}

export function GetArcPointsAngle(point1, point2, circle, isBigArc) {
  var angle1 = getAngleBy2Points(circle, point1);
  var angle2 = getAngleBy2Points(circle, point2);
  var angle = Math.abs(angle1 - angle2);
  if (isBigArc === CIRCLE_SIZE_TYPE.BIGARC) {
    if (angle < Math.PI) {
      angle = 2 * Math.PI - angle;
    }
  } else {
    if (angle > Math.PI) {
      angle = 2 * Math.PI - angle;
    }
  }
  return angle;
}

/**
 * 获取两点的中心 (Get the center of two points)
 * @param {array} p1
 * @param {array} p2
 */
export function GetCenterInTwoPoints(p1, p2) {
  if (!p1) {
    p1 = [0, 0];
  }
  if (!p2) {
    p2 = [0, 0];
  }
  var centerx = toFixed((p1[0] + p2[0]) / 2, 4);
  var centery = toFixed((p1[1] + p2[1]) / 2, 4);
  return [centerx, centery];
}
/**
 *求N个点的中心
 * @param {array} points
 */
export function GetCenterInTwoPoints1(points) {
  let center = [];
  var xMin = 0,
    yMin = 0,
    xMax = 0,
    yMax = 0;
  for (let i = 0; i < points.length; i++) {
    if (points[i][0] < xMin || xMin == 0) {
      xMin = points[i][0];
    }
    if (points[i][0] > xMax || xMax == 0) {
      xMax = points[i][0];
    }
    if (points[i][1] < yMin || yMin == 0) {
      yMin = points[i][1];
    }
    if (points[i][1] > yMax || yMax == 0) {
      yMax = points[i][1];
    }
  }
  center = GetCenterInTwoPoints([xMin, yMin], [xMax, yMax]);
  return center;
}

//从p1起获取两点的n分 (Obtaining n-points of two points from P1)
export function GetCenter(p1, p2, n) {
  if (!p1) {
    p1 = [0, 0];
  }
  if (!p2) {
    p2 = [0, 0];
  }
  var centerx = toFixed(p1[0] + (p2[0] - p1[0]) * n, 3);
  var centery = toFixed(p1[1] + (p2[1] - p1[1]) * n, 3);
  return [centerx, centery];
}

//获取两点之间得比例点 (Get the proportional point between two points)
export function GetTowPointsScale(p1, p2, scale) {
  return [(p1[0] + p2[0]) * scale, (p1[1] + p2[1]) * scale];
}

/**
 * 已知两点和半径 求圆心坐标 有两个,瞬时间位置，和逆势针位置 (There are two coordinates for finding the center of a circle with known two points and radius, the instantaneous position and the counter-potential needle position)
 * @param {array} p1 圆弧起点
 * @param {array} p2 圆弧终点
 * @param {number} R
 * @param {boolean} isBigArc
 * @param {boolean} isShun 判断圆弧是否是顺时针  根据圆心判断
 */
export function GetCircleCenter(p1, p2, R, isBigArc, isShun) {
  if (isBigArc == null) {
    isBigArc = false;
  }
  if (isShun == null) {
    isShun = true;
  }
  var pLeft = p1.concat(),
    pRight = p2.concat();
  var x1 = pLeft[0],
    x2 = pRight[0],
    y1 = pLeft[1],
    y2 = pRight[1];
  var c1 = 0,
    c2 = 0,
    circleCenter1 = [0, 0],
    circleCenter2 = [0, 0];
  if (x1 != x2) {
    c1 = (x2 * x2 - x1 * x1 + y2 * y2 - y1 * y1) / (2 * (x2 - x1));
    c2 = (y2 - y1) / (x2 - x1);
    var A = c2 * c2 + 1;
    var B = 2 * x1 * c2 - 2 * c1 * c2 - 2 * y1;
    var C = x1 * x1 - 2 * x1 * c1 + c1 * c1 + y1 * y1 - R * R;
    circleCenter1[1] = (-B - Math.sqrt(B * B - 4 * A * C)) / (2 * A);
    circleCenter1[1] = toFixed(circleCenter1[1], 2);
    circleCenter2[1] = (-B + Math.sqrt(B * B - 4 * A * C)) / (2 * A);
    circleCenter2[1] = toFixed(circleCenter2[1], 3);
    circleCenter1[0] = c1 - c2 * circleCenter1[1];
    circleCenter1[0] = toFixed(circleCenter1[0], 3);
    circleCenter2[0] = c1 - c2 * circleCenter2[1];
    circleCenter2[0] = toFixed(circleCenter2[0], 3);
  } else {
    var center = GetCenterInTwoPoints(p1, p2);
    circleCenter1[0] = p1[0] + Math.sqrt(Math.pow(R, 2) - Math.pow(p1[1] - center[1], 2));
    circleCenter1[0] = toFixed(circleCenter1[0], 3);
    circleCenter2[0] = p1[0] - Math.sqrt(Math.pow(R, 2) - Math.pow(p1[1] - center[1], 2));
    circleCenter2[0] = toFixed(circleCenter2[0], 3);
    circleCenter1[1] = center[1];
    circleCenter2[1] = center[1];
  }

  var circleShun = circleCenter1.concat();
  var circleNi = circleCenter2.concat();
  //求得圆心位置相对起始中点位置顺逆
  if (ByTime(p1, circleCenter1, p2) === THREE_POINT_STATUS_TYPE.BYTIME) {
    circleShun = circleCenter1.concat();
    circleNi = circleCenter2.concat();
  } else {
    circleShun = circleCenter2.concat();
    circleNi = circleCenter1.concat();
  }
  if (isBigArc == false && isShun == true) {
    //圆弧是顺时针的话，圆心就是逆时针
    return circleNi;
  } else if (isBigArc == false && isShun == false) {
    return circleShun;
  } else if (isBigArc == true && isShun == true) {
    return circleShun;
  } else if (isBigArc == true && isShun == false) {
    return circleNi;
  }
  return circleShun;
}

export function GetCircleCenter1(circle, p1, p2, isBytime) {
  return GetArcCenter(circle, p1, p2, 1 / 2, isBytime);
}

//h为中心点到顶点的高度
export function GetCircleCenter2(circle, p1, p2, h, isBytime) {
  let center = GetCenterInTwoPoints(p1, p2);
  return TowPointVerticalLengthByStatus(p1, p2, center, h, isBytime);
}

/**
 * 已知两点和半径 求圆心坐标 求圆弧上中点 小圆弧 (Finding the center coordinate of a circle with known two points and radius and finding the small arc of the middle point on the arc)
 * @param {*} p1
 * @param {*} p2
 * @param {*} R
 * @param {*} isShun
 */
export function GetSmallCircleCenter(p1, p2, R, isShun) {
  if (isShun == null) {
    isShun = false;
  }
  //获取小圆弧的中心
  var circleCenter = GetCircleCenter(p1, p2, R, false, isShun);
  var pointsMedium = GetCenterInTwoPoints(p1, p2);
  var length = LengthBetweenPoints(circleCenter, pointsMedium);
  var scale = 1,
    destPoint = [];
  if (IsZero(length)) {
    destPoint = TowPointVerticalLengthByTime(p1, p2, pointsMedium, R, isShun);
  } else {
    scale = R / length;
    destPoint = ScalePoint(pointsMedium, scale, scale, circleCenter);
  }
  return destPoint;
}

//已知两点和半径 求圆心坐标 求圆弧上中点 大圆弧 (Find the center coordinate of a circle with known two points and radius, and find the large arc with midpoint on the arc)
export function GetBigCircleCenter(p1, p2, R, isShun) {
  if (isShun == null) {
    isShun = true;
  }
  var center = GetCircleCenter(p1, p2, R, true, isShun);
  var pointsMedium = GetCenterInTwoPoints(p1, p2);
  var length = LengthBetweenPoints(center, pointsMedium);
  var scale = 1,
    destPoint = [];
  if (IsZero(length)) {
    scale = 0;
    //圆心等于中点时
    destPoint = TowPointVerticalLengthByTime(p1, p2, pointsMedium, R, isShun);
  } else {
    scale = (R + length) / length;
    destPoint = ScalePoint(center, scale, scale, pointsMedium);
  }
  return destPoint;
}

//求圆弧上得中心点
export function GetCircleArcCenter2(p1, p2, R, isArcBig, isShun) {
  if (isShun == null) {
    isShun = true;
  }
  if (isArcBig == null) {
    isArcBig = false;
  }
  var point = [];
  if (isArcBig == true) {
    point = GetBigCircleCenter(p1, p2, R, isShun);
  } else {
    point = GetSmallCircleCenter(p1, p2, R, isShun);
  }
  return point;
}

export function GetCircleArcCenter(p1, p2, R, isArcBig, isShun, n) {
  if (isShun == null) {
    isShun = true;
  }
  if (isArcBig == null) {
    isArcBig = false;
  }
  var pointN = GetCenter(p1, p2, n);
  var circleCenter = GetCircleCenter(p1, p2, R, isArcBig, isShun);
  var circle = [circleCenter[0], circleCenter[1], R];
  var pointAfter = FindCircleLinePoint(circle, pointN);
  return pointAfter;
}

//得到两点之间点 的比例 可适用于圆形上面的点, p1 为起点
export function GetTowPointsN(p1, p2, destPoint) {
  var destCross = TowPointVerticalAcross(p1, p2, destPoint);
  var lengthAll = LengthBetweenPoints(p1, p2);
  var lengthCross = LengthBetweenPoints(p1, destCross);
  var scaleN = toFixed(lengthCross / lengthAll);
  return scaleN;
}

export function fixArray(point) {
  return [toFixed(point[0], 3), toFixed(point[1], 3)];
}

/**
 * 是否三点一线
 * @param {[number, number]} p1
 * @param {[number, number]} p2
 * @param {[number, number]} p3
 * @returns {0 | 3} 0: THREE_POINT_STATUS_TYPE.ONLINE, 3: THREE_POINT_STATUS_TYPE.UNKOWN
 */
export function IsOneLine(p1, p2, p3) {
  if (IsEqual(p1[0], p2[0]) && IsEqual(p1[0], p3[0])) {
    return THREE_POINT_STATUS_TYPE.ONLINE;
  } else if (IsEqual(p1[1], p2[1]) && IsEqual(p1[1], p3[1])) {
    return THREE_POINT_STATUS_TYPE.ONLINE;
  }

  let k1 = 0;
  let k2 = 0;

  if (!IsEqual(p2[0], p1[0])) {
    k1 = toFixed((p2[1] - p1[1]) / (p2[0] - p1[0]), 3);
  }

  if (!IsEqual(p3[0], p1[0])) {
    k2 = toFixed((p3[1] - p1[1]) / (p3[0] - p1[0]), 3);
  }

  if (IsEqual(k1, k2)) {
    return THREE_POINT_STATUS_TYPE.ONLINE;
  } else {
    return THREE_POINT_STATUS_TYPE.UNKOWN;
  }
}

// FIXME: add comment for this method, what is this used for?
/**
 * FindCircle
 * @param {number[]} pt1
 * @param {number[]} pt2
 * @param {number[]} pt3
 * @returns {number[]} [cx, cy, r]
 */
export function FindCircle(pt1, pt2, pt3) {
  if (IsOneLine(pt1, pt2, pt3) === THREE_POINT_STATUS_TYPE.ONLINE) {
    var center12 = GetCenterInTwoPoints(pt1, pt2);
    var center123 = GetCenterInTwoPoints(center12, pt3);
    // the 3 points are in the same line
    return [center123[0], center123[1], 0];
  }
  var e = 2 * (pt2[0] - pt1[0]);
  var f = 2 * (pt2[1] - pt1[1]);
  var g = Math.pow(pt2[0], 2) - Math.pow(pt1[0], 2) + Math.pow(pt2[1], 2) - Math.pow(pt1[1], 2); //  x2*x2 - x1*x1 + y2*y2 - y1*y1;
  var a = 2 * (pt3[0] - pt2[0]); // 2 * (x3 - x2);
  var b = 2 * (pt3[1] - pt2[1]); //2 * (y3 - y2);
  var c = Math.pow(pt3[0], 2) - Math.pow(pt2[0], 2) + Math.pow(pt3[1], 2) - Math.pow(pt2[1], 2); //  x3*x3 - x2*x2 + y3*y3 - y2*y2;
  var circle = [0, 0, 0];
  circle[0] = (g * b - c * f) / (e * b - a * f); //(g*b - c*f) / (e*b - a*f);
  circle[1] = (a * g - c * e) / (a * f - b * e);
  var r = Math.sqrt((circle[0] - pt1[0]) * (circle[0] - pt1[0]) + (circle[1] - pt1[1]) * (circle[1] - pt1[1]));
  circle[2] = r;
  return circle;
}

/**
 * 已知两点和两点 和圆 上切点，求圆
 * @param {} p1
 * @param {*} p2
 * @param {*} pointTemp
 */
export function FindCircle1(p1, p2, pointTemp) {
  let line1 = GetLineKB(p1, pointTemp);
  let line2 = GetLineKB(p2, pointTemp);
  let line1Temp = GetLineKBVertical(p1, line1);
  let line2Temp = GetLineKBVertical(p2, line2);
  let circle = TowLineAcrossPoint1(line1Temp, line2Temp);
  circle[2] = LengthBetweenPoints(circle, p1);
  return circle;
}

export function FindCircleByR(pt1, pt2, R, isByTime) {
  if (IsEqual(pt1[0], pt2[0]) && IsEqual(pt1[1], pt2[1])) return [0, 0, 0];
  let a = 2 * (pt1[0] - pt2[0]);
  let tempb = 2 * (pt1[1] - pt2[1]);
  let c = pt1[0] * pt1[0] - pt2[0] * pt2[0] + pt1[1] * pt1[1] - pt2[1] * pt2[1];
  let k = IsZero(tempb) ? 0 : -a / tempb;
  let b = IsZero(tempb) ? 0 : -c / tempb;
  let A = 1 + k * k;
  let B = -2 * pt1[0] - 2 * (pt1[1] - b) * k;
  let C = pt1[0] * pt1[0] + Math.pow(pt1[1] - b, 2) - R * R;
  let D = Math.abs(Math.pow(B, 2) - 4 * A * C);
  var x1 = (-B + Math.sqrt(D)) / (2 * A);
  x1 = toFixed(x1);
  var x2 = (-B - Math.sqrt(D)) / (2 * A);
  x2 = toFixed(x2);
  var y1 = k * x1 + b;
  var y2 = k * x2 + b;
  let isAngleBig = ByTime(pt1, [x1, y1], pt2) === THREE_POINT_STATUS_TYPE.BYTIME;
  if (isByTime) {
    if (isAngleBig) {
      return [x2, y2, R];
    } else {
      return [x1, y1, R];
    }
  } else {
    if (isAngleBig) {
      return [x1, y1, R];
    } else {
      return [x2, y2, R];
    }
  }
}
/**
 *  求直线到 圆心的距离
 * @param {number[]} circle
 * @param {*} linekb
 * @returns {number}
 */
export function GetCircleLineLength(circle, linekb) {
  let A = linekb[0];
  let B = -1;
  let C = linekb[1];
  let top = A * circle[0] + B * circle[1] + C;
  top = Math.abs(top);
  let bottom = Math.sqrt(A * A + B * B);
  let length = top / bottom;
  if (linekb[0] === 1) {
    length = Math.abs(linekb[1] - circle[0]);
  } else if (linekb[0] === 0) {
    length = Math.abs(linekb[1] - circle[1]);
  }
  return length;
}

//圆形和 直线的交点
export function GetCircleLineAcrossPoint(circle, linekb) {
  let A = 1 + linekb[0] * linekb[0];
  let B = -2 * circle[0] + 2 * linekb[0] * (linekb[1] - circle[1]);
  let C = circle[0] * circle[0] + Math.pow(linekb[1] - circle[1], 2) - circle[2] * circle[2];
  let D = Math.abs(Math.pow(B, 2) - 4 * A * C);
  let x1 = (-B - Math.sqrt(D)) / (2 * A);
  let y1 = linekb[0] * x1 + linekb[1];
  let x2 = (-B + Math.sqrt(D)) / (2 * A);
  let y2 = linekb[0] * x2 + linekb[1];
  if (linekb[0] === 1) {
    x1 = linekb[1];
    x2 = linekb[1];
    let deta = Math.abs(circle[2] * circle[2] - Math.pow(x1 - circle[0], 2));
    y1 = circle[1] + Math.sqrt(deta);
    y2 = circle[1] - Math.sqrt(deta);
  }
  if (linekb[0] === 0) {
    y1 = linekb[1];
    y2 = linekb[1];
    let deta = Math.abs(circle[2] * circle[2] - Math.pow(y1 - circle[1], 2));
    x1 = circle[0] + Math.sqrt(deta);
    x2 = circle[0] - Math.sqrt(deta);
  }
  return [[toFixed(x1), toFixed(y1)], [toFixed(x2), toFixed(y2)]];
}
//确定两个圆形有交点使用
export function TowCircleAcrossPoints(circle1, circle2) {
  let a = -2 * circle1[0] + 2 * circle2[0];
  let b = -2 * circle1[1] + 2 * circle2[1];
  let c =
    Math.pow(circle1[2], 2) -
    Math.pow(circle2[2], 2) -
    Math.pow(circle1[0], 2) +
    Math.pow(circle2[0], 2) -
    Math.pow(circle1[1], 2) +
    Math.pow(circle2[1], 2);
  let linek = -a / b;
  let lineb = c / b;
  let A = 1 + linek * linek;
  let B = -2 * circle1[0] + 2 * linek * (lineb - circle1[1]);
  let C = circle1[0] * circle1[0] + Math.pow(lineb - circle1[1], 2) - circle1[2] * circle1[2];
  let D = Math.abs(Math.pow(B, 2) - 4 * A * C);
  let x1 = (-B - Math.sqrt(D)) / (2 * A);
  let y1 = linek * x1 + lineb;
  let x2 = (-B + Math.sqrt(D)) / (2 * A);
  let y2 = linek * x2 + lineb;
  return [[toFixed(x1), toFixed(y1)], [toFixed(x2), toFixed(y2)]];
}
//过圆心的线交点 近点端
export function FindCircleLinePoint(circle, point) {
  var k = 0;
  if (!IsEqual(circle[0], point[0])) {
    k = (circle[1] - point[1]) / (circle[0] - point[0]);
  }
  var b = point[1] - k * point[0];
  var A = 1 + Math.pow(k, 2);
  var B = 2 * (k * b - k * circle[1] - circle[0]);
  var C = Math.pow(circle[0], 2) + Math.pow(b - circle[1], 2) - Math.pow(circle[2], 2);
  var x1 = (-B + Math.sqrt(Math.pow(B, 2) - 4 * A * C)) / (2 * A);
  x1 = toFixed(x1, 3);
  var x2 = (-B - Math.sqrt(Math.pow(B, 2) - 4 * A * C)) / (2 * A);
  x2 = toFixed(x2, 3);
  var point1 = [x1, toFixed(k * x1 + b, 3)];
  var point2 = [x2, toFixed(k * x2 + b, 3)];
  var length1 = LengthBetweenPoints(point1, point);
  var length2 = LengthBetweenPoints(point2, point);
  if (length1 > length2) {
    return point2;
  } else {
    return point1;
  }
}

//求圆上某点的斜率
export function GetCirclePointK(circle, point) {
  var lineKb = GetLineKB(point, circle);
  var k = lineKb[0] == 0 ? -1 : -1 / lineKb[0];
  return k;
}

//到圆上最近的点不过圆心
export function FindCircleLinePointK(circle, point, k) {
  if (k == null) {
    k = 0;
  }
  var b = point[1] - k * point[0];
  var A = 1 + Math.pow(k, 2);
  var B = 2 * (k * b - k * circle[1] - circle[0]);
  var C = Math.pow(circle[0], 2) + Math.pow(b - circle[1], 2) - Math.pow(circle[2], 2);
  var x1 = (-B + Math.sqrt(Math.pow(B, 2) - 4 * A * C)) / (2 * A);
  x1 = toFixed(x1, 3);
  var x2 = (-B - Math.sqrt(Math.pow(B, 2) - 4 * A * C)) / (2 * A);
  x2 = toFixed(x2, 3);
  var point1 = [x1, toFixed(k * x1 + b, 3)];
  var point2 = [x2, toFixed(k * x2 + b, 3)];
  var length1 = LengthBetweenPoints(point1, point);
  var length2 = LengthBetweenPoints(point2, point);
  if (length1 > length2) {
    return point2;
  } else return point1;
}

/**
 * 有两点坐标，判断第三点是在上面还是下面
 * @param {[number, number]} point1
 * @param {[number, number]} point2
 * @param {[number, number]} point
 * @returns {boolean}
 */
export function IsUnderLine(point1, point2, point) {
  // if (IsEqual(point1[0], point2[0])) {
  //     //x相等是一条平行于y轴的线
  //     return point[0] > point1[0] ? true : false;
  // }
  // else if (IsEqual(point1[1], point2[1])) {
  //     //平行于x轴
  //     return point[1] > point1[1] ? true : false;
  // }
  // var k = (point1[1] - point2[1]) / (point1[0] - point2[0]);
  // var b = point1[1] - k * point1[0];
  // return (point[1] - k * point[0]) > b ? true : false;
  return ByTime(point1, point, point2) !== THREE_POINT_STATUS_TYPE.BYTIME;
}

//计算 两点坐标 中点垂直线公式 (Formula for Calculating Vertical Lines of Two-Point Coordinates)
export function TowPointVertical(point1, point2, destPoint) {
  var pointCenter = GetCenterInTwoPoints(point1, point2);

  var pointy = 0,
    pointx = 0;
  if (point1[0] === point2[0]) {
    pointx = destPoint[0];
    pointy = pointCenter[1];
  } else if (point1[1] === point2[1]) {
    pointx = pointCenter[0];
    pointy = destPoint[1];
  } else {
    //-(Bx-Ax)/(By-Ay)

    var k1 = -toFixed((point2[0] - point1[0]) / (point2[1] - point1[1]), 3);
    // (Bx-Ax)/(By-Ay)*(Ax+Bx)/2+(Ay+By)/2

    var b =
      (((point2[0] - point1[0]) / (point2[1] - point1[1])) * (point1[0] + point2[0])) / 2 + (point1[1] + point2[1]) / 2;
    b = toFixed(b, 3);
    var destPoint1x = destPoint[0],
      destPoint1y,
      destPoint2x,
      destPoint2y = destPoint[1];
    destPoint1y = k1 * destPoint1x + b;
    destPoint2x = (destPoint2y - b) / k1;
    var lengthPoint1 = LengthBetweenPoints(destPoint, [destPoint1x, destPoint1y]);
    var lengthPoint2 = LengthBetweenPoints(destPoint, [destPoint2x, destPoint2y]);
    if (lengthPoint1 > lengthPoint2) {
      pointx = destPoint2x;
      pointy = destPoint2y;
    } else {
      pointx = destPoint1x;
      pointy = destPoint1y;
    }
  }
  pointx = toFixed(pointx, 3);
  pointy = toFixed(pointy, 3);
  return [pointx, pointy];
}

//计算 两点坐标 起点垂直线公式
export function TowPointBeginVertical(point1, point2, destPoint) {
  var pointy = 0,
    pointx = 0;
  if (point1[0] === point2[0]) {
    pointx = destPoint[0];
    pointy = point1[1];
  } else if (point1[1] === point2[1]) {
    pointx = point1[0];
    pointy = destPoint[1];
  } else {
    //-(Bx-Ax)/(By-Ay)

    var k1 = -toFixed((point2[0] - point1[0]) / (point2[1] - point1[1]), 3);
    // (Bx-Ax)/(By-Ay)*(Ax+Bx)/2+(Ay+By)/2

    var b = point1[1] - k1 * point1[0];
    b = toFixed(b, 3);
    var destPoint1x = destPoint[0],
      destPoint1y,
      destPoint2x,
      destPoint2y = destPoint[1];
    destPoint1y = k1 * destPoint1x + b;
    destPoint2x = (destPoint2y - b) / k1;
    var lengthPoint1 = LengthBetweenPoints(destPoint, [destPoint1x, destPoint1y]);
    var lengthPoint2 = LengthBetweenPoints(destPoint, [destPoint2x, destPoint2y]);
    if (lengthPoint1 > lengthPoint2) {
      pointx = destPoint2x;
      pointy = destPoint2y;
    } else {
      pointx = destPoint1x;
      pointy = destPoint1y;
    }
  }
  pointx = toFixed(pointx, 3);
  pointy = toFixed(pointy, 3);
  return [pointx, pointy];
}

//计算 两点直线坐标 垂直线上一点destPoint 求交点  求垂足
export function TowPointVerticalAcross(point1, point2, destPoint) {
  var pointy = 0,
    pointx = 0;
  if (IsEqual(point1[0], point2[0])) {
    pointx = point1[0];
    pointy = destPoint[1];
  } else if (IsEqual(point1[1], point2[1])) {
    pointx = destPoint[0];
    pointy = point1[1];
  } else {
    //-(Bx-Ax)/(By-Ay)
    var k12 = toFixed((point2[1] - point1[1]) / (point2[0] - point1[0]), 3);
    var b12 = toFixed(point1[1] - k12 * point1[0]);
    var k = -toFixed(1 / k12, 3);
    var b = toFixed(destPoint[1] - k * destPoint[0], 3);
    pointx = (b - b12) / (k12 - k);
    pointy = k * pointx + b;
  }
  pointx = toFixed(pointx, 3);
  pointy = toFixed(pointy, 3);
  return [pointx, pointy];
}

/**
 * 计算 两点直线坐标 垂直线上一点 destPoint 求点到线的距离
 * @param {[number, number]} point1
 * @param {[number, number]} point2
 * @param {[number, number]} destPoint
 * @returns {number}
 */
export function TowPointDestPointLength(point1, point2, destPoint) {
  if (IsOneLine(point1, destPoint, point2) === THREE_POINT_STATUS_TYPE.ONLINE) {
    return 0;
  }

  var pointVertical = TowPointVerticalAcross(point1, point2, destPoint);
  var length = LengthBetweenPoints(pointVertical, destPoint);

  return length;
}

//计算 两点直线坐标 垂直线上一点destPoint
export function TowPointVerticalLength(point1, point2, destPoint, length, tempPoint) {
  var point1y = 0,
    point1x = 0,
    point2x = 0,
    point2y = 0;
  if (IsEqual(point1[0], point2[0]) && IsEqual(point1[1], point2[1])) {
    point1x = destPoint[0];
    point1y = destPoint[1] - length;
    point2x = destPoint[0];
    point2y = destPoint[1] + length;
  } else if (IsEqual(point1[0], point2[0])) {
    point1x = point1[0] - length;
    point1y = destPoint[1];
    point2x = point1[0] + length;
    point2y = destPoint[1];
  } else if (IsEqual(point1[1], point2[1])) {
    point1x = destPoint[0];
    point1y = point1[1] - length;
    point2x = destPoint[0];
    point2y = point1[1] + length;
  } else {
    //-(Bx-Ax)/(By-Ay)
    var k12 = (point2[1] - point1[1]) / (point2[0] - point1[0]);
    var b12 = point1[1] - k12 * point1[0];
    var k = -1 / k12;
    var b = destPoint[1] - k * destPoint[0];
    var verticalx = 0;
    if (!IsEqual(k12, k)) {
      verticalx = toFixed((b - b12) / (k12 - k));
    }
    var verticaly = toFixed(k * verticalx + b);
    if (IsZero(length)) {
      //如果三点一线就返回垂足点
      return [verticalx, verticaly];
    }
    var A = 1 + Math.pow(k, 2);
    var B = -2 * verticalx + 2 * k * (b - verticaly);
    var C = Math.pow(verticalx, 2) + Math.pow(b - verticaly, 2) - Math.pow(length, 2);
    var D = Math.abs(Math.pow(B, 2) - 4 * A * C);
    point1x = (-B + Math.sqrt(D)) / (2 * A);
    point1y = k * point1x + b;
    point2x = (-B - Math.sqrt(D)) / (2 * A);
    point2y = k * point2x + b;
  }
  point1x = toFixed(point1x, 3);
  point1y = toFixed(point1y, 3);
  point2x = toFixed(point2x, 3);
  point2y = toFixed(point2y, 3);
  var bRetTempPoint = IsUnderLine(point1, point2, tempPoint);
  var bRetPoint1 = IsUnderLine(point1, point2, [point1x, point1y]);
  //// console.log("dd",point1,point2,tempPoint)
  if (bRetTempPoint == bRetPoint1) {
    return [point1x, point1y];
  }
  return [point2x, point2y];
}

/**
 * //计算 两点直线坐标 垂直线上一点destPoint
 * @param {[number, number, string, object]} point1 start
 * @param {[number, number, string, object]} point2 end
 * @param {[number, number]} destPoint
 * @param {number} thickness - 测量长度的辅助线到被测量线间的距离
 * @param {boolean} lineStatus - true返回p1,false返回p2
 * @returns {[number, number]}
 */
export function TowPointVerticalLengthByStatus(point1, point2, destPoint, thickness, lineStatus) {
  // point1为右面或上面的线 point2为左面或下面的线
  let p1Y = 0,
    p1X = 0,
    p2X = 0,
    p2Y = 0;
  if (IsEqual(point1[0], point2[0]) && IsEqual(point1[1], point2[1])) {
    p1X = destPoint[0];
    p1Y = destPoint[1] - thickness;
    p2X = destPoint[0];
    p2Y = destPoint[1] + thickness;
  } else if (IsEqual(point1[0], point2[0])) {
    p1X = point1[0] - thickness;
    p1Y = destPoint[1];
    p2X = point1[0] + thickness;
    p2Y = destPoint[1];
  } else if (IsEqual(point1[1], point2[1])) {
    p1X = destPoint[0];
    p1Y = point1[1] - thickness;
    p2X = destPoint[0];
    p2Y = point1[1] + thickness;
  } else {
    var k12 = (point2[1] - point1[1]) / (point2[0] - point1[0]); // k=y2-y1/x2-x1
    var b12 = point1[1] - k12 * point1[0]; // b=y1-k*x1
    var k = -1 / k12; // 计算得到与 y=kx+b 垂直的线
    var b = destPoint[1] - k * destPoint[0];

    // 计算测量辅助线与被测量线的距离
    var vx = 0;
    if (!IsEqual(k12, k)) {
      // 原本为 b-b12/k-k12，由于SVG与屏幕坐标系的问题，因此乘以-1
      vx = toFixed((-1 * (b - b12)) / (k - k12), 3);
    }
    var vy = toFixed(k * vx + b, 3);
    if (IsZero(thickness)) {
      //如果三点一线就返回垂足点
      return [vx, vy];
    }

    var A = 1 + Math.pow(k, 2);
    var B = -2 * vx + 2 * k * (b - vy);
    var C = Math.pow(vx, 2) + Math.pow(b - vy, 2) - Math.pow(thickness, 2);
    var D = Math.abs(Math.pow(B, 2) - 4 * A * C);
    p1X = (-B + Math.sqrt(D)) / (2 * A);
    p1Y = k * p1X + b;
    p2X = (-B - Math.sqrt(D)) / (2 * A);
    p2Y = k * p2X + b;
    // point2x -= strokeWidth;
    // point1x -= strokeWidth;
  }
  p1X = toFixed(p1X, 3);
  p1Y = toFixed(p1Y, 3);
  p2X = toFixed(p2X, 3);
  p2Y = toFixed(p2Y, 3);
  var bRetPoint1 = IsUnderLine(point1, point2, [p1X, p1Y]);
  // console.log("dd",point1,point2,tempPoint)
  if (bRetPoint1 == lineStatus) {
    return [p1X, p1Y];
  } else {
    return [p2X, p2Y];
  }
}

//计算 两点直线坐标 垂直线上一点destPoint 两点的坐标
export function TowPointVerticalLengthPoints(point1, point2, destPoint, length) {
  var point1y = 0,
    point1x = 0,
    point2x = 0,
    point2y = 0;
  if (IsEqual(point1[0], point2[0]) && IsEqual(point1[1], point2[1])) {
    point1x = destPoint[0];
    point1y = destPoint[1] - length;
    point2x = destPoint[0];
    point2y = destPoint[1] + length;
  } else if (IsEqual(point1[0], point2[0])) {
    point1x = point1[0] - length;
    point1y = destPoint[1];
    point2x = point1[0] + length;
    point2y = destPoint[1];
  } else if (IsEqual(point1[1], point2[1])) {
    point1x = destPoint[0];
    point1y = point1[1] - length;
    point2x = destPoint[0];
    point2y = point1[1] + length;
  } else {
    //-(Bx-Ax)/(By-Ay)
    var k12 = (point2[1] - point1[1]) / (point2[0] - point1[0]);
    var b12 = point1[1] - k12 * point1[0];
    var k = -1 / k12;
    var b = destPoint[1] - k * destPoint[0];
    var verticalx = 0;
    if (!IsEqual(k12, k)) {
      verticalx = toFixed((b - b12) / (k12 - k));
    }
    var verticaly = toFixed(k * verticalx + b);
    if (IsZero(length)) {
      //如果三点一线就返回垂足点
      return [[verticalx, verticaly], [verticalx, verticaly]];
    }
    var A = 1 + Math.pow(k, 2);
    var B = -2 * verticalx + 2 * k * (b - verticaly);
    var C = Math.pow(verticalx, 2) + Math.pow(b - verticaly, 2) - Math.pow(length, 2);
    var D = Math.abs(Math.pow(B, 2) - 4 * A * C);
    point1x = (-B + Math.sqrt(D)) / (2 * A);
    point1y = k * point1x + b;
    point2x = (-B - Math.sqrt(D)) / (2 * A);
    point2y = k * point2x + b;
  }
  return [[toFixed(point1x), toFixed(point1y)], [toFixed(point2x), toFixed(point2y)]];
}

//计算 两点直线坐标 垂直线上一点destPoint
export function TowPointVerticalLengthByTime(point1, point2, destPoint, length, isShun) {
  var point1y = 0,
    point1x = 0,
    point2x = 0,
    point2y = 0;
  if (IsEqual(point1[0], point2[0])) {
    point1x = point1[0] - length;
    point1y = destPoint[1];
    point2x = point1[0] + length;
    point2y = destPoint[1];
  } else if (IsEqual(point1[1], point2[1])) {
    point1x = destPoint[0];
    point1y = point1[1] - length;
    point2x = destPoint[0];
    point2y = point1[1] + length;
  } else {
    //-(Bx-Ax)/(By-Ay)
    var k12 = toFixed((point2[1] - point1[1]) / (point2[0] - point1[0]), 3);
    var b12 = toFixed(point1[1] - k12 * point1[0], 3);
    var k = -toFixed(1 / k12, 3);
    var b = toFixed(destPoint[1] - k * destPoint[0], 3);
    var verticalx = toFixed((b - b12) / (k12 - k));
    var verticaly = toFixed(k * verticalx + b);
    if (IsZero(length)) {
      //如果三点一线就返回垂足点
      return [verticalx, verticaly];
    }
    var A = toFixed(1 + Math.pow(k, 2), 3);
    var B = toFixed(-2 * verticalx + 2 * k * (b - verticaly), 3);
    var C = Math.pow(verticalx, 2) + Math.pow(b - verticaly, 2) - Math.pow(length, 2);
    C = toFixed(C, 3);
    var D = Math.abs(Math.pow(B, 2) - 4 * A * C);
    point1x = (-B + Math.sqrt(D)) / (2 * A);
    point1y = k * point1x + b;
    point2x = (-B - Math.sqrt(D)) / (2 * A);
    point2y = k * point2x + b;
  }
  point1x = toFixed(point1x, 3);
  point1y = toFixed(point1y, 3);
  point2x = toFixed(point2x, 3);
  point2y = toFixed(point2y, 3);
  var shun = [point1x, point1y];
  var ni = [point2x, point2y];
  if (ByTime(point1, shun, point2) == THREE_POINT_STATUS_TYPE.BYTIME) {
    shun = [point1x, point1y];
    ni = [point2x, point2y];
  } else {
    shun = [point2x, point2y];
    ni = [point1x, point1y];
  }
  if (isShun) {
    return shun;
  } else return ni;
}

//计算 两点坐标 一条线上得点最近点
export function TowPointLinePoint(point1, point2, destPoint) {
  var pointCenter = GetCenterInTwoPoints(point1, point2);

  var pointy = 0,
    pointx = 0;
  if (point1[0] === point2[0]) {
    pointx = point1[0];
    pointy = destPoint[1];
  } else if (point1[1] === point2[1]) {
    pointx = destPoint[0];
    pointy = point1[1];
  } else {
    var k1 = toFixed((point2[1] - point1[1]) / (point2[0] - point1[0]), 3);
    var b = point1[1] - k1 * point1[0];
    b = toFixed(b, 3);
    var destPoint1x = destPoint[0],
      destPoint1y,
      destPoint2x,
      destPoint2y = destPoint[1];
    destPoint1y = k1 * destPoint1x + b;
    destPoint2x = (destPoint2y - b) / k1;
    var lengthPoint1 = LengthBetweenPoints(destPoint, [destPoint1x, destPoint1y]);
    var lengthPoint2 = LengthBetweenPoints(destPoint, [destPoint2x, destPoint2y]);
    if (lengthPoint1 > lengthPoint2) {
      pointx = destPoint2x;
      pointy = destPoint2y;
    } else {
      pointx = destPoint1x;
      pointy = destPoint1y;
    }
  }
  pointx = toFixed(pointx, 3);
  pointy = toFixed(pointy, 3);
  return [pointx, pointy];
}

export function GenerateHandlePoint(position) {
  var handlePoint = [];
  // 生成原点初始形状 (Generating initial shape)

  var angle = [0, 0];
  if (position.x1 > position.x2) {
    angle = [position.x1 + LENGTH_TYPE.INFORANGLESPACE, (position.y1 + position.y2) / 2];
  } else {
    angle = [position.x2 + LENGTH_TYPE.INFORANGLESPACE, (position.y1 + position.y2) / 2];
  }
  handlePoint = [
    [position.x1, position.y1],
    [position.x2, position.y1],
    [position.x2, position.y2],
    [position.x1, position.y2],
    [(position.x1 + position.x2) / 2, position.y1],
    [(position.x1 + position.x2) / 2, position.y2],
    [position.x1, (position.y1 + position.y2) / 2],
    [position.x2, (position.y1 + position.y2) / 2],
    [angle[0], angle[1]],
    [angle[0], position.y1],
    [angle[0], position.y2],
  ];
  return handlePoint;
}

/**
 * Determine the sweep-flag
 * @param {*} point1 start point
 * @param {*} point2 via point
 * @param {*} point3 end point
 * @returns {string} clockwise '1', counter-clockwise '0'. notice as the canvas is up-side-down, so the sweep-flag will have opposite effect
 */
export function IsArcDirection(point1, point2, point3) {
  if (ByTime(point1, point2, point3) == 1) {
    return '1';
  } else return '0';
}

/**
 * Check if the arc is a major arc (BIGARC)
 * @param {*} point1 one end of the arc
 * @param {*} point2 another end of the arc
 * @param {*} pointArcCenter the middle point on the arc
 * @param {number} r radius of the circle
 * @returns {boolean} whether the arc is a major arc
 */
export function IsBigArc(point1, point2, pointArcCenter, r) {
  //圆上两点中点到圆上点的距离大于半径就是大圆弧
  var center = GetCenterInTwoPoints(point1, point2);
  var length = LengthBetweenPoints(center, pointArcCenter);

  if (length > r) {
    return CIRCLE_SIZE_TYPE.BIGARC;
  } else {
    return CIRCLE_SIZE_TYPE.SMALLARC;
  }
}
export function IsBigArc1(point1, point2, isBytime, circle) {
  //圆上两点中点到圆上点的距离大于半径就是大圆弧
  let angle1 = getAngleBy2Points(circle, point1);
  let angle2 = getAngleBy2Points(circle, point2);
  if (isBytime && angle2 < angle1) {
    angle2 += Math.PI * 2;
  }
  if (!isBytime && angle1 < angle2) angle1 += Math.PI * 2;
  let angle = Math.abs(angle1 - angle2);
  // let pointArcCenter=GetCirclePoint(circle,angle);
  // let length = LengthBetweenPoints(center, pointArcCenter);
  // // console.log("dd",length,Math.round(circle[2]) ,length >Math.round(circle[2]))
  if (angle > Math.PI) {
    //1大圆弧
    return CIRCLE_SIZE_TYPE.BIGARC;
  } else {
    return CIRCLE_SIZE_TYPE.SMALLARC;
  }
}

//判断一点是圆内还是圆外
export function PointCircleState(point, circle) {
  //圆上两点中点到圆上点的距离大于半径就是大圆弧
  var length = LengthBetweenPoints(point, circle);
  if (IsEqual(length, circle[2])) {
    return POINT_CIRCLE_STATE.ONCIRCLE;
  } else if (length > circle[2]) {
    return POINT_CIRCLE_STATE.OUTCIRCLE;
  } else return POINT_CIRCLE_STATE.INCIRCLE;
}

//已知两点，求过点pointdest
export function GetParallel(point1, point2, pointDest) {
  var k = 0;
  if (point1[0] != point2[0]) {
    k = (point2[1] - point1[1]) / (point2[0] - point1[0]);
    k = toFixed(k, 3);
  }
  var b = pointDest[1] - k * pointDest[0];
  b = toFixed(b, 3);
  return [k, b];
}

//已知两点，求过点pointdest,垂直于该两点得垂直线
export function GetVertical(point1, point2, pointDest) {
  if (point1[0] == point2[0]) {
    return [0, pointDest[1]];
  }

  var k = (point2[1] - point1[1]) / (point2[0] - point1[0]);
  k = toFixed(-1 / k, 3);
  var b = pointDest[1] - k * pointDest[0];
  b = toFixed(b, 3);
  return [k, b];
}

//已知两点，求过两点直线得k和b
export function GetLineKB(point1, point2) {
  if (IsEqual(point1[0], point2[0])) {
    return [1, point1[0]];
  } else if (IsEqual(point1[1], point2[1])) {
    return [0, point1[1]];
  }
  var k = (point2[1] - point1[1]) / (point2[0] - point1[0]);
  //   k = toFixed(k, 3);
  var b = point1[1] - k * point1[0];
  // b = toFixed(b, 3);
  return [k, b];
}

/**
 * 过某点直线的垂直线
 * @param {array} p1
 * @param {array} lineKB1
 * @returns {array}
 */
export function GetLineKBVertical(p1, lineKB1) {
  let newLineKB = [];
  if (IsZero(lineKB1[0])) {
    newLineKB[0] = 1;
    newLineKB[1] = p1[0];
  } else if (lineKB1[0] === 1) {
    newLineKB[0] = 0;
    newLineKB[1] = p1[1];
  } else {
    newLineKB[0] = -1 / lineKB1[0];
    newLineKB[1] = p1[1] - newLineKB[0] * p1[0];
  }
  return newLineKB;
}

/**
 * 浮点数判断是否等于0，只判断到0.01为止
 * @param {number} num
 * @param {boolean} isRough - 粗算值
 * @returns {boolean}
 */
export function IsZero(num, isRough = false, hasMax = true) {
  let lastNum = Math.abs(Math.round(num * 100) / 100);
  if (isRough) {
    lastNum = Math.abs(Math.round((num + 0.005) * 100) / 100);
  }

  if (lastNum == 0) {
    return true;
  } else if (hasMax && lastNum > 1000000) {
    return true;
  } else {
    return false;
  }
}

/**
 * 浮点数判断是否相等，只判断到0.01为止
 * @param {number} num1
 * @param {number} num2
 * @returns {boolean}
 */
export function IsEqual(num1, num2) {
  var lastNum1 = Math.round(num1 * 100) / 100;
  var lastNum2 = Math.round(num2 * 100) / 100;
  if (lastNum1 == lastNum2) {
    return true;
  } else return false;
}

/**
 * 矩阵转换为字符串表示
 * @param {array} matrix
 */
export function GetMatrixString(matrix) {
  return 'matrix(' + matrix.join(',') + ')';
}

export function GetAngleDegree(arc, digit) {
  const angle = (180 * arc) / Math.PI;
  const fixedValue = typeof digit === 'number' ? toFixed(angle, digit) : toFixed(angle);

  const angleValue = fixedValue === 360 ? 0 : fixedValue;
  return angleValue;
}

/**
 * GetAngleArc
 * @param {number} angle
 * @param {number} digit
 * @returns {number}
 */
export function GetAngleArc(angle, digit = 0) {
  var arc = (Math.PI * angle) / 180;
  const fixedValue = digit ? toFixed(arc, digit) : toFixed(arc);
  return fixedValue;
}

/**
 * 距离point2点length处 靠近 point1的点
 * @param {number[]} point1
 * @param {number[]} point2
 * @param {number} length
 * @returns {number[]}
 */
export function LinePointsLength(point1, point2, length, isNearPoint1) {
  let kb = GetLineKB(point1, point2);
  let A = Math.pow(kb[0], 2) + 1;
  let B = -2 * point2[0] + 2 * kb[0] * (kb[1] - point2[1]);
  let C = Math.pow(point2[0], 2) + Math.pow(kb[1] - point2[1], 2) - Math.pow(length, 2);

  let D = Math.sqrt(Math.abs(B * B - 4 * A * C));
  let x1 = (-B - D) / (2 * A);
  x1 = toFixed(x1);
  let x2 = (-B + D) / (2 * A);
  x2 = toFixed(x2);
  let y1 = kb[0] * x1 + kb[1];
  y1 = toFixed(y1);
  let y2 = kb[0] * x2 + kb[1];
  y2 = toFixed(y2);
  let isBetween = LengthBetweenPoints(point1, [x2, y2]);
  if (isNearPoint1) {
    isBetween = LengthBetweenPoints(point1, [x2, y2]) && isNearPoint1;
  }
  if (LengthBetweenPoints(point1, [x1, y1]) < isBetween) {
    return [x1, y1];
  } else {
    return [x2, y2];
  }
}

/**
 * Get arc radian
 * @param {number[]} circle
 * @param {number[]} point1
 * @param {number[]} point2
 * @param {boolean} clockwise
 * @returns {number} radian of the arc
 */
export function GetArcAngle(circle, point1, point2, clockwise) {
  let angle1 = getAngleBy2Points(circle, point1);
  let angle2 = getAngleBy2Points(circle, point2);
  var angle = clockwise ? angle2 - angle1 : angle1 - angle2;
  // return radian in range [0, 2 * Math.PI)
  if (angle < 0) angle = angle + 2 * Math.PI;
  return toFixed(angle);
}

/**
 * GetArcCenter
 * @param {number[]} circle
 * @param {number[]} point1
 * @param {number[]} point2
 * @param {number} n
 * @param {boolean} isBytime
 */
export function GetArcCenter(circle, point1, point2, n, isBytime) {
  let angle1 = getAngleBy2Points(circle, point1);
  let angle = GetArcAngle(circle, point1, point2, isBytime);
  let x1 = 0,
    y1 = 0;
  angle = isBytime ? angle : -angle;
  let angleGo = angle1 + angle * n;
  x1 = toFixed(circle[0] + circle[2] * Math.cos(angleGo));
  y1 = toFixed(circle[1] + circle[2] * Math.sin(angleGo));
  return [x1, y1];
}

/**
 * GetCirclePoint
 * @param {number[]} circle
 * @param {number} angle
 * @returns {number[]}
 */
export function GetCirclePoint(circle, angle) {
  let x1 = circle[0] + circle[2] * Math.cos(angle);
  let y1 = circle[1] + circle[2] * Math.sin(angle);
  return [x1, y1];
}

/**
 * IsEqualPoint
 * @param {number[]} p1
 * @param {number[]} p2
 * @returns {boolean}
 */
export function IsEqualPoint(p1, p2) {
  if (IsEqual(p1[0], p2[0]) && IsEqual(p1[1], p2[1])) return true;
  else return false;
}

/**
 * 得到两点的范围
 * @param {number[]} p1
 * @param {number[]} p2
 * @returns {[number[], number[]]}
 */
export function GetTowPointRange(p1, p2) {
  let xMin = 0,
    yMin = 0,
    xMax = 0,
    yMax = 0;
  xMin = p1[0] < p2[0] ? p1[0] : p2[0];
  xMax = p1[0] > p2[0] ? p1[0] : p2[0];
  yMin = p1[1] < p2[1] ? p1[1] : p2[1];
  yMax = p1[1] > p2[1] ? p1[1] : p2[1];
  return [[xMin, yMin], [xMax, yMax]];
}

/**
 * 得到两点向量
 * @param {number[]} p1
 * @param {number[]} p2
 * @returns {number[]}
 */
export function GetVector(p1, p2) {
  return [p2[0] - p1[0], p2[1] - p1[1]];
}

/**
 * 判断直线上两点相对于其中一点 是否相反
 * @param {number[]} origin - 相对点
 * @param {number[]} p1
 * @param {number[]} p2
 * @returns {boolean}
 */
export function IsReverse(origin, p1, p2) {
  let angle1 = getAngleBy2Points(origin, p1);
  let angle2 = getAngleBy2Points(origin, p2);
  if (IsZero(Math.round(Math.abs(angle2 - angle1)))) {
    return false;
  } else return true;
}

/**
 * 删除数组中指定下标的元素
 * @param {array} arr - 数组
 * @param {number} index - 下标
 */
export function removeArray(arr, index) {
  let length = arr.length;
  if (index < 0 || index > length - 1) return;
  arr.splice(index, length - index);
}

/**
 * 判断arc 的圆形上一点是否在圆弧范围
 * @param {number[]} p1
 * @param {number[]} p2
 * @param {number[]} circle
 * @param {boolean} isBytime
 * @param {number[]} point
 * @param {boolean} isNotEqual - 如上只是范围上不等于
 * @returns {boolean}
 */
export function IsArcRangePoint(p1, p2, circle, isBytime, point, isNotEqual = false) {
  let angle1 = getAngleBy2Points(circle, p1);
  let angle2 = getAngleBy2Points(circle, p2);
  let anglePoint = getAngleBy2Points(circle, point);
  if (isNotEqual) {
    if (isBytime) {
      if (angle2 < angle1) angle2 += Math.PI * 2;
      if (anglePoint < angle2 && anglePoint > angle1) return true;
      else if (anglePoint + Math.PI * 2 < angle2 && anglePoint + Math.PI * 2 > angle1) return true;
    } else {
      if (angle1 < angle2) angle1 += Math.PI * 2;
      if (anglePoint < angle1 && anglePoint > angle2) return true;
      else if (anglePoint + Math.PI * 2 < angle1 && anglePoint + Math.PI * 2 > angle2) return true;
    }
  }
  if (isBytime) {
    if (angle2 < angle1) angle2 += Math.PI * 2;
    if (anglePoint <= angle2 && anglePoint >= angle1) return true;
    else if (anglePoint + Math.PI * 2 <= angle2 && anglePoint + Math.PI * 2 >= angle1) return true;
  } else {
    if (angle1 < angle2) angle1 += Math.PI * 2;
    if (anglePoint <= angle1 && anglePoint >= angle2) return true;
    else if (anglePoint + Math.PI * 2 <= angle1 && anglePoint + Math.PI * 2 >= angle2) return true;
  }
  return false;
}

/**
 * IsPointInRange
 * @param {number[]} point
 * @param {[number[], number[]]} pointRange
 * @returns {boolean}
 */
export function IsPointInRange(point, pointRange) {
  if (
    point[0] > pointRange[0][0] &&
    point[0] < pointRange[1][0] &&
    point[1] > pointRange[0][1] &&
    point[1] < pointRange[1][1]
  )
    return true;
  else return false;
}

/**
 * IsPointInRange
 * @param {number[]} point
 * @param {[number[], number[]]} pointRange
 * @returns {boolean}
 */
export function IsPointInRange1(point, pointRange) {
  if (
    point[0] >= pointRange[0][0] &&
    point[0] <= pointRange[1][0] &&
    point[1] >= pointRange[0][1] &&
    point[1] <= pointRange[1][1]
  )
    return true;
  else return false;
}

/**
 * IsPointValid
 * @param {number} point
 * @returns {boolean}
 */
export function IsPointValid(point) {
  if (typeof point == 'object' && point.length >= 2) return true;
  else return false;
}

/**
 * BezierLineAcross
 * @param {number[]} p0
 * @param {number[]} p1
 * @param {number[]} p2
 * @param {number[]} linePoint0
 * @param {number[]} linePoint1
 * @returns {number[]}
 */
export function BezierLineAcross(p0, p1, p2, linePoint0, linePoint1) {
  // //贝塞尔曲线的3个点
  // var p0 = new Point(-1, 0);
  // var p1 = new Point(1, -1);
  // var p2 = new Point(2, 2);

  // //直线上的两个点
  // var lineP0 = new Point(-2, -2);
  // var lineP1 = new Point(4, 14 / 5);

  //根据两点式求出的直线一般式系数
  var lineA = linePoint1[1] - linePoint0[1]; // (lineP1.y - lineP0.y);
  var lineB = linePoint0[0] - linePoint1[0]; // (lineP0.x - lineP1.x);
  var lineC = linePoint1[0] * linePoint0[1] - linePoint1[0] * linePoint1[1]; //(lineP1.x * lineP0.y - lineP1.y * lineP0.x);

  //贝塞尔曲线x分量的3个系数（可在连载十七中找到这公式）
  var ax = p0[0] - 2 * p1[0] + p2[0]; // p0.x - 2 * p1.x + p2.x;
  var bx = 2 * p1[0] - 2 * p0[0]; //2 * p1.x - 2 * p0.x;
  var cx = p0[0]; // p0.x;

  //贝塞尔曲线y分量的3个系数（可在连载十七中找到这公式）
  var ay = p0[1] - 2 * p1[1] + p2[1]; //p0.y - 2 * p1.y + p2.y;
  var by = 2 * p1[1] - 2 * p0[1]; // 2 * p1.y - 2 * p0.y;
  var cy = p0[1]; // p0.y;

  //At+Bt^2+C=0
  //一元二次方程的3个系数
  var squareFormulaA = lineA * ax + lineB * ay;
  var squareFormulaB = lineA * bx + lineB * by;
  var squareFormulaC = lineA * cx + lineB * cy + lineC;
  var ts = [];

  if (squareFormulaA != 0) {
    //接着求根公式
    var delta = squareFormulaB * squareFormulaB - 4 * squareFormulaA * squareFormulaC;
    // console.log(delta);
    //delta小于0时无实数解
    if (delta >= 0) {
      var t1 = (-squareFormulaB + Math.sqrt(delta)) / 2 / squareFormulaA;
      var t2 = (-squareFormulaB - Math.sqrt(delta)) / 2 / squareFormulaA;
      if (t1 >= 0 && t1 <= 1) {
        ts.push(t1);
      }
      if (t2 >= 0 && t2 <= 1) {
        ts.push(t2);
      }
    }
  } else if (squareFormulaB != 0) {
    var t = -squareFormulaC / squareFormulaB;
    if (t >= 0 && t <= 1) {
      ts.push(t);
    }
  }
  let acrossPoints = [];
  for (var i = 0, len = ts.length; i < len; i++) {
    //把t代入到参数方程中求出xy
    let t = ts[i];
    var x = ax * t * t + bx * t + cx;
    var y = ay * t * t + by * t + cy;
    acrossPoints.push([x, y]);
  }
  return acrossPoints;
}

/**
 * generateOperateId
 * @returns {string}
 */
export function generateOperateId() {
  var operateLastId = 0;
  var randomId = '';
  for (var i = 0; i < 4; ++i) {
    randomId += Math.floor(Math.random() * 10);
  }
  var operateId = '';
  var dateTime = new Date().getTime();
  operateId = String(dateTime) + String(randomId) + operateLastId.toString(5);
  // var date = new Date();
  // operateLastId = useData.length;
  // operateId = date.toLocaleDateString() + operateLastId.toString(5);
  return operateId;
}

/**
 * getStyle
 * @deprecated
 * @param {*} item
 * @param {*} style
 */
export function getStyle(items, style) {
  items.forEach(item => {
    if (item.tagName == 'g') {
      if (item.hasAttribute('lncd-role') && item.attributes['lncd-role'].value == 'symbol-hook') {
        if (item.hasAttribute('stroke-width')) style.strokeWidth = item.attributes['stroke-width'].value;
        if (item.hasAttribute('stroke')) style.stroke = item.attributes['stroke'].value;
        if (item.hasAttribute('fill')) style.fill = item.attributes['fill'].value;
      }
      let children = Array.prototype.slice.call(item.children, 0);
      getStyle(children, style);
    }
  });
}

/**
 * getAllShape
 * @param {*} shape
 * @returns
 */
export function getAllShape(shape) {
  var s = [];
  shape.forEach(item => {
    if (item.tagName == 'g') {
      let svgItems = Array.prototype.slice.call(item.children, 0);
      var operateId = generateOperateId();
      s.push(<g key={operateId}>{getAllShape(svgItems)}</g>);
    } else if (item.tagName == 'path') {
      var operateId = generateOperateId();
      if (item.hasAttribute('style')) {
        s.push(<path key={operateId} d={item.attributes['d'].value} style={item.attributes['style'].value}></path>);
      } else {
        s.push(<path key={operateId} d={item.attributes['d'].value}></path>);
      }
    } else if (item.tagName == 'rect') {
      var operateId = generateOperateId();
      if (item.hasAttribute('style')) {
        s.push(
          <rect
            key={operateId}
            x={item.attributes['x'].value}
            y={item.attributes['y'].value}
            width={item.attributes['width'].value}
            height={item.attributes['height'].value}
            style={item.attributes['style'].value}
          ></rect>
        );
      } else {
        s.push(
          <rect
            key={operateId}
            x={item.attributes['x'].value}
            y={item.attributes['y'].value}
            width={item.attributes['width'].value}
            height={item.attributes['height'].value}
          ></rect>
        );
      }
    } else if (item.tagName == 'text') {
      var operateId = generateOperateId();
      s.push(
        <text key={operateId} x={item.attributes['x'].value} y={item.attributes['y'].value}>
          {item.innerHTML}
        </text>
      );
    }
  });
  return s;
}

export function getAllRShape(shape) {
  var s = [];
  React.Children.map(shape, function(item) {
    if (item.type == 'g') {
      let svgItems = item.props.children;
      var operateId = generateOperateId();
      s.push(<g key={operateId}>{getAllRShape(svgItems)}</g>);
    } else if (item.type == 'path') {
      var operateId = generateOperateId();
      if (item.props.style != typeof undefined) {
        s.push(<path key={operateId} d={item.props.d} style={item.props.style}></path>);
      } else {
        s.push(<path key={operateId} d={item.props.d}></path>);
      }
    } else if (item.type == 'rect') {
      var operateId = generateOperateId();
      if (item.props.style != typeof undefined) {
        s.push(
          <rect
            key={operateId}
            x={item.props.x}
            y={item.props.y}
            width={item.props.width}
            height={item.props.height}
            style={item.props.style}
          ></rect>
        );
      } else {
        s.push(
          <rect
            key={operateId}
            x={item.props.x}
            y={item.props.y}
            width={item.props.width}
            height={item.props.height}
          ></rect>
        );
      }
    } else if (item.type == 'text') {
      var operateId = generateOperateId();
      s.push(
        <text key={operateId} x={item.props.x} y={item.props.y}>
          {item.props.children}
        </text>
      );
    }
  });
  return s;
}

export function createNewChildren(children, props) {
  if (!children) return;
  if (!children.length) {
    return React.cloneElement(children, {});
  } else {
    return children.map((child, i) => {
      if (!child) return;
      return React.cloneElement(child, { key: `symbol-child-${i}` });
    });
  }
}

/**
 * SVG 适配器
 * @param {string} svgStr
 * @param {object} config
 * @param {number} config.displayHeight - 添加到 diagram 后，默认显示的高度
 * @returns {{svg: SVGElement, width: number, height; number}}
 */
export function SVGAdapter(svgStr, config) {
  const svg = createSVGElement(svgStr);
  const layer = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  let { width, height } = getBBox(svg, layer, g => {
    g.setAttributeNS(null, 'lncd-role', 'symbol-hook');
    g.setAttributeNS(null, 'preserveAspectRatio', 'XMinYMin meet');
  });

  let SCALE_FACTOR = 1;
  // if (width > height) {
  //   SCALE_FACTOR = LANE_WIDTH / width;
  // } else {
  //   SCALE_FACTOR = LANE_WIDTH / height;
  // }
  const { displayHeight } = config;
  let ratio = width / height;
  const newHeight = displayHeight;
  const newWidth = displayHeight * ratio;
  const scale = newHeight / height;

  const offset = calcSymbolPos(svg, SCALE_FACTOR, scale);
  width = newWidth * SCALE_FACTOR;
  height = newHeight * SCALE_FACTOR;
  layer.setAttributeNS(
    null,
    'transform',
    `matrix(${SCALE_FACTOR * scale} 0 0 -${SCALE_FACTOR * scale} ${offset.H} ${offset.V})`
  );
  return {
    svg: svg,
    width: width,
    height: height,
  };
}

/**
 * 计算 symbol 的位置
 * @param {HTMLElement} svg
 * @param {number} SCALE_FACTOR - 根据 Street 宽度处理后的缩放系数
 * @param {number} scale - 按照Symbol类别的缩放系数
 * @returns {{H: number, V: number}}
 */
function calcSymbolPos(svg, SCALE_FACTOR, scale = 1) {
  // 计算 handlepoint 的半径
  const r = getUserUnitByPixel() * utility.getHandleRadius();
  const offset = { H: 0, V: 0 };

  // 根据 viewBox 偏移
  if (svg.getAttribute('viewBox')) {
    let originViewBoxArr = svg.getAttribute('viewBox').split(' ');
    let originViewBoxNumberArr = originViewBoxArr.map(i => Number(i));

    // 根据 viewBox 调整 symbol 的位置
    const [viewBoxX, viewBoxY, viewBoxWidth, viewBoxHeight] = originViewBoxNumberArr;
    offset.H = offset.H - (viewBoxWidth / 2) * SCALE_FACTOR * scale;
    offset.H = offset.H - viewBoxX * SCALE_FACTOR * scale;
    offset.V = offset.V + (viewBoxHeight / 2) * SCALE_FACTOR * scale;
    offset.V = offset.V + viewBoxY * SCALE_FACTOR * scale;
  }

  return offset;
}
