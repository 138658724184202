import React from 'react';
import { FindCircle, IsArcDirection, IsBigArc } from '@src/data/CommonFunc';
import { GetShapeStyle } from '@src/data/CommonProps';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import { getCircleHandlePoint } from '@src/data/AnchorsPoint';
import utility from '@src/data/Utility';
import { ANCHORS_TYPE } from '@src/constant';
import { SquareRectHandlePoint, CircleHandlePoint } from '@src/data/AnchorsPoint';

export function getHandles(anchors) {
  let index = 0;
  return anchors.map(anchor => {
    const [x, y, type] = anchor
    const point = [x, y]
    const id = `${index}||${type}`
    index += 1;
    switch(type) {
      case ANCHORS_TYPE.CONNECTBEGIN:
      case ANCHORS_TYPE.CONNECTNORMAL:
      case ANCHORS_TYPE.CLOSEEND: {
        return <SquareRectHandlePoint key={id} id={id} point={point} />;
      }
      case  ANCHORS_TYPE.CONNECTCANGLE: {
        return <CircleHandlePoint key={id} id={id} point={point} />;
      }
      case ANCHORS_TYPE.CLOSEANGLE: {
        return <CircleHandlePoint key={id} id={ANCHORS_TYPE.CLOSEANGLE} point={point} />;
      }
      default: return null;
    }
  });
}

class SelectConnectedLines extends React.Component {
  getPathShape = object => {
    if (object.handlepoint.length < 3) return null;
    var D = [];
    for (let i = 0; i < object.handlepoint.length; i++) {
      if (object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTBEGIN) {
        D.push('M' + String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      } else if (
        object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTNORMAL ||
        object.handlepoint[i][2] === ANCHORS_TYPE.CLOSEEND
      ) {
        var cirlcleLeft = FindCircle(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1]);
        D.push('A' + String(cirlcleLeft[2]) + ',' + String(cirlcleLeft[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1], cirlcleLeft[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i - 2], object.handlepoint[i - 1], object.handlepoint[i])
        );
        D.push(String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      }
    }
    return <path d={D.join(' ')} />;
  };

  render() {
    const { props } = this;
    return (
      <g>
        {this.getPathShape(props.object)}
        {getHandles(props.object.handlepoint)}
      </g>
    );
  }
}

export default SelectConnectedLines;
