import { produce } from 'immer';
import {
  SET_HEADER_HEIGHT,
  ON_WINDOW_RESIZE,
  SET_PROPS_MENU_OPEN,
  SET_COLLAPSED,
  SET_TEMPLATES_STATUS,
  SET_SYMBOLS_STATUS,
  TOGGLE_NIGHT_MODE,
  SET_LOADING,
  SET_APP_DEVICE,
  SET_SHOW_PROGRESS,
  SET_PROGRESS_TOTAL,
  SET_PROGRESS_CURRENT,
} from '@src/type/app';

const initialState = {
  headerHeight: 0,
  canvasHeight: 0,
  siderWidth: 170,
  // as an embeded app, use these 2 variable names are more specific for app layout dimension
  appHeight: 0,
  appWidth: 0,
  // TODO: create reselect to determine this value by selected shape
  propsMenuOpen: false,
  collapsed: false,
  nightMode: false,
  loading: false,
  device: 'untouch', // ['touch', 'untouch']
  showProgress: false,
  progressTotal: 0,
  progressCurrent: 0,
};

export default produce((draft, action) => {
  if (!draft) {
    return initialState;
  }

  switch (action.type) {
    case SET_HEADER_HEIGHT: {
      const { value } = action;
      draft.headerHeight = value;
      // TODO: use reselect to represent canvas height
      draft.canvasHeight = draft.appHeight - value;
      break;
    }
    case ON_WINDOW_RESIZE: {
      const { height, width } = action;
      draft.appHeight = height;
      draft.appWidth = width;
      draft.canvasHeight = height - draft.headerHeight;
      break;
    }
    case SET_PROPS_MENU_OPEN: {
      draft.propsMenuOpen = action.value;
      break;
    }
    case SET_COLLAPSED: {
      draft.collapsed = action.value;
      if (action.value) {
        draft.siderWidth = 46;
      } else {
        draft.siderWidth = 170;
      }
      break;
    }
    case SET_TEMPLATES_STATUS: {
      draft.template.isLoaded = action.value;
      break;
    }
    case SET_SYMBOLS_STATUS: {
      draft.symbol.isLoaded = action.value;
      break;
    }
    case TOGGLE_NIGHT_MODE: {
      draft.nightMode = !draft.nightMode;
      break;
    }
    case SET_LOADING: {
      draft.loading = action.loading;
      break;
    }
    case SET_APP_DEVICE: {
      draft.device = action.device;
      break;
    }
    case SET_SHOW_PROGRESS: {
      draft.showProgress = action.show;
      break;
    }
    case SET_PROGRESS_TOTAL: {
      draft.progressTotal = action.total;
      break;
    }
    case SET_PROGRESS_CURRENT: {
      draft.progressCurrent = action.current;
      break;
    }
    default:
      break;
  }
});
