import React from 'react';
import {
  GetCenterInTwoPoints,
  TowPointVerticalLengthByStatus,
  LengthBetweenPoints,
  IsUnderLine,
  IsZero,
} from '@src/data/CommonFunc';
import { GetShapes, GetStreetPathD } from '@src/data/BusinessFun';
import ArrowLength from '../ArrowLength';
import {
  getSquareHandlePoint,
  getTriangleHandlePoint,
  GetCrossAnchors,
  getCircleHandlePoint,
} from '@src/data/AnchorsPoint';
import {
  GetStreetAreaBegin,
  GetStreetAreaEnd,
  GetMaxHeight,
  GetStreetMaxPoints,
  GetStreetLineBegin,
  GetStreetLinePoints,
  GetStreetBeginTopPoint,
  GetStreetEndTopPoint,
} from '@src/actions/StreetFunHandle';
import { GetStreetText } from '@src/actions/ShapeTextHandle';
import { GetShapeLine } from '../ShapeFun';
import {
  ANCHORS_TYPE,
  COLOR_TYPE,
  DASH_ARRAY_TYPE,
  STREET_LINE_TYPE,
  STREET_POINT_TYPE,
  STREET_SPACE,
} from '@src/constant';
import LncdText from '../LncdText';
import StreetService from '@src/services/StreetService';
import { getTextContainerBox } from '@src/utils';

class SelectDirt extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps(prevProps) {
    this.init(prevProps.object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    let maxheight = GetMaxHeight(object);
    let maxTopPoints = GetStreetMaxPoints(object, maxheight / 2, true);
    let linepoints = [];
    let beginStreetLine = GetStreetLineBegin(object.groupdata);
    linepoints[beginStreetLine] = maxTopPoints;
    // 获取各个车道的锚点
    if (isFirst) {
      // FIXME: what's the difference use setState ?
      // eslint-disable-next-line
      this.state = {
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
      };
    } else {
      this.setState({
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
      });
    }
  };

  getAnchors = (shapes, object) => {
    let anchors = object.handlepoint;
    shapes.push(getSquareHandlePoint(ANCHORS_TYPE.STREETBEGIN, anchors[3]));
    shapes.push(getSquareHandlePoint(ANCHORS_TYPE.STREETEND, anchors[4]));
    let i = GetStreetAreaBegin(anchors);
    while (i != -1 && anchors[i][3].rightindex != -1 && i != null) {
      let centerIndex = anchors[i][3].rightindex;
      let endIndex = anchors[centerIndex][3].rightindex;
      i = endIndex;
      if (anchors[centerIndex][2] === STREET_POINT_TYPE.Center) {
        let id = String(centerIndex) + '||' + ANCHORS_TYPE.STREETCENTER;
        shapes.push(getCircleHandlePoint(id, anchors[centerIndex]));
      }
      if (
        anchors[i][2] === STREET_POINT_TYPE.NORMAL &&
        anchors[i][3].rightindex != -1 &&
        anchors[i][3].leftIndex != -1
      ) {
        let id = String(i) + '||' + ANCHORS_TYPE.STREETNORMAL;
        shapes.push(getSquareHandlePoint(id, anchors[i]));
      }
    }
  };
  getCenterLine = (shapes, object) => {
    let D = [];
    let beginIndex = GetStreetAreaBegin(object.handlepoint);
    GetStreetPathD(D, object.handlepoint, beginIndex);
    shapes.push(<path d={D.join(' ')} key="centerline" />);
  };
  getTextRect = object => {
    const {
      text: { size, text, width, height },
      rotateangle,
    } = object;
    if (!text) return;

    // text point coord, i.e. bottom-middle of the rect/text
    const [x, y] = new StreetService(object).getTextPosition();
    return (
      <g>
        {getTextContainerBox({
          x,
          y,
          width,
          height,
          rotateAngle: (rotateangle * 180) / Math.PI,
          id: ANCHORS_TYPE.STREETTEXTRECT,
          fill: 'transparent',
          cursor: 'move',
        })}
      </g>
    );
  };
  getAcrossSelect(shapes) {
    let acrossData = this.state.object.streetacrossdata;
    for (let i = 0; i < acrossData.length; i++) {
      if (acrossData[i].length < 3) continue;
      if (acrossData[i].length > 3 && acrossData[i][3].selectflag) {
        let shapeLine = GetShapeLine(acrossData[i][0], acrossData[i][2], 'acrossline' + String(i), true, {
          stroke: COLOR_TYPE.GREEN,
          fill: COLOR_TYPE.NONE,
          strokewidth: 1,
          strokedasharray: DASH_ARRAY_TYPE.Dashes,
        });
        shapes.push(shapeLine);
        let shapeMove1 = getSquareHandlePoint(
          String(i) + '||' + String(0) + '||' + ANCHORS_TYPE.STREETACROSSPOINT,
          acrossData[i][0]
        );
        shapes.push(shapeMove1);
        let shapeMove2 = getSquareHandlePoint(
          String(i) + '||' + String(2) + '||' + ANCHORS_TYPE.STREETACROSSPOINT,
          acrossData[i][2]
        );
        shapes.push(shapeMove2);
        // let shapeMove3 = getSquareHandlePoint(
        //   String(i) +
        //     '||' +
        //     String(1) +
        //     '||' +
        //     ANCHORS_TYPE.STREETACROSSCenter,
        //   GetCenterInTwoPoints(acrossData[i][0], acrossData[i][2]),
        //   4
        // );
        // shapes.push(shapeMove3);

        let acrossPoint = GetCrossAnchors('cross' + String(i), acrossData[i][1], 4);
        shapes.push(acrossPoint);
        let acrossPointIsUnder = IsUnderLine(acrossData[i][0], acrossData[i][2], acrossData[i][1]);
        let topAcrossPoint0 = TowPointVerticalLengthByStatus(
          acrossData[i][0],
          acrossData[i][1],
          acrossData[i][0],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint1 = TowPointVerticalLengthByStatus(
          acrossData[i][0],
          acrossData[i][1],
          acrossData[i][1],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint11 = TowPointVerticalLengthByStatus(
          acrossData[i][1],
          acrossData[i][2],
          acrossData[i][1],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint2 = TowPointVerticalLengthByStatus(
          acrossData[i][1],
          acrossData[i][2],
          acrossData[i][2],
          8,
          acrossPointIsUnder
        );
        shapes.push(
          <ArrowLength p1={topAcrossPoint0} p2={topAcrossPoint1} istop={false} key={String(i) + 'acrossLength1'} />
        );
        shapes.push(
          <ArrowLength p1={topAcrossPoint11} p2={topAcrossPoint2} istop={false} key={String(i) + 'acrossLength2'} />
        );
      }
    }
  }
  render() {
    if (this.state == null) return null;
    const { object } = this.state;
    if (object == null || object.handlepoint.length < 3) return null;
    let shapes = [];
    this.getAnchors(shapes, object);
    this.getCenterLine(shapes, object);
    this.getAcrossSelect(shapes);
    return (
      <g id="select-street">
        {GetShapes(shapes)}
        {this.getTextRect(this.props.object)}
      </g>
    );
  }
}

export default SelectDirt;
