import * as React from 'react';

function SvgIconRoundaboutRoad(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 31 31" {...props}>
      <g transform="translate(-7 -2)" fill="none" fillRule="evenodd">
        <circle stroke="currentColor" strokeWidth={2} cx={22.5} cy={17.5} r={14.5} />
        <path
          d="M28.077 9.654a10.467 10.467 0 014.199 6.852 10.493 10.493 0 01-.227 4.365"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M27.32 11.477l-2.267-3.496a.2.2 0 01.212-.304l4.445 1.01a.2.2 0 01.106.326L27.639 11.5a.2.2 0 01-.318-.023z"
          fill="currentColor"
        />
        <path
          d="M13.276 17.355a10.467 10.467 0 013.46-7.254 10.493 10.493 0 013.784-2.186"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path
          d="M15.217 16.996l-1.697 3.806a.2.2 0 01-.37-.012l-1.574-4.278a.2.2 0 01.216-.267l3.271.472a.2.2 0 01.154.279z"
          fill="currentColor"
        />
        <g>
          <path
            d="M27.473 26.181a10.467 10.467 0 01-8 .77 10.452 10.452 0 01-4.522-2.784"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
          />
          <path
            d="M26.165 24.702l4.137-.505a.2.2 0 01.18.324l-2.856 3.552a.2.2 0 01-.34-.048l-1.281-3.047a.2.2 0 01.16-.276z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconRoundaboutRoad;
