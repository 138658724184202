import * as React from 'react';

function SvgImageArrowPositionNone(props) {
  return (
    <svg width={30} height={3} {...props}>
      <path fill="currentColor" d="M0 0h30v3H0z" fillRule="evenodd" />
    </svg>
  );
}

export default SvgImageArrowPositionNone;
