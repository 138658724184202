import { SET_MATRIX, SET_MATRIX_INITIALIZED } from '@src/type/transform';

export const setMatrixInitialized = value => ({ type: SET_MATRIX_INITIALIZED, value });

// TODO: store the transform matrix of the viewport, to trigger Grid refresh, or set transform while loading SVG back (TBD)
export const setMatrix = matrix => (dispatch, getState) => {
  if (matrix instanceof Array) {
    dispatch({
      type: SET_MATRIX,
      matrix,
    });
  } else {
    const currentMatrix = getState().transform.matrix;
    const scale = matrix.scale || currentMatrix[0];
    const x = matrix.x || currentMatrix[4];
    const y = matrix.y || currentMatrix[5];
    dispatch({
      type: SET_MATRIX,
      matrix: [scale, currentMatrix[1], currentMatrix[2], -scale, x, y],
    });
  }
};

/**
 * async get position in the transform matrix
 * @description 由于matrix更新频繁，绑定reducer到组件会造成大量重渲染
 * @returns {[number, number]} - position
 */
export const getMatrixPos = () => (dispatch, getState) => {
  const { transform } = getState();
  return [transform.matrix[4], transform.matrix[5]];
};

export const getMatrixScale = () => (dispatch, getState) => {
  const { transform } = getState();
  return transform.matrix[0];
};
