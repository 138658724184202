import * as React from 'react';

function SvgIconTemplates(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M5 16h14v3H5zM5 11h14v3H5zM5 6h14v3H5z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconTemplates;
