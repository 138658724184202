module.exports = {
  getStrokePattern: function(strokePattern, strokeWidth) {
    var strokeDashArray;
    var a = 8 * strokeWidth;
    var b = 2 * strokeWidth;
    c = 2 * strokeWidth;
    switch (strokePattern) {
      case 'solid':
        strokeDashArray = null;
        break;
      case 'dashes':
        strokeDashArray = a + ',' + c;
        break;
      case 'dots':
        strokeDashArray = b + ',' + c;
        break;
      case 'dashdot':
        strokeDashArray = a + ',' + c + ',' + b + ',' + c;
        break;
      case 'dashdotdot':
        strokeDashArray = a + ',' + c + ',' + b + ',' + c + ',' + b + ',' + c;
        break;
      default:
        strokeDashArray = null;
    }
    return strokeDashArray;
  },
};
