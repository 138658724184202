import * as handlePoint from '@src/actions/HandlePoint';
import * as workData from '@src/data/WorkData';
import { CANVAS_MENU_TYPE } from '@src/constant';
import types, { TOGGLE_GRID, SET_AROUND_CENTER_OFFSET, RESET_AROUND_CENTER_OFFSET } from '@src/type/canvas';
import { namespace } from '@src/utils';
import produce from 'immer';
import _ from 'lodash';

namespace('appState');
const initialState = {
  clipboard: [],
  clipboardStatus: '',

  /**
   * the current drawing shape - active toolbar item name
   */
  drawingShape: undefined,
  streetType: undefined,

  /**
   * whether all roads are locked
   */
  isLockingRoads: false,
  isShowGrid: false,

  /**
   * when zoom around not at center, the canvas will offset
   */
  offsetXToCenter: 0,
  offsetYToCenter: 0,
};

window.appState.canvas = _.cloneDeep(initialState);

export default produce((draft, action) => {
  if (!draft) {
    return initialState;
  }

  switch (action.type) {
    // case types.FLUSH_WORK_DATA: {
    //   draft.objList = workData.getUseData();
    //   break;
    // }
    case types.SET_DRAWING_SHAPE: {
      draft.drawingShape = action.name;
      draft.streetType = action.streetType;
      break;
    }
    case types.COPY: {
      if (workData.getSelectObjects().length > 0) {
        draft.clipboard = workData.getSelectObjects();
        draft.clipboardStatus = 'copy';
      }
      break;
    }
    case types.CUT: {
      draft.clipboard = workData.getSelectObjects();
      // FIXME: side effects
      draft.clipboard.forEach(obj => workData.deleteObject(obj.operateid));
      draft.clipboardStatus = 'cut';
      break;
    }
    case types.PASTE: {
      // TODO: accept parameter to relocate pasted data:
      // the new center point for clipboard data
      // pastePoint: [],
      // FIXME: side effects

      workData.pasteData(
        draft.clipboard,
        draft.clipboardStatus,
        // draft.isMouseOverCanvas && !isTouchDevice() ? [draft.clientX, draft.clientY] : null
        action.pt
      );
      break;
    }
    // case types.TAKE_SNAPSHOT: {
    //   workData.takeSnapshot(action.map);
    //   break;
    // }
    // case types.UNDO: {
    //   workData.undo();
    //   break;
    // }
    // case types.REDO: {
    //   workData.redo();
    //   break;
    // }
    case types.ALIGN_BOTTOM:
      handlePoint.AlignBottom();
      break;
    case types.ALIGN_CENTER:
      handlePoint.AlignCenter();
      break;
    case types.ALIGN_LEFT:
      handlePoint.AlignLeft();
      break;
    case types.ALIGN_MIDDLE:
      handlePoint.AlignMiddle();
      break;
    case types.ALIGN_RIGHT:
      handlePoint.AlignRight();
      break;
    case types.ALIGN_TOP:
      handlePoint.AlignTop();
      break;
    case types.BRING_FRONT:
      workData.BringFront();
      break;
    case types.SEND_BACK:
      workData.BringBack();
      break;
    case types.GROUP:
      workData.GroupShapes();
      break;
    case types.UNGROUP:
      workData.UnGroupShapes();
      break;
    case types.TOGGLE_LOCK_ALL_ROADS: {
      draft.isLockingRoads = !draft.isLockingRoads;
      // TODO: unselect all roads
      // TODO: using global variable temporarily
      window.appState.canvas.isLockingRoads = draft.isLockingRoads;
      break;
    }
    case types.SET_LOCK_ALL_ROADS: {
      draft.isLockingRoads = action.value;
      window.appState.canvas.isLockingRoads = action.value;
      break;
    }
    // TODO: extend this to include work data reset etc.
    case types.RESET: {
      draft.isLockingRoads = false;
      window.appState.canvas.isLockingRoads = false;
      draft.drawingShape = undefined;
      break;
    }
    case TOGGLE_GRID: {
      draft.isShowGrid = !draft.isShowGrid;
      break;
    }
    case SET_AROUND_CENTER_OFFSET: {
      draft.offsetXToCenter = action.offsetX;
      draft.offsetYToCenter = action.offsetY;
      break;
    }
    case RESET_AROUND_CENTER_OFFSET: {
      draft.offsetXToCenter = 0;
      draft.offsetYToCenter = 0;
      break;
    }
    default:
      break;
  }
});
