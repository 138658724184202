import { GetCrossWalkText } from '@src/actions/ShapeTextHandle';
import { COLOR_TYPE, CROSS_WALK_PATTERN, CROSS_WALK_SPACE } from '@src/constant';
import { GetPathLineD } from '@src/data/BusinessFun';
import {
  getAngleBy2Points,
  GetCenter, LengthBetweenPoints,
  TowPointVerticalLength
} from '@src/data/CommonFunc';
import GeomPoint from '@src/data/GeomPoint';
import { fillOtherProps, strokeOtherProps } from '@src/utils';
import React from 'react';

class CrossWalk extends React.Component {
  constructor(props) {
    super(props);
  }

  getRect = (anchors, p1, p2, p3, p4) => {
    let D = [];
    GetPathLineD(D, anchors[1], anchors[2]);
    GetPathLineD(D, anchors[7], anchors[8]);
    //GetPathLineD(D, [p1[0] - 5, p1[1] + 5], [p2[0] - 5, p2[1] - 5]);
    //GetPathLineD1(D, [p3[0] + 5, p3[1] - 5]);
    //GetPathLineD1(D, [p4[0] + 5, p4[1] + 5]);
    D.push('Z');
    return <path d={D.join(' ')} stroke={COLOR_TYPE.WHITE} strokeWidth={0} />;
  };

  render() {
    const { props } = this;
    const { object } = props;
    let anchors = object.handlepoint;
    if (anchors.length < 4) return null;

    var width = LengthBetweenPoints(anchors[0], anchors[6]);
    let height = LengthBetweenPoints(anchors[4], anchors[5]) / 2;
    let transform = '';
    const cx = (anchors[0][0] + anchors[6][0]) / 2;
    const cy = (anchors[0][1] + anchors[6][1]) / 2;
    var angleD = getAngleBy2Points(anchors[0], anchors[6]);
    var gp = new GeomPoint(anchors[0][0] + height, anchors[0][1]);
    gp.rotateRad((Math.PI * 3) / 2 + angleD, new GeomPoint(anchors[0][0], anchors[0][1]));
    let startPoint = [gp.x, gp.y];
    angleD = (angleD * 180) / Math.PI;
    transform = 'rotate(' + angleD + ',' + startPoint[0] + ',' + startPoint[1] + ')';
    let pointLeftTop = TowPointVerticalLength(anchors[0], anchors[6], anchors[0], height, anchors[1]);
    let pointRightTop = TowPointVerticalLength(anchors[0], anchors[6], anchors[6], height, anchors[7]);
    let pointLeftBottom = TowPointVerticalLength(anchors[0], anchors[6], anchors[0], height, anchors[2]);
    let pointRightBottom = TowPointVerticalLength(anchors[0], anchors[6], anchors[6], height, anchors[8]);
    var D = [];
    if (object.style.pattern === CROSS_WALK_PATTERN.HORIZONTAL) {
      // D.push("M" + anchors[1][0].toString() + "," + anchors[1][1].toString());
      // D.push("L" + anchors[7][0].toString() + "," + anchors[7][1].toString());
      // D.push("M" + anchors[2][0].toString() + "," + anchors[2][1].toString());
      // D.push("L" + anchors[8][0].toString() + "," + anchors[8][1].toString());
      GetPathLineD(D, anchors[1], anchors[7]);
      GetPathLineD(D, anchors[2], anchors[8]);
    } else if (object.style.pattern === CROSS_WALK_PATTERN.VERTICAL && width) {
      let n = Math.round(width / CROSS_WALK_SPACE.VERTICALSPACE);
      for (let i = 0; i <= n; i++) {
        let topCenter = GetCenter(pointLeftTop, pointRightTop, i / n);
        let bottomCenter = GetCenter(pointLeftBottom, pointRightBottom, i / n);
        //  D.push("M" + topCenter[0].toString() + "," + topCenter[1].toString());
        //  D.push("L" + bottomCenter[0].toString() + "," + bottomCenter[1].toString());
        GetPathLineD(D, topCenter, bottomCenter);
      }
    }

    return (
      // TODO: can we assume it always return a crosswalk component?
      <g id={object.functype} lncd-role="crosswalk" fill={object.style.fill} {...fillOtherProps(object.style.fill)}>
        <rect
          x={startPoint[0]}
          y={startPoint[1]}
          width={width}
          height={height * 2}
          strokeOpacity="0"
          strokeWidth="0"
          transform={transform}
        />
        {this.getRect(anchors, pointLeftTop, pointLeftBottom, pointRightBottom, pointRightTop)}
        <path d={D.join(' ')} lncd-role="crosswalk-line" stroke={object.style.stroke} {...strokeOtherProps(object.style.stroke)} />
        {GetCrossWalkText(object, height)}
      </g>
    );
  }
}

export default CrossWalk;
