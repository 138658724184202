import App from './App';
import withMap from '@src/components/Map/withMap';
import withTransformManager from '@src/components/TransformManager/withTransformManager';
import './index.scss';
import { compose } from 'ramda';

export default compose(
  withTransformManager,
  withMap
)(App);
