import * as React from 'react';

function SvgIconLocked(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 20" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-5-2h24v24H-5z" />
        <g fill="currentColor" fillRule="nonzero">
          <circle cx={7} cy={14.5} r={1.5} />
          <path d="M12 9V5c0-2.757-2.243-5-5-5S2 2.243 2 5v4a2 2 0 00-2 2v7c0 1.103.896 2 2 2h10c1.104 0 2-.897 2-2v-7a2 2 0 00-2-2zM4 5c0-1.654 1.346-3 3-3s3 1.346 3 3v4H4V5zM2 18v-7h10l.002 7H2z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconLocked;
