import React from 'react';
import { GetMatrixRotate } from '@src/data/BusinessFun';
import { GetMatrixString } from '@src/data/CommonFunc';

class Stall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { props } = this;
    const { x, y, angle } = props;
    let angleRotate = 0;
    if (angle != null) {
      angleRotate = angle + (Math.PI * 3) / 2;
    }
    var matrix = GetMatrixRotate([x, y], angleRotate);
    return (
      <g transform={GetMatrixString(matrix)}>
        <line x1={x - 12} y1={y} x2={x + 12} y2={y} />
        <line x1={x} y1={y} x2={x} y2={y - 12} />
      </g>
    );
  }
}

export default Stall;
