import { fabric } from 'fabric';

export const getFileNameFromFullName = fileName => {
  const fileNameArray = fileName.split('.');
  if (1 === fileNameArray.length) {
    return fileName;
  } else {
    return fileNameArray.slice(0, fileNameArray.length - 1).join('.');
  }
};

function loadContentFromFileUrl(url) {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(url, function(_a, _b, _c, objects) {
      if (!objects || 0 === objects.length) {
        reject(false);
      } else {
        resolve(objects.shift());
      }
    });
  });
}

/**
 * load SVG file that Crash Designer exported
 * @description Used to load SVG diagram file into Crash Designer, see src\components\Header\TopMenuBar.js
 * @param {string} url
 * @returns {Promise<{}>}
 */
export function loadMetaDataFormFileUrl(url) {
  return loadContentFromFileUrl(url).then(result => {
    const desc = result.querySelector('#shapes-data');
    try {
      const metadata = JSON.parse(desc.innerHTML, (key, value) => {
        if (key === 'originsvg') {
          return decodeURIComponent(value);
        }
        return value;
      });
      return metadata;
    } catch (error) {
      console.error(error);
      return null;
    }
  });
}

export function readFileContent(file) {
  const reader = new FileReader();
  reader.readAsText(file);
  return new Promise(resolve => {
    reader.onload = event => {
      resolve(event.target.result);
    };
  });
}

export function readFileMetadataFromContent(content) {
  const div = document.createElement('div');
  div.innerHTML = content;
  const desc = div.querySelector('#shapes-data');
  if (!desc || !desc.innerHTML) {
    return null;
  }
  try {
    const metadata = JSON.parse(desc.innerHTML, (key, value) => {
      if (key === 'originsvg') {
        return decodeURIComponent(value);
      }
      return value;
    });
    return metadata;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {Blob} file
 * @returns {Promise<string | ArrayBuffer>}
 */
export function readFileAsDataUrl(file) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise(resolve => {
    reader.onload = event => {
      resolve(event.target.result);
    };
  });
}

/**
 *
 * @param {Blob} blob
 */
export async function loadTemplateDataFromBlob(blob) {
  const content = await blob.text();
  const div = document.createElement('div');
  div.innerHTML = content;
  const shapesDataElem = div.querySelector('#shapes-data');
  let shapeData;
  if (shapesDataElem) {
    if (shapesDataElem.innerHTML.length > 0) {
      shapeData = JSON.parse(shapesDataElem.innerHTML);
    } else {
      throw new Error('shapes data is empty.');
    }
  } else {
    throw new Error('not found element.');
  }
  return {
    useData: JSON.stringify(shapeData.useData.flat()),
    mapData: shapeData.map,
    src: content,
  };
}
