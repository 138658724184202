import React from 'react';
import { FindCircle, IsArcDirection, IsBigArc } from '@src/data/CommonFunc';
import Text from '../Text';
import { GetClosedText, GetLengthConnectLine } from '@src/data/BusinessFun';
import { ANCHORS_TYPE } from '@src/constant';

class CloseShapes extends React.Component {
  constructor(props) {
    super(props);
  }
  getPathShape = object => {
    if (object.handlepoint.length < 3) {
      return null;
    }

    var D = [];
    for (let i = 0; i < object.handlepoint.length; i++) {
      if (object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTBEGIN) {
        D.push('M' + String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      } else if (object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTNORMAL) {
        var cirlcleLeft = FindCircle(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1]);
        D.push('A' + String(cirlcleLeft[2]) + ',' + String(cirlcleLeft[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1], cirlcleLeft[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i - 2], object.handlepoint[i - 1], object.handlepoint[i])
        );
        D.push(String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      } else if (object.handlepoint[i][2] === ANCHORS_TYPE.CLOSEEND) {
        var cirlcleLeft = FindCircle(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1]);
        D.push('A' + String(cirlcleLeft[2]) + ',' + String(cirlcleLeft[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1], cirlcleLeft[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i - 2], object.handlepoint[i - 1], object.handlepoint[i])
        );
        D.push(String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));

        var cirlcleRight = FindCircle(object.handlepoint[0], object.handlepoint[i], object.handlepoint[i + 1]);
        D.push('A' + String(cirlcleRight[2]) + ',' + String(cirlcleRight[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[0], object.handlepoint[i], object.handlepoint[i + 1], cirlcleRight[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i], object.handlepoint[i + 1], object.handlepoint[0])
        );
        D.push(String(object.handlepoint[0][0]) + ',' + String(object.handlepoint[0][1]));
      }
    }
    return <path d={D.join(' ')} />;
  };

  getText = object => {
    if (
      object.handlepoint.length < 6 ||
      object.handlepoint[object.handlepoint.length - 3][2] !== ANCHORS_TYPE.CLOSEEND
    ) {
      return null;
    }

    const content = object.text.islength ? String(GetLengthConnectLine(object.handlepoint)) : object.text.text;

    const textObject = GetClosedText(
      object.handlepoint,
      object.text.position,
      object.marks.height,
      object.marks.isexist,
      object.style.strokewidth,
      object.text.size
    );
    return (
      <Text
        angle={textObject.angletext}
        x={textObject.textpoint[0]}
        y={textObject.textpoint[1]}
        textAnchor={textObject.textanchor}
        text={object.text}
        content={content}
      />
    );
  };

  render() {
    const { props } = this;
    const { object } = props;
    return (
      <g id={object.functype}>
        <metadata sstrokepattern={object.style.strokepattern} />
        {this.getPathShape(props.object)}
        {/* {this.getPathHideShape(object)} */}
        {this.getText(object)}
      </g>
    );
  }
}

export default CloseShapes;
