import * as React from 'react';

function SvgImageDottedLine(props) {
  return (
    <svg width={75} height={7} {...props}>
      <path
        d="M1 3.5h74"
        stroke="#1D1F20"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeDasharray="1,6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgImageDottedLine;
