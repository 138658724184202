import { OPERATIONS } from '@src/constant';

const ns = '[Canvas(Root SVG)] ';

export const composeActionType = operation => `${ns}${operation}`;

const operations = {};
for (let key in OPERATIONS) {
  operations[key] = composeActionType(OPERATIONS[key]);
}

export default {
  TAKE_SNAPSHOT: `${ns}take a snapshot`,
  FLUSH_WORK_DATA: `${ns}flush work data`,
  SET_DRAWING_SHAPE: `${ns}set current drawing shape`,
  TOGGLE_LOCK_ALL_ROADS: `${ns}toggle lock all roads`,
  SET_LOCK_ALL_ROADS: `${ns}lock/unlock all road`,
  RESET: `${ns}reset canvas and start over`,
  ...operations,
};

export const TOGGLE_GRID = `${ns}toggle grid`;
export const SET_AROUND_CENTER_OFFSET = `${ns}set around center offset`;
export const RESET_AROUND_CENTER_OFFSET = `${ns}reset around center offset`;
