import { ANCHORS_TYPE } from '@src/constant';
import { CircleHandlePoint, SquareRectHandlePoint } from '@src/data/AnchorsPoint';
import GeomPoint from '@src/data/GeomPoint';
import { getHandlePointSize, getLinePathData, getTextContainerBox } from '@src/utils';
import React from 'react';

const SelectCallout = ({ anchors, text, rotateangle }) => {
  if (anchors.length > 6) {
    const [x, y] = anchors[1];
    const { width = 0, height = 0 } = text;
    const ptCenter = new GeomPoint(x, y);
    const point = new GeomPoint(x + width / 2 + getHandlePointSize() * 2, y);
    point.rotateRad(rotateangle, ptCenter);

    const pathData = getLinePathData(...anchors.slice(0, 2));
    const box = getTextContainerBox({
      x,
      y,
      width,
      height,
      rotateAngle: (rotateangle * 180) / Math.PI,
    });

    return (
      <g>
        <path d={pathData} />
        {box}
        <SquareRectHandlePoint id={ANCHORS_TYPE.LINEBEGIN} point={anchors[0]} />
        <CircleHandlePoint id={ANCHORS_TYPE.CALLOUTANGLE} point={point} lncd-role="rotate-handle" />
      </g>
    );
  } else {
    return null;
  }
};

export default SelectCallout;
