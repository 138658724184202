import React, { Component } from 'react';
import { Menu, Dropdown, Button, Icon } from 'antd';
import { IconLeftOut, IconRightOut, IconLane, IconShoulder, IconBikeLane } from '@src/components/UI/Icons';
import { addStreetLane } from '@src/actions/HandlePoint';
import { connect } from 'react-redux';
import { addLane } from '@src/action/propMenu';
import { withTranslation } from 'react-i18next';

const MenuItem = Menu.Item;

class AddLaneSelectGroup extends Component {
  /**
   * the "left" icon used to add lane to left/top
   * the "right" icon used to add lane to right/bottom
   */
  getOverlay = iconPosition => {
    const { streetId, streetHorizontal } = this.props;

    // new lane position
    let position;
    if (iconPosition === 'left') {
      position = streetHorizontal ? 'top' : 'left';
    } else if (iconPosition === 'right') {
      position = streetHorizontal ? 'bottom' : 'right';
    }

    const addLane = name => {
      addStreetLane({ streetId, name, position });
    };

    return (
      <Menu className="prop-menu-select-overlay">
        <MenuItem
          key="lane"
          onClick={() => {
            addLane('Add a Lane');
          }}
        >
          <Icon component={IconLane} />
          {this.props.t('property.addLane.laneText')}
        </MenuItem>
        <MenuItem
          key="bike-lane"
          onClick={() => {
            addLane('Add a Bike Lane');
          }}
        >
          <Icon component={IconBikeLane} />
          {this.props.t('property.addLane.bikeLaneText')}
        </MenuItem>
        <MenuItem
          key="shoulder"
          onClick={() => {
            addLane('Add Shoulder');
          }}
        >
          <Icon component={IconShoulder} />
          {this.props.t('property.addLane.shoulderText')}
        </MenuItem>
      </Menu>
    );
  };

  render() {
    const { streetHorizontal } = this.props;

    const angle = streetHorizontal ? 90 : 0;
    const iconStyle = {
      transform: `rotate(${angle}deg)`,
    };

    return (
      <div className="prop-menu-select-group">
        <Dropdown overlay={this.getOverlay('left')} placement="topLeft" trigger={['click']}>
          <Button>
            <Icon component={IconLeftOut} style={iconStyle} />
          </Button>
        </Dropdown>
        <Dropdown overlay={this.getOverlay('right')} placement="topLeft" trigger={['click']}>
          <Button>
            <Icon component={IconRightOut} style={iconStyle} />
          </Button>
        </Dropdown>
      </div>
    );
  }
}

export default connect(
  state => ({
    streetHorizontal: state.propMenu.streetHorizontal,
  }),
  { addLane }
)(withTranslation()(AddLaneSelectGroup));
