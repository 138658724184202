import React from 'react';
import { connect } from 'react-redux';
import { setPropsMenuOpen } from '@src/action/app';
import { Icon } from 'antd';

/**
 * Custom TabBar for property menus
 * @param {*} props
 */
function TabBar(props) {
  return (
    <div style={{ display: 'flex' }}>
      {props.children}
      <span style={{ flex: 1, textAlign: 'right', padding: 5, marginRight: 5 }}>
        <Icon type="down" onClick={() => props.setPropsMenuOpen(false)} />
      </span>
    </div>
  );
}

export default connect(
  null,
  { setPropsMenuOpen }
)(TabBar);
