import React from 'react';
import {
  GetPathArcD,
  GetPathLineD,
  GetPathLineD1,
  GetPathTowBessel,
  GetPathArcD1,
  GetPathArcDL,
} from '@src/data/BusinessFun';
import { IsArcDirection, IsBigArc, FindCircle, IsPointValid } from '@src/data/CommonFunc';
import { COLOR_TYPE, CIRCLE_SIZE_TYPE } from '@src/constant';

/**
 *  基本直线封装
 *
 * @export
 * @param {*} p1
 * @param {*} p2
 * @param {*} id
 * @param {*} isStyle
 * @param {*} style
 * @returns
 */
export function GetShapeLine(p1, p2, id, isStyle, style) {
  if (IsPointValid(p1) || IsPointValid(p2)) return null;
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: COLOR_TYPE.NONE,
      strokeDasharray: style.strokedasharray,
    };
  }
  return <line x1={p1[0]} y1={p1[1]} x2={p2[0]} y2={p2[1]} id={id} key={id} {...shapeStyle} />;
}

/**
 * 基本弧形封装
 *
 * @export
 * @param {*} p1
 * @param {*} p2
 * @param {*} r
 * @param {*} isBigArc
 * @param {*} isBytime
 * @param {*} id
 * @param {*} isStyle
 * @param {*} style
 * @returns
 */
export function GetShapeArc(p1, p2, r, isBigArc, isBytime, id, isStyle, style) {
  let bigArcParam = CIRCLE_SIZE_TYPE.SMALLARC;
  let arcDirectionParam = '0';
  if (isBigArc) {
    bigArcParam = CIRCLE_SIZE_TYPE.BIGARC;
  }
  if (isBytime) {
    arcDirectionParam = '1';
  }
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathArcD(D, p1, p2, r, bigArcParam, arcDirectionParam);
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}

export function GetShapeArc1(p1, p2, p3, id, isStyle, style) {
  let circle = FindCircle(p1, p2, p3);
  let bigArcParam = IsBigArc(p1, p3, p2);
  let arcDirectionParam = IsArcDirection(p1, p2, p3);
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathArcD(D, p1, p2, circle[2], bigArcParam, arcDirectionParam);
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
export function GetShapeCircle(circle, id, isStyle, style) {
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  return <circle id={id} key={id} cx={circle[0]} cy={circle[1]} r={circle[2]} {...shapeStyle} />;
}

export function GetShapePath(D, id, isStyle, style) {
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
//streetacross得一个覆盖图形
export function GetShapeStreetAcross0(p1, p2, p3, p4, isBytime, topR, bottomR, id, isStyle, style) {
  if (!IsPointValid(p1) || !IsPointValid(p2) || !IsPointValid(p3) || !IsPointValid(p4)) return null;
  var D = [];
  GetPathArcD(D, p1, p2, topR, CIRCLE_SIZE_TYPE.SMALLARC, isBytime ? '0' : '1');
  GetPathArcDL(D, p3, p4, bottomR, CIRCLE_SIZE_TYPE.SMALLARC, isBytime ? '1' : '0');
  D.push('Z');
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}

export function GetShapeClosePath(points, id, isStyle, style) {
  if (points.length < 3) return null;
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathLineD(D, points[0], points[1]);
  for (let i = 2; i < points.length; i++) {
    GetPathLineD1(D, points[i]);
  }
  D.push('Z');
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
export function GetShapeTowBesselPath(points, id, isStyle, style) {
  if (points.length < 3) return null;
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathTowBessel(D, points[0], points[1], points[2]);

  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
export function GetShapeTowBesselPath1(points, id, isStyle, style) {
  if (points.length < 3) return null;
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathTowBessel(D, points[0], points[1], points[2]);
  GetPathLineD1(D, points[1]);
  D.push('Z');
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
export function GetShapeThreeTriangle(points, id, isStyle, style) {
  if (points.length < 3) return null;
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathLineD(D, points[0], points[1]);
  GetPathLineD1(D, points[2]);
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
//streetacross 一个覆盖图形
export function GetShapeStreetAcross1(p1, p2, p3, p4, topR, bottomR, id, isStyle, style) {
  if (!IsPointValid(p1) || !IsPointValid(p2) || !IsPointValid(p3) || !IsPointValid(p4)) return null;
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathArcD(D, p1, p2, topR, CIRCLE_SIZE_TYPE.SMALLARC, '0');
  GetPathArcD(D, p1, p2, topR, CIRCLE_SIZE_TYPE.SMALLARC, '1');
  GetPathArcD(D, p3, p4, bottomR, CIRCLE_SIZE_TYPE.SMALLARC, '1');
  GetPathArcD(D, p3, p4, bottomR, CIRCLE_SIZE_TYPE.SMALLARC, '0');
  GetPathLineD(D, p1, p2);
  GetPathLineD1(D, p3);
  GetPathLineD1(D, p4);
  D.push('Z');
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
export function GetShapeRect(p1, p2, p3, p4, id, isStyle, style) {
  if (!IsPointValid(p1) || !IsPointValid(p2) || !IsPointValid(p3) || !IsPointValid(p4)) return null;
  let shapeStyle = {};
  if (isStyle) {
    shapeStyle = {
      stroke: style.stroke,
      strokeWidth: style.strokewidth,
      fill: style.fill,
      strokeDasharray: style.strokedasharray,
    };
  }
  let D = [];
  GetPathLineD(D, p1, p2);
  GetPathLineD1(D, p3);
  GetPathLineD1(D, p4);
  D.push('Z');
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}
