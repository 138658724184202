import { useCallback } from 'react';
import domtoimage from 'dom-to-image';
import { message } from '@src/components/Message';
import { useTranslation } from 'react-i18next';

export function useLeafletMap(leafletMap) {
  const { t } = useTranslation();

  // FIXME: 获取数据失败未捕获异常，离线没考虑进来
  // FIXME: also it's too slow to fetch tiles and download a image as the tiles should have been fetched already!
  /**
   * @description 地图由 tiles 拼接而成，每个 tile 由 http 获取到，
   * 导出时，将当前整个 tiles 组成的地图转换为 base64 保存
   * @param {function} callback
   */
  const getMapHref = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (leafletMap) {
        var node = leafletMap.getContainer();
        domtoimage
          .toPng(node, {
            style: {
              overflow: 'hidden',
            },
          })
          .then(function(dataUrl) {
            // var img = new Image();
            // img.src = dataUrl;
            // document.body.appendChild(img);
            resolve(dataUrl);
          })
          .catch(function(error) {
            message.error(t('menu.top.exportMapError'));
            reject(error);
          });
      } else {
        resolve('');
      }
    });
  }, [leafletMap]);

  return { getMapHref };
}
