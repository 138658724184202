import * as React from 'react';

function SvgIconChatBubble(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M5.998 22v-4h-2c-1.103 0-2-.897-2-2V4c0-1.104.897-2 2-2h16c1.103 0 2 .896 2 2v12c0 1.103-.897 2-2 2h-8.667l-5.333 4zm-2-18v12h4v2l2.667-2h9.333V4h-16z" />
          <path d="M5.998 6h12v2h-12zM5.998 9h12v2h-12zM5.998 12h8v2h-8z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconChatBubble;
