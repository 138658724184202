import React from 'react';
import { IconPin, IconPinFill } from '@src/components/UI/Icons';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { togglePin } from '@src/action/sidebar';
import './Pin.css';

function Pin({ pin, togglePin }) {
  return (
    <div className="lncd-pin-sidebar" onClick={togglePin}>
      <Icon component={pin ? IconPinFill : IconPin} style={{ transform: pin ? 'rotateZ(-45deg)' : 'none' }} />
    </div>
  );
}

const mapDispatchToProps = {
  togglePin,
};

const mapStateToProps = state => ({
  pin: state.sidebar.pin,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pin);
