import React, { useState } from 'react';
import _ from 'lodash';
import CustomInputNumber from '../Element/CustomInputNumber';
import useObjectProperty from '../useObjectProperty';
import { INCHES_PER_FOOT } from '@src/data/CommonFunction';
import TouchScroll from '@src/components/TouchDevice/TouchScroll';
import { useTranslation } from 'react-i18next';

function ArcRadiusInputNumber({ value, min, max, step, onChangeValue }) {
  const changeValue = value => {
    if (value > min - step && value < max) {
      onChangeValue(value);
    }
  };
  const onPlus = () => {
    const v = _.round(value, 1);
    const m = _.round(min, 1);
    // console.log(`plus: value:${v}, min:${m}`);
    if (v >= m) {
      changeValue(v - step);
    }
  };
  const onMinus = () => {
    const v = _.round(value, 1);
    const m = _.round(min, 1);
    // console.log(`minus: value:${v}, min:${m}`);
    if (v >= m) {
      changeValue(v + step);
    }
  };
  return (
    <CustomInputNumber
      value={value}
      min={min}
      max={max}
      step={step}
      formatterClass="Measurement"
      parser={'"'}
      onChangeValue={onChangeValue}
      onPlus={onPlus}
      onMinus={onMinus}
    />
  );
}

const SizeMenu = props => {
  const [angle, handleAngle] = useObjectProperty(props.objectId, 'sizeAngle');
  const [width, handleWidth] = useObjectProperty(props.objectId, 'sizeWidth');
  const [height, handleHeight] = useObjectProperty(props.objectId, 'sizeHeight');
  const [length, handleLength] = useObjectProperty(props.objectId, 'sizeLength');

  const { t } = useTranslation();

  return (
    <TouchScroll className="props-container scrollbar">
      {props.item.map(element => {
        switch (element) {
          case 'length': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.lengthLabel')}:</span>
                <CustomInputNumber
                  value={length / INCHES_PER_FOOT}
                  min={1}
                  max={1000}
                  step={2}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={handleLength}
                />
              </div>
            );
          }
          case 'width': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.widthLabel')}:</span>
                <CustomInputNumber
                  value={props.width ? props.width : width}
                  min={1}
                  max={1000}
                  step={2}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={props.handlewidth ? props.handlewidth : handleWidth}
                />
              </div>
            );
          }
          case 'pointWidth': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.pointWidthLabel')}:</span>
                <CustomInputNumber
                  value={props.pointWidth}
                  min={1}
                  max={1000}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={props.handlePointWidth}
                />
              </div>
            );
          }
          case 'height': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.heightLabel')}:</span>
                <CustomInputNumber
                  value={height}
                  min={1}
                  max={1000}
                  step={2}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={handleHeight}
                />
              </div>
            );
          }
          case 'chord': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.chordLabel')}:</span>
                <CustomInputNumber
                  value={props.chord}
                  min={1}
                  max={1000}
                  step={2}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={props.chordhandle}
                />
              </div>
            );
          }
          case 'ordinate': {
            const value = props.ordinate;

            // 当 arc 变为一条 line 时，移除这个组件
            if (value == 0 || props.radius == 0) return null;

            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.ordinateLabel')}:</span>
                <CustomInputNumber
                  value={value}
                  min={0}
                  max={1000}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={props.ordinatehandle}
                />
              </div>
            );
          }
          case 'radius': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.radiusLabel')}:</span>
                <CustomInputNumber
                  value={props.radius}
                  min={1}
                  max={1000}
                  step={2}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={props.handleradius}
                />
              </div>
            );
          }
          case 'arcRadius': {
            // 当 arc 变为一条 line 时，移除这个组件
            if (props.ordinate == 0 || props.radius == 999) return null;

            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.arcRadiusLabel')}:</span>
                <ArcRadiusInputNumber
                  value={props.radius}
                  min={props.chord / 2}
                  max={1000}
                  onChangeValue={props.radiushandle}
                />
              </div>
            );
          }
          case 'rotation': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.rotationLabel')}:</span>
                <CustomInputNumber
                  value={isNaN(props.angle) ? angle : props.angle}
                  min={0}
                  max={360}
                  formatter={`°`}
                  parser={'°'}
                  step={5}
                  isLoop
                  onChangeValue={props.handleAngle ? props.handleAngle : handleAngle}
                />
              </div>
            );
          }
          case 'threadDepth': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.threadDepthLabel')}</span>
                <CustomInputNumber
                  value={props.stateObject.marks.length}
                  min={1}
                  max={1000}
                  formatter={`"`}
                  parser={'"'}
                  onChangeValue={props.handlemarkslength}
                />
              </div>
            );
          }
          case 'threadWidth': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.sizeTab.threadWidthLabel')}:</span>
                <CustomInputNumber
                  value={props.width / INCHES_PER_FOOT}
                  min={1}
                  max={1000}
                  step={2}
                  formatterClass="Measurement"
                  parser={'"'}
                  onChangeValue={props.handlewidth}
                />
              </div>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </TouchScroll>
  );
};

export default SizeMenu;
