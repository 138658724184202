import Utility from './Utility';
import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';

class GeomRect {
  constructor(left = 0, top = 0, right = 0, bottom = 0) {
    if (Utility.isTypeofNumber(left)) {
      this.left = left;
    }
    if (Utility.isTypeofNumber(top)) {
      this.top = top;
    }
    if (Utility.isTypeofNumber(right)) {
      this.right = right;
    }
    if (Utility.isTypeofNumber(bottom)) {
      this.bottom = bottom;
    }
    if (this.left > this.right) {
      var t = this.left;
      this.left = this.right;
      this.right = t;
    }
    if (this.top < this.bottom) {
      var t = this.top;
      this.top = this.bottom;
      this.bottom = t;
    }
  }

  Center() {
    return new GeomPoint((this.left + this.right) / 2, (this.bottom + this.top) / 2);
  }

  clone() {
    return new GeomRect(this.left, this.top, this.right, this.bottom);
  }

  getBisectorsSvgPathData() {
    var gl0 = new GeomLine(this.ptMidLeft(), this.ptMidRight());
    var gl1 = new GeomLine(this.ptMidTop(), this.ptMidBottom());
    return gl0.getSvgPathData() + ' ' + gl1.getSvgPathData() + ' ';
  }

  getAspectRatio() {
    var h = this.Height();
    if (h === 0) {
      return NaN;
    }
    return this.Width() / h;
  }

  Height() {
    return this.top - this.bottom;
  }

  /**
   *
   * @param {number} cx
   * @param {number} cy
   * @returns {GeomRect}
   */
  inflateRect(cx, cy) {
    this.left -= cx;
    this.bottom -= cy;
    this.right += cx;
    this.top += cy;
    return this;
  }

  ptMidLeft() {
    return new GeomPoint(this.left, (this.top + this.bottom) / 2);
  }

  ptMidTop() {
    return new GeomPoint((this.left + this.right) / 2, this.top);
  }

  ptMidRight() {
    return new GeomPoint(this.right, (this.top + this.bottom) / 2);
  }

  ptMidBottom() {
    return new GeomPoint((this.left + this.right) / 2, this.bottom);
  }

  Width() {
    return this.right - this.left;
  }
}

export default GeomRect;
