import Utility from './Utility';

class GeomSize {
  cx;
  cy;

  constructor(x, y) {
    if (Utility.isTypeofNumber(x)) {
      this.cx = x;
    }
    if (Utility.isTypeofNumber(y)) {
      this.cy = y;
    }
  }
}

export default GeomSize;
