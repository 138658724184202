import { getTextContainerBox } from '@src/utils';
import React from 'react';

const SelectMarker = ({ anchors, text }) => {
  if (anchors.length >= 1) {
    const [x, y] = anchors[0];
    const { width = 0, height = 0 } = text;

    const box = getTextContainerBox({
      x: x + width / 2,
      y,
      width,
      height,
    });

    return <g>{box}</g>;
  } else {
    return null;
  }
};

export default SelectMarker;
