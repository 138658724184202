import * as React from 'react';

function SvgIconClose(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M20.749 4.707l-1.415-1.414-7.292 7.293-7.293-7.293-1.415 1.414L10.627 12l-7.293 7.293 1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L13.456 12z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconClose;
