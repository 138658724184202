import Images from '@src/assets/Images';
import * as SvgImages from '@src/components/UI/Images';

export const APP_ROOT_EL_ID = 'road-engine-container';

export const LIMIT_VALUE = {
  MAX_TEXT_SIZE: 500, // inch
  MIN_TEXT_SIZE: 1, // inch
};

export const MEASUREMENT_TEXT_SIZE = 25;

export const MEASUREMENT_ARROW_SIZE = 24;

/**
 * the direction line length
 */
export const STATION_LONG = 150;

/**
 * the length of lines which are not the direction line
 */
export const STATION_SHORT = 30;

export const HANDLE_RADIUS = 8;

export const FONT_SIZE = 12;

export const SPOT_WIDTH = 3.5;

/**
 * the SVG unit inicates the range for a stripe to be selected/touched.
 */
export const STRIPE_TOUCH_RANGE = 60;

export const PARKING_STALL_TOUCH_RANGE = 60;

/**
 * horizontal padding for text container
 */
export const TEXT_BOX_PX = 10;

// WARNING: don't change the property's string value yet
// as they may used literally in conditions somewhere
/**
 * all shape types
 * @enum {string}
 */
export const FUNCTION_TYPE = {
  LINE: 'Line',
  RECTANGLE: 'Rectangle',
  ARC: 'Arc',
  CONNECTEDLINES: 'Connected lines',
  CLOSEDSHAPE: 'Polygon',
  ELLIPSE: 'Oval',
  SQUARE: 'Square',
  CIRCLE: 'Circle',
  DIMENSIONLINE: 'DimensionLine',
  TEXTBOX: 'TextBox',
  CALLOUT: 'Callout',
  COUNTER_TOP: 'CounterTop',
  STAIRS: 'Stairs',
  STRUCTURE: 'Structure',
  STRIPE: 'Stripe',
  PARKINGSTALLS: 'ParkingStalls',
  CROSSWALK: 'Crosswalk',
  LASSOAREA: 'lassoarea',
  ZOOMSELECT: 'zoomselect',
  // ZOOMFIT: 'ZOOMFIT',
  SELECTION: 'selection',
  IMAGE: 'image',
  GROUP: 'group',
  DIRT: 'dirt',
  GRAVEL: 'gravel',
  SIDEWALK: 'sidewalk',
  LANEMARKER: 'lanemarker',
  INDICATOR: 'indicator',
  STREETACROSS: 'street-across',
  STATIONLINE: 'StationLine',
  TRIANGULATIONNETWORK: 'TriangulationNetwork',
  XYMEASUREMENT: 'XYMeasurement',
  STREET: 'Street',
  STREETNEW: 'StreetNew',
  CURBRETURN: 'CurbReturn',
  SYMBOL: 'Symbol',
  STREETSURFACE: 'StreetSurface',
  MARKER: 'Marker',
};

export const HOME_BUTTON_STATE = {
  INCREASE_TEST_SIZE: 'increaseTextSize',
  DECREASE_TEST_SIZE: 'decreaseTextSize',
};

/**
 * @enum {string}
 */
export const SHAPES_FUNC = {
  PROPERTIES: 'properties',
  SELECTALL: 'selectall',
  BRINGFRONT: 'bringfront',
  BRINGBACK: 'bringback',
  GROUP: 'group',
  UNGROUP: 'ungroup',
  FLIPVERTICAL: 'flipvertical',
  FLIPHORIZONTAL: 'fliphorizontal',
  LEFT: 'left',
  Center: 'center',
  RIGHT: 'right',
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

/**
 * @enum {string} - 修改变量的值需要同时修改 i18n 对应的 key
 */
export const TEXT_BOX_TYPE = {
  BOX: 'Box',
  UNDERLINE: 'Underline',
  BARE: 'Bare',
};
export const MANAGE_FUNC_TYPE = {
  IMPORT: 'import',
  PRINT: 'print',
  EREA: 'erea',
  ADDIMG: 'addimg',
  LOCAL: 'local',
  START_OVER: 'startover',
};
export const TOP_MENU_FUNC = {
  UNDO: 'undo',
  REDO: 'redo',
  COPY: 'copy',
  CUT: 'cut',
  PASTE: 'paste',
  DELETE: 'delete',
  ZOOMFIT: 'zoomfit',
};

/**
 * @enum {string}
 */
export const CANVAS_MENU_TYPE = {
  COPY: 'copy',
  CUT: 'cut',
  PASTE: 'paste',
  BRINGFRONT: 'bringfront',
  BRINGBACK: 'bringback',
  GROUP: 'group',
  UNGROUP: 'ungroup',
  FLIPVERTICAL: 'flipvertical',
  FLIPHORIZONTAL: 'fliphorizontal',
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
  MODAL: 'modal',
};

export const STREET_MENU = {
  LINE: 'Line',
  ARC: 'Arc',
  DOUBLEARC: 'Double Arc',
  TRIPEARC: 'Triple Arc',
  OFFSETARC: 'Offset Arc',
  SPLITSEGMENT: 'Split Segment',
  DELETESEGMENT: 'Delete Segment',
  MERGESEGMENT: 'Merge Segment',
};
export const STRUCTURE_DOOR_TYPE = {
  door32: '32door',
  door72: '72door',
  doubledoor16: '16ft double door',
};
export const STRUCTURE_DOOR_WIDTH = {
  door32: 36, //36标准单位
  door72: 72 / 2, //72标准单位
  doubledoor16: (16 * 12) / 2, //16英尺
};
export const STREET_STRIPES_TYPE = {
  dash: 'dash',
  solid: 'solid',
  doublesolid: 'doublesolid',
  doubledash: 'doubledash',
  dashsolid: 'dashssolid',
  soliddash: 'soliddash',
  reflectors: 'reflectors',
  hide: 'hide',
  splitpattem: 'splitpattem',
};

// { cursor:default } 默认正常鼠标指针 (Default normal mouse pointer)
// { cursor:hand } and div{ cursor:text } 文本选择效果 (Text Selection Effect)
// { cursor:move } 移动选择效果 (Move Selection Effect)
// { cursor:pointer } 手指形状 链接选择效果 (Finger shape, Link selection effect)
// { cursor:crosshair }精准 + (Precision +)
/** @enum {string} */
export const CURSOR = {
  DEFAULT: 'default',
  HAND: 'hand',
  MOVE: 'move',
  POINTER: 'pointer',
  CROSSHAIR: 'crosshair',
  // FIXME: check if its working nwb build
  ANGLE: `url(${Images.IconRotate}) 12 12, auto`,
  DRAWING: `url(${Images.IconPencil}) 0 0, auto`,
};

/**
 * @enum {string}
 */
export const ANCHORS_TYPE = {
  LEFTTOP: 'lefttop',
  RIGHTTOP: 'righttop',
  LEFTBOTTOM: 'leftbottom',
  RIGHTBOTTOM: 'rightbottom',
  TOPMEDIUM: 'topmedium',
  BOTTOMMEDIUM: 'bottommedium',
  LEFTMEDIUM: 'leftmedium',
  RIGHTMEDIUM: 'rightmedium',
  ANGLE: 'angle',
  LINEBEGIN: 'linebegin',
  LINEEND: 'lineend',
  ARCBEGIN: 'arcbegin',
  ARCEND: 'arcend',
  ARCANGLE: 'arcangle',
  CONNECTBEGIN: 'connectbegin',
  CONNECTNORMAL: 'connectnormal', // 普通锚点用于连接和移动 (Ordinary anchors for connection and movement)
  CONNECTCANGLE: 'connectangle', // 拉动可以形成弧形 (Pulling can form an arc)
  CLOSEEND: 'closeend',
  CLOSEANGLE: 'closeangle',
  COUNTERBEIGIN: 'countertopbegin',
  COUNTERMIDDLE: 'countertopmiddle',
  COUNTERTHREE: 'countertopthree',
  COUNTERMOVELEFT: 'countertopmoveleft',
  COUNTERMOVERIGHT: 'countertopmoveright',
  COUNTERHELP: 'counterhelp',
  COUNTERNORMAL: 'counternormal',
  COUNTEREND: 'counterend',
  CALLOUTANGLE: 'calloutangle',
  STREETBEGIN: 'streetbegin',
  STREETEND: 'streetend',
  STREETCENTER: 'streetcenter',
  STREETLEFTTOP: 'streetlefttop',
  STREETRIGHTTOP: 'streetrighttop',
  OFFSETRIGHT: 'streetrightbottom',
  OFFSETLEFT: 'streetleftbottom',
  OFFSETUP: 'offsetup',
  OFFSETDOWN: 'offsetdown',
  OFFSETCENTER: 'offsetcenter',
  STREETARCCenterTOP: 'streetarccentertop',
  STREETARCCenterBOTTOM: 'streetarccenterbottom',
  STREETARCCenter: 'streetarccenter',
  STREETNORMAL: 'streetnormal',
  STREETTEXTRECT: 'streettextrect',
  STREETACROSSAREA: 'street-across-erarea',
  STREETACROSSPOINT: 'street-across-point',
  STREETACROSSCenter: 'street-across-center',
  STREETARCLEFT: 'street-arc-left',
  STREETARCRIGHT: 'street-arc-right',
  STREETSPLITPATTEM: 'splitpattemmove',
  STAIRSBEGIN: 'stairsbeigin',
  STAIRSEND: 'stairsend',
  STAIRSMIDDLE: 'stairsmiddle',
  STRUCTUREBEGIN: 'structurebegin',
  STRUCTUREEND: 'structureend',
  STRUCTURELINE: 'structureline',
  PARKING_STALL_ANGLE: 'parkingstallangle',
  CROSSWALKBEGIN: 'crosswalkbegin',
  CROSSWALKEND: 'crosswalkend',
  CROSSWALKLEFTTOP: 'crosswalklefttop',
  CROSSWALKRIGHTTOP: 'crosswalkrighttop',
  CROSSWALKLEFTBOTTOM: 'crosswalkleftbottom',
  CROSSWALKRIGHTBOTTOM: 'crosswalkrightbottom',
  CROSSWALKMIDDLETOP: 'crosswalkmiddletop',
  CROSSWALKMIDDLEBOTTOM: 'crosswalkmiddlebottom',
  OFFSETPOSITION: 'offsetposition',

  // SelectStreetNew, SelectCurved
  ADD_SECTION_BEGIN: 'add-section-begin',
  ADD_SECTION_END: 'add-section-end',
};
// 修改变量的值需要同时修改 i18n 对应的 key
export const PARK_STALL_PATTERN_TYPE = {
  STANDARD: 'Standard',
  PARALLELONE: 'Parallel One',
  PARALLELTWO: 'Parallel Two',
};

// TODO: consolidate with ../util/getDashArray
export const DASH_WIDTH = 2;

/**
 * Enum of useData stroke dash array
 * @enum {string}
 */
export const DASH_ARRAY_TYPE = {
  solid: '0',
  dashes: `10,${DASH_WIDTH * 2}`,
  dots: `5,${DASH_WIDTH * 2}`,
  dashdot: `10,${DASH_WIDTH * 2},3,${DASH_WIDTH * 2}`,
  dashdotdot: `20,${DASH_WIDTH * 2},3,${DASH_WIDTH * 2},3,${DASH_WIDTH * 2}`,
  Dash2: '2,5',
  Dash3: '3,3',
  Dash205: '20,5,3,5,3,5',
};

/**
 * @enum {JSX.Element}
 */
export const DashArrayStyles = {
  solid: SvgImages.ImageLineStyleSolid,
  dashes: SvgImages.ImageLineStyleDashes,
  dots: SvgImages.ImageLineStyleDots,
  dashdot: SvgImages.ImageLineStyleDashDot,
  dashdotdot: SvgImages.ImageLineStyleDashDotDot,
};

/**
 * Enum of useData stroke pattern
 * @enum {string}
 */
export const PATTERN_TYPE = {
  singleSolid: 'singlesolid',
  singleDash: 'singledash',
  doubleSolid: 'doublesolid',
  dashSolid: 'dashsolid',
  solidDash: 'soliddash',
  POTLINE: 'potline',
  doubleDash: 'doubledash',
};
export const LENGTH_TYPE = {
  MENUHEAD: 118.5, //头部菜单的高度 (Height of Header Menu)
  FONTSPACE: 10, //字体间距 (Font spacing)
  FONTSIZE: 12, //字体默认大小12 (Default font size 12)
  CONNECTSHAPESPACE: 60, //连接性图形线段生成锚点间距 (Anchor Spacing Generated by Connectivity Graphic Line Segment)
  INFORSPACE: 50,
  INFORANGLESPACE: 20, //用于info按钮位置向上偏移大小
  CALLOUTWIDTH: 185,
  CALLOUTHEIGHT: 27,
  ANGLELENGTH: 20,
};
export const CIRCLE_SIZE_TYPE = {
  BIGARC: '1',
  SMALLARC: '0',
};
export const THREE_POINT_STATUS_TYPE = {
  ONLINE: 0,
  BYTIME: 1,
  DEFYTIME: 2,
  UNKOWN: 3,
};

export const FONT_WEIGHT_TYPE = {
  NORMAL: 'normal',
  BOLD: 'bold',
  BOLDER: 'bolder',
  LIGHTER: 'lighter',
};
export const FONT_STYLE_TYPE = {
  NORMAL: 'normal',
  ITALIC: 'italic',
  OBLIQUE: 'oblique',
};
export const ARROW_SHOW_TYPE = {
  // START: "arrowStart",
  // END: "arrowEnd",
  NONE: 'none',
  BOTH: 'both',
  StartOnly: 'startOnly',
  StopOnly: 'stopOnly',
  ArrowSimple: 'arrowSimple',
  ArrowTriangleLine: 'arrowTriangleLine',
  ArrowFancy: 'arrowFancy',
  // FIXME: separate position enum and style enum
  ArrowNone: 'none',
};

export const ArrowPositions = {
  [ARROW_SHOW_TYPE.NONE]: SvgImages.ImageArrowPositionNone,
  [ARROW_SHOW_TYPE.BOTH]: SvgImages.ImageArrowPositionBoth,
  [ARROW_SHOW_TYPE.StartOnly]: SvgImages.ImageArrowPositionLeft,
  [ARROW_SHOW_TYPE.StopOnly]: SvgImages.ImageArrowPositionRight,
};

export const ArrowStyles = {
  [ARROW_SHOW_TYPE.ArrowSimple]: SvgImages.ImageArrowSimple,
  [ARROW_SHOW_TYPE.ArrowTriangleLine]: SvgImages.ImageArrowTriangleLine,
  [ARROW_SHOW_TYPE.ArrowFancy]: SvgImages.ImageArrowFancy,
};

export const MARKER = {
  ARROWSTART: 'url(#arrowStart)',
  ARROWEND: 'url(#arrowEnd)',
  BOTH: 'both',
};

/**
 * @enum {string}
 */
export const TEXT_POSITION_TYPE = {
  LEFTTOP: 'lefttop',
  MIDIUMTOP: 'midiumtop',
  RIGHTTOP: 'righttop',
  LEFTBOTTOM: 'leftbottom',
  MIDIUMBOTTOM: 'midiumbottom',
  RIGHTBOTTOM: 'rightbottom',
  STAIRDIRECTION: 'stairdirection',
};
export const TEXT_ANCHORS_TYPE = {
  START: 'start',
  MIDDLE: 'middle',
  END: 'end',
};
export const STAIR_SPACE = {
  HEIGHT: 18, //上下间距 (Distance between top and bottom)
  STAIRWIDTH: 10, //梯子之间间隔 (Ladder Interval)
  STAIRMARKWIDTH: 10 / 12,
  STAIRMARKBEIGIN: 1 / 4,
  STAIRMARKEND: 3 / 4,
  TreadDepth: 10 / 12,
  TreadWidth: 36,
};
export const CROSS_WALK_SPACE = {
  // Half of the width of a crosswalk
  HEIGHT: (8 / 3) * 12,
  HEIGHTMIN: 5,
  STAIRWIDTH: 10, //梯子之间间隔 (Ladder Interval)
  VERTICALSPACE: 30,
};
// 修改变量的值需要同时修改 i18n 对应的 key
export const CROSS_WALK_PATTERN = {
  HORIZONTAL: 'Horizontal', //上下间距
  VERTICAL: 'Vertical',
};
export const STREET_SPACE = {
  HEIGHT: (20 * 12) / 2, //上下间距
  BIKEHEIGHT: 4 * 12, //bikelane通道宽度
  RMAXHEIGHT: 120, //梯子之间间隔
  POINTLENTH: 13,
  SIDEANCHROSSPACE: 12,
  OFFSETARCSPACE: 40,
  OFFSETMOVESPACE: 30,
  OFFSETARCANGLE: Math.PI / 8,
  TWOWAY: 20,
  PAINTED: 10,
  WIDE: 40,
  ACROSS: 5 * 12,
};
export const STREET_POINT_TYPE = {
  NORMAL: 'streetareanormal', //上下间距
  Center: 'streetareacenter',
};
export const STREET_LINE_TYPE = {
  NONE: 0,
  TWOWAY: 2,
  WIDE: 3,
  PAINTED: 4,
  GRASS: 5,
  CEMENT: 6,
};
// 修改变量的值需要同时修改 i18n 对应的 key
export const STREET_DIVIDER_TYPE = {
  NONE: 'None',
  WIDE: 'Wide',
  PAINTED: 'Painted',
  TWO_WAY: '2-Way Left Turn Lane',
  GRASS: 'Grass',
  CEMENT: 'Cement',
};
export const LANE_TYPE = {
  VEHICLE_LANE: 0,
  BIKE_LANE: 1,
};
export const PARK_STALL_SPACE = {
  HEIGHT: 18 * 12, //高度 (Height)
  PARKWIDTH: 11 * 12, //梯子之间间隔 (Ladder Interval)
  PARKTOWWIDHT: 18 * 12,
  PARKTOWHEIGHT: 8.5 * 12,
  PARKONEWIDTH: 18 * 12,
  PARKONEHEIGHT: 8.5 * 12,
};
export const TRIANGLE_SPACE = {
  HEIGHT: 6, //高度
  PARKWIDTH: 20, //梯子之间间隔
};
export const POINT_CIRCLE_STATE = {
  ONCIRCLE: 0,
  INCIRCLE: 1,
  OUTCIRCLE: 2,
};
export const ADD_IMAGE_LENGTH = {
  SIDELENGTH: 50,
};
export const HOME_FUNC_TYPE = {
  INCREASE_TEST_SIZE: 'increaseTextSize',
  DECREASE_TEST_SIZE: 'decreaseTextSize',
};
export const START_FUNC = {
  TEMPLATESTART: 'templatestart',
  MAPSTART: 'mapstart',
};
export const COUNTER_TOP_PARAM_TYPE = {
  COUNTERMINMIDDLESPACE: 6,
  COUNTERTOPSPACE: 20,
  COUNTERMAXMIDDLESPCACE: 100,
  COUNTERMOVE: 10,
};

export const STRUCTURE_PARAM_TYPE = {
  GUIDSPACE: 5,
};
export const SIDE_WALK_PARAM_TYPE = {
  HEIGHT: 20,
  WALKSPACE: 25,
};
export const STREET_LINE_POSITION_TYPE = {
  TOP: 1,
  CETNRE: 0,
  BOTTOM: 2,
};

// canvas operations, or App operations that applied to selected objects or clipboard
// accessed from TopMenuBar, CanvasDropMenu, and keyboard etc.
// this will eventually be transformed as redux action types.
// this is now also used as canvas action types
export const OPERATIONS = {
  COPY: 'copy',
  PASTE: 'paste',
  CUT: 'cut',
  DELETE: 'delete',
  UNDO: 'undo',
  REDO: 'redo',
  // toolbar only
  BRING_FRONT: 'bring to front',
  SEND_BACK: 'send to back',
  ZOOM_IN: 'zoom in',
  ZOOM_OUT: 'zoom out',
  // keyboard only
  SELECT_ALL: 'select all',
  MOVE_LEFT: 'move left',
  MOVE_RIGHT: 'move right',
  MOVE_UP: 'move up',
  MOVE_DOWN: 'move down',
  // arrangement
  ALIGN_TOP: 'align vertical top',
  ALIGN_MIDDLE: 'align vertical middle',
  ALIGN_BOTTOM: 'align vertical bottom',
  ALIGN_LEFT: 'align horizontal left',
  ALIGN_CENTER: 'align horizontal center',
  ALIGN_RIGHT: 'align horizontal right',
  GROUP: 'group',
  UNGROUP: 'ungroup',
  FLIP_HORIZONTAL: 'flip horizontal',
  FLIP_VERTICAL: 'flip vertical',
};

/**
 * Enum of hotkey operations
 * @enum {{ operation: string, keyNames: string[] }}
 */
export const KEY_OPERATIONS = [
  {
    operation: OPERATIONS.UNDO,
    keyNames: ['ctrl+z', 'command+z'],
  },
  {
    operation: OPERATIONS.REDO,
    keyNames: ['ctrl+y', 'command+shift+z'],
  },
  {
    operation: OPERATIONS.COPY,
    keyNames: ['ctrl+c', 'command+c'],
  },
  {
    operation: OPERATIONS.PASTE,
    keyNames: ['ctrl+v', 'command+v'],
  },
  {
    operation: OPERATIONS.CUT,
    keyNames: ['ctrl+x', 'command+x'],
  },
  {
    operation: OPERATIONS.SELECT_ALL,
    keyNames: ['ctrl+a', 'command+a'],
  },
  {
    operation: OPERATIONS.DELETE,
    keyNames: ['delete', 'command+backspace'],
  },
  {
    operation: OPERATIONS.MOVE_LEFT,
    keyNames: ['left'],
  },
  {
    operation: OPERATIONS.MOVE_RIGHT,
    keyNames: ['right'],
  },
  {
    operation: OPERATIONS.MOVE_UP,
    keyNames: ['up'],
  },
  {
    operation: OPERATIONS.MOVE_DOWN,
    keyNames: ['down'],
  },
];

export const KEY_NAMES = KEY_OPERATIONS.map(n => n.keyNames).reduce((arr, keyNames) => arr.concat(keyNames), []);

export const SLIDER_MENU_TYPE = {
  STREETSSTRUCTURES: 'Streets & Structures',
  SYMBOLS: 'Symbols',
  MEANSUREMENTS: 'Meansurements',
};

export const SNAP_TO_STREET_STYLE = {
  /**
   * perpendicular to the street
   */
  PERPENDICULAR: 0,

  /**
   * the same direction with the street lane
   */
  ALONG_WITH: 1,
};

/**
 * Enum of useData stroke
 * @enum {string}
 */
export const COLOR_TYPE = {
  WHITE: '#FFFFFF',
  OFFWHITE: '#FFFBF0',
  VERYLIGHTGRAY: '#F0F0F0',
  LIGHTGRAY: '#C0C0C0',
  MEDGRAY: '#A0A0A4',
  DARKGRAY: '#808080',
  VERYDARKGRAY: '#333333',
  BLACK: '#000000',
  BLUE: '#0000FF',
  DARKBLUE: '#000080',
  NAVYBLUE: '#330066',
  LIGHTBLUE: '#330066',
  BLUEGRAY: '#6699FF',
  CYAN: '#00FFFF',
  DARKCYAN: '#008080',
  GREEN: '#00FF00',
  DARKGREEN: '#008000',
  FORESTGREEN: '#006633',
  PALEGREEN: '#C0DCC0',
  RED: '#FF0000',
  FLAME: '#FF3300',
  DARKRED: '#800000',
  PURPLE: '#800080',
  VIOLET: '#660099',
  MAGENTA: '#FF00FF',
  LIGHTPINK: '#FF99CC',
  DARKMAGENTA: '#990033',
  DARKBROWN: '#663300',
  LIGHTBROWN: '#996633',
  DARKTAN: '#CC9933',
  TAN: '#FFCC66',
  LIGHTTAN: '#FFEFD1',
  CREAM: '#FFFF99',
  YELLOW: '#FFFF00',
  GOLD: '#FFCC00',
  ORANGE: '#FF9F00',
  OLIVE: '#808000',
  NONE: 'none',
  TRANSPARENT: 'transparent',
  CANVAS_BG: 'var(--color-canvas-bg)',
  LNCD_MAIN_COLOR: '#22507A',
};

// 0 初始状态 1 按下状态，2 调整状态 3 选择伸展状态 //4 完成状态 5 平移状态 6 用于多次点击的图形要用右键才能停止 (0 Initial State 1 Press State, 2 Adjust State 3 Select Extension State //4 Complete State 5 Translational State 6 Graphics for Multiple Clicks need to be right-clicked to stop)
export const DRAW_STATE = {
  DEFAULT: 0,
  // TODO: rename if only used while start drawing?
  PRESSED: 1,
  ADJUST: 2,
  SELECT_EXTENSION: 3,
  COMPLETE: 4,
  TRANSLATION: 5,
  MULTI_POINT: 6,
  MOVING_CANVAS: 7,
  // TODO: naming
  BAR: 8,
};

export const CANVAS = {
  CONNECTSHAPESPCAE: LENGTH_TYPE.CONNECTSHAPESPACE,
  CANVASWIDTH: 190080, //画布的长和高要一致，要不然viewbox放大后坐标不可控
  CANVASHEIGHT: 190080, //画布的长和高要一致，要不然viewbox放大后坐标不可控
  CANVASSPACE: 500, //背景和画布之间间隔
  CANVASSCREENWIDHT: 1536 - 336.05,
  VIEWCANVASWIDTH: 1600, //document.body.clientWidth-SIDERWIDTH;
  VIEWCANVSAHEIGTHT: 1600, //document.body.clientHeight-HEADHEIGHT;
  CANVASSCALESPACE: 60, //用于控制放大比例
  INITIAL_SCALE: 0.4,
};

/**
 * street types
 * @enum {string}
 */
export const STREET_TYPE = {
  STRAIGHT: 'straight',
  ROUNDABOUT: 'roundabout',
  CURVED: 'curved',
};

export const MAP = {
  // this is the zoom level that aligns to diagram default scale
  INITIAL_ZOOM: 21.233299429341333,
};

/**
 * @enum {string}
 */
export const ORIENTATION_VIEW = {
  TOP: 'top',
  SIDE: 'side',
  BOTTOM: 'bottom',
};

/**
 * @enum {number}
 */
export const STREET_DEFAULT_SIZE = {
  STREET_LEN: 676.03,
  STREET_WIDTH: 240,

  // 普通、宽、窄车道
  LANE_WIDTH: 120,
  WIDE_LANE_WIDTH: 240,
  NARROW_LANE_WIDTH: 48,

  DIVIDER_WIDTH: 80,
};

/**
 * @enum {number}
 */
export const ROAD_LAYER_VALUES = {
  GROUND: 0,
  OVERPASS: 1,
  UNDER: -1,
};

/**
 * @enum {{value: number, label: ROAD_LAYER_VALUES}}
 */
export const ROAD_LAYER = [
  {
    value: ROAD_LAYER_VALUES.UNDER,
    label: 'under',
  },
  {
    value: ROAD_LAYER_VALUES.GROUND,
    label: 'ground',
  },
  {
    value: ROAD_LAYER_VALUES.OVERPASS,
    label: 'overpass',
  },
];

export const STRIPE_LIKE = ['stripe', 'shoulder', 'grass', 'cement'];
