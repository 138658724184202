import * as React from 'react';

function SvgImageArrowPositionBoth(props) {
  return (
    <svg width={32} height={10} {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M9 3h9v3H9z" />
        <path d="M0 4.295L15 0v9.288zM32 4.295L17 0v9.288z" />
      </g>
    </svg>
  );
}

export default SvgImageArrowPositionBoth;
