import React from 'react';
import { Icon, Button, Input, InputNumber, Select, Form, Table } from 'antd';
import Utility from '@src/data/Utility';
import { StationLineData } from '@src/data/ShapeDataList';
import ModalContainer from './components/ModalContainer';
import * as workData from '@src/data/WorkData';
import { withTranslation } from 'react-i18next';
import PointModal from './components/PointModal';
import EditableRowOperation from './components/EditableRowOperation';
import EditableTableContainer from './components/EditableTableContainer';
import { EditableContext } from './context/EditableContext';

class EditableCell extends React.Component {
  handleDistanceValidator = (rule, val, callback) => {
    if (!val) {
      callback();
    }

    let validateResult = true; // 自定义规则
    let message = '';

    const value = typeof val !== 'number' ? val.replace("'", '').replace('"', '') : val;

    if (value < -1320) {
      validateResult = false;
      message = this.props.t('property.measurementTab.distanceSmallerErr');
    } else if (value > 1320) {
      validateResult = false;
      message = this.props.t('property.measurementTab.distanceLargerErr');
    }

    if (!validateResult) {
      callback(message);
    }

    callback();
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      onFocus,
      direction,
      onSelectChange,
      t,
      ...restProps
    } = this.props;

    // reset the numeric value to be edited.
    if (editing === true && record) {
      record.imperialStation = record.station;
      record.imperialDistance = record.distance;
    }

    const focusInput = event => {
      onFocus(event.currentTarget.id);
    };

    const getInputForm = () => {
      if (dataIndex === 'station' || dataIndex === 'distance') {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
                {
                  validator: this.handleDistanceValidator,
                },
              ],
              initialValue: record[dataIndex],
            })(
              <InputNumber
                // formatter={value => Utility.format(value)}
                // parser={value => value.replace('"', '')}
                onFocus={focusInput}
                size="small"
              />
            )}
          </Form.Item>
        );
      } else if (dataIndex === 'direction') {
        const dropDownList = StationLineData[direction].dropDownList;
        const initialValue = StationLineData[direction].defaultDropDownValue;
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Select ${title}!`,
                },
              ],
              initialValue,
            })(
              <Select
                onChange={(value, component) => onSelectChange(value, component, record.id)}
                suffixIcon={<Icon type="caret-down" style={{ color: 'black' }} />}
                size="small"
                style={{ width: '100%' }}
              >
                {dropDownList.map((item, index) => {
                  return (
                    <Select.Option key={index} value={item.name}>
                      {t(`property.measurementTab.${item.name}Direction`)}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        );
      } else {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: false,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(<Input onFocus={focusInput} size="small" />)}
          </Form.Item>
        );
      }
    };

    return <td {...restProps}>{editing ? getInputForm() : children}</td>;
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);

    const { pointList } = this.props;
    if (pointList && pointList.length > 0) {
      pointList.forEach(item => {
        item.imperialStation = this.getImperial(item.station);
        item.imperialDistance = this.getImperial(item.distance);
      });
    }

    this.state = {
      data: pointList,
      editingKey: '',
      inputTitle: '',
    };

    this.columns = [
      {
        title: this.props.t('property.measurementTab.id'),
        dataIndex: 'id',
        width: '6%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.description'),
        dataIndex: 'description',
        width: '28%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.station'),
        dataIndex: 'station',
        width: '15%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.distance'),
        dataIndex: 'distance',
        width: '15%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.direction'),
        dataIndex: 'direction',
        width: '15%',
        editable: true,
      },
      {
        title: this.props.t('property.measurementTab.operation'),
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <EditableRowOperation
              editable={editable}
              keyValue={record.key}
              editingKey={editingKey}
              save={this.save}
              cancel={this.cancel}
              edit={this.edit}
              handleDelete={this.handleDelete}
              t={this.props.t}
            />
          );
        },
      },
    ];
  }

  /**
   *
   * @param {object} record
   * @param {string} record.key
   * @returns {boolean}
   */
  isEditing = record => record.key === this.state.editingKey;

  getImperial = value => {
    let imperialValue = '';
    let tempVal = 0;

    if (value) {
      tempVal = Number(value);

      if (!isNaN(tempVal)) {
        imperialValue = Utility.format(tempVal);
      }
    } else if (value === 0) {
      imperialValue = '0';
    }

    return imperialValue;
  };

  cancel = key => {
    // const newData = [...this.state.data];
    // const index = newData.findIndex(item => key === item.key);
    // const item = newData[index];

    this.setState({ editingKey: '', inputTitle: '' });
  };

  save = (form, key) => {
    const { handleChangePoints } = this.props;
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);

      if (index > -1) {
        const item = newData[index];

        // set imperial value to be display, and numeric value to save
        if (typeof row.station === 'number') {
          let station = this.getImperial(row.station);
          item.station = row.station;
          item.station = station;
        }

        // set imperial value to be display, and numeric value to save
        if (typeof row.distance === 'number') {
          let distance = this.getImperial(row.distance);
          item.distance = row.distance;
          item.distance = distance;
        }

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }

      // this.setState({ data: newData, editingKey: '' });
      handleChangePoints(newData);
    });
  };

  edit = key => {
    this.setState({ editingKey: key });
  };

  handleDelete = key => {
    const { handleChangePoints } = this.props;

    const data = [...this.state.data];
    const currentData = data.filter(item => item.key !== key);

    this.setState({ data: currentData });

    handleChangePoints(currentData);
  };

  handleAdd = () => {
    const { data } = this.state;
    const { direction, handleChangePoints } = this.props;

    let maxKey = 0;

    data.map(item => {
      let key = Number(item.key);
      if (key > maxKey) {
        maxKey = key;
      }
    });

    let count = maxKey + 1;
    const defaultDropDownValue = StationLineData[direction].defaultDropDownValue;
    const newData = {
      key: count,
      id: count,
      description: '',
      station: 0,
      distance: 0,
      direction: defaultDropDownValue,
    };

    data.push(newData);

    this.setState({
      data: data,
      editingKey: count,
    });

    // handleChangePoints(data);
  };

  onSelectChange = (val, component, id) => {
    const { data } = this.state;
    const { value, children } = component.props;
    for (const item of data) {
      if (item.id === Number(id)) {
        item.direction = value;
        break;
      }
    }
    this.setState({ data });
    // this.props.handleChangePoints(data);
  };

  handleFocusInput = columnIndex => {
    let inputTitle = '';

    switch (columnIndex) {
      case 'id':
        inputTitle = this.props.t('property.measurementTab.idInputTitle');
        break;
      case 'description':
        inputTitle = this.props.t('property.measurementTab.descriptionInputTitle');
        break;
      case 'station':
        inputTitle = this.props.t('property.measurementTab.stationInputTitle');
        break;
      case 'distance':
        inputTitle = this.props.t('property.measurementTab.distanceInputTitle');
        break;
      case 'direction':
        inputTitle = this.props.t('property.measurementTab.directionInputTitle');
        break;
    }

    this.setState({
      inputTitle: inputTitle,
    });
  };

  render() {
    const { data, inputTitle } = this.state;
    const components = {
      table: EditableTableContainer,
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      const handleOnCell = (record, rowIndex) => {
        return {
          onFocus: this.handleFocusInput,
          record,
          inputType: col.dataIndex === 'station' || col.dataIndex === 'distance' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          onSelectChange: this.onSelectChange,
          direction: this.props.direction,
          t: this.props.t,
        };
      };

      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: handleOnCell,
      };
    });

    const header = (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <Button className="lncd-button" size="small" onClick={this.handleAdd}>
          {this.props.t('property.measurementTab.addPointButtonText')}
        </Button>
      </div>
    );

    const footer = () => {
      return inputTitle;
    };

    return (
      <EditableContext.Provider value={this.props.form}>
        {header}
        <Table
          id="lncd-measurement-table"
          components={components}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={false}
          footer={footer}
          size="small"
        />
      </EditableContext.Provider>
    );
  }
}

class StationLineModal extends React.Component {
  state = {
    object: this.props.object,
  };

  componentDidUpdate({ object }) {
    if (this.state.object.operateid !== object.operateid) {
      this.setState({ object });
    }
  }

  handleChangePoints = data => {
    const { object } = this.state;

    const pointList = data.filter(
      item => item.id && typeof item.distance === 'number' && typeof item.station === 'number'
    );

    object.pointList = pointList;
    workData.setObj(object.operateid, object);
    this.setState({ object: object });
    this.props.handle();
  };

  render() {
    const { object } = this.state;
    const { type = '', pointList = [] } = object;

    const EditableFormTable = Form.create({
      // onValuesChange: this.handleValuesChange,
      // mapPropsToFields(props) {
      //   // console.log('mapPropsToFields', props);
      //   return {
      //     id: Form.createFormField({
      //       ...props.id,
      //       value: props.id.value,
      //     }),
      //     description: Form.createFormField({
      //       ...props.description,
      //       value: props.description.value,
      //     }),
      //   };
      // },
    })(EditableTable);

    return (
      <ModalContainer
        object={object}
        handle={this.props.handle}
        renderPointModal={() => (
          <PointModal>
            <EditableFormTable
              pointList={pointList}
              handleChangePoints={this.handleChangePoints}
              direction={type}
              t={this.props.t}
            />
          </PointModal>
        )}
      />
    );
  }
}

export default withTranslation()(StationLineModal);
