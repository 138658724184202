import * as React from 'react';

function SvgIconMarker(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.001.002h24v24h-24z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M22 18V6c0-1.103-.898-2-2-2H9c-1.103 0-2 .897-2 2v2.434L1.057 12 7 15.566V18c0 1.103.897 2 2 2h11c1.102 0 2-.897 2-2zM9 18v-3.566L4.943 12 9 9.566V6h11v12H9z" />
          <path d="M13.293 16l.388-2.14h.917L14.2 16h1.463l.404-2.14h1.584v-1.39h-1.327l.164-.919H18V10.13h-1.25L17.148 8h-1.463l-.393 2.129h-.907L14.79 8h-1.463l-.393 2.129h-1.567v1.422h1.3l-.17.92H11v1.39h1.229L11.835 16h1.458zm1.562-3.53h-.901l.164-.919h.906l-.17.92z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconMarker;
