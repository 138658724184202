import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { APP_ROOT_EL_ID } from '@src/constant';

export function SymbolAvatar(props) {
  const { symbolKey, clientX, clientY, rotationAngle, symbolImage } = props;
  let el = document.querySelector('.symbol-avatar');
  if (!el) {
    el = document.createElement('div');
    el.className = 'symbol-avatar';
  }

  el.style.left = `${clientX}px`;
  el.style.top = `${clientY}px`;

  useEffect(() => {
    const root = document.getElementById(APP_ROOT_EL_ID);
    root.appendChild(el);
    return () => {
      root.removeChild(el);
    };
  }, [symbolKey]);

  const children = (
    <SymbolAvatarChild key={symbolKey} symbolKey={symbolKey} rotationAngle={rotationAngle} symbolImage={symbolImage} />
  );

  return ReactDOM.createPortal(children, el);
}

export function SymbolAvatarChild(props) {
  const { symbolKey, rotationAngle, symbolImage } = props;
  return (
    <div
      className="symbol-thumb-svg"
      style={{
        transform: `rotate(${rotationAngle}deg)`,
      }}
    >
      <object
        className="symbol-thumb-object"
        type="image/svg+xml"
        // TODO: @ alias causes build issue?
        data={`data:image/svg+xml;utf8,${encodeURIComponent(symbolImage)}`}
        width={100}
      />
    </div>
  );
}
