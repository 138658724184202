import { COLOR_TYPE, DASH_ARRAY_TYPE } from '@src/constant';

export function GetShapeStyle() {
  let GuidelineStyle = {
    stroke: COLOR_TYPE.FLAME,
    strokeWidth: 1,
    fill: COLOR_TYPE.NONE,
    fillOpacity: 1,
    strokeDasharray: DASH_ARRAY_TYPE.dashdot,
  };
  let AnchorsStyle = {
    stroke: COLOR_TYPE.BLACK,
    fill: COLOR_TYPE.GREEN,
    fillOpacity: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  };
  let ArrowLengthStyle = {
    stroke: COLOR_TYPE.LNCD_MAIN_COLOR,
    strokeWidth: 0.5,
    fill: COLOR_TYPE.LNCD_MAIN_COLOR,
    fillOpacity: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  };
  let AnchorsAngleStyle = {
    stroke: COLOR_TYPE.BLACK,
    fill: COLOR_TYPE.WHITE,
    fillOpacity: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  };
  let SelectStreetStyle = {
    stroke: COLOR_TYPE.NONE,
    strokeWidth: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
    fill: COLOR_TYPE.LIGHTGRAY,
  };
  let LineWhiteStyle = {
    strokeWidth: 60,
    strokeOpacity: 0,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
    fill: COLOR_TYPE.NONE,
  }; //用于加强点击精度
  let NormalStyle = {
    stroke: COLOR_TYPE.BLACK,
    strokeWidth: 1,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
    fill: COLOR_TYPE.NONE,
  };
  let StreetWhiteStyle = {
    stroke: COLOR_TYPE.WHITE,
    strokeWidth: 3,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
    fill: COLOR_TYPE.NONE,
  }; //用于加强点击精度
  let StreetLaneSelectStyle = {
    stroke: COLOR_TYPE.WHITE,
    strokeWidth: 20,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
    fill: COLOR_TYPE.NONE,
  };
  return {
    ANCHORSSTYPE: AnchorsStyle,
    ANCHORSANGLESTYLE: AnchorsAngleStyle,
    SELECTSTREETSTYLE: SelectStreetStyle,
    LINEWHITESTYLE: LineWhiteStyle, //用于线的隐藏 加强点击精度
    NORMALSTYLE: NormalStyle,
    STREETWHITESTYELE: StreetWhiteStyle,
    STREETLANESELECT: StreetLaneSelectStyle,
    ARROWLENGHTSTYLE: ArrowLengthStyle,
    GUIDELINE: GuidelineStyle,
  };
}
