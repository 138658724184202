import React, { useState, useEffect, useContext } from 'react';
import { Layout, Tabs, Icon } from 'antd';
import { IconMenu } from '@src/components/UI/Icons';
import SymbolCategoryMenu from '@src/components/LncdSider/SymbolCategoryMenu/SymbolCategoryMenu';
import { connect } from 'react-redux';
import { SymbolContext } from '@src/contexts';
import LayerMenu from './LayerMenu/LayerMenu';
import { withTranslation } from 'react-i18next';
import Pin from './Pin/Pin';
import { FavoriteContext } from '@src/contexts/FavoriteContext';

const { Sider } = Layout;
const { TabPane } = Tabs;

function LncdSider({ collapsed, canvasHeight, siderWidth, toggleCollapsed, t }) {
  const [activedTab, setActivedTab] = useState('1');
  const { favorites } = useContext(FavoriteContext);
  const burgerMenu = (
    <div className="lncd-burger-menu" onClick={() => toggleCollapsed()}>
      <Icon component={IconMenu} />
    </div>
  );

  useEffect(() => {
    if (collapsed) {
      setActivedTab('1');
    }
  }, [collapsed]);

  return (
    <Sider
      className="lncd-sider"
      style={{ position: 'absolute', zIndex: 9, maxHeight: canvasHeight || 0 }}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={siderWidth}
      width={siderWidth}
    >
      <Tabs
        type="card"
        activeKey={activedTab}
        tabBarStyle={{ display: !collapsed ? 'block' : 'none' }}
        onTabClick={key => setActivedTab(key)}
      >
        <TabPane tab={!collapsed ? t('menu.sider.symbols') : ''} key="1">
          <SymbolContext.Consumer>
            {({ symbols }) => (
              <SymbolCategoryMenu
                symbols={symbols}
                toggleCollapsed={toggleCollapsed}
                collapsed={collapsed}
                burgerMenu={burgerMenu}
                favorites={favorites}
              />
            )}
          </SymbolContext.Consumer>
        </TabPane>
        <TabPane tab={!collapsed ? t('menu.sider.layers') : ''} key="2">
          <LayerMenu />
        </TabPane>
      </Tabs>
      {!collapsed ? <Pin /> : null}
    </Sider>
  );
}

const mapStateToProps = state => ({
  canvasHeight: state.app.canvasHeight,
  siderWidth: state.app.siderWidth,
  collapsed: state.app.collapsed,
});

export default connect(
  mapStateToProps,
  null
)(withTranslation()(LncdSider));
