import React from 'react';
import _ from 'lodash';
import {
  FindCircle,
  FindCircleLinePoint,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetCenter,
  GetCirclePointK,
  IsArcDirection,
  IsBigArc,
  LengthArc,
  LengthBetweenPoints,
} from '@src/data/CommonFunc';
import ArrowMarker from '../ArrowMarker';
import { getArrowType, GetConnectText, GetLengthConnectLine, GetPathDPoints } from '@src/data/BusinessFun';
import Text from '../Text';
import { GetShapeStyle } from '@src/data/CommonProps';
import Utility from '@src/data/Utility';
import { COLOR_TYPE, ANCHORS_TYPE } from '@src/constant';

class ConnectedLines extends React.Component {
  /**
   *
   * @param {*} object
   * @param {string} [type]
   */
  getPathShape = (object, type) => {
    if (object.handlepoint.length < 3) return null;
    var D = [];
    for (let i = 0; i < object.handlepoint.length; i++) {
      if (object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTBEGIN) {
        D.push('M' + String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      } else if (
        object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTNORMAL ||
        object.handlepoint[i][2] === ANCHORS_TYPE.CLOSEEND
      ) {
        var circleLeft = FindCircle(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1]);
        // if (circleLeft.every(i => isNaN(i))) {
        //   console.log(_.cloneDeep(object));
        // }
        D.push('A' + String(circleLeft[2]) + ',' + String(circleLeft[2]));
        D.push('0');
        D.push(
          IsBigArc(object.handlepoint[i - 2], object.handlepoint[i], object.handlepoint[i - 1], circleLeft[2]) +
            ',' +
            IsArcDirection(object.handlepoint[i - 2], object.handlepoint[i - 1], object.handlepoint[i])
        );
        D.push(String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      }
    }
    if (type === 'hide') {
      return <path d={D.join(' ')} {...GetShapeStyle().LINEWHITESTYLE} />;
    }
    return (
      <path
        d={D.join(' ')}
        markerStart={getArrowType(object.operateid, object.arrow.position, 0)}
        markerEnd={getArrowType(object.operateid, object.arrow.position, 1)}
        fill={COLOR_TYPE.NONE}
      />
    );
  };

  getMarks = object => {
    if (!object.marks.isexist || object.handlepoint.length < 6) return null;
    //半壶半弧画
    // point1 ，point2 ，arcCenter,markLength,
    var D = [];
    for (let i = 0; i < object.handlepoint.length; i++) {
      if (object.handlepoint[i][2] === ANCHORS_TYPE.CONNECTCANGLE && i != object.handlepoint.length - 1) {
        var temD = GetPathDPoints(
          object.handlepoint[i - 1],
          object.handlepoint[i + 1],
          object.handlepoint[i],
          object.marks.length,
          object.marks.height
        );
        D = D.concat(temD);
      }
    }
    return <path d={D.join(' ')} strokeWidth={1} />;
  };
  getText = object => {
    if (object.handlepoint.length < 6 || object.handlepoint[object.handlepoint.length - 2][2] != ANCHORS_TYPE.CLOSEEND)
      return null;
    var text = '';
    if (object.text.islength) {
      text = Utility.format(GetLengthConnectLine(object.handlepoint) / 12);
    } else {
      text = object.text.text;
    }
    var connectText = GetConnectText(
      object.handlepoint,
      object.text.position,
      object.marks.height,
      object.marks.isexist,
      object.style.strokewidth,
      object.text.size
    );
    return (
      <Text
        angle={connectText.angletext}
        x={connectText.textpoint[0]}
        y={connectText.textpoint[1]}
        textAnchor={connectText.textanchor}
        text={object.text}
        content={text}
      />
    );
  };
  render() {
    const { object } = this.props;

    return (
      <g id={object.functype}>
        <metadata
          sstrokepattern={object.style.strokepattern}
          sterminatortype={object.arrow.position}
          sterminatorstyle={object.arrow.type}
        />
        <ArrowMarker
          id={object.operateid}
          width={object.arrow.width}
          height={object.arrow.height}
          type={object.arrow.type}
          fill={object.style.stroke}
        />
        {this.getPathShape(object, 'hide')}
        {this.getPathShape(object)}
        {this.getMarks(object)}
        {this.getText(object)}
      </g>
    );
  }
}

export default ConnectedLines;
