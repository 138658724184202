import React, { Component } from 'react';
import { Icon, Menu, Dropdown, Button } from 'antd';
import Images from '@src/assets/Images';

const templateSection = require('@src/assets/Templates');
const { Item } = Menu;

class GroupSelect extends React.Component {
  constructor(props) {
    super(props);

    const templateGroups = templateSection.TemplateGroups();

    const menus = [];

    templateGroups.forEach(item => {
      menus.push({
        key: item.id,
        name: item.name,
      });
    });

    this.menus = menus;

    this.state = {
      selectKey: '0',
    };
  }

  getMenu = () => {
    return this.menus.map(item => <Item key={item.key}>{item.name}</Item>);
  };

  selectMenuKey = menuItem => {
    const { key } = menuItem;
    const { handleChangeGroup } = this.props;

    // const selectedMenu = this.menus[key] || {};
    // const menuName = selectedMenu.name || "";

    handleChangeGroup(key);

    this.setState({
      selectKey: key,
    });
  };

  render() {
    const { disabled = false } = this.props;
    const { selectKey } = this.state;
    const selectKeyString = selectKey.toString();

    const selectedMenu = this.menus[selectKey] || {};
    const menuName = selectedMenu.name || '';

    const groupMenus = (
      <Menu selectedKeys={[selectKeyString]} onClick={this.selectMenuKey}>
        {this.getMenu()}
      </Menu>
    );

    return (
      <Dropdown className="custom-dropdown" overlay={groupMenus} trigger={['click']} disabled={disabled}>
        <div className="custom-dropdown-item">
          <span>{menuName}</span>
          <img src={Images.IconDownBlack} alt="down" />
        </div>
      </Dropdown>
    );
  }
}

export default GroupSelect;
