import produce from 'immer';
import { CURSOR } from '@src/constant';

const initialState = {
  /**
   * @type {-1|0|1|2|3|4|5|6|7}
   * @description
   * -1无状态
   * 0 初始状态 Initial State
   * 1 按下状态 Press State
   * 2 调整状态 Adjust State; 选中Shape时mousemove;
   * 3 选择伸展状态 Select Extension State
   * 4 完成状态 Complete State; 未选中Shape时mousemove;
   * 5 平移Shape状态 Translational State; 选中Shape时mousedown;
   * 6 用于多次点击的图形要用右键才能停止 Graphics for Multiple Clicks need to be right-clicked to stop
   * 7 未选择Shape,拖动画布; 重置到状态4;
   */
  drawingState: -1,
  anchorsType: '', // handle point type
  cursor: CURSOR.DEFAULT,
};

export default produce((draft, action) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case 'SET_CURRENT_STATE':
      draft.drawingState = action.state;
      break;
    case 'SET_ANCHORS_TYPE':
      draft.anchorsType = action.anchor;
      break;
    case 'SET_CURSOR':
      draft.cursor = action.cursor;
      break;
    default:
      break;
  }
});
