import React from 'react';
import { Dropdown, Button, Icon, Menu } from 'antd';
import { IconDown } from '@src/components/UI/Icons';
import _ from 'lodash';
import styled from 'styled-components';

const { Item } = Menu;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  width: ${props => props.width || '65px'};
`;

const StyledButtonContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-prop-menu-dropdown);
`;

export default function LncdDropdown(props) {
  const { items, size = 'small', selectedKey, defaultKey, onClick } = props;

  const getItemContent = key => {
    const item = _.find(items, n => n.key === key);
    if (item) {
      return item.image || item.text;
    }
    return null;
  };

  const overlay = (
    // FIXME: use antd override
    <Menu className="reg-no-select" selectedKeys={[selectedKey || defaultKey]} onClick={({ key }) => onClick(key)}>
      {items.map(({ key }) => (
        <Item key={key}>{getItemContent(key)}</Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={overlay} size={size} trigger={['click']}>
      <StyledButton>
        <StyledButtonContent>{getItemContent(selectedKey || defaultKey)}</StyledButtonContent>
        <Icon component={IconDown} className="dropdown-indicator" />
      </StyledButton>
    </Dropdown>
  );
}
