import * as actionTypes from '@src/type/symbolBar';

export const toggleExpand = () => ({
  type: actionTypes.TOGGLE_EXPAND,
});

export const openExpand = () => ({
  type: actionTypes.OPEN_EXPAND,
});

export const selectSymbol = key => ({
  type: actionTypes.SELECT_SYMBOL,
  key,
});

export const barSizeChanged = (expandable, overlay) => ({
  type: actionTypes.BAR_SIZE_CHANGED,
  expandable,
  overlay,
});

export default {
  toggleExpand,
  selectSymbol,
  barSizeChanged,
};
