import React from 'react';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomPoint from '@src/data/GeomPoint';
import {
  getSquareHandlePoint,
  getCircleHandlePoint,
  CircleHandlePoint,
  ImageHandlePoint,
} from '@src/data/AnchorsPoint';
import GeomArc from '@src/data/GeomArc';
import { StreetService } from '@src/services/StreetService';
import Images from '@src/assets/Images';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import { getTextContainerBox } from '@src/utils';
import { ANCHORS_TYPE } from '@src/constant';

export default function SelectCurved({ object }) {
  const getTextRect = () => {
    const {
      text: { size, text, width, height },
      rotateangle,
    } = object;
    if (!text) return;

    // text point coord, i.e. bottom-middle of the rect/text
    const [x, y] = new StreetService(object).getTextPosition();
    return (
      <g id={'StreetName' + '-' + object.operateid + '-ht'} key={'StreetName' + '-' + object.operateid} fill="lime">
        {getTextContainerBox({
          x,
          y,
          width,
          height,
          rotateAngle: (rotateangle * 180) / Math.PI,
          id: ANCHORS_TYPE.STREETTEXTRECT,
          fill: 'transparent',
          cursor: 'move',
        })}
      </g>
    );
  };

  const getPathData = () => {
    const gpa = new GeomPolyArc(object.segments);
    const pathData = gpa.getSvgPathData();
    return { pathData };
  };

  const getPoint = () => {
    const result = [];
    const len = object.segments.length;
    for (let i = 0; i < len; i++) {
      const segment = object.segments[i];
      const ptStart = new GeomPoint(segment.ptStart.x, segment.ptStart.y);
      const ptStop = new GeomPoint(segment.ptStop.x, segment.ptStop.y);
      let ptCenter;
      if (segment.type === 'arc') {
        const { r, largeArcFlag, sweepFlag } = segment;
        const ptArc = new GeomArc(ptStart, ptStop, r, largeArcFlag, sweepFlag);
        ptCenter = ptArc.getMidPoint();
      } else if (segment.type === 'line') {
        ptCenter = ptStart.midPoint(ptStop);
      }
      result.push({ ptStart, ptStop, ptCenter });
    }
    const gpa = new GeomPolyArc(object.segments);
    const size = 5 * 4 * getUserUnitByPixel();
    const IMAGE_SIZE = 5 * 4 * getUserUnitByPixel();
    const ptImageStart = gpa.getAlignmentAtLength(-size - IMAGE_SIZE, 0).ptAlign;
    const ptImageEnd = gpa.getAlignmentAtLength(gpa.getLength() + size + IMAGE_SIZE, 0).ptAlign;
    return {
      points: result,
      ptImageStart,
      ptImageEnd,
    };
  };

  const getShapes = points => {
    const shapes = [];
    points.forEach((p, index) => {
      if (index === 0) {
        shapes.push(getSquareHandlePoint(`StreetNewBegin`, [p.ptStart.x, p.ptStart.y]));
        shapes.push(getCircleHandlePoint(`StreetNewCenter-${index}`, [p.ptCenter.x, p.ptCenter.y]));
      } else if (index === points.length - 1) {
        shapes.push(getCircleHandlePoint(`StreetNewCenter-${index}`, [p.ptCenter.x, p.ptCenter.y]));
        shapes.push(getSquareHandlePoint(`StreetNewConnect-${index}`, [p.ptStart.x, p.ptStart.y]));
        shapes.push(getSquareHandlePoint(`StreetNewEnd`, [p.ptStop.x, p.ptStop.y]));
      } else {
        shapes.push(getCircleHandlePoint(`StreetNewCenter-${index}`, [p.ptCenter.x, p.ptCenter.y]));
        shapes.push(getSquareHandlePoint(`StreetNewConnect-${index}`, [p.ptStart.x, p.ptStart.y]));
      }
    });
    return { shapes };
  };

  const { pathData } = getPathData();
  const { points, ptImageStart, ptImageEnd } = getPoint();
  const { shapes } = getShapes(points);
  return (
    <g lncd-role="street-selector">
      <path d={pathData} key="stripe-main" />
      <ImageHandlePoint
        key={ANCHORS_TYPE.ADD_SECTION_BEGIN}
        id={ANCHORS_TYPE.ADD_SECTION_BEGIN}
        src={Images.IconPlus}
        point={ptImageStart}
      />
      <ImageHandlePoint
        key={ANCHORS_TYPE.ADD_SECTION_END}
        id={ANCHORS_TYPE.ADD_SECTION_END}
        src={Images.IconPlus}
        point={ptImageEnd}
      />
      {shapes}
      {getTextRect()}
    </g>
  );
}
