import { GetCenterInTwoPoints } from '@src/data/CommonFunc';
import { getPolygonPathData } from '@src/utils';
import React from 'react';
import LncdText from '../LncdText';

/**
 * 0 4 1
 * 6 7 8
 * 3 5 2
 * @class Rect
 * @extends {React.Component}
 */
class Rect extends React.Component {
  render() {
    const { object } = this.props;
    if (object.handlepoint.length < 4) return null;
    var anchors = object.handlepoint;
    const d = getPolygonPathData(anchors.slice(0, 4));
    return (
      <g lncd-role={object.functype}>
        <metadata sstrokepattern={object.style.strokepattern} />
        <path d={d}></path>
        <LncdText
          {...object.text}
          rad={object.rotateangle}
          objectId={object.operateid}
          point={GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[2])}
        />
      </g>
    );
  }
}

export default Rect;
