import * as React from 'react';

function SvgIconFontLarger(props) {
  return (
    <svg className="icon-font-larger_svg__icon" viewBox="0 0 1024 1024" width="1em" height="1em" {...props}>
      <path
        fill="currentColor"
        d="M499.712 286.72l184.32 466.944h-61.44L573.44 622.592H360.448l-49.152 131.072h-61.44l184.32-466.944h65.536zm57.344 290.816l-90.112-233.472-90.112 233.472h180.224zM692.224 344.064v-81.92h40.96v81.92h81.92v40.96h-81.92v81.92h-40.96v-81.92h-81.92v-40.96h81.92z"
      />
    </svg>
  );
}

export default SvgIconFontLarger;
