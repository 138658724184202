import GeomLine from './GeomLine';
import GeomPolyArc from '@src/data/GeomPolyArc';
import StreetBorder from './StreetBorder';

class StreetEndCaps {
  /** @type {string} operateId */
  streetId;

  /** @type {GeomLine}*/
  startCap;

  /** @type {GeomLine}*/
  stopCap;

  /**
   *
   * @param {StreetBorder} streetBorder0
   * @param {StreetBorder} streetBorder1
   */
  constructor(streetBorder0, streetBorder1) {
    this.streetId = streetBorder0.streetId;
    this.startCap = new GeomLine(streetBorder0.gpaStripe.getPointStart(), streetBorder1.gpaStripe.getPointStart());
    this.stopCap = new GeomLine(streetBorder0.gpaStripe.getPointStop(), streetBorder1.gpaStripe.getPointStop());
  }
}

export default StreetEndCaps;
