import React from 'react';

class StreetSurface extends React.Component {
  render() {
    const { object } = this.props;
    // const getStreetFills = () => {
    //   let streetFillList = [];

    //   for (let i = 0; i < object.sfills.length; i++) {
    //     streetFillList.push(
    //       <path
    //         key={object.sfills[i].key}
    //         ttn-role="streetFill"
    //         fill="transparent"
    //         ttn-link={object.sfills[i].key}
    //         d={object.sfills[i].pathData}
    //       ></path>
    //     );
    //   }
    //   return streetFillList;
    // };
    const getCurbReturnFills = () => {
      let curbReturnFillList = [];

      for (let i = 0; i < object.crfills.length; i++) {
        curbReturnFillList.push(
          <path
            key={object.crfills[i].key}
            ttn-role="crFill"
            ttn-link={object.crfills[i].key}
            d={object.crfills[i].pathData}
          ></path>
        );
      }
      return curbReturnFillList;
    };
    return (
      <g id={object.functype}>
        <metadata ssurfacetype={object.surfaceType}></metadata>
        {/* {getStreetFills()} */}
        {getCurbReturnFills()}
      </g>
    );
  }
}

export default StreetSurface;
