import * as React from 'react';

function SvgIconRv(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-.001-.002h24v24h-24z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M11 11h3v3h-3zM16 11h3v3h-3z" />
          <path d="M21 5H8a1 1 0 00-.874.514l-5 9A.997.997 0 002 15v4a1 1 0 001 1h2.185A2.993 2.993 0 008 22a2.993 2.993 0 002.815-2h3.369a2.994 2.994 0 002.815 2 2.994 2.994 0 002.815-2H21a1 1 0 001-1V6a1 1 0 00-1-1zM4 15.261L4.144 15H7v-2H5.255l.556-1H8v4a2.993 2.993 0 00-2.815 2H4v-2.739zM8 20c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm9 0c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm3-2h-.185A2.994 2.994 0 0017 16a2.994 2.994 0 00-2.815 2h-3.369a2.979 2.979 0 00-.815-1.22V11a1 1 0 00-1-1H6.922l1.667-3H20v11z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconRv;
