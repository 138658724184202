import React from 'react';
import {
  FindCircle,
  GetCenterInTwoPoints,
  LengthBetweenPoints,
  IsUnderLine,
  getAngleBy2Points,
  ByTime,
  RotatePoint,
  GetCenter,
  IsOneLine,
  IsBigArc,
  IsArcDirection,
  TowPointVerticalLengthPoints,
  IsZero,
  GetArcAngle,
  GetArcCenter,
  GetCircleCenter,
  GetCircleArcCenter2,
  toFixed,
  FindCircleLinePoint,
  IsByTime,
} from '@src/data/CommonFunc';
import ArrowDirection from '../ArrowDirection';
import { TextStairsHandle } from '@src/actions/ShapeTextHandle';
import { STAIR_SPACE, THREE_POINT_STATUS_TYPE } from '@src/constant';

class Stairs extends React.Component {
  getMarks = (handlepoint, marks) => {
    let D = [];
    if (IsOneLine(handlepoint[0], handlepoint[3], handlepoint[6]) === THREE_POINT_STATUS_TYPE.ONLINE) {
      let length = LengthBetweenPoints(handlepoint[0], handlepoint[6]);
      let n = Math.round(length / marks.length);
      if (IsZero(n)) {
        return null;
      }

      let height = LengthBetweenPoints(handlepoint[1], handlepoint[0]);
      for (let i = 1; i < n; i++) {
        let center = GetCenter(handlepoint[0], handlepoint[6], i / n);
        let points = TowPointVerticalLengthPoints(handlepoint[0], handlepoint[6], center, height);
        D.push('M' + String(points[0][0]) + ',' + String(points[0][1]));
        D.push('L' + String(points[1][0]) + ',' + String(points[1][1]));
      }
    } else {
      let anchors = handlepoint;
      let height = LengthBetweenPoints(anchors[1], anchors[0]);
      let circle = FindCircle(anchors[0], anchors[3], anchors[6]);
      let outCircle = [circle[0], circle[1], circle[2] + height];
      let innerCircle = [circle[0], circle[1], circle[2] - height];
      let isByTime = IsByTime(anchors[0], anchors[3], anchors[6]);
      let angle = GetArcAngle(circle, anchors[0], anchors[6], isByTime);
      let n = Math.round((angle * circle[2]) / marks.length);
      let oneAngle = angle / n;
      let angle0 = getAngleBy2Points(circle, anchors[0]);
      let bRet = ByTime(anchors[0], anchors[3], anchors[6]);
      for (let i = 1; i < n; i++) {
        let angleGo = 0;
        if (bRet == THREE_POINT_STATUS_TYPE.BYTIME) {
          angleGo = angle0 + i * oneAngle;
        } else {
          angleGo = angle0 - i * oneAngle;
        }
        let x1 = circle[0] + outCircle[2] * Math.cos(angleGo);
        let y1 = circle[1] + outCircle[2] * Math.sin(angleGo);
        let x2 = circle[0] + innerCircle[2] * Math.cos(angleGo);
        let y2 = circle[1] + innerCircle[2] * Math.sin(angleGo);
        D.push('M' + String(x1) + ',' + String(y1));
        D.push('L' + String(x2) + ',' + String(y2));
      }
    }

    return <path d={D.join(' ')} />;
  };
  getDirection = (marks, anchors) => {
    if (!marks.isexist) return null;
    let begin = GetCenter(anchors[1], anchors[2], 1 / 6);
    let pointEnd = GetCenter(anchors[4], anchors[5], 1 / 6);
    if (IsOneLine(anchors[0], anchors[3], anchors[6]) === THREE_POINT_STATUS_TYPE.ONLINE) {
      let p1 = GetCenter(begin, pointEnd, 0.2);
      let p2 = GetCenter(begin, pointEnd, 0.8);
      let center = GetCenterInTwoPoints(p1, p2);
      return <ArrowDirection p1={p1} p2={p2} center={center} />;
    } else {
      let circle = FindCircle(anchors[0], anchors[3], anchors[6]);
      let r = LengthBetweenPoints(begin, circle);
      let circleNew = [circle[0], circle[1], r];
      let centerMiddle = GetCenterInTwoPoints(begin, pointEnd);
      let center = FindCircleLinePoint(circleNew, centerMiddle); // GetCircleArcCenter2(begin, pointEnd, r, isBigArc, isShun);
      let centerCircle = FindCircle(begin, pointEnd, center);
      let isByTime = IsByTime(begin, center, pointEnd);
      let p1 = GetArcCenter(centerCircle, begin, pointEnd, STAIR_SPACE.STAIRMARKBEIGIN, isByTime);
      let p2 = GetArcCenter(centerCircle, begin, pointEnd, STAIR_SPACE.STAIRMARKEND, isByTime);
      return <ArrowDirection p1={p1} p2={p2} center={center} />;
    }
  };
  getOutShapes = anchors => {
    let rOut = 0,
      rInner = 0,
      r = 0;
    let D = [];
    D.push('M' + String(anchors[1][0]) + ',' + String(anchors[1][1]));
    D.push('L' + String(anchors[2][0]) + ',' + String(anchors[2][1]));
    if (IsOneLine(anchors[0], anchors[3], anchors[6]) === THREE_POINT_STATUS_TYPE.ONLINE) {
      rOut = 0;
      rInner = 0;
    } else {
      let circle = FindCircle(anchors[0], anchors[3], anchors[6]);
      rOut = LengthBetweenPoints(circle, anchors[1]);
      rInner = LengthBetweenPoints(circle, anchors[2]);
      r = circle[2];
    }
    let isBigArc = IsBigArc(anchors[0], anchors[6], anchors[3], r);
    let isDirction = IsArcDirection(anchors[2], anchors[5], anchors[8]);
    D.push('A' + String(rInner) + ',' + String(rInner));
    D.push('0');
    D.push(isBigArc + ',' + isDirction);
    D.push(String(anchors[8][0]) + ',' + String(anchors[8][1]));
    D.push('L' + String(anchors[7][0]) + ',' + String(anchors[7][1]));
    let reBackDirction = isDirction == '0' ? '1' : '0';
    D.push('A' + String(rOut) + ',' + String(rOut));
    D.push('0');
    D.push(isBigArc + ',' + reBackDirction);
    D.push(String(anchors[1][0]) + ',' + String(anchors[1][1]));

    return <path d={D.join(' ')} />;
  };
  render() {
    const { object } = this.props;

    if (object.handlepoint.length <= 3) return null;

    return (
      <g id={object.functype}>
        {this.getOutShapes(object.handlepoint)}
        {this.getMarks(object.handlepoint, object.marks)}
        {this.getDirection(object.marks, object.handlepoint)}
        {TextStairsHandle(object)}
      </g>
    );
  }
}

export default Stairs;
