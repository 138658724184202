import {
  SET_OPACITY,
  SET_CURRENT_LOCATION,
  SET_SELECT_OPTIONS,
  TOGGLE_MAP_CONTAINER,
  SWITCH_LOCATION_LOADING,
  SWITCH_MAP_TAB,
  SET_KEYWORD,
  SET_MAP_TYPE,
  SET_MAP_VIEW,
  RESUME_MAP_STATE,
  SET_MAP_CONFIGS,
  SET_NIGHT_MODE,
} from '../type/map';

export const setMapView = ({ latlng, zoom }) => ({
  type: SET_MAP_VIEW,
  payload: {
    latlng,
    zoom,
  },
});

export const setMapType = ({ schema, type }) => ({
  type: SET_MAP_TYPE,
  payload: { schema, type },
});

export const setOpacity = opacity => ({
  type: SET_OPACITY,
  opacity,
});

export const setCurrentLocation = value => ({
  type: SET_CURRENT_LOCATION,
  value,
});

export const setSelectOptions = value => ({
  type: SET_SELECT_OPTIONS,
  value,
});

export const toggleMapContainer = value => ({
  type: TOGGLE_MAP_CONTAINER,
  value,
});

export const switchLocationLoading = loading => ({
  type: SWITCH_LOCATION_LOADING,
  loading,
});

export const switchMapTab = value => ({
  type: SWITCH_MAP_TAB,
  value,
});

export const setKeyword = value => ({
  type: SET_KEYWORD,
  value,
});

/**
 *
 * @param {object} [payload]
 */
export const resumeMapState = payload => (dispatch, getState) => {
  const { mapConfigs, mapTabKey } = getState().map;
  if (payload) {
    // 如果地图没有任何变更，点击cancel，地图不应该切换tab
    if (mapTabKey === '1' || mapTabKey === '2') {
      payload.mapTabKey = '2';
    }
  }
  dispatch({
    type: RESUME_MAP_STATE,
    payload: { ...payload, mapConfigs },
  });
};

export const setMapConfigs = payload => ({
  type: SET_MAP_CONFIGS,
  payload,
});

export const setNightMode = value => ({
  type: SET_NIGHT_MODE,
  value,
});
