/* eslint-disable */

import React from 'react';
import {
  GetCenterInTwoPoints,
  TowPointVerticalLengthByStatus,
  LengthBetweenPoints,
  IsUnderLine,
  IsZero,
  ByTime,
  getAngleBy2Points,
  GetCenter,
  GetCirclePoint,
  IsArcDirection,
  GetArcCenter,
  ScalePoint,
  FindCircle,
} from '@src/data/CommonFunc';
import { GetShapeStyle } from '@src/data/CommonProps';
import { GetShapes, GetStreetPathD } from '@src/data/BusinessFun';
import ArrowLength from '../ArrowLength';
import { GetArcAnchors, getSquareHandlePoint, getTriangleHandlePoint, GetCrossAnchors, getCircleHandlePoint } from '@src/data/AnchorsPoint';
import {
  GetStreetAreaBegin,
  GetStreetAreaEnd,
  getStreetLaneHeight,
  getStreetLanePoint,
  GetBikeLaneCount,
  GetMaxHeight,
  GetStreetMaxPoints,
  GetStreetLineBegin,
  GetStreetLinePoints,
  GetStreetBeginTopPoint,
  GetStreetEndTopPoint,
  GetStreetLineCount,
} from '@src/actions/StreetFunHandle';
import { GetStreetText } from '@src/actions/ShapeTextHandle';
import { GetShapeLine } from '../ShapeFun';
import SplitPattem from '../SplitPattem';
import {
  ANCHORS_TYPE,
  COLOR_TYPE,
  DASH_ARRAY_TYPE,
  STREET_LINE_TYPE,
  STREET_POINT_TYPE,
  STREET_SPACE,
  THREE_POINT_STATUS_TYPE,
} from '@src/constant';

class SelectStreet extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps({ object }) {
    this.init(object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    let maxheight = GetMaxHeight(object);
    let maxTopPoints = GetStreetMaxPoints(object, maxheight / 2, true);
    let linepoints = [];
    let beginStreetLine = GetStreetLineBegin(object.groupdata);
    linepoints[beginStreetLine] = maxTopPoints;
    let i = beginStreetLine;
    let lineData = object.groupdata;
    while (i >= 0 && i < lineData.length) {
      let laneHeight = object.lanewidth;
      if (i < object.lanes.length) {
        laneHeight = object.lanes[i].width;
      }
      let bottomLine = lineData[i].bottomline;
      if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.TWOWAY)
      ) {
        laneHeight = laneHeight + STREET_SPACE.TWOWAY;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.PAINTED)
      ) {
        laneHeight = laneHeight + STREET_SPACE.PAINTED;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.WIDE)
      ) {
        laneHeight = laneHeight + STREET_SPACE.WIDE;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, false);
        }
      }
      if (bottomLine != -1) linepoints[bottomLine] = GetStreetLinePoints(linepoints[i], laneHeight, false);
      i = bottomLine;
    }
    //获取各个车道的锚点
    if (isFirst) {
      this.state = {
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
      };
    } else {
      this.setState({
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
      });
    }
  };

  getAnchors = (shapes, object) => {
    let anchors = object.handlepoint;
    shapes.push(getSquareHandlePoint(ANCHORS_TYPE.STREETBEGIN, anchors[3]));
    shapes.push(getSquareHandlePoint(ANCHORS_TYPE.STREETEND, anchors[4]));
    let i = GetStreetAreaBegin(anchors);
    while (i != -1 && anchors[i][3].rightindex != -1 && i != null) {
      let centerIndex = anchors[i][3].rightindex;
      let endIndex = anchors[centerIndex][3].rightindex;
      i = endIndex;
      if (anchors[centerIndex][2] === STREET_POINT_TYPE.CENTER) {
        let id = String(centerIndex) + '||' + ANCHORS_TYPE.STREETCENTER;
        shapes.push(getCircleHandlePoint(id, anchors[centerIndex]));
      }
      if (
        anchors[i][2] === STREET_POINT_TYPE.NORMAL &&
        anchors[i][3].rightindex != -1 &&
        anchors[i][3].leftIndex != -1
      ) {
        let id = String(i) + '||' + ANCHORS_TYPE.STREETNORMAL;
        shapes.push(getSquareHandlePoint(id, anchors[i]));
      }
    }
  };
  getLaneAnchors = shapes => {
    let anchors = this.state.object.handlepoint;
    let lineData = this.state.object.groupdata;
    let i = GetStreetLineBegin(lineData);
    while (i >= 0 && i < lineData.length) {
      let anchorData = this.state.linepoints[i];
      let endIndex = GetStreetAreaEnd(anchorData);
      let beginIndex = GetStreetAreaBegin(anchorData);

      let begincenterIndex = anchorData[beginIndex][3].rightindex;
      let beginendIndex = anchorData[begincenterIndex][3].rightindex;

      let endCenterIndex = anchorData[endIndex][3].leftIndex;
      let endBeginIndex = anchorData[endCenterIndex][3].leftIndex;

      // let topHeight = getStreetLaneHeight(object.streetwidth, object.streetlanes, 0);
      // let lanePoints = getStreetLanePoint(anchors, anchors[endCenterIndex][3].leftIndex, topHeight, true);
      let laneHeight = LengthBetweenPoints(anchors[beginIndex], anchorData[beginIndex]);
      let isUnderLine = IsUnderLine(anchors[beginIndex], anchors[beginendIndex], anchorData[beginIndex]);
      let id =
        String(i) +
        '||' +
        String(beginIndex) +
        '||' +
        String(laneHeight) +
        '||' +
        String(isUnderLine) +
        '||' +
        ANCHORS_TYPE.STREETLEFTTOP;
      let leftTopPoint = GetStreetBeginTopPoint(
        lineData[i],
        anchorData[beginIndex],
        anchorData[begincenterIndex],
        anchorData[beginendIndex]
      );
      if (!IsZero(lineData[i].topleftr)) {
        shapes.push(
          <ArrowLength p1={anchorData[beginIndex]} p2={leftTopPoint} istop={true} key={id + 'arrowlength'} />
        );
        this.getArcLeftMovePoint(i, beginIndex, shapes);
      }

      if (!(GetStreetLineCount(lineData) === 3 && i === 2)) shapes.push(getTriangleHandlePoint(id, leftTopPoint));

      let idEnd =
        String(i) +
        '||' +
        String(endIndex) +
        '||' +
        String(laneHeight) +
        '||' +
        String(isUnderLine) +
        '||' +
        ANCHORS_TYPE.STREETRIGHTTOP;

      let rightTopPoint = GetStreetEndTopPoint(
        lineData[i],
        anchorData[endBeginIndex],
        anchorData[endCenterIndex],
        anchorData[endIndex]
      );
      if (!IsZero(lineData[i].toprightr)) {
        shapes.push(
          <ArrowLength p1={anchorData[endIndex]} p2={rightTopPoint} istop={true} key={idEnd + 'arrowlength'} />
        );
        this.getArcRightMovePoint(i, endIndex, shapes);
      }

      if (!(GetStreetLineCount(lineData) === 3 && i === 2)) shapes.push(getTriangleHandlePoint(idEnd, rightTopPoint));
      i = lineData[i].bottomline;
    }
  };
  getArcLeftMovePoint = (lineIndex, beginIndex, shapes) => {
    let points = this.state.linepoints[lineIndex];
    let linedata = this.state.object.groupdata[lineIndex];
    let centerIndex = points[beginIndex][3].rightindex;
    let endIndex = points[centerIndex][3].rightindex;
    let circle = FindCircle(points[beginIndex], points[centerIndex], points[endIndex]);
    let beginTopR = 0;
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [];
    let beginTopPoint = GetStreetBeginTopPoint(linedata, points[beginIndex], points[centerIndex], points[endIndex]);
    let isBytime = ByTime(points[beginIndex], points[centerIndex], points[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[beginIndex], points[endIndex]);
      arcEndPoint = GetCenter(points[beginIndex], points[endIndex], linedata.pathend);
      arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], linedata.topleftr * 2], angle + Math.PI);
      let arcTopAngle = linedata.upstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint([arcBeginPoint[0], arcBeginPoint[1], linedata.topleftr], arcTopAngle);
    } else {
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      arcEndPoint = GetArcCenter(circle, points[beginIndex], points[endIndex], linedata.pathend, isBytime);
      let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd + (isBytime ? (-linedata.topleftr * 2) / circle[2] : (linedata.topleftr * 2) / circle[2]);
      arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circle[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    }
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let id = String(lineIndex) + '||' + ANCHORS_TYPE.STREETARCLEFT;
    shapes.push(getSquareHandlePoint(id, arcCenter));
  };
  getArcRightMovePoint = (lineIndex, endIndex, shapes) => {
    let points = this.state.linepoints[lineIndex];
    let linedata = this.state.object.groupdata[lineIndex];
    let centerIndex = points[endIndex][3].leftIndex;
    let beginIndex = points[centerIndex][3].leftIndex;
    let circle = FindCircle(points[beginIndex], points[centerIndex], points[endIndex]);
    let beginTopR = 0;
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [];
    let beginTopPoint = GetStreetEndTopPoint(linedata, points[beginIndex], points[centerIndex], points[endIndex]);
    let isBytime = ByTime(points[endIndex], points[centerIndex], points[beginIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;

    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[beginIndex], points[endIndex]);
      arcEndPoint = GetCenter(points[beginIndex], points[endIndex], linedata.pathrightend);
      arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], linedata.toprightr * 2], angle);
      let arcTopAngle = linedata.uprightstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint([arcBeginPoint[0], arcBeginPoint[1], linedata.toprightr], arcTopAngle);
    } else {
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      arcEndPoint = GetArcCenter(circle, points[beginIndex], points[endIndex], linedata.pathrightend, !isBytime);
      let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd + (!isBytime ? (linedata.toprightr * 2) / circle[2] : (-linedata.toprightr * 2) / circle[2]);
      arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circle[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    }

    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let id = String(lineIndex) + '||' + ANCHORS_TYPE.STREETARCRIGHT;
    shapes.push(getSquareHandlePoint(id, arcCenter));
  };
  GetCenterInTwoPointsLine = (shapes, anchors) => {
    let D = [];
    let beginIndex = GetStreetAreaBegin(anchors);
    GetStreetPathD(D, anchors, beginIndex);
    shapes.push(<path d={D.join(' ')} key="centerline" />);
  };

  getTextRect = (shapes, object) => {
    if (object.text.text.length == 0) return;
    let anchors = object.handlepoint;
    let textPoint = object.text.point;
    if (textPoint.length === 0) {
      let center01 = GetCenterInTwoPoints(anchors[0], anchors[1]);
      textPoint = TowPointVerticalLengthByStatus(anchors[0], anchors[1], center01, object.lanewidth / 2, false);
    }
    let textLength = object.text.text.length * object.text.size + 2;
    let textHeight = object.text.size + 2;
    let orginPoint = [textPoint[0] - textLength / 2, textPoint[1] - 4];

    let shape = (
      <g id={ANCHORS_TYPE.STREETTEXTRECT} key={ANCHORS_TYPE.STREETTEXTRECT}>
        <rect
          x={orginPoint[0]}
          y={orginPoint[1]}
          width={textLength}
          height={textHeight}
          fill={COLOR_TYPE.NONE}
          key={'textrect'}
          id={ANCHORS_TYPE.STREETTEXTRECT}
        />
        {GetStreetText(object)}
      </g>
    );
    shapes.push(shape);
  };
  getAcrossSelect(shapes) {
    let acrossData = this.state.object.streetacrossdata;
    for (let i = 0; i < acrossData.length; i++) {
      if (acrossData[i].length < 3) continue;
      if (acrossData[i].length > 3 && acrossData[i][3].selectflag) {
        let shapeLine = GetShapeLine(acrossData[i][0], acrossData[i][2], 'acrossline' + String(i), true, {
          stroke: COLOR_TYPE.GREEN,
          fill: COLOR_TYPE.NONE,
          strokewidth: 1,
          strokedasharray: DASH_ARRAY_TYPE.Dashes,
        });
        shapes.push(shapeLine);
        let shapeMove1 = getSquareHandlePoint(
          String(i) + '||' + String(0) + '||' + ANCHORS_TYPE.STREETACROSSPOINT,
          acrossData[i][0]
        );
        shapes.push(shapeMove1);
        let shapeMove2 = getSquareHandlePoint(
          String(i) + '||' + String(2) + '||' + ANCHORS_TYPE.STREETACROSSPOINT,
          acrossData[i][2]
        );
        shapes.push(shapeMove2);
        // let shapeMove3 = getSquareHandlePoint(
        //   String(i) + '||' + String(1) + '||' + ANCHORS_TYPE.STREETACROSSCENTER,
        //   GetCenterInTwoPoints(acrossData[i][0], acrossData[i][2]),
        //   4
        // );
        // shapes.push(shapeMove3);
        let acrossPoint = GetCrossAnchors('cross' + String(i), acrossData[i][1], 4);
        shapes.push(acrossPoint);
        let acrossPointIsUnder = IsUnderLine(acrossData[i][0], acrossData[i][2], acrossData[i][1]);
        let topAcrossPoint0 = TowPointVerticalLengthByStatus(
          acrossData[i][0],
          acrossData[i][1],
          acrossData[i][0],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint1 = TowPointVerticalLengthByStatus(
          acrossData[i][0],
          acrossData[i][1],
          acrossData[i][1],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint11 = TowPointVerticalLengthByStatus(
          acrossData[i][1],
          acrossData[i][2],
          acrossData[i][1],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint2 = TowPointVerticalLengthByStatus(
          acrossData[i][1],
          acrossData[i][2],
          acrossData[i][2],
          8,
          acrossPointIsUnder
        );
        shapes.push(
          <ArrowLength p1={topAcrossPoint0} p2={topAcrossPoint1} istop={false} key={String(i) + 'acrossLength1'} />
        );
        shapes.push(
          <ArrowLength p1={topAcrossPoint11} p2={topAcrossPoint2} istop={false} key={String(i) + 'acrossLength2'} />
        );
      }
    }
  }
  getLineSplitMove(shapes) {
    for (let i = 0; i < this.state.object.groupdata.length; i++) {
      for (let j = 0; j < this.state.object.groupdata[i].splitpattem.length; j++) {
        let lineIndex = i;
        let scalesplit = this.state.object.groupdata[i].splitpattem[j].startPct;
        let linePoints = this.state.linepoints[lineIndex];
        let lineData = this.state.object.groupdata[lineIndex];
        let beginIndex = GetStreetAreaBegin(linePoints);
        let centerIndex = linePoints[beginIndex][3].rightindex; // anchors[begin[3].rightindex];
        let endIndex = linePoints[centerIndex][3].rightindex; // anchors[center[3].rightindex];
        let circleLine = FindCircle(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
        if (IsZero(circleLine[2])) {
          let scalePoint = GetCenter(linePoints[beginIndex], linePoints[endIndex], scalesplit);
          let angle = getAngleBy2Points(linePoints[beginIndex], linePoints[endIndex]) + Math.PI / 2;
          shapes.push(
            <SplitPattem
              point={scalePoint}
              key={JSON.stringify(lineData) + 'splitPattem'}
              id={'0||' + String(lineIndex) + '||' + '0||splitpattemmove'}
              angle={angle}
            />
          );
        } else {
          let circle = FindCircle(inePoints[beginIndex], linePoints[endIndex], linePoints[centerIndex]);
          let isByTime = IsByTime(inePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
          let scalePoint = GetArcCenter(circle, linePoints[beginIndex], linePoints[endIndex], scalesplit, isByTime);
          let angle = Math.PI / 2;
          shapes.push(
            <SplitPattem
              point={scalePoint}
              key={JSON.stringify(lineData) + 'splitPattem'}
              id={'0||' + String(lineIndex) + '||' + '0||splitpattemmove'}
              angle={angle}
            />
          );
        }
      }
    }
  }
  /**
   *splitPattem
   *
   * @memberof Street
   */
  getLineSplitD = (lineIndex, D, shapes) => {
    let linePoints = this.state.linepoints[lineIndex];
    let lineData = this.state.object.groupdata[lineIndex];
    if (lineData.splitpattem.length == 0) {
      GetStreetLinePathD(D, linePoints);
    } else {
      let beginIndex = GetStreetAreaBegin(linePoints);
      let centerIndex = linePoints[beginIndex][3].rightindex; // anchors[begin[3].rightindex];
      let endIndex = linePoints[centerIndex][3].rightindex; // anchors[center[3].rightindex];
      let circleLine = FindCircle(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
      if (IsZero(circleLine[2])) {
        let scalePoint = GetCenter(linePoints[beginIndex], linePoints[endIndex], lineData.splitpattem[0].startPct);
        if (lineData.scalesplitleft) GetPathLineD(D, linePoints[beginIndex], scalePoint);
        else GetPathLineD(D, scalePoint, linePoints[endIndex]);
        let angle = getAngleBy2Points(linePoints[beginIndex], linePoints[endIndex]);
        if (this.state.object.selectflag)
          shapes.push(
            <SplitPattem
              point={scalePoint}
              key={JSON.stringify(lineData) + 'splitPattem'}
              angle={angle + Math.PI / 2}
            />
          );
      } else {
        let isBigArc = IsBigArc(linePoints[beginIndex], linePoints[endIndex], linePoints[centerIndex], circleLine[2]);
        let isArcDirection = IsArcDirection(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
        let circle = FindCircle(inePoints[beginIndex], linePoints[endIndex], linePoints[centerIndex]);
        let isByTime = IsByTime(inePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
        let scalePoint = GetArcCenter(
          circle,
          linePoints[beginIndex],
          linePoints[endIndex],
          lineData.splitpattem[0].startPct,
          isByTime
        );
        if (lineData.scalesplitleft)
          GetPathArcD(D, linePoints[beginIndex], scalePoint, circleLine[2], isBigArc, isArcDirection);
        else GetPathArcD(D, scalePoint, linePoints[endIndex], circleLine[2], isBigArc, isArcDirection);
        let angle = getAngleBy2Points(circleLine, scalePoint);
        if (this.state.object.selectflag)
          shapes.push(<SplitPattem point={scalePoint} key={JSON.stringify(lineData) + 'splitPattem'} angle={angle} />);
      }
    }
  };
  render() {
    if (this.state == null) return null;
    const { object } = this.state;
    if (object == null || object.handlepoint.length < 3) return null;
    let shapes = [];
    this.getAnchors(shapes, object);
    this.GetCenterInTwoPointsLine(shapes, object.handlepoint);
    this.getTextRect(shapes, object);
    this.getLaneAnchors(shapes);
    this.getLineSplitMove(shapes);
    this.getAcrossSelect(shapes);
    return <g>{GetShapes(shapes)}</g>;
  }
}

export default SelectStreet;
