import React from 'react';
import { Drawer } from 'antd';
import './modal-win.scss';
import { connect } from 'react-redux';
class ModalWin extends React.Component {
  render() {
    const { children, visible, appWidth, siderWidth } = this.props;

    return visible ? (
      <Drawer
        closable={false}
        visible={visible}
        // TODO: use createPortal to set as root element child so events will not be propagated to the root SVG
        getContainer={false}
        mask={false}
        bodyStyle={{ padding: 0 }}
        placement="bottom"
        height="auto"
        style={{
          width: appWidth - siderWidth,
          marginLeft: siderWidth,
        }}
        className="prop-menu-root"
      >
        {children}
      </Drawer>
    ) : null;
  }
}

const mapStateToProps = state => ({
  appWidth: state.app.appWidth,
  siderWidth: state.app.siderWidth,
  collapsed: state.app.collapsed,
});

export default connect(
  mapStateToProps,
  null
)(ModalWin);
