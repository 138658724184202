import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { toggleNightMode } from './action/app';
import App from './components/App';
import DataProvider from './components/DataProvider';
import { APP_ROOT_EL_ID } from './constant';
import { getSettings } from './settings';
import store from './store';
import './index.scss';

const __DEV__ = process.env.NODE_ENV === 'development';
global.__DEV__ = __DEV__;

const noop = () => {};

message.config({
  top: 60,
  maxCount: 1,
  getContainer: () => document.getElementById(APP_ROOT_EL_ID),
});

const RoadEngine = props => {
  const { isNightMode, templates, symbols, favoriteKeys } = props;
  const [imageData, setImageData] = useState();
  const [imageDataLoadError, setImageDataLoadError] = useState();

  // Load image from RN
  useEffect(() => {
    if (typeof window.CrashDesignerWrapper_imageData === 'string') {
      alert('Web imageData:' + window.CrashDesignerWrapper_imageData);
      // text
      // setImageData(new Blob([window.CrashDesignerWrapper_imageData], { type: 'image/svg+xml' }));

      // blobUrl
      fetch(window.CrashDesignerWrapper_imageData)
        .then(r => r.blob())
        .then(blob => {
          setImageData(blob);
        });
    }
  }, [window.CrashDesignerWrapper_imageData]);

  useEffect(() => {
    if (window.CrashDesignerWrapper_imageDataLoadError) {
      alert('Web imageDataError:' + window.CrashDesignerWrapper_imageDataLoadError);
      setImageDataLoadError(imageDataLoadError);
    }
  }, [window.CrashDesignerWrapper_imageDataLoadError]);

  useEffect(() => {
    setImageData(props.imageData);
  }, [props.imageData]);

  useEffect(() => {
    if (isNightMode !== undefined && store.getState().app.nightMode !== isNightMode) {
      store.dispatch(toggleNightMode());
    }
  }, [isNightMode]);

  return (
    <Provider store={store}>
      <DataProvider templates={templates} symbols={symbols} favoriteKeys={favoriteKeys}>
        <App {...props} imageData={imageData} />
      </DataProvider>
    </Provider>
  );
};

// don't provide default values for: onExit, onSvgImage, isNightMode as they'll be used to determine if we're in an
// embedded build or a standalone build
RoadEngine.defaultProps = {
  onChange: noop,
};

RoadEngine.propTypes = {
  imageData: PropTypes.instanceOf(Blob),
  existingImage: PropTypes.instanceOf(Blob),
  onChange: PropTypes.func,
  onSvgImage: PropTypes.func,
  isNightMode: PropTypes.bool,
  onExit: PropTypes.func,
  mapInfo: PropTypes.oneOfType([
    PropTypes.shape({
      currentLocation: PropTypes.string.isRequired,
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      opacity: PropTypes.number,
      schema: PropTypes.oneOf(['Bing', 'ESRI', 'Google']),
      type: PropTypes.string,
      zoom: PropTypes.number,
    }),
    PropTypes.object,
  ]),
};

export { getSettings };
export default RoadEngine;
