import React from 'react';
import { textOtherProps } from '@src/utils';

class Text extends React.Component {
  constructor(props) {
    super(props);
    let { x, y, text } = props;
    this.state = {
      isMouseDown: false,
      startPoint: [x, -(y - (83 * text.size) / 100)],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps) {
      let { x, y, text } = nextProps;
      this.setState({
        startPoint: [x, -(y - (83 * text.size) / 100)],
      });
    }
  }

  onMouseDown = evt => {
    this.setState({
      isMouseDown: true,
    });
  };

  onMouseUp = evt => {
    this.setState({
      isMouseDown: false,
    });
  };

  onMouseMove = evt => {
    let { clientX, clientY } = evt;
    this.setState({
      startPoint: [clientX, clientY],
    });
  };

  render() {
    let { text, textAnchor, angle, id, content } = this.props;
    let { startPoint, isMouseDown } = this.state;
    let pId = id == null ? 'text' : id;
    let style = {
      fontSize: text.size,
      fontWeight: text.bold,
      fontStyle: text.italic,
    };

    if (Math.abs(angle) < 180) {
      startPoint[1] = startPoint[1] + Math.abs(angle) / 5;
    } else {
      startPoint[1] = startPoint[1] + (360 - Math.abs(angle)) / 5;
    }

    return (
      <text
        x={startPoint[0]}
        y={startPoint[1]}
        strokeWidth={0.1}
        textAnchor={textAnchor}
        stroke={text.color}
        style={style}
        fill={text.color}
        id={pId}
        transform={`scale(1, -1) rotate(${-angle} ${startPoint[0]} ${startPoint[1]})`}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={isMouseDown ? this.onMouseMove : undefined}
        {...textOtherProps(text.color)}
      >
        {content}
      </text>
    );
  }
}

export default Text;
