import { TransformMoveObject } from '@src/actions/TransformHandle';
import {
  ANCHORS_TYPE,
  CANVAS,
  COUNTER_TOP_PARAM_TYPE,
  CROSS_WALK_SPACE,
  DASH_ARRAY_TYPE,
  FUNCTION_TYPE,
  LANE_TYPE,
  LENGTH_TYPE,
  STAIR_SPACE,
  STREET_DEFAULT_SIZE,
  STREET_DIVIDER_TYPE,
  STREET_LINE_TYPE,
  STREET_POINT_TYPE,
  STREET_SPACE,
  STREET_STRIPES_TYPE,
  STRUCTURE_PARAM_TYPE,
} from '@src/constant';
import {
  AddStreetData,
  AddStreetLineData,
  AddStructureData,
  CopyPoint,
  GetClosedShapeAngle,
  GetClosedShapeAnglePoint,
  GetCLoseShapeAnglePoint,
  GetStructureAngle,
} from '@src/data/BusinessFun';
import {
  GetAngleArc,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetCirclePoint,
  GetSmallCircleCenter,
  GetTowPointRange,
  LengthBetweenPoints,
  RotatePoint,
  TowPointDestPointLength,
  TowPointVerticalAcross,
} from '@src/data/CommonFunc';
import { clientPtToUserPt, getUserUnitByPixel } from '@src/data/CommonFunction';
import diagramManager from '@src/data/DiagramManager';
import emitter from '@src/data/Event';
import FreeStripe from '@src/data/FreeStripe';
import GeomArc from '@src/data/GeomArc';
import GeomPoint from '@src/data/GeomPoint';
import { stripeStyles, SymbolKeyNames } from '@src/data/ShapeDataList';
import { createArc, createLane, createLine, createShoulder, createStripe } from '@src/data/ShapeOperationData';
import UtilityMath from '@src/data/UtilityMath';
import * as workData from '@src/data/WorkData';
import _Street from '@src/data/_Street';
import { StreetService } from '@src/services/StreetService';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import _ from 'lodash';
import { GetStreetLineBegin } from './StreetFunHandle';

const { LANE_WIDTH, STREET_LEN } = STREET_DEFAULT_SIZE;
const { CANVASWIDTH, CANVASHEIGHT, CANVASSPACE } = CANVAS;

// export function getViewPoint(viewBox, canvasPoint, canvasbengin, svgpoint) {
//   var scalex = viewBox[2] / (CANVASWIDTH + CANVASSPACE);
//   var scaley = viewBox[3] / (CANVASHEIGHT + CANVASSPACE);
//   var x = canvasPoint[0] * scalex;
//   x = toFixed(x);
//   canvasPoint[1] = canvasPoint[1] - HEADHEIGHT;
//   var y = canvasPoint[1] * scaley;
//   y = toFixed(y);
//   //30用于间隔出空间
//   return [svgpoint[0] + canvasbengin[0] - x - 30, svgpoint[1] + canvasbengin[1] - y - 30];
// }

/**
 * svg坐标转化成 Canvas 坐标
 * @param {[number, number, number, number]} viewbox
 * @param {[number, number]} canvasBegin
 * @param {[number, number]} canvaspoint
 * @returns {[number, number]}
 */
// export function getCanvasXY(viewbox, canvasBegin, canvaspoint) {
//   //传入事件屏幕坐标
//   var x = canvaspoint[0],
//     y = canvaspoint[1];
//   var scalex = viewbox[2] / (CANVASWIDTH + CANVASSPACE);
//   var scaley = viewbox[3] / (CANVASHEIGHT + CANVASSPACE);
//   scalex = toFixed(scalex);
//   scaley = toFixed(scaley);
//   y = y - HEADHEIGHT;
//   x = x * scalex;
//   y = y * scaley;
//   var afterX = x + viewbox[0] - canvasBegin[0];
//   var afterY = y + viewbox[1] - canvasBegin[1];
//   afterX = toFixed(afterX);
//   afterY = toFixed(afterY);
//   return [afterX, afterY];
// }

/**
 * svg坐标转化成界面坐标
 * @param {[number, number, number, number]} viewbox
 * @param {[number, number]} canvasBegin
 * @param {[number, number]} svgpoint
 * @returns {[number, number]}
 */
// export function getViewXY(viewbox, canvasBegin, svgpoint) {
//   var scalex = viewbox[2] / (CANVASWIDTH + CANVASSPACE);
//   var scaley = viewbox[3] / (CANVASHEIGHT + CANVASSPACE);
//   scalex = toFixed(scalex);
//   scaley = toFixed(scaley);
//   var x = svgpoint[0] + canvasBegin[0] - viewbox[0];
//   var y = svgpoint[1] + canvasBegin[1] - viewbox[1];
//   x = x / scalex;
//   y = y / scaley;
//   return [toFixed(x), toFixed(y) + HEADHEIGHT];
// }

const HEADHEIGHT = LENGTH_TYPE.MENUHEAD;
const INFOHEIGHT = LENGTH_TYPE.INFORSPACE;
const INFOANCHORSLENGTH = LENGTH_TYPE.INFORANGLESPACE;
const CONNECTSHAPESPCAE = LENGTH_TYPE.CONNECTSHAPESPACE;

export function HandlePointCallout(obj) {
  const { x1, y1, x2, y2 } = obj.position;
  // end of Callout line, center of text/box
  const [cx, cy] = [x2, y2];
  // FIXME: currently set as half the desired size
  var width = LENGTH_TYPE.CALLOUTWIDTH;
  var height = LENGTH_TYPE.CALLOUTHEIGHT;
  // bottom-left
  const [xMin, yMin] = [cx - width, cy - height];
  // top-right
  const [xMax, yMax] = [cx + width, cy + height];
  var angle = [xMax + INFOANCHORSLENGTH, y2];

  obj.handlepoint = [
    [x1, y1],
    [x2, y2],
    // bottom-left
    [xMin, yMin],
    // bottom-right
    [xMax, yMin],
    // top-right
    [xMax, yMax],
    // top-left,
    [xMin, yMax],
    [angle[0], angle[1]],
    [angle[0], yMin],
    [angle[0], yMax],
  ];
  return obj;
}

/**
 * 闭合图形的 handlepoint 行为
 * @param {object} obj - objList
 * @param {[number, number]} point
 * @param {1 | 2} [addstate] - 1 添加 2 结束
 * @returns {object} objList
 */
export function HandlePointClosedShape(obj, addstate) {
  // TODO: 对 objList 直接进行了修改
  var iCount = obj.handlepoint.length;
  let center;
  let point = [obj.position.x2, obj.position.y2];
  if (addstate == 1) {
    center = GetCenterInTwoPoints(obj.handlepoint[iCount - 1], point);
    let ptAngle = [center[0], center[1], ANCHORS_TYPE.CONNECTCANGLE];
    let ptNormal = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];

    // 防止点击一个地方绘制多个相同的点
    if (iCount >= 3) {
      if (_.isEqual(ptAngle, obj.handlepoint[iCount - 2]) && _.isEqual(ptNormal, obj.handlepoint[iCount - 3])) {
        return obj;
      }
    }
    obj.handlepoint[iCount] = ptAngle;
    obj.handlepoint[iCount + 1] = ptNormal;
  } else if (addstate == 2) {
    obj.handlepoint.length -= 2;
    iCount -= 2;
    var centerLeft = GetCenterInTwoPoints(obj.handlepoint[iCount - 3], point);
    obj.handlepoint[iCount - 2] = [centerLeft[0], centerLeft[1], ANCHORS_TYPE.CONNECTCANGLE];
    obj.handlepoint[iCount - 1] = [point[0], point[1], ANCHORS_TYPE.CLOSEEND];
    var centerRight = GetCenterInTwoPoints(obj.handlepoint[0], point);
    obj.handlepoint[iCount] = [centerRight[0], centerRight[1], ANCHORS_TYPE.CONNECTCANGLE];
    if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
      obj.handlepoint[iCount + 1] = GetClosedShapeAnglePoint(obj.handlepoint, 0);

      setInitialAngularDeviationOfClosedShape(obj);
    }
  } else {
    if (obj.handlepoint[iCount - 1][2] == ANCHORS_TYPE.CONNECTBEGIN) {
      center = GetCenterInTwoPoints(obj.handlepoint[iCount - 1], point);
      obj.handlepoint[iCount] = [center[0], center[1], ANCHORS_TYPE.CONNECTCANGLE];
      obj.handlepoint[iCount + 1] = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];
    } else {
      center = GetCenterInTwoPoints(obj.handlepoint[iCount - 3], point);
      obj.handlepoint[iCount - 2] = [center[0], center[1], ANCHORS_TYPE.CONNECTCANGLE];
      obj.handlepoint[iCount - 1] = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];
    }
  }

  return obj;
}

/**
 * connect shape 的 handlepoint 行为
 * @param {object} obj - objList
 * @param {[number, number]} point
 * @param {1 | 2} [addstate] - 1 添加 2 结束
 * @returns {object} objList
 */
export function HandlePointConnectShape(obj, addstate) {
  // TODO: 对 objList 直接进行了修改
  var iCount = obj.handlepoint.length;
  let center;
  let point = [obj.position.x2, obj.position.y2];
  if (addstate == 1) {
    // 获取第一个绘制点和第二个绘制点的中心
    center = GetCenterInTwoPoints(obj.handlepoint[iCount - 1], point);
    let ptAngle = [center[0], center[1], ANCHORS_TYPE.CONNECTCANGLE];
    let ptNormal = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];

    // 防止点击一个地方绘制多个相同的点
    if (iCount >= 3) {
      if (_.isEqual(ptAngle, obj.handlepoint[iCount - 2]) && _.isEqual(ptNormal, obj.handlepoint[iCount - 3])) {
        return obj;
      }
    }
    obj.handlepoint[iCount] = ptAngle;
    obj.handlepoint[iCount + 1] = ptNormal;
  } else if (addstate == 2) {
    obj.handlepoint.length -= 2;
    iCount -= 2;
    var centerLeft = GetCenterInTwoPoints(obj.handlepoint[iCount - 3], point);
    obj.handlepoint[iCount - 2] = [centerLeft[0], centerLeft[1], ANCHORS_TYPE.CONNECTCANGLE];
    obj.handlepoint[iCount - 1] = [point[0], point[1], ANCHORS_TYPE.CLOSEEND];
    if (obj.functype === FUNCTION_TYPE.CLOSEDSHAPE) {
      var centerRight = GetCenterInTwoPoints(obj.handlepoint[0], point);
      obj.handlepoint[iCount] = [centerRight[0], centerRight[1], ANCHORS_TYPE.CONNECTCANGLE];
      obj.handlepoint[iCount + 1] = GetCLoseShapeAnglePoint(obj.handlepoint, 0);
    } else {
      obj.handlepoint[iCount] = GetCLoseShapeAnglePoint(obj.handlepoint, 0);
    }
  } else {
    // 没有addstate表示正处于绘制过程中(mousemove, click)
    if (obj.handlepoint[iCount - 1][2] == ANCHORS_TYPE.CONNECTBEGIN) {
      center = GetCenterInTwoPoints(obj.handlepoint[iCount - 1], point);

      // FIXME: 这是一个临时解决方案，用于避免移动设备在控制台出现报错
      // 移动设备上通过click触发，导致结束点和此时的点相同。因此center为NaN
      if (!center.every(n => isNaN(n))) {
        obj.handlepoint[iCount] = [center[0], center[1], ANCHORS_TYPE.CONNECTCANGLE];
        obj.handlepoint[iCount + 1] = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];
      }
    } else {
      center = GetCenterInTwoPoints(obj.handlepoint[iCount - 3], point);
      if (!center.every(n => isNaN(n))) {
        obj.handlepoint[iCount - 2] = [center[0], center[1], ANCHORS_TYPE.CONNECTCANGLE];
        obj.handlepoint[iCount - 1] = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];
      }
    }
  }

  return obj;
}

export function HandlePointSquareCircle(obj) {
  var squareWidth = Math.abs(obj.position.x2 - obj.position.x1);
  var squareHeight = Math.abs(obj.position.y2 - obj.position.y1);
  var lenthMax = squareWidth;
  if (squareHeight > lenthMax) {
    lenthMax = squareHeight;
  }
  if (obj.position.x2 > obj.position.x1) {
    obj.position.x2 = obj.position.x1 + lenthMax;
  } else {
    obj.position.x2 = obj.position.x1 - lenthMax;
  }
  if (obj.position.y2 > obj.position.y1) {
    obj.position.y2 = obj.position.y1 + lenthMax;
  } else {
    obj.position.y2 = obj.position.y1 - lenthMax;
  }
  var angle = [0, 0];
  let position = obj.position;
  if (position.x1 > position.x2) {
    angle = [position.x1 + INFOANCHORSLENGTH, (position.y1 + position.y2) / 2];
  } else {
    angle = [position.x2 + INFOANCHORSLENGTH, (position.y1 + position.y2) / 2];
  }
  obj.handlepoint = [
    [position.x1, position.y1],
    [position.x2, position.y1],
    [position.x2, position.y2],
    [position.x1, position.y2],
    [(position.x1 + position.x2) / 2, position.y1],
    [(position.x1 + position.x2) / 2, position.y2],
    [position.x1, (position.y1 + position.y2) / 2],
    [position.x2, (position.y1 + position.y2) / 2],
    [angle[0], angle[1]],
    [angle[0], position.y1],
    [angle[0], position.y2],
  ];
  return obj;
}

export function HandlePointLine(obj) {
  let position = obj.position;
  obj.handlepoint = [[position.x1, position.y1], [position.x2, position.y2]];
  obj.ptStart = new GeomPoint(position.x1, position.y1);
  obj.ptStop = new GeomPoint(position.x2, position.y2);
  return obj;
}

export function HandlePointArc(obj) {
  let position = obj.position;
  obj.handlepoint = [[position.x1, position.y1], [position.x2, position.y2]];
  var dest = GetSmallCircleCenter(
    obj.handlepoint[0],
    obj.handlepoint[1],
    LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1])
  );
  obj.handlepoint[2] = dest.concat();
  return obj;
}

export function HandlePointRect(obj) {
  const angle = [0, 0];
  const { x1, x2, y1, y2 } = obj.position;
  angle[0] = Math.max(x1, x2) + INFOANCHORSLENGTH;
  angle[1] = (y1 + y2) / 2;

  obj.handlepoint = [
    [x1, y1],
    [x2, y1],
    [x2, y2],
    [x1, y2],
    [(x1 + x2) / 2, y1],
    [(x1 + x2) / 2, y2],
    [x1, (y1 + y2) / 2],
    [x2, (y1 + y2) / 2],
    [angle[0], angle[1]],
    [angle[0], y1],
    [angle[0], y2],
  ];
  return obj;
}

// export function HandlePointCounterTop(obj, point, isStop) {
//   obj.handlepoint[0] = [obj.position.x1, obj.position.y1, ANCHORS_TYPE.CONNECTBEGIN];
//   var lastIndex = obj.handlepoint.length - 1;
//   var pointLast = obj.handlepoint[lastIndex];
//   if (pointLast[2] === ANCHORS_TYPE.CONNECTBEGIN) {
//     obj.handlepoint[1] = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];
//   } else {
//     obj.handlepoint[lastIndex] = [point[0], point[1], ANCHORS_TYPE.CONNECTNORMAL];
//   }
//   if (isStop) {
//     obj.handlepoint.push([point[0], point[1], ANCHORS_TYPE.CLOSEEND]);
//   }

//   return obj;
// }

/**
 *
 * @param {object} obj - objList
 * @param {[number, number]} point
 * @param {1 | 2} [addstate] - 1 添加 2 结束
 * @returns {object} objList
 */
export function HandlePointCounter(obj, addstate) {
  // TODO: 对 objList 直接进行了修改
  var iCount = obj.handlepoint.length;
  let point = [obj.position.x2, obj.position.y2];
  if (addstate == 1) {
    obj.handlepoint[iCount - 3][2] = ANCHORS_TYPE.COUNTERNORMAL;
    obj.handlepoint[iCount] = [
      obj.handlepoint[iCount - 2][0],
      obj.handlepoint[iCount - 2][1],
      ANCHORS_TYPE.COUNTERTHREE,
    ];
    obj.handlepoint[iCount + 1] = [
      obj.handlepoint[iCount - 1][0],
      obj.handlepoint[iCount - 1][1],
      ANCHORS_TYPE.COUNTERTHREE,
    ];
    obj.handlepoint[iCount + 2] = [
      obj.handlepoint[iCount - 3][0],
      obj.handlepoint[iCount - 3][1],
      ANCHORS_TYPE.COUNTERMIDDLE,
    ];
    obj.handlepoint[iCount + 3] = [
      obj.handlepoint[iCount - 2][0],
      obj.handlepoint[iCount - 2][1],
      ANCHORS_TYPE.COUNTERTHREE,
    ];
    obj.handlepoint[iCount + 4] = [
      obj.handlepoint[iCount - 1][0],
      obj.handlepoint[iCount - 1][1],
      ANCHORS_TYPE.COUNTERTHREE,
    ];
    obj.handlepoint[iCount + 5] = [
      obj.handlepoint[iCount - 3][0],
      obj.handlepoint[iCount - 3][1],
      ANCHORS_TYPE.COUNTEREND,
    ];
    obj.handlepoint[iCount + 6] = [
      obj.handlepoint[iCount - 2][0],
      obj.handlepoint[iCount - 2][1],
      ANCHORS_TYPE.COUNTERHELP,
    ];
    obj.handlepoint[iCount + 7] = [
      obj.handlepoint[iCount - 1][0],
      obj.handlepoint[iCount - 1][1],
      ANCHORS_TYPE.COUNTERHELP,
    ];
  } else if (addstate == 2) {
    obj.handlepoint[iCount - 3][2] = ANCHORS_TYPE.COUNTEREND;
    obj.handlepoint[iCount] = GetCLoseShapeAnglePoint(obj.handlepoint, 0);
  } else {
    let origin, angle, width, lengthSpace;
    let tempPoint;
    if (iCount == 1) {
      origin = obj.handlepoint[0];
      angle = getAngleBy2Points(origin, point);
      width = LengthBetweenPoints(origin, point);
      lengthSpace = COUNTER_TOP_PARAM_TYPE.COUNTERTOPSPACE;
      obj.handlepoint[1] = [origin[0], origin[1] - lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      obj.handlepoint[2] = [origin[0], origin[1] + lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      obj.handlepoint[3] = [origin[0] + width / 2, origin[1], ANCHORS_TYPE.COUNTERMIDDLE];
      obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - lengthSpace, ANCHORS_TYPE.COUNTERTHREE];
      obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + lengthSpace, ANCHORS_TYPE.COUNTERTHREE];
      obj.handlepoint[6] = [origin[0] + width, origin[1], ANCHORS_TYPE.COUNTEREND];
      obj.handlepoint[7] = [origin[0] + width, origin[1] - lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      obj.handlepoint[8] = [origin[0] + width, origin[1] + lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      for (let i = 1; i <= 8; i++) {
        tempPoint = RotatePoint(obj.handlepoint[i], angle, origin);
        obj.handlepoint[i][0] = tempPoint[0];
        obj.handlepoint[i][1] = tempPoint[1];
      }
    } else {
      var originIndex = iCount - 11;
      if (originIndex < 0) {
        //可能前一个点是起始点
        originIndex = 0;
      }
      origin = obj.handlepoint[originIndex];
      angle = getAngleBy2Points(origin, point);
      width = LengthBetweenPoints(origin, point);
      lengthSpace = COUNTER_TOP_PARAM_TYPE.COUNTERTOPSPACE;
      obj.handlepoint[iCount - 8] = [origin[0], origin[1] - lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      obj.handlepoint[iCount - 7] = [origin[0], origin[1] + lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      obj.handlepoint[iCount - 6] = [origin[0] + width / 2, origin[1], ANCHORS_TYPE.COUNTERMIDDLE];
      obj.handlepoint[iCount - 5] = [origin[0] + width / 2, origin[1] - lengthSpace, ANCHORS_TYPE.COUNTERTHREE];
      obj.handlepoint[iCount - 4] = [origin[0] + width / 2, origin[1] + lengthSpace, ANCHORS_TYPE.COUNTERTHREE];
      obj.handlepoint[iCount - 3] = [origin[0] + width, origin[1], ANCHORS_TYPE.COUNTEREND];
      obj.handlepoint[iCount - 2] = [origin[0] + width, origin[1] - lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      obj.handlepoint[iCount - 1] = [origin[0] + width, origin[1] + lengthSpace, ANCHORS_TYPE.COUNTERHELP];
      for (let i = iCount - 8; i <= iCount - 1; i++) {
        tempPoint = RotatePoint(obj.handlepoint[i], angle, origin);
        obj.handlepoint[i][0] = tempPoint[0];
        obj.handlepoint[i][1] = tempPoint[1];
      }
    }
  }
  return obj;
}

/**
 *
 * @param {object} obj
 * @param {[number, number]} point
 * @param {1 | 2} [addstate] - 1 添加 2 结束
 * @returns {object}
 */
export function HandlePointStructure(obj, addstate) {
  // TODO: 对 objList 直接进行了修改
  var iCount = obj.handlepoint.length;
  let point = [obj.position.x2, obj.position.y2];

  let circle = [0, 0, 0];
  CopyPoint(circle, obj.handlepoint[iCount - 2]);
  circle[2] = LengthBetweenPoints(circle, point);
  let angle = getAngleBy2Points(circle, point);
  let angle5 = GetAngleArc(5); // 绘制wall时转动的角度
  let pointAngle = Math.round(angle / angle5) * angle5;
  let pointDest = GetCirclePoint(circle, pointAngle);
  CopyPoint(obj.handlepoint[iCount - 1], pointDest);

  const GUIDSPACE = STRUCTURE_PARAM_TYPE.GUIDSPACE; //精度
  let index = iCount - 1;
  let minXindex = -1,
    minXlength = -1;
  let minYindex = -1,
    minYlength = -1;
  let minLineIndex = -1;
  let indexPoint = point;
  let lineindexPoint = point;
  let beginIndex = obj.handlepoint[index - 1][3].index;
  let endIndex = obj.handlepoint[index][3].index;
  for (
    let i = 0;
    i < obj.handlepoint.length - 1;
    i++ //排除旋转点
  ) {
    if (i == index || i == index - 1 || i == beginIndex || i == endIndex) continue;
    let lengthx = Math.abs(obj.handlepoint[i][0] - point[0]);
    let lengthy = Math.abs(obj.handlepoint[i][1] - point[1]);
    if ((minXlength === -1 || lengthx < minXlength) && (minYlength === -1 || lengthy < minYlength)) {
      minXlength = lengthx;
      minYlength = lengthy;
      if (lengthx <= GUIDSPACE && lengthy <= GUIDSPACE) {
        minXindex = i;
        indexPoint[0] = obj.handlepoint[i][0];
        minYindex = i;
        indexPoint[1] = obj.handlepoint[i][1];
      }
    }
  }
  for (
    let i = 0;
    i < obj.handlepoint.length - 1;
    i++ //排除旋转点
  ) {
    if (i == index || i == index - 1) continue;
    if (obj.handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
      let lengthLine = TowPointDestPointLength(obj.handlepoint[i], obj.handlepoint[i + 1], point);
      if (lengthLine <= GUIDSPACE) {
        lineindexPoint = TowPointVerticalAcross(obj.handlepoint[i], obj.handlepoint[i + 1], point);
        let pointRange = GetTowPointRange(obj.handlepoint[i], obj.handlepoint[i + 1]);
        if (
          pointRange[0][0] < lineindexPoint[0] &&
          pointRange[1][0] > lineindexPoint[0] &&
          pointRange[0][1] < lineindexPoint[1] &&
          pointRange[1][1] > lineindexPoint[1]
        ) {
          //线上的点必须在两点的范围内
          minLineIndex = i;
        }
      }
    }
  }
  obj.handlepoint[index][3].nearx = minXindex;
  obj.handlepoint[index][3].neary = minYindex;
  obj.handlepoint[index][3].nearline = minLineIndex;
  if (minXindex != -1) {
    obj.handlepoint[index][0] = indexPoint[0];
  }
  if (minYindex != -1) {
    obj.handlepoint[index][1] = indexPoint[1];
  }
  if (minLineIndex != -1) {
    CopyPoint(obj.handlepoint[index], lineindexPoint);
  }

  if (addstate == 1) {
    obj.handlepoint[iCount] = [pointDest[0], pointDest[1], ANCHORS_TYPE.STRUCTUREBEGIN, AddStructureData()];
    obj.handlepoint[iCount][3].index = iCount - 1;
    obj.handlepoint[iCount - 1][3] = AddStructureData();
    obj.handlepoint[iCount - 1][3].index = iCount;
    obj.handlepoint[iCount - 2][3].nearline = -1;
    obj.handlepoint[iCount - 2][3].nearx = -1;
    obj.handlepoint[iCount - 2][3].neary = -1;
    obj.handlepoint[iCount + 1] = [pointDest[0], pointDest[1], ANCHORS_TYPE.STRUCTUREEND, AddStructureData()];
  } else if (addstate == 2) {
    if (iCount < 6) {
      obj.handlepoint = [];
    } else {
      obj.handlepoint[iCount - 3][3].index = -1;
      obj.handlepoint[iCount - 2] = GetClosedShapeAnglePoint(obj.handlepoint, 0);
      obj.handlepoint.splice(iCount - 1, 1);

      setInitialAngularDeviationOfStructure(obj);

      obj.marks.islength = false;
    }
  }
  return obj;
}

/**
 * @description Roundabout 是一个四合一的封闭 StreetNew
 * @param {object} obj
 * @returns {object}
 */
export function HandlePointRoundabout(obj) {
  const { x1, y1, x2, y2 } = obj.position;
  const ptStart = new GeomPoint(x1, y1);
  const ptStop = new GeomPoint(x2, y2);
  const distance = ptStart.distance(ptStop);
  const r = distance / 2;

  // 更新每条StreetNew的半径
  for (let i = 0; i < obj.segments.length; i++) {
    obj.segments[i].r = r;
  }

  // 与起始点以圆心为轴对称的点，始终为绘制结束点
  obj.segments[1].ptStop = { x: x2, y: y2 };
  obj.segments[2].ptStart = { x: x2, y: y2 };

  const ptArc1 = new GeomArc(ptStart, ptStop, r, false, false);
  const ptMid1 = ptArc1.getMidPoint();
  obj.segments[0].ptStop = { x: ptMid1.x, y: ptMid1.y };
  obj.segments[1].ptStart = { x: ptMid1.x, y: ptMid1.y };
  const ptArc2 = new GeomArc(ptStart, ptStop, r, false, true);
  const ptMid2 = ptArc2.getMidPoint();
  obj.segments[2].ptStop = { x: ptMid2.x, y: ptMid2.y };
  obj.segments[3].ptStart = { x: ptMid2.x, y: ptMid2.y };

  // workData.setObj(obj.operateid, obj);
  // this.setAncyObjList();
  let _s = new _Street();
  _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);

  return obj;
}

export function HandlePointStraight(obj) {
  const position = obj.position;
  obj.segments[obj.segments.length - 1].ptStop = { x: position.x2, y: position.y2 };

  // workData.setObj(obj.operateid, obj);
  // this.setAncyObjList();
  let _s = new _Street();
  _s.alignComponentsToStreetAxis(obj);
  _s.computeStreets(obj);

  return obj;
}

/**
 * A helper function to render color point on diagram. Just used in development
 * @param {*} pt
 * @param {*} fill
 */
function renderHelperPoint(pt, fill) {
  const viewport = document.querySelector('#viewport');
  const circle = `<circle cx="${pt.x}" cy="${pt.y}" r="5" fill-opaicty="0.7" fill="${fill}" style="pointer-events: none;"></circle>`;
  viewport.innerHTML += circle;
}

let trackPoints = [];
export function clearTrackPoints() {
  trackPoints = [];
}
/**
 *
 * @param {[number, number]} point
 * @param {number[]} curvedBuffer
 * @typedef {object} segment
 * @property {'line' | 'arc'} type
 * @property {{x:number, y:number}} ptStart
 * @property {{x:number, y:number}} ptStop
 * @returns {segment[]}
 */
export function HandlePointCurved(point, curvedBuffer) {
  const [x, y] = point;
  // segment 的长度
  const threshold = STREET_LEN / 2;
  let lastSegment = curvedBuffer[curvedBuffer.length - 1];
  let lastStartPt = new GeomPoint(lastSegment.ptStart.x, lastSegment.ptStart.y);
  let currentPt = new GeomPoint(x, y);

  // 记录一段 segment 的轨迹点
  trackPoints.push(currentPt);

  let center = trackPoints[Math.floor(trackPoints.length / 2)];
  let centerPt = new GeomPoint(center.x, center.y);
  let radian = lastStartPt.radianBetweenTwoPoints(centerPt, currentPt);
  // let radian = currentPt.radianBetweenTwoPoints(centerPt, lastStartPt);
  let r = 0,
    sweepFlag;

  const generateSegment = pt => {
    let segment;
    if (r > diagramManager.getUserUnitsPerPixel() * 1) {
      segment = createArc({
        ptStart: { x: pt.x, y: pt.y },
        ptStop: { x: currentPt.x, y: currentPt.y },
        r,
        largeArcFlag: false,
        sweepFlag,
      });
    } else {
      segment = createLine({
        ptStart: { x: pt.x, y: pt.y },
        ptStop: { x: currentPt.x, y: currentPt.y },
      });
    }
    return segment;
  };

  // 超过这个角度范围为直线路
  const minAngle = 3,
    maxAngle = 12;
  const mathUtil = new UtilityMath();
  if (radian > mathUtil.toRadians(minAngle)) {
    if (radian < mathUtil.toRadians(maxAngle)) {
      r = threshold * (Math.PI - radian);
    } else {
      // 防止拐角过小，导致计算的半径太小，弧长太大
      r = threshold;

      // console.log(currentPt, lastStartPt);
      // console.log(curvedBuffer);
      // console.log(r);
      // renderHelperPoint(currentPt, '#f00');
      // renderHelperPoint(centerPt, '#00f');
      // let segment = generateSegment(lastSegment.ptStop);
      // console.log(segment);
    }

    let vec1 = lastStartPt.getVector(currentPt);
    let vec2 = lastStartPt.getVector(centerPt);
    sweepFlag = vec1.cross(vec2) > 0 ? false : true;
    // console.log('angle', angle);
    // console.log('r', r);
    // console.log(currentPt, pt);
    // console.log('trackPoints', trackPoints);
  }

  if (lastStartPt.distance(currentPt) > threshold) {
    // 距离大于阈值时，创建新 segment
    let segment = generateSegment(lastSegment.ptStop);
    // renderHelperPoint(segment.ptStart, '#f00');
    // renderHelperPoint(segment.ptStop, '#00f');
    curvedBuffer.push(segment);
    trackPoints = [];
  } else {
    // 绘制过程中，实时更新 segment 的类型
    // renderHelperPoint(center, '#f00');
    curvedBuffer[curvedBuffer.length - 1] = generateSegment(lastSegment.ptStart);
  }

  return curvedBuffer;
}

export function HandlePointStreet(obj) {
  let x1 = obj.position.x1,
    y1 = obj.position.y1,
    x2 = obj.position.x2,
    y2 = obj.position.y2;
  let angle = getAngleBy2Points([x1, y1], [x2, y2]);
  let width = LengthBetweenPoints([x1, y1], [x2, y2]);
  let handlepoint = [];
  handlepoint[0] = [x1, y1];
  handlepoint[1] = [x1 + width / 2, y1];
  handlepoint[2] = [x1 + width, y1];
  handlepoint[3] = [x1 - STREET_SPACE.SIDEANCHROSSPACE * getUserUnitByPixel(), y1];
  handlepoint[4] = [x1 + width + STREET_SPACE.SIDEANCHROSSPACE * getUserUnitByPixel(), y1];
  for (let i in handlepoint) {
    handlepoint[i] = RotatePoint(handlepoint[i], angle, handlepoint[0]);
  }
  handlepoint[0][2] = STREET_POINT_TYPE.NORMAL;
  handlepoint[0][3] = AddStreetData(-1, 1);
  handlepoint[0][3].rightindex = 1;
  handlepoint[1][2] = STREET_POINT_TYPE.CENTER;
  handlepoint[1][3] = AddStreetData(0, 2);
  handlepoint[2][2] = STREET_POINT_TYPE.NORMAL;
  handlepoint[2][3] = AddStreetData(1, -1);
  handlepoint[2][3].leftIndex = 1;
  //2个车道 3条LaneLine
  obj.groupdata[0] = AddStreetLineData();
  obj.groupdata[0].strokedasharray = DASH_ARRAY_TYPE.solid;
  obj.groupdata[0].topline = -1; //表示最上面线
  obj.groupdata[0].bottomline = 2;
  obj.groupdata[1] = AddStreetLineData();
  obj.groupdata[1].strokedasharray = DASH_ARRAY_TYPE.solid;
  obj.groupdata[1].topline = 2;
  obj.groupdata[1].bottomline = -1; //表示最下面线
  obj.groupdata[2] = AddStreetLineData();
  obj.groupdata[2].bottomline = 1;
  obj.groupdata[2].topline = 0;
  obj.handlepoint = handlepoint;
  obj.streetacrossdata = [[], [], [], []];
  obj.streetdividetype = STREET_DIVIDER_TYPE.NONE;
  obj.isstreetacross = false;
  for (let idx = 0; idx < obj.groupdata.length; idx++) {
    obj.groupdata[idx].strokewidth = 4;
  }
  return obj;
}

//根据street lanes和width修改道路 增加普通车道
export function HandlePointStreetByNumWidth(obj) {
  //道路的两边 沿线比 道路多一个数据
  let lanes = obj.groupdata.length;
  if (lanes > obj.lanes.length + 1) {
    let lastLine = obj.groupdata[lanes - 1];
    obj.groupdata[lastLine.bottomline].topline = lastLine.topline;
    obj.groupdata[lastLine.topline].bottomline = lastLine.bottomline;
    obj.groupdata.splice(lanes - 1, 1);
  } else if (lanes <= obj.lanes.length) {
    let lastLine = obj.groupdata[lanes - 1];
    let newLine = AddStreetLineData();
    newLine.stroke = obj.style.stroke;
    newLine.topline = lanes - 1;
    newLine.bottomline = lastLine.bottomline;
    obj.groupdata.push(newLine);
    obj.groupdata[newLine.bottomline].topline = lanes;
    lastLine.bottomline = lanes;
  }
  return obj;
}

// TODO: may move as constant
// export const LANE_ACTION = {
//   ADD_VEHICLE_LANE: 0,
//   ADD_BIKE_LANE: 1,
//   ADD_SHOULDERS: 2,
//   ADD_SHOULDER: 3,

// }

/**
 *
 * @param {object} param0
 * @param {string} param0.streetId
 * @param {number} param0.stripeIndex - stripe 索引值
 * @param {string} param0.name
 * @param {[number, number] | undefined} param0.position
 */
export function addStreetLane({ streetId, stripeIndex, name, position }) {
  const typeIndex = SymbolKeyNames.laneShoulderNames.indexOf(name);
  let obj = workData.getObject(streetId);
  const index = stripeIndex === undefined ? new StreetService(obj).getStripeIndex(position) : stripeIndex;

  if (obj) {
    // TODO: define name constants instead of using index, consider use TS enum
    switch (typeIndex) {
      // add a lane
      case 0: {
        obj = AddStreetLine(obj, index);
        break;
      }
      case 1: {
        // add a bike lane
        obj = AddStreetBikeLine(obj, index);
        break;
      }
      case 2: {
        // add shoulders
        let beiginLine = GetStreetLineBegin(obj.groupdata);
        AddStreetBikeLine(obj, beiginLine);
        AddStreetBikeLine(obj, 1);
        break;
      }
      case 3: {
        // add shoulder
        obj = AddStreetShoulderLine(obj, index);
        break;
      }
      case 4: {
        // 2 Way Left Turn Lane
        obj = AddStreet2WayLine(obj, index);
        break;
      }
      case 5: {
        // Painted Divider
        obj = AddStreetPaintedLine(obj, index);
        break;
      }
      case 6: {
        // Wide Divider
        obj = AddStreetWideLine(obj, index);
        break;
      }
      case 8: {
        // Remove
        // obj.shapename = '';
        obj = RemoveStreetLine(obj, index);
        break;
      }
    }
    // TODO: maybe no longer used?
    if ([4, 5, 6, 8].indexOf(typeIndex) > -1) {
      obj.idSelectedComponent = -1;
    }
    new _Street().computeStreets(obj);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  }
}

export function addSplitPattern({ streetId, stripeIndex, point }) {
  const obj = workData.getObject(streetId);
  const fs = new FreeStripe();
  fs.splitPatternAtPoint(obj.components[stripeIndex], point ? new GeomPoint(...point) : null);
  emitter.emit(EVENT_EMIT_TYPE.SLIDERSHAPEFUNC, 'refresh');
}

export function setStripeStyle({ streetId, stripeIndex, stripeType, point }) {
  let obj = workData.getObject(streetId);
  if (obj) {
    const { pattern } = stripeStyles.find(n => n.type === stripeType);
    if (obj.functype === FUNCTION_TYPE.STREETNEW) {
      const stripe = obj.components[stripeIndex];
      if (!point) {
        FreeStripe.setPattern(stripe, pattern);
      } else {
        let fs = new FreeStripe();
        fs.setPatternAtPoint(stripe, pattern, new GeomPoint(...clientPtToUserPt(...point)));
      }
    } else {
      obj.groupdata[stripeIndex].stripetype = stripeType;
    }
    emitter.emit(EVENT_EMIT_TYPE.SLIDERSHAPEFUNC, 'refresh');
  }
}

/**
 * add a line/lane to a street
 * @param {any} obj the street object/operation
 * @param {number} index stripe index
 */
export function AddStreetLine(obj, index) {
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    let insIdx;
    const ss = new StreetService(obj);
    const dividerIdx = ss.dividerIndex;
    if (dividerIdx > -1 && index === dividerIdx + 1) {
      // there's divider
      insIdx = dividerIdx + 2;
    } else {
      insIdx = Math.max(index - 1, 1);
    }
    obj.components.splice(
      insIdx,
      0,
      createLane({ key: obj.nextKey, laneType: LANE_TYPE.VEHICLE_LANE, width: obj.width }),
      createStripe(obj.nextKey + 1, obj.segments, 'singledash')
    );
    obj.nextKey += 2;
    new _Street().alignComponentsToStreetAxis(obj);
  } else {
    //道路的两边 沿线比 道路多一个数据
    obj.lanes.push({ width: LANE_WIDTH, travelDir: 'normal' });
    let iCount = obj.groupdata.length;
    let indexLine = obj.groupdata[index];
    let newLine = AddStreetLineData();
    newLine.stroke = obj.style.stroke;
    newLine.strokedasharray = DASH_ARRAY_TYPE.solid;
    newLine.streetlinetype = STREET_LINE_TYPE.NONE;
    newLine.topline = indexLine.topline;
    newLine.bottomline = index;
    if (indexLine.topline >= 0) obj.groupdata[indexLine.topline].bottomline = iCount;
    indexLine.topline = iCount;
    newLine.stripetype = STREET_STRIPES_TYPE.dash;
    obj.groupdata.push(newLine);
  }
  return obj;
}

/**
 * Bike Line
 * @param {*} obj
 * @param {number} index
 */
export function AddStreetBikeLine(obj, index) {
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    obj.components[index].primary.patterns[0].pattern = 'singlesolid';
    if (index == 0) {
      obj.components.splice(
        1,
        0,
        createLane({ key: obj.nextKey, laneType: LANE_TYPE.BIKE_LANE }),
        createStripe(obj.nextKey + 1, obj.segments)
      );
    } else {
      obj.components.splice(index, 1, createStripe(obj.nextKey, obj.segments));
      obj.nextKey = obj.nextKey + 2;
      obj.components.splice(
        index,
        0,
        createStripe(obj.nextKey, obj.segments),
        createLane({ key: obj.nextKey + 1, laneType: LANE_TYPE.BIKE_LANE })
      );
    }
    obj.nextKey = obj.nextKey + 2;
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
  } else {
    obj.lanes.push({ width: 48, travelDir: 'normal' });

    let iCount = obj.groupdata.length;
    let indexLine = obj.groupdata[index];
    let newLine = AddStreetLineData();

    newLine.stroke = obj.style.stroke;
    newLine.strokedasharray = DASH_ARRAY_TYPE.solid;
    newLine.streetlinetype = STREET_LINE_TYPE.NONE;
    newLine.topline = indexLine.topline;
    newLine.bottomline = index;
    if (indexLine.topline >= 0) obj.groupdata[indexLine.topline].bottomline = iCount;
    indexLine.topline = iCount;
    indexLine.stripetype = STREET_STRIPES_TYPE.solid;
    newLine.stripetype = STREET_STRIPES_TYPE.solid;
    obj.groupdata.push(newLine);
  }
  return obj;
}

/**
 * Shoulder
 * @param {*} obj
 * @param {number} index
 */
export function AddStreetShoulderLine(obj, index) {
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    // 计算初始、终止位置
    let begin, end;
    if (index > obj.components.length / 2) {
      begin = Math.ceil(obj.components.length / 2);
      end = obj.components.length;
    } else {
      begin = 0;
      end = Math.floor(obj.components.length / 2);
    }

    // 当一侧已经存在shoulder时，直接返回结果
    for (let i = begin; i < end; i++) {
      let component = obj.components[i];
      if (component.type && component.type === 'shoulder') return obj;
    }

    // 一侧任意stripe添加shoulder，将添加到street两侧
    if (index < obj.components.length / 2) {
      obj.components.splice(1, 0, createShoulder({ key: obj.nextKey }), createStripe(obj.nextKey + 1, obj.segments));
    } else {
      obj.components.splice(
        obj.components.length - 1,
        0,
        createStripe(obj.nextKey, obj.segments),
        createShoulder({ key: obj.nextKey + 1 })
      );
    }
    obj.nextKey = obj.nextKey + 2;
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
  } else {
    obj.lanes.push({ width: 48, travelDir: 'normal' });

    let iCount = obj.groupdata.length;
    let indexLine = obj.groupdata[index];
    let newLine = AddStreetLineData();

    newLine.stroke = obj.style.stroke;
    newLine.strokedasharray = DASH_ARRAY_TYPE.solid;
    newLine.streetlinetype = STREET_LINE_TYPE.NONE;
    newLine.topline = indexLine.topline;
    newLine.bottomline = index;
    if (indexLine.topline >= 0) obj.groupdata[indexLine.topline].bottomline = iCount;
    indexLine.topline = iCount;
    indexLine.stripetype = STREET_STRIPES_TYPE.solid;
    newLine.stripetype = STREET_STRIPES_TYPE.solid;
    obj.groupdata.push(newLine);
  }
  return obj;
}

/**
 * add 2 Way Line
 * @param {*} obj
 * @param {number} index
 * @return {*} obj
 */
export function AddStreet2WayLine(obj, index) {
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    if (index == 0) {
      obj.components.splice(
        1,
        0,
        { type: 'divider', key: obj.nextKey.toString(), width: obj.lanewidth },
        {
          type: 'stripe',
          key: (obj.nextKey + 1).toString(),
          segments: obj.segments,
          primary: { patterns: [{ pattern: 'singlesolid', startPct: 0 }] },
        }
      );
    } else {
      var s = new _Street(obj.components);
      var dividerIndex = s._getDividerIndex();
      if (dividerIndex != -1) {
        dividerIndex = dividerIndex - 1;
        obj.components.splice(dividerIndex, 2);
      } else {
        dividerIndex = index;
      }
      obj.components[dividerIndex].primary.patterns[0].pattern = 'dashsolid';
      obj.components.splice(
        dividerIndex,
        0,
        {
          type: 'stripe',
          key: obj.nextKey.toString(),
          segments: obj.segments,
          primary: { patterns: [{ pattern: 'soliddash', startPct: 0 }] },
        },
        { type: 'divider', key: (obj.nextKey + 1).toString(), divider: 2, width: obj.lanewidth }
      );
    }
    obj.nextKey = (obj.nextKey + 2).toString();
    obj.streetdividetype = STREET_DIVIDER_TYPE.TWO_WAY;
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    return obj;
  }
  obj = ReplaceStreetNoneLine(obj);
  let iCount = obj.groupdata.length;
  obj.groupdata[index].streetlinetype = STREET_LINE_TYPE.TWOWAY;
  obj.groupdata[index].specialtop = iCount;
  obj.groupdata[index].specialbottom = iCount + 1;
  let newLineTop = AddStreetLineData();
  newLineTop.stroke = obj.style.stroke;
  newLineTop.stripetype = STREET_STRIPES_TYPE.soliddash;
  let newLineBottom = AddStreetLineData();
  newLineBottom.stroke = obj.style.stroke;
  newLineBottom.stripetype = STREET_STRIPES_TYPE.dashsolid;
  obj.groupdata.push(newLineTop);
  obj.groupdata.push(newLineBottom);
  obj.streetdividetype = STREET_DIVIDER_TYPE.TWO_WAY;
  return obj;
}

export function AddStreetWideLine(obj, index) {
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    if (index == 0) {
      obj.components.splice(
        1,
        0,
        { type: 'divider', key: obj.nextKey.toString(), width: STREET_DEFAULT_SIZE.LANE_WIDTH * 2 },
        {
          type: 'stripe',
          key: (obj.nextKey + 1).toString(),
          segments: obj.segments,
          primary: { patterns: [{ pattern: 'singlesolid', startPct: 0 }] },
        }
      );
    } else {
      var s = new _Street(obj.components);
      var dividerIndex = s._getDividerIndex();
      if (dividerIndex != -1) {
        dividerIndex = dividerIndex - 1;
        obj.components.splice(dividerIndex, 2);
      } else {
        dividerIndex = index;
      }
      obj.components[dividerIndex].primary.patterns[0].pattern = 'singlesolid';
      obj.components.splice(
        dividerIndex,
        0,
        {
          type: 'stripe',
          key: obj.nextKey.toString(),
          segments: obj.segments,
          primary: { patterns: [{ pattern: 'singlesolid', startPct: 0 }] },
        },
        { type: 'divider', key: (obj.nextKey + 1).toString(), divider: 1, width: STREET_DEFAULT_SIZE.LANE_WIDTH * 2 }
      );
    }
    obj.nextKey = (obj.nextKey + 2).toString();
    obj.streetdividetype = STREET_DIVIDER_TYPE.WIDE;
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    return obj;
  }
  obj.lanes.push({ width: STREET_DEFAULT_SIZE.LANE_WIDTH * 2, travelDir: 'normal' });
  let iCount = obj.groupdata.length;
  let indexLine = obj.groupdata[index];
  let newLine = AddStreetLineData();
  newLine.stroke = obj.style.stroke;
  newLine.strokedasharray = DASH_ARRAY_TYPE.solid;
  newLine.streetlinetype = STREET_LINE_TYPE.NONE;
  newLine.topline = indexLine.topline;
  newLine.bottomline = index;
  if (indexLine.topline >= 0) obj.groupdata[indexLine.topline].bottomline = iCount;
  indexLine.topline = iCount;
  newLine.stripetype = STREET_STRIPES_TYPE.solid;
  obj.groupdata.push(newLine);
  return obj;
}

export function AddStreetPaintedLine(obj, index) {
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    if (index == 0) {
      obj.components.splice(
        1,
        0,
        { type: 'divider', key: obj.nextKey.toString(), divider: 3, width: 48 },
        {
          type: 'stripe',
          key: (obj.nextKey + 1).toString(),
          segments: obj.segments,
          primary: { patterns: [{ pattern: 'singlesolid', startPct: 0 }] },
        }
      );
    } else {
      var s = new _Street(obj.components);
      var dividerIndex = s._getDividerIndex();
      if (dividerIndex != -1) {
        dividerIndex = dividerIndex - 1;
        obj.components.splice(dividerIndex, 2);
      } else {
        dividerIndex = index;
      }
      obj.components[dividerIndex].primary.patterns[0].pattern = 'doublesolid';
      obj.components.splice(
        dividerIndex,
        0,
        {
          type: 'stripe',
          key: obj.nextKey.toString(),
          segments: obj.segments,
          primary: { patterns: [{ pattern: 'doublesolid', startPct: 0 }] },
        },
        { type: 'divider', key: (obj.nextKey + 1).toString(), width: 48 }
      );
    }
    obj.nextKey = (obj.nextKey + 2).toString();
    obj.streetdividetype = STREET_DIVIDER_TYPE.PAINTED;
    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
    return obj;
  }
  obj = ReplaceStreetNoneLine(obj);
  let iCount = obj.groupdata.length;
  obj.groupdata[index].streetlinetype = STREET_LINE_TYPE.PAINTED;
  obj.groupdata[index].specialtop = iCount;
  obj.groupdata[index].specialbottom = iCount + 1;
  let newLineTop = AddStreetLineData();
  newLineTop.stroke = obj.style.stroke;
  newLineTop.stripetype = STREET_STRIPES_TYPE.doublesolid;
  let newLineBottom = AddStreetLineData();
  newLineBottom.stroke = obj.style.stroke;
  newLineBottom.stripetype = STREET_STRIPES_TYPE.doublesolid;
  obj.groupdata.push(newLineTop);
  obj.groupdata.push(newLineBottom);
  obj.streetdividetype = STREET_DIVIDER_TYPE.PAINTED;
  return obj;
}

export function ReplaceStreetNoneLine(obj) {
  for (let i = 0; i < obj.groupdata.length; i++) {
    if (
      obj.groupdata[i].streetlinetype === STREET_LINE_TYPE.WIDE ||
      obj.groupdata[i].streetlinetype === STREET_LINE_TYPE.TWOWAY ||
      obj.groupdata[i].streetlinetype === STREET_LINE_TYPE.PAINTED ||
      obj.groupdata[i].streetlinetype === STREET_LINE_TYPE.GRASS ||
      obj.groupdata[i].streetlinetype === STREET_LINE_TYPE.CEMENT
    ) {
      obj.groupdata[i].streetlinetype = STREET_LINE_TYPE.NONE;
      // obj.groupdata[i].specialtop = -5;
      // obj.groupdata[i].specialbottom = -5;
    }
  }
  obj.streetdividetype = STREET_DIVIDER_TYPE.NONE;
  return obj;
}

/**
 *
 * @param {*} obj
 * @param {number} index
 */
export function RemoveStreetLine(obj, index) {
  // FIXME: The logic not clearly, try to add comment or refactor code
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    if (obj.components.some(o => o.type === 'shoulder')) {
      if (index == 2) {
        obj.components.splice(index - 2, 2);
      } else if (index == obj.components.length - 1) {
        obj.components.splice(index - 2, 2);
      } else {
        obj.components.splice(index, 2);
      }
    } else {
      if (index == 2) {
        obj.components.splice(1, 2);
      } else {
        obj.components.splice(index, 2);
      }
    }

    let _s = new _Street();
    let lw = _s.alignComponentsToStreetAxis(obj);
  } else {
    let indexLine = obj.groupdata[index];
    obj.groupdata[indexLine.bottomline].topline = indexLine.topline;
    obj.groupdata[indexLine.topline].bottomline = indexLine.bottomline;
    indexLine.bottomline = -5;
    indexLine.topline = -5;
    obj.lanes.pop();
  }
  return obj;
}

/**
 * cancel the street line selection
 * @param {*} obj
 */
export function SetStreetLinesNoSelect(obj) {
  if (obj && obj.groupdata) {
    for (let i = 0; i < obj.groupdata.length; i++) {
      obj.groupdata[i].selectline = false;
    }
  }
}

export function HandlePointRange(objlist) {
  var xMin = Infinity,
    xMax = -Infinity,
    yMin = Infinity,
    yMax = -Infinity,
    x,
    y;
  for (let i = 0; i < objlist.length; i++) {
    let handlePoint = objlist[i].handlepoint.concat();
    for (let j = 0; j < handlePoint.length; j++) {
      x = handlePoint[j][0];
      y = handlePoint[j][1];

      if (x < xMin) {
        xMin = x;
      }
      if (x > xMax) {
        xMax = x;
      }
      if (y < yMin) {
        yMin = y;
      }
      if (y > yMax) {
        yMax = y;
      }
    }
  }
  return [[xMin, yMax], [xMax, yMin]];
}

function getPointByPosition(handlepoint, position) {
  var leftPoint = handlepoint[0],
    rightPoint = handlepoint[0],
    topPoint = handlepoint[0],
    bottomPoint = handlepoint[0];
  for (let i = 0; i < handlepoint.length; i++) {
    if (handlepoint[i][0] < leftPoint[0]) {
      leftPoint = [handlepoint[i][0], handlepoint[i][1]];
    }
    if (handlepoint[i][0] > rightPoint[0]) {
      rightPoint = [handlepoint[i][0], handlepoint[i][1]];
    }
    if (handlepoint[i][1] < bottomPoint[1]) {
      bottomPoint = [handlepoint[i][0], handlepoint[i][1]];
    }
    if (handlepoint[i][1] > topPoint[1]) {
      topPoint = [handlepoint[i][0], handlepoint[i][1]];
    }
  }
  if (position == 'left') {
    // console.log(leftPoint);
    return leftPoint;
  }
  if (position == 'right') {
    return rightPoint;
  }
  if (position == 'top') {
    return topPoint;
  }
  if (position == 'bottom') {
    return bottomPoint;
  }
}

export function AlignLeft() {
  var selectUserData = workData.getSelectObjects();
  var pointRange = HandlePointRange(selectUserData);
  for (let i = 0; i < selectUserData.length; i++) {
    var leftPoint = getPointByPosition(selectUserData[i].handlepoint, 'left');
    selectUserData[i].handlepoint = TransformMoveObject(selectUserData[i].handlepoint, leftPoint, [
      pointRange[0][0],
      leftPoint[1],
    ]);
    workData.setObj(selectUserData[i].operateid, selectUserData[i]);
  }
}

export function AlignRight() {
  var selectUserData = workData.getSelectObjects();
  var pointRange = HandlePointRange(selectUserData);
  for (let i = 0; i < selectUserData.length; i++) {
    var rightPoint = getPointByPosition(selectUserData[i].handlepoint, 'right');
    selectUserData[i].handlepoint = TransformMoveObject(selectUserData[i].handlepoint, rightPoint, [
      pointRange[1][0],
      rightPoint[1],
    ]);
    workData.setObj(selectUserData[i].operateid, selectUserData[i]);
  }
}

export function AlignCenter() {
  var selectUserData = workData.getSelectObjects();
  var pointRange = HandlePointRange(selectUserData);
  var centerRange = GetCenterInTwoPoints(pointRange[0], pointRange[1]);
  for (let i = 0; i < selectUserData.length; i++) {
    var rightPoint = getPointByPosition(selectUserData[i].handlepoint, 'right');
    var leftPoint = getPointByPosition(selectUserData[i].handlepoint, 'left');
    var center = GetCenterInTwoPoints(leftPoint, rightPoint);
    selectUserData[i].handlepoint = TransformMoveObject(selectUserData[i].handlepoint, center, [
      centerRange[0],
      center[1],
    ]);
    workData.setObj(selectUserData[i].operateid, selectUserData[i]);
  }
}

export function AlignTop() {
  var selectUserData = workData.getSelectObjects();
  var pointRange = HandlePointRange(selectUserData);
  for (let i = 0; i < selectUserData.length; i++) {
    var topPoint = getPointByPosition(selectUserData[i].handlepoint, 'top');
    selectUserData[i].handlepoint = TransformMoveObject(selectUserData[i].handlepoint, topPoint, [
      topPoint[0],
      pointRange[1][1],
    ]);
    workData.setObj(selectUserData[i].operateid, selectUserData[i]);
  }
}

export function AlignBottom() {
  var selectUserData = workData.getSelectObjects();
  var pointRange = HandlePointRange(selectUserData);
  for (let i = 0; i < selectUserData.length; i++) {
    var bottomPoint = getPointByPosition(selectUserData[i].handlepoint, 'bottom');
    selectUserData[i].handlepoint = TransformMoveObject(selectUserData[i].handlepoint, bottomPoint, [
      bottomPoint[0],
      pointRange[0][1],
    ]);
    workData.setObj(selectUserData[i].operateid, selectUserData[i]);
  }
}

export function AlignMiddle() {
  var selectUserData = workData.getSelectObjects();
  var pointRange = HandlePointRange(selectUserData);
  var centerRange = GetCenterInTwoPoints(pointRange[0], pointRange[1]);
  for (let i = 0; i < selectUserData.length; i++) {
    var rightPoint = getPointByPosition(selectUserData[i].handlepoint, 'right');
    var leftPoint = getPointByPosition(selectUserData[i].handlepoint, 'left');
    var center = GetCenterInTwoPoints(leftPoint, rightPoint);
    selectUserData[i].handlepoint = TransformMoveObject(selectUserData[i].handlepoint, center, [
      center[0],
      centerRange[1],
    ]);
    workData.setObj(selectUserData[i].operateid, selectUserData[i]);
  }
}

export function HandlePointStairs(obj) {
  var x1 = obj.position.x1,
    y1 = obj.position.y1,
    x2 = obj.position.x2,
    y2 = obj.position.y2;
  let origin = obj.handlepoint[0];
  let width = LengthBetweenPoints(obj.handlepoint[0], [x2, y2]);
  let height = STAIR_SPACE.HEIGHT;
  obj.handlepoint[1] = [origin[0], origin[1] - height];
  obj.handlepoint[2] = [origin[0], origin[1] + height];
  obj.handlepoint[3] = [origin[0] + width / 2, origin[1]];
  obj.handlepoint[4] = [origin[0] + width / 2, origin[1] - height];
  obj.handlepoint[5] = [origin[0] + width / 2, origin[1] + height];
  obj.handlepoint[6] = [origin[0] + width, origin[1]];
  obj.handlepoint[7] = [origin[0] + width, origin[1] - height];
  obj.handlepoint[8] = [origin[0] + width, origin[1] + height];
  var angle = getAngleBy2Points(obj.handlepoint[0], [x2, y2]);
  for (let i = 0; i < obj.handlepoint.length; i++) {
    obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
  }
  return obj;
}

/**
 *
 * @param {*} obj The crosswalk work data
 * @param {*} height Half of the width of the crosswalk
 * @returns Modified crosswalk work data
 */
export function HandlePointCrosswalk(obj, height) {
  height = height || CROSS_WALK_SPACE.HEIGHT;
  let { x1, y1, x2, y2 } = obj.position;
  // left-middle
  obj.handlepoint[0] = [x1, y1];
  let origin = obj.handlepoint[0];
  // The length of the crosswalk
  let length = LengthBetweenPoints(obj.handlepoint[0], [x2, y2]);
  obj.handlepoint[1] = [origin[0], origin[1] - height];
  obj.handlepoint[2] = [origin[0], origin[1] + height];
  obj.handlepoint[3] = [origin[0] + length / 2, origin[1]];
  obj.handlepoint[4] = [origin[0] + length / 2, origin[1] - height];
  obj.handlepoint[5] = [origin[0] + length / 2, origin[1] + height];
  obj.handlepoint[6] = [origin[0] + length, origin[1]];
  obj.handlepoint[7] = [origin[0] + length, origin[1] - height];
  obj.handlepoint[8] = [origin[0] + length, origin[1] + height];
  var angle = getAngleBy2Points(obj.handlepoint[0], [x2, y2]);
  for (let i = 0; i < obj.handlepoint.length; i++) {
    obj.handlepoint[i] = RotatePoint(obj.handlepoint[i], angle, origin);
  }
  return obj;
}

export function HandlePointStructureSelect(obj, index) {
  let handlepoint = [...obj.handlepoint];
  /** 取消所有 wall 的选中状态 */
  handlepoint = handlepoint.map(point => {
    if (point[3] && point[3].selectflag) {
      point[3].selectflag = false;
    }
    return point;
  });
  /** 选中当前点击的 wall */
  handlepoint = [
    ...handlepoint.slice(0, index),
    [
      handlepoint[index][0],
      handlepoint[index][1],
      handlepoint[index][2],
      {
        ...handlepoint[index][3],
        nearline: -1,
        nearx: -1,
        neary: -1,
        selectflag: !handlepoint[index][3].selectflag,
      },
    ],
    ...handlepoint.slice(index + 1, handlepoint.length),
  ];
  obj.handlepoint = handlepoint;
  return obj;
}

export function HandlePointParkStall(obj) {
  obj.handlepoint = [
    [obj.position.x1, obj.position.y1],
    [obj.position.x2, obj.position.y2],
    [(obj.position.x1 + obj.position.x2) / 2, (obj.position.y1 + obj.position.y2) / 2],
    //[x1, y1 - STAIR_SPACE.HEIGHT],
    //[x2, y2 - STAIR_SPACE.HEIGHT],
    //[x2, y2 + STAIR_SPACE.HEIGHT],
    //[x1, y1 + STAIR_SPACE.HEIGHT],
  ];
  return obj;
}

export function HandlePointStreetAcross(obj) {
  obj.handlepoint = [[], [], [], []];
  return obj;
}

export function HandlePointMeasurement(obj) {
  obj.handlepoint = [[obj.position.x1, obj.position.y1]];
  return obj;
}

export function HandlePointStripe(obj) {
  obj.handlepoint = [
    [obj.position.x1, obj.position.y1],
    [obj.position.x2, obj.position.y2],
    [(obj.position.x1 + obj.position.x2) / 2, (obj.position.y1 + obj.position.y2) / 2],
  ];
  var length = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
  obj.text.text = (length / 12).toString();
  obj.text.islength = true;
  return obj;
}

/**
 *
 * @param {{functype: 'TriangulationNetwork'}} obj
 */
export function HandlePointTriangulationNetwork(obj) {
  let handles = [];
  for (let i = 0; i < obj.referencePoints.length; i++) {
    handles.push(obj.referencePoints[i]);
  }
  obj.handlepoint = handles;
  return obj;
}
export function setInitialAngularDeviationOfClosedShape(object) {
  const arc = GetClosedShapeAngle(object.handlepoint);
  object.InitialAngularDeviation = arc;
}

export function setInitialAngularDeviationOfStructure(object) {
  const arc = GetStructureAngle(object.handlepoint);
  object.InitialAngularDeviation = arc;
}
