import React from 'react';
import {
  LengthBetweenPoints,
  IsZero,
  IsBigArc,
  FindCircle,
  IsArcDirection,
  GetCenter1,
  GetCenter,
  getAngleBy2Points,
  GetArcCenter,
  IsPointValid,
  IsByTime,
} from '@src/data/CommonFunc';
import {
  CreateStreetMoveShapeLeft,
  CreateStreetMoveShapeRight,
  CreateStreetMoveShapeRigt,
  GetPathArcD,
  GetPathArcDL,
  GetShapes,
  GetPathTowBessel,
  GetPathTowBesselL,
  GetPathLineD,
  sortStreetCrossPointsToClockwise,
} from '@src/data/BusinessFun';
import {
  GetBikeLaneCount,
  GetMaxHeight,
  GetStreetAreaBegin,
  GetStreetLanePathD,
  GetStreetLanesArea,
  GetStreetLineBegin,
  GetStreetLinePathD,
  GetStreetLinePoints,
  GetStreetMaxPoints,
} from '@src/actions/StreetFunHandle';
import { GetShapeStyle } from '@src/data/CommonProps';
import StreetLine from './StreetLine';
import { GetStreetText } from '@src/actions/ShapeTextHandle';
import {
  GetShapeClosePath,
  GetShapeTowBesselPath,
  GetShapePath,
  GetShapeThreeTriangle,
  GetShapeCircle,
  GetShapeTowBesselPath1,
  GetShapeLine,
} from '../ShapeFun';
import SplitPattem from '../SplitPattem';
import { STREET_LINE_TYPE, STREET_SPACE, COLOR_TYPE, DASH_ARRAY_TYPE, ANCHORS_TYPE } from '@src/constant';

import * as workData from '@src/data/WorkData';
class Street extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps({ object }) {
    this.init(object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    let maxheight = GetMaxHeight(object);
    let maxTopPoints = GetStreetMaxPoints(object, maxheight / 2, true);
    let linepoints = [];
    let beginStreetLine = GetStreetLineBegin(object.groupdata);
    linepoints[beginStreetLine] = maxTopPoints;
    let i = beginStreetLine;
    let lineData = object.groupdata;
    while (i >= 0 && i < lineData.length) {
      let laneHeight = object.lanewidth;
      if (i < object.lanes.length) {
        laneHeight = object.lanes[i].width;
      }
      let bottomLine = lineData[i].bottomline;
      if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.TWOWAY)
      ) {
        laneHeight = laneHeight + STREET_SPACE.TWOWAY;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.PAINTED)
      ) {
        laneHeight = laneHeight + STREET_SPACE.PAINTED;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.WIDE)
      ) {
        laneHeight = laneHeight + STREET_SPACE.WIDE;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, false);
        }
      }
      if (bottomLine != -1) linepoints[bottomLine] = GetStreetLinePoints(linepoints[i], laneHeight, false);
      i = bottomLine;
    }
    if (isFirst) {
      // FIXME: do not directly mutate state
      /* eslint-disable-next-line */
      this.state = {
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
      };
    } else {
      this.setState({
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
      });
    }
  };
  getRect = shapes => {
    //背景渲染 方便右击和拖拉编辑时使用
    let fillcolor = COLOR_TYPE.WHITE;
    if (this.state.object.selectflag) fillcolor = COLOR_TYPE.LIGHTGRAY;
    let topLine = GetStreetLineBegin(this.state.object.groupdata);
    let anchors = this.state.object.handlepoint;
    let i = GetStreetAreaBegin(anchors);
    while (i != -1 && anchors[i][3].rightindex != -1 && i != null) {
      var D1 = [],
        D2 = [];
      GetStreetLanesArea(D1, D2, this.state.linepoints[topLine], this.state.linepoints[1], i);
      let centerIndex = anchors[i][3].rightindex;
      let endIndex = anchors[centerIndex][3].rightindex;
      i = endIndex;
      let idLeft = String(centerIndex) + '||' + 'left' + '||' + anchors[centerIndex][2];
      var shapeLeft = <path d={D1.join(' ')} fill={fillcolor} stroke={COLOR_TYPE.NONE} key={idLeft} id={idLeft} />;
      shapes.push(shapeLeft);
      let idRight = String(centerIndex) + '||' + 'right' + '||' + anchors[centerIndex][2];
      var shapeRight = <path d={D2.join(' ')} fill={fillcolor} stroke={COLOR_TYPE.NONE} key={idRight} id={idRight} />;
      shapes.push(shapeRight);
    }
  };
  getLaneShape = shapes => {
    let lineData = this.state.object.groupdata;
    let i = GetStreetLineBegin(lineData);
    while (i >= 0 && i < lineData.length) {
      if (lineData[i].streetlinetype == 'invisible') {
        i = lineData[i].bottomline;
        continue;
      }
      let D = [];
      // GetStreetLinePathD(D,this.state.linepoints[i]);
      this.getLineSplitD(i, D, shapes);
      let commonPropsSelect = {
        stroke: lineData[i].selectline ? COLOR_TYPE.GREEN : COLOR_TYPE.NONE,
        strokeWidth: lineData[i].strokewidth + 5 + 15,
        strokeDasharray: DASH_ARRAY_TYPE.solid,
        fill: COLOR_TYPE.NONE,
      };
      shapes.push(<path d={D.join(' ')} {...commonPropsSelect} key={'line||' + String(i)} />);
      if (lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY) {
        let linepontTop = lineData[i].specialtop;
        let linepontBottom = lineData[i].specialbottom;
        shapes.push(
          <StreetLine
            points={this.state.linepoints[linepontTop]}
            linedata={lineData[linepontTop]}
            key={'lanetowaytop+' + String(i)}
          />
        );
        shapes.push(
          <StreetLine
            points={this.state.linepoints[linepontBottom]}
            linedata={lineData[linepontBottom]}
            key={'lanetowaybottom+' + String(i)}
          />
        );
      } else if (lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED) {
        let linepontTop = lineData[i].specialtop;
        let linepontBottom = lineData[i].specialbottom;
        shapes.push(
          <StreetLine
            points={this.state.linepoints[linepontTop]}
            linedata={lineData[linepontTop]}
            key={'lanetowaytop+' + String(i)}
          />
        );
        shapes.push(
          <StreetLine
            points={this.state.linepoints[linepontBottom]}
            linedata={lineData[linepontBottom]}
            key={'lanetowaybottom+' + String(i)}
          />
        );
      } else if (lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE) {
        let linepontTop = lineData[i].specialtop;
        let linepontBottom = lineData[i].specialbottom;
        shapes.push(
          <StreetLine
            points={this.state.linepoints[linepontTop]}
            linedata={lineData[linepontTop]}
            key={'lanetowaytop+' + String(i)}
          />
        );
        shapes.push(
          <StreetLine
            points={this.state.linepoints[linepontBottom]}
            linedata={lineData[linepontBottom]}
            key={'lanetowaybottom+' + String(i)}
          />
        );
      } else {
        shapes.push(<StreetLine points={this.state.linepoints[i]} linedata={lineData[i]} key={'line+' + String(i)} />);
      }

      i = lineData[i].bottomline;
    }
  };
  getLaneHideShape = (shapes, object) => {
    //只能拖拉模式进入
    if (object.streetmodel != 1) return;
    let laneData = object.groupdata;
    let beginStreetLine = GetStreetLineBegin(laneData);
    let i = beginStreetLine;
    while (i >= 0 && i < laneData.length) {
      let bottomLine = laneData[i].bottomline;
      let D = [];
      GetStreetLinePathD(D, this.state.linepoints[i]);
      let id = object.functype + 'line' + '||' + object.operateid + '||' + String(i);
      let style = GetShapeStyle().STREETLANESELECT;
      if (object.selectflag) style.stroke = COLOR_TYPE.LIGHTGRAY;
      //style.strokeWidth = object.streetwidth;
      shapes.push(<path d={D.join(' ')} {...style} key={id} id={id} strokeLinecap="butt" />);
      i = bottomLine;
    }
  };
  getStreetAcross(shapes, obj) {
    let acrossData = obj.streetacrossdata;
    for (let i = 0; i < acrossData.length; i++) {
      let tempData = acrossData[i];
      //id||objid  (相交图形Id||要计算的道路图形objid)
      if (typeof tempData === 'string') {
        let acrossObj = workData.getObject(tempData.split('||')[0]);
        if (typeof acrossObj === 'object' && acrossObj != null) {
          for (let j = 0; j < acrossObj.handlepoint.length; j++) {
            if (acrossObj.handlepoint[j].length < 2) continue;
            let acrossId = acrossObj.handlepoint[j][3].acrossobjid;
            let nowObjId = acrossId.split('||')[0];
            let dataObjId = acrossId.split('||')[2];
            let nowObj = workData.getObject(nowObjId);
            let dataObj = workData.getObject(dataObjId);
            if (!(typeof nowObj == 'object' && typeof dataObj == 'object')) continue;
            let closeBezier = GetShapeThreeTriangle(
              acrossObj.handlepoint[j],
              String(i * 10 + j) + '||' + acrossObj.operateid + '||' + ANCHORS_TYPE.STREETACROSSAREA,
              true,
              {
                stroke: COLOR_TYPE.WHITE,
                fill: COLOR_TYPE.WHITE,
                strokewidth: 4,
              }
            );
            shapes.push(closeBezier);
            let shapeTowBessel = GetShapeTowBesselPath(
              acrossObj.handlepoint[j],
              'bezier333' + String(i * 10 + j),
              true,
              {
                stroke: COLOR_TYPE.BLACK,
                fill: COLOR_TYPE.NONE,
                strokewidth: 4,
              }
            );
            shapes.push(shapeTowBessel);
          }
        }
      }
    }
  }
  /**
   *splitPattem
   *
   * @memberof Street
   */
  getLineSplitD = (lineIndex, D, shapes) => {
    let linePoints = this.state.linepoints[lineIndex];
    let lineData = this.state.object.groupdata[lineIndex];
    if (lineData.splitpattem.length == 0) {
      GetStreetLinePathD(D, linePoints);
    } else {
      let beginIndex = GetStreetAreaBegin(linePoints);
      let centerIndex = linePoints[beginIndex][3].rightindex; // anchors[begin[3].rightindex];
      let endIndex = linePoints[centerIndex][3].rightindex; // anchors[center[3].rightindex];
      let circleLine = FindCircle(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
      if (IsZero(circleLine[2])) {
        let scalePoint = GetCenter(linePoints[beginIndex], linePoints[endIndex], lineData.splitpattem[0].startPct);
        if (lineData.scalesplitleft) GetPathLineD(D, linePoints[beginIndex], scalePoint);
        else GetPathLineD(D, scalePoint, linePoints[endIndex]);
        let angle = getAngleBy2Points(linePoints[beginIndex], linePoints[endIndex]);
        if (this.state.object.selectflag)
          shapes.push(
            <SplitPattem
              point={scalePoint}
              key={JSON.stringify(lineData) + 'splitPattem'}
              angle={angle + Math.PI / 2}
            />
          );
      } else {
        let isBigArc = IsBigArc(linePoints[beginIndex], linePoints[endIndex], linePoints[centerIndex], circleLine[2]);
        let isArcDirection = IsArcDirection(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
        let circle = FindCircle(linePoints[beginIndex], linePoints[endIndex], linePoints[centerIndex]);
        let isByTime = IsByTime(linePoints[beginIndex], linePoints[centerIndex], linePoints[endIndex]);
        let scalePoint = GetArcCenter(
          circle,
          linePoints[beginIndex],
          linePoints[endIndex],
          lineData.splitpattem[0].startPct,
          isByTime
        );
        if (lineData.scalesplitleft)
          GetPathArcD(D, linePoints[beginIndex], scalePoint, circleLine[2], isBigArc, isArcDirection);
        else GetPathArcD(D, scalePoint, linePoints[endIndex], circleLine[2], isBigArc, isArcDirection);
        let angle = getAngleBy2Points(circleLine, scalePoint);
        if (this.state.object.selectflag)
          shapes.push(<SplitPattem point={scalePoint} key={JSON.stringify(lineData) + 'splitPattem'} angle={angle} />);
      }
    }
  };
  render() {
    if (this.state == null) return null;
    const { object } = this.state;
    if (object == null || object.handlepoint.length < 3) return null;
    let shapes = [];
    this.getRect(shapes);
    // 用于拖拉模式下得精度选择
    this.getLaneHideShape(shapes, object);
    this.getLaneShape(shapes);
    shapes.push(GetStreetText(object));
    this.getStreetAcross(shapes, object);
    return (
      <g id={object.functype}>
        <metadata nlanewidth={object.lanewidth} ssurfacetype={object.surfaceType} />
        {GetShapes(shapes)}
      </g>
    );
  }
}

export default Street;
