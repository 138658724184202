import * as React from 'react';

function SvgIconSelectArea(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .001h24v23.04H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M22.414 20.161l-3.207-3.079 1.439-1.381c.194-.186.137-.399-.127-.472l-6.039-1.657c-.264-.072-.418.076-.344.33l1.727 5.797c.074.255.296.31.49.123l1.439-1.382L21 21.52l1.414-1.359zM8 1.921h3v1.92H8zM8 19.201h3v1.92H8zM13 1.921h3v1.92h-3zM2 7.681h2v2.88H2zM20 7.681h2v2.88h-2zM2 12.481h2v2.88H2zM4 5.761H2v-2.88c0-.53.447-.96 1-.96h3v1.92H4v1.92zM6 21.121H3c-.553 0-1-.43-1-.96v-2.88h2v1.92h2v1.92zM22 5.761h-2v-1.92h-2v-1.92h3c.553 0 1 .43 1 .96v2.88z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconSelectArea;
