import * as React from 'react';

function SvgIconShoulder(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .002h24v24H0z" />
        <path stroke="currentColor" strokeWidth={2} d="M12 24V0" />
        <path stroke="currentColor" strokeWidth={2} opacity={0.502} strokeDasharray={5} d="M22 24V0" />
        <path stroke="currentColor" strokeWidth={2} d="M2 24V0" />
      </g>
    </svg>
  );
}

export default SvgIconShoulder;
