import * as React from 'react';

function SvgIconCurvedRoad(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 23" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.001.002h23.04v23.04H.001z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M17.28.96h3.84c.53 0 .96.43.96.96V9.6c0 .53-.43.96-.96.96h-3.84a6.727 6.727 0 00-6.72 6.72v3.84c0 .53-.43.96-.96.96H1.92a.96.96 0 01-.96-.96v-3.84C.96 8.281 8.281.96 17.28.96zm-14.4 19.2h5.76v-2.88c0-4.764 3.876-8.64 8.64-8.64h2.88V2.88h-2.88c-7.94 0-14.4 6.46-14.4 14.4v2.88z" />
          <path d="M16.787 6.732c-1.047.048-2.074.25-3.053.599l-.644-1.808a12.43 12.43 0 013.607-.709l.09 1.918zM6.73 16.796l-1.918-.086c.056-1.237.293-2.451.705-3.61l1.81.643c-.349.98-.548 2.008-.596 3.053zM9.847 7.255l1.144 1.54a10.61 10.61 0 00-2.2 2.203L7.249 9.854a12.581 12.581 0 012.598-2.6z" />
        </g>
        <g>
          <path d="M11 11h10.909v10.909H11z" />
          <path
            d="M20.464 12.445a1.817 1.817 0 00-1.294-.536c-.488 0-.948.19-1.294.536L12.69 17.63v.002a.58.58 0 00-.12.21l-.647 2.593c-.04.154.007.319.12.43v.001a.455.455 0 00.432.12l2.592-.648a.575.575 0 00.21-.118l.002-.002 5.186-5.185c.345-.346.535-.805.535-1.294s-.19-.948-.535-1.294zm-2.914 1.613l1.302 1.302-3.895 3.894-1.301-1.301 3.894-3.895zm-4.561 5.863l.27-1.08.81.81-1.08.27zm6.833-6.833a.944.944 0 010 1.302l-.327.327-1.302-1.302.327-.327a.943.943 0 011.302 0z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconCurvedRoad;
