import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomArc from '@src/data/GeomArc';
import GeomPolyArc from '@src/data/GeomPolyArc';
import StreetBreakLine from './StreetBreakLine';

class StreetIntersectionArea {
  /** @type {number} */
  intLocation;

  /**
   *
   * @param {number} startB1key - 第一个开始相交部分 CurbReturn 的 operateId
   * @param {number} startB2key - 第二个开始相交部分 CurbReturn 的 operateId
   * @param {string | 0} stopB1key - 第一个结束相交 CurbReturn 的 operateId, 等于 0 代表结束部分未相交
   * @param {string | 0} stopB2key - 第二个结束相交 CurbReturn 的 operateId, 同上
   * @param {GeomLine | StreetBreakLine} border1 - start breakLine cloneLine
   * @param {StreetBreakLine | GeomLine} border2 - stop breakLine cloneLine
   * @param {number} streetIntersectionLocation
   * @param {array} intersectionAreas
   * @param {{functype: 'StreetNew'}} _570 - street
   * @param {{functype: 'StreetNew'}[]} _571 - streets
   * @param {{functype: 'CurbReturn'}[]} curbReturns
   */
  constructor(
    startB1key,
    startB2key,
    stopB1key,
    stopB2key,
    border1,
    border2,
    streetIntersectionLocation,
    intersectionAreas,
    _570,
    _571,
    curbReturns
  ) {
    this.startB1key = startB1key;
    this.startB2key = startB2key;
    this.stopB1key = stopB1key;
    this.stopB2key = stopB2key;
    //this.border1 = _56c.getSvgPathData();
    //this.border2 = _56d.getSvgPathData();
    this.intLocation = streetIntersectionLocation;
    this.suppressStripes = true;
    this.stripeVisibilityAuto = this.intLocation === 3;
    this.medianEndCapAuto = true;
    intersectionAreas.some(function(ia) {
      if (this.isSame(ia)) {
        this.key = ia.key;
        this.suppressStripes = ia.suppressStripes;
        this.stripeVisibilityAuto = ia.stripeVisibilityAuto;
        this.medianEndCapAuto = ia.medianEndCapAuto;
        return true;
      }
      return false;
    }, this);
    this.lnStart = border1;
    if (border1.declaredClass == 'GeomLine') {
      this.gpaStart = new GeomPolyArc([
        { type: 'line', ptStart: border1.getPointStart(), ptStop: border1.getPointStop() },
      ]);
    } else {
      this.gpaStart = new GeomPolyArc([
        {
          type: 'arc',
          ptStart: border1.getPointStart(),
          ptStop: border1.getPointStop(),
          r: border1.r,
          largeArcFlag: border1.largeArcFlag,
          sweepFlag: border1.sweepFlag,
        },
      ]);
    }
    this.lnStop = border2;
    if (border2.declaredClass == 'GeomLine') {
      this.gpaStop = new GeomPolyArc([
        { type: 'line', ptStart: border2.getPointStart(), ptStop: border2.getPointStop() },
      ]);
    } else {
      this.gpaStop = new GeomPolyArc([
        {
          type: 'arc',
          ptStart: border2.getPointStart(),
          ptStop: border2.getPointStop(),
          r: border2.r,
          largeArcFlag: border2.largeArcFlag,
          sweepFlag: border2.sweepFlag,
        },
      ]);
    }
    this.ptsMedianCapAtStart = [];
    this.ptsMedianCapAtStop = [];
    this.stripePatternAtStart = 'singlesolid';
    this.stripePatternAtStop = 'singlesolid';
    if (this.stripeVisibilityAuto) {
      //this.suppressStripes = this.computeStripeSuppressionBehavior(_570, _571, curbReturns);
    }
  }

  /**
   * 计算 stripe 抑制行为
   * @deprecated
   * @param {*} _573
   * @param {*} _574
   * @param {*} _575
   */
  computeStripeSuppressionBehavior(_573, _574, _575) {
    var curbReturns = this.getParticipatingCurbReturns(_575),
      _577 = this.getParticipatingStreets(_574, curbReturns),
      _578,
      _579,
      _57a;
    if (this.intLocation === 3 && 4 === curbReturns.length && 2 === _577.length) {
      return true;
    }
    _578 = curbReturns.some(function(cr) {
      return UtilityMath.isBetween(cr.tetonAgent().getradianBetweenTwoPointsSetbacks(), 60, 120);
    });
    if (!_578) {
      return false;
    }
    if (2 === _577.length) {
      _57a = _577[_573 === _577[0] ? 1 : 0];
      _579 = _573
        .tetonAgent()
        .axis.tetonAgent()
        .getGeomPolyArc()
        .getIntersection(
          _57a
            .tetonAgent()
            .axis.tetonAgent()
            .getGeomPolyArc()
        );
      return 1 === _579.length;
    }
    return true;
  }
}

export default StreetIntersectionArea;
