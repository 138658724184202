import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import L from 'leaflet';
import 'leaflet-bing-layer';
import 'leaflet/dist/leaflet.css';
import mapJson from '@src/assets/map.json';

import {
  resumeMapState,
  setCurrentLocation,
  setMapConfigs,
  setMapType,
  setMapView,
  setNightMode,
  setSelectOptions,
  switchMapTab,
  toggleMapContainer,
} from '@src/action/map';
import { getMatrixPos, setMatrix } from '@src/action/transform';
import { takeSnapshot, getMapState } from '@src/action/snapshots';
import { setGlobalLoading, setShowProgress, setProgressTotal, setProgressCurrent } from '@src/action/app';
import { setMapConsumed } from '@src/action/ethos';

import { message } from '@src/components/Message';
import { STREET_TYPE, MAP } from '@src/constant';
import emitter from '@src/data/Event';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { getMapUrl, setLocationApiKey } from '@src/utils';
import { clientPtToUserPt, userPtToClientPt } from '@src/data/CommonFunction';
import _Street from '@src/data/_Street';
import { createLine } from '@src/data/ShapeOperationData';
import { getElementsFromMap } from '@src/services/MapService';

const DOM_ID = 'lncd-map-layer';

const mapPinIcon = L.icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
});

const Map = WrappedComponent => {
  class Map extends Component {
    /** @type {L.Map} */
    leafletMap = null;

    /** @type {L.Layer} */
    layer = null;
    cancelLoop = false;

    state = {
      // prevent to much zoom actions make some of them being filtered by leaflet
      zooming: false,
    };

    componentDidMount() {
      this.loadMapConfig();
    }

    componentDidUpdate(prevProps) {
      const {
        service: { schema, type, latitude, longitude },
        nightMode,
        setNightMode,
        appWidth,
        canvasHeight,
      } = this.props;

      if (
        (latitude !== prevProps.service.latitude || longitude !== prevProps.service.longitude) &&
        latitude !== 0 &&
        longitude !== 0
      ) {
        this.setMapLatLng([latitude, longitude]);
        return;
      }
      if (prevProps.service.schema !== schema || prevProps.service.type !== type) {
        this.updateMapLayer({ schema, type });
      } else if (prevProps.nightMode !== nightMode) {
        setNightMode(nightMode);
      }

      if (appWidth !== prevProps.appWidth || canvasHeight !== prevProps.canvasHeight) {
        // if receive imageData, don't load mapInfo
        if (this.props.mapInfo && !this.props.imageData) {
          // initial reload
          if (!this.props.mapConsumed) {
            const mapLayer = document.querySelector('#road-engine-container #lncd-map-layer');
            if (mapLayer instanceof HTMLDivElement) {
              this.initialLoadMapInfo(this.props.mapInfo);
              this.props.setMapConsumed();
            }
          }
        }
      }
    }

    // TODO: move to DataProvider and use context
    // request map config or return default config
    loadMapConfig = () => {
      // let data;
      // try {
      //   // TODO: API request should be handled by DataProvider
      //   // // request map provider
      //   // const res = await axios.get('/map.json');
      //   // // TODO: is this status code logic condition right?
      //   // if (res.status >= 200 && res.status < 400) data = res.data;
      // } catch (err) {
      //   // eslint-disable-next-line
      //   console.error(`Failed to load map configuration from server: ${JSON.stringify(err)}`);
      // } finally {
      //   if (!data) data = mapJson;
      // }

      // FIXME: search_key why use snake case?
      // TODO: change the search provider config format
      // this.mapContainerKey = data.search_key;
      // TODO: search_key is a bad name
      setLocationApiKey(mapJson.search_key);
      this.props.setMapConfigs(mapJson.items);
    };

    /**
     * read&load map info from Ethos
     * @param {*} mapInfo
     */
    initialLoadMapInfo = mapInfo => {
      // console.log('initial load map info');
      // TODO: update after map info data shape is determined
      // FIXME: prop should be set to redux state in App/DataProvider level
      if (typeof mapInfo.latitude === 'number' && typeof mapInfo.longitude === 'number') {
        if (mapInfo.latitude !== 0 && mapInfo.longitude !== 0) {
          // TODO: create layer by mapInfo
          // FIXME: why separate into two operations to update location?
          const { latitude, longitude, currentLocation, zoom, schema, type, opacity } = mapInfo;
          const mapState = {
            currentLocation: currentLocation || this.props.currentLocation,
            keyword: currentLocation,
            mapTabKey: '2', // if received mapInfo, set map tab change to second panel;
            opacity: opacity || this.props.opacity,
            service: {
              latitude,
              longitude,
              schema: schema || this.props.service.schema,
              type: type || this.props.service.type,
            },
            zoom: zoom || this.props.zoom,
          };
          this.resumeMap(mapState);
          // HACK
          /**
           * see withTransformManager.js -> componentDidUpdate
           * load this logic after setMatrixInitialized run
           */
          setTimeout(() => {
            const { appWidth, canvasHeight, headerHeight } = this.props;
            const zoomScale = (MAP.INITIAL_ZOOM - mapState.zoom) / 0.25;
            const sign = zoomScale > 0 ? 1 : -1;
            const zoomDelta = sign * 0.25;
            const canvasZoomDelta = Math.pow(2, zoomDelta);
            let scale;
            if (sign > 0) {
              scale = canvasZoomDelta / zoomScale;
            } else {
              scale = canvasZoomDelta * zoomScale;
            }
            const clientX = appWidth / 2;
            const clientY = headerHeight + canvasHeight / 2;
            this.props.zoomCanvas(scale, clientX, clientY);
          }, 1e3);
          // TODO: should this map data in _snapshotIndex 0 or 1?
          this.props.takeSnapshot();
        }
      }
    };

    createMap() {
      if (!this.leafletMap) {
        // https://leafletjs.com/reference-1.6.0.html#map-option
        this.leafletMap = L.map(DOM_ID, {
          dragging: false, // we control the map position by our code
          zoomControl: false,
          doubleClickZoom: false,
          inertia: false,
          boxZoom: false,
          touchZoom: false,
          trackResize: false,
          zoomSnap: undefined, // 修改此值会导致地图与画布错位的问题
          zoomDelta: 0.25,
          fadeAnimation: true,
          scrollWheelZoom: false,
        });

        // this.leafletMap.on('zoomstart', e => {
        //   this.props.setGlobalLoading(true);
        // });

        this.leafletMap.on('zoomend', e => {
          this.setState({ zooming: false });
        });

        this.leafletMap.on('moveend', e => {
          const target = e.target;
          if (target) {
            const { lat, lng } = target.getCenter();
            this.props.setMapView({ latlng: [lat, lng] });
          }
        });

        this.leafletMap.on('layeradd', () => {
          this.props.setGlobalLoading(true);
        });

        this.leafletMap.whenReady(() => {
          this.props.setGlobalLoading(true);
        });
      }
    }

    /**
     * Resume map from serialized map redux state from SVGs or from snapshots
     * @param {*} state
     */
    resumeMap = state => {
      const mapState = state || this.props.getMapState();
      if (!mapState || (mapState.service.latitude === 0 && mapState.service.longitude === 0)) {
        this.clearMap();
      } else {
        this.props.resumeMapState(mapState);
        if (this.props.service.schema !== mapState.service.schema) {
          this.updateMapLayer({
            schema: mapState.service.schema,
            type: mapState.service.type,
          });
        }
      }
    };

    /**
     * The move vector of the canvas/map
     */
    moveMap = move => {
      // get the center point
      // find the new center point's latlng
      // set latlng
      const [x, y] = move;
      this.leafletMap && this.leafletMap.panBy([-x, -y], { animate: false });
      // const latlng = this.leafletMap.getCenter();
      // TODO: issue with default icon https://github.com/Leaflet/Leaflet/issues/4968
      // L.marker(latlng, { icon: mapPinIcon }).addTo(this.leafletMap);
    };

    /**
     *
     * @param {[number, number]} latlng
     */
    setMapLatLng = latlng => {
      const [lat, lng] = latlng;

      // TODO: is it possible the leaflet map is not instantiated by now
      if (!this.leafletMap) this.createMap();

      if (lat && lng) {
        this.leafletMap.setView([lat, lng], this.props.zoom);
      }

      // when set location from the set location component, the layer is not ready
      if (!this.layer)
        this.updateMapLayer({
          schema: this.props.service.schema,
          type: this.props.service.type,
        });
    };

    updateMapLayer = ({ schema, type }) => {
      const {
        service: { latitude, longitude },
        t,
      } = this.props;
      if (latitude === 0 || longitude === 0) return;
      // make sure leafletMap is instantiated
      if (!this.leafletMap) this.createMap();

      // remove previous layer
      if (this.layer) {
        this.leafletMap.removeLayer(this.layer);
      }

      // TODO: move to redux
      let mapKey;
      this.props.mapConfigs.forEach(obj => {
        if (obj.provider === schema) {
          mapKey = obj.key;
        }
      });
      const url = getMapUrl(schema, { type });
      // Google map does not require a key?
      if (schema === 'Google') {
        this.layer = L.tileLayer(url, {
          // FIXME: should not hard code these domains
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          maxNativeZoom: 21,
          maxZoom: 25,
          minZoom: 15,
        }).addTo(this.leafletMap);
      } else if (schema === 'Bing') {
        /**
         * ! This basemap plugin is not stable and old, consider use REST API to instead it.
         * ! refer to https://docs.microsoft.com/en-us/bingmaps/rest-services/imagery/get-a-static-map
         */
        this.layer = L.tileLayer
          .bing({
            bingMapsKey: mapKey,
            imagerySet: type,
            maxNativeZoom: 19,
            maxZoom: 25,
            minZoom: 15,
            // TODO: the ability to set locale for road labels
            // see: https://docs.microsoft.com/en-us/bingmaps/rest-services/common-parameters-and-types/supported-culture-codes?redirectedfrom=MSDN
            // culture: 'zh-Hans',
          })
          .addTo(this.leafletMap);
      } else if (schema === 'ESRI') {
        this.layer = L.tileLayer(url, {
          maxZoom: 25,
          maxNativeZoom: 19,
          minZoom: 15,
        }).addTo(this.leafletMap);
      }

      /**
       * https://leafletjs.com/reference-1.7.1.html#event
       */
      this.layer.on('load', event => {
        this.props.setGlobalLoading(false);
      });

      this.layer.on('tileerror', (error, tile) => {
        message.warning(t('map.other.mapTilesRequestError'));
      });
    };

    /**
     * 缩放画布，有地图缩放地图
     * @param {boolean} isZoomIn - 是否为 Zoom in
     * @param {number} clientX
     * @param {number} clientY
     */
    handleZoom = (isZoomIn, clientX, clientY) => {
      // TODO: this solution works, but the ideal solution would be stack all zoom operations (with mouse position, because when zooming, the user may still moves the mouse), and after given time - say 200ms, zoom the map with the accumulated value
      // to prevent sync issue when zooming too fast using the wheel
      if (this.state.zooming) return;
      const { service, zoom, zoomCanvas, headerHeight, canvasHeight, appWidth, setMapView, t } = this.props;
      const sign = isZoomIn ? 1 : -1;
      const zoomDelta = sign * 0.25;
      const canvasZoomDelta = Math.pow(2, zoomDelta);
      const nextZoom = zoom + zoomDelta;
      let zoomVal;
      if (!clientX || !clientY) {
        // using map control to zoom
        clientX = appWidth / 2;
        clientY = headerHeight + canvasHeight / 2;
      }
      if (service.latitude === 0 && service.longitude === 0) {
        // 仅缩放画布
        zoomCanvas(canvasZoomDelta, clientX, clientY);
      } else {
        this.setState({ zooming: true });
        message.destroy();
        if (nextZoom > this.layer.options.maxZoom) {
          message.warning(t('map.other.zoomMaxWarning'));
          zoomVal = this.layer.options.maxZoom - zoomDelta;
        } else if (nextZoom < this.layer.options.minZoom) {
          message.warning(t('map.other.zoomMinWarning'));
          zoomVal = this.layer.options.minZoom - zoomDelta;
        } else {
          zoomCanvas(canvasZoomDelta, clientX, clientY);
          zoomVal = nextZoom;
        }
        this.leafletMap.setZoomAround(L.point(clientX, clientY - headerHeight), zoomVal, { animate: true });
      }
      // set map zoom level even there is no map so that when a map is created, the correct zoom level is applied
      setMapView({ zoom: zoomVal });
    };

    scaleMapAround = (scale, x, y) => {
      const { headerHeight, zoom, setMapView } = this.props;
      const nextZoom = (scale >= 1 ? 1 : -1) * Math.sqrt(scale) + zoom;
      if (this.leafletMap) {
        this.leafletMap.setZoomAround(L.point(x, y - headerHeight), nextZoom, { animate: true });
        setMapView({ zoom: nextZoom });
      }
    };

    /**
     * 清空地图
     */
    clearMap = () => {
      if (this.leafletMap) {
        // destroy the map and events
        this.leafletMap.remove();
        const container = this.leafletMap.getContainer();
        // FIXME: can we make sure this will remove any potential error-prune classes?
        // or can we just delete the container element?
        container.classList.remove('leaflet-container', 'leaflet-fade-anim');
        this.layer = null;
        this.leafletMap = null;
      }
      this.props.resumeMapState();
    };

    /**
     * generate a sketch from map
     */
    generateSketch = async () => {
      const { appWidth, canvasHeight, offsetXToCenter, offsetYToCenter, setGlobalLoading, t } = this.props;
      this.cancelLoop = false;
      setGlobalLoading(true);
      this.props.setShowProgress(true);
      try {
        /**
         * 转换 point 为经纬度
         * @returns {[number, number]} [lat, lon]
         */
        const convertPtToLatLng = pt => Object.values(this.leafletMap.containerPointToLatLng(L.point(pt[0], pt[1])));

        // 获取请求api的中心点（根据该点得到的bbox）以及当前画布所处的中心点，计算偏移量
        const presentCenter = userPtToClientPt(0, 0);
        let originCenter = [appWidth / 2, canvasHeight / 2];
        originCenter = [originCenter[0] + offsetXToCenter, originCenter[1] + offsetYToCenter];
        const presentLatLng = convertPtToLatLng(presentCenter);
        const originLatLng = convertPtToLatLng(originCenter);
        const offsetCoords = [presentLatLng[0] - originLatLng[0], presentLatLng[1] - originLatLng[1]];

        // 根据中心点(0, 0)计算left, bottom, right, top四个点的坐标
        const ptTop = convertPtToLatLng([appWidth / 2, 0]);
        const ptRight = convertPtToLatLng([appWidth, canvasHeight / 2]);
        const ptBottom = convertPtToLatLng([appWidth / 2, canvasHeight]);
        const ptLeft = convertPtToLatLng([0, canvasHeight / 2]);
        const bbox = [ptLeft[1], ptBottom[0], ptRight[1], ptTop[0]];

        // highway types: https://wiki.openstreetmap.org/wiki/Key:highway#:~:text=Highway%20%20%20%20Key%20%20%20,%20%20%20%2021%20more%20rows%20
        const highwayLevel1 = ['motorway', 'trunk', 'primary', 'secondary', 'tertiary', 'unclassified', 'residential'];
        const highwayLevel2 = ['motorway_link', 'trunk_link', 'primary_link', 'secondary_link', 'tertiary_link'];
        const highwayLevel3 = [
          'living_street',
          'service',
          'pedestrian',
          'track',
          'bus_guideway',
          'escape',
          'raceway',
          'road',
        ];
        const highwayValues = highwayLevel1.concat(highwayLevel2, highwayLevel3);

        const elements = await getElementsFromMap(bbox);
        const nodes = []; // 存储所有节点
        const ways = []; // 存储所有道路
        elements.forEach(elem =>
          elem.type === 'node' ? nodes.push(elem) : elem.type === 'way' ? ways.push(elem) : undefined
        );
        const nodesLen = nodes.length;
        let allSegments = [];

        // console.log('ways.length', ways.length);
        for (let i = 0, waysLen = ways.length; i < waysLen; i++) {
          const way = ways[i];
          if (way.tags && highwayValues.some(v => v === way.tags.highway)) {
            let segments = [];
            let relatedNodes = [];
            for (let j = 0, len = way.nodes.length; j < len; j++) {
              // 遍历得到与当前 way 有关的 node
              for (let k = 0; k < nodesLen; k++) {
                const node = nodes[k];
                // bounds.contains(L.latLng(node.lat, node.lon))
                if (node.id === way.nodes[j]) {
                  relatedNodes.push(node);
                  break;
                }
              }
              // console.log('relatedNodes', relatedNodes);

              // 遍历所有相关 node 连接两个连续 node
              let prev;
              segments = relatedNodes.reduce((total, cur) => {
                if (prev) {
                  let startPt = [prev.lat + offsetCoords[0], prev.lon + offsetCoords[1]];
                  startPt = L.latLng(...startPt);
                  startPt = this.leafletMap.latLngToLayerPoint(startPt);
                  startPt = clientPtToUserPt(startPt.x, startPt.y);
                  let endPt = [cur.lat + offsetCoords[0], cur.lon + offsetCoords[1]];
                  endPt = L.latLng(...endPt);
                  endPt = this.leafletMap.latLngToLayerPoint(endPt);
                  endPt = clientPtToUserPt(endPt.x, endPt.y);
                  prev = cur;
                  const ptStart = { x: startPt[0], y: startPt[1] };
                  const ptStop = { x: endPt[0], y: endPt[1] };
                  return total.concat(createLine({ ptStart, ptStop }));
                } else {
                  prev = cur;
                  return total;
                }
              }, []);
            }
            allSegments.push(segments);
          }
        }
        // console.log('allSegments', allSegments);
        if (allSegments.length > 0) {
          this.props.setProgressTotal(allSegments.length);

          workData.deleteSketchRoads();
          let sketchRoadsLen = 0;
          let _s = new _Street();
          for (let i = 0, il = allSegments.length; i < il; ++i) {
            let segments = allSegments[i];
            this.st = setTimeout(() => {
              if (this.cancelLoop) return;
              let street = workData.addStreetData(segments, STREET_TYPE.CURVED, { isSketchRoad: true });
              _s.alignComponentsToStreetAxis(street);
              _s.computeStreets(street);
              sketchRoadsLen += 1;
              this.props.setProgressCurrent(sketchRoadsLen);
              emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, false);
              if (sketchRoadsLen == allSegments.length) {
                this.completeGenerateSketch();
                this.props.takeSnapshot();
              }
            }, 0);
          }
        } else {
          this.completeGenerateSketch();
        }
      } catch (error) {
        console.error(error);
        message.error(t('map.other.generateSketchError'));
      }
    };

    completeGenerateSketch = () => {
      this.props.setGlobalLoading(false);
      this.props.setShowProgress(false);
      this.props.setProgressCurrent(0);
      this.props.setProgressTotal(0);
    };

    cancelGenerateSketch = () => {
      this.cancelLoop = true;
      clearTimeout(this.st);
      workData.deleteSketchRoads();
      emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, false);
      this.props.setGlobalLoading(false);
      this.props.setShowProgress(false);
      this.props.setProgressCurrent(0);
      this.props.setProgressTotal(0);
    };

    render() {
      const { zoom, appWidth, service, mapConfigs, nightMode, ...passThroughProps } = this.props;
      return (
        <WrappedComponent
          {...passThroughProps}
          resumeMap={this.resumeMap}
          setMapLatLng={this.setMapLatLng}
          clearMap={this.clearMap}
          handleZoom={this.handleZoom}
          updateMapLayer={this.updateMapLayer}
          leafletMap={this.leafletMap}
          moveMap={this.moveMap}
          generateSketch={this.generateSketch}
          cancelGenerateSketch={this.cancelGenerateSketch}
        />
      );
    }
  }

  Map.displayName = 'withMap';

  return Map;
};

const mapStateToProps = state => ({
  canvasHeight: state.app.canvasHeight,
  headerHeight: state.app.headerHeight,
  appWidth: state.app.appWidth,
  nightMode: state.app.nightMode,
  zoom: state.map.zoom,
  service: state.map.service,
  currentLocation: state.map.currentLocation,
  mapConfigs: state.map.mapConfigs,
  mapTabKey: state.map.mapTabKey,
  opacity: state.map.opacity,
  offsetXToCenter: state.canvas.offsetXToCenter,
  offsetYToCenter: state.canvas.offsetYToCenter,
  mapConsumed: state.ethos.mapConsumed,
});

const mapDispatchToProps = {
  setMapView,
  setMapType,
  setCurrentLocation,
  resumeMapState,
  setGlobalLoading,
  setSelectOptions,
  switchMapTab,
  toggleMapContainer,
  setMapConfigs,
  setNightMode,
  takeSnapshot,
  getMatrixPos,
  setMatrix,
  setMapConsumed,
  getMapState,
  setShowProgress,
  setProgressTotal,
  setProgressCurrent,
};

export default WrappedComponent =>
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(withTranslation(undefined, { withRef: true })(Map(WrappedComponent)));
