export default {
  idsSelectedShapes: [],

  getNextShapeId: function() {
    let svg = document.getElementById('svg');
    let idshapenext = parseInt(svg.getAttribute('idshapenext'), 10);
    svg.setAttribute('idshapenext', idshapenext + 1);
    return 's' + idshapenext;
  },

  getUserUnitsPerPixel: function() {
    let viewport = document.getElementById('viewport');
    let m = viewport.getCTM();
    return 1 / m.a;
  },

  getPixelsPerUserUnit: function() {
    let viewport = document.getElementById('viewport');
    let m = viewport.getCTM();
    return m.a;
  },

  getDefaultTextSize: function() {
    var host = document.getElementById('viewport');
    var fontSize = parseFloat(host.getAttribute('font-size'));
    return isNaN(fontSize) || fontSize <= 0 ? undefined : fontSize;
  },

  resizeAllText: function(isLarge) {
    var fontSize = this.getDefaultTextSize();
    if (fontSize && ((!isLarge && fontSize > 1) || (isLarge && fontSize < 6000))) {
      var scale = isLarge ? 1.2 : 0.833333333;
      var host = document.getElementById('viewport');
      host.setAttribute('font-size', fontSize * scale);
    }
  },
}
