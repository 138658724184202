import LncdSpin from '@src/components/LncdSpin';
import TooltipWrapper from '@src/components/TooltipWrapper';
import { Icon } from 'antd';
import classNames from 'classnames';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Img from 'react-image';

// 0.5s to consider as long pressed
const LONG_PRESS_THRESHOLD = 500;

function SymbolBarItem(props) {
  const { selected, symbol, symbolSubCategory, shouldAdaptIcon, toggleFavorite, favorite, device } = props;
  const [timeoutId, setTimeoutId] = useState(null);
  const { t } = useTranslation();

  const onClick = useCallback(
    e => {
      e.stopPropagation();
      props.onClick(symbol.key, e);
    },
    [props.onClick, symbol]
  );

  const onLongPressStart = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId);
    const id = setTimeout(() => {
      // FIXME: use key instead of the entire symbol item
      toggleFavorite(symbol);
    }, LONG_PRESS_THRESHOLD);
    setTimeoutId(id);
  });

  const onLongPressEnd = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId);
    setTimeoutId(null);
  });

  const itemClass = classNames('symbol-bar-item', {
    'symbol-bar-item-selected': selected,

    // XXX: Use a good handler replace the classNames
    'lncd-symbol-adapter': shouldAdaptIcon,
    // [`lncd-symbol-${symbolSubCategory}`]: shouldAdaptIcon,
  });

  const tooltipProps = {};
  if (selected) {
    tooltipProps.visible = false;
  }

  const { key, name, image, subCategoryKey } = symbol;

  const symbolContent = (
    <div className="symbol-image-wrapper">
      <Img
        className="symbol-image"
        src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`}
        alt={name}
        loader={<LncdSpin size={24} />}
        draggable="false"
      />
      {favorite && (
        <div className="lncd-favorite-icon">
          <Icon type="star" theme="filled" />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={itemClass}
      onClick={onClick}
      onMouseDown={onLongPressStart}
      onMouseMove={onLongPressEnd}
      onMouseUp={onLongPressEnd}
      onTouchStart={onLongPressStart}
      onTouchMove={onLongPressEnd}
      onTouchEnd={onLongPressEnd}
      data-symbol-key={key}
      data-symbol-name={name}
      data-symbol-sub-category={subCategoryKey}
    >
      <TooltipWrapper placement="bottom" title={t(`symbol.symbol.${key}`, name)} {...tooltipProps}>
        {symbolContent}
      </TooltipWrapper>
      {selected ? <div className="symbol-label">{t(`symbol.symbol.${key}`, name)}</div> : false}
    </div>
  );
}

export default memo(SymbolBarItem);
