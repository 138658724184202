import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomArc from './GeomArc';
import GeomPolyArc from './GeomPolyArc';
import StreetBreakLine from './StreetBreakLine';
import utility from './Utility';
import UtilityMath from './UtilityMath';
import GeomPathOffset from './GeomPathOffset';
import StreetBorder from './StreetBorder';
const workData = require('./WorkData');

class CurbReturnEngine {
  /** @type {number} */
  fromStart;

  /** @type {number} */
  fromStartPercent;

  /** @type {number} */
  normal;

  /**
   *
   * @param {number} fromStart
   * @param {number} fromStartPercent
   * @param {number} normal
   */
  constructor(fromStart, fromStartPercent, normal) {
    this.fromStart = 0;
    this.fromStartPercent = 0;
    this.normal = 0;
    if (utility.isTypeofNumber(fromStart)) {
      this.fromStart = fromStart;
    }
    if (utility.isTypeofNumber(fromStartPercent)) {
      this.fromStartPercent = fromStartPercent;
    }
    if (utility.isTypeofNumber(normal)) {
      this.normal = normal;
    }
  }

  getFillPathData(o) {
    if (o.streetStripes.length !== 2) {
      return '';
    }
    var street0 = workData.getObject(o.streetStripes[0].idStreet);
    var street1 = workData.getObject(o.streetStripes[1].idStreet);
    if (!utility.isValid(street0) || !utility.isValid(street1)) return;
    var gpa0 = new GeomPolyArc(street0.segments);
    var gpa1 = new GeomPolyArc(street1.segments);
    var c43c = gpa0.getAlignmentAtLength(o.streetStripes[0].setbackOffset, 0).ptAlign;
    var c43d = gpa1.getAlignmentAtLength(o.streetStripes[1].setbackOffset, 0).ptAlign;
    // var p1 = o.ptApex.clone();
    const p1 = new GeomPoint(o.ptApex.x, o.ptApex.y);
    p1.offsetToward(c43c, -24);
    // var p2 = o.ptApex.clone();
    const p2 = new GeomPoint(o.ptApex.x, o.ptApex.y);
    p2.offsetToward(c43d, -24);
    var mp = p1.midPoint(p2);
    var c43e = c43c.clone();
    var c43f = c43d.clone();
    var c440 = c43c.midPoint(o.ptApex);
    var c441 = c43d.midPoint(o.ptApex);
    if (gpa0 && gpa1) {
      c43e.offsetToward(gpa0.getPointClosest(c43c), 24);
      c43f.offsetToward(gpa1.getPointClosest(c43d), 24);
      c440.offsetToward(
        gpa0.getPointClosest(c440),
        Math.min(Math.abs(o.streetStripes[0].apexOffset - o.streetStripes[0].setbackOffset) / 2, 120)
      );
      c441.offsetToward(
        gpa1.getPointClosest(c441),
        Math.min(Math.abs(o.streetStripes[1].apexOffset - o.streetStripes[1].setbackOffset) / 2, 120)
      );
    }
    var path = '';
    path += ' C' + c43f.toString() + ' ' + c441.toString() + ' ' + o.ptApex.toString();
    path += ' C' + c440.toString() + ' ' + c43e.toString() + ' ' + c43c.toString() + ' Z';
    return path;
  }

  /**
   * 重叠
   * @param {{functype: 'CurbReturn'}} curbReturn0
   * @param {{functype: 'CurbReturn'}} curbReturn1
   * @returns {boolean}
   */
  overlaps(curbReturn0, curbReturn1) {
    var streetStripes0 = curbReturn0.streetStripes,
      streetStripes1 = curbReturn1.streetStripes;
    /**
     * 判断是否重叠
     * @param {number} apexOffset0
     * @param {number} setbackOffset0
     * @param {number} apexOffset1
     * @param {number} setbackOffset1
     * @returns {boolean}
     */
    const f = function(apexOffset0, setbackOffset0, apexOffset1, setbackOffset1) {
      if (apexOffset0 < apexOffset1) {
        return setbackOffset0 > setbackOffset1;
      } else {
        return setbackOffset0 < setbackOffset1;
      }
    };

    if (streetStripes0[0].idStreet === streetStripes1[0].idStreet) {
      if (streetStripes0[0].keyStripe === streetStripes1[0].keyStripe) {
        return f(
          streetStripes0[0].apexOffset,
          streetStripes0[0].setbackOffset,
          streetStripes1[0].apexOffset,
          streetStripes1[0].setbackOffset
        );
      }
    } else {
      if (streetStripes0[0].idStreet === streetStripes1[1].idStreet) {
        if (streetStripes0[0].keyStripe === streetStripes1[1].keyStripe) {
          return f(
            streetStripes0[0].apexOffset,
            streetStripes0[0].setbackOffset,
            streetStripes1[1].apexOffset,
            streetStripes1[1].setbackOffset
          );
        }
      }
    }
    if (streetStripes0[1].idStreet === streetStripes1[0].idStreet) {
      if (streetStripes0[1].keyStripe === streetStripes1[0].keyStripe) {
        return f(
          streetStripes0[1].apexOffset,
          streetStripes0[1].setbackOffset,
          streetStripes1[0].apexOffset,
          streetStripes1[0].setbackOffset
        );
      }
    } else {
      if (streetStripes0[1].idStreet === streetStripes1[1].idStreet) {
        if (streetStripes0[1].keyStripe === streetStripes1[1].keyStripe) {
          return f(
            streetStripes0[1].apexOffset,
            streetStripes0[1].setbackOffset,
            streetStripes1[1].apexOffset,
            streetStripes1[1].setbackOffset
          );
        }
      }
    }
    return false;
  }

  /**
   *
   * @param {{functype: 'CurbReturn'}} curbReturn0
   * @param {{functype: 'CurbReturn'}} curbReturn1
   * @returns {boolean | undefined}
   */
  negotiateOverlap(curbReturn0, curbReturn1) {
    var streetStripes0 = curbReturn0.streetStripes,
      streetStripes1 = curbReturn1.streetStripes;
    var index0 = -1,
      index1 = -1;
    var streetId = '';
    var stripeKey = 0;
    if (utility.isNonEmptyArray(streetStripes0) && utility.isNonEmptyArray(streetStripes1)) {
      streetStripes0.some(function(streetStripe0, i) {
        return streetStripes1.some(function(streetStripe1, j) {
          if (
            streetStripe0.idStreet === streetStripe1.idStreet &&
            streetStripe0.keyStripe === streetStripe1.keyStripe
          ) {
            index0 = i;
            index1 = j;
            streetId = streetStripe0.idStreet;
            stripeKey = streetStripe0.keyStripe;
            return true;
          }
          return false;
        });
      });
    }
    if (index1 === -1 || index0 === -1) {
      return;
    }
    var apexOffset0 = streetStripes0[index0].apexOffset;
    var setbackPref0 = streetStripes0[index0].setbackPref;
    var apexOffset1 = streetStripes1[index1].apexOffset;
    var setbackPref1 = streetStripes1[index1].setbackPref;
    var apexOffset, sign;
    var distance = Math.abs(apexOffset0 - apexOffset1);
    if (setbackPref0 < distance / 2) {
      sign = apexOffset0 < apexOffset1 ? 1 : -1;
      apexOffset = apexOffset0 + sign * setbackPref0;
    } else {
      if (setbackPref1 < distance / 2) {
        sign = apexOffset0 < apexOffset1 ? -1 : 1;
        apexOffset = apexOffset1 + sign * setbackPref1;
      } else {
        apexOffset = (apexOffset0 + apexOffset1) / 2;
      }
    }
    var street = workData.getObject(streetId);
    for (let i = 0; i < street.components.length; i++) {
      if (street.components[i].key == stripeKey) {
        var gpa = new GeomPolyArc(street.components[i].segments);
        var pt = gpa.getPointAtLength(apexOffset);
        streetStripes0[index0].ptSetback = pt;
        streetStripes1[index1].ptSetback = pt;
        streetStripes0[index0].setbackOffset = apexOffset;
        streetStripes1[index1].setbackOffset = apexOffset;
        curbReturn0.streetStripes = streetStripes0;
        var segments = this.computeRenderPath(curbReturn0);
        if (utility.isNonEmptyArray(segments)) curbReturn0.segments = segments;
        workData.setObj(curbReturn0.operateid, curbReturn0);
        curbReturn1.streetStripes = streetStripes1;
        segments = this.computeRenderPath(curbReturn1);
        if (utility.isNonEmptyArray(segments)) curbReturn1.segments = segments;
        workData.setObj(curbReturn1.operateid, curbReturn1);
      }
    }
  }

  /**
   *
   * @param {{functype: 'CurbReturn'}} curbReturn
   * @returns {array} - segment array that arc or q type inside
   */
  computeRenderPath(curbReturn) {
    var streetStripes = curbReturn.streetStripes,
      strokeWidth = 4,
      // ptApex = curbReturn.ptApex,
      ptApex = new GeomPoint(curbReturn.ptApex.x, curbReturn.ptApex.y),
      pt1,
      pt2,
      centerPt,
      r,
      result;
    if (utility.isNonEmptyArray(streetStripes)) {
      var street0 = workData.getObject(streetStripes[0].idStreet);
      var street1 = workData.getObject(streetStripes[1].idStreet);
      let street0Len = street0.components.length;
      let street1Len = street1.components.length;
      for (let i = 0; i < street0Len; i++) {
        if (street0.components[i].key == streetStripes[0].keyStripe) {
          var gpa0 = new GeomPolyArc(street0.components[i].segments);
          for (let j = 0; j < street1Len; j++) {
            if (street1.components[j].key == streetStripes[1].keyStripe) {
              var gpa1 = new GeomPolyArc(street1.components[j].segments);
              pt1 = gpa0.getAlignmentAtLength(streetStripes[0].setbackOffset, 0).ptAlign;
              pt2 = gpa1.getAlignmentAtLength(streetStripes[1].setbackOffset, 0).ptAlign;
              if (Math.abs(ptApex.distance(pt1) - ptApex.distance(pt2)) < strokeWidth / 4) {
                centerPt = this.getPointArcCenter(
                  gpa0,
                  gpa1,
                  ptApex,
                  streetStripes[0].setbackOffset,
                  streetStripes[0].apexOffset,
                  streetStripes[1].setbackOffset,
                  streetStripes[1].apexOffset,
                  pt1,
                  pt2
                );
                if (centerPt && Math.abs(ptApex.distance(pt1) - ptApex.distance(pt2)) < strokeWidth / 4) {
                  r = centerPt.distance(pt1);
                  var sweepFlag = new UtilityMath().isCounterClockwise(pt1, ptApex, pt2) ? '1' : '0';
                  result = [
                    {
                      type: 'arc',
                      ptStart: { x: pt1.x, y: pt1.y },
                      ptStop: { x: pt2.x, y: pt2.y },
                      r: r,
                      largeArcFlag: false,
                      sweepFlag: sweepFlag,
                    },
                  ];
                }
              }
            }
          }
        }
      }
    }
    if (!result) {
      result = [
        {
          type: 'q',
          ptStart: { x: pt1.x, y: pt1.y },
          ptStop: { x: pt2.x, y: pt2.y },
          pt: { x: ptApex.x, y: ptApex.y },
        },
      ];
    }
    return result;
  }

  /**
   *
   * @param {GeomPolyArc} gpa0
   * @param {GeomPolyArc} gpa1
   * @param {GeomPoint} ptApex
   * @param {number} setbackOffset0
   * @param {number} apexOffset0
   * @param {number} setbackOffset1
   * @param {number} apexOffset1
   * @param {number} sp
   * @param {number} ep
   * @returns {GeomPoint | undefined}
   */
  getPointArcCenter(gpa0, gpa1, ptApex, setbackOffset0, apexOffset0, setbackOffset1, apexOffset1, sp, ep) {
    var sns = this.getStripeNormalSegments(
      gpa0,
      gpa1,
      ptApex,
      setbackOffset0,
      apexOffset0,
      setbackOffset1,
      apexOffset1
    );
    var p;
    if (sns) {
      p = sns.stripeNormal0.getPointIntersect(sns.stripeNormal1);
    }
    if (p && Math.abs(sp.distance(p) - ep.distance(p)) < 4) {
      return p;
    }
    return undefined;
  }

  /**
   *
   * @param {GeomPolyArc} gpa0
   * @param {GeomPolyArc} gpa1
   * @param {GeomPoint} ptApex
   * @param {number} setbackOffset0
   * @param {number} apexOffset0
   * @param {number} setbackOffset1
   * @param {number} apexOffset1
   * @returns {{ stripeNormal0: GeomLine, stripeNormal1: GeomLine } | undefined}
   */
  getStripeNormalSegments(gpa0, gpa1, ptApex, setbackOffset0, apexOffset0, setbackOffset1, apexOffset1) {
    var sga = gpa0.getAlignmentAtLength(setbackOffset0, 0).ptAlign;
    var ega = gpa1.getAlignmentAtLength(setbackOffset1, 0).ptAlign;
    var d = sga.distanceSquared(ega);
    var sign = new UtilityMath().isClockwise(ptApex, sga, ega) ? -1 : 1;
    var rd = setbackOffset0 > apexOffset0 ? d : -d;
    var sga1 = gpa0.getAlignmentAtLength(setbackOffset0, sign * rd).ptAlign;
    var gl0 = new GeomLine(sga, sga1);
    sign *= -1;
    rd = setbackOffset1 > apexOffset1 ? d : -d;
    var ega1 = gpa1.getAlignmentAtLength(setbackOffset1, sign * rd).ptAlign;
    var gl1 = new GeomLine(ega, ega1);
    if (gl0 && gl1) {
      return {
        stripeNormal0: gl0,
        stripeNormal1: gl1,
      };
    }
    return undefined;
  }

  /**
   * 是否连接到 stripe
   * @param {object} curbReturn
   * @param {StreetBorder} streetBorder
   * @returns {boolean}
   */
  isConnectedToStripe(curbReturn, streetBorder) {
    var streetStripes = curbReturn.streetStripes;
    if (utility.isNonEmptyArray(streetStripes)) {
      for (var idx = 0; idx < streetStripes.length; idx += 1) {
        var streetStripe = streetStripes[idx];
        if (streetBorder.streetId === streetStripe.idStreet && streetBorder.stripeKey === streetStripe.keyStripe) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   *
   * @param {*} street
   * @param {*} curbReturn0
   * @param {*} curbReturn1
   * @param {number} breakLineSetback
   * @returns {StreetBreakLine | undefined}
   */
  getBreakLineType1(street, curbReturn0, curbReturn1, breakLineSetback) {
    var c3fc, c3fd, c3fe, c3ff;
    if (2 === curbReturn0.streetStripes.length && 2 === curbReturn1.streetStripes.length) {
      for (var i = 0; i < 2; i += 1) {
        for (var j = 0; j < 2; j += 1) {
          c3fc = curbReturn0.streetStripes[i];
          c3fd = curbReturn0.streetStripes[(i + 1) % 2];
          c3fe = curbReturn1.streetStripes[j];
          c3ff = curbReturn1.streetStripes[(j + 1) % 2];
          if (
            street.operateid === c3fc.idStreet &&
            street.operateid === c3fe.idStreet &&
            c3fd.idStreet === c3ff.idStreet &&
            c3fd.keyStripe === c3ff.keyStripe
          ) {
            if (this._isPositiveVisibleOnStripe(c3fc) === this._isPositiveVisibleOnStripe(c3fe)) {
              var streetBorderPoint0 = this._getStreetBorderPoint(curbReturn0.ptApex, c3fc, breakLineSetback);
              var streetBorderPoint1 = this._getStreetBorderPoint(curbReturn1.ptApex, c3fe, breakLineSetback);
              if (streetBorderPoint0 && streetBorderPoint1) {
                var streetAxisOffset = this._getstreetAxisOffsetToBreakLine(
                  street,
                  streetBorderPoint0,
                  streetBorderPoint1
                );
                var posIsVisible = this._isPositiveVisibleOnStripe(c3fc);
                if (utility.isTypeofNumber(streetAxisOffset)) {
                  return new StreetBreakLine(
                    1,
                    streetAxisOffset,
                    posIsVisible,
                    curbReturn0.operateid,
                    streetBorderPoint0,
                    curbReturn1.operateid,
                    streetBorderPoint1,
                    c3fc.keyStripe,
                    c3fe.keyStripe
                  );
                }
              }
            }
          }
        }
      }
    }
    return undefined;
  }

  /**
   *
   * @param {*} street
   * @param {*} curbReturn0
   * @param {*} curbReturn1
   * @param {number} c407
   * @param {number} breakLineSetback
   * @returns {StreetBreakLine | undefined}
   */
  getBreakLineType3(street, curbReturn0, curbReturn1, c407, breakLineSetback) {
    var c410, c411, c412, c413;
    if (2 === curbReturn0.streetStripes.length && 2 === curbReturn1.streetStripes.length) {
      for (var i = 0; i < 2; i += 1) {
        for (var j = 0; j < 2; j += 1) {
          c410 = curbReturn0.streetStripes[i];
          c411 = curbReturn0.streetStripes[(i + 1) % 2];
          c412 = curbReturn1.streetStripes[j];
          c413 = curbReturn1.streetStripes[(j + 1) % 2];
          if (street.operateid === c410.idStreet && street.operateid === c412.idStreet) {
            if (this._isPositiveVisibleOnStripe(c410) === this._isPositiveVisibleOnStripe(c412)) {
              var streetBorderPoint0 = this._getStreetBorderPoint(curbReturn0.ptApex, c410, breakLineSetback);
              var streetBorderPoint1 = this._getStreetBorderPoint(curbReturn1.ptApex, c412, breakLineSetback);
              if (streetBorderPoint0 && streetBorderPoint1) {
                var gpa = new GeomPolyArc(street.segments);
                var go0 = gpa.getOffsetsToPoint(streetBorderPoint0);
                var go1 = gpa.getOffsetsToPoint(streetBorderPoint1);
                if (go0 && go1 && c407 >= Math.abs(go0.fromStart - go1.fromStart)) {
                  var streetAxisOffset = this._getstreetAxisOffsetToBreakLine(
                    street,
                    streetBorderPoint0,
                    streetBorderPoint1
                  );
                  var posIsVisible = this._isPositiveVisibleOnStripe(c410);
                  if (utility.isTypeofNumber(streetAxisOffset)) {
                    return new StreetBreakLine(
                      2,
                      streetAxisOffset,
                      posIsVisible,
                      curbReturn0.operateid,
                      streetBorderPoint0,
                      curbReturn1.operateid,
                      streetBorderPoint1,
                      c410.keyStripe,
                      c412.keyStripe
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
    return undefined;
  }

  /**
   *
   * @param {*} street
   * @param {*} curbReturn
   * @param {StreetBorder} streetBorder0
   * @param {StreetBorder} streetBorder1
   * @param {number} breakLineSetback
   * @returns {StreetBreakLine | undefined}
   */
  getBreakLineType4(street, curbReturn, streetBorder0, streetBorder1, breakLineSetback) {
    var c426;
    if (2 === curbReturn.streetStripes.length) {
      for (var i = 0; i < 2; i += 1) {
        var streetStripe = curbReturn.streetStripes[i];
        if (street.operateid === streetStripe.idStreet && streetStripe.keyStripe === streetBorder0.stripeKey) {
          var streetBorderPoint0 = this._getStreetBorderPoint(curbReturn.ptApex, streetStripe, breakLineSetback);
          if (streetBorderPoint0) {
            c426 = streetBorder1.gpaStripe.getPointClosest(streetBorderPoint0);
            var streetAxisOffset = this._getstreetAxisOffsetToBreakLine(street, streetBorderPoint0, c426);
            var posIsVisible = this._isPositiveVisibleOnStripe(streetStripe);
            if (utility.isTypeofNumber(streetAxisOffset)) {
              return new StreetBreakLine(
                3,
                streetAxisOffset,
                posIsVisible,
                curbReturn.operateid,
                streetBorderPoint0,
                0,
                c426,
                streetStripe.keyStripe,
                streetBorder1.stripeKey
              );
            }
          }
        }
      }
    }
    return undefined;
  }

  /**
   *
   * @param {*} street
   * @param {GeomPoint} streetBorderPoint0
   * @param {GeomPoint} streetBorderPoint1
   * @returns {GeomPathOffset | number}
   */
  _getstreetAxisOffsetToBreakLine(street, streetBorderPoint0, streetBorderPoint1) {
    var gpa = new GeomPolyArc(street.segments);
    var ogpa = new GeomPolyArc([{ type: 'line', ptStart: streetBorderPoint0, ptStop: streetBorderPoint1 }]);
    var c4ab = undefined;
    if (streetBorderPoint0 && streetBorderPoint1) {
      var c4aa = gpa.getIntersection(ogpa);
      if (1 === c4aa.length) {
        c4ab = c4aa[0].offset0;
      }
    }
    if (!c4ab) {
      c4ab = gpa.getOffsetsToPoint(streetBorderPoint0.midPoint(streetBorderPoint1)).fromStart;
    }
    return c4ab;
  }

  /**
   *
   * @param {GeomPoint} ptApex
   * @param {*} streetStripe
   * @param {number} breakLineSetback
   * @returns {GeomPoint | undefined}
   */
  _getStreetBorderPoint(ptApex, streetStripe, breakLineSetback) {
    var pt;
    if (ptApex && streetStripe.ptSetback && streetStripe.apexOffset !== streetStripe.setbackOffset) {
      // pt = ptApex.clone();
      pt = new GeomPoint(ptApex.x, ptApex.y);
      pt.offsetToward(streetStripe.ptSetback, breakLineSetback);
    }
    return pt;
  }

  _isPositiveVisibleOnStripe(streetStripe) {
    return streetStripe.apexOffset < streetStripe.setbackOffset;
  }
}

export default CurbReturnEngine;
