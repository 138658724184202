import * as React from 'react';

function SvgIconPolice(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-.001-.002h24v24h-24z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M16 6a1 1 0 00-1-1h-2a1 1 0 00-1 1v2h4V6zM19.445 1.168l1.11 1.664-3 2-1.11-1.663zM18 6h4v2h-4zM8.555 1.168l3 2-1.11 1.664-3-2zM6 6h4v2H6zM23 13h-3.382l-1.724-3.447A.998.998 0 0017 9h-6c-.379 0-.725.214-.895.553L8.382 13H5c-2.206 0-4 1.795-4 4v2a1 1 0 001 1h3.185A2.993 2.993 0 008 22a2.996 2.996 0 002.816-2h4.369A2.994 2.994 0 0018 22a2.997 2.997 0 002.816-2H23a1 1 0 001-1v-5a1 1 0 00-1-1zM8 20c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm10 0c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm4-2h-1.184A2.996 2.996 0 0018 16a2.993 2.993 0 00-2.815 2h-4.369A2.996 2.996 0 008 16a2.993 2.993 0 00-2.815 2H3v-1c0-1.104.897-2 2-2h4c.379 0 .725-.214.895-.553L11.618 11h4.764l1.724 3.447A.998.998 0 0019 15h3v3z" />
          <path d="M13 12h3v2h-3z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconPolice;
