import _ from 'lodash';
import { unifyPoint } from '@src/utils';
import { clientPtToUserPt, getUserUnitByPixel } from '@src/data/CommonFunction';

export default class TrackService {
  /** @type {number} 平滑度，越大越平滑，被优化掉的点越多 */
  bufferSize;

  constructor() {
    this.buffer = [];
    this.strPath = [];
    this.bufferSize = 1;
    this.containerId = 'viewport';
    this.pathId = 'lncd-track-path';
  }

  /**
   * 添加点到 track 缓冲区
   * @param {[number, number]} pt
   */
  _appendToBuffer(pt) {
    pt = unifyPoint(pt, false);
    this.buffer.push(pt);
    while (this.buffer.length > this.bufferSize) {
      this.buffer.shift();
    }
  }

  /**
   * 获取平分点
   * @param {number} offset
   * @returns {{ x: number, y: number } | null}
   */
  _getAveragePoint(offset) {
    let len = this.buffer.length;
    if (len % 2 === 1 || len >= this.bufferSize) {
      let totalX = 0;
      let totalY = 0;
      let pt, i;
      let count = 0;
      for (i = offset; i < len; i++) {
        count++;
        pt = this.buffer[i];
        // pt = devicePtToUserPt(pt.x, pt.y);
        // pt = unifyPoint(pt, false);
        totalX += pt.x;
        totalY += pt.y;
      }
      return {
        x: totalX / count,
        y: totalY / count,
      };
    }
    return null;
  }

  /**
   * 更新 track path
   */
  _updateTrackPath() {
    // const newObj = _.cloneDeep(obj);
    // let { buffer, points } = obj.shadow;
    // eslint-disable-next-line no-undef
    let path = document.getElementById(this.pathId);
    let pt = this._getAveragePoint(0, this.buffer);
    if (pt) {
      // Get the smoothed part of the path that will not change
      this.strPath += ' L' + pt.x + ' ' + pt.y;

      // Get the last part of the path (close to the current mouse position)
      // This part will change if the mouse moves again
      let tmpPath = '';
      for (let offset = 2; offset < this.buffer.length; offset += 2) {
        pt = this._getAveragePoint(offset, this.buffer);
        // pt = clientPtToUserPt(pt.x, pt.y);
        // pt = unifyPoint(pt, false);
        tmpPath += ' L' + pt.x + ' ' + pt.y;
      }

      // const c = _.cloneDeep(obj);
      // console.log(c.position);
      // Set the complete current path coordinates
      path.setAttribute('d', this.strPath + tmpPath);
    }
  }

  /**
   * 初始化 Track
   * @description Mouse Down Left 触发
   * @param {[number, number]} afterXY
   * @param {*} config
   * @param {number} config.bufferSize
   */
  init(afterXY, config) {
    if (config) {
      this.bufferSize = config.bufferSize;
    }
    this.buffer = [];
    this._appendToBuffer(afterXY);
    this.strPath = 'M' + afterXY[0] + ' ' + afterXY[1];
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('id', this.pathId);
    path.setAttribute('fill', 'none');
    path.setAttribute('stroke', 'rgba(193, 193, 193, 0.5)');
    path.setAttribute('stroke-width', 5 * getUserUnitByPixel());
    path.setAttribute('stroke-linejoin', 'round');
    path.setAttribute('d', this.strPath);
    path.style.pointerEvents = 'none';
    const svgElement = document.getElementById(this.containerId);
    svgElement.appendChild(path);
  }

  /**
   * 更新 Track
   * @description Mouse Move 触发
   * @param {[number, number]} point
   */
  update(point) {
    let pt = clientPtToUserPt(...point);
    this._appendToBuffer(pt);
    this._updateTrackPath();
  }

  /**
   * 销毁 Track
   * @description Mouse Up 触发
   * @param {function} [callback]
   */
  destroy(callback) {
    const svgElement = document.getElementById(this.containerId);
    let path = document.getElementById(this.pathId);

    // 防抖判断
    if (path.getAttribute('d').length > 100) {
      callback && callback({ path });
    }
    svgElement.removeChild(path);
  }
}
