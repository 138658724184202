import * as React from 'react';

function SvgIconTruck(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24.002H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M23 8H11a1 1 0 00-1 1v8H8.815A2.969 2.969 0 008 15.781V9a1 1 0 00-1-1H4c-2.206 0-4 1.795-4 4v6a1 1 0 001 1h2.185A2.993 2.993 0 006 21a2.993 2.993 0 002.815-2h7.369a2.994 2.994 0 002.815 2 2.994 2.994 0 002.815-2H23a1 1 0 001-1V9a1 1 0 00-1-1zM2 12c0-1.104.897-2 2-2h2v5a2.993 2.993 0 00-2.815 2H2v-5zm4 7c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm13 0c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm3-2h-.185A2.994 2.994 0 0019 15a2.994 2.994 0 00-2.815 2H12v-7h2v3h2v-3h2v3h2v-3h2v7z" />
          <path d="M3 11h2v2H3z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconTruck;
