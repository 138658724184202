import { produce } from 'immer';

const ns = '[DEV Options] ';

const LOAD_OPTIONS = `${ns}load dev options`;
const TOGGLE_TRACK_MOUSE = `${ns}toggle track mouse`;

export const loadOptions = options => ({
  type: LOAD_OPTIONS,
  options,
});

export const toggleTrackMouse = () => ({
  type: TOGGLE_TRACK_MOUSE,
});

const initialState = {
  trackMouse: false,
};

const updateOption = (key, value) => {
  const optionData = localStorage.getItem('dev-options');
  const options = optionData ? JSON.parse(optionData) : {};
  options[key] = value;
  localStorage.setItem('dev-options', JSON.stringify(options));
};

export const reducer = produce((draft, action) => {
  if (!draft) {
    return initialState;
  }

  switch (action.type) {
    case LOAD_OPTIONS: {
      const { trackMouse } = action.options;
      draft.trackMouse = trackMouse;
      return;
    }
    case TOGGLE_TRACK_MOUSE: {
      const trackMouse = !draft.trackMouse;
      draft.trackMouse = trackMouse;
      updateOption('trackMouse', trackMouse);
      return;
    }
    default:
      return;
  }
});
