import { ORIENTATION_VIEW, FUNCTION_TYPE } from '@src/constant/constant';

const symbolData = {
  categories: [
    {
      key: 'roads-types',
      name: 'Road Types',
      subCategories: [
        {
          key: 'streets',
          icon: 'streets',
          name: 'Streets',
          symbols: [
            {
              name: 'Paved Striped N‑S',
              key: 'PavedNS',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Paved Striped E‑W',
              key: 'PavedEW',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Paved Unstriped N‑S',
              key: 'PavedUnstripedNS',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Paved Unstriped E‑W',
              key: 'PavedUnstripedEW',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Ramp',
              key: 'Ramp',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Roundabout',
              key: 'Roundabout',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Crosswalk',
              key: 'RoadCrosswalk',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Dirt N‑S',
              key: 'DirtNS',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Dirt E‑W',
              key: 'DirtEW',
              importance: 9,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Gravel N‑S',
              key: 'GravelNS',
              importance: 10,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Gravel E‑W',
              key: 'GravelEW',
              importance: 11,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Parking Stalls',
              key: 'ParkingStalls',
              importance: 12,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'lane-markers',
          icon: 'lane-markers',
          name: 'Lane Markers',
          symbols: [
            {
              name: 'Left Arrow',
              key: 'ArrowLeftTurn',
              importance: 1,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Right Arrow',
              key: 'ArrowRightTurn',
              importance: 2,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Straight Arrow',
              key: 'StraightArrow',
              importance: 3,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Directional Arrow',
              key: 'ArrowDirectional',
              importance: 4,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Left Turning Arrow',
              key: 'ArrowTurningLeft',
              importance: 5,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Right Turning Arrow',
              key: 'ArrowTurningRight',
              importance: 6,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Left Turn Arrow',
              key: 'LeftTurnArrow',
              importance: 7,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Right Turn Arrow',
              key: 'RightTurnArrow',
              importance: 8,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Left and Straight Arrow',
              key: 'ArrowStraightAndLeft',
              importance: 9,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Right and Straight Arrow',
              key: 'ArrowStraightAndRight',
              importance: 10,
              displayHeight: 60,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
      ],
    },
    {
      key: 'vehicles',
      name: 'Vehicles',
      subCategories: [
        {
          key: 'passengers',
          icon: 'passengers',
          name: 'Passengers',
          symbols: [
            {
              name: 'Sedan (top view)',
              key: 'SedanTop',
              importance: 1,
              orientationGroup: 'Sedan',
              orientationView: ORIENTATION_VIEW.TOP,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Sedan',
              key: 'Sedan',
              importance: 2,
              orientationGroup: 'Sedan',
              orientationView: ORIENTATION_VIEW.SIDE,
              displayHeight: 70,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Underbody view',
              key: 'SedanUnderBody',
              importance: 3,
              orientationGroup: 'Sedan',
              orientationView: ORIENTATION_VIEW.BOTTOM,
              displayHeight: 80,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'SUV (top view)',
              key: 'SUVTop',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'SUV',
              key: 'SUVSide',
              importance: 5,
              displayHeight: 70,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Station Wagon (top view)',
              key: 'StationWagonTop',
              importance: 6,
              displayHeight: 90,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Station Wagon',
              key: 'StationWagonSide',
              importance: 7,
              displayHeight: 65,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Mini Van',
              key: 'MiniVan',
              importance: 8,
              displayHeight: 90,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'pickup',
          icon: 'pickup',
          name: 'Pick-up',
          symbols: [
            {
              name: 'Pickup truck (top view)',
              key: 'PickupTruckTop',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Pickup truck',
              key: 'PickupTruck',
              importance: 2,
              displayHeight: 75,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Pickup flatbed truck (top view)',
              key: 'PickupFlatBedTruckTop',
              importance: 3,
              displayHeight: 90,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Pickup flatbed truck',
              key: 'PickupFlatBedTruck',
              importance: 4,
              displayHeight: 85,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'commercial',
          icon: 'commercial',
          name: 'Commercial',
          symbols: [
            {
              name: 'Bus (top view)',
              key: 'BusTop',
              importance: 1,
              displayHeight: 80,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Bus',
              key: 'Bus',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Delivery Van (top view)',
              key: 'DeliveryVanTop',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Delivery Van',
              key: 'DeliveryVanSide',
              importance: 4,
              displayHeight: 90,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'School Bus (top view)',
              key: 'SchoolBustop',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'School Bus',
              key: 'SchoolBus',
              importance: 6,
              displayHeight: 125,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Snow Plow',
              key: 'SnowPlow',
              importance: 7,
              displayHeight: 135,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Snow Plow w/ snow',
              key: 'SnowPlowWithSnow',
              importance: 8,
              displayHeight: 115,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Dump Truck (top view)',
              key: 'DumpTruckTop',
              importance: 9,
              displayHeight: 120,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Dump Truck',
              key: 'DumpTruckSide',
              importance: 10,
              displayHeight: 125,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Metro (top view)',
              key: 'MetroTop',
              importance: 11,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Metro',
              key: 'MetroSide',
              importance: 12,
              displayHeight: 110,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Tow Truck (top view)',
              key: 'TowTruckTop',
              importance: 13,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Tow Truck',
              key: 'TowTruck',
              importance: 14,
              displayHeight: 125,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Concrete Truck (top view)',
              key: 'ConcreteTruckTop',
              importance: 15,
              displayHeight: 125,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Concrete Truck',
              key: 'ConcreteTruck',
              importance: 16,
              displayHeight: 115,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Logging Truck (top view)',
              key: 'LoggingTruckTop',
              importance: 17,
              displayHeight: 120,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Logging Truck',
              key: 'LoggingTruckSide',
              importance: 18,
              displayHeight: 75,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Tractor Co 1',
              key: 'TractorCo1',
              importance: 19,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Tractor Co 2',
              key: 'TractorCo2',
              importance: 20,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Forklift',
              key: 'ForkLift',
              importance: 21,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Fuel Transport',
              key: 'FuelTransport',
              importance: 22,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'emergency',
          icon: 'emergency',
          name: 'Emergency',
          symbols: [
            {
              name: 'Police Vehicle (top view)',
              key: 'PoliceVehicleTop',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Police Vehicle',
              key: 'PoliceVehicle',
              importance: 2,
              displayHeight: 80,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Ambulance (top view)',
              key: 'AmbulanceTop',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Ambulance',
              key: 'Ambulance',
              importance: 4,
              displayHeight: 105,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'FireTruck (top view)',
              key: 'FireTruckTop',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'FireTruck',
              key: 'FireTruck',
              importance: 6,
              displayHeight: 120,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'semi-trailers',
          icon: 'semi-trailers',
          name: 'Semi/Trailers',
          symbols: [
            {
              name: 'Semi Truck',
              key: 'SemiTruck',
              importance: 1,
              displayHeight: 160,
              isHeightAdaptive: true,
              shouldAdaptIcon: true,
            },
            {
              name: 'Semi Trailer (top view)',
              key: 'SemiTrailerTop',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Semi Trailer',
              key: 'SemiTrailer',
              importance: 3,
              displayHeight: 180,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Semi Trailer Flatbed (top view) ',
              key: 'SemiTrailerFlatBedtop',
              importance: 4,
              displayHeight: 120,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Car Transport trailer',
              key: 'CarTransportTrailer',
              importance: 5,
              displayHeight: 200,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: '28 ft Trailer',
              key: 'Trailer28FT',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: '36 ft Trailer',
              key: 'Trailer36FT',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: '52 ft Trailer',
              key: 'Trailer52FT',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'marine',
          icon: 'marine',
          name: 'Marine',
          symbols: [
            {
              name: 'Boat w/ Trailer',
              key: 'BoatWithTrailer',
              importance: 1,
              displayHeight: 150,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Boat Trailer',
              key: 'BoatTrailer',
              importance: 2,
              displayHeight: 150,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'recreational',
          icon: 'recreational',
          name: 'Recreational',
          symbols: [
            {
              name: 'Motorcycle (top view)',
              key: 'MotorcycleTop',
              importance: 1,
              displayHeight: 60,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Motorcycle',
              key: 'Motorcycle',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Luxury Boat',
              key: 'LuxuryBoat',
              importance: 3,
              displayHeight: 200,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Bicycle (top view)',
              key: 'BicycleTop',
              importance: 4,
              displayHeight: 35,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Bicycle',
              key: 'Bicycle',
              importance: 5,
              displayHeight: 80,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Mini Caravan',
              key: 'MiniCaravan',
              importance: 6,
              displayHeight: 150,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'ATV (top view)',
              key: 'ATVTop',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: true,
            },
            {
              name: 'ATV',
              key: 'ATV',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Dirt Bike',
              key: 'DirtBike',
              importance: 9,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Snow Mobile',
              key: 'SnowMobile',
              importance: 10,
              displayHeight: 80,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'rail',
          icon: 'rail',
          name: 'Rail',
          symbols: [
            {
              name: 'Train Engine',
              key: 'TrainEngine',
              importance: 1,
              displayHeight: 140,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Flatcar',
              key: 'RailFlatCar',
              importance: 2,
              displayHeight: 80,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Freight Car',
              key: 'FreightCar',
              importance: 3,
              displayHeight: 150,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Container Boxcar',
              key: 'TrainContainerBox',
              importance: 4,
              displayHeight: 150,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'other',
          icon: 'other',
          name: 'Other',
          symbols: [
            {
              name: 'Farm Tractor (top view)',
              key: 'FarmTractorTop',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Farm Tractor',
              key: 'FarmTractor',
              importance: 2,
              displayHeight: 120,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Tractor Trolley',
              key: 'TractorTrolley',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Bulldozer',
              key: 'Bulldozer',
              importance: 4,
              displayHeight: 150,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Horse Buggy',
              key: 'HorseBuggy',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Horse Carriage',
              key: 'HorseCarriage',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Military Tank',
              key: 'MilitaryTank',
              importance: 7,
              displayHeight: 150,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Military Truck',
              key: 'MilitaryTruck',
              importance: 8,
              displayHeight: 120,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
            {
              name: 'Airplane (top view)',
              key: 'AirplaneTop',
              importance: 9,
              displayHeight: 500,
              shouldAdaptIcon: true,
              isHeightAdaptive: false,
            },
          ],
        },
      ],
    },
    {
      key: 'objects',
      name: 'Objects',
      subCategories: [
        {
          key: 'accident-scene',
          icon: 'accident-scene',
          name: 'Accident Scene',
          symbols: [
            {
              name: 'Laying Body',
              key: 'PersonLaying',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Resting Body w/ Blood',
              key: 'PersonRestingBody',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Pooled Blood',
              key: 'WaterBloodSpillPooledBlood',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Accident Impact',
              key: 'AccidentImpact',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Car Skid Marks',
              key: 'CarSkidMarks',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Motorcycle Skid Marks',
              key: 'MotorcycleSkid',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Person Standing',
              key: 'PersonStanding',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Child Laying',
              key: 'ChildLaying',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Child Standing',
              key: 'ChildStanding',
              importance: 9,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'animals',
          icon: 'animals',
          name: 'Animals',
          symbols: [
            {
              name: 'Dog (top view)',
              key: 'DogTop',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Dog',
              key: 'Dog',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Cat (top view)',
              key: 'CatTop',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Bird',
              key: 'Bird',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Deer',
              key: 'Deer',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Cow (top view)',
              key: 'CowTop',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Cow',
              key: 'Cow',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Horse (top view)',
              key: 'HorseTop',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Horse',
              key: 'HorseSide',
              importance: 9,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Alligator',
              key: 'Alligator',
              importance: 10,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Pig (top view)',
              key: 'PigTop',
              importance: 11,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Sheep',
              key: 'Sheep',
              importance: 12,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Turkey',
              key: 'Turkey',
              importance: 13,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Donkey',
              key: 'Donkey',
              importance: 14,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Armadillo (top view)',
              key: 'ArmadilloTop',
              importance: 15,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Armadillo',
              key: 'ArmadilloSide',
              importance: 16,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Bear',
              key: 'Bear',
              importance: 17,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'traffic-signals',
          icon: 'traffic-signals',
          name: 'Traffic Signals',
          symbols: [
            {
              name: 'Light Traffic 1',
              key: 'TrafficLight1',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Light Traffic 2',
              key: 'TrafficLight2',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Light Traffic 3',
              key: 'TrafficLight3',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Light Traffic 4',
              key: 'TrafficLight4',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'road-objects',
          icon: 'road-objects',
          name: 'Road Objects',
          symbols: [
            {
              name: 'Guardrail',
              key: 'Guardrail',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Sidewalk',
              key: 'SidewalkSymbol',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Sidewalk Track',
              key: 'SidewalkTrack',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Railway Track',
              key: 'RailwayTrack',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Utility Pole',
              key: 'UtilityPole',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Utility Pole (top view)',
              key: 'UtilityPoleTop',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Street Lamp',
              key: 'StreetLamp',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Street Lamp (top view)',
              key: 'StreetLampTop',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Mailbox',
              key: 'Mailbox',
              importance: 9,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Fence',
              key: 'Fence',
              importance: 10,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Garbage Can',
              key: 'GarbageCan',
              importance: 11,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Fire Hydrant',
              key: 'FireHydrant',
              importance: 12,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Crosswalk',
              key: 'Crosswalk',
              importance: 13,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Construction Barrel',
              key: 'ConstructionBarrel',
              importance: 14,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Construction Barrel (top view)',
              key: 'ConstructionBarrelTop',
              importance: 15,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Construction Cone',
              key: 'ConstructionCone',
              importance: 16,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Guardrail (top view)',
              key: 'GuardrailTop',
              importance: 17,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Highway Cable Barrier',
              key: 'HighwayCableBarrier',
              importance: 18,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Cement Barracade',
              key: 'CementBarracade',
              importance: 19,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Concrete Barrier',
              key: 'ConcreteBarrier',
              importance: 20,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Debris',
              key: 'Debris',
              importance: 21,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Ditch',
              key: 'Ditch',
              importance: 22,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Misc Object (Question Mark)',
              key: 'MiscObjectQuestionMark',
              importance: 23,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'nature-objects',
          icon: 'nature-objects',
          name: 'Nature Objects',
          symbols: [
            {
              name: 'Tree (top view)',
              key: 'TreeTop',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Tree',
              key: 'Tree',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Body Of Water',
              key: 'BodyOfWater',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Stream Edge',
              key: 'StreamEdge',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Boulder',
              key: 'Boulder',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
      ],
    },
    {
      key: 'road-signs',
      name: 'Road Signs',
      subCategories: [
        {
          key: 'signs',
          icon: 'signs',
          name: 'Signs',
          symbols: [
            {
              name: 'North Arrow',
              key: 'NorthArrow',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Add Lane Left',
              key: 'AddLaneLeft',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Add Lane Right',
              key: 'AddLaneRight',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Stop Sign 1',
              key: 'StopSign1',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Stop Sign 2',
              key: 'StopSign2',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Keep Right',
              key: 'KeepRight',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Do Not Enter',
              key: 'DoNotEnter',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Dead End',
              key: 'DeadEnd',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Outlet',
              key: 'NoOutlet',
              importance: 9,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Wrong Way',
              key: 'WrongWay',
              importance: 10,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'All Way',
              key: 'AllWay',
              importance: 11,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Four Way',
              key: 'FourWay',
              importance: 12,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Two Directions',
              key: 'TwoDirections',
              importance: 13,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'One Way Left',
              key: 'OneWayLeft1',
              importance: 14,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'One Way Right 1',
              key: 'OneWayRight1',
              importance: 15,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'One Way Right 2',
              key: 'OneWayRight2',
              importance: 16,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'One Direction Right',
              key: 'OneDirectionRight1',
              importance: 17,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'One Direction Left',
              key: 'OneDirectionLeft1',
              importance: 18,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Begin One Way',
              key: 'BeginOneWay',
              importance: 19,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'End One Way',
              key: 'EndOneWay',
              importance: 20,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Turns',
              key: 'NoTurns',
              importance: 21,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No U Turn',
              key: 'NoUTurn',
              importance: 22,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Left Turn',
              key: 'NoLeftTurn',
              importance: 23,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Right Turn',
              key: 'NoRightTurn',
              importance: 24,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Parking 1',
              key: 'NoParking1',
              importance: 25,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Parking 2',
              key: 'NoParking2',
              importance: 26,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Turn On Red 1',
              key: 'NoTurnOnRed1',
              importance: 27,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Turn On Red 2',
              key: 'NoTurnOnRed2',
              importance: 28,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Turn On Red 3',
              key: 'NoTurnOnRed3',
              importance: 29,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Railroad Crossing',
              key: 'RailroadCrossing',
              importance: 30,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Roundabout Sign',
              key: 'RoundaboutSign',
              importance: 31,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Yield Sign 1',
              key: 'YieldSign1',
              importance: 32,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Yield Sign 2',
              key: 'YieldSign2',
              importance: 33,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Stop For Pedestrians',
              key: 'StopForPedestrians',
              importance: 34,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Yield To Pedestrians',
              key: 'YieldToPedestrians',
              importance: 35,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Handicap Sign',
              key: 'HandicapSign',
              importance: 36,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Trucks 1',
              key: 'NoTrucks1',
              importance: 37,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Trucks 2',
              key: 'NoTrucks2',
              importance: 38,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Motor Vehicles',
              key: 'NoMotorVehicles',
              importance: 39,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Speed Bump',
              key: 'SpeedBump',
              importance: 40,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Symbol Sign',
              key: 'SymbolSign',
              importance: 41,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Universal Sign',
              key: 'UniversalSign',
              importance: 42,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Triangle Sign',
              key: 'TriangleSign',
              importance: 43,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Not to Scale',
              key: 'NottoScale',
              importance: 44,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'nhtsa',
          icon: 'nhtsa',
          name: 'NHTSA',
          symbols: [
            {
              name: 'Automobile',
              key: 'Automobile',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Automobile Damage',
              key: 'AutomobileDamage',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Area of Impact',
              key: 'AreaofImpact',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Pedestrian or Animal',
              key: 'PedestrianorAnimalSketch',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Head On',
              key: 'HeadOn',
              importance: 5,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Head on Side swipe',
              key: 'HeadonSideswipe',
              importance: 6,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Overturned',
              key: 'Overturned',
              importance: 7,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Parked Vehicle',
              key: 'ParkedVehicle',
              importance: 8,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Pedestrian',
              key: 'PedestrianSketch',
              importance: 9,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Out of Control Spinout',
              key: 'Outofcontrolspinout',
              importance: 10,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Overtaking Side Swipe',
              key: 'OvertakingSideSwipe',
              importance: 11,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Overtaking Turn',
              key: 'OvertakingTurn',
              importance: 12,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Bicycle Motorcycle',
              key: 'BicycleMotorcycleSketch',
              importance: 13,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Marked Center Line',
              key: 'MarkedCenterLine',
              importance: 14,
              displayHeight: 10,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Marked Lane Line',
              key: 'MarkedLaneLine',
              importance: 15,
              displayHeight: 10,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'No Passing Line',
              key: 'NoPassingLine',
              importance: 16,
              displayHeight: 10,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Rear End',
              key: 'RearEnd',
              importance: 17,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Sign',
              key: 'SignSketch',
              importance: 18,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Signal',
              key: 'SignalSketch',
              importance: 19,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Signal Overhanging',
              key: 'SignalOverhangingSketch',
              importance: 20,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Skid Marks',
              key: 'SkidMarks',
              importance: 21,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Street Light',
              key: 'StreetLightSketch',
              importance: 22,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Vehicle backing',
              key: 'VehicleBacking',
              importance: 23,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Vehicle or Bicycle Not Parked',
              key: 'VehicleorBicycleNotParked',
              importance: 24,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Animal',
              key: 'Animal',
              importance: 25,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Approach Turn',
              key: 'ApproachTurn',
              importance: 26,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Street Light Over Hanging',
              key: 'StreetLightOverHanging',
              importance: 27,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Streetcar Bus',
              key: 'StreetcarBusSketch',
              importance: 28,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Tractor and Trailer',
              key: 'TractorandTrailerSketch',
              importance: 29,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Train',
              key: 'Train',
              importance: 30,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Truck',
              key: 'TruckSketch',
              importance: 31,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Abutment or wall',
              key: 'Abutmentorwall',
              importance: 32,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Railroad Track',
              key: 'RailroadTrack',
              importance: 33,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Pavement Edge',
              key: 'PavementEdge',
              importance: 34,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Broadside',
              key: 'Broadside',
              importance: 35,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Camera',
              key: 'Camera',
              importance: 36,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Debris',
              key: 'Debris2Sketch',
              importance: 37,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Dirt Shoulder',
              key: 'DirtShoulder',
              importance: 38,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Double line',
              key: 'Doubleline',
              importance: 39,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Embankment',
              key: 'Embankment',
              importance: 40,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Fence',
              key: 'FenceSketch',
              importance: 41,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Fluid Spill',
              key: 'FluidSpillSketch',
              importance: 42,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Guard Fence or Rail',
              key: 'GuardFenceorRail',
              importance: 43,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'House',
              key: 'HouseSketch',
              importance: 44,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'House View Obstructed',
              key: 'HouseviewObstructed',
              importance: 45,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'AOI',
              key: 'AOI',
              importance: 46,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
      ],
    },
    {
      key: 'buildings',
      name: 'Buildings',
      subCategories: [
        {
          key: 'buildings',
          icon: 'buildings',
          name: 'Buildings',
          symbols: [
            {
              name: 'House',
              key: 'House',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'House (top view)',
              key: 'HouseTop',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Building',
              key: 'Building',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'Building (top view)',
              key: 'BuildingTop',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
      ],
    },
    {
      key: 'measurements',
      name: 'Measurements',
      subCategories: [
        {
          key: 'station-line',
          icon: 'station-line',
          name: 'Station Line',
          functype: FUNCTION_TYPE.STATIONLINE,
          symbols: [
            {
              name: 'North Station Line',
              key: 'North_Station_Line',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'South Station Line',
              key: 'South_Station_Line',
              importance: 2,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'East Station Line',
              key: 'East_Station_Line',
              importance: 3,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
            {
              name: 'West Station Line',
              key: 'West_Station_Line',
              importance: 4,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'triangulation',
          icon: 'triangulation',
          name: 'Triangulation',
          functype: FUNCTION_TYPE.TRIANGULATIONNETWORK,
          symbols: [
            {
              name: 'Reference Point',
              key: 'Reference_Point',
              importance: 1,
              displayHeight: 100,
              shouldAdaptIcon: false,
              isHeightAdaptive: false,
            },
          ],
        },
        {
          key: 'xy-measurement',
          icon: 'xy-measurement',
          name: 'X-Y Measurement',
          functype: FUNCTION_TYPE.XYMEASUREMENT,
          symbols: [{ name: 'X-Y Measurement', key: 'XY_Measurement', importance: 1 }],
        },
      ],
    },
  ],
};

export default symbolData;
