import React from 'react';
import {
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetMatrixString,
  GetRotateMatrix,
  LengthBetweenPoints,
  matrixMultiply,
} from '@src/data/CommonFunc';
import { GetMatrixRotate } from '@src/data/BusinessFun';

function Image({ object }) {
  let anchors = object.handlepoint;
  const x1 = anchors[0][0];
  const y1 = anchors[0][1];
  var center = anchors[0];
  var angle = getAngleBy2Points(anchors[0], anchors[1]);
  let matrix = GetMatrixRotate(center, angle);
  let width = LengthBetweenPoints(anchors[0], anchors[1]);
  let height = LengthBetweenPoints(anchors[1], anchors[2]);
  return (
    <g
      id={object.functype}
      transform={`
        scale(1, -1)
        translate(0, 0)
        rotate(${(-angle * 180) / Math.PI}, ${anchors[3][0]}, ${-anchors[3][1]})
      `}
    >
      <image xlinkHref={object.imagesrc} x={anchors[3][0]} y={-anchors[3][1]} width={width} height={height}></image>
    </g>
  );
}

export default Image;
