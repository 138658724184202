import { isArray } from 'lodash';
import GeomSize from './GeomSize';
import GeomVector from './GeomVector';
import Utility from './Utility';
import UtilityMath from './UtilityMath';

/**
 * A geometry point
 */
class GeomPoint {
  /**
   * @type {number}
   */
  x;

  /**
   * @type {number}
   */
  y;

  constructor(...args) {
    if (args.length === 1) {
      if (isArray(args[0])) {
        // new GeomPoint([x, y])
        [this.x, this.y] = args[0];
      } else {
        // new GeomPoint({x, y})
        const point = args[0];
        this.x = point.x;
        this.y = point.y;
      }
    } else {
      // new GeomPoint(x, y)
      [this.x, this.y] = args;
    }
    if (!typeof this.x === 'number' || !typeof this.y === 'number') {
      console.error(`Incorrect arguments: ${args}`);
    }
  }

  /**
   *
   * @param {GeomPoint} head
   * @returns {number}
   */
  angle(head) {
    if (this.isEqual(head)) {
      return 0;
    }
    var a = 0;
    if (this.y === head.y) {
      if (head.x > this.x) {
        a = 0;
      } else {
        a = Math.PI;
      }
    } else {
      a = Math.atan((head.y - this.y) / (head.x - this.x));
      if (head.y > this.y) {
        a = Math.PI * 2 - a;
      }
    }
    return a;
  }

  /**
   *
   * https://stackoverflow.com/questions/1211212/how-to-calculate-an-angle-from-three-points
   * http://jsfiddle.net/d3aZD/88/
   * @param {GeomPoint} p0
   * @param {GeomPoint} p2
   * @returns {number} - [0, PI]
   */
  radianBetweenThreePoints(p0, p2) {
    var b = Math.pow(this.x - p0.x, 2) + Math.pow(this.y - p0.y, 2),
      a = Math.pow(this.x - p2.x, 2) + Math.pow(this.y - p2.y, 2),
      c = Math.pow(p2.x - p0.x, 2) + Math.pow(p2.y - p0.y, 2);
    var value = (a + b - c) / Math.sqrt(4 * a * b);
    var result = {
      quad: value > 0 ? '14' : '23', // Quadrant
      radian: Math.acos(value)
    }
    if (isNaN(result.radian)) {
      return {
        quad: '14',
        radian: 0
      };
    } else {
      return result;
    }
  }

  /**
   *
   * @param {GeomPoint} p2
   * @param {GeomPoint} p3
   * @returns {number} - [0, 360]
   */
  angleBetweenThreePoints(p2, p3) {
    var { radian, quad } = this.radianBetweenThreePoints(p2, p3);
    var utilityMath = new UtilityMath();
    return utilityMath.toDegree(radian);
  }

  /**
   * Get the radian between 2 vectors which starts from current point
   * 获取两个向量之间的夹角弧度制
   * @param {GeomPoint} head1
   * @param {GeomPoint} head2
   * @returns {number} - [0, PI]
   */
  radianBetweenTwoPoints(head1, head2) {
    var v1 = this.getVector(head1);
    var v2 = this.getVector(head2);
    return Math.acos(v1.angleCos(v2));
  }

  /**
   *
   * @param {GeomPoint} head
   * @return {number}
   */
  AngleFromHorizontal(head) {
    var a = this.AngleToHorizontal(head);
    if (0 === a) {
      return 0;
    }
    return Math.PI * 2 - this.AngleToHorizontal(head);
  }

  /**
   * Rotation (anticlockwise) angle to positive horizontal axis
   * @param {GeomPoint} head
   * @returns {number} - radian rotation angle range=[0, PI]
   */
  AngleToHorizontal(head) {
    var a = 0;
    if (this.isEqual(head)) {
      return 0;
    }
    if (this.y === head.y) {
      if (head.x > this.x) {
        a = 0;
      } else {
        a = Math.PI;
      }
    } else {
      // var ptNew = this.clone();
      // var b = Math.abs(head.x - this.x) + Math.abs(head.y - this.y);
      // ptNew.offset(b, 0);
      // a = Math.abs(this.radianBetweenTwoPoints(head, ptNew));
      a = Math.abs(this.radianBetweenTwoPoints(head, new GeomPoint(this.x + 1, this.y)));
      if (head.y > this.y) {
        a = Math.PI * 2 - a;
      }
    }
    return a;
  }

  /**
   * @returns {GeomPoint}
   */
  clone() {
    return new GeomPoint(this.x, this.y);
  }

  /**
   * 求两点间的直线距离
   * @param {GeomPoint} pt
   */
  distance(pt) {
    return Math.sqrt(this.distanceSquared(pt));
  }

  distanceSquared(pt) {
    var tX = this.x - pt.x;
    var tY = this.y - pt.y;
    return tX * tX + tY * tY;
  }

  /**
   * 求倾斜角
   * @param {GeomPoint} pt
   * @returns {number} atan2
   */
  getInclineAngle(pt) {
    var x, y;
    x = pt.x - this.x;
    y = pt.y - this.y;
    return Math.atan2(y, x);
  }

  /**
   * Get a vector pointing to given `pt`
   * @param {GeomPoint} pt
   * @returns {GeomVector}
   */
  getVector(pt) {
    return new GeomVector(pt.x - this.x, pt.y - this.y);
  }

  isEqual(pt) {
    return this.x == pt.x && this.y == pt.y;
  }

  isNotEqual(pt) {
    return this.x !== pt.x || this.y !== pt.y;
  }

  /**
   * 求两点直线距离的中点
   * @param {GeomPoint} pt
   */
  midPoint(pt) {
    var ptMid = new GeomPoint((this.x + pt.x) / 2, (this.y + pt.y) / 2);
    if (this.x == pt.x) {
      ptMid.x = this.x;
    }
    if (this.y == pt.y) {
      ptMid.y = this.y;
    }
    return ptMid;
  }

  /**
   * Move point
   * @param {number} offsetX
   * @param {number} offsetY
   */
  offset(offsetX, offsetY) {
    this.x += offsetX;
    this.y += offsetY;
  }

  /**
   * Move towards to a given point by length
   * @param {GeomPoint} pt
   * @param {number} length
   */
  offsetToward(pt, length) {
    var v;
    var scale;
    if (this.isNotEqual(pt) && length) {
      if (this.y === pt.y) {
        if (this.x < pt.x) {
          this.x += length;
        } else {
          this.x -= length;
        }
      } else {
        if (this.x === pt.x) {
          if (this.y < pt.y) {
            this.y += length;
          } else {
            this.y -= length;
          }
        } else {
          v = this.getVector(pt);
          var d = Math.sqrt(v.vx * v.vx + v.vy * v.vy);
          scale = length / d;
          this.offset(v.vx * scale, v.vy * scale);
        }
      }
    }
  }

  /**
   *
   * @param {number} angle
   * @param {GeomPoint} ptPin
   */
  rotate(angle, ptPin) {
    var ptPinNew = ptPin.clone();
    var ptNew = this.clone();
    var a = Math.cos(angle);
    var b = Math.sin(angle);
    ptNew.offset(-ptPinNew.x, -ptPinNew.y);
    this.x = ptNew.x * a - ptNew.y * b;
    this.y = ptNew.x * b + ptNew.y * a;
    this.offset(ptPinNew.x, ptPinNew.y);
  }

  /**
   * Rotation centered on originPoint - move to origin; rotate; move back
   * @param {number} angle radian
   * @param {GeomPoint} originPoint
   */
  rotateRad(angle, originPoint) {
    // TODO: use matrix
    var ptPinNew = new GeomPoint(0, 0);
    var ptNew = this.clone();
    var a = Math.cos(angle);
    var b = Math.sin(angle);
    if (Utility.isValid(originPoint) && Utility.isTypeofNumber(originPoint.x)) {
      ptPinNew = originPoint.clone();
    }
    ptNew.offset(-ptPinNew.x, -ptPinNew.y);
    this.x = ptNew.x * a - ptNew.y * b;
    this.y = ptNew.x * b + ptNew.y * a;
    this.offset(ptPinNew.x, ptPinNew.y);
  }

  scale(sX, sY) {
    this.x *= sX;
    this.y *= sY;
  }

  subtract(pt) {
    return new GeomSize(this.x - pt.x, this.y - pt.y);
  }

  toString() {
    var str = '';
    if (!isNaN(this.x) && !isNaN(this.y)) {
      str = Utility.toFixed(this.x, 4) + ',' + Utility.toFixed(this.y, 4);
    }
    return str;
  }
}

export default GeomPoint;
