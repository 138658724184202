import * as React from 'react';

function SvgIconPickup(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0-.002h24V24H0z" />
        <path
          d="M23 12h-9V8a1 1 0 00-1-1H8.677c-.823 0-1.552.494-1.856 1.258L5.323 12H4c-2.206 0-4 1.795-4 4v3a1 1 0 001 1h2.185A2.993 2.993 0 006 22a2.996 2.996 0 002.816-2h7.369A2.994 2.994 0 0019 22a2.997 2.997 0 002.816-2H22c1.103 0 2-.896 2-2v-5a1 1 0 00-1-1zM12 9v3H7.477l1.2-3H12zM6 20c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm13 0c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm3-2h-.184A2.996 2.996 0 0019 16a2.993 2.993 0 00-2.815 2H8.816A2.996 2.996 0 006 16a2.993 2.993 0 00-2.815 2H2v-2c0-1.104.897-2 2-2h18v4z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconPickup;
