import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomPolyArc from './GeomPolyArc';
import StripeHiddenSegment from './StripeHiddenSegment';
import _Street from './_Street';
import UtilityGeom from './UtilityGeom';
import StreetIntersectionArea from './StreetIntersectionArea';
import StreetBorder from './StreetBorder';
import * as workData from './WorkData';

class StreetInteriorStripe {
  /** @type {StripeHiddenSegment[]} */
  hiddenSegs;

  /**
   *
   * @param {{functype: 'StreetNew'}} street
   * @param {{type: 'stripe'}} stripe
   */
  constructor(street, stripe) {
    this.street = street;
    this.streetId = street.operateid;
    if (stripe) {
      this.stripe = stripe;
      this.stripeKey = stripe.key;
      this.patterns = stripe.primary.patterns;
      this.axisPatterns = stripe.axis ? stripe.axis.patterns : null;
      this.gpaStripe = new GeomPolyArc(stripe.segments);
      this.gpaAxis = stripe.axis ? new GeomPolyArc(stripe.offsetArcSegments) : undefined;
    }
    this.isectOffsets = [];
    this.hiddenSegs = [];
    this.axisHiddenSegs = [];
  }

  /**
   *
   * @param {number} setbackOffset - CurbReturn setback 点的偏移量
   */
  addIntersectOffset(setbackOffset) {
    this.isectOffsets.push(setbackOffset);
  }

  /**
   * 获取 StreetEndCap 交叉点
   * @param {GeomLine} seg
   */
  getStreetEndCapIntersection(seg) {
    return this.gpaStripe.getSegmentIntersection(seg);
  }

  /**
   *
   * @param {StreetBorder} streetBorder
   */
  getStripeIntersection(streetBorder) {
    return this.gpaStripe.getIntersection(streetBorder.gpaStripe);
  }

  /**
   *
   * @param {{functype: 'StreetNew'}[]} streets
   * @param {StreetIntersectionArea[]} streetIntersectionAreas
   */
  computeVisibility(streets, streetIntersectionAreas) {
    // console.log('streetIntersectionAreas', streetIntersectionAreas);
    var stripeLength = streetIntersectionAreas.length > 0 ? this.gpaStripe.getLength() : undefined;
    var axisLength;
    if (this.gpaAxis) {
      axisLength = streetIntersectionAreas.length > 0 ? this.gpaAxis.getLength() : undefined;
    }
    // console.log('street', this.street);
    // console.log('---------------------------');
    for (var idx = 0; idx < streetIntersectionAreas.length; idx += 1) {
      var streetIntersectionArea = streetIntersectionAreas[idx];
      var anotherStreetIntersectionArea = idx < streetIntersectionAreas.length - 1 ? streetIntersectionAreas[idx + 1] : undefined;
      if (streetIntersectionArea.suppressStripes) {
        this.computeVisibilityInIntArea(streetIntersectionArea, anotherStreetIntersectionArea, stripeLength, axisLength, 96);
      }
    }
  }

  /**
   * 计算交叉区域中隐藏的 segment
   * @param {StreetIntersectionArea} streetIntersectionArea
   * @param {streetIntersectionArea} anotherStreetIntersectionArea
   * @param {number} stripeLength
   * @param {number} axisLength
   * @param {number} interiorStripeSegmentMin
   */
  computeVisibilityInIntArea(streetIntersectionArea, anotherStreetIntersectionArea, stripeLength, axisLength, interiorStripeSegmentMin) {
    // console.log('streetIntersectionArea', streetIntersectionArea);
    var startOffset0 = 0,
      startOffset1 = 0,
      stopOffset0 = stripeLength,
      stopOffset1 = axisLength,
      intersectPoints,
      hiddenSegment,
      d3d1,
      d3d2,
      d3d3;
    switch (streetIntersectionArea.intLocation) {
      case 1:
        intersectPoints = this.gpaStripe.getIntersection(streetIntersectionArea.gpaStop);
        if (intersectPoints.length > 0) {
          stopOffset0 = intersectPoints[0].offset0;
        }
        if (this.gpaAxis) {
          intersectPoints = this.gpaAxis.getIntersection(streetIntersectionArea.gpaStop);
          if (intersectPoints.length > 0) {
            stopOffset1 = intersectPoints[0].offset0;
          }
        }
        break;
      case 2:
        intersectPoints = this.gpaStripe.getIntersection(streetIntersectionArea.gpaStart);
        if (intersectPoints.length > 0) {
          startOffset0 = intersectPoints[0].offset0;
        }
        if (this.gpaAxis) {
          intersectPoints = this.gpaAxis.getIntersection(streetIntersectionArea.gpaStart);
          if (intersectPoints.length > 0) {
            startOffset1 = intersectPoints[0].offset0;
          }
        }
        break;
      case 3:
        intersectPoints = this.gpaStripe.getIntersection(streetIntersectionArea.gpaStart);
        startOffset0 = intersectPoints.length > 0 ? intersectPoints[0].offset0 : 0;
        intersectPoints = this.gpaStripe.getIntersection(streetIntersectionArea.gpaStop);
        stopOffset0 = intersectPoints.length > 0 ? intersectPoints[0].offset0 : stripeLength;
        if (this.gpaAxis) {
          intersectPoints = this.gpaAxis.getIntersection(streetIntersectionArea.gpaStart);
          startOffset1 = intersectPoints.length > 0 ? intersectPoints[0].offset0 : 0;
          intersectPoints = this.gpaAxis.getIntersection(streetIntersectionArea.gpaStop);
          stopOffset1 = intersectPoints.length > 0 ? intersectPoints[0].offset0 : axisLength;
        }
        break;
      default:
        break;
    }

    // TODO: refactor these conditional statements
    if (stopOffset0 > startOffset0) {
      hiddenSegment = undefined;
      if (this.hiddenSegs.length > 0) {
        hiddenSegment = this.hiddenSegs[this.hiddenSegs.length - 1];
      }
      if (hiddenSegment && startOffset0 - hiddenSegment.stopOffset < interiorStripeSegmentMin) {
        hiddenSegment.extend(stopOffset0, stripeLength);
      } else {
        const newHiddenSegment = new StripeHiddenSegment(startOffset0, stopOffset0, stripeLength);
        // if (startOffset === 0) {
        //   console.log(`start:${startOffset}, stop:${stopOffset}, length:${stripeLength}`);
        // }
        this.hiddenSegs.push(newHiddenSegment);
      }
    }

    if (this.gpaAxis && stopOffset1 > startOffset1) {
      hiddenSegment = undefined;
      if (this.axisHiddenSegs.length > 0) {
        hiddenSegment = this.axisHiddenSegs[this.axisHiddenSegs.length - 1];
      }
      if (hiddenSegment && startOffset1 - hiddenSegment.stopOffset < interiorStripeSegmentMin) {
        hiddenSegment.extend(stopOffset1, axisLength);
      } else {
        this.axisHiddenSegs.push(new StripeHiddenSegment(startOffset1, stopOffset1, axisLength));
      }
    }
    // console.log('hiddenSegs', this.hiddenSegs);
    // console.log('axisHiddenSegs', this.axisHiddenSegs);

    /*var _s = new _Street();
	d3d1 = _s.getAdjaCentergions(this.stripeKey);
	if (d3d1.previous && d3d1.previous.__serializedType === "divider") {
		d3d2 = d3d1.previous;
	} else {
		if (d3d1.next && d3d1.next.__serializedType === "divider") {
			d3d2 = d3d1.next;
		}
	}
	if (d3d2 && (d3d2.divider === Tenum.DividerType.raisedDepressed || d3d2.divider === Tenum.DividerType.painted)) {
		d3d3 = d3b7.getSettingInStdU(d3b7.items["curbReturnSetback_" + this.streetAgent.getSurfaceType()]);
		if (streetIntersectionArea.intLocation === 2 || streetIntersectionArea.intLocation === 3) {
			streetIntersectionArea.setMedianPointsStart(this.gpaStripe.getPointAtLength(d3cb), this.gpaStripe.getPointAtLength(d3cb + d3d3), this.getPatternAtPoint(this.gpaStripe.getPointAtLength(d3cb)));
		}
		if (streetIntersectionArea.intLocation === 1 || streetIntersectionArea.intLocation === 3) {
			streetIntersectionArea.setMedianPointsStop(this.gpaStripe.getPointAtLength(d3cd), this.gpaStripe.getPointAtLength(d3cd - d3d3), this.getPatternAtPoint(this.gpaStripe.getPointAtLength(d3cd)));
		}
	}*/
  }

  isPointInShape(segments, pt) {
    var gpa = new GeomPolyArc(segments);
    var gpo = gpa.getOffsetsToPoint(pt);
    if (gpo) {
      if (gpo.fromStartPercent > 0 && gpo.fromStartPercent < 1) {
        if (Math.abs(gpo.normal) < 240 / 2) {
          return true;
        }
      }
    }
    return false;
  }
}

export default StreetInteriorStripe;
