import React from 'react';
import {
  LengthBetweenPoints,
  IsZero,
  IsBigArc,
  FindCircle,
  IsArcDirection,
  GetCenter,
  LengthArc1,
  ByTime,
  GetArcCenter,
} from '@src/data/CommonFunc';
import {
  GetLineHeightPoints,
  GetPathArcD,
  GetPathArcDL,
  GetPathLineD,
  GetPathLineD1,
  GetShapes,
} from '@src/data/BusinessFun';
import { COLOR_TYPE, SIDE_WALK_PARAM_TYPE, THREE_POINT_STATUS_TYPE } from '@src/constant';

class SideWalk extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps({ object }) {
    this.init(object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    if (isFirst) {
      this.state = {
        object: object,
      };
    } else {
      this.setState({
        object: object,
      });
    }
  };

  getLaneShape = shapes => {
    let anchors = this.state.object.handlepoint;
    let topPoints = GetLineHeightPoints(anchors[0], anchors[2], anchors[1], SIDE_WALK_PARAM_TYPE.HEIGHT, true);
    let bottomPoints = GetLineHeightPoints(anchors[0], anchors[2], anchors[1], SIDE_WALK_PARAM_TYPE.HEIGHT, false);
    let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
    let isBigArc = IsBigArc(anchors[0], anchors[1], anchors[2], circle[2]);
    let isArcDirection = IsArcDirection(anchors[0], anchors[2], anchors[1]);
    let D = [];
    let topCircle = FindCircle(topPoints[0], topPoints[1], topPoints[2]);
    GetPathArcD(D, topPoints[0], topPoints[2], topCircle[2], isBigArc, isArcDirection);
    let bottomCircle = FindCircle(bottomPoints[0], bottomPoints[1], bottomPoints[2]);
    GetPathArcD(D, bottomPoints[0], bottomPoints[2], bottomCircle[2], isBigArc, isArcDirection);
    if (IsZero(circle[2])) {
      let length = LengthBetweenPoints(anchors[0], anchors[1]);
      let n = Math.round(length / SIDE_WALK_PARAM_TYPE.WALKSPACE);
      for (let i = 0; i < n; i++) {
        let topMovePoint = GetCenter(topPoints[0], topPoints[2], i / n);
        let bottomMovePoint = GetCenter(bottomPoints[0], bottomPoints[2], i / n);
        GetPathLineD(D, topMovePoint, bottomMovePoint);
      }
    } else {
      let isBytime = ByTime(anchors[0], anchors[2], anchors[1]) === THREE_POINT_STATUS_TYPE.BYTIME;
      let length = LengthArc1(anchors[0], anchors[1], circle, isBytime);
      let n = Math.round(length / SIDE_WALK_PARAM_TYPE.WALKSPACE);
      for (let i = 0; i < n; i++) {
        let topMovePoint = GetArcCenter(topCircle, topPoints[0], topPoints[2], i / n, isBytime);
        let bottomMovePoint = GetArcCenter(bottomCircle, bottomPoints[0], bottomPoints[2], i / n, isBytime);
        GetPathLineD(D, topMovePoint, bottomMovePoint);
      }
    }

    let hideD = [];
    GetPathArcD(hideD, topPoints[0], topPoints[2], topCircle[2], isBigArc, isArcDirection);
    GetPathArcDL(hideD, bottomPoints[2], bottomPoints[0], bottomCircle[2], isBigArc, isArcDirection == '1' ? '0' : '1');
    hideD.push('Z');
    shapes.push(
      <path
        d={hideD.join(' ')}
        key="hidewalk"
        fill={COLOR_TYPE.NONE}
        stroke={COLOR_TYPE.TRANSPARENT}
        strokeWidth={50}
      />
    );
    shapes.push(<path d={D.join(' ')} key="sidewalk" fill={COLOR_TYPE.NONE} />);
  };

  render() {
    if (this.state == null) return null;
    const { object } = this.state;
    if (object == null || object.handlepoint.length < 3) return null;
    let shapes = [];
    //用于拖拉模式下得精度选择
    this.getLaneShape(shapes);
    return <g id={object.functype}>{GetShapes(shapes)}</g>;
  }
}

export default SideWalk;
