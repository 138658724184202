import * as React from 'react';

function SvgImageSolidLine(props) {
  return (
    <svg width={75} height={7} {...props}>
      <path d="M0 3.5h75" stroke="#1D1F20" strokeWidth={2} fill="none" fillRule="evenodd" />
    </svg>
  );
}

export default SvgImageSolidLine;
