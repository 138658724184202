import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { TowPointVerticalLengthByStatus } from '@src/data/CommonFunc';
import { getArrowType, GetConnectText, GetLengthConnectLine, GetPathDPoints, GetShapes } from '@src/data/BusinessFun';
import { GetShapeStyle } from '@src/data/CommonProps';
import ArrowLength from '../ArrowLength';
import GuideLine from '../GuideLine';
import GuideCircle from '../GuideCircle';
import GuideAngle from '../GuideAngle';
import { StructureSelectDoorShape } from '@src/actions/StructureFunHandle';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import utility from '@src/data/Utility';
import { ANCHORS_TYPE, STRUCTURE_PARAM_TYPE, STRUCTURE_DOOR_TYPE, COLOR_TYPE } from '@src/constant';
import { CircleHandlePoint, SquareRectHandlePoint } from '@src/data/AnchorsPoint';
import GeomPoint from '@src/data/GeomPoint';

function SelectStructure({ object }) {
  const [joins, setJoins] = useState([]);

  if (object.handlepoint.length < 2) {
    return null;
  }

  let shapes = [];
  let marks = [];
  let guid = [];

  // TODO: add joins
  const getJoin = object => {};

  const getMarks = handlepoint => {
    // let poi = new GeomPoint(x, y); // poi: point of intersection
    let prevPointTopLeft;
    let prevPointBottomLeft;

    for (let i = 0, len = handlepoint.length; i < len; i++) {
      let j = len > 1 ? i - 2 : len - 2;
      if (handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREEND) {
        let curPtBegin = handlepoint[i - 1]; // current structure-begin
        let curPtEnd = handlepoint[i]; // current structure-end
        let prevPtEnd; // prev structure-end
        let prevPtBegin; // prev structure-begin
        let offsetBegin = 0;
        let offsetEnd = 0;
        let curThickness = curPtBegin[3].strokewidth;
        let prevThickness = 0;

        // 排除第一个Structure
        if (j !== -1 && handlepoint[j][2] === ANCHORS_TYPE.STRUCTUREEND) {
          prevPtEnd = handlepoint[j];
          prevPtBegin = handlepoint[j - 1];
          prevThickness = prevPtBegin[3].strokewidth;
          // 与上一个Structure的连接点
          if (prevPtEnd[0] === curPtBegin[0] && prevPtEnd[1] === curPtBegin[1]) {
            let poi = new GeomPoint(curPtBegin[0], curPtBegin[1]); // poi: point of intersection
            let { radian, quad } = poi.radianBetweenThreePoints(
              new GeomPoint(prevPtBegin[0], prevPtBegin[1]),
              new GeomPoint(curPtEnd[0], curPtEnd[1])
            );
            // 根据象限计算
            if (quad === '14') {
              if (radian > 0 && radian < Math.PI * 0.5) {
                offsetBegin = Math.sin(radian) * curThickness;
                offsetEnd = Math.sin(Math.PI - radian) * prevThickness;
              } else {
                offsetBegin = Math.sin(radian) * curThickness;
                offsetEnd = Math.sin(radian - Math.PI) * prevThickness;
              }
            } else {
              if (radian > Math.PI * 0.5 && radian < Math.PI) {
                offsetBegin = Math.sin(radian) * curThickness;
                offsetEnd = Math.sin(Math.PI - radian) * prevThickness;
              } else {
                offsetBegin = Math.sin(radian) * curThickness;
                offsetEnd = Math.sin(radian - Math.PI) * prevThickness;
              }
            }
          }
          // 修改上一个Structure的marks
          if (
            offsetEnd !== 0 &&
            typeof prevPointTopLeft !== 'undefined' &&
            typeof prevPointBottomLeft !== 'undefined'
          ) {
            let v1 = [prevPtEnd[0] - offsetEnd, prevPtEnd[1]];
            let v2 = [prevPtEnd[0] + offsetEnd, prevPtEnd[1]];
            let prevPointTopRight = TowPointVerticalLengthByStatus(prevPtBegin, prevPtEnd, v1, prevThickness, false);
            let prevPointBottomRight = TowPointVerticalLengthByStatus(prevPtBegin, prevPtEnd, v2, prevThickness, true);

            marks = marks
              .slice(0, -2)
              .concat([
                <ArrowLength key={j * 2 + 1} p1={prevPointTopLeft} p2={prevPointTopRight} istop={true} />,
                <ArrowLength key={j * 2 + 2} p1={prevPointBottomLeft} p2={prevPointBottomRight} istop={false} />,
              ]);
          }
        }

        let v1 = [curPtBegin[0] - offsetBegin, curPtBegin[1]];
        let v2 = [curPtBegin[0] + offsetBegin, curPtBegin[1]];
        let pointTopLeft = TowPointVerticalLengthByStatus(curPtBegin, curPtEnd, v1, curThickness, false);
        let pointTopRight = TowPointVerticalLengthByStatus(curPtBegin, curPtEnd, curPtEnd, curThickness, false);
        let pointBottomLeft = TowPointVerticalLengthByStatus(curPtBegin, curPtEnd, v2, curThickness, true);
        let pointBottomRight = TowPointVerticalLengthByStatus(curPtBegin, curPtEnd, curPtEnd, curThickness, true);

        prevPointTopLeft = pointTopLeft;
        prevPointBottomLeft = pointBottomLeft;

        marks.push(
          <ArrowLength key={i * 2 + 1} p1={pointTopLeft} p2={pointTopRight} istop={true} />,
          <ArrowLength key={i * 2 + 2} p1={pointBottomLeft} p2={pointBottomRight} istop={false} />
        );
      }
    }
  };

  const getGuid = handlepoint => {
    for (let i = 0; i < handlepoint.length; i++) {
      //找到选中点
      let [x, y, type, attr] = handlepoint[i];
      // FIXME: update component key
      if (type != ANCHORS_TYPE.CLOSEANGLE) {
        let { nearx, neary, nearline } = attr;
        //在线上
        if (nearline != -1) {
          guid.push(<GuideCircle key={i * 2 + 4} center={handlepoint[i]} />);
          continue;
        }
        //在点上
        if (nearx != -1 && neary == nearx) {
          guid.push(<GuideCircle key={i * 2 + 3} center={handlepoint[nearx]} />);
          guid.push(<GuideLine key={i * 2} p1={handlepoint[i]} />);
          continue;
        }
        // 跳过绘制的第一条structure
        // if (i > 1 && type === ANCHORS_TYPE.STRUCTUREBEGIN) {
        //   let poi = new GeomPoint(x, y); // poi: point of intersection
        //   let aCosValue = poi.radianBetweenTwoPoints(
        //     new GeomPoint(handlepoint[i - 2][0], handlepoint[i - 2][1]),
        //     new GeomPoint(handlepoint[i + 1][0], handlepoint[i + 1][1])
        //   );
        //   // 90°夹角
        //   if (aCosValue.toFixed(2) === Math.acos(0).toFixed(2)) {
        //     guid.push(<GuideAngle key={i * 2 + 5} p={[x, y]} />);
        //   }
        //   // console.log('angle acos value', (aCosValue * 180) / Math.PI);
        // }
        if (nearx != -1) {
          guid.push(<GuideLine key={i * 2} p1={handlepoint[nearx]} p2={handlepoint[i]} />);
        }
        if (neary != -1) {
          guid.push(<GuideLine key={i * 2 + 1} p1={handlepoint[neary]} p2={handlepoint[i]} />);
        }
      }
    }
  };

  const getAnchorsPoint = (shapes, handlepoint, operateid) => {
    for (let i = 0, il = handlepoint.length; i < il; i++) {
      /**
       * Structure handlepoint有三种点structurebegin, structureend和closeangle
       * 其中closeangle为旋转点
       * begin和end点在line的两侧
       */
      if (handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN && handlepoint[i][3].selectflag === true) {
        let D = [];
        D.push('M' + String(handlepoint[i][0]) + ',' + String(handlepoint[i][1]));
        D.push('L' + String(handlepoint[i + 1][0]) + ',' + String(handlepoint[i + 1][1]));
        let idLine = String(i) + '||' + operateid + '||' + ANCHORS_TYPE.STRUCTURELINE;
        shapes.push(
          <path
            key={i}
            id={idLine}
            d={D.join(' ')}
            {...GetShapeStyle().ANCHORSSTYPE}
            stroke={COLOR_TYPE.GREEN}
            pointerEvents="none"
          />
        );
        let idBegin = String(i) + '||' + ANCHORS_TYPE.STRUCTUREBEGIN;
        shapes.push(<SquareRectHandlePoint key={idBegin} id={idBegin} point={handlepoint[i]} />);
        let idEnd = String(i + 1) + '||' + ANCHORS_TYPE.STRUCTUREEND;
        shapes.push(<SquareRectHandlePoint key={idEnd} id={idEnd} point={handlepoint[i + 1]} />);
      }
      // Rotate point
      if (handlepoint[i][2] === ANCHORS_TYPE.CLOSEANGLE) {
        shapes.push(
          <CircleHandlePoint key={ANCHORS_TYPE.CLOSEANGLE} id={ANCHORS_TYPE.CLOSEANGLE} point={handlepoint[i]} />
        );
      }
    }
  };

  const getWalls = (shapes, walls) => {
    if (typeof walls != 'undefined' && walls != null && (Array.isArray(walls) && walls.length > 0)) {
      for (let i = 0, il = walls.length; i < il; i++) {
        let arr = walls[i].profile
          .replace('M', '')
          .replace('L', ',')
          .replace(' ', '')
          .split(',');
        let coords = arr.map(val => parseFloat(val));
        if (coords.length == 4) {
          shapes.push(<SquareRectHandlePoint key={walls[i].key + '-s'} point={[coords[0], coords[1]]} />);
          shapes.push(<SquareRectHandlePoint key={walls[i].key + '-e'} point={[coords[2], coords[3]]} />);
        }
      }
    }
  };

  const getDoorSelectShape = (shapes, handlepoint) => {
    let anchors = handlepoint;
    let iCount = 0;

    for (let i = 0; i < anchors.length; i++) {
      if (anchors[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
        for (let j = 4; j < anchors[i].length; j++) {
          //用于门选择拖动的时候显示
          if (anchors[i][j].doortype === STRUCTURE_DOOR_TYPE.door32 && anchors[i][j].selectflag === true) {
            iCount++;
            shapes.push(StructureSelectDoorShape(anchors[i], anchors[i + 1], j));
          }
        }
      }
    }
  };

  getAnchorsPoint(shapes, object.handlepoint, object.operateid);
  getDoorSelectShape(shapes, object.handlepoint);
  getMarks(object.handlepoint);
  getWalls(shapes, object.walls);
  getGuid(object.handlepoint);

  return (
    <g>
      <g fill="none" pointerEvents="none">
        {marks}
      </g>
      <g fill="none" pointerEvents="none">
        {guid}
      </g>
      {shapes}
    </g>
  );
}

export default SelectStructure;
