import * as React from 'react';

function SvgIconCheck(props) {
  return (
    <svg className="icon-check_svg__icon" viewBox="0 0 1024 1024" width="1em" height="1em" {...props}>
      <defs>
        <style />
      </defs>
      <path
        d="M75.712 445.712l240.176 185.52s13.248 6.624 29.808 0l591.36-493.872s84.272-17.968 68.64 71.488c-57.04 57.968-638.464 617.856-638.464 617.856s-38.096 21.536-74.544 0C256.272 790.256 12.816 523.568 12.816 523.568s-6.672-64.592 62.896-77.856z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconCheck;
