import React from 'react';
import { Menu, Dropdown, Icon } from 'antd';
import { IconDown } from '@src/components/UI/Icons';
import { TEXT_BOX_TYPE } from '@src/constant';
import { withTranslation } from 'react-i18next';

class TextBoxSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.t(`property.textBox.${props.type}`),
    };
  }

  handleClick = e => {
    if (this.props.handle) {
      this.props.handle(e.key);
    }
    this.setState({ type: e.item.props.value });
  };

  render() {
    const shapes = (
      <Menu onClick={this.handleClick.bind(this)}>
        <Menu.Item key={TEXT_BOX_TYPE.BOX} value={this.props.t(`property.textBox.${TEXT_BOX_TYPE.BOX}`)}>
          {this.props.t(`property.textBox.${TEXT_BOX_TYPE.BOX}`)}
        </Menu.Item>
        <Menu.Item key={TEXT_BOX_TYPE.UNDERLINE} value={this.props.t(`property.textBox.${TEXT_BOX_TYPE.UNDERLINE}`)}>
          {this.props.t(`property.textBox.${TEXT_BOX_TYPE.UNDERLINE}`)}
        </Menu.Item>
        <Menu.Item key={TEXT_BOX_TYPE.BARE} value={this.props.t(`property.textBox.${TEXT_BOX_TYPE.BARE}`)}>
          {this.props.t(`property.textBox.${TEXT_BOX_TYPE.BARE}`)}
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={shapes} trigger={['click']} className="custom-dropdown text-box-select">
        <div className="custom-dropdown-item">
          {this.state.type}
          <Icon component={IconDown} alt="down" />
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(TextBoxSelect);
