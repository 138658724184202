import { EXIT_DIAGRAM, SET_IMAGE_CONSUMED } from '@src/type/ethos';
import { produce } from 'immer';

const initialState = {
  imageConsumed: false,
  mapConsumed: false,
};

export default produce((draft, action) => {
  if (!draft) return initialState;

  switch (action.type) {
    case EXIT_DIAGRAM: {
      draft.imageConsumed = false;
      draft.mapConsumed = false;
      break;
    }
    case SET_IMAGE_CONSUMED: {
      draft.imageConsumed = true;
      break;
    }
    case 'SET_MAP_CONSUMED': {
      draft.mapConsumed = true;
      break;
    }
    default:
      break;
  }
});
