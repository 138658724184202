import * as React from 'react';

function SvgImageDoubleSolid(props) {
  return (
    <svg width={75} height={7} {...props}>
      <g stroke="#1D1F20" strokeWidth={2} fill="none" fillRule="evenodd">
        <path d="M0 5.5h75M0 1.5h75" />
      </g>
    </svg>
  );
}

export default SvgImageDoubleSolid;
