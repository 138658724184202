import React from 'react';
import { Tabs, Icon } from 'antd';
import { getTextPosition } from '@src/data/BusinessFun';
import { LengthBetweenPoints, ScalePoint } from '@src/data/CommonFunc';
import Utility from '@src/data/Utility';
import TabItem from '@src/components/PropMenu/TabItem';
import emitter from '@src/data/Event';
import { ROAD_CONSTANT } from '@src/type/event';
import utilitySvg from '@src/data/UtilitySvg';
import { FONT_WEIGHT_TYPE, FONT_STYLE_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';
import ArrowMenu from '@src/components/Modal/Menu/ArrowMenu';
import { EVENT_EMIT_TYPE } from '@src/type/event';

export default class ConnectLineModal extends React.Component {
  // Modal menu and items
  menu = {
    style: ['line', 'width', 'lineColor', 'lineLength', 'marks'],
    text: ['text', 'fontSize', 'color', 'style', 'display', 'position'],
    arrow: ['arrow', 'arrowType', 'width'],
  };

  state = {
    object: this.props.object,
    // pointlength: LengthBetweenPoints(this.props.object.handlepoint[0], this.props.object.handlepoint[1]),
    tabKey: 'style',
    intervalMarks: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
    });
  }

  handleStrokeDashArray = (strokepattern, strokeDash) => {
    workData.setObjectStyleLine(this.state.object.operateid, strokepattern, strokeDash);
    var obj = this.state.object;
    obj.style.strokepattern = strokepattern;
    obj.style.strokedasharray = utilitySvg.getStrokePattern(obj.style.strokepattern, obj.style.strokewidth);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStrokeWidth = width => {
    var obj = this.state.object;
    obj.style.strokewidth = width;
    // obj.style.strokedasharray = utilitySvg.getStrokepattern(obj.style.strokepattern, obj.style.strokewidth);
    obj.marks.height = width * 2;
    if (obj.arrow.width < obj.style.strokewidth * 2) {
      obj.arrow.width = obj.style.strokewidth * 2;
      obj.arrow.height = obj.arrow.width;
    }
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStroke = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleLineColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.stroke = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleFill = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleFillColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.fill = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleMarks = e => {
    const { intervalMarks, object: obj } = this.state;
    obj.marks.isexist = !intervalMarks;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj, intervalMarks: !intervalMarks });
    this.props.handle();
  };

  handleMarksLenth = length => {
    var obj = this.state.object;
    obj.marks.length = length;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  changePosition = e => {
    var obj = this.state.object;
    obj.text.position = getTextPosition(obj.text.position);
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  textLength = () => {
    const { object: obj } = this.state;
    obj.text.islength = !obj.text.islength;
    if (obj.text.islength) {
      obj.text.text = Utility.format(LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]) / 12);
    } else {
      obj.text.text = '';
    }
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  // textHandle = e => {
  //   var obj = this.state.object;
  //   obj.text.text = e.target.value;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textSizeHandle = size => {
  //   var obj = this.state.object;
  //   obj.text.size = size;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textBoldHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.bold === FONT_WEIGHT_TYPE.NORMAL) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.BOLD;
  //   } else if (obj.text.bold === FONT_WEIGHT_TYPE.BOLD) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textItalicHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.italic === FONT_STYLE_TYPE.NORMAL) {
  //     obj.text.italic = FONT_STYLE_TYPE.ITALIC;
  //   } else if (obj.text.italic === FONT_STYLE_TYPE.ITALIC) {
  //     obj.text.italic = FONT_STYLE_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textColorHandle = e => {
  //   var colortype = e.currentTarget.id;
  //   var obj = this.state.object;
  //   obj.text.color = colortype;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // pointlengthHandle = length => {
  //   var scale = (length * 12) / this.state.pointlength;
  //   var obj = this.state.object;
  //   obj.handlepoint[1] = ScalePoint(obj.handlepoint[1], scale, scale, obj.handlepoint[0]);
  //   this.setState({ pointlength: length * 12 });
  //   this.setState({ object: obj });
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu
                item={this.menu.text}
                objectId={this.props.object.operateid}
                // stateObject={this.state.object}
                // textHandle={this.textHandle}
                // textSizeHandle={this.textSizeHandle}
                // textColorHandle={this.textColorHandle}
                // textBoldHandle={this.textBoldHandle}
                // textItalicHandle={this.textItalicHandle}
                displayLength={this.props.object.text.islength}
                textLengthHandle={this.textLength}
                // textPositionHandle={this.changePosition}
              />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
              />
            </Tabs.TabPane>
          )}
          {'arrow' in this.menu && (
            <Tabs.TabPane tab="arrow" key="arrow">
              <ArrowMenu item={this.menu.arrow} objectId={this.state.object.operateid} />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
