import React from 'react';
import PropTypes from 'prop-types';
import ArrowMarker from './ArrowMarker';
import { getArrowType } from '@src/data/BusinessFun';
import { GetShapeStyle } from '@src/data/CommonProps';
import {
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetCenter,
  LengthBetweenPoints,
  TowPointVerticalLength,
  TowPointVerticalLengthByStatus,
  TowPointVerticalLengthPoints,
} from '@src/data/CommonFunc';
import Text from './Text';
import { convertToFeet, convertToStandard, getUserUnitByPixel } from '@src/data/CommonFunction';
import { COLOR_TYPE, FONT_WEIGHT_TYPE, FONT_STYLE_TYPE, TEXT_ANCHORS_TYPE, ARROW_SHOW_TYPE } from '@src/constant';

class ArrowLength extends React.Component {
  getLine = (p1, p2, id) => {
    const x1 = p1[0];
    const x2 = p2[0];
    const y1 = p1[1];
    const y2 = p2[1];
    return (
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        markerStart={getArrowType(id, ARROW_SHOW_TYPE.BOTH, 0)}
        markerEnd={getArrowType(id, ARROW_SHOW_TYPE.BOTH, 1)}
      />
    );
  };

  getText = (point1, point2, isTextTop) => {
    var thickness = 13;
    var text = convertToFeet(LengthBetweenPoints(point1, point2)) + 0.05;
    var textArr = text.toFixed(1).split('.');
    if (textArr[1] == '0') {
      text = textArr[0] + "'";
    } else {
      text = textArr[0] + "' " + textArr[1] + '"';
    }
    var angle = getAngleBy2Points(point1, point2);

    var center = GetCenterInTwoPoints(point1, point2);
    var textPoint = [];
    if (isTextTop) {
      textPoint = TowPointVerticalLengthByStatus(point1, point2, center, thickness, false);
    } else {
      textPoint = TowPointVerticalLengthByStatus(point1, point2, center, thickness, true);
    }
    var textObject = {
      text: text,
      color: COLOR_TYPE.LNCD_MAIN_COLOR,
      bold: FONT_WEIGHT_TYPE.NORMAL,
      italic: FONT_STYLE_TYPE.NORMAL,
      size: thickness * getUserUnitByPixel(),
    };
    return (
      <Text
        angle={angle}
        x={textPoint[0]}
        y={textPoint[1]}
        textAnchor={TEXT_ANCHORS_TYPE.MIDDLE}
        text={textObject}
        content={textObject.text}
      />
    );
  };

  render() {
    const { p1, p2, istop } = this.props;
    let isTextTop = istop === null ? true : istop;
    var id = new Date().getTime();
    id = String(id);
    return (
      <g id="arrowlength" {...GetShapeStyle().ARROWLENGHTSTYLE}>
        <ArrowMarker id={id} width={4} height={3} type={ARROW_SHOW_TYPE.ArrowSimple} />
        {this.getLine(p1, p2, id)}
        {this.getText(p1, p2, isTextTop)}
      </g>
    );
  }
}

ArrowLength.propTypes = {
  p1: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  p2: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  istop: PropTypes.bool,
};

export default ArrowLength;
