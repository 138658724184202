import * as constants from './constant';
import _ from 'lodash';

const DASH_ARRAY_TYPE = constants.DASH_ARRAY_TYPE;
const DASH_WIDTH = constants.DASH_WIDTH;

export const isNormalHandle = anchorType => {
  return (
    anchorType === constants.ANCHORS_TYPE.LEFTTOP ||
    anchorType === constants.ANCHORS_TYPE.RIGHTTOP ||
    anchorType === constants.ANCHORS_TYPE.LEFTBOTTOM ||
    anchorType === constants.ANCHORS_TYPE.RIGHTBOTTOM ||
    anchorType === constants.ANCHORS_TYPE.TOPMEDIUM ||
    anchorType === constants.ANCHORS_TYPE.BOTTOMMEDIUM ||
    anchorType === constants.ANCHORS_TYPE.LEFTMEDIUM ||
    anchorType === constants.ANCHORS_TYPE.RIGHTMEDIUM ||
    anchorType === constants.ANCHORS_TYPE.LINEBEGIN ||
    anchorType === constants.ANCHORS_TYPE.LINEEND ||
    anchorType === constants.ANCHORS_TYPE.ARCBEGIN ||
    anchorType === constants.ANCHORS_TYPE.ARCEND ||
    anchorType === constants.ANCHORS_TYPE.ARCANGLE ||
    anchorType === constants.ANCHORS_TYPE.STREETBEGIN ||
    anchorType === constants.ANCHORS_TYPE.STREETEND ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETLEFTTOP) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETRIGHTTOP) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.OFFSETRIGHT) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.OFFSETLEFT) ||
    anchorType === constants.ANCHORS_TYPE.STREETARCCenterBOTTOM ||
    anchorType === constants.ANCHORS_TYPE.STREETARCCenterTOP ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETCENTER) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETNORMAL) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETARCCenter) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETACROSSCenter) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETACROSSPOINT) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETARCLEFT) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETARCRIGHT) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STREETSPLITPATTEM) ||
    anchorType === constants.ANCHORS_TYPE.STAIRSBEGIN ||
    anchorType === constants.ANCHORS_TYPE.STAIRSMIDDLE ||
    anchorType === constants.ANCHORS_TYPE.STAIRSEND ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKBEGIN ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKEND ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKLEFTTOP ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKLEFTBOTTOM ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKRIGHTTOP ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKRIGHTBOTTOM ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKMIDDLETOP ||
    anchorType === constants.ANCHORS_TYPE.CROSSWALKMIDDLEBOTTOM ||
    anchorType === constants.ANCHORS_TYPE.OFFSETUP ||
    anchorType === constants.ANCHORS_TYPE.OFFSETDOWN ||
    anchorType === constants.ANCHORS_TYPE.OFFSETCENTER ||
    anchorType.endsWith(constants.ANCHORS_TYPE.CONNECTBEGIN) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.CONNECTNORMAL) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.CLOSEEND) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.CONNECTCANGLE) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.COUNTERBEIGIN) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.COUNTERNORMAL) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.COUNTEREND) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.COUNTERTHREE) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.COUNTERMOVELEFT) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.COUNTERMOVERIGHT) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STRUCTURELINE) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STRUCTUREBEGIN) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.STRUCTUREEND) ||
    anchorType.endsWith(constants.ANCHORS_TYPE.OFFSETPOSITION) ||
    anchorType.endsWith('TransformHeadPosition') ||
    anchorType.endsWith('TransformTailPosition') ||
    anchorType.startsWith('TransformReferencePosition') ||
    anchorType.startsWith('StreetNewBegin') ||
    anchorType.startsWith('StreetNewEnd') ||
    anchorType.startsWith('StreetNewConnect') ||
    anchorType.startsWith('StreetNewCenter') ||
    anchorType.startsWith('OffsetArcPathStart') ||
    anchorType.startsWith('OffsetArcPathStop') ||
    anchorType.startsWith('StreetOffsetArcPathStart') ||
    anchorType.startsWith('StreetOffsetArcPathStop') ||
    anchorType.startsWith('StreetOffsetArcPathTransition') ||
    anchorType.startsWith('OffsetOffsetArcPathStart') ||
    anchorType.startsWith('OffsetOffsetArcPathStop') ||
    anchorType.startsWith('OffsetOffsetArcPathTransition') ||
    anchorType.startsWith('PatternBreak')
  );
};

export const isAngleHandle = anchorType => {
  return (
    anchorType in
    [
      constants.ANCHORS_TYPE.ANGLE,
      constants.ANCHORS_TYPE.CALLOUTANGLE,
      constants.ANCHORS_TYPE.CLOSEANGLE,
      constants.ANCHORS_TYPE.PARKING_STALL_ANGLE,
    ]
  );
};

export const getAnchorPointCursor = anchorType => {
  if (isNormalHandle(anchorType)) {
    return constants.CURSOR.CROSSHAIR;
  } else if (isAngleHandle(anchorType)) {
    return constants.CURSOR.ANGLE;
  } else if (anchorType === constants.ANCHORS_TYPE.STREETTEXTRECT) {
    return constants.CURSOR.MOVE;
  } else {
    return constants.CURSOR.DEFAULT;
  }
};

/**
 *
 * @param {string} keyName
 */
export const getOperationByKeyName = keyName => {
  const entry = _.find(constants.KEY_OPERATIONS, n => n.keyNames.indexOf(keyName) > -1);
  return entry ? entry.operation : undefined;
};

export const getDashArray = (type, width = DASH_WIDTH) => {
  if (typeof DASH_ARRAY_TYPE[type] === 'undefined') return DASH_ARRAY_TYPE.solid;
  if (type === DASH_ARRAY_TYPE.dashes) {
    return `10,${width * 2}`;
  } else if (type === DASH_ARRAY_TYPE.dots) {
    return `5,${width * 2}`;
  } else if (type === DASH_ARRAY_TYPE.dashdot) {
    return `10,${width * 2},3,${width * 2}`;
  } else if (type === DASH_ARRAY_TYPE.dashdotdot) {
    return `20,${width * 2},3,${width * 2},3,${width * 2}`;
  } else {
    return DASH_ARRAY_TYPE[type];
  }
};

export const calcExecTime = cbAsync => {
  return Promise.resolve()
    .then(() => {
      return Date.now();
    })
    .then(start => {
      return cbAsync().then(() => {
        return Date.now() - start;
      });
    })
    .then(time => {
      // eslint-disable-next-line no-console
      console.log('loading time:', time);
    });
};
