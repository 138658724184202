import * as React from 'react';

function SvgIconTwoWayLeft(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .002h24v24H0z" />
        <path stroke="currentColor" strokeWidth={2} d="M5 24V0M19 24V0" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M15.399 15.34v-4.6a.96.96 0 00-.96-.96H9.837l1.101-1.101L10.26 8 8 10.26l2.26 2.259.678-.679-1.1-1.1h4.6v4.6h.96z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconTwoWayLeft;
