import * as React from 'react';

function SvgIconRoadBlock(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24.001v24.001H0z" />
        <path
          d="M19.76 20L14.963 2.732A1 1 0 0014 2h-4a1 1 0 00-.963.732L4.24 20H2v2h20v-2h-2.24zm-3.742-6H7.982l.834-3h6.368l.834 3zM10.76 4h2.48l.833 3H9.927l.833-3zM6.315 20l.556-2h10.258l.556 2H6.315z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconRoadBlock;
