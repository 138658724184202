import { SYNC_WORK_DATA } from '@src/type/workData';
import _ from 'lodash';

const initialState = {
  useData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SYNC_WORK_DATA:
      return { useData: _.cloneDeep(action.useData) };

    default:
      return state;
  }
};
