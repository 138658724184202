import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'antd';
import { IconSplitter } from '@src/components/UI/Icons';
import { addSplitPattern } from '@src/actions/HandlePoint';

export class AddSplitterButton extends Component {
  addSplitter = () => {
    const { streetId, stripeIndex } = this.props;
    addSplitPattern({
      streetId,
      stripeIndex,
    });
  };

  render() {
    return (
      <Button onClick={this.addSplitter}>
        <Icon component={IconSplitter} />
      </Button>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSplitterButton);
