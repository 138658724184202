import GeomLine from './GeomLine';
import GeomPoint from './GeomPoint';

class UtilityMath {
  /**
   * converts degree to radians
   * @param degree
   * @returns {number}
   */
  toRadians(degree) {
    return degree * (Math.PI / 180);
  }

  /**
   * Converts radian to degree
   * @param radians
   * @returns {number}
   */
  toDegrees(radians) {
    return radians / (Math.PI / 180);
  }

  /**
   * Is the value between the min and the max
   * @param {number} val
   * @param {number} min
   * @param {number} max
   * @returns {boolean}
   */
  isBetween(val, min, max) {
    return val >= min && val <= max;
  }

  /**
   *
   * @param {{x: number, y: number}} p0
   * @param {{x: number, y: number}} p1
   * @param {{x: number, y: number}} p2
   * @returns {boolean}
   */
  isClockwise(p0, p1, p2) {
    return !this.isCounterClockwise(p0, p1, p2);
  }

  /**
   * cross product
   * @param {{x: number, y: number}} p0
   * @param {{x: number, y: number}} p1
   * @param {{x: number, y: number}} p2
   * @returns {boolean}
   */
  isCounterClockwise(p0, p1, p2) {
    var dx1 = p1.x - p0.x,
      dx2 = p2.x - p0.x,
      dy1 = p1.y - p0.y,
      dy2 = p2.y - p0.y;
    return dx1 * dy2 > dy1 * dx2;
  }

  isPointInPolygon(pt, arrPt) {
    var cnt = arrPt.length;
    if (cnt < 3) {
      return false;
    }
    if (arrPt[0].isEqual(arrPt[cnt - 1])) {
      cnt -= 1;
    }
    if (cnt < 3) {
      return false;
    }
    var maxx, minx, i;
    minx = maxx = arrPt[0].x;
    for (i = 1; i < cnt; i++) {
      maxx = Math.max(maxx, arrPt[i].x);
      minx = Math.min(minx, arrPt[i].x);
    }
    if (pt.x > maxx || pt.x < minx) {
      return false;
    }
    var line1,
      line2,
      n = 0;
    line1 = new GeomLine(pt.clone(), new GeomPoint(maxx + 1000, pt.y));
    for (i = 0; i < cnt - 1; i++) {
      line2 = new GeomLine(arrPt[i], arrPt[i + 1]);
      if (line1.getPointIntersect(line2)) {
        n += 1;
      }
    }
    line2 = new GeomLine(arrPt[cnt - 1], arrPt[0]);
    if (line1.getPointIntersect(line2)) {
      n += 1;
    }
    return n & 1;
  }

  normalizeAngleDeg(a) {
    var r = a % 360;
    if (r < 0) {
      // FIXME: r += 360?
      r += a;
    }
    return r;
  }

  normalizeAngleRads(a) {
    var TWOPI = 2 * Math.PI,
      r = a % TWOPI;
    if (r < 0) {
      r += TWOPI;
    }
    return r;
  }
}

export default UtilityMath;
