import { SymbolContext } from '@src/contexts';
import { FavoriteContext } from '@src/contexts/FavoriteContext';
import React, { memo, useContext } from 'react';
import HeaderSecond from '../HeaderSecond';
import SymbolBar from '../SymbolBar';
import TopMenuBar from '../TopMenuBar';
import HeaderInner from './HeaderInner';

function Header (props) {
  const { onSvgImage, imageData, existingImage, onChange, isNightMode, onExit, handleCanvasOperation, setMapLatLng, clearMap, handleZoom, leafletMap, resumeMap } = props;

  const {favorites} = useContext(FavoriteContext);
  const {symbols} = useContext(SymbolContext);

  return (
    <HeaderInner>
      <TopMenuBar
        onSvgImage={onSvgImage}
        imageData={imageData}
        existingImage={existingImage}
        onChange={onChange}
        isNightMode={isNightMode}
        onExit={onExit}
        handleCanvasOperation={handleCanvasOperation}
        resumeMap={resumeMap}
        setMapLatLng={setMapLatLng}
        clearMap={clearMap}
        handleZoom={handleZoom}
        leafletMap={leafletMap}
      />
      <HeaderSecond onChange={onChange} handleCanvasOperation={handleCanvasOperation} />
      <SymbolBar onChange={onChange} favorites={favorites} symbols={symbols} />
    </HeaderInner>
  );
}

export default memo(Header);
