import * as React from 'react';

function SvgIconTrafficLight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.001 0h24v24.002h-24z" />
        <g transform="translate(7 2)" fill="currentColor" fillRule="nonzero">
          <path d="M8 0H2C.897 0 0 .896 0 2v12c0 1.104.897 2 2 2h2v4h2v-4h2c1.103 0 2-.896 2-2V2c0-1.104-.897-2-2-2zM2 14V2h6l.001 12H2z" />
          <circle cx={5} cy={4.5} r={1.5} />
          <circle cx={5} cy={8} r={1.5} />
          <circle cx={5} cy={11.5} r={1.5} />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconTrafficLight;
