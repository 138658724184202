import React from 'react';
import { Tabs, Icon } from 'antd';
import { getTextPosition } from '@src/data/BusinessFun';
import { FindCircle, GetCenterInTwoPoints, LengthArc, LengthBetweenPoints, ScalePoint } from '@src/data/CommonFunc';
import { INCHES_PER_FOOT } from '@src/data/CommonFunction';
import Utility from '@src/data/Utility';
import emitter from '@src/data/Event';
import TabItem from '@src/components/PropMenu/TabItem';
import utilitySvg from '@src/data/UtilitySvg';
import { ROAD_CONSTANT } from '@src/type/event';
import { ARROW_SHOW_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';
import ArrowMenu from '@src/components/Modal/Menu/ArrowMenu';
import { EVENT_EMIT_TYPE } from '@src/type/event';

const NONE_ARROW_TYPE = ARROW_SHOW_TYPE.NONE;
const DEFAULT_ARROW_TYPE = ARROW_SHOW_TYPE.ArrowSimple;

export default class ArcModal extends React.Component {
  // Modal menu and items
  menu = {
    style: ['line', 'width', 'lineColor', 'lineLength', 'marks'],
    text: ['text', 'fontSize', 'color', 'style', 'display', 'position'],
    size: ['chord', 'ordinate', 'arcRadius'],
    arrow: ['arrow', 'arrowType', 'width'],
  };

  state = {
    object: this.props.object,
    chord: this.calculateChord(this.props.object),
    ordinate: this.calculateOrdinate(this.props.object),
    radius: this.calculateRidius(this.props.object),
    tabKey: 'style',
    intervalMarks: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
      chord: this.calculateChord(nextProps.object),
      ordinate: this.calculateOrdinate(nextProps.object),
      radius: this.calculateRidius(nextProps.object),
    });
  }

  calculateChord(object) {
    return LengthBetweenPoints(object.handlepoint[0], object.handlepoint[1]);
  }

  calculateOrdinate(object) {
    return LengthBetweenPoints(
      object.handlepoint[2],
      GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[1])
    );
  }

  calculateRidius(object) {
    return FindCircle(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2])[2];
  }

  handleStrokeDashArray = (strokepattern, strokeDash) => {
    workData.setObjectStyleLine(this.state.object.operateid, strokepattern, strokeDash);
    const obj = this.state.object;
    obj.style.strokepattern = strokepattern;
    obj.style.strokedasharray = utilitySvg.getStrokePattern(obj.style.strokepattern, obj.style.strokewidth);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStrokeWidth = width => {
    const obj = this.state.object;
    obj.style.strokewidth = width;
    // obj.style.strokedasharray = utilitySvg.getStrokepattern(obj.style.strokepattern, obj.style.strokewidth);
    obj.marks.height = width * 2;
    if (obj.arrow.width < obj.style.strokewidth * 2) {
      obj.arrow.width = obj.style.strokewidth * 2;
      obj.arrow.height = obj.arrow.width;
    }

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStroke = evt => {
    const colortype = evt.currentTarget.id;
    workData.setObjectStyleLineColor(this.state.object.operateid, colortype);
    const obj = this.state.object;
    obj.style.stroke = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleFill = evt => {
    const colortype = evt.currentTarget.id;
    workData.setObjectStyleFillColor(this.state.object.operateid, colortype);
    const obj = this.state.object;
    obj.style.fill = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleMarks = e => {
    const { intervalMarks, object: obj } = this.state;
    obj.marks.isexist = !intervalMarks;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj, intervalMarks: !intervalMarks });
    this.props.handle();
  };

  handleMarksLenth = length => {
    const obj = this.state.object;
    obj.marks.length = length;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  // changePosition = e => {
  //   const obj = this.state.object;
  //   obj.text.position = getTextPosition(obj.text.position);

  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  textLength = e => {
    const { object: obj } = this.state;
    obj.text.islength = !obj.text.islength;
    if (obj.text.islength) {
      let length = LengthArc(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]);
      obj.text.text = Utility.format(length / 12);
    } else {
      obj.text.text = '';
    }
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  // textHandle = e => {
  //   const obj = this.state.object;
  //   obj.text.text = e.target.value;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textSizeHandle = size => {
  //   const obj = this.state.object;
  //   obj.text.size = size;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textBoldHandle = () => {
  //   const obj = this.state.object;
  //   if (obj.text.bold == FONT_WEIGHT_TYPE.NORMAL) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.BOLD;
  //   } else if (obj.text.bold == FONT_WEIGHT_TYPE.BOLD) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textItalicHandle = () => {
  //   const obj = this.state.object;
  //   if (obj.text.italic == FONT_STYLE_TYPE.NORMAL) {
  //     obj.text.italic = FONT_STYLE_TYPE.ITALIC;
  //   } else if (obj.text.italic == FONT_STYLE_TYPE.ITALIC) {
  //     obj.text.italic = FONT_STYLE_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // textColorHandle = e => {
  //   const colortype = e.currentTarget.id;
  //   const obj = this.state.object;
  //   obj.text.color = colortype;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // pointlengthHandle = length => {
  //   const scale = length / this.state.pointlength;
  //   const obj = this.state.object;
  //   obj.handlepoint[1] = ScalePoint(obj.handlepoint[1], scale, scale, obj.handlepoint[0]);
  //   this.setState({ pointlength: length });
  //   this.setState({ object: obj });
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  chordHandle = chord => {
    let scale = 1;
    if (this.state.chord != 0) scale = (chord * INCHES_PER_FOOT) / this.state.chord;
    const obj = this.state.object;
    const center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
    obj.handlepoint[0] = ScalePoint(obj.handlepoint[0], scale, scale, center);
    obj.handlepoint[1] = ScalePoint(obj.handlepoint[1], scale, scale, center);
    const circle = FindCircle(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]);
    this.setState({ radius: circle[2], chord: chord * INCHES_PER_FOOT, object: obj });
    workData.setObj(obj.operateid, obj);
    this.props.handle();
  };

  ordinateHandle = ordinate => {
    let scale = 1;
    if (this.state.ordinate != 0) scale = (ordinate * INCHES_PER_FOOT) / this.state.ordinate;
    const obj = this.state.object;
    const center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
    obj.handlepoint[2] = ScalePoint(obj.handlepoint[2], scale, scale, center);
    const circle = FindCircle(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]);
    this.setState({ radius: circle[2], ordinate: ordinate * INCHES_PER_FOOT, object: obj });
    workData.setObj(obj.operateid, obj);
    this.props.handle();
  };

  radiusHandle = radius => {
    let scale = 1;
    if (this.state.radius != 0) scale = (radius * INCHES_PER_FOOT) / this.state.radius;
    const obj = this.state.object;
    const center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
    obj.handlepoint[2] = ScalePoint(obj.handlepoint[2], scale, scale, center);
    const ordinate = LengthBetweenPoints(center, obj.handlepoint[2]);
    this.setState({ radius: radius * INCHES_PER_FOOT, ordinate, object: obj });
    workData.setObj(obj.operateid, obj);
    this.props.handle();
  };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    const { arrow = {} } = this.state.object;
    const arrowPosition = arrow.position || NONE_ARROW_TYPE;
    const arrowType = arrow.type || DEFAULT_ARROW_TYPE;
    const disabledChangeArrowType = arrowPosition === NONE_ARROW_TYPE;

    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu
                item={this.menu.text}
                objectId={this.props.object.operateid}
                // stateObject={this.state.object}
                displayLength={this.props.object.text.islength}
                // textHandle={this.textHandle}
                // textSizeHandle={this.textSizeHandle}
                // textColorHandle={this.textColorHandle}
                // textBoldHandle={this.textBoldHandle}
                // textItalicHandle={this.textItalicHandle}
                textLengthHandle={this.textLength}
                // textPositionHandle={this.changePosition}
              />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                // width={this.state.width}
                // height={this.state.height}
                // handlewidth={this.handlewidth}
                // handleheight={this.handleheight}
                // handleradius={this.radiushandle}
                chord={this.state.chord / INCHES_PER_FOOT}
                chordhandle={this.chordHandle}
                ordinate={this.state.ordinate / INCHES_PER_FOOT}
                ordinatehandle={this.ordinateHandle}
                radius={this.state.radius / INCHES_PER_FOOT}
                radiushandle={this.radiusHandle}
              />
            </Tabs.TabPane>
          )}
          {'arrow' in this.menu && (
            <Tabs.TabPane tab="arrow" key="arrow">
              <ArrowMenu item={this.menu.arrow} objectId={this.state.object.operateid} />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
