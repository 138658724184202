import React, { useState, useRef } from 'react';

export default function EditableTableContainer({ children }) {
  const [{ x, y }, set] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  const touchStart = event => {
    set({
      x: event.targetTouches[0].clientX,
      y: event.targetTouches[0].clientY,
    });
  };

  const touchMove = event => {
    let touch = event.targetTouches[0];
    let endY = touch.clientY;
    let endX = touch.clientX;
    let distanceY = endY - y;
    let distanceX = endX - x;
    let speed = 0.1;
    containerRef.current.scrollTop += -speed * distanceY;
    containerRef.current.scrollLeft += -speed * distanceX;
    set({ x, y });
  };

  return (
    <div className="lncd-measurement-scrollbar" ref={containerRef} onTouchStart={touchStart} onTouchMove={touchMove}>
      <table>{children}</table>
    </div>
  );
}
