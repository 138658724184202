import _ from 'lodash';
import * as workData from '@src/data/WorkData';
import * as transformHandle from '@src/actions/TransformHandle';
import { RotatePoint } from '@src/data/CommonFunc';
import GeomPoint from '@src/data/GeomPoint';
import TrackService from '@src/services/TrackService';

export let track = new TrackService();

/**
 * 创建 Shadow
 * @param {*} obj
 * @param {[number, number]} afterXY
 */
export function createShadow(obj, afterXY) {
  track.init(afterXY);

  obj.shadow.isActiveShadowMode = true;
  obj.shadow.originHandlePoint = _.cloneDeep(obj.handlepoint);
  obj.shadow.originRotateAngle = obj.rotateangle;
  workData.setObj(obj);
}

/**
 * 更新 Track
 * @param {*} obj
 */
export function updateTrack(stopPoint) {
  track.update(stopPoint);
}

/**
 * 创建 Shadow Shape
 * @param {*} obj
 * @param {HTMLElement} path
 */
export function createShadowShape(obj, path) {
  const { width, originwidth } = obj;
  let space;
  let shadowShapes = [];

  // Get the coordinates of the point that is the fraction 'pos' along the path
  let pathLength = path.getTotalLength();

  let originRad;
  let shapeCount;

  // When track length less than space, don't add shadow
  if (pathLength < space) return;

  // HACK: When track is short, space will more smaller.
  let opacities;
  let gap = width || originwidth;
  if (pathLength > 3 * gap) {
    space = Math.round(gap + 100);
    opacities = [0.7, 0.57, 0.43, 0.3]; // 0.3 to 0.7 equal difference sequence
  } else {
    space = Math.round(gap + 50);
    opacities = [0.65, 0.3];
  }
  shapeCount = Math.round(pathLength / space);

  for (let i = 0, il = shapeCount; i < il; i++) {
    let loc = path.getPointAtLength((i / shapeCount) * pathLength);

    // When shadow position length(previous position) less than half of space will out of loop.
    if (pathLength - (i / shapeCount) * pathLength < space / 2) break;

    let opacity = opacities[shapeCount - i - 1];
    if (typeof opacity === 'undefined') {
      opacity = opacities[opacities.length - 1];
    }
    let clone = _.cloneDeep(obj);
    let startPoint = obj.clickpoint;
    let endPoint = [loc.x, loc.y];

    // 从起始点移动到平均点，得到 handlepoint
    let newHandlePoint = transformHandle.TransformMoveObject(clone.shadow.originHandlePoint, startPoint, endPoint);
    let copyData = workData.addCopyData(clone, {
      handlepoint: newHandlePoint,
      selectflag: false,
      style: {
        ...obj.style,
        opacity,
      },
      shadow: {
        shapes: [],
        isShadow: true,
        isActiveShadowMode: false,
      },
      text: {
        ...clone.text,
        text: '',
      },
    });

    // 计算第一个 symbol 点与所在点切线夹角
    if (i === 0) {
      let firstLoc = path.getPointAtLength(0);
      // The second point is length same with symbol width.
      let secondLoc = path.getPointAtLength(originwidth / 2);
      let firstPt = new GeomPoint(firstLoc);
      let secondPt = new GeomPoint(secondLoc);
      let firstOldAnchorPt = new GeomPoint(copyData.handlepoint[8][0], copyData.handlepoint[8][1]);
      originRad = firstPt.radianBetweenTwoPoints(secondPt, firstOldAnchorPt);
    } else {
      rotateShapes({
        loc1: loc,
        loc2: path.getPointAtLength((i / shapeCount) * pathLength + originwidth / 2),
        originRad,
        object: copyData,
      });
    }
    shadowShapes.push({ operateId: copyData.operateid });
  }

  // 获取终点上一个坐标的相反坐标
  let loc = path.getPointAtLength(pathLength);
  let prevLoc = path.getPointAtLength(pathLength - obj.originwidth / 2);
  let newLoc = { x: loc.x + (loc.x - prevLoc.x), y: loc.y + (loc.y - prevLoc.y) };
  rotateShapes({
    loc1: path.getPointAtLength(pathLength),
    loc2: newLoc,
    originRad,
    object: obj,
  });

  // we cannot quit shadow mode before user has confirmed the motion track creation
  // obj.shadow.isActiveShadowMode = false;
  obj.shadow.shapes = shadowShapes;
  workData.setObj(obj);
}

/**
 * 销毁 Shadow Shape
 * @param {*} obj
 */
export function destroyShadowShape(obj) {
  for (let i = 0, il = obj.shadow.shapes.length; i < il; i++) {
    const shape = obj.shadow.shapes[i];
    workData.deleteObject(shape.operateId);
  }
}

/**
 * 旋转图形
 * @param {*} param0
 * @param {SVGPoint} param0.loc1 当前图形所在的位置
 * @param {SVGPoint} param0.loc2 下一个图形所在的位置
 * @param {number} param0.originRad 第一个图形与切线的夹角
 * @param {*} param0.object 需要旋转的图形
 */
function rotateShapes({ loc1, loc2, originRad, object }) {
  // 计算 shadow shape 所在的点与所在点切线夹角
  let pt1 = new GeomPoint(loc1);
  let pt2 = new GeomPoint(loc2);
  let oldAnchorPt = new GeomPoint(object.handlepoint[8][0], object.handlepoint[8][1]);
  let vec1 = oldAnchorPt.getVector(pt1);
  let vec2 = oldAnchorPt.getVector(pt2);
  let rad = pt1.radianBetweenTwoPoints(pt2, oldAnchorPt);

  if (vec1.cross(vec2) > 0) {
    // console.log('顺时针');
    rad = Math.PI * 2 - rad;
  } else {
    // console.log('逆时针');
  }

  // TODO: 存在边界情况
  // 防抖
  let radAngle,
    threshold = 0.2;
  rad = Math.round(rad * 1000) / 1000;

  // 图形向不同方向拖动，Shadow有不同的转向
  if (originRad <= Math.PI / 4) {
    // 当图形向右上方拖动
    radAngle = rad;
  } else if (originRad > Math.PI / 2 - threshold && originRad < Math.PI / 2 + threshold) {
    if (rad > Math.PI / 2 - threshold && rad < Math.PI / 2 + threshold) {
      // 当图形向右下方拖动
      radAngle = rad + originRad + Math.PI;
    } else {
      // 当图形向左下方拖动
      radAngle = rad + originRad;
    }
  } else {
    // 当图形向左上方拖动
    radAngle = rad + originRad;
  }

  let endPoint = [pt1.x, pt1.y];
  let newAnchorPt = RotatePoint(object.handlepoint[8], radAngle, endPoint);
  object = transformHandle.TransformRotateObject(object, newAnchorPt);
  return radAngle;
}
