import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomArc from './GeomArc';
import GeomPolyArc from './GeomPolyArc';
import StripeHiddenSegment from './StripeHiddenSegment';
import Utility from './Utility';
import UtilityGeom from './UtilityGeom';
import UtilityMath from './UtilityMath';
import CurbReturn from '@src/shapes/CurbReturn/CurbReturn';
import StreetIntersectionArea from './StreetIntersectionArea';
import { ROAD_LAYER_VALUES } from '@src/constant';

class StreetBorder {
  street;

  /** @type {string} operateId */
  streetId;
  stripe;

  /** @type {string} */
  stripeKey;
  patterns;
  axisPatterns;

  /** @type {GeomPolyArc} */
  gpaStripe;
  gpaAxis;
  isectOffsets;
  axisIsectOffsets;

  /** @type {StripeHiddenSegment[]} */
  hiddenSegs;

  /** @type {StripeHiddenSegment[]} */
  axisHiddenSegs;

  /**
   *
   * @param {{functype: 'StreetNew'}} street - StreetNew
   * @param {{type: 'stripe'}} stripe
   */
  // TODO: what is the purpose of using stripe parameter here
  constructor(street, stripe) {
    this.street = street;
    this.streetId = street.operateid;
    this.stripe = stripe;
    this.stripeKey = stripe.key;
    this.patterns = stripe.primary.patterns;
    this.axisPatterns = stripe.axis ? stripe.axis.patterns : null;
    this.gpaStripe = new GeomPolyArc(stripe.segments);
    this.gpaAxis = stripe.axis ? new GeomPolyArc(stripe.offsetArcSegments) : undefined;
    this.isectOffsets = [];
    this.axisIsectOffsets = [];
    this.hiddenSegs = [];
    this.axisHiddenSegs = [];
  }

  /**
   *
   * @param {number} setbackOffset
   */
  addIntersectOffset(setbackOffset) {
    this.isectOffsets.push(setbackOffset);
  }

  /**
   * 获取 StreetEndCap 交叉点
   * @param {GeomLine} seg
   */
  getStreetEndCapIntersection(seg) {
    return this.gpaStripe.getSegmentIntersection(seg);
  }

  /**
   * 获取两条道路边线的所有交点信息
   * @param {StreetBorder} streetBorder
   */
  getStripeIntersection(streetBorder) {
    let gpa0 = this.gpaAxis || this.gpaStripe;
    let gpa1 = streetBorder.gpaAxis || streetBorder.gpaStripe;
    return gpa0.getIntersection(gpa1);
  }

  /**
   *
   * @param {{functype: 'StreetNew'}[]} streets
   * @param {{functype: 'CurbReturn'}[]} curbReturns
   * @param {ROAD_LAYER} roadLayer
   */
  computeVisibility(streets, curbReturns, roadLayer) {
    // console.log('streets', streets);
    var gpa = this.gpaAxis ? this.gpaAxis : this.gpaStripe;
    var length = gpa.getLength();

    if (0 === this.isectOffsets.length) {
      this.isectOffsets.push(0, length);
    } else {
      this.isectOffsets.sort((a, b) => a - b);
      if (this.isectOffsets[0] > 0) {
        this.isectOffsets.unshift(0);
      }
      if (this.isectOffsets[this.isectOffsets.length - 1] < length) {
        this.isectOffsets.push(length);
      }
    }

    var prevStripeHiddenSegment;
    // console.log('isectOffsets', this.isectOffsets);
    // console.log('roadLayer', roadLayer);
    // console.log('streetId', this.streetId);
    for (let i = 1; i < this.isectOffsets.length; i++) {
      var distance = this.isectOffsets[i] - this.isectOffsets[i - 1];
      var ab = [this.isectOffsets[i - 1] + distance / 3];
      ab.push(ab[0] + distance / 3);
      var p1 = gpa.getPointAtLength(ab[0]);
      var p2 = gpa.getPointAtLength(ab[1]);

      // 判断 street 边界是否需要进行裁剪
      var b = streets.some(street => {
        return (
          street.operateid !== this.streetId &&
          (this.isPointInShape(street, p1) || this.isPointInShape(street, p2)) &&
          street.roadLayer >= roadLayer
        );
      });

      if (!b) {
        b = curbReturns.some(cr => {
          return this.isBorderOffsetInSetback(cr, this, ab);
        });
      }

      if (b) {
        var stripeHiddenSegment = new StripeHiddenSegment(this.isectOffsets[i - 1], this.isectOffsets[i], length);
        if (prevStripeHiddenSegment && prevStripeHiddenSegment.stopOffset === stripeHiddenSegment.startOffset) {
          prevStripeHiddenSegment.stopOffset = stripeHiddenSegment.stopOffset;
          prevStripeHiddenSegment.stopPct = stripeHiddenSegment.stopPct;
        } else {
          this.hiddenSegs.push(stripeHiddenSegment);
          prevStripeHiddenSegment = stripeHiddenSegment;
        }
      }
    }
  }

  /**
   *
   * @param {*} streetIntersectionAreas
   * @param {*} interiorStripeSegmentMin
   */
  computeAxisVisibility(streetIntersectionAreas, interiorStripeSegmentMin) {
    var acab, acad;
    if (this.gpaAxis) {
      acad = streetIntersectionAreas.length > 0 ? this.gpaAxis.getLength() : undefined;
    } else {
      return;
    }
    for (var idx = 0; idx < streetIntersectionAreas.length; idx += 1) {
      var streetIntersectionArea = streetIntersectionAreas[idx];
      acab = idx < streetIntersectionAreas.length - 1 ? streetIntersectionAreas[idx + 1] : undefined;
      if (streetIntersectionArea.suppressStripes) {
        this.computeAxisVisibilityInIntArea(streetIntersectionArea, acab, acad, interiorStripeSegmentMin);
      }
    }
  }

  /**
   *
   * @param {StreetIntersectionArea} streetIntersectionArea
   * @param {*} acaf
   * @param {number} acb0
   * @param {number} interiorStripeSegmentMin
   */
  computeAxisVisibilityInIntArea(streetIntersectionArea, acaf, acb0, interiorStripeSegmentMin) {
    var acb2 = 0,
      acb3 = acb0,
      acb4,
      acb5,
      acb6;
    switch (streetIntersectionArea.intLocation) {
      case 1:
        acb5 = streetIntersectionArea.gpaStop.segments[0].clone();
        acb5.inflate(2);
        acb4 = this.gpaAxis.getIntersection(new GeomPolyArc([acb5]));
        if (acb4.length > 0) {
          acb3 = acb4[0].offset0;
        }
        break;
      case 2:
        acb5 = streetIntersectionArea.gpaStart.segments[0].clone();
        acb5.inflate(2);
        acb4 = this.gpaAxis.getIntersection(new GeomPolyArc([acb5]));
        if (acb4.length > 0) {
          acb2 = acb4[0].offset0;
        }
        break;
      case 3:
        acb5 = streetIntersectionArea.gpaStart.segments[0].clone();
        acb5.inflate(2);
        acb4 = this.gpaAxis.getIntersection(new GeomPolyArc([acb5]));
        acb2 = acb4.length > 0 ? acb4[0].offset0 : 0;
        acb5 = streetIntersectionArea.gpaStop.segments[0].clone();
        acb5.inflate(2);
        acb4 = this.gpaAxis.getIntersection(new GeomPolyArc([acb5]));
        acb3 = acb4.length > 0 ? acb4[0].offset0 : acb0;
        break;
      default:
        break;
    }
    if (this.gpaAxis && acb3 > acb2) {
      acb6 = undefined;
      if (this.axisHiddenSegs.length > 0) {
        acb6 = this.axisHiddenSegs[this.axisHiddenSegs.length - 1];
      }
      if (acb6 && acb2 - acb6.stopOffset < interiorStripeSegmentMin) {
        acb6.extend(acb3, acb0);
      } else {
        this.axisHiddenSegs.push(new StripeHiddenSegment(acb2, acb3, acb0));
      }
    }
  }

  /**
   *
   * @param {CurbReturn} curbReturn
   * @param {StreetBorder} streetBorder
   * @param {number[]} ab
   * @return {boolean}
   */
  isBorderOffsetInSetback(curbReturn, streetBorder, ab) {
    var streetStripes = curbReturn.streetStripes;
    if (Utility.isNonEmptyArray(streetStripes)) {
      return streetStripes.some(function(stripe) {
        if (streetBorder.streetId === stripe.idStreet && streetBorder.stripeKey === stripe.keyStripe) {
          return ab.some(function(a) {
            return new UtilityMath().isBetween(
              a,
              Math.min(stripe.apexOffset, stripe.setbackOffset),
              Math.max(stripe.apexOffset, stripe.setbackOffset)
            );
          });
        }
        return false;
      });
    }
    return false;
  }

  /**
   * 判断一个点是否在图形内
   * @param {{functype: 'StreetNew'}} street
   * @param {GeomPoint} pt
   * @returns {boolean}
   */
  isPointInShape(street, pt) {
    let gpa = new GeomPolyArc(street.segments);
    if (street.offsetArcPath) {
      gpa = new GeomPolyArc(street.offsetArcPath.segments);
    }
    var gpo = gpa.getOffsetsToPoint(pt);
    if (gpo) {
      if (gpo.fromStartPercent > 0 && gpo.fromStartPercent < 1) {
        // distance from the point to the axis - street middle divider
        if (Math.abs(gpo.normal) < this.getStreetWidth(street) / 2) {
          return true;
        }
      }
    }
    return false;
  }

  getStreetWidth(street) {
    let width = 0;
    if (street) {
      street.components.forEach(cmp => {
        width += cmp.width || 0;
      });
    }
    return width;
  }
}

export default StreetBorder;
