import * as actionTypes from '@src/type/street';

export function addLane({ streetId, index, laneType }) {
  return {
    type: actionTypes.ADD_LANE,
    streetId,
    index,
    laneType,
  };
}

/**
 * update the radian between x-axis and street axis
 * @param {number} degree
 */
export function updateAxisRad(rad) {
  return {
    type: actionTypes.UPDATE_AXIS_RAD,
    rad,
  };
}

export function stripeClicked(stripeId) {
  return {
    type: actionTypes.STRIPE_CLICKED,
    stripeId,
  };
}
