import * as React from 'react';

function SvgImageLineStyleDashes(props) {
  return (
    <svg {...props}>
      <path strokeDasharray="10,5" d="M5 10h210" fill="none" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

export default SvgImageLineStyleDashes;
