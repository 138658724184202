import * as React from 'react';

function SvgIconSedan(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .958h24v23.04H0z" />
        <path
          d="M13 5c-3.521 0-6.442 2.612-6.929 6H6c-2.206 0-4 1.795-4 4v2a1 1 0 001 1h2.185A2.993 2.993 0 008 20a2.996 2.996 0 002.816-2h3.369A2.994 2.994 0 0017 20a2.997 2.997 0 002.816-2H21a1 1 0 001-1v-3c0-4.963-4.038-9-9-9zm6.315 6H14V7.08A7.008 7.008 0 0119.315 11zM12 7.102V11H8.101A5.013 5.013 0 0112 7.102zM8 18c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm9 0c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm3-2h-.184A2.996 2.996 0 0017 14a2.993 2.993 0 00-2.815 2h-3.369A2.996 2.996 0 008 14a2.993 2.993 0 00-2.815 2H4v-1c0-1.104.897-2 2-2h13.92c.047.328.08.66.08 1v2z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconSedan;
