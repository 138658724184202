import * as React from 'react';

function SvgIconCrosswalk(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <path id="icon-crosswalk_svg__a" d="M0 0h12v12H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <path d="M15.682 2a.91.91 0 01.887.712l1.64 7.375.037.171a2.248 2.248 0 00-1.356-.423c-.197 0-.395.014-.591.043l-1.346-6.06H7.32L4.088 18.364l8.116-.002c-.032.557-.068 1.164-.108 1.82H2.955a.911.911 0 01-.888-1.107L5.704 2.712A.91.91 0 016.59 2z" />
          <path d="M10.227 2.909h1.818v16h-1.818z" />
        </g>
        <path
          d="M16.795 12.25c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm-1.875 1.7L13.545 21h1.05l.875-4 1.075 1v3h1v-3.775L16.52 16.2l.3-1.5c.65.8 1.625 1.3 2.725 1.3v-1c-.925 0-1.725-.5-2.175-1.225l-.475-.8a.975.975 0 00-.85-.475c-.125 0-.25.025-.375.075l-2.625 1.075V16h1v-1.675l.875-.375z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconCrosswalk;
