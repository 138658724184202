import * as constant from '@src/constant';
import {
  RESUME_MAP_STATE,
  SET_CURRENT_LOCATION,
  SET_KEYWORD,
  SET_MAP_CONFIGS,
  SET_MAP_TYPE,
  SET_MAP_VIEW,
  SET_NIGHT_MODE,
  SET_OPACITY,
  SET_SELECT_OPTIONS,
  SWITCH_LOCATION_LOADING,
  SWITCH_MAP_TAB,
  TOGGLE_MAP_CONTAINER,
} from '@src/type/map';
import { getDefaultMapType, isNightMapType } from '@src/utils';
import produce from 'immer';
import { mergeDeepRight } from 'ramda';

const initialState = {
  // 可用的地图服务和可选择的地图类型配置
  mapConfigs: [],

  service: {
    // the provider identifier, Bing, ESRI, etc.
    schema: '',
    // imagerySet for Bing map
    type: '',

    // NOTICE: latitude and longitude are not reactive values, they are here only for view/storage purpose. See how they're used in leaflet zoomend event
    latitude: 0,
    longitude: 0,
  },
  opacity: 1,
  zoom: constant.MAP.INITIAL_ZOOM,

  keyword: '',
  mapTabKey: '1',
  currentLocation: '',
  selectOptions: [],

  lightMapType: '',
  isOpenMapContainer: false,
  isLocationLoading: false,
  // subscribe to night mode
  nightMode: false,
};

export default produce((draft, action) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case SET_MAP_VIEW: {
      const { latlng, zoom } = action.payload;
      if (latlng) {
        const [lat, lng] = latlng;
        draft.service.latitude = lat;
        draft.service.longitude = lng;
      }
      if (zoom) {
        draft.zoom = zoom;
      }
      break;
    }
    case SET_MAP_TYPE: {
      const { schema, type } = action.payload;
      if (!schema && !type) break;
      if (schema) {
        draft.service.schema = schema;
      }
      if (type) {
        draft.service.type = type;
      } else {
        // only changing map provider
        draft.service.type = getDefaultMapType(draft.mapConfigs, schema, draft.nightMode);
      }
      draft.lightMapType = '';
      break;
    }
    case SET_OPACITY: {
      draft.opacity = action.opacity;
      break;
    }
    case SET_CURRENT_LOCATION: {
      draft.currentLocation = action.value;
      break;
    }
    case SET_SELECT_OPTIONS: {
      draft.selectOptions = action.value;
      break;
    }
    case TOGGLE_MAP_CONTAINER: {
      if (typeof action.value !== 'undefined') {
        draft.isOpenMapContainer = action.value;
      } else {
        draft.isOpenMapContainer = !draft.isOpenMapContainer;
      }
      break;
    }
    case SWITCH_LOCATION_LOADING: {
      draft.isLocationLoading = action.loading;
      break;
    }
    case SWITCH_MAP_TAB: {
      draft.mapTabKey = action.value;
      break;
    }
    case SET_KEYWORD: {
      draft.keyword = action.value;
      break;
    }
    case RESUME_MAP_STATE: {
      let mapState = action.payload || {};
      // mapState comes without a map provider, so we try to provide the default one
      if ((!mapState.service || !mapState.service.schema || !mapState.service.type) && draft.mapConfigs.length > 0) {
        // TODO: DRY to get default map provider
        const { provider, types } = draft.mapConfigs[0];
        mapState = mergeDeepRight(mapState, {
          service: {
            schema: provider,
            type: types[0].value,
          },
        });
      }
      // don't flush mapConfigs
      // mapState.mapConfigs = draft.mapConfigs;
      // return initialState and override necessary properties
      // see: https://github.com/immerjs/immer/issues/296
      return mergeDeepRight(initialState, mapState);
    }
    case SET_NIGHT_MODE: {
      const nightMode = action.value;
      draft.nightMode = action.value;
      const bing = draft.mapConfigs.find(n => n.provider === 'Bing');
      const esri = draft.mapConfigs.find(n => n.provider === 'ESRI');
      if (nightMode) {
        draft.lightMapType = draft.service.type;
        draft.service.type = getDefaultMapType(draft.mapConfigs, draft.service.schema, nightMode, draft.service.type);
        // 100% opacity makes dark map to dark and the road stripes are not obvious
        draft.opacity = isNightMapType(draft.service.schema, draft.service.type) ? 0.8 : 0.5;
      } else {
        // day mode
        draft.opacity = 1;
        draft.service.type = getDefaultMapType(draft.mapConfigs, draft.service.schema, nightMode, draft.lightMapType);
        draft.lightMapType = '';
      }
      break;
    }
    case SET_MAP_CONFIGS: {
      const mapConfigs = action.payload;
      draft.mapConfigs = mapConfigs;
      if (mapConfigs.length > 0 && mapConfigs.some(n => n.enabled && n.types.length > 0)) {
        const { provider } = mapConfigs.filter(n => !n.disabled)[0];
        draft.service.schema = provider;
        draft.service.type = getDefaultMapType(mapConfigs, provider, draft.nightMode);
      }
      break;
    }
    default:
      break;
  }
});
