import React, { useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import PropTypes from 'prop-types';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import db, {
  FAVORITE_STORE,
  LANGUAGE_STORE,
  settingKeys,
  SETTING_STORE,
  SYMBOL_STORE,
  TEMPLATE_STORE,
} from './database';
import { getSymbolData, getSymbolCategoryOrderMap, getTemplateData } from './source';
import { SymbolContext, TemplateContext } from '@src/contexts';
import { FavoriteContext } from '@src/contexts/FavoriteContext';
import { appName, environment } from '@src/environment';
import { loadTemplateDataFromBlob } from '@src/utils';
import LncdSpin from '@src/components/LncdSpin';

/**
 * expose to Ethos to get Crash Designer template version
 */
export const getSettings = async () => {
  const templateUpdatedAt = (await db[SETTING_STORE].get(settingKeys.TEMPLATE)).updatedAt;
  const symbolUpdatedAt = (await db[SETTING_STORE].get(settingKeys.SYMBOL)).updatedAt;
  return { templateUpdatedAt, symbolUpdatedAt };
};

/**
 * Check if the symbol should be considered as user favorites by usage.
 */
export const isFrequentlyUsed = useTimestamps => {
  const now = new Date().getTime();
  const recentTimestamps = useTimestamps.filter(timestamp => timestamp + environment.favoriteTimeRange > now);
  return recentTimestamps.length > environment.favoriteUseThreshold;
};

/**
 * Track use of a symbol
 */
export const addUseTimestamp = useTimestamps => {
  return [new Date().getTime(), ...useTimestamps].slice(0, environment.favoriteUseThreshold + 1);
};

const getAllTemplates = async () => {
  const arr = await db[TEMPLATE_STORE].filter(n => n.templateStatus !== 'pendingDelete').toArray();
  // set default contentType as `lncdSvg` if missing
  return arr.map(n => ({ contentType: 'lncdSvg', ...n }));
};

// Recreate database if the local version is the legacy one which is not managed with Dexie
const shouldDrop = name =>
  new Promise(resolve => {
    const request = window.indexedDB.open(name);
    request.onsuccess = ev => resolve(request.result.version > 10e3 || request.result.version < 10);
    request.onerror = ev => resolve(false);
  });

const dropDatabase = name =>
  new Promise(resolve => {
    const request = window.indexedDB.deleteDatabase(name);
    request.onsuccess = ev => resolve();
  });

const DataProvider = props => {
  // Contexts which store data fetched from local IndexedDB
  const [templates, setTemplates] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [favorites, setFavorites] = useState([]);

  // purpose is that user preference need to be loaded before showing the application UI
  const [loaded, setLoaded] = useState(false);

  /**
   * FIXME: LN-389 This part is running for too long caused the white screen to be unresponsive.
   * 临时解决方案：加载时，添加spin图标
   */
  const prepareData = async () => {
    if (await shouldDrop(appName)) await dropDatabase(appName);
    await db.open();

    // listen to table data changes and update context data if necessary
    // this requires package `dexie-observable`
    db.on('changes', async changes => {
      let favoritesChanged = false;

      changes.forEach(async change => {
        const { table } = change;

        if (table === FAVORITE_STORE) {
          // need to reload favorites even only useTimestamps be changed
          favoritesChanged = true;
        }
      });
      favoritesChanged && (await db[FAVORITE_STORE].toArray(setFavorites));
    });

    await initLanguageContext();

    // TODO: put after symbol/template loaded?
    setLoaded(true);

    await Promise.all([loadSymbols(), loadTemplates()]);
    // await load
  };

  React.useEffect(() => {
    prepareData();
  }, []);

  const initLanguageContext = async () => {
    const lang = (await db[SETTING_STORE].get(settingKeys.LANGUAGE)).value;

    // the way to add/update language data to i18n
    // const { id, translation } = ITEM_IN_INDEXEDDB;
    // i18n.addResourceBundle(id, 'translation', translation, true, true);

    const resources = {};
    const languages = await db[LANGUAGE_STORE].toArray();
    languages.forEach(language => {
      resources[language.key] = language;
    });
    i18n.use(initReactI18next).init({
      resources,
      lng: lang,
      fallbackLng: 'en-US',
      load: 'currentOnly',
      interpolation: {
        escapeValue: false,
      },
    });
    i18n.antdLanguage = { enUS, zhCN };
    i18n.on('languageChanged', lang =>
      db[SETTING_STORE].put({
        key: settingKeys.LANGUAGE,
        value: lang,
      })
    );
  };

  /**
   * Sync Ethos passed in symbols/favorites to IndexedDB, and load it back to the context.
   */
  const loadSymbols = async () => {
    try {
      // load built-in resource data if there isn't any
      const symbolCount = await db[SYMBOL_STORE].count();
      if (!symbolCount) {
        const symbols = await getSymbolData();
        await db[SYMBOL_STORE].bulkAdd(symbols);
      }

      // sync Ethos updates
      if (props.symbols) {
        const { putSymbols, updatedAt } = props.symbols;
        if (putSymbols && putSymbols.length > 0) {
          await db[SYMBOL_STORE].where({ source: 'ethos' }).delete();
          await Promise.all(
            putSymbols.map(async symbol => {
              const symbolData = {
                importance: 999,
                displayHeight: 100,
                shouldAdaptIcon: false,
                isHeightAdaptive: false,
                ...symbol,
                functype: '',
                source: 'ethos',
              };
              await db[SYMBOL_STORE].put(symbolData);
            })
          );
          await db[SETTING_STORE].update(settingKeys.SYMBOL, { updatedAt });
        }
      }

      // get from indexedDB
      const symbols = await db[SYMBOL_STORE].toArray();
      const orderMap = getSymbolCategoryOrderMap();
      setSymbols(
        symbols.sort((a, b) => {
          const keyA = `${a.categoryKey}/${a.subCategoryKey}`;
          const keyB = `${b.categoryKey}/${b.subCategoryKey}`;
          if (keyA === keyB) {
            // in the same sub-category
            if (typeof a.importance === 'number' && typeof b.importance === 'number')
              return a.importance - b.importance;
            else if (typeof a.importance === 'number') return -1;
            else if (typeof b.importance === 'number') return 1;
            else return 0;
          } else {
            return orderMap.get(keyA) - orderMap.get(keyB);
          }
        })
      );

      // should set favorites data after symbol data is ready
      if (props.favoriteKeys) {
        const keys = props.favoriteKeys;
        // delete all favorited items, or recently used items that has been marked as favorite from Ethos
        await db[FAVORITE_STORE].filter(n => n.favorite || keys.includes(n.key)).delete();
        const records = symbols
          .filter(n => keys.includes(n.key))
          .map(symbol => ({
            ...symbol,
            favorite: true,
            useCount: 0,
            useTimestamps: [],
          }));
        await db[FAVORITE_STORE].bulkAdd(records);
      }
      // loads favorite data from IndexedDB store to local state
      await db[FAVORITE_STORE].toArray(setFavorites);
    } catch (error) {
      console.error('failed to load/sync symbol data', error);
    }
  };

  const loadTemplates = async () => {
    try {
      // load built-in resource data if there isn't any
      const templateCount = await db[TEMPLATE_STORE].count();
      if (!templateCount) {
        const templates = await getTemplateData();
        await db[TEMPLATE_STORE].bulkAdd(templates);
      }

      // sync Ethos updates
      if (props.templates) {
        // template keys is not accurate to identify a template, need type
        const { putTemplates, deleteTemplateKeys, updatedAt } = props.templates;
        putTemplates &&
          (await Promise.all(
            putTemplates.map(async template => {
              const templateFileData = await loadTemplateDataFromBlob(template.fileContent);
              if (templateFileData) {
                const templateData = {
                  key: template.key,
                  name: template.name,
                  type: template.type,
                  contentType: 'lncdSvg',
                  source: 'ethos',
                  ...templateFileData,
                  ...(template.type === 'userDefined' ? { group: template.group || 'Untitled Group' } : {}),
                  templateStatus: null, // overwrite 'pendingUpload' status
                };
                await db[TEMPLATE_STORE].put(templateData);
              }
            })
          ));
        deleteTemplateKeys && (await db[TEMPLATE_STORE].bulkDelete(deleteTemplateKeys));
        await db[SETTING_STORE].update(settingKeys.TEMPLATE, { updatedAt });
      }

      const templates = await getAllTemplates();
      setTemplates(templates);
    } catch (error) {
      console.error('failed to load/sync template data', error);
    }
  };

  const reloadTemplateData = async () => {
    const templates = await getAllTemplates();
    setTemplates(templates);
  };

  // TODO: listen to template table changes
  const saveTemplate = async template => {
    await db[TEMPLATE_STORE].add(template);
    await reloadTemplateData();
  };

  const editTemplate = async template => {
    await db[TEMPLATE_STORE].put(template);
    await reloadTemplateData();
  };

  // mark as pendingDelete
  const removeTemplate = async id => {
    await db[TEMPLATE_STORE].update(id, { templateStatus: 'pendingDelete' });
    await reloadTemplateData();
  };

  return loaded ? (
    <TemplateContext.Provider value={{ templates, saveTemplate, editTemplate, removeTemplate }}>
      <SymbolContext.Provider value={{ symbols }}>
        <FavoriteContext.Provider value={{ favorites }}>{props.children}</FavoriteContext.Provider>
      </SymbolContext.Provider>
    </TemplateContext.Provider>
  ) : (
    <LncdSpin preload center size={72} />
  );
};

export { i18n };

DataProvider.propTypes = {
  children: PropTypes.any,

  /**
   * additional templates from Ethos
   */
  templates: PropTypes.shape({
    putTemplates: PropTypes.array,
    deleteTemplateKeys: PropTypes.arrayOf(PropTypes.string),
  }),

  /**
   * additional symbols from Ethos
   */
  symbols: PropTypes.object,
};

export default DataProvider;
