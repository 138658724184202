import React, { Component } from 'react';
import _ from 'lodash';
import { GetShapeStyle } from './CommonProps';
import { GetPathLineD } from './BusinessFun';
import { getUserUnitByPixel } from './CommonFunction';
import utility from './Utility';
import { COLOR_TYPE, DASH_ARRAY_TYPE } from '@src/constant';
import { getHandlePointSize, unifyPoint } from '../utils';
import { GetMatrixRotate } from '@src/data/BusinessFun';
import { GetMatrixString } from '@src/data/CommonFunc';

/**
 * Render a path element for cross anchors
 * @component
 * @param {string} id
 * @param {[number, number]} point
 * @param {number} size
 */
export function GetCrossAnchors(id, point, size) {
  if (size == null) size = getUserUnitByPixel() * utility.getHandleRadius();
  let D = [];
  GetPathLineD(D, [point[0] - size, point[1]], [point[0] + size, point[1]]);
  GetPathLineD(D, [point[0], point[1] - size], [point[0], point[1] + size]);
  let shapeStyle = {
    stroke: COLOR_TYPE.BLUE,
    strokeWidth: 1,
    fill: COLOR_TYPE.NONE,
    strokeDasharray: DASH_ARRAY_TYPE.solid,
  };
  return <path d={D.join(' ')} id={id} key={id} {...shapeStyle} />;
}

/**
 * Render a ellipse element for arc anchors
 * @component
 * @param {string} id
 * @param {[number, number]} point
 */
export function GetArcAnchors(id, point) {
  var size = getHandlePointSize();
  return <ellipse id={id} key={id} cx={point[0]} cy={point[1]} rx={size} ry={size} {...GetShapeStyle().ANCHORSSTYPE} />;
}

/**
 * @deprecated discuss before use this method
 * @param {*} id
 * @param {*} point
 */
export function GetRectHandle(id, point) {
  var size = getHandlePointSize();
  return (
    <rect
      id={id}
      key={id}
      x={point[0] - size}
      y={point[1] - size}
      width={size * 2}
      height={size * 2}
      {...GetShapeStyle().ANCHORSSTYPE}
    />
  );
}

/**
 * used for arc transform and street stretch
 * @param {String} id
 * @param {Array | Object} point
 * @returns {SVGCircleElement}
 */
export function CircleHandlePoint({ id, point, ...restProps }) {
  let x, y;
  if (_.isArray(point)) {
    x = point[0];
    y = point[1];
  } else {
    x = point.x;
    y = point.y;
  }
  return <circle className="handle-point" id={id} cx={x} cy={y} r={getHandlePointSize()} {...restProps} />;
}

/**
 *
 * @param {string} id
 * @param {[number, number]} point
 * @returns {SVGCircleElement}
 */
export function getCircleHandlePoint(id, point) {
  return <CircleHandlePoint id={id} point={unifyPoint(point)} key={id} />;
}

export function PolygonHandlePoint({ id, points, ...restProps }) {
  const size = getHandlePointSize();
  const d = points
    .map(point => {
      const [x, y] = point;
      return `${x},${y}`;
    })
    .join(' ');

  return <polygon id={id} points={d} strokeWidth={size / 8} className="handle-point" {...restProps} />;
}

export function SquareRectHandlePoint({ id, point, ...restProps }) {
  const size = getHandlePointSize();
  const [x, y] = unifyPoint(point);
  return (
    <rect
      className="handle-point"
      id={id}
      key={id}
      x={x - size}
      y={y - size}
      width={size * 2}
      height={size * 2}
      {...restProps}
    />
  );
}

// TODO: remove angle prop
export function SquareHandlePoint({ id, point, angle, ...restProps }) {
  const size = getHandlePointSize();
  const [x, y] = unifyPoint(point);
  const points = [[x - size, y - size], [x - size, y + size], [x + size, y + size], [x + size, y - size]];
  restProps.transform = angle ? `rotate(${angle},${x},${y})` : '';

  return <PolygonHandlePoint id={id} points={points} {...restProps} />;
}

/**
 *
 * @param {string} id
 * @param {[number, number]} point
 * @param {number} angle
 */
export function getSquareHandlePoint(id, point, angle) {
  return <SquareHandlePoint id={id} key={id} point={point} angle={angle} />;
}

// FIXME: remove
export function TriangleHandlePoint({ id, angle, point, ...restProps }) {
  const size = getHandlePointSize();
  const [x, y] = point;
  const points = [[x, y + size], [x - size, y - size], [x + size, y - size]];

  let transform = '';
  let angleRotate = 0;
  if (angle) {
    angleRotate = angle + (Math.PI * 3) / 2;
  }
  transform = GetMatrixString(GetMatrixRotate([x, y], angleRotate));

  return <PolygonHandlePoint points={points} id={id} transform={transform} {...restProps} />;
}

// FIXME: remove
export function getTriangleHandlePoint(id, point) {
  return <TriangleHandlePoint id={id} key={id} point={point} />;
}

// FIXME: should be perpendicular to the stripe
export function StripeSplitter(id, point, angle) {
  var width = getUserUnitByPixel() * 3.75;
  var height = getUserUnitByPixel() * 10;
  let D = [];
  D.push('M' + String(point[0] - width) + ',' + String(point[1] - height));
  D.push('L' + String(point[0] - width) + ',' + String(point[1] + height));
  D.push('M' + String(point[0] + width) + ',' + String(point[1] - height));
  D.push('L' + String(point[0] + width) + ',' + String(point[1] + height));
  return (
    <path
      id={id}
      key={id}
      d={D.join(' ')}
      stroke="lime"
      strokeWidth={getUserUnitByPixel() * 3}
      strokeDasharray="none"
      transform={'rotate(' + angle + ',' + point[0] + ',' + point[1] + ')'}
    />
  );
}

// FIXME: use path to render
export function ImageHandlePoint({ id, src, point, ...restProps }) {
  let size = getHandlePointSize() * 3;
  const [x, y] = unifyPoint(point);
  return (
    <image
      className="handle-point btn"
      href={src}
      id={id}
      width={size}
      height={size}
      x={x - size / 2}
      y={y - size / 2}
      {...restProps}
    />
  );
}
