import React, { useCallback } from 'react';

import { GetCloseShapeHandlePointRange, GetShapes } from '@src/data/BusinessFun';
import { GetShapeStyle } from '@src/data/CommonProps';
import {
  StructureDoor32Shape,
  StructureDoor72Shape,
  StructureDoubleDoor16Shape,
} from '@src/actions/StructureFunHandle';
import { COLOR_TYPE, ANCHORS_TYPE, STRUCTURE_DOOR_TYPE } from '@src/constant';
import { strokeOtherProps } from '@src/utils';

function StructureWall({ profile }) {
  return (
    <g ttn-role="wall" ttn-agent="structureWall">
      <path ttn-role="render" ttn-agent="polyArc" d={profile}></path>
    </g>
  );
}

function Structure({ object }) {
  let shapes = [];
  let hideShapes = [];

  if (object.handlepoint.length < 2) return null;

  const getBackRect = object => {
    let pointRange = GetCloseShapeHandlePointRange(object.handlepoint);
    let D = [];
    D.push('M' + String(pointRange[0][0]) + ',' + String(pointRange[0][1]));
    D.push('L' + String(pointRange[1][0]) + ',' + String(pointRange[0][1]));
    D.push('L' + String(pointRange[1][0]) + ',' + String(pointRange[1][1]));
    D.push('L' + String(pointRange[0][0]) + ',' + String(pointRange[1][1]));
    D.push('Z');
    return <path d={D.join(' ')} fill={COLOR_TYPE.NONE} strokeOpacity="0" pointerEvents="all" />;
  };

  const getDoorShape = (shapes, object) => {
    let anchors = object.handlepoint;
    for (let i = 0; i < anchors.length; i++) {
      if (anchors[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
        for (let j = 4; j < anchors[i].length; j++) {
          // 用于门选择拖动的时候显示
          if (anchors[i][j].doortype === STRUCTURE_DOOR_TYPE.door32 && anchors[i][j].isfinish === true) {
            shapes.push(StructureDoor32Shape(anchors[i], anchors[i + 1], j));
          } else if (anchors[i][j].doortype === STRUCTURE_DOOR_TYPE.door72 && anchors[i][j].isfinish === true) {
            shapes.push(StructureDoor72Shape(anchors[i], anchors[i + 1], j));
          } else if (anchors[i][j].doortype === STRUCTURE_DOOR_TYPE.doubledoor16 && anchors[i][j].isfinish === true) {
            shapes.push(StructureDoubleDoor16Shape(anchors[i], anchors[i + 1], j));
          }
        }
      }
    }
  };

  const getWalls = () => {
    let results = [];
    if (
      typeof object.walls != 'undefined' &&
      object.walls != null &&
      (Array.isArray(object.walls) && object.walls.length > 0)
    ) {
      for (let i = 0; i < object.walls.length; i++) {
        results.push(<StructureWall key={object.walls[i].key} profile={object.walls[i].profile} />);
      }
    }
    return results;
  };

  for (let i = 0; i < object.handlepoint.length; i++) {
    if (object.handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
      let D = [];
      let pointHandle = object.handlepoint[i];
      let commonProps = {
        // stroke: pointHandle[3].stroke,
        strokeWidth: pointHandle[3].strokewidth,
        strokeDasharray: pointHandle[3].strokedasharray,
        fill: COLOR_TYPE.NONE,
      };
      D.push('M' + String(object.handlepoint[i][0]) + ',' + String(object.handlepoint[i][1]));
      D.push('L' + String(object.handlepoint[i + 1][0]) + ',' + String(object.handlepoint[i + 1][1]));
      let id = String(i) + '||' + object.operateid + '||' + ANCHORS_TYPE.STRUCTURELINE;
      let hideStyle = GetShapeStyle().LINEWHITESTYLE;
      hideStyle.fill = COLOR_TYPE.NONE;
      hideShapes.push(<path key={i * 2 + 1} d={D.join(' ')} {...hideStyle} id={id} />);
      shapes.push(
        <path
          key={i * 2}
          d={D.join(' ')}
          {...commonProps}
          id={id}
          stroke={pointHandle[3].stroke}
          strokeLinecap="butt"
          {...strokeOtherProps(pointHandle[3].stroke)}
        />
      );
    }
  }

  getDoorShape(shapes, object);
  return (
    <g id={object.functype}>
      {getBackRect(object)}
      {getWalls()}
      {GetShapes(hideShapes)}
      {GetShapes(shapes)}
    </g>
  );
}

export default Structure;
