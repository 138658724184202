import * as React from 'react';

function SvgImageArrowSimple(props) {
  return (
    <svg width={19} height={10} {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M0 3h5v3H0z" />
        <path d="M19 4.295L4 0v9.288z" />
      </g>
    </svg>
  );
}

export default SvgImageArrowSimple;
