import React from 'react';
import { GetShapeStyle } from '@src/data/CommonProps';
import ArrowLength from './ArrowLength';
import { DASH_ARRAY_TYPE } from '@src/constant';

function GuideCircle({ center }) {
  return (
    <g id="guideCircle">
      <circle
        cx={center[0]}
        cy={center[1]}
        r="20"
        {...GetShapeStyle().GUIDELINE}
        strokeDasharray={DASH_ARRAY_TYPE.solid}
      />
    </g>
  );
}

export default GuideCircle;
