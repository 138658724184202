import React from 'react';
import { Button, Input } from 'antd';
import ReactSVG from 'react-svg';
import GroupSelect from '@src/shapes/Template/GroupSelect';
import emitter from '@src/data/Event';
import CustomInputNumber from '@src/components/Modal/Element/CustomInputNumber';
import { EVENT_EMIT_TYPE } from '@src/type/event';

const templateSection = require('@src/assets/Templates');

class ModalTemplate extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;

    this.templateGroups = templateSection.TemplateGroups();
    this.templates = this.initTemplatesData();
    const selectedTemplate = this.templates['0'][0];
    const defaultTemplateId = selectedTemplate.id;

    this.state = {
      object: object,
      groupId: '0',
      templateId: defaultTemplateId,
      templates: this.getDefaultTemplates(),
      searchText: '',
      selectedTemplate: selectedTemplate,
      angle: 0,
    };
  }

  initTemplatesData = () => {
    const templateGroups = this.templateGroups;
    const templates = templateSection.Templates();

    const data = {};

    const compareFn = (a, b) => {
      return a.describe > b.describe ? 1 : a.describe < b.describe ? -1 : 0;
    };

    templateGroups.forEach(item => {
      data[item.id] = [];
    });

    templates.forEach(item => {
      data[item.groupId].push(item);
    });

    templateGroups.forEach(item => {
      data[item.id].sort(compareFn);
    });

    return data;
  };

  getDefaultTemplates = () => {
    return this.templates['0'].filter(item => {
      return item.id;
    });
  };

  getOriginalTemplates = groupId => {
    const templates = this.templates[groupId];
    return templates.filter(item => item.id);
  };

  getSelectedTemplate = templateId => {
    const { templates } = this.state;

    return templates.find(item => {
      return item.id === templateId;
    });
  };

  getTemplateItems = groupId => {
    const { templates, selectedTemplate } = this.state;
    // const templates = this.templates[groupId];
    return templates.map(item => (
      <li className="template-list-item" key={item.id} data-template-id={item.id} onClick={this.handleSelectTemplate}>
        <img src={item.src} className={item.id === selectedTemplate.id ? 'selected' : ''} />
        <p className="desc">{item.describe}</p>
      </li>
    ));
  };

  handleChangeSearchText = e => {
    this.setState({ searchText: e.target.value.trim() });
  };

  handleClose = () => {
    const { handleCloseModal } = this.props;
    handleCloseModal();
  };

  handleChangeGroup = groupId => {
    const id = parseInt(groupId, 10);
    const selectedGroup = this.templateGroups[id];
    const selectedTemplates = this.templates[selectedGroup.id];
    const selectedTemplate = selectedTemplates[0] || null;
    const defaultTemplateId = selectedTemplate ? selectedTemplate.id : '';

    this.setState({
      groupId: groupId,
      templateId: defaultTemplateId,
      templates: selectedTemplates,
      searchText: '',
      selectedTemplate: selectedTemplate,
      angle: 0,
    });
  };

  handleSearch = () => {
    const { groupId, templates, searchText } = this.state;
    const currentTemplates = this.templates[groupId];

    const searched = searchText
      ? currentTemplates.filter(item => {
          return item.describe.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1;
        })
      : this.getOriginalTemplates(groupId);

    this.setState({
      templates: searched,
      angle: 0,
    });
  };

  handleSelectTemplate = e => {
    const templateId = e.currentTarget.getAttribute('data-template-id');
    const template = this.getSelectedTemplate(templateId);

    this.setState({
      templateId: templateId,
      selectedTemplate: template,
      angle: 0,
    });
  };

  handleAngle = angle => {
    const angleValue = angle || 0;
    this.setState({
      angle: angleValue,
    });
  };

  handleUseSelectTemplate = () => {
    const { templateId } = this.state;
    const template = this.getSelectedTemplate(templateId);

    emitter.emit(EVENT_EMIT_TYPE.USING_TEMPLATE, {
      id: template.id,
      name: template.describe,
      path: template.src,
    });

    this.handleClose();
  };

  render() {
    const { groupId, templateId, angle } = this.state;

    const selectedTemplate = this.getSelectedTemplate(templateId) || {};

    const menuStyle = (
      <div className="modal-template-container">
        <div className="modal-template-left-content">
          <div className="template-group">
            <p className="menulblname template-group-title">Template Groups:</p>
            <GroupSelect handleChangeGroup={this.handleChangeGroup} />
          </div>
          <div className="template-search">
            <Input value={this.state.searchText} onChange={this.handleChangeSearchText} style={{ borderRadius: 0 }} />
            <Button type="primary" style={{ marginLeft: 15, borderRadius: 0 }} onClick={this.handleSearch}>
              Search
            </Button>
          </div>
          <ul className="template-list">{this.getTemplateItems(groupId)}</ul>
        </div>
        <div className="modal-template-right-content">
          <div>
            <p className="menulblname preview-title height-28">Preview</p>
            <div className="preview-image">
              {selectedTemplate.src ? (
                <ReactSVG
                  src={selectedTemplate.src}
                  beforeInjection={svg => {
                    svg.classList.add('preview-svg');
                    // svg.setAttribute('style', 'width: 155px');
                    // svg.setAttribute('style', 'height: 155px');
                  }}
                  afterInjection={(error, svg) => {
                    if (error) {
                      console.error(error);
                      return;
                    }

                    const rotateFn = `rotate(${angle.toString()})`;

                    const g = svg.querySelector('g');
                    g.setAttribute('transform', rotateFn);
                  }}
                />
              ) : null}
            </div>
          </div>
          <div>
            <p className="menulblname rotation-title height-28">Rotation</p>
            <div className="template-preview-rotation">
              <CustomInputNumber
                value={this.state.angle}
                min={0}
                max={355}
                formatter={'°'}
                parser={'°'}
                onChangeValue={this.handleAngle}
              />
            </div>
          </div>
          <div className="action-bar">
            <Button type="primary" style={{ marginLeft: 15, borderRadius: 0 }} onClick={this.handleUseSelectTemplate}>
              OK
            </Button>
            <Button
              type="ghost"
              style={{
                marginLeft: 3,
                borderRadius: 0,
                color: '#1f5078',
                border: '1px solid #1f5078',
                width: 82,
              }}
              onClick={this.handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );

    return menuStyle;
  }
}

export default ModalTemplate;
