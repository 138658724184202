import React, { memo, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as workData from '@src/data/WorkData';
import { Tree, Button, Icon } from 'antd';
import { IconDown } from '@src/components/UI/Icons';
import { withTranslation } from 'react-i18next';
import { FUNCTION_TYPE, ROAD_LAYER } from '@src/constant';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import _Street from '@src/data/_Street';
import TouchScroll from '@src/components/TouchDevice/TouchScroll';
import './layer-menu.scss';

const { TreeNode } = Tree;

const Body = ({ treeData, selectedKeys, onSelectItem }) => {
  return (
    <TouchScroll className="lncd-layer-menu-body-container">
      <Tree
        className="lncd-layer-menu-body"
        multiple
        onSelect={onSelectItem}
        selectedKeys={selectedKeys}
        defaultExpandAll
        switcherIcon={<Icon key="caret-down" component={IconDown} className="caret-down" />}
      >
        <TreeNode
          className="lncd-layer-menu-item lncd-layer-menu__sub-menu"
          value={treeData[0].value}
          title={treeData[0].title}
        >
          {treeData[0].children.map((data, i) => (
            <TreeNode
              className="lncd-layer-menu-item lncd-layer-menu__sub-menu-item"
              key={data.key}
              title={<span>{`${i + 1} ${data.title}`}</span>}
            />
          ))}
        </TreeNode>
        <TreeNode
          className="lncd-layer-menu-item lncd-layer-menu__sub-menu"
          value={treeData[1].value}
          title={treeData[1].title}
        >
          {treeData[1].children.map((data, i) => (
            <TreeNode
              className="lncd-layer-menu-item lncd-layer-menu__sub-menu-item"
              key={data.key}
              title={<span>{`${i + 1} ${data.title}`}</span>}
            />
          ))}
        </TreeNode>
        <TreeNode
          className="lncd-layer-menu-item lncd-layer-menu__sub-menu"
          value={treeData[2].value}
          title={treeData[2].title}
        >
          {treeData[2].children.map((data, i) => (
            <TreeNode
              className="lncd-layer-menu-item lncd-layer-menu__sub-menu-item"
              key={data.key}
              title={<span>{`${i + 1} ${data.title}`}</span>}
            />
          ))}
        </TreeNode>
      </Tree>
    </TouchScroll>
  );
};

const Footer = ({
  t,
  treeData,
  selectedDataset,
  onClickTopBtn,
  onClickPrevBtn,
  onClickNextBtn,
  onClickBottomBtn,
  onClickRemoveBtn,
}) => {
  const [disabledTop, setDisabledTop] = useState(true);
  const [disabledBottom, setDisabledBottom] = useState(true);
  const [disabledRemoveBtn, setDisabledRemoveBtn] = useState(true);
  useEffect(() => {
    if (selectedDataset.length === 0) {
      // 没有选中任何对象 禁用所有操作
      setDisabledTop(true);
      setDisabledBottom(true);
      setDisabledRemoveBtn(true);
    } else if (selectedDataset.length > 1) {
      // 选中的对象超过两个 只能进行删除操作
      setDisabledTop(true);
      setDisabledBottom(true);
      setDisabledRemoveBtn(false);
    } else {
      const { operateId, roadLayer } = selectedDataset[0];

      let currentTreeData;
      if (roadLayer === 0) {
        currentTreeData = treeData[1].children;
      } else if (roadLayer === 1) {
        currentTreeData = treeData[2].children;
      } else if (roadLayer === -1) {
        currentTreeData = treeData[0].children;
      }
      if (currentTreeData.length === 0 || operateId === '') {
        // 当前菜单中不存在对象 禁用所有操作
        setDisabledTop(true);
        setDisabledBottom(true);
        setDisabledRemoveBtn(true);
      } else {
        // 当对象处于顶部时 禁用向上移动操作
        // 当对象处于底部时 禁用向下移动操作
        setDisabledTop(operateId === currentTreeData[0].operateId);
        setDisabledBottom(operateId === currentTreeData[currentTreeData.length - 1].operateId);
        setDisabledRemoveBtn(false);
      }
    }
  }, [treeData, selectedDataset]);

  return (
    <div className="lncd-layer-menu-footer">
      <Button className="top-btn" icon="double-left" size="small" disabled={disabledTop} onClick={onClickTopBtn} />
      <Button icon="up" size="small" disabled={disabledTop} onClick={onClickPrevBtn} />
      <Button icon="down" size="small" disabled={disabledBottom} onClick={onClickNextBtn} />
      <Button
        className="bottom-btn"
        icon="double-right"
        size="small"
        disabled={disabledBottom}
        onClick={onClickBottomBtn}
      />
      <Button size="small" onClick={onClickRemoveBtn} disabled={disabledRemoveBtn}>
        {t('property.laneTab.removeLabel')}
      </Button>
    </div>
  );
};

function LayerMenu({ useData, t }) {
  const [treeData, setTreeData] = useState([
    {
      title: ROAD_LAYER[0].label, // Under
      value: '0-0',
      key: '0-0',
      children: [],
    },
    {
      title: ROAD_LAYER[1].label, // Ground
      value: '0-1',
      key: '0-1',
      children: [],
    },
    {
      title: ROAD_LAYER[2].label, // Overpass
      value: '0-2',
      key: '0-2',
      children: [],
    },
  ]);
  const [selectedDataset, setSelectedDataset] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    // 排除不能被图层控制的对象
    const filteredData = useData.filter(
      obj =>
        obj.functype !== FUNCTION_TYPE.STREETSURFACE &&
        obj.functype !== FUNCTION_TYPE.CURBRETURN &&
        obj.functype !== FUNCTION_TYPE.SELECTION &&
        obj.functype !== FUNCTION_TYPE.ZOOMSELECT
    );

    const newTreeData = filteredData
      .map(o => ({
        title: getTitle(o),
        key: o.operateid,
        operateId: o.operateid,
        // 非StreetNew对象 没有roadLayer 默认设置为ground类型
        roadLayer: o.roadLayer || 0,
      }))
      .reduce(
        (data, x) => {
          if (x.roadLayer === 0) {
            data['ground'].push(x);
          } else if (x.roadLayer === 1) {
            data['overpass'].push(x);
          } else if (x.roadLayer === -1) {
            data['under'].push(x);
          }
          return data;
        },
        { ground: [], overpass: [], under: [] }
      );

    setTreeData([
      {
        ...treeData[0],
        children: newTreeData['under'],
      },
      {
        ...treeData[1],
        children: newTreeData['ground'],
      },
      {
        ...treeData[2],
        children: newTreeData['overpass'],
      },
    ]);

    //
    const selectedDataset = workData.getSelectObjects();
    if (selectedDataset.length > 0) {
      // 逻辑变化时 通过workData找到对象进行修改
      setSelectedDataset(
        selectedDataset.map(data => ({
          operateId: data.operateid,
          // functype: data.functype,
          // selectflag: data.selectflag,
          roadLayer: data.roadLayer || 0,
        }))
      );
    } else {
      setSelectedDataset([]);
    }
  }, [useData]);

  useEffect(() => {
    setSelectedKeys(selectedDataset.map(data => data.operateId));
  }, [selectedDataset]);

  /**
   *
   * @param {*} obj
   * @returns {string}
   */
  const getTitle = obj => {
    if (obj.functype === FUNCTION_TYPE.SYMBOL) {
      return obj.symbol.key;
    } else {
      return obj.functype;
    }
  };

  function getCurrentLayer(data) {
    return typeof data.roadLayer !== 'undefined' ? ROAD_LAYER.find(x => x.value === data.roadLayer).value : 0;
  }

  const onClickTopBtn = () => {
    const selectedDataId = selectedDataset[0].operateId;
    let selectedData = workData.getObject(selectedDataId);
    let currentLayer = getCurrentLayer(selectedData);

    const data = treeData[currentLayer + 1].children;
    let firstData = data[0];

    workData.deleteObject(selectedDataId);
    workData.addDataBeforeObject(firstData.operateId, selectedData);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  const onClickPrevBtn = () => {
    const selectedDataId = selectedDataset[0].operateId;
    let selectedData = workData.getObject(selectedDataId);
    let currentLayer = getCurrentLayer(selectedData);

    const data = treeData[currentLayer + 1].children;
    let currentIndex = data.findIndex(v => v.operateId === selectedDataId);
    let currentData = data[currentIndex - 1];

    workData.deleteObject(selectedDataId);
    workData.addDataBeforeObject(currentData.operateId, selectedData);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  const onClickNextBtn = () => {
    const selectedDataId = selectedDataset[0].operateId;
    let selectedData = workData.getObject(selectedDataId);
    let currentLayer = getCurrentLayer(selectedData);

    const data = treeData[currentLayer + 1].children;
    let currentIndex = data.findIndex(v => v.operateId === selectedDataId);
    let nextData = data[currentIndex + 1];

    workData.deleteObject(selectedDataId);
    workData.addDataAfterObject(nextData.operateId, selectedData);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  const onClickBottomBtn = () => {
    const selectedDataId = selectedDataset[0].operateId;
    let selectedData = workData.getObject(selectedDataId);
    let currentLayer = getCurrentLayer(selectedData);

    const data = treeData[currentLayer + 1].children;
    let lastData = data[data.length - 1];

    workData.deleteObject(selectedDataId);
    workData.addDataAfterObject(lastData.operateId, selectedData);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  const onClickRemoveBtn = () => {
    if (selectedDataset.length === 1) {
      const selectedDataId = selectedDataset[0].operateId;
      workData.deleteObject(selectedDataId);
    } else {
      const selectedDataIdArr = selectedDataset.map(data => data.operateId);
      workData.deleteObject(selectedDataIdArr);
    }
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    setSelectedDataset([]);
  };
  const onSelectItem = data => {
    if (data.length === 0) {
      workData.selectObject(null);
      emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      setSelectedDataset([]);
    } else {
      const operateId = data[data.length - 1];
      workData.selectObject(operateId);
      let selectedData = workData.getObject(operateId);
      emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      setSelectedDataset([
        {
          operateId,
          roadLayer: selectedData.roadLayer || 0,
        },
      ]);
    }
  };

  return (
    <div className="lncd-layer-menu">
      <Body treeData={treeData} selectedKeys={selectedKeys} onSelectItem={onSelectItem} />
      <Footer
        t={t}
        treeData={treeData}
        selectedDataset={selectedDataset}
        onClickTopBtn={onClickTopBtn}
        onClickPrevBtn={onClickPrevBtn}
        onClickNextBtn={onClickNextBtn}
        onClickBottomBtn={onClickBottomBtn}
        onClickRemoveBtn={onClickRemoveBtn}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  useData: state.workData.useData,
});

export default connect(
  mapStateToProps,
  null
)(withTranslation()(memo(LayerMenu)));
