import * as React from 'react';

function SvgIconPathMutiple(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g transform="translate(1)" stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
        <rect x={2} y={15} width={4} height={4} rx={1} />
        <rect x={5} y={2} width={4} height={4} rx={1} />
        <rect x={17} y={18} width={4} height={4} rx={1} />
        <rect x={13} y={11} width={4} height={4} rx={1} />
        <rect x={16} y={3} width={4} height={4} rx={1} />
        <path d="M4 15l3-9M6 17l11 3M9 4l7 1M18 7l-3 4" />
      </g>
    </svg>
  );
}

export default SvgIconPathMutiple;
