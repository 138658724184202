import * as React from 'react';

function SvgIconRuler(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M21 2H3a1 1 0 00-1 1v18a1 1 0 001 1h5a1 1 0 001-1V9h12a1 1 0 001-1V3a1 1 0 00-1-1zm-1 5h-1V5h-2v2h-2V5h-2v2h-2V5H9v2H8a1 1 0 00-1 1v1H5v2h2v2H5v2h2v2H5v2h2v1H4V4h16v3z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconRuler;
