import _ from 'lodash';
import * as workData from '@src/data/WorkData';
import { INITIAL_SNAPSHOTS, TAKE_SNAPSHOTS, SET_DIAGRAM_SAVED, UNDO, REDO } from '@src/type/snapshots';

const initialState = {
  /**
   * all versions of snapshots - different versions of use data
   * push initial version of useData to it directly
   * @type {Snapshot[]}
   */
  snapshots: [],
  /**
   * the snapshot version user is viewing by undo/redo functionalities.
   * the snapshot of `_snapshots.length - 1` is not always the same as `useData` as
   * `useData` may has unsaved changes
   */
  snapshotIndex: 0,
  isBrowsingSnapshot: false,
  // track the status of the diagram data so that we know if there's any unsaved changes
  hasUnsavedChanges: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_SNAPSHOTS: {
      return {
        snapshots: action.snapshot ? [action.snapshot] : [{ map: undefined, shapes: [] }],
        snapshotIndex: 0,
        isBrowsingSnapshot: false,
        hasUnsavedChanges: false,
      };
    }
    case TAKE_SNAPSHOTS: {
      return {
        snapshots: action.snapshots,
        snapshotIndex: action.snapshots.length - 1,
        isBrowsingSnapshot: false,
        hasUnsavedChanges: true,
      };
    }
    case SET_DIAGRAM_SAVED: {
      return {
        ...state,
        hasUnsavedChanges: false,
      };
    }

    // Used by action/canvas
    case UNDO: {
      const { snapshots, snapshotIndex } = state;
      let newSnapshots = _.cloneDeep(snapshots);
      let newSnapshotIndex = Math.max(0, snapshotIndex - 1);
      workData.resumeUseData(newSnapshots[newSnapshotIndex].shapes);
      return {
        ...state,
        snapshotIndex: newSnapshotIndex,
        isBrowsingSnapshot: newSnapshotIndex !== newSnapshots.length - 1,
        hasUnsavedChanges: true,
      };
    }
    // Used by action/canvas
    case REDO: {
      const { snapshots, snapshotIndex } = state;
      let newSnapshots = _.cloneDeep(snapshots);
      let newSnapshotIndex = Math.min(newSnapshots.length - 1, snapshotIndex + 1);
      workData.resumeUseData(newSnapshots[newSnapshotIndex].shapes);
      return {
        ...state,
        snapshotIndex: newSnapshotIndex,
        isBrowsingSnapshot: newSnapshotIndex !== newSnapshots.length - 1,
        hasUnsavedChanges: true,
      };
    }
    // case 'DELETE_SNAPSHOT': {
    //   const { snapshots } = state;
    //   const { index } = action;
    //   return {
    //     ...state,
    //     snapshots: [snapshots.slice(0, index - 1), snapshots.slice(index)],
    //   };
    // }
    default:
      return state;
  }
};
