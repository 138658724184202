import { combineReducers } from 'redux';
import symbolBar from './symbolBar';
import sidebar from './sidebar';
import street from './street';
import propMenu from './propMenu';
import app from './app';
import canvas from './canvas';
import transform from './transform';
import pointer from './pointer';
// FIXME: migrate to @src/store/WorkData module
import map from './map';
import ethos from './ethos';
import { reducer as devOptions } from '@src/components/Header/DevOptions';
import workData from './workData';
import snapshots from './snapshots';
import drawingStatus from './drawingStatus';

const reducers = combineReducers({
  sidebar,
  symbolBar,
  street,
  propMenu,
  app,
  canvas,
  transform,
  pointer,
  map,
  ethos,
  devOptions,
  workData,
  snapshots,
  drawingStatus,
});

export default reducers;
