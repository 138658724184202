import React from 'react';
import {
  FindCircle,
  GetCenterInTwoPoints,
  LengthBetweenPoints,
  IsUnderLine,
  getAngleBy2Points,
  ByTime,
  IsBigArc,
  IsArcDirection,
  TowPointVerticalLength,
  GetCenter,
  TowPointVerticalLengthPoints,
  FindCircleLinePoint,
  LengthArc,
  GetLineKB,
  toFixed,
  GetCirclePointK,
  IsOneLine,
  GetArcAngle,
  IsZero,
  TowPointVerticalLengthByStatus,
  pointTransform,
} from '@src/data/CommonFunc';
import { GetShapeStyle } from '@src/data/CommonProps';
import { getArrowType } from '@src/data/BusinessFun';
import Text from '../Text';
import ArrowMarker from '../ArrowMarker';
import { COLOR_TYPE, PATTERN_TYPE, DASH_ARRAY_TYPE } from '@src/constant';

class Stripe extends React.Component {
  constructor(props) {
    super(props);
  }
  getRect = (point1, point2, point3, point4, topR, r, isBigArc, isDirection) => {
    var D = [];
    D.push('M' + point1[0].toString() + ',' + point1[1].toString());
    D.push('L' + point2[0].toString() + ',' + point2[1].toString());
    D.push('A' + r.toString() + ',' + r.toString());
    D.push('0');
    D.push(isBigArc + ',' + isDirection);
    D.push(point3[0].toString() + ',' + point4[1].toString());
    D.push('L' + point4[0].toString() + ',' + point4[1].toString());
    let rebackDirection = isDirection == '0' ? '1' : '0';
    D.push('A' + topR.toString() + ',' + topR.toString());
    D.push('0');
    D.push(isBigArc + ',' + rebackDirection);
    D.push(point1[0].toString() + ',' + point1[1].toString());
    return <path d={D.join(' ')} {...GetShapeStyle().LINEWHITESTYLE} fill={COLOR_TYPE.WHITE} />;
  };
  getShape = object => {
    const SPACE = 0.75 * object.style.strokewidth * 2;
    const x1 = object.handlepoint[0][0];
    const x2 = object.handlepoint[1][0];
    const y1 = object.handlepoint[0][1];
    const y2 = object.handlepoint[1][1];
    const cirlcle = FindCircle(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2]);
    const r = cirlcle[2];
    let isBigArc = IsBigArc(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2], r);
    let isDirection = IsArcDirection(object.handlepoint[0], object.handlepoint[2], object.handlepoint[1]);
    var D = [],
      shape = null;
    D.push('M' + x1.toString() + ',' + y1.toString());
    D.push('A' + r.toString() + ',' + r.toString());
    D.push('0');
    D.push(isBigArc + ',' + isDirection);
    D.push(x2.toString() + ',' + y2.toString());

    let endPoint = TowPointVerticalLengthByStatus(
      object.handlepoint[0],
      object.handlepoint[1],
      object.handlepoint[0],
      SPACE,
      false
    );
    let moveMatrix = [1, 0, 0, 1, endPoint[0] - object.handlepoint[0][0], endPoint[1] - object.handlepoint[0][1]];
    let point0 = pointTransform(moveMatrix, object.handlepoint[0]);
    let point1 = pointTransform(moveMatrix, object.handlepoint[1]);
    let point2 = pointTransform(moveMatrix, object.handlepoint[2]);
    let topR = isDirection == '0' ? r - SPACE : r + SPACE;
    let R = r == 0 ? 0 : topR;
    if (!IsZero(r)) {
      let circleOut = [cirlcle[0], cirlcle[1], R];
      point0 = FindCircleLinePoint(circleOut, object.handlepoint[0]);
      point1 = FindCircleLinePoint(circleOut, object.handlepoint[1]);
    }
    let hideShape = this.getRect(
      point0,
      object.handlepoint[0],
      object.handlepoint[1],
      point1,
      R,
      r,
      isBigArc,
      isDirection
    );
    if (object.style.pattern === PATTERN_TYPE.singleSolid) {
      shape = (
        <g id={object.style.pattern}>
          {hideShape}
          <path d={D.join(' ')} />
        </g>
      );
    } else if (object.style.pattern === PATTERN_TYPE.singleDash) {
      shape = (
        <g id={object.style.pattern}>
          {hideShape}
          <path d={D.join(' ')} strokeDasharray="96,48" />
        </g>
      );
    } else if (object.style.pattern === PATTERN_TYPE.doubleSolid) {
      var dLine = [];
      dLine.push('M' + point0[0].toString() + ',' + point0[1].toString());
      dLine.push('A' + R.toString() + ',' + R.toString());
      dLine.push('0');
      dLine.push(isBigArc + ',' + isDirection);
      dLine.push(point1[0].toString() + ',' + point1[1].toString());
      shape = (
        <g id={object.style.pattern}>
          {hideShape}
          <path d={dLine.join(' ')} strokeDasharray={DASH_ARRAY_TYPE.solid} />
          <path d={D.join(' ')} strokeDasharray={DASH_ARRAY_TYPE.solid} />
        </g>
      );
    } else if (object.style.pattern === PATTERN_TYPE.dashSolid) {
      console.log(D);
      console.log(dLine);
      var dLine = [];
      dLine.push('M' + point0[0].toString() + ',' + point0[1].toString());
      dLine.push('A' + R.toString() + ',' + R.toString());
      dLine.push('0');
      dLine.push(isBigArc + ',' + isDirection);
      dLine.push(point1[0].toString() + ',' + point1[1].toString());
      shape = (
        <g id={object.style.pattern}>
          {hideShape}
          <path d={D.join(' ')} strokeDasharray="96,48" />
          <path d={dLine.join(' ')} strokeDasharray={DASH_ARRAY_TYPE.solid} />
        </g>
      );
    } else if (object.style.pattern === PATTERN_TYPE.solidDash) {
      var dLine = [];
      dLine.push('M' + point0[0].toString() + ',' + point0[1].toString());
      dLine.push('A' + R.toString() + ',' + R.toString());
      dLine.push('0');
      dLine.push(isBigArc + ',' + isDirection);
      dLine.push(point1[0].toString() + ',' + point1[1].toString());
      shape = (
        <g id={object.style.pattern}>
          {hideShape}
          <path d={D.join(' ')} />
          <path d={dLine.join(' ')} strokeDasharray="96,48" />
        </g>
      );
    } else if (object.style.pattern === PATTERN_TYPE.POTLINE) {
      shape = (
        <g id={object.style.pattern}>
          {hideShape}
          <path d={D.join(' ')} strokeDasharray={DASH_ARRAY_TYPE.Dashes} />
        </g>
      );
    } else if (object.style.pattern === PATTERN_TYPE.doubleDash) {
      var dLine = [];
      dLine.push('M' + point0[0].toString() + ',' + point0[1].toString());
      dLine.push('A' + R.toString() + ',' + R.toString());
      dLine.push('0');
      dLine.push(isBigArc + ',' + isDirection);
      dLine.push(point1[0].toString() + ',' + point1[1].toString());
      shape = (
        <g id={object.style.pattern}>
          {hideShape}
          <path d={D.join(' ')} strokeDasharray="96,48" />
          <path d={dLine.join(' ')} strokeDasharray="96,48" />
        </g>
      );
    }
    return shape;
  };

  render() {
    const { props } = this;
    const { object } = props;
    if (object.handlepoint.length < 3) return null;

    return <g id={object.functype}>{this.getShape(object)}</g>;
  }
}

export default Stripe;
