import * as React from 'react';

function SvgIconPaintedDivider(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-5 .002h24v24H-5z" />
        <path stroke="currentColor" d="M11 24V0M3 24V0M13 24V0M1 24V0" />
      </g>
    </svg>
  );
}

export default SvgIconPaintedDivider;
