import React from 'react';
import Images from '@src/assets/Images';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckboxContainer = styled.div`
  height: ${props => (props.size ? props.size : 28)}px;
  display: flex;
  cursor: pointer;
`;

const StyledCheckbox = styled.div`
  margin: auto;
  height: ${props => (props.size ? props.size : 28)}px;
  width: ${props => (props.size ? props.size : 28)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-prop-menu-checkbox-border);
  border-radius: 2px;
  background: ${props => (props.checked ? 'var(--color-prop-menu-checkbox)' : 'white')};
`;

function LncdCheckbox(props) {
  const { size, checked, onClick } = props;
  return (
    <CheckboxContainer>
      <StyledCheckbox checked={checked} size={size}>
        <img src={Images.IconCheckBoxRight} alt="" onClick={onClick} style={{ width: size && size - 2 }} />
      </StyledCheckbox>
    </CheckboxContainer>
  );
}

LncdCheckbox.propTypes = {
  size: PropTypes.number,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LncdCheckbox;
