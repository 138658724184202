import * as React from 'react';

function SvgIconMoon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd" opacity={0.9}>
        <path d="M0 0h24v24H0z" />
        <path
          d="M16.486 3.071a1 1 0 00-1.343 1.343A7.92 7.92 0 0116 8c0 4.411-3.589 8-8 8a7.913 7.913 0 01-3.586-.856.998.998 0 00-1.343 1.343A9.95 9.95 0 0012 22c5.514 0 10-4.485 10-10 0-3.796-2.113-7.217-5.514-8.929z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconMoon;
