import * as Icons from '@src/components/UI/Icons';

export const subCategoryIconMapping = {
  streets: Icons.IconRoad,
  'lane-markers': Icons.IconRoadMarker,
  passengers: Icons.IconSedan,
  pickup: Icons.IconPickup,
  commercial: Icons.IconTruck,
  emergency: Icons.IconPolice,
  'semi-trailers': Icons.IconTrailer,
  marine: Icons.IconBoat,
  recreational: Icons.IconRv,
  rail: Icons.IconTrain,
  other: Icons.IconFolder,
  'accident-scene': Icons.IconAccidentScene,
  animals: Icons.IconPet,
  'traffic-signals': Icons.IconTrafficLight,
  'road-objects': Icons.IconRoadBlock,
  'nature-objects': Icons.IconTree,
  signs: Icons.IconRoadSign,
  nhtsa: Icons.IconNhtsa,
  buildings: Icons.IconBuilding,
  'station-line': Icons.IconStationLine,
  triangulation: Icons.IconTriangulation,
  'xy-measurement': Icons.IconXyMeasurement,
};
