export default `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Helvetica;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:宋体;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"\@宋体";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"页脚 字符";
	margin:0cm;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
span.apple-converted-space
	{mso-style-name:apple-converted-space;}
span.a
	{mso-style-name:"页脚 字符";
	mso-style-link:页脚;}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
span.msoDel
	{mso-style-name:"";
	text-decoration:line-through;
	color:red;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:10.0pt;
	line-height:115%;}
 /* Page Definitions */
 @page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 54.0pt 72.0pt 54.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
  {margin-bottom:0cm;}
-->
</style>

</head>

<body lang=ZH-CN link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;margin-top:0cm;text-align:justify;
text-justify:inter-ideograph;line-height:normal;border:none;padding:0cm'><span
lang=EN-US style='color:red;display:none'>Top of Form</span></p>

</div>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;text-justify:inter-ideograph;line-height:normal'><span
lang=EN-US style='color:red'>The following End User License Agreement applies
to your use of LexisNexis site(s), services, applications, and software
(including accompanying drivers, libraries, etc.), accessed through or by you
(or your organization) if you (or your organization) do not have a valid
non-expired written agreement (“Agreement”) directly with LexisNexis.&nbsp;If
you (or your organization) have an Agreement with LexisNexis the terms and
conditions of that Agreement shall control over the terms of this End User
License Agreement.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:6.0pt;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><b><span lang=EN-US style='color:#333333'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify;text-justify:inter-ideograph;line-height:normal'><b><span
lang=EN-US><span class=msoIns><ins
cite="mailto:Carmichael,%20Thomas%20P%20(RIS-HBE)" datetime="2021-01-13T14:37">LEXISNEXIS
CRASH DESIGNER</ins></span> END USER LICENSE AGREEMENT</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.95pt;margin-bottom:
0cm;margin-left:6.0pt;text-align:justify;text-justify:inter-ideograph;
line-height:normal'><b><span lang=EN-US style='color:red'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.95pt;margin-bottom:
0cm;margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><b><span lang=EN-US style='color:red'>IMPORTANT:  READ</span></b><b><span
lang=EN-US style='color:#333333'>&nbsp;</span></b><b><span lang=EN-US
style='color:red'>CAREFULLY</span></b><b><span lang=EN-US style='color:#333333'>&nbsp;</span></b><span
lang=EN-US>– This End User License Agreement (“<b>EULA</b>”) is a binding
contract between you as an individual user and on behalf of the law enforcement
agency you represent (“<b>Agency</b>”) (collectively “<b>User(s)</b>”) and
LexisNexis Coplogic Solutions Inc., on behalf of itself and its affiliates (collectively
“<b>LN</b>”), for the LN sites, services, applications, and software
(including, accompanying drivers, libraries, etc.), accessed through or by
Users hereunder (collectively, “<b>Services</b>”).&nbsp;As used herein, the
term “<b>User</b>” shall only include those individuals and entities permitted
under Section 2 (“<b>Authorized Users</b>”) to use the Services pursuant to
this EULA.</span><span lang=EN-US> </span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.8pt;margin-bottom:0cm;
margin-left:6.0pt;text-align:justify;text-justify:inter-ideograph;text-indent:
-.05pt;line-height:normal'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.8pt;margin-bottom:0cm;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><b><span lang=EN-US>BY CLICKING THE BOX LABELED “I ACCEPT”, OR BY USING
THESE SERVICES, USER ACCEPTS AND AGREES TO BE BOUND BY ALL OF THE TERMS AND
CONDITIONS OF THIS EULA AND/OR YOU WARRANT THAT YOU ARE AUTHORIZED TO BIND USER
AND ACT ON USER’S BEHALF WITH RESPECT TO THIS EULA&nbsp;</span></b><span
lang=EN-US>.&nbsp;If User does not agree to the terms of this EULA, User may
not use the Services.&nbsp;LN reserves all rights not expressly granted to User
in this EULA.&nbsp;Note that this EULA may be amended or modified by LN at any
time without advanced notice.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:5.8pt;margin-bottom:0cm;
margin-left:0cm;text-align:justify;text-justify:inter-ideograph;line-height:
normal'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Scope</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.&nbsp;Subject
to the terms and conditions of this EULA, User desires to use and/or order the
Services from LN. &nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.7pt;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Some Services
may include the ability to upload, capture, distribute, and/or display Reports.
As used within this EULA, a “<b>Report</b>” shall mean a law enforcement report
related to vehicle accidents, citations, and/or incidents.&nbsp;“<b>Reports</b>”
shall also include any associated or supplemental information provided with the
Report including, agency name, images and upload date, as applicable.  </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.7pt;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Some
Services may include the ability to create, upload, and share information
related to User’s agency, including training materials and User profile
information (collectively, “<b>Agency Information</b>”) with other law
enforcement users of the Services, or otherwise share User information for law
enforcement purposes.  Any sharing of Agency Information by User on the Services
is specifically subject to the terms of this EULA and any instructions,
disclaimers, or requirements within the Services themselves.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.7pt;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.7pt;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Authorized
User(s).&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Only law enforcement entities acting in their official
capacity (“<b>Authorized User(s)</b>”) that have read and agreed to the terms
of this EULA or relevant Agreement with LN are permitted to access and use the
Services. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.7pt;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.7pt;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Grant
and Scope of License.</span></b></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:5.7pt;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>License
Grant and License Restrictions.</span></u><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif'> By accepting this EULA and paying any
applicable Fees (as set forth in Section 7. Fees), LN hereby grants to User a
restricted, limited, revocable license to use the Services only as set forth in
this EULA, and for no other purposes, subject to the restrictions and
limitations set forth below:</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
shall not access or use Services from outside the United States without LN’s
prior written approval; and</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
shall not use the Services to create a competing product, provide data
processing services to third parties, resell, or broker the Services to any third
party, or otherwise use the Services for any personal (non-law enforcement)
purposes; and</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User’s
use of the Services hereunder will not knowingly violate any agreements to
which User is bound; and</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(iv)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
shall not harvest, post, transmit, copy, modify, create derivative works from,
tamper, distribute the Services; or in any way circumvent the navigational
structure of the Services, including to upload or transmit through the any
computer viruses, Trojan horses, worms or anything else designed to interfere
with, interrupt or disrupt the normal operating procedures of Services; and</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(v)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
may not use the Services to&nbsp;store or transmit infringing, libelous, or
otherwise unlawful or tortuous material, or to store or transmit material in
violation of third-party privacy rights or otherwise infringe on the rights of
others; and&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(vi)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
shall not reveal any links, user accounts, or passwords for the Services to any
third parties (third parties shall not include User’s employees who have a need
to know such information); and</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(vii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
shall not permit any third party (third parties shall not include User’s
employees who have a need to know such information) to view or use the
Services, even if such third party is under contract to provide services to
User; and</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:149.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-36.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(viii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
shall comply with all laws, regulations, and rules which govern the use of the
Services.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Other
Restrictions</span></u><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>.&nbsp;In addition LN may, at any time, impose
restrictions and/or prohibitions on User’s use of the Services, or certain data
or no longer offer certain functionalities or features that may be the result
of a modification in LN policy, a modification of third-party agreements, a
modification in industry standards, a Security Event (defined below), a change
in law or regulation, or the interpretation thereof, and/or an order from a
Court, regulator, tribunal or other authority that has jurisdiction over User
or LN.  Upon written notification by LN of such restrictions, User agrees to
comply with such restrictions or, in the event that User is unable to comply,
it shall notify LN in writing of its inability to comply within ten (10) days
after receipt of LN’s written notification.  </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>c.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Violation
of License Terms and / or Restrictions</span></u><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. User agrees that,
if LN determines or reasonably suspects that: (i) User is violating any license
terms, restrictions, or other material provision of this EULA; or (ii) User has
experienced a “<b>Security Event</b>” (as herein defined), LN may, at its sole
option, take immediate action up to and including, without further obligation
or liability of any kind, terminating or suspending User’s account and the
license to use the Services.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
Responsibilities.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>User represents and warrants that User: (i)
has the right to provide Reports and/or Agency Information submitted to or
through the Services to LN and expressly permits LN to distribute and use such
information as permitted hereunder; and (ii) has the right to use the Services,
in all manners and purposes for which it uses or accesses the Services, and
subject to all applicable laws and regulations. User shall be liable for its
agency’s breach of this EULA as if such breach had been committed by User. User
agrees that it will not attempt to gain unauthorized access to any portions of
the Services, other accounts, computer systems or networks connected to any of
LN’s equipment and/or servers through hacking, password mining or other means.
User agrees that it will not obtain or attempt to obtain, materials or
information through any means not intentionally made available through this
Services.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Retention</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.&nbsp;LN
may retain or disclose User data, including Reports, if required to do so by
law or in the good faith belief that such preservation or disclosure is
reasonably necessary to: (a) comply with legal process; (b) enforce this EULA;
(c) respond to claims that any of the Services violates the rights of
third-parties; or (d) protect the rights, property, or personal safety of LN,
other Services users, or the public.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-left:23.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><b><span lang=X-NONE
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>6.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Contribution/Distribution</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. </span></p>

<p class=MsoListParagraph style='margin-left:59.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><span lang=X-NONE
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>
acknowledges and agrees that </span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>LN</span><span lang=X-NONE style='font-size:
11.0pt;font-family:"Calibri",sans-serif'> has compiled certain databases of
person data, vehicle data, and/or incident data contributed from other law
enforcement agencies (“<b>Participating Agencies</b>”) accessing certain of the
Services. </span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'> further
acknowledges and agrees that such contribution data (a) is available only to
Participating Agencies that have authorized the contribution and distribution
of their data via such databases and (b) may be used solely for investigative
and/or law enforcement purposes and for no other purpose whatsoever.  As such, </span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'> agrees
to allow access to </span><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>User’s</span><span lang=X-NONE style='font-size:11.0pt;
font-family:"Calibri",sans-serif'> Reports by Participating Agencies and, in
return, shall receive access to Participating Agencies’ Reports. Agency agrees
that it shall use Participating Agency Reports strictly for investigative
and/or law enforcement purposes only. </span></p>

<p class=MsoListParagraph style='margin-left:59.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><b><span lang=X-NONE
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>For
Services that contemplate the sale of Reports</span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'> via an LN e-Commerce
portal (“<b>LN e-Commerce Portal</b>”)</span><span lang=X-NONE
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>, </span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>LN</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'> shall
distribute Reports and/or specific data extracted from </span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>R</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>eport</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>s</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'> to
individuals or legal entities (“<b>Authorized Requestors</b>”) and other
authorized law enforcement entities (“<b>Agency Requestors</b>”) in accordance
with applicable laws</span><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>,</span><span lang=X-NONE style='font-size:11.0pt;
font-family:"Calibri",sans-serif'> regulations</span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>, and/or an order
from a Court, regulator, tribunal or other authority that has jurisdiction over
User or LN</span><span lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.
</span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Nothing
in this EULA shall prohibit LN’s Affiliates (as defined herein) from purchasing
Reports from the LN e-Commerce Portal, or from distributing previously
purchased Reports and/or specific data extracted from Reports to Authorized
Requestors or Agency Requestors in accordance with applicable laws,
regulations, and/or an order from a court, regulator, tribunal or other
authority that has jurisdiction over User or LN.  </span><span lang=X-NONE
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Nothing in this </span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>EULA</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'> shall
prohibit </span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>A</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>ffiliates
from acquiring Reports from a source other than the </span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>LN </span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>e</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>-C</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>ommerce </span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>P</span><span
lang=X-NONE style='font-size:11.0pt;font-family:"Calibri",sans-serif'>ortal</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Fees.&nbsp;</span></b></p>

<p class=MsoListParagraph style='margin-left:59.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><b><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Fees
Payable to LN</span></u><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>. Any fees due to LN for Services hereunder (“<b>Fees</b>”)
shall be set forth in writing<b>&nbsp;</b>and LN shall issue an invoice for
such Fees.&nbsp;Invoices shall be paid in full by User within thirty (30) days
from the invoice date.<b>&nbsp;</b>LN may increase or decrease the Fee by
providing User no less than sixty (60) days written notice prior to the
effective date of such pricing change. To the extent an interface or other
technological development is required to enable a User designated third party
(i.e., RMS Vendor) to receive Reports from LN at User’s request or to enable LN
to intake User data, such cost shall not be borne by LN.<b>   </b></span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:95.95pt;text-align:justify;text-justify:inter-ideograph;text-indent:
-95.95pt;line-height:normal;punctuation-wrap:simple;text-autospace:none;
vertical-align:baseline'><span lang=EN-US><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><u><span lang=EN-US>Disputes</span></u><span lang=EN-US>. In the
event User has a good faith dispute on all or a portion of an unpaid invoice (“<b>Dispute</b>”),
User shall notify LN in writing and follow the procedures set forth herein.  In
the event of a Dispute, User shall promptly, but in no case more than thirty
(30) days from receipt of the invoice, notify LN in writing pursuant to the
notice provisions of this EULA (“<b>Dispute Notice</b>”).  User shall identify
in writing the specific cause of the Dispute, the amount in dispute, and
reasonable documentation supporting the Dispute.  Invoices not disputed within
ninety (90) days of the invoice date will be deemed valid and may not later be
disputed.  The Parties agree to use best efforts to resolve all Disputes promptly
and in good faith.  However, in the event the Parties are not able to resolve a
Dispute within sixty (60) days from LN’s receipt of a Dispute Notice, LN shall
have the right to discontinue the applicable Service immediately, without such
action constituting a breach or incurring any liability herein. All Fees not
properly disputed or paid shall accrue interest at the rate of eighteen percent
(18%) per annum.   All Fees are calculated for payment made via ACH, Wire, or User
check.  User agrees that Fees exclude taxes (if applicable) or other cost
incurred by User’s RMS Vendor or other third parties and agrees such costs
shall be passed on to User.  LN shall not be required to enter into a
third-party relationship to obtain payment for the Service provided to User;
however, should LN elect to do so, LN reserves the right to charge User additional
fees for such accommodation.  </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Report Fees</span></u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.<b>&nbsp;</b>If
the Services include the sale of Reports:</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:95.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-95.95pt'><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><u><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Agency
Fees</span></u><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. 
The sale of Reports may also generate a fee to be paid to the law enforcement
agency generating the Report (“<b>Agency Fee</b>”) or other agency designated
party.&nbsp; Unless otherwise agreed by the parties in writing, LN shall
collect an Agency Fee on behalf of the Agency for any Reports provided to
Authorized Requestors.&nbsp; In connection with this Section, on a monthly
basis, LN will electronically transfer to agency’s designated account, the
total amount of applicable Agency Fees collected by LN during the previous
month. &nbsp;LN will make available a monthly report to User identifying the
number of Reports provided on its behalf via the LexisNexis® Command Center (“<b>Command
Center</b>”) administration portal and/or its successor.</span></p>

<p class=MsoListParagraph style='margin-left:149.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-36.0pt'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>No
Agency Fee will be paid with respect to the following: </span></p>

<p class=MsoListParagraph style='margin-left:167.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>When an
Affiliate of LN has paid an Agency Fee to acquire a Report for an Authorized
Requestor (including Reports purchased before the applicable Effective Date)
and such Affiliate later resells that Report from its inventory of previously
purchased Reports to another Authorized Requestor; or</span></p>

<p class=MsoListParagraph style='margin-left:167.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>When one
or more components of a Report (e.g., VIN number), rather than the Report in
its entirety, is provided by LN to an Authorized Requestor or an Affiliate of LN
; or</span></p>

<p class=MsoListParagraph style='margin-left:167.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>c.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>When a
Report is acquired by an Affiliate of LN from a source other than the LN e-Commerce
Portal; or</span></p>

<p class=MsoListParagraph style='margin-left:167.95pt;text-align:justify;
text-justify:inter-ideograph;text-indent:-18.0pt'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>d.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>When a fee
is not charged to an Authorized Requestor for the Report.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:95.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Nothing in
this EULA shall require LN or its Affiliate to pay an Agency Fee to the User when
an Authorized Requestor provides a Report and/or specific data extracted from
the Report to a third party after the Authorized Requestor has purchased such
Report from the Affiliate’s inventory of previously purchased Reports. User acknowledges
that all Reports requested by Agency Requestors shall be provided free of
charge.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:95.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-95.95pt'><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'><span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><u><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Fees
Retained by LN</span></u><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>.  Where permitted by law, LN will charge a convenience
fee for each Report provided to an Authorized Requestor (“<b>Convenience Fee</b>”)
which shall be retained by LN. The Convenience Fee shall be established by LN
at its discretion, but in no event shall exceed the amount LN may legally
charge an Authorized Requestor.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>c.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Changes to
Agency Fees</span></u><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>. LN sets the Agency Fee according to the amount
specified by the User in writing, or if no writing exists, the historical
amount in LN’s file for the User.&nbsp;If no historical amount is on file, LN
will attempt to contact the User to determine the appropriate fee.&nbsp;Agency
Fees may be changed anytime by providing written notice pursuant to Section 31
herein. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Third
Party Communications and Sites</span></b><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Third
Party Communications</span></u><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>.  LN disclaims all liability for any “Third Party
Communications” User may receive or any action User may take or refrain from taking
as a result of any Third Party Communications. As used in this EULA, “<b>Third Party
Communications</b>” shall mean any communications, Agency Information, Reports,
or content created or provided by other users available through or on the
Services. User is solely responsible for assessing and verifying the identity
and trustworthiness of the source and content of any Third Party
Communications. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Third
Party Sites</span></u><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>.  The Services may have links to websites hosted by
other parties (&quot;<b>Third Party Sites</b>&quot;), or such Third Party Sites
may have links to the Services. Third Party Sites are independent of LN and LN
has no control over the content on such Third Party Sites. Third Party Sites
are maintained by their respective organizations, and those organizations are
solely responsible for the content of their own websites. Links to Third Party
Sites within the Services are offered as a convenience and for informational
purposes only, not as referrals or endorsements by LN of the Third Party Sites or
their content. Such links do not constitute acceptance of responsibility by LN
for the content or use of Third Party Sites. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>c.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>No
Warranty</span></u><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. 
LN does not verify or make any warranty or representation about the identity or
trustworthiness of the source, information, content, accuracy, opinions
expressed, warranties, products or services, intellectual property compliance,
or links of any Third Party Communications or Third Party Sites. LN assumes no
liability for any infringement, mistakes, misstatements of law, defamation,
slander, libel, omissions, falsehood, obscenity, pornography or profanity in
the statements, opinions, representations or any other form of content
contained in any Third Party Communications or Third Party Sites appearing on or
accessible through this site. User should read the privacy policies and terms
of use agreements on all Third Party Sites.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>d.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Not Legal
Advice</span></u><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.
Content available on or through the Services including any Third Party Sites or
Third Party Communications are not intended to and do not constitute legal
advice and no attorney-client relationship is formed. User’s use of content on
this site or materials linked from this site is at User’s own risk.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Applicable
Laws.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>Each party shall comply with all applicable federal,
state, and local laws and regulations related to its performance hereunder,
including:</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Driver’s
Privacy Protection Act</span></u><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>.&nbsp;User acknowledges that certain
Services provided under this EULA may include the provision of certain personal
information from a motor vehicle record obtained from state Departments of
Motor Vehicles as those terms are defined by the Federal Driver’s Privacy
Protection Act, 18 U.S.C. § 2721 et seq., (“<b>DPPA</b>”) and its state
analogues (“<b>DMV Data</b>”), and that User is required to comply with the
DPPA or state analogues, as applicable.&nbsp;User agrees that it may be
required to certify its permissible use of DPPA or DMV Data at the time it
requests information in connection with certain Services and will recertify
upon request by LN.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Fair
Credit Reporting Act</span></u><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>.&nbsp;The Services provided pursuant to this
EULA are not provided by “consumer reporting agencies” as that term is defined
in the Fair Credit Reporting Act (15 U.S.C. § 1681, et seq.) (“<b>FCRA</b>”)
and do not constitute “consumer reports” as that term is defined in the
FCRA.&nbsp;User certifies that it will not use any of the information it
receives through the Services in whole or in part as a factor in determining
eligibility for credit, insurance, or employment (including hiring,
termination, and promotion decisions) or for any other eligibility purpose that
would qualify the information as a consumer report.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>c.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Protected
Health Information</span></u><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>.&nbsp;User will not provide LN with any
Protected Health Information (as that term is defined in 45 C.F.R. Sec.
160.103) or with Electronic Health Records or Patient Health Records (as those
terms are defined in 42 U.S.C. Sec. 17921(5), and 42 U.S.C. Sec. 17921(11),
respectively) or with information from such records without the execution of a
separate agreement between the Parties.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>d.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Social
Security Numbers.&nbsp;</span></u><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>Social Security Numbers may be available
hereunder as part of Reports and/or related data provided from certain
states.&nbsp;However, User acknowledges that LN will not provide Social
Security Numbers to User. User also agrees not to provide Social Security
Numbers to LN.&nbsp;Should User require Social Security Numbers in connection
with its legal and permitted use of the Services hereunder, User should contact
LN User Service at 1-866-215-2771 for assistance.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>e.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Privacy
Principles</span></u><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.
User shall comply with the “LN Data Privacy Principles” available at&nbsp;</span><span
lang=EN-US><a
href="http://www.lexisnexis.com/privacy/data-privacy-principles.aspx"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;color:windowtext;
text-decoration:none'>http://www.lexisnexis.com/privacy/data-privacy-principles.aspx&nbsp;</span></a></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>, as
updated from time to time.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:59.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Confidential
Information.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>“<b>Confidential Information</b>” means all
non-public information provided by one party (“<b>Disclose</b>r”) to the other
party (“<b>Recipient</b>”), hereunder including without limitation the Services
including technical documentation, applications, configurations, pricing,
computer programs, algorithms, know-how, processes, databases, systems,
inventions (whether patentable or not) Trade Secretes (as defined by law) and
other information (oral or written). <b>Confidential Information does not
include Reports&nbsp;or Agency Information</b>, or information that was, at the
time of the disclosure: (a) or becomes (through no improper action or inaction
by the Recipient) generally known to the public; (b) lawfully disclosed to
Recipient by a third-party and received in good faith and without any duty of
confidentiality by the Recipient or the third-party; (c) in Recipient’s
possession or known to it prior to receipt from Discloser; or (d) independently
developed by Recipient; provided in each case that such forgoing information
was not delivered to or obtained by Recipient as a result of any breach of this
EULA. In addition, LN Confidential Information also includes product and order
information, order inquiry information, product development plans including
prototypes and all feedback related thereto, forecasts, the Services and any
data&nbsp;related thereto, including any improvements, modifications, or
enhancements.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Treatment
of Confidential Information.&nbsp;</span></b><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>Recipient agrees to: (a) protect the
Confidential Information with the same degree of care it uses to protect its
own confidential information of a similar nature, but not less than a
reasonable standard of care; (b) not use Discloser’s Confidential Information
other than as necessary to perform its obligations or as permitted under this
EULA; (c) only disclose Confidential Information to any of its employees or
agents who have a need to know, and are under obligations of confidentiality
(either as a condition of employment or in a written agreement) no less
restrictive than the requirements set forth hereunder; and (d) not remove or
destroy any proprietary or confidential legends or markings placed upon or
contained within any Confidential Information. Recipient may disclose
Confidential Information solely to the extent required by subpoena, court order
or other governmental authority, provided that the Recipient shall give the
Discloser prompt written notice of such subpoena, court order or other
governmental authority so as to allow the Discloser to have an opportunity to
obtain a protective order to prohibit or limit such disclosure at its sole cost
and expense. Confidential Information disclosed pursuant to subpoena, court
order or other governmental authority shall otherwise remain subject to the
terms applicable to Confidential Information.&nbsp;Each party’s obligations
hereunder shall continue for the term of this EULA and for a period of five (5)
years after termination of this EULA, provided however, that with respect to
Trade Secrets, each party’s obligations shall continue for so long as such
Confidential Information continues to constitute a Trade Secret. Unless
otherwise specified herein, upon the written request of Discloser, Recipient
shall return or destroy (and certify such destruction in a signed writing)
Confidential Information of Discloser, including copies thereof, unless
retention of such information is required by law, regulation, court order, or
other similar mandate. In the event of a breach or a threatened breach of the
confidentiality or privacy provisions of this EULA, the non-breaching party may
have no adequate remedy in monetary damages and, accordingly, may seek an
injunction against the breaching party.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Term,
Termination, Payment, and Account Inactivation.&nbsp;</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>This EULA
shall become effective upon the earlier of User’s acceptance of this EULA or
LN’s grant of access to the Services. User shall be responsible for all fees
associated with accessing the Services.&nbsp;LN may terminate this EULA and/or
all access to the Services immediately and without notice: (i) for User’s
non-payment; (ii) if LN has a reasonable basis to believe that User or any of
its employees or agents have used or will use the Services in violation of the
terms of this EULA or have committed or will commit hostile network attacks or
other improper activities; or (iii) due to changes in LN policy, modification
of third-party agreements, a modification in industry standards, a Security
Event (defined below), a change in law or regulation, or the interpretation
thereof.&nbsp;Either party may terminate this EULA at any time, with or without
cause, upon prior written notice to the other party. Upon termination, User
shall immediately cease using the Services and shall dispose of all copies of
any documentation and any other materials received from LN by either returning
same to LN or erasing, purging or destroying same. Upon termination of this
EULA, each party shall be liable for payment to the other party of all amounts
due and payable for Services provided through the effective date of such
termination. LN shall not be obligated to delete from its databases (or from
other storage media) and/or return to User, Reports already provided to LN by
User, and shall be permitted to continue to maintain and distribute the Reports
already in its possession to Authorized Requestors in compliance with
applicable laws and regulations.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Proprietary
Rights.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>The Services are licensed not sold to User pursuant to
the terms of this EULA. This EULA does not transfer ownership of the Services,
documentation or any copy thereof, or any other intellectual property rights of
LN.&nbsp;LN shall retain all right, title and interest in and to the Services,
the documentation and any copies thereof furnished to User or otherwise made by
User hereunder, and all intellectual property embodied therein, including all
rights and benefits afforded under United States copyright, patent, or trade
secret law and international treaties. User shall use the Services consistently
with LN’s right, title and interest therein, shall promptly notify LN of any
threatened or actual infringement thereof, and shall cooperate without charge
(provided that LN will reimburse out of pocket expenses) in LN’s efforts to
protect its rights therein.&nbsp;User shall not remove or obscure any
trademarks, copyright notices or other notices contained on materials accessed
through the Services.&nbsp;Each party retains all right, title, and interest under
applicable contractual, copyright and related laws to their respective
Confidential Information including the right to use such information for all
purposes permissible by applicable laws, rules, and regulations. For LN
Confidential Information, User shall use such information consistent with such
right, title and interest and notify LN of any threatened or actual
infringement thereof.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Security.&nbsp;</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User
acknowledges that the information available through the Services may include
personally identifiable information and it is User’s obligation to keep all
such accessed information confidential and secure.&nbsp;Accordingly, User on
behalf of itself and any of its employees (as applicable) shall (a) restrict
access to Services to those employees who have a need to know as part of their
official duties; (b) ensure that User nor its employees shall (i) obtain and/or
use any information from the Services for personal reasons, or (ii) transfer
any information received through the Services to any party except as permitted
hereunder; (c) keep all user identification numbers, and related passwords, or
other security measures (collectively, “<b>User IDs</b>”) confidential and
prohibit the sharing of User IDs; (d) immediately deactivate the User ID of any
employee who no longer has a need to know, or for terminated employees on or
prior to the date of termination; (e) in addition to any obligations hereunder,
take all commercially reasonable measures to prevent unauthorized access to, or
use of, the Services or data received therefrom, whether the same is in
electronic form or hard copy, by any person or entity; (f) maintain and enforce
data destruction procedures to protect the security and confidentiality of all
information obtained through Services as it is being disposed; (g) purge all information
received through the Services within ninety (90) days of initial receipt;
provided that User may extend such period if and solely to the extent such
information is retained thereafter in archival form to provide documentary
support required for User’s legal or regulatory compliance efforts; (h) be
capable of receiving the Services where the same are provided utilizing “secure
socket layer”, or such other means of secure transmission as is deemed
reasonable by LN; (i) not access and/or use the Services via mechanical,
programmatic, robotic, scripted or other automated search means, other than
through batch or machine-to-machine applications approved by LN; (j) take all
steps to protect their networks and computer environments, or those used to
access the Services, from compromise; (k) on at least a quarterly basis, review
searches performed by its User IDs to ensure that such searches were performed
for a legitimate business purpose and in compliance with all terms and
conditions herein; and (l) maintain policies and procedures to prevent
unauthorized use of User IDs and the Services.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Security
Event.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>In the event User learns or has reason to believe that
User IDs, the Services, or any information related thereto have been misused,
disclosed, or accessed in an unauthorized manner or by an unauthorized person
or contrary to any terms of this EULA (a “<b>User Security Event</b>”), User
shall provide immediate written notice to the Information Security and
Compliance Organization at 1000 Alderman Drive, Alpharetta, Georgia 30005; or
via email to (security.investigations@lexisnexis.com); or by phone at
(1-888-872-5375) with a written notification to follow within twenty four (24)
hours.&nbsp;Additionally User shall promptly investigate the situation and
obtain written consent from LN prior to disclosing LN or the Services to any
third party in connection with the User Security Event.&nbsp;If required by
law, or in LN’s discretion, User shall notify the individuals whose information
was disclosed that a User Security Event has occurred and be responsible for
all legal and regulatory obligations including any associated costs which may
arise in connection with the User Security Event. To the extent permitted by
law, User shall remain solely liable for all costs and claims that may arise
from the User Security Event, including, but not limited to: litigation
(including attorney’s fees); reimbursement sought by individuals (including
costs for credit monitoring and other losses alleged to be in connection with
such User Security Event) and provide all proposed third party notification
materials to LN for review and approval prior to distribution. In the event of
a User Security Event, LN may, in its sole discretion, take immediate action,
including suspension or termination of User’s account, without further
obligation or liability of any kind.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Inspection;
Records.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>User understands and agrees that in order to ensure
compliance with applicable law and LN policies, LN may conduct periodic audits
of User activity and may contact User to provide documentation regarding usage
and executed searches. LN also investigates reports of abuse or misuse by User,
and User agrees to cooperate fully with any and all audits and/or
investigations. When necessary in LN’s determination, subject to reasonable
advance notification, User agrees to permit LN to enter the premises of User
and/or its agents for LN to inspect records, reports and the use of the
Services(s) during regular business hours to verify compliance with the terms
of this EULA. User agrees to permit LN to review User’s account(s), links and
passwords to verify compliance with the terms of this EULA. Violations
discovered in any review by LN will be subject to immediate action including,
but not limited to, immediate suspension of User’s access to data and/or
termination of this EULA. If LN determines that User is in violation of the
terms of this EULA, User shall reimburse LN for all costs incurred in
connection with the inspection(s). Notwithstanding the foregoing, LN shall (i)
retain all other remedies available to it under this EULA, or at law or in
equity, and (ii) be permitted at any time to review by remote access User’s
accounts, links and passwords to verify compliance with the terms of this EULA.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>17.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Downtimes.&nbsp;</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>User acknowledges
and agrees that from time to time access to the Services may be unavailable or
inoperable for various reasons, including but not limited to periodic
maintenance procedures or repairs which LN may undertake from time to time,
equipment malfunctions, or other causes beyond the control of LN or which are
not reasonably foreseeable by LN, including but not limited to interruption or
failure of telecommunication or digital&nbsp;transmission links, network
congestion, or other failures (collectively “<b>Downtimes</b>”). Downtimes may
also result from suspension by LN, in its sole discretion and without notice,
of access to data files and/or the Services due to actual or suspected hostile
network attacks or other improper activities.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>18.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Performance</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. LN will
use commercially reasonable efforts to deliver the Services requested by User
subject to the terms of this EULA.&nbsp;User understands that User may be
restricted from accessing certain Services which may be otherwise available. LN
reserves the right to add materials and features to, and to discontinue
offering any of the materials and features that are currently a part of the
Services.&nbsp;Due to the nature of public record information, the public
records and commercially available data sources used in the Services may contain
errors.&nbsp;Source data is sometimes reported or entered inaccurately,
processed poorly or incorrectly, and is generally not free from defect. The
Services are not the source of data, nor are they a comprehensive compilation
of the data. Before relying on any data, it should be independently verified.</span></p>

<p class=MsoListParagraph style='margin:0cm'><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>19.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Disclaimer
of Warranty.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
LAW, LN AND ITS DISTRIBUTORS AND RELEVANT DATA SUPPLIERS DO NOT MAKE AND HEREBY
DISCLAIM ANY WARRANTY (WHETHER EXPRESS OR IMPLIED), INCLUDING BUT NOT LIMITED
TO ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE,
NONINFRINGEMENT OF THIRD PARTY RIGHTS, OR THE CORRECTNESS, COMPLETENESS OR
CURRENTNESS OF ANY THIRD PARTY COMMUNICATIONS, THIRD PARTY STIES OR <span
class=apple-converted-space>&nbsp;</span>CONTENT THEREIN PROVIDED OR OTHERWISE
MADE AVAILABLE THROUGH THE SERVICES, DATA OR RESULTS, WITH RESPECT TO THE
SERVICES OR DOCUMENTATION, ALL OF WHICH ARE BEING PROVIDED ON AN “<b>AS IS”
BASIS</b>. ANY STATEMENTS REGARDING THE SAME MADE BY A DISTRIBUTOR OR OTHER
THIRD PARTY ARE NOT WARRANTIES AND CANNOT BE RELIED UPON BY USER. THE ENTIRE
RISK ARISING OUT OF USE OF THE SERVICES REMAINS WITH THE USER.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;text-justify:
inter-ideograph'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>20.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Limitation
of Liability.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>User agrees that LN's aggregate liability for
any and all losses or injuries arising out of any act or omission of LN in
connection with anything to be done or furnished under this EULA, regardless of
the cause of the loss or injury, and regardless of the nature of the legal or
equitable right claimed to have been violated, shall never exceed One Hundred
Dollars ($100.00); and User covenants and promises that it will not sue LN for
an amount greater than such sum even if User and/or third-parties were advised
of the possibility of such damages and that it will not seek punitive damages
in any suit against LN.&nbsp;IN NO EVENT SHALL LN BE LIABLE FOR ANY INDIRECT,
PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, HOWEVER ARISING, INCURRED BY
USER FROM RECEIPT OR USE OF INFORMATION DELIVERED HEREUNDER OR THE
UNAVAILABILITY THEREOF.</span></p>

<p class=MsoListParagraph style='margin:0cm'><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>21.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Indemnification.&nbsp;</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>To the
extent permitted by law,<b>&nbsp;</b>User hereby agrees to protect, indemnify,
defend, and hold harmless LN, its affiliates, its subsidiaries, officers,
directors, employees, representatives, data suppliers (including other law
enforcement users providing Reports or Agency Information hereunder), and
distributors from and against any and all costs, claims, demands, damages,
losses, and liabilities (including attorneys' fees and costs) arising from or
in any way related to (a) User’s use of Services and any information received
by User furnished by or through LN; (b) User’s breach of any terms, conditions,
representations or certifications in this EULA; and (c) any Security Event.</span></p>

<p class=MsoListParagraph style='margin:0cm'><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>22.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Arbitration</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.&nbsp;Other
than claims and controversies involving any (i) violation of any of the
proprietary rights of LN, including claims in equity or law to protect the
intellectually property rights of LNs or any of its third party providers, or
(ii) failure to comply with restrictions on use of the Services herein; any
controversy, claim or counterclaim, arising out of or in connection with this
EULA will be resolved by binding arbitration under this Section and the
then-current American Arbitration Association (“<b>AAA</b>”) Commercial Rules,
WITH THE EXCEPTION THAT ANY ARBITRATION WILL BE CONDUCTED AND RESOLVED ON AN
INDIVIDUAL BASIS ONLY AND NOT ON A CLASS-WIDE, MULTIPLE PLAINTIFF OR SIMILAR
BASIS. In the event any court, arbitrator or panel of arbitrators rules that
the foregoing limitation is invalid, then, arbitration shall not be available
and is expressly precluded as a method of conducting and resolving disputes
arising under this EULA. The duty to arbitrate will extend to any employee,
officer, agent or affiliate of either party.&nbsp;The arbitration will be
conducted by a sole arbitrator who is knowledgeable with respect to the
electronic information services industry and is an attorney. The arbitrator’s
award will be final and binding and may be entered in any court having
jurisdiction. The arbitrator will not have the power to award punitive or
exemplary damages, or any damages excluded by, or in excess of, any damage
limitations expressed in this EULA. Each party will bear its own attorneys’
fees and other costs associated with the arbitration, except that the fees
assessed by the AAA for the services of the arbitrator will be divided equally
by the parties.&nbsp;If court proceedings to stay litigation or compel
arbitration are necessary, the party who unsuccessfully opposes such
proceedings will pay all associated costs, expenses and attorneys’ fees which
are reasonably incurred by the other party. Issues of arbitrability will be
determined in accordance and solely with the federal substantive and procedural
laws relating to arbitration; in all other respects, the arbitrator will be
obligated to apply and follow the substantive law of the state as specified in
this EULA.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>23.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Employee
Training.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>User shall train new employees prior to allowing access
to Services on all User’s obligations under this EULA. User shall conduct a
similar review of its obligations under this EULA with existing employees who
have access to Services no less than annually.&nbsp;User shall keep records of
such training.</span></p>

<p class=MsoListParagraph style='margin:0cm'><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>24.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Promotional
Release.&nbsp;</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>User shall not name LN or refer to the use of the
Services or other LN products or services in any publication, news release,
advertisement, or promotional or marketing materials without LN’s prior written
consent, such consent not to be unreasonably withheld. LN may disclose to third
parties that LN provides Services to User.&nbsp;LN may also use User’s name or
related information to the extent necessary to provide the relevant Services
hereunder.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>25.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>No
Solicitation</span></b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>. User shall not distribute on or through the Services
any content containing any advertising, promotion, solicitation for goods,
services or funds or solicitation for other to become members of any enterprise
or organization without the express prior written permission of LN. In no event
may any person or entity solicit anyone with data, information or content
retrieved from this Site.&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>26.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Commercial
Items and Export Restrictions&nbsp;</span></b><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.THE APPLICATION OF
THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS
IS EXPRESSLY EXCLUDED. User acknowledges that the Services are of U.S. origin
and subject to U.S. export jurisdiction. Any export and/or use of Services
outside of the United States of America, and compliance with corresponding
laws, Nation State regulations, taxes, and tariffs, are the sole responsibility
of the User. User acknowledges that LN is subject to economic sanctions laws,
including but not limited to those enforced by the U.S. Department of the
Treasury’s Office of Foreign Assets Control (“<b>OFAC</b>”), the European
Union, and the United Kingdom. Accordingly, User shall comply with all economic
sanctions laws of the United States, the European Union, and the United
Kingdom. User shall not provide access to the Services to any individuals
identified on OFAC’s list of Specially Designated Nationals (“<b>SDN List</b>”),
the UK’s HM Treasury’s Consolidated List of Sanctions Targets, or the EU’s
Consolidated List of Persons, Groups, and Entities Subject to EU Financial
Sanctions. User shall not take any action which would place LN in a position of
non-compliance with any such economic sanctions laws.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>27.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Assignment.&nbsp;</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Neither
this EULA nor the license granted herein may be assigned, transferred or
conveyed by User, in whole or in part by User, without the prior written
consent of LN. An assignment includes without limitation the dissolution,
merger, consolidation, reorganization, sale or other transfer of assets,
properties, or controlling interest of twenty percent (20%) or more of
User.&nbsp;Any assignment by User without the prior written consent of LN shall
be void.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>28.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Survival.&nbsp;</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Those
provisions of this EULA that by their terms, nature or sense survive any
termination or expiration of this EULA shall so survive in accordance with
their terms, including but not limited to provisions related to intellectual
property ownership and proprietary rights, limitations of liability, payment
for product or services, disclaimer of warranties, and nondisclosure of
confidential information.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;text-justify:
inter-ideograph'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>29.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Governing
Law</span></b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.&nbsp;The
laws of the State of Georgia, excluding its conflicts of law rules, govern this
EULA and User’s use of any materials, reports, or the Service. User’s use of
any materials or reports provided by the Service may also be subject to other
local, state, national, or international laws.</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>30.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Affiliates
and Share Facilities</span></b><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif'>. For purposes of this EULA, “Affiliate”
means any corporation, firm, partnership or other entity that directly or
indirectly controls, or is controlled by, or is under common control with LN.
Affiliates shall not be bound by the terms and conditions of this EULA with
respect to the provision of their applicable Services hereunder and nothing in
this EULA shall prevent or limit Affiliates from offering previously purchased
Reports or data extracted from Reports for sale. User understands and agrees
that certain Services furnished under this EULA may actually be provided by one
or more of LN’s affiliates. LN may utilize facilities located outside the
United States to provide support or the Services under this EULA, and if such
centers are utilized they shall be under the control of LN and subject to all
LN policies that govern data access, protection and transport in the United
States.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><span lang=EN-US> </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-indent:-18.0pt'><b><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif'>31.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span></b><b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Notice</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>. All
notices, requests, demands or other communications under this EULA shall be in
writing to LN at 1000 Alderman Drive, Alpharetta, GA 30005 Attn: Coplogic
Solutions.&nbsp;User may also contact customer services at 1-888-949-3835 with
any questions. Notice shall be deemed to have been duly given: (i) on the date
of service if served personally to LN; (ii) on the day after delivery to a
commercial or postal overnight carrier service; or (iii) on the fifth day after
mailing, if mailed to LN, by first class mail, registered or certified, postage
prepaid and properly addressed.  LN or User may change its address for the
purpose of this Section by giving the other party timely, written notice of its
new address in the manner set forth above.</span></p>

<p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:5.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif'>32.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Additional
Terms</span></b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>.
To the extent User does not have a valid non-expired written Agreement directly
with LN, &nbsp;this EULA is the entire agreement between User and LN relating
to the Services and supersedes all prior EULAs or agreements and all other
prior or contemporaneous oral or written communications, proposals and
representations with respect to the Services. This EULA including those terms
and conditions posted on web pages specifically set forth herein or contained
with any software provided under this EULA, as may be updated from time to
time. Any additional terms or conditions contained in purchase orders or other
forms are expressly rejected by LN and shall not be binding.&nbsp;Acceptance or
non-rejection of purchase orders or other forms containing such terms; LN’s
continuation of providing Products or Services; or any other inaction by LN
shall not constitute LN’s consent to or acceptance of any additional or
different terms from that stated in this EULA.&nbsp;This EULA may not be
modified by User without a written amendment signed by both Parties. </span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraph style='margin-top:0cm;margin-right:0cm;margin-bottom:
0cm;margin-left:23.95pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-18.0pt'><b><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;'>33.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></b><b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>Miscellaneous.&nbsp;</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif'>This EULA
applies to updates, supplements, modifications, enhancements, or service
components of the Services that LN may provide or make available to User after
the date User obtains the initial copy of the Services, unless they are
accompanied by separate terms. LN reserves the right to discontinue providing
the Services at any time. Neither party will be liable for any delay or failure
to perform its obligations hereunder due to causes beyond its reasonable
control, including force majeure event or acts of God or public enemy. The
failure or delay by either party in exercising any right, power or remedy under
this EULA shall not operate as a waiver of any such right, power or remedy. If
any provision of this EULA shall be held by a court of competent jurisdiction
to be contrary to law, such provision shall be changed and interpreted so as to
best accomplish the objectives of the original provision to the fullest extent
allowed by law, and the remaining provisions of this EULA shall remain in full
force and effect. The headings in this EULA are inserted for reference and
convenience only and shall not enter into the interpretation hereof.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;text-align:justify;text-justify:
inter-ideograph;line-height:normal'><span lang=EN-US>&nbsp;</span></p>

</div>

</body>

</html>`
