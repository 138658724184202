import { setHeaderHeight } from "@src/action/app";
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { withSize } from 'react-sizeme';
import { areEqualHeight } from "@src/utils/react";

function HeaderInner(props) {

  useEffect(() => {
    if (props.size.height) {
      props.setHeaderHeight(props.size.height)
    }
  }, [props.size.height])

  return <div>{props.children}</div> ;
}

// TODO: check if memo works
export default withSize({ monitorWidth: false, monitorHeight: true })(
  connect(null, {setHeaderHeight})(memo(HeaderInner, areEqualHeight))
);
