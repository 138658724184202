import _ from 'lodash';
import * as workData from '@src/data/WorkData';
import { INITIAL_SNAPSHOTS, TAKE_SNAPSHOTS, SET_DIAGRAM_SAVED } from '@src/type/snapshots';

export const initialSnapshots = snapshot => ({
  type: INITIAL_SNAPSHOTS,
  snapshot,
});

export const takeSnapshot = () => (dispatch, getState) => {
  const { map, snapshots: snapshotsReducer } = getState();
  const { snapshots, snapshotIndex, isBrowsingSnapshot } = snapshotsReducer;

  let newSnapshots = _.cloneDeep(snapshots);
  if (isBrowsingSnapshot) {
    newSnapshots.splice(snapshotIndex + 1);
  }
  newSnapshots.push({
    map: {
      service: map.service,
      zoom: map.zoom,
      opacity: map.opacity,
      currentLocation: map.currentLocation,
      // selectOptions: map.selectOptions,
      // keyword: map.keyword,
      // mapTabKey: map.mapTabKey,
    },
    shapes: _.cloneDeep(workData.getUseData()),
  });
  dispatch({
    type: TAKE_SNAPSHOTS,
    snapshots: newSnapshots,
  });
};

export const getMapState = () => (dispatch, getState) => {
  const { snapshots, snapshotIndex } = getState().snapshots;
  if (snapshotIndex === 0) {
    return null;
  } else {
    return snapshots[snapshotIndex].map;
  }
};

export const setDiagramSaved = () => ({
  type: SET_DIAGRAM_SAVED,
});

export const deleteSnapshot = index => ({
  type: SET_DIAGRAM_SAVED,
  index,
});
