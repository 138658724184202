import { ANCHORS_TYPE } from '@src/constant';
import { SquareRectHandlePoint } from '@src/data/AnchorsPoint';
import { getLinePathData } from '@src/utils';
import React from 'react';

class SelectLine extends React.Component {
  render() {
    const { anchors } = this.props;
    if (anchors.length > 0) {
      let [point1, point2] = anchors.slice(0, 2);
      return (
        <g>
          <path d={getLinePathData(point1, point2)} />
          <SquareRectHandlePoint id={ANCHORS_TYPE.LINEBEGIN} point={point1} />
          <SquareRectHandlePoint id={ANCHORS_TYPE.LINEEND} point={point2} />
        </g>
      );
    } else {
      return null;
    }
  }
}

export default SelectLine;
