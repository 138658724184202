import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GeomPoint from '@src/data/GeomPoint';
import { getSpotCrossPathData, getSpotCrossWidth, getDefaultFontSize, intersectTwoCircles } from '@src/utils';
import LncdText from '../LncdText';
import { getUserUnitByPixel, convertToFeet, convertToStandard } from '@src/data/CommonFunction';

/**
 *
 * @param {object} param
 * @param {Array<[number, number]>} param.referencePoints
 * @param {string[]} param.referencePointsText
 * @param {object} param.text
 * @returns {Array<JSX.Element>}
 */
const getReferencePoints = ({ referencePoints, referencePointsText, text }) => {
  const spotCrossWidth = getSpotCrossWidth();
  const textOffset = spotCrossWidth * 3.5;
  const radius = spotCrossWidth * 2;
  let points = [];
  for (let i = 0, len = referencePoints.length; i < len; i++) {
    const [x, y] = referencePoints[i];
    const point = new GeomPoint(x, y);
    const textPoint = point.clone();
    textPoint.offset(textOffset, textOffset);
    const textProps = {
      ...text,
      point: textPoint,
      text: referencePointsText[i],
      size: getDefaultFontSize(),
    };
    points.push(
      <g key={i} strokeWidth={getUserUnitByPixel()}>
        <circle cx={x} cy={y} r={radius} fill="transparent"></circle>
        <path d={getSpotCrossPathData(point)} />
        <LncdText {...textProps} />
      </g>
    );
  }
  return points;
};

const TriangulationNetwork = props => {
  const { object } = props;
  const { pointList = [], referencePoints } = object;
  const [triangulationPoints, setTriangulationPoints] = useState([]);

  useEffect(() => {
    if (referencePoints.length > 1) {
      const points = pointList.map(item => {
        setPtCoords(item);
        const { id, coords } = item;

        if (coords.length > 0) {
          const textProps = {
            ...object.text,
            size: getDefaultFontSize(),
          };

          let point = new GeomPoint(...coords);
          const textPoint = point.clone();
          const offset = getSpotCrossWidth() * 2;
          textPoint.offset(offset, offset);

          return (
            <React.Fragment key={id}>
              <path d={getSpotCrossPathData(point)} />
              <LncdText {...textProps} point={textPoint} text={id} textAnchor={'left'} />
            </React.Fragment>
          );
        }
      });

      setTriangulationPoints(points);
    }
  }, [referencePoints]);

  useEffect(() => {
    if (pointList.length > 0) {
      const points = pointList.map(item => {
        const { id, coords } = item;

        if (coords.length > 0) {
          const textProps = {
            ...object.text,
            size: getDefaultFontSize(),
          };

          let point = new GeomPoint(...coords);
          const textPoint = point.clone();
          const offset = getSpotCrossWidth() * 2;
          textPoint.offset(offset, offset);

          return (
            <React.Fragment key={id}>
              <path d={getSpotCrossPathData(point)} />
              <LncdText {...textProps} point={textPoint} text={id} textAnchor={'left'} />
            </React.Fragment>
          );
        }
      });

      setTriangulationPoints(points);
    }
  }, [pointList]);

  const setPtCoords = item => {
    const [x1, y1, x2, y2] = referencePoints.flat().map(p => convertToFeet(p));
    let intersects = intersectTwoCircles(x1, y1, item.distance1, x2, y2, item.distance2);
    let results = [];
    if (intersects.length > 0) {
      results =
        item.dropDirection === 'unshaded'
          ? intersects[1].map(n => convertToStandard(n))
          : intersects[0].map(n => convertToStandard(n));
      item.coords = results;
    } else {
      item.coords = [];
    }
    return results;
  };

  return (
    <g id={object.functype}>
      {getReferencePoints({
        referencePoints: object.referencePoints,
        referencePointsText: object.referencePointsText,
        text: object.text,
      })}
      {triangulationPoints}
    </g>
  );
};

export default TriangulationNetwork;
