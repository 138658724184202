import React, { Component } from 'react';
import { Menu, Dropdown, Icon } from 'antd';
import { IconDown } from '@src/components/UI/Icons';
import { CROSS_WALK_PATTERN } from '@src/constant';
import { withTranslation } from 'react-i18next';

class CrossWalkSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.t(`property.crosswalk.${props.type}`),
    };
  }

  handleClick = e => {
    const { handle } = this.props;
    handle && handle(e.key);
    this.setState({ type: e.item.props.value });
  };

  render() {
    const shapes = (
      <Menu className="reg-no-select" onClick={this.handleClick.bind(this)}>
        <Menu.Item
          key={CROSS_WALK_PATTERN.HORIZONTAL}
          value={this.props.t(`property.crosswalk.${CROSS_WALK_PATTERN.HORIZONTAL}`)}
        >
          {this.props.t(`property.crosswalk.${CROSS_WALK_PATTERN.HORIZONTAL}`)}
        </Menu.Item>
        <Menu.Item
          key={CROSS_WALK_PATTERN.VERTICAL}
          value={this.props.t(`property.crosswalk.${CROSS_WALK_PATTERN.VERTICAL}`)}
        >
          {this.props.t(`property.crosswalk.${CROSS_WALK_PATTERN.VERTICAL}`)}
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={shapes} trigger={['click']} className="custom-dropdown">
        <div className="custom-dropdown-item">
          {this.state.type}
          <Icon component={IconDown} alt="down" />
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(CrossWalkSelect);
