import {
  SET_MOUSE_OVER_CANVAS,
  UPDATE_MOUSE_POSITION,
  RECORD_CLICK_STATE_ON_CANVAS,
  UPDATE_LAST_CLICK_POS,
} from '@src/type/pointer';

export const setMouseOverCanvas = value => ({
  type: SET_MOUSE_OVER_CANVAS,
  value,
});

export const recordClickStateOnCanvas = isClick => ({
  type: RECORD_CLICK_STATE_ON_CANVAS,
  isClick,
});

export const updateMousePosition = (clientX, clientY) => ({
  type: UPDATE_MOUSE_POSITION,
  clientX,
  clientY,
});

export const updateLastClickPos = (x, y) => ({
  type: UPDATE_LAST_CLICK_POS,
  x,
  y,
});
