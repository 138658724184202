import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Modal, Tabs, Button } from 'antd';
import MapSearch from '../MapSearch';
import MapModal from '../Modal/MapModal';
import MapSideBar from '@src/components/Map/SideBar/MapSideBar';
import { connect } from 'react-redux';
import { message } from '@src/components/Message';
import './map-container.scss';

// action
import {
  toggleMapContainer,
  setCurrentLocation,
  setSelectOptions,
  switchLocationLoading,
  setOpacity,
  switchMapTab,
  setKeyword,
  setMapType,
} from '@src/action/map';
import { resetAroundCenterOffset } from '@src/action/canvas';
import { takeSnapshot, getMapState } from '@src/action/snapshots';
import { withTranslation } from 'react-i18next';
import { getLocationQueryUrl } from '@src/utils';

class MapContainer extends React.Component {
  componentDidUpdate(prevProps) {
    // 打开 MapContainer 时
    if (this.props.isOpenMapContainer !== prevProps.isOpenMapContainer) {
      if (this.props.isOpenMapContainer) {
        this.mapSearch && this.mapSearch.mapInput.focus();
      }
    }

    // 切换 MapTab 时
    if (this.props.mapTabKey !== prevProps.mapTabKey) {
      if (this.props.mapTabKey === '1') {
        this.mapSearch && this.mapSearch.mapInput.focus();
      } else if (this.props.mapTabKey === '3') {
        this.mapReSearch && this.mapReSearch.mapInput.focus();
      }
    }
  }

  /************************************Search & Change*************************** */
  handleSetLocation(url) {
    // redux
    const { switchLocationLoading, setSelectOptions, setCurrentLocation } = this.props;

    switchLocationLoading(true);
    axios
      .get(getLocationQueryUrl(url), { timeout: 4000 })
      .then(res => {
        if (res.data.resourceSets[0].estimatedTotal !== 0) {
          const s = [];
          for (let i = 0; i < res.data.resourceSets[0].estimatedTotal; i++) {
            const item = res.data.resourceSets[0].resources[i];
            s.push({
              name: item.name,
              value: item.point.coordinates,
              id: i + 1,
            });
          }
          setSelectOptions(s);
          switchLocationLoading(false);
          // setCurrentLocation('');
        } else {
          message.warning(this.props.t('map.modal.locationNotFount'));
          switchLocationLoading(false);
          setCurrentLocation('');
          setSelectOptions([]);
        }
      })
      .catch(error => {
        message.warning(this.props.t('map.modal.searchLocationError'));
        switchLocationLoading(false);
        setCurrentLocation('');
        setSelectOptions([]);
      });
  }

  onSearch = value => {
    if (value === '') {
      message.warning(this.props.t('map.modal.searchLocationKeywordRequired'));
      return;
    }
    // message && message.destroy();
    if (!isNaN(value)) {
      return;
    }
    this.handleSetLocation(value);
  };

  onInput = e => {
    const { setSelectOptions } = this.props;
    // message && message.destroy();
    const value = e.target.value;
    this.props.setKeyword(value);
    if (!isNaN(value)) {
      return;
    }
    // 防抖
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (value.length < 2) {
        setSelectOptions([]);
      } else {
        this.handleSetLocation(value);
      }
    }, 500);
  };

  /***********************************Search******************************** */
  onReselect = () => {
    const { setSelectOptions, switchMapTab } = this.props;
    // setSelectOptions([]);
    switchMapTab('3');
  };

  // select a suggested location
  onListItemClick = item => {
    const { service, setCurrentLocation, switchMapTab, setMapLatLng, mapTabKey, takeSnapshot } = this.props;
    if (service.schema && service.type) {
      switchMapTab('2');
      setCurrentLocation(item.name);
      setMapLatLng(item.value);
    } else {
      // FIXME: should show this message right after clicking the set location top menu button
      message.error(this.props.t('map.modal.mapProviderNotExists'));
    }
  };

  /************************************Modal*************************** */
  onSelectMap = schema => {
    this.props.setMapType({ schema });
  };

  onSelectMapType = type => {
    this.props.setMapType({ type });
  };

  onOK = () => {
    this.props.toggleMapContainer();
    this.props.takeSnapshot();
  };

  onCancel = () => {
    const { toggleMapContainer, resumeMap, zoom, mapTabKey } = this.props;

    /**
     * click "Cancel" on tab "Add Location", close dialog.
     * click "Cancel" on tab "Current Location", resume location status and close dialog.
     * click "Cancel" on tab "Change Location", switch to tab "Current Location".
     */
    switch (mapTabKey) {
      case '1': {
        toggleMapContainer(false);
        break;
      }
      case '2': {
        // TODO: use state to track if map has changed since the modal is opened
        const mapState = this.props.getMapState();
        if (mapState) {
          resumeMap({ ...mapState, zoom });
        } else {
          resumeMap();
        }
        toggleMapContainer(false);
        break;
      }
      case '3': {
        this.props.switchMapTab('2');
        break;
      }
      default:
        break;
    }
  };

  onSlide = value => {
    this.props.setOpacity(value);
  };

  /**
   * @param {boolean} [isTakeSnapshot]
   */
  onClear = (isTakeSnapshot = false) => {
    const { clearMap } = this.props;
    const { toggleMapContainer, takeSnapshot, resetAroundCenterOffset } = this.props; // redux
    // this.mapSearch.mapInput.state.value = '';
    this.props.setKeyword('');
    clearMap();
    resetAroundCenterOffset();
    isTakeSnapshot && takeSnapshot();
    toggleMapContainer(false);
  };

  render() {
    const { nightMode, handleZoom } = this.props;
    const { isOpenMapContainer, mapTabKey, generateSketch, service } = this.props;

    const mapTabs = [
      {
        tab: 'Add Location',
        title: this.props.t('map.modal.addLocationTitle'),
        content: (
          <MapSearch
            ref={e => (this.mapSearch = e)}
            onInput={this.onInput}
            onSearch={this.onSearch}
            onListItemClick={this.onListItemClick}
            onCancel={this.onCancel}
            searchText={this.props.t('map.modal.searchButtonText')}
            cancelText={this.props.t('map.modal.cancelButtonText')}
            searchPlaceholder={this.props.t('map.modal.searchPlaceholder')}
          />
        ),
      },
      {
        tab: 'Current Location',
        title: this.props.t('map.modal.currentLocationTitle'),
        content: (
          <MapModal
            onSelectMapType={this.onSelectMapType}
            onReselect={this.onReselect}
            onOK={this.onOK}
            onCancel={this.onCancel}
            onClear={this.onClear}
            onSelectMap={this.onSelectMap}
            onSlide={this.onSlide}
          />
        ),
      },
      {
        tab: 'Change Location',
        title: this.props.t('map.modal.changeLocationTitle'),
        content: (
          // TODO: refactor this component
          <MapSearch
            ref={e => (this.mapReSearch = e)}
            onInput={this.onInput}
            onSearch={this.onSearch}
            onListItemClick={this.onListItemClick}
            onCancel={this.onCancel}
            searchText={this.props.t('map.modal.searchButtonText')}
            cancelText={this.props.t('map.modal.cancelButtonText')}
            searchPlaceholder={this.props.t('map.modal.searchPlaceholder')}
          />
        ),
      },
    ];

    return (
      <>
        {/* FIXME: the UI is not the same as normal Modal component, the footer components are located in body now */}
        <Modal
          title={mapTabs[mapTabKey - 1].title}
          visible={isOpenMapContainer}
          footer={null}
          maskClosable={false}
          onCancel={this.onCancel}
          className={[nightMode ? 'night-mode night-mode-modal' : 'day-mode night-mode-modal']}
        >
          <Tabs activeKey={mapTabKey} tabBarStyle={{ display: 'none' }}>
            {mapTabs.map((item, index) => (
              <Tabs.TabPane tab={item.tab} key={index + 1}>
                {item.content}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Modal>
        {service.latitude !== 0 && service.longitude !== 0 ? (
          <MapSideBar
            onSelectMapType={this.onSelectMapType}
            onClear={this.onClear}
            onZoomIn={() => handleZoom(true)}
            onZoomOut={() => handleZoom(false)}
            generateSketch={generateSketch}
          />
        ) : null}
      </>
    );
  }
}

export default connect(
  state => ({
    nightMode: state.app.nightMode,
    isOpenMapContainer: state.map.isOpenMapContainer,
    mapTabKey: state.map.mapTabKey,
    zoom: state.map.zoom,
    service: state.map.service,
  }),
  {
    toggleMapContainer,
    setCurrentLocation,
    setSelectOptions,
    switchLocationLoading,
    takeSnapshot,
    setOpacity,
    switchMapTab,
    setKeyword,
    setMapType,
    getMapState,
    resetAroundCenterOffset,
  }
)(withTranslation()(MapContainer));
