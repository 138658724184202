import * as React from 'react';

function SvgIconRightOut(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M1 .004h24v24H1z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M17.293 8.707L19.586 11H7v2h12.586l-2.293 2.293 1.414 1.414L23.414 12l-4.707-4.707zM3 4h2v16H3z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconRightOut;
