import React from 'react';
import Line from '@src/shapes/Line/Line';
import Rect from '@src/shapes/Rect/Rect';
import Ellipse from '@src/shapes/Ellipse/Ellipse';
import Arc from '@src/shapes/Arc/Arc';
import ConnectedLines from '@src/shapes/ConnectedLines/ConnectedLines';
import CloseShapes from '@src/shapes/CloseShapes/CloseShapes';
import Circle from '@src/shapes/Circle/Circle';
import Callout from '@src/shapes/Callout/Callout';
import CounterTop from '@src/shapes/CounterTop/CounterTop';
import Stairs from '@src/shapes/Stairs/Stairs';
import ParkStall from '@src/shapes/ParkStall/ParkStall';
import CrossWalk from '@src/shapes/CrossWalk/CrossWalk';
import Street from '@src/shapes/Street/Street';
import Image from '@src/shapes/Image/Image';
import Structure from '@src/shapes/Structure/Structure';
import Symbol from '@src/shapes/Symbol/Symbol';
import Stripe from '@src/shapes/Stripe/Stripe';
import StreetOffset from '@src/shapes/StreetOffset/StreetOffset';
import Dirt from '@src/shapes/Dirt/Dirt';
import Gravel from '@src/shapes/Dirt/Gravel';
import SideWalk from '@src/shapes/SideWalk/SideWalk';
import LaneMarker from '@src/shapes/LaneMarker/LaneMarker';
import Indicator from '@src/shapes/Indicator/Indicator';
import StreetAcross from '@src/shapes/StreetAcross/StreetAcross';
import { Measurement, TriangulationNetwork } from '@src/shapes/Measurement';
import StreetSurface from '@src/shapes/StreetSurface/StreetSurface';
import StreetNew from '@src/shapes/StreetNew/StreetNew';
import CurbReturn from '@src/shapes/CurbReturn/CurbReturn';
import Marker from '@src/shapes/Marker/Marker';
import { FUNCTION_TYPE, STREET_TYPE } from '@src/constant';
import { strokeOtherProps, fillOtherProps } from '@src/utils';

function StyledContainer(props) {
  const { style, children, ...otherProps } = props;
  let commonProps = style && {
    strokeWidth: style.strokewidth,
    strokeOpacity: style.strokeopacity,
    strokeDasharray: style.strokedasharray,
    fillOpacity: style.fillopacity,
    fontSize: style.fontsize,
    stroke: style.stroke,
    fill: style.fill,
  };
  return style ? (
    <g {...strokeOtherProps(style.stroke)} {...fillOtherProps(style.fill)} {...commonProps} {...otherProps}>
      {children}
    </g>
  ) : (
    <g {...otherProps}>{children}</g>
  );
}

function render(obj, handlers) {
  let element;

  switch (obj.functype) {
    case FUNCTION_TYPE.LINE:
      element = <Line object={obj} />;
      break;
    case FUNCTION_TYPE.DIMENSIONLINE:
    case FUNCTION_TYPE.ARC:
      element = <Arc object={obj} />;

      break;
    case FUNCTION_TYPE.STRIPE:
      element = <Stripe object={obj} />;
      break;
    case FUNCTION_TYPE.CALLOUT:
      element = <Callout object={obj} />;
      break;
    case FUNCTION_TYPE.RECTANGLE:
    case FUNCTION_TYPE.TEXTBOX:
    case FUNCTION_TYPE.SQUARE:
    case FUNCTION_TYPE.LASSOAREA:
    case FUNCTION_TYPE.ZOOMSELECT:
    case FUNCTION_TYPE.SELECTION:
      element = <Rect object={obj} />;
      break;
    case FUNCTION_TYPE.ELLIPSE:
      element = <Ellipse object={obj} />;
      break;
    case FUNCTION_TYPE.CONNECTEDLINES:
      element = <ConnectedLines object={obj} />;
      break;
    case FUNCTION_TYPE.CLOSEDSHAPE:
      element = <CloseShapes object={obj} />;
      break;
    case FUNCTION_TYPE.COUNTER_TOP:
      element = <CounterTop object={obj} />;
      break;
    case FUNCTION_TYPE.STRUCTURE:
      element = <Structure object={obj} />;
      break;
    case FUNCTION_TYPE.SYMBOL:
      element = <Symbol object={obj} />;
      break;
    case FUNCTION_TYPE.CIRCLE:
      element = <Circle object={obj} />;
      break;
    case FUNCTION_TYPE.STAIRS:
      element = <Stairs object={obj} />;
      break;
    case FUNCTION_TYPE.PARKINGSTALLS:
      element = <ParkStall object={obj} />;
      break;
    case FUNCTION_TYPE.CROSSWALK:
      element = <CrossWalk object={obj} />;
      break;
    case FUNCTION_TYPE.STREET: {
      if (obj.streetoffarc) {
        element = <StreetOffset object={obj} />;
      } else {
        element = <Street object={obj} />;
      }
      break;
    }
    case FUNCTION_TYPE.DIRT:
      element = <Dirt object={obj} />;
      break;
    case FUNCTION_TYPE.GRAVEL:
      element = <Gravel object={obj} />;
      break;
    case FUNCTION_TYPE.IMAGE:
      element = <Image object={obj} />;
      break;
    case FUNCTION_TYPE.SIDEWALK:
      element = <SideWalk object={obj} />;
      break;
    case FUNCTION_TYPE.LANEMARKER:
      element = <LaneMarker object={obj} />;
      break;
    case FUNCTION_TYPE.INDICATOR:
      element = <Indicator object={obj} />;
      break;
    case FUNCTION_TYPE.GROUP:
      element = obj.groupdata.map(obj => render(obj, handlers));
      break;
    case FUNCTION_TYPE.STREETACROSS:
      element = <StreetAcross object={obj} />;
      break;
    case FUNCTION_TYPE.STATIONLINE:
    case FUNCTION_TYPE.XYMEASUREMENT:
      element = <Measurement object={obj} />;
      break;
    case FUNCTION_TYPE.TRIANGULATIONNETWORK:
      element = <TriangulationNetwork object={obj} />;
      break;
    case FUNCTION_TYPE.STREETSURFACE:
      element = <StreetSurface object={obj} />;
      break;
    case FUNCTION_TYPE.STREETNEW:
      element = <StreetNew object={obj} />;
      break;
    case FUNCTION_TYPE.CURBRETURN:
      element = <CurbReturn object={obj} />;
      break;
    case FUNCTION_TYPE.MARKER:
      element = <Marker object={obj} />;
      break;
    default:
      break;
  }

  if (obj.isgroupchild) {
    return (
      <StyledContainer style={obj.style} key={obj.operateid} id={obj.operateid}>
        {element}
      </StyledContainer>
    );
  } else {
    return (
      <StyledContainer
        style={obj.style}
        key={obj.operateid}
        id={obj.operateid}
        onClick={handlers.shapeClick}
        onDoubleClick={handlers.showPropsMenu}
        onMouseDown={handlers.shapeMouseDown}
        // onMouseMove={handlers.shapeMouseMove}
        onTouchStart={handlers.shapeMouseDown}
        // onTouchMove={handlers.shapeMouseMove}
      >
        {element}
      </StyledContainer>
    );
  }
};

function CreateElement({ objList, ...otherProps }) {
  return objList.map(obj => render(obj, otherProps));
}

export default CreateElement;
