import produce from 'immer';
import { CHANGE_CATEGORY } from '@src/type/sidebar';
import * as actionTypes from '@src/type/symbolBar';

const initialState = {
  expanded: false,
  selectedSymbolKey: undefined,
  symbols: [],
  expandable: false,
  overlay: false,
  showScrollBar: false,
};

const symbolBarReducer = produce((draft, action) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case actionTypes.TOGGLE_EXPAND: {
      draft.expanded = !draft.expanded;
      draft.showScrollBar = !draft.showScrollBar;
      break;
    }
    case actionTypes.OPEN_EXPAND: {
      draft.expanded = true;
      draft.showScrollBar = true;
      break;
    }
    case actionTypes.SELECT_SYMBOL: {
      draft.selectedSymbolKey = action.key;
      break;
    }
    case CHANGE_CATEGORY: {
      // reset expandable an expanded status
      draft.symbols = action.symbols;
      draft.selectedSymbolKey = undefined;
      break;
    }
    case actionTypes.BAR_SIZE_CHANGED: {
      draft.expandable = action.expandable;
      draft.expanded = action.expandable && draft.expanded;
      draft.showScrollBar = action.expandable && draft.expanded;
      draft.overlay = action.overlay;
      break;
    }
    default:
      break;
  }
});

export default symbolBarReducer;
