import * as React from 'react';

function SvgIconFontSmaller(props) {
  return (
    <svg className="icon-font-smaller_svg__icon" viewBox="0 0 1024 1024" width="1em" height="1em" {...props}>
      <path
        fill="currentColor"
        d="M500.204 286.72l184.32 466.944h-61.44l-49.152-131.072H360.94l-49.152 131.072h-61.44l184.32-466.944h65.536zm57.344 290.816l-90.112-233.472-90.112 233.472h180.224zM610.796 344.064h204.8v40.96h-204.8z"
      />
    </svg>
  );
}

export default SvgIconFontSmaller;
