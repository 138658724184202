import React from 'react';
import { TEXT_BOX_PX, STATION_SHORT } from '@src/constant';
import { getLinePathData } from './svg';
import { StationLineDirections } from '@src/data/ShapeDataList';
import GeomPoint from '@src/data/GeomPoint';
import { getPixelByUserUnit } from '@src/data/CommonFunction';

/**
 *
 * @param {number[]} point
 * @param {boolean} [asArray]
 * @returns {number[] | { x: number, y: number }}
 */
export const unifyPoint = (point, asArray = true) => {
  let x, y;
  if (point) {
    if (Array.isArray(point)) {
      [x, y] = point;
    } else {
      x = point.x;
      y = point.y;
    }
  }
  x = x || 0;
  y = y || 0;
  return asArray ? [x, y] : { x, y };
};

export const getTextContainerBox = ({ x, y, width = 0, height = 0, rotateAngle = 0, ...restProps }) => (
  <rect
    x={x - width / 2 - TEXT_BOX_PX}
    y={y - height / 2}
    width={width + 2 * TEXT_BOX_PX}
    height={height}
    transform={`rotate(${rotateAngle} ${x} ${y})`}
    {...restProps}
  />
);

export const getTextUnderline = ({ x, y, width, height, rotateAngle }) => (
  <path
    d={getLinePathData([x - width / 2 - TEXT_BOX_PX, y - height / 2], [x + width / 2 + TEXT_BOX_PX, y - height / 2])}
    transform={`rotate(${rotateAngle} ${x} ${y})`}
  />
);

const DIRECTIONS = ['north', 'west', 'south', 'east'];
const DIR_TEXT_MAP = new Map([['north', 'Y+'], ['west', 'X-'], ['south', 'Y-'], ['east', 'X+']]);
// TODO: change value directly to direction, or create direction property for station line object and xy measurement
const STATION_LINE_TYPES = [
  StationLineDirections.NORTH,
  StationLineDirections.WEST,
  StationLineDirections.SOUTH,
  StationLineDirections.EAST,
];

/**
 * @typedef {Object} EndPoint
 * @property {string} direction
 * @property {number} length
 * @property {GeomPoint} point
 * @property {string} text
 * @property {GeomPoint} textPoint
 */
/**
 *
 * @param {{functype: 'StationLine' | 'XYMeasurement'}} object
 * @returns {{ endpoints: Map<String, EndPoint>, pc: GeomPoint }}
 */
export const initMeasurementCoords = object => {
  const textOffset = 10 / getPixelByUserUnit();
  const { lenZeroHead, lenZeroTail, handlepoint, rotation, type } = object;

  let direction = 'north';
  let dirTextMap = DIR_TEXT_MAP;

  const stationLineIdx = STATION_LINE_TYPES.indexOf(type);
  if (stationLineIdx > -1) {
    dirTextMap = new Map([['north', 'N'], ['west', 'W'], ['south', 'S'], ['east', 'E']]);
    direction = DIRECTIONS[stationLineIdx];
  }

  const pc = new GeomPoint(...handlepoint[0]);
  const endpoints = new Map();
  // unit point starting from north
  const pn = pc.clone();
  pn.y += 1;
  pn.rotate(rotation, pc);
  let position;
  let length = 0;
  for (let i = 0; i < 4; i++) {
    const pu = pn.clone();
    pu.rotate((Math.PI / 2) * i, pc);
    const point = pc.clone();
    switch (i) {
      case 0: {
        position = 'head';
        length = lenZeroHead;
        break;
      }
      case 1: {
        position = 'left';
        length = STATION_SHORT;
        break;
      }
      case 2: {
        position = 'tail';
        length = lenZeroTail;
        break;
      }
      case 3: {
        position = 'right';
        length = STATION_SHORT;
        break;
      }
      default:
        break;
    }

    point.offsetToward(pu, length);

    const textPoint = point.clone();
    textPoint.offsetToward(pc, -textOffset);
    endpoints.set(position, { point, textPoint, length });
  }

  const idx = DIRECTIONS.indexOf(direction);
  const directions = DIRECTIONS.slice(idx).concat(DIRECTIONS.slice(0, idx));

  let rad = (idx * Math.PI) / 2;
  let i = 0;
  for (let endpoint of endpoints.values()) {
    endpoint.direction = directions[i];
    endpoint.text = dirTextMap.get(endpoint.direction);
    endpoint.point.rotate(rad, pc);
    endpoint.textPoint.rotate(rad, pc);
    i++;
  }

  return { endpoints, pc };
};

/**
 *
 * based on the math here:
 * http://math.stackexchange.com/a/1367732
 * @param {number} x1 is the center of the first circle
 * @param {number} y1 is the center of the first circle
 * @param {number} r1 radius
 * @param {number} x2 is the center of the second ricle
 * @param {number} y2 is the center of the second ricle
 * @param {number} r2 radius
 * @returns {[number[], number[]]}
 */
export function intersectTwoCircles(x1, y1, r1, x2, y2, r2) {
  var centerdx = x1 - x2;
  var centerdy = y1 - y2;
  var R = Math.sqrt(centerdx * centerdx + centerdy * centerdy);
  if (!(Math.abs(r1 - r2) <= R && R <= r1 + r2)) {
    // no intersection
    return []; // empty list of results
  }
  // intersection(s) should exist

  var R2 = R * R;
  var R4 = R2 * R2;
  var a = (r1 * r1 - r2 * r2) / (2 * R2);
  var r2r2 = r1 * r1 - r2 * r2;
  var c = Math.sqrt((2 * (r1 * r1 + r2 * r2)) / R2 - (r2r2 * r2r2) / R4 - 1);

  var fx = (x1 + x2) / 2 + a * (x2 - x1);
  var gx = (c * (y2 - y1)) / 2;
  var ix1 = +(fx + gx).toFixed(3);
  var ix2 = +(fx - gx).toFixed(3);

  var fy = (y1 + y2) / 2 + a * (y2 - y1);
  var gy = (c * (x1 - x2)) / 2;
  var iy1 = +(fy + gy).toFixed(3);
  var iy2 = +(fy - gy).toFixed(3);

  // note if gy == 0 and gx == 0 then the circles are tangent and there is only one solution
  // but that one solution will just be duplicated as the code is currently written
  return [[ix1, iy1], [ix2, iy2]];
}
