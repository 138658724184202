import emitter from '@src/data/Event';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { textOtherProps, unifyPoint } from '@src/utils';
import React, { useEffect, useRef, useState } from 'react';

function LncdText(props) {
  // pass text properties (color, text, size, italic, bold) via {...object.text}
  // need to cut off islength prop as it's boolean type, causes error
  const {
    point,
    rad = 0,
    color,
    text,
    size,
    italic,
    bold,
    objectId,
    islength,
    // normally text will centered to the point horizontally and vertically
    // for text like Station Line point id, the anchor will be left
    textAnchor = 'middle',
    ...restProps
  } = props;

  const [timeoutId, setTimeoutId] = useState(undefined);
  const ref = useRef();

  useEffect(() => {
    // 获取渲染尺寸
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // some text does not require width/height values to be updated
    if (text && size && objectId) {
      setTimeoutId(
        setTimeout(() => {
          if (ref.current) {
            const { width, height } = ref.current.getBBox();
            const object = workData.getObjectOrGroupObject(objectId);
            object.text = {
              ...(object.text || {}),
              width,
              height,
            };
            emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, false);
          }
        }, 16)
      );
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [text, size, bold, italic]);

  // center point of text
  const [cx, cy] = unifyPoint(point);
  const angle = (rad * 180) / Math.PI;
  let rx = cx;
  if (textAnchor === 'left' && props.width) {
    rx += props.width / 2;
  }
  return (
    <text
      ref={ref}
      x={cx}
      y={-cy}
      textAnchor={textAnchor}
      dominantBaseline="middle"
      fill={color}
      // in case being affected by out box stroke style
      stroke="none"
      strokeOpacity={0}
      transform={`scale(1, -1) rotate(${-angle} ${rx} ${-cy})`}
      fontSize={size}
      fontStyle={italic}
      fontWeight={bold}
      {...restProps}
      {...textOtherProps(color)}
    >
      {text}
    </text>
  );
}

export default LncdText;
