import React from 'react';
import { Tabs, Icon } from 'antd';
import TabItem from '@src/components/PropMenu/TabItem';
import { LengthBetweenPoints } from '@src/data/CommonFunc';
import Utility from '@src/data/Utility';
import { FONT_WEIGHT_TYPE, FONT_STYLE_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';
import ArrowMenu from '@src/components/Modal/Menu/ArrowMenu';

export default class MarkerModal extends React.Component {
  menu = {
    text: ['text', 'fontSize', 'color', 'style'],
    size: ['rotation'],
  };

  state = {
    object: this.props.object,
    tabKey: 'text',
  };

  // textlength = e => {
  //   const { object: obj } = this.state;
  //   obj.text.islength = !obj.text.islength;
  //   if (obj.text.islength) {
  //     obj.text.text = Utility.format(LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]) / 12);
  //   } else {
  //     obj.text.text = '';
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  // textHandle = e => {
  //   var obj = this.state.object;
  //   obj.text.text = e.target.value;
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  // textSizeHandle = size => {
  //   var obj = this.state.object;
  //   obj.text.size = size;
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  // textBoldHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.bold === FONT_WEIGHT_TYPE.NORMAL) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.BOLD;
  //   } else if (obj.text.bold === FONT_WEIGHT_TYPE.BOLD) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  // textItalicHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.italic === FONT_STYLE_TYPE.NORMAL) {
  //     obj.text.italic = FONT_STYLE_TYPE.ITALIC;
  //   } else if (obj.text.italic === FONT_STYLE_TYPE.ITALIC) {
  //     obj.text.italic = FONT_STYLE_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  // textColorHandle = e => {
  //   var colortype = e.currentTarget.id;
  //   var obj = this.state.object;
  //   obj.text.color = colortype;
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {/*'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
              />
            </Tabs.TabPane>
          )*/}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu
                item={this.menu.text}
                objectId={this.props.object.operateid}
                // disabled={this.props.object.text.islength}
                // stateObject={this.state.object}
                // // textHandle={this.textHandle}
                // textSizeHandle={this.textSizeHandle}
                // textColorHandle={this.textColorHandle}
                // textBoldHandle={this.textBoldHandle}
                // textItalicHandle={this.textItalicHandle}
                // textLengthHandle={this.textlength}
                // textPositionHandle={this.changePosition}
              />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                // stateObject={this.state.object}
                // pointLength={this.getLength()}
                // pointLengthHandle={this.pointlengthHandle}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
