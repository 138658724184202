import React, { useState, useEffect } from 'react';
import {
  FindCircle,
  GetCenterInTwoPoints,
  LengthBetweenPoints,
  getAngleBy2Points,
  ByTime,
  IsBigArc,
  IsArcDirection,
  GetCenter,
  TowPointVerticalLengthPoints,
  FindCircleLinePoint,
  LengthArc,
  GetCirclePointK,
  IsOneLine,
  GetArcAngle,
  IsZero,
  IsByTime,
} from '@src/data/CommonFunc';
import { GetShapeStyle } from '@src/data/CommonProps';
import { getArrowType } from '@src/data/BusinessFun';
import Text from '../Text';
import ArrowMarker from '../ArrowMarker';
import Utility from '@src/data/Utility';
import { COLOR_TYPE, TEXT_ANCHORS_TYPE, TEXT_POSITION_TYPE, THREE_POINT_STATUS_TYPE } from '@src/constant';
import LncdText from '@src/shapes/LncdText';

function Arc({ object }) {
  if (object.handlepoint.length < 3) return null;
  const cirlcle = FindCircle(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2]);

  const getDPath = (object, cirlcle) => {
    const r = cirlcle[2];
    const x1 = object.handlepoint[0][0];
    const x2 = object.handlepoint[1][0];
    const y1 = object.handlepoint[0][1];
    const y2 = object.handlepoint[1][1];
    const D = [];
    D.push(`M${x1.toString()},${y1.toString()}`);
    D.push(`A${r.toString()},${r.toString()}`);
    D.push('0');
    D.push(
      `${IsBigArc(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2], r)},${IsArcDirection(
        object.handlepoint[0],
        object.handlepoint[2],
        object.handlepoint[1]
      )}`
    );
    D.push(`${x2.toString()},${y2.toString()}`);
    return D;
  };

  const getMarks = (object, circle) => {
    if (!object.marks.isexist || object.handlepoint.length < 2) return null;
    // 半壶半弧画
    const length = LengthArc(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2]);
    const n = Math.round(length / object.marks.length);
    if (IsZero(n)) {
      return null;
    }
    const D = [];
    const outCircle = [circle[0], circle[1], circle[2] + object.marks.height];
    const inerCircle = [circle[0], circle[1], circle[2] - object.marks.height];
    if (
      IsOneLine(object.handlepoint[0], object.handlepoint[2], object.handlepoint[1]) === THREE_POINT_STATUS_TYPE.ONLINE
    ) {
      for (let i = 1; i < n; i++) {
        const Center = GetCenter(object.handlepoint[0], object.handlepoint[1], i / n);
        const points = TowPointVerticalLengthPoints(
          object.handlepoint[0],
          object.handlepoint[1],
          Center,
          object.marks.height
        );
        D.push(`M${String(points[0][0])},${String(points[0][1])}`);
        D.push(`L${String(points[1][0])},${String(points[1][1])}`);
      }
    } else {
      const circle = FindCircle(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2]);
      const isByTime = IsByTime(object.handlepoint[0], object.handlepoint[2], object.handlepoint[1]);
      const angle = GetArcAngle(circle, object.handlepoint[0], object.handlepoint[1], isByTime);
      const oneAngle = angle / n;
      const angle0 = getAngleBy2Points(circle, object.handlepoint[0]);
      const bRet = ByTime(object.handlepoint[0], object.handlepoint[2], object.handlepoint[1]);
      for (let i = 1; i < n; i++) {
        let angleGo = 0;
        if (bRet == THREE_POINT_STATUS_TYPE.BYTIME) {
          angleGo = angle0 + i * oneAngle;
        } else {
          angleGo = angle0 - i * oneAngle;
        }
        const x1 = circle[0] + outCircle[2] * Math.cos(angleGo);
        const y1 = circle[1] + outCircle[2] * Math.sin(angleGo);
        const x2 = circle[0] + inerCircle[2] * Math.cos(angleGo);
        const y2 = circle[1] + inerCircle[2] * Math.sin(angleGo);
        D.push(`M${String(x1)},${String(y1)}`);
        D.push(`L${String(x2)},${String(y2)}`);
      }
    }

    return <path d={D.join(' ')} strokeWidth={1} />;
  };

  const getText = (object, circle) => {
    let text = '';
    if (object.text.islength) {
      let length = LengthArc(object.handlepoint[0], object.handlepoint[1], object.handlepoint[2]);
      length = Math.round((length * 1000 + 0.5) / 1000);
      text = Utility.format(length / 12);
    } else {
      text = object.text.text;
    }

    let textShape = null;
    const angle = getAngleBy2Points(object.handlepoint[0], object.handlepoint[1]);
    const center = GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[1]);
    let uplength = LengthBetweenPoints(center, object.handlepoint[2]) + object.style.strokewidth + object.text.size;
    const outCircle = [circle[0], circle[1], circle[2] + object.style.strokewidth];
    const inerCircle = [circle[0], circle[1], circle[2] - object.style.strokewidth - object.text.size];
    const bPointsState = IsOneLine(object.handlepoint[0], object.handlepoint[2], object.handlepoint[1]);

    if (object.marks.isexist) {
      uplength += object.marks.height;
      outCircle[2] += object.marks.height;
      inerCircle[2] -= object.marks.height;
    }

    const infos = [
      {
        position: TEXT_POSITION_TYPE.MIDIUMTOP,
        point: object.handlepoint[2],
        circle: outCircle,
        type: TEXT_ANCHORS_TYPE.MIDDLE,
      },
      {
        position: TEXT_POSITION_TYPE.RIGHTTOP,
        point: object.handlepoint[1],
        circle: outCircle,
        type: TEXT_ANCHORS_TYPE.END,
      },
      {
        position: TEXT_POSITION_TYPE.RIGHTBOTTOM,
        point: object.handlepoint[1],
        circle: inerCircle,
        type: TEXT_ANCHORS_TYPE.END,
      },
      {
        position: TEXT_POSITION_TYPE.MIDIUMBOTTOM,
        point: object.handlepoint[2],
        circle: inerCircle,
        type: TEXT_ANCHORS_TYPE.MIDDLE,
      },
      {
        position: TEXT_POSITION_TYPE.LEFTBOTTOM,
        point: object.handlepoint[0],
        circle: inerCircle,
        type: TEXT_ANCHORS_TYPE.START,
      },
      {
        position: TEXT_POSITION_TYPE.LEFTTOP,
        point: object.handlepoint[0],
        circle: outCircle,
        type: TEXT_ANCHORS_TYPE.START,
      },
    ];

    const getPointAndAngle = (point, circle) => {
      let textPoint = [0, 0];
      let angletext = 0;
      if (bPointsState === THREE_POINT_STATUS_TYPE.ONLINE) {
        textPoint = [point[0], point[1] - uplength];
        angletext = getAngleBy2Points(object.handlepoint[0], object.handlepoint[1]);
      } else {
        textPoint = FindCircleLinePoint(circle, point);
        let k = GetCirclePointK(circle, textPoint);
        angletext = Math.atan(k);
      }
      return { textPoint, angletext };
    };
    const getType = (type = TEXT_ANCHORS_TYPE.MIDDLE) => type;

    let info = infos.find(x => object.text.position === x.position) || infos[0];
    let { textPoint, angletext } = getPointAndAngle(info.point, info.circle);
    let anchorsType = getType(info.type);

    textShape = (
      // FIXME: use LncdText
      // <LncdText point={textPoint} rad={angletext} text={object.text} textAnchor={anchorsType} />
      <Text
        angle={angletext}
        x={textPoint[0] || 0}
        y={textPoint[1] || 0}
        textAnchor={anchorsType}
        text={object.text}
        content={text}
      />
    );
    return textShape;
  };

  let pathD = getDPath(object, cirlcle);
  let marks = getMarks(object, cirlcle);
  let text = getText(object, cirlcle);

  return (
    <g>
      <metadata
        sstrokepattern={object.style.strokepattern}
        sterminatortype={object.arrow.position}
        sterminatorstyle={object.arrow.type}
      />
      <ArrowMarker
        id={object.operateid}
        width={object.arrow.width}
        height={object.arrow.height}
        type={object.arrow.type}
        fill={object.style.stroke}
      />
      <path d={pathD.join(' ')} {...GetShapeStyle().LINEWHITESTYLE} fill={COLOR_TYPE.NONE} />
      <path
        d={pathD.join(' ')}
        fill={COLOR_TYPE.NONE}
        markerStart={getArrowType(object.operateid, object.arrow.position, 0)}
        markerEnd={getArrowType(object.operateid, object.arrow.position, 1)}
      />
      {marks}
      {text}
    </g>
  );
}

export default Arc;
