import * as React from 'react';

function SvgImageArrowFancy(props) {
  return (
    <svg width={19} height={10} {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M0 3h10v3H0z" />
        <path d="M7.383 4.644L4 0l14.643 4.5L4 9.553z" />
      </g>
    </svg>
  );
}

export default SvgImageArrowFancy;
