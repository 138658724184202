import React, { useEffect, useState } from 'react';
import './index.scss';
import { clientPtToUserPt } from '@src/data/CommonFunction';
import { connect } from 'react-redux';

export const MouseTracker = ({ leafletMap }) => {
  const [clientPt, setClientPt] = useState(undefined);
  const [userPt, setUserPt] = useState([0, 0]);
  const [locPt, setLocPt] = useState([0, 0]);

  const updateTrackData = e => {
    const { clientX, clientY } = e;
    setClientPt([clientX, clientY]);
    setUserPt(clientPtToUserPt(clientX, clientY));
    if (leafletMap) {
      const latlng = leafletMap.mouseEventToLatLng(e);
      const point = leafletMap.mouseEventToLayerPoint(e);
      setLocPt([latlng.lat, latlng.lng]);
    }
  };

  useEffect(() => {
    const canvas = document.getElementById('canvas');
    if (canvas) {
      canvas.addEventListener('mousemove', updateTrackData);
    }
    return () => {
      if (canvas) {
        canvas.removeEventListener('mousemove', updateTrackData);
      }
    };
  }, [leafletMap]);

  if (!clientPt) {
    return null;
  } else {
    const [clientX, clientY] = clientPt;
    const [userX, userY] = userPt;
    const [locX, locY] = locPt;

    return (
      <div
        className="mouse-tracker"
        style={{
          position: 'fixed',
          top: clientY + 15,
          left: clientX + 15,
          pointerEvents: 'none',
        }}
      >
        <div>{`SVG   : [${userX.toFixed(1)}, ${userY.toFixed(1)}]`}</div>
        <div>{`Client: [${clientX.toFixed(1)}, ${clientY.toFixed(1)}]`}</div>
        <div>{`LatLng: [${locX.toFixed(6)}, ${locY.toFixed(6)}]`}</div>
      </div>
    );
  }
};

export default MouseTracker;
