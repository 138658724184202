import * as React from 'react';

function SvgImageLineStyleDashDotDot(props) {
  return (
    <svg {...props}>
      <path strokeDasharray="20,5,3,5,3,5" d="M5 10h215" fill="none" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

export default SvgImageLineStyleDashDotDot;
