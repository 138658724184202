import { changeCategory } from '@src/action/sidebar';
import LncdSpin from '@src/components/LncdSpin';
import TooltipWrapper from '@src/components/TooltipWrapper';
import { Button, Icon, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import escapeStringRegexp from 'escape-string-regexp';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './symbol-category.scss';
import SymbolCategoryMenuBody from './SymbolCategoryMenuBody';
import { subCategoryIconMapping } from './SymbolData';

/**
 * distance to scroll up/down while scroll up/down button is clicked
 */
const BUTTON_SCROLL_HEIGHT = 70;

class SymbolCategoryMenu extends Component {
  constructor(props) {
    super(props);
    this.menuContainerRef = React.createRef();
    this.onMenuStatusChange = this.onMenuStatusChange.bind(this);
  }

  favoriteNodeHeight = 72;
  defaultSubCategory = 'streets';

  state = {
    scrollTop: 0,
    scrollHeight: 0,
    keyword: '',
    categories: [],
    expandedKeys: [],
  };

  componentDidMount() {
    const categories = this.formatSymbols(this.props.symbols);
    this.setState(
      {
        categories,
        expandedKeys: categories.map(item => item.key),
      },
      () => this.changeSubCategory(this.defaultSubCategory)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.symbols !== this.props.symbols) {
      const categories = this.formatSymbols(nextProps.symbols);
      const oldKeys = this.state.categories.map(item => item.key);
      const newKeys = categories.map(item => item.key);
      const differentKeys = newKeys.filter(key => !oldKeys.includes(key));
      const symbolsLength = this.props.symbols.length;
      this.setState(
        {
          categories,
          expandedKeys: [...this.state.expandedKeys, ...differentKeys],
        },
        () => {
          if (symbolsLength === 0 && nextProps.symbols.length !== 0) {
            this.changeSubCategory(this.defaultSubCategory);
          }
        }
      );
    }
  }

  formatSymbols(symbols) {
    const result = [];
    symbols.forEach(symbol => {
      if (!result.find(item => item.key === symbol.categoryKey)) {
        result.push({
          key: symbol.categoryKey,
          name: symbol.categoryKey,
          subCategories: [],
        });
      }
      const category = result.find(item => item.key === symbol.categoryKey);
      if (!category.subCategories.find(item => item.key === symbol.subCategoryKey)) {
        category.subCategories.push({
          key: symbol.subCategoryKey,
          name: symbol.subCategoryName,
          functype: symbol.functype,
          symbols: [],
          icon: subCategoryIconMapping[symbol.subCategoryIcon],
        });
      }
      const subCategory = category.subCategories.find(item => item.key === symbol.subCategoryKey);
      subCategory.symbols.push(symbol);
    });
    return result;
  }

  handleOnTouchStart = evt => {
    let touch = evt.targetTouches[0];
    this.startY = touch.clientY;
  };

  handleOnTouchScroll = evt => {
    let touch = evt.targetTouches[0];
    this.endY = touch.clientY;
    let distanceY = this.endY - this.startY;
    let speed = 1;
    this.scroll(-speed * distanceY);
    this.startY = this.endY;
  };

  scroll = value => {
    const container = this.menuContainerRef.current;
    container.scrollTop += value;
  };

  onMenuStatusChange() {
    const container = this.menuContainerRef.current;
    if (container) {
      this.setState({
        scrollTop: container.scrollTop,
        scrollHeight: container.scrollHeight,
      });
    }
  }

  getSubcategoryKey = (keyword, subCategory) => {
    if (keyword) {
      return 'search';
    } else if ('search' === subCategory && 'search' !== this.props.selectedCategory) {
      return this.props.selectedCategory;
    } else if (!this.props.selectedCategory || 'search' === this.props.selectedCategory || 'search' === subCategory) {
      return this.defaultSubCategory;
    }
    return this.props.selectedCategory;
  };

  changeKeyword = event => {
    const subCategoryKey = this.getSubcategoryKey(event.target.value.trim(), 'search');
    this.updateKeyword(event.target.value, subCategoryKey);
  };

  clearKeyword = event => {
    const subCategoryKey = this.getSubcategoryKey('', this.props.selectedCategory);
    this.updateKeyword('', subCategoryKey);
  };

  focusSearch = event => {
    const subCategoryKey = this.getSubcategoryKey(this.state.keyword, 'search');
    this.updateKeyword(this.state.keyword, subCategoryKey);
  };

  updateKeyword = (value, subCategoryKey) => {
    const regular = new RegExp(escapeStringRegexp(value.trim()), 'i');
    this.setState(
      {
        keyword: value,
        categories: this.formatSymbols(
          this.props.symbols.filter(symbol => regular.test(this.props.t(`symbol.symbol.${symbol.key}`)))
        ),
      },
      () => this.changeSubCategory(subCategoryKey)
    );
  };

  changeSubCategory = subCategoryKey => {
    this.props.changeCategory({
      key: subCategoryKey,
      symbols: this.fetchSubCategorySymbol(subCategoryKey),
    });
  };

  fetchSubCategorySymbol = subCategoryKey => {
    subCategoryKey = subCategoryKey || this.props.selectedCategory;
    const regular = new RegExp(escapeStringRegexp(this.state.keyword), 'i');
    const favoriteSymbolsKey = this.props.favorites
      .filter(favorite => {
        const isFilteredByKeyword = regular.test(this.props.t(`symbol.symbol.${favorite.key}`));
        return isFilteredByKeyword && favorite.favorite;
      })
      .sort((a, b) => {
        if (a.favorite && b.favorite) {
          return a.useCount > b.useCount ? -1 : 1;
        } else if (a.favorite && !b.favorite) {
          return -1;
        } else if (!a.favorite && b.favorite) {
          return 1;
        } else {
          return a.useCount > b.useCount ? -1 : 1;
        }
      })
      .map(favorite => favorite.key);
    const favoriteSymbols = this.props.symbols.filter(symbol => favoriteSymbolsKey.includes(symbol.key));
    if ('favorites' === subCategoryKey) {
      return favoriteSymbols;
    } else if ('search' === subCategoryKey) {
      const result = [];
      this.state.categories.forEach(category => {
        category.subCategories.forEach(subCategory => {
          result.push(...subCategory.symbols);
        });
      });
      return result;
    } else {
      const category = this.state.categories.find(category => {
        return category.subCategories.find(subCategory => subCategory.key === subCategoryKey);
      });
      if (!category) {
        return [];
      }
      const subCategory = category.subCategories.find(subCategory => subCategory.key === subCategoryKey);
      return subCategory.symbols;
    }
  };

  render() {
    const { collapsed, canvasHeight, changeCategory, selectedCategory, burgerMenu } = this.props;

    /**
     * HACK
     * symbols & layers tabs height reduce border width equals tabs height. see: src\components\LncdSider\LayerMenu\layer-menu.scss
     */
    const tabsHeight = 40;

    // if collapsed, reduce the burger menu icon item height
    let maxSiderHeight = collapsed ? canvasHeight - 8 : canvasHeight - tabsHeight;
    maxSiderHeight -= this.favoriteNodeHeight;

    const container = this.menuContainerRef.current;
    let scrollUpBtnVisible = false;
    let scrollDownBtnVisible = false;
    if (container) {
      scrollUpBtnVisible = container.scrollTop > 0;
      scrollDownBtnVisible =
        Math.round(container.scrollTop) + canvasHeight < container.scrollHeight + this.favoriteNodeHeight;
    }

    const favorites = (
      <div
        className={classNames('symbol-category', { active: selectedCategory === 'favorites' })}
        onClick={this.changeSubCategory.bind(this, 'favorites')}
        style={{ borderBottomWidth: '1px' }}
      >
        <Icon type="star" />
        <span>{this.props.t('menu.symbol.favoriteText')}</span>
      </div>
    );

    const expandModule = (
      <>
        <Input
          id="lncd-favorite-search"
          value={this.state.keyword}
          onChange={this.changeKeyword}
          onFocus={this.focusSearch}
          placeholder={this.props.t('menu.symbol.searchPlaceholder')}
          type="text"
          autoComplete="off"
          prefix={
            <Icon type="search" style={{ fontSize: '16px', marginRight: '10px' }} className="lncd-auto-fit-text" />
          }
          suffix={
            this.state.keyword && (
              <Icon
                type="close"
                style={{ fontSize: '16px' }}
                className="lncd-auto-fit-text"
                onClick={this.clearKeyword}
              />
            )
          }
        />
        {/* <Pin /> */}
      </>
    );

    const showTooltip = this.props.device === 'untouch' && collapsed;

    return (
      <div className={classNames('symbol-category-menu', 'reg-no-select', { collapsed })}>
        {collapsed ? (
          <TooltipWrapper placement="right" title={this.props.t('menu.symbol.expandTitle')}>
            {burgerMenu}
          </TooltipWrapper>
        ) : (
          expandModule
        )}
        {!showTooltip ? (
          favorites
        ) : (
          <Tooltip key="favorites" placement="right" title={this.props.t('menu.symbol.favoriteTitle')}>
            {favorites}
          </Tooltip>
        )}
        {this.props.symbols.length === 0 ? (
          <LncdSpin />
        ) : (
          <div
            className="menu"
            style={{ maxHeight: maxSiderHeight }}
            ref={this.menuContainerRef}
            onScroll={this.onMenuStatusChange}
            onTouchStart={this.handleOnTouchStart}
            onTouchMove={this.handleOnTouchScroll}
          >
            {scrollUpBtnVisible && (
              <Button
                type="primary"
                icon="double-left"
                className="top-btn"
                onClick={() => {
                  this.scroll(-1 * BUTTON_SCROLL_HEIGHT);
                }}
              />
            )}
            <SymbolCategoryMenuBody
              onSize={this.onMenuStatusChange}
              collapsed={collapsed}
              showTooltip={showTooltip}
              categories={this.state.categories}
              changeSubCategory={this.changeSubCategory}
              selectedCategory={selectedCategory}
              onMenuStatusChange={this.onMenuStatusChange}
            />
            {scrollDownBtnVisible && (
              <Button
                type="primary"
                icon="double-right"
                className="bottom-btn"
                onClick={() => {
                  this.scroll(BUTTON_SCROLL_HEIGHT);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  canvasHeight: state.app.canvasHeight,
  selectedCategory: state.sidebar.selectedCategory,
  device: state.app.device,
});

const mapDispatchToProps = {
  changeCategory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SymbolCategoryMenu));
