import React, { useState, useEffect } from 'react';
import Images from '@src/assets/Images';
import { LENGTH_TYPE } from '@src/constant';
import './StyleInfo.css';

function StyleInfo({ disabled, position, onClick }) {
  const [pos, setPos] = useState([0, 0])
  const [marginLeft, setMarginLeft] = useState(0)
  const [marginTop, setMarginTop] = useState(0)
  useEffect(() => {
    if (position instanceof Promise) {
      position.then(pos => {
        setPos(pos);
        setMarginLeft(pos[0]);
        setMarginTop(pos[1]);
      });
    } else {
      setPos(position);
      setMarginLeft(pos[0]);
      setMarginTop(pos[1]);
    }
  }, [position])

  return (
    !disabled && (
      <div className="custom-info-mask reg-no-select">
        <div
          className="custom-info-win"
          style={{
            // width: 24,
            height: 24,
            marginLeft: marginLeft - LENGTH_TYPE.INFORANGLESPACE + 'px',
            marginTop: marginTop - LENGTH_TYPE.INFORANGLESPACE + 'px',
          }}
        >
          <img src={Images.IconProps} alt="" onClick={onClick} />
        </div>
      </div>
    )
  );
}

export default StyleInfo;
