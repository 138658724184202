import * as React from 'react';

function SvgIconPrinter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M7 8v2h10V8h2a3.004 3.004 0 012.995 2.824L22 11v9h-3v4H5v-4H2v-9a3.004 3.004 0 012.824-2.995L5 8h2zm10 10H7v4h10v-4zm2-8v2H5v-2c-.512 0-.935.386-.993.883L4 11v7h1v-2h14v2h1v-7c0-.552-.449-1-1-1zm-2 3a1 1 0 110 2 1 1 0 010-2zm2-12v6h-2V3H7v4H5V1h14z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconPrinter;
