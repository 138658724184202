import React from 'react';
import Text from '@src/shapes/Text';
import {
  FindCircle,
  FindCircleLinePoint,
  // FindCircleLinePointK,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetCenter,
  GetCirclePointK,
  IsOneLine,
  LengthBetweenPoints,
  // TowPointVerticalLength,
  TowPointVerticalLengthByStatus,
} from '@src/data/CommonFunc';
import {
  ANCHORS_TYPE,
  STAIR_SPACE,
  TEXT_ANCHORS_TYPE,
  TEXT_POSITION_TYPE,
  THREE_POINT_STATUS_TYPE,
} from '@src/constant';

export function TextStairsHandle({ handlepoint, text, style }) {
  let angle = getAngleBy2Points(handlepoint[0], handlepoint[6]);
  let isLine = IsOneLine(handlepoint[0], handlepoint[3], handlepoint[6]) == THREE_POINT_STATUS_TYPE.ONLINE;
  let textPoint = []; // [center[0] - style.strokewidth, center[1] - style.strokewidth];
  let textAnchors = TEXT_ANCHORS_TYPE.MIDDLE;
  if (text.position === TEXT_POSITION_TYPE.STAIRDIRECTION) {
    let begin = GetCenter(handlepoint[1], handlepoint[2], STAIR_SPACE.STAIRMARKEND);
    let pointEnd = GetCenter(handlepoint[4], handlepoint[5], STAIR_SPACE.STAIRMARKEND);
    if (isLine) {
      textPoint = GetCenterInTwoPoints(begin, pointEnd);
    } else {
      let circle = FindCircle(handlepoint[0], handlepoint[3], handlepoint[6]);
      let circlePoint = [circle[0], circle[1], LengthBetweenPoints(circle, begin)];
      textPoint = FindCircleLinePoint(circlePoint, GetCenterInTwoPoints(begin, pointEnd));
      let k = GetCirclePointK(circlePoint, textPoint);
      angle = Math.atan(k);
    }
  } else if (text.position === TEXT_POSITION_TYPE.LEFTTOP) {
    textPoint = [handlepoint[1][0] - style.strokewidth - text.size, handlepoint[1][1] - style.strokewidth - text.size];
    if (!isLine) {
      let circle = FindCircle(handlepoint[0], handlepoint[3], handlepoint[6]);
      let circlePoint = [circle[0], circle[1], LengthBetweenPoints(circle, textPoint)];
      let k = GetCirclePointK(circlePoint, textPoint);
      angle = Math.atan(k);
    }
  } else if (text.position === TEXT_POSITION_TYPE.MIDIUMTOP) {
    textPoint = [handlepoint[4][0] - style.strokewidth - text.size, handlepoint[4][1] - style.strokewidth - text.size];
    if (!isLine) {
      let circle = FindCircle(handlepoint[0], handlepoint[3], handlepoint[6]);
      let circlePoint = [circle[0], circle[1], LengthBetweenPoints(circle, textPoint)];
      let k = GetCirclePointK(circlePoint, textPoint);
      angle = Math.atan(k);
    }
  } else if (text.position === TEXT_POSITION_TYPE.RIGHTTOP) {
    textPoint = [handlepoint[7][0] - style.strokewidth - text.size, handlepoint[7][1] - style.strokewidth - text.size];
    if (!isLine) {
      let circle = FindCircle(handlepoint[0], handlepoint[3], handlepoint[6]);
      let circlePoint = [circle[0], circle[1], LengthBetweenPoints(circle, textPoint)];
      let k = GetCirclePointK(circlePoint, textPoint);
      angle = Math.atan(k);
    }
  } else if (text.position === TEXT_POSITION_TYPE.RIGHTBOTTOM) {
    textPoint = [handlepoint[8][0] + style.strokewidth + text.size, handlepoint[8][1] + style.strokewidth + text.size];
    if (!isLine) {
      let circle = FindCircle(handlepoint[0], handlepoint[3], handlepoint[6]);
      let circlePoint = [circle[0], circle[1], LengthBetweenPoints(circle, textPoint)];
      let k = GetCirclePointK(circlePoint, textPoint);
      angle = Math.atan(k);
    }
  } else if (text.position === TEXT_POSITION_TYPE.MIDIUMBOTTOM) {
    textPoint = [handlepoint[5][0] + style.strokewidth + text.size, handlepoint[5][1] + style.strokewidth + text.size];
    if (!isLine) {
      let circle = FindCircle(handlepoint[0], handlepoint[3], handlepoint[6]);
      let circlePoint = [circle[0], circle[1], LengthBetweenPoints(circle, textPoint)];
      let k = GetCirclePointK(circlePoint, textPoint);
      angle = Math.atan(k);
    }
  } else if (text.position === TEXT_POSITION_TYPE.LEFTBOTTOM) {
    textPoint = [handlepoint[2][0] + style.strokewidth + text.size, handlepoint[2][1] + style.strokewidth + text.size];
    if (!isLine) {
      let circle = FindCircle(handlepoint[0], handlepoint[3], handlepoint[6]);
      let circlePoint = [circle[0], circle[1], LengthBetweenPoints(circle, textPoint)];
      let k = GetCirclePointK(circlePoint, textPoint);
      angle = Math.atan(k);
    }
  }
  return (
    <Text angle={angle} x={textPoint[0]} y={textPoint[1]} textAnchor={textAnchors} text={text} content={text.text} />
  );
}
export function GetCrossWalkText(object, height) {
  let angle = getAngleBy2Points(object.handlepoint[0], object.handlepoint[6]);
  let textHeight = height + object.text.size + object.style.strokewidth;
  let textPoint = TowPointVerticalLengthByStatus(
    object.handlepoint[0],
    object.handlepoint[6],
    object.handlepoint[3],
    textHeight,
    true
  );
  return (
    <Text
      angle={angle}
      x={textPoint[0]}
      y={textPoint[1]}
      textAnchor={TEXT_ANCHORS_TYPE.MIDDLE}
      text={object.text}
      content={object.text.text}
    />
  );
}

export function GetStreetText(object) {
  if (object.text.text.length === 0) return null;
  let anchors = object.handlepoint;
  let textPoint = object.text.point;
  if (textPoint.length === 0) {
    let center01 = GetCenterInTwoPoints(anchors[0], anchors[1]);
    textPoint = TowPointVerticalLengthByStatus(anchors[0], anchors[1], center01, object.lanewidth / 2, false);
  }
  return (
    <Text
      angle={0}
      x={textPoint[0]}
      y={textPoint[1]}
      textAnchor={TEXT_ANCHORS_TYPE.MIDDLE}
      text={object.text}
      content={object.text.text}
      key={'streettext'}
      id={ANCHORS_TYPE.STREETTEXTRECT}
    />
  );
}
