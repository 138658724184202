/**
 * This module provides utilities to handle SVG transform matrix related operations
 */

import { matrixMultiply } from '@src/data/CommonFunc';

/**
 * Scale the SVG viewport and returns the new SVGMatrix
 * @param {Array} originMatrix
 * @param {number} scale
 * @param {number} originX unit point
 * @param {number} originY unit point
 * @returns {SVGMatrix}
 */
export const scaleAround = (originMatrix, scale, originX, originY) => {
  let matrix = matrixMultiply(originMatrix, [1, 0, 0, 1, originX, originY]);
  matrix = matrixMultiply(matrix, [scale, 0, 0, scale, 0, 0]);
  matrix = matrixMultiply(matrix, [1, 0, 0, 1, -originX, -originY]);
  return matrix;
};
