import { CHANGE_CATEGORY, TOGGLE_PIN } from '@src/type/sidebar';
import types from '@src/type/canvas';

export const changeCategory = ({ key, symbols }) => dispatch => {
  dispatch({
    type: CHANGE_CATEGORY,
    key,
    symbols,
  });
  const doLockRoads = key !== 'streets';
  dispatch({
    type: types.SET_LOCK_ALL_ROADS,
    value: doLockRoads,
  });
};

export const togglePin = () => ({
  type: TOGGLE_PIN,
});
