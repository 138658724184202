import {
  SET_MOUSE_OVER_CANVAS,
  UPDATE_MOUSE_POSITION,
  RECORD_CLICK_STATE_ON_CANVAS,
  UPDATE_LAST_CLICK_POS,
} from '@src/type/pointer';
import { produce } from 'immer';

const initialState = {
  clientX: 0,
  clientY: 0,
  lastClickPosX: 0,
  lastClickPosY: 0,
  isMouseOverCanvas: false,
  isMouseClickCanvas: false,
};

export default produce((draft, action) => {
  if (!draft) return initialState;

  switch (action.type) {
    case UPDATE_MOUSE_POSITION: {
      draft.clientX = action.clientX;
      draft.clientY = action.clientY;
      break;
    }
    case SET_MOUSE_OVER_CANVAS: {
      draft.isMouseOverCanvas = action.value;
      break;
    }
    case RECORD_CLICK_STATE_ON_CANVAS: {
      draft.isMouseClickCanvas = action.isClick;
      break;
    }
    case UPDATE_LAST_CLICK_POS: {
      draft.lastClickPosX = action.x;
      draft.lastClickPosY = action.y;
      break;
    }
    default:
      break;
  }
});
