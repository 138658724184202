// action
import { toggleNightMode, setGlobalLoading } from '@src/action/app';
import { getMatrixScale, getMatrixPos, setMatrix } from '@src/action/transform';
import { toggleGrid } from '@src/action/canvas';
import { toggleMapContainer } from '@src/action/map';
import { ImportImg } from '@src/actions/ManageDiagram';
import { TransformCalloutScaleWidth } from '@src/actions/TransformHandle';
import { message } from '@src/components/Message';
import TemplateModal from '@src/components/Template/TemplateModal';
import * as SvgIcons from '@src/components/UI/Icons';
import IconFontLarger from '@src/components/UI/Icons/fixed/IconFontLarger';
import IconFontSmaller from '@src/components/UI/Icons/fixed/IconFontSmaller';
import { APP_ROOT_EL_ID, FUNCTION_TYPE, MANAGE_FUNC_TYPE, OPERATIONS } from '@src/constant';
import emitter from '@src/data/Event';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import {
  crossAddUseDataIntoWorkData,
  getSVGFile,
  loadMetaDataFormFileUrl,
  reGenerateOperateIdForUseData,
} from '@src/utils';
import { Dropdown, Icon, Menu, Modal } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState, useReducer, useContext, useRef, useMemo, memo } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DevOptions from './DevOptions/DevOptions';
import ExportDialog from './ExportDialog';
import PrintDialog from './PrintDialog';
import SettingDialog from './SettingDialog';
import { useLeafletMap } from '@src/hooks';
import { exitDiagram, setImageConsumed } from '@src/action/ethos';
import { calcExecTime } from '@src/constant/utils';
import { setDiagramSaved, initialSnapshots } from '@src/action/snapshots';
import './top-menu-bar.scss';

const { REDO, UNDO, COPY, PASTE, CUT, DELETE } = OPERATIONS;

function TopMenuBar(props) {
  const { imageData, existingImage, leafletMap } = props;

  const { getMapHref } = useLeafletMap(leafletMap);

  const fileInputRef = useRef();

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_SHOW_EXPORT_DIALOG': {
          return {
            ...state,
            showExportDialog: action.payload,
          };
        }
        case 'SET_SHOW_TEMPLATES_DIALOG': {
          return {
            ...state,
            showTemplatesDialog: action.payload,
          };
        }
        case 'SET_SHOW_APP_SETTINGS': {
          return {
            ...state,
            showAppSettings: action.payload,
          };
        }
        case 'SET_SHOW_PRINT_DIALOG': {
          return {
            ...state,
            showPrintDialog: action.payload,
          };
        }
        default:
          return state;
      }
    },
    {
      // visibility of export image modal
      showExportDialog: false,
      showTemplatesDialog: false,
      showAppSettings: false,
      showPrintDialog: false,
    }
  );

  useEffect(() => {
    new Promise(resolve => {
      // the imageData comes with fresh new/restart Crash Designer
      if (imageData && !props.imageConsumed) {
        resolve(readDataAsSVG(imageData));
      }
    }).then(() => {
      props.setImageConsumed();
    });
  }, [imageData]);

  useEffect(() => {
    new Promise(resolve => {
      // MEDIUMBLOB for 16777215 bytes (16 MB)
      if (existingImage && existingImage.size < 16777215) {
        resolve(readDataAsImg(existingImage));
      }
    });
  }, [existingImage]);

  // TODO: repeat with handleExportImage, need redesign
  /**
   * @description from Ethos
   * @async
   */
  const onSaveImage = async () => {
    const { map } = props;
    props.setGlobalLoading(true);
    calcExecTime(async () => {
      let mapData, href;
      if (map.service.latitude !== 0 && map.service.longitude !== 0) {
        mapData = {
          service: map.service, // latitude, longitude, schema, type
          zoom: map.zoom,
          opacity: map.opacity,
          currentLocation: map.currentLocation,
          // selectOptions: map.selectOptions,
          // keyword: map.keyword,
          // mapTabKey: map.mapTabKey,
        };
        href = await getMapHref();
      }
      const position = props.getMatrixPos();
      const file = getSVGFile('save-file.svg', {
        map: mapData,
        href: href,
        image: {
          scale: props.getMatrixScale(),
          x: position[0],
          y: position[1],
        },
      });
      props.onSvgImage(file);
      props.setDiagramSaved();
    }).finally(() => {
      props.setGlobalLoading(false);
    });
  };

  let modal;
  const doExitDiagram = useCallback(() => {
    emitter.emit(EVENT_EMIT_TYPE.MANAGEFUNC, MANAGE_FUNC_TYPE.START_OVER);
    props.exitDiagram();
    props.onExit();
  }, []);

  /**
   * @description check unsaved change status and let the user decide if to quit by resetting diagram and call method from Ethos
   */
  const onExitImage = useCallback(() => {
    if (!props.hasUnsavedChanges) doExitDiagram();
    else {
      modal = Modal.confirm({
        title: t('menu.top.exitWarning'),
        okText: t('common.ok'),
        getContainer: () => document.getElementById(APP_ROOT_EL_ID),
        destroyOnClose: true,
        onOk: () => {
          /**
           * Fixed: when click ok in confirm window and exit from the app, the container of app lost scrollbar.
           * https://github.com/ant-design/ant-design/issues/21539
           */
          modal.destroy();
          setTimeout(() => {
            doExitDiagram();
          }, 1000);
        },
      });
    }
  }, [props.hasUnsavedChanges]);

  // Unused in anywhere
  /**
   * read a file object as blob
   * @param {File} file - the svg element convert to file
   * @param {Function} callback
   */
  // const readFileAsBlob = (file, callback) => {
  //   let reader = new FileReader();
  //   reader.onload = function(e) {
  //     let blob = new Blob(new Uint8Array(e.target.result), { type: file.type, name: file.name });
  //     callback && callback(blob);
  //   };
  //   reader.readAsArrayBuffer(file);
  // };

  /**
   * Load file from ethos props and render it in current diagram!
   * @param {File} file
   */
  const readDataAsSVG = async file => {
    const url = window.URL.createObjectURL(file);
    const loadFileMetaDataPromise = loadMetaDataFormFileUrl(url);
    await handleSelectFilePromises([loadFileMetaDataPromise], true);
  };

  /**
   *
   * @param {Blob} blob
   */
  const readDataAsImg = async blob => {
    const file = new File([blob], 'image.jpg', { type: blob.type });
    handleImageFile(file);
  };

  const selectFiles = event => {
    const files = event.target.files;
    if (0 === selectFiles.length) {
      return message.error('Please select files to import!');
    }
    // handleSelectFiles();
    handleInsertFiles(files);
  };

  const handleInsertFiles = async files => {
    const loadFileMetaDataPromises = [];
    const imageFileList = [];
    for (let i = 0, len = files.length; i < len; i++) {
      const file = files[i];
      switch (file.type) {
        case 'image/svg+xml': {
          const url = window.URL.createObjectURL(file);
          try {
            const loadFileMetaDataPromise = await loadMetaDataFormFileUrl(url);
            loadFileMetaDataPromises.push(loadFileMetaDataPromise);
          } catch (e) {
            imageFileList.push(file);
          }
          break;
        }
        case 'image/png':
        case 'image/jpeg': {
          imageFileList.push(file);
          break;
        }
        default:
          message.error(t('template.listModal.loadFileFormatIncorrect'));
      }
    }
    if (0 === workData.getUseData().length) {
      handleInsertFilesAction(loadFileMetaDataPromises, imageFileList);
    } else {
      // FIXME: it's not correct to use 'cancel' event as 'override' operation
      Modal.confirm({
        // TODO: disable cancel with ESC button, otherwise, onCancel being executed when pressing ESC
        keyboard: false,
        getContainer: () => document.getElementById(APP_ROOT_EL_ID),
        title: t('menu.top.importFileSelectionMessage'),
        okText: t('menu.top.appendFileText'),
        onOk: () => {
          handleInsertFilesAction(loadFileMetaDataPromises, imageFileList);
        },
        cancelText: t('menu.top.overrideFileText'),
        onCancel: () => {
          workData.getUseData().length = 0;
          props.clearMap();
          handleInsertFilesAction(loadFileMetaDataPromises, imageFileList);
        },
      });
    }
  };

  /**
   *
   * @param {{map: any, operateLastid: number, useData: []}[]} loadFileMetaDataPromises
   * @param {File[]} imageFileList
   */
  const handleInsertFilesAction = (loadFileMetaDataPromises, imageFileList) => {
    if (imageFileList.length > 0) {
      workData.cancelSelectAllObjects();
      imageFileList.forEach(file => {
        handleImageFile(file);
      });
    }
    handleSelectFilePromises(loadFileMetaDataPromises);
  };

  const handleImageFile = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = evt => {
      ImportImg(evt.target.result);
    };
    reader.onerror = () => {
      message.onerror('Image source load failed');
    };
  };

  /**
   *
   * @param {{map: any, operateLastid: number, useData: []}[]} loadFileMetaDataPromises
   * @param {boolean} [isDataFromEthos]
   */
  const handleSelectFilePromises = (loadFileMetaDataPromises, isDataFromEthos = false) => {
    Promise.all(loadFileMetaDataPromises).then(results => {
      let initialSnapshot;
      results.forEach(result => {
        if (result && result instanceof Object) {
          reGenerateOperateIdForUseData(result.useData);
          crossAddUseDataIntoWorkData(result.useData);
          // if (workData.hasOnlyOneObject(result.useData)) {
          //   crossAddUseDataIntoWorkData(result.useData);
          // } else {
          //   GroupShapes(result.useData);
          // }
          if (result.map) props.resumeMap(result.map);
          if (result.image) props.setMatrix({ ...result.image });
          if (isDataFromEthos) {
            initialSnapshot = {
              map: result.map,
              shapes: result.useData,
            };
          }
        }
      });

      if (isDataFromEthos) {
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, false);
        props.initialSnapshots(initialSnapshot);
      } else {
        /**
         * FIXME: 在 handleInsertFilesAction 方法中的调用存在问题，此时图片未加载出来。
         */
        // emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      }
    });
  };

  const startOver = () => {
    Modal.confirm({
      title: t('menu.top.deleteAllConfirm'),
      okText: t('menu.settingModal.okButtonText'),
      getContainer: () => document.getElementById(APP_ROOT_EL_ID),
      onOk: () => {
        props.setDiagramSaved();
        emitter.emit(EVENT_EMIT_TYPE.MANAGEFUNC, MANAGE_FUNC_TYPE.START_OVER);
      },
    });
  };

  const changeDataFontSize = (data, value) => {
    const realValue = parseInt((data.text.size * value) / 5, 10);
    if (data.text) {
      data.text.size += realValue;
    }
    if (FUNCTION_TYPE.CALLOUT === data.functype) {
      const scaleHeight = realValue / data.text.size + 1;
      data.handlepoint = TransformCalloutScaleWidth(
        data.handlepoint,
        data.text.text.length * data.text.size,
        scaleHeight
      );
    }
  };

  const changeFontSize = value => {
    workData.getUseData().forEach(data => {
      changeDataFontSize(data, value);
      if (FUNCTION_TYPE.GROUP === data.functype && data.groupdata) {
        data.groupdata.forEach(groupData => {
          changeDataFontSize(groupData, value);
        });
      }
      emitter.emit(EVENT_EMIT_TYPE.OBJECT_UPDATED, data);
    });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };

  const renderIconBtn = useCallback(item => {
    const { handleCanvasOperation } = props;
    const { name, title, handleClick, smHide, icon: Icon, toggled, disabled } = item;
    let onClick = handleClick;
    if (Object.values(OPERATIONS).indexOf(name) > -1) {
      onClick = () => handleCanvasOperation(name);
    }
    return (
      <div
        key={name}
        className={classNames('top-menu-bar-btn', {
          'lncd-sm-hide': smHide,
          active: toggled,
          'top-menu-bar-btn-enabled': !disabled,
        })}
        title={title}
        onClick={disabled ? null : onClick}
      >
        <Icon style={{ color: disabled ? `var(--color-top-menu-item-disabled)` : undefined }} />
      </div>
    );
  }, []);

  const {
    onExit,
    onSvgImage,
    toggleNightMode,
    // redux night mode state value
    nightMode,
    handleCanvasOperation,
    isShowGrid,
    // passed from Ethos App
    isNightMode,
    // redux map
    map,
    toggleMapContainer,
    // withMap
    clearMap,
    //
    snapshotIndex,
    snapshotsLength,
  } = props;

  const { t } = useTranslation();

  const actionBtns = useMemo(
    () =>
      [
        {
          name: UNDO,
          title: t('menu.top.undoTitle'),
          icon: SvgIcons.IconUndo,
          disabled: snapshotsLength < 2 || snapshotIndex === 0,
        },
        {
          name: REDO,
          title: t('menu.top.redoTitle'),
          icon: SvgIcons.IconRedo,
          disabled: snapshotsLength < 2 || snapshotIndex === snapshotsLength - 1,
        },
        {
          name: CUT,
          title: t('menu.top.cutTitle'),
          icon: SvgIcons.IconCut,
        },
        {
          name: COPY,
          title: t('menu.top.copyTitle'),
          icon: SvgIcons.IconCopy,
        },
        {
          name: PASTE,
          title: t('menu.top.pasteTitle'),
          icon: SvgIcons.IconPaste,
        },
        {
          name: DELETE,
          title: t('menu.top.deleteTitle'),
          icon: SvgIcons.IconTrash,
        },
        {
          name: 'importTitle',
          title: t('menu.top.importTitle'),
          icon: SvgIcons.IconAddImage,
          handleClick: () => {
            if (fileInputRef.current) {
              // clear previous value so that user could choose the same file.
              fileInputRef.current.value = '';
              fileInputRef.current.click();
            }
          },
          smHide: true,
        },
        {
          name: 'export',
          title: t('menu.top.exportTitle'),
          icon: SvgIcons.IconDownload,
          handleClick: () => {
            if (workData.getUseData().length === 0) {
              message.warning(t('menu.top.noDiagramToExport'));
              return;
            }
            dispatch({ type: 'SET_SHOW_EXPORT_DIALOG', payload: true });
          },
          smHide: true,
        },
        {
          name: 'printDiagram',
          title: t('menu.top.printTitle'),
          icon: SvgIcons.IconPrinter,
          // handleClick: () => {
          //   emitter.emit(EVENT_EMIT_TYPE.MANAGEFUNC, MANAGE_FUNC_TYPE.PRINT);
          // },
          handleClick: () => dispatch({ type: 'SET_SHOW_PRINT_DIALOG', payload: true }),
          smHide: true,
        },
        {
          name: 'locationTitle',
          title: t('menu.top.setLocationTitle'),
          icon: SvgIcons.IconMap,
          handleClick: () => {
            toggleMapContainer();
          },
          smHide: true,
        },
        {
          name: 'eraseAndStartOver',
          title: t('menu.top.eraseTitle'),
          icon: SvgIcons.IconChalkboard,
          handleClick: startOver,
        },
        {
          name: 'templates',
          title: t('menu.top.templateTitle'),
          icon: SvgIcons.IconTemplates,
          handleClick: () => dispatch({ type: 'SET_SHOW_TEMPLATES_DIALOG', payload: true }),
          smHide: true,
        },
      ].filter(n => n.visible !== false),
    [snapshotsLength, snapshotIndex]
  );

  const viewBtns = useMemo(
    () =>
      [
        {
          name: 'enableGrid',
          title: t('menu.top.gridTitle'),
          icon: SvgIcons.IconGrid,
          handleClick: () => props.toggleGrid(),
          toggled: isShowGrid,
          smHide: true,
        },
        {
          name: 'nightModeTitle',
          title: t('menu.top.nightModeTitle'),
          icon: SvgIcons.IconMoon,
          toggled: nightMode,
          handleClick: toggleNightMode,
          // means that night mode is not controled by the parent App
          visible: isNightMode === undefined,
          smHide: true,
        },
        {
          // FIXME: compare with the origin logic in case missing something
          // emitter.emit(EVENT_EMIT_TYPE.HOMEFUNC, HOME_FUNC_TYPE.INCREASE_TEST_SIZE);
          name: 'increaseFontSize',
          title: t('menu.top.increaseFontSizeTitle'),
          icon: IconFontLarger,
          handleClick: changeFontSize.bind(this, 1),
          smHide: true,
        },
        {
          // emitter.emit(EVENT_EMIT_TYPE.HOMEFUNC, HOME_FUNC_TYPE.DECREASE_TEST_SIZE);
          name: 'decreaseFontSize',
          title: t('menu.top.decreaseFontSizeTitle'),
          icon: IconFontSmaller,
          handleClick: changeFontSize.bind(this, -1),
          smHide: true,
        },
        {
          name: 'zoomIn',
          title: t('menu.top.zoomInTitle'),
          icon: SvgIcons.IconZoomIn,
          handleClick: () => props.handleZoom(true),
        },
        {
          name: 'zoomOut',
          title: t('menu.top.zoomOutTitle'),
          icon: SvgIcons.IconZoomOut,
          handleClick: () => props.handleZoom(false),
        },
        {
          name: 'zoomFit',
          title: t('menu.top.zoomFitTitle'),
          icon: SvgIcons.IconZoomFit,
          handleClick: () => emitter.emit(EVENT_EMIT_TYPE.ZOOM),
        },
        {
          name: 'crashDesignerSettings',
          title: t('menu.top.crashDesignerSettingsTitle'),
          icon: SvgIcons.IconSetting,
          handleClick: () => dispatch({ type: 'SET_SHOW_APP_SETTINGS', payload: true }),
          smHide: true,
        },
        {
          name: 'save',
          title: t('menu.top.saveTitle'),
          icon: SvgIcons.IconSave,
          handleClick: onSaveImage,
          visible: !!onSvgImage,
          smHide: true,
        },
        {
          name: 'exitDiagram',
          title: t('menu.top.exitTitle'),
          icon: SvgIcons.IconCloseCircle,
          handleClick: onExitImage,
          visible: !!onExit,
          smHide: true,
        },
      ].filter(n => n.visible !== false),
    [onSvgImage, onExit, isShowGrid, nightMode, isNightMode, onExitImage, map]
  );

  return (
    <div className="headerContainer reg-no-select top-menu-bar">
      <div>
        <span className="lncd-sm-hide section-label">{t('menu.top.actionLabel')} : </span>
        {actionBtns.map(renderIconBtn)}
        <input
          id="lncd-import-local-image-file"
          multiple
          ref={fileInputRef}
          type="file"
          accept="image/jpeg,image/gif,image/png,image/svg+xml"
          style={{ display: 'none' }}
          onChange={selectFiles}
        />
      </div>
      <div className="infill" />
      <div>
        <span className="lncd-sm-hide section-label">{t('menu.top.viewLabel')} : </span>
        {viewBtns.map(renderIconBtn)}
        {/* FIXME: repeat code to create buttons */}
        <div className="lncd-lg-hide lncd-more-menu top-menu-bar-btn">
          {/*<Icon type="more" style={{ fontSize: '28px' }} />*/}
          <Menu mode="horizontal">
            <Menu.SubMenu
              title={<Icon type="more" style={{ fontSize: '24px', margin: 0 }} />}
              popupClassName="lncd-more-submenu"
            >
              {[...actionBtns, ...viewBtns]
                .filter(n => n.smHide)
                .map(item => {
                  // FIXME: if SubMenu supports rendering custom component, we could reuse the renderIconBtn function
                  const { name, handleClick, title, icon: Icon } = item;
                  let onClick = handleClick;
                  if (Object.keys(OPERATIONS).indexOf(name) > -1) {
                    onClick = () => handleCanvasOperation(name);
                  }
                  return (
                    <Menu.Item key={name} onClick={onClick}>
                      <div className={classNames('top-menu-bar-btn')} title={title}>
                        <Icon />
                      </div>
                    </Menu.Item>
                  );
                })}
            </Menu.SubMenu>
          </Menu>
        </div>
        {__DEV__ && (
          <Dropdown trigger={['click']} overlay={<DevOptions />} className="lncd-sm-hide">
            <div className={classNames('top-menu-bar-btn', { active: nightMode })}>
              <SvgIcons.IconSetting />
            </div>
          </Dropdown>
        )}
      </div>
      {state.showExportDialog && (
        <ExportDialog
          leafletMap={leafletMap}
          onCancel={() => dispatch({ type: 'SET_SHOW_EXPORT_DIALOG', payload: false })}
        />
      )}
      {state.showPrintDialog && (
        <PrintDialog onCancel={() => dispatch({ type: 'SET_SHOW_PRINT_DIALOG', payload: false })} />
      )}
      {state.showTemplatesDialog && (
        <TemplateModal
          clearMap={clearMap}
          onCancel={() => dispatch({ type: 'SET_SHOW_TEMPLATES_DIALOG', payload: false })}
        />
      )}
      {state.showAppSettings && (
        <SettingDialog onCancel={() => dispatch({ type: 'SET_SHOW_APP_SETTINGS', payload: false })} />
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  nightMode: state.app.nightMode,
  isShowGrid: state.canvas.isShowGrid,
  map: state.map,
  imageConsumed: state.ethos.imageConsumed,
  hasUnsavedChanges: state.snapshots.hasUnsavedChanges,
  snapshotsLength: state.snapshots.snapshots.length,
  snapshotIndex: state.snapshots.snapshotIndex,
});

const mapDispatchToProps = {
  toggleNightMode,
  toggleMapContainer,
  toggleGrid,
  setImageConsumed,
  exitDiagram,
  setGlobalLoading,
  getMatrixScale,
  getMatrixPos,
  setMatrix,
  setDiagramSaved,
  initialSnapshots,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(TopMenuBar));
