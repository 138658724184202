import produce from 'immer';
import * as actionTypes from '@src/type/street';
import { FLUSH_WORK_DATA } from '@src/type/canvas';
import workData from '@src/data/WorkData';

const initialState = {
  selectedStreet: {
    xAxisAngle: 0,
  },
};

// TODO: don't use it yet
const streetReducer = produce((draft, action) => {
  if (!draft) {
    return initialState;
  }
  switch (action.type) {
    case actionTypes.ADD_LANE:
      break;
    case actionTypes.UPDATE_AXIS_RAD: {
      draft.selectedStreet.xAxisAngle = action.degree;
      break;
    }
    default:
      break;
  }
});

export default streetReducer;
