class GeomVector {
  /** @type {number} */
  vx;

  /** @type {number} */
  vy;

  /**
   *
   * @param {number} [x]
   * @param {number} [y]
   */
  constructor(x = 0, y = 0) {
    this.vx = x;
    this.vy = y;
  }

  static get unitVectorHorizontal() {
    return new GeomVector(1, 0);
  }

  /**
   * Get the cosine value of the angle between two vectors
   * @param {GeomVector} v
   * @returns {number} - range=[-1, 1]
   */
  angleCos(v) {
    var v1 = this.clone();
    var v2 = v.clone();
    v1.normalize();
    v2.normalize();
    var a = v1.dot(v2);
    if (a > 1) {
      a = 1;
    } else if (a < -1) {
      a = -1;
    }
    return a;
  }

  angleSin(v) {
    return Math.sqrt(1 - this.angleCos(v) * this.angleCos(v));
  }

  /**
   * The rotation rad from this vector to given vector `v`
   * @param {GeomVector} v
   * @returns {number} - radian
   */
  getRotationRad(v) {
    const cp = this.cross(v)
    let rad = Math.acos(this.angleCos(v))
    return cp > 0 ? rad : (2 * Math.PI - rad)
  }

  clone() {
    return new GeomVector(this.vx, this.vy);
  }

  dot(v) {
    return this.vx * v.vx + this.vy * v.vy;
  }

  /**
   * Cross product of this * v
   * if the result is positive, this vector is clockwise to v (angle between these 2 vectors is [0, PI])
   * @param {GeomVector} v
   */
  cross(v) {
    const {vx: x1, vy: y1} = this;
    const {vx: x2, vy: y2} = v;
    return x1 * y2 - x2 * y1;
  }

  isEqual(v) {
    return this.vx == v.x && this.vy == v.y;
  }

  magnitude() {
    return Math.sqrt(this.square());
  }

  normal() {
    return new GeomVector(-this.vy, this.vx);
  }

  normalize() {
    var mag = this.magnitude();
    if (0 !== mag) {
      this.vx /= mag;
      this.vy /= mag;
    }
  }

  /**
   * 缩放
   * @param {number} s
   */
  scale(s) {
    this.vx *= s;
    this.vy *= s;
  }

  square() {
    return this.dot(this);
  }

  /**
   * 向量和
   * @param {GeomVector} v
   * @returns {GeomVector}
   */
  add(v) {
    return new GeomVector(this.vx + v.vx, this.vy + v.vy);
  }

  /**
   * 向量差
   * @param {GeomVector} v
   * @returns {GeomVector}
   */
  subtract(v) {
    return new GeomVector(this.vx - v.vx, this.vy - v.vy);
  }
}

export default GeomVector;
