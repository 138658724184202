import { setMeasurementPointsOpen } from '@src/action/propMenu';
import ColorSelect from '@src/components/Modal/Element/ColorSelect';
import TabBar from '@src/components/PropMenu/TabBar';
import TabItem from '@src/components/PropMenu/TabItem';
import * as workData from '@src/data/WorkData';
import { Button, Tabs } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './measurement-modal.scss';

class ModalContainer extends React.Component {
  state = {
    tabKey: 'measurementPoints',
    isButtonDisabled: false,
  };

  componentDidMount() {
    const { object } = this.props;
    if (object.functype === 'TriangulationNetwork') {
      if (object.referencePoints.length < 2) {
        this.setState({ isButtonDisabled: true });
      }
    }
  }

  handleColor = evt => {
    var color = evt.currentTarget.id;

    const { object } = this.props;
    object.text.color = color;
    object.style.stroke = color;
    object.style.fill = color;

    workData.setObj(object.operateid, object);
    this.setState({ object: object });
    this.props.handle();
  };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    const { tabKey, isButtonDisabled } = this.state;
    const { setMeasurementPointsOpen, renderPointModal } = this.props;

    return (
      <div className="reg-no-select">
        <Tabs
          activeKey={tabKey}
          renderTabBar={() => (
            <TabBar>
              <TabItem
                name="measurementPoints"
                key="measurementPoints"
                tabKey={tabKey}
                onSwitchTab={this.onSwitchTab}
              />
              <TabItem name="style" key="style" tabKey={tabKey} onSwitchTab={this.onSwitchTab} />
            </TabBar>
          )}
        >
          <Tabs.TabPane tab="measurementPoints" key="measurementPoints">
            <div className="props-container">
              <div className="container-item">
                <span className="item-title-space" />
                <Button
                  onClick={() => setMeasurementPointsOpen(true)}
                  className="lncd-button"
                  style={{ padding: '0px 12px' }}
                  disabled={isButtonDisabled}
                >
                  {/* TODO: update tx key */}
                  {this.props.t('property.measurementTab.showPointsButtonText')}
                </Button>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="style" key="style">
            <div className="props-container">
              <div className="container-item">
                <span className="item-title">{this.props.t('property.styleTab.colorLabel')}:</span>
                <ColorSelect
                  iconType="fill"
                  colorHex={this.props.object.style.fill}
                  handleColorHex={this.handleColor}
                />
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
        {renderPointModal()}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMeasurementPointsOpen,
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(ModalContainer));
