import * as React from 'react';

function SvgIconNhtsa(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fillRule="nonzero" fill="currentColor">
        <path d="M23.997 15.331l-.003.656-1.811-.003v.325H24c-.005 0-.003.635-.003.635H.081v-.632h1.73v-.328H.086L.08 15.33h23.916zm-18.905.654H2.614v.327h2.478v-.327zm3.234 0H5.847v.327h2.479v-.327zm3.28 0H9.129v.327h2.479v-.327zm3.282 0h-2.479v.327h2.479v-.327zm3.234 0h-2.479v.327h2.479v-.327zm3.281 0h-2.478v.327h2.478v-.327zM21.041 11.024h-.014l-.346 1.013.697-.004-.337-1.009zm-.872 2.538l-.504 1.255h-1.514l2.111-5.489h1.558l2.067 5.49h-1.52l-.462-1.252-1.736-.004zM.028 9.35h1.427l2.614 3.357h.014V9.351H5.51v5.49H4.083l-.1-.13-2.513-3.234h-.015v3.363H.028zM17.71 10.729a1.468 1.468 0 00-.926-.356c-.257 0-.598.15-.598.449 0 .313.377.434.62.512l.355.107c.748.221 1.325.598 1.325 1.467 0 .535-.128 1.083-.556 1.446-.42.356-.982.506-1.523.506a3.315 3.315 0 01-1.888-.613l.599-1.125c.348.306.762.555 1.239.555.327 0 .676-.163.676-.54 0-.393-.548-.528-.847-.614-.876-.249-1.453-.476-1.453-1.516 0-1.09.776-1.802 1.851-1.802.542 0 1.204.171 1.68.442l-.554 1.082zM9.306 11.47V9.35h1.427v5.49H9.306v-2.047h-2.06v2.047H5.819V9.35h1.427v2.12zM13.495 14.84h-1.427v-4.28h-1.18V9.35h3.786v1.21h-1.179zM.537 8.812l.931-.651.907.683-.332-1.086.93-.652-1.135-.02L1.506 6l-.37 1.074L0 7.054l.907.684zM3.617 8.812l.93-.651.908.683-.332-1.086.93-.652-1.136-.02L4.585 6l-.37 1.074-1.135-.02.907.684zM6.697 8.812l.93-.651.908.683-.332-1.086.93-.652-1.136-.02L7.665 6l-.37 1.074-1.136-.02.908.684zM9.777 8.812l.93-.651.908.683-.333-1.086.931-.652-1.136-.02L10.745 6l-.37 1.074-1.136-.02.908.684zM12.857 8.812l.93-.651.908.683-.333-1.086.93-.652-1.135-.02L13.825 6l-.37 1.074-1.136-.02.908.684z" />
      </g>
    </svg>
  );
}

export default SvgIconNhtsa;
