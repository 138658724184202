import React from 'react';
import { TowLineAcrossPoint } from '@src/data/BusinessFun';
import { GetShapeStyle } from '@src/data/CommonProps';
import { GetCenter, LengthBetweenPoints, LinePointsLength, TowPointVerticalLength } from '@src/data/CommonFunc';
import ArrowLength from '../ArrowLength';
import { ANCHORS_TYPE, COLOR_TYPE, COUNTER_TOP_PARAM_TYPE } from '@src/constant';

class SelectCounterTop extends React.Component {
  constructor(props) {
    super(props);
  }

  getShapes = object => {
    var D = [];
    for (let i = 0; i < object.handlepoint.length; i++) {
      if (object.handlepoint[i][2] === ANCHORS_TYPE.COUNTERBEIGIN) {
        D.push('M' + String(object.handlepoint[1][0]) + ',' + String(object.handlepoint[1][1]));
        D.push('L' + String(object.handlepoint[2][0]) + ',' + String(object.handlepoint[2][1]));
      } else if (object.handlepoint[i][2] === ANCHORS_TYPE.COUNTERNORMAL) {
        if (i - 13 > 0) {
          var topAcrossLeft = TowLineAcrossPoint(
            [object.handlepoint[i - 13], object.handlepoint[i - 10]],
            [object.handlepoint[i - 2], object.handlepoint[i + 1]]
          );
          var bottomAcrossLeft = TowLineAcrossPoint(
            [object.handlepoint[i - 12], object.handlepoint[i - 9]],
            [object.handlepoint[i - 1], object.handlepoint[i + 2]]
          );
          var topAcrossRight = TowLineAcrossPoint(
            [object.handlepoint[i - 5], object.handlepoint[i - 2]],
            [object.handlepoint[i + 6], object.handlepoint[i + 9]]
          );
          var bottomAcrossRight = TowLineAcrossPoint(
            [object.handlepoint[i - 4], object.handlepoint[i - 1]],
            [object.handlepoint[i + 7], object.handlepoint[i + 10]]
          );
          D.push('M' + String(topAcrossLeft[0]) + ',' + String(topAcrossLeft[1]));
          D.push('L' + String(topAcrossRight[0]) + ',' + String(topAcrossRight[1]));
          D.push('M' + String(bottomAcrossLeft[0]) + ',' + String(bottomAcrossLeft[1]));
          D.push('L' + String(bottomAcrossRight[0]) + ',' + String(bottomAcrossRight[1]));
        } else {
          var topAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 5], object.handlepoint[i - 2]],
            [object.handlepoint[i + 6], object.handlepoint[i + 9]]
          );
          var bottomAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 4], object.handlepoint[i - 1]],
            [object.handlepoint[i + 7], object.handlepoint[i + 10]]
          );
          D.push('M' + String(object.handlepoint[i - 5][0]) + ',' + String(object.handlepoint[i - 5][1]));
          D.push('L' + String(topAcross[0]) + ',' + String(topAcross[1]));
          D.push('M' + String(object.handlepoint[i - 4][0]) + ',' + String(object.handlepoint[i - 4][1]));
          D.push('L' + String(bottomAcross[0]) + ',' + String(bottomAcross[1]));
        }
      } else if (object.handlepoint[i][2] == ANCHORS_TYPE.COUNTEREND) {
        if (object.handlepoint.length == 9) {
          D.push('M' + String(object.handlepoint[1][0]) + ',' + String(object.handlepoint[1][1]));
          D.push('L' + String(object.handlepoint[7][0]) + ',' + String(object.handlepoint[7][1]));
          D.push('M' + String(object.handlepoint[8][0]) + ',' + String(object.handlepoint[8][1]));
          D.push('L' + String(object.handlepoint[2][0]) + ',' + String(object.handlepoint[2][1]));
        } else {
          var topAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 13], object.handlepoint[i - 10]],
            [object.handlepoint[i - 2], object.handlepoint[i + 1]]
          );
          var bottomAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 12], object.handlepoint[i - 9]],
            [object.handlepoint[i - 1], object.handlepoint[i + 2]]
          );
          D.push('M' + String(topAcross[0]) + ',' + String(topAcross[1]));
          D.push('L' + String(object.handlepoint[i + 1][0]) + ',' + String(object.handlepoint[i + 1][1]));
          D.push('L' + String(object.handlepoint[i + 2][0]) + ',' + String(object.handlepoint[i + 2][1]));
          D.push('L' + String(bottomAcross[0]) + ',' + String(bottomAcross[1]));
        }
      }
    }
    return <path d={D.join(' ')} fill={COLOR_TYPE.NONE} />;
  };
  getCenterLine = anchors => {
    var D = [];
    for (let i = 0; i < anchors.length; i++) {
      if (anchors[i][2] === ANCHORS_TYPE.COUNTERBEIGIN) {
        D.push('M' + String(anchors[i][0]) + ',' + String(anchors[i][1]));
      } else if (anchors[i][2] === ANCHORS_TYPE.COUNTERNORMAL) {
        D.push('L' + String(anchors[i][0]) + ',' + String(anchors[i][1]));
      } else if (anchors[i][2] === ANCHORS_TYPE.COUNTEREND) {
        D.push('L' + String(anchors[i][0]) + ',' + String(anchors[i][1]));
      }
    }
    return <path d={D.join(' ')} />;
  };
  getAnchors = anchors => {
    var anchorsindex = 0;
    var shapes = anchors.map(point => {
      let id = String(anchorsindex++) + '||' + point[2];
      if (point[2] === ANCHORS_TYPE.COUNTERBEIGIN) {
        return (
          <rect
            id={id}
            key={id}
            x={point[0] - 5}
            y={point[1] - 5}
            width="10"
            height="10"
            rx="0"
            ry="0"
            {...GetShapeStyle().ANCHORSSTYPE}
          />
        );
      } else if (point[2] === ANCHORS_TYPE.COUNTEREND) {
        return (
          <rect
            id={id}
            key={id}
            x={point[0] - 5}
            y={point[1] - 5}
            width="10"
            height="10"
            rx="0"
            ry="0"
            {...GetShapeStyle().ANCHORSSTYPE}
          />
        );
      } else if (point[2] === ANCHORS_TYPE.COUNTERNORMAL) {
        let pointLeft = [];
        let idLeft = String(anchorsindex - 1) + '||' + ANCHORS_TYPE.COUNTERMOVELEFT;
        let idRight = String(anchorsindex - 1) + '||' + ANCHORS_TYPE.COUNTERMOVERIGHT;
        if (anchorsindex - 1 === 6) {
          pointLeft = LinePointsLength(anchors[0], point, COUNTER_TOP_PARAM_TYPE.COUNTERMOVE);
        } else {
          pointLeft = LinePointsLength(anchors[anchorsindex - 9], point, COUNTER_TOP_PARAM_TYPE.COUNTERMOVE);
        }
        let pointRight = LinePointsLength(anchors[anchorsindex + 7], point, COUNTER_TOP_PARAM_TYPE.COUNTERMOVE);

        return (
          <g key={id}>
            <TriangleHandlePoint id={idLeft} point={pointLeft} {...GetShapeStyle().ANCHORSSTYPE} />
            <TriangleHandlePoint id={idRight} point={pointRight} {...GetShapeStyle().ANCHORSSTYPE} />
            <rect
              id={id}
              x={point[0] - 5}
              y={point[1] - 5}
              width="10"
              height="10"
              rx="0"
              ry="0"
              {...GetShapeStyle().ANCHORSSTYPE}
            />
          </g>
        );
      } else if (point[2] === ANCHORS_TYPE.COUNTERTHREE) {
        return <TriangleHandlePoint key={id} id={id} point={point} {...GetShapeStyle().ANCHORSSTYPE} />;
      } else if (point[2] === ANCHORS_TYPE.CLOSEANGLE) {
        return (
          <circle
            id={ANCHORS_TYPE.CLOSEANGLE}
            key={id}
            cx={point[0]}
            cy={point[1]}
            r="5"
            {...GetShapeStyle().ANCHORSANGLESTYLE}
          />
        );
      }
    });
    return shapes;
  };
  getArrowsShape = object => {
    const ARROWSPACE = 10;
    const MIDDLEARROWSPACE = 8;
    var pointIndex = 0;
    var shapes = object.handlepoint.map(point => {
      let i = pointIndex;
      pointIndex++;

      if (point[2] === ANCHORS_TYPE.COUNTERNORMAL) {
        let lengthHeight = LengthBetweenPoints(object.handlepoint[i + 1], object.handlepoint[i + 2]);
        let arrowHeight = ARROWSPACE + lengthHeight / 2;
        if (i - 13 > 0) {
          var topAcrossLeft = TowLineAcrossPoint(
            [object.handlepoint[i - 13], object.handlepoint[i - 10]],
            [object.handlepoint[i - 2], object.handlepoint[i + 1]]
          );
          var bottomAcrossLeft = TowLineAcrossPoint(
            [object.handlepoint[i - 12], object.handlepoint[i - 9]],
            [object.handlepoint[i - 1], object.handlepoint[i + 2]]
          );
          var topAcrossRight = TowLineAcrossPoint(
            [object.handlepoint[i - 5], object.handlepoint[i - 2]],
            [object.handlepoint[i + 6], object.handlepoint[i + 9]]
          );
          var bottomAcrossRight = TowLineAcrossPoint(
            [object.handlepoint[i - 4], object.handlepoint[i - 1]],
            [object.handlepoint[i + 7], object.handlepoint[i + 10]]
          );
          var topArrowLeft = TowPointVerticalLength(
            object.handlepoint[i - 8],
            object.handlepoint[i],
            topAcrossLeft,
            arrowHeight,
            topAcrossLeft
          );
          var topArrowRight = TowPointVerticalLength(
            object.handlepoint[i - 8],
            object.handlepoint[i],
            topAcrossRight,
            arrowHeight,
            topAcrossRight
          );
          var bottomArrowLeft = TowPointVerticalLength(
            object.handlepoint[i - 8],
            object.handlepoint[i],
            bottomAcrossLeft,
            arrowHeight,
            bottomAcrossLeft
          );
          var bottomArrowRight = TowPointVerticalLength(
            object.handlepoint[i - 8],
            object.handlepoint[i],
            bottomAcrossRight,
            arrowHeight,
            bottomAcrossRight
          );
          var arrowLinesShape = (
            <g key={i}>
              <ArrowLength p1={topArrowLeft} p2={topArrowRight} istop={true} />
              <ArrowLength p1={bottomArrowLeft} p2={bottomArrowRight} istop={false} />
            </g>
          );
          return arrowLinesShape;
        } else {
          var topAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 5], object.handlepoint[i - 2]],
            [object.handlepoint[i + 6], object.handlepoint[i + 9]]
          );
          var bottomAcross = TowLineAcrossPoint(
            [object.handlepoint[i - 4], object.handlepoint[i - 1]],
            [object.handlepoint[i + 7], object.handlepoint[i + 10]]
          );
          var topArrowLeft = TowPointVerticalLength(
            object.handlepoint[i - 6],
            object.handlepoint[i],
            object.handlepoint[i - 5],
            arrowHeight,
            object.handlepoint[i - 5]
          );
          var topArrowRight = TowPointVerticalLength(
            object.handlepoint[i - 6],
            object.handlepoint[i],
            topAcross,
            arrowHeight,
            topAcross
          );
          var bottomArrowLeft = TowPointVerticalLength(
            object.handlepoint[i - 6],
            object.handlepoint[i],
            object.handlepoint[i - 4],
            arrowHeight,
            object.handlepoint[i - 4]
          );
          var bottomArrowRight = TowPointVerticalLength(
            object.handlepoint[i - 6],
            object.handlepoint[i],
            bottomAcross,
            arrowHeight,
            bottomAcross
          );
          var arrowLinesShape = (
            <g key={i}>
              <ArrowLength p1={topArrowLeft} p2={topArrowRight} istop={true} />
              <ArrowLength p1={bottomArrowLeft} p2={bottomArrowRight} istop={false} />
            </g>
          );
          return arrowLinesShape;
        }
      } else if (point[2] === ANCHORS_TYPE.COUNTEREND) {
        let lengthHeight = LengthBetweenPoints(object.handlepoint[i + 1], object.handlepoint[i + 2]);
        let arrowHeight = ARROWSPACE + lengthHeight / 2;
        var topAcross = TowLineAcrossPoint(
          [object.handlepoint[i - 13], object.handlepoint[i - 10]],
          [object.handlepoint[i - 2], object.handlepoint[i + 1]]
        );
        var bottomAcross = TowLineAcrossPoint(
          [object.handlepoint[i - 12], object.handlepoint[i - 9]],
          [object.handlepoint[i - 1], object.handlepoint[i + 2]]
        );
        var topArrowLeft = TowPointVerticalLength(
          object.handlepoint[i - 8],
          object.handlepoint[i],
          topAcross,
          arrowHeight,
          topAcross
        );
        var topArrowRight = TowPointVerticalLength(
          object.handlepoint[i - 8],
          object.handlepoint[i],
          object.handlepoint[i + 1],
          arrowHeight,
          object.handlepoint[i + 1]
        );
        var bottomArrowLeft = TowPointVerticalLength(
          object.handlepoint[i - 8],
          object.handlepoint[i],
          bottomAcross,
          arrowHeight,
          bottomAcross
        );
        var bottomArrowRight = TowPointVerticalLength(
          object.handlepoint[i - 8],
          object.handlepoint[i],
          object.handlepoint[i + 2],
          arrowHeight,
          object.handlepoint[i + 2]
        );
        var arrowLinesShape = (
          <g key={i}>
            <ArrowLength p2={topArrowLeft} p1={topArrowRight} istop={true} />
            <ArrowLength p2={bottomArrowLeft} p1={bottomArrowRight} istop={false} />
          </g>
        );
        return arrowLinesShape;
      } else if (point[2] === ANCHORS_TYPE.COUNTERMIDDLE) {
        var originIndex = i - 5;
        if (i == 3) {
          originIndex = 0;
        }
        var center = GetCenter(object.handlepoint[i], object.handlepoint[originIndex], 1 / 5);
        let length = LengthBetweenPoints(center, object.handlepoint[i]);
        if (length >= MIDDLEARROWSPACE) {
          let lengthHeight = LengthBetweenPoints(object.handlepoint[i + 1], object.handlepoint[i + 2]);
          var topArrow = TowPointVerticalLength(
            object.handlepoint[i],
            object.handlepoint[originIndex],
            center,
            lengthHeight / 2,
            object.handlepoint[i + 1]
          );
          var bottomArrow = TowPointVerticalLength(
            object.handlepoint[i],
            object.handlepoint[originIndex],
            center,
            lengthHeight / 2,
            object.handlepoint[i + 2]
          );
          return <ArrowLength key={i} p2={topArrow} p1={bottomArrow} />;
        }
      }
    });
    return shapes;
  };

  render() {
    const { props } = this;
    const { object } = props;
    if (object.handlepoint.length < 6) return null;
    return (
      <g>
        {this.getCenterLine(object.handlepoint)}
        {this.getShapes(object)}
        {this.getArrowsShape(object)}
        {this.getAnchors(object.handlepoint)}
      </g>
    );
  }
}

export default SelectCounterTop;
