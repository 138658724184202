import * as React from 'react';

function SvgIconXyMeasurement(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path d="M14.075 8.512l-5.563 5.563 1.413 1.414 5.563-5.563z" fill="currentColor" fillRule="nonzero" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M6.138 16L5 17.653 3.862 16H2l2.069 3L2 22h1.862l1.139-1.65L6.138 22H8l-2.069-3L8 16zM18.014 5.75V8h2V5.723L22 2h-2l-1 1.875L18 2h-2l2 3.75z"
        />
      </g>
    </svg>
  );
}

export default SvgIconXyMeasurement;
