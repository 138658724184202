import { devicePtToUserPt, getUserUnitByPixel } from '@src/data/CommonFunction';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { LENGTH_TYPE, ADD_IMAGE_LENGTH, MANAGE_FUNC_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';
import emitter from '@src/data/Event';

/**
 *
 * @param {string} src - base64
 * @param {function} onloadeddata
 */
export function ImportImg(src) {
  let img = new Image();
  img.onload = function() {
    if (img.width > 0 && img.height > 0) {
      let svg = document.getElementById('svg');
      let svgPoint = devicePtToUserPt(svg.clientWidth / 2, svg.clientHeight / 2);
      let sideLength = getUserUnitByPixel() * ADD_IMAGE_LENGTH.SIDELENGTH;
      let handlepoint = [
        svgPoint,
        [svgPoint[0] + img.width, svgPoint[1]],
        [svgPoint[0] + img.width, svgPoint[1] + img.height],
        [svgPoint[0], svgPoint[1] + img.height],
        [svgPoint[0] + img.width / 2, svgPoint[1]],
        [svgPoint[0] + img.width / 2, svgPoint[1] + img.height],
        [svgPoint[0], svgPoint[1] + img.height / 2],
        [svgPoint[0] + img.width, svgPoint[1] + img.height / 2],
        [svgPoint[0] + img.width + LENGTH_TYPE.ANGLELENGTH, svgPoint[1] + sideLength / 2],
        [svgPoint[0] + img.width + LENGTH_TYPE.ANGLELENGTH, svgPoint[1]],
        [svgPoint[0] + img.width + LENGTH_TYPE.ANGLELENGTH, svgPoint[1] + sideLength],
      ];
      workData.addImageData(handlepoint, src);
      emitter.emit(EVENT_EMIT_TYPE.MANAGEFUNC, MANAGE_FUNC_TYPE.ADDIMG);
    }
  };
  img.onerror = function(err) {
    console.error(err);
  };
  img.src = src;
}
