import GeomPoint from '@src/data/GeomPoint';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomLine from "@src/data/GeomLine";
import GeomArc from "@src/data/GeomArc";
import StreetBorder from '@src/data/StreetBorder';
import {
  ByTime,
  FindCircle,
  getAngleBy2Points,
  GetArcCenter,
  GetCenterInTwoPoints,
  GetCenter,
  GetCircleCenter2,
  GetCircleLineAcrossPoint,
  GetCirclePoint,
  GetLineKB,
  IsArcDirection,
  IsBigArc,
  IsEqual,
  IsReverse,
  IsZero,
  LengthArc1,
  LengthBetweenPoints,
  removeArray,
  RotatePoint,
  TowCircleAcrossPoints,
  TowPointDestPointLength,
  TowPointVerticalLength,
  TowPointVerticalLengthByStatus,
  GetCirclePointK,
  ScalePoint,
  GetCircleLineLength,
  IsArcRangePoint,
  TowPointVerticalAcross,
  GetTowPointRange,
  FindCircleLinePoint,
  IsPointValid,
  IsPointInRange,
  IsPointInRange1,
  FindCircle1,
  IsByTime,
} from '@src/data/CommonFunc';
import {
  AddStreetData,
  CopyPoint,
  GetPathArcD,
  GetPathArcD1,
  GetPathArcDL,
  GetStreetArcSideOutPoint,
  GetStreetSideOutPoint,
  TowLineAcrossPoint,
  IsRangePoint,
  GetLineHeightPoints,
} from '@src/data/BusinessFun';
import { HandlePointCrosswalk, HandlePointStreetAcross, HandlePointArc } from './HandlePoint';
import { offArcPoints } from './StreetOffArcFunHandle';
import _Street from '@src/data/_Street';
import {
  CIRCLE_SIZE_TYPE,
  CROSS_WALK_PATTERN,
  CROSS_WALK_SPACE,
  FUNCTION_TYPE,
  STREET_DIVIDER_TYPE,
  STREET_LINE_TYPE,
  STREET_POINT_TYPE,
  STREET_SPACE,
  THREE_POINT_STATUS_TYPE,
  SNAP_TO_STREET_STYLE,
} from '@src/constant';

import * as workData from '@src/data/WorkData';
import GeomVector from '@src/data/GeomVector';

//曲线下得到steettop三个点 //这里的height是 handlePoint[0]到topPoint的距离
export function GetStreetArcTopPoint(circle, height, p1, p2, p3, isByTime) {
  let bigCircle = [circle[0], circle[1], circle[2] + height];
  let smallCircle = [circle[0], circle[1], circle[2] - height];
  let topCircle = isByTime ? bigCircle : smallCircle;
  let angle1 = getAngleBy2Points(topCircle, p1);
  let point0 = GetCirclePoint(topCircle, angle1);
  let angle2 = getAngleBy2Points(topCircle, p2);
  let point1 = GetCirclePoint(topCircle, angle2);
  let angle3 = getAngleBy2Points(topCircle, p3);
  let point2 = GetCirclePoint(topCircle, angle3);
  return [point0, point1, point2];
}

//道路线到中心得距离
export function getStreetLaneHeight(laneWidth, lanes, lanesNum) {
  let heightLane = lanesNum;
  if (!IsZero(lanesNum % 2)) {
    heightLane = lanesNum - 1;
  }
  var laneHeight = ((lanes - heightLane) * laneWidth) / 2;
  return laneHeight;
}

export function GetAcrossStreetPoint(leftPoints, rightPoints, isTop) {
  let leftCircle = FindCircle(leftPoints[0], leftPoints[1], leftPoints[2]);
  let rightCircle = FindCircle(rightPoints[0], rightPoints[1], rightPoints[2]);
  let acrossPoint = [];
  if (IsZero(leftCircle[2]) && IsZero(rightCircle[2])) {
    acrossPoint = TowLineAcrossPoint([leftPoints[0], leftPoints[2]], [rightPoints[0], rightPoints[2]]);
    let lineLeft = GetLineKB(leftPoints[0], leftPoints[2]);
    let lineRight = GetLineKB(rightPoints[0], rightPoints[2]);
    if (IsEqual(lineLeft[0], lineRight[0])) {
      acrossPoint = leftPoints[2];
    }
  } else if (IsZero(leftCircle[2]) && !IsZero(rightCircle[2])) {
    let lineKB = GetLineKB(leftPoints[0], leftPoints[2]);
    let points = GetCircleLineAcrossPoint(rightCircle, lineKB);
    if (LengthBetweenPoints(leftPoints[2], points[0]) < LengthBetweenPoints(leftPoints[2], points[1])) {
      acrossPoint = points[0];
    } else {
      acrossPoint = points[1];
    }
  } else if (!IsZero(leftCircle[2]) && IsZero(rightCircle[2])) {
    let lineKB = GetLineKB(rightPoints[0], rightPoints[2]);
    let points = GetCircleLineAcrossPoint(leftCircle, lineKB);
    if (LengthBetweenPoints(leftPoints[2], points[0]) < LengthBetweenPoints(leftPoints[2], points[1])) {
      acrossPoint = points[0];
    } else {
      acrossPoint = points[1];
    }
  } else {
    //圆和圆
    let points = TowCircleAcrossPoints(leftCircle, rightCircle);
    let nearPoint = [],
      awayPoint = [];
    if (LengthBetweenPoints(leftPoints[2], points[0]) < LengthBetweenPoints(leftPoints[2], points[1])) {
      nearPoint = points[0];
      awayPoint = points[1];
    } else {
      nearPoint = points[1];
      awayPoint = points[0];
    }
    let isBytimeLeft = ByTime(leftPoints[0], leftPoints[1], leftPoints[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let isBytimeRight = ByTime(rightPoints[0], rightPoints[1], rightPoints[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    if (isBytimeLeft && isBytimeRight) {
      if (isTop) acrossPoint = nearPoint;
      else acrossPoint = awayPoint;
    } else if (!isBytimeLeft && !isBytimeRight) {
      if (isTop) acrossPoint = awayPoint;
      else acrossPoint = nearPoint;
    } else {
      acrossPoint = nearPoint;
    }
  }
  return acrossPoint;
}

//求取道路线上得三个点
export function getStreetLanePoint(anchors, index, laneHeight, isTopLane) {
  let begin = anchors[index];
  let centerindex = anchors[index][3].rightindex;
  let center = anchors[centerindex];
  let endindex = center[3].rightindex;
  let endpoint = anchors[endindex];
  let circle = FindCircle(begin, center, endpoint);
  var laneBegin = [],
    laneCenter = [],
    laneEnd = [];
  //中线下面得道路高度相反
  let streetHeight = laneHeight;
  if (isTopLane) streetHeight = -laneHeight;
  if (IsZero(circle[2])) {
    let angle = getAngleBy2Points(begin, endpoint);
    let length = LengthBetweenPoints(begin, endpoint);
    laneBegin = [begin[0], begin[1] + streetHeight];
    laneCenter = [begin[0] + length / 2, begin[1] + streetHeight];
    laneEnd = [begin[0] + length, begin[1] + streetHeight];
    laneBegin = RotatePoint(laneBegin, angle, begin);
    laneCenter = RotatePoint(laneCenter, angle, begin);
    laneEnd = RotatePoint(laneEnd, angle, begin);
  } else {
    let isBytime = ByTime(begin, center, endpoint) === THREE_POINT_STATUS_TYPE.BYTIME;
    let arcPoints = GetStreetArcTopPoint(circle, -streetHeight, begin, center, endpoint, isBytime);
    laneBegin = arcPoints[0];
    laneCenter = arcPoints[1];
    laneEnd = arcPoints[2];
  }
  laneBegin[2] = begin[2];
  laneBegin[3] = JSON.parse(JSON.stringify(begin[3]));
  laneCenter[2] = center[2];
  laneCenter[3] = JSON.parse(JSON.stringify(center[3]));
  laneEnd[2] = endpoint[2];
  laneEnd[3] = JSON.parse(JSON.stringify(endpoint[3]));
  return [laneBegin, laneCenter, laneEnd];
}

//根据路线号判断是否是在中线上面
export function IsOnTop(laneNum) {
  return IsZero(laneNum % 2);
}

export function GetStreetAreaBegin(anchors) {
  let beiginIndex = 0;
  if (typeof anchors == 'undefined') {
    return -1;
  }
  for (let i = 0; i < anchors.length; i++) {
    if (anchors[i][2] === STREET_POINT_TYPE.NORMAL && anchors[i][3].leftIndex === -1) {
      beiginIndex = i;
      break;
    }
  }
  return beiginIndex;
}

export function GetStreetAreaEnd(anchors) {
  let endIndex = 0;
  if (typeof anchors == 'undefined') {
    return -1;
  }
  for (let i = 0; i < anchors.length; i++) {
    if (anchors[i][2] === STREET_POINT_TYPE.NORMAL && anchors[i][3].rightindex === -1) {
      endIndex = i;
      break;
    }
  }
  return endIndex;
}
export function GetStreetAreaLength(anchors) {
  let i = GetStreetAreaBegin(anchors);
  let length = 1;
  while (i != -1 && anchors[i][3].rightindex != -1 && i != null) {
    length = length + 2;
    let centerIndex = anchors[i][3].rightindex;
    let endIndex = anchors[centerIndex][3].rightindex;
    i = endIndex;
  }

  return length;
}
export function GetStreetLineBegin(streetGroupdata) {
  let begin = 0;
  for (let i = 0; i < streetGroupdata.length; i++) {
    if (streetGroupdata[i].topline === -1) begin = i;
  }
  return begin;
}
export function GetStreetLineEnd(streetGroupdata) {
  let end = 1;
  return end;
}
export function GetStreetLanePathD(D, object, laneNum) {
  let anchors = object.handlepoint;
  let laneHeight = getStreetLaneHeight(object.lanewidth, object.lanes.length, laneNum);
  let isTop = IsZero(laneNum % 2);
  //先找到头，头部特征为leftIndex==-1
  let beginIndex = GetStreetAreaBegin(anchors);
  let i = beginIndex;
  while (i != -1 && i != null && anchors[i][3].rightindex != -1) {
    let lanePoints = getStreetLanePoint(anchors, i, laneHeight, isTop);
    let begin = lanePoints[0];
    let center = lanePoints[1];
    let endpoint = lanePoints[2];
    //与上一个区域的交点
    let beforeIndex = anchors[i][3].leftIndex;
    if (beforeIndex != -1) {
      let beforeCenterIndex = anchors[beforeIndex][3].leftIndex;
      let beforeLanePoints = getStreetLanePoint(anchors, beforeCenterIndex, laneHeight, isTop);
      begin = GetAcrossStreetPoint(beforeLanePoints, lanePoints, isTop);
    }
    //与下一个区域的交点
    let centerIndex = anchors[i][3].rightindex;
    let nextIndex = anchors[centerIndex][3].rightindex;
    if (anchors[nextIndex][3].rightindex != -1) {
      let nextLanePoints = getStreetLanePoint(anchors, nextIndex, laneHeight, isTop);
      endpoint = GetAcrossStreetPoint(lanePoints, nextLanePoints, isTop);
    }
    let circle = FindCircle(begin, center, endpoint);
    let isBigArc = IsBigArc(begin, endpoint, center, circle[2]);
    let isArcDirection = IsArcDirection(begin, center, endpoint);
    if (i === beginIndex) GetPathArcD(D, begin, endpoint, circle[2], isBigArc, isArcDirection);
    else GetPathArcD1(D, endpoint, circle[2], isBigArc, isArcDirection);
    i = nextIndex;
  }
}

//根据区域得到背景范围
export function GetStreetLanesArea(D1, D2, anchorsTop, anchorsBottom, index) {
  //求顶部道路线
  let topCenterIndex = anchorsTop[index][3].rightindex;
  let topCenter = anchorsTop[topCenterIndex];
  let topEndIndex = topCenter[3].rightindex;
  let topPoints = [anchorsTop[index], topCenter, anchorsTop[topEndIndex]];
  let topCircle = FindCircle(topPoints[0], topPoints[1], topPoints[2]);
  let topIsArcDrection = IsArcDirection(topPoints[0], topPoints[1], topPoints[2]);
  //求底部道路线
  let bottomCenterIndex = anchorsBottom[index][3].rightindex;
  let bottomCenter = anchorsBottom[bottomCenterIndex];
  let bottomEndIndex = bottomCenter[3].rightindex;
  let bottomPoints = [anchorsBottom[index], bottomCenter, anchorsBottom[bottomEndIndex]];
  let bottomCircle = FindCircle(bottomPoints[0], bottomPoints[1], bottomPoints[2]);
  let bottomIsArcDirection = IsArcDirection(bottomPoints[2], bottomPoints[1], bottomPoints[0]);
  GetPathArcD(D1, topPoints[0], topPoints[1], topCircle[2], CIRCLE_SIZE_TYPE.SMALLARC, topIsArcDrection);
  GetPathArcDL(D1, bottomPoints[1], bottomPoints[0], bottomCircle[2], CIRCLE_SIZE_TYPE.SMALLARC, bottomIsArcDirection);
  D1.push('Z');
  GetPathArcD(D2, topPoints[1], topPoints[2], topCircle[2], CIRCLE_SIZE_TYPE.SMALLARC, topIsArcDrection);
  GetPathArcDL(D2, bottomPoints[2], bottomPoints[1], bottomCircle[2], CIRCLE_SIZE_TYPE.SMALLARC, bottomIsArcDirection);
  D2.push('Z');
}

//根据street 区域添加 区域 split segment
//index 为center index
export function SplitSegmentStreet(objid, index) {
  let obj = workData.getObject(objid);
  if (obj === null || index < 0 || index >= obj.handlepoint.length) return false;
  let iCount = obj.handlepoint.length;
  let leftIndex = obj.handlepoint[index][3].leftIndex;
  let rightIndex = obj.handlepoint[index][3].rightindex;
  let circle = FindCircle(obj.handlepoint[leftIndex], obj.handlepoint[index], obj.handlepoint[rightIndex]);
  let leftPoint = [],
    rightPoint = [];
  if (IsZero(circle[2])) {
    leftPoint = GetCenterInTwoPoints(obj.handlepoint[leftIndex], obj.handlepoint[index]);
    rightPoint = GetCenterInTwoPoints(obj.handlepoint[index], obj.handlepoint[rightIndex]);
  } else {
    let isBytime =
      ByTime(obj.handlepoint[leftIndex], obj.handlepoint[index], obj.handlepoint[rightIndex]) ==
      THREE_POINT_STATUS_TYPE.BYTIME;
    leftPoint = GetArcCenter(circle, obj.handlepoint[leftIndex], obj.handlepoint[index], 1 / 2, isBytime);
    rightPoint = GetArcCenter(circle, obj.handlepoint[index], obj.handlepoint[rightIndex], 1 / 2, isBytime);
  }
  leftPoint[2] = STREET_POINT_TYPE.CENTER;
  leftPoint[3] = AddStreetData(leftIndex, index);
  rightPoint[2] = STREET_POINT_TYPE.CENTER;
  rightPoint[3] = AddStreetData(index, rightIndex);
  //修改当前点变成normal
  obj.handlepoint[index][2] = STREET_POINT_TYPE.NORMAL;
  obj.handlepoint[iCount] = leftPoint;
  obj.handlepoint[iCount + 1] = rightPoint;
  obj.handlepoint[leftIndex][3].rightindex = iCount;
  obj.handlepoint[index][3] = AddStreetData(iCount, iCount + 1);
  obj.handlepoint[rightIndex][3].leftIndex = iCount + 1;
  return true;
}
export function MergeSegmentStreet(objid, position, index) {
  let obj = workData.getObject(objid);
  if (obj === null || index < 0 || index >= obj.handlepoint.length) return false;
  let iCount = obj.handlepoint.length;
  let anchors = obj.handlepoint;
  //2 根据当前的center点计算 合并后形状
  let leftIndex = anchors[index][3].leftIndex;
  let rightIndex = anchors[index][3].rightindex;
  if (leftIndex === -1 || rightIndex === -1) return false;
  //1 根据方位找到normal点，left right
  let destNormalIndex = -1;
  if (position === 'left') {
    destNormalIndex = anchors[index][3].leftIndex;
  } else if (position === 'right') {
    destNormalIndex = anchors[index][3].rightindex;
  }
  if (destNormalIndex === -1) return false;
  let circle = FindCircle(anchors[leftIndex], anchors[index], anchors[rightIndex]);
  if (IsZero(circle[2])) {
    if (position === 'left') {
      let destCenterIndex = anchors[destNormalIndex][3].leftIndex;
      let destBeginIndex = anchors[destCenterIndex][3].leftIndex;
      let center = GetCenterInTwoPoints(anchors[destBeginIndex], anchors[rightIndex]);
      CopyPoint(anchors[destNormalIndex], center);
      anchors[destNormalIndex][2] = STREET_POINT_TYPE.CENTER;
      anchors[destNormalIndex][3].leftIndex = destBeginIndex;
      anchors[destNormalIndex][3].rightindex = rightIndex;
      anchors[destBeginIndex][3].rightindex = destNormalIndex;
      anchors[rightIndex][3].leftIndex = destNormalIndex;
    } else {
      let destCenterIndex = anchors[destNormalIndex][3].rightindex;
      let destEndIndex = anchors[destCenterIndex][3].rightindex;
      let center = GetCenterInTwoPoints(anchors[destEndIndex], anchors[leftIndex]);
      CopyPoint(anchors[destNormalIndex], center);
      anchors[destNormalIndex][2] = STREET_POINT_TYPE.CENTER;
      anchors[destNormalIndex][3].leftIndex = leftIndex;
      anchors[destNormalIndex][3].rightindex = destEndIndex;
      anchors[leftIndex][3].rightindex = destNormalIndex;
      anchors[destEndIndex][3].leftIndex = destNormalIndex;
    }
  } else {
    let isBytime = ByTime(anchors[leftIndex], anchors[index], anchors[rightIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let length = TowPointDestPointLength(anchors[leftIndex], anchors[rightIndex], anchors[index]);
    if (position === 'left') {
      let destCenterIndex = anchors[destNormalIndex][3].leftIndex;
      let destBeginIndex = anchors[destCenterIndex][3].leftIndex;
      let center = GetCircleCenter2(circle, anchors[destBeginIndex], anchors[rightIndex], length, !isBytime);
      CopyPoint(anchors[destNormalIndex], center);
      anchors[destNormalIndex][2] = STREET_POINT_TYPE.CENTER;
      anchors[destNormalIndex][3].leftIndex = destBeginIndex;
      anchors[destNormalIndex][3].rightindex = rightIndex;
      anchors[destBeginIndex][3].rightindex = destNormalIndex;
      anchors[rightIndex][3].leftIndex = destNormalIndex;
    } else {
      let destCenterIndex = anchors[destNormalIndex][3].rightindex;
      let destEndIndex = anchors[destCenterIndex][3].rightindex;
      let center = GetCircleCenter2(circle, anchors[leftIndex], anchors[destEndIndex], length, !isBytime);
      CopyPoint(anchors[destNormalIndex], center);
      anchors[destNormalIndex][2] = STREET_POINT_TYPE.CENTER;
      anchors[destNormalIndex][3].leftIndex = leftIndex;
      anchors[destNormalIndex][3].rightindex = destEndIndex;
      anchors[leftIndex][3].rightindex = destNormalIndex;
      anchors[destEndIndex][3].leftIndex = destNormalIndex;
    }
  }

  return true;
}
export function DeleteSegmentStreet(objid, index) {
  let obj = workData.getObject(objid);
  if (obj === null || index < 0 || index >= obj.handlepoint.length) return false;
  let anchors = obj.handlepoint;
  if (GetStreetAreaLength(anchors) <= 3) return false;
  //2 根据当前的center点计算 合并后形状
  let leftIndex = anchors[index][3].leftIndex;
  let rightIndex = anchors[index][3].rightindex;
  if (anchors[leftIndex][3].leftIndex === -1) {
    //删除节点设置为-5
    anchors[leftIndex][3] = AddStreetData(-5, -5);
    anchors[rightIndex][3].leftIndex = -1;
    //重新定义streetbeigin 3节点
    let rightCenterIndex = anchors[rightIndex][3].rightindex;
    let rightEndIndex = anchors[rightCenterIndex][3].rightindex;
    let circle = FindCircle(anchors[rightIndex], anchors[rightCenterIndex], anchors[rightEndIndex]);
    if (IsZero(circle[2])) {
      let point3 = GetStreetSideOutPoint(anchors[rightIndex], anchors[rightEndIndex]);
      CopyPoint(anchors[3], point3);
    } else {
      let isBytime =
        ByTime(anchors[rightIndex], anchors[rightCenterIndex], anchors[rightEndIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME; //顺时针
      let point3 = GetStreetArcSideOutPoint(circle, anchors[rightIndex], isBytime);
      CopyPoint(anchors[3], point3);
    }
  } else if (anchors[rightIndex][3].rightindex === -1) {
    anchors[rightIndex][3] = AddStreetData(-5, -5);
    anchors[leftIndex][3].rightindex = -1;
    let leftCenter = anchors[leftIndex][3].leftIndex;
    let leftEndIndex = anchors[leftCenter][3].leftIndex;
    let circle = FindCircle(anchors[leftEndIndex], anchors[leftCenter], anchors[leftIndex]);
    if (IsZero(circle[2])) {
      let point4 = GetStreetSideOutPoint(anchors[leftIndex], anchors[leftEndIndex]);
      CopyPoint(anchors[4], point4);
    } else {
      let isBytime =
        ByTime(anchors[leftEndIndex], anchors[leftCenter], anchors[leftIndex]) == THREE_POINT_STATUS_TYPE.BYTIME; //顺时针
      let point4 = GetStreetArcSideOutPoint(circle, anchors[leftIndex], !isBytime);
      CopyPoint(anchors[4], point4);
    }
  } else return false;

  return true;
}
export function SetLineStreet(objid) {
  let obj = workData.getObject(objid);
  if (obj === null) return false;
  obj.streetoffarc = false;
  let anchors = obj.handlepoint;
  let iCount = anchors.length;
  let beginIndex = GetStreetAreaBegin(anchors);
  let endIndex = GetStreetAreaEnd(anchors);
  let center = GetCenterInTwoPoints(anchors[beginIndex], anchors[endIndex]);
  anchors[0] = [anchors[beginIndex][0], anchors[beginIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(-1, 1)];
  anchors[1] = [center[0], center[1], STREET_POINT_TYPE.NORMAL, AddStreetData(0, 2)];
  anchors[2] = [anchors[endIndex][0], anchors[endIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(1, -1)];
  anchors[3] = GetStreetSideOutPoint(anchors[0], anchors[2]);
  anchors[4] = GetStreetSideOutPoint(anchors[2], anchors[0]);
  //4为右边streetend点
  removeArray(anchors, 5);
  return true;
}

export function SetArcStreet(objid) {
  let obj = workData.getObject(objid);
  if (obj === null) return false;
  obj.streetoffarc = false;
  let anchors = obj.handlepoint;
  let iCount = anchors.length;
  let beginIndex = GetStreetAreaBegin(anchors);
  let endIndex = GetStreetAreaEnd(anchors);
  let center = GetCenterInTwoPoints(anchors[beginIndex], anchors[endIndex]);
  anchors[0] = [anchors[beginIndex][0], anchors[beginIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(-1, 1)];
  anchors[1] = [center[0], center[1], STREET_POINT_TYPE.CENTER, AddStreetData(0, 2)];
  anchors[2] = [anchors[endIndex][0], anchors[endIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(1, -1)];
  anchors[3] = GetStreetSideOutPoint(anchors[0], anchors[2]);
  anchors[4] = GetStreetSideOutPoint(anchors[2], anchors[0]);
  //4为右边streetend点
  removeArray(anchors, 5);
  return true;
}
export function SetDoubleArcStreet(objid) {
  let obj = workData.getObject(objid);
  if (obj === null) return false;
  obj.streetoffarc = false;
  let anchors = obj.handlepoint;
  let iCount = anchors.length;
  let beginIndex = GetStreetAreaBegin(anchors);
  let endIndex = GetStreetAreaEnd(anchors);
  let center = GetCenterInTwoPoints(anchors[beginIndex], anchors[endIndex]);
  anchors[0] = [anchors[beginIndex][0], anchors[beginIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(-1, 5)];
  anchors[1] = [center[0], center[1], STREET_POINT_TYPE.NORMAL, AddStreetData(5, 6)];
  anchors[2] = [anchors[endIndex][0], anchors[endIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(6, -1)];
  let center01 = GetCenterInTwoPoints(anchors[0], anchors[1]);
  anchors[5] = [center01[0], center01[1], STREET_POINT_TYPE.CENTER, AddStreetData(0, 1)];
  let center12 = GetCenterInTwoPoints(anchors[1], anchors[2]);
  anchors[6] = [center12[0], center12[1], STREET_POINT_TYPE.CENTER, AddStreetData(1, 2)];
  anchors[3] = GetStreetSideOutPoint(anchors[0], anchors[2]);
  anchors[4] = GetStreetSideOutPoint(anchors[2], anchors[0]);
  //4为右边streetend点
  removeArray(anchors, 7);
  return true;
}

export function SetTripleArcStreet(objid) {
  let obj = workData.getObject(objid);
  if (obj === null) return false;
  obj.streetoffarc = false;
  let anchors = obj.handlepoint;
  let iCount = anchors.length;
  let beginIndex = GetStreetAreaBegin(anchors);
  let endIndex = GetStreetAreaEnd(anchors);
  let center = GetCenterInTwoPoints(anchors[beginIndex], anchors[endIndex]);
  anchors[0] = [anchors[beginIndex][0], anchors[beginIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(-1, 5)];
  anchors[1] = [center[0], center[1], STREET_POINT_TYPE.CENTER, AddStreetData(7, 8)];
  anchors[2] = [anchors[endIndex][0], anchors[endIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(6, -1)];
  let center5 = GetCenter(anchors[0], anchors[1], 1 / 3);
  anchors[5] = [center5[0], center5[1], STREET_POINT_TYPE.CENTER, AddStreetData(0, 7)];
  let center7 = GetCenter(anchors[0], anchors[1], 2 / 3);
  anchors[7] = [center7[0], center7[1], STREET_POINT_TYPE.NORMAL, AddStreetData(5, 1)];
  let center6 = GetCenter(anchors[1], anchors[2], 2 / 3);
  anchors[6] = [center6[0], center6[1], STREET_POINT_TYPE.CENTER, AddStreetData(8, 2)];
  let center8 = GetCenter(anchors[1], anchors[2], 1 / 3);
  anchors[8] = [center8[0], center8[1], STREET_POINT_TYPE.NORMAL, AddStreetData(1, 6)];
  anchors[3] = GetStreetSideOutPoint(anchors[0], anchors[2]);
  anchors[4] = GetStreetSideOutPoint(anchors[2], anchors[0]);
  //4为右边streetend点
  removeArray(anchors, 9);
  return true;
}

export function SetOffSetArcStreet(objid) {
  let obj = workData.getObject(objid);
  if (obj === null) return false;
  obj.streetoffarc = true;
  obj.offarccenter = 1 / 2;
  obj.offarcheight = STREET_SPACE.OFFSETARCSPACE;
  let anchors = obj.handlepoint;
  let beginIndex = GetStreetAreaBegin(anchors);
  let endIndex = GetStreetAreaEnd(anchors);
  let center = GetCenterInTwoPoints(anchors[beginIndex], anchors[endIndex]);
  anchors[0] = [anchors[beginIndex][0], anchors[beginIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(-1, 1)];
  anchors[1] = [center[0], center[1], STREET_POINT_TYPE.CENTER, AddStreetData(0, 2)];
  anchors[2] = [anchors[endIndex][0], anchors[endIndex][1], STREET_POINT_TYPE.NORMAL, AddStreetData(1, -1)];
  anchors[3] = GetStreetSideOutPoint(anchors[0], anchors[2]);
  anchors[4] = GetStreetSideOutPoint(anchors[2], anchors[0]);
  anchors[5] = [anchors[1][0], anchors[1][1]];
  anchors[6] = TowPointVerticalLengthByStatus(anchors[0], anchors[2], anchors[0], STREET_SPACE.OFFSETARCSPACE, true);
  anchors[7] = TowPointVerticalLengthByStatus(anchors[0], anchors[2], anchors[2], STREET_SPACE.OFFSETARCSPACE, false);
  //4为右边streetend点
  removeArray(anchors, 8);
  return true;
}
export function GetBikeLaneCount(streetobjgroupdata) {
  let count = 0;
  for (let i = 0; i < streetobjgroupdata.length; i++) {
    if (streetobjgroupdata[i].topline != -5 && streetobjgroupdata[i].streetlinetype === STREET_LINE_TYPE.BIKE)
      count += 1;
  }
  return count;
}
export function GetStreetLineCount(streetObjgroudata) {
  let lineIndex = GetStreetLineBegin(streetObjgroudata);
  let iCount = 0;
  while (lineIndex >= 0 && lineIndex < streetObjgroudata.length) {
    iCount = iCount + 1;
    lineIndex = streetObjgroudata[lineIndex].bottomline;
  }
  return iCount;
}
/**
 *得到2way painted wide特殊分类的索引
 *
 * @export
 * @param {*} streetObjGroupData
 * @returns
 */
export function GetSpecialLineIndex(streetObjGroupData) {
  let index = -1;
  for (let i = 0; i < streetObjGroupData.length; i++) {
    if (
      streetObjGroupData[i].streetlinetype === STREET_DIVIDER_TYPE.TWO_WAY ||
      streetObjGroupData[i].streetlinetype === STREET_DIVIDER_TYPE.WIDE ||
      streetObjGroupData[i].streetlinetype === STREET_DIVIDER_TYPE.PAINTED
    )
      index = i;
  }
  return index;
}

//求取 特别高度 ，2 way-left wide painted 只能存在一个
export function GetLaneSpecialHeight(streetobjgroupdata) {
  let height = 0;
  for (let i = 0; i < streetobjgroupdata.length; i++) {
    if (streetobjgroupdata[i].topline == -5) continue;
    if (streetobjgroupdata[i].streetlinetype === STREET_LINE_TYPE.TWOWAY) height = STREET_SPACE.TWOWAY;
    else if (streetobjgroupdata[i].streetlinetype === STREET_LINE_TYPE.PAINTED) height = STREET_SPACE.PAINTED;
    else if (streetobjgroupdata[i].streetlinetype === STREET_LINE_TYPE.WIDE) height = STREET_SPACE.WIDE;
  }
  return height;
}
//道路最上方到最下方得距离
export function GetMaxHeight(streetobj) {
  // console.log(streetobj)
  let laneData = streetobj.groupdata;
  let specialHeight = GetLaneSpecialHeight(laneData) * 2;
  let specialCount = IsZero(specialHeight) ? 0 : 1;
  let maxHeight = specialHeight;
  for (let i = 0; i < streetobj.lanes.length; i++) {
    maxHeight = maxHeight + streetobj.lanes[i].width;
  }
  return maxHeight;
}
//得到最上面得点，或者最下面得点
export function GetStreetMaxPoints(object, topHeight, isTop) {
  let anchors = object.handlepoint;
  let topPoints = [];
  //先找到头，头部特征为leftIndex==-1
  let beginIndex = GetStreetAreaBegin(anchors);
  let i = beginIndex;
  topPoints[3] = anchors[3];
  topPoints[4] = anchors[4];
  while (i != -1 && i != null && anchors[i][3] && anchors[i][3].rightindex != -1) {
    let lanePoints = getStreetLanePoint(anchors, i, topHeight, isTop);
    let begin = lanePoints[0];
    let center = lanePoints[1];
    let endpoint = lanePoints[2];
    // 与下一个区域
    let centerIndex = anchors[i][3].rightindex;
    let nextIndex = anchors[centerIndex][3].rightindex;
    topPoints[i] = begin;
    topPoints[centerIndex] = center;
    topPoints[nextIndex] = endpoint;
    i = nextIndex;
  }
  return topPoints;
}
//
export function GetStreetLinePoints(anchors, height, isTop) {
  let points = [];
  //先找到头，头部特征为leftIndex==-1
  let beginIndex = GetStreetAreaBegin(anchors);
  let i = beginIndex;
  points[3] = anchors[3];
  points[4] = anchors[4];
  while (i != -1 && i != null && anchors[i][3].rightindex != -1) {
    let lanePoints = getStreetLanePoint(anchors, i, height, isTop);
    let begin = lanePoints[0];
    let center = lanePoints[1];
    let endpoint = lanePoints[2];
    //与下一个区域
    let centerIndex = anchors[i][3].rightindex;
    let nextIndex = anchors[centerIndex][3].rightindex;
    points[i] = begin;
    points[centerIndex] = center;
    points[nextIndex] = endpoint;
    i = nextIndex;
  }
  return points;
}

//根据道路线得锚点 得到D
export function GetStreetLinePathD(D, anchors) {
  let beginIndex = GetStreetAreaBegin(anchors);
  let i = beginIndex;
  while (i != -1 && i != null && anchors[i][3].rightindex != -1) {
    let begin = anchors[i];
    let center = anchors[begin[3].rightindex];
    let endpoint = anchors[center[3].rightindex];
    let circle = FindCircle(begin, center, endpoint);
    let isBigArc = IsBigArc(begin, endpoint, center, circle[2]);
    let isArcDirection = IsArcDirection(begin, center, endpoint);
    if (i === beginIndex) GetPathArcD(D, begin, endpoint, circle[2], isBigArc, isArcDirection);
    else GetPathArcD1(D, endpoint, circle[2], isBigArc, isArcDirection);
    i = center[3].rightindex;
  }
}

/**
 * Dirt得到 曲线边界
 * @param {string} D
 * @param {*} anchors
 */
export function GetDirtLinePathD(D, anchors) {
  const SPACE = 18;
  let beginIndex = GetStreetAreaBegin(anchors);
  let i = beginIndex;
  while (i != -1 && i != null && anchors[i][3].rightindex != -1) {
    let begin = anchors[i];
    let center = anchors[begin[3].rightindex];
    let endpoint = anchors[center[3].rightindex];
    let circle = FindCircle(begin, center, endpoint);
    let lintPoints = [];
    if (i === beginIndex) D.push('M' + String(begin[0]) + ',' + String(begin[1]));
    else D.push('L' + String(begin[0]) + ',' + String(begin[1]));
    if (IsZero(circle[2])) {
      let n = LengthBetweenPoints(begin, endpoint) / SPACE;
      let seed = 1;
      let getSeed = function() {
        seed = Math.sin(seed) * 10000;
        seed -= Math.floor(seed);
        return seed;
      };
      for (let i = 1; i <= n; i++) {
        let point = GetCenter(begin, endpoint, i / n);
        if (i % 2 == 0) point = [point[0] + 1, point[1] + 1];
        else point = [point[0] - 1, point[1] - 1];
        lintPoints.push(point);
      }
    } else {
      let isBytime = ByTime(begin, center, endpoint) === THREE_POINT_STATUS_TYPE.BYTIME;
      let n = LengthArc1(begin, endpoint, circle, isBytime) / SPACE;
      for (let i = 1; i <= n; i++) {
        let point = GetArcCenter(circle, begin, endpoint, i / n, isBytime);
        if (i % 2 == 0) point = [point[0] + 1, point[1] + 1];
        else point = [point[0] - 1, point[1] - 1];
        lintPoints.push(point);
      }
    }
    for (let i = 0; i < lintPoints.length; i++) {
      D.push('L' + String(lintPoints[i][0]) + ',' + String(lintPoints[i][1]));
    }
    i = center[3].rightindex;
  }
}

export function GetStreetBeginTopPoint(linedata, beginPoint, centerPoint, endPoint) {
  let circle = FindCircle(beginPoint, centerPoint, endPoint);
  let topArcPoint = [];
  if (IsZero(circle[2])) {
    topArcPoint = TowPointVerticalLengthByStatus(
      beginPoint,
      endPoint,
      beginPoint,
      linedata.topleftr,
      !linedata.upstate
    );
  } else {
    let isByTime = ByTime(beginPoint, centerPoint, endPoint) === THREE_POINT_STATUS_TYPE.BYTIME;
    let lengthBegin = LengthBetweenPoints(beginPoint, circle);
    let scale = linedata.upstate
      ? (lengthBegin + linedata.topleftr) / lengthBegin
      : (lengthBegin - linedata.topleftr) / lengthBegin;
    if (!isByTime) {
      scale = linedata.upstate
        ? (lengthBegin - linedata.topleftr) / lengthBegin
        : (lengthBegin + linedata.topleftr) / lengthBegin;
    }
    topArcPoint = ScalePoint(beginPoint, scale, scale, circle);
  }
  return topArcPoint;
}

export function GetStreetEndTopPoint(linedata, beginPoint, centerPoint, endPoint) {
  let circle = FindCircle(beginPoint, centerPoint, endPoint);
  let topArcPoint = [];
  if (IsZero(circle[2])) {
    topArcPoint = TowPointVerticalLengthByStatus(
      beginPoint,
      endPoint,
      endPoint,
      linedata.toprightr,
      !linedata.uprightstate
    );
  } else {
    let isByTime = ByTime(beginPoint, centerPoint, endPoint) === THREE_POINT_STATUS_TYPE.BYTIME;
    let lengthEnd = LengthBetweenPoints(endPoint, circle);
    let scale = linedata.uprightstate
      ? (lengthEnd + linedata.toprightr) / lengthEnd
      : (lengthEnd - linedata.toprightr) / lengthEnd;
    if (!isByTime) {
      scale = linedata.uprightstate
        ? (lengthEnd - linedata.toprightr) / lengthEnd
        : (lengthEnd + linedata.toprightr) / lengthEnd;
    }
    topArcPoint = ScalePoint(endPoint, scale, scale, circle);
  }
  return topArcPoint;
}

/**
 *根据道路线 得到交汇点
 *
 * @export
 * @param {*} points1
 * @param {*} points2
 * @param {*} acrossPoints
 */
export function StreetAreaAcrossPoint(points1, points2, acrossPoints) {
  let circle1 = FindCircle(points1[0], points1[1], points1[2]);
  let circle2 = FindCircle(points2[0], points2[1], points2[2]);
  let isBytime1 = IsByTime(points1[0], points1[1], points1[2]);
  let isBytime2 = IsByTime(points2[0], points2[1], points2[2]);
  if (IsZero(circle1[2]) && IsZero(circle2[2])) {
    let pointRange2 = GetTowPointRange(points2[0], points2[2]);
    let pointRange1 = GetTowPointRange(points1[0], points1[2]);
    let acrossPoint = TowLineAcrossPoint([points1[0], points1[2]], [points2[0], points2[2]]);
    if (IsPointInRange1(acrossPoint, pointRange1) && IsPointInRange1(acrossPoint, pointRange2)) {
      //acrossPoints.push(acrossPoint);
      //acrossPoints=acrossPoint;
      CopyPoint(acrossPoints, acrossPoint);
    }
  } else if (!IsZero(circle1[2]) && IsZero(circle2[2])) {
    let lineKB2 = GetLineKB(points2[0], points2[2]);
    let acrossPoint = GetCircleLineAcrossPoint(circle1, lineKB2);
    let pointRange2 = GetTowPointRange(points2[0], points2[2]);
    if (GetCircleLineLength(circle1, lineKB2) <= circle1[2]) {
      if (
        IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[0]) &&
        IsPointInRange1(acrossPoint[0], pointRange2) &&
        IsZero(LengthBetweenPoints(acrossPoint[0], circle1) - circle1[2])
      ) {
        // acrossPoints.push(acrossPoint[0]);
        CopyPoint(acrossPoints, acrossPoint[0]);
      }

      if (
        IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[1]) &&
        IsPointInRange1(acrossPoint[1], pointRange2) &&
        IsZero(LengthBetweenPoints(acrossPoint[1], circle1) - circle1[2])
      ) {
        // acrossPoints.push(acrossPoint[1]);
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
  } else if (IsZero(circle1[2]) && !IsZero(circle2[2])) {
    let lineKB1 = GetLineKB(points1[0], points1[2]);
    if (GetCircleLineLength(circle2, lineKB1) <= circle2[2]) {
      let acrossPoint = GetCircleLineAcrossPoint(circle2, lineKB1);
      let pointRange1 = GetTowPointRange(points1[0], points1[2]);
      if (
        IsArcRangePoint(points2[0], points2[2], circle2, isBytime2, acrossPoint[0]) &&
        IsPointInRange1(acrossPoint[0], pointRange1) &&
        IsZero(LengthBetweenPoints(acrossPoint[0], circle2) - circle2[2])
      ) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (
        IsArcRangePoint(points2[0], points2[2], circle2, isBytime2, acrossPoint[1]) &&
        IsPointInRange1(acrossPoint[1], pointRange1) &&
        IsZero(LengthBetweenPoints(acrossPoint[1], circle2) - circle2[2])
      ) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
  } else if (!IsZero(circle1[2]) && !IsZero(circle2[2])) {
    if (LengthBetweenPoints(circle1, circle2) <= circle1[2] + circle2[2]) {
      let acrossPoint = TowCircleAcrossPoints(circle1, circle2);
      if (
        IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[0]) &&
        IsArcRangePoint(points2[0], points2[2], circle2, isBytime2, acrossPoint[0]) &&
        IsZero(LengthBetweenPoints(acrossPoint[0], circle2) - circle2[2]) &&
        IsZero(LengthBetweenPoints(acrossPoint[0], circle1) - circle1[2])
      ) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (
        IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[1]) &&
        IsArcRangePoint(points2[0], points2[2], circle2, isBytime2, acrossPoint[1]) &&
        IsZero(LengthBetweenPoints(acrossPoint[1], circle2) - circle2[2], true, true) &&
        IsZero(LengthBetweenPoints(acrossPoint[1], circle1) - circle1[2], true, true)
      ) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
  }
}

export function OffsetArcAcrossPoint(points, pointOffset, arcPoint, offArcHeight, acrossPoint) {
  let angle = -STREET_SPACE.OFFSETARCANGLE;
  if (offArcHeight < 0) angle = STREET_SPACE.OFFSETARCANGLE;
  let circle = FindCircle(pointOffset[0], pointOffset[1], pointOffset[2]);
  let topLinePoints = GetLineHeightPoints(pointOffset[0], pointOffset[1], pointOffset[2], offArcHeight, true);
  let bottomLinePoints = GetLineHeightPoints(pointOffset[0], pointOffset[1], pointOffset[2], offArcHeight, false);
  let leftPoint = [],
    leftTemp = [],
    rightPoint = [],
    rightTemp = [],
    arcCenter = [];
  if (IsZero(circle[2])) {
    leftPoint = TowLineAcrossPoint([bottomLinePoints[0], bottomLinePoints[2]], [arcPoint[0], arcPoint[2]]);
    rightPoint = TowLineAcrossPoint([topLinePoints[0], topLinePoints[1]], [arcPoint[0], arcPoint[2]]);
    arcCenter = GetCenterInTwoPoints(leftPoint, rightPoint);
    leftTemp = RotatePoint(GetCenterInTwoPoints(leftPoint, arcCenter), angle, arcCenter);
    rightTemp = RotatePoint(GetCenterInTwoPoints(arcCenter, rightPoint), angle, arcCenter);
  } else {
    let bottomCircle = [circle[0], circle[1], LengthBetweenPoints(circle, bottomLinePoints[0])];
    let topCircle = [circle[0], circle[1], LengthBetweenPoints(circle, topLinePoints[0])];
    let lineKB = GetLineKB(arcPoint[0], arcPoint[2]);
    let acrossLeft = GetCircleLineAcrossPoint(bottomCircle, lineKB);
    if (LengthBetweenPoints(acrossLeft[0], arcPoint[0]) > LengthBetweenPoints(acrossLeft[1], arcPoint[0]))
      leftPoint = acrossLeft[1];
    else leftPoint = acrossLeft[0];
    let acrossRight = GetCircleLineAcrossPoint(topCircle, lineKB);
    if (LengthBetweenPoints(acrossRight[0], arcPoint[2]) > LengthBetweenPoints(acrossRight[1], arcPoint[2]))
      rightPoint = acrossRight[1];
    else rightPoint = acrossRight[0];
    arcCenter = GetCenterInTwoPoints(leftPoint, rightPoint);
    leftTemp = RotatePoint(GetCenterInTwoPoints(leftPoint, arcCenter), angle, arcCenter);
    rightTemp = RotatePoint(GetCenterInTwoPoints(arcCenter, rightPoint), angle, arcCenter);
  }
  let circleDest = FindCircle(points[0], points[1], points[2]);
  let circleLeft = FindCircle1(leftPoint, arcCenter, leftTemp);
  let circleRight = FindCircle1(arcCenter, rightPoint, rightTemp);

  let isBytime = ByTime(leftPoint, leftTemp, arcCenter) === THREE_POINT_STATUS_TYPE.BYTIME;
  if (IsZero(circleDest[2])) {
    let lineDest = GetLineKB(points[0], points[2]);
    let pointRange = GetTowPointRange(points[0], points[2]);
    let acrossPointArc = GetCircleLineAcrossPoint(circleLeft, lineDest);
    let acrossPointArcRight = GetCircleLineAcrossPoint(circleRight, lineDest);
    if (
      IsArcRangePoint(leftPoint, arcCenter, circleLeft, isBytime, acrossPointArc[0]) &&
      IsPointInRange1(acrossPointArc[0], pointRange) &&
      IsZero(circleLeft[2] - LengthBetweenPoints(circleLeft, acrossPointArc[0]))
    ) {
      CopyPoint(acrossPoint, acrossPointArc[0]);
    } else if (
      IsArcRangePoint(leftPoint, arcCenter, circleLeft, isBytime, acrossPointArc[1]) &&
      IsPointInRange1(acrossPointArc[1], pointRange) &&
      IsZero(circleLeft[2] - LengthBetweenPoints(circleLeft, acrossPointArc[1]))
    ) {
      CopyPoint(acrossPoint, acrossPointArc[1]);
    } else if (
      IsArcRangePoint(arcCenter, rightPoint, circleRight, !isBytime, acrossPointArcRight[0]) &&
      IsPointInRange1(acrossPointArcRight[0], pointRange) &&
      IsZero(circleRight[2] - LengthBetweenPoints(circleRight, acrossPointArcRight[0]))
    ) {
      CopyPoint(acrossPoint, acrossPointArcRight[0]);
    } else if (
      IsArcRangePoint(arcCenter, rightPoint, circleRight, !isBytime, acrossPointArcRight[1]) &&
      IsPointInRange1(acrossPointArcRight[1], pointRange) &&
      IsZero(circleRight[2] - LengthBetweenPoints(circleRight, acrossPointArcRight[1]))
    ) {
      CopyPoint(acrossPoint, acrossPointArcRight[1]);
    }
  } else {
    let acrossPointArc = TowCircleAcrossPoints(circleLeft, circleDest);
    let isByTimeDest = ByTime(points[0], points[1], points[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    if (
      IsArcRangePoint(leftPoint, arcCenter, circleLeft, isBytime, acrossPointArc[0]) &&
      IsArcRangePoint(points[0], points[2], circleDest, isByTimeDest, acrossPointArc[0])
    )
      CopyPoint(acrossPoint, acrossPointArc[0]);
    else if (
      IsArcRangePoint(leftPoint, arcCenter, circleLeft, isBytime, acrossPointArc[1]) &&
      IsArcRangePoint(points[0], points[2], circleDest, isByTimeDest, acrossPointArc[1])
    )
      CopyPoint(acrossPoint, acrossPointArc[1]);
    let acrossPointArcRight = TowCircleAcrossPoints(circleRight, circleDest);
    if (
      IsArcRangePoint(arcCenter, rightPoint, circleRight, !isBytime, acrossPointArcRight[0]) &&
      IsArcRangePoint(points[0], points[2], circleDest, isByTimeDest, acrossPointArcRight[0])
    )
      CopyPoint(acrossPoint, acrossPointArcRight[0]);
    else if (
      IsArcRangePoint(arcCenter, rightPoint, circleRight, !isBytime, acrossPointArcRight[1]) &&
      IsArcRangePoint(points[0], points[2], circleDest, isByTimeDest, acrossPointArcRight[1])
    )
      CopyPoint(acrossPoint, acrossPointArcRight[1]);
  }
}

export function OffsetArcAcrossPoint1(pointOffset, arcPoint, offArcHeight, circleDest) {
  let angle = -STREET_SPACE.OFFSETARCANGLE;
  if (offArcHeight < 0) angle = STREET_SPACE.OFFSETARCANGLE;
  let circle = FindCircle(pointOffset[0], pointOffset[1], pointOffset[2]);
  let topLinePoints = GetLineHeightPoints(pointOffset[0], pointOffset[1], pointOffset[2], offArcHeight, true);
  let bottomLinePoints = GetLineHeightPoints(pointOffset[0], pointOffset[1], pointOffset[2], offArcHeight, false);
  let leftPoint = [],
    leftTemp = [],
    rightPoint = [],
    rightTemp = [],
    arcCenter = [];
  if (IsZero(circle[2])) {
    leftPoint = TowLineAcrossPoint([bottomLinePoints[0], bottomLinePoints[2]], [arcPoint[0], arcPoint[2]]);
    rightPoint = TowLineAcrossPoint([topLinePoints[0], topLinePoints[2]], [arcPoint[0], arcPoint[2]]);
    arcCenter = GetCenterInTwoPoints(leftPoint, rightPoint);
    leftTemp = RotatePoint(GetCenterInTwoPoints(leftPoint, arcCenter), angle, arcCenter);
    rightTemp = RotatePoint(GetCenterInTwoPoints(arcCenter, rightPoint), angle, arcCenter);
  } else {
    let bottomCircle = [circle[0], circle[1], LengthBetweenPoints(circle, bottomLinePoints[0])];
    let topCircle = [circle[0], circle[1], LengthBetweenPoints(circle, topLinePoints[0])];
    let lineKB = GetLineKB(arcPoint[0], arcPoint[2]);
    let acrossLeft = GetCircleLineAcrossPoint(bottomCircle, lineKB);
    if (LengthBetweenPoints(acrossLeft[0], arcPoint[0]) > LengthBetweenPoints(acrossLeft[1], arcPoint[0]))
      leftPoint = acrossLeft[1];
    else leftPoint = acrossLeft[0];
    let acrossRight = GetCircleLineAcrossPoint(topCircle, lineKB);
    if (LengthBetweenPoints(acrossRight[0], arcPoint[2]) > LengthBetweenPoints(acrossRight[1], arcPoint[2]))
      rightPoint = acrossRight[1];
    else rightPoint = acrossRight[0];
    arcCenter = GetCenterInTwoPoints(leftPoint, rightPoint);
    leftTemp = RotatePoint(GetCenterInTwoPoints(leftPoint, arcCenter), angle, arcCenter);
    rightTemp = RotatePoint(GetCenterInTwoPoints(arcCenter, rightPoint), angle, arcCenter);
  }
  let circleLeft = FindCircle1(leftPoint, arcCenter, leftTemp);
  let circleRight = FindCircle1(arcCenter, rightPoint, rightTemp);
  let isBytime = ByTime(leftPoint, leftTemp, arcCenter) === THREE_POINT_STATUS_TYPE.BYTIME;
  let acrossPointArc = TowCircleAcrossPoints(circleLeft, circleDest);
  let acrossPointArcRight = TowCircleAcrossPoints(circleRight, circleDest);
  var acrossPoint = [];
  if (
    (IsZero(circleLeft[2] - LengthBetweenPoints(circleLeft, acrossPointArc[0])) &&
      IsArcRangePoint(leftPoint, arcCenter, circleLeft, isBytime, acrossPointArc[0])) ||
    (IsZero(circleLeft[2] - LengthBetweenPoints(circleLeft, acrossPointArc[1])) &&
      IsArcRangePoint(leftPoint, arcCenter, circleLeft, isBytime, acrossPointArc[1]))
  ) {
    acrossPoint.push(acrossPointArc[0]);
    acrossPoint.push(acrossPointArc[1]);
  } else if (
    (IsZero(circleRight[2] - LengthBetweenPoints(circleRight, acrossPointArcRight[0])) &&
      IsArcRangePoint(arcCenter, rightPoint, circleRight, !isBytime, acrossPointArcRight[0])) ||
    (IsZero(circleRight[2] - LengthBetweenPoints(circleRight, acrossPointArcRight[1])) &&
      IsArcRangePoint(arcCenter, rightPoint, circleRight, !isBytime, acrossPointArcRight[1]))
  ) {
    acrossPoint.push(acrossPointArcRight[0]);
    acrossPoint.push(acrossPointArcRight[1]);
  }
  return acrossPoint;
}

/**
 * offset 交点
 * @param {*} points
 * @param {*} pointOffset
 * @param {*} arcPoint
 * @param {*} acrossPoints
 */
export function OffsetAreaAcrossPoint(points, pointOffset, arcPoint, offArcHeight, acrossPoints) {
  let circle1 = FindCircle(points[0], points[1], points[2]);
  let circle2 = FindCircle(pointOffset[0], pointOffset[1], pointOffset[2]);
  let topLinePoints = GetLineHeightPoints(pointOffset[0], pointOffset[1], pointOffset[2], offArcHeight, true);
  let bottomLinePoints = GetLineHeightPoints(pointOffset[0], pointOffset[1], pointOffset[2], offArcHeight, false);
  if (IsZero(circle1[2]) && IsZero(circle2[2])) {
    let leftPoint = TowLineAcrossPoint([bottomLinePoints[0], bottomLinePoints[2]], [arcPoint[0], arcPoint[2]]);
    let rightPoint = TowLineAcrossPoint([topLinePoints[0], topLinePoints[1]], [arcPoint[0], arcPoint[2]]);
    if (IsRangePoint([points[0], points[2]], [bottomLinePoints[0], leftPoint])) {
      let acrossPoint = TowLineAcrossPoint([points[0], points[2]], [bottomLinePoints[0], leftPoint]);
      CopyPoint(acrossPoints, acrossPoint);
    }
    if (IsRangePoint([points[0], points[2]], [rightPoint, topLinePoints[2]])) {
      let acrossPoint = TowLineAcrossPoint([points[0], points[2]], [rightPoint, topLinePoints[2]]);
      CopyPoint(acrossPoints, acrossPoint);
    }
    // if (IsRangePoint([points[0], points[2]], [leftPoint, rightPoint])) {
    //     let acrossPoint = TowLineAcrossPoint([points[0], points[2]], [leftPoint, rightPoint]);
    //     CopyPoint(acrossPoints, acrossPoint);;
    // }
  } else if (!IsZero(circle1[2]) && IsZero(circle2[2])) {
    let isBytime1 = ByTime(points[0], points[1], points[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let leftPoint = TowLineAcrossPoint([bottomLinePoints[0], bottomLinePoints[2]], [arcPoint[0], arcPoint[2]]);
    let rightPoint = TowLineAcrossPoint([topLinePoints[0], topLinePoints[1]], [arcPoint[0], arcPoint[2]]);
    let lineKB1 = GetLineKB(bottomLinePoints[0], bottomLinePoints[2]);
    let lineKB2 = GetLineKB(leftPoint, rightPoint);
    let lineKB3 = GetLineKB(topLinePoints[0], topLinePoints[1]);
    if (GetCircleLineLength(circle1, lineKB1) <= circle1[2]) {
      let acrossPoint = GetCircleLineAcrossPoint(circle1, lineKB1);
      let pointRange1 = GetTowPointRange(bottomLinePoints[0], leftPoint);
      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[0]) &&
        IsPointInRange1(acrossPoint[0], pointRange1)
      ) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[1]) &&
        IsPointInRange1(acrossPoint[1], pointRange1)
      ) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
    // if (GetCircleLineLength(circle1, lineKB2) <= circle1[2]) {
    //     let acrossPoint = GetCircleLineAcrossPoint(circle1, lineKB2);
    //     let pointRange1 = GetTowPointRange(leftPoint, rightPoint);
    //     if (IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[0]) && IsPointInRange1(acrossPoint[0], pointRange1)) {
    //         CopyPoint(acrossPoints, acrossPoint[0]);
    //     }

    //     if (IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[1]) && IsPointInRange1(acrossPoint[1], pointRange1)) {
    //         CopyPoint(acrossPoints, acrossPoint[1]);
    //     }
    // }
    if (GetCircleLineLength(circle1, lineKB3) <= circle1[2]) {
      let acrossPoint = GetCircleLineAcrossPoint(circle1, lineKB3);
      let pointRange1 = GetTowPointRange(rightPoint, topLinePoints[2]);
      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[0]) &&
        IsPointInRange1(acrossPoint[0], pointRange1)
      ) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }

      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[1]) &&
        IsPointInRange1(acrossPoint[1], pointRange1)
      ) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
  } else if (IsZero(circle1[2]) && !IsZero(circle2[2])) {
    let bottomCircle = [circle2[0], circle2[1], LengthBetweenPoints(circle2, bottomLinePoints[0])];
    let topCircle = [circle2[0], circle2[1], LengthBetweenPoints(circle2, topLinePoints[0])];
    let isBytime2 = ByTime(pointOffset[0], pointOffset[1], pointOffset[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let leftPoint = FindCircleLinePoint(bottomCircle, arcPoint[0]);
    let rightPoint = FindCircleLinePoint(topCircle, arcPoint[2]);
    let lineKB = GetLineKB(points[0], points[2]);
    if (GetCircleLineLength(bottomCircle, lineKB) <= bottomCircle[2]) {
      let acrossPoint = GetCircleLineAcrossPoint(bottomCircle, lineKB);
      if (IsArcRangePoint(bottomLinePoints[0], leftPoint, bottomCircle, isBytime2, acrossPoint[0])) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (IsArcRangePoint(bottomLinePoints[0], leftPoint, bottomCircle, isBytime2, acrossPoint[1])) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
    if (GetCircleLineLength(topCircle, lineKB) <= topCircle[2]) {
      let acrossPoint = GetCircleLineAcrossPoint(topCircle, lineKB);
      if (IsArcRangePoint(rightPoint, topLinePoints[2], topCircle, isBytime2, acrossPoint[0])) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (IsArcRangePoint(rightPoint, topLinePoints[2], topCircle, isBytime2, acrossPoint[1])) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
    // if (IsRangePoint([points[0], points[2]], [leftPoint, rightPoint])) {
    //     let acrossPoint = TowLineAcrossPoint([points[0], points[2]], [leftPoint, rightPoint]);
    //     CopyPoint(acrossPoints, acrossPoint);
    // }
  } else if (!IsZero(circle1[2]) && !IsZero(circle2[2])) {
    let isBytime1 = ByTime(points[0], points[1], points[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let isBytime2 = ByTime(pointOffset[0], pointOffset[1], pointOffset[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    let bottomCircle = [circle2[0], circle2[1], LengthBetweenPoints(circle2, bottomLinePoints[0])];
    let topCircle = [circle2[0], circle2[1], LengthBetweenPoints(circle2, topLinePoints[0])];
    let leftPoint = FindCircleLinePoint(bottomCircle, arcPoint[0]);
    let rightPoint = FindCircleLinePoint(topCircle, arcPoint[2]);
    if (LengthBetweenPoints(circle1, bottomCircle) <= circle1[2] + bottomCircle[2]) {
      let acrossPoint = TowCircleAcrossPoints(circle1, bottomCircle);
      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[0]) &&
        IsArcRangePoint(bottomLinePoints[0], leftPoint, bottomCircle, isBytime2, acrossPoint[0])
      ) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[1]) &&
        IsArcRangePoint(bottomLinePoints[0], leftPoint, bottomCircle, isBytime2, acrossPoint[1])
      ) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
    if (LengthBetweenPoints(circle1, topCircle) <= circle1[2] + topCircle[2]) {
      let acrossPoint = TowCircleAcrossPoints(circle1, topCircle);
      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[0]) &&
        IsArcRangePoint(rightPoint, topLinePoints[2], topCircle, isBytime2, acrossPoint[0])
      ) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (
        IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[1]) &&
        IsArcRangePoint(rightPoint, topLinePoints[2], topCircle, isBytime2, acrossPoint[1])
      ) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
    // let lineKB2 = GetLineKB(leftPoint, rightPoint);
    // if (GetCircleLineLength(circle1, lineKB2) <= circle1[2]) {
    //     let acrossPoint = GetCircleLineAcrossPoint(circle1, lineKB2);
    //     let pointRange1 = GetTowPointRange(leftPoint, rightPoint);
    //     if (IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[0]) && IsPointInRange1(acrossPoint[0], pointRange1)) {
    //         CopyPoint(acrossPoints, acrossPoint[0]);
    //     }

    //     if (IsArcRangePoint(points[0], points[2], circle1, isBytime1, acrossPoint[1]) && IsPointInRange1(acrossPoint[1], pointRange1)) {
    //         CopyPoint(acrossPoints, acrossPoint[1]);
    //     }
    // }
  }
  OffsetArcAcrossPoint(points, pointOffset, arcPoint, offArcHeight, acrossPoints);
}
export function addStreetAcrossData() {
  var data = {
    selectflag: false,
    acrossobjid: '',
    nearline: -1,
  };
  return data;
}
/**
 *道路相交时 弧形的起始点
 *
 * @export
 * @param {*} linePoints
 * @param {*} circle
 * @param {*} nearPoint
 * @returns
 */
export function GetAcrossSidePoint(linePoints, circle, nearPoint) {
  let lineCircle = FindCircle(linePoints[0], linePoints[1], linePoints[2]);
  let acrossPoints = [];
  if (IsZero(lineCircle[2])) {
    let lineKB = GetLineKB(linePoints[0], linePoints[2]);
    acrossPoints = GetCircleLineAcrossPoint(circle, lineKB);
  } else {
    acrossPoints = TowCircleAcrossPoints(lineCircle, circle);
  }
  if (acrossPoints.length > 1) {
    if (LengthBetweenPoints(acrossPoints[0], nearPoint) < LengthBetweenPoints(acrossPoints[1], nearPoint)) {
      if (IsZero(LengthBetweenPoints(acrossPoints[0], circle) - circle[2])) return acrossPoints[0];
    } else {
      if (IsZero(LengthBetweenPoints(acrossPoints[1], circle) - circle[2])) return acrossPoints[1];
    }
  }
  return [];
}
export function GetAcrossSidePoint1(linePoints, circle, nearPoint) {
  let lineCircle = FindCircle(linePoints[0], linePoints[1], linePoints[2]);
  let acrossPoints = [];
  if (IsZero(lineCircle[2])) {
    let lineKB = GetLineKB(linePoints[0], linePoints[2]);
    acrossPoints = GetCircleLineAcrossPoint(circle, lineKB);
    let pointRnage = GetTowPointRange(linePoints[0], linePoints[2]);
    if (LengthBetweenPoints(acrossPoints[0], nearPoint) < LengthBetweenPoints(acrossPoints[1], nearPoint)) {
      if (
        IsPointInRange1(acrossPoints[0], pointRnage) &&
        IsZero(LengthBetweenPoints(acrossPoints[0], circle) - circle[2])
      )
        return acrossPoints[0];
    } else {
      if (
        IsPointInRange1(acrossPoints[1], pointRnage) &&
        IsZero(LengthBetweenPoints(acrossPoints[1], circle) - circle[2])
      )
        return acrossPoints[1];
    }
  } else {
    acrossPoints = TowCircleAcrossPoints(lineCircle, circle);
    let isBytime = IsByTime(linePoints[0], linePoints[1], linePoints[2]);
    if (LengthBetweenPoints(acrossPoints[0], nearPoint) < LengthBetweenPoints(acrossPoints[1], nearPoint)) {
      if (
        IsArcRangePoint(linePoints[0], linePoints[2], lineCircle, isBytime, acrossPoints[0]) &&
        IsZero(LengthBetweenPoints(acrossPoints[0], circle) - circle[2])
      ) {
        return acrossPoints[0];
      }
    } else {
      if (
        IsArcRangePoint(linePoints[0], linePoints[2], lineCircle, isBytime, acrossPoints[1]) &&
        IsZero(LengthBetweenPoints(acrossPoints[1], circle) - circle[2])
      ) {
        return acrossPoints[1];
      }
    }
  }
  return [];
}
/**
 * 得到offset 交点弧度起始点和终点
 *
 * @export
 * @param {*} linePoints
 * @param {*} offArcHeight
 * @param {*} arcPoint
 * @param {*} circle
 * @param {*} nearPoint
 * @returns
 */
export function GetOffsetAcrossSidePoint(linePoints, offArcHeight, arcPoint, circle, nearPoint) {
  let lineCircle = FindCircle(linePoints[0], linePoints[1], linePoints[2]);
  let topLinePoints = GetLineHeightPoints(linePoints[0], linePoints[1], linePoints[2], offArcHeight, true);
  let bottomLinePoints = GetLineHeightPoints(linePoints[0], linePoints[1], linePoints[2], offArcHeight, false);
  let acrossPoints = [];
  if (IsZero(lineCircle[2])) {
    let leftPoint = TowLineAcrossPoint([bottomLinePoints[0], bottomLinePoints[2]], [arcPoint[0], arcPoint[2]]);
    let rightPoint = TowLineAcrossPoint([topLinePoints[0], topLinePoints[1]], [arcPoint[0], arcPoint[2]]);
    let lineKB1 = GetLineKB(bottomLinePoints[0], bottomLinePoints[2]);
    let acrossPoints1 = GetCircleLineAcrossPoint(circle, lineKB1);
    let pointRange1 = GetTowPointRange(bottomLinePoints[0], leftPoint);
    if (IsPointInRange1(acrossPoints1[0], pointRange1) || IsPointInRange1(acrossPoints1[1], pointRange1)) {
      acrossPoints.push(acrossPoints1[0]);
      acrossPoints.push(acrossPoints1[1]);
    }

    let lineKB2 = GetLineKB(leftPoint, rightPoint);
    let acrossPoints2 = GetCircleLineAcrossPoint(circle, lineKB2);
    let pointRange2 = GetTowPointRange(leftPoint, rightPoint);
    // if (IsPointInRange1(acrossPoints2[0], pointRange2))
    //     acrossPoints.push(acrossPoints2[0]);
    // if (IsPointInRange1(acrossPoints2[1], pointRange2))
    //     acrossPoints.push(acrossPoints2[1]);
    let lineKB3 = GetLineKB(topLinePoints[0], topLinePoints[2]);
    let acrossPoints3 = GetCircleLineAcrossPoint(circle, lineKB3);
    let pointRange3 = GetTowPointRange(rightPoint, topLinePoints[2]);
    if (IsPointInRange1(acrossPoints3[0], pointRange3) || IsPointInRange1(acrossPoints3[1], pointRange3)) {
      acrossPoints.push(acrossPoints3[0]);
      acrossPoints.push(acrossPoints3[1]);
    }
  } else {
    let bottomCircle = [lineCircle[0], lineCircle[1], LengthBetweenPoints(lineCircle, bottomLinePoints[0])];
    let topCircle = [lineCircle[0], lineCircle[1], LengthBetweenPoints(lineCircle, topLinePoints[0])];
    let acrossPoints1 = TowCircleAcrossPoints(circle, bottomCircle);
    let leftPoint = [];
    let rightPoint = [];

    let lineKB = GetLineKB(arcPoint[0], arcPoint[2]);
    let acrossLeft = GetCircleLineAcrossPoint(bottomCircle, lineKB);
    if (LengthBetweenPoints(acrossLeft[0], arcPoint[0]) > LengthBetweenPoints(acrossLeft[1], arcPoint[0]))
      leftPoint = acrossLeft[1];
    else leftPoint = acrossLeft[0];
    let acrossRight = GetCircleLineAcrossPoint(topCircle, lineKB);
    if (LengthBetweenPoints(acrossRight[0], arcPoint[2]) > LengthBetweenPoints(acrossRight[1], arcPoint[2]))
      rightPoint = acrossRight[1];
    else rightPoint = acrossRight[0];
    let isBytime = ByTime(linePoints[0], linePoints[1], linePoints[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    if (
      IsArcRangePoint(bottomLinePoints[0], leftPoint, bottomCircle, isBytime, acrossPoints1[0]) ||
      IsArcRangePoint(bottomLinePoints[0], leftPoint, bottomCircle, isBytime, acrossPoints1[1])
    ) {
      acrossPoints.push(acrossPoints1[0]);
      acrossPoints.push(acrossPoints1[1]);
    }

    let acrossPoint3 = TowCircleAcrossPoints(circle, topCircle);
    if (
      IsArcRangePoint(rightPoint, topLinePoints[2], topCircle, isBytime, acrossPoint3[0]) ||
      IsArcRangePoint(rightPoint, topLinePoints[2], topCircle, isBytime, acrossPoint3[1])
    ) {
      acrossPoints.push(acrossPoint3[0]);
      acrossPoints.push(acrossPoint3[1]);
    }

    let lineKB2 = GetLineKB(arcPoint[0], arcPoint[2]);
    let acrossPoints2 = GetCircleLineAcrossPoint(circle, lineKB2);
    let pointRange2 = GetTowPointRange(leftPoint, rightPoint);
    // if (IsPointInRange1(acrossPoints2[0], pointRange2))
    //     acrossPoints.push(acrossPoints2[0]);
    // if (IsPointInRange1(acrossPoints2[1], pointRange2))
    //     acrossPoints.push(acrossPoints2[1]);
  }

  var acrossArc = OffsetArcAcrossPoint1(linePoints, arcPoint, offArcHeight, circle);
  if (acrossArc.length == 2) {
    acrossPoints = [];
    acrossPoints.push(acrossArc[0]);
    acrossPoints.push(acrossArc[1]);
  }
  if (acrossPoints.length > 1) {
    if (LengthBetweenPoints(acrossPoints[0], nearPoint) < LengthBetweenPoints(acrossPoints[1], nearPoint))
      return acrossPoints[0];
    else return acrossPoints[1];
  } else {
    return [];
  }
}
export function GetOffsetLinePoints(lineArcPoints, linePoints, offarcHeight) {
  let bottomPoints = GetLineHeightPoints(linePoints[0], linePoints[1], linePoints[2], offarcHeight, false);
  let topPoints = GetLineHeightPoints(linePoints[0], linePoints[1], linePoints[2], offarcHeight, true);
  let circle = FindCircle(linePoints[0], linePoints[1], linePoints[2]);
  let leftPoint = [],
    rightPoint = [],
    blCenter = [],
    rtCenter = [];
  if (IsZero(circle[2])) {
    leftPoint = TowLineAcrossPoint([bottomPoints[0], bottomPoints[1]], [lineArcPoints[0], lineArcPoints[2]]);
    rightPoint = TowLineAcrossPoint([lineArcPoints[0], lineArcPoints[2]], [topPoints[0], topPoints[2]]);
    blCenter = GetCenterInTwoPoints(bottomPoints[0], leftPoint);
    rtCenter = GetCenterInTwoPoints(rightPoint, topPoints[2]);
  } else {
    let bottomR = LengthBetweenPoints(circle, bottomPoints[0]);
    let topR = LengthBetweenPoints(circle, topPoints[2]);
    let lineKB = GetLineKB(lineArcPoints[0], lineArcPoints[2]);
    let acrossLeft = GetCircleLineAcrossPoint([circle[0], circle[1], bottomR], lineKB);
    if (LengthBetweenPoints(acrossLeft[0], lineArcPoints[0]) > LengthBetweenPoints(acrossLeft[1], lineArcPoints[0]))
      leftPoint = acrossLeft[1];
    else leftPoint = acrossLeft[0];
    let acrossRight = GetCircleLineAcrossPoint([circle[0], circle[1], topR], lineKB);
    if (LengthBetweenPoints(acrossRight[0], lineArcPoints[2]) > LengthBetweenPoints(acrossRight[1], lineArcPoints[2]))
      rightPoint = acrossRight[1];
    else rightPoint = acrossRight[0];

    let isBytime = IsByTime(linePoints[0], linePoints[1], linePoints[2]);
    blCenter = GetArcCenter([circle[0], circle[1], bottomR], bottomPoints[0], leftPoint, 1 / 2, isBytime);
    rtCenter = GetArcCenter([circle[0], circle[1], topR], rightPoint, topPoints[2], 1 / 2, isBytime);
  }
  let arcCenter = GetCenterInTwoPoints(leftPoint, rightPoint);
  let arcAngle = -STREET_SPACE.OFFSETARCANGLE;
  if (offarcHeight < 0) arcAngle = STREET_SPACE.OFFSETARCANGLE;
  let leftPoint1 = RotatePoint(GetCenterInTwoPoints(leftPoint, arcCenter), arcAngle, arcCenter);
  let rightPoint1 = RotatePoint(GetCenterInTwoPoints(arcCenter, rightPoint), arcAngle, arcCenter);

  var outPoints = [];
  outPoints.push(bottomPoints[0]);
  outPoints.push(blCenter);
  outPoints.push(leftPoint);
  outPoints.push(leftPoint1);
  outPoints.push(arcCenter);
  outPoints.push(rightPoint1);
  outPoints.push(rightPoint);
  outPoints.push(rtCenter);
  outPoints.push(topPoints[2]);
  return outPoints;
}
export function handleOffsetAcross(objid) {
  let obj = workData.getObject(objid);
  if (obj === null || obj === undefined || obj.functype != FUNCTION_TYPE.STREET) return;
  if (!obj.streetoffarc) return;
  workData.deleteStreetAcrossObject(obj.operateid);
  let maxheight = GetMaxHeight(obj);
  let maxTopPoints = GetStreetMaxPoints(obj, maxheight / 2, true);
  let maxBottomPoints = GetStreetMaxPoints(obj, maxheight / 2, false);
  let beginIndex = GetStreetAreaBegin(maxTopPoints);
  let centerIndex = maxTopPoints[beginIndex][3].rightindex;
  let endIndex = maxTopPoints[centerIndex][3].rightindex;

  let arcPoints = offArcPoints(obj.handlepoint, obj.offarcheight, obj.offarccenter);
  let topLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], maxheight / 2, true);
  let bottomLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], maxheight / 2, false);
  let beginLine = GetStreetLineBegin(obj.groupdata);
  let endLine = GetStreetLineEnd(obj.groupdata);
  let userData = workData.getUseData();
  let ttFind = false,
    tbFind = false,
    btFind = false,
    bbFind = false;
  for (let i = 0; i < userData.length; i++) {
    if (
      userData[i].functype === FUNCTION_TYPE.STREET &&
      !userData[i].streetoffarc &&
      userData[i].operateid != obj.operateid
    ) {
      let acrossObj = GetAcrossObj(obj.streetacrossdata, userData[i].operateid);
      // 2.1 如果有之前相交，之前没有相交数据，新增默认
      //2.2 如果之前相交，之前有相交数据，就获取原来的半径进行计算
      if (!acrossObj) {
        workData.addData(FUNCTION_TYPE.STREETACROSS, obj.operateid + '-' + userData[i].operateid);
        acrossObj = workData.getCurrentOperateObject();
        HandlePointStreetAcross(acrossObj);
        let idAcross = acrossObj.operateid + '||' + userData[i].operateid;
        obj.streetacrossdata.push(idAcross);
        obj.isstreetacross = true;
        userData[i].streetacrossdata.push(acrossObj.operateid + '||' + obj.operateid);
      }
      let dataMaxHeight = GetMaxHeight(userData[i]);
      let maxDataTopPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, true);
      let maxDataBottomPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, false);
      let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
      let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
      let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
      let topTopPoint = [],
        topBottomPoint = [],
        bottomTopPoint = [],
        bottomBottomPoint = [];
      let dataBeginLine = GetStreetLineBegin(userData[i].groupdata);
      let dataEndLine = GetStreetLineEnd(userData[i].groupdata);
      OffsetAreaAcrossPoint(
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        maxTopPoints,
        topLineArcPoints,
        obj.offarcheight,
        topTopPoint
      );
      OffsetAreaAcrossPoint(
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        maxTopPoints,
        topLineArcPoints,
        obj.offarcheight,
        topBottomPoint
      );
      OffsetAreaAcrossPoint(
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        maxBottomPoints,
        bottomLineArcPoints,
        obj.offarcheight,
        bottomBottomPoint
      );
      OffsetAreaAcrossPoint(
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        maxBottomPoints,
        bottomLineArcPoints,
        obj.offarcheight,
        bottomTopPoint
      );
      let isByTimeAcross =
        ByTime(maxTopPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxTopPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      let isByTimeAcrossBottom =
        ByTime(maxBottomPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxBottomPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      if (topTopPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[0].length > 2 && acrossObj.handlepoint[0][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[0][0][2];
        }
        if (acrossObj.handlepoint[0].length > 2 && acrossObj.handlepoint[0][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[0][1][2];
        }
        let acrossCircle1 = [topTopPoint[0], topTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [topTopPoint[0], topTopPoint[1], acrossCircle2R];
        let ttPoint1 = GetAcrossSidePoint(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          acrossCircle1,
          dataNearPoint
        ); //  ScalePoint(topTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataBeginIndex]);
        let ttPoint2 = GetOffsetAcrossSidePoint(
          maxTopPoints,
          obj.offarcheight,
          topLineArcPoints,
          acrossCircle2,
          nearPoint
        );
        ttPoint1[2] = acrossCircle1R;
        ttPoint1[3] = dataNearIndex;
        ttPoint2[2] = acrossCircle2R;
        ttPoint2[3] = nearIndex;
        let ttPoints = [];
        ttPoints.push(ttPoint1);
        ttPoints.push(topTopPoint);
        ttPoints.push(ttPoint2);
        ttPoints.push(addStreetAcrossData());
        ttPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[0].length > 2) ttPoints[3].selectflag = acrossObj.handlepoint[0][3].selectflag;
        acrossObj.handlepoint[0] = ttPoints;
        ttFind = true;
      } else {
        acrossObj.handlepoint[0] = [];
      }

      if (topBottomPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = endIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[1].length > 2 && acrossObj.handlepoint[1][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[1][0][2];
        }
        if (acrossObj.handlepoint[1].length > 2 && acrossObj.handlepoint[1][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[1][1][2];
        }
        let acrossCircle1 = [topBottomPoint[0], topBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [topBottomPoint[0], topBottomPoint[1], acrossCircle2R];
        let tbPoint1 = GetAcrossSidePoint(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          acrossCircle1,
          dataNearPoint
        );
        let tbPoint2 = GetOffsetAcrossSidePoint(
          maxTopPoints,
          obj.offarcheight,
          topLineArcPoints,
          acrossCircle2,
          nearPoint
        );
        tbPoint1[2] = acrossCircle1R;
        tbPoint1[3] = dataNearIndex;
        tbPoint2[2] = acrossCircle2R;
        tbPoint2[3] = nearIndex;
        let tbPoints = [];
        tbPoints.push(tbPoint1);
        tbPoints.push(topBottomPoint);
        tbPoints.push(tbPoint2);
        tbPoints.push(addStreetAcrossData());
        tbPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[1].length > 2) tbPoints[3].selectflag = acrossObj.handlepoint[1][3].selectflag;
        acrossObj.handlepoint[1] = tbPoints;
        tbFind = true;
      } else {
        acrossObj.handlepoint[1] = [];
      }

      if (bottomBottomPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = endIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[2].length > 2 && acrossObj.handlepoint[2][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[2][0][2];
        }
        if (acrossObj.handlepoint[2].length > 2 && acrossObj.handlepoint[2][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[2][1][2];
        }
        let acrossCircle1 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle2R];
        let bbPoint1 = GetAcrossSidePoint(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          acrossCircle1,
          dataNearPoint
        );
        // ScalePoint(
        //   bottomBottomPoint,
        //   scaleOwn,
        //   scaleOwn,
        //   maxDataBottomPoints[dataEndIndex]
        // );
        let bbPoint2 = GetOffsetAcrossSidePoint(
          maxBottomPoints,
          obj.offarcheight,
          bottomLineArcPoints,
          acrossCircle2,
          nearPoint
        );
        bbPoint1[2] = acrossCircle1R;
        bbPoint1[3] = dataNearIndex;
        bbPoint2[2] = acrossCircle2R;
        bbPoint2[3] = nearIndex;
        let bbPoints = [];
        bbPoints.push(bbPoint1);
        bbPoints.push(bottomBottomPoint);
        bbPoints.push(bbPoint2);
        bbPoints.push(addStreetAcrossData());
        bbPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[2].length > 2) bbPoints[3].selectflag = acrossObj.handlepoint[2][3].selectflag;
        acrossObj.handlepoint[2] = bbPoints;
        bbFind = true;
      } else {
        acrossObj.handlepoint[2] = [];
      }

      if (bottomTopPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[3].length > 2 && acrossObj.handlepoint[3][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[3][0][2];
        }
        if (acrossObj.handlepoint[3].length > 2 && acrossObj.handlepoint[3][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[3][1][2];
        }
        let acrossCircle1 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle2R];
        let btPoint1 = GetAcrossSidePoint(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataEndIndex]);
        let btPoint2 = GetOffsetAcrossSidePoint(
          maxBottomPoints,
          obj.offarcheight,
          bottomLineArcPoints,
          acrossCircle2,
          nearPoint
        );
        btPoint1[2] = acrossCircle1R;
        btPoint1[3] = dataNearIndex;
        btPoint2[2] = acrossCircle2R;
        btPoint2[3] = nearIndex;
        let btPoints = [];
        btPoints.push(btPoint1);
        btPoints.push(bottomTopPoint);
        btPoints.push(btPoint2);
        btPoints.push(addStreetAcrossData());
        btPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[3].length > 2) btPoints[3].selectflag = acrossObj.handlepoint[3][3].selectflag;
        acrossObj.handlepoint[3] = btPoints;
        btFind = true;
      } else {
        acrossObj.handlepoint[3] = [];
      }
      if (!ttFind && !tbFind && !btFind && !bbFind) {
        //workData.deleteStreetAcrossObject(obj.operateid);
        RemoveAcrossObj(obj.streetacrossdata, acrossObj.operateid);
        RemoveAcrossObj(userData[i].streetacrossdata, acrossObj.operateid);
        workData.deleteObject(acrossObj.operateid);
      } else {
        if (workData.IsBeforeObject(userData[i].operateid, obj.operateid)) {
          //obj对象在界面底层
          if ((!ttFind && !btFind) || (!tbFind && !bbFind)) {
            workData.deleteObject(obj.operateid);
            workData.addDataBeforeObject(userData[i].operateid, obj);
          }
        } else {
          if ((!ttFind && !tbFind) || (!btFind && !bbFind)) {
            workData.deleteObject(obj.operateid);
            workData.addDataAfterObject(userData[i].operateid, obj);
            workData.deleteObject(acrossObj.operateid);
            workData.addDataAfterObject(obj.operateid, acrossObj);
          }
        }
      }
    }
  }
}

/**
 *根据当前的相交数据和计算的道路id得到图形obj
 *
 * @export
 * @param {*} acrossData
 * @param {*} dataObjId
 * @returns
 */
export function GetAcrossObj(acrossData, dataObjId) {
  var acrossObj = null;
  for (let i = 0; i < acrossData.length; i++) {
    let tempData = acrossData[i];
    //id||objid  (相交图形Id||要计算的道路图形objid)
    if (typeof tempData === 'string' && tempData.split('||')[1] === dataObjId) {
      acrossObj = workData.getObject(tempData.split('||')[0]);
      break;
    }
  }
  return acrossObj;
}
export function RemoveAcrossObj(acrossData, acrossObjId) {
  for (let i = 0; i < acrossData.length; i++) {
    let tempData = acrossData[i];
    //id||objid  (相交图形Id||要计算的道路图形objid)
    if (typeof tempData === 'string' && tempData.split('||')[0] === acrossObjId) {
      acrossData.splice(i, 1);
    }
  }
}
export function GetStreetLineArcLeftPoints(points, linedata) {
  let circle = FindCircle(points[0], points[1], points[2]);
  let beginTopR = 0;
  let arcEndPoint = [],
    arcBeginPoint = [],
    arcTopBeginPoint = [];
  let beginTopPoint = GetStreetBeginTopPoint(linedata, points[0], points[1], points[2]);
  let isBytime = IsByTime(points[0], points[1], points[2]);
  let topCenter = [],
    rightCenter = [];
  if (IsZero(circle[2])) {
    let angle = getAngleBy2Points(points[0], points[2]);
    arcEndPoint = GetCenter(points[0], points[2], linedata.pathend);
    arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], linedata.topleftr * 2], angle + Math.PI);
    let arcTopAngle = linedata.upstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
    arcTopBeginPoint = GetCirclePoint([arcBeginPoint[0], arcBeginPoint[1], linedata.topleftr], arcTopAngle);
    topCenter = GetCenterInTwoPoints(beginTopPoint, arcTopBeginPoint);
    rightCenter = GetCenterInTwoPoints(arcEndPoint, points[2]);
  } else {
    beginTopR = LengthBetweenPoints(circle, beginTopPoint);
    arcEndPoint = GetArcCenter(circle, points[0], points[2], linedata.pathend, isBytime);
    let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
    let arcBenginAnlge =
      angleArcEnd + (isBytime ? (-linedata.topleftr * 2) / circle[2] : (linedata.topleftr * 2) / circle[2]);
    arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
    let scaleArcTopBegin = beginTopR / circle[2];
    arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    topCenter = GetArcCenter([circle[0], circle[1], beginTopR], beginTopPoint, arcTopBeginPoint, 1 / 2, isBytime);
    rightCenter = GetArcCenter(circle, arcEndPoint, points[2], 1 / 2, isBytime);
  }
  let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
  let angleTemp = STREET_SPACE.OFFSETARCANGLE;
  if (!linedata.upstate) angleTemp = -STREET_SPACE.OFFSETARCANGLE;
  let arcTopbeginPointTemp = RotatePoint(GetCenterInTwoPoints(arcTopBeginPoint, arcCenter), angleTemp, arcCenter);
  let arcEndPointTemp = RotatePoint(GetCenterInTwoPoints(arcCenter, arcEndPoint), angleTemp, arcCenter);
  var lineArcPoints = [];
  lineArcPoints.push(beginTopPoint);
  lineArcPoints.push(topCenter);
  lineArcPoints.push(arcTopBeginPoint);
  lineArcPoints.push(arcTopbeginPointTemp);
  lineArcPoints.push(arcCenter);
  lineArcPoints.push(arcEndPointTemp);
  lineArcPoints.push(arcEndPoint);
  lineArcPoints.push(rightCenter);
  lineArcPoints.push(points[2]);
  lineArcPoints.push(arcBeginPoint);
  return lineArcPoints;
}
export function GetStreetLineArcRightPoints(points, linedata) {
  let circle = FindCircle(points[0], points[1], points[2]);
  let beginTopR = 0;
  let arcEndPoint = [],
    arcBeginPoint = [],
    arcTopBeginPoint = [];
  let beginTopPoint = GetStreetEndTopPoint(linedata, points[0], points[1], points[2]);
  let isBytime = IsByTime(points[2], points[1], points[0]);
  let topCenter = [],
    leftCenter = [];
  if (IsZero(circle[2])) {
    let angle = getAngleBy2Points(points[0], points[2]);
    arcEndPoint = GetCenter(points[0], points[2], linedata.pathrightend);
    arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], linedata.toprightr * 2], angle);
    let arcTopAngle = linedata.uprightstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
    arcTopBeginPoint = GetCirclePoint([arcBeginPoint[0], arcBeginPoint[1], linedata.toprightr], arcTopAngle);
    topCenter = GetCenterInTwoPoints(beginTopPoint, arcTopBeginPoint);
    leftCenter = GetCenterInTwoPoints(arcEndPoint, points[0]);
  } else {
    beginTopR = LengthBetweenPoints(circle, beginTopPoint); //this.state.linedata.upstate? circle[2] +this.state.linedata.topleftr : (circle[2] +-this.state.linedata.topleftr);
    arcEndPoint = GetArcCenter(circle, points[0], points[2], linedata.pathrightend, !isBytime);
    let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
    let arcBenginAnlge =
      angleArcEnd + (!isBytime ? (linedata.toprightr * 2) / circle[2] : (-linedata.toprightr * 2) / circle[2]);
    arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
    let scaleArcTopBegin = beginTopR / circle[2];
    arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    topCenter = GetArcCenter([circle[0], circle[1], beginTopR], beginTopPoint, arcTopBeginPoint, 1 / 2, isBytime);
    leftCenter = GetArcCenter(circle, arcEndPoint, points[0], 1 / 2, isBytime);
  }
  let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
  let angleTemp = -STREET_SPACE.OFFSETARCANGLE;
  if (!linedata.uprightstate) angleTemp = STREET_SPACE.OFFSETARCANGLE;
  let arcTopbeginPointTemp = RotatePoint(GetCenterInTwoPoints(arcTopBeginPoint, arcCenter), angleTemp, arcCenter);
  let arcEndPointTemp = RotatePoint(GetCenterInTwoPoints(arcCenter, arcEndPoint), angleTemp, arcCenter);
  var lineArcPoints = [];
  lineArcPoints.push(points[0]);
  lineArcPoints.push(leftCenter);
  lineArcPoints.push(arcEndPoint);
  lineArcPoints.push(arcEndPointTemp);
  lineArcPoints.push(arcCenter);
  lineArcPoints.push(arcTopbeginPointTemp);
  lineArcPoints.push(arcTopBeginPoint);
  lineArcPoints.push(topCenter);
  lineArcPoints.push(beginTopPoint);
  lineArcPoints.push(arcBeginPoint);
  return lineArcPoints;
}
export function StreetLeftArcAcross(leftArcPoints, points, acrossPoint) {
  if (!IsPointValid(acrossPoint)) {
    StreetAreaAcrossPoint(
      [leftArcPoints[0], leftArcPoints[1], leftArcPoints[2]],
      [points[0], points[1], points[2]],
      acrossPoint
    );
  }
  if (!IsPointValid(acrossPoint)) {
    StreetAreaAcrossPoint(
      [leftArcPoints[2], leftArcPoints[3], leftArcPoints[4]],
      [points[0], points[1], points[2]],
      acrossPoint
    );
  }

  if (!IsPointValid(acrossPoint)) {
    StreetAreaAcrossPoint(
      [leftArcPoints[4], leftArcPoints[5], leftArcPoints[6]],
      [points[0], points[1], points[2]],
      acrossPoint
    );
  }

  if (!IsPointValid(acrossPoint)) {
    StreetAreaAcrossPoint(
      [leftArcPoints[6], leftArcPoints[7], leftArcPoints[8]],
      [points[0], points[1], points[2]],
      acrossPoint
    );
  }
}

export function StreetLineArcAcrossPoints(points, linedata, dataLinePoints, dataLineData, acrossPoint) {
  if (IsZero(dataLineData.topleftr) && IsZero(dataLineData.toprightr)) {
    StreetAreaAcrossPoint(points, dataLinePoints, acrossPoint);
  } else if (!IsZero(dataLineData.topleftr) && IsZero(dataLineData.toprightr)) {
    let dataLeftArcPoints = GetStreetLineArcLeftPoints(dataLinePoints, dataLineData);
    StreetLeftArcAcross(dataLeftArcPoints, points, acrossPoint);
  } else if (IsZero(dataLineData.topleftr) && !IsZero(dataLineData.toprightr)) {
    let dataLeftArcPoints = GetStreetLineArcRightPoints(dataLinePoints, dataLineData);
    StreetLeftArcAcross(dataLeftArcPoints, points, acrossPoint);
  }
}

export function StreetLineLeftArcAcrossPoints(points, linedata, dataLinePoints, dataLineData, acrossPoint) {
  let objLeftArcPoints = GetStreetLineArcLeftPoints(points, linedata);
  if (IsZero(dataLineData.topleftr) && IsZero(dataLineData.toprightr)) {
    StreetLeftArcAcross(objLeftArcPoints, dataLinePoints, acrossPoint);
  } else if (!IsZero(dataLineData.topleftr) && IsZero(dataLineData.toprightr)) {
    let dataLeftArcPoints = GetStreetLineArcLeftPoints(dataLinePoints, dataLineData);
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[0], objLeftArcPoints[1], objLeftArcPoints[2]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[2], objLeftArcPoints[3], objLeftArcPoints[4]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[4], objLeftArcPoints[5], objLeftArcPoints[6]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[6], objLeftArcPoints[7], objLeftArcPoints[8]],
        acrossPoint
      );
    }
  } else if (IsZero(dataLineData.topleftr) && !IsZero(dataLineData.toprightr)) {
    let dataLeftArcPoints = GetStreetLineArcRightPoints(dataLinePoints, dataLineData);
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[0], objLeftArcPoints[1], objLeftArcPoints[2]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[2], objLeftArcPoints[3], objLeftArcPoints[4]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[4], objLeftArcPoints[5], objLeftArcPoints[6]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[6], objLeftArcPoints[7], objLeftArcPoints[8]],
        acrossPoint
      );
    }
  }
}
export function StreetLineRightArcAcrossPoints(points, linedata, dataLinePoints, dataLineData, acrossPoint) {
  let objLeftArcPoints = GetStreetLineArcRightPoints(points, linedata);
  if (IsZero(dataLineData.topleftr) && IsZero(dataLineData.toprightr)) {
    StreetLeftArcAcross(objLeftArcPoints, dataLinePoints, acrossPoint);
  } else if (!IsZero(dataLineData.topleftr) && IsZero(dataLineData.toprightr)) {
    let dataLeftArcPoints = GetStreetLineArcLeftPoints(dataLinePoints, dataLineData);
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[0], objLeftArcPoints[1], objLeftArcPoints[2]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[2], objLeftArcPoints[3], objLeftArcPoints[4]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[4], objLeftArcPoints[5], objLeftArcPoints[6]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[6], objLeftArcPoints[7], objLeftArcPoints[8]],
        acrossPoint
      );
    }
  } else if (IsZero(dataLineData.topleftr) && !IsZero(dataLineData.toprightr)) {
    let dataLeftArcPoints = GetStreetLineArcRightPoints(dataLinePoints, dataLineData);
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[0], objLeftArcPoints[1], objLeftArcPoints[2]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[2], objLeftArcPoints[3], objLeftArcPoints[4]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[4], objLeftArcPoints[5], objLeftArcPoints[6]],
        acrossPoint
      );
    }
    if (!IsPointValid(acrossPoint)) {
      StreetLeftArcAcross(
        dataLeftArcPoints,
        [objLeftArcPoints[6], objLeftArcPoints[7], objLeftArcPoints[8]],
        acrossPoint
      );
    }
  }
}
export function StreetArcAcrossSidePoint(leftArcPoints, circle, nearPoint) {
  var acrossPoint = GetAcrossSidePoint1([leftArcPoints[0], leftArcPoints[1], leftArcPoints[2]], circle, nearPoint);
  if (!IsPointValid(acrossPoint)) {
    acrossPoint = GetAcrossSidePoint1([leftArcPoints[2], leftArcPoints[3], leftArcPoints[4]], circle, nearPoint);
  }
  if (!IsPointValid(acrossPoint)) {
    acrossPoint = GetAcrossSidePoint1([leftArcPoints[4], leftArcPoints[5], leftArcPoints[6]], circle, nearPoint);
  }
  if (!IsPointValid(acrossPoint)) {
    acrossPoint = GetAcrossSidePoint1([leftArcPoints[6], leftArcPoints[7], leftArcPoints[8]], circle, nearPoint);
  }
  return acrossPoint;
}
function handleStreetLineAcross(points1, lineData1, points2, lineData2, acrossPoint) {
  if (IsZero(lineData1.topleftr) && IsZero(lineData1.toprightr))
    StreetLineArcAcrossPoints(points1, lineData1, points2, lineData2, acrossPoint);
  else if (!IsZero(lineData1.topleftr)) {
    StreetLineLeftArcAcrossPoints(points1, lineData1, points2, lineData2, acrossPoint);
  } else if (!IsZero(lineData1.toprightr)) {
    StreetLineRightArcAcrossPoints(points1, lineData1, points2, lineData2, acrossPoint);
  }
}
function handleStreetSideAcross(points, lineData, circle, nearPoint) {
  var acrossPoint = [];
  if (IsZero(lineData.topleftr) && IsZero(lineData.toprightr))
    acrossPoint = GetAcrossSidePoint(points, circle, nearPoint);
  else if (!IsZero(lineData.topleftr)) {
    let dataLeftArcPoints = GetStreetLineArcLeftPoints(points, lineData);
    acrossPoint = StreetArcAcrossSidePoint(dataLeftArcPoints, circle, nearPoint);
  } else if (!IsZero(lineData.toprightr)) {
    let dataRightArcPoints = GetStreetLineArcRightPoints(points, lineData);
    acrossPoint = StreetArcAcrossSidePoint(dataRightArcPoints, circle, nearPoint);
  }
  return acrossPoint;
}
//将当前的street所有相关的acrossobj 全部至于顶部
function upStreetAcrossObj(obj) {
  let acrossData = obj.streetacrossdata;
  for (let i = 0; i < acrossData.length; i++) {
    let tempData = acrossData[i];
    //id||objid  (相交图形Id||要计算的道路图形objid)
    if (typeof tempData === 'string') {
      let acrossObj = workData.getObject(tempData.split('||')[0]);
      if (typeof acrossObj === 'object' && acrossObj != null) {
        workData.deleteObject(acrossObj.operateid);
        workData.addData(acrossObj);
      }
    }
  }
}
/**
 *得到两个道路的交叉图形
 *
 * @export
 * @param {*} objid
 */
export function handleStreetAcross(objid) {
  let obj = workData.getObject(objid);
  if (obj === null || obj === undefined || obj.functype != FUNCTION_TYPE.STREET) return;
  workData.deleteStreetAcrossObject(obj.operateid);
  let maxheight = GetMaxHeight(obj);
  let maxTopPoints = GetStreetMaxPoints(obj, maxheight / 2, true);
  let maxBottomPoints = GetStreetMaxPoints(obj, maxheight / 2, false);
  let beginIndex = GetStreetAreaBegin(maxTopPoints);
  let centerIndex = maxTopPoints[beginIndex][3].rightindex;
  let endIndex = maxTopPoints[centerIndex][3].rightindex;
  let userData = workData.getUseData();
  let beginLine = GetStreetLineBegin(obj.groupdata);
  let endLine = GetStreetLineEnd(obj.groupdata);
  let acrossObjs = [];
  for (let i = 0; i < userData.length; i++) {
    let ttFind = false,
      tbFind = false,
      btFind = false,
      bbFind = false;
    if (
      userData[i].functype === FUNCTION_TYPE.STREET &&
      !userData[i].streetoffarc &&
      userData[i].operateid != obj.operateid
    ) {
      //1 先判断当前道路和要计算的道路是否已经有相交
      let acrossObj = GetAcrossObj(obj.streetacrossdata, userData[i].operateid);
      // 2.1 如果有之前相交，之前没有相交数据，新增默认
      //2.2 如果之前相交，之前有相交数据，就获取原来的半径进行计算
      if (!acrossObj) {
        workData.addData(FUNCTION_TYPE.STREETACROSS, obj.operateid + '-' + userData[i].operateid);
        acrossObj = workData.getCurrentOperateObject();
        HandlePointStreetAcross(acrossObj);
        let idAcross = acrossObj.operateid + '||' + userData[i].operateid;
        obj.streetacrossdata.push(idAcross);
        obj.isstreetacross = true;
        userData[i].streetacrossdata.push(acrossObj.operateid + '||' + obj.operateid);
      }
      let dataMaxHeight = GetMaxHeight(userData[i]);
      let maxDataTopPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, true);
      let maxDataBottomPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, false);
      let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
      let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
      let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
      let topTopPoint = [],
        topBottomPoint = [],
        bottomTopPoint = [],
        bottomBottomPoint = [];
      let dataBeginLine = GetStreetLineBegin(userData[i].groupdata);
      let dataEndLine = GetStreetLineEnd(userData[i].groupdata);
      handleStreetLineAcross(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        obj.groupdata[beginLine],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        userData[i].groupdata[dataBeginLine],
        topTopPoint
      );

      handleStreetLineAcross(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        obj.groupdata[beginLine],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        userData[i].groupdata[dataEndLine],
        topBottomPoint
      );
      handleStreetLineAcross(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        obj.groupdata[endLine],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        userData[i].groupdata[dataEndLine],
        bottomBottomPoint
      );
      handleStreetLineAcross(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        obj.groupdata[endLine],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        userData[i].groupdata[dataBeginLine],
        bottomTopPoint
      );

      //4.1 如果没相交，之前没有相交数据，不用管
      //4.2 如果没有相交，要判断之前是否有相交数据，去除

      let isByTimeAcross =
        ByTime(maxTopPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxTopPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      let isByTimeAcrossBottom =
        ByTime(maxBottomPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxBottomPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      if (topTopPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[0].length > 2 && acrossObj.handlepoint[0][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[0][0][2];
        }
        if (acrossObj.handlepoint[0].length > 2 && acrossObj.handlepoint[0][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[0][1][2];
        }
        let acrossCircle1 = [topTopPoint[0], topTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [topTopPoint[0], topTopPoint[1], acrossCircle2R];
        let ttPoint1 = [],
          ttPoint2 = [];
        ttPoint1 = handleStreetSideAcross(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          userData[i].groupdata[beginLine],
          acrossCircle1,
          dataNearPoint
        );
        ttPoint2 = handleStreetSideAcross(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          obj.groupdata[beginLine],
          acrossCircle2,
          nearPoint
        );
        let ttPoints = [];
        ttPoint1[2] = acrossCircle1R;
        ttPoint1[3] = dataNearIndex;
        ttPoint2[2] = acrossCircle2R;
        ttPoint2[3] = nearIndex;
        ttPoints.push(ttPoint1);
        ttPoints.push(topTopPoint);
        ttPoints.push(ttPoint2);
        ttPoints.push(addStreetAcrossData());
        ttPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        if (acrossObj.handlepoint[0].length > 2) ttPoints[3].selectflag = acrossObj.handlepoint[0][3].selectflag;
        acrossObj.handlepoint[0] = ttPoints;
        ttFind = true;
      } else {
        acrossObj.handlepoint[0] = [];
      }

      if (topBottomPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = endIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[1].length > 2 && acrossObj.handlepoint[1][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[1][0][2];
        }
        if (acrossObj.handlepoint[1].length > 2 && acrossObj.handlepoint[1][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[1][1][2];
        }
        let acrossCircle1 = [topBottomPoint[0], topBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [topBottomPoint[0], topBottomPoint[1], acrossCircle2R];
        let tbPoint1 = handleStreetSideAcross(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          userData[i].groupdata[dataEndLine],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(topBottomPoint,scaleOwn,scaleOwn,maxDataBottomPoints[dataBeginIndex]);
        let tbPoint2 = handleStreetSideAcross(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          obj.groupdata[beginLine],
          acrossCircle2,
          nearPoint
        );

        tbPoint1[2] = acrossCircle1R;
        tbPoint1[3] = dataNearIndex;
        tbPoint2[2] = acrossCircle2R;
        tbPoint2[3] = nearIndex;
        let tbPoints = [];
        tbPoints.push(tbPoint1);
        tbPoints.push(topBottomPoint);
        tbPoints.push(tbPoint2);
        tbPoints.push(addStreetAcrossData());
        tbPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[1].length > 2) tbPoints[3].selectflag = acrossObj.handlepoint[1][3].selectflag;
        acrossObj.handlepoint[1] = tbPoints;
        tbFind = true;
      } else {
        acrossObj.handlepoint[1] = [];
      }
      if (bottomBottomPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = endIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[2].length > 2 && acrossObj.handlepoint[2][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[2][0][2];
        }
        if (acrossObj.handlepoint[2].length > 2 && acrossObj.handlepoint[2][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[2][1][2];
        }
        let acrossCircle1 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle2R];
        let bbPoint1 = handleStreetSideAcross(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          userData[i].groupdata[dataEndLine],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomBottomPoint,scaleOwn,scaleOwn,maxDataBottomPoints[dataEndIndex]);
        let bbPoint2 = handleStreetSideAcross(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          obj.groupdata[endLine],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomBottomPoint,scaleData,scaleData,maxBottomPoints[beginIndex]);
        bbPoint1[2] = acrossCircle1R;
        bbPoint1[3] = dataNearIndex;
        bbPoint2[2] = acrossCircle2R;
        bbPoint2[3] = nearIndex;
        let bbPoints = [];
        bbPoints.push(bbPoint1);
        bbPoints.push(bottomBottomPoint);
        bbPoints.push(bbPoint2);
        bbPoints.push(addStreetAcrossData());
        bbPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[2].length > 2) bbPoints[3].selectflag = acrossObj.handlepoint[2][3].selectflag;
        acrossObj.handlepoint[2] = bbPoints;

        bbFind = true;
      } else {
        acrossObj.handlepoint[2] = [];
      }

      if (bottomTopPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[3].length > 2 && acrossObj.handlepoint[3][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[3][0][2];
        }
        if (acrossObj.handlepoint[3].length > 2 && acrossObj.handlepoint[3][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[3][1][2];
        }
        let acrossCircle1 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle2R];
        let btPoint1 = handleStreetSideAcross(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          userData[i].groupdata[dataBeginLine],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataEndIndex]);
        let btPoint2 = handleStreetSideAcross(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          obj.groupdata[endLine],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomTopPoint,scaleData,scaleData,maxBottomPoints[endIndex]);
        btPoint1[2] = acrossCircle1R;
        btPoint1[3] = dataNearIndex;
        btPoint2[2] = acrossCircle2R;
        btPoint2[3] = nearIndex;
        let btPoints = [];
        btPoints.push(btPoint1);
        btPoints.push(bottomTopPoint);
        btPoints.push(btPoint2);
        btPoints.push(addStreetAcrossData());
        btPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[3].length > 2) btPoints[3].selectflag = acrossObj.handlepoint[3][3].selectflag;
        acrossObj.handlepoint[3] = btPoints;
        btFind = true;
      } else {
        acrossObj.handlepoint[3] = [];
      }
      if (!ttFind && !tbFind && !btFind && !bbFind) {
        //workData.deleteStreetAcrossObject(obj.operateid);
        RemoveAcrossObj(obj.streetacrossdata, acrossObj.operateid);
        RemoveAcrossObj(userData[i].streetacrossdata, acrossObj.operateid);
        workData.deleteObject(acrossObj.operateid);
      } else {
        acrossObjs.push(userData[i]);
        if (workData.IsBeforeObject(userData[i].operateid, obj.operateid)) {
          //obj对象在界面底层
          if ((!ttFind && !btFind) || (!tbFind && !bbFind)) {
            workData.deleteObject(obj.operateid);
            workData.addDataBeforeObject(userData[i].operateid, obj);
          }
        } else {
          if ((!ttFind && !tbFind) || (!btFind && !bbFind)) {
            workData.deleteObject(obj.operateid);
            if (userData[i]) {
              if (typeof userData[i].operateid !== 'undefined' && userData[i].operateid !== null) {
                workData.addDataAfterObject(userData[i].operateid, obj);
              }
            }
            workData.deleteObject(acrossObj.operateid);
            workData.addDataAfterObject(obj.operateid, acrossObj);
          }
        }
      }
    } else if (
      userData[i].functype === FUNCTION_TYPE.STREET &&
      userData[i].streetoffarc &&
      userData[i].operateid != obj.operateid
    ) {
      //1 先判断当前道路和要计算的道路是否已经有相交
      let acrossObj = GetAcrossObj(obj.streetacrossdata, userData[i].operateid);
      // 2.1 如果有之前相交，之前没有相交数据，新增默认
      //2.2 如果之前相交，之前有相交数据，就获取原来的半径进行计算
      if (!acrossObj) {
        workData.addData(FUNCTION_TYPE.STREETACROSS, obj.operateid + '-' + userData[i].operateid);
        acrossObj = workData.getCurrentOperateObject();
        HandlePointStreetAcross(acrossObj);
        let idAcross = acrossObj.operateid + '||' + userData[i].operateid;
        obj.streetacrossdata.push(idAcross);
        obj.isstreetacross = true;
        userData[i].streetacrossdata.push(acrossObj.operateid + '||' + obj.operateid);
      }
      let dataMaxHeight = GetMaxHeight(userData[i]);
      let maxDataTopPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, true);
      let maxDataBottomPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, false);
      let arcPoints = offArcPoints(userData[i].handlepoint, userData[i].offarcheight, userData[i].offarccenter);
      let topLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], dataMaxHeight / 2, true);
      let bottomLineArcPoints = GetLineHeightPoints(arcPoints[0], arcPoints[1], arcPoints[2], dataMaxHeight / 2, false);
      let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
      let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
      let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
      let topTopPoint = [],
        topBottomPoint = [],
        bottomTopPoint = [],
        bottomBottomPoint = [];
      let dataBeginLine = GetStreetLineBegin(userData[i].groupdata);
      let dataEndLine = GetStreetLineEnd(userData[i].groupdata);
      OffsetAreaAcrossPoint(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        maxDataTopPoints,
        topLineArcPoints,
        userData[i].offarcheight,
        topTopPoint
      );

      OffsetAreaAcrossPoint(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        maxDataBottomPoints,
        bottomLineArcPoints,
        userData[i].offarcheight,
        topBottomPoint
      );
      OffsetAreaAcrossPoint(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        maxDataBottomPoints,
        bottomLineArcPoints,
        userData[i].offarcheight,
        bottomBottomPoint
      );
      OffsetAreaAcrossPoint(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        maxDataTopPoints,
        topLineArcPoints,
        userData[i].offarcheight,
        bottomTopPoint
      );
      let isByTimeAcross =
        ByTime(maxTopPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxTopPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      let isByTimeAcrossBottom =
        ByTime(maxBottomPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxBottomPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      if (topTopPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[0].length > 2 && acrossObj.handlepoint[0][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[0][0][2];
        }
        if (acrossObj.handlepoint[0].length > 2 && acrossObj.handlepoint[0][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[0][1][2];
        }
        let acrossCircle1 = [topTopPoint[0], topTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [topTopPoint[0], topTopPoint[1], acrossCircle2R];
        let ttPoint1 = GetOffsetAcrossSidePoint(
          maxDataTopPoints,
          userData[i].offarcheight,
          topLineArcPoints,
          acrossCircle1,
          dataNearPoint
        ); //  ScalePoint(topTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataBeginIndex]);
        let ttPoint2 = GetAcrossSidePoint(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); // ScalePoint(topTopPoint,scaleData,scaleData,maxTopPoints[endIndex]);
        let ttPoints = [];
        ttPoint1[2] = acrossCircle1R;
        ttPoint1[3] = dataNearIndex;
        ttPoint2[2] = acrossCircle2R;
        ttPoint2[3] = nearIndex;
        ttPoints.push(ttPoint1);
        ttPoints.push(topTopPoint);
        ttPoints.push(ttPoint2);
        ttPoints.push(addStreetAcrossData());
        ttPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[0].length > 2) ttPoints[3].selectflag = acrossObj.handlepoint[0][3].selectflag;
        acrossObj.handlepoint[0] = ttPoints;
        ttFind = true;
      } else {
        acrossObj.handlepoint[0] = [];
      }

      if (topBottomPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = endIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[1].length > 2 && acrossObj.handlepoint[1][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[1][0][2];
        }
        if (acrossObj.handlepoint[1].length > 2 && acrossObj.handlepoint[1][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[1][1][2];
        }
        let acrossCircle1 = [topBottomPoint[0], topBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [topBottomPoint[0], topBottomPoint[1], acrossCircle2R];
        let tbPoint1 = GetOffsetAcrossSidePoint(
          maxDataBottomPoints,
          userData[i].offarcheight,
          bottomLineArcPoints,
          acrossCircle1,
          dataNearPoint
        );
        let tbPoint2 = GetAcrossSidePoint(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          acrossCircle2,
          nearPoint
        );

        tbPoint1[2] = acrossCircle1R;
        tbPoint1[3] = dataNearIndex;
        tbPoint2[2] = acrossCircle2R;
        tbPoint2[3] = nearIndex;
        let tbPoints = [];
        tbPoints.push(tbPoint1);
        tbPoints.push(topBottomPoint);
        tbPoints.push(tbPoint2);
        tbPoints.push(addStreetAcrossData());
        tbPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[1].length > 2) tbPoints[3].selectflag = acrossObj.handlepoint[1][3].selectflag;
        acrossObj.handlepoint[1] = tbPoints;
        tbFind = true;
      } else {
        acrossObj.handlepoint[1] = [];
      }

      if (bottomBottomPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = endIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[2].length > 2 && acrossObj.handlepoint[2][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[2][0][2];
        }
        if (acrossObj.handlepoint[2].length > 2 && acrossObj.handlepoint[2][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[2][1][2];
        }
        let acrossCircle1 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle2R];
        let bbPoint1 = GetOffsetAcrossSidePoint(
          maxDataBottomPoints,
          userData[i].offarcheight,
          bottomLineArcPoints,
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomBottomPoint,scaleOwn,scaleOwn,maxDataBottomPoints[dataEndIndex]);
        let bbPoint2 = GetAcrossSidePoint(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomBottomPoint,scaleData,scaleData,maxBottomPoints[beginIndex]);
        bbPoint1[2] = acrossCircle1R;
        bbPoint1[3] = dataNearIndex;
        bbPoint2[2] = acrossCircle2R;
        bbPoint2[3] = nearIndex;
        let bbPoints = [];
        bbPoints.push(bbPoint1);
        bbPoints.push(bottomBottomPoint);
        bbPoints.push(bbPoint2);
        bbPoints.push(addStreetAcrossData());
        bbPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[2].length > 2) bbPoints[3].selectflag = acrossObj.handlepoint[2][3].selectflag;
        acrossObj.handlepoint[2] = bbPoints;
        bbFind = true;
      } else {
        acrossObj.handlepoint[2] = [];
      }

      if (bottomTopPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS;
        let acrossCircle2R = STREET_SPACE.ACROSS;
        if (acrossObj.handlepoint[3].length > 2 && acrossObj.handlepoint[3][0][2] != undefined) {
          acrossCircle1R = acrossObj.handlepoint[3][0][2];
        }
        if (acrossObj.handlepoint[3].length > 2 && acrossObj.handlepoint[3][1][2] != undefined) {
          acrossCircle2R = acrossObj.handlepoint[3][1][2];
        }
        let acrossCircle1 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle2R];
        let btPoint1 = GetOffsetAcrossSidePoint(
          maxDataTopPoints,
          userData[i].offarcheight,
          topLineArcPoints,
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataEndIndex]);
        let btPoint2 = GetAcrossSidePoint(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomTopPoint,scaleData,scaleData,maxBottomPoints[endIndex]);
        btPoint1[2] = acrossCircle1R;
        btPoint1[3] = dataNearIndex;
        btPoint2[2] = acrossCircle2R;
        btPoint2[3] = nearIndex;
        let btPoints = [];
        btPoints.push(btPoint1);
        btPoints.push(bottomTopPoint);
        btPoints.push(btPoint2);
        btPoints.push(addStreetAcrossData());
        btPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        acrossObj.acrossid = obj.operateid + '||' + userData[i].operateid;
        if (acrossObj.handlepoint[3].length > 2) btPoints[3].selectflag = acrossObj.handlepoint[3][3].selectflag;
        acrossObj.handlepoint[3] = btPoints;
        btFind = true;
      } else {
        acrossObj.handlepoint[3] = [];
      }
      if (!ttFind && !tbFind && !btFind && !bbFind) {
        //workData.deleteStreetAcrossObject(obj.operateid);
        RemoveAcrossObj(obj.streetacrossdata, acrossObj.operateid);
        RemoveAcrossObj(userData[i].streetacrossdata, acrossObj.operateid);
        workData.deleteObject(acrossObj.operateid);
      } else {
        if (workData.IsBeforeObject(userData[i].operateid, obj.operateid)) {
          //obj对象在界面底层
          if ((!ttFind && !btFind) || (!tbFind && !bbFind)) {
            workData.deleteObject(obj.operateid);
            workData.addDataBeforeObject(userData[i].operateid, obj);
          }
        } else {
          if ((!ttFind && !tbFind) || (!btFind && !bbFind)) {
            workData.deleteObject(obj.operateid);
            workData.addDataAfterObject(userData[i].operateid, obj);
            workData.deleteObject(acrossObj.operateid);
            workData.addDataAfterObject(obj.operateid, acrossObj);
          }
        }
      }
    }
  }
  for (let i = 0; i < acrossObjs.length; i++) {
    upStreetAcrossObj(acrossObjs[i]);
  }
  upStreetAcrossObj(obj);
}

/**
 *得到两个道路的交叉图形
 *
 * @export
 * @param {*} objid
 */
export function handleDirtAcross(objid) {
  let obj = workData.getObject(objid);
  if (obj === null || obj === undefined || obj.functype != FUNCTION_TYPE.DIRT) return;
  let maxheight = GetMaxHeight(obj);
  let maxTopPoints = GetStreetMaxPoints(obj, maxheight / 2, true);
  let maxBottomPoints = GetStreetMaxPoints(obj, maxheight / 2, false);
  let beginIndex = GetStreetAreaBegin(maxTopPoints);
  let centerIndex = maxTopPoints[beginIndex][3].rightindex;
  let endIndex = maxTopPoints[centerIndex][3].rightindex;
  let userData = workData.getUseData();
  let beginLine = GetStreetLineBegin(obj.groupdata);
  let endLine = GetStreetLineEnd(obj.groupdata);
  let ttFind = false,
    tbFind = false,
    btFind = false,
    bbFind = false;
  for (let i = 0; i < userData.length; i++) {
    if (userData[i].functype === FUNCTION_TYPE.DIRT && userData[i].operateid != obj.operateid) {
      let dataMaxHeight = GetMaxHeight(userData[i]);
      let maxDataTopPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, true);
      let maxDataBottomPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, false);
      let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
      let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
      let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
      let topTopPoint = [],
        topBottomPoint = [],
        bottomTopPoint = [],
        bottomBottomPoint = [];
      let dataBeginLine = GetStreetLineBegin(userData[i].groupdata);
      let dataEndLine = GetStreetLineEnd(userData[i].groupdata);
      StreetAreaAcrossPoint(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        topTopPoint
      );

      StreetAreaAcrossPoint(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        topBottomPoint
      );
      StreetAreaAcrossPoint(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        bottomBottomPoint
      );
      StreetAreaAcrossPoint(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        bottomTopPoint
      );
      let isByTimeAcross =
        ByTime(maxTopPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxTopPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      let isByTimeAcrossBottom =
        ByTime(maxBottomPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxBottomPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      if (topTopPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[0].length > 2 && obj.streetacrossdata[0][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[0][0][2];
        }
        if (obj.streetacrossdata[0].length > 2 && obj.streetacrossdata[0][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[0][1][2];
        }
        let acrossCircle1 = [topTopPoint[0], topTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [topTopPoint[0], topTopPoint[1], acrossCircle2R];
        let ttPoint1 = GetAcrossSidePoint(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          acrossCircle1,
          dataNearPoint
        ); //  ScalePoint(topTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataBeginIndex]);
        let ttPoint2 = GetAcrossSidePoint(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); // ScalePoint(topTopPoint,scaleData,scaleData,maxTopPoints[endIndex]);
        let ttPoints = [];
        ttPoint1[2] = acrossCircle1R;
        ttPoint1[3] = dataNearIndex;
        ttPoint2[2] = acrossCircle2R;
        ttPoint2[3] = nearIndex;
        ttPoints.push(ttPoint1);
        ttPoints.push(topTopPoint);
        ttPoints.push(ttPoint2);
        ttPoints.push(addStreetAcrossData());
        ttPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        obj.streetacrossdata[0] = ttPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        ttFind = true;
      } else {
        if (!ttFind) obj.streetacrossdata[0] = [];
      }

      if (topBottomPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = endIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[1].length > 2 && obj.streetacrossdata[1][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[1][0][2];
        }
        if (obj.streetacrossdata[1].length > 2 && obj.streetacrossdata[1][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[1][1][2];
        }
        let acrossCircle1 = [topBottomPoint[0], topBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [topBottomPoint[0], topBottomPoint[1], acrossCircle2R];
        let tbPoint1 = GetAcrossSidePoint(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(topBottomPoint,scaleOwn,scaleOwn,maxDataBottomPoints[dataBeginIndex]);
        let tbPoint2 = GetAcrossSidePoint(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          acrossCircle2,
          nearPoint
        );

        tbPoint1[2] = acrossCircle1R;
        tbPoint1[3] = dataNearIndex;
        tbPoint2[2] = acrossCircle2R;
        tbPoint2[3] = nearIndex;
        let tbPoints = [];
        tbPoints.push(tbPoint1);
        tbPoints.push(topBottomPoint);
        tbPoints.push(tbPoint2);
        tbPoints.push(addStreetAcrossData());
        tbPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        obj.streetacrossdata[1] = tbPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        tbFind = true;
      } else {
        if (!tbFind) obj.streetacrossdata[1] = [];
      }

      if (bottomBottomPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = endIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[2].length > 2 && obj.streetacrossdata[2][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[2][0][2];
        }
        if (obj.streetacrossdata[2].length > 2 && obj.streetacrossdata[2][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[2][1][2];
        }
        let acrossCircle1 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle2R];
        let bbPoint1 = GetAcrossSidePoint(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomBottomPoint,scaleOwn,scaleOwn,maxDataBottomPoints[dataEndIndex]);
        let bbPoint2 = GetAcrossSidePoint(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomBottomPoint,scaleData,scaleData,maxBottomPoints[beginIndex]);
        bbPoint1[2] = acrossCircle1R;
        bbPoint1[3] = dataNearIndex;
        bbPoint2[2] = acrossCircle2R;
        bbPoint2[3] = nearIndex;
        let bbPoints = [];
        bbPoints.push(bbPoint1);
        bbPoints.push(bottomBottomPoint);
        bbPoints.push(bbPoint2);
        bbPoints.push(addStreetAcrossData());
        bbPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        obj.streetacrossdata[2] = bbPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        bbFind = true;
      } else {
        if (!bbFind) obj.streetacrossdata[2] = [];
      }

      if (bottomTopPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[3].length > 2 && obj.streetacrossdata[3][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[3][0][2];
        }
        if (obj.streetacrossdata[3].length > 2 && obj.streetacrossdata[3][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[3][1][2];
        }
        let acrossCircle1 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle2R];
        let btPoint1 = GetAcrossSidePoint(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataEndIndex]);
        let btPoint2 = GetAcrossSidePoint(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomTopPoint,scaleData,scaleData,maxBottomPoints[endIndex]);
        btPoint1[2] = acrossCircle1R;
        btPoint1[3] = dataNearIndex;
        btPoint2[2] = acrossCircle2R;
        btPoint2[3] = nearIndex;
        let btPoints = [];
        btPoints.push(btPoint1);
        btPoints.push(bottomTopPoint);
        btPoints.push(btPoint2);
        btPoints.push(addStreetAcrossData());
        btPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        obj.streetacrossdata[3] = btPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        btFind = true;
      } else {
        if (!btFind) obj.streetacrossdata[3] = [];
      }
    }
  }
  //替换到顶层
  // workData.deleteObject(objid);
  // workData.addDataObject(obj);
}

/**
 *得到两个道路的交叉图形
 *
 * @export
 * @param {*} objid
 */
export function handleGravelAcross(objid) {
  let obj = workData.getObject(objid);
  if (obj === null || obj === undefined || obj.functype != FUNCTION_TYPE.GRAVEL) return;
  let maxheight = GetMaxHeight(obj);
  let maxTopPoints = GetStreetMaxPoints(obj, maxheight / 2, true);
  let maxBottomPoints = GetStreetMaxPoints(obj, maxheight / 2, false);
  let beginIndex = GetStreetAreaBegin(maxTopPoints);
  let centerIndex = maxTopPoints[beginIndex][3].rightindex;
  let endIndex = maxTopPoints[centerIndex][3].rightindex;
  let userData = workData.getUseData();
  let beginLine = GetStreetLineBegin(obj.groupdata);
  let endLine = GetStreetLineEnd(obj.groupdata);
  let ttFind = false,
    tbFind = false,
    btFind = false,
    bbFind = false;
  for (let i = 0; i < userData.length; i++) {
    if (userData[i].functype === FUNCTION_TYPE.GRAVEL && userData[i].operateid != obj.operateid) {
      let dataMaxHeight = GetMaxHeight(userData[i]);
      let maxDataTopPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, true);
      let maxDataBottomPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, false);
      let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
      let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
      let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
      let topTopPoint = [],
        topBottomPoint = [],
        bottomTopPoint = [],
        bottomBottomPoint = [];
      let dataBeginLine = GetStreetLineBegin(userData[i].groupdata);
      let dataEndLine = GetStreetLineEnd(userData[i].groupdata);
      StreetAreaAcrossPoint(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        topTopPoint
      );

      StreetAreaAcrossPoint(
        [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        topBottomPoint
      );
      StreetAreaAcrossPoint(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        bottomBottomPoint
      );
      StreetAreaAcrossPoint(
        [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        bottomTopPoint
      );
      let isByTimeAcross =
        ByTime(maxTopPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxTopPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      let isByTimeAcrossBottom =
        ByTime(maxBottomPoints[beginIndex], maxDataTopPoints[dataEndIndex], maxBottomPoints[endIndex]) ==
        THREE_POINT_STATUS_TYPE.BYTIME;
      if (topTopPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[0].length > 2 && obj.streetacrossdata[0][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[0][0][2];
        }
        if (obj.streetacrossdata[0].length > 2 && obj.streetacrossdata[0][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[0][1][2];
        }
        let acrossCircle1 = [topTopPoint[0], topTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [topTopPoint[0], topTopPoint[1], acrossCircle2R];
        let ttPoint1 = GetAcrossSidePoint(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          acrossCircle1,
          dataNearPoint
        ); //  ScalePoint(topTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataBeginIndex]);
        let ttPoint2 = GetAcrossSidePoint(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); // ScalePoint(topTopPoint,scaleData,scaleData,maxTopPoints[endIndex]);
        let ttPoints = [];
        ttPoint1[2] = acrossCircle1R;
        ttPoint1[3] = dataNearIndex;
        ttPoint2[2] = acrossCircle2R;
        ttPoint2[3] = nearIndex;
        ttPoints.push(ttPoint1);
        ttPoints.push(topTopPoint);
        ttPoints.push(ttPoint2);
        ttPoints.push(addStreetAcrossData());
        ttPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        obj.streetacrossdata[0] = ttPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        ttFind = true;
      } else {
        if (!ttFind) obj.streetacrossdata[0] = [];
      }

      if (topBottomPoint.length > 0) {
        let dataNearIndex = dataEndIndex,
          nearIndex = endIndex;
        if (!isByTimeAcross) {
          dataNearIndex = dataBeginIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxTopPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[1].length > 2 && obj.streetacrossdata[1][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[1][0][2];
        }
        if (obj.streetacrossdata[1].length > 2 && obj.streetacrossdata[1][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[1][1][2];
        }
        let acrossCircle1 = [topBottomPoint[0], topBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [topBottomPoint[0], topBottomPoint[1], acrossCircle2R];
        let tbPoint1 = GetAcrossSidePoint(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(topBottomPoint,scaleOwn,scaleOwn,maxDataBottomPoints[dataBeginIndex]);
        let tbPoint2 = GetAcrossSidePoint(
          [maxTopPoints[beginIndex], maxTopPoints[centerIndex], maxTopPoints[endIndex]],
          acrossCircle2,
          nearPoint
        );

        tbPoint1[2] = acrossCircle1R;
        tbPoint1[3] = dataNearIndex;
        tbPoint2[2] = acrossCircle2R;
        tbPoint2[3] = nearIndex;
        let tbPoints = [];
        tbPoints.push(tbPoint1);
        tbPoints.push(topBottomPoint);
        tbPoints.push(tbPoint2);
        tbPoints.push(addStreetAcrossData());
        tbPoints[3].acrossobjid =
          obj.operateid + '||' + String(beginLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        obj.streetacrossdata[1] = tbPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        tbFind = true;
      } else {
        if (!tbFind) obj.streetacrossdata[1] = [];
      }

      if (bottomBottomPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = endIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = beginIndex;
        }
        let dataNearPoint = maxDataBottomPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[2].length > 2 && obj.streetacrossdata[2][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[2][0][2];
        }
        if (obj.streetacrossdata[2].length > 2 && obj.streetacrossdata[2][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[2][1][2];
        }
        let acrossCircle1 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomBottomPoint[0], bottomBottomPoint[1], acrossCircle2R];
        let bbPoint1 = GetAcrossSidePoint(
          [
            maxDataBottomPoints[dataBeginIndex],
            maxDataBottomPoints[dataCenterIndex],
            maxDataBottomPoints[dataEndIndex],
          ],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomBottomPoint,scaleOwn,scaleOwn,maxDataBottomPoints[dataEndIndex]);
        let bbPoint2 = GetAcrossSidePoint(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomBottomPoint,scaleData,scaleData,maxBottomPoints[beginIndex]);
        bbPoint1[2] = acrossCircle1R;
        bbPoint1[3] = dataNearIndex;
        bbPoint2[2] = acrossCircle2R;
        bbPoint2[3] = nearIndex;
        let bbPoints = [];
        bbPoints.push(bbPoint1);
        bbPoints.push(bottomBottomPoint);
        bbPoints.push(bbPoint2);
        bbPoints.push(addStreetAcrossData());
        bbPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataEndLine);
        obj.streetacrossdata[2] = bbPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        bbFind = true;
      } else {
        if (!bbFind) obj.streetacrossdata[2] = [];
      }

      if (bottomTopPoint.length > 0) {
        let dataNearIndex = dataBeginIndex,
          nearIndex = beginIndex;
        if (!isByTimeAcrossBottom) {
          dataNearIndex = dataEndIndex;
          nearIndex = endIndex;
        }
        let dataNearPoint = maxDataTopPoints[dataNearIndex],
          nearPoint = maxBottomPoints[nearIndex];
        let acrossCircle1R = STREET_SPACE.ACROSS / 2;
        let acrossCircle2R = STREET_SPACE.ACROSS / 2;
        if (obj.streetacrossdata[3].length > 2 && obj.streetacrossdata[3][0][2] != undefined) {
          acrossCircle1R = obj.streetacrossdata[3][0][2];
        }
        if (obj.streetacrossdata[3].length > 2 && obj.streetacrossdata[3][1][2] != undefined) {
          acrossCircle2R = obj.streetacrossdata[3][1][2];
        }
        let acrossCircle1 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle1R];
        let acrossCircle2 = [bottomTopPoint[0], bottomTopPoint[1], acrossCircle2R];
        let btPoint1 = GetAcrossSidePoint(
          [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
          acrossCircle1,
          dataNearPoint
        ); //ScalePoint(bottomTopPoint,scaleOwn,scaleOwn,maxDataTopPoints[dataEndIndex]);
        let btPoint2 = GetAcrossSidePoint(
          [maxBottomPoints[beginIndex], maxBottomPoints[centerIndex], maxBottomPoints[endIndex]],
          acrossCircle2,
          nearPoint
        ); //ScalePoint(bottomTopPoint,scaleData,scaleData,maxBottomPoints[endIndex]);
        btPoint1[2] = acrossCircle1R;
        btPoint1[3] = dataNearIndex;
        btPoint2[2] = acrossCircle2R;
        btPoint2[3] = nearIndex;
        let btPoints = [];
        btPoints.push(btPoint1);
        btPoints.push(bottomTopPoint);
        btPoints.push(btPoint2);
        btPoints.push(addStreetAcrossData());
        btPoints[3].acrossobjid =
          obj.operateid + '||' + String(endLine) + '||' + userData[i].operateid + '||' + String(dataBeginLine);
        obj.streetacrossdata[3] = btPoints;
        userData[i].streetacrossdata = [[], [], [], []];
        btFind = true;
      } else {
        if (!btFind) obj.streetacrossdata[3] = [];
      }
    }
  }
  //替换到顶层
  // workData.deleteObject(objid);
  // workData.addDataObject(obj);
}

export function handleStreetRange(obj) {
  let maxheight = GetMaxHeight(obj);
  let maxTopPoints = GetStreetMaxPoints(obj, maxheight / 2, true);
  let maxBottomPoints = GetStreetMaxPoints(obj, maxheight / 2, false);
  var xMin = 0,
    yMin = 0,
    xMax = 0,
    yMax = 0;
  for (let i = 0; i < maxTopPoints.length; i++) {
    if (maxTopPoints[i][0] < xMin || xMin == 0) {
      xMin = maxTopPoints[i][0];
    }
    if (maxTopPoints[i][0] > xMax || xMax == 0) {
      xMax = maxTopPoints[i][0];
    }
    if (maxTopPoints[i][1] < yMin || yMin == 0) {
      yMin = maxTopPoints[i][1];
    }
    if (maxTopPoints[i][1] > yMax || yMax == 0) {
      yMax = maxTopPoints[i][1];
    }
  }
  for (let i = 0; i < maxBottomPoints.length; i++) {
    if (maxBottomPoints[i][0] < xMin || xMin == 0) {
      xMin = maxBottomPoints[i][0];
    }
    if (maxBottomPoints[i][0] > xMax || xMax == 0) {
      xMax = maxBottomPoints[i][0];
    }
    if (maxBottomPoints[i][1] < yMin || yMin == 0) {
      yMin = maxBottomPoints[i][1];
    }
    if (maxBottomPoints[i][1] > yMax || yMax == 0) {
      yMax = maxBottomPoints[i][1];
    }
  }
  return [[xMin, yMin], [xMax, yMax]];
}

/**
 * Get the rotation angle, streetId of the object to be attached to
 * @param {GeomPoint} point
 * @param {string} snapStyle
 * @return {{ angle: number, streetId: string }}
 */
export function getSnapToStreetData(point, snapStyle) {
  let angle = 0;
  let streetId = undefined;
  // console.log(getSnapToStreetData);
  const data = workData.getUseData().filter(n => n.functype === FUNCTION_TYPE.STREETNEW);
  // TODO: consider traverse useData reversely so the direction is determined by the newest added street (the top one)
  for (let i = 0; i < data.length; i++) {
    const street = data[i];
    if (!street.components || street.components.length===0 || !street.segments || street.segments.length === 0) {
      console.error(`Street has empty components/segments: ${JSON.stringify(street)}. Street data: ${JSON.stringify(street)}`);
      continue;
    }
    let sb = new StreetBorder(street, street.components[0]);
    if (sb.isPointInShape(street, point)) {
      streetId = street.operateid;
      let gpa = new GeomPolyArc(street.segments);
      if (street.offsetArcPath) gpa = new GeomPolyArc(street.offsetArcPath.segments);
      // which segment has the point that is closest to the given point
      let idx = gpa.getIndexOfSegmentClosestToPt(gpa.segments, point);
      const segment = gpa.segments[idx]
      let cpt = segment.getPointClosest(point);
      // TODO: compute the distance to determine the lane, and get the lane's travel direction
      // whether the travel direction of the vehicle at current point should be the same as the direction from ptStart -> ptStop
      let sameDir;
      if (segment instanceof GeomLine) {
        const rad = segment.getPointStart().AngleFromHorizontal(segment.getPointStop());
        angle = rad * 180 / Math.PI;
        const {ptStart, ptStop} = segment;
        const v1 = ptStart.getVector(point)
        const v2 = ptStart.getVector(ptStop)
        sameDir = v1.getRotationRad(v2) <= Math.PI
      } else if (segment instanceof GeomArc) {
        // GeomArc
        let gpo = segment.getOffsetsToPoint(point);
        let ga = segment.getAlignmentAtLength(gpo.fromStart, gpo.normal);
        angle = ga.rotateDeg;
        // FIXME: sign of gpo.normal is confusing with travelling direction, as 'normal' means the natrue right-hand travelling direction, and it means sth different here
        sameDir = gpo.normal <= 0;
      }

      switch (snapStyle) {
        case SNAP_TO_STREET_STYLE.PERPENDICULAR:
          angle = angle - 90;
          break;
        case SNAP_TO_STREET_STYLE.ALONG_WITH:
          if (!sameDir) {
            angle = angle + 180;
          }
          break;
        default:
          break;
      }
      break;
    }
  }
  // the angle returned is used for rotate() transform, which is clockwise, so we use (360 - angle) to make it right
  return {angle: 360 - angle, streetId};
}

/**
 * Transform the crosswalk to align/snap the crosswalk to the street
 * @export
 * @param {*} objid
 * @param {*} point
 */
export function AcrosswalkStreet(objid, point) {
  let obj = workData.getObject(objid);
  if (obj.functype == FUNCTION_TYPE.STREETNEW) {
    let _s = new _Street();
    let streetWidth = _s.getStreetWidth(obj);
    let stripes = _s.getStripes(obj);
    let h1 = 0;
    let gpa1 = new GeomPolyArc(stripes[0].segments);
    if (stripes[0].axis) {
      // is ramp
      gpa1 = new GeomPolyArc(stripes[0].offsetArcSegments);
      h1 = stripes[0].arcOffsetStart;
    }
    let h2 = 0;
    let gpa2 = new GeomPolyArc(stripes[stripes.length - 1].segments);
    if (stripes[stripes.length - 1].axis) {
      gpa1 = new GeomPolyArc(stripes[stripes.length - 1].offsetArcSegments);
      h2 = stripes[stripes.length - 1].arcOffsetStart;
    }
    let gpa = new GeomPolyArc(obj.segments);
    // road with curves, the 5th street style
    if (obj.offsetArcPath) gpa = new GeomPolyArc(obj.offsetArcPath.segments);
    var idx = gpa.getIndexOfSegmentClosestToPt(gpa.segments, new GeomPoint(point[0], point[1]));
    var cpt = gpa.segments[idx].getPointClosest(new GeomPoint(point[0], point[1]));
    var angle = 0;
    if (gpa.segments[idx].declaredClass == 'GeomLine')
      angle = gpa.segments[idx].getPointStart().AngleFromHorizontal(gpa.segments[idx].getPointStop());
    else {
      var gpo = gpa.segments[idx].getOffsetsToPoint(cpt);
      var ga = gpa.segments[idx].getAlignmentAtLength(gpo.fromStart, gpo.normal);
      angle = (ga.rotateDeg * Math.PI) / 180;
    }
    workData.addData(FUNCTION_TYPE.CROSSWALK);
    let cwobj = workData.getCurrentOperateObject();
    cwobj.style.pattern = CROSS_WALK_PATTERN.VERTICAL;
    cwobj.position.x1 = cpt.x - (streetWidth / 2 + h1 * 2) * Math.cos(angle - Math.PI / 2);
    cwobj.position.y1 = cpt.y - (streetWidth / 2 + h1 * 2) * Math.sin(angle - Math.PI / 2);
    cwobj.position.x2 = cpt.x + (streetWidth / 2 - h2 * 2) * Math.cos(angle - Math.PI / 2);
    cwobj.position.y2 = cpt.y + (streetWidth / 2 - h2 * 2) * Math.sin(angle - Math.PI / 2);
    cwobj = HandlePointCrosswalk(cwobj, CROSS_WALK_SPACE.HEIGHT);
    cwobj.style.strokewidth = 8;
    cwobj.snapToStreet = true;
    cwobj.selectflag = true;
    cwobj.streetId = objid;
    return;
  }
  let anchors = obj.handlepoint;
  let maxheight = GetMaxHeight(obj);
  let maxTopPoints = GetStreetMaxPoints(obj, maxheight / 2 - 1, true);
  let maxBottomPoints = GetStreetMaxPoints(obj, maxheight / 2 - 1, false);
  let i = GetStreetAreaBegin(anchors);

  while (i != -1 && anchors[i][3].rightindex != -1 && i != null) {
    let centerIndex = anchors[i][3].rightindex;
    let endIndex = anchors[centerIndex][3].rightindex;
    let circle = FindCircle(anchors[i], anchors[centerIndex], anchors[endIndex]);
    if (IsZero(circle[2])) {
      let pointsRange = GetTowPointRange(anchors[i], anchors[endIndex]);
      let acrossPoint = TowPointVerticalAcross(anchors[i], anchors[endIndex], point);
      if (
        pointsRange[0][0] <= acrossPoint[0] &&
        acrossPoint[0] <= pointsRange[1][0] &&
        pointsRange[0][1] <= acrossPoint[1] &&
        acrossPoint[1] <= pointsRange[1][1]
      ) {
        let acrossUpPoint = TowPointVerticalAcross(maxTopPoints[i], maxTopPoints[endIndex], point);
        let acrossDownPoint = TowPointVerticalAcross(maxBottomPoints[i], maxBottomPoints[endIndex], point);

        workData.addData(FUNCTION_TYPE.CROSSWALK);
        let newobj = workData.getCurrentOperateObject();
        newobj.style.pattern = CROSS_WALK_PATTERN.VERTICAL;
        newobj.position.x1 = acrossUpPoint[0];
        newobj.position.y1 = acrossUpPoint[1];
        newobj.position.x2 = acrossDownPoint[0];
        newobj.position.y2 = acrossDownPoint[1];
        newobj = HandlePointCrosswalk(newobj, CROSS_WALK_SPACE.HEIGHT / 2);
        newobj.selectflag = true;
      }
    } else {
      let acrossPoint = FindCircleLinePoint(circle, point);
      let isBytime = ByTime(anchors[i], anchors[centerIndex], anchors[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
      if (IsArcRangePoint(anchors[i], anchors[endIndex], circle, isBytime, acrossPoint)) {
        let topCircle = [circle[0], circle[1], LengthBetweenPoints(maxTopPoints[i], circle) - 1];
        let boCircle = [circle[0], circle[1], LengthBetweenPoints(maxBottomPoints[i], circle) + 1];
        if (!isBytime) {
          topCircle = [circle[0], circle[1], LengthBetweenPoints(maxTopPoints[i], circle) + 1];
          boCircle = [circle[0], circle[1], LengthBetweenPoints(maxBottomPoints[i], circle) - 1];
        }
        let acrossUpPoint = FindCircleLinePoint(topCircle, point);
        let acrossDownPoint = FindCircleLinePoint(boCircle, point);
        workData.addData(FUNCTION_TYPE.CROSSWALK);
        let newobj = workData.getCurrentOperateObject();
        newobj.style.pattern = CROSS_WALK_PATTERN.VERTICAL;
        newobj.position.x1 = acrossUpPoint[0];
        newobj.position.y1 = acrossUpPoint[1];
        newobj.position.x2 = acrossDownPoint[0];
        newobj.position.y2 = acrossDownPoint[1];
        newobj = HandlePointCrosswalk(newobj, CROSS_WALK_SPACE.HEIGHT / 2);
        newobj.selectflag = true;
      }
    }
    i = endIndex;
  }
}

/**
 *根据道路线 得到交汇点
 *  crosswalk 和street 相交
 * @export
 * @param {*} points1  crosswalk 三个点
 * @param {*} points2  street 三个点
 * @param {*} acrossPoints
 */
export function CrossWalkStreetAcrossPoint(points1, points2, acrossPoints) {
  let circle1 = FindCircle(points1[0], points1[1], points1[2]);
  let circle2 = FindCircle(points2[0], points2[1], points2[2]);
  if (IsZero(circle1[2]) && IsZero(circle2[2])) {
    let acrossPoint = TowLineAcrossPoint([points1[0], points1[2]], [points2[0], points2[2]]);
    let pointRange = GetTowPointRange(points2[0], points2[2]);
    if (IsRangePoint([points1[0], points1[2]], [points2[0], points2[2]])) {
      CopyPoint(acrossPoints, acrossPoint);
    } else if (LengthBetweenPoints(points1[0], acrossPoint) < 60 || LengthBetweenPoints(points1[2], acrossPoint) < 60)
      CopyPoint(acrossPoints, acrossPoint);
  } else if (!IsZero(circle1[2]) && IsZero(circle2[2])) {
    let lineKB2 = GetLineKB(points2[0], points2[2]);
    if (GetCircleLineLength(circle1, lineKB2) <= circle1[2]) {
      let isBytime1 = ByTime(points1[0], points1[1], points1[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
      let acrossPoint = GetCircleLineAcrossPoint(circle1, lineKB2);
      if (IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[0])) {
        // acrossPoints.push(acrossPoint[0]);
        CopyPoint(acrossPoints, acrossPoint[0]);
      }

      if (IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[1])) {
        // acrossPoints.push(acrossPoint[1]);
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
  } else if (IsZero(circle1[2]) && !IsZero(circle2[2])) {
    let lineKB1 = GetLineKB(points1[0], points1[2]);
    if (GetCircleLineLength(circle2, lineKB1) <= circle2[2]) {
      let isBytime2 = ByTime(points2[0], points2[1], points2[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
      let acrossPoint = GetCircleLineAcrossPoint(circle2, lineKB1);
      if (IsArcRangePoint(points2[0], points2[2], circle2, isBytime2, acrossPoint[0])) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (IsArcRangePoint(points2[0], points2[2], circle2, isBytime2, acrossPoint[1])) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
  } else if (!IsZero(circle1[2]) && !IsZero(circle2[2])) {
    if (LengthBetweenPoints(circle1, circle2) <= circle1[2] + circle2[2]) {
      let acrossPoint = TowCircleAcrossPoints(circle1, circle2);
      let isBytime1 = ByTime(points1[0], points1[1], points1[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
      if (IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[0])) {
        CopyPoint(acrossPoints, acrossPoint[0]);
      }
      if (IsArcRangePoint(points1[0], points1[2], circle1, isBytime1, acrossPoint[1])) {
        CopyPoint(acrossPoints, acrossPoint[1]);
      }
    }
  }
}

/**
 *
 * @param {string} objid
 * @param {[number, number]} point
 */
export function HandleCrossWalkAcross(objid, point) {
  let obj = workData.getObject(objid);
  if (obj === null || obj === undefined || obj.functype != FUNCTION_TYPE.CROSSWALK) return;
  let anchors = obj.handlepoint;
  let userData = workData.getUseData();
  let bFind = false;
  let dataObject = {};
  for (let i = 0; i < userData.length; i++) {
    if (userData[i].functype === 'StreetNewxxx') {
      var sb = new StreetBorder(userData[i], userData[i].components[0]);
      if (sb.isPointInShape(userData[i].segments, new GeomPoint(point[0], point[1]))) {
        let _s = new _Street();
        let streetWidth = _s.getStreetWidth(userData[i]);
        let gpa = new GeomPolyArc(userData[i].segments);
        if (userData[i].offsetArcPath) gpa = new GeomPolyArc(userData[i].offsetArcPath.segments);
        var idx = gpa.getIndexOfSegmentClosestToPt(gpa.segments, new GeomPoint(point[0], point[1]));
        var cpt = gpa.segments[idx].getPointClosest(new GeomPoint(point[0], point[1]));
        var angle = 0;
        if (gpa.segments[idx].declaredClass == 'GeomLine')
          angle = gpa.segments[idx].getPointStart().AngleFromHorizontal(gpa.segments[idx].getPointStop());
        else {
          var gpo = gpa.segments[idx].getOffsetsToPoint(cpt);
          var ga = gpa.segments[idx].getAlignmentAtLength(gpo.fromStart, gpo.normal);
          angle = (ga.rotateDeg * Math.PI) / 180;
        }
        obj.position.x1 = cpt.x - (streetWidth / 2) * Math.cos(angle - Math.PI / 2);
        obj.position.y1 = cpt.y - (streetWidth / 2) * Math.sin(angle - Math.PI / 2);
        obj.position.x2 = cpt.x + (streetWidth / 2) * Math.cos(angle - Math.PI / 2);
        obj.position.y2 = cpt.y + (streetWidth / 2) * Math.sin(angle - Math.PI / 2);
        var height = new GeomPoint(obj.handlepoint[1][0], obj.handlepoint[1][1]).distance(
          new GeomPoint(obj.handlepoint[2][0], obj.handlepoint[2][1])
        );
        obj = HandlePointCrosswalk(obj, height / 2);
      }
    } else if (
      userData[i].functype === FUNCTION_TYPE.STREET &&
      !userData[i].streetoffarc &&
      userData[i].operateid != obj.operateid
    ) {
      let dataMaxHeight = GetMaxHeight(userData[i]);
      let maxDataTopPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, true);
      let maxDataBottomPoints = GetStreetMaxPoints(userData[i], dataMaxHeight / 2, false);
      let dataBeginIndex = GetStreetAreaBegin(maxDataTopPoints);
      let dataCenterIndex = maxDataTopPoints[dataBeginIndex][3].rightindex;
      let dataEndIndex = maxDataTopPoints[dataCenterIndex][3].rightindex;
      let topTopPoint = [],
        topBottomPoint = [],
        bottomTopPoint = [],
        bottomBottomPoint = [];
      CrossWalkStreetAcrossPoint(
        [anchors[1], anchors[4], anchors[7]],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        topTopPoint
      );

      CrossWalkStreetAcrossPoint(
        [anchors[1], anchors[4], anchors[7]],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        topBottomPoint
      );
      CrossWalkStreetAcrossPoint(
        [anchors[2], anchors[5], anchors[8]],
        [maxDataBottomPoints[dataBeginIndex], maxDataBottomPoints[dataCenterIndex], maxDataBottomPoints[dataEndIndex]],
        bottomBottomPoint
      );
      CrossWalkStreetAcrossPoint(
        [anchors[2], anchors[5], anchors[8]],
        [maxDataTopPoints[dataBeginIndex], maxDataTopPoints[dataCenterIndex], maxDataTopPoints[dataEndIndex]],
        bottomTopPoint
      );
      if (
        IsPointValid(topTopPoint) &&
        IsPointValid(topBottomPoint) &&
        IsPointValid(bottomBottomPoint) &&
        IsPointValid(bottomTopPoint)
      ) {
        CopyPoint(anchors[1], topTopPoint);
        CopyPoint(anchors[7], topBottomPoint);
        CopyPoint(anchors[2], bottomTopPoint);
        CopyPoint(anchors[8], bottomBottomPoint);
        CopyPoint(anchors[4], GetCenterInTwoPoints(anchors[1], anchors[7]));
        CopyPoint(anchors[5], GetCenterInTwoPoints(anchors[2], anchors[8]));
        CopyPoint(anchors[0], GetCenterInTwoPoints(anchors[1], anchors[2]));
        CopyPoint(anchors[6], GetCenterInTwoPoints(anchors[7], anchors[8]));
        CopyPoint(anchors[3], GetCenterInTwoPoints(anchors[0], anchors[6]));
        dataObject = userData[i];
        bFind = true;
      }
    }
  }
  //替换到顶层
  if (bFind) {
    workData.deleteObject(objid);
    workData.addDataAfterObject(dataObject.operateid, obj);
  }
}
