import React from 'react';
import { GetLineHeightPoints, GetPathArcD, GetPathArcD1, GetShapes, GetPathBesselS1 } from '@src/data/BusinessFun';
import {
  FindCircle,
  IsArcDirection,
  IsBigArc,
  IsZero,
  GetCenter,
  getAngleBy2Points,
  GetCirclePoint,
  FindCircleLinePointK,
  GetArcCenter,
  ByTime,
  GetCirclePointK,
  LengthBetweenPoints,
  IsBigArc1,
  ScalePoint,
  GetCenterInTwoPoints,
  RotatePoint,
} from '@src/data/CommonFunc';
import {
  GetStreetAreaBegin,
  GetStreetBeginTopPoint,
  GetStreetEndTopPoint,
  GetStreetAreaEnd,
} from '@src/actions/StreetFunHandle';
import { GetShapePath } from '../ShapeFun';
import {
  CIRCLE_SIZE_TYPE,
  COLOR_TYPE,
  DASH_ARRAY_TYPE,
  STREET_SPACE,
  STREET_STRIPES_TYPE,
  THREE_POINT_STATUS_TYPE,
} from '@src/constant';

var HEIGHT = 3;
class StreetLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      points: props.points,
      linedata: props.linedata,
    };
  }
  UNSAFE_componentWillReceiveProps({ points, linedata }) {
    this.setState({
      points: points,
      linedata: linedata,
    });
  }
  getArcPathD(D, points) {
    // let centerIndex = points[beginIndex][3].rightindex;
    // let endIndex = points[centerIndex][3].rightindex;
    let circle = FindCircle(points[0], points[1], points[2]);
    let beginTopR = 0,
      arcEndR = this.state.linedata.topleftr;
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [],
      arcCenterPoint = [];
    let isArcDirection = '1';
    let beginTopPoint = GetStreetBeginTopPoint(this.state.linedata, points[0], points[1], points[2]);
    let isBytime = ByTime(points[0], points[1], points[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[0], points[2]);
      arcEndPoint = GetCenter(points[0], points[2], this.state.linedata.pathend);
      arcBeginPoint = GetCirclePoint(
        [arcEndPoint[0], arcEndPoint[1], this.state.linedata.topleftr * 2],
        angle + Math.PI
      );
      let arcTopAngle = this.state.linedata.upstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.topleftr],
        arcTopAngle
      );
    } else {
      arcEndR = 0;
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      /*
      this.state.linedata.upstate
        ? circle[2] + this.state.linedata.topleftr
        : circle[2] + -this.state.linedata.topleftr;
      */
      isArcDirection = IsArcDirection(points[0], points[1], points[2]);
      arcEndPoint = GetArcCenter(circle, points[0], points[2], this.state.linedata.pathend, isBytime);
      let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (isBytime ? (-this.state.linedata.topleftr * 2) / circle[2] : (this.state.linedata.topleftr * 2) / circle[2]);
      arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circle[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    }
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);
  }
  beginArcLine(shapes, beginIndex) {
    let points = this.state.points;
    let centerIndex = points[beginIndex][3].rightindex;
    let endIndex = points[centerIndex][3].rightindex;
    let circle = FindCircle(points[beginIndex], points[centerIndex], points[endIndex]);
    let beginTopR = 0;
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [],
      arcCenterPoint = [];
    let isArcDirection = '1';
    let beginTopPoint = GetStreetBeginTopPoint(
      this.state.linedata,
      points[beginIndex],
      points[centerIndex],
      points[endIndex]
    );
    let isBytime = ByTime(points[beginIndex], points[centerIndex], points[endIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;
    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[beginIndex], points[endIndex]);
      arcEndPoint = GetCenter(points[beginIndex], points[endIndex], this.state.linedata.pathend);
      arcBeginPoint = GetCirclePoint(
        [arcEndPoint[0], arcEndPoint[1], this.state.linedata.topleftr * 2],
        angle + Math.PI
      );
      let arcTopAngle = this.state.linedata.upstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.topleftr],
        arcTopAngle
      );
    } else {
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      isArcDirection = IsArcDirection(points[beginIndex], points[centerIndex], points[endIndex]);
      arcEndPoint = GetArcCenter(circle, points[beginIndex], points[endIndex], this.state.linedata.pathend, isBytime);
      let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (isBytime ? (-this.state.linedata.topleftr * 2) / circle[2] : (this.state.linedata.topleftr * 2) / circle[2]);
      arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circle[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    }
    let DHide = [];
    GetPathArcD(DHide, arcBeginPoint, arcEndPoint, circle[2], CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    shapes.push(
      GetShapePath(DHide, JSON.stringify(this.linedata) + 'beginArcHide', true, {
        stroke: COLOR_TYPE.TRANSPARENT,
        strokewidth: this.state.linedata.strokewidth + 3,
        fill: COLOR_TYPE.NONE,
      })
    );
    let D = [],
      Dtop = [],
      DBottom = [];
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      this.state.linedata.isUnderLine ? -STREET_SPACE.OFFSETARCANGLE : STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      this.state.linedata.isUnderLine ? -STREET_SPACE.OFFSETARCANGLE : STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);
    let topPoints = GetLineHeightPoints(points[beginIndex], points[centerIndex], points[endIndex], HEIGHT, true);
    let bottomPoints = GetLineHeightPoints(points[beginIndex], points[centerIndex], points[endIndex], HEIGHT, false);
    this.getArcPathD(Dtop, topPoints);
    this.getArcPathD(DBottom, bottomPoints);
    this.getLineShape(shapes, D, Dtop, DBottom, JSON.stringify(this.linedata) + 'beginarc');
  }
  getEndArcPathD(D, points) {
    // let centerIndex = points[beginIndex][3].rightindex;
    // let endIndex = points[centerIndex][3].rightindex;
    let circle = FindCircle(points[0], points[1], points[2]);
    let beginTopR = 0,
      arcEndR = this.state.linedata.toprightr;
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [],
      arcCenterPoint = [];
    let isArcDirection = '0';
    let beginTopPoint = GetStreetEndTopPoint(this.state.linedata, points[0], points[1], points[2]);
    let isBytime = ByTime(points[2], points[1], points[0]) === THREE_POINT_STATUS_TYPE.BYTIME;

    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[0], points[2]);
      arcEndPoint = GetCenter(points[0], points[2], this.state.linedata.pathrightend);
      arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], this.state.linedata.toprightr * 2], angle);
      let arcTopAngle = this.state.linedata.uprightstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.toprightr],
        arcTopAngle
      );
    } else {
      arcEndR = 0;
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      /*
      this.state.linedata.upstate
        ? circle[2] + this.state.linedata.topleftr
        : circle[2] + -this.state.linedata.topleftr;
      */
      isArcDirection = IsArcDirection(points[2], points[1], points[0]);
      arcEndPoint = GetArcCenter(circle, points[0], points[2], this.state.linedata.pathrightend, !isBytime);
      let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (!isBytime
          ? (this.state.linedata.toprightr * 2) / circle[2]
          : (-this.state.linedata.toprightr * 2) / circle[2]);
      arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circle[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    }
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);
  }
  endArcLine(shapes, endIndex) {
    let points = this.state.points;
    let centerIndex = points[endIndex][3].leftIndex;
    let beginIndex = points[centerIndex][3].leftIndex;
    let circle = FindCircle(points[beginIndex], points[centerIndex], points[endIndex]);
    let beginTopR = 0,
      arcEndR = this.state.linedata.toprightr;
    let arcEndPoint = [],
      arcBeginPoint = [],
      arcTopBeginPoint = [],
      arcCenterPoint = [];
    let isArcDirection = '0';
    let beginTopPoint = GetStreetEndTopPoint(
      this.state.linedata,
      points[beginIndex],
      points[centerIndex],
      points[endIndex]
    );
    let isBytime = ByTime(points[endIndex], points[centerIndex], points[beginIndex]) === THREE_POINT_STATUS_TYPE.BYTIME;

    if (IsZero(circle[2])) {
      let angle = getAngleBy2Points(points[beginIndex], points[endIndex]);
      arcEndPoint = GetCenter(points[beginIndex], points[endIndex], this.state.linedata.pathrightend);
      arcBeginPoint = GetCirclePoint([arcEndPoint[0], arcEndPoint[1], this.state.linedata.toprightr * 2], angle);
      let arcTopAngle = this.state.linedata.uprightstate ? angle - (Math.PI * 1) / 2 : angle + Math.PI / 2;
      arcTopBeginPoint = GetCirclePoint(
        [arcBeginPoint[0], arcBeginPoint[1], this.state.linedata.toprightr],
        arcTopAngle
      );
    } else {
      arcEndR = 0;
      beginTopR = LengthBetweenPoints(circle, beginTopPoint);
      /*
      this.state.linedata.upstate
        ? circle[2] + this.state.linedata.topleftr
        : circle[2] + -this.state.linedata.topleftr;
      */
      isArcDirection = IsArcDirection(points[endIndex], points[centerIndex], points[beginIndex]);
      arcEndPoint = GetArcCenter(
        circle,
        points[beginIndex],
        points[endIndex],
        this.state.linedata.pathrightend,
        !isBytime
      );
      let angleArcEnd = getAngleBy2Points(circle, arcEndPoint);
      let arcBenginAnlge =
        angleArcEnd +
        (!isBytime
          ? (this.state.linedata.toprightr * 2) / circle[2]
          : (-this.state.linedata.toprightr * 2) / circle[2]);
      arcBeginPoint = GetCirclePoint(circle, arcBenginAnlge);
      let scaleArcTopBegin = beginTopR / circle[2];
      arcTopBeginPoint = ScalePoint(arcBeginPoint, scaleArcTopBegin, scaleArcTopBegin, circle);
    }
    let DHide = [];
    GetPathArcD(DHide, arcBeginPoint, arcEndPoint, circle[2], CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    shapes.push(
      GetShapePath(DHide, JSON.stringify(this.linedata) + 'beginArcHide', true, {
        stroke: COLOR_TYPE.TRANSPARENT,
        strokewidth: this.state.linedata.strokewidth + 3,
        fill: COLOR_TYPE.NONE,
      })
    );
    let D = [],
      Dtop = [],
      DBottom = [];
    let arcCenter = GetCenterInTwoPoints(arcTopBeginPoint, arcEndPoint);
    let arcTopbeginPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcTopBeginPoint, arcCenter),
      this.state.linedata.isUnderLine ? STREET_SPACE.OFFSETARCANGLE : -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    let arcEndPointTemp = RotatePoint(
      GetCenterInTwoPoints(arcCenter, arcEndPoint),
      this.state.linedata.isUnderLine ? STREET_SPACE.OFFSETARCANGLE : -STREET_SPACE.OFFSETARCANGLE,
      arcCenter
    );
    GetPathArcD(D, beginTopPoint, arcTopBeginPoint, beginTopR, CIRCLE_SIZE_TYPE.SMALLARC, isArcDirection);
    GetPathBesselS1(D, arcTopbeginPointTemp, arcCenter, arcEndPointTemp, arcEndPoint);

    let topPoints = GetLineHeightPoints(points[beginIndex], points[centerIndex], points[endIndex], HEIGHT, true);
    let bottomPoints = GetLineHeightPoints(points[beginIndex], points[centerIndex], points[endIndex], HEIGHT, false);
    this.getEndArcPathD(Dtop, topPoints);
    this.getEndArcPathD(DBottom, bottomPoints);
    this.getLineShape(shapes, D, Dtop, DBottom, JSON.stringify(this.linedata) + 'endarc');
  }
  shapes = shapes => {
    let points = this.state.points;
    let Dtop = [],
      DBottom = [],
      D = [];

    let beginIndex = GetStreetAreaBegin(points);
    let i = beginIndex;
    while (i != -1 && i != null && points[i][3].rightindex != -1) {
      let begin = points[i];
      let center = points[begin[3].rightindex];
      let endpoint = points[center[3].rightindex];
      let topPoints = GetLineHeightPoints(begin, center, endpoint, HEIGHT, false);
      let bottomPoints = GetLineHeightPoints(begin, center, endpoint, HEIGHT, true);
      let circle = FindCircle(begin, center, endpoint);
      let isBigArc = IsBigArc(begin, endpoint, center, circle[2]);
      let isArcDirection = IsArcDirection(begin, center, endpoint);
      let topCircle = FindCircle(topPoints[0], topPoints[1], topPoints[2]);
      let bottomCircle = FindCircle(bottomPoints[0], bottomPoints[1], bottomPoints[2]);
      if (i === beginIndex) {
        GetPathArcD(Dtop, topPoints[0], topPoints[2], topCircle[2], isBigArc, isArcDirection);
        GetPathArcD(DBottom, bottomPoints[0], bottomPoints[2], bottomCircle[2], isBigArc, isArcDirection);
        GetPathArcD(D, begin, endpoint, circle[2], isBigArc, isArcDirection);
      } else {
        GetPathArcD1(Dtop, topPoints[2], topCircle[2], isBigArc, isArcDirection);
        GetPathArcD1(DBottom, bottomPoints[2], bottomCircle[2], isBigArc, isArcDirection);
        GetPathArcD1(D, endpoint, circle[2], isBigArc, isArcDirection);
      }

      i = center[3].rightindex;
    }

    this.getLineShape(shapes, D, Dtop, DBottom, JSON.stringify(this.state.linedata));
    let endIndex = GetStreetAreaEnd(points);
    if (!IsZero(this.state.linedata.topleftr)) this.beginArcLine(shapes, beginIndex);
    if (!IsZero(this.state.linedata.toprightr)) this.endArcLine(shapes, endIndex);
  };
  getLineShape(shapes, D, Dtop, DBottom, key) {
    let stripetype = this.state.linedata.stripetype;
    if (stripetype === STREET_STRIPES_TYPE.dash) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeDasharray="96,48" />);
    } else if (stripetype === STREET_STRIPES_TYPE.solid) {
      shapes.push(
        <path
          ttn-role="visibleCore"
          fill="none"
          d={D.join(' ')}
          key={'dash' + key}
          strokeDasharray={DASH_ARRAY_TYPE.solid}
        />
      );
    } else if (stripetype === STREET_STRIPES_TYPE.doublesolid) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
    } else if (stripetype === STREET_STRIPES_TYPE.doubledash) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray="96,48" />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray="96,48" />);
    } else if (stripetype === STREET_STRIPES_TYPE.dashsolid) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray="96,48" />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
    } else if (stripetype === STREET_STRIPES_TYPE.soliddash) {
      shapes.push(<path d={Dtop.join(' ')} key={'topline' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
      shapes.push(<path d={DBottom.join(' ')} key={'bottomline' + key} strokeDasharray="96,48" />);
    } else if (stripetype === STREET_STRIPES_TYPE.reflectors) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeDasharray="6,48" />);
    } else if (stripetype === STREET_STRIPES_TYPE.hide) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeOpacity="0" strokeWidth="0" />);
    } else if (stripetype === STREET_STRIPES_TYPE.splitpattem) {
      shapes.push(<path d={D.join(' ')} key={'dash' + key} strokeDasharray={DASH_ARRAY_TYPE.solid} />);
    } else {
      shapes.push(<path ttn-role="visibleCore" fill="none" d={D.join(' ')} key={'streetline' + key} />);
    }
  }

  render() {
    let commonProps = {
      stroke: this.state.linedata.stroke,
      strokeWidth: this.state.linedata.strokewidth,
      strokeDasharray: this.state.linedata.strokedasharray,
      fill: COLOR_TYPE.NONE,
    };
    HEIGHT = 0.75 * this.state.linedata.strokewidth * 2;
    let shape = [];
    this.shapes(shape);
    return (
      <g id={'streetline'} ttn-agent="stripe" {...commonProps}>
        {GetShapes(shape)}
      </g>
    );
  }
}

export default StreetLine;
