import * as React from 'react';

function SvgIconTrailer(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0-.002h24V24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M19 6H7c-1.103 0-2 .896-2 2v7H1v4h2v-2h2v2h8.185A2.994 2.994 0 0016 21a2.994 2.994 0 002.815-2H19c1.103 0 2-.896 2-2V8c0-1.104-.897-2-2-2zm-3 13c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1zm3-2h-.185A2.994 2.994 0 0016 15a2.994 2.994 0 00-2.815 2H7V8h12v9z" />
          <path d="M8 9h4v7H8zM14 9h4v4h-4z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconTrailer;
