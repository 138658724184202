import React from 'react';
import ArrowMarker from './ArrowMarker';
import { getArrowType } from '@src/data/BusinessFun';
import { GetShapeStyle } from '@src/data/CommonProps';
import {
  FindCircle,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetCenter,
  IsArcDirection,
  IsBigArc,
  IsOneLine,
  LengthBetweenPoints,
  TowPointVerticalLength,
  TowPointVerticalLengthPoints,
} from '@src/data/CommonFunc';
import Text from './Text';
import { CIRCLE_SIZE_TYPE, THREE_POINT_STATUS_TYPE, COLOR_TYPE, ARROW_SHOW_TYPE } from '@src/constant';

class ArrowDirection extends React.Component {
  constructor(props) {
    super(props);
  }

  getShape = (p1, p2, arcCenter, id) => {
    var D = [];
    let r = 0,
      isBigArc = CIRCLE_SIZE_TYPE.SMALLARC,
      arcDirection = '0';
    if (IsOneLine(p1, arcCenter, p2) === THREE_POINT_STATUS_TYPE.ONLINE) {
      r = 0;
      isBigArc = CIRCLE_SIZE_TYPE.SMALLARC;
      arcDirection = '0';
    } else {
      var circle = FindCircle(p1, p2, arcCenter);
      r = circle[2];
      isBigArc = IsBigArc(p1, p2, arcCenter, r);
      arcDirection = IsArcDirection(p1, arcCenter, p2);
    }
    D.push('M' + p1[0].toString() + ',' + p1[1].toString());
    D.push('A' + r.toString() + ',' + r.toString());
    D.push('0');
    D.push(isBigArc + ',' + arcDirection);
    D.push(p2[0].toString() + ',' + p2[1].toString());
    return <path d={D.join(' ')} fill={COLOR_TYPE.NONE} markerEnd={getArrowType(id, ARROW_SHOW_TYPE.BOTH, 1)} />;
  };
  render() {
    const { props } = this;
    const { p1, p2, center } = props;
    var id = new Date().getTime();
    id = String(id);
    return (
      <g id="arrowdirection">
        <ArrowMarker id={id} width={4} height={3} type={ARROW_SHOW_TYPE.ArrowSimple} />
        {this.getShape(p1, p2, center, id)}
      </g>
    );
  }
}

export default ArrowDirection;
