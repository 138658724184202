import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { InputNumber, Button } from 'antd';
import Utility from '@src/data/Utility';
import './custom-input-number.scss';

function CustomInputNumber(props) {
  const {
    min,
    max,
    step = 1,
    value,
    size = 'default',
    formatter,
    parser,
    disabled = false,
    formatterClass = '',
    style = {},
    isLoop,
  } = props;
  const { onChangeValue, onPlus, onMinus } = props;
  // const [gap, setGap] = useState(step);
  const [bWidth, setBWidth] = useState(50);
  const [isHoldingPlus, setHoldingPlus] = useState(false);
  const [isHoldingMinus, setHoldingMinus] = useState(false);
  let rotationRate = 10;
  let timeoutIdPlus = null;
  let intervalIdPlus = null;
  let timeoutIdMinus = null;
  let intervalIdMinus = null;
  const handlePlus = onPlus || plusValue;
  const handleMinus = onMinus || minusValue;

  useEffect(() => {
    setBWidth(size === 'small' ? 50 : 70);
  }, [size]);
  useEffect(() => {
    timeoutIdPlus = setTimeout(() => {
      if (isHoldingPlus) {
        intervalIdPlus = setInterval(() => {
          handlePlus();
        }, rotationRate);
      }
    });
    return () => {
      clearTimeout(timeoutIdPlus);
      clearInterval(intervalIdPlus);
    };
  }, [isHoldingPlus, value]);
  useEffect(() => {
    timeoutIdMinus = setTimeout(() => {
      if (isHoldingMinus) {
        intervalIdMinus = setInterval(() => {
          handleMinus();
        }, rotationRate);
      }
    });
    return () => {
      clearTimeout(timeoutIdMinus);
      clearInterval(intervalIdMinus);
    };
  }, [isHoldingMinus, value]);

  const changeValue = value => {
    // 点击+-号时 或 输入小数格式时
    if (typeof value === 'number') {
      if (value >= min && value < max) {
        onChangeValue(value);
      }
    }
    // 输入字符串格式时
    else if (typeof value === 'string') {
      let n = Utility.parse(value);
      if (n >= min && n < max) {
        onChangeValue(n);
      }
    }
  };

  /* ---------------------------- Plus value logic ---------------------------- */

  const plusMouseDown = () => setHoldingPlus(true);
  const plusMouseUp = () => setHoldingPlus(false);
  function plusValue() {
    if (!isLoop) {
      changeValue(_.round(value) + step);
      return;
    }
    if (value + step > max) {
      changeValue(min + step);
    } else if (value + step == max) {
      changeValue(min);
    } else {
      changeValue(_.round(value) + step);
    }
  }

  /* ---------------------------- Minus value logic ---------------------------- */
  const minusMouseDown = () => setHoldingMinus(true);
  const minusMouseUp = () => setHoldingMinus(false);
  function minusValue() {
    if (!isLoop) {
      changeValue(_.round(value) - step);
      return;
    }
    if (value - step < min) {
      changeValue(max - step);
    } else {
      changeValue(_.round(value) - step);
    }
  }

  const buttons = [
    {
      key: 1,
      icon: 'minus',
      handleMouseDown: minusMouseDown,
      handleMouseUp: minusMouseUp,
    },
    {
      key: 2,
      icon: 'plus',
      handleMouseDown: plusMouseDown,
      handleMouseUp: plusMouseUp,
    },
  ];

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputNumber
        disabled={disabled}
        value={value}
        min={min}
        max={max}
        step={step}
        style={style}
        formatter={value => (formatterClass === '' ? `${value}${formatter}` : Utility.format(value))}
        parser={value => value.replace(parser, '')}
        className={`custom-number-input custom-${size}`}
        onPressEnter={e => changeValue(e.target.value)}
      />
      <div
        className="addon-group"
        style={{
          width: bWidth,
          borderRadius: '0 4px 4px 0',
          marginLeft: -(bWidth + 1),
          zIndex: 1,
          height: 30,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {buttons.map(o => (
          <Button
            key={o.key}
            type="ghost"
            icon={o.icon}
            disabled={disabled}
            style={{ width: bWidth / 2, border: 0, height: 30 }}
            onMouseDown={o.handleMouseDown}
            onTouchStart={o.handleMouseDown}
            onMouseUp={o.handleMouseUp}
            onTouchEnd={o.handleMouseUp}
            onMouseLeave={o.handleMouseUp}
          ></Button>
        ))}
      </div>
    </div>
  );
}
export default CustomInputNumber;
