import * as React from 'react';

function SvgIconRoadSign(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.001 0h23.04v23.042H.001z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M22.707 11.293l-10-10a.999.999 0 00-1.414 0l-10 10a.999.999 0 000 1.414l10 10a.997.997 0 001.414 0l10-10a.999.999 0 000-1.414zM12 20.586L3.414 12 12 3.414 20.586 12 12 20.586z" />
          <path d="M12.994 9.253V9H15l-3-3-3 3h1.994v.253c0 .354-.081.45-.296.71-.309.373-.776.936-.698 2.041.094 1.308.882 1.887 1.405 2.269.438.321.565.431.591.798.026.366-.086.561-.341.965-.279.442-.661 1.046-.661 1.965h2c0-.323.114-.52.352-.897.303-.48.719-1.138.646-2.173-.093-1.309-.882-1.887-1.404-2.27-.438-.322-.565-.431-.592-.799-.021-.303.019-.352.242-.621.301-.364.756-.913.756-1.988z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconRoadSign;
