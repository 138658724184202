import * as React from 'react';

function SvgIconPaste(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M12.917 8.333V6.5A.916.916 0 0012 5.583h-.917v-.916h2.75v3.666h1.834V4.667a1.835 1.835 0 00-1.834-1.834h-2.75v-.916A.916.916 0 0010.167 1H6.5a.916.916 0 00-.917.917v.916h-2.75A1.835 1.835 0 001 4.667V17.5c0 1.011.822 1.833 1.833 1.833h7.334V17.5H2.833V4.667h2.75v.916h-.916a.916.916 0 00-.917.917v9.167c0 .507.41.916.917.916h5.5V14.75H5.583V7.417h5.5v.916h1.834zM9.25 5.583H7.417v-2.75H9.25v2.75z" />
          <path d="M22.083 10.167h-9.166a.917.917 0 00-.917.916v11c0 .507.41.917.917.917h9.166c.507 0 .917-.41.917-.917v-11a.916.916 0 00-.917-.916zm-.916 11h-7.334V12h7.334v9.167z" />
          <path d="M14.75 15.667h5.5V17.5h-5.5zM16.583 12.917h3.667v1.833h-3.667zM14.75 18.417h5.5v1.833h-5.5z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconPaste;
