import * as React from 'react';

function SvgIconDown(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 13" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h13v13H0z" />
        <path
          d="M13 3.778c0 .21-.08.393-.241.547L7.07 9.769A.798.798 0 016.5 10a.798.798 0 01-.571-.23L.24 4.324A.732.732 0 010 3.778c0-.21.08-.393.241-.547A.798.798 0 01.813 3h11.375c.22 0 .41.077.57.23.162.155.242.337.242.548z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconDown;
