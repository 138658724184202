import { SET_MATRIX, SET_MATRIX_INITIALIZED } from '@src/type/transform';
import { CANVAS } from '@src/constant';
import produce from 'immer';

const initialState = {
  matrixInitialized: false,
  matrix: [CANVAS.INITIAL_SCALE, 0, 0, -CANVAS.INITIAL_SCALE, 0, 0],
};

export default produce((draft, action) => {
  if (!draft) return initialState;

  switch (action.type) {
    case SET_MATRIX_INITIALIZED: {
      draft.matrixInitialized = action.value;
      break;
    }
    case SET_MATRIX: {
      draft.matrix = action.matrix;
      break;
    }
    default:
      break;
  }
});
