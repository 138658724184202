import React from 'react';
import { ARROW_SHOW_TYPE } from '@src/constant';

// strokeWidth 和 userSpaceOnUse
class ArrowMarker extends React.Component {
  constructor(props) {
    super(props);
    const { width, height, id, type } = this.props;
    this.data = {
      markerWidth: width + 2,
      markerHeight: height + 2,
      width,
      height,
      type,
      id,
    };
  }

  setData = props => {
    const { width, height, type, id } = props;
    this.data = {
      markerWidth: width + 2,
      markerHeight: height + 2,
      width,
      height,
      type,
      id,
    };
  };

  getArrowStartShape = () => {
    const { fill } = this.props;

    const D = [];

    if (this.data.type === ARROW_SHOW_TYPE.ArrowSimple) {
      D.push(`M${String(1)},${String(this.data.markerHeight / 2)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(this.data.markerHeight - 1)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(1)}`);
      D.push('Z');
    } else if (this.data.type === ARROW_SHOW_TYPE.ArrowTriangleLine) {
      D.push(`M${String(1)},${String(1)}`);
      D.push(`L${String(1)},${String(this.data.markerHeight - 1)}`);
      D.push(`M${String(1)},${String(this.data.markerHeight / 2)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(this.data.markerHeight - 1)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(1)}`);
      D.push('Z');
    } else if (this.data.type === ARROW_SHOW_TYPE.ArrowFancy) {
      D.push(`M${String(1)},${String(this.data.markerHeight / 2)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(this.data.markerHeight - 1)}`);
      D.push(`L${String(this.data.markerWidth / 2)},${String(this.data.markerHeight / 2)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(1)}`);
      D.push('Z');
    }

    return (
      <marker
        id={ARROW_SHOW_TYPE.StartOnly + this.data.id}
        markerWidth={this.data.markerWidth}
        markerHeight={this.data.markerHeight}
        refX={0}
        refY={this.data.markerHeight / 2}
        orient="auto"
        markerUnits="userSpaceOnUse"
      >
        <path d={D.join(' ')} fill={fill} stroke={fill} />
      </marker>
    );
  };

  getArrowEndShape = () => {
    const { fill } = this.props;
    const D = [];

    if (this.data.type === ARROW_SHOW_TYPE.ArrowSimple) {
      D.push('M1,1');
      D.push(`L${String(1)},${String(this.data.markerHeight - 1)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(this.data.markerHeight / 2)}`);
      D.push('Z');
    } else if (this.data.type === ARROW_SHOW_TYPE.ArrowTriangleLine) {
      D.push(`M${String(this.data.markerWidth - 1)},${String(1)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(this.data.markerHeight - 1)}`);
      D.push('M1,1');
      D.push(`L${String(1)},${String(this.data.markerHeight - 1)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(this.data.markerHeight / 2)}`);
      D.push('Z');
    } else if (this.data.type === ARROW_SHOW_TYPE.ArrowFancy) {
      D.push('M1,1');
      D.push(`L${String(this.data.markerWidth / 2)},${String(this.data.markerHeight / 2)}`);
      D.push(`L${String(1)},${String(this.data.markerHeight - 1)}`);
      D.push(`L${String(this.data.markerWidth - 1)},${String(this.data.markerHeight / 2)}`);
      D.push('Z');
    }

    return (
      <marker
        id={ARROW_SHOW_TYPE.StopOnly + this.data.id}
        markerWidth={this.data.markerWidth}
        markerHeight={this.data.markerHeight}
        refX={this.data.markerWidth}
        refY={this.data.markerHeight / 2}
        orient="auto"
        markerUnits="userSpaceOnUse"
      >
        <path d={D.join(' ')} fill={fill} stroke={fill} />
      </marker>
    );
  };

  render() {
    this.setData(this.props);
    return (
      <defs strokeWidth={1}>
        {/* should not add marker defs to CanvasSVG root, shapes can change the color and size of the markers belongs to it */}
        {/* TODO: possible to create a better way to identify markers */}
        {this.getArrowStartShape()}
        {this.getArrowEndShape()}
      </defs>
    );
  }
}

export default ArrowMarker;
