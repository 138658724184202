import React from 'react';
import _ from 'lodash';
import GeomPoint from '@src/data/GeomPoint';
import { GetPathLineD, GetPathLineD1 } from '@src/data/BusinessFun';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import utility from '@src/data/Utility';
import { ANCHORS_TYPE, COLOR_TYPE, DASH_ARRAY_TYPE } from '@src/constant';
import { SquareRectHandlePoint, CircleHandlePoint } from '@src/data/AnchorsPoint';

class SelectRect extends React.Component {
  constructor(props) {
    super(props);
    let anchors = SelectRect.getAnchors(props.anchors);
    let rmpt = SelectRect.getCirclePoint(anchors);
    this.state = {
      anchors,
      DString: '',
      rmpt,
    };
  }

  componentDidMount() {
    if (this.props.canChangeToLine) {
      let anchors = SelectRect.preventHandlePointOverlap(this.props.anchors);
      let rmpt = SelectRect.getCirclePoint(anchors);
      this.setState({ anchors, rmpt });
    }
    this.getPathD();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state.anchors, prevState.anchors) || this.props.rotateangle !== prevProps.rotateangle) {
      this.getPathD();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.anchors, state.anchors)) {
      let anchors = SelectRect.getAnchors(props.anchors);
      let rmpt = SelectRect.getCirclePoint(anchors);
      return { anchors, rmpt };
    }
    return null;
  }

  static getAnchors(anchors) {
    if (anchors.length >= 7) {
      return anchors;
    }
  }

  static getCirclePoint(anchors) {
    let rmpt = null;
    if (anchors.length >= 7) {
      let r = getUserUnitByPixel() * utility.getHandleRadius();
      var lmpt = new GeomPoint(anchors[6][0], anchors[6][1]);
      rmpt = new GeomPoint(anchors[7][0], anchors[7][1]);
      rmpt.offsetToward(lmpt, -r * 2.5);
    }
    return rmpt;
  }

  /**
   * HACK
   * @description 绘制textbox时，绘制出一条没有高度的线会导致控制点只有三个，且拖动中间控制点会令程序崩溃
   * @param {*} anchors
   */
  static preventHandlePointOverlap(anchors) {
    let points = [...anchors];
    if (points[0][1] === points[3][1]) {
      // 横向绘制一条线时
      points[2][1] -= 2;
      points[3][1] -= 2;
      points[5][1] -= 2;
      points[6][1] -= 1;
      points[7][1] -= 1;
      points[8][1] -= 1;
      points[10][1] -= 2;
    } else if (points[0][0] === points[3][0]) {
      // 纵向绘制一条线时
      points[1][0] -= 2;
      points[2][0] -= 2;
      points[4][0] -= 1;
      points[5][0] -= 1;
      points[7][0] -= 2;
      points[8][0] -= 3;
      points[9][0] -= 3;
      points[10][0] -= 3;
    }
    return points;
  }

  getPathD() {
    const anchors = this.state.anchors;
    if (anchors.length > 0) {
      let D = [];
      GetPathLineD(D, anchors[0], anchors[1]);
      GetPathLineD1(D, anchors[2]);
      GetPathLineD1(D, anchors[3]);
      D.push('Z');
      let DString = D.join(' ');
      this.setState({ DString });
    }
  }

  render() {
    const { anchors, rmpt } = this.state;
    const { showResizeHandles, isSquare } = this.props;
    const showHandlePoints = anchors.length >= 7 && !!showResizeHandles;

    return (
      <g lncd-role="object-selector-layer">
        <path d={this.state.DString}></path>
        {showHandlePoints && (
          <React.Fragment>
            <SquareRectHandlePoint key="1" id={ANCHORS_TYPE.LEFTTOP} point={anchors[0]} />
            <SquareRectHandlePoint key="2" id={ANCHORS_TYPE.RIGHTTOP} point={anchors[1]} />
            <SquareRectHandlePoint key="3" id={ANCHORS_TYPE.LEFTBOTTOM} point={anchors[3]} />
            <SquareRectHandlePoint key="4" id={ANCHORS_TYPE.RIGHTBOTTOM} point={anchors[2]} />
            {!isSquare && (
              <React.Fragment>
                <SquareRectHandlePoint key="5" id={ANCHORS_TYPE.TOPMEDIUM} point={anchors[4]} />
                <SquareRectHandlePoint key="6" id={ANCHORS_TYPE.BOTTOMMEDIUM} point={anchors[5]} />
                <SquareRectHandlePoint key="7" id={ANCHORS_TYPE.LEFTMEDIUM} point={anchors[6]} />
                <SquareRectHandlePoint key="8" id={ANCHORS_TYPE.RIGHTMEDIUM} point={anchors[7]} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {rmpt && <CircleHandlePoint id={ANCHORS_TYPE.ANGLE} point={rmpt} lncd-role="rotate-handle" />}
      </g>
    );
  }
}

export default SelectRect;
