import LaneMenu from '@src/components/Modal/Menu/LaneMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';
import TextMenu from '@src/components/Modal/Menu/TextMenu';
import TabItem from '@src/components/PropMenu/TabItem';
import { PARK_STALL_PATTERN_TYPE, PARK_STALL_SPACE } from '@src/constant';
import { FindCircle, GetCenterInTwoPoints, LengthBetweenPoints, ScalePoint } from '@src/data/CommonFunc';
import emitter from '@src/data/Event';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { Icon, Tabs } from 'antd';
import React from 'react';

export default class ParkStallModal extends React.Component {
  // Modal menu and items
  menu = {
    lane: ['stallWidth', 'stallLength'],
    style: ['stallType', 'width', 'lineColor'],
    // text: ['text', 'fontSize', 'color', 'style', 'position'],
  };

  state = {
    object: this.props.object,
    chord: LengthBetweenPoints(this.props.object.handlepoint[0], this.props.object.handlepoint[1]),
    ordinate: LengthBetweenPoints(
      this.props.object.handlepoint[2],
      GetCenterInTwoPoints(this.props.object.handlepoint[0], this.props.object.handlepoint[1])
    ),
    radius: FindCircle(
      this.props.object.handlepoint[0],
      this.props.object.handlepoint[1],
      this.props.object.handlepoint[2]
    )[2],
    tabKey: 'lane',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
    });
  }

  handleStrokeWidth = width => {
    var obj = this.state.object;
    obj.style.strokewidth = width;
    if (obj.arrow.width < obj.style.strokewidth * 2) {
      obj.arrow.width = obj.style.strokewidth * 2;
      obj.arrow.height = obj.arrow.width;
    }

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };

  handleStroke = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleLineColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.stroke = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  handleFill = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleFillColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.fill = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  handleMarks = e => {
    var obj = this.state.object;
    obj.marks.isexist = e.target.checked;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
  };
  handleMarksLenth = length => {
    var obj = this.state.object;
    obj.marks.length = length;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
  };

  pointLengthHandle = length => {
    var scale = length / this.state.pointlength;
    var obj = this.state.object;
    obj.handlepoint[1] = ScalePoint(obj.handlepoint[1], scale, scale, obj.handlepoint[0]);
    this.setState({ pointlength: length });
    this.setState({ object: obj });
    workData.setObj(obj.operateid, obj);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  stallWidthHandle = length => {
    var obj = this.state.object;
    obj.marks.length = length * 12;
    this.setState({ object: obj });
    workData.setObj(obj.operateid, obj);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };
  stallLength = height => {
    var obj = this.state.object;
    obj.marks.height = height * 12;
    this.setState({ object: obj });
    workData.setObj(obj.operateid, obj);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };

  patternHandle = type => {
    const obj = this.state.object;
    if (type === obj.style.pattern) return;
    // changing from standard or to standard style, swap height and width (length)
    if (obj.style.pattern === PARK_STALL_PATTERN_TYPE.STANDARD || type === PARK_STALL_PATTERN_TYPE.STANDARD) {
      const { height, length } = obj.marks;
      obj.marks.length = height;
      obj.marks.height = length;
    }
    obj.style.pattern = type;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'lane' in this.menu && (
            <Tabs.TabPane tab="lane" key="lane">
              <LaneMenu
                item={this.menu.lane}
                stateObject={this.state.object}
                stallWidthHandle={this.stallWidthHandle}
                stallLength={this.stallLength}
              />
            </Tabs.TabPane>
          )}
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
                wallWidth={this.state.strokewidth}
                patternHandle={this.patternHandle}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                pointLength={this.state.pointlength}
                pointLengthHandle={this.pointlengthHandle}
                width={this.state.width}
                height={this.state.height}
                radius={this.state.radius}
                angle={this.state.angle}
                handlewidth={this.handlewidth}
                handleheight={this.handleheight}
                handleradius={this.handleradius}
                handleAngle={this.handleAngle}
                handlemarkslength={this.handlemarkslength}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
