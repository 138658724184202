import * as React from 'react';

function SvgImageLineStyleSolid(props) {
  return (
    <svg {...props}>
      <path strokeDasharray={0} d="M5 10h215" fill="none" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}

export default SvgImageLineStyleSolid;
