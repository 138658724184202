import React, { Component } from 'react';
import { Button, Select, Slider, Icon } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './map-modal.scss';

class MapModal extends Component {
  render() {
    const { onSelectMapType, onReselect, onOK, onCancel, onClear, onSelectMap, onSlide } = this.props;
    const { service, opacity, currentLocation, mapConfigs } = this.props;

    let mapTypeItems = [];
    for (const obj of mapConfigs) {
      if (obj.provider === service.schema) {
        mapTypeItems = obj.types;
        break;
      }
    }

    return (
      <div className="custom-night-mode-text map-modal">
        <div className="map-modal-header">
          <span>{currentLocation ? currentLocation : `${service.latitude},${service.longitude}`}</span>
          <Button type="primary" onClick={onReselect}>
            {this.props.t('map.modal.changeButtonText')}
          </Button>
        </div>
        <div className="map-modal-body">
          <div className="left-section">
            <div className="lncd-map-modal-title">{this.props.t('map.modal.opacityLabel')}</div>
            <div className="article">
              <Slider
                className="lncd-slider"
                style={{ marginLeft: 0, flex: 1 }}
                value={opacity}
                min={0}
                max={1}
                step={0.01}
                tipFormatter={v => `${Number.parseFloat(v * 100).toFixed(0)}%`}
                onChange={onSlide}
              />
              <span style={{ margin: '0 4px' }}>{`${Number.parseFloat(opacity * 100).toFixed(0)}%`}</span>
            </div>
          </div>
          <div className="right-section">
            <div className="lncd-map-modal-title">{this.props.t('map.modal.serviceProviderLabel')}</div>
            <Select
              value={service.schema}
              onChange={onSelectMap}
              style={{ width: '100%' }}
              className="custom-select-add"
              suffixIcon={<Icon type="caret-down" className="custom-night-mode-text lncd-select-suffix" />}
            >
              {mapConfigs.map(v => (
                <Select.Option key={v.provider} value={v.provider} disabled={!v.enabled || v.types.length === 0}>
                  {this.props.t(`map.serviceProvider.${v.provider}`)}
                </Select.Option>
              ))}
            </Select>
            <div className="lncd-map-modal-title">{this.props.t('map.modal.displayTypeLabel')}</div>
            <Select
              value={service.type}
              style={{ width: '100%' }}
              onChange={onSelectMapType}
              className="custom-select-add"
              suffixIcon={<Icon type="caret-down" className="custom-night-mode-text lncd-select-suffix" />}
            >
              {mapTypeItems.map(item => {
                return (
                  <Select.Option key={item.id} value={item.value}>
                    {this.props.t(`map.mapType.${item.value}`)}
                  </Select.Option>
                );
              })}
            </Select>
            <div className="btns">
              <Button type="primary" onClick={onOK}>
                {this.props.t('map.modal.okButtonText')}
              </Button>
              <Button type="ghost" onClick={onCancel}>
                {this.props.t('map.modal.cancelButtonText')}
              </Button>
              <Button type="ghost" onClick={onClear}>
                {this.props.t('map.modal.clearButtonText')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  service: state.map.service,
  opacity: state.map.opacity,
  currentLocation: state.map.currentLocation,
  mapConfigs: state.map.mapConfigs,
}))(withTranslation()(MapModal));
