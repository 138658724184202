import React from 'react';
import { Icon, Tabs } from 'antd';
import { GetStructureAngle } from '@src/data/BusinessFun';
import { GetAngleArc, GetAngleDegree, IsEqual } from '@src/data/CommonFunc';
import { TransformRotateAngleCloseShape } from '@src/actions/TransformHandle';
import TabItem from '@src/components/PropMenu/TabItem';
import emitter from '@src/data/Event';
import { ROAD_CONSTANT } from '@src/type/event';
import { ANCHORS_TYPE, COLOR_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import _ from 'lodash';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';

export default class StructureModal extends React.Component {
  // Modal menu and items
  menu = {
    style: ['wallWidth', 'color'],
    size: ['rotation'],
    // text: ['text', 'fontSize', 'color', 'style'],
  };

  constructor(props) {
    super(props);

    const { object } = this.props;
    // let widthDisable = false;
    // let angleDisable = false;
    let strokeWidthEqual = true;

    // let indexCount = 0;
    let selectIndex = -1;
    let strokeWidth = object.handlepoint[0][3].strokewidth;

    let stroke = COLOR_TYPE.BLACK;

    for (let i = 0, il = object.handlepoint.length; i < il - 1; i++) {
      let type = object.handlepoint[i][2];
      let attr = object.handlepoint[i][3];
      // if (object.handlepoint[i][3].index == -1) {
      //   indexCount++;
      // }
      if (type === ANCHORS_TYPE.STRUCTUREBEGIN) {
        if (attr.selectflag === true) {
          // angleDisable = true;
          selectIndex = i;
          strokeWidth = object.handlepoint[i][3].strokewidth;
          stroke = object.handlepoint[i][3].stroke;
        }
      }
      if (type === ANCHORS_TYPE.STRUCTUREBEGIN) {
        if (strokeWidth === 0) {
          strokeWidth = object.handlepoint[i][3].strokewidth;
        }
        if (!IsEqual(strokeWidth, object.handlepoint[i][3].strokewidth)) {
          strokeWidthEqual = false;
        }
      }
    }

    // if (indexCount > 2) {
    //   // 要排除首和尾的情况
    //   widthDisable = true;
    // }

    this.state = {
      object: object,
      strokeWidth: strokeWidth,
      // widthDisable: widthDisable,
      // angleDisable: angleDisable,
      // indexCount: indexCount,
      strokeWidthEqual: strokeWidthEqual,
      selectIndex: selectIndex,
      stroke: stroke,
      angle: this.calculateAngle(props),
      tabKey: 'style',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
      angle: this.calculateAngle(nextProps),
    });
  }

  componentDidUpdate() {
    let obj = this.props.object;
    let selectIndex = obj.handlepoint.findIndex(x => x[3] && x[3].selectflag);
    if (selectIndex !== this.state.selectIndex) {
      if (selectIndex > -1) {
        const attr = obj.handlepoint[selectIndex][3];
        this.setState({
          strokeWidth: attr.strokewidth,
          stroke: attr.stroke,
        });
      }
      this.setState({ selectIndex: selectIndex });
    }
  }

  calculateAngle(props) {
    const { object } = props;
    const { InitialAngularDeviation = 0 } = object;
    const arc = GetStructureAngle(object.handlepoint);
    const fixedArc = arc - InitialAngularDeviation;
    const angle = GetAngleDegree(fixedArc, 0);
    return angle < 0 ? 360 + angle : angle;
  }

  handleStrokeWidth = width => {
    let obj = this.props.object;
    if (this.state.selectIndex == -1) {
      // 改变整个Structure
      if (this.state.strokeWidthEqual) {
        for (let i = 0; i < obj.handlepoint.length - 1; i++) {
          if (obj.handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
            obj.handlepoint[i][3].strokewidth = width;
          }
        }
      }
    } else {
      // 改变Structure其中一段
      obj.handlepoint[this.state.selectIndex][3].strokewidth = width;
    }

    workData.setObj(obj.operateid, obj);
    this.setState({ strokeWidth: width, object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    // this.props.handle();
  };

  handleStroke = evt => {
    let colortype = evt.currentTarget.id;
    let obj = this.state.object;
    if (this.state.selectIndex == -1) {
      for (let i = 0; i < obj.handlepoint.length - 1; i++) {
        if (obj.handlepoint[i][2] === ANCHORS_TYPE.STRUCTUREBEGIN) {
          obj.handlepoint[i][3].stroke = colortype;
        }
      }
    } else {
      obj.handlepoint[this.state.selectIndex][3].stroke = colortype;
    }
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj, stroke: colortype });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    // this.props.handle();
  };

  handleAngle = angleValue => {
    const { object, angle } = this.state;
    const angleArc = GetAngleArc(angleValue - angle, 5);

    object.handlepoint = TransformRotateAngleCloseShape(object.handlepoint, angleArc);

    workData.setObj(object.operateid, object);

    this.setState({ object: object, angle: angleValue });
    this.props.handle();
  };
  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
                wallWidth={this.state.strokeWidth}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                // width={this.state.width}
                // height={this.state.height}
                // radius={this.state.radius}
                angle={this.state.angle}
                // handlewidth={this.handlewidth}
                // handleheight={this.handleheight}
                // handleradius={this.handleradius}
                handleAngle={this.handleAngle}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
