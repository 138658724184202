import GeomPolyArc from './GeomPolyArc';
import GeomPoint from './GeomPoint';
import utility from './Utility';
import _ from 'lodash';
import Axial from './Axial';

class GeomStripe {
  /**
   *
   * @param {GeomPolyArc} axis
   * @param {number} offset
   * @param {array} patterns
   * @param {array} hiddenSegs
   * @param {number} strokeWidth
   * @param {object} [turnbaySpec]
   * @param {*} turnbaySpec.transitionPercentFromStart
   * @param {*} turnbaySpec.stopOffset
   * @param {*} turnbaySpec.multiSegment
   * @param {boolean} turnbaySpec.atEnd
   * @param {number} [highlightPatternIndex]
   */
  constructor(axis, offset, patterns, hiddenSegs, strokeWidth, turnbaySpec, highlightPatternIndex = -1) {
    this.highlightPatternIndex = highlightPatternIndex;

    if (0 === offset) {
      this.axis = axis;
    } else {
      this.axis = axis.clone();
      this.axis.offsetNormal(offset);
    }
    this.segments;
    // save a copy of the original patterns from street object
    this._patterns = patterns;
    this.patterns = [];
    if (utility.isNonEmptyArray(hiddenSegs)) {
      this.patterns = this._mergePatterns(patterns, hiddenSegs);
    } else {
      this.patterns = patterns;
    }
    this.strokeWidth = strokeWidth;
    this.turnbaySpec = turnbaySpec;
    if (turnbaySpec) {
      this.patterns = this._applyTurnBayPattern(patterns, turnbaySpec);
    }
  }

  get highlightRange() {
    const range = [1, 1];
    const index = this.highlightPatternIndex;
    if (index > -1) {
      const highlightPattern = this._patterns[index];
      range[0] = highlightPattern.startPct;
      // ended at stripe's end point or next pattern's start point
      if (index !== this._patterns.length - 1) {
        range[1] = this._patterns[index + 1].startPct;
      }
    }
    return range;
  }

  /**
   * 转弯图案?
   * @param {array} patterns
   * @param {*} turnbaySpec
   * @param {*} turnbaySpec.atEnd
   * @param {*} turnbaySpec.multiSegment
   * @param {*} turnbaySpec.transitionPercentFromStart
   * @param {*} turnbaySpec.stopOffset
   * @returns {array}
   */
  _applyTurnBayPattern(patterns, turnbaySpec) {
    var bc44 = [],
      bc45,
      bc46,
      bc47 = this.axis.getLength(),
      bc48;
    function bc49(bc4a, index, pct) {
      var bc4b = [],
        bc4c = 0;
      for (var i = 0; i <= index; i++) {
        bc4b.push(bc4a[i]);
      }
      if (index === bc4a.length - 1) {
        bc4b.push({ pattern: 'singlesolid', startPct: 0, paintable: true });
        bc4b[bc4b.length - 1].startPct = pct;
        return bc4b;
      }
      for (i = index; i < bc4a.length; i++) {
        if (bc4a[i].startPct < pct) {
          bc4c = i;
        }
      }
      if (bc4c === index) {
        for (i = index + 1; i < bc4a.length; i++) {
          bc4b.push(bc4a[i]);
        }
      } else {
        for (i = bc4c; i < bc4a.length; i++) {
          bc4b.push(bc4a[i]);
        }
      }
      if (index < bc4b.length - 1) {
        bc4b[index + 1].startPct = pct;
      }
      return bc4b;
    }
    function bc4d(bc4e, index, pct) {
      var bc4f = [],
        last = bc4e.length - 1,
        bc50 = 0;
      for (var i = 0; i < index; i++) {
        if (bc4e[i].startPct < pct) {
          bc4f.push(bc4e[i]);
          bc50 = i;
        }
      }
      if (bc50 === index) {
        bc4f.push(bc4e[index]);
        for (i = index + 1; i < bc4e.length; i++) {
          bc4f.push(bc4e[i]);
        }
        bc4f[index].startPct = pct;
        if (index === 0 && pct !== 0) {
          bc4f.unshift({ pattern: 'singlesolid', startPct: 0, paintable: true });
        }
        if (bc4f[1].startPct === 0) {
        }
        return bc4f;
      }
      bc4f.push(bc4e[index]);
      bc4f[bc4f.length - 1].startPct = pct;
      for (i = index + 1; i < bc4e.length; i++) {
        bc4f.push(bc4e[i]);
      }
      if (bc4f[1].startPct === 0) {
      }
      return bc4f;
    }
    function bc51(bc52) {
      for (var i = 0; i < bc52.length; i++) {
        if (!bc52[i].paintable) {
          return i;
        }
      }
      return -1;
    }
    if (turnbaySpec) {
      if (turnbaySpec.atEnd) {
        if (turnbaySpec.multiSegment) {
          bc46 = this.axis.segments[this.axis.segments.length - 1].getLength();
          bc48 = (bc47 - bc46 * (1 - turnbaySpec.transitionPercentFromStart)) / bc47;
          bc48 += (2.5 * Math.abs(turnbaySpec.stopOffset)) / bc47;
        } else {
          var bc48 = (turnbaySpec.transitionPercentFromStart * bc47 + 2.5 * Math.abs(turnbaySpec.stopOffset)) / bc47;
        }
        bc45 = bc51(patterns);
        if (turnbaySpec.stopOffset === 0) {
          bc44 = patterns;
          bc44.splice(bc45 + 1);
          return bc44;
        }
        bc44 = bc49(patterns, bc45, bc48);
      } else {
        bc46 = this.axis.segments[0].getLength();
        bc48 = (bc46 * turnbaySpec.transitionPercentFromStart) / bc47;
        bc48 -= (2.5 * Math.abs(turnbaySpec.stopOffset)) / bc47;
        bc45 = bc51(patterns);
        if (turnbaySpec.stopOffset === 0) {
          bc44 = patterns.slice(bc45);
          bc44[0].startPct = 0;
          return bc44;
        }
        bc44 = bc4d(patterns, bc45, bc48);
      }
      return bc44;
    } else {
      return patterns;
    }
  }

  /**
   * 合并图案
   * @param {array} patterns
   * @param {array} hiddenSegs
   * @returns {array}
   */
  _mergePatterns(patterns, hiddenSegs) {
    if (0 === hiddenSegs.length) {
      return patterns;
    }
    var newPatterns = [];
    var startPct = hiddenSegs[0].startPct;
    patterns.forEach(function(pattern) {
      if (pattern.startPct < startPct) {
        newPatterns.push(pattern);
      }
    });
    for (let i = 0; i < hiddenSegs.length; i++) {
      var seg = hiddenSegs[i];
      newPatterns.push({
        pattern: 'invisible',
        startPct: seg.startPct,
      });
      var stopPct = seg.stopPct;
      if (stopPct < 1) {
        var index = 0;
        for (let j = 0; j < patterns.length; j++) {
          if (patterns[j].startPct < stopPct) {
            index = j;
          }
        }
        newPatterns.push({
          pattern: patterns[index].pattern,
          startPct: stopPct,
        });
        if (i < hiddenSegs.length - 1) {
          var startPct = hiddenSegs[i + 1].startPct;
          for (let j = index + 1; j < patterns.length; j++) {
            if (patterns[j].startPct < startPct) {
              newPatterns.push(patterns[j]);
            } else {
              break;
            }
          }
        } else {
          for (let j = index + 1; j < patterns.length; j++) {
            newPatterns.push(patterns[j]);
          }
        }
      }
    }
    return newPatterns;
  }

  /**
   * 计算渲染路径
   * @param {boolean} [isHighlightPath]
   * @returns {string} path
   */
  computeRenderPath(isHighlightPath = false) {
    var path = '';
    if (this.axis && utility.isNonEmptyArray(this.segments) && utility.isNonEmptyArray(this.patterns)) {
      for (let i = 0; i < this.patterns.length; i++) {
        var pattern = this.patterns[i];
        if (pattern.pattern == 'invisible') continue;
        var end = i === this.patterns.length - 1 ? 1 : this.patterns[i + 1].startPct;
        let pp = '';
        if (!isHighlightPath) {
          pp = this._computeRenderPathForPattern(pattern, end);
        } else {
          pp = this._computeHighlightPathSegment(pattern.startPct, end);
        }

        if (utility.isNonEmptyString(pp)) {
          if (path.length > 0) {
            path += ' ';
          }
          path += pp;
        }
      }
    }
    return path;
  }

  _computeHighlightPathSegment(startPct, endPct) {
    let path = '';
    const [start, end] = this.highlightRange;
    if (startPct >= start && startPct < end) {
      const gpa = new GeomPolyArc(this.segments);
      const length = gpa.getLength();
      path = gpa.getPartialSvgPathData(length * startPct, 1 === endPct ? -1 : length * endPct, true);
    }
    return path;
  }

  /**
   * 计算渲染路径，根据图案
   * @param {object} pattern
   * @param {string} pattern.pattern
   * @param {number} startPct this is the startPct of next segment, i.e. END. boom!
   * @returns {string} path
   */
  _computeRenderPathForPattern(pattern, startPct) {
    var path = '',
      gap = 5;
    if (pattern.pattern === 'singlesolid') {
      path = this._computeRenderPath_solid(pattern, startPct);
    } else if (pattern.pattern === 'singledash') {
      path = this._computeRenderPath_dashed(pattern, startPct);
    } else if (pattern.pattern === 'doublesolid') {
      path = this._computeRenderPath_solid(pattern, startPct, gap);
      path = path + ' ' + this._computeRenderPath_solid(pattern, startPct, -gap);
    } else if (pattern.pattern === 'doubledash') {
      path = this._computeRenderPath_dashed(pattern, startPct, gap);
      path = path + ' ' + this._computeRenderPath_dashed(pattern, startPct, -gap);
    } else if (pattern.pattern === 'soliddash') {
      path = this._computeRenderPath_solid(pattern, startPct, gap);
      path = path + ' ' + this._computeRenderPath_dashed(pattern, startPct, -gap);
    } else if (pattern.pattern === 'dashsolid') {
      path = this._computeRenderPath_dashed(pattern, startPct, gap);
      path = path + ' ' + this._computeRenderPath_solid(pattern, startPct, -gap);
    } else if (pattern.pattern === 'reflectors') {
      path = this._computeRenderPath_reflectors(pattern, startPct);
    } else if (pattern.pattern === 'irregular') {
      path = this._computeRenderPath_irregular(pattern, startPct);
    } else {
      path = this._computeRenderPath_solid(pattern, startPct);
    }
    return path;
  }

  /**
   *
   * @param {object} pattern
   * @param {number} pattern.startPct
   * @param {number} startPct
   * @param {number} offset
   * @returns {string} path
   */
  _computeRenderPath_solid(pattern, startPct, offset) {
    var path = '';
    var gpa = new GeomPolyArc(this.segments);
    var length = gpa.getLength();
    if (offset) {
      var ogpa = gpa.clone();
      ogpa.offsetNormal(offset);
      if (0 === pattern.startPct && 1 === startPct) {
        path = ogpa.getSvgPathData(0, -1);
      } else {
        var bc79 =
          0 === pattern.startPct ? 0 : this._getParallelPathFromStartDistance(pattern.startPct * length, gpa, ogpa);
        var bc7a = 1 === startPct ? -1 : this._getParallelPathFromStartDistance(startPct * length, gpa, ogpa);
        path = ogpa.getPartialSvgPathData(bc79, bc7a);
      }
    } else {
      if (0 === pattern.startPct && 1 === startPct) {
        path = gpa.getSvgPathData(0, -1);
      } else {
        path = gpa.getPartialSvgPathData(length * pattern.startPct, 1 === startPct ? -1 : length * startPct, true);
      }
    }
    return path;
  }

  /**
   *
   * @param {*} pattern
   * @param {number} pattern.startPct
   * @param {0 | 1} startPct
   * @param {number} offset - 与平行线的偏移量
   * @returns {string}
   */
  _computeRenderPath_dashed(pattern, startPct, offset) {
    var dashLength = 96;
    var gapLength = 48;
    var initDash = 96;
    var initGap = 0;
    var path = '';
    var gpa = new GeomPolyArc(this.segments);
    var length = gpa.getLength();
    var startLen = pattern.startPct * length;
    var s = startPct * length;
    var isLoop = true;
    while (s - startLen > 0) {
      if (isLoop) {
        var x = startLen;
        var y = Math.min(startLen + dashLength, s);
        if (utility.isValid(offset)) {
          var ogpa = gpa.clone();
          ogpa.offsetNormal(offset);

          /**
           * Make parallel path only show in the first section.
           */
          // if (x > 0) {
          //   x = this._getParallelPathFromStartDistance(x, gpa, ogpa);
          // }
          // y = this._getParallelPathFromStartDistance(y, gpa, ogpa);

          if (x < y) {
            path += ogpa.getPartialSvgPathData(x, y) + ' ';
          }
        } else {
          if (x < y) {
            path += gpa.getPartialSvgPathData(x, y) + ' ';
          }
        }
        startLen += dashLength;
      } else {
        startLen += gapLength;
      }
      isLoop = !isLoop;
    }
    return path;
  }

  /**
   * 计算渲染路径：reflectors
   * @param {object} pattern
   * @param {string} pattern.pattern
   * @param {number} pattern.startPct
   * @param {number} endPct
   * @returns {string}
   */
  _computeRenderPath_reflectors(pattern, endPct) {
    var gap = 24; // 值越大，间隙越大
    var path = '';
    var gpa = new GeomPolyArc(this.segments);
    var length = gpa.getLength();
    // var r = 0.75 * this.strokeWidth;
    var r = 0.1; // 圆点半径
    let s = pattern.startPct * length + r * 1;
    let bc5c = endPct * length - r * 1;
    let bc5d = utility.toFixed(r, 4);
    const bc5e = ' a' + bc5d + ',' + bc5d + ' 0 1 1 ';
    let pt, bc61, bc62;
    for (var i = s; i < bc5c; i += gap) {
      pt = gpa.getPointAtLength(i);
      pt.offset(-r, 0);
      bc61 = new GeomPoint(r * 2, 0);
      bc62 = new GeomPoint(-r * 2, 0);
      path += 'M' + pt.toString() + bc5e + bc61.toString() + bc5e + bc62.toString() + ' ';
    }
    return path.trimRight();
  }

  /**
   * 不规则路径
   * @param {object} pattern
   * @param {string} pattern.pattern
   * @param {number} pattern.startPct
   * @param {number} startPct
   * @returns {string}
   */
  _computeRenderPath_irregular(pattern, startPct) {
    var gap = 18;
    var path = '';
    var gpa = new GeomPolyArc(this.segments);
    var length = gpa.getLength();
    var ps = pattern.startPct * length;
    var s = startPct * length;
    var pt,
      seed = 1,
      bc74 = function() {
        seed = Math.sin(seed) * 10000;
        seed -= Math.floor(seed);
        return seed;
      };
    path = 'M' + gpa.getPointAtLength(ps).toString();
    for (var i = ps + gap; i < s; i += gap) {
      pt = gpa.getPointAtLength(i);
      var bc73 = 4 * (bc74() - 0.5);
      path += 'L' + gpa.pointOffsetNormal(pt, bc73).toString();
    }
    path += 'L' + gpa.getPointAtLength(s).toString();
    return path;
  }

  /**
   * 从起始距离计算平行路径
   * @param {number} startLen
   * @param {GeomPolyArc} gpa - 此 stripe 的 segments 组成的 PolyArc
   * @param {GeomPolyArc} ogpa - 与这条 stripe 平行的另一条 PolyArc
   * @returns {number | GeomPoint}
   */
  _getParallelPathFromStartDistance(startLen, gpa, ogpa) {
    var s = startLen,
      length = 0,
      pt,
      currentIndex = -1,
      streetLen = 0;

    if (!pt) {
      for (let i = 0; i < gpa.segments.length - 1; i += 1) {
        length = gpa.segments[i].getLength();
        if (s <= length) {
          pt = gpa.segments[i].getPointAtLength(s);
          // currentIndex = i;
        } else {
          s -= length;
        }
      }
    }

    if (!pt) {
      let i = gpa.segments.length - 1;
      pt = gpa.segments[i].getPointAtLength(s);
      currentIndex = i;
    }

    if (pt && currentIndex >= 0) {
      for (let i = 0; i < ogpa.segments.length; i += 1) {
        if (currentIndex === ogpa.segments[i]._sourceIdx) {
          streetLen += ogpa.segments[i].getOffsetsToPoint(pt).fromStart;
        } else {
          streetLen += ogpa.segments[i].getLength();
        }
      }
      return streetLen;
    }

    // for (let i = gpa.segments.length - 1; i > 0; i--) {
    //   if (!pt) {
    //     bc80 = i;
    //     pt = gpa.segments[i].getPointAtLength(s);
    //   }

    //   if (pt && bc80 >= 0) {
    //     // console.log('pt', pt);
    //     console.log('ogpa', ogpa);
    //     console.log('gpa', gpa);
    //     for (let i = 0; i < ogpa.segments.length; i += 1) {
    //       if (bc80 === ogpa.segments[i]._sourceIdx) {
    //         let res = bc81 + ogpa.segments[i].getOffsetsToPoint(pt).fromStart;
    //         pt = null;
    //         return res;
    //       } else {
    //         bc81 += ogpa.segments[i].getLength();
    //       }
    //     }
    //   }
    // }

    return ogpa.getPointAtLength(startLen);
  }
}

export default GeomStripe;
