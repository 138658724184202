import React from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { toggleTrackMouse } from './redux';
import { IconCheck } from '@src/components/UI/Icons';

function DevOptions(props) {
  const { toggleTrackMouse, trackMouse } = props;

  return (
    <Menu>
      <Menu.Item key="mouse" onClick={() => toggleTrackMouse()}>
        {trackMouse && <IconCheck />}Track Mouse Position
      </Menu.Item>
    </Menu>
  );
}

export default connect(
  state => ({
    trackMouse: state.devOptions.trackMouse,
  }),
  {
    toggleTrackMouse,
  }
)(DevOptions);
