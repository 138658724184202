import GeomPoint from "./GeomPoint";

class GeomAlign {
  /**
   * @type {GeomPoint}
   */
  ptAlign;

  /**
   * @type {number}
   */
  rotateDeg;

  /**
   *
   * @param {GeomPoint} ptAlign
   * @param {number} rotateDeg
   */
  constructor(ptAlign, rotateDeg) {
    this.ptAlign = ptAlign;
    this.rotateDeg = rotateDeg;
  }
}

export default GeomAlign;
