import React from 'react';
import { GetShapes } from '@src/data/BusinessFun';
import { TowPointVerticalLengthByStatus, IsUnderLine } from '@src/data/CommonFunc';
import { GetShapeLine } from '../ShapeFun';
import { GetCrossAnchors, getSquareHandlePoint } from '@src/data/AnchorsPoint';
import ArrowLength from '../ArrowLength';
import { ANCHORS_TYPE, COLOR_TYPE, DASH_ARRAY_TYPE } from '@src/constant';

class SelectStreetAcross extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps({ object }) {
    this.init(object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    if (isFirst) {
      this.state = {
        object: object,
      };
    } else {
      this.setState({
        object: object,
      });
    }
  };
  getAcrossSelect(shapes) {
    let acrossData = this.state.object.handlepoint;
    for (let i = 0; i < acrossData.length; i++) {
      if (acrossData[i].length < 3) continue;
      if (acrossData[i].length > 3 && acrossData[i][3].selectflag) {
        let shapeLine = GetShapeLine(acrossData[i][0], acrossData[i][2], 'acrossline' + String(i), true, {
          stroke: COLOR_TYPE.GREEN,
          fill: COLOR_TYPE.NONE,
          strokewidth: 1,
          strokedasharray: DASH_ARRAY_TYPE.Dashes,
        });
        shapes.push(shapeLine);
        let shapeMove1 = getSquareHandlePoint(
          String(i) + '||' + String(0) + '||' + ANCHORS_TYPE.STREETACROSSPOINT,
          acrossData[i][0]
        );
        shapes.push(shapeMove1);
        let shapeMove2 = getSquareHandlePoint(
          String(i) + '||' + String(2) + '||' + ANCHORS_TYPE.STREETACROSSPOINT,
          acrossData[i][2]
        );
        shapes.push(shapeMove2);
        // let shapeMove3 = getSquareHandlePoint(
        //   String(i) + '||' + String(1) + '||' + ANCHORS_TYPE.STREETACROSSCenter,
        //   GetCenterInTwoPoints(acrossData[i][0], acrossData[i][2]),
        //   4
        // );
        // shapes.push(shapeMove3);
        let acrossPoint = GetCrossAnchors('cross' + String(i), acrossData[i][1], 4);
        shapes.push(acrossPoint);
        let acrossPointIsUnder = IsUnderLine(acrossData[i][0], acrossData[i][2], acrossData[i][1]);
        let topAcrossPoint0 = TowPointVerticalLengthByStatus(
          acrossData[i][0],
          acrossData[i][1],
          acrossData[i][0],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint1 = TowPointVerticalLengthByStatus(
          acrossData[i][0],
          acrossData[i][1],
          acrossData[i][1],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint11 = TowPointVerticalLengthByStatus(
          acrossData[i][1],
          acrossData[i][2],
          acrossData[i][1],
          8,
          acrossPointIsUnder
        );
        let topAcrossPoint2 = TowPointVerticalLengthByStatus(
          acrossData[i][1],
          acrossData[i][2],
          acrossData[i][2],
          8,
          acrossPointIsUnder
        );
        shapes.push(
          <ArrowLength p1={topAcrossPoint0} p2={topAcrossPoint1} istop={false} key={String(i) + 'acrossLength1'} />
        );
        shapes.push(
          <ArrowLength p1={topAcrossPoint11} p2={topAcrossPoint2} istop={false} key={String(i) + 'acrossLength2'} />
        );
      }
    }
  }
  render() {
    const { props } = this;
    const { object } = props;
    if (object == null || object.handlepoint.length < 3) return null;
    var shapes = [];
    this.getAcrossSelect(shapes);
    return <g id={object.functype}>{GetShapes(shapes)}</g>;
  }
}

export default SelectStreetAcross;
