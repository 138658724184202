import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomArc from '@src/data/GeomArc';
import GeomPolyArc from '@src/data/GeomPolyArc';
import Utility from './Utility';
import UtilityGeom from './UtilityGeom';
import UtilityMath from './UtilityMath';
import StreetBorder from './StreetBorder';
import StreetEndCaps from './StreetEndCaps';
import * as workData from './WorkData';

class StreetBorderIsectTracker {
  /** @type {{
   * pt: GeomPoint,
   * offset0: number,
   * offset1: number,
   * border0: StreetBorder,
   * border1: StreetBorder,
   * street0id: string,
   * street1id: string,
   * stripe0key: string,
   * stripe1key: string
   * }[]} */
  borderIsects;

  /**
   *
   * @param {string} surfaceType
   * @param {{functype: 'StreetNew'}[]} streets - objList 中所有 StreetNew 类型
   * @param {number} setback - curbReturn 的大小
   */
  constructor(surfaceType = 'paved', streets = [], setback) {
    this.surfaceType = surfaceType;
    this.streets = streets;
    this.defaultSetback = 60;
    if (Utility.isTypeofNumber(setback)) {
      this.defaultSetback = setback;
    }
    this.borderIsects = [];
  }

  /**
   * @param {StreetBorder} streetBorder
   * @param {StreetEndCaps} streetEndCap
   */
  intersectBorderStripeEndCaps(streetBorder, streetEndCap) {
    var intersectionFromStart = streetBorder.getStreetEndCapIntersection(streetEndCap.startCap);
    var intersectionFromStop = streetBorder.getStreetEndCapIntersection(streetEndCap.stopCap);
    intersectionFromStart = intersectionFromStart.concat(intersectionFromStop);
    intersectionFromStart.forEach(intersection => {
      if (
        !this.streets.some(street => {
          return (
            street.streetId !== streetBorder.streetKey &&
            street.streetId !== streetEndCap.streetKey &&
            this.isPointInShape(street.segments, intersection.pt)
          );
        })
      ) {
        streetBorder.addIntersectOffset(intersection.offset0);
      }
    });
  }

  /**
   * 两条道路边线的相交计算
   * @param {StreetBorder} streetBorder0
   * @param {StreetBorder} streetBorder1
   */
  intersectBorderStripes(streetBorder0, streetBorder1) {
    if (streetBorder0.streetId === streetBorder1.streetId) {
      return;
    }
    var isects = streetBorder0.getStripeIntersection(streetBorder1);
    if (Utility.isNonEmptyArray(isects)) {
      for (let i = 0; i < isects.length; i++) {
        var b = false;
        for (let j = 0; j < this.streets.length; j++) {
          var street = this.streets[j];
          if (
            street.operateid !== streetBorder0.streetId &&
            street.operateid !== streetBorder1.streetId &&
            this.isPointInShape(street.segments, isects[i].pt)
          ) {
            b = true;
            break;
          }
        }
        if (!b) {
          var borderIsect = isects[i];
          borderIsect.border0 = streetBorder0;
          borderIsect.border1 = streetBorder1;
          borderIsect.street0id = streetBorder0.streetId;
          borderIsect.street1id = streetBorder1.streetId;
          borderIsect.stripe0key = streetBorder0.stripeKey;
          borderIsect.stripe1key = streetBorder1.stripeKey;
          this.borderIsects.push(borderIsect);
        }
      }
    }
  }

  /**
   *
   * @param {{type: 'line' | 'stipe'}[]} segments
   * @param {GeomPoint} pt
   * @returns {boolean}
   */
  isPointInShape(segments, pt) {
    var gpa = new GeomPolyArc(segments);
    var gpo = gpa.getOffsetsToPoint(pt);
    if (gpo) {
      if (gpo.fromStartPercent > 0 && gpo.fromStartPercent < 1) {
        // HACK: Need to define the magic number
        // the 240 number is Street width, see: src\constant\constant.js STREET_DEFAULT_SIZE
        if (Math.abs(gpo.normal) < 240 / 2) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * create new CurbReturns and remove previous CurbReturns
   * @param {{functype: 'CurbReturn'}[]} curbReturns
   * @param {{functype: 'CurbReturn'}[]} refreshCurbReturns
   * @param {{functype: 'CurbReturn'}[]} removeCurbReturns
   */
  createCurbReturns(curbReturns, refreshCurbReturns, removeCurbReturns) {
    /** @type {curbReturns} */
    var crs = [];
    Array.prototype.push.apply(crs, curbReturns);
    if (Utility.isNonEmptyArray(this.borderIsects)) {
      for (let i = 0; i < this.borderIsects.length; i++) {
        var borderIsect = this.borderIsects[i];
        var street0 = workData.getObject(borderIsect.street0id);
        var street1 = workData.getObject(borderIsect.street1id);
        let defaultSetback = this.defaultSetback;
        if (street0.roadLayer !== street1.roadLayer) {
          defaultSetback = 15;
        }
        var sp0 = defaultSetback,
          sp1 = defaultSetback;
        var apexOffset0 = borderIsect.offset0;
        var apexOffset1 = borderIsect.offset1;
        var setbackPref0 = defaultSetback,
          setbackPref1 = defaultSetback;
        var index = -1;
        for (let j = 0; j < crs.length; j++) {
          if (crs[j].streetStripes.length == 2) {
            if (
              (borderIsect.street0id === crs[j].streetStripes[0].idStreet &&
                borderIsect.stripe0key === crs[j].streetStripes[0].keyStripe &&
                borderIsect.street1id === crs[j].streetStripes[1].idStreet &&
                borderIsect.stripe1key === crs[j].streetStripes[1].keyStripe) ||
              (borderIsect.street0id === crs[j].streetStripes[1].idStreet &&
                borderIsect.stripe0key === crs[j].streetStripes[1].keyStripe &&
                borderIsect.street1id === crs[j].streetStripes[0].idStreet &&
                borderIsect.stripe1key === crs[j].streetStripes[0].keyStripe)
            ) {
              if (-1 === index) {
                index = j;
              } else {
                var pt = new GeomPoint(borderIsect.pt.x, borderIsect.pt.y);
                if (crs[index].ptApex.distance(pt) > crs[j].ptApex.distance(pt)) {
                  index = j;
                }
              }
            }
          }
        }
        var curbReturn = undefined;
        if (index >= 0) {
          curbReturn = crs[index];
          crs.splice(index, 1);
          if (curbReturn.streetStripes.length == 2) {
            if (
              borderIsect.street0id === curbReturn.streetStripes[0].idStreet &&
              borderIsect.stripe0key === curbReturn.streetStripes[0].keyStripe &&
              borderIsect.street1id === curbReturn.streetStripes[1].idStreet &&
              borderIsect.stripe1key === curbReturn.streetStripes[1].keyStripe
            ) {
              setbackPref0 = curbReturn.streetStripes[0].setbackPref;
              setbackPref1 = curbReturn.streetStripes[1].setbackPref;
            } else if (
              borderIsect.street0id === curbReturn.streetStripes[1].idStreet &&
              borderIsect.stripe0key === curbReturn.streetStripes[1].keyStripe &&
              borderIsect.street1id === curbReturn.streetStripes[0].idStreet &&
              borderIsect.stripe1key === curbReturn.streetStripes[0].keyStripe
            ) {
              setbackPref0 = curbReturn.streetStripes[1].setbackPref;
              setbackPref1 = curbReturn.streetStripes[0].setbackPref;
            }
          }
          sp0 = setbackPref0;
          sp1 = setbackPref1;
        }

        //
        var s2 = new GeomPolyArc(street1.segments);
        // console.log('borderIsect', borderIsect);
        // console.log('s2', s2);
        if (s2.segments.length > 0) {
          var ept = s2.getPointClosest(borderIsect.pt);
          sp0 = Math.abs(sp0);
          var gpa0 = undefined;
          if (borderIsect.border0.gpaAxis) gpa0 = borderIsect.border0.gpaAxis;
          else gpa0 = borderIsect.border0.gpaStripe;
          var ptSetback0 = gpa0.getPointAtLength(apexOffset0 + sp0);
          var tptSetback0 = gpa0.getPointAtLength(apexOffset0 - sp0);
          if (ept.distance(ptSetback0) < ept.distance(tptSetback0)) {
            sp0 *= -1;
          }
        }
        var setbackOffset0 = apexOffset0 + sp0;
        if (setbackOffset0 < 0) {
          setbackOffset0 = Math.max(-defaultSetback, setbackOffset0);
        } else {
          if (setbackOffset0 > gpa0.getLength()) {
            setbackOffset0 = Math.min(setbackOffset0, gpa0.getLength() + defaultSetback);
          }
        }
        var ga0 = gpa0.getAlignmentAtLength(setbackOffset0, 0);
        var ptAlign0 = ga0.ptAlign;

        //
        var s1 = new GeomPolyArc(street0.segments);
        var spt = s1.getPointClosest(borderIsect.pt);
        sp1 = Math.abs(sp1);
        var gpa1 = undefined;
        if (borderIsect.border1.gpaAxis) gpa1 = borderIsect.border1.gpaAxis;
        else gpa1 = borderIsect.border1.gpaStripe;
        var ptSetback1 = gpa1.getPointAtLength(apexOffset1 + sp1);
        var tptSetback1 = gpa1.getPointAtLength(apexOffset1 - sp1);
        if (spt.distance(ptSetback1) < spt.distance(tptSetback1)) {
          sp1 *= -1;
        }
        var setbackOffset1 = apexOffset1 + sp1;
        if (setbackOffset1 < 0) {
          setbackOffset1 = Math.max(-defaultSetback, setbackOffset1);
        } else {
          if (setbackOffset1 > gpa1.getLength()) {
            setbackOffset1 = Math.min(setbackOffset1, gpa1.getLength() + defaultSetback);
          }
        }
        var ga1 = gpa1.getAlignmentAtLength(setbackOffset1, 0);
        var ptAlign1 = ga1.ptAlign;

        //
        var r = 60;
        var sweepFlag = 0;
        var segments = undefined;
        if (Math.abs(borderIsect.pt.distance(ptAlign0) - borderIsect.pt.distance(ptAlign1)) < 4 / 4) {
          var cpt = this.getPointArcCenter(
            gpa0,
            gpa1,
            borderIsect.pt,
            setbackOffset0,
            apexOffset0,
            setbackOffset1,
            apexOffset1,
            ptAlign0,
            ptAlign1
          );
          if (cpt && Math.abs(cpt.distance(ptAlign0) - cpt.distance(ptAlign1)) < 4 / 4) {
            r = cpt.distance(ptAlign0);
            sweepFlag = new UtilityMath().isCounterClockwise(ptAlign0, borderIsect.pt, ptAlign1) ? 1 : 0;
            segments = [
              {
                type: 'arc',
                ptStart: { x: ptAlign0.x, y: ptAlign0.y },
                ptStop: { x: ptAlign1.x, y: ptAlign1.y },
                r: r,
                largeArcFlag: false,
                sweepFlag: sweepFlag,
              },
            ];
          }
        }
        if (!segments) {
          segments = [
            {
              type: 'q',
              ptStart: { x: ptAlign0.x, y: ptAlign0.y },
              ptStop: { x: ptAlign1.x, y: ptAlign1.y },
              pt: { x: borderIsect.pt.x, y: borderIsect.pt.y },
            },
          ];
        }
        var ptSetback0 = gpa0.getPointAtLength(setbackOffset0);
        var ptSetback1 = gpa1.getPointAtLength(setbackOffset1);

        //
        if (curbReturn) {
          curbReturn.ptApex = borderIsect.pt;
          curbReturn.streetStripes = [
            {
              key: '1',
              idStreet: borderIsect.border0.streetId,
              keyStripe: borderIsect.border0.stripeKey,
              ptSetback: ptSetback0,
              apexOffset: apexOffset0,
              setbackOffset: setbackOffset0,
              setbackPref: setbackPref0,
            },
            {
              key: '2',
              idStreet: borderIsect.border1.streetId,
              keyStripe: borderIsect.border1.stripeKey,
              ptSetback: ptSetback1,
              apexOffset: apexOffset1,
              setbackOffset: setbackOffset1,
              setbackPref: setbackPref1,
            },
          ];
          curbReturn.segments = segments;
          refreshCurbReturns.push(curbReturn);

          if (street0.roadLayer !== street1.roadLayer) {
            workData.deleteObject(curbReturn.operateid);
          }
        } else {
          // add curb return to work data
          workData.addData('CurbReturn');
          let obj = workData.getCurrentOperateObject();
          let index = workData.getLastObject('CurbReturn');
          if (index < workData.getUseData().length - 1) {
            workData.deleteObject(obj.operateid);
            workData.insertObject(index, obj);
          }
          obj.style.strokewidth = 4;
          obj.surfaceType = 'paved';
          obj.ptApex = borderIsect.pt;
          obj.streetStripes = [
            {
              key: '1',
              idStreet: borderIsect.border0.streetId,
              keyStripe: borderIsect.border0.stripeKey,
              ptSetback: ptSetback0,
              apexOffset: apexOffset0,
              setbackOffset: setbackOffset0,
              setbackPref: setbackPref0,
            },
            {
              key: '2',
              idStreet: borderIsect.border1.streetId,
              keyStripe: borderIsect.border1.stripeKey,
              ptSetback: ptSetback1,
              apexOffset: apexOffset1,
              setbackOffset: setbackOffset1,
              setbackPref: setbackPref1,
            },
          ];
          obj.segments = segments;
          refreshCurbReturns.push(obj);

          if (street0.roadLayer !== street1.roadLayer) {
            workData.deleteObject(obj.operateid);
          }
        }

        //
        borderIsect.border0.addIntersectOffset(setbackOffset0);
        borderIsect.border1.addIntersectOffset(setbackOffset1);
      }
    }
    Array.prototype.push.apply(removeCurbReturns, crs);
  }

  /**
   *
   * @param {GeomArc|GeomLine|GeomPolyArc} l0
   * @param {GeomArc|GeomLine|GeomPolyArc} l1
   * @param {{x: number, y: number}} ptApex
   * @param {number} setbackOffset0
   * @param {number} apexOffset0
   * @param {number} setbackOffset1
   * @param {number} apexOffset1
   * @param {GeomPoint} sp
   * @param {GeomPoint} ep
   * @returns {GeomPoint | undefined}
   */
  getPointArcCenter(l0, l1, ptApex, setbackOffset0, apexOffset0, setbackOffset1, apexOffset1, sp, ep) {
    var sns = this.getStripeNormalSegments(l0, l1, ptApex, setbackOffset0, apexOffset0, setbackOffset1, apexOffset1);
    var p;
    if (sns) {
      p = sns.stripeNormal0.getPointIntersect(sns.stripeNormal1);
    }
    if (p && Math.abs(sp.distance(p) - ep.distance(p)) < 4) {
      return p;
    }
    return undefined;
  }

  /**
   *
   * @param {GeomArc|GeomLine|GeomPolyArc} l0
   * @param {GeomArc|GeomLine|GeomPolyArc} l1
   * @param {{x: number, y: number}} ptApex
   * @param {number} setbackOffset0
   * @param {number} apexOffset0
   * @param {number} setbackOffset1
   * @param {number} apexOffset1
   * @returns {{stripeNormal0: GeomLine, stripeNormal1: GeomLine} | undefined}
   */
  getStripeNormalSegments(l0, l1, ptApex, setbackOffset0, apexOffset0, setbackOffset1, apexOffset1) {
    var sga = l0.getAlignmentAtLength(setbackOffset0, 0).ptAlign;
    var ega = l1.getAlignmentAtLength(setbackOffset1, 0).ptAlign;
    var d = sga.distanceSquared(ega);
    var sign = new UtilityMath().isClockwise(ptApex, sga, ega) ? -1 : 1;
    var rd = setbackOffset0 > apexOffset0 ? d : -d;
    var sga1 = l0.getAlignmentAtLength(setbackOffset0, sign * rd).ptAlign;
    var gl0 = new GeomLine(sga, sga1);
    sign *= -1;
    rd = setbackOffset1 > apexOffset1 ? d : -d;
    var ega1 = l1.getAlignmentAtLength(setbackOffset1, sign * rd).ptAlign;
    var gl1 = new GeomLine(ega, ega1);
    if (gl0 && gl1) {
      return {
        stripeNormal0: gl0,
        stripeNormal1: gl1,
      };
    }
    return undefined;
  }
}

export default StreetBorderIsectTracker;
