import React from 'react';
import classNames from 'classnames';

export default function MapSideBarBtn({ src, title, disabled, onClick }) {
  return (
    <div
      className={classNames('map-sidebar-btn', {
        disabled,
      })}
    >
      <img src={src} title={title} onClick={disabled ? null : onClick} />
    </div>
  );
}
