import { setDrawingShape, toggleLockAllRoads } from '@src/action/canvas';
import { setCurrentState, setCursor } from '@src/action/drawingStatus';
import {
  IconChatBubble,
  IconCrosswalk,
  IconDown,
  IconDrawRoad,
  IconLadder,
  IconLayers,
  IconPathArc,
  IconPathCircle,
  IconPathEllipse,
  IconPathLine,
  IconPathMutiple,
  IconPathPolygon,
  IconPathRect,
  IconPathSquare,
  IconPathSquareOpen,
  IconPathText,
  IconPencil,
  IconRuler,
  IconSelectArea,
  IconRoundaboutRoad,
  IconCurvedRoad,
  IconLock,
  IconLocked,
  IconMarker,
  IconZoomSelect,
} from '@src/components/UI/Icons';
import { FUNCTION_TYPE, OPERATIONS, STREET_TYPE, CURSOR } from '@src/constant';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { generateData } from '@src/utils';
import { Dropdown, Icon, Menu, Button } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TemplateSaveModal from '@src/components/Template/TemplateSaveModal';
import * as workData from '@src/data/WorkData';
import { DropMenuOnHeader } from '@src/components/Content/CanvasDropMenu';
import { message } from '@src/components/Message';
import { withTranslation } from 'react-i18next';

class HeaderSecond extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateSaveModalVisiable: false,
      templateName: '',
      templateGroup: null,
      templateSrc: '',
      templateGroups: [],
      isLoadingTemplates: false,
      templateShape: ['draw', 'textbox', 'street'],
      /**
       * disable arrange (Bring to Front..) menu items if not selecting only one symbol
       */
      selectingOneSymbol: false,
    };
  }

  /************************** Draw *********************** */

  /**
   * @param {FUNCTION_TYPE} functype
   * @param {STREET_TYPE} streetType
   */
  onStartDrawing = (functype, streetType) => {
    this.props.setDrawingShape(functype, streetType);
    this.props.setCursor(CURSOR.DRAWING);
    this.props.setCurrentState(0);
  };

  handlePositionMenuClick = ({ key }) => {
    const { handleCanvasOperation } = this.props;
    handleCanvasOperation(key);
  };

  /**
   * @param {FUNCTION_TYPE} name
   * @param {STREET_TYPE} [streetType]
   */
  onClick = (name, streetType) => {
    if (Object.values(FUNCTION_TYPE).indexOf(name) > -1) {
      if (name === FUNCTION_TYPE.STREETNEW) {
        this.onStartDrawing(name, streetType);
      } else if (name === FUNCTION_TYPE.MARKER) {
        const selectedData = workData.getUseData().filter(data => data.selectflag);
        selectedData.forEach(data => {
          if (data.functype !== FUNCTION_TYPE.MARKER) {
            workData.addMarkData(data);
          }
        });
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
      } else {
        this.onStartDrawing(name);
      }
    } else if (name === 'add-template-button') {
      this.openTemplateModal();
    } else {
      // emit event
    }
  };

  openTemplateModal = () => {
    if (0 === generateData([]).length) {
      message.warning(this.props.t('template.saveModal.diagramRequired'));
      return;
    }
    if (workData.getUseData().some(data => data.functype === FUNCTION_TYPE.GROUP)) {
      message.warning(this.props.t('menu.second.unGroupShapesToSaveTemplate'));
      return;
    }
    this.setState({ templateSaveModalVisiable: true });
  };

  closeTemplateSaveModal = () => this.setState({ templateSaveModalVisiable: false });

  generateDropdown = (item, allDrawShapes) => {
    const { drawingShape, drawingStreetType } = this.props;
    const { icon, iconType, name, streetType, title, overlay, onTriggerClick, smHide } = item;
    const iconProps = {
      key: 'tool-icon',
    };
    let key = streetType ? `${name}-${streetType}` : name;
    let isSelected = false;
    const className = classNames('toolbar-item', {
      selected: isSelected,
      'lncd-sm-hide': smHide,
    });

    if (iconType) {
      iconProps.type = iconType;
    } else {
      iconProps.component = icon;
    }

    if (drawingShape === name) {
      // StreetNew 类型分为多种 streetType，对于 key 和分类要考虑到
      if (name === FUNCTION_TYPE.STREETNEW) {
        if (`${drawingShape}-${drawingStreetType}` === key) {
          isSelected = true;
        }
      } else {
        isSelected = true;
      }
    } else if (allDrawShapes.filter(n => n.name === drawingShape).length > 0 && name === 'draw-shape-menu') {
      isSelected = true;
    }

    return overlay ? (
      <Dropdown key={key} overlay={overlay} trigger={['click']}>
        <span className={className} title={title} onClick={onTriggerClick}>
          <Icon {...iconProps} />
          <Icon key="caret-down" component={IconDown} className="caret-down" />
        </span>
      </Dropdown>
    ) : (
      <span key={key} className={className} title={title} onClick={() => this.onClick(name, streetType)}>
        <Icon {...iconProps} />
      </span>
    );
  };

  render() {
    const allDrawShapes = [
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.LINE}`),
        functype: FUNCTION_TYPE.LINE,
        icon: IconPathLine,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.LINE}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.SQUARE}`),
        functype: FUNCTION_TYPE.SQUARE,
        icon: IconPathSquare,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.SQUARE}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.RECTANGLE}`),
        functype: FUNCTION_TYPE.RECTANGLE,
        icon: IconPathRect,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.RECTANGLE}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.CIRCLE}`),
        functype: FUNCTION_TYPE.CIRCLE,
        icon: IconPathCircle,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.CIRCLE}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.CLOSEDSHAPE}`),
        functype: FUNCTION_TYPE.CLOSEDSHAPE,
        icon: IconPathPolygon,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.CLOSEDSHAPE}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.ARC}`),
        functype: FUNCTION_TYPE.ARC,
        icon: IconPathArc,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.ARC}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.CONNECTEDLINES}`),
        functype: FUNCTION_TYPE.CONNECTEDLINES,
        icon: IconPathMutiple,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.CONNECTEDLINES}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.ELLIPSE}`),
        functype: FUNCTION_TYPE.ELLIPSE,
        icon: IconPathEllipse,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.ELLIPSE}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.STRUCTURE}`),
        functype: FUNCTION_TYPE.STRUCTURE,
        icon: IconPathSquareOpen,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.STRUCTURE}Title`),
      },
      {
        name: this.props.t(`menu.drawShape.${FUNCTION_TYPE.STAIRS}`),
        functype: FUNCTION_TYPE.STAIRS,
        icon: IconLadder,
        title: this.props.t(`menu.drawShape.${FUNCTION_TYPE.STAIRS}Title`),
      },
    ];

    const { selectingOneSymbol } = this.state;
    const { isLockingRoads, toggleLockAllRoads } = this.props;

    const sections = [
      {
        label: this.props.t('menu.second.roadwayTypeLabel'),
        items: [
          {
            name: FUNCTION_TYPE.STREETNEW,
            functype: FUNCTION_TYPE.STREETNEW,
            icon: IconDrawRoad,
            streetType: STREET_TYPE.STRAIGHT,
            title: this.props.t('menu.second.drawStreetTitle'),
          },
          {
            name: FUNCTION_TYPE.STREETNEW,
            functype: FUNCTION_TYPE.STREETNEW,
            icon: IconRoundaboutRoad,
            streetType: STREET_TYPE.ROUNDABOUT,
            title: this.props.t('menu.second.drawRoundaboutTitle'),
          },
          {
            name: FUNCTION_TYPE.STREETNEW,
            functype: FUNCTION_TYPE.STREETNEW,
            icon: IconCurvedRoad,
            streetType: STREET_TYPE.CURVED,
            title: this.props.t('menu.second.drawCurvedTitle'),
          },
          {
            name: FUNCTION_TYPE.CROSSWALK,
            functype: FUNCTION_TYPE.CROSSWALK,
            icon: IconCrosswalk,
            title: this.props.t('menu.second.drawCrosswalkTitle'),
          },
        ],
      },
      {
        label: this.props.t('menu.second.drawLabel'),
        items: [
          {
            name: 'draw-shape-menu',
            icon: IconPencil,
            title: this.props.t('menu.second.drawShapeTitle'),
            overlay: (
              <Menu className="reg-no-select" onClick={({ key }) => this.onStartDrawing(key)}>
                {allDrawShapes.map(item => {
                  const { name, title, icon } = item;
                  return (
                    <Menu.Item key={item.functype} style={{ display: 'flex', alignItems: 'center' }} title={title}>
                      <Icon component={icon} />
                      <span>{name}</span>
                    </Menu.Item>
                  );
                })}
              </Menu>
            ),
          },
        ],
      },
      {
        label: this.props.t('menu.second.selectLabel'),
        items: [
          {
            name: FUNCTION_TYPE.SELECTION,
            functype: FUNCTION_TYPE.SELECTION,
            icon: IconSelectArea,
            title: this.props.t('menu.second.selectTitle'),
            smHide: true,
          },
          {
            name: FUNCTION_TYPE.ZOOMSELECT,
            functype: FUNCTION_TYPE.ZOOMSELECT,
            icon: IconZoomSelect,
            title: this.props.t('menu.second.selectZoomTitle'),
            smHide: true,
          },
          {
            name: FUNCTION_TYPE.DIMENSIONLINE,
            functype: FUNCTION_TYPE.DIMENSIONLINE,
            icon: IconRuler,
            title: this.props.t('menu.second.dimensionTitle'),
            smHide: true,
          },
          {
            name: 'layer-menu',
            icon: IconLayers,
            title: this.props.t('menu.second.positionTitle'),
            overlay: (
              <div className="reg-no-select" style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1' }}>
                <DropMenuOnHeader handleCanvasOperation={this.props.handleCanvasOperation} />
              </div>
            ),
            onTriggerClick: () => {
              const selectedData = workData.getUseData().filter(data => data.selectflag);
              const selectingOneSymbol = selectedData.length === 1 && selectedData[0].functype === FUNCTION_TYPE.SYMBOL;
              this.setState({ selectingOneSymbol });
            },
          },
        ],
      },
      {
        label: this.props.t('menu.second.textLabel'),
        items: [
          {
            name: FUNCTION_TYPE.TEXTBOX,
            functype: FUNCTION_TYPE.TEXTBOX,
            icon: IconPathText,
            title: this.props.t('menu.second.drawTextboxTitle'),
            smHide: true,
          },
          {
            name: FUNCTION_TYPE.CALLOUT,
            functype: FUNCTION_TYPE.CALLOUT,
            icon: IconChatBubble,
            title: this.props.t('menu.second.drawCalloutTitle'),
            smHide: true,
          },
          {
            name: FUNCTION_TYPE.MARKER,
            functype: FUNCTION_TYPE.MARKER,
            icon: IconMarker,
            title: this.props.t('menu.second.addMarkerTitle'),
            smHide: true,
          },
        ],
        smHide: true,
      },
      {
        label: this.props.t('menu.second.templateLabel'),
        items: [
          {
            name: 'add-template-button',
            iconType: 'file-add',
            title: this.props.t('menu.second.saveTemplateTitle'),
            smHide: true,
          },
        ],
        smHide: true,
      },
    ];

    return (
      <div className="toolbar reg-no-select">
        {/* icons */}
        <div style={{ display: 'flex', flex: '1 1' }}>
          {sections.map(({ label, items, smHide }) => {
            return (
              <div
                key={label}
                className={classNames('toolbar-section', {
                  'lncd-sm-hide': smHide,
                })}
              >
                <label className="lncd-sm-hide">{`${label}:`}</label>
                {items.map(item => this.generateDropdown(item, allDrawShapes))}
              </div>
            );
          })}
        </div>

        {/* lock icon */}
        <div className="toolbar-section">
          <span
            className={classNames('toolbar-item', {
              selected: isLockingRoads,
            })}
            onClick={() => toggleLockAllRoads()}
            title={
              isLockingRoads ? this.props.t('menu.second.unlockRoadTitle') : this.props.t('menu.second.lockRoadTitle')
            }
          >
            <Icon component={isLockingRoads ? IconLocked : IconLock} />
          </span>
        </div>

        {/* more icon */}
        <div className="lncd-lg-hide lncd-more-menu toolbar-section">
          <Menu mode="horizontal">
            <Menu.SubMenu
              title={<Icon type="more" style={{ fontSize: '24px', margin: 0, color: `var(--color-toolbar-item)` }} />}
              popupClassName="lncd-more-submenu toolbar-submenu"
            >
              {sections
                .reduce((arr, section) => section.items && arr.concat(section.items), [])
                .filter(n => n.smHide)
                .map(item => {
                  const { title, icon: ItemIcon, iconType, name, streetType } = item;
                  const iconProps = {};
                  if (iconType) {
                    iconProps.key = 'tool-icon';
                    iconProps.type = iconType;
                    iconProps.style = {
                      margin: 0,
                      fontSize: 'inherit',
                      minWidth: 'auto',
                    };
                  }
                  return (
                    <Menu.Item key={title} onClick={() => this.onClick(name, streetType)}>
                      <div className={classNames('toolbar-section')} title={title}>
                        {ItemIcon ? <ItemIcon /> : <Icon {...iconProps} />}
                      </div>
                    </Menu.Item>
                  );
                })}
            </Menu.SubMenu>
          </Menu>
        </div>

        {this.state.templateSaveModalVisiable && (
          <TemplateSaveModal
            source="diagram"
            visible={this.state.templateSaveModalVisiable}
            closeModal={this.closeTemplateSaveModal}
          />
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    drawingShape: state.canvas.drawingShape,
    drawingStreetType: state.canvas.streetType,
    isLockingRoads: state.canvas.isLockingRoads,
  }),
  {
    setDrawingShape,
    toggleLockAllRoads,
    setCurrentState,
    setCursor,
  }
)(withTranslation()(HeaderSecond));
