import React, { Component } from 'react';
import { GetShapeStyle } from '@src/data/CommonProps';
import { GetMatrixString } from '@src/data/CommonFunc';
import { GetMatrixRotate, GetPathLineD } from '@src/data/BusinessFun';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import { COLOR_TYPE } from '@src/constant';

class SplitPattem extends React.Component {
  constructor(props) {
    super(props);
  }
  getShape = (x, y, id) => {
    var D = [];
    const WIDTH = getUserUnitByPixel() * 3.5;
    const HEIGHT = getUserUnitByPixel() * 10;
    var leftTop = [x - WIDTH, y - HEIGHT];
    var leftBottom = [x - WIDTH, y + HEIGHT];
    var rightTop = [x + WIDTH, y - HEIGHT];
    var rightBottom = [x + WIDTH, y + HEIGHT];
    GetPathLineD(D, leftTop, leftBottom);
    GetPathLineD(D, rightTop, rightBottom);
    return <path d={D.join(' ')} id={id}></path>;
  };
  render() {
    const { props } = this;
    const { angle } = props;
    let x = props.point[0];
    let y = props.point[1];
    let angleRotate = 0;
    if (angle != null) {
      angleRotate = angle + (Math.PI * 3) / 2;
    }
    var matrix = GetMatrixRotate([x, y], angleRotate);
    let style = GetShapeStyle().ANCHORSSTYPE;
    style.stroke = COLOR_TYPE.GREEN;
    style.fill = COLOR_TYPE.NONE;
    style.strokeWidth = 3;
    return (
      <g id="splitpattem" transform={GetMatrixString(matrix)} {...style}>
        {this.getShape(x, y, props.id)}
      </g>
    );
  }
}

export default SplitPattem;
