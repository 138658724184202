import * as React from 'react';

function SvgIconPencil(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .5h24v24H0z" />
        <path
          d="M20.821 3.679A3.997 3.997 0 0017.974 2.5a3.997 3.997 0 00-2.846 1.179L3.719 15.087l-.003.004a1.274 1.274 0 00-.261.461L2.03 21.256a.997.997 0 00.263.949l.001.001.001.001a1 1 0 00.949.264l5.704-1.427a1.266 1.266 0 00.465-.264L20.822 9.373A3.996 3.996 0 0022 6.526a4.002 4.002 0 00-1.178-2.847h-.001zm-6.41 3.549l2.863 2.863-8.568 8.568-2.863-2.863 8.568-8.568zM4.375 20.126l.595-2.374 1.78 1.781-2.375.593zM19.409 5.094c.764.765.764 2.099 0 2.864l-.719.72-2.866-2.865.721-.719c.765-.764 2.099-.765 2.864 0z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconPencil;
