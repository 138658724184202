import { setMeasurementPointsOpen } from '@src/action/propMenu';
import { DraggableModal } from 'ant-design-draggable-modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledPointModal = styled(DraggableModal)`
  & .ant-modal-body {
    padding: 10px;
  }
`;

/**
 * Draggable table modal to manage measurement points
 * @param {*} props
 */
function PointModal(props) {
  const { t } = useTranslation();
  const { pointModal, setMeasurementPointsOpen, children } = props;

  return pointModal ? (
    <StyledPointModal
      visible={true}
      onCancel={() => setMeasurementPointsOpen(false)}
      footer={null}
      title={t('property.measurementTab.modalTitle')}
      // see https://github.com/DylanVann/ant-design-draggable-modal/issues/8
      initialWidth={600}
      initialHeight={345}
      zIndex={1000}
    >
      {children}
    </StyledPointModal>
  ) : null;
}

const mapStateToProps = state => ({
  pointModal: state.propMenu.pointModal,
});

const mapDispatchToProps = {
  setMeasurementPointsOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointModal);
