import { fromJS } from 'immutable';
import { STREET_DEFAULT_SIZE, STREET_DIVIDER_TYPE, LANE_TYPE } from '@src/constant';

// define default values
const { STREET_LEN, LANE_WIDTH, WIDE_LANE_WIDTH, NARROW_LANE_WIDTH, DIVIDER_WIDTH } = STREET_DEFAULT_SIZE;

const lineBase = fromJS({
  type: 'line',
  ptStart: { x: 0, y: 0 },
  ptStop: { x: 0, y: 0 },
});

const arcBase = fromJS({
  type: 'arc',
  largeArcFlag: true,
  ptStart: { x: 0, y: 0 },
  ptStop: { x: 0, y: 0 },
  r: 0,
  sweepFlag: false,
});

const stripeBase = fromJS({
  type: 'stripe',
  key: undefined,
  segments: [],
  primary: { patterns: [{ pattern: undefined, startPct: 0 }] },
});

const dividerBase = fromJS({
  type: 'divider',
  key: undefined,
  dividerType: undefined,
  width: 0,
  primary: { patterns: [{ pattern: undefined, startPct: 0 }] },
});

const laneBase = fromJS({
  type: 'lane',
  key: undefined,
  width: 0,
});

const shoulderBase = fromJS({
  type: 'shoulder',
  key: undefined,
  width: 0,
  primary: { patterns: [{ pattern: undefined, startPct: 0 }] },
});

export const createLine = ({ ptStart, ptStop }) => {
  const line = lineBase.toJS();
  line.ptStart = ptStart;
  line.ptStop = ptStop;
  return line;
};

export const createArc = ({ ptStart, ptStop, r = STREET_LEN / 2, largeArcFlag = false, sweepFlag = false }) => {
  const arc = arcBase.toJS();
  arc.ptStart = ptStart;
  arc.ptStop = ptStop;
  arc.r = r;
  arc.largeArcFlag = largeArcFlag;
  arc.sweepFlag = sweepFlag;
  return arc;
};

// TODO: params type checking
/**
 *
 * @param {number} key
 * @param {array} segments
 * @param {string} [pattern]
 * @param {boolean} [median]
 * @returns {{ type: 'stripe' }}
 */
export const createStripe = (key, segments, pattern = 'singlesolid', median = false) => {
  const stripe = stripeBase.toJS();
  stripe.key = `${key}`;
  stripe.primary.patterns[0].pattern = pattern;
  stripe.segments = segments;
  stripe.median = median;
  return stripe;
};

/**
 *
 * @param {object} param0
 * @param {string} param0.type
 * @param {string} param0.key
 * @param {array} param0.segments
 * @param {string} [param0.backgroundColor]
 */
export const createDivider = ({ type, key, segments }) => {
  if (type === STREET_DIVIDER_TYPE.NONE) {
    return [createStripe(key, segments, 'invisible', true)];
  }
  let stripe1;
  let stripe2;
  let divider = dividerBase.toJS();
  divider.key = (key + 1).toString();
  divider.dividerType = type.toLowerCase();
  switch (type) {
    case STREET_DIVIDER_TYPE.TWO_WAY: {
      stripe1 = createStripe(key, segments, 'soliddash');
      stripe2 = createStripe(key + 2, segments, 'dashsolid');
      divider.width = LANE_WIDTH;
      break;
    }
    case STREET_DIVIDER_TYPE.WIDE: {
      stripe1 = createStripe(key, segments, 'singlesolid');
      stripe2 = createStripe(key + 2, segments, 'singlesolid');
      divider.width = WIDE_LANE_WIDTH;
      break;
    }
    case STREET_DIVIDER_TYPE.PAINTED: {
      stripe1 = createStripe(key, segments, 'doublesolid');
      stripe2 = createStripe(key + 2, segments, 'doublesolid');
      divider.width = NARROW_LANE_WIDTH;
      break;
    }
    case STREET_DIVIDER_TYPE.GRASS: {
      stripe1 = createStripe(key, segments, 'singlesolid');
      stripe2 = createStripe(key + 2, segments, 'singlesolid');
      divider.dividerType = STREET_DIVIDER_TYPE.GRASS.toLowerCase();
      divider.backgroundColor = `rgba(0, 108, 0, .35)`;
      divider.primary.patterns[0].pattern = 'singlesolid';
      divider.width = DIVIDER_WIDTH;
      break;
    }
    case STREET_DIVIDER_TYPE.CEMENT: {
      stripe1 = createStripe(key, segments, 'singlesolid');
      stripe2 = createStripe(key + 2, segments, 'singlesolid');
      divider.backgroundColor = `rgba(128, 128, 128, .5)`;
      divider.primary.patterns[0].pattern = 'singlesolid';
      divider.width = DIVIDER_WIDTH;
      break;
    }
  }
  return [stripe1, divider, stripe2];
};

/**
 *
 * @param {number} key
 * @param {number} [width]
 */
export const createLane = ({ key, laneType = LANE_TYPE.VEHICLE_LANE, width }) => {
  let lane = laneBase.toJS();
  lane.key = `${key}`;
  lane.laneType = laneType;

  switch (laneType) {
    case LANE_TYPE.VEHICLE_LANE:
      lane.width = width || STREET_DEFAULT_SIZE.LANE_WIDTH;
      break;
    case LANE_TYPE.BIKE_LANE:
      lane.width = width || STREET_DEFAULT_SIZE.NARROW_LANE_WIDTH;
      break;
  }

  return lane;
};

/**
 *
 * @param {object} param0
 * @param {number} param0.key
 * @param {string | undefined} param0.pattern
 * @param {number | undefined} param0.width
 * @param {array} param0.segments
 * @returns {{ type: 'shoulder' }}
 */
export const createShoulder = ({ key, width = NARROW_LANE_WIDTH, pattern = 'singlesolid' }) => {
  const shoulder = shoulderBase.toJS();
  shoulder.key = `${key}`;
  shoulder.width = width;
  shoulder.backgroundColor = `rgba(230, 230, 230, 1)`;
  shoulder.primary.patterns[0].pattern = pattern;
  return shoulder;
};
