import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import TextMenu from '@src/components/Modal/Menu/TextMenu';
import TabItem from '@src/components/PropMenu/TabItem';
import { Icon, Tabs } from 'antd';
import React from 'react';

export default class ImageModal extends React.Component {
  // Modal menu and items
  menu = {
    text: ['text', 'fontSize', 'color', 'style'],
    size: ['width', 'height', 'rotation'],
  };

  state = {
    tabKey: 'text',
    object: this.props.object,
  };

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     object: nextProps.object,
  //     // width: this.calculateWidth(this.props.object),
  //     // height: this.calculateHeight(this.props.object),
  //   });
  // }

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                // width={this.width}
                // height={this.height}
                // handlewidth={this.handlewidth}
                // handleheight={this.handleheight}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
