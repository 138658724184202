import * as React from 'react';

function SvgIconPlus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 .001h24v23.04H0z" />
        <path d="M21 11.001h-8v-8h-2v8H3v2h8v8h2v-8h8z" fill="currentColor" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default SvgIconPlus;
