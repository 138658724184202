import GeomVector from '@src/data/GeomVector';
import _ from 'lodash';
import { LANE_TYPE, FUNCTION_TYPE } from '@src/constant';

const isVehicleLane = component => component.type === 'lane' && component.laneType === LANE_TYPE.VEHICLE_LANE;

/**
 * feed in any work data to generate/calculate things like street main axis horizontal/vertical radian
 */
class StreetService {
  functype = undefined;
  data = undefined;
  components = [];

  constructor(data) {
    this.data = data;
    this.functype = data.functype;
    this.components = data.components || [];
    if (this.functype === FUNCTION_TYPE.STREETNEW) {
      // FIXME: deal with Ramp
      const line = data.segments[0];
      this.ptStart = line.ptStart;
      this.ptStop = line.ptStop;
      const { x: x1, y: y1 } = this.ptStart;
      const { x: x2, y: y2 } = this.ptStop;
      // street main aixs vector defined as start point to stop point
      this.axisVector = new GeomVector(x2 - x1, y2 - y1);
      this.stripes = _.filter(data.components, c => c.type === 'stripe');
    }
  }

  getHorizontalRad() {
    const cos = this.axisVector.angleCos(GeomVector.unitVectorHorizontal);
    return Math.acos(cos);
  }

  // TODO: get by current street shape; text default on street
  getTextPosition() {
    if (this.data.text.point.length > 0) {
      return this.data.text.point;
    }
    let ptStart, ptStop;
    if (this.data.components) {
      ptStart = this.data.components[0].segments[0].ptStart;
      ptStop = this.data.components[0].segments[0].ptStop;
    } else {
      ptStart = { x: this.data.handlepoint[0][0], y: this.data.handlepoint[0][1] };
      ptStop = { x: this.data.handlepoint[1][0], y: this.data.handlepoint[1][1] };
    }
    // TODO: refine the definition of initial text position
    // FIXME: no magic number
    const offset = 60;
    if (ptStart.y === ptStop.y) {
      return [(ptStart.x + ptStop.x) / 2.0, ptStart.y + offset];
    } else {
      return [ptStart.x + offset, Math.max(ptStart.y, ptStop.y) + offset];
    }
  }

  /**
   * get the first stripe's index in the position
   * @param {string} position top|bottom|left|right
   */
  getStripeIndex(position) {
    let stripe;
    switch (position) {
      case 'left':
        stripe = _.minBy(this.stripes, s => Math.min(s.segments[0].ptStart.x, s.segments[0].ptStop.x));
        break;
      case 'right':
        stripe = _.maxBy(this.stripes, s => Math.min(s.segments[0].ptStart.x, s.segments[0].ptStop.x));
        break;
      case 'top':
        stripe = _.maxBy(this.stripes, s => Math.min(s.segments[0].ptStart.y, s.segments[0].ptStop.y));
        break;
      case 'bottom':
        stripe = _.minBy(this.stripes, s => Math.min(s.segments[0].ptStart.y, s.segments[0].ptStop.y));
        break;
      default:
        break;
    }
    return stripe ? this.data.components.indexOf(stripe) : 0;
  }

  get vehicleLaneCount() {
    return this.components.filter(isVehicleLane).length;
  }

  get dividerIndex() {
    return this.functype === FUNCTION_TYPE.STREETNEW
      ? this.components.findIndex(n => n.type === 'divider')
      : this.data.lanes.length;
  }
}

export { StreetService, isVehicleLane };
export default StreetService;
