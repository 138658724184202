import React, { useEffect, useRef } from 'react';
import CustomInputNumber from '../Element/CustomInputNumber';
import { Input, Button } from 'antd';
import ColorSelect from '../Element/ColorSelect';
import Images from '@src/assets/Images';
import { LIMIT_VALUE, FONT_WEIGHT_TYPE, FONT_STYLE_TYPE } from '@src/constant';
import TouchScroll from '@src/components/TouchDevice/TouchScroll';
import useObjectProperty from '../useObjectProperty';
import { useTranslation } from 'react-i18next';
import LncdCheckbox from '../Element/LncdCheckbox';
import { connect } from 'react-redux';
import { isSafari, focusAndOpenKeyboard } from '@src/utils';
import * as workData from '@src/data/WorkData';

const TextMenu = props => {
  const [text, handleText] = useObjectProperty(props.objectId, 'textText');
  const [size, handleSize] = useObjectProperty(props.objectId, 'textSize');
  const [color, handleColor] = useObjectProperty(props.objectId, 'textColor');
  const [bold, handleBold] = useObjectProperty(props.objectId, 'textBold');
  const [italic, handleItalic] = useObjectProperty(props.objectId, 'textItalic');
  const [position, handlePosition] = useObjectProperty(props.objectId, 'textPosition');
  const textInputRef = useRef();

  useEffect(() => {
    /**
     * Safari prevent website autofocus the input, if user not interact to input.
     */
    if (!isSafari()) {
      if (props.displayLength) {
        setTimeout(() => {
          textInputRef.current && textInputRef.current.focus();
        });
      }
    }
    // FIXME: do we really need this dependency?
  }, [props.propsMenuOpen, props.objectId]);
  useEffect(() => {
    if (props.displayLength) {
      const object = workData.getObject(props.objectId);
      handleText(object.text.text);
    }
  }, [props.displayLength]);

  const onFocus = e => {
    e.target.select();
  };

  const { t } = useTranslation();

  return (
    <TouchScroll className="props-container scrollbar">
      {props.item.map(element => {
        switch (element) {
          case 'text':
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.textTab.textLabel')}:</span>
                <Input.TextArea
                  rows={1}
                  ref={textInputRef}
                  disabled={props.displayLength}
                  value={text}
                  onChange={e => handleText(e.target.value)}
                  onFocus={onFocus}
                />
              </div>
            );
          case 'fontSize':
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.textTab.fontSizeLabel')}</span>
                <CustomInputNumber
                  size="default"
                  value={size}
                  min={LIMIT_VALUE.MIN_TEXT_SIZE}
                  max={LIMIT_VALUE.MAX_TEXT_SIZE}
                  onChangeValue={handleSize}
                  formatter={'pt'}
                  parser={'pt'}
                />
              </div>
            );
          case 'color':
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.textTab.colorLabel')}:</span>
                <ColorSelect iconType="text" colorHex={color} handleColorHex={e => handleColor(e.currentTarget.id)} />
              </div>
            );
          case 'style':
            return (
              <div className="container-item" key={element}>
                <span className="item-title-space" />
                <div style={{ display: 'flex' }}>
                  <div
                    className={bold == FONT_WEIGHT_TYPE.BOLD ? 'lncd-propmenu-select' : 'lncd-propmenu-button'}
                    onClick={handleBold}
                  >
                    <img src={Images.IconBold} alt="" />
                  </div>
                  <div
                    className={italic == FONT_STYLE_TYPE.ITALIC ? 'lncd-propmenu-select' : 'lncd-propmenu-button'}
                    onClick={handleItalic}
                  >
                    <img src={Images.IconItalic} alt="" />
                  </div>
                </div>
              </div>
            );
          case 'display':
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.textTab.displayLabel')}:</span>
                <LncdCheckbox checked={props.displayLength} onClick={props.textLengthHandle} />
              </div>
            );
          case 'position':
            return (
              <div className="container-item" key={element}>
                <span className="item-title-space" />
                <Button onClick={handlePosition} className="lncd-button" style={{ width: '120px' }}>
                  {t('property.textTab.positionLabel')}
                </Button>
              </div>
            );
          default:
            return null;
        }
      })}
    </TouchScroll>
  );
};

export default connect(
  state => ({
    propsMenuOpen: state.app.propsMenuOpen,
  }),
  null
)(TextMenu);
