import * as React from 'react';

function SvgIconRoadMarker(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M8.941 6.727V22h2.588v-8.91c0-.7.581-1.272 1.295-1.272.713 0 1.294.572 1.294 1.273v2.545h-1.294l2.588 3.819L18 15.636h-1.294v-2.545c0-2.105-1.742-3.818-3.882-3.818-.45 0-.882.075-1.284.214l-.01-2.76h1.94L10.236 1 7 6.727h1.941z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconRoadMarker;
