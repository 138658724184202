import * as React from 'react';

function SvgImageArrowPositionRight(props) {
  return (
    <svg width={33} height={10} {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M0 3h19v3H0z" />
        <path d="M33 4.295L18 0v9.288z" />
      </g>
    </svg>
  );
}

export default SvgImageArrowPositionRight;
