import GeomPoint from './GeomPoint';
import GeomLine from './GeomLine';
import GeomArc from './GeomArc';
import GeomPolyArc from './GeomPolyArc';
import GeomOffsetArc from './GeomOffsetArc';
import StreetBorder from './StreetBorder';
import StreetEndCaps from './StreetEndCaps';
import StreetIntersectionArea from './StreetIntersectionArea';
import StreetInteriorStripe from './StreetInteriorStripe';
import StreetBorderIsectTracker from './StreetBorderIsectTracker';
import CurbReturnEngine from './CurbReturnEngine';
import Utility from './Utility';
import UtilityGeom from './UtilityGeom';
import UtilityMath from './UtilityMath';
import Axial from './Axial';
import * as workData from '@src/data/WorkData';
import diagramManager from '@src/data/DiagramManager';
import StreetBreakLine from './StreetBreakLine';
import { createLine, createArc } from './ShapeOperationData';
import { STRIPE_LIKE, STREET_TYPE, FUNCTION_TYPE, ROAD_LAYER_VALUES, ROAD_LAYER } from '@src/constant';

class _Street {
  /**
   *
   * @param {[]} components
   */
  constructor(components) {
    this.defaultSetback = 60;
    this.components = [];
    if (Utility.isNonEmptyArray(components)) {
      this.components = components;
    }
  }

  /**
   *
   * @param {{functype: 'StreetNew'}} o
   * @returns {number}
   */
  getStreetWidth(o) {
    var width = 0;
    if (o) {
      var components = o.components;
      components.forEach(function(item) {
        if (item.width) {
          width += item.width;
        }
      }, this);
    }
    return width;
  }

  /**
   * 对齐 component 和 street 轴线
   * @description 用于渲染 stripe 中 components 的核心方法
   * @param {{functype: 'StreetNew'}} o
   */
  alignComponentsToStreetAxis(o) {
    let obj = o;
    let lw = this.getStreetWidth(obj) / 2;
    for (let i = 0; i < obj.components.length; i++) {
      let component = obj.components[i];
      let type = component.dividerType || component.type;
      if (component.type === 'divider' || type === 'shoulder') {
        lw -= component.width / 2;
        this.alignDividerToStreetAxis(obj.components, i, lw);
        lw -= component.width / 2;
      }
      if (STRIPE_LIKE.includes(type)) {
        let segments = [];
        let gpa = null;
        if (obj.offsetArcPath) gpa = new GeomPolyArc(obj.offsetArcPath.segments);
        else gpa = new GeomPolyArc(obj.segments);
        if (obj.streetType === STREET_TYPE.ROUNDABOUT) {
          gpa.offsetNormal(lw, true);
        } else {
          gpa.offsetNormal(lw);
        }
        for (let j = 0; j < gpa.segments.length; j++) {
          const ptStart = {
            x: gpa.segments[j].ptStart.x,
            y: gpa.segments[j].ptStart.y,
          };
          const ptStop = {
            x: gpa.segments[j].ptStop.x,
            y: gpa.segments[j].ptStop.y,
          };
          if (gpa.segments[j].declaredClass === 'GeomLine') {
            segments.push(
              createLine({
                ptStart,
                ptStop,
              })
            );
          } else {
            segments.push(
              createArc({
                ...gpa.segments[j],
                ptStart,
                ptStop,
              })
            );
          }
        }
        component.segments = segments;
        this.alignStripeToStreetAxis(component);
      } else if (type === 'lane') {
        lw -= component.width / 2;
        this.alignLaneToStreetAxis(component, lw);
        lw -= component.width / 2;
      }
    }
  }

  /**
   * 对齐 stripe 和 street 轴线
   * @param {{type: 'stripe' | 'lane'}} stripe - stripe | lane
   */
  alignStripeToStreetAxis(stripe) {
    // console.log('stripe', stripe);
    var ss = [];
    var multiSegment = stripe.segments.length > 1;
    if (stripe.axis) {
      var bd61 = function(strp) {
        return Utility.isNumeric(strp.arcOffsetStart) && strp.arcOffsetStart !== 0;
      };
      var bd62 = function(strp) {
        return Utility.isNumeric(strp.arcOffsetStop) && strp.arcOffsetStop !== 0;
      };
      stripe.segments.forEach(function(seg) {
        ss.push(seg);
      }, this);
      if (bd61(stripe) && !bd62(stripe) && multiSegment) {
        if (stripe.stopOffsetStart === stripe.arcOffsetStart) {
          stripe.stopOffsetStop = stripe.stopOffsetStart;
          stripe.trLenStop = stripe.trLenStart;
          stripe.trPctStop = stripe.trPctStart;
          stripe.arcOffsetStop = stripe.arcOffsetStart;
          stripe.stopOffsetStart = undefined;
          stripe.trLenStart = undefined;
          stripe.trPctStart = undefined;
          stripe.arcOffsetStart = undefined;
        }
      }
      if (bd62(stripe) && !multiSegment) {
        if (bd61(stripe)) {
        } else {
          stripe.stopOffsetStart = stripe.stopOffsetStop;
          stripe.trLenStart = stripe.trLenStop;
          stripe.trPctStart = stripe.trPctStop;
          stripe.arcOffsetStart = stripe.arcOffsetStop;
        }
        stripe.stopOffsetStop = undefined;
        stripe.trLenStop = undefined;
        stripe.trPctStop = undefined;
        stripe.arcOffsetStop = undefined;
      }
      var goa = undefined;
      if (bd61(stripe)) {
        goa = new GeomOffsetArc([ss[0]], 30, stripe.trLenStart, stripe.trPctStart, stripe.stopOffsetStart);
        goa.inflateAxis(stripe.arcOffsetStart);
        goa.calculateOffsetPath();
        ss.shift();
        for (var i = goa.offsetArcSegments.length - 1; i >= 0; i--) {
          if (goa.offsetArcSegments[i].declaredClass == 'GeomLine')
            ss.unshift({
              type: 'line',
              ptStart: { x: goa.offsetArcSegments[i].getPointStart().x, y: goa.offsetArcSegments[i].getPointStart().y },
              ptStop: { x: goa.offsetArcSegments[i].getPointStop().x, y: goa.offsetArcSegments[i].getPointStop().y },
            });
          else
            ss.unshift({
              type: 'arc',
              ptStart: { x: goa.offsetArcSegments[i].getPointStart().x, y: goa.offsetArcSegments[i].getPointStart().y },
              ptStop: { x: goa.offsetArcSegments[i].getPointStop().x, y: goa.offsetArcSegments[i].getPointStop().y },
              r: goa.offsetArcSegments[i].r,
              largeArcFlag: goa.offsetArcSegments[i].largeArcFlag,
              sweepFlag: goa.offsetArcSegments[i].sweepFlag,
            });
        }
      }
      if (bd62(stripe)) {
        goa = new GeomOffsetArc([ss[ss.length - 1]], 30, stripe.trLenStop, stripe.trPctStop, stripe.stopOffsetStop);
        goa.inflateAxis(stripe.arcOffsetStop);
        goa.calculateOffsetPath();
        ss.pop();
        goa.offsetArcSegments.forEach(function(seg) {
          if (seg.declaredClass == 'GeomLine')
            ss.push({
              type: 'line',
              ptStart: { x: seg.getPointStart().x, y: seg.getPointStart().y },
              ptStop: { x: seg.getPointStop().x, y: seg.getPointStop().y },
            });
          else
            ss.push({
              type: 'arc',
              ptStart: { x: seg.getPointStart().x, y: seg.getPointStart().y },
              ptStop: { x: seg.getPointStop().x, y: seg.getPointStop().y },
              r: seg.r,
              largeArcFlag: seg.largeArcFlag,
              sweepFlag: seg.sweepFlag,
            });
        });
      }
      stripe.offsetArcSegments = ss;
      /*if (!Utility.isValid(goa))
	  return;
  if (goa) {
	var offsetArcSegments = [];
  for (let j = 0; j < goa.offsetArcSegments.length; j++) {
	if (goa.offsetArcSegments[j].declaredClass == 'GeomLine') {
	  offsetArcSegments.push({
		type: 'line',
		ptStart: {
		  x: goa.offsetArcSegments[j].getPointStart().x,
		  y: goa.offsetArcSegments[j].getPointStart().y,
		},
		ptStop: {
		  x: goa.offsetArcSegments[j].getPointStop().x,
		  y: goa.offsetArcSegments[j].getPointStop().y,
		},
	  });
	} else {
	  offsetArcSegments.push({
		type: 'arc',
		ptStart: {
		  x: goa.offsetArcSegments[j].getPointStart().x,
		  y: goa.offsetArcSegments[j].getPointStart().y,
		},
		ptStop: {
		  x: goa.offsetArcSegments[j].getPointStop().x,
		  y: goa.offsetArcSegments[j].getPointStop().y,
		},
		r: goa.offsetArcSegments[j].r,
		largeArcFlag: goa.offsetArcSegments[j].largeArcFlag,
		sweepFlag: goa.offsetArcSegments[j].sweepFlag,
	  });
	}
  }
  stripe.offsetArcSegments = offsetArcSegments;
	}*/
    }
  }

  alignLaneToStreetAxis(component, width) {
    //var gl = b52.clone();
    //gl.offsetNormal(width);
  }

  alignDividerToStreetAxis(components, idx, width) {}

  /**
   * Calculate street across, etc.
   * @param {{functype: 'StreetNew', surfaceType: string, roadLayer: ROAD_LAYER_VALUES}} street street object
   */
  computeStreets(street) {
    const { surfaceType } = street;
    var streets = workData.getStreets({ surfaceType });
    let [streetBorders, streetEndCaps] = this.getStreetEdges(streets);
    // get existing curbs
    let curbReturns = this.getCurbReturns(surfaceType);
    // console.log('curbReturns', curbReturns);
    this.computeStreetBorders(surfaceType, streets, streetEndCaps, streetBorders, curbReturns);
    // console.log('streetBorders after compute street borders', streetBorders);
    // console.log('streetEndCaps after compute street borders', streetEndCaps);
    // console.log('curbReturns after compute street borders', curbReturns);
    var streetInteriorStripes = this.getStreetInteriorStripes(streets);
    // console.log('streetInteriorStripes', streetInteriorStripes);
    this.computeStreetInteriors(surfaceType, streets, streetBorders, streetInteriorStripes, curbReturns, street);
    // console.log('curbReturns after compute street interiors', curbReturns);
    this.computeCurbReturnFillPaths(surfaceType, curbReturns);
    // console.log('curbReturns after compute curbReturn fill paths', curbReturns);
    // console.log('--------------------------');
  }

  /**
   * Street 相交边界计算
   * @param {string} surfaceType
   * @param {{functype: 'StreetNew'}[]} streets - 逻辑上会相交的道路
   * @param {GeomLine[]} streetEndCaps - Street 的两个边界的 GeomLine，路的尽头
   * @param {StreetBorder[]} streetBorders - Street 的和轴线平行的两个边界，例如PavedEW的最上边和最下边
   * @param {{functype: 'CurbReturn'}[]} curbReturns - 道路拐角
   */
  computeStreetBorders(surfaceType, streets, streetEndCaps, streetBorders, curbReturns) {
    // console.log('streets', streets);
    // console.log('streetBorders', streetBorders);
    // console.log('curbReturns', curbReturns);
    // FIXME: why surfaceType not used in the next expression?
    var streetBorderIsectTracker = new StreetBorderIsectTracker('paved', streets, this.defaultSetback);
    // console.log('streetBorderIsectTracker', streetBorderIsectTracker)

    let streetBordersLen = streetBorders.length;
    for (let i = 0; i < streetBordersLen; i++) {
      let b1 = streetBorders[i];
      for (let j = i + 1; j < streetBordersLen; j++) {
        let b2 = streetBorders[j];
        streetBorderIsectTracker.intersectBorderStripes(b1, b2);
      }
      let streetEndCapLen = streetEndCaps.length;
      for (let j = 0; j < streetEndCapLen; j++) {
        var streetEndCap = streetEndCaps[j];
        if (b1.streetId !== streetEndCap.streetId) {
          streetBorderIsectTracker.intersectBorderStripeEndCaps(b1, streetEndCap);
        }
      }
    }

    var refreshCurbReturns = [];
    var removeCurbReturns = [];
    streetBorderIsectTracker.createCurbReturns(curbReturns, refreshCurbReturns, removeCurbReturns);

    // console.log('removeCurbReturns', removeCurbReturns);
    let removeCurbReturnsLen = removeCurbReturns.length;
    for (let i = 0; i < removeCurbReturnsLen; i++) {
      workData.deleteObject(removeCurbReturns[i].operateid);
      this.removeCurbReturnFillPaths(surfaceType, removeCurbReturns[i]);
    }

    // console.log('refreshCurbReturns', refreshCurbReturns);
    let refreshCurbReturnsLen = refreshCurbReturns.length;
    for (let i = 0; i < refreshCurbReturnsLen; i++) {
      var b = false;
      for (let j = 0; j < curbReturns.length; j++) {
        if (refreshCurbReturns[i].operateid == curbReturns[j].operateid) {
          b = true;
          break;
        }
      }
      if (!b) {
        curbReturns.push(refreshCurbReturns[i]);
      }
    }

    let curbReturnsLen = curbReturns.length;
    for (let i = 0; i < curbReturnsLen; i++) {
      for (let j = i + 1; j < curbReturnsLen; j++) {
        var cre = new CurbReturnEngine();
        if (cre.overlaps(curbReturns[i], curbReturns[j])) {
          cre.negotiateOverlap(curbReturns[i], curbReturns[j]);
        }
      }
    }

    for (let i = 0; i < streetBordersLen; i++) {
      const border = streetBorders[i];
      var obj = workData.getObject(border.streetId);
      border.computeVisibility(streets, curbReturns, obj.roadLayer);
      if (obj.components instanceof Array) {
        for (let j = 0, len = obj.components.length; j < len; j++) {
          if (obj.components[j].key === border.stripeKey) {
            obj.components[j].hiddenSegs = border.hiddenSegs;
          }
        }
      }
    }
  }

  /**
   * 计算 Street 内部
   * @param {string} surfaceType
   * @param {{functype: 'StreetNew'}[]} streets
   * @param {StreetBorder[]} streetBorders
   * @param {StreetInteriorStripe[]} streetInteriorStripes - Street 内部区域的 Stripe
   * @param {{functype: 'CurbReturn'}[]} curbReturns
   * @param {ROAD_LAYER} roadLayer
   */
  computeStreetInteriors(surfaceType, streets, streetBorders, streetInteriorStripes, curbReturns, street) {
    // console.log('streets', streets);
    // console.log('streetBorders', streetBorders);
    // console.log('streetInteriorStripes', streetInteriorStripes);
    let streetSurface;
    let { operateid, roadLayer } = street;
    var data = workData.getUseData();
    for (let i = 0; i < data.length; i++) {
      if (data[i].functype == 'StreetSurface') {
        if (data[i].surfaceType == surfaceType) {
          streetSurface = data[i];
          break;
        }
      }
    }

    let intersectStreets = [];
    if (curbReturns.length > 0) {
      for (const cb of curbReturns) {
        let id = '_';
        let streetArr = cb.streetStripes.map(s => {
          id += s.idStreet;
          return workData.getObject(s.idStreet);
        });
        if (intersectStreets.findIndex(item => item.id === id) === -1) {
          intersectStreets.push({ id, streetArr });
        }
      }
    }
    // console.log(intersectStreets);

    if (typeof streetSurface === 'object') {
      streetSurface.sfills = [];

      let selectedStreets = streets;
      for (const item of intersectStreets) {
        if (item.id.includes(operateid)) {
          selectedStreets = item.streetArr;
        }
      }
      // console.log(selectedStreets);

      let maxRoadLayer = 0;
      if (selectedStreets[0]) {
        maxRoadLayer = selectedStreets.reduce((prev, next) => {
          return prev > next.roadLayer ? prev : next.roadLayer;
        }, selectedStreets[0].roadLayer);
      }
      // console.log('maxRoadLayer', maxRoadLayer);
      // console.log('streets', streets);

      for (let i = 0, len = selectedStreets.length; i < len; i++) {
        let street = selectedStreets[i];

        var siss = streetInteriorStripes.filter(function(item) {
          return street.operateid === item.streetId;
        });
        var sbs = streetBorders.filter(function(streetBorder) {
          return street.operateid === streetBorder.streetId;
        });
        var streetBreakLines = this.computeStreetBreakLines(street, sbs, curbReturns);
        var streetIntersectionAreas = this.computeStreetIntersectionAreas(
          street,
          selectedStreets,
          sbs,
          streetBreakLines,
          curbReturns
        );
        // console.log('streetBreakLines', streetBreakLines);
        // console.log('streetInteriorStripes', siss);
        // console.log('streetBorders', sbs);
        // console.log('streetIntersectionAreas', streetIntersectionAreas)

        // 计算 Street 相交部分内部的 stripe
        for (let j = 0; j < siss.length; j++) {
          var stripe = this.getStripeByKey(street, siss[j].stripeKey);
          var sis = new StreetInteriorStripe(street, stripe);
          // console.log('StreetInteriorStripe', sis);
          sis.segments = siss[j].segments;
          sis.computeVisibility(selectedStreets, streetIntersectionAreas);
          // siss[j].hiddenSegs = sis.hiddenSegs;

          // FIXME: interior stripe not correct
          if (stripe) {
            if (street.roadLayer < maxRoadLayer) {
              stripe.hiddenSegs = sis.hiddenSegs;
            }
            if (street.roadLayer === maxRoadLayer) {
              stripe.hiddenSegs = [];
            }
            if (selectedStreets.every(s => s.roadLayer === roadLayer)) {
              stripe.hiddenSegs = sis.hiddenSegs;
            }
            // stripe.hiddenSegs = sis.hiddenSegs;
          }
        }

        var pd = this.getFillPathData(street);
        if (Utility.isNonEmptyString(pd)) {
          streetSurface.sfills.push({
            key: street.operateid,
            pathData: pd,
          });
        }
      }
    }
    if (streetSurface) {
      workData.setObj(streetSurface.operateid, streetSurface);
    }
  }

  /**
   * 计算 Street 断线
   * @param {{functype: 'StreetNew'}} street
   * @param {StreetBorder[]} streetBorders
   * @param {{functype: 'CurbReturn'}[]} curbReturns
   * @returns {StreetBreakLine[]}
   */
  computeStreetBreakLines(street, streetBorders, curbReturns) {
    // console.log('streetBorders', streetBorders);
    // console.log('curbReturns', curbReturns);
    var curbReturnsPart1 = [],
      curbReturnsPart2 = [],
      streetBreakLines = [];
    var cre = new CurbReturnEngine();
    if (2 === streetBorders.length) {
      curbReturnsPart1 = curbReturns.filter(function(curbReturn) {
        return cre.isConnectedToStripe(curbReturn, streetBorders[0]);
      });
      curbReturnsPart2 = curbReturns.filter(function(curbReturn) {
        return cre.isConnectedToStripe(curbReturn, streetBorders[1]);
      });
    }
    if (curbReturnsPart1.length > 0 || curbReturnsPart2.length > 0) {
      streetBreakLines = this.getBreakLinesForStreet(
        street,
        streetBorders[0],
        streetBorders[1],
        curbReturnsPart1,
        curbReturnsPart2
      );
    }
    if (streetBreakLines.length > 0) {
      streetBreakLines.sort(function(a, b) {
        return a.streetAxisOffset - b.streetAxisOffset;
      });
      //this.discardIntersectingBreakLines(streetBreakLines);
    }
    return streetBreakLines;
  }

  /**
   * 获取 Street 断线
   * @param {{functype: 'StreetNew'}} street
   * @param {StreetBorder} streetBorder0
   * @param {StreetBorder} streetBorder1
   * @param {{functype: 'CurbReturn'}} curbReturns0
   * @param {{functype: 'CurbReturn'}} curbReturns1
   * @returns {StreetBreakLine[]}
   */
  getBreakLinesForStreet(street, streetBorder0, streetBorder1, curbReturns0, curbReturns1) {
    var streetBreakLines = [],
      streetWidth = this.getStreetWidth(street);

    for (let i = curbReturns0.length - 1; i >= 0; i -= 1) {
      var curbReturn0 = curbReturns0[i];
      for (let j = curbReturns1.length - 1; j >= 0; j -= 1) {
        var curbReturn1 = curbReturns1[j];
        var cre = new CurbReturnEngine();
        var streetBreakLine = cre.getBreakLineType1(street, curbReturn0, curbReturn1, 48);
        if (streetBreakLine) {
          streetBreakLines.push(streetBreakLine);
          curbReturns0.splice(i, 1);
          curbReturns1.splice(j, 1);
          break;
        }
      }
    }

    // 位于上层 street 覆盖下层部分的处理,仅相交一侧的情况下,即T型道路
    for (let k = 1; k <= 5 && curbReturns0.length > 0 && curbReturns1.length > 0; k += 1) {
      for (let i = curbReturns0.length - 1; i >= 0; i -= 1) {
        var curbReturn0 = curbReturns0[i];
        for (let j = curbReturns1.length - 1; j >= 0; j -= 1) {
          var curbReturn1 = curbReturns1[j];
          var cre = new CurbReturnEngine();
          var streetBreakLine = cre.getBreakLineType3(street, curbReturn0, curbReturn1, (streetWidth / 5) * k, 48);
          if (streetBreakLine) {
            streetBreakLines.push(streetBreakLine);
            curbReturns0.splice(i, 1);
            curbReturns1.splice(j, 1);
            break;
          }
        }
      }
    }

    // 当只相交左侧,即T型相交时?
    if (curbReturns0.length > 0) {
      curbReturns0.forEach(function(cr) {
        var cre = new CurbReturnEngine();
        var streetBreakLine = cre.getBreakLineType4(street, cr, streetBorder0, streetBorder1, 48);
        if (streetBreakLine) {
          streetBreakLines.push(streetBreakLine);
        }
      }, this);
      curbReturns0.splice(0, curbReturns0.length);
    }

    // 当只相交右侧,即T型相交时?
    if (curbReturns1.length > 0) {
      curbReturns1.forEach(function(cr) {
        var cre = new CurbReturnEngine();
        var streetBreakLine = cre.getBreakLineType4(street, cr, streetBorder1, streetBorder0, 48);
        if (streetBreakLine) {
          streetBreakLines.push(streetBreakLine);
        }
      }, this);
      curbReturns1.splice(0, curbReturns1.length);
    }

    return streetBreakLines;
  }

  /**
   * 计算 Street 相交区域
   * @param {{functype: 'StreetNew'}} street
   * @param {{functype: 'StreetNew'}[]} streets
   * @param {StreetBorder[]} streetBorders
   * @param {StreetBreakLine[]} streetBreakLines
   * @param {{functype: 'CurbReturn'}[]} curbReturns
   * @returns {StreetIntersectionArea[]}
   */
  computeStreetIntersectionAreas(street, streets, streetBorders, streetBreakLines, curbReturns) {
    // console.log('streetBreakLines', streetBreakLines);
    // console.log('curbReturns', curbReturns);
    var intersectionAreas = []; // street.intersectionAreas;
    var streetIntersectionAreas = [];
    if (0 === streetBreakLines.length) {
      return [];
    }
    var gpa = streetBorders[0].gpaStripe;
    var ptStart = gpa.getPointStart();
    gpa = streetBorders[1].gpaStripe;
    var ptStop = gpa.getPointStart();
    for (var idx = 0; idx < streetBreakLines.length; idx += 1) {
      var streetBreakLine = streetBreakLines[idx];
      if (0 === idx && streetBreakLine.posIsVisible) {
        if (streetBreakLine.breakLineType < 3) {
          var sia = new StreetIntersectionArea(
            0,
            0,
            streetBreakLine.curbReturnKeyBorder0,
            streetBreakLine.curbReturnKeyBorder1,
            new GeomLine(ptStart, ptStop),
            streetBreakLine.cloneLine(),
            1,
            intersectionAreas,
            street,
            streets,
            curbReturns
          );
          streetIntersectionAreas.push(sia);
        }
      }
      if (streetBreakLines.length - 1 === idx) {
        if (!streetBreakLine.posIsVisible) {
          if (streetBreakLine.breakLineType < 3) {
            streetIntersectionAreas.push(
              new StreetIntersectionArea(
                streetBreakLine.curbReturnKeyBorder0,
                streetBreakLine.curbReturnKeyBorder1,
                0,
                0,
                streetBreakLine.cloneLine(),
                new GeomLine(ptStart, ptStop),
                2,
                intersectionAreas,
                street,
                streets,
                curbReturns
              )
            );
          }
        }
      } else {
        if (!streetBreakLine.posIsVisible) {
          var breakLines, temp;
          for (var j = streetBreakLines.length - 1; j > idx; j -= 1) {
            if (streetBreakLines[j].posIsVisible) {
              breakLines = streetBreakLines[j];
              temp = j;
            }
          }
          if (breakLines && temp > idx) {
            for (var j = temp + 1; j < streetBreakLines.length; j += 1) {
              if (streetBreakLines[j].posIsVisible) {
                breakLines = streetBreakLines[j];
                temp = j;
              } else {
                break;
              }
            }
          }
          if (breakLines && temp > idx) {
            var sia = new StreetIntersectionArea(
              streetBreakLine.curbReturnKeyBorder0,
              streetBreakLine.curbReturnKeyBorder1,
              breakLines.curbReturnKeyBorder0,
              breakLines.curbReturnKeyBorder1,
              streetBreakLine.cloneLine(),
              breakLines.cloneLine(),
              3,
              intersectionAreas,
              street,
              streets,
              curbReturns
            );
            streetIntersectionAreas.push(sia);
            idx = temp;
          }
        }
      }
    }
    return streetIntersectionAreas;
  }

  /**
   * 计算 CurbReturn 填充路径
   * @param {string} surfaceType
   * @param {{functype: 'CurbReturn'}[]} curbReturns
   */
  computeCurbReturnFillPaths(surfaceType, curbReturns) {
    let streetSurface;
    var data = workData.getUseData();
    for (let i = 0; i < data.length; i++) {
      if (data[i].functype == 'StreetSurface') {
        if (data[i].surfaceType == surfaceType) {
          streetSurface = data[i];
          break;
        }
      }
    }
    if (streetSurface) {
      streetSurface.crfills = [];
      for (let i = 0; i < curbReturns.length; i++) {
        var curbReturn = curbReturns[i];
        var gpa = new GeomPolyArc(curbReturn.segments);
        var cr = new CurbReturnEngine(curbReturn.fromStart, curbReturn.fromStartPercent, curbReturn.normal);
        var pd = gpa.getSvgPathData();
        if (cr.getFillPathData(curbReturn)) {
          pd += cr.getFillPathData(curbReturn);
        }
        if (Utility.isNonEmptyString(pd)) {
          streetSurface.crfills.push({
            key: curbReturn.operateid,
            pathData: pd,
          });
        }
      }
    }
    if (streetSurface) {
      workData.setObj(streetSurface.operateid, streetSurface);
    }
  }

  /**
   * 移除 CurbReturn 填充路径
   * @param {string} surfaceType
   * @param {{functype: 'CurbReturn'}[]} curbReturn
   */
  removeCurbReturnFillPaths(surfaceType, curbReturn) {
    let streetSurface;
    var data = workData.getUseData();
    for (let i = 0; i < data.length; i++) {
      if (data[i].functype == 'StreetSurface') {
        if (data[i].surfaceType == surfaceType) {
          streetSurface = data[i];
          break;
        }
      }
    }
    if (streetSurface) {
      for (let i = streetSurface.crfills.length - 1; i >= 0; i--) {
        if (streetSurface.crfills[i].key == curbReturn.operateid) {
          streetSurface.crfills.splice(i);
        }
      }
      workData.setObj(streetSurface.operateid, streetSurface);
    }
  }

  /**
   *
   * @param {{functype: 'CurbReturn' | 'StreetNew'}} o - StreetNew | CurbReturn
   * @returns {string} - path
   */
  getFillPathData(o) {
    // sbs: streetBorders
    let [sbs, streetEndCaps] = this.getStreetEdges([o]);
    if (sbs.length > 0) {
      var gpa;
      if (sbs[0]) {
        if (sbs[0] && sbs[0].gpaAxis) {
          gpa = sbs[0].gpaAxis;
        } else {
          gpa = sbs[0].gpaStripe; // new GeomPolyArc(sbs[0].segments);
        }
      }
      var path = gpa.getSvgPathData();
      if (sbs[1]) {
        if (sbs[1].gpaAxis) {
          gpa = sbs[1].gpaAxis;
        } else {
          gpa = sbs[1].gpaStripe; // new GeomPolyArc(sbs[1].segments);
        }
      }
      gpa.reverse();
      if (gpa && Utility.isNonEmptyArray(gpa.segments)) {
        path = path + ' L' + gpa.getPointStart().toString() + ' ';
        for (let i = 0, il = gpa.segments.length; i < il; i++) {
          path = path + gpa.segments[i].getSegmentPathData(false);
        }
      }
      return path;
    }
  }

  // move to workData
  // /**
  //  * Get streets of same surface type and road layer
  //  * @param {string} surfaceType
  //  * @param {ROAD_LAYER_VALUES} roadLayer
  //  * @returns {{functype: 'StreetNew'}[]}
  //  */
  // getStreets(surfaceType, roadLayer) {
  //   const streets = workData
  //     .getUseData()
  //     .filter(
  //       n =>
  //         n.functype === FUNCTION_TYPE.STREETNEW &&
  //         n.surfaceType === surfaceType &&
  //         (roadLayer === undefined || n.roadLayer === roadLayer)
  //     );
  //   return streets;
  // }

  // TODO: rename or refactor this method
  /**
   *
   * @param {{functype: 'StreetNew'}} street
   * @param {array} street.components
   * @returns {{type: 'stripe'}[]} - stripe array
   */
  getStripes(street) {
    var stripes = [];
    if (Utility.isNonEmptyArray(street.components)) {
      for (let i = 0; i < street.components.length; i++) {
        if (street.components[i].type == 'stripe' || street.components[i].type == 'shoulder') {
          stripes.push(street.components[i]);
        }
      }
    }
    return stripes;
  }

  /**
   *
   * @param {string} surfaceType
   * @param {ROAD_LAYER_VALUES} roadLayer
   * @returns {{functype: 'CurbReturn'}[]} - CurbReturn array
   */
  getCurbReturns(surfaceType, roadLayer) {
    let curbReturns = [];
    var data = workData.getUseData();
    for (let i = 0; i < data.length; i++) {
      if (data[i].functype == 'CurbReturn') {
        if (data[i].surfaceType == surfaceType) {
          if (roadLayer === undefined || data[i].roadLayer === roadLayer) {
            curbReturns.push(data[i]);
          }
        }
      }
    }
    return curbReturns;
  }

  /**
   *
   * @param {{functype: 'StreetNew'}[]} streets
   * @returns {[StreetBorder[], StreetEndCaps[]]}
   */
  getStreetEdges(streets) {
    const streetBorders = [];
    const streetEndCaps = [];
    for (let i = 0; i < streets.length; i++) {
      var stripes = this.getStripes(streets[i]);
      var streetBorder0 = undefined;
      var streetBorder1 = undefined;
      for (let j = 0; j < stripes.length; j++) {
        // FIXME: this is not used
        var segments = stripes[j].segments;
        if (stripes[j].axis) {
          segments = stripes[j].axis.profile;
        }
        if (j == 0) {
          let streetBorder = new StreetBorder(streets[i], stripes[j]);
          // console.log(streetBorder);
          streetBorders.push(streetBorder);
          streetBorder0 = streetBorder;
        } else if (j == stripes.length - 1) {
          let streetBorder = new StreetBorder(streets[i], stripes[j]);
          streetBorders.push(streetBorder);
          streetBorder1 = streetBorder;
        }
      }
      if (streetBorder0 && streetBorder1) {
        streetEndCaps.push(new StreetEndCaps(streetBorder0, streetBorder1));
      }
    }
    // console.log('streetBorders', streetBorders);
    // console.log('streetEndCaps', streetEndCaps);
    return [streetBorders, streetEndCaps];
  }

  /**
   * 获取 Street 内部的 stripe
   * @param {{functype: 'StreetNew'}[]} streets
   * @returns {StreetInteriorStripe[]}
   */
  getStreetInteriorStripes(streets) {
    var streetInteriorStripes = [];
    for (let i = 0; i < streets.length; i++) {
      var stripes = this.getStripes(streets[i]);
      for (let j = 0; j < stripes.length; j++) {
        if (j != 0 && j != stripes.length - 1) {
          let streetInteriorStripe = new StreetInteriorStripe(streets[i], stripes[j]);
          streetInteriorStripe.segments = stripes[j].segments;
          streetInteriorStripes.push(streetInteriorStripe);
        }
      }
    }
    return streetInteriorStripes;
  }

  /**
   * @returns {number} - lane count
   */
  getLaneCount() {
    var count = 0;
    for (let i = 0; i < this.components.length; i++) {
      if (this.components[i].type === 'lane') {
        count++;
      }
    }
    return count;
  }

  /**
   *
   * @param {number} newCount
   */
  setLaneCount(newCount) {
    var count = this.getLaneCount();
    if (newCount < 1) {
      newCount = 1;
    } else {
      if (newCount > 20) {
        newCount = 20;
      }
    }
    if (count === newCount) {
      return;
    }
    if (count < newCount) {
      while (this.getLaneCount() < newCount) {
        this.addTravelLane();
      }
    } else {
      while (this.getLaneCount() > newCount) {
        this.removeTravelLane();
      }
    }
  }

  addTravelLane() {
    return this.addRegion('lane', 'travel');
  }

  // TODO: This method is uncompleted.
  /**
   *
   * @param {string} streetRegionType
   * @param {string} streetLaneType
   */
  addRegion(streetRegionType, streetLaneType) {
    var dividerIndex = this._getDividerIndex();
    var prevLaneCount = 0,
      nextLaneCount = 0;
    if (dividerIndex !== -1) {
      for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].type === 'lane') {
          if (i > dividerIndex) {
            nextLaneCount++;
          } else {
            prevLaneCount++;
          }
        }
      }
      var bd2a = this.getAdjacentStripes(this.components[dividerIndex]);
      if (prevLaneCount <= nextLaneCount) {
      } else {
      }
    }
  }

  removeTravelLane() {
    var dividerIndex = this._getDividerIndex(),
      bdaf,
      prevLaneCount = 0,
      nextLaneCount = 0,
      bdb2,
      bdb3;
    if (dividerIndex === -1) {
    } else {
      for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].type === 'lane') {
          if (i > dividerIndex) {
            nextLaneCount++;
          } else {
            prevLaneCount++;
          }
        }
      }
      bdb2 = this.getAdjacentStripes(this.components[dividerIndex]);
      if (prevLaneCount > nextLaneCount) {
        bdb3 = this.getAdjaCentergions(bdb2.previous.key);
        this._removeRegionByKey(bdb3.previous.key);
      } else {
        bdb3 = this.getAdjaCentergions(bdb2.next.key);
        this._removeRegionByKey(bdb3.next.key);
      }
    }
  }

  _getDividerIndex() {
    for (let idx = 0; idx < this.components.length; idx++) {
      const component = this.components[idx];
      if (component.type === 'divider') {
        return idx;
      }
    }
    return -1;
  }

  _getMedianIndex() {
    for (let idx = 0; idx < this.components.length; idx++) {
      const component = this.components[idx];
      if (component.type === 'stripe' && component.median) {
        return idx;
      }
    }
    return -1;
  }

  /**
   * 获取相邻的 stripe
   * @param {{type: 'stripe'}} stripe
   * @returns {{ previous: object | null, next: object | null }}
   */
  getAdjacentStripes(stripe) {
    var bd8f = {
      previous: null,
      next: null,
    };
    for (var i = 0; i < this.components.length; i++) {
      if (this.components[i].key === stripe.key) {
        if (i < this.components.length - 1 && this.components[i + 1].type === 'stripe') {
          bd8f.next = this.components[i + 1];
          break;
        }
      } else {
        if (this.components[i].type === 'stripe') {
          bd8f.previous = this.components[i];
        } else {
          bd8f.previous = null;
        }
      }
    }
    return bd8f;
  }

  /**
   *
   * @param {*} key
   */
  _removeRegionByKey(key) {
    var child,
      bdf4,
      bdf5,
      bdf6 = false,
      bdf7,
      bdf8,
      bdf9,
      // FIXME: componentItem method is not exist.
      bdfa = this.componentItem(key);
    bdf8 = this.getAdjacentStripes(bdfa);
    bdf9 = this.getAdjaCentergions(bdf8.previous.key);
    if (bdf9.previous && bdf9.previous.type === 'lane') {
      bdf6 = true;
      bdf7 = bdf8.previous;
    } else {
      bdf9 = this.getAdjaCentergions(bdf8.next.key);
      if (bdf9.next && bdf9.next.type === 'lane') {
        bdf7 = bdf8.next;
      }
    }
    if (!bdf7) {
      bdf9 = this.getAdjaCentergions(bdf8.previous.key);
      if (bdf9.previous && bdf9.previous.type === 'divider') {
        bdf7 = bdf8.next;
      } else {
        bdf9 = this.getAdjaCentergions(bdf8.next.key);
        if (bdf9.next && bdf9.next.type === 'divider') {
          bdf6 = true;
          bdf7 = bdf8.previous;
        }
      }
    }
    for (var i = 0; i < this.components.length; i++) {
      if (this.components[i].key === key) {
        bdf4 = this.components[i].type;
        if (bdf6) {
          this.components.splice(i - 1, 2);
        } else {
          this.components.splice(i, 2);
        }
        break;
      }
    }
  }

  /**
   * This method is not used in anywhere
   * @param {GeomPoint} pt
   * @param {number} width
   */
  getStripeInProximity(pt, width) {
    var bda0;
    for (var i = 0; i < this.components.length; i++) {
      if (this.components[i].type === 'stripe') {
        var stripe = this.components[i];
        var gpa = new GeomPolyArc(stripe.segments);
        var gpo = gpa.getOffsetsToPoint(pt);
        if (gpo) {
          var normal = Math.abs(gpo.normal);
          if (normal <= width && normal < 2 * width) {
            //bd9f = normal;
            bda0 = {
              stripe: stripe,
              part: 'primary',
              fromStartPercent: gpo.fromStartPercent,
            };
          }
        }
      }
    }
    return bda0;
  }

  /**
   * 获取最近的 region
   * @param {GeomPoint} pt
   * @returns {{type: 'stripe'} | undefined}
   */
  getRegionClosest(pt) {
    var streetWidth = this.getStreetWidth();
    var bda0;
    for (var i = 0; i < this.components.length; i++) {
      if (this.components[i].type === 'stripe') {
        var stripe = this.components[i];
        var gpa = new GeomPolyArc(stripe.segments);
        var gpo = gpa.getOffsetsToPoint(pt);
        if (gpo) {
          var normal = Math.max(0, Math.abs(gpo.normal) - item.width / 2);
          if (normal < streetWidth) {
            //bd9f = normal;
            bda0 = stripe;
          }
        }
      }
    }
    return bda0;
  }

  /**
   *
   * @param {*} value
   */
  setDirectionOfTravel(value) {
    if (Utility.isNonEmptyArray(this.components)) {
      for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].key === this.idSelectedComponent) {
          this.components[i].travelDir = value;
        }
      }
    }
  }

  /**
   *
   * @param {{functype: 'StreetNew'}} street
   * @param {{type: 'stripe'}} stripe
   * @param {number} index
   */
  appendTurnbayDimensionLine(street, stripe, index) {
    var gpa = new GeomPolyArc(stripe.segments);
    var ptHandle;
    var ptAxisRef;
    if (index == 1) {
      ptHandle = gpa.getPointStart();
      var gl = new GeomLine(
        new GeomPoint(stripe.offsetArcSegments[0].ptStart.x, stripe.offsetArcSegments[0].ptStart.y),
        new GeomPoint(stripe.offsetArcSegments[0].ptStop.x, stripe.offsetArcSegments[0].ptStop.y)
      );
      ptAxisRef = gl.getPointStart();
    } else {
      ptHandle = gpa.getPointStop();
      var n = stripe.offsetArcSegments.length - 1;
      var gl = new GeomLine(
        new GeomPoint(stripe.offsetArcSegments[n].ptStart.x, stripe.offsetArcSegments[n].ptStart.y),
        new GeomPoint(stripe.offsetArcSegments[n].ptStop.x, stripe.offsetArcSegments[n].ptStop.y)
      );
      ptAxisRef = gl.getPointStop();
    }
    gpa = new GeomPolyArc(street.segments);
    var pt = gpa.getAlignmentAtLength(gpa.getLength() / 2, 0).ptAlign;
    var uupp = diagramManager.getUserUnitsPerPixel();
    if (ptAxisRef && ptHandle && pt && ptAxisRef.distance(ptHandle) > 28 * uupp) {
      var ccw = new UtilityMath().isCounterClockwise(ptHandle, ptAxisRef, pt);
      var shift = uupp * Utility.getHandleRadius() * 2.5;
      var gl = new GeomLine(new GeomPoint(ptHandle.x, ptHandle.y), new GeomPoint(ptAxisRef.x, ptAxisRef.y));
      var ga = gl.getAlignmentAtLength(0, ccw ? shift : -shift);
      var pt = gl.getPointStart();
      gl.offset(ga.ptAlign.x - pt.x, ga.ptAlign.y - pt.y);
      ptHandle = gl.getPointStart();
      ptAxisRef = gl.getPointStop();
      var t = new Axial(gl).configureShapeDimensionLine(ccw ? 1 : 4);
      var sa = new GeomPoint(ptAxisRef.x, ptAxisRef.y).getInclineAngle(new GeomPoint(ptHandle.x, ptHandle.y));
      var ea = new GeomPoint(ptHandle.x, ptHandle.y).getInclineAngle(new GeomPoint(ptAxisRef.x, ptAxisRef.y));
      var terminators = [{ pt: { x: ptHandle.x, y: ptHandle.y }, sa }, { pt: { x: ptAxisRef.x, y: ptAxisRef.y }, ea }];
      var axial = {
        type: 'line',
        ptStart: { x: ptHandle.x, y: ptHandle.y },
        ptStop: { x: ptAxisRef.x, y: ptAxisRef.y },
        terminators: terminators,
      };
      return axial;
    }
    return undefined;
  }

  /**
   *
   * @param {{functype: 'StreetNew'}} street
   * @param {string} key
   * @returns {{type: 'stripe'}}
   */
  getStripeByKey(street, key) {
    for (var i = 0; i < street.components.length; i++) {
      if (street.components[i].key === key) {
        return street.components[i];
      }
    }
    return undefined;
  }
}

export default _Street;
