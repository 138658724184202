import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import ScrollArea from 'react-scrollbar';
import { connect } from 'react-redux';
import './touch-scroll.scss';

class TouchScroll extends Component {
  render() {
    const { children, showScrollBar, ...restProps } = this.props;
    return (
      <ScrollArea
        {...restProps}
        speed={0.8}
        horizontal={false}
        vertical={true}
        verticalContainerStyle={{ opacity: 1, display: showScrollBar ? 'block' : 'none' }}
        /**
         * HACK: fix the bar disappeared when enter the app at first time
         * THIS IS A BUG FROM SCROLLAREA, see
         * https://github.com/souhe/reactScrollbar/issues/53
         * https://github.com/souhe/reactScrollbar/issues/69
         *
         * containerHeight equals menu expanded height, see
         * src\components\Header\SymbolBar\symbol-bar.scss
         * .symbol-bar-expanded .symbol-bar-inner height
         */
        onScroll={value => (value.containerHeight = 110)}
      >
        {children}
      </ScrollArea>
    );
  }
}

const mapStateToProps = state => ({
  showScrollBar: state.symbolBar.showScrollBar,
});

export default connect(
  mapStateToProps,
  null
)(withSize({ monitorHeight: true })(TouchScroll));
