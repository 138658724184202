import * as React from 'react';

function SvgIconBoat(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.001 0h23.04v23.042H.001z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M21.12 14.4H19.2c0 1.06-.861 1.92-1.92 1.92s-1.92-.86-1.92-1.92h-1.92c0 1.06-.861 1.92-1.92 1.92S9.6 15.46 9.6 14.4H7.68c0 1.06-.861 1.92-1.92 1.92s-1.92-.86-1.92-1.92H1.92c0 1.06-.861 1.92-1.92 1.92v1.92a3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328 3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328 3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328 3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328v-1.92c-1.059 0-1.92-.86-1.92-1.92zM21.12 19.2H19.2c0 1.06-.861 1.92-1.92 1.92s-1.92-.86-1.92-1.92h-1.92c0 1.06-.861 1.92-1.92 1.92S9.6 20.26 9.6 19.2H7.68c0 1.06-.861 1.92-1.92 1.92s-1.92-.86-1.92-1.92H1.92c0 1.06-.861 1.92-1.92 1.92v1.92a3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328 3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328 3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328 3.817 3.817 0 002.88-1.328 3.817 3.817 0 002.88 1.328v-1.92c-1.059 0-1.92-.86-1.92-1.92z" />
          <path d="M4.852 15.115l1.772-.736-1.604-3.863 6.493-2.752 6.493 2.752-1.58 3.782 1.773.738 1.946-4.662a.96.96 0 00-.512-1.254l-3.32-1.407V3.84a.96.96 0 00-.96-.96h-2.88V0h-1.92v2.88h-2.88a.96.96 0 00-.96.96v3.872l-3.32 1.407a.96.96 0 00-.511 1.253l1.97 4.743zM8.633 4.8h5.76v2.099l-2.505-1.062a.965.965 0 00-.75 0L8.634 6.899V4.8z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconBoat;
