import { areEqualHeight } from '@src/utils/react';
import { Collapse, Icon, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withSize } from 'react-sizeme';

function SymbolCategoryMenuBody(props) {
  const { t } = useTranslation();

  const { showTooltip, collapsed, selectedCategory, categories, changeSubCategory } = props;

  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    setExpandedKeys(categories.map(item => item.key));
  }, [categories]);

  const getPanelHeader = category => {
    return collapsed ? (
      <div style={{ lineHeight: '4px', padding: '0px 10px' }}>
        {/* FIXME: check logic */}
        {categories.some(item => item.key === category.key) ? <Icon type="down" /> : <Icon type="right" />}
      </div>
    ) : (
      <span>{t(`symbol.category.${category.key}`)}</span>
    );
  };

  const onPanelCollapseChange = expandedKeys => {
    setExpandedKeys(expandedKeys);
  };

  return (
    <Collapse
      className="symbol-category-menu-collapse"
      expandIconPosition="right"
      onChange={onPanelCollapseChange}
      activeKey={expandedKeys}
    >
      {categories.map(category => {
        return (
          category.subCategories.some(subCategory => subCategory.symbols.length > 0) && (
            <Collapse.Panel key={category.key} header={getPanelHeader(category)} showArrow={!collapsed}>
              {category.subCategories.map(subCategory => {
                const childCategory = (
                  <div
                    key={subCategory.key}
                    className={classNames('symbol-category', {
                      active: selectedCategory === subCategory.key,
                    })}
                    onClick={() => changeSubCategory(subCategory.key)}
                  >
                    <Icon component={subCategory.icon} />
                    <span>{t(`symbol.category.${subCategory.key}`)}</span>
                  </div>
                );
                if (subCategory.symbols.length > 0) {
                  return showTooltip ? (
                    <Tooltip key={subCategory.key} placement="right" title={t(`symbol.category.${subCategory.key}`)}>
                      {childCategory}
                    </Tooltip>
                  ) : (
                    childCategory
                  );
                } else {
                  return null;
                }
              })}
            </Collapse.Panel>
          )
        );
      })}
    </Collapse>
  );
}

export default withSize({ monitorHeight: true, monitorWidth: false })(memo(SymbolCategoryMenuBody, areEqualHeight));
