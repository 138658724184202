import { message } from '@src/components/Message';
import * as workData from '@src/data/WorkData';
import {
  allShapeCategories,
  generateData,
  generateNode,
  getFileNameFromFullName,
  readFileAsDataUrl,
  readFileContent,
  readFileMetadataFromContent,
  shapeMapping,
} from '@src/utils';
import { Button, Icon, Input, Modal, Select, Spin } from 'antd';
import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import './template.scss';
import TemplateGroup from './TemplateGroup';
import TemplatePreview from './TemplatePreview';
import { withTemplateContext } from '@src/contexts';
import LncdSpin from '@src/components/LncdSpin';

class TemplateSaveModal extends React.Component {
  defaultTemplateShape;

  state = {
    templateName: '',
    templateGroup: null,
    templateSrc: '',
    templateGroups: [],
    isLoadingTemplates: false,
    templateShape: allShapeCategories,
    templateUseData: null,
    contentType: 'lncdSvg',
  };

  componentDidMount() {
    const groups = this.props.templates
      .filter(template => 'userDefined' === template.type)
      .map(template => template.group);
    this.setState({
      templateGroups: _.uniq(groups),
    });
    const templateShape = this.initializeTemplateShape(workData.getUseData());
    if ('diagram' === this.props.source) {
      this.defaultTemplateShape = templateShape;
      this.changeTemplateShape(templateShape);
    }
    if ('file' === this.props.source) {
      switch (this.props.file.type) {
        case 'image/svg+xml':
          this.setLncdFile(this.props.file);
          break;
        case 'image/jpeg':
        case 'image/png':
          this.setOtherFile(this.props.file);
          break;
        default:
      }
    }
    setTimeout(() => this.textInput && this.textInput.focus());
  }

  initializeTemplateShape(useData) {
    const templateShape = [];
    useData.forEach(data => {
      Object.keys(shapeMapping).some(category => {
        const functypeInCategory = shapeMapping[category].includes(data.functype);
        if (functypeInCategory && !templateShape.includes(category)) {
          templateShape.push(category);
        }
        return functypeInCategory;
      });
    });
    return templateShape;
  }

  setLncdFile = file => {
    readFileContent(file).then(content => {
      const metadata = readFileMetadataFromContent(content);
      if (metadata) {
        this.setState({
          templateName: getFileNameFromFullName(file.name),
          templateSrc: content,
          templateUseData: metadata.useData,
        });
      } else {
        this.setOtherFile(file);
      }
    });
  };

  setOtherFile = file => {
    readFileAsDataUrl(file).then(result => {
      this.setState({
        templateName: getFileNameFromFullName(file.name),
        templateSrc: result,
        templateUseData: null,
        contentType: 'image',
      });
    });
  };

  isContaind = (children, parent) => children.some(v => parent.includes(v));
  changeTemplateShape = value => {
    if (value.length === 0) {
      message.error(this.props.t('template.saveModal.objectRequired1'));
      return;
    }
    if (!this.isContaind(this.defaultTemplateShape, value)) {
      message.error(this.props.t('template.saveModal.objectRequired2'));
      return;
    }
    this.setState(
      {
        templateShape: value,
        isLoadingTemplates: true,
      },
      () => this.changeTemplateSrc()
    );
  };
  haveShapesInCanvas = catetory => generateData(catetory).length > 0;
  changeTemplateSrc = () => {
    const node = generateNode(this.state.templateShape);
    this.setState({
      isLoadingTemplates: false,
      templateSrc: node.outerHTML,
    });
  };
  changeTemplateName = event => this.setState({ templateName: event.target.value });
  changeTemplateGroup = value => this.setState({ templateGroup: value });

  saveTemplate = async () => {
    const { map } = this.props;
    const mapData = {
      service: map.service,
      currentLocation: map.currentLocation,
      selectOptions: map.selectOptions,
    };
    if (!this.state.templateName) {
      message.error(this.props.t('template.saveModal.nameRequired'));
      return;
    }
    if ('diagram' === this.props.source && !this.haveShapesInCanvas(this.state.templateShape)) {
      message.error(this.props.t('template.saveModal.diagramRequired'));
      return;
    }
    await this.props.saveTemplate({
      name: this.state.templateName,
      type: 'userDefined',
      group: this.state.templateGroup || 'Untitled Group',
      angle: 0,
      src: this.state.templateSrc,
      useData: JSON.stringify(this.state.templateUseData || generateData(this.state.templateShape)),
      mapData: this.state.templateUseData ? {} : mapData,
      contentType: this.state.contentType,
      source: 'user',
      key: uuid(),
      templateStatus: 'pendingUpload',
    });
    message.success(this.props.t('template.saveModal.success'));
    this.props.closeModal();
  };

  addOption = value =>
    this.setState({
      templateGroups: [...this.state.templateGroups, value],
      templateGroup: value,
    });

  render() {
    return (
      <Modal
        title={this.props.t('template.saveModal.title')}
        visible={this.props.visible}
        footer={null}
        onCancel={this.props.closeModal}
        maskClosable={false}
        width="680px"
      >
        <div className="custom-template-body">
          <div style={{ marginRight: '10px' }}>
            <label style={{ fontWeight: 'bold', lineHeight: '30px', marginBottom: '10px' }}>
              {this.props.t('template.saveModal.previewLabel')}
            </label>
            <div className="custom-preview-template">
              {this.state.isLoadingTemplates ? (
                <LncdSpin />
              ) : (
                <TemplatePreview src={this.state.templateSrc} contentType={this.state.contentType} />
              )}
            </div>
          </div>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '5px' }}>
              <label style={{ fontWeight: 'bold', lineHeight: '30px', marginBottom: '10px' }}>
                {this.props.t('template.saveModal.nameLabel')}
              </label>
              <Input
                style={{ width: '100%' }}
                value={this.state.templateName}
                onChange={this.changeTemplateName}
                placeholder={this.props.t('template.saveModal.namePlaceholder')}
                ref={textInput => (this.textInput = textInput)}
              />
            </div>
            <div style={{ marginBottom: '5px' }}>
              <TemplateGroup
                value={this.state.templateGroup}
                options={this.state.templateGroups}
                addOption={this.addOption}
                onSelect={this.changeTemplateGroup}
                placeholder={this.props.t('template.saveModal.groupPlaceholder')}
              />
            </div>
            {'diagram' === this.props.source && (
              <div style={{ marginBottom: '5px' }}>
                <label style={{ fontWeight: 'bold', lineHeight: '30px', marginBottom: '10px' }}>
                  {this.props.t('template.saveModal.objectLabel')}
                </label>
                <div>
                  <Select
                    mode="multiple"
                    value={this.state.templateShape}
                    style={{ width: '100%' }}
                    placeholder={this.props.t('template.saveModal.objectPlaceholder')}
                    className="custom-select-add"
                    onChange={this.changeTemplateShape}
                    showArrow={true}
                    suffixIcon={<Icon type="caret-down" className="custom-night-mode-text lncd-select-suffix" />}
                  >
                    {allShapeCategories.map(category => (
                      <Select.Option key={category}>{this.props.t(`template.objectType.${category}`)}</Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}
            <div style={{ flex: 1 }}></div>
            <div className="custom-template-save-modal-btns">
              <Button
                className="custom-template-save-modal-btn"
                type="primary"
                onClick={this.saveTemplate}
                disabled={this.state.isLoadingTemplates}
              >
                {this.props.t('template.saveModal.saveButtonText')}
              </Button>
              <Button className="custom-template-save-modal-btn" type="ghost" onClick={this.props.closeModal}>
                {this.props.t('template.saveModal.cancelButtonText')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    map: state.map,
  }),
  null
)(withTranslation()(withTemplateContext(TemplateSaveModal)));
