import * as React from 'react';

function SvgIconCopy(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M16.414 1H9c-1.103 0-2 .898-2 2v14c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V5.586L16.414 1zm2.587 16H9V3h6v4h4l.001 10z" />
          <path d="M5 5H3v16c0 1.103.897 2 2 2h12v-2H5V5z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconCopy;
