import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { setMatrix, setMatrixInitialized } from '@src/action/transform';
import { setAroundCenterOffset } from '@src/action/canvas';
import * as constant from '@src/constant';
import { devicePtToUserPt } from '@src/data/CommonFunction';
import emitter from '@src/data/Event';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import { convertToUserPoint, scaleAround } from '@src/utils';
import { matrixMultiply } from '@src/data/CommonFunc';

const { CANVAS } = constant;

const hoc = WrappedComponent => {
  class TransformManager extends React.Component {
    constructor(props) {
      super(props);
      // pass to withMap
      this.withMapRef = React.createRef();
    }

    // timer to flag transform matrix as initialized
    timeoutId;

    componentDidMount() {
      // listener will be removed in SVGRoot
      emitter.addListener(EVENT_EMIT_TYPE.ZOOM, this.zoomToFit);
    }

    componentWillUnmount() {
      this.timeoutId && clearTimeout(this.timeoutId);
      emitter.removeAllListeners(EVENT_EMIT_TYPE.ZOOM);
    }

    initTransform = () => {
      const { canvasHeight, appWidth, setMatrix } = this.props;
      const matrix = [CANVAS.INITIAL_SCALE, 0, 0, -CANVAS.INITIAL_SCALE, appWidth / 2, canvasHeight / 2];
      setMatrix(matrix);
    };

    componentDidUpdate(prevProps) {
      const { matrixInitialized, setMatrixInitialized } = this.props;
      // HACK: before the canvas DOM is ready, we're not able to set the correct transform matrix because we depend on it's dimension to calculate translate values
      if (
        !matrixInitialized &&
        (prevProps.canvasHeight !== this.props.canvasHeight || prevProps.appWidth !== this.props.appWidth)
      ) {
        this.initTransform();
        this.timeoutId && clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          setMatrixInitialized(true);
        }, 1e3);
      }
    }

    // FIXME: refactor matrix actions
    /**
     * 平移
     * @param {number} x
     * @param {number} y
     */
    pan = (x, y) => {
      const { setMatrix, matrix } = this.props;
      let updatedMatrix = matrixMultiply([1, 0, 0, 1, x, y], matrix);
      setMatrix(updatedMatrix);
    };

    /**
     * 以鼠标位置进行缩放
     * @param {number} scale
     * @param {number} clientX
     * @param {number} clientY
     */
    zoomCanvas = (scale, clientX, clientY) => {
      const { headerHeight, matrix } = this.props;

      const updatedMatrix = scaleAround(matrix, scale, ...devicePtToUserPt(clientX, clientY - headerHeight));

      this.props.setAroundCenterOffset(updatedMatrix[4] - matrix[4], updatedMatrix[5] - matrix[5]);
      this.props.setMatrix(updatedMatrix);
    };

    //获取 fit 的缩放比和中心点的位置
    getDrawingDimension = id => {
      const { siderWidth, appWidth, canvasHeight, headerHeight } = this.props;
      // zoom fit 后的距离包围边框之间的距离 px
      const padding = 50;
      const { width, height, x, y } = document.querySelector(`#${id}`).getBoundingClientRect();
      const desiredWidth = appWidth - siderWidth - padding * 2;
      const desiredHeight = canvasHeight - padding * 2;
      const center = [siderWidth + appWidth / 2, headerHeight + canvasHeight / 2];

      const scale = Math.min(desiredWidth / width, desiredHeight / height);

      const [xmid, ymid] = [x + width / 2, y + height / 2];
      const move = [center[0] - xmid, center[1] - ymid];
      return { move, scale, center };
    };

    /**
     * @param {string} operateId zoom select id
     */
    zoomToFit = operateId => {
      if (workData.getUseData().length > 0) {
        const id = operateId || 'all-shapes';
        const { move, scale, center } = this.getDrawingDimension(id);
        const { matrix, headerHeight, setMatrix } = this.props;
        let updatedMatrix = matrixMultiply([1, 0, 0, 1, ...move], matrix);
        updatedMatrix = scaleAround(
          updatedMatrix,
          scale,
          ...convertToUserPoint(updatedMatrix, center[0], center[1] - headerHeight)
        );
        setMatrix(updatedMatrix);
        if (this.withMapRef.current) {
          const lmap = this.withMapRef.current;
          lmap.moveMap(move);
          lmap.scaleMapAround(scale, ...center);
        }
      }
    };

    render() {
      const {
        siderWidth,
        canvasHeight,
        headerHeight,
        appWidth,
        matrix,
        matrixInitialized,
        ...passThroughProps
      } = this.props;

      return (
        <WrappedComponent
          // otherwise, all props will be passed down to child component which is bad, e.g. matrix passed to App, see https://reactjs.org/docs/higher-order-components.html#convention-pass-unrelated-props-through-to-the-wrapped-component
          {...passThroughProps}
          zoomCanvas={this.zoomCanvas}
          pan={this.pan}
          initTransform={this.initTransform}
          ref={this.withMapRef}
        />
      );
    }
  }

  return TransformManager;
};

const mapStateToProps = state => ({
  siderWidth: state.app.siderWidth,
  canvasHeight: state.app.canvasHeight,
  headerHeight: state.app.headerHeight,
  appWidth: state.app.appWidth,
  matrix: state.transform.matrix,
  matrixInitialized: state.transform.matrixInitialized,
});

export default WrappedComponent =>
  connect(
    mapStateToProps,
    { setMatrix, setMatrixInitialized, setAroundCenterOffset }
  )(hoc(WrappedComponent));
