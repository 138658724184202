import { setPropsMenuOpen, setCollapsed } from '@src/action/app';
import { flushWorkData, setDrawingShape, toggleGrid } from '@src/action/canvas';
import { syncWorkData } from '@src/action/workData';
import { initialSnapshots, takeSnapshot } from '@src/action/snapshots';
import { setCurrentState, setAnchorsType, setCursor } from '@src/action/drawingStatus';
import {
  setMouseOverCanvas,
  recordClickStateOnCanvas,
  updateMousePosition,
  updateLastClickPos,
} from '@src/action/pointer';
import { selectSymbol } from '@src/action/symbolBar';
import CreateDialog from '@src/actions/CreateDialog';
import {
  HandlePointArc,
  HandlePointCallout,
  HandlePointClosedShape,
  HandlePointConnectShape,
  HandlePointCounter,
  HandlePointCrosswalk,
  HandlePointLine,
  HandlePointParkStall,
  HandlePointRange,
  HandlePointRect,
  HandlePointSquareCircle,
  HandlePointStairs,
  HandlePointStreet,
  HandlePointStructure,
  HandlePointStructureSelect,
  HandlePointRoundabout,
  HandlePointCurved,
  HandlePointStripe,
  HandlePointStraight,
  clearTrackPoints,
} from '@src/actions/HandlePoint';
import { initMeasurementData, initStreet } from '@src/actions/SliderShapeHandle';
import {
  DeleteSegmentStreet,
  HandleCrossWalkAcross,
  handleDirtAcross,
  handleGravelAcross,
  handleOffsetAcross,
  handleStreetAcross,
  MergeSegmentStreet,
  SetArcStreet,
  SetDoubleArcStreet,
  SetLineStreet,
  SetOffSetArcStreet,
  SetTripleArcStreet,
  SplitSegmentStreet,
} from '@src/actions/StreetFunHandle';
import { StreetShapeData } from '@src/actions/TemplateShapeHandle';
import * as transformHandle from '@src/actions/TransformHandle';
import * as transformStreetHandle from '@src/actions/TransformStreetHandle';
import { loadOptions } from '@src/components/Header/DevOptions';
import { createPropsMenu } from '@src/components/Modal/createPropsMenu';
import MouseTracker from '@src/components/MouseTracker';
// TODO: this is a namespace should be named as Constant - as the same as React
import * as constant from '@src/constant';
import { getOperationByKeyName } from '@src/constant';
import { AddStructureData } from '@src/data/BusinessFun';
import { getStyle, LengthBetweenPoints, SVGAdapter, toFixed, RotatePoint } from '@src/data/CommonFunc';
import {
  clientPtToUserPt,
  devicePtToUserPt,
  getUserUnitByPixel,
  roundStandardUnit,
  userPtToClientPt,
  userPtToDevicePt,
} from '@src/data/CommonFunction';
import emitter from '@src/data/Event';
import GeomPoint from '@src/data/GeomPoint';
import GeomLine from '@src/data/GeomLine';
import Utility from '@src/data/Utility';
import * as workData from '@src/data/WorkData';
import _Street from '@src/data/_Street';
import { EVENT_EMIT_TYPE, ROAD_CONSTANT } from '@src/type/event';
import { getClickPoint, isLeftClick, isRightClick } from '@src/utils';
import _ from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Hotkeys from 'react-hot-keys';
import { connect } from 'react-redux';
import { DropMenuOnCanvasConnectRedux as DropMenuOnCanvas } from '../CanvasDropMenu';
import ModalWin from '../ModalWin';
import StyleInfo from '../StyleInfo/StyleInfo';
import CanvasSvg from './CanvasSvg';
import { createStripe, createLane, createArc, createLine } from '@src/data/ShapeOperationData';
import GeomPolyArc from '@src/data/GeomPolyArc';
import GeomArc from '@src/data/GeomArc';
import ShowPropsMenuBtn from './ShowPropsMenuBtn';
import StopDrawing from './StopDrawing';
import { i18n } from '@src/components/DataProvider/DataProvider';
import ShadowConfirm from './ShadowConfirm';
import { track, createShadow, createShadowShape } from '@src/utils/shadow';

const { CANVAS, KEY_NAMES, APP_ROOT_EL_ID, STREET_DEFAULT_SIZE, FUNCTION_TYPE, STREET_TYPE, LENGTH_TYPE } = constant;
const { STREET_LEN, LANE_WIDTH } = STREET_DEFAULT_SIZE;

const MultiStrokeShapes = [
  FUNCTION_TYPE.CLOSEDSHAPE,
  FUNCTION_TYPE.CONNECTEDLINES,
  FUNCTION_TYPE.STRUCTURE,
  FUNCTION_TYPE.COUNTER_TOP,
  FUNCTION_TYPE.SYMBOL,
];

const initialState = {
  objList: [],
  // cursor: constant.CURSOR.DEFAULT,
  currentObject: {},
  openDrawing: false,
  afterXY: [0, 0],
  shadowConfirmVisible: false,
  isShowCanvasDropMenu: true,

  /**
   * @type {'left' | 'right'}
   */
  mouse: '',
};

const drawObject = {
  currentObjId: '',
  // TODO: use get to fetch from work data with currentObjectId
  currentObject: {},
  startingPoint: [],
  handlepoint: [],

  /**
   * 捕捉到事件的点位置，和结束位置形成移动向量
   */
  capturePoint: [],

  /**
   * @type {[number, number]}
   */
  movePoint: [0, 0],
  // viewbox: [0, 0, 0, 0],
  selectzoom: [],
  streetareaindex: -1,
  streetareapoistion: '',
  isStreetAcross: false,

  /**
   * @type {[number, number]}
   * @description 绘制多线段图形时用于记录绘制过的点
   */
  prevDrawPoint: [], //
};

class SVGRoot extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.drawObject = _.cloneDeep(drawObject);
    this.canvasSvg = React.createRef();
    this.curvedBuffer = [];
  }

  // 点击图形时鼠标坐标
  shapeMouseDownPosition = null;

  get drawingObject() {
    return this.drawObject.currentObject;
  }

  componentDidMount() {
    const { loadOptions } = this.props;

    // expose work data module
    if (__DEV__) {
      global._drawObject = this.drawObject;
      global._workData = workData;
      let options = localStorage.getItem('dev-options');
      if (options) {
        loadOptions(JSON.parse(options));
      }
    }

    this.shapeMap = (() => {
      let shapeMap = new Map();
      shapeMap.set(FUNCTION_TYPE.SQUARE, HandlePointSquareCircle);
      shapeMap.set(FUNCTION_TYPE.CIRCLE, HandlePointSquareCircle);
      shapeMap.set(FUNCTION_TYPE.LINE, HandlePointLine);
      shapeMap.set(FUNCTION_TYPE.RECTANGLE, HandlePointRect);
      shapeMap.set(FUNCTION_TYPE.ELLIPSE, HandlePointRect);
      shapeMap.set(FUNCTION_TYPE.TEXTBOX, HandlePointRect);
      shapeMap.set(FUNCTION_TYPE.LASSOAREA, HandlePointRect);
      shapeMap.set(FUNCTION_TYPE.ZOOMSELECT, HandlePointRect);
      shapeMap.set(FUNCTION_TYPE.SELECTION, HandlePointRect);
      shapeMap.set(FUNCTION_TYPE.ARC, HandlePointArc);
      shapeMap.set(FUNCTION_TYPE.STAIRS, HandlePointStairs);
      shapeMap.set(FUNCTION_TYPE.PARKINGSTALLS, HandlePointParkStall);
      shapeMap.set(FUNCTION_TYPE.CROSSWALK, HandlePointCrosswalk);
      shapeMap.set(FUNCTION_TYPE.STREET, HandlePointStreet);
      shapeMap.set(FUNCTION_TYPE.CALLOUT, HandlePointCallout);
      shapeMap.set(FUNCTION_TYPE.DIMENSIONLINE, HandlePointStripe);
      shapeMap.set(FUNCTION_TYPE.STRIPE, HandlePointStripe);
      shapeMap.set(FUNCTION_TYPE.CONNECTEDLINES, HandlePointConnectShape);
      shapeMap.set(FUNCTION_TYPE.CLOSEDSHAPE, HandlePointClosedShape);
      shapeMap.set(FUNCTION_TYPE.COUNTER_TOP, HandlePointCounter);
      shapeMap.set(FUNCTION_TYPE.STRUCTURE, HandlePointStructure);
      shapeMap.set(FUNCTION_TYPE.STREETNEW, obj => {
        if (obj.streetType === constant.STREET_TYPE.ROUNDABOUT) {
          obj = HandlePointRoundabout(obj);
        } else if (obj.streetType === constant.STREET_TYPE.CURVED) {
          track.update(userPtToClientPt(obj.position.x2, obj.position.y2));
          this.curvedBuffer = HandlePointCurved([obj.position.x2, obj.position.y2], this.curvedBuffer);
        } else if (obj.streetType === constant.STREET_TYPE.STRAIGHT) {
          obj = HandlePointStraight(obj);
        }
      });
      return shapeMap;
    })();

    emitter.addListener(EVENT_EMIT_TYPE.USING_TEMPLATE, this.onUsingTemplate);
    emitter.addListener(EVENT_EMIT_TYPE.STREET, this.onStreet);
    // 首次加载非 Symbol 图形到画布
    emitter.addListener(EVENT_EMIT_TYPE.SLIDERSHAPEFUNC, this.onSliderShapeFunc);
    // 首次加载 Symbol 图形到画布
    emitter.addListener(EVENT_EMIT_TYPE.SLIDERSYMBOLFUNC, this.onSliderSymbolFunc);
    // Grid, Zoom In/Out
    emitter.addListener(EVENT_EMIT_TYPE.HOMEFUNC, this.onHomeFunc);
    emitter.addListener(EVENT_EMIT_TYPE.MANAGEFUNC, this.onManageFunc);
    emitter.addListener(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, this.onUpdateDiagram);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.svgVisible !== this.props.svgVisible) {
      this.hidePropsMenu();
    }
  }

  componentWillUnmount() {
    emitter
      .removeAllListeners(EVENT_EMIT_TYPE.USING_TEMPLATE)
      .removeAllListeners(EVENT_EMIT_TYPE.STREET)
      .removeAllListeners(EVENT_EMIT_TYPE.SLIDERSYMBOLFUNC)
      .removeAllListeners(EVENT_EMIT_TYPE.SLIDERSHAPEFUNC)
      .removeAllListeners(EVENT_EMIT_TYPE.HOMEFUNC)
      .removeAllListeners(EVENT_EMIT_TYPE.MANAGEFUNC)
      .removeAllListeners(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
  }

  setShowCanvasDropMenu = value => {
    this.setState({ isShowCanvasDropMenu: value });
  };

  /**
   * @param {boolean} [revision]
   */
  onUpdateDiagram = (revision = true) => {
    revision && this.props.takeSnapshot();
    this.setAncyObjList();
  };

  onDrawShape = () => {
    const functype = this.props.drawingShape;
    const streetType = this.props.streetType;

    // 对当前工作进行操作 (Operate on current work)
    // 让鼠标进入精准画图 (Let the mouse enter the precise drawing)
    // 添加当前数据并同步 (Add current data and synchronize)
    let currentObj;
    if (functype === constant.FUNCTION_TYPE.STREETNEW) {
      if (!workData.hasStreet('paved')) {
        workData.addData(constant.FUNCTION_TYPE.STREETSURFACE);
        let obj = workData.getCurrentOperateObject();
        obj.surfaceType = 'paved';
        obj.sfills = [];
        obj.crfills = [];
      }
      currentObj = workData.addData(functype, undefined, streetType);
    } else {
      currentObj = workData.addData(functype);
    }

    this.drawObject.isStreetAcross = true;
    // Draw Shape 开始前，将 workData 中当前操作对象赋值给 currentObject
    this.drawObject.currentObject = currentObj;
    this.drawObject.currentObjId = this.drawObject.currentObject.operateid;

    let index = workData.getLastObject(functype);
    if (index < workData.getUseData().length - 1) {
      workData.deleteObject(this.drawObject.currentObject.operateid);
      workData.insertObject(index, this.drawObject.currentObject);
      let data = workData.getUseData();
      let lastData = data[data.length - 1];
      if (lastData.functype === constant.FUNCTION_TYPE.STREETSURFACE) {
        workData.deleteObject(lastData.operateid);
        workData.insertObject(index, lastData);
      }
    }
  };

  onUsingTemplate = template => {
    const { id, name, path, radianAngle } = template;

    const svg = document.getElementById('svg');
    const w = svg.clientWidth / 2;
    const h = svg.clientHeight / 2;

    let svgPosition = clientPtToUserPt(w, h);
    StreetShapeData(path, radianAngle).then(result => {
      this.props.setCurrentState(2);
      this.drawObject.isStreetAcross = true;
      this.onUpdateDiagram();
    });
  };

  /**
   *
   * @param {string} type
   * @param {{functype: string, operateid: string}} object
   */
  onStreet = (type, object) => {
    if (type === 'refresh') {
      workData.setObj(object.operateid, object);
      this.setAncyObjList();
      // let _s = new _Street();
      // _s.alignComponentsToStreetAxis(object);
      // _s.computeStreets(object);
    }
  };

  /**
   *
   * @param {string} type
   */
  onSliderShapeFunc = type => {
    if (type === 'refresh') {
      this.drawObject.isStreetAcross = true;
      this.setAncyObjList();
    }
  };

  createSymbolObject = async (symbolKey, point, streetId, rotationAngle) => {
    workData.addData(constant.FUNCTION_TYPE.SYMBOL);
    let obj = workData.getCurrentOperateObject();
    let index = workData.getLastObject(constant.FUNCTION_TYPE.SYMBOL);
    if (index < workData.getUseData().length - 1) {
      workData.deleteObject(obj.operateid);
      workData.insertObject(index, obj);
    }
    const symbolData = this.props.symbols.find(symbol => symbol.key === symbolKey);
    obj.symbol = {
      ...symbolData,
      image: '',
    };
    // 根据属性适配 SVG
    let { svg, width, height } = SVGAdapter(symbolData.image, { ...obj.symbol });
    obj.marks.islength = true;
    obj.selectflag = true;
    obj.originsvg = `<?xml version="1.0" encoding="utf-8"?>${svg.outerHTML}`;
    obj.originwidth = width;
    obj.originheight = height;
    obj.vertical = 1;
    obj.horizontal = 1;
    obj.position.x1 = point[0] - width / 2;
    obj.position.y1 = point[1] - height / 2;
    obj.position.x2 = point[0] + width / 2;
    obj.position.y2 = point[1] + height / 2;

    if (streetId) {
      obj.snapToStreet = true;
      obj.streetId = streetId;
    }

    HandlePointRect(obj);

    if (rotationAngle !== undefined) {
      const rad = (rotationAngle * Math.PI) / 180;
      const oldAnchorPt = obj.handlepoint[8];
      const newAnchorPt = RotatePoint(oldAnchorPt, rad, point);
      obj = transformHandle.TransformRotateObject(obj, newAnchorPt);
    }

    // let g = svg.querySelector('g[lncd-role="symbol-hook"]');
    // console.log('g', g);

    // if (g) {
    //   let metadata = g.querySelector('metadata');
    //   if (metadata && metadata.hasAttribute('bshowresizehandles')) {
    //     obj.showResizeHandles = metadata.attributes['bshowresizehandles'].value == 'true' ? true : false;
    //   }
    // }

    // TODO: These code is necessity?
    // const svgItems = Array.prototype.slice.call(svg.children, 1);
    // const strokeWidth = g && g.getAttribute('stroke-width');
    // const fillColor = g && g.getAttribute('fill');
    // const style = {
    //   strokeWidth: strokeWidth || obj.style.strokewidth,
    //   stroke: obj.style.stroke,
    //   fill: fillColor || obj.style.fill,
    // };
    // getStyle(svgItems, style);
    // if (svg.hasAttribute('stroke-width')) {
    //   obj.style.strokewidth = parseFloat(svg.attributes['stroke-width'].value);
    // }
    // if (svg.hasAttribute('stroke')) {
    //   obj.style.stroke = svg.attributes['stroke'].value;
    // }
    // // if (svg.hasAttribute('fill')) {
    // //   // obj.style.fill = svg.attributes['fill'].value;
    // // }
    // obj.style.strokewidth = strokeWidth || style.strokeWidth;
    // //obj.style.stroke = style.stroke;
    // //obj.style.fill = style.fill;
    // obj.style.fill = fillColor || style.fill;
    // //obj.operateid = diagramManager.getNextShapeId();
    // //shapes.push(<g key={operateId} transform={GetMatrixString(matrix)}>{getAllShape(svgItems)}</g>);

    function createMarkup() {
      return { __html: svg.outerHTML };
    }

    const shapes = [];
    shapes.push(<g key={obj.operateid} dangerouslySetInnerHTML={createMarkup()} />);
    obj.shapes = shapes;
  };

  // FIXME: find a better place, measurement or symbol or DataProvider scope, just not here
  getMeasurementSubcategory = () => {
    const result = [];
    this.props.symbols.forEach(symbol => {
      if (symbol.categoryKey === 'measurements') {
        if (!result.find(item => item.key === symbol.subCategoryKey)) {
          result.push({
            key: symbol.subCategoryKey,
            name: symbol.subCategoryName,
            functype: symbol.functype,
            symbols: [],
          });
        }
        const subCategory = result.find(item => item.key === symbol.subCategoryKey);
        if (!subCategory.symbols.find(item => item.key === symbol.key)) {
          subCategory.symbols.push(symbol);
        }
      }
    });
    return result;
  };

  getMeasurementSymbolInfo = symbolKey => {
    const measurementSubcategory = this.getMeasurementSubcategory();
    for (let index = 0; index < measurementSubcategory.length; index++) {
      const category = measurementSubcategory[index];
      const symbol = category.symbols.find(n => n.key === symbolKey);
      if (symbol) {
        // special data structure to create measurement object
        return {
          functype: category.functype,
          type: symbolKey,
        };
      }
    }
    return undefined;
  };

  /**
   * 点击 SymbolBar 上的菜单项进行触发
   *
   * @async
   * @param {*} obj
   * @param {string} obj.symbolKey - selectedSymbolKey in symbolBar reducer
   * @param {number} obj.clientX
   * @param {number} obj.clientY
   * @param {string} obj.streetId - such as 's1','s2',etc.
   * @param {number} obj.angle
   */
  onSliderSymbolFunc = async ({ symbolKey, clientX, clientY, streetId, angle }) => {
    let afterXY = clientPtToUserPt(clientX, clientY);
    const measurementSymbol = this.getMeasurementSymbolInfo(symbolKey);
    const streetSymbols = this.props.symbols.filter(symbol => 'streets' === symbol.subCategoryKey);
    if (streetSymbols.findIndex(n => n.key === symbolKey) > -1) {
      // street
      initStreet(symbolKey, afterXY, streetId);
      this.drawObject.isStreetAcross = true;
    } else if (measurementSymbol) {
      // measurement
      initMeasurementData(measurementSymbol, afterXY);
    } else {
      // normal SVGs
      await this.createSymbolObject(symbolKey, afterXY, streetId, angle);
    }

    const object = workData.getCurrentOperateObject();
    // object.selectflag = true;
    this.drawObject.currentObject = object;
    this.drawObject.currentObjId = object.operateid;
    this.drawObject.startingPoint = [clientX, clientY];
    // 如果需要在创建后被选中，需要更新 drawingState
    // 同时，在 mousedown 事件中需要更新 drawingState，使得在 map 存在情况下能够直接拖动画布和地图
    this.props.setCurrentState(2);
    this.onUpdateDiagram();
  };

  onHomeFunc = functype => {
    // home功能
    if (functype === constant.HOME_FUNC_TYPE.INCREASE_TEST_SIZE) {
      this.resizeAllText(true);
      this.setAncyObjList();
    } else if (functype === constant.HOME_FUNC_TYPE.DECREASE_TEST_SIZE) {
      this.resizeAllText(false);
      this.setAncyObjList();
    }
  };

  onManageFunc = functype => {
    //home功能
    if (functype == constant.MANAGE_FUNC_TYPE.PRINT) {
      this.props.print();
    } else if (functype == constant.MANAGE_FUNC_TYPE.IMPORT) {
      this.setAncyObjList();
    } else if (functype === constant.MANAGE_FUNC_TYPE.START_OVER) {
      workData.clearUseData();
      this.props.initialSnapshots();
      this.setState(initialState);
      this.drawObject = _.cloneDeep(drawObject);
      // TODO: use redux-saga to listen to resetCanvas action
      this.props.setCollapsed(false);
      this.setAncyObjList();
    } else if (functype == constant.MANAGE_FUNC_TYPE.ADDIMG) {
      this.props.setCurrentState(2);
      this.onUpdateDiagram();
    }
  };

  /************************************************************************************ */

  // TODO: rename param
  resizeAllText = isLarge => {
    const fontSize = this.getDefaultTextSize();
    const scale = isLarge ? 1.2 : 0.833333333;
    const viewport = document.getElementById('viewport');

    const newFontSize = toFixed(fontSize * scale);

    viewport.setAttribute('font-size', newFontSize);

    const objList = workData.getUseData();

    let contentNumber = 0;
    let limitMaxSizeNumber = 0;
    let limitMinSizeNumber = 0;

    const keys = Object.keys(objList);

    keys.forEach(key => {
      let textObject = objList[key].text;
      let content = textObject.text;
      let textSize = textObject.size;
      let resizedValue = textSize * scale;
      let size;

      if (content) {
        contentNumber += 1;
      }

      if (resizedValue < 4) {
        if (isLarge === false) {
          size = Math.floor(resizedValue);
        } else {
          size = Math.ceil(resizedValue);
        }
      } else {
        size = toFixed(resizedValue, 0);
      }

      if (size >= constant.LIMIT_VALUE.MAX_TEXT_SIZE) {
        size = constant.LIMIT_VALUE.MAX_TEXT_SIZE;
        limitMaxSizeNumber += 1;
      } else if (size <= constant.LIMIT_VALUE.MIN_TEXT_SIZE) {
        size = constant.LIMIT_VALUE.MIN_TEXT_SIZE;
        limitMinSizeNumber += 1;
      }

      objList[key].text.size = size;
    });

    const enableIncrease = limitMaxSizeNumber < contentNumber;
    const enableDecrease = limitMinSizeNumber < contentNumber;

    const state = {};

    state[constant.HOME_BUTTON_STATE.INCREASE_TEST_SIZE] = enableIncrease;
    state[constant.HOME_BUTTON_STATE.DECREASE_TEST_SIZE] = enableDecrease;
  };

  getDefaultTextSize = () => {
    const viewport = document.getElementById('viewport');
    const fontSizeString = viewport.getAttribute('font-size') || '1';
    const fontSize = Number(fontSizeString);
    return fontSize;
  };

  /********************************************************************************************************** */

  /**
   * deleteSelectObject 后，处理workData
   */
  removeWorkData = () => {
    var selectedData = workData.getSelectObjects();
    if (selectedData.length) {
      var streets = [];
      for (let i = 0; i < selectedData.length; i++) {
        if (selectedData[i].functype == 'StreetNew') {
          streets.push(selectedData[i]);
        }
      }
      var data = workData.getUseData();
      var streetIds = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].functype == 'CurbReturn') {
          var curbReturn = data[i];
          for (let j = 0; j < curbReturn.streetStripes.length; j++) {
            if (streetIds.indexOf(curbReturn.streetStripes[j].idStreet) == -1)
              streetIds.push(curbReturn.streetStripes[j].idStreet);
          }
        }
      }
      for (let i = streetIds.length; i >= 0; i--) {
        for (let j = 0; j < streets.length; j++) {
          if (streetIds[i] == streets[j].operateid) streetIds.splice(i, 1);
        }
      }
      workData.deleteSelectObject();
      let _s = new _Street();
      let street;
      for (let i = 0; i < streetIds.length; i++) {
        street = workData.getObject(streetIds[i]);
        let lw = _s.alignComponentsToStreetAxis(street);
        _s.computeStreets(street);
      }
      data = workData.getUseData();
      for (let i = 0; i < data.length; i++) {
        if (data[i].functype == 'StreetNew') {
          street = data[i];
          let lw = _s.alignComponentsToStreetAxis(street);
          _s.computeStreets(street);
        }
      }
      var ss = workData.getStreets({
        surfaceType: 'paved',
      });
      if (ss.length == 0) {
        for (let i = data.length - 1; i >= 0; i--) {
          if (data[i].functype == 'StreetSurface') workData.deleteObject(data[i].operateid);
        }
      }
    }
  };

  // refreshStreetSurface = usedata => {
  //   let _s = new _Street();
  //   let data = usedata[usedata.length - 1];
  //   if (data.functype === 'StreetNew') {
  //     _s.alignComponentsToStreetAxis(data);
  //     _s.computeStreets(data);
  //   } else if (data.functype === 'StreetSurface') {
  //     workData.deleteObject(data.operateid);
  //   }
  // };

  /**
   *
   * @param {string} keyName
   * @param {KeyboardEvent} e
   */
  onKeyUpHandle(keyName, e) {
    // console.log(`key pressed: ${keyName}`);
    e.preventDefault();
    e.stopPropagation();
    const operation = getOperationByKeyName(keyName);
    if (!operation) {
      return;
    }
    const { handleCanvasOperation } = this.props;
    handleCanvasOperation(operation);
  }

  /**
   * 画布按下事件 (Canvas Press Event)
   * In touch device, if draw something in diagram, this event will not be trigger
   */
  onClick = e => {
    if (!this.props.collapsed && workData.hasData()) {
      this.props.setCollapsed(true);
    }

    // when paste something to canvas, record the click position.
    if (this.props.clipboard.length > 0) {
      this.props.recordClickStateOnCanvas(true);
    }
  };

  /**
   * the objList of state sync with workData
   * @param {{functype: string, operateid: string}[]} [useData]
   */
  setAncyObjList = useData => {
    let usedata = useData || workData.getUseData();
    this.setState({ objList: usedata });
    this.props.flushWorkData();
    this.props.syncWorkData(usedata);
    this.props.onChange(true);
  };

  // TODO: move object initialization logic to workData.addData;
  // use adapter to adjust object styles etc.
  /**
   * 生成（绘制） Shape 开始
   * @param {number} x - 坐标 x
   * @param {number} y - 坐标 y
   * @param {object} obj - 当前绘制对象 this.drawObject.currentObject
   */
  setShapeBegin(x, y, obj) {
    // console.log('set shape begin');
    if (!(obj && obj.position)) {
      return;
    }

    obj.position.x1 = x;
    obj.position.y1 = y;
    obj.position.x2 = x;
    obj.position.y2 = y;

    switch (obj.functype) {
      case FUNCTION_TYPE.LINE:
      case FUNCTION_TYPE.ARC:
        obj.style.fill = constant.COLOR_TYPE.BLACK;
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.CALLOUT:
        obj.style.stroke = constant.COLOR_TYPE.RED;
        obj.style.strokewidth = roundStandardUnit(0.15 * 12);
        obj.style.fill = constant.COLOR_TYPE.RED;
        obj.arrow.position = constant.ARROW_SHOW_TYPE.StartOnly;
        obj.arrow.type = constant.ARROW_SHOW_TYPE.ArrowSimple;
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.LASSOAREA:
        obj.style.stroke = constant.COLOR_TYPE.BLUE;
        obj.style.strokedasharray = constant.DASH_ARRAY_TYPE.dashdotdot;
        obj.style.fill = constant.COLOR_TYPE.NONE;
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.ZOOMSELECT:
      case FUNCTION_TYPE.SELECTION:
        obj.style.stroke = constant.COLOR_TYPE.GREEN;
        obj.style.strokewidth = getUserUnitByPixel() * 1.25;
        obj.style.strokedasharray = getUserUnitByPixel() * 5;
        obj.style.fill = constant.COLOR_TYPE.NONE;
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.STAIRS:
        obj.handlepoint = [[obj.position.x1, obj.position.y1]];
        obj.marks.isexist = true;
        obj.text.position = constant.TEXT_POSITION_TYPE.STAIRDIRECTION;
        obj.marks.length = constant.STAIR_SPACE.STAIRMARKWIDTH * 12;
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.CROSSWALK:
        obj.style.pattern = constant.CROSS_WALK_PATTERN.VERTICAL;
        obj.style.strokewidth = 8;
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.STREET:
        obj.lanes = [{ width: LANE_WIDTH, travelDir: 'normal' }, { width: LANE_WIDTH, travelDir: 'normal' }];
        obj.stripes = [
          { stripe: { patterns: [{ pattern: 'singlesolid' }] } },
          { stripe: { patterns: [{ pattern: 'singledash' }] } },
          { stripe: { patterns: [{ pattern: 'singlesolid' }] } },
        ];
        obj.lanewidth = constant.STREET_SPACE.HEIGHT;
        obj.handlepoint = [x, y];
        obj.style.strokewidth = 4;
        for (let idx = 0; idx < obj.groupdata.length; idx++) {
          obj.groupdata[idx].strokewidth = 4;
        }
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.DIMENSIONLINE:
        obj.arrow.type = constant.ARROW_SHOW_TYPE.ArrowSimple;
        obj.arrow.position = constant.ARROW_SHOW_TYPE.BOTH;
        obj.style.fill = constant.COLOR_TYPE.BLACK;
        obj.style.strokewidth = 0.5;
        obj.handlepoint = [
          [obj.position.x1, obj.position.y1],
          [obj.position.x2, obj.position.y2],
          [(obj.position.x1 + obj.position.x2) / 2, (obj.position.y1 + obj.position.y2) / 2],
        ];
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.PARKINGSTALLS:
        obj.marks.length = constant.PARK_STALL_SPACE.PARKWIDTH;
        obj.marks.height = constant.PARK_STALL_SPACE.HEIGHT;
        obj.style.pattern = constant.PARK_STALL_PATTERN_TYPE.STANDARD;
        obj.rotateangle = 0;
        obj.handlepoint = [];
        obj.style.strokewidth = 4;
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.STRIPE:
        obj.style.fill = constant.COLOR_TYPE.NONE;
        obj.style.strokewidth = 4;
        obj.style.strokedasharray = constant.DASH_ARRAY_TYPE.LINE;
        obj.handlepoint = [
          [obj.position.x1, obj.position.y1],
          [obj.position.x2, obj.position.y2],
          [(obj.position.x1 + obj.position.x2) / 2, (obj.position.y1 + obj.position.y2) / 2],
        ];
        workData.setObj(obj.operateid, obj);
        break;
      case FUNCTION_TYPE.CONNECTEDLINES:
        obj.style.fill = constant.COLOR_TYPE.BLACK;
        obj.handlepoint = [];
        obj.handlepoint[0] = [x, y, constant.ANCHORS_TYPE.CONNECTBEGIN];
        break;
      case FUNCTION_TYPE.CLOSEDSHAPE:
        obj.handlepoint = [];
        obj.handlepoint[0] = [x, y, constant.ANCHORS_TYPE.CONNECTBEGIN];
        break;
      case FUNCTION_TYPE.COUNTER_TOP:
        obj.handlepoint = [];
        obj.handlepoint[0] = [x, y, constant.ANCHORS_TYPE.COUNTERBEIGIN];
        obj.style.fill = constant.COLOR_TYPE.LIGHTGRAY;
        obj.style.strokewidth = 1;
        obj.arrow.position = constant.ARROW_SHOW_TYPE.BOTH;
        obj.arrow.type = constant.ARROW_SHOW_TYPE.ArrowSimple;
        obj.text.islength = true;
        break;
      case FUNCTION_TYPE.STRUCTURE:
      case FUNCTION_TYPE.SYMBOL:
        obj.handlepoint = [];
        obj.handlepoint[0] = [x, y, constant.ANCHORS_TYPE.STRUCTUREBEGIN, AddStructureData()];
        obj.handlepoint[1] = [x, y, constant.ANCHORS_TYPE.STRUCTUREEND, AddStructureData()];
        obj.style.stroke = constant.COLOR_TYPE.BLACK;
        obj.style.strokewidth = 3;
        obj.marks.islength = true;
        obj.selectflag = true;
        break;
      case FUNCTION_TYPE.STREETNEW:
        // TODO: these code need refactor, they are repeat
        // look at SliderShapeHandle.js

        if (obj.streetType == constant.STREET_TYPE.ROUNDABOUT) {
          const ptStart = new GeomPoint(x, y);
          const ptStop = new GeomPoint(x + 240, y);
          const r = 120;
          const ptArc1 = new GeomArc(ptStart, ptStop, r, false, false);
          const ptMid1 = ptArc1.getMidPoint();
          const ptArc2 = new GeomArc(ptStart, ptStop, r, false, true);
          const ptMid2 = ptArc2.getMidPoint();
          obj.segments = [
            createArc({
              ptStart: { x: x, y: y },
              ptStop: { x: ptMid1.x, y: ptMid1.y },
              r,
            }),
            createArc({
              ptStart: { x: ptMid1.x, y: ptMid1.y },
              ptStop: { x: x + 240, y: y },
              r,
            }),
            createArc({
              ptStart: { x: x + 240, y: y },
              ptStop: { x: ptMid2.x, y: ptMid2.y },
              r,
            }),
            createArc({
              ptStart: { x: ptMid2.x, y: ptMid2.y },
              ptStop: { x: x, y: y },
              r,
            }),
          ];
          obj.position.x2 = obj.position.x2 + 240;
        } else if (obj.streetType == constant.STREET_TYPE.CURVED) {
          /**
           * Efficiency First:
           * data don't assign to workData object, they save in a buffer array.
           * when draw stop, buffer will assign to workData object.
           */
          track.init([x, y], { bufferSize: 3 });
          this.curvedBuffer = [createLine({ ptStart: { x, y }, ptStop: { x, y } })];
          return;
        } else if (obj.streetType == constant.STREET_TYPE.STRAIGHT) {
          obj.segments = [createLine({ ptStart: { x, y }, ptStop: { x, y } })];
        }

        obj.components = [
          createStripe(1, obj.segments),
          createLane({ key: 2 }),
          createStripe(3, obj.segments, 'singledash', true),
          createLane({ key: 4 }),
          createStripe(5, obj.segments),
        ];
        obj.isShowDividerBackground = true;
        obj.isShowShoulderBackground = true;

        obj.strokePattern = 'solid';
        obj.key = 1;
        obj.nextKey = 6;
        obj.lanewidth = LANE_WIDTH;
        obj.handlepoint = [x, y];
        obj.style.strokewidth = 4;
        for (let i = 0, il = obj.groupdata.length; i < il; i++) {
          obj.groupdata[i].strokewidth = 4;
        }
        if (obj.streetType == constant.STREET_TYPE.ROUNDABOUT) {
          let _s = new _Street();
          _s.alignComponentsToStreetAxis(obj);
          _s.computeStreets(obj);
        }
        workData.setObj(obj.operateid, obj);
        break;
      default:
        break;
    }

    return obj;
  }

  /**
   * 生成（绘制） Shape 结束
   * @description 主要用于绘制图形或 Street
   * @param {number} x - 坐标 x
   * @param {number} y - 坐标 y
   * @param {object} obj - 当前绘制对象 this.drawObject.currentObject
   * @param {1 | 2} [addstate] - 1 添加 2 结束
   */
  setShapeEnd(x, y, obj, addstate) {
    // console.log('set shape end');
    if (!(obj && obj.position)) {
      return;
    }

    // TODO: should not store drawing information in object; should be passed to HandlePointx fn as parameters
    obj.position.x2 = x;
    obj.position.y2 = y;

    const handler = this.shapeMap.get(obj.functype);
    if (typeof handler === 'function') {
      handler(obj, addstate ? addstate : undefined);
      workData.setObj(obj);
      this.setAncyObjList();
    }
  }

  onMouseDownLeft = evt => {
    const { clientX, clientY } = getClickPoint(evt);
    const drawingState = this.props.drawingState;
    const afterXY = clientPtToUserPt(clientX, clientY);
    // console.log('drawingState', drawingState);
    // console.log('functype', this.drawObject.currentObject.functype);
    // console.log('evt.currentTarget', evt.currentTarget.id, evt.currentTarget);
    // console.log('evt.target', evt.target.id, evt.target);

    if (drawingState === 0) {
      this.onDrawShape();
      this.setShapeBegin(afterXY[0], afterXY[1], this.drawObject.currentObject);

      if (MultiStrokeShapes.includes(this.props.drawingShape)) {
        // 解决在触屏设备上，连续绘制图形会多绘制一个点的问题。
        if ('canvas' === evt.currentTarget.id) return;
        // 需要连续绘制的图形
        this.props.setCurrentState(6);
      } else {
        // 一次绘制完成的图形
        this.props.setCurrentState(1);
      }
    } else if (drawingState === 1) {
      //移动出画布又进来 (Move out the canvas and come in again)
      // 完成 Draw Shape
      this.setShapeEnd(afterXY[0], afterXY[1], this.drawObject.currentObject);
    } else if (drawingState === 6) {
      // 连续性 绘图
      if ('svg' === evt.currentTarget.id) {
        this.onOpenDrawing(true);
        this.setShapeEnd(afterXY[0], afterXY[1], this.drawObject.currentObject, 1);
      }
    } else if (drawingState === 2 || drawingState === -1 || drawingState === 4) {
      // FIXME: refactor
      // TODO: 当 Shape 选中时需要单击下画布空白处取消选中，再拖动画布才能移动画布
      // 正常逻辑应该是选中 Shape 时，直接拖动画布，取消选中并移动

      //选择绘图后完成画图 (Finish drawing)
      //画布移动状态
      this.props.setCurrentState(7);
      workData.cancelSelectAllObjects();
      this.setAncyObjList();
      this.hidePropsMenu();
      this.drawObject.currentObjId = '';
      this.drawObject.currentObject = {};

      this.drawObject.movePoint = [clientX, clientY];
      this.drawObject.startingPoint = [clientX, clientY];

      if (this.props.clipboard.length > 0) {
        // when clipboard has data, need mouse position for paste location on desktop browser
        this.props.updateLastClickPos(clientX, clientY);
      }
    }

    //send to back
    emitter.emit(ROAD_CONSTANT.SELECT_SYMBOL_ITEM, false);
  };

  /**
   * Stop drawing by a right-click or clicking the STOP DRAWING button
   */
  stopDrawing = (point, isButton) => {
    // TODO: if no passed in no point, could we use the last clicking point, so that the drawing shape could be ended in a correct position
    if (this.props.drawingState === 6) {
      //连续性绘图
      this.onOpenDrawing(false);
      const obj = this.drawObject.currentObject;
      const { handlepoint, functype, operateid } = obj;
      // FIXME: so it's a Line?
      if (handlepoint.length <= 1 || (functype === constant.FUNCTION_TYPE.COUNTER_TOP && handlepoint.length <= 9)) {
        this.setShapeEnd(point[0], point[1], obj, 1);
      } else {
        /**
         * 1.当右键结束绘制时，此时鼠标所在的点作为结束点
         * 2.当点击按钮结束绘制时，以绘制的最后一点作为结束点
         */
        !isButton && this.setShapeEnd(point[0], point[1], obj, 1);
        this.setShapeEnd(point[0], point[1], obj, 2);
      }
      // obj.handlepoint = obj.handlepoint.splice(obj.handlepoint.length - 1, 1);
      workData.setObjectSelectFlag(operateid, true);
      this.props.takeSnapshot();

      // 进入调整状态 (Enter the preset state)
      this.props.setCurrentState(2);
      this.props.setCursor(constant.CURSOR.DEFAULT);
      this.setShowCanvasDropMenu(false);
    }
  };

  onMouseDownRight = evt => {
    // this.drawObject.startingPoint = [clientX, clientY];
    const target = evt.target;

    //获取street区域
    if (target.id.endsWith(constant.STREET_POINT_TYPE.Center)) {
      this.drawObject.streetareaindex = Number(target.id.split('||')[0]);
      this.drawObject.streetareapoistion = target.id.split('||')[1];
    }

    if (this.props.drawingState === 6) {
      evt.stopPropagation();
      const { clientX, clientY } = getClickPoint(evt);
      var point = clientPtToUserPt(clientX, clientY);
      this.stopDrawing(point);
    }
  };

  // 鼠标按下 (mousedown)
  onMouseDown = evt => {
    if (isLeftClick(evt)) {
      this.onMouseDownLeft(evt);
      this.setState({ mouse: 'left' });
    } else if (isRightClick(evt)) {
      this.onMouseDownRight(evt);
      this.setState({ mouse: 'right' });
    }
  };

  /**
   *
   * @param {string} anchorType
   * @param {number} x
   * @param {number} y
   * @param {*} obj deep-cloned object data
   */
  transformSelectShape(anchorType, x, y, obj) {
    if (!obj) {
      return;
    }
    // const [x0, y0] = devicePtToUserPt(...this.drawObject.startingPoint);
    // TODO: cannot use offset dx, dy to update object as drawObject is not in sync with usedata object
    // const [dx, dy] = [x - x0, y - y0];
    let anchorsTypeData = [];
    let pointIndex;
    let objdata = obj;

    switch (anchorType) {
      // Scaling handle points
      case constant.ANCHORS_TYPE.LEFTTOP:
        obj = transformHandle.TransformScaleLeftTop(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.RIGHTTOP:
        obj = transformHandle.TransformScaleRightTop(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.RIGHTBOTTOM:
        obj = transformHandle.TransformScaleRightBottom(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.LEFTBOTTOM:
        obj = transformHandle.TransformScaleLeftBottom(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.TOPMEDIUM:
        obj = transformHandle.TransformScaleTopMedium(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.BOTTOMMEDIUM:
        obj = transformHandle.TransformScaleBottomMedium(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.LEFTMEDIUM:
        obj = transformHandle.TransformScaleLeftMedium(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.RIGHTMEDIUM:
        obj = transformHandle.TransformScaleRightMedium(objdata, [x, y]);
        break;

      // Rotating handle points
      case constant.ANCHORS_TYPE.ANGLE:
        obj = transformHandle.TransformRotateObject(objdata, [x, y]);
        break;

      // Line
      case constant.ANCHORS_TYPE.LINEBEGIN: {
        obj.handlepoint[0] = [x, y];
        let length = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
        obj.width = length;
        if (obj.text.islength) {
          length = Math.round((length * 1000 + 0.5) / 1000);
          obj.text.text = Utility.format(length / 12);
        }
        break;
      }
      case constant.ANCHORS_TYPE.LINEEND: {
        obj.handlepoint[1] = [x, y];
        let length = LengthBetweenPoints(obj.handlepoint[0], obj.handlepoint[1]);
        obj.width = length;
        if (obj.text.islength) {
          length = Math.round((length * 1000 + 0.5) / 1000);
          obj.text.text = Utility.format(length / 12);
        }
        break;
      }

      // Arc
      case constant.ANCHORS_TYPE.ARCBEGIN:
        obj = transformHandle.TransformArcBeginObject(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.ARCEND:
        obj = transformHandle.TransformArcEndObject(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.ARCANGLE:
        obj = transformHandle.TransformArcCenterObject(objdata, [x, y]);
        break;

      // Stair
      case constant.ANCHORS_TYPE.STAIRSBEGIN:
        obj = transformHandle.TransformStairsBeginObject(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.STAIRSMIDDLE:
        obj = transformHandle.TransformStairsMiddleObject(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.STAIRSEND:
        obj = transformHandle.TransformStairsEndObject(objdata, [x, y]);
        break;

      // Parking Stall
      case constant.ANCHORS_TYPE.PARKING_STALL_ANGLE:
        obj = transformHandle.TransformParkingStallAngle(objdata, [x, y]);
        break;

      // Crosswalk
      case constant.ANCHORS_TYPE.CROSSWALKBEGIN:
        obj = transformHandle.TransformCrossWalkBegin(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.CROSSWALKEND:
        obj = transformHandle.TransformCrossWalkEnd(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.CROSSWALKLEFTTOP:
        obj = transformHandle.TransformCrossWalkLeftTop(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.CROSSWALKLEFTBOTTOM:
        obj = transformHandle.TransformCrossWalkLeftBottom(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.CROSSWALKRIGHTTOP:
        obj = transformHandle.TransformCrossWalkRightTop(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.CROSSWALKRIGHTBOTTOM:
        obj = transformHandle.TransformCrossWalkRightBottom(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.CROSSWALKMIDDLETOP:
        obj = transformHandle.TransformCrossWalkMiddleTop(objdata, [x, y]);
        break;
      case constant.ANCHORS_TYPE.CROSSWALKMIDDLEBOTTOM:
        obj = transformHandle.TransformCrossWalkMiddleBottom(objdata, [x, y]);
        break;
      default:
        break;
    }

    // Connected Lines
    if (anchorType.endsWith(constant.ANCHORS_TYPE.CONNECTBEGIN)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformConnectedBeginObject(objdata, pointIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.CONNECTNORMAL)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformConnectedNormalObject(objdata, pointIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.CONNECTCANGLE)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformConnectedAngleObject(objdata, pointIndex, [x, y]);
    }
    // Closed Shape
    else if (anchorType.endsWith(constant.ANCHORS_TYPE.CLOSEEND)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformConnectedEndObject(objdata, pointIndex, [x, y]);
    } else if (anchorType === constant.ANCHORS_TYPE.CLOSEANGLE) {
      obj = transformHandle.TransformCloseShapeAngleObject(objdata, [x, y]);
    }
    // Counter
    else if (anchorType.endsWith(constant.ANCHORS_TYPE.COUNTERBEIGIN)) {
      obj = transformHandle.TransformCounterBeginObject(objdata, 0, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.COUNTEREND)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformCounterEndObject(objdata, pointIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.COUNTERNORMAL)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformCounterNormalObject(objdata, pointIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.COUNTERTHREE)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformCounterMiddleObject(objdata, pointIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.COUNTERMOVELEFT)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformCounterMoveLeftObject(objdata, pointIndex, [x, y], this.drawObject.capturePoint);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.COUNTERMOVERIGHT)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformCounterMoveRightObject(objdata, pointIndex, [x, y], this.drawObject.capturePoint);
    }
    // Structure
    else if (anchorType.endsWith(constant.ANCHORS_TYPE.STRUCTURELINE)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformStructureLine(objdata, pointIndex, [x, y], this.drawObject.capturePoint);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STRUCTUREBEGIN)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformStructureBegin(objdata, pointIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STRUCTUREEND)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformHandle.TransformStructureEnd(objdata, pointIndex, [x, y]);
    }
    // Callout
    else if (anchorType.endsWith(constant.ANCHORS_TYPE.CALLOUTANGLE)) {
      obj = transformHandle.TransformRotateCalloutObject(objdata, [x, y]);
    }
    // Street/StreetNew
    else if (anchorType === constant.ANCHORS_TYPE.STREETBEGIN) {
      obj = transformStreetHandle.TransformStreetBeginObject(objdata, [x, y]);
      this.drawObject.isStreetAcross = true;
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETCENTER)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformStreetHandle.TransformStreetCenterObject(objdata, pointIndex, [x, y]);
      this.drawObject.isStreetAcross = true;
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETNORMAL)) {
      pointIndex = Number(anchorType.split('||')[0]);
      obj = transformStreetHandle.TransformStreetNormalObject(objdata, pointIndex, [x, y]);
      this.drawObject.isStreetAcross = true;
    } else if (anchorType === constant.ANCHORS_TYPE.STREETEND) {
      obj = transformStreetHandle.TransformStreetEndObject(objdata, [x, y]);
      this.drawObject.isStreetAcross = true;
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETLEFTTOP)) {
      anchorsTypeData = anchorType.split('||');
      let lineIndex = Number(anchorsTypeData[0]);
      let beginIndex = Number(anchorsTypeData[1]);
      let lineHeight = Number(anchorsTypeData[2]);
      let isUnderLine = anchorsTypeData[3] === 'true';
      obj = transformStreetHandle.TransformStreetLeftTopObject(
        objdata,
        lineIndex,
        beginIndex,
        lineHeight,
        isUnderLine,
        [x, y]
      );
      // this.drawObject.isStreetAcross=true;
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETRIGHTTOP)) {
      anchorsTypeData = anchorType.split('||');
      let lineIndex = Number(anchorsTypeData[0]);
      let endIndex = Number(anchorsTypeData[1]);
      let lineHeight = Number(anchorsTypeData[2]);
      let isUnderLine = anchorsTypeData[3] === 'true';
      obj = transformStreetHandle.TransformStreetRightTopObject(objdata, lineIndex, endIndex, lineHeight, isUnderLine, [
        x,
        y,
      ]);
      // this.drawObject.isStreetAcross=true;
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.OFFSETLEFT)) {
      anchorsTypeData = anchorType.split('||');
      let lineIndex = Number(anchorsTypeData[0]);
      let beginIndex = Number(anchorsTypeData[1]);
      let lineHeight = Number(anchorsTypeData[2]);
      let isUnderLine = anchorsTypeData[3] === 'true';

      obj = transformStreetHandle.TransformStreetLeftTopObject(
        objdata,
        lineIndex,
        beginIndex,
        lineHeight,
        isUnderLine,
        [x, y]
      );
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.OFFSETRIGHT)) {
      anchorsTypeData = anchorType.split('||');
      let lineIndex = Number(anchorsTypeData[0]);
      let endIndex = Number(anchorsTypeData[1]);
      let lineHeight = Number(anchorsTypeData[2]);
      let isUnderLine = anchorsTypeData[3] === 'true';
      obj = transformStreetHandle.TransformStreetRightTopObject(objdata, lineIndex, endIndex, lineHeight, isUnderLine, [
        x,
        y,
      ]);
    } else if (anchorType === constant.ANCHORS_TYPE.OFFSETCENTER) {
      obj = transformStreetHandle.TransformOffsetCenterObject(objdata, [x, y]);
    } else if (anchorType === constant.ANCHORS_TYPE.STREETARCCenterTOP) {
      obj = transformStreetHandle.TransformStreetArcCenterTop(objdata, [x, y]);
    } else if (anchorType === constant.ANCHORS_TYPE.STREETTEXTRECT) {
      obj = transformStreetHandle.TransformStreetTextRect(objdata, [x, y], this.drawObject.capturePoint);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETACROSSPOINT)) {
      anchorsTypeData = anchorType.split('||');
      let acrossDataIndex = Number(anchorsTypeData[0]);
      let pointIndex = Number(anchorsTypeData[1]);
      obj = transformHandle.TransformStreetAcrossPoint(objdata, acrossDataIndex, pointIndex, [x, y]);
      this.drawObject.isStreetAcross = false;
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETARCLEFT)) {
      let lineIndex = Number(anchorType.split('||')[0]);
      obj = transformStreetHandle.TransformStreetArcLeftMove(objdata, lineIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETARCRIGHT)) {
      let lineIndex = Number(anchorType.split('||')[0]);
      obj = transformStreetHandle.TransformStreetArcRightMove(objdata, lineIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.STREETSPLITPATTEM)) {
      anchorsTypeData = anchorType.split('||');
      let lineIndex = Number(anchorsTypeData[1]);
      let dataIndex = Number(anchorsTypeData[2]);
      obj = transformStreetHandle.TransformStreetSplitPattem(objdata, lineIndex, dataIndex, [x, y]);
    } else if (anchorType.endsWith(constant.ANCHORS_TYPE.OFFSETPOSITION)) {
      obj = transformHandle.TransformPosition(objdata, [x, y]);
    } else if (anchorType === 'StreetNewBegin') {
      obj = transformHandle.TransformStreetNewBeginObject(objdata, [x, y]);
    } else if (anchorType === 'StreetNewEnd') {
      obj = transformHandle.TransformStreetNewEndObject(objdata, [x, y]);
    } else if (anchorType.indexOf('RoundaboutConnect') > -1) {
      let index = +anchorType.split('-')[1];
      obj = transformHandle.TransformRoundaboutConnectObject(objdata, [x, y], index);
    } else if (anchorType.indexOf('RoundaboutCenter') > -1) {
      let index = +anchorType.split('-')[1];
      obj = transformHandle.TransformRoundaboutCenterObject(objdata, [x, y], index);
    } else if (anchorType.indexOf('StreetNewCenter') > -1) {
      // TODO: refactor StreetNew and Roundabout transform handle
      let index = +anchorType.split('-')[1];
      if (index) {
        obj = transformHandle.TransformStreetNewCenterObject(objdata, [x, y], index);
      } else {
        obj = transformHandle.TransformStreetNewCenterObject(objdata, [x, y]);
      }
    } else if (anchorType.indexOf('StreetNewConnect') > -1) {
      let index = +anchorType.split('-')[1];
      obj = transformHandle.TransformRoundaboutConnectObject(objdata, [x, y], index);
    } else if (anchorType.startsWith('StreetOffsetArcPathStart')) {
      anchorsTypeData = anchorType.split('-');
      let stripeKey = anchorsTypeData[2];
      obj = transformHandle.dragStreetOffsetArcPathStartHandle(objdata, stripeKey, [x, y]);
    } else if (anchorType.startsWith('StreetOffsetArcPathStop')) {
      anchorsTypeData = anchorType.split('-');
      let stripeKey = anchorsTypeData[2];
      obj = transformHandle.dragStreetOffsetArcPathStopHandle(objdata, stripeKey, [x, y]);
    } else if (anchorType.startsWith('StreetOffsetArcPathTransition')) {
      anchorsTypeData = anchorType.split('-');
      let stripeKey = anchorsTypeData[2];
      let dragIndex = anchorsTypeData[3];
      obj = transformHandle.dragStreetOffsetArcPathTransitionHandle(objdata, stripeKey, dragIndex, [x, y]);
    } else if (anchorType.startsWith('OffsetOffsetArcPathStart')) {
      anchorsTypeData = anchorType.split('-');
      obj = transformHandle.dragOffsetOffsetArcPathStartHandle(objdata, [x, y]);
    } else if (anchorType.startsWith('OffsetOffsetArcPathStop')) {
      anchorsTypeData = anchorType.split('-');
      obj = transformHandle.dragOffsetOffsetArcPathStopHandle(objdata, [x, y]);
    } else if (anchorType.startsWith('OffsetOffsetArcPathTransition')) {
      anchorsTypeData = anchorType.split('-');
      obj = transformHandle.dragOffsetOffsetArcPathTransitionHandle(objdata, [x, y]);
    } else if (anchorType.startsWith('PatternBreak')) {
      anchorsTypeData = anchorType.split('-');
      let stripeKey = anchorsTypeData[2];
      let dragIndex = anchorsTypeData[3];
      obj = transformHandle.dragPatternBreakHandle(objdata, stripeKey, dragIndex, [x, y]);
    } else if (
      anchorType === constant.ANCHORS_TYPE.ADD_SECTION_BEGIN ||
      anchorType === constant.ANCHORS_TYPE.ADD_SECTION_END
    ) {
      // Add Section只存在点击事件，不存在其他事件响应
      return;
    }
    // Measurement
    else if (anchorType.endsWith('TransformHeadPosition')) {
      obj = transformHandle.TransformHeadPosition(objdata, [x, y]);
    } else if (anchorType.endsWith('TransformTailPosition')) {
      obj = transformHandle.TransformTailPosition(objdata, [x, y]);
    } else if (anchorType.startsWith('TransformReferencePosition')) {
      let i = Number(anchorType.split('-')[1]);
      obj = transformHandle.TransformReferencePosition(objdata, [x, y], i);
    }

    workData.setObj(obj);
    this.setAncyObjList();
  }

  /**
   * 移动 (Mouse Move)
   * @param {MouseEvent} evt
   */
  onMouseMove = evt => {
    // console.log('mouse move & touch move');
    // console.log('drawObject state', this.props.drawingState);
    const { clientX, clientY, clientX1, clientY1 } = getClickPoint(evt);
    const { selectedSymbolKey, clipboard, updateMousePosition } = this.props;
    const drawingState = this.props.drawingState;
    const { currentObject: drawingObject, movePoint, startingPoint } = this.drawObject;

    // FIXME: set avatar position directly, store mouse position as SVGRoot state
    // FIXME: move to street on mouse move
    // if (selectedSymbolKey) {
    //   // when symbol selected, need mouse position to calculate direction
    //   updateMousePosition(clientX, clientY);
    // } else if (clipboard.length > 0) {
    if (selectedSymbolKey) {
      // when symbol selected, need mouse position to calculate direction
      updateMousePosition(clientX, clientY);
    }
    // else if (clipboard.length > 0) {
    //   // when clipboard has data, need mouse position for paste location on desktop browser
    //   this.throttledUpdateMousePosition(clientX, clientY);
    // }

    // 双指缩放
    // TODO: 导致图形从画布上消失
    // this.touchScaleHandle({ clientX, clientY, clientX1, clientY1 });
    let afterXY = clientPtToUserPt(clientX, clientY);

    if (drawingState === 0) {
      if (this.props.cursor != constant.CURSOR.DRAWING) {
        this.props.setCursor(constant.CURSOR.DRAWING);
      }
    } else if (drawingState === 1 || drawingState === 6) {
      //预设置终点位置 (Preset endpoint location)
      // Draw Shape 过程中（鼠标未抬起时）
      if (!(drawingObject.functype === constant.FUNCTION_TYPE.STRUCTURE)) {
        this.setShapeEnd(afterXY[0], afterXY[1], drawingObject);
      }
    } else if (drawingState === 3) {
      drawingObject &&
        this.transformSelectShape(this.props.anchorsType, afterXY[0], afterXY[1], _.cloneDeep(drawingObject));
      if (drawingObject.functype === constant.FUNCTION_TYPE.STREETNEW) {
        let _s = new _Street();
        let lw = _s.alignComponentsToStreetAxis(drawingObject);
        _s.computeStreets(drawingObject);
      }
      this.setAncyObjList();
    } else if (drawingState === 5) {
      // 有物体处于被选中状态
      //平移状态 (Translation state)
      let startPoint = devicePtToUserPt(...startingPoint);
      let stopPoint = [clientX, clientY];
      let selectUserData = workData.getSelectObjects();
      for (let i = 0, il = selectUserData.length; i < il; i++) {
        const obj = _.cloneDeep(selectUserData[i]);
        if (!workData.isLocked(obj)) {
          workData.setObj(transformHandle.TransformMoveShapes(obj, startPoint, stopPoint));
        }
      }
      // update move start point
      this.drawObject.startingPoint = [clientX, clientY];
      if (this.drawObject.currentObject.functype === constant.FUNCTION_TYPE.STREET) {
        //道路交叉计算
        if (this.drawObject.isStreetAcross) {
          if (!this.drawObject.currentObject.streetoffarc) handleStreetAcross(this.drawObject.currentObjId);
          else handleOffsetAcross(this.drawObject.currentObjId);
        }
      } else if (this.drawObject.currentObject.functype === constant.FUNCTION_TYPE.DIRT) {
        if (this.drawObject.isStreetAcross) {
          handleDirtAcross(this.drawObject.currentObjId);
        }
      } else if (this.drawObject.currentObject.functype === constant.FUNCTION_TYPE.GRAVEL) {
        if (this.drawObject.isStreetAcross) {
          handleGravelAcross(this.drawObject.currentObjId);
        }
      } else if (this.drawObject.currentObject.functype === constant.FUNCTION_TYPE.CROSSWALK) {
        HandleCrossWalkAcross(this.drawObject.currentObjId, afterXY);
      } else if (this.drawObject.currentObject.functype === constant.FUNCTION_TYPE.STREETNEW) {
        //道路交叉计算
        let _s = new _Street();
        let lw = _s.alignComponentsToStreetAxis(this.drawObject.currentObject);
        _s.computeStreets(this.drawObject.currentObject);
      }
      this.setAncyObjList();
    } else if (drawingState === 7) {
      //画布移动状态
      let move = [clientX - movePoint[0], clientY - movePoint[1]];
      this.drawObject.movePoint = [clientX, clientY];
      this.props.pan(move[0], move[1]);
      this.props.moveMap(move);
    }
  };

  // //移出画布
  // onMouseOut() { }

  /**
   * @deprecated
   * @param {*} evt
   */
  touchScaleHandle = ({ clientX: x0, clientY: y0, clientX1: x1, clientY1: y1 }) => {
    if (typeof x1 !== 'undefined' && typeof y1 !== 'undefined') {
      let x = (x0 + x1) / 2;
      let y = (y0 + y0) / 2;
      let xMove = x1 - x0;
      let yMove = y1 - y0;

      // 防抖
      if (Math.abs(xMove) >= 50 || Math.abs(yMove) >= 50) {
        // 缩放比例数值
        let distance = Math.sqrt(xMove * xMove + yMove * yMove) / 2;
        let delta = this.prevTouch - distance;
        let isZoomIn = delta > 0 ? false : true;

        // 保存这次的移动距离与下一次作比较
        this.prevTouch = distance;

        this.props.handleZoom(isZoomIn, x, y);
      }
    }
  };

  /**
   * 鼠标滚轮事件
   * @param {number} deltaY
   * @param {number} clientX
   * @param {number} clientY
   */
  onWheelHandle = evt => {
    // console.log('onwheelhandle');
    // TODO: encapsulate utility to get touch/mouse position
    let { clientX, clientY } = evt;
    // touch功能
    if (evt.changedTouches && evt.changedTouches.length > 1) {
      ({ clientX, clientY } = evt.changedTouches[0]);
    }
    // disable zoom when moving canvas
    if (this.props.drawingState !== 7) this.props.handleZoom(evt.deltaY < 0, clientX, clientY);
  };

  /**
   * 注意：请在下方填入所有用此方法来处理的场景，便于分析和整理逻辑，优化代码
   * 注意：在需要的地方添加 takeSnapshot 调用
   * - stop moving an object
   * - stop drawing things
   * - TBD etc.
   */
  onMouseUp = evt => {
    const { moveMap, collapsed, latitude, longitude } = this.props;

    // FIXME: drawObject state is not always consistent
    // console.log(this.drawObject);
    // console.log('drawingState', this.props.drawingState);
    // console.log('functype', this.drawObject.currentObject.functype);
    // console.log('evt.currentTarget', evt.currentTarget.id, evt.currentTarget);
    // console.log('evt.target', evt.target.id, evt.target);
    const { clientX, clientY } = getClickPoint(evt);

    // drawing object state values:
    // drawingState = 1: 结束通过拖拽（非多次点击）绘制图形
    // store current drawing state as it may be updated later.
    const drawingState = this.props.drawingState;
    const { functype } = this.drawObject.currentObject;
    const afterXY = clientPtToUserPt(clientX, clientY);

    if (drawingState === 0 || drawingState === 4) return;

    //开始状态或者完成状态不能进入 (Initiation or completion state cannot be entered)
    if (drawingState === 6) {
      if (functype !== FUNCTION_TYPE.STRUCTURE) {
        this.drawObject.prevDrawPoint = afterXY;
        this.setShapeEnd(afterXY[0], afterXY[1], this.drawObject.currentObject);
      }
      return;
    }

    // FIXME:用于在完成画布上的操作后强制执行一遍状态
    if (drawingState === 7) {
      // 移动画布
      this.props.setCurrentState(4);
      this.props.setDrawingShape();
      // TODO: another approach is to store the map latlgn + SVG positions, so that we can calculate the new latlngs after SVG translated, by doing this, we do not need to pass in the move vector
      // can also get from the props

      // if map exists, need to take snapshot to record map locations
      if (latitude !== 0 && longitude !== 0) {
        this.props.takeSnapshot();
      }
      return;
    }

    // 刚刚结束（非多次点击进行的）绘制
    if (drawingState === 1) {
      switch (functype) {
        case constant.FUNCTION_TYPE.CALLOUT:
        case constant.FUNCTION_TYPE.TEXTBOX: {
          // 防止点击画布添加图形的行为
          if (this.drawObject.currentObject.handlepoint && this.drawObject.currentObject.handlepoint.length === 0) {
            workData.deleteObject(this.drawObject.currentObject.operateid);
          }

          this.drawObject.currentObject.text.text = i18n.t('property.textTab.textDefaultText');
          this.drawObject.currentObject.selectflag = true;
          // make sure use data is in sync
          workData.setObj(this.drawObject.currentObject);
          this.showPropsMenu();
          break;
        }
        case constant.FUNCTION_TYPE.STREETNEW: {
          if (this.drawObject.currentObject.streetType === constant.STREET_TYPE.CURVED) {
            if (this.curvedBuffer.length > 0) {
              /**
               * Render Curved Road Processing:
               * 1. curvedBuffer => obj.segments
               * 2. calculate obj
               * 3. sync workData
               * 4. clear curvedBuffer and track points
               * 5. destory track
               */
              const obj = this.drawObject.currentObject;
              obj.segments = this.curvedBuffer;
              obj.components = [
                createStripe(1, this.curvedBuffer),
                createLane({ key: 2 }),
                createStripe(3, this.curvedBuffer, 'singledash', true),
                createLane({ key: 4 }),
                createStripe(5, this.curvedBuffer),
              ];
              obj.selectflag = true;
              obj.isShowDividerBackground = true;
              obj.isShowShoulderBackground = true;
              obj.strokePattern = 'solid';
              obj.key = 1;
              obj.nextKey = 6;
              obj.style.strokewidth = 4;
              for (let idx = 0, il = obj.groupdata.length; idx < il; idx++) {
                obj.groupdata[idx].strokewidth = 4;
              }

              var _s = new _Street();
              _s.alignComponentsToStreetAxis(obj);
              _s.computeStreets(obj);
              workData.setObj(obj);

              this.curvedBuffer = [];
              clearTrackPoints();
              track.destroy();
            }
          }
          break;
        }
        default:
          // 防止点击画布添加图形的行为
          if (this.drawObject.currentObject.handlepoint && this.drawObject.currentObject.handlepoint.length === 0) {
            workData.deleteObject(this.drawObject.currentObject.operateid);
          }
          break;
      }
    }

    if (drawingState === -1 || drawingState === 5 || drawingState === 3 || drawingState === 1 || drawingState === 2) {
      this.props.setDrawingShape();
      if (functype === constant.FUNCTION_TYPE.STREET) {
        //道路交叉计算
        if (this.drawObject.isStreetAcross) {
          if (!this.drawObject.currentObject.streetoffarc) handleStreetAcross(this.drawObject.currentObjId);
          else handleOffsetAcross(this.drawObject.currentObjId);
        }
      } else if (functype === constant.FUNCTION_TYPE.DIRT) {
        if (this.drawObject.isStreetAcross) {
          handleDirtAcross(this.drawObject.currentObjId);
        }
      } else if (functype === constant.FUNCTION_TYPE.GRAVEL) {
        if (this.drawObject.isStreetAcross) {
          handleGravelAcross(this.drawObject.currentObjId);
        }
      } else if (functype === constant.FUNCTION_TYPE.CROSSWALK) {
        HandleCrossWalkAcross(this.drawObject.currentObjId, afterXY);
      } else if (functype === constant.FUNCTION_TYPE.STREETNEW) {
        const street = this.drawObject.currentObject;
        workData.sortUseData();

        //
        let _s = new _Street();
        _s.alignComponentsToStreetAxis(street);
        _s.computeStreets(street);

        //
        const streets = workData.getStreets({
          surfaceType: street.surfaceType,
        });
        streets.forEach(s => {
          _s.alignComponentsToStreetAxis(s);
          _s.computeStreets(s);
        });
      }
    }

    let cancelTakeSnapshot = false;
    if (drawingState === 3) {
      if (functype === constant.FUNCTION_TYPE.STREETNEW) {
        let anchorType = this.props.anchorsType;
        if (
          anchorType === constant.ANCHORS_TYPE.ADD_SECTION_BEGIN ||
          anchorType === constant.ANCHORS_TYPE.ADD_SECTION_END
        ) {
          cancelTakeSnapshot = true;
        } else if (
          anchorType.startsWith('StreetOffsetArcPathStart') ||
          anchorType.startsWith('StreetOffsetArcPathStop')
        ) {
          cancelTakeSnapshot = true;
        } else if (anchorType === 'lncd-map-layer') {
          // Mouse up on the canvas
          cancelTakeSnapshot = true;
        }

        if (!cancelTakeSnapshot) {
          // Fixed: When you increase the road widen by use handle point(mouseup on the canvas), undo doesn’t work.
          this.props.takeSnapshot();
        }
      }
    }

    // 需要选取范围的对象
    if (functype == constant.FUNCTION_TYPE.TEXTBOX) {
      // TODO: 考虑能否将该逻辑与 “防止点击画布添加 Callout 的行为” 共用？
      // 防止点击画布添加 Textbox 的行为
      if (workData.getObject(this.drawObject.currentObjId)) {
        this.drawObject.currentObject = workData.getObject(this.drawObject.currentObjId);
        this.drawObject.currentObject.style.stroke = constant.COLOR_TYPE.TRANSPARENT;
        workData.setObj(this.drawObject.currentObjId, this.drawObject.currentObject);
      }
    } else if (functype == constant.FUNCTION_TYPE.SELECTION) {
      const handlePoint = this.drawObject.currentObject.handlepoint.concat();
      workData.setObjectFlagByRange(handlePoint[0], handlePoint[2]);
      this.drawObject.selectzoom = [handlePoint[0], handlePoint[2]];
      workData.deleteObject(this.drawObject.currentObjId);
    } else if (functype == constant.FUNCTION_TYPE.ZOOMSELECT) {
      const handlePoint = this.drawObject.currentObject.handlepoint.concat();
      this.drawObject.selectzoom = [handlePoint[0], handlePoint[2]];
      emitter.emit(EVENT_EMIT_TYPE.ZOOM, this.drawObject.currentObject.operateid);
      workData.deleteObject(this.drawObject.currentObjId);
    }

    if (drawingState === 5 && functype === constant.FUNCTION_TYPE.SYMBOL) {
      let obj = workData.getObject(this.drawObject.currentObjId);
      if (obj.shadow.isActiveShadowMode) {
        track.destroy(({ path }) => {
          createShadowShape(obj, path);
          this.setState({ shadowConfirmVisible: true });
        });
      }
    }

    this.setAncyObjList();
    if (this.state.mouse === 'left') {
      if (1 === drawingState) {
        if (!cancelTakeSnapshot) {
          this.props.takeSnapshot();
        }
      } else if ((3 === drawingState || 5 === drawingState) && this.shapeMouseDownPosition) {
        const mouseXMoved = Math.abs(this.shapeMouseDownPosition.clientX - clientX) > 2;
        const mouseYMoved = Math.abs(this.shapeMouseDownPosition.clientY - clientY) > 2;
        if ((mouseXMoved || mouseYMoved) && !cancelTakeSnapshot) {
          this.props.takeSnapshot();
        }
        this.shapeMouseDownPosition = null;
      }
    }
    this.setState(() => ({ mouse: '' }));

    // XXX:This logic fix the sidebar collapsed issue when draw something in touch devices.
    if (!collapsed && workData.hasData()) {
      this.props.setCollapsed(true);
    }

    this.props.setCursor(constant.CURSOR.DEFAULT);
    //进入预设状态 (Enter the preset state)
    this.props.setCurrentState(2);
  };

  shapeClick = evt => {
    const { isLockingRoads } = this.props;
    // console.log('drawingState', this.props.drawingState);
    // console.log('functype', this.drawObject.currentObject.functype);

    if (this.props.drawingState == 4 || this.props.drawingState == 2) {
      const { clientX, clientY } = getClickPoint(evt);
      //结束其他图形的选择状态 (End the selection state for other graphics)
      const operateId = evt.currentTarget.id;
      if (!evt.shiftKey) {
        if (this.drawObject.currentObject.operateid === operateId) {
          return;
        }
      }
      const obj = workData.getObject(operateId);
      if (obj.functype === constant.FUNCTION_TYPE.STREETNEW && isLockingRoads) {
        return;
      }
      this.props.setCurrentState(2);
      this.drawObject.currentObject = obj;

      this.drawObject.currentObject.clickpoint = clientPtToUserPt(clientX, clientY);
      // this.drawObject.currentObjId = operateId;
      //让当前图形进入选择状态 (Let the current graphic enters the selection state)
      workData.setObjectSelectFlag(operateId, true);
      this.setAncyObjList();
    }
  };
  // shapeMouseMove = evt => { };
  // 选择后鼠标按下
  shapeMouseDown = evt => {
    if (isLeftClick(evt)) {
      this.shapeMouseDownLeft(evt);
      this.setState({ mouse: 'left' });
    }
  };
  // realyShapMouseDownRight = evt => { };
  shapeMouseDownLeft = evt => {
    // console.log('shape mouse down left');
    // console.log('drawingState', this.props.drawingState);
    // console.log('functype', this.drawObject.currentObject.functype);
    this.shapeMouseDownPosition = getClickPoint(evt);
    if (workData.getObject(evt.currentTarget.id).functype === constant.FUNCTION_TYPE.SYMBOL) {
      emitter.emit(ROAD_CONSTANT.SELECT_SYMBOL_ITEM, true);
    } else {
      emitter.emit(ROAD_CONSTANT.SELECT_SYMBOL_ITEM, false);
    }

    const { clientX, clientY, target, ctrlKey } = getClickPoint(evt);
    let afterXY = clientPtToUserPt(clientX, clientY);

    // Unused in anywhere
    // if (this.props.drawingState === 2 && target.id.endsWith(constant.ANCHORS_TYPE.STREET)) {
    //   if (this.drawObject.currentObject.selectlane == -1) {
    //     this.drawObject.currentObject.selectlane = 1;
    //   } else {
    //     this.drawObject.currentObject.selectlane = -1;
    //   }
    // }

    if (this.props.drawingState === 2) {
      // Dirt, Gravel等旧类型(Street)道路交叉后拐角的部分
      if (target.id.endsWith(constant.ANCHORS_TYPE.STREETACROSSAREA)) {
        evt.stopPropagation();
        this.props.setCurrentState(3);

        let objId = target.id.split('||')[1];
        this.drawObject.currentObject = workData.getObject(objId);
        this.drawObject.capturePoint = clientPtToUserPt(clientX, clientY);
        this.drawObject.currentObject.clickpoint = afterXY;
        this.drawObject.isStreetAcross = true;

        workData.cancelSelectAllObjects();
        workData.setObjectSelectFlag(objId, true);

        let index = Number(target.id.split('||')[0]);
        this.drawObject.currentObject.handlepoint[index][3].selectflag = true;

        this.setAncyObjList();
        this.props.setAnchorsType(target.id);
        return;
      }
      // Structure上的组成部分 wall
      if (target.id.endsWith(constant.ANCHORS_TYPE.STRUCTURELINE)) {
        evt.stopPropagation();
        this.props.setCurrentState(3);

        let objId = target.id.split('||')[1];
        this.drawObject.currentObjId = objId;
        this.drawObject.currentObject = workData.getObject(objId);
        this.drawObject.capturePoint = clientPtToUserPt(clientX, clientY);
        this.drawObject.currentObject.clickpoint = afterXY;

        // wall 的处理逻辑
        let index = Number(target.id.split('||')[0]);
        HandlePointStructureSelect(this.drawObject.currentObject, index);

        this.setAncyObjList();
        this.props.setAnchorsType(target.id);
        return;
      }
    }

    if (this.props.drawingState === 4 || this.props.drawingState === 2) {
      evt.stopPropagation();
      this.props.setCurrentState(5);

      let objId = evt.currentTarget.id;
      this.drawObject.currentObjId = objId;
      this.drawObject.currentObject = workData.getObject(objId);
      this.drawObject.startingPoint = [clientX, clientY];
      this.drawObject.currentObject.clickpoint = afterXY;

      const obj = this.drawObject.currentObject;
      if (obj.functype === constant.FUNCTION_TYPE.SYMBOL) {
        if (obj.shadow.isActiveShadowMode) {
          createShadow(obj, afterXY);
        }
        // hot key on shadow feature: ctrl
        if (ctrlKey) {
          createShadow(obj, afterXY);
        }
      }

      // 选中被点击的对象 取消当前下选中的对象
      if (
        // 如果这个事件没有快捷键
        // 并且只选中一个对象
        !evt.shiftKey &&
        workData.getSelectObjects().length < 2
      ) {
        workData.cancelSelectAllObjects();
      }
      workData.setObjectSelectFlag(objId, true);
    }
  };

  calcSectionPt = (type, prevSeg, pt) => {
    let prevSegPt, sign;
    if (type === 'start') {
      prevSegPt = prevSeg.ptStop;
      sign = -1;
    } else if (type === 'stop') {
      prevSegPt = prevSeg.ptStart;
      sign = 1;
    }
    const segmentLen = STREET_LEN / 2;
    const point = pt.clone();
    if (prevSeg instanceof GeomLine) {
      point.offsetToward(new GeomPoint(prevSegPt), -1 * segmentLen);
    } else if (prevSeg instanceof GeomArc) {
      const ptCenter = prevSeg.getCircleCenter();
      point.offsetToward(ptCenter, -1 * segmentLen);
      sign = prevSeg.sweepFlag ? sign : -sign;
      point.rotateRad((sign * Math.PI) / 2, pt);
    }
    return point;
  };

  selectClick = evt => {
    // console.log('drawingState', this.props.drawingState);
    // console.log('functype', this.drawObject.currentObject.functype);
    if (this.props.drawingState == 2 || this.props.drawingState === 4) {
      // TODO 直接修改了 workData 的引用，应该使用 clone 构建，并通过 setObj 更新 workData
      let obj = this.drawObject.currentObject;
      if (obj.functype === constant.FUNCTION_TYPE.STREETNEW) {
        let segmentsArr = obj.offsetArcPath ? [obj.segments, obj.offsetArcPath.segments] : [obj.segments];
        segmentsArr.forEach(segments => {
          const gpa = new GeomPolyArc(segments);
          if (evt.target.id === constant.ANCHORS_TYPE.ADD_SECTION_BEGIN) {
            const prevSeg = gpa.segments[0];
            const ptStop = new GeomPoint(prevSeg.ptStart);
            // const point = calcSectionStartPt(prevSeg, ptStop);
            const point = this.calcSectionPt('start', prevSeg, ptStop);
            segments.unshift(createLine({ ptStart: point, ptStop }));

            if (Utility.isNonEmptyArray(obj.segments)) {
              let _s = new _Street();
              let lw = _s.alignComponentsToStreetAxis(obj);
              _s.computeStreets(obj);
            }

            // Update two offsets path on the borders
            if (obj.components.length >= 5) {
              obj = transformHandle.dragStreetOffsetArcPathStartHandle(obj, '1', obj.handlepoint[0]);
              obj = transformHandle.dragStreetOffsetArcPathStartHandle(obj, '5', obj.handlepoint[2]);
              workData.setObj(obj);
              this.setAncyObjList();
            }

            // workData.setObj(obj);
            // this.onUpdateDiagram();
            this.props.takeSnapshot();
          } else if (evt.target.id === constant.ANCHORS_TYPE.ADD_SECTION_END) {
            const prevSeg = gpa.segments[gpa.segments.length - 1];
            const ptStart = new GeomPoint(prevSeg.ptStop);
            // const point = calcSectionStopPt(prevSeg, ptStart);
            const point = this.calcSectionPt('stop', prevSeg, ptStart);
            segments.push(createLine({ ptStart, ptStop: point }));

            if (Utility.isNonEmptyArray(obj.segments)) {
              let _s = new _Street();
              let lw = _s.alignComponentsToStreetAxis(obj);
              _s.computeStreets(obj);
            }

            // Update two offsets path on the borders
            if (obj.components.length >= 5) {
              obj = transformHandle.dragStreetOffsetArcPathStopHandle(obj, '1', obj.handlepoint[1]);
              obj = transformHandle.dragStreetOffsetArcPathStopHandle(obj, '5', obj.handlepoint[3]);
              workData.setObj(obj);
              this.setAncyObjList();
            }

            // workData.setObj(obj);
            // this.onUpdateDiagram();
            this.props.takeSnapshot();
          }
        });
      }
      // else if (obj.functype === constant.FUNCTION_TYPE.STRUCTURE) {
      //   console.log('targetId', target.id);
      //   let selectedObjIndex = obj.handlepoint.findIndex(x => x[3] && x[3].selectflag);
      //   if (selectedObjIndex > -1) {
      //     obj.handlepoint[selectedObjIndex][3].selectflag = false;
      //     workData.setObj(obj);
      //     this.onUpdateDiagram();
      //   }
      // }
    }
  };

  selectMouseDown = evt => {
    // console.log('selectMouseDown');
    evt.stopPropagation();
    this.shapeMouseDownPosition = getClickPoint(evt);
    if (evt.changedTouches) {
      // touch 功能
      if (evt.changedTouches.length == 1) {
        this.selectMouseDownLeft(evt);
      } else if (evt.changedTouches.length == 2) {
        // this.selectMouseDownRight(evt);
      }
    } else {
      if (evt.button == 0) {
        //左键点击
        this.selectMouseDownLeft(evt);
      } else if (evt.button == 2) {
        // this.selectMouseDownRight(evt);
      }
    }
  };
  selectMouseDownLeft = evt => {
    // console.log('select mouse down left');
    const { clientX, clientY } = getClickPoint(evt);
    if (this.props.drawingState == 2 || this.props.drawingState === 4) {
      const objId = evt.currentTarget.id;
      const moveElement = evt.target;
      this.startTransformDrawObj(objId, moveElement.id, clientX, clientY);
    }
  };
  // selectMouseDownRight = evt => { };
  // 选择后鼠标 移动到 (Move the mouse to)
  selectMouseMove = evt => {
    // console.log('select mouse move');
    if (this.props.drawingState == 2 || this.props.drawingState === 4) {
      var moveElement = evt.target;
      if (constant.isNormalHandle(moveElement.id)) {
        moveElement.style.cursor = constant.CURSOR.CROSSHAIR;
      } else if (
        moveElement.id == constant.ANCHORS_TYPE.ANGLE ||
        moveElement.id === constant.ANCHORS_TYPE.CALLOUTANGLE ||
        moveElement.id === constant.ANCHORS_TYPE.CLOSEANGLE ||
        moveElement.id === constant.ANCHORS_TYPE.PARKING_STALL_ANGLE
      ) {
        moveElement.style.cursor = constant.CURSOR.ANGLE;
        if (this.state.selectmouse == true) {
          this.props.setCursor(constant.CURSOR.ANGLE);
        } else {
          this.props.setCursor(constant.CURSOR.DEFAULT);
        }
      } else if (moveElement.id === constant.ANCHORS_TYPE.STREETTEXTRECT) {
        moveElement.style.cursor = constant.CURSOR.MOVE;
      }
    }
  };
  selectMouseUp = evt => {
    // console.log('select mouse up');
    // if (this.drawObject.currentObject.functype === constant.FUNCTION_TYPE.STREET) {
    //   handleStreetAcross(this.drawObject.currentObjId);
    //   this.setAncyObjList();
    // }
  };
  /**
   *
   * @param {string} objId - shape 的 id
   * @param {string} selectorId - shape selector 的 id
   * @param {number} clientX
   * @param {number} clientY
   */
  startTransformDrawObj(objId, selectorId, clientX, clientY) {
    // console.log(objId);
    // console.log(selectorId);
    const drawObject = this.drawObject;
    this.props.setCurrentState(3);
    drawObject.currentObjId = objId;
    drawObject.currentObject = workData.getObject(objId);
    this.props.setAnchorsType(selectorId);
    drawObject.capturePoint = clientPtToUserPt(clientX, clientY);
    this.props.setCursor(constant.getAnchorPointCursor(selectorId));
  }

  getInfoBtnPosition = () => {
    const selectedData = workData.getSelectObjects();
    if (1 !== selectedData.length) {
      return [0, 0];
    }
    const currentData = selectedData.pop();

    // Image 类型加载到画布上进行异步处理
    if (currentData.functype === FUNCTION_TYPE.IMAGE) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(foo());
        }, 50);
      });
    } else {
      return foo();
    }

    function foo() {
      const selectedNode = document.querySelector(`#all-shapes > #${currentData.operateid}`);
      if (!selectedNode) {
        return [0, 0];
      }
      const selectedBBox = selectedNode.getBBox();
      const bBoxTopRightPoint = new GeomPoint({
        x: selectedBBox.x + selectedBBox.width + LENGTH_TYPE.INFORANGLESPACE,
        y: selectedBBox.y + selectedBBox.height + LENGTH_TYPE.INFORANGLESPACE,
      });
      let segments = [];
      let offset = LANE_WIDTH;
      if (FUNCTION_TYPE.STREETNEW === currentData.functype) {
        segments = currentData.segments;
        offset = LANE_WIDTH * (currentData.components.length / 4 + 0.75);
      } else {
        if (0 === currentData.handlepoint.length) {
          return [0, 0];
        }
        if (FUNCTION_TYPE.DIRT === currentData.functype || FUNCTION_TYPE.GRAVEL === currentData.functype) {
          offset += currentData.lanewidth;
        } else if (FUNCTION_TYPE.PARKINGSTALLS === currentData.functype) {
          offset += currentData.marks.height;
        }
        let xMaxPoint, yMaxPoint;
        currentData.handlepoint.forEach(point => {
          if (!yMaxPoint || yMaxPoint[1] < point[1]) {
            yMaxPoint = point;
          } else if (yMaxPoint[1] === point[1] && yMaxPoint[0] < point[0]) {
            yMaxPoint = point;
          }
          if (!xMaxPoint || xMaxPoint[0] < point[0]) {
            xMaxPoint = point;
          } else if (xMaxPoint[0] === point[0] && xMaxPoint[1] < point[1]) {
            xMaxPoint = point;
          }
        });
        segments = [
          {
            ptStart: {
              x: xMaxPoint[0],
              y: xMaxPoint[1],
            },
            ptStop: {
              x: yMaxPoint[0],
              y: yMaxPoint[1],
            },
            type: 'line',
          },
        ];
      }
      const geomPolyArc = new GeomPolyArc(segments);
      const p = geomPolyArc.getPointClosest(bBoxTopRightPoint);
      // const distance = Math.sqrt(Math.pow(bBoxTopRightPoint.x - p.x, 2) + Math.pow(bBoxTopRightPoint.y - p.y, 2));
      // const cosTheta = (bBoxTopRightPoint.x - p.x) / distance;
      // const sinTheta = (bBoxTopRightPoint.y - p.y) / distance;
      const cosTheta = 0.8;
      const sinTheta = 0.6;
      let result = userPtToClientPt(p.x + offset * cosTheta, p.y + offset * sinTheta);
      const canvasRect = document.querySelector('#road-engine-container #svg').getBoundingClientRect();
      result = [
        Math.max(result[0], canvasRect.left + LENGTH_TYPE.INFORANGLESPACE),
        Math.max(result[1], canvasRect.top + LENGTH_TYPE.INFORANGLESPACE),
      ];
      result = [
        Math.min(result[0], canvasRect.left + canvasRect.width),
        Math.min(result[1], canvasRect.top + canvasRect.height),
      ];
      return result;
    }
  };

  showPropsMenu = () => {
    this.props.setPropsMenuOpen(true);
  };

  hidePropsMenu = () => {
    this.props.setPropsMenuOpen(false);
  };

  onOpenDrawing = status => {
    this.setState({ openDrawing: status });
  };

  render() {
    // props from redux
    const { propsMenuOpen, trackMouse, setMouseOverCanvas, handleCanvasOperation, leafletMap } = this.props;
    const { openDrawing, objList, shadowConfirmVisible, isShowCanvasDropMenu } = this.state;

    let child = null;
    let modalWidth = '';
    let modalHeight = '';
    // if (startBlankDialog) {
    //   child = CreateDialog(startBlankDialog, this.hidePropsMenu);
    //   // modalWidth = '670px';
    //   modalWidth = '500px';
    // } else {
    //   modalWidth = '400px';
    //   modalHeight = '100';

    //   const selectedModal = objList.find(item => {
    //     return item.selectflag === true;
    //   });

    //   // set special modal width
    //   selectedModal && selectedModal.functype === constant.FUNCTION_TYPE.XYMEASUREMENT && (modalWidth = '550px');

    //   selectedModal && selectedModal.functype === constant.FUNCTION_TYPE.STATIONLINE && (modalWidth = '600px');
    // }

    modalWidth = '400px';
    modalHeight = '100';

    const selectedModal = objList.find(item => {
      return item.selectflag === true;
    });

    // set special modal width
    selectedModal && selectedModal.functype === constant.FUNCTION_TYPE.XYMEASUREMENT && (modalWidth = '550px');

    selectedModal && selectedModal.functype === constant.FUNCTION_TYPE.STATIONLINE && (modalWidth = '600px');

    // const showSVG = workData.getUseData().length > 0 || this.props.currentShape || true;
    const showSVG = true;
    let selectedObjects = _.filter(objList, obj => obj.selectflag);
    let infoBtnVisible =
      selectedObjects &&
      selectedObjects.length === 1 &&
      selectedObjects.every(item => {
        return constant.FUNCTION_TYPE.GROUP !== item.functype;
      });
    let infoBtnPosition = this.getInfoBtnPosition();
    return (
      <Hotkeys keyName={KEY_NAMES.join(',')} onKeyUp={this.onKeyUpHandle.bind(this)}>
        {/* TODO: show startup guide wizard */}
        {/*<div style={{ display: showSVG ? 'none' : 'block', height: '100%', marginLeft: '170px' }}>
            <div style={{ padding: '120px 0px', width: '400px', margin: '0 auto' }}>
              <h3 style={{ marginBottom: '20px' }}>Drag an object to start a new diagram</h3>
              <img src={require('@src/assets/images/placeholder.png')} />
              <Divider>or</Divider>
              <Button block type="primary" style={{ marginBottom: '12px', height: '36px' }}>
                Start with a template
              </Button>
              <Button block type="primary" style={{ marginBottom: '12px', height: '36px' }}>
                Start with a map
              </Button>
            </div>
          </div>*/}
        <div
          className="canvas"
          id="canvas"
          onClick={this.onClick}
          onMouseEnter={() => setMouseOverCanvas(true)}
          onMouseLeave={() => setMouseOverCanvas(false)}
          onTouchStart={this.onMouseDown}
          onTouchMove={this.onMouseMove}
          // onTouchCancel={this.onMouseOut}
          onTouchEnd={this.onMouseUp}
          style={{ display: showSVG ? 'block' : 'none' }}
        >
          <DropMenuOnCanvas
            handleCanvasOperation={handleCanvasOperation}
            isShowCanvasDropMenu={isShowCanvasDropMenu}
            setShowCanvasDropMenu={this.setShowCanvasDropMenu}
          >
            <CanvasSvg
              // TODO: remove useless bindings
              onMouseDown={this.onMouseDown}
              onMouseMove={this.onMouseMove}
              onMouseUp={this.onMouseUp}
              onWheelHandle={this.onWheelHandle}
              objList={objList}
              showPropsMenu={this.showPropsMenu}
              shapeClick={this.shapeClick}
              shapeMouseDown={this.shapeMouseDown}
              selectClick={this.selectClick}
              selectMouseDown={this.selectMouseDown}
              selectMouseMove={this.selectMouseMove}
              selectMouseUp={this.selectMouseUp}
            />
          </DropMenuOnCanvas>
        </div>
        {infoBtnVisible && (
          <ModalWin
            // TODO: modal component is modal dialog | props menu modal?
            visible={propsMenuOpen}
          >
            {/* TODO: convert function to component */}
            {createPropsMenu({
              object: selectedObjects[0],
              updateView: this.setAncyObjList,
              hidePropsMenu: this.hidePropsMenu,
            })}
          </ModalWin>
        )}
        <StyleInfo disabled={!infoBtnVisible} position={infoBtnPosition} onClick={this.showPropsMenu} />
        <ShowPropsMenuBtn disabled={!infoBtnVisible} icon="up" onClick={this.showPropsMenu}></ShowPropsMenuBtn>
        {openDrawing && <StopDrawing clickEvent={() => this.stopDrawing(this.drawObject.prevDrawPoint, true)} />}
        {shadowConfirmVisible && (
          <ShadowConfirm
            obj={workData.getObject(this.drawObject.currentObjId)}
            visible={shadowConfirmVisible}
            setVisible={value => this.setState({ shadowConfirmVisible: value })}
          />
        )}
        {trackMouse &&
          ReactDOM.createPortal(<MouseTracker leafletMap={leafletMap} />, document.getElementById(APP_ROOT_EL_ID))}
      </Hotkeys>
    );
  }
}

const mapStateToProps = state => ({
  propsMenuOpen: state.app.propsMenuOpen,
  collapsed: state.app.collapsed,
  selectedSymbolKey: state.symbolBar.selectedSymbolKey,
  trackMouse: state.devOptions.trackMouse && __DEV__,
  isLockingRoads: state.canvas.isLockingRoads,
  drawingShape: state.canvas.drawingShape,
  streetType: state.canvas.streetType,
  clipboard: state.canvas.clipboard,
  latitude: state.map.service.latitude,
  longitude: state.map.service.longitude,
  drawingState: state.drawingStatus.drawingState,
  anchorsType: state.drawingStatus.anchorsType,
  cursor: state.drawingStatus.cursor,
  snapshots: state.snapshots,
});

const mapDispatchToProps = {
  flushWorkData,
  syncWorkData,
  setMouseOverCanvas,
  recordClickStateOnCanvas,
  setPropsMenuOpen,
  loadOptions,
  toggleGrid,
  setDrawingShape,
  selectSymbol,
  setCollapsed,
  updateMousePosition,
  updateLastClickPos,
  initialSnapshots,
  takeSnapshot,
  setCurrentState,
  setAnchorsType,
  setCursor,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SVGRoot);
