import * as React from 'react';

function SvgIconTrash(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fillRule="nonzero" fill="none">
        <path d="M0 0h24v24H0z" />
        <path
          d="M9.273 9.708v8.25a.44.44 0 01-.455.458H7.91a.44.44 0 01-.454-.458v-8.25c0-.133.042-.243.127-.33a.44.44 0 01.327-.128h.91a.44.44 0 01.454.458zm3.636 0v8.25a.44.44 0 01-.455.458h-.909a.44.44 0 01-.454-.458v-8.25a.44.44 0 01.455-.458h.909a.44.44 0 01.454.458zm3.636 0v8.25a.44.44 0 01-.455.458h-.908a.44.44 0 01-.455-.458v-8.25a.44.44 0 01.455-.458h.909a.44.44 0 01.454.458zm1.819 10.37V6.5H5.636v13.578c0 .21.034.404.1.58.066.177.135.306.206.387.07.081.12.122.149.122h11.818c.028 0 .078-.04.15-.122.07-.081.139-.21.205-.387.066-.176.1-.37.1-.58zM8.818 4.667h6.364L14.5 2.99a.4.4 0 00-.241-.158H9.756a.4.4 0 00-.242.158l-.696 1.676zM22 5.125v.917a.44.44 0 01-.455.458h-1.363v13.578c0 .793-.223 1.478-.668 2.055-.445.578-.98.867-1.605.867H6.091c-.625 0-1.16-.28-1.605-.838-.445-.558-.668-1.234-.668-2.027V6.5H2.455A.44.44 0 012 6.042v-.917a.44.44 0 01.455-.458h4.389l.994-2.392c.142-.354.398-.654.767-.903.37-.248.743-.372 1.122-.372h4.546c.379 0 .753.124 1.122.372.37.249.625.55.767.903l.994 2.392h4.39a.44.44 0 01.454.458z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default SvgIconTrash;
