import * as React from 'react';

function SvgIconPathPolygon(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="currentColor" strokeWidth={2}>
          <rect x={2} y={18} width={4} height={4} rx={1} />
          <rect x={2} y={2} width={4} height={4} rx={1} />
          <rect x={18} y={18} width={4} height={4} rx={1} />
          <rect x={13} y={10} width={4} height={4} rx={1} />
          <rect x={18} y={2} width={4} height={4} rx={1} />
          <path d="M18 20H6M4 18V6M6 4h12M20 6l-5 4M15 14l5 4" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconPathPolygon;
