import * as React from 'react';

function SvgIconLadder(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.001 1.001h23.04v23.041H.001z" />
        <path
          d="M19.255 2.603L18.47 4.84H9.945l.56-1.603-1.81-.634-6.72 19.2 1.811.634 1.455-4.157h8.525l-1.231 3.523 1.811.634 6.72-19.2-1.811-.634zM17.799 6.76l-.673 1.92H8.601l.673-1.92h8.525zm-1.344 3.84l-.672 1.92H7.258l.672-1.92h8.525zM5.914 16.36l.67-1.92h8.526l-.67 1.92H5.913z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconLadder;
