import React from 'react';
import {
  FindCircle,
  IsZero,
  LengthBetweenPoints,
  ScalePoint,
  TowPointVerticalLengthByStatus,
  IsUnderLine,
  ByTime,
  GetCircleCenter1,
  GetCenterInTwoPoints,
} from '@src/data/CommonFunc';
import { GetShapes, GetStreetPathD, GetLineHeightPoints } from '@src/data/BusinessFun';
import {
  GetStreetLineBegin,
  GetBikeLaneCount,
  GetStreetMaxPoints,
  GetMaxHeight,
  GetStreetLinePoints,
  GetStreetBeginTopPoint,
  GetStreetEndTopPoint,
  GetStreetLineCount,
} from '@src/actions/StreetFunHandle';
import { GetArcAnchors, getSquareHandlePoint, getTriangleHandlePoint } from '@src/data/AnchorsPoint';
import { GetOffArcLinePath, offArcPoints } from '@src/actions/StreetOffArcFunHandle';
import ArrowLength from '../ArrowLength';
import { GetStreetText } from '@src/actions/ShapeTextHandle';
import { ANCHORS_TYPE, COLOR_TYPE, STREET_LINE_TYPE, STREET_SPACE, THREE_POINT_STATUS_TYPE } from '@src/constant';

class SelectStreetOffset extends React.Component {
  constructor(props) {
    super(props);
    const { object } = this.props;
    this.init(object, true);
  }
  UNSAFE_componentWillReceiveProps({ object }) {
    this.init(object, false);
  }
  init = (object, isFirst) => {
    if (object == null || object.handlepoint.length < 3) return;

    let maxheight = GetMaxHeight(object);
    let maxTopPoints = GetStreetMaxPoints(object, maxheight / 2, true);
    let linepoints = [];
    let beginStreetLine = GetStreetLineBegin(object.groupdata);
    linepoints[beginStreetLine] = maxTopPoints;
    let i = beginStreetLine;
    let lineData = object.groupdata;
    while (i >= 0 && i < lineData.length) {
      let laneHeight = object.lanewidth;
      if (i < object.lanes.length) {
        laneHeight = object.lanes[i].width;
      }
      let bottomLine = lineData[i].bottomline;
      if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.TWOWAY)
      ) {
        laneHeight = laneHeight + STREET_SPACE.TWOWAY;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.TWOWAY) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.TWOWAY, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.PAINTED)
      ) {
        laneHeight = laneHeight + STREET_SPACE.PAINTED;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.PAINTED) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.PAINTED, false);
        }
      } else if (
        lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE ||
        (bottomLine != -1 && lineData[bottomLine].streetlinetype === STREET_LINE_TYPE.WIDE)
      ) {
        laneHeight = laneHeight + STREET_SPACE.WIDE;
        if (lineData[i].streetlinetype === STREET_LINE_TYPE.WIDE) {
          let specialTop = lineData[i].specialtop,
            specialBottom = lineData[i].specialbottom;
          linepoints[specialTop] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, true);
          linepoints[specialBottom] = GetStreetLinePoints(linepoints[i], STREET_SPACE.WIDE, false);
        }
      }
      if (bottomLine != -1) linepoints[bottomLine] = GetStreetLinePoints(linepoints[i], laneHeight, false);
      i = bottomLine;
    }
    let arcPoints = offArcPoints(object.handlepoint, object.offarcheight, object.offarccenter);
    if (isFirst) {
      this.state = {
        object: object,
        maxheight: maxheight,
        linepoints: linepoints, //主要用到该变量 用于 每个道路的 锚点
        arcpoints: arcPoints,
      };
    } else {
      this.setState({
        object: object,
        maxheight: maxheight,
        linepoints: linepoints,
        arcpoints: arcPoints,
      });
    }
  };
  getAnchors = (shapes, object) => {
    let anchors = object.handlepoint;
    shapes.push(getSquareHandlePoint(ANCHORS_TYPE.STREETBEGIN, anchors[3]));
    shapes.push(getSquareHandlePoint(ANCHORS_TYPE.STREETEND, anchors[4]));
    let id = String(1) + '||' + ANCHORS_TYPE.STREETCENTER;
    shapes.push(GetArcAnchors(id, anchors[1]));
    let lineData = object.groupdata;
    let linePoints = this.state.linepoints;
    let i = GetStreetLineBegin(lineData);
    while (i >= 0 && i < lineData.length) {
      let anchorData = linePoints[i];
      let bottomPoints = GetLineHeightPoints(
        linePoints[i][0],
        linePoints[i][1],
        linePoints[i][2],
        object.offarcheight,
        false
      );
      let topPoints = GetLineHeightPoints(
        linePoints[i][0],
        linePoints[i][1],
        linePoints[i][2],
        object.offarcheight,
        true
      );
      let laneHeight = LengthBetweenPoints(linePoints[i][0], anchors[0]);
      let isUnderLine = IsUnderLine(anchors[0], anchors[2], bottomPoints[0]);
      let id =
        String(i) +
        '||' +
        String(0) +
        '||' +
        String(laneHeight) +
        '||' +
        String(isUnderLine) +
        '||' +
        ANCHORS_TYPE.STREETLEFTTOP;
      let beginPointLength = LengthBetweenPoints(linePoints[i][0], bottomPoints[0]);
      let leftScale = 0;
      if (!IsZero(beginPointLength)) {
        leftScale = lineData[i].upstate
          ? (beginPointLength + lineData[i].topleftr) / beginPointLength
          : (beginPointLength - lineData[i].topleftr) / beginPointLength;
        if (isUnderLine) {
          leftScale = lineData[i].upstate
            ? (beginPointLength - lineData[i].topleftr) / beginPointLength
            : (beginPointLength + lineData[i].topleftr) / beginPointLength;
        }
      }
      let leftTopPoint = ScalePoint(bottomPoints[0], leftScale, leftScale, linePoints[i][0]);
      if (IsZero(beginPointLength))
        leftTopPoint = GetStreetBeginTopPoint(lineData[i], anchorData[0], anchorData[1], anchorData[2]);
      if (!IsZero(lineData[i].topleftr))
        shapes.push(<ArrowLength p1={bottomPoints[0]} p2={leftTopPoint} istop={true} key={id + 'arrowlength'} />);
      if (!(GetStreetLineCount(lineData) === 3 && i === 2)) shapes.push(getTriangleHandlePoint(id, leftTopPoint));

      let idEnd =
        String(i) +
        '||' +
        String(2) +
        '||' +
        String(laneHeight) +
        '||' +
        String(isUnderLine) +
        '||' +
        ANCHORS_TYPE.STREETRIGHTTOP;

      let rightScale = 0;
      if (!IsZero(beginPointLength)) {
        rightScale = lineData[i].uprightstate
          ? (beginPointLength + lineData[i].toprightr) / beginPointLength
          : (beginPointLength - lineData[i].toprightr) / beginPointLength;
        let isUnderRight = IsUnderLine(anchors[0], anchors[2], topPoints[2]);
        if (isUnderRight) {
          rightScale = lineData[i].uprightstate
            ? (beginPointLength - lineData[i].toprightr) / beginPointLength
            : (beginPointLength + lineData[i].toprightr) / beginPointLength;
        }
      } else {
      }

      let rightTopPoint = ScalePoint(topPoints[2], rightScale, rightScale, linePoints[i][2]);
      if (IsZero(beginPointLength))
        rightTopPoint = GetStreetEndTopPoint(lineData[i], anchorData[0], anchorData[1], anchorData[2]);
      if (!IsZero(lineData[i].toprightr))
        shapes.push(<ArrowLength p1={topPoints[2]} p2={rightTopPoint} istop={true} key={idEnd + 'arrowlength'} />);
      if (!(GetStreetLineCount(lineData) === 3 && i === 2)) shapes.push(getTriangleHandlePoint(idEnd, rightTopPoint));
      i = lineData[i].bottomline;
    }
    //
  };
  getAnchorsOffArcHeight(shapes) {
    let anchors = this.state.object.handlepoint;
    let offArcHeight = this.state.object.offarcheight;
    let bottomPoints = GetLineHeightPoints(anchors[0], anchors[1], anchors[2], offArcHeight, false);
    let topPoints = GetLineHeightPoints(anchors[0], anchors[1], anchors[2], offArcHeight, true);
    let arcPoints = this.state.arcpoints;
    let circle = FindCircle(anchors[0], anchors[1], anchors[2]);
    if (IsZero(circle[2])) {
      let uppoint = GetCenterInTwoPoints(bottomPoints[0], arcPoints[0]);
      let downpoint = GetCenterInTwoPoints(arcPoints[2], topPoints[2]);
      shapes.push(getTriangleHandlePoint(ANCHORS_TYPE.OFFSETUP, uppoint));
      shapes.push(getTriangleHandlePoint(ANCHORS_TYPE.OFFSETDOWN, downpoint));
    } else {
      let isBytime = ByTime(anchors[0], anchors[1], anchors[2]) === THREE_POINT_STATUS_TYPE.BYTIME;
      let leftCircle = FindCircle(bottomPoints[0], bottomPoints[1], bottomPoints[2]);
      let rightCircle = FindCircle(topPoints[0], topPoints[1], topPoints[2]);
      let uppoint = GetCircleCenter1(leftCircle, bottomPoints[0], arcPoints[0], isBytime);
      let downpoint = GetCircleCenter1(rightCircle, arcPoints[2], topPoints[2], isBytime);
      shapes.push(getTriangleHandlePoint(ANCHORS_TYPE.OFFSETUP, uppoint));
      shapes.push(getTriangleHandlePoint(ANCHORS_TYPE.OFFSETDOWN, downpoint));
    }
    shapes.push(getTriangleHandlePoint(ANCHORS_TYPE.OFFSETCENTER, this.state.arcpoints[1]));
  }
  getCenterLine = (shapes, object, arcPoints) => {
    let D = [];
    let anchors = object.handlepoint;
    GetStreetPathD(D, anchors, 0);
    shapes.push(<path d={D.join(' ')} key="centerline1" />);
    let D1 = [];
    GetOffArcLinePath(D1, object.offarcheight, anchors, arcPoints);
    shapes.push(<path d={D1.join(' ')} key="centerline2" />);
  };
  getTextRect = (shapes, object) => {
    if (object.text.text.length == 0) return;
    let anchors = object.handlepoint;
    let textPoint = object.text.point;
    if (textPoint.length === 0) {
      let center01 = GetCenterInTwoPoints(anchors[0], anchors[1]);
      textPoint = TowPointVerticalLengthByStatus(anchors[0], anchors[1], center01, object.lanewidth / 2, false);
    }
    let textLength = object.text.text.length * object.text.size + 2;
    let textHeight = object.text.size + 2;
    let orginPoint = [textPoint[0] - textLength / 2, textPoint[1] - 4];
    let shape = (
      <g id={ANCHORS_TYPE.STREETTEXTRECT} key={ANCHORS_TYPE.STREETTEXTRECT}>
        <rect
          x={orginPoint[0]}
          y={orginPoint[1]}
          width={textLength}
          height={textHeight}
          fill={COLOR_TYPE.NONE}
          key={'textrect'}
          id={ANCHORS_TYPE.STREETTEXTRECT}
        />
        {GetStreetText(object)}
      </g>
    );
    shapes.push(shape);
  };
  render() {
    const { object } = this.props;
    if (object.handlepoint.length < 3) return null;
    let arcPoints = offArcPoints(object.handlepoint, object.offarcheight, object.offarccenter);
    let shapes = [];
    this.getTextRect(shapes, object);
    this.getAnchors(shapes, object);
    this.getAnchorsOffArcHeight(shapes);
    this.getCenterLine(shapes, object, arcPoints);
    return <g>{GetShapes(shapes)}</g>;
  }
}

export default SelectStreetOffset;
