import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './tab-item.scss';

class TabItem extends Component {
  onSwitchTab = tabKey => {
    const { onSwitchTab } = this.props;
    onSwitchTab(tabKey);
  };

  render() {
    const { tabKey, name } = this.props;

    return (
      <span
        className={`prop-menu-tab-header ${tabKey === name ? 'active' : ''}`}
        onClick={() => this.onSwitchTab(name)}
      >
        {this.props.t(`property.tab.${name}`)}
      </span>
    );
  }
}

export default withTranslation()(TabItem);
