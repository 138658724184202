export const TemplateGroups = function() {
  return [
    { id: '0', name: 'Curve' },
    { id: '1', name: 'Interchange' },
    { id: '2', name: 'Intersection' },
    { id: '3', name: 'Building' },
    { id: '4', name: 'User Defined' },
  ];
};

export const Templates = function() {
  return [
    {
      id: '1',
      groupId: '0',
      groupName: 'Curve',
      name: 'Straight',
      describe: 'Straight',
      src: require('./templates/1.svg'),
    },
    /*
    {
      id: "2",
      groupId: "0",
      groupName: "Curve",
      name: "Curve_1",
      describe: "Curve 1",
      src: "./res/templates/2.svg"
    },
    {
      id: "3",
      groupId: "0",
      groupName: "Curve",
      name: "Curve_2",
      describe: "Curve 2",
      src: "./res/templates/3.svg"
    },
    {
      id: "4",
      groupId: "0",
      groupName: "Curve",
      name: "Offset",
      describe: "Offset",
      src: "./res/templates/4.svg"
    },
    {
      id: "5",
      groupId: "0",
      groupName: "Curve",
      name: "90°",
      describe: "90°",
      src: "./res/templates/5.svg"
    },
    {
      id: "6",
      groupId: "0",
      groupName: "Curve",
      name: "120°",
      describe: "120°",
      src: "./res/templates/6.svg"
    },
    {
      id: "7",
      groupId: "0",
      groupName: "Curve",
      name: "S-Curve_1",
      describe: "S-Curve 1",
      src: "./res/templates/7.svg"
    },
    {
      id: "8",
      groupId: "0",
      groupName: "Curve",
      name: "S-Curve_2",
      describe: "S-Curve 2",
      src: "./res/templates/8.svg"
    },
    {
      id: "9",
      groupId: "0",
      groupName: "Curve",
      name: "Detour1",
      describe: "Detour1",
      src: "./res/templates/9.svg"
    },
    */
    {
      id: '10',
      groupId: '0',
      groupName: 'Curve',
      name: '3_Lane_2_Way',
      describe: '3 Lane 2 Way',
      src: require('./templates/10.svg'),
    },
    /*
    {
      id: "11",
      groupId: "1",
      groupName: "Interchange",
      name: "Short_On_Ramp",
      describe: "Short On Ramp",
      src: "./res/templates/11.svg"
    },
    {
      id: "12",
      groupId: "1",
      groupName: "Interchange",
      name: "Extended_On_Ramp",
      describe: "Extended On Ramp",
      src: "./res/templates/12.svg"
    },
    {
      id: "13",
      groupId: "1",
      groupName: "Interchange",
      name: "Short_Off_Ramp",
      describe: "Short Off Ramp",
      src: "./res/templates/13.svg"
    },
    */
    {
      id: '14',
      groupId: '1',
      groupName: 'Interchange',
      name: 'Extended_Off_Ramp',
      describe: 'Extended Off Ramp',
      src: require('./templates/14.svg'),
    },
    /*
    {
      id: "15",
      groupId: "1",
      groupName: "Interchange",
      name: "Straight_Off_Ramp",
      describe: "Straight Off Ramp",
      src: "./res/templates/15.svg"
    },
    {
      id: "16",
      groupId: "1",
      groupName: "Interchange",
      name: "Y",
      describe: "Y",
      src: "./res/templates/16.svg"
    },
    */
    {
      id: '17',
      groupId: '1',
      groupName: 'Interchange',
      name: '4_Lane_Y',
      describe: '4 Lane Y',
      src: require('./templates/17.svg'),
    },
    /*
    {
      id: "18",
      groupId: "2",
      groupName: "Intersection",
      name: "4-Way",
      describe: "4-Way",
      src: "./res/templates/18.svg"
    },
    {
      id: "19",
      groupId: "2",
      groupName: "Intersection",
      name: "T_Intersection",
      describe: "T intersection",
      src: "./res/templates/19.svg"
    },
    */
    {
      id: '20',
      groupId: '2',
      groupName: 'Intersection',
      name: '5-Way',
      describe: '5-Way',
      src: require('./templates/20.svg'),
    },
    /*
    {
      id: "21",
      groupId: "2",
      groupName: "Intersection",
      name: "5_Lane_4-Way",
      describe: "5 Lane 4-Way",
      src: "./res/templates/21.svg"
    },
    {
      id: "22",
      groupId: "2",
      groupName: "Intersection",
      name: "Turn_Bay_1",
      describe: "Turn Bay 1",
      src: "./res/templates/22.svg"
    },
    {
      id: "23",
      groupId: "2",
      groupName: "Intersection",
      name: "Turn_Bay_2",
      describe: "Turn Bay 2",
      src: "./res/templates/23.svg"
    },
    {
      id: "24",
      groupId: "2",
      groupName: "Intersection",
      name: "4-Way_Offset",
      describe: "4-Way Offset",
      src: "./res/templates/24.svg"
    },
    {
      id: "25",
      groupId: "2",
      groupName: "Intersection",
      name: "4-Way_Offset_2",
      describe: "4-Way Offset 2",
      src: "./res/templates/25.svg"
    },
    */
    {
      id: '26',
      groupId: '2',
      groupName: 'Intersection',
      name: '4-Way_with_Turn_Lanes',
      describe: '4-Way with Turn Lanes',
      src: require('./templates/26.svg'),
    },
    /*
    {
      id: "27",
      groupId: "2",
      groupName: "Intersection",
      name: "Roundabout",
      describe: "Roundabout",
      src: "./res/templates/27.svg"
    },
    {
      id: "28",
      groupId: "2",
      groupName: "Intersection",
      name: "Divided_with_Crossover",
      describe: "Divided with Crossover",
      src: "./res/templates/28.svg"
    },
    {
      id: "29",
      groupId: "3",
      groupName: "Structures",
      name: "30'_x_30'",
      describe: "30' x 30'",
      src: "./res/templates/29.svg"
    },
    {
      id: "30",
      groupId: "3",
      groupName: "Structures",
      name: "30'_x_40'",
      describe: "30' x 40'",
      src: "./res/templates/30.svg"
    },
    {
      id: "31",
      groupId: "3",
      groupName: "Structures",
      name: "30'_x_50'",
      describe: "30' x 50'",
      src: "./res/templates/31.svg"
    },
    {
      id: "32",
      groupId: "3",
      groupName: "Structures",
      name: "14'_x_50'",
      describe: "14' x 50'",
      src: "./res/templates/32.svg"
    },
    {
      id: "33",
      groupId: "3",
      groupName: "Structures",
      name: "30'_x_50",
      describe: "30' x 50",
      src: "./res/templates/33.svg"
    },
    {
      id: "34",
      groupId: "3",
      groupName: "Structures",
      name: "3_Rooms",
      describe: "3 Rooms",
      src: "./res/templates/34.svg"
    },
    {
      id: "35",
      groupId: "3",
      groupName: "Structures",
      name: "4_Rooms",
      describe: "4 Rooms",
      src: "./res/templates/35.svg"
    },
    {
      id: "36",
      groupId: "3",
      groupName: "Structures",
      name: "30'_x_30'",
      describe: "30' x 30'",
      src: "./res/templates/36.svg"
    },
    {
      id: "37",
      groupId: "3",
      groupName: "Structures",
      name: "30'_x_30'",
      describe: "30' x 30'",
      src: "./res/templates/37.svg"
    }
    */
  ];
};
