/**
 * This file defines indexedDB stores, and exports the database instance
 */

import Dexie from 'dexie';
import 'dexie-observable';
import { appName } from '../../environment';
import { getTranslationData } from './source';

export const FAVORITE_STORE = 'favorite';
export const SETTING_STORE = 'setting';
export const LANGUAGE_STORE = 'language';
export const SYMBOL_STORE = 'symbol';

/**
 * Template resource that stores: a) built-in templates, b) user-defined templates, c) ethos templates
 * source: 'default' -> built-in
 * source: 'user' -> user-defined
 * source: 'ethos' -> Ethos
 */
export const TEMPLATE_STORE = 'template';

export const settingKeys = {
  /**
   * Language preference
   */
  LANGUAGE: 'lang',
  /**
   * Built-in template meta including version, and `updatedAt` means timestamp of last sync with Ethos via API
   */
  TEMPLATE: 'template',
  /**
   * Built-in symbol meta including version
   */
  SYMBOL: 'symbol',
};

// after modifying built-in resource data, make sure to increase version numbers, this is used to determine if we need to reload data.
const templateVersion = 2;
const symbolVersion = 2;
const languageVersion = 4;

export const defaultSettings = [
  { key: settingKeys.TEMPLATE, version: templateVersion, updatedAt: 0 },
  { key: settingKeys.SYMBOL, version: symbolVersion, updatedAt: 0 },
  { key: settingKeys.LANGUAGE, version: languageVersion, value: 'en-US' },
];

// actual database version number will be multiplied by 10.
const db = new Dexie(appName);

// // NOTICE: upgrade of primary key definition is not supported at this moment
// // https://github.com/dfahlander/Dexie.js/issues/781

// version: 10
db.version(1).stores({
  [FAVORITE_STORE]: '&key',
  [LANGUAGE_STORE]: '&key',
  [SETTING_STORE]: '&key',
  // unique key
  [SYMBOL_STORE]: '&key',
  // unique key or uuid
  [TEMPLATE_STORE]: '&key',
});

db.version(2).stores({
  // source is used in query for SYMBOL_STORE so we need to index it
  [SYMBOL_STORE]: '&key,source',
});

// run once, https://dexie.org/docs/Dexie/Dexie.on.ready
db.on('ready', async () => {
  const settingCount = await db[SETTING_STORE].count();
  if (!settingCount) {
    await db[SETTING_STORE].bulkAdd(defaultSettings);
  }

  const languageSetting = await db[SETTING_STORE].get(settingKeys.LANGUAGE);
  if (languageSetting && languageSetting.version !== languageVersion) {
    await db[LANGUAGE_STORE].clear();
    await db[SETTING_STORE].update(settingKeys.LANGUAGE, { version: languageVersion });
  }
  const languageCount = await db[LANGUAGE_STORE].count();
  if (!languageCount) {
    const languages = getTranslationData();
    await db[LANGUAGE_STORE].bulkAdd(languages);
  }
});

export default db;
