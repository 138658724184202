import React from 'react';
import { Tabs, Icon } from 'antd';
import TabItem from '@src/components/PropMenu/TabItem';
import emitter from '@src/data/Event';
import { ROAD_CONSTANT } from '@src/type/event';
import * as workData from '@src/data/WorkData';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';
import LaneMenu from '@src/components/Modal/Menu/LaneMenu';

export default class CurbReturnModal extends React.Component {
  // Modal menu and items
  menu = {
    style: ['hide'],
  };

  state = {
    object: this.props.object,
    tabKey: 'style',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
    });
  }

  handleHide = () => {
    var obj = this.state.object;
    obj.style.strokeopacity = 0 === obj.style.strokeopacity ? 1 : 0;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'lane' in this.menu && (
            <Tabs.TabPane tab="lane" key="lane">
              <LaneMenu
                item={this.menu.lane}
                stateObject={this.state.object}
                stallWidthHandle={this.stallWidthHandle}
                stallLength={this.stallLength}
              />
            </Tabs.TabPane>
          )}
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
                wallWidth={this.state.strokewidth}
                handleDirection={this.showDirection}
                handleTextbox={this.handleTextbox}
                handleSnapToStreet={this.handleSnapToStreet}
                handleHide={this.handleHide}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                // width={this.state.width}
                // height={this.state.height}
                // radius={this.state.radius}
                // angle={this.state.angle}
                // handlewidth={this.handlewidth}
                // handleheight={this.handleheight}
                // handleradius={this.handleradius}
                // handleAngle={this.handleAngle}
                // handlemarkslength={this.handlemarkslength}
                // pointWidth={this.state.pointwidth}
                // handlePointWidth={this.handlePointWidth}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
