import { ADD_LANE, SET_MEASUREMENT_POINTS_OPEN, SET_CSV_PREVIEW_OPEN, SET_CSV_DATA } from '@src/type/propMenu';

/**
 * add a street lane
 * @param {string} name e.g. Add a Lane
 * @param {string} position top|bottom|left|right
 */
export const addLane = (name, position) => ({
  type: ADD_LANE,
  name,
  position,
});

/**
 * Set modal status to be open or close
 * @param {boolean} value
 */
export const setMeasurementPointsOpen = value => ({
  type: SET_MEASUREMENT_POINTS_OPEN,
  value,
});

export const setCSVPreviewOpen = value => ({
  type: SET_CSV_PREVIEW_OPEN,
  value,
});

export const setCSVData = data => ({
  type: SET_CSV_DATA,
  data,
});
