import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Spin } from 'antd';
import { IconInterfaceLoading } from '@src/components/UI/Icons';
import { APP_ROOT_EL_ID } from '@src/constant';
import { connect } from 'react-redux';

class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.position = 'absolute';
    this.el.style.top = 0;
    this.el.style.left = 0;
    this.el.style.zIndex = 1001;
    // this.el.style.fontSize = '10em';
    this.el.style.width = '100vw';
    this.el.style.height = '100vh';
    this.el.style.background = 'var(--color-loading-mask)';
  }
  componentDidMount() {
    let overlay = document.getElementById(APP_ROOT_EL_ID);
    overlay && overlay.appendChild(this.el);
  }
  componentWillUnmount() {
    let overlay = document.getElementById(APP_ROOT_EL_ID);
    overlay && overlay.removeChild(this.el);
  }
  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const StyledLncdSpin = styled.div`
  ${props =>
    props.center === true &&
    `
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: inherit;
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  i {
    color: ${props => (props.isPreload ? 'rgb(34, 80, 122)' : 'var(--color-loading-spin)')};
    animation: rotate 1.25s infinite linear;
  }

  .progress-bar {
    border: 1px solid var(--color-loading-spin);
    width: 200px;
    height: 10px;
  }

  a {
    color: var(--color-loading-spin);
    font-size: 1.5em;
    &:hover {
      color: darken(var(--color-loading-spin), 30%);
    }
  }

  @keyframes rotate {
    0% {
      -webkit-animation-timing-function: steps(12, end);
      -moz-animation-timing-function: steps(12, end);
      -ms-animation-timing-function: steps(12, end);
      -o-animation-timing-function: steps(12, end);
      animation-timing-function: steps(12, end);
    }

    100% {
      transform: rotate(360deg);
      transform-origin: center;
    }
  }
`;

function LncdSpin({ size, center, global, style, preload, showProgress, progressCurrent, progressTotal, onCancel }) {
  const [percent, setPercent] = useState(100 * (progressCurrent / progressTotal));
  useEffect(() => {
    setPercent(100 * (progressCurrent / progressTotal));
  }, [progressCurrent, progressTotal]);
  const spin = (
    <StyledLncdSpin style={style} center={center} isPreload={preload}>
      <div>
        <Spin indicator={<Icon component={IconInterfaceLoading} style={{ fontSize: size ? size : 48 }} />} />
        {showProgress && (
          <>
            <div
              className="progress-bar"
              style={{
                background: `
                linear-gradient(
                  90deg,
                  var(--color-loading-spin) 0% ${percent}%,
                  transparent ${percent}% 100%
                )
              `,
              }}
            />
            <a onClick={onCancel}>Cancel</a>
          </>
        )}
      </div>
    </StyledLncdSpin>
  );
  return global ? <Overlay>{spin}</Overlay> : spin;
}

LncdSpin.propTypes = {
  style: PropTypes.object,
  center: PropTypes.bool,
  global: PropTypes.bool,
  size: PropTypes.number,
  preload: PropTypes.bool,
  showProgress: PropTypes.bool,
  progressCurrent: PropTypes.number,
  progressTotal: PropTypes.number,
  onCancel: PropTypes.func,
};

const mapStateToProps = state => ({
  showProgress: state.app.showProgress,
  progressCurrent: state.app.progressCurrent,
  progressTotal: state.app.progressTotal,
});

export default connect(
  mapStateToProps,
  null
)(LncdSpin);
