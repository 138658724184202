import {
  ON_WINDOW_RESIZE,
  SET_COLLAPSED,
  SET_HEADER_HEIGHT,
  SET_PROPS_MENU_OPEN,
  TOGGLE_NIGHT_MODE,
  SET_LOADING,
  SET_APP_DEVICE,
  SET_SHOW_PROGRESS,
  SET_PROGRESS_TOTAL,
  SET_PROGRESS_CURRENT,
} from '@src/type/app';
import { SET_PIN } from '@src/type/sidebar';
import { SET_MEASUREMENT_POINTS_OPEN } from '@src/type/propMenu';

export const setHeaderHeight = value => ({
  type: SET_HEADER_HEIGHT,
  value,
});

export const onWindowResize = ({ width, height }) => (dispatch, getState) => {
  // 大尺寸屏幕左侧扩展栏始终为展开状态
  if (width > 768) {
    dispatch({
      type: SET_PIN,
      pin: true,
    });
  }
  dispatch({
    type: ON_WINDOW_RESIZE,
    width,
    height,
  });
};

export const setPropsMenuOpen = value => dispatch => {
  dispatch({
    type: SET_PROPS_MENU_OPEN,
    value,
  });
  // reset measurement points modal open status
  dispatch({ type: SET_MEASUREMENT_POINTS_OPEN, value: false });
};

export const setCollapsed = value => (dispatch, getState) => {
  if (!getState().sidebar.pin) {
    dispatch({
      type: SET_COLLAPSED,
      value,
    });
  }
};

export const toggleNightMode = () => ({
  type: TOGGLE_NIGHT_MODE,
});

export const setGlobalLoading = loading => ({
  type: SET_LOADING,
  loading,
});

export const setAppDevice = device => ({
  type: SET_APP_DEVICE,
  device,
});

export const setShowProgress = show => ({
  type: SET_SHOW_PROGRESS,
  show,
});

export const setProgressTotal = total => ({
  type: SET_PROGRESS_TOTAL,
  total,
});

export const setProgressCurrent = current => ({
  type: SET_PROGRESS_CURRENT,
  current,
});
