import * as React from 'react';

function SvgIconZoomFit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.056.005h24v23.04h-24z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M7.473 20.25h1.833v-5.5h-5.5v1.833h2.37l-4.851 4.852 1.296 1.296 4.852-4.852zM21.491 1.269L16.64 6.12V3.75h-1.834v5.5h5.5V7.417h-2.37l4.852-4.852zM20.306 16.583V14.75h-5.5v5.5h1.834v-2.37l4.851 4.851 1.297-1.296-4.852-4.852zM3.806 9.25h5.5v-5.5H7.473v2.37L2.62 1.27 1.325 2.565l4.852 4.852h-2.37z" />
          <path d="M12.056 15.667A3.672 3.672 0 0015.723 12a3.672 3.672 0 00-3.667-3.667A3.672 3.672 0 008.39 12a3.672 3.672 0 003.666 3.667zm0-5.5a1.834 1.834 0 11-.001 3.668 1.834 1.834 0 01.001-3.668z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconZoomFit;
