class StripeHiddenSegment {
  startOffset;
  startPct;
  stopOffset;
  stopPct;

  /**
   *
   * @param {number} startOffset
   * @param {number} stopOffset
   * @param {number} length
   */
  constructor(startOffset, stopOffset, length) {
    this.startOffset = startOffset;
    this.startPct = startOffset / length;
    this.stopOffset = stopOffset;
    this.stopPct = stopOffset / length;
  }

  /**
   * 延伸
   * @param {number} stopOffset
   * @param {number} length
   */
  extend(stopOffset, length) {
    this.stopOffset = stopOffset;
    this.stopPct = stopOffset / length;
  }
}

export default StripeHiddenSegment;
