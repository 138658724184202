import { ImportImg } from '@src/actions/ManageDiagram';
import { message } from '@src/components/Message';
import CustomInputNumber from '@src/components/Modal/Element/CustomInputNumber';
import { APP_ROOT_EL_ID, MANAGE_FUNC_TYPE } from '@src/constant';
import { withTemplateContext } from '@src/contexts';
import emitter from '@src/data/Event';
import * as workData from '@src/data/WorkData';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import {
  crossAddUseDataIntoWorkData,
  getFileNameFromFullName,
  getShapeBoxCenterFromHtml,
  readFileAsDataUrl,
  readFileContent,
  readFileMetadataFromContent,
  reGenerateOperateIdForUseData,
  rotateDataPoint,
} from '@src/utils';
import { Button, Card, Collapse, Icon, Input, Modal, Tabs, Tooltip } from 'antd';
import escapeStringRegexp from 'escape-string-regexp';
import _ from 'lodash';
import React, { createRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import LncdSpin from '../LncdSpin';
import './template.scss';
import TemplateGroup from './TemplateGroup';
import TemplatePreview from './TemplatePreview';
import TemplateSaveModal from './TemplateSaveModal';
import TooltipWrapper from '@src/components/TooltipWrapper';
import TouchScroll from '@src/components/TouchDevice/TouchScroll';

function TemplateItem({ t, title, template, group, selectedKey, selectTemplate, draggable, dragStart, dragEnd }) {
  const content = (
    <Card
      draggable={draggable}
      onDragStart={() => dragStart(template.key)}
      onDragEnd={dragEnd}
      hoverable
      className={['template-card', template.key === selectedKey && 'template-card-active']}
      onClick={() => selectTemplate(template.key)}
      cover={<TemplatePreview src={template.src} contentType={template.contentType} />}
      bodyStyle={{ padding: '6px 4px', textAlign: 'center' }}
    >
      <Card.Meta
        title={<span style={{ fontSize: '10px' }}>{t(`template.template.${template.key}`, template.name)}</span>}
      />
    </Card>
  );

  if (group === 'undefined' || template.group === group) {
    return (
      <div style={{ padding: '5px', float: 'left' }}>
        <TooltipWrapper title={title} placement="bottom">
          {content}
        </TooltipWrapper>
      </div>
    );
  } else {
    return null;
  }
}

function TemplateEditButtons({
  t,
  editing,
  template,
  handleInsert,
  onCancel,
  changeMode,
  updateTemplate,
  deleteTemplate,
}) {
  return !editing ? (
    <div style={{ marginBottom: '10px' }}>
      <Button type="primary" style={{ marginRight: '5px', width: '89px' }} onClick={handleInsert}>
        {t('template.listModal.insertButtonText')}
      </Button>
      <Button type="ghost" style={{ marginRight: '5px', width: '89px' }} onClick={onCancel}>
        {t('template.listModal.cancelButtonText')}
      </Button>
      {template && 'userDefined' === template.type && (
        <TooltipWrapper placement="bottom" title={t(`template.listModal.editButtonText`)}>
          <Button type="ghost" icon="edit" onClick={() => changeMode(true)}></Button>
        </TooltipWrapper>
      )}
    </div>
  ) : (
    <div style={{ marginBottom: '10px' }}>
      <Button type="primary" style={{ marginRight: '5px', width: '89px' }} onClick={updateTemplate}>
        {t('template.listModal.saveButtonText')}
      </Button>
      <Button type="ghost" style={{ marginRight: '5px', width: '89px' }} onClick={() => changeMode(false)}>
        {t('template.listModal.cancelButtonText')}
      </Button>
      <TooltipWrapper placement="bottom" title={t(`template.listModal.deleteButtonText`)}>
        <Button type="ghost" icon="delete" onClick={() => deleteTemplate()}></Button>
      </TooltipWrapper>
    </div>
  );
}

class TemplateModal extends React.Component {
  tabs = ['all', 'interchange', 'intersection', 'roomFloor', 'userDefined'];

  drageImage;

  state = {
    type: 'all',
    group: '',
    filter: '',
    editing: false,

    // currently editing/selected
    template: null,

    isLoadingTemplate: false,
    // filtered templates in selected type
    templates: [],
    // store user defined template groups
    templateGroups: [],

    templateSaveModalVisiable: false,
    file: null,
    dragOverGroup: null,
  };

  constructor(props) {
    super(props);
    this.fileInputRef = createRef();
  }

  get selectedKey() {
    return this.state.template ? this.state.template.key : undefined;
  }

  componentDidMount() {
    this.onTemplateLoaded();

    this.setDragImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.templates !== this.props.templates) this.onTemplateLoaded();
  }

  onTemplateLoaded() {
    // TODO: in rare cases, when the user added a new group, then the templates are updated, the added group might be flushed
    let templateGroups = this.props.templates
      .filter(template => 'userDefined' === template.type)
      .map(template => template.group);

    templateGroups = _.uniq(templateGroups).sort((a, b) => {
      // TODO: use constant
      // TODO: the order of named groups
      if ('Untitled Group' === a) {
        return -1;
      }
      if ('Untitled Group' === b) {
        return 1;
      }
    });

    this.setState(
      {
        templateGroups,
      },
      this.filterTemplates
    );
  }

  filterTemplates() {
    const { type, filter } = this.state;
    let templates = _.cloneDeep(this.props.templates).filter(template => 'all' === type || template.type === type);

    if (filter) {
      const regular = new RegExp(escapeStringRegexp(filter), 'i');
      templates = templates.filter(template => regular.test(template.name));
    }

    templates = templates.sort((a, b) => {
      if (a.type === 'userDefined') {
        return 1;
      }
      if (b.type === 'userDefined') {
        return -1;
      }
      return 1;
    });

    let template = this.state.template;
    if (!template || templates.indexOf(template) === -1) {
      template = templates.length > 0 ? templates[0] : null;
    }

    this.setState({
      templates,
      template,
      editing: false,
    });
  }

  setDragImage() {
    this.drageImage = new Image();
    this.drageImage.width = 20;
    this.drageImage.src = require('@src/assets/images/drag-image.png');
  }

  // change template type
  changeTab = type => {
    this.setState({ type }, this.filterTemplates);
  };

  searchTemplate = event => this.setState({ filter: event.target.value }, this.filterTemplates);

  selectTemplate = key => {
    this.setState({
      editing: false,
      template: this.state.templates.find(n => n.key === key),
    });
  };

  handleInsert = () => {
    if (this.state.template) {
      if (0 === workData.getUseData().length) {
        this.handleInsertAction();
      } else {
        Modal.confirm({
          title: this.props.t('template.listModal.insertTemplateGiveUpCurrentDraft'),
          okText: this.props.t('menu.settingModal.okButtonText'),
          getContainer: () => document.getElementById(APP_ROOT_EL_ID),
          onOk: () => {
            workData.getUseData().length = 0;
            this.props.clearMap();
            this.handleInsertAction();
          },
        });
      }
    } else {
      message.error(this.props.t('template.listModal.insertTemplateRequired'));
    }
  };

  handleInsertAction = () => {
    const radianAngle = (-Math.PI * (this.state.template.angle || 0)) / 180;
    const { contentType, useData, src } = this.state.template;
    if ('lncdSvg' === contentType) {
      const parsedUseData = JSON.parse(useData);
      const rotateCenter = getShapeBoxCenterFromHtml(src);
      rotateDataPoint(parsedUseData, radianAngle, rotateCenter);
      reGenerateOperateIdForUseData(parsedUseData);
      crossAddUseDataIntoWorkData(parsedUseData);
      // if (workData.hasOnlyOneObject(parsedUseData)) {
      //   crossAddUseDataIntoWorkData(parsedUseData);
      // } else {
      //   GroupShapes(parsedUseData);
      // }
    } else {
      ImportImg(src);
    }
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.onCancel();
  };

  // add a new group
  addOption = value =>
    this.setState({
      templateGroups: [...this.state.templateGroups, value],
      template: {
        ...this.state.template,
        group: value,
      },
    });

  changeTemplateGroup = value =>
    this.setState({
      template: {
        ...this.state.template,
        group: value,
      },
    });

  changeName = event =>
    this.setState({
      template: {
        ...this.state.template,
        name: event.target.value,
      },
    });

  changeAngle = angle =>
    this.setState({
      template: {
        ...this.state.template,
        angle,
      },
    });

  changeMode = editing => {
    if (editing && !this.state.template) {
      message.error(this.props.t('template.listModal.selectedTemplateRequiredToEdit'));
    } else {
      this.setState({ editing });
    }
  };

  deleteTemplate = () => {
    Modal.confirm({
      title: this.props.t('template.listModal.deleteTemplateConfirm'),
      okText: this.props.t('menu.settingModal.okButtonText'),
      getContainer: () => document.getElementById(APP_ROOT_EL_ID),
      onOk: () => {
        const { key } = this.state.template;
        this.props.removeTemplate(key).then(() => {
          this.setState({
            templates: this.state.templates.filter(n => n.key !== key),
            // unset template in preview
            template: null,
          });
        });
      },
    });
  };

  updateTemplate = () => {
    this.setState({ editing: false }, () => {
      this.props.editTemplate(this.state.template).then(() => {
        message.success(this.props.t('template.listModal.templateUpdatedSuccess'));
      });
    });
  };

  selectTemplateFromDisk = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = '';
      this.fileInputRef.current.click();
    }
  };

  openTemplateSaveModal = event => {
    if (0 === event.target.files.length) {
      return message.error(this.props.t('template.listModal.loadFileRequired'));
    }
    const allowedFileType = ['image/svg+xml', 'image/jpeg', 'image/png'];
    // FIXME: use same logic for batch upload and single
    if (1 === event.target.files.length) {
      if (!allowedFileType.includes(event.target.files[0].type)) {
        return message.error(this.props.t('template.listModal.loadFileFormatIncorrect'));
      }
      this.setState({
        templateSaveModalVisiable: true,
        file: event.target.files[0],
      });
    } else {
      const saveMultipleFilePromise = [];
      for (let i = 0, len = event.target.files.length; i < len; i++) {
        switch (event.target.files[i].type) {
          case 'image/svg+xml':
            saveMultipleFilePromise.push(this.saveLncdFilePromise(event.target.files[i]));
            break;
          case 'image/jpeg':
          case 'image/png':
            saveMultipleFilePromise.push(this.saveOtherFilePromise(event.target.files[i]));
            break;
          default:
            message.error(
              this.props.t('template.listModal.fileRealTypeIncorrect', { fileName: event.target.files[i].name })
            );
        }
      }
      Promise.all(saveMultipleFilePromise).then(result =>
        message.success(this.props.t('template.listModal.multipleLoadFilesSuccess', { fileNumber: result.length }))
      );
    }
  };

  saveLncdFilePromise = file => {
    return readFileContent(file).then(content => {
      const metadata = readFileMetadataFromContent(content);
      if (metadata) {
        return this.props.saveTemplate({
          name: file.name,
          type: 'userDefined',
          group: 'Untitled Group',
          angle: 0,
          src: content,
          useData: JSON.stringify(metadata.useData),
          mapData: {},
          contentType: 'lncdSvg',
          source: 'user',
          key: uuid(),
          templateStatus: 'pendingUpload',
        });
      } else {
        return this.saveOtherFilePromise(file);
      }
    });
  };

  // TODO: refactor and remove duplicated code with saveLncdFilePromise
  saveOtherFilePromise = file => {
    return readFileAsDataUrl(file).then(result => {
      this.setState({
        templateName: getFileNameFromFullName(file.name),
        templateSrc: result,
        templateUseData: null,
        contentType: 'image',
      });
      return this.props.saveTemplate({
        name: getFileNameFromFullName(file.name),
        type: 'userDefined',
        group: 'Untitled Group',
        angle: 0,
        src: result,
        useData: null,
        mapData: {},
        contentType: 'image',
        source: 'user',
        key: uuid(),
        templateStatus: 'pendingUpload',
      });
    });
  };

  closeTemplateSaveModal = () => {
    this.setState({
      templateSaveModalVisiable: false,
    });
  };

  dragStart = (key, event) => {
    event.dataTransfer.setDragImage(this.drageImage, 10, 10);
    this.setState({
      editing: false,
      template: this.state.templates.find(n => n.key === key),
    });
  };

  setDragOverGroup = dragOverGroup => {
    if (dragOverGroup !== this.state.dragOverGroup) {
      this.setState({
        dragOverGroup,
      });
    }
  };

  dragEnd = () => {
    if (this.state.dragOverGroup !== this.state.template.group) {
      this.props
        .editTemplate({
          ...this.state.template,
          id: this.state.template.key,
          group: this.state.dragOverGroup,
        })
        .then(() => {
          message.success(this.props.t('template.listModal.templateUpdatedSuccess'));
        });
    }
  };

  getGroupName = group => ('Untitled Group' === group ? this.props.t('template.listModal.unTitledGroupName') : group);

  render() {
    const { templates, filter, template } = this.state;

    return (
      <Modal
        visible={true}
        width={800}
        footer={null}
        onCancel={this.props.onCancel}
        bodyStyle={{ padding: '0px 24px 24px' }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div>
          <div className="template-header">
            <Tabs type="card" style={{ userSelect: 'none', flex: '1 1 0%' }} onChange={this.changeTab}>
              {this.tabs.map(tab => (
                <Tabs.TabPane tab={this.props.t(`template.tabType.${tab}`)} key={tab}></Tabs.TabPane>
              ))}
            </Tabs>
            <div
              style={{
                flex: '0.5 1 0%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Button type="ghost" onClick={this.selectTemplateFromDisk}>
                {this.props.t('template.listModal.loadTemplateButtonText')}
              </Button>
              <input
                type="file"
                multiple
                style={{ display: 'none' }}
                id="lncd-load-template"
                ref={this.fileInputRef}
                onChange={this.openTemplateSaveModal}
              />
              {this.state.templateSaveModalVisiable && (
                <TemplateSaveModal
                  source="file"
                  file={this.state.file}
                  visible={this.state.templateSaveModalVisiable}
                  closeModal={this.closeTemplateSaveModal}
                />
              )}
            </div>
          </div>
          <div className="template-body">
            <div className="template-left-box">
              <Input
                placeholder={this.props.t('template.listModal.searchPlaceholder')}
                className="template-left-box-search"
                onChange={this.searchTemplate}
                prefix={
                  <Icon
                    type="search"
                    style={{ fontSize: '16px', marginRight: '10px' }}
                    className="custom-night-mode-text"
                  />
                }
              />
              <TouchScroll className="template-left-box-content">
                {this.state.isLoadingTemplate ? (
                  <LncdSpin center />
                ) : (
                  <React.Fragment>
                    {'userDefined' === this.state.type ? (
                      <Collapse defaultActiveKey={this.state.templateGroups}>
                        {this.state.templateGroups.map(group => (
                          <Collapse.Panel header={this.getGroupName(group)} key={group}>
                            <div onDragOver={this.setDragOverGroup.bind(this, group)}>
                              {templates.map((template, index) => {
                                return (
                                  <TemplateItem
                                    key={index}
                                    t={this.props.t}
                                    title={<span style={{ fontSize: '10px' }}>{template.name}</span>}
                                    template={template}
                                    group={group}
                                    selectedKey={this.selectedKey}
                                    selectTemplate={this.selectTemplate}
                                    draggable="true"
                                    onDragStart={this.dragStart}
                                    onDragEnd={this.dragEnd}
                                  />
                                );
                              })}
                              <div style={{ clear: 'both' }}></div>
                            </div>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    ) : (
                      <div style={{ padding: '5px' }}>
                        {templates.map((template, index) => {
                          return (
                            <TemplateItem
                              key={index}
                              t={this.props.t}
                              title={
                                <span style={{ fontSize: '10px' }}>
                                  {this.props.t(`template.template.${template.key}`, template.name)}
                                </span>
                              }
                              template={template}
                              selectedKey={this.selectedKey}
                              selectTemplate={this.selectTemplate}
                            />
                          );
                        })}
                      </div>
                    )}
                  </React.Fragment>
                )}
                <div style={{ clear: 'both' }}></div>
              </TouchScroll>
            </div>
            <div className="template-right-box">
              <div style={{ marginBottom: '5px' }}>
                <label
                  style={{ lineHeight: '36px', margin: '0px', fontWeight: 'bold', position: 'relative', zIndex: 1 }}
                >
                  {this.props.t('template.listModal.previewLabel')}
                </label>
                <div
                  style={{
                    width: '220px',
                    height: '200px',
                    lineHeight: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {this.state.template && (
                    <div
                      style={{
                        overflow: 'hidden',
                        height: '100%',
                        transform: `rotate(${this.state.template.angle || 0}deg)`,
                      }}
                    >
                      {<TemplatePreview src={this.state.template.src} contentType={this.state.template.contentType} />}
                    </div>
                  )}
                </div>
              </div>
              {this.state.template && this.state.template.src && (
                <div style={{ marginBottom: '5px' }}>
                  <label style={{ lineHeight: '28px', fontWeight: 'bold', display: 'block' }}>
                    {this.props.t('template.listModal.nameLabel')}
                  </label>
                  <Input
                    value={
                      'userDefined' === this.state.template.type
                        ? this.state.template.name
                        : this.props.t(`template.template.${this.selectedKey}`, this.state.template.name)
                    }
                    style={{ width: '100%', height: '32px' }}
                    disabled={!this.state.editing || this.state.template.type !== 'userDefined'}
                    onChange={this.changeName}
                  />
                </div>
              )}
              {this.state.template && 'userDefined' === this.state.template.type && (
                <div style={{ marginBottom: '5px' }}>
                  <TemplateGroup
                    value={this.state.template.group}
                    options={this.state.templateGroups}
                    addOption={this.addOption}
                    onSelect={this.changeTemplateGroup}
                    disabled={!this.state.editing || this.state.template.type !== 'userDefined'}
                  />
                </div>
              )}
              {this.state.template && this.state.template.src && (
                <div style={{ marginBottom: '5px' }}>
                  <label style={{ lineHeight: '28px', fontWeight: 'bold', display: 'block' }}>
                    {this.props.t('template.listModal.angleLabel')}
                  </label>
                  <CustomInputNumber
                    value={this.state.template.angle || 0}
                    style={{ width: '100%', height: '32px' }}
                    min={-360}
                    max={360}
                    formatter={'°'}
                    parser={'°'}
                    onChangeValue={this.changeAngle}
                  />
                </div>
              )}
              <div style={{ flex: 1 }}></div>
              <TemplateEditButtons
                t={this.props.t}
                editing={this.state.editing}
                template={this.state.template}
                handleInsert={this.handleInsert}
                onCancel={this.props.onCancel}
                changeMode={this.changeMode}
                updateTemplate={this.updateTemplate}
                deleteTemplate={this.deleteTemplate}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(withTemplateContext(TemplateModal));
