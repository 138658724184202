import React from 'react';
import { Tabs, Icon } from 'antd';
import { getTextPosition } from '@src/data/BusinessFun';
import { FindCircle, GetCenterInTwoPoints, LengthArc, LengthBetweenPoints, ScalePoint } from '@src/data/CommonFunc';
import { TransformCrossWalkWidth } from '@src/actions/TransformHandle';
import { convertToFeet, convertToStandard } from '@src/data/CommonFunction';
import TabItem from '@src/components/PropMenu/TabItem';
import emitter from '@src/data/Event';
import { ROAD_CONSTANT } from '@src/type/event';
import { LIMIT_VALUE, FONT_WEIGHT_TYPE, FONT_STYLE_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';
import LaneMenu from '@src/components/Modal/Menu/LaneMenu';
import { EVENT_EMIT_TYPE } from '@src/type/event';

export default class CrossWalkModal extends React.Component {
  // Modal menu and items
  menu = {
    style: ['lineType', 'width', 'fillColor', 'snap'],
    text: ['text', 'fontSize', 'color', 'style'],
    size: ['pointWidth'],
  };

  state = {
    object: this.props.object,
    textarea: false,
    pointwidth: this.calculatePointWidth(this.props.object),
    tabKey: 'style',
    SnapToStreet: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
      pointwidth: this.calculatePointWidth(this.props.object),
    });
  }

  calculatePointWidth(object) {
    let pointWidth = convertToFeet(LengthBetweenPoints(object.handlepoint[4], object.handlepoint[5]));
    pointWidth = Math.round(pointWidth * 1000) / 1000;
    return pointWidth;
  }

  handleStrokeDashArray = (strokePattern, strokeDash) => {
    workData.setObjectStyleLine(this.state.object.operateid, strokePattern, strokeDash);
    var obj = this.state.object;
    obj.style.strokepattern = strokePattern;
    obj.style.strokedasharray = strokeDash;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStrokeWidth = width => {
    var obj = this.state.object;
    obj.style.strokewidth = width;
    obj.marks.height = width * 2;
    if (obj.arrow.width < obj.style.strokewidth * 2) {
      obj.arrow.width = obj.style.strokewidth * 2;
      obj.arrow.height = obj.arrow.width;
    }

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStroke = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleLineColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.stroke = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };
  handleFill = evt => {
    var colortype = evt.currentTarget.id;
    workData.setObjectStyleFillColor(this.state.object.operateid, colortype);
    var obj = this.state.object;
    obj.style.fill = colortype;
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };
  handleArrowPositon = e => {
    var obj = this.state.object;
    obj.arrow.position = e.key;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };
  handleArrowType = e => {
    var obj = this.state.object;
    obj.arrow.type = e.key;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };
  handleArrowWidth = width => {
    var obj = this.state.object;
    obj.arrow.width = width;
    obj.arrow.height = width;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };
  handleSnapToStreet = e => {
    const { object: obj, SnapToStreet } = this.state;
    obj.snapToStreet = !SnapToStreet;
    if (obj.snapToStreet) {
      obj.streetId = undefined;
    } else {
      // TODO: check if it's on any street and set streetId
    }
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj, SnapToStreet: !SnapToStreet });
    this.props.handle();
  };
  handleMarksLenth = length => {
    var obj = this.state.object;
    obj.marks.length = length;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };
  // changePosition = e => {
  //   var obj = this.state.object;
  //   obj.text.position = getTextPosition(obj.text.position);

  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  textlength = e => {
    var obj = this.state.object;
    obj.text.islength = e.target.checked;
    if (obj.text.islength) {
      obj.text.text = String(LengthArc(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]));
    } else {
      obj.text.text = '';
    }
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    if (obj.text.islength) {
      this.setState({ textarea: true });
    } else {
      this.setState({ textarea: false });
    }
    this.props.handle();
  };
  // textHandle = e => {
  //   var obj = this.state.object;
  //   obj.text.text = e.target.value;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textSizeHandle = size => {
  //   var obj = this.state.object;
  //   obj.text.size = size;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textBoldHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.bold == FONT_WEIGHT_TYPE.NORMAL) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.BOLD;
  //   } else if (obj.text.bold == FONT_WEIGHT_TYPE.BOLD) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textItalicHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.italic == FONT_STYLE_TYPE.NORMAL) {
  //     obj.text.italic = FONT_STYLE_TYPE.ITALIC;
  //   } else if (obj.text.italic == FONT_STYLE_TYPE.ITALIC) {
  //     obj.text.italic = FONT_STYLE_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textColorHandle = e => {
  //   var colortype = e.currentTarget.id;
  //   var obj = this.state.object;
  //   obj.text.color = colortype;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // pointlengthHandle = length => {
  //   var scale = length / this.state.pointlength;
  //   var obj = this.state.object;
  //   obj.handlepoint[1] = ScalePoint(obj.handlepoint[1], scale, scale, obj.handlepoint[0]);
  //   this.setState({ pointlength: length });
  //   this.setState({ object: obj });
  //   workData.setObj(obj.operateid, obj);
  //   this.props.handle();
  // };
  chordhandle = chord => {
    var scale = chord / this.state.chord;
    var obj = this.state.object;
    var center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
    obj.handlepoint[0] = ScalePoint(obj.handlepoint[0], scale, scale, center);
    obj.handlepoint[1] = ScalePoint(obj.handlepoint[1], scale, scale, center);
    var circle = FindCircle(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]);
    this.setState({ radius: circle[2] });
    this.setState({ chord: chord });
    this.setState({ object: obj });
    workData.setObj(obj.operateid, obj);
    this.props.handle();
  };
  ordinatehandle = ordinate => {
    var scale = ordinate / this.state.ordinate;
    var obj = this.state.object;
    var center = GetCenterInTwoPoints(obj.handlepoint[0], obj.handlepoint[1]);
    obj.handlepoint[2] = ScalePoint(obj.handlepoint[2], scale, scale, center);
    var circle = FindCircle(obj.handlepoint[0], obj.handlepoint[1], obj.handlepoint[2]);
    this.setState({ radius: circle[2] });
    this.setState({ ordinate: ordinate });
    this.setState({ object: obj });
    workData.setObj(obj.operateid, obj);
    this.props.handle();
  };

  patternHandle = type => {
    var obj = this.state.object;
    obj.style.pattern = type;
    this.setState({ object: obj });
    workData.setObj(obj.operateid, obj);
    this.props.handle();
  };
  handlePointWidth = width => {
    var obj = this.state.object;
    let standardWidth = convertToStandard(width);
    obj = TransformCrossWalkWidth(obj, standardWidth);
    obj.pointwidth = standardWidth;
    // console.log(obj);
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj, pointwidth: width });
    this.props.handle();
  };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <div className="reg-no-select">
        <div style={{ textAlign: 'left', display: 'flex' }}>
          {Object.keys(this.menu).map(name => (
            <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
          ))}
          <span style={{ flex: 1 }}></span>
          <span style={{ padding: 5, marginRight: 5 }}>
            <Icon type="down" onClick={this.props.hidePropsMenu} />
          </span>
        </div>
        <Tabs activeKey={this.state.tabKey} tabBarStyle={{ display: 'none' }}>
          {'lane' in this.menu && (
            <Tabs.TabPane tab="lane" key="lane">
              <LaneMenu
                item={this.menu.lane}
                stateObject={this.state.object}
                stallWidthHandle={this.stallWidthHandle}
                stallLength={this.stallLength}
              />
            </Tabs.TabPane>
          )}
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
                wallWidth={this.state.strokewidth}
                handleDirection={this.showDirection}
                handleTextbox={this.handleTextbox}
                patternHandle={this.patternHandle}
                handleSnapToStreet={this.handleSnapToStreet}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                // width={this.state.width}
                // height={this.state.height}
                // radius={this.state.radius}
                // angle={this.state.angle}
                // handlewidth={this.handlewidth}
                // handleheight={this.handleheight}
                // handleradius={this.handleradius}
                // handleAngle={this.handleAngle}
                // handlemarkslength={this.handlemarkslength}
                pointWidth={this.state.pointwidth}
                handlePointWidth={this.handlePointWidth}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}
