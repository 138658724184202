import React, { Component } from 'react';
import { Input, Button, List, Icon } from 'antd';
import { connect } from 'react-redux';
import LncdSpin from '../LncdSpin';

class MapSearch extends Component {
  onFocus = e => {
    e.target.select();
  };

  render() {
    const { onInput, onSearch, onListItemClick, onCancel } = this.props;
    const { selectOptions, isLocationLoading } = this.props;

    return (
      <div className="map-search">
        <div className="map-search-head">
          <Input
            placeholder={this.props.searchPlaceholder}
            ref={e => (this.mapInput = e)}
            value={this.props.keyword}
            autoFocus
            prefix={
              <Icon
                type="search"
                style={{ fontSize: '16px', marginRight: '10px' }}
                className="custom-night-mode-text"
              />
            }
            onInput={onInput}
            onPressEnter={e => onSearch(e.target.value)}
            onFocus={this.onFocus}
          />
          <Button
            type="primary"
            style={{
              marginLeft: 15,
            }}
            onClick={() => onSearch(this.mapInput.state.value)}
          >
            {this.props.searchText}
          </Button>
        </div>
        <div className="map-search-content">
          {isLocationLoading && <LncdSpin center />}
          {selectOptions.length > 0 && (
            <List className="map-search-content__list" size="small" header={null} footer={null} bordered>
              {selectOptions.map(item => {
                return (
                  <List.Item
                    key={item.id}
                    className="map-search-content__list-item"
                    onClick={() => onListItemClick(item)}
                  >
                    {item.name}
                  </List.Item>
                );
              })}
            </List>
          )}
        </div>
        <div className="map-search-foot">
          <Button type="ghost" onClick={onCancel}>
            {this.props.cancelText}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    selectOptions: state.map.selectOptions,
    isLocationLoading: state.map.isLocationLoading,
    keyword: state.map.keyword,
  }),
  null,
  null,
  { forwardRef: true }
)(MapSearch);
