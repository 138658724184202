import React, { useContext } from 'react';
import { Tabs, Icon } from 'antd';

import {
  GetAngleArc,
  GetAngleDegree,
  getAngleBy2Points,
  GetCenterInTwoPoints,
  LengthBetweenPoints,
  SVGAdapter,
} from '@src/data/CommonFunc';
import { TransformRotateAngle, TransformScaleHeight, TransformScaleWidth } from '@src/actions/TransformHandle';
import { convertToFeet, convertToStandard } from '@src/data/CommonFunction';
import TabItem from '@src/components/PropMenu/TabItem';
import emitter from '@src/data/Event';
import { ROAD_CONSTANT } from '@src/type/event';
import utilitySvg from '@src/data/UtilitySvg';
import { LIMIT_VALUE, FONT_WEIGHT_TYPE, FONT_STYLE_TYPE, DASH_ARRAY_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';
import { requestSvgData } from '@src/utils';
import { connect } from 'react-redux';

import TextMenu from '@src/components/Modal/Menu/TextMenu';
import SizeMenu from '@src/components/Modal/Menu/SizeMenu';
import StyleMenu from '@src/components/Modal/Menu/StyleMenu';
import LaneMenu from '@src/components/Modal/Menu/LaneMenu';
import { SymbolContext } from '@src/contexts';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import TabBar from '@src/components/PropMenu/TabBar';

class SymbolModal extends React.Component {
  // Modal menu and items
  menu = {
    style: ['line', 'width', 'lineColor', 'fillColor', 'flip', 'shadow', 'orientation'],
    text: ['text', 'fontSize', 'color', 'style'],
    size: ['width', 'height', 'rotation'],
  };

  constructor(props) {
    super(props);
    const { object } = this.props;

    // var center = GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[2]);
    // var angle = getAngleBy2Points(center, object.handlepoint[8]);
    this.state = {
      object: object,
      // width: LengthBetweenPoints(object.handlepoint[0], object.handlepoint[1]),
      // height: LengthBetweenPoints(object.handlepoint[0], object.handlepoint[3]),
      // width: convertToFeet(LengthBetweenPoints(object.handlepoint[0], object.handlepoint[1])),
      // height: convertToFeet(LengthBetweenPoints(object.handlepoint[0], object.handlepoint[3])),
      // angle: GetAngleDegree(angle, 0),
      tabKey: 'style',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      object: nextProps.object,
    });
  }

  componentDidUpdate({ object }) {
    if (this.state.object.operateid !== object.operateid) {
      if (object.handlepoint.length > 0) {
        const width = convertToFeet(LengthBetweenPoints(object.handlepoint[0], object.handlepoint[1]));
        const height = convertToFeet(LengthBetweenPoints(object.handlepoint[0], object.handlepoint[3]));
        var center = GetCenterInTwoPoints(object.handlepoint[0], object.handlepoint[2]);
        var angle = getAngleBy2Points(center, object.handlepoint[8]);

        this.setState({ object, width, height, angle: GetAngleDegree(angle, 0) });
      }
    }
  }

  /**
   *
   * @param {DASH_ARRAY_TYPE} strokePattern
   * @param {*} strokeDash
   */
  handleStrokeDashArray = (strokePattern, strokeDash) => {
    workData.setObjectStyleLine(this.state.object.operateid, strokePattern, strokeDash);
    var obj = this.state.object;
    obj.style.strokepattern = strokePattern;
    obj.style.strokedasharray = utilitySvg.getStrokePattern(obj.style.strokepattern, obj.style.strokewidth);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  handleStrokeWidth = width => {
    var obj = this.state.object;
    obj.style.strokewidth = width;
    obj.marks.height = width * 2;

    if (obj.arrow.width < obj.style.strokewidth * 2) {
      obj.arrow.width = obj.style.strokewidth * 2;
      obj.arrow.height = obj.arrow.width;
    }

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };
  // textItalicHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.italic == FONT_STYLE_TYPE.NORMAL) {
  //     obj.text.italic = FONT_STYLE_TYPE.ITALIC;
  //   } else if (obj.text.italic == FONT_STYLE_TYPE.ITALIC) {
  //     obj.text.italic = FONT_STYLE_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textColorHandle = e => {
  //   var colortype = e.currentTarget.id;
  //   var obj = this.state.object;
  //   obj.text.color = colortype;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // handlewidth = width => {
  //   const scale = (width * 12) / this.state.width;
  //   const obj = this.state.object;

  //   obj.handlepoint = TransformScaleWidth(obj.handlepoint, scale);
  //   obj.width = width * 12;
  //   workData.setObj(obj.operateid, obj);

  //   this.setState({ object: obj });
  //   this.setState({ width: width * 12 });
  //   this.props.handle();
  // };
  // handleheight = height => {
  //   const scale = height / this.state.height;
  //   const obj = this.state.object;

  //   obj.handlepoint = TransformScaleHeight(obj.handlepoint, scale);
  //   obj.height = convertToStandard(height);
  //   workData.setObj(obj.operateid, obj);

  //   this.setState({ object: obj });
  //   this.setState({ height: height });
  //   this.props.handle();
  // };

  // FIXME: Should not use "id" to transfer color values
  handleStroke = evt => {
    var colorType = evt.currentTarget.id;
    workData.setObjectStyleLineColor(this.state.object.operateid, colorType);
    var obj = this.state.object;
    obj.style.stroke = colorType;
    if (obj.shadow.shapes.length > 0) {
      obj.shadow.shapes.forEach(o => {
        workData.setObjectStyleLineColor(o.operateId, colorType);
      });
    }
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };
  handleFill = evt => {
    var colorType = evt.currentTarget.id;
    workData.setObjectStyleFillColor(this.state.object.operateid, colorType);
    var obj = this.state.object;
    obj.style.fill = colorType;
    if (obj.shadow.shapes.length > 0) {
      obj.shadow.shapes.forEach(o => {
        workData.setObjectStyleFillColor(o.operateId, colorType);
      });
    }
    this.setState({ object: obj });
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };
  // textHandle = e => {
  //   var obj = this.state.object;
  //   obj.text.text = e.target.value;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textSizeHandle = size => {
  //   var obj = this.state.object;
  //   obj.text.size = size;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textBoldHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.bold == FONT_WEIGHT_TYPE.NORMAL) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.BOLD;
  //   } else if (obj.text.bold == FONT_WEIGHT_TYPE.BOLD) {
  //     obj.text.bold = FONT_WEIGHT_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textItalicHandle = () => {
  //   var obj = this.state.object;
  //   if (obj.text.italic == FONT_STYLE_TYPE.NORMAL) {
  //     obj.text.italic = FONT_STYLE_TYPE.ITALIC;
  //   } else if (obj.text.italic == FONT_STYLE_TYPE.ITALIC) {
  //     obj.text.italic = FONT_STYLE_TYPE.NORMAL;
  //   }
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };
  // textColorHandle = e => {
  //   var colortype = e.currentTarget.id;
  //   var obj = this.state.object;
  //   obj.text.color = colortype;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.props.handle();
  // };

  // handlewidth = width => {
  //   const scale = width / this.state.width;
  //   const obj = this.state.object;
  //   obj.handlepoint = TransformScaleWidth(obj.handlepoint, scale);
  //   obj.width = convertToStandard(width);
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.setState({ width: width });
  //   this.props.handle();
  // };
  // handleheight = height => {
  //   const scale = height / this.state.height;
  //   const obj = this.state.object;
  //   obj.handlepoint = TransformScaleHeight(obj.handlepoint, scale);
  //   obj.height = height;
  //   workData.setObj(obj.operateid, obj);
  //   this.setState({ object: obj });
  //   this.setState({ height: height });
  //   this.props.handle();
  // };

  // handleAngle = angleValue => {
  //   const { object, angle } = this.state;
  //   const angleArc = GetAngleArc(angleValue - angle, 5);

  //   object.handlepoint = TransformRotateAngle(object.handlepoint, angleArc);

  //   workData.setObj(object.operateid, object);

  //   this.setState({ object: object, angle: angleValue });
  //   this.props.handle();
  // };

  changeVertical = e => {
    var obj = this.state.object;
    obj.vertical = -obj.vertical;
    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };
  changeHorizontal = e => {
    var obj = this.state.object;
    obj.horizontal = -obj.horizontal;

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };
  changeOver = e => {
    var obj = this.state.object;

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  onSwitchTab = tabKey => {
    this.setState({ tabKey });
  };

  toggleShadow = () => {
    var obj = this.state.object;
    obj.shadow = { ...obj.shadow, isActiveShadowMode: !obj.shadow.isActiveShadowMode };

    workData.setObj(obj.operateid, obj);
    this.setState({ object: obj });
    this.props.handle();
  };

  changeOrientation = (symbol, view) => {
    const targetSymbol = this.props.symbols.find(
      symbolItem => symbolItem.orientationGroup === symbol.orientationGroup && symbolItem.orientationView === view
    );
    const targetObject = {
      ...this.state.object,
      symbol: {
        ...targetSymbol,
        image: '',
      },
    };
    // TODO size changed, symbol shaped issue
    let { svg, width, height } = SVGAdapter(targetSymbol.image, { ...targetObject.symbol });
    targetObject.originsvg = `<?xml version="1.0" encoding="utf-8"?>${svg.outerHTML}`;
    // targetObject.originwidth = width;
    // targetObject.originheight = height;
    // targetObject.vertical = 1;
    // targetObject.horizontal = 1;
    // targetObject.position.x1 = targetObject.clickpoint[0] - width / 2;
    // targetObject.position.y1 = targetObject.clickpoint[1] - height / 2;
    // targetObject.position.x2 = targetObject.clickpoint[0] + width / 2;
    // targetObject.position.y2 = targetObject.clickpoint[1] + height / 2;
    workData.setObj(this.state.object.operateid, targetObject);
    emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM);
    this.props.handle();
  };

  render() {
    return (
      <div className="reg-no-select">
        <Tabs
          activeKey={this.state.tabKey}
          renderTabBar={() => (
            <TabBar>
              {Object.keys(this.menu).map(name => (
                <TabItem key={name} name={name} tabKey={this.state.tabKey} onSwitchTab={this.onSwitchTab} />
              ))}
            </TabBar>
          )}
        >
          {'lane' in this.menu && (
            <Tabs.TabPane tab="lane" key="lane">
              <LaneMenu
                item={this.menu.lane}
                stateObject={this.state.object}
                stallWidthHandle={this.stallWidthHandle}
                stallLength={this.stallLength}
              />
            </Tabs.TabPane>
          )}
          {'style' in this.menu && (
            <Tabs.TabPane tab="style" key="style">
              <StyleMenu
                item={this.menu.style}
                stateObject={this.state.object}
                handleLine={this.handleStrokeDashArray}
                handleMarks={this.handleMarks}
                handleMarksLenth={this.handleMarksLenth}
                handleStroke={this.handleStroke}
                handleStrokeWidth={this.handleStrokeWidth}
                handleFill={this.handleFill}
                wallWidth={this.state.strokewidth}
                handleDirection={this.showDirection}
                handleTextbox={this.handleTextbox}
                handleSnapToStreet={this.handleSnapToStreet}
                handlehide={this.handlehide}
                changeVertical={this.changeVertical}
                changeHorizontal={this.changeHorizontal}
                toggleShadow={this.toggleShadow}
                changeOrientation={this.changeOrientation}
              />
            </Tabs.TabPane>
          )}
          {'text' in this.menu && (
            <Tabs.TabPane tab="text" key="text">
              <TextMenu item={this.menu.text} objectId={this.props.object.operateid} />
            </Tabs.TabPane>
          )}
          {'size' in this.menu && (
            <Tabs.TabPane tab="size" key="size">
              <SizeMenu
                item={this.menu.size}
                objectId={this.props.object.operateid}
                stateObject={this.state.object}
                // pointLength={this.state.pointlength}
                // pointLengthHandle={this.pointlengthHandle}
                // width={this.state.width}
                // height={this.state.height}
                // radius={this.state.radius}
                // angle={this.state.angle}
                // handlewidth={this.handlewidth}
                // handleheight={this.handleheight}
                // handleradius={this.handleradius}
                // handleAngle={this.handleAngle}
                // handlemarkslength={this.handlemarkslength}
                // pointWidth={this.state.pointwidth}
                // handlePointWidth={this.handlePointWidth}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
    );
  }
}

function ConsumeSymbolContext(props) {
  const { symbols } = useContext(SymbolContext);

  return <SymbolModal symbols={symbols} {...props} />;
}

export default ConsumeSymbolContext;
