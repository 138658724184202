import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import GeomRect from '@src/data/GeomRect';
import Utility from '@src/data/Utility';
import { parseStyleFromNode, removeNode } from '@src/utils';
import LncdCheckbox from '../Modal/Element/LncdCheckbox';
import * as workData from '@src/data/WorkData';
import { FUNCTION_TYPE } from '@src/constant';
import emitter from '@src/data/Event';
import { EVENT_EMIT_TYPE } from '@src/type/event';
import './PrintDialog.css';

export function filterMeasurement(callback, timeout) {
  // 隐藏 measurement
  let useData = workData.getUseData();
  let cacheUseData = cloneDeep(useData);
  let newUseData = useData.filter(
    data =>
      data.functype !== FUNCTION_TYPE.XYMEASUREMENT &&
      data.functype !== FUNCTION_TYPE.STATIONLINE &&
      data.functype !== FUNCTION_TYPE.TRIANGULATIONNETWORK
  );
  workData.resumeUseData(newUseData);
  emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, false);

  // HACK: measurement 隐藏后再打印
  setTimeout(() => {
    let returnValue = callback();
    // 渲染打印页面后显示 measurement
    if (returnValue instanceof Promise) {
      returnValue.then(() => {
        workData.resumeUseData(cacheUseData);
        emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, false);
      });
    } else {
      workData.resumeUseData(cacheUseData);
      emitter.emit(EVENT_EMIT_TYPE.UPDATE_DIAGRAM, false);
    }
  }, timeout);
}

function PrintDialog({ onCancel }) {
  const { t } = useTranslation();
  const [hasBorder, setHasBorder] = useState(false);
  const [showMeasurement, setShowMeasurement] = useState(false);

  const print = () => {
    let svg = document.getElementById('svg');
    let bbox = document.getElementById('all-shapes').getBBox();
    let rect = new GeomRect(bbox.x, bbox.y + bbox.height, bbox.x + bbox.width, bbox.y);

    // 地图存在时，扩大导出范围
    // if (Utility.isValid(mapdata) && Utility.isNonEmptyString(mapdata.schema)) {
    //   const MAX_WIDTH = 2400;
    //   const MAX_HEIGHT = 2400;
    //   let width = rect.Width();
    //   let height = rect.Height();
    //   let cx = width < MAX_WIDTH ? (MAX_WIDTH - width) / 2 : 0;
    //   let cy = height < MAX_HEIGHT ? (MAX_HEIGHT - height) / 2 : 0;
    //   rect.inflateRect(cx, cy);
    // }

    // 计算输出图像与边框的间隔
    let x = Math.sqrt(rect.Width() * rect.Height());
    let y = (Math.min(120, x * 0.05) + x * 0.01) / 2;
    rect.inflateRect(y, y);

    /** @type {SVGSVGElement} */
    let clone = svg.cloneNode(true);
    parseStyleFromNode(clone, 'day');
    // Remove select layer and grid layer
    removeNode(clone, '#selector-layer');
    removeNode(clone, '#grid-layer');
    let m = clone.createSVGMatrix();

    /** @type {SVGGElement} */
    let host = clone.getElementById('host');
    let v = host.transform.baseVal;
    v.initialize(v.createSVGTransformFromMatrix(m));
    m.d = -1;

    /** @type {SVGGElement} */
    let viewport = clone.getElementById('viewport');
    v = viewport.transform.baseVal;
    v.initialize(v.createSVGTransformFromMatrix(m));
    clone.setAttribute('viewBox', rect.left + ' ' + -rect.top + ' ' + rect.Width() + ' ' + rect.Height());

    /** @type {HTMLIFrameElement} */
    let iframe = document.getElementById('lncd-print-frame');
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'lncd-print-frame');
      iframe.setAttribute('style', 'position:absolute;visibility:hidden;width:0px;height:0px;top:0px');
      document.body.appendChild(iframe);
    }
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.onreadystatechange = event => {
      if (event.target.readyState === 'complete') {
        /**
         * 打印页面使用iframe单独渲染
         * 这里要跳过渲染页面所需要的时间
         * 渲染页面完毕后再打印
         */
        setTimeout(() => {
          if (document.queryCommandSupported('print')) {
            iframe.contentWindow.document.execCommand('print', false, null);
          } else {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          }
        }, 200);
      }
    };

    let printPages;
    let useData = workData.getUseData();
    if (showMeasurement) {
      let xyId = 1;
      let lineId = 1;
      let table = [];
      // Todo: Clean code
      useData.forEach(data => {
        if (data.functype === FUNCTION_TYPE.XYMEASUREMENT) {
          if (table.findIndex(x => x.title === 'X-Y Measurement Points') === -1) {
            table.push({
              id: table.length,
              title: 'X-Y Measurement Points',
              children: [],
            });
          }
          let measurements = table.find(x => x.title === 'X-Y Measurement Points');
          let measurement = {
            id: xyId.toString(),
            subTitle: 'X-Y Measurement',
            points: [['ID', 'Description', 'X', 'Y']],
          };
          if (data.pointList) {
            data.pointList.forEach(p => {
              measurement.points.push([p.id, p.description, p.x.toString(), p.y.toString()]);
            });
          }
          measurements.children.push(measurement);
          xyId += 1;
        } else if (data.functype === FUNCTION_TYPE.STATIONLINE) {
          if (table.findIndex(x => x.title === 'Station Line Measurement Points') === -1) {
            table.push({
              id: table.length,
              title: 'Station Line Measurement Points',
              children: [],
            });
          }
          let measurements = table.find(x => x.title === 'Station Line Measurement Points');
          let measurement = {
            id: lineId.toString(),
            subTitle: 'Station Line',
            points: [['ID', 'Description', 'Station', 'Direction', 'Distance']],
          };
          if (data.pointList) {
            data.pointList.forEach(p => {
              measurement.points.push([
                p.id.toString(),
                p.description,
                p.station.toString(),
                p.direction,
                p.distance.toString(),
              ]);
            });
          }
          measurements.children.push(measurement);
          lineId += 1;
        } else if (data.functype === FUNCTION_TYPE.TRIANGULATIONNETWORK) {
          if (table.findIndex(x => x.title === 'Triangulation Measurement') === -1 && data.pointList) {
            let measurement = {
              points: data.pointList.reduce(
                (points, p) =>
                  points.concat([[p.id, p.description, p.p1, p.distance1.toString(), p.p2, p.distance2.toString()]]),
                [['ID', 'Description', 'Pt1', 'Distance1', 'Pt2', 'Distance2']]
              ),
            };
            table.push({
              id: table.length,
              title: 'Triangulation Measurement',
              children: [measurement],
            });
          }
        }
      });
      printPages = `
        <p>
          <div id="printedDiagram">
            ${clone.outerHTML}
          </div>
        </p>
        <p>
          <h2>Field Measurements</h2>
          ${table
            .map(item => {
              return `
                <div>
                <h3>${item.title}</h3>
                ${item.children
                  .map(measurement => {
                    let head = `<tr>${measurement.points[0].map(x => `<th>${x}</th>`).join('')}</tr>`;
                    let body = '';
                    if (measurement.points.length > 1) {
                      body = measurement.points
                        .slice(1)
                        .map(point => {
                          let row = point.map(x => `<td>${x}</td>`).join('');
                          return `<tr>${row}</tr>`;
                        })
                        .join('');
                    }
                    return `
                    ${measurement.subTitle ? `<h4>${measurement.subTitle}, ${measurement.id}</h4>` : ''}
                    <table>
                      <thead>${head}</thead>
                      <tbody>${body}</tbody>
                    </table>
                  `;
                  })
                  .join('')}
                </div>
              `;
            })
            .join('')}
        </p>
      `;
    } else {
      printPages = `
        <div id="printedDiagram">
          ${clone.outerHTML}
        </div>
      `;
    }

    /**
     *  两个目的
     *  1. 使图形居中
     *  2. 创建一个border
     */
    iframe.contentWindow.document.write(
      `
      <!DOCTYPE html>
        <html>
        <head>
          <title>LexisNexis Crash Designer</title>
          <style>
            @media print {
              p { page-break-after: always; }
              html, body {
                margin: 0;
                padding: 0;
                border: 0;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
              }
              #printedDiagram {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border: ${hasBorder ? '0.5pt black solid' : 'none'};
                margin: 5px;
              }
            }
          </style>
        </head>
        <body>
          ${printPages}
        </body>
      </html>
      `
    );
    iframe.contentWindow.document.close();
  };

  /**
   * 计算显示分辨率
   * @param {GeomRect} bbox
   * @param {number} resolution
   * @return {number}
   */
  const calculateDisplayResolution = (bbox, resolution) => {
    let x = 10 * resolution;
    let y = Math.max(bbox.Width(), bbox.Height()) / x;
    return y;
  };

  const onPrint = () => {
    if (showMeasurement) {
      print();
    } else {
      filterMeasurement(print, 0);
    }
  };

  return (
    <Modal
      title={t('menu.printModal.title')}
      centered={true}
      visible={true}
      onOk={onPrint}
      okText={t('menu.printModal.okLabel')}
      onCancel={onCancel}
    >
      <div className="lncd-print-content-container">
        <div>
          <LncdCheckbox checked={hasBorder} onClick={() => setHasBorder(!hasBorder)} size={16} />
          &nbsp;{t('menu.printModal.printBorderLabel')}
        </div>
        <div>
          <LncdCheckbox checked={showMeasurement} onClick={() => setShowMeasurement(!showMeasurement)} size={16} />
          &nbsp;{t('menu.printModal.printMeasurementLabel')}
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintDialog);
