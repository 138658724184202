import React, { createContext, useContext } from 'react';

const TemplateContext = createContext({
  templates: [],
});

/**
 * An HOC to provide TemplateContext data and actions to any componenet
 * @param {*} WrappedComponent
 */
const withTemplateContext = WrappedComponent => props => {
  return <WrappedComponent {...props} {...useContext(TemplateContext)} />;
};

export { TemplateContext, withTemplateContext };
