import React from 'react';
import {
  getAngleBy2Points,
  GetCenterInTwoPoints,
  GetMatrixString,
  GetRotateMatrix,
  IsArcDirection,
  IsBigArc,
  LengthBetweenPoints,
  matrixMultiply,
  ScalePoint,
  TowPointVerticalLengthByStatus,
} from '@src/data/CommonFunc';
import { GetPathArcD, GetPathArcD1, GetPathLineD, GetPathLineD1, GetShapes } from '@src/data/BusinessFun';
import { CIRCLE_SIZE_TYPE } from '@src/constant';

class LaneMarker extends React.Component {
  constructor(props) {
    super(props);
  }

  getArrowShape = (shapes, anchors) => {
    let firstPoint = anchors[3];
    let height = LengthBetweenPoints(anchors[0], anchors[3]);
    let width = LengthBetweenPoints(anchors[0], anchors[1]);
    //height*3/4;
    let secondTempPoint = ScalePoint(anchors[0], 7 / 11, 7 / 11, anchors[3]);
    let thirdPoint = ScalePoint(anchors[1], 3 / 4, 3 / 4, anchors[0]);
    let secondR = LengthBetweenPoints(anchors[0], thirdPoint);
    let secondPoint = TowPointVerticalLengthByStatus(
      anchors[0],
      anchors[3],
      secondTempPoint,
      LengthBetweenPoints(thirdPoint, anchors[0]),
      false
    );
    let fourPoint = ScalePoint(anchors[1], 6 / 11, 6 / 11, anchors[2]);
    let fourLeftPoint = ScalePoint(anchors[0], 6 / 11, 6 / 11, anchors[3]);
    let fivePoint = TowPointVerticalLengthByStatus(
      anchors[0],
      anchors[1],
      thirdPoint,
      LengthBetweenPoints(fourPoint, anchors[1]) * 2,
      true
    );
    let sixLength = LengthBetweenPoints(fourPoint, anchors[1]) + LengthBetweenPoints(fourLeftPoint, secondTempPoint);
    let sixPoint = TowPointVerticalLengthByStatus(anchors[0], anchors[1], thirdPoint, sixLength, true);
    let scaleSeven = (LengthBetweenPoints(secondTempPoint, fourLeftPoint) * 2) / width;
    let sevenPoint = ScalePoint(anchors[2], scaleSeven, scaleSeven, anchors[3]);
    let D = [];
    GetPathArcD(D, firstPoint, secondPoint, secondR, CIRCLE_SIZE_TYPE.SMALLARC, '1');
    GetPathLineD1(D, thirdPoint);
    GetPathLineD1(D, fourPoint);
    GetPathLineD1(D, fivePoint);
    GetPathLineD1(D, sixPoint);
    GetPathArcD1(
      D,
      sevenPoint,
      secondR - LengthBetweenPoints(fourLeftPoint, secondTempPoint) * 2,
      CIRCLE_SIZE_TYPE.SMALLARC,
      '0'
    );
    D.push('Z');
    shapes.push(<path d={D.join(' ')} key={'arrowmarks'} />);
  };

  render() {
    const { props } = this;
    const { object } = props;
    let anchors = object.handlepoint;
    let center = GetCenterInTwoPoints(anchors[0], anchors[2]);
    //1 先平移到原点
    var matrix1 = [1, 0, 0, 1, -center[0], -center[1]];
    //2 旋转 平行
    var matrix2 = GetRotateMatrix(-object.rotateangle);
    //3 对称
    var matrix3 = [1, 0, 0, 1, 0, 0];
    if (object.isleft) matrix3 = [-1, 0, 0, 1, 0, 0];
    //4 在旋转回去
    var matrix4 = GetRotateMatrix(object.rotateangle);
    //5 在移动回去
    var matrix5 = [1, 0, 0, 1, center[0], center[1]];
    //得到最后的矩阵
    var matrix = matrixMultiply(matrix5, matrix4);
    matrix = matrixMultiply(matrix, matrix3);
    matrix = matrixMultiply(matrix, matrix2);
    matrix = matrixMultiply(matrix, matrix1);
    let shapes = [];
    this.getArrowShape(shapes, anchors);
    return (
      <g id={object.functype} transform={GetMatrixString(matrix)}>
        {GetShapes(shapes)}
      </g>
    );
  }
}

export default LaneMarker;
