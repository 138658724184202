import * as React from 'react';

function SvgIconPathCircle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M1 0h24v24H1z" />
        <path
          d="M24 9h-1.485A10.003 10.003 0 0016 2.486V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v1.492A10.005 10.005 0 003.485 9H2a1 1 0 00-1 1v4a1 1 0 001 1h1.485A10.006 10.006 0 0010 21.509V23a1 1 0 001 1h4a1 1 0 001-1v-1.484A10.006 10.006 0 0022.515 15H24a1 1 0 001-1v-4a1 1 0 00-1-1zM12 2h2v2h-2V2zm-9 9h2v2H3v-2zm11 11h-2v-2h2v2zm2-2.596V19a1 1 0 00-1-1h-4a1 1 0 00-1 1v.413A8.036 8.036 0 015.596 15H6a1 1 0 001-1v-4a1 1 0 00-1-1h-.404A8.038 8.038 0 0110 4.588V5a1 1 0 001 1h4a1 1 0 001-1v-.404A8.027 8.027 0 0120.404 9H20a1 1 0 00-1 1v4a1 1 0 001 1h.404A8.02 8.02 0 0116 19.404zM23 13h-2v-2h2v2z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconPathCircle;
