import GeomPoint from './GeomPoint';
import GeomVector from './GeomVector';
import GeomLine from './GeomLine';
import GeomArc from './GeomArc';
import SVGPathSeg from './SVGPathSeg';

class UtilityGeom {
  /**
   * 获取两个线段 - 直线或者弧线，的所有交点
   * @param {GeomLine} b1
   * @param {GeomLine} b2
   * @returns {GeomPoint[]}
   */
  getIntersectPoints(b1, b2) {
    var pts = [];
    if (b1.declaredClass == 'GeomLine') {
      if (b2.declaredClass == 'GeomLine') {
        var p = b1.getPointIntersect(b2);
        if (p) {
          pts.push(p);
        }
        // console.log(pts);
      } else {
        pts = this.getPointsArcIntersectingLine(b2, b1);
      }
    } else {
      if (b2.declaredClass == 'GeomLine') {
        pts = this.getPointsArcIntersectingLine(b1, b2);
        // console.log(pts);
      } else {
        pts = this.getPointsArcIntersectingArc(b1, b2);
      }
    }
    // if (pts.length > 0) {
    //   console.log('pts', pts , b1.declaredClass, b2.declaredClass);
    // }
    return pts;
  }

  /**
   * 获取 line 和 arc 的交点
   * @param {GeomArc} arc
   * @param {GeomLine} line
   * @returns {GeomPoint[]}
   */
  getPointsArcIntersectingLine(arc, line) {
    var ps = this.getCirclePointsArcIntersectingLine(arc, line);
    return ps.filter(function(p) {
      return arc.containsPoint(p);
    });
  }

  /**
   * 获取弧线所在的圆与直线段的所有交点
   * @param {GeomArc} arc
   * @param {GeomLine} line
   * @returns {GeomPoint[]}
   */
  getCirclePointsArcIntersectingLine(arc, line) {
    var lu,
      lv,
      Cc,
      G,
      sv,
      a,
      b,
      c,
      d,
      dsq,
      a2,
      a90f,
      a910,
      a911 = [],
      int1,
      int2,
      a912;
    arc._ensureComputedValues();
    a912 = line.clone();
    a912.inflate(0);
    lu = new GeomVector(a912.getPointStart().x, a912.getPointStart().y);
    lv = a912.getPointStart().getVector(a912.getPointStop());
    Cc = new GeomVector(arc.getCircleCenter().x, arc.getCircleCenter().y);
    G = lu.subtract(Cc);
    a = lv.square();
    b = 2 * lv.dot(G);
    c = G.square() - arc.r * arc.r;
    d = b * b - 4 * a * c;
    if (d < 0) {
      return a911;
    }
    dsq = Math.sqrt(d);
    a2 = 2 * a;
    a90f = (-b + dsq) / a2;
    a910 = (-b - dsq) / a2;
    if (a90f >= 0 && a90f <= 1) {
      sv = lv.clone();
      sv.scale(a90f);
      int1 = a912.getPointStart();
      int1.offset(sv.vx, sv.vy);
      a911.push(int1);
    }
    if (a910 >= 0 && a910 <= 1) {
      sv = lv.clone();
      sv.scale(a910);
      int2 = a912.getPointStart();
      int2.offset(sv.vx, sv.vy);
      a911.push(int2);
    }
    return a911;
  }

  /**
   *
   * @param {GeomArc} arc1
   * @param {GeomArc} arc2
   * @returns {GeomPoint[]}
   */
  getPointsArcIntersectingArc(arc1, arc2) {
    var ps = this.getCirclePointsArcIntersectingArc(arc1, arc2);
    return ps.filter(function(p) {
      return arc1.containsPoint(p) && arc2.containsPoint(p);
    });
  }

  /**
   *
   * @param {GeomArc} arc1
   * @param {GeomArc} arc2
   * @returns {GeomPoint[]}
   */
  getCirclePointsArcIntersectingArc(arc1, arc2) {
    var r1,
      r2,
      d,
      radiusSquareDiff,
      distanceSquare,
      theta,
      s,
      x2,
      c1,
      c2,
      v1,
      v1Clone,
      c2Clone,
      c2CloneClone1,
      c2CloneClone2,
      points = [];
    if (arc2.r > arc1.r) {
      return this.getCirclePointsArcIntersectingArc(arc2, arc1);
    }
    r1 = arc1.r;
    r2 = arc2.r;
    c1 = arc1.getCircleCenter();
    c2 = arc2.getCircleCenter();
    d = c1.distance(c2);
    if (d <= r2 - r1) {
      return points;
    }
    if (d >= r2 + r1) {
      return points;
    }
    radiusSquareDiff = r2 * r2 - r1 * r1;
    distanceSquare = d * d;
    if (0 === d || 0 === r2) {
      return points;
    }
    if (distanceSquare < radiusSquareDiff) {
      s = (radiusSquareDiff - distanceSquare) / (2 * d);
      theta = Math.acos((d + s) / r2);
    } else {
      x2 = (distanceSquare + radiusSquareDiff) / (2 * d);
      theta = Math.acos(x2 / r2);
    }
    if (isNaN(theta)) {
      return points;
    }
    v1 = c2.getVector(c1);
    v1Clone = v1.clone();
    v1Clone.scale(r2 / d);
    c2Clone = c2.clone();
    c2Clone.offset(v1Clone.vx, v1Clone.vy);
    c2CloneClone1 = c2Clone.clone();
    c2CloneClone1.rotate(theta, c2);
    c2CloneClone2 = c2Clone.clone();
    c2CloneClone2.rotate(-theta, c2);
    points.push(c2CloneClone1);
    points.push(c2CloneClone2);
    return points;
  }

  /**
   *
   * @param {string} profile - svg path
   * @returns {array}
   */
  getSegments(profile) {
    var ps = new SVGPathSeg();
    var ss = ps.parsePath(profile);
    var segments = [];
    for (let j = 0; j < ss.length; j++) {
      if (ss[j].type == 'L') {
        segments.push({
          type: 'line',
          ptStart: {
            x: ss[j - 1].point[0],
            y: ss[j - 1].point[1],
          },
          ptStop: {
            x: ss[j].point[0],
            y: ss[j].point[1],
          },
        });
      } else if (ss[j].type == 'A') {
        segments.push({
          type: 'arc',
          ptStart: {
            x: ss[j - 1].point[0],
            y: ss[j - 1].point[1],
          },
          ptStop: {
            x: ss[j].point[0],
            y: ss[j].point[1],
          },
          r: ss[j].radius[0],
          largeArcFlag: ss[j].arcLarge ? 1 : 0,
          sweepFlag: ss[j].arcSweep ? 1 : 0,
        });
      }
    }
    return segments;
  }
}

export default UtilityGeom;
