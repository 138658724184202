import React from 'react';
import CustomInputNumber from '../Element/CustomInputNumber';
import LncdDropdown from '../Element/LncdDropdown';
import { ARROW_SHOW_TYPE, ArrowPositions, ArrowStyles } from '@src/constant';
import TouchScroll from '@src/components/TouchDevice/TouchScroll';
import useObjectProperty from '../useObjectProperty';
import { useTranslation } from 'react-i18next';

const arrowPositionOptions = Object.entries(ArrowPositions).map(([key, value]) => {
  const ArrowPositionImage = value;
  return {
    key,
    image: <ArrowPositionImage width={32} />,
  };
});

const arrowStyleOptions = Object.entries(ArrowStyles).map(([key, value]) => {
  const ArrowStyleImage = value;
  return {
    key,
    image: <ArrowStyleImage width={17} />,
  };
});

const ArrowMenu = props => {
  const { objectId } = props;
  const [position, handlePosition] = useObjectProperty(objectId, 'arrowPosition');
  // this has dependency to the position property so we need to add listener
  const [style, handleStyle] = useObjectProperty(objectId, 'arrowStyle');
  const [size, handleSize] = useObjectProperty(objectId, 'arrowSize');

  const { t } = useTranslation();

  return (
    <TouchScroll className="props-container scrollbar">
      {props.item.map(element => {
        switch (element) {
          case 'arrow': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.arrowTab.arrowLabel')}:</span>
                <LncdDropdown
                  items={arrowPositionOptions}
                  onClick={props.handleArrowPosition || handlePosition}
                  selectedKey={position}
                  defaultKey={ARROW_SHOW_TYPE.NONE}
                />
              </div>
            );
          }
          case 'arrowType': {
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.arrowTab.typeLabel')}:</span>
                <LncdDropdown
                  items={arrowStyleOptions}
                  onClick={props.handleArrowType || handleStyle}
                  selectedKey={style}
                />
              </div>
            );
          }
          case 'width':
            return (
              <div className="container-item" key={element}>
                <span className="item-title">{t('property.arrowTab.widthLabel')}:</span>
                <CustomInputNumber
                  value={size}
                  min={0}
                  max={200}
                  formatter={`"`}
                  parser={'"'}
                  size="default"
                  onChangeValue={props.handleArrowWidth || handleSize}
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </TouchScroll>
  );
};

export default ArrowMenu;
