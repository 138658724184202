import React from 'react';
import SelectLine from '@src/shapes/Line/SelectLine';
import SelectRect from '@src/shapes/Rect/SelectRect';
import SelectArc from '@src/shapes/Arc/SelectArc';
import SelectConnectedLines from '@src/shapes/ConnectedLines/SelectConnectedLines';
import SelectCloseShapes from '@src/shapes/CloseShapes/SelectCloseShapes';
import SelectCallout from '@src/shapes/Callout/SelectCallout';
import SelectStreet from '@src/shapes/Street/SelectStreet';
import SelectCounterTop from '@src/shapes/CounterTop/SelectCounterTop';
import SelectStairs from '@src/shapes/Stairs/SelectStairs';
import SelectParkStall from '@src/shapes/ParkStall/SelectParkStall';
import SelectCrossWalk from '@src/shapes/CrossWalk/SelectCrossWalk';
import SelectStructure from '@src/shapes/Structure/SelectStructure';
import SelectStreetOffset from '@src/shapes/StreetOffset/SelectStreetOffset';
import SelectDirt from '@src/shapes/Dirt/SelectDirt';
import { SelectMeasurement, SelectTriangulationNetwork } from '@src/shapes/Measurement';
import SelectStreetAcross from '@src/shapes/StreetAcross/SelectStreetAcross';
import SelectStreetNew from '@src/shapes/StreetNew/SelectStreetNew';
import SelectRoundabout from '@src/shapes/StreetNew/SelectRoundabout';
import SelectCurved from '@src/shapes/StreetNew/SelectCurved';
import SelectCurbReturn from '@src/shapes/CurbReturn/SelectCurbReturn';
import SelectMarker from '@src/shapes/Marker/SelectMarker';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import { FUNCTION_TYPE, COLOR_TYPE, STREET_TYPE } from '@src/constant';
import * as workData from '@src/data/WorkData';
import { shapeMapping } from '@src/utils';

function StyledContainer({ children, ...otherProps }) {
  const point = getUserUnitByPixel();

  const commonProps = {
    stroke: COLOR_TYPE.LNCD_MAIN_COLOR,
    strokeWidth: 1.25 * point,
    strokeOpacity: 1,
    strokeDasharray: 5 * point + ' ' + 3 * point,
    fill: 'none',
    fillOpacity: 1,
  };

  return (
    <g {...commonProps} {...otherProps}>
      {children}
    </g>
  );
}

function render(obj, handlers) {
  let element;

  switch (obj.functype) {
    case FUNCTION_TYPE.LINE:
      element = <SelectLine anchors={obj.handlepoint} />;
      break;
    case FUNCTION_TYPE.CROSSWALK:
      element = <SelectCrossWalk object={obj} />;
      break;
    case FUNCTION_TYPE.CALLOUT:
      element = <SelectCallout anchors={obj.handlepoint} text={obj.text} rotateangle={obj.rotateangle} />;
      break;
    case FUNCTION_TYPE.RECTANGLE:
    case FUNCTION_TYPE.SQUARE:
    case FUNCTION_TYPE.ELLIPSE:
    case FUNCTION_TYPE.TEXTBOX:
    case FUNCTION_TYPE.LASSOAREA:
    case FUNCTION_TYPE.LANEMARKER:
    case FUNCTION_TYPE.SYMBOL:
    case FUNCTION_TYPE.CIRCLE:
    case FUNCTION_TYPE.INDICATOR:
    case FUNCTION_TYPE.IMAGE:
    case FUNCTION_TYPE.GROUP:
      if (obj.handlepoint && obj.handlepoint.length > 0) {
        const isGroupAndHasStreet =
          obj.functype === FUNCTION_TYPE.GROUP &&
          obj.groupdata.some(data => shapeMapping.street.includes(data.functype));
        element = (
          <SelectRect
            showResizeHandles={obj.showResizeHandles !== false && !isGroupAndHasStreet}
            anchors={obj.handlepoint}
            rotateangle={obj.rotateangle}
            canChangeToLine={
              obj.functype === FUNCTION_TYPE.RECTANGLE ||
              obj.functype === FUNCTION_TYPE.ELLIPSE ||
              obj.functype === FUNCTION_TYPE.TEXTBOX ||
              obj.functype === FUNCTION_TYPE.LASSOAREA ||
              obj.functype === FUNCTION_TYPE.LANEMARKER ||
              obj.functype === FUNCTION_TYPE.INDICATOR ||
              obj.functype === FUNCTION_TYPE.IMAGE
            }
            isSquare={
              obj.functype === FUNCTION_TYPE.SQUARE ||
              obj.functype === FUNCTION_TYPE.CIRCLE ||
              (obj.functype === FUNCTION_TYPE.GROUP && !isGroupAndHasStreet)
            }
          />
        );
      }
      break;
    case FUNCTION_TYPE.STAIRS:
      element = <SelectStairs anchors={obj.handlepoint} />;
      break;
    case FUNCTION_TYPE.ARC:
    case FUNCTION_TYPE.DIMENSIONLINE:
    case FUNCTION_TYPE.STRIPE:
    case FUNCTION_TYPE.SIDEWALK:
      element = <SelectArc anchors={obj.handlepoint} />;
      break;
    case FUNCTION_TYPE.PARKINGSTALLS:
      element = <SelectParkStall object={obj} />;
      break;
    case FUNCTION_TYPE.CONNECTEDLINES:
      element = <SelectConnectedLines object={obj} />;
      break;
    case FUNCTION_TYPE.CLOSEDSHAPE:
      element = <SelectCloseShapes object={obj} />;
      break;
    case FUNCTION_TYPE.STREET:
      if (obj.streetoffarc) {
        element = <SelectStreetOffset object={obj} />;
      } else {
        element = <SelectStreet object={obj} />;
      }
      break;
    case FUNCTION_TYPE.DIRT:
    case FUNCTION_TYPE.GRAVEL:
      element = <SelectDirt object={obj} />;
      break;
    case FUNCTION_TYPE.COUNTER_TOP:
      element = <SelectCounterTop object={obj} />;
      break;
    case FUNCTION_TYPE.STRUCTURE:
      element = <SelectStructure object={obj} />;
      break;
    case FUNCTION_TYPE.STREETACROSS:
      element = <SelectStreetAcross object={obj} />;
      break;
    case FUNCTION_TYPE.STATIONLINE:
    case FUNCTION_TYPE.XYMEASUREMENT:
      element = <SelectMeasurement object={obj} />;
      break;
    case FUNCTION_TYPE.TRIANGULATIONNETWORK:
      element = <SelectTriangulationNetwork object={obj} />;
      break;
    case FUNCTION_TYPE.STREETNEW:
      if (obj.streetType === STREET_TYPE.ROUNDABOUT) {
        element = <SelectRoundabout object={obj} />;
      } else if (obj.streetType === STREET_TYPE.CURVED) {
        element = <SelectCurved object={obj} />;
      } else if (obj.streetType === STREET_TYPE.STRAIGHT) {
        element = <SelectStreetNew object={obj} />;
      }
      break;
    case FUNCTION_TYPE.CURBRETURN:
      element = <SelectCurbReturn object={obj} />;
      break;
    case FUNCTION_TYPE.MARKER:
      element = <SelectMarker anchors={obj.handlepoint} text={obj.text} rotateangle={obj.rotateangle} />;
      break;
    default:
      break;
  }

  return (
    <StyledContainer
      key={obj.operateid}
      id={obj.operateid}
      onClick={handlers.selectClick}
      onMouseUp={handlers.selectMouseUp}
      onMouseDown={handlers.selectMouseDown}
      onMouseMove={handlers.selectMouseMove}
      onTouchEnd={handlers.selectMouseUp}
      onTouchStart={handlers.selectMouseDown}
      onTouchMove={handlers.selectMouseMove}
    >
      {element}
    </StyledContainer>
  );
}

function CreateSelectElement({ objList, ...otherProps }) {
  return objList.filter(obj => obj.selectflag && !workData.isLocked(obj)).map(obj => render(obj, otherProps));
}

export default CreateSelectElement;
