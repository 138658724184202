import axios from 'axios';

import { AddStructureData } from '@src/data/BusinessFun';
import SVGPathSeg from '@src/data/SVGPathSeg';
import Utility from '@src/data/Utility';
import _Street from '@src/data/_Street';
import { FUNCTION_TYPE, PATTERN_TYPE, COLOR_TYPE } from '@src/constant';

import * as workData from '@src/data/WorkData';
import { rotateDataPoint } from '@src/utils';

/**
 *
 * @param {string} path - url
 * @returns {boolean}
 */
export function TemplateShapeHandle(path) {
  if (StreetShapeData(path)) {
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @param {string} path - url
 * @param {number} radianAngle
 * @returns {boolean}
 */
export function StreetShapeData(path, radianAngle) {
  return axios.get(path).then(res => {
    const data = res.data;

    let div = document.createElement('div');
    div.innerHTML = data;

    let svg = div.querySelector('g[ttn-agent="layer"]');
    const originalUseDataIds = workData.getUseData().map(data => data.operateid);
    let svgItems = Array.prototype.slice.call(svg.children, 1);
    svgItems.forEach(item => {
      if (item.tagName == 'g') {
        if (item.hasAttribute('ttn-agent') && item.attributes['ttn-agent'].value == 'street') {
          if (!workData.hasStreet('paved')) {
            workData.addData('StreetSurface');
            let obj = workData.getCurrentOperateObject();
            obj.surfaceType = 'paved';
            obj.sfills = [];
            obj.crfills = [];
          }
          workData.addData('StreetNew');
          let obj = workData.getCurrentOperateObject();
          let index = workData.getLastObject('StreetNew');
          if (index < workData.getUseData().length - 1) {
            workData.deleteObject(obj.operateid);
            workData.insertObject(index, obj);
          }
          if (item.hasAttribute('stroke-width')) {
            obj.style.strokewidth = item.attributes['stroke-width'].value;
          }
          let metadata = item.querySelector('metadata');
          if (metadata && metadata.hasAttribute('nlanewidth')) {
            obj.lanewidth = Number(metadata.attributes['nlanewidth'].value);
          }
          if (metadata && metadata.hasAttribute('straveldirborder')) {
            obj.traveldirborder = metadata.attributes['straveldirborder'].value;
          }
          let ps = new SVGPathSeg();
          let segments = ps.parsePath(obj.traveldirborder);
          let axis = item.querySelector('path[ttn-role="axis"]');
          if (Utility.isValid(axis)) segments = ps.parsePath(axis.attributes['d'].value);
          obj.segments = [];
          for (let j = 0; j < segments.length; j++) {
            if (segments[j].type == 'L') {
              obj.segments.push({
                type: 'line',
                ptStart: {
                  x: segments[j - 1].point[0],
                  y: segments[j - 1].point[1],
                },
                ptStop: {
                  x: segments[j].point[0],
                  y: segments[j].point[1],
                },
              });
            } else if (segments[j].type == 'A') {
              obj.segments.push({
                type: 'arc',
                ptStart: {
                  x: segments[j - 1].point[0],
                  y: segments[j - 1].point[1],
                },
                ptStop: {
                  x: segments[j].point[0],
                  y: segments[j].point[1],
                },
                r: segments[j].radius[0],
                largeArcFlag: segments[j].arcLarge ? 1 : 0,
                sweepFlag: segments[j].arcSweep ? 1 : 0,
              });
            }
          }
          if (metadata && metadata.hasAttribute('ssurfacetype')) {
            obj.surfaceType = metadata.attributes['ssurfacetype'].value;
          }
          if (metadata && metadata.hasAttribute('nkey')) {
            var streetFillKey = metadata.attributes['nkey'].value;
          }
          obj.components = [];
          let components = metadata.querySelector('components');
          for (let j = 0; j < components.children.length; j++) {
            let child = components.children[j];
            if (child.tagName == 'stripe') {
              let key = '';
              if (child.hasAttribute('nkey')) {
                key = child.attributes['nkey'].value;
              }
              let primary = child.querySelector('primary');
              let mdpatterns = primary.querySelector('patterns');
              let items = mdpatterns.querySelectorAll('item');
              let patterns = [];
              items.forEach(function(item) {
                let pattern = '';
                if (item.hasAttribute('spattern')) {
                  pattern = item.attributes['spattern'].value;
                }
                let startpct = '';
                if (item.hasAttribute('nstartpct')) {
                  startpct = item.attributes['nstartpct'].value;
                }
                patterns.push({
                  pattern: pattern,
                  startPct: parseFloat(startpct),
                });
              });
              let stripe = {
                type: 'stripe',
                key: key,
                segments: obj.segments,
                primary: { patterns: patterns },
              };
              obj.components.push(stripe);
            } else if (child.tagName == 'lane') {
              let key = '';
              if (child.hasAttribute('nkey')) {
                key = child.attributes['nkey'].value;
              }
              let width = 0;
              if (child.hasAttribute('nwidth')) {
                width = Number(child.attributes['nwidth'].value);
              }
              obj.components.push({
                type: 'lane',
                key: key,
                width: width,
              });
            }
          }
          let _s = new _Street();
          let lw = _s.alignComponentsToStreetAxis(obj);
          _s.computeStreets(obj);
        } else if (item.hasAttribute('ttn-agent') && item.attributes['ttn-agent'].value == 'freeStripe') {
          workData.addData(FUNCTION_TYPE.STRIPE);
          let obj = workData.getCurrentOperateObject();
          if (item.hasAttribute('stroke-width')) obj.style.strokewidth = item.attributes['stroke-width'].value;
          let mp = item.querySelector('metadata patterns');
          let items = mp.querySelectorAll('item');
          let patterns = [];
          items.forEach(function(item) {
            let pattern = '';
            if (item.hasAttribute('spattern')) {
              pattern = item.attributes['spattern'].value;
            }
            let startpct = '';
            if (item.hasAttribute('nstartpct')) {
              startpct = item.attributes['nstartpct'].value;
            }
            patterns.push({ pattern: pattern, startpct: startpct });
            if (pattern == 'doublesolid') {
              obj.style.pattern = PATTERN_TYPE.doubleSolid;
            }
          });
          let polyArc = item.querySelector('path[ttn-agent="polyArc"]');
          let d = polyArc.getAttribute('d');
          let ps = new SVGPathSeg();
          let segments = ps.parsePath(d);
          if (segments.length == 2) {
            obj.position.x1 = segments[0].point[0];
            obj.position.y1 = segments[0].point[1];
            obj.position.x2 = segments[1].point[0];
            obj.position.y2 = segments[1].point[1];
            obj.handlepoint = [
              [obj.position.x1, obj.position.y1],
              [obj.position.x2, obj.position.y2],
              [(obj.position.x1 + obj.position.x2) / 2, (obj.position.y1 + obj.position.y2) / 2],
            ];
          }
        } else if (item.hasAttribute('ttn-agent') && item.attributes['ttn-agent'].value == 'structure') {
          let structureCore = item.querySelector('g[ttn-agent="structureCore"]');

          workData.addData(FUNCTION_TYPE.STRUCTURE);
          let obj = workData.getCurrentOperateObject();
          // obj.handlepoint[0] = [svgPosition[0], svgPosition[1], ANCHORS_TYPE.STRUCTUREBEGIN, AddStructureData()];
          // obj.handlepoint[1] = [svgPosition[0], svgPosition[1], ANCHORS_TYPE.STRUCTUREEND, AddStructureData()];
          obj.style.stroke = COLOR_TYPE.BLACK;
          if (structureCore.hasAttribute('stroke-width'))
            obj.style.strokewidth = structureCore.attributes['stroke-width'].value;
          obj.marks.islength = true;
          obj.selectflag = true;
          let structureWalls = item.querySelectorAll('g[ttn-agent="structureWall"]');
          obj.walls = [];
          structureWalls.forEach(function(wall) {
            let metadata = wall.querySelector('metadata');
            let key = '';
            if (metadata && metadata.hasAttribute('nkey')) {
              key = metadata.attributes['nkey'].value;
            }
            let path = wall.querySelector('path');
            let d = path.getAttribute('d');
            let ps = new SVGPathSeg();
            let segments = ps.parsePath(d);
            obj.walls.push({ key: key, profile: d, segments: segments });
          });
        }
      }
    });
    const addedUseData = workData.getUseData().filter(data => !originalUseDataIds.includes(data.operateid));
    workData.setGroupForObjects(addedUseData);
    rotateDataPoint(addedUseData, radianAngle, [0, 0]);
    return true;
  });
}
