import React from 'react';
import { GetShapes, sortStreetCrossPointsToClockwise } from '@src/data/BusinessFun';
import {
  GetBikeLaneCount,
  GetDirtLinePathD,
  GetMaxHeight,
  GetStreetAreaBegin,
  GetStreetLanePathD,
  GetStreetLanesArea,
  GetStreetLineBegin,
  GetStreetLinePathD,
  GetStreetLinePoints,
  GetStreetMaxPoints,
} from '@src/actions/StreetFunHandle';
import { GetShapeStyle } from '@src/data/CommonProps';
import { GetShapeThreeTriangle, GetShapeTowBesselPath, GetShapeClosePath } from '../ShapeFun';
import { ANCHORS_TYPE, COLOR_TYPE } from '@src/constant';
import LncdText from '../LncdText';
import StreetService from '@src/services/StreetService';
class Gravel extends React.Component {
  constructor(props) {
    super(props);
    let { object, maxheight, linepoints } = this.init(props.object);
    this.state = {
      object: object,
      maxheight: maxheight,
      linepoints: linepoints,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let { object, maxheight, linepoints } = this.init(nextProps.object);
    this.setState({
      object: object,
      maxheight: maxheight,
      linepoints: linepoints,
    });
  }
  init = object => {
    if (object == null || object.handlepoint.length < 3) return;
    let maxheight = GetMaxHeight(object);
    let maxTopPoints = GetStreetMaxPoints(object, maxheight / 2, true);
    let linepoints = [];
    let beginStreetLine = GetStreetLineBegin(object.groupdata);
    linepoints[beginStreetLine] = maxTopPoints;
    let i = beginStreetLine;
    let laneData = object.groupdata;
    while (i >= 0 && i < laneData.length) {
      let laneHeight = object.lanewidth;
      if (i < object.lanes.length) {
        laneHeight = object.lanes[i].width;
      }
      let bottomLine = laneData[i].bottomline;
      linepoints[bottomLine] = GetStreetLinePoints(linepoints[i], laneHeight, false);
      i = bottomLine;
    }
    return { object, maxheight, linepoints };
  };
  getRect = shapes => {
    let fillcolor = COLOR_TYPE.VERYLIGHTGRAY;
    if (this.state.object.selectflag) fillcolor = COLOR_TYPE.LIGHTGRAY;
    let topLine = GetStreetLineBegin(this.state.object.groupdata);
    let anchors = this.state.object.handlepoint;
    let i = GetStreetAreaBegin(anchors);
    while (i != -1 && anchors[i][3].rightindex != -1 && i != null) {
      var D1 = [],
        D2 = [];
      GetStreetLanesArea(D1, D2, this.state.linepoints[topLine], this.state.linepoints[1], i);
      let centerIndex = anchors[i][3].rightindex;
      let endIndex = anchors[centerIndex][3].rightindex;
      i = endIndex;
      let idLeft = String(centerIndex) + '||' + 'left' + '||' + anchors[centerIndex][2];
      var shapeLeft = <path d={D1.join(' ')} fill={fillcolor} stroke={COLOR_TYPE.NONE} key={idLeft} id={idLeft} />;
      shapes.push(shapeLeft);
      let idRight = String(centerIndex) + '||' + 'right' + '||' + anchors[centerIndex][2];
      var shapeRight = <path d={D2.join(' ')} fill={fillcolor} stroke={COLOR_TYPE.NONE} key={idRight} id={idRight} />;
      shapes.push(shapeRight);
    }
  };

  getLaneShape = shapes => {
    let laneData = this.state.object.groupdata;
    let i = GetStreetLineBegin(laneData);
    while (i >= 0 && i < laneData.length) {
      if (laneData[i].topline != -1 && laneData[i].bottomline != -1) {
        i = laneData[i].bottomline;
        continue;
      }
      let D = [];
      GetDirtLinePathD(D, this.state.linepoints[i]);
      let commonProps = {
        stroke: laneData[i].stroke,
        strokeWidth: laneData[i].strokewidth,
        strokeDasharray: laneData[i].strokedasharray,
        fill: COLOR_TYPE.NONE,
      };
      shapes.push(<path d={D.join(' ')} {...commonProps} key={'lane+' + String(i)} />);
      i = laneData[i].bottomline;
    }
  };
  getLaneHideShape = (shapes, object) => {
    //只能拖拉模式进入
    if (object.streetmodel != 1) return;
    let anchors = object.handlepoint;
    let laneData = object.groupdata;
    for (let i = 0; i < laneData.length; i++) {
      if (i != 0 && i != laneData.length - 1) {
        continue;
      }
      let D = [];
      GetStreetLinePathD(D, this.state.linepoints[i]);
      let id = object.functype + 'line' + '||' + object.operateid + '||' + String(i);
      let style = GetShapeStyle().STREETLANESELECT;
      if (object.selectflag) style.stroke = COLOR_TYPE.LIGHTGRAY;
      //style.strokeWidth = object.lanewidth;
      shapes.push(<path d={D.join(' ')} {...style} key={id} id={id} />);
    }
  };
  getAcrossShape(shapes) {
    let acrossData = this.state.object.streetacrossdata;
    let D = [];

    if (
      acrossData.length > 0 &&
      (function(acrossData) {
        for (var m = 0; m < acrossData.length; m++) {
          if (acrossData[m] && acrossData[m].length > 0) return true;
        }
      })(acrossData)
    ) {
      let closePoints = [];

      //sort all points in across data by AntiClockwise
      closePoints = sortStreetCrossPointsToClockwise(acrossData);

      let shapeCloseHide = GetShapeClosePath(closePoints, 'hideCloseShape', true, {
        stroke: COLOR_TYPE.VERYLIGHTGRAY,
        fill: COLOR_TYPE.VERYLIGHTGRAY,
        strokewidth: 0,
      });

      shapeCloseHide && shapes.push(shapeCloseHide);

      closePoints = sortStreetCrossPointsToClockwise(acrossData, false);

      let squareClosePath = GetShapeClosePath(closePoints, 'hideAcrossCloseShape', true, {
        stroke: COLOR_TYPE.VERYLIGHTGRAY,
        fill: COLOR_TYPE.VERYLIGHTGRAY,
        strokewidth: 5,
      });

      squareClosePath && shapes.push(squareClosePath);
    }

    for (let i = 0; i < acrossData.length; i++) {
      if (acrossData[i].length < 2) continue;
      // 贝塞尔曲线所在区域的连线，是为了遮挡原图形的相同位置的区域
      let closeBezier = GetShapeThreeTriangle(
        acrossData[i],
        String(i) + '||' + this.state.object.operateid + '||' + ANCHORS_TYPE.STREETACROSSAREA,
        true,
        {
          stroke: COLOR_TYPE.VERYLIGHTGRAY,
          fill: COLOR_TYPE.VERYLIGHTGRAY,
          strokewidth: 5,
        }
      );
      closeBezier && shapes.push(closeBezier);

      // 贝塞尔曲线本身
      let shapeTowBessel = GetShapeTowBesselPath(acrossData[i], 'bezier' + String(i), true, {
        stroke: COLOR_TYPE.MEDGRAY,
        fill: COLOR_TYPE.CANVAS_BG,
        strokewidth: 4,
      });
      shapeTowBessel && shapes.push(shapeTowBessel);
    }
  }
  /*
  getAcrossShape(shapes) {
    let acrossData = this.state.object.streetacrossdata;
    let D = [];
    let closePoints = [];

    for (let i = 0; i < acrossData.length; i++) {
      if (acrossData[i].length < 2) continue;
      let closeBezier = GetShapeThreeTriangle(
        acrossData[i],
        String(i) +
          '||' +
          this.state.object.operateid +
          '||' +
          ANCHORS_TYPE.STREETACROSSAREA,
        true,
        {
          stroke: COLOR_TYPE.VERYLIGHTGRAY,
          strokewidth: 3,
          fill: COLOR_TYPE.VERYLIGHTGRAY,
        }
      );
      shapes.push(closeBezier);
      let shapeTowBessel = GetShapeTowBesselPath(
        acrossData[i],
        'bezier' + String(i),
        true,
        {
          stroke: COLOR_TYPE.MEDGRAY,
          strokewidth: 1,
        }
      );
      shapes.push(shapeTowBessel);
      closePoints.push(acrossData[i][1]);
    }
    if (closePoints.length > 0) {
      let shapeCloseHide = GetShapeClosePath(
        closePoints,
        'hideCloseShape',
        true,
        {
          stroke: COLOR_TYPE.VERYLIGHTGRAY,
          fill: COLOR_TYPE.VERYLIGHTGRAY,
          strokewidth: 3,
        }
      );
      shapes.push(shapeCloseHide);
    }
  }
  */
  getText = object => {
    const id = object.operateid;
    const point = new StreetService(object).getTextPosition();
    return <LncdText id={`street-text-${id}`} objectId={id} {...object.text} point={point} />;
  };
  render() {
    if (this.state == null) return null;
    const { object } = this.state;
    if (object == null || object.handlepoint.length < 3) return null;
    let shapes = [];
    this.getRect(shapes);
    // 用于拖拉模式下得精度选择
    this.getLaneHideShape(shapes, object);
    this.getLaneShape(shapes);
    this.getAcrossShape(shapes);
    return (
      <g id={object.functype}>
        <metadata nlanewidth={object.lanewidth} ssurfacetype={object.surfaceType} />
        {GetShapes(shapes)}
        {object.text.text && this.getText(object)}
      </g>
    );
  }
}

export default Gravel;
