import * as React from 'react';

function SvgIconPathArc(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0 0h24v24H0z" />
          <path
            d="M23 9h-1.485A10.003 10.003 0 0015 2.486V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v1.492A10.005 10.005 0 002.485 9H1a1 1 0 00-1 1v4a1 1 0 001 1h1.485A10.006 10.006 0 009 21.509V24h6v-2l7-7h1a1 1 0 001-1v-4a1 1 0 00-1-1zM11 2h2v2h-2V2zm-9 9h2v2H2v-2zm13 8.404V24H9v-4.587A8.036 8.036 0 014.596 15H5a1 1 0 001-1v-4a1 1 0 00-1-1h-.404A8.038 8.038 0 019 4.588V5a1 1 0 001 1h4a1 1 0 001-1v-.404A8.027 8.027 0 0119.404 9H19a1 1 0 00-1 1v4a1 1 0 001 1h3l-7 7v-2.596zM22 13h-2v-2h2v2z"
            fill="currentColor"
            fillRule="nonzero"
          />
          <rect stroke="currentColor" strokeWidth={2} x={10} y={19} width={4} height={4} rx={1} />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconPathArc;
