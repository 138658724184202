import Terminator from './Terminator';
import diagramManager from '@src/data/DiagramManager';

class Axial {
  constructor(core) {
    this.core = core;
  }

  getLength() {
    return this.core.getLength();
  }

  getPointStart() {
    return this.core.getPointStart();
  }

  getPointStop() {
    return this.core.getPointStop();
  }

  getTextPlacementRect(fontSize, textLength) {}

  getZoomAwareArrow(strokeWidth, terminatorType, terminatorStyle, terminatorWidth, color) {
    var uupp = diagramManager.getUserUnitsPerPixel();
    this.terminatorType = terminatorType;
    this.terminatorStyle = terminatorStyle;
    this.terminatorWidth = terminatorWidth * uupp;
    var terminators = _updateTerminators();
    var g = (
      <g ttn-agent="axial" strokeDasharray="none" fill={color} strokeWidth={strokeWidth * uupp} stroke={color}>
        <metadata
          sstrokePattern="solid"
          sterminatorType={terminatorType}
          bshowLength="false"
          bshowInterval="false"
          ntxtPosition="1"
          nversion="2"
          sterminatorStyle={terminatorStyle}
          nterminatorWidth={terminatorWidth}
        ></metadata>
        {terminators}
      </g>
    );
    return g;
  }

  _updateTerminators() {
    var lineWidth = 1;
    var startTerminator;
    if (this.terminatorType === 'both' || this.terminatorType === 'startOnly') {
      var startAngle = this.getPointStop().getInclineAngle(this.getPointStart());
      var terminator = new Terminator();
      terminator.createTerminator(
        this.getPointStart(),
        startAngle,
        this.terminatorWidth,
        this.terminatorStyle,
        lineWidth,
        '#0000FF'
      );
      startTerminator = terminator.path;
      this.startTerminatorLength = terminator.axialLength;
    } else {
      startTerminator = undefined;
      this.startTerminatorLength = 0;
    }
    var stopTerminator;
    if (this.terminatorType === 'both' || this.terminatorType === 'stopOnly') {
      var stopAngle = this.getPointStart().getInclineAngle(this.getPointStop());
      var terminator = new Terminator();
      terminator.createTerminator(
        this.getPointStop(),
        stopAngle,
        this.terminatorWidth,
        this.terminatorStyle,
        lineWidth,
        '#0000FF'
      );
      stopTerminator = terminator.path;
      this.stopTerminatorLength = terminator.axialLength;
    } else {
      stopTerminator = undefined;
      this.stopTerminatorLength = 0;
    }
    var length = this.getLength();
    if (
      (startTerminator || stopTerminator) &&
      length < this.startTerminatorLength + this.stopTerminatorLength + lineWidth * 2
    ) {
      startTerminator = undefined;
      this.startTerminatorLength = 0;
      stopTerminator = undefined;
      this.stopTerminatorLength = 0;
    }
  }

  configureShapeDimensionLine(textPosn) {
    var uupp = diagramManager.getUserUnitsPerPixel();
    var strokeWidth = 1.5 * uupp;
    this.terminatorType = 'both';
    this.terminatorStyle = 'arrowTriangleLine';
    this.terminatorWidth = 7 * uupp;
    this._updateTerminators();
    var terminators = [];
    return terminators;
  }

  _updateLength() {}
}

export default Axial;
