import * as React from 'react';

function SvgIconPathEllipse(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
        <path d="M3.355 13.963C4.483 16.487 6.963 18.403 10 19m4 0c3.029-.606 5.5-2.498 6.626-4.985m.09-3.813C19.625 7.617 17.107 5.638 14 5m-4 0c-3.044.594-5.523 2.482-6.628 4.961" />
        <rect x={19} y={10} width={4} height={4} rx={1} />
        <rect x={10} y={17} width={4} height={4} rx={1} />
        <rect x={10} y={3} width={4} height={4} rx={1} />
        <rect x={1} y={10} width={4} height={4} rx={1} />
      </g>
    </svg>
  );
}

export default SvgIconPathEllipse;
