import * as React from 'react';

function SvgIconRedo(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h23.97v24H0z" />
        <path
          d="M11.985 1C5.928 1 .999 5.935.999 12c0 6.066 4.929 11 10.986 11s10.986-4.934 10.986-11c0-6.065-4.929-11-10.986-11zm0 20c-4.957 0-8.989-4.037-8.989-9s4.032-9 8.989-9c4.957 0 8.989 4.037 8.989 9s-4.032 9-8.989 9z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          d="M14.112 5.612l-1.43 1.428 2.023 2.02h-4.638a4.047 4.047 0 00-4.046 4.038 4.047 4.047 0 004.046 4.038h1.011v-2.02h-1.011a2.022 2.022 0 01-2.023-2.018c0-1.113.906-2.02 2.023-2.02h4.638l-2.023 2.02 1.43 1.428 4.465-4.456-4.465-4.458z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconRedo;
