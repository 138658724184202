import React from 'react';
import { Popconfirm, Icon } from 'antd';
import styled from 'styled-components';
import { EditableContext } from '../context/EditableContext';
import { IconSave, IconClose } from '@src/components/UI/Icons';

const StyledEditableRowOperation = styled.div`
  font-size: 1.3em;
`;

export default function EditableRowOperation({ editable, keyValue, editingKey, save, cancel, edit, handleDelete, t }) {
  return (
    <StyledEditableRowOperation>
      {editable ? (
        <>
          <EditableContext.Consumer>
            {form => (
              <a onClick={() => save(form, keyValue)} style={{ marginRight: 8 }}>
                <IconSave />
              </a>
            )}
          </EditableContext.Consumer>
          <Popconfirm title={t('property.measurementTab.cancelWarningOperation')} onConfirm={() => cancel(keyValue)}>
            <a>
              <IconClose />
            </a>
          </Popconfirm>
        </>
      ) : (
        <>
          <a disabled={editingKey !== ''} onClick={() => edit(keyValue)} style={{ marginRight: 8 }}>
            <Icon type="edit" />
          </a>
          <a disabled={editingKey !== ''} onClick={() => handleDelete(keyValue)}>
            <Icon type="delete" />
          </a>
        </>
      )}
    </StyledEditableRowOperation>
  );
}
