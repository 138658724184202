import React from 'react';
import { connect } from 'react-redux';

import './Map.css';

// TODO: can we remove this component or move it out of CanvasSVG?

function Map({ opacity, canvasHeight, appWidth, device }) {
  return (
    <foreignObject
      className="lncd-map-layer-container"
      width={appWidth}
      height={canvasHeight}
      style={{
        position: 'absolute',
        overflow: 'visible',
        zIndex: -1,
        // HACK: Resolve shape can't select in mobile
        pointerEvents: device === 'touch' ? 'none' : 'initial',
      }}
    >
      <div
        ttn-agent="mapLayer"
        id="lncd-map-layer"
        style={{ outline: 'none', opacity, height: canvasHeight, width: appWidth, zIndex: -1, overflow: 'auto' }}
      ></div>
    </foreignObject>
  );
}

const mapStateToProps = state => ({
  opacity: state.map.opacity,
  canvasHeight: state.app.canvasHeight,
  appWidth: state.app.appWidth,
  device: state.app.device,
});

export default connect(
  mapStateToProps,
  null
)(Map);
