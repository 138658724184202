import _ from 'lodash';
import types from '@src/type/canvas';
import { StreetService } from '@src/services/StreetService';
import { updateAxisRad } from '@src/action/street';
import * as workData from '@src/data/WorkData';
import { FUNCTION_TYPE } from '@src/constant';

export default function updateStreetMiddleware() {
  const _throttledUpdateAxisRad = _.throttle(
    dispatch => {
      // check if there's any selected street
      const useData = workData.getUseData();
      const selectedStreets = _.filter(useData, n => n.functype === FUNCTION_TYPE.STREETNEW && n.selectflag);
      if (selectedStreets.length === 1) {
        const street = selectedStreets[0];
        const service = new StreetService(street);
        const rad = service.getHorizontalRad();
        dispatch(updateAxisRad(rad));
      }
    },
    200,
    {
      leading: false,
      trailing: true,
    }
  );

  return ({ dispatch }) => next => action => {
    if (action.type === types.FLUSH_WORK_DATA) {
      _throttledUpdateAxisRad(dispatch);
    }
    return next(action);
  };
}
