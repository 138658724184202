import * as React from 'react';

function SvgIconDownload(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g opacity={0.902}>
          <path d="M0 .006h24v23.04H0z" />
          <g fill="currentColor" fillRule="nonzero">
            <path d="M12.98 18.497V7.737h-2.245v10.76L8.16 15.923 6.574 17.51l5.283 5.283 5.284-5.283-1.588-1.587z" />
            <path d="M17.47 1.002H6.244A2.246 2.246 0 004 3.247v8.98a2.247 2.247 0 002.245 2.245h1.122v-2.245H6.245v-8.98h11.224v8.98h-1.122v2.245h1.122a2.247 2.247 0 002.245-2.245v-8.98a2.246 2.246 0 00-2.245-2.245z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgIconDownload;
