import * as React from 'react';

function SvgIconZoomIn(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.056 0h24v24h-24z" />
        <g fill="currentColor" fillRule="nonzero">
          <path d="M11.056 6h-2v3h-3v2h3v3h2v-3h3V9h-3z" />
          <path d="M21.763 20.293L16.37 14.9a7.928 7.928 0 001.686-4.9 7.945 7.945 0 00-2.344-5.656A7.94 7.94 0 0010.056 2 7.94 7.94 0 004.4 4.344 7.945 7.945 0 002.056 10c0 2.137.833 4.146 2.344 5.656A7.94 7.94 0 0010.056 18a7.922 7.922 0 004.9-1.686l5.393 5.392 1.414-1.413zM10.056 16a5.959 5.959 0 01-4.242-1.757A5.958 5.958 0 014.056 10c0-1.602.624-3.109 1.758-4.242A5.956 5.956 0 0110.056 4c1.603 0 3.11.624 4.242 1.758A5.957 5.957 0 0116.056 10c0 1.603-.624 3.11-1.758 4.243A5.959 5.959 0 0110.056 16z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconZoomIn;
