import GeomPoint from './GeomPoint';

class Terminator {
  createTerminator(pt, angle, width, style, lineWidth) {
    this._drawTerminator(pt, angle, width, style, lineWidth);
  }

  _drawTerminator(pt, angle, width, style, lineWidth) {
    var height;
    switch (style) {
      case 'arrowSimple':
        height = width * 1.5;
        this._createTriangle(width, height, pt, angle);
        break;
      case 'arrowTriangleLine':
        height = width * 0.5;
        this._createTriangle(width, height, pt, angle);
        this._createEndLine(width, lineWidth / 3, pt, angle);
        break;
      default:
        height = 0;
    }
    this.axialLength = height;
  }

  _createEndLine(width, strokeWidth, pt, angle) {
    var _44e9,
      pt2 = new GeomPoint(pt.x, pt.y + width / 2),
      pt3 = new GeomPoint(pt.x, pt.y - width / 2);
    pt2.rotate(angle, pt);
    pt3.rotate(angle, pt);
    //_44e9 = UtilitySvg.createSvgElement("line");
    //_44e9.setAttribute(Tenum.svgAttr.strokeWidth, strokeWidth);
    //_44e9.setAttribute("x1", pt2.x);
    //_44e9.setAttribute("y1", pt2.y);
    //_44e9.setAttribute("x2", pt3.x);
    //_44e9.setAttribute("y2", pt3.y);
    return _44e9;
  }

  _createTriangle(width, height, pt, a) {
    var path,
      angle = a + Math.PI / 2,
      pt2 = new GeomPoint(pt.x + width / 2, pt.y + height),
      pt3 = new GeomPoint(pt.x - width / 2, pt.y + height);
    pt2.rotate(angle, pt);
    pt3.rotate(angle, pt);
    path =
      'M' +
      pt.x +
      ',' +
      pt.y +
      ' ' +
      'L' +
      pt2.x +
      ',' +
      pt2.y +
      ' ' +
      'L' +
      pt3.x +
      ',' +
      pt3.y +
      ' ' +
      'L' +
      pt.x +
      ',' +
      pt.y;
    this.path = path;
  }
}

export default Terminator;
