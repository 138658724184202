import { OPERATIONS } from '@src/constant';
import { Dropdown, Menu } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as workData from '@src/data/WorkData';
import { FUNCTION_TYPE } from '@src/constant';
import { useTranslation } from 'react-i18next';

const {
  CUT,
  COPY,
  PASTE,
  BRING_FRONT,
  SEND_BACK,
  GROUP,
  UNGROUP,
  FLIP_HORIZONTAL,
  FLIP_VERTICAL,
  ALIGN_LEFT,
  ALIGN_CENTER,
  ALIGN_RIGHT,
  ALIGN_TOP,
  ALIGN_MIDDLE,
  ALIGN_BOTTOM,
} = OPERATIONS;

const CanvasDropMenu = props => {
  const { handleCanvasOperation } = props;

  const handleClick = ({ key }) => {
    handleCanvasOperation(key);
  };

  // FIXME: get from work data selected objects
  const onlySymbolSelected = false;
  const selectedData = workData.getUseData().filter(data => data.selectflag);
  const selectingOneSymbol = selectedData.length === 1 && selectedData[0].functype === FUNCTION_TYPE.SYMBOL;
  const { t } = useTranslation();
  return (
    <Menu onClick={handleClick} className="lncd-menu">
      <Menu.SubMenu key="arrange" title={<span>{t('menu.context.arrange')}</span>} popupClassName="lncd-submenu">
        <Menu.Item key={BRING_FRONT} disabled={!selectingOneSymbol}>
          {t('menu.context.front')}
        </Menu.Item>
        <Menu.Item key={SEND_BACK} disabled={!selectingOneSymbol}>
          {t('menu.context.back')}
        </Menu.Item>
        {onlySymbolSelected && <Menu.Divider />}
        <Menu.Item key={GROUP}>{t('menu.context.group')}</Menu.Item>
        <Menu.Item key={UNGROUP}>{t('menu.context.ungroup')}</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={FLIP_HORIZONTAL} disabled={!selectingOneSymbol}>
          {t('menu.context.horizontal')}
        </Menu.Item>
        <Menu.Item key={FLIP_VERTICAL} disabled={!selectingOneSymbol}>
          {t('menu.context.vertical')}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="align" title={<span>{t('menu.context.align')}</span>} popupClassName="lncd-submenu">
        <Menu.Item key={ALIGN_LEFT}>{t('menu.context.left')}</Menu.Item>
        <Menu.Item key={ALIGN_CENTER}>{t('menu.context.center')}</Menu.Item>
        <Menu.Item key={ALIGN_RIGHT}>{t('menu.context.right')}</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={ALIGN_TOP}>{t('menu.context.top')}</Menu.Item>
        <Menu.Item key={ALIGN_MIDDLE}>{t('menu.context.middle')}</Menu.Item>
        <Menu.Item key={ALIGN_BOTTOM}>{t('menu.context.bottom')}</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

function DropMenuOnHeader(props) {
  return <CanvasDropMenu {...props} />;
}

function DropMenuOnCanvas(props) {
  // FIXME: Can use CanvasDropMenu?
  const { handleCanvasOperation } = props;
  const handleClick = ({ key }) => {
    handleCanvasOperation(key);
  };
  const { t } = useTranslation();
  // FIXME: get from work data selected objects
  const onlySymbolSelected = false;
  const selectedData = workData.getUseData().filter(data => data.selectflag);
  const selectingOneSymbol = selectedData.length === 1 && selectedData[0].functype === FUNCTION_TYPE.SYMBOL;
  const menu = (
    <Menu onClick={handleClick} className="lncd-menu">
      <Menu.Item key={CUT}>{t('menu.context.cut')}</Menu.Item>
      <Menu.Item key={COPY}>{t('menu.context.copy')}</Menu.Item>
      <Menu.Item key={PASTE}>{t('menu.context.paste')}</Menu.Item>
      <Menu.SubMenu key="arrange" title={<span>{t('menu.context.arrange')}</span>} popupClassName="lncd-submenu">
        <Menu.Item key={BRING_FRONT} disabled={!selectingOneSymbol}>
          {t('menu.context.front')}
        </Menu.Item>
        <Menu.Item key={SEND_BACK} disabled={!selectingOneSymbol}>
          {t('menu.context.back')}
        </Menu.Item>
        {onlySymbolSelected && <Menu.Divider />}
        <Menu.Item key={GROUP}>{t('menu.context.group')}</Menu.Item>
        <Menu.Item key={UNGROUP}>{t('menu.context.ungroup')}</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={FLIP_HORIZONTAL} disabled={!selectingOneSymbol}>
          {t('menu.context.horizontal')}
        </Menu.Item>
        <Menu.Item key={FLIP_VERTICAL} disabled={!selectingOneSymbol}>
          {t('menu.context.vertical')}
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu key="align" title={<span>{t('menu.context.align')}</span>} popupClassName="lncd-submenu">
        <Menu.Item key={ALIGN_LEFT}>{t('menu.context.left')}</Menu.Item>
        <Menu.Item key={ALIGN_CENTER}>{t('menu.context.center')}</Menu.Item>
        <Menu.Item key={ALIGN_RIGHT}>{t('menu.context.right')}</Menu.Item>
        <Menu.Divider />
        <Menu.Item key={ALIGN_TOP}>{t('menu.context.top')}</Menu.Item>
        <Menu.Item key={ALIGN_MIDDLE}>{t('menu.context.middle')}</Menu.Item>
        <Menu.Item key={ALIGN_BOTTOM}>{t('menu.context.bottom')}</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  const [isDisabled, setDisabled] = useState(false);
  useEffect(() => {
    let id;
    // 移动设备禁用DropMenu
    if (props.device === 'touch') {
      setDisabled(true);
    } else if (props.device === 'untouch') {
      // 多点绘制图形结束绘制时，禁用DropMenu
      if (!props.isShowCanvasDropMenu) {
        setDisabled(true);
        id = setTimeout(() => {
          props.setShowCanvasDropMenu && props.setShowCanvasDropMenu(true);
        }, 500);
      } else {
        setDisabled(false);
      }
    }
    return () => {
      clearTimeout(id);
    };
  }, [props.children, props.device]);

  return (
    <Dropdown overlay={menu} trigger={['contextMenu']} disabled={isDisabled}>
      {props.children}
    </Dropdown>
  );
}

const mapStateToProps = state => ({
  device: state.app.device,
});

const DropMenuOnCanvasConnectRedux = connect(
  mapStateToProps,
  null
)(DropMenuOnCanvas);

export { DropMenuOnCanvasConnectRedux, DropMenuOnHeader };
