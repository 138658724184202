import React from 'react';
import {
  FindCircle,
  GetCircleCenter,
  GetCenterInTwoPoints,
  LengthBetweenPoints,
  IsUnderLine,
  getAngleBy2Points,
  ByTime,
  IsZero,
  TowPointVerticalLengthByStatus,
  GetRotateMatrix,
  pointTransform,
  RotatePoint,
  TowPointVerticalLength,
  TowPointDestPointLength,
  IsByTime,
} from '@src/data/CommonFunc';
import { GetShapeStyle } from '@src/data/CommonProps';
import { GetShapes, GetPathArcD } from '@src/data/BusinessFun';
import { GetShapePath } from '../ShapeFun';
import { getSquareHandlePoint, GetArcAnchors, getCircleHandlePoint, CircleHandlePoint } from '@src/data/AnchorsPoint';
import { getUserUnitByPixel } from '@src/data/CommonFunction';
import utility from '@src/data/Utility';
import { ANCHORS_TYPE, DASH_ARRAY_TYPE } from '@src/constant';
import GeomArc from '@src/data/GeomArc';
import GeomPoint from '@src/data/GeomPoint';
import GeomVector from '@src/data/GeomVector';

class SelectParkStall extends React.Component {
  constructor(props) {
    super(props);
  }

  getAnglePoint = (marksHeight, anchors, r, angle) => {
    let point = [];
    if (IsZero(r)) {
      //直线
      point = TowPointVerticalLengthByStatus(anchors[0], anchors[1], anchors[2], marksHeight, true);
    } else {
      // FIXME: use/create GeomArc built in methods
      const [hpBegin, hpEnd, hpCenter] = anchors.slice(0, 3);
      const ptBegin = new GeomPoint(...hpBegin);
      const ptEnd = new GeomPoint(...hpEnd);
      const ptCenter = new GeomPoint(...hpCenter);
      const v1 = ptCenter.getVector(ptBegin);
      const v2 = ptCenter.getVector(ptEnd);
      const largeArcFlag = Math.acos(v1.angleCos(v2)) > Math.PI / 2;
      const isClockwise = IsByTime(hpBegin, hpCenter, hpEnd);
      const ga = new GeomArc(ptBegin, ptEnd, r, largeArcFlag, !isClockwise);
      const ptCircleCenter = ga.getCircleCenter();
      let p = ptCenter.clone();
      p.offsetToward(ptCircleCenter, isClockwise ? marksHeight : -marksHeight);
      point = [p.x, p.y];
    }
    point = RotatePoint(point, angle, anchors[2]);
    return (
      <g>
        <CircleHandlePoint id={ANCHORS_TYPE.PARKING_STALL_ANGLE} point={point} lncd-role="rotate-handle" />
        <line x1={anchors[2][0]} y1={anchors[2][1]} x2={point[0]} y2={point[1]} />
      </g>
    );
  };

  render() {
    const { props } = this;
    const { object } = props;
    var anchors = object.handlepoint;
    if (anchors.length < 3) return null;

    const x1 = anchors[0][0];
    const x2 = anchors[1][0];
    const y1 = anchors[0][1];
    const y2 = anchors[1][1];
    const cirlcle = FindCircle(anchors[0], anchors[1], anchors[2]);
    const r = cirlcle[2];
    let getIsUnder = () => {
      if (ByTime(anchors[0], anchors[2], anchors[1]) == 1) {
        return '1';
      } else return '0';
    };
    let getIsBigArc = () => {
      var center = GetCenterInTwoPoints(anchors[0], anchors[1]);
      var lenth = LengthBetweenPoints(center, anchors[2]);
      if (lenth > r) {
        return '1';
      } else {
        return '0';
      }
    };
    let getDPath = () => {
      var D = [];
      // D.push("M" + x1.toString() + "," + y1.toString());
      // D.push("A" + r.toString() + "," + r.toString());
      // D.push("0");
      // D.push(getIsBigArc() + "," + getIsUnder());
      // D.push(x2.toString() + "," + y2.toString());
      GetPathArcD(D, [x1, y1], [x2, y2], r, getIsBigArc(), getIsUnder());

      return D;
    };
    var pathshape = this.getAnglePoint(object.marks.height, anchors, r, object.rotateangle);
    var shape = [];
    shape.push(
      GetShapePath(getDPath(), 'centerdash', true, {
        strokeDasharray: DASH_ARRAY_TYPE.Dashes,
      })
    );
    shape.push(getSquareHandlePoint(ANCHORS_TYPE.ARCBEGIN, [x1, y1]));
    shape.push(getSquareHandlePoint(ANCHORS_TYPE.ARCEND, [x2, y2]));
    shape.push(getCircleHandlePoint(ANCHORS_TYPE.ARCANGLE, anchors[2]));
    return (
      <g>
        {GetShapes(shape)}
        {pathshape}
      </g>
    );
  }
}

export default SelectParkStall;
