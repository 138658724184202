import * as React from 'react';

function SvgIconPet(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-.999 0h24v24.002h-24z" />
        <path
          d="M22 7h-2c0 3-1.505 3-2 3h-4.272l5.864-7.539-1.184-.921-6.199 7.97-4.333-6.498L5.382 8H4.5A2.503 2.503 0 002 10.5C2 11.879 3.122 13 4.5 13H6c.551 0 1 .449 1 1v7h2v-4h9v4h2v-9.534c1.271-.757 2-2.3 2-4.466zm-4 8H9v-1c0-1.654-1.346-3-3-3H4.5a.5.5 0 010-1h2.118l1.506-3.012L11.465 12H18v3z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgIconPet;
