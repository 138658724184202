export const environment = {
  favoriteUseThreshold: 9, // 判断 是否为 favorite 的使用次数阈值
  favoriteTimeRange: 1000 * 60 * 60 * 24 * 7, // (one week)判断 是否为 favorite 的 使用时间 范围限制
};

/**
 * @deprecated Since version 2.0.0, IndexedDB version is manually managed
 * bind DB version to application version number defined in package.json
 * version number follows this format: major.M.D
 */
export const getDBVersion = () => {
  const parts = process.env.REACT_APP_VERSION.split('.');
  let i = 0;
  return parts.reduceRight((prev, cur) => prev + parseInt(cur, 10) * Math.pow(10, 2 * i++), 0) + 1;
};

export const appName = process.env.REACT_APP_NAME;
