import React, { useState, useEffect } from 'react';
import { Table, Button, Select } from 'antd';
import { setCSVPreviewOpen } from '@src/action/propMenu';
import { DraggableModal } from 'ant-design-draggable-modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import LncdCheckbox from '@src/components/Modal/Element/LncdCheckbox';
import { message } from '@src/components/Message';

function TableController({ columnCount, setHeaderValues, hasHeaderRow, setHasHeaderRow }) {
  const options = ['id', 'x', 'y', 'description'];
  const selections = [];
  for (let i = 0; i < columnCount; i++) {
    selections.push(
      <Select
        key={i}
        defaultValue={options[0]}
        onChange={value => {
          setHeaderValues(prevIndexes => [...prevIndexes.slice(0, i), value, ...prevIndexes.slice(i + 1)]);
        }}
        style={{ width: 100 / columnCount + '%', marginRight: '5px' }}
      >
        {options.map(option => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    );
  }
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LncdCheckbox
            size={16}
            checked={hasHeaderRow}
            onClick={() => setHasHeaderRow(prevHasHeaderRow => !prevHasHeaderRow)}
          />
          <span style={{ marginLeft: 5 }}>This file has header row</span>
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <div style={{ marginLeft: '3em', display: 'flex' }}>{selections.map(component => component)}</div>
      </div>
    </>
  );
}

function PreviewFooter({ headerValues, handleImportOk, handleImportCancel, handleSelectDifferentFile }) {
  const [id, setId] = useState(-1);
  const [x, setX] = useState(-1);
  const [y, setY] = useState(-1);

  useEffect(() => {
    setId(headerValues.indexOf('id'));
    setX(headerValues.indexOf('x'));
    setY(headerValues.indexOf('y'));
  }, [headerValues]);

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button className="lncd-button" style={{ marginRight: 10 }} onClick={handleSelectDifferentFile}>
        Select a Different File
      </Button>
      <Button
        className="lncd-button"
        onClick={handleImportOk}
        style={{ marginRight: 10 }}
        /**
         * Rule
         * 1. 必须存在id,x,y
         * 2. id,x,y互不相等
         */
        disabled={(x == y && y == id) || (x == -1 || y == -1 || id == -1)}
      >
        Import
      </Button>
      <Button className="lncd-button" onClick={handleImportCancel}>
        Cancel
      </Button>
    </div>
  );
}

const StyledPointModal = styled(DraggableModal)`
  & .ant-modal-body {
    padding: 10px;
  }
`;

function CSVPreview(props) {
  const { csvData, setCSVPreviewOpen, t, handleChangePoints, handleSelectDifferentFile } = props;
  const [headerValues, setHeaderValues] = useState(['id', 'id', 'id', 'id']);
  const [hasHeaderRow, setHasHeaderRow] = useState(false);
  const [firstRow, setFirstRow] = useState([]);
  const [otherRow, setOtherRow] = useState(csvData);

  // TODO: will render TWICE!
  useEffect(() => {
    if (hasHeaderRow) {
      let [header, ...body] = csvData;
      header = header.length > 4 ? header.slice(0, 4) : header;
      setFirstRow(header);
      setOtherRow(body);
    } else {
      if (csvData[0].length === 0) {
        message.error('Missing data in the first row');
        return;
      } else {
        let firstRow = csvData[0].map((data, i) => 'column' + (i + 1));
        firstRow = firstRow.length > 4 ? firstRow.slice(0, 4) : firstRow;
        setFirstRow(firstRow);
        setOtherRow(csvData);
      }
    }
    // console.log('firstRow', firstRow);
    // console.log('otherRow', otherRow);
  }, [hasHeaderRow, csvData]);

  // all of columns except first column
  const columns = firstRow.map((item, i) => {
    return { title: item, dataIndex: item, key: item, width: 100 / firstRow.length + '%' };
  });

  const data = [];
  const array = columns.map(col => col.dataIndex);
  const il = hasHeaderRow ? array.length - 2 : array.length - 1;
  for (let i = 0; i < il; i++) {
    const row = otherRow[i];
    if (row) {
      let o = { key: i, row: i + 1 };
      for (let j = 0, jl = row.length; j < jl; j++) {
        o[array[j]] = row[j];
      }
      data.push(o);
    }
  }
  // console.log('data', data);

  // first column
  columns.unshift({ title: 'row', dataIndex: 'row', key: 'row', width: '3em' });

  const handleImportOk = () => {
    if (headerValues.length !== new Set(headerValues).size) {
      message.error('The value of header cannot be repeated');
      return;
    }
    const results = [];
    for (let i = 0, il = otherRow.length; i < il; i++) {
      const item = otherRow[i];
      let indexId = headerValues.indexOf('id');
      let indexDesc = headerValues.indexOf('description');
      let indexX = headerValues.indexOf('x');
      let indexY = headerValues.indexOf('y');
      const newItem = {
        key: i,
        id: Number(item[indexId]),
        description: item[indexDesc],
        x: Number(item[indexX]),
        y: Number(item[indexY]),
      };
      if (results.some(result => result.id === newItem.id)) {
        message.error('The id attribute cannot repeat.');
        return;
      }
      results.push(newItem);
    }
    // console.log('results', results);
    setCSVPreviewOpen(false);
    handleChangePoints(results);
  };

  const handleImportCancel = () => {
    setCSVPreviewOpen(false);
  };

  return (
    <StyledPointModal
      visible={true}
      onCancel={() => setCSVPreviewOpen(false)}
      footer={null}
      title="Import Measurements from CSV File"
      // see https://github.com/DylanVann/ant-design-draggable-modal/issues/8
      initialWidth={600}
      initialHeight={345}
      zIndex={1000}
    >
      <TableController
        columnCount={firstRow.length}
        setHeaderValues={setHeaderValues}
        hasHeaderRow={hasHeaderRow}
        setHasHeaderRow={setHasHeaderRow}
      />
      <Table
        id="lncd-measurement-table"
        bordered
        dataSource={data}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        size="small"
        style={{ marginBottom: 10 }}
      />
      <PreviewFooter
        headerValues={headerValues}
        handleImportOk={handleImportOk}
        handleImportCancel={handleImportCancel}
        handleSelectDifferentFile={handleSelectDifferentFile}
      />
    </StyledPointModal>
  );
}

const mapStateToProps = state => ({
  csvData: state.propMenu.csvData,
});

const mapDispatchToProps = {
  setCSVPreviewOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CSVPreview);
