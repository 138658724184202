import GeomPolyArc from './GeomPolyArc';
import Utility from './Utility';
import _ from 'lodash';
import GeomStripe from './GeomStripe';

class FreeStripe {
  /**
   * when there's splitters on a stripe, return which part is the point in
   * @param {GeomPoint} point
   */
  static getStripePatternIndex = (stripe, point) => {
    const patterns = stripe.primary.patterns;
    if (!Utility.isNonEmptyArray(patterns)) {
      return -1;
    }
    const gpa = new GeomPolyArc(stripe.segments);
    const goa = gpa.getOffsetsToPoint(point || gpa.getMidPoint());
    let index = -1;
    for (let i = 0; i < patterns.length; i++) {
      if (goa.fromStartPercent > patterns[i].startPct) {
        index = i;
      }
    }
    return index;
  };

  static setPattern(stripe, pattern) {
    if (stripe.selectedPatternIndex > -1) {
      const patterns = stripe.primary.patterns;
      patterns[stripe.selectedPatternIndex].pattern = pattern;
    }
  }

  /**
   *
   * @param {GeomStripe} stripe
   * @param {string} type
   * @param {GeomPoint} point
   */
  setPatternAtPoint(stripe, type, point) {
    const patterns = stripe.primary.patterns;
    const index = FreeStripe.getStripePatternIndex(stripe, point);
    var pattern = { pattern: type, startPct: 0 };
    pattern.startPct = patterns[index].startPct;
    stripe.primary.patterns[index] = pattern;
  }

  /**
   *
   * @param {GeomStripe} stripe
   * @param {GeomPoint} [pt]
   */
  splitPatternAtPoint(stripe, pt) {
    var patterns = stripe.primary.patterns;
    if (!Utility.isNonEmptyArray(patterns)) {
      return;
    }
    var gpa = new GeomPolyArc(stripe.segments);
    const point = pt || gpa.getMidPoint();
    var goa = gpa.getOffsetsToPoint(point);
    // prevent adding splitter to same location, this is no-op
    if (patterns.findIndex(n => n.startPct === goa.fromStartPercent) === -1) {
      var idx = 0;
      for (var i = 0; i < patterns.length; i++) {
        if (goa.fromStartPercent > patterns[i].startPct) {
          idx = i;
        }
      }
      var pattern = { pattern: patterns[idx].pattern, startPct: patterns[idx].startPct };
      pattern.startPct = goa.fromStartPercent;
      stripe.selectedPatternIndex = idx + 1;
      stripe.primary.patterns.splice(idx + 1, 0, pattern);
    }
  }
}

export default FreeStripe;
