import React from 'react';
import PropTypes from 'prop-types';
import CustomInputNumber from '../Element/CustomInputNumber';
import ColorSelect from '../Element/ColorSelect';
import Images from '@src/assets/Images';
import TextBoxSelect from '../Element/TextBoxSelect';
import ParkStallSelect from '../Element/ParkStallSelect';
import CrossWalkSelect from '../Element/CrossWalkSelect';
import { withTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { ORIENTATION_VIEW, DashArrayStyles } from '@src/constant/constant';
import LncdDropdown from '../Element/LncdDropdown';
import TouchScroll from '@src/components/TouchDevice/TouchScroll';
import LncdCheckbox from '../Element/LncdCheckbox';

class StyleMenu extends React.Component {
  render() {
    const object = this.props.stateObject;
    const stripeIndex = object.idSelectedComponent;
    return (
      <TouchScroll className="props-container scrollbar">
        <div>
          {this.props.item.map(element => {
            switch (element) {
              case 'type':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.typeLabel')}:</span>
                    <TextBoxSelect type={this.props.stateObject.text.textboxtype} handle={this.props.handleTextbox} />
                  </div>
                );
              case 'stallType':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.stallTypeLabel')}:</span>
                    {/* FIXME: use LncdDropdown */}
                    <ParkStallSelect type={this.props.stateObject.style.pattern} handle={this.props.patternHandle} />
                  </div>
                );
              case 'lineType':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.lineTypeLabel')}:</span>
                    <CrossWalkSelect type={this.props.stateObject.style.pattern} handle={this.props.patternHandle} />
                  </div>
                );
              case 'line': {
                const { strokepattern } = this.props.stateObject.style;
                const items = Object.entries(DashArrayStyles).map(([key, value]) => {
                  const DashArrayStyleImage = value;
                  return {
                    key,
                    image: <DashArrayStyleImage width={40} height={20} />,
                  };
                });
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.lineLabel')}:</span>
                    <LncdDropdown
                      items={items}
                      onClick={this.props.handleLine}
                      selectedKey={strokepattern}
                      defaultkey="solid"
                    />
                  </div>
                );
              }
              case 'width':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.widthLabel')}:</span>
                    <CustomInputNumber
                      value={this.props.stateObject.style.strokewidth}
                      min={0}
                      max={100}
                      formatter={`"`}
                      parser={'"'}
                      size="default"
                      onChangeValue={this.props.handleStrokeWidth}
                    />
                  </div>
                );
              case 'wallWidth':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.wallWidthLabel')}:</span>
                    <CustomInputNumber
                      value={this.props.wallWidth}
                      min={0}
                      max={100}
                      formatter={`"`}
                      parser={'"'}
                      size="default"
                      onChangeValue={this.props.handleStrokeWidth}
                    />
                  </div>
                );
              case 'lineColor':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.lineColorLabel')}:</span>
                    {stripeIndex > -1 ? (
                      <ColorSelect
                        colorHex={object.components[stripeIndex].stroke}
                        handleColorHex={this.props.handleStroke}
                      />
                    ) : (
                      <ColorSelect
                        colorHex={this.props.stateObject.style.stroke}
                        handleColorHex={this.props.handleStroke}
                      />
                    )}
                  </div>
                );
              case 'fillColor':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.fillColorLabel')}:</span>
                    <ColorSelect
                      iconType="fill"
                      colorHex={this.props.stateObject.style.fill}
                      handleColorHex={this.props.handleFill}
                    />
                  </div>
                );
              case 'color':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.colorLabel')}:</span>
                    <ColorSelect
                      colorHex={this.props.stateObject.style.stroke}
                      handleColorHex={this.props.handleStroke}
                    />
                  </div>
                );
              case 'lineLength':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.lineLengthLabel')}:</span>
                    <CustomInputNumber
                      value={this.props.stateObject.marks.length}
                      min={2}
                      max={50}
                      formatter={`"`}
                      parser={'"'}
                      size="default"
                      onChangeValue={this.props.handleMarksLenth}
                    />
                  </div>
                );
              case 'marks':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.markLabel')}:</span>
                    <LncdCheckbox checked={this.props.stateObject.marks.isexist} onClick={this.props.handleMarks} />
                  </div>
                );
              case 'direction':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.directionLabel')}:</span>
                    <LncdCheckbox checked={this.props.stateObject.marks.isexist} onClick={this.props.handleDirection} />
                  </div>
                );
              case 'snap':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.snapLabel')}:</span>
                    <LncdCheckbox
                      checked={this.props.stateObject.snapToStreet}
                      onClick={this.props.handleSnapToStreet}
                    />
                  </div>
                );
              case 'hide':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.hideLabel')}:</span>
                    <LncdCheckbox
                      checked={this.props.stateObject.style.strokeopacity === 0}
                      onClick={this.props.handleHide}
                    />
                  </div>
                );
              case 'flip':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.flipLabel')}:</span>
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: 40,
                          height: 32,
                          border: '1px solid #1f5078',
                          marginRight: 5,
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 2,
                        }}
                      >
                        <img
                          src={this.props.stateObject.vertical === 1 ? Images.IconFlipUp : Images.IconFlipDown}
                          alt=""
                          onClick={this.props.changeVertical}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: 40,
                          height: 32,
                          border: '1px solid #1f5078',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 2,
                        }}
                      >
                        <img
                          src={this.props.stateObject.horizontal === 1 ? Images.IconFlipLeft : Images.IconFlipRight}
                          alt=""
                          onClick={this.props.changeHorizontal}
                        />
                      </div>
                    </div>
                  </div>
                );
              case 'shadow':
                return (
                  <div className="container-item" key={element}>
                    <span className="item-title">{this.props.t('property.styleTab.shadowLabel')}:</span>
                    <LncdCheckbox
                      checked={this.props.stateObject.shadow.isActiveShadowMode}
                      onClick={this.props.toggleShadow}
                    />
                  </div>
                );
              case 'orientation':
                return (
                  this.props.stateObject.symbol.orientationGroup && (
                    <div className="container-item" key={element}>
                      <span className="item-title">{this.props.t('property.styleTab.orientationLabel')}:</span>
                      <div>
                        <Radio.Group value={this.props.stateObject.symbol.orientationView} buttonStyle="solid">
                          {Object.values(ORIENTATION_VIEW).map(view => {
                            return (
                              <Radio.Button
                                key={view}
                                value={view}
                                onChange={this.props.changeOrientation.bind(this, this.props.stateObject.symbol, view)}
                              >
                                {this.props.t(`property.orientation.${view}`)}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </div>
                    </div>
                  )
                );
              default:
                return null;
            }
          })}
        </div>
      </TouchScroll>
    );
  }
}

StyleMenu.propTypes = {
  item: PropTypes.array.isRequired,
  stateObject: PropTypes.object.isRequired,

  // Arc
  handleLine: PropTypes.func,
  handleMarks: PropTypes.func,
  handleMarksLenth: PropTypes.func,
  handleStroke: PropTypes.func,
  handleStrokeWidth: PropTypes.func,
  handleFill: PropTypes.func,
  handleTextbox: PropTypes.func,

  // Callout
  handleDirection: PropTypes.func,

  // CrossWalk
  patternHandle: PropTypes.func,
  handleSnapToStreet: PropTypes.func,

  // CurbReturn
  wallWidth: PropTypes.number,
  handleHide: PropTypes.func,

  // Symbol
  changeVertical: PropTypes.func,
  changeHorizontal: PropTypes.func,
  toggleShadow: PropTypes.func,
  changeOrientation: PropTypes.func,
};

export default withTranslation()(StyleMenu);
