import * as React from 'react';

function SvgIconMap(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v23.041H0z" />
        <g transform="translate(2 .96)" fill="currentColor" fillRule="nonzero">
          <path d="M10 16.32c-.392 0-.748-.22-.91-.563C8.407 14.317 5 7.017 5 4.8 5 2.153 7.243 0 10 0s5 2.153 5 4.8c0 2.217-3.406 9.516-4.09 10.957a1.001 1.001 0 01-.91.563zm0-14.4c-1.654 0-3 1.292-3 2.88 0 1.136 1.593 5.05 3 8.186 1.408-3.136 3-7.051 3-8.186 0-1.588-1.346-2.88-3-2.88z" />
          <ellipse cx={10} cy={4.8} rx={1.5} ry={1.44} />
          <path d="M17.483 19.2H2.517a2.02 2.02 0 01-1.669-.862 1.846 1.846 0 01-.168-1.815l2.142-4.8c.314-.706 1.035-1.163 1.837-1.163h.824v1.92h-.824l-2.143 4.8h14.967l-2.143-4.8h-.857v-1.92h.857c.802 0 1.523.457 1.838 1.164l2.142 4.8a1.846 1.846 0 01-.168 1.812 2.015 2.015 0 01-1.669.864z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconMap;
