import { SLIDER_MENU_TYPE, STREET_STRIPES_TYPE } from '@src/constant';

export const StreetStructureData = [
  // 0
  'Street',
  // 1
  'Lanes/Shoulder',
  // 2
  'Stripes',
  // 3
  'Lane Marks',
  // 4
  'Structures',
  // 5
  'Indicators/Arrows',
  // 6
  'Labels',
];
export const SymbolData = ['Vehicle', 'person', 'Indoor', 'Outdoor', 'Objects', 'Aircraft', 'Inspetion'];
export const MeasureMentsData = ['Station Line', 'Triangulation', 'X-Y Measurements'];

export function StreetStructureType() {
  return {
    Street: 'Street',
    LanesShoulder: 'Lanes/Shoulder',
    Stripes: 'Stripes',
    LaneMarks: 'Lane Marks',
    Structures: 'Structures',
    IndicatorsArrows: 'Indicators/Arrows',
    Labels: 'Labels',
    MeasureMents: 'MeasureMents',
  };
}

export function SymbolsType() {
  return {
    Vehicle: 'Vehicle',
    Person: 'person',
    Indoor: 'Indoor',
    Outdoor: 'Outdoor',
    Objects: 'Objects',
    Aircraft: 'Aircraft',
    Inspetion: 'Inspetion',
  };
}
export function MeasureMentsType() {
  return {
    StationLine: 'Station Line',
    Triangulation: 'Triangulation',
    XYMeasureMents: 'X-Y Measurements',
  };
}

export function getSlideMenuTyeList(type) {
  let typeList = [];
  if (type === SLIDER_MENU_TYPE.STREETSSTRUCTURES) {
    typeList = StreetStructureData;
  } else if (type === SLIDER_MENU_TYPE.SYMBOLS) {
    typeList = SymbolData;
  } else if (type === SLIDER_MENU_TYPE.MEANSUREMENTS) {
    typeList = MeasureMentsData;
  }
  return typeList;
}

export const StreetData = [
  {
    name: 'Paved 1',
    src: './res/shape/paved1.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Paved 2',
    src: './res/shape/paved2.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Paved Unstriped 1',
    src: './res/shape/pavedunstriped1.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Paved Unstriped 2',
    src: './res/shape/pavedunstriped2.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Offset',
    src: './res/shape/offset.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Crosswalk',
    src: './res/shape/crosswalk.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Dirt 1',
    src: './res/shape/dirt1.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Dirt 2',
    src: './res/shape/dirt2.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Gravel 1',
    src: './res/shape/gravel1.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Gravel 2',
    src: './res/shape/gravel2.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Parking Stalls',
    src: './res/shape/parkstall.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];
export const LanesShoulderData = [
  {
    // 0
    name: 'Add a Lane',
    src: './res/shape/addlane.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Add a Bike Lane',
    src: './res/shape/addlane.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Add Shoulders',
    src: './res/shape/addshoulders.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Add Shoulder',
    src: './res/shape/addshoulder.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    // 4
    name: '2 Way Left Turn Lane',
    src: './res/shape/2way.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Painted Divider',
    src: './res/shape/painteddivider.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Wide Divider',
    src: './res/shape/addlane.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    // 7
    name: 'Sidewalk',
    src: './res/shape/sidewalk.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Remove',
    src: './res/shape/remove.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];

export const SymbolKeyNames = {
  laneShoulderNames: LanesShoulderData.map(item => item.name),
  // TODO: these keys aren't robust that need to be updated afterwards
  laneShoulderWeakKeys: LanesShoulderData.map(item => item.name.replace(/\s/g, '_')),
};

export const StripeData = [
  {
    name: 'Dash',
    src: './res/shape/dash.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Solid',
    src: './res/shape/solid.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Double Solid',
    src: './res/shape/doublesolid.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Double Dash',
    src: './res/shape/doubledash.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Dash & Solid',
    src: './res/shape/dashsolid.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    // 5
    name: 'Solid & Dash',
    src: './res/shape/soliddash.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Reflectors',
    src: './res/shape/reflectors.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Hidden',
    src: './res/shape/hidden.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    // 8
    name: 'Split Pattern',
    src: './res/shape/splitpattern.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];

export const stripeStyles = [
  {
    pattern: 'singledash',
    type: STREET_STRIPES_TYPE.dash,
    name: 'Dash',
  },
  {
    pattern: 'singlesolid',
    type: STREET_STRIPES_TYPE.solid,
    name: 'Solid',
  },
  {
    pattern: 'doublesolid',
    type: STREET_STRIPES_TYPE.doublesolid,
    name: 'Double Solid',
  },
  {
    pattern: 'doubledash',
    type: STREET_STRIPES_TYPE.doubledash,
    name: 'Double Dash',
  },
  {
    pattern: 'dashsolid',
    type: STREET_STRIPES_TYPE.dashsolid,
    name: 'Dash & Solid',
  },
  {
    pattern: 'soliddash',
    type: STREET_STRIPES_TYPE.soliddash,
    name: 'Solid & Dash',
  },
  {
    pattern: 'reflectors',
    type: STREET_STRIPES_TYPE.reflectors,
    name: 'Reflectors',
  },
  {
    pattern: 'invisible',
    type: STREET_STRIPES_TYPE.hide,
    name: 'Hidden',
  },
];

export const LaneMarksData = [
  {
    name: 'Left Turn Only',
    src: './res/shape/leftturn.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Right Turn Only',
    src: './res/shape/rightturn.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];
export const StructureData = [
  {
    name: '10 Ft X 12 Ft Structure',
    src: './res/shape/structure10x12.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: '30 Ft X 40 Ft Structure',
    src: './res/shape/structure30x40.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: '36 In Door',
    src: './res/shape/36indoor.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: '72 In French Door',
    src: './res/shape/72door.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: '16 Ft Double Garage Door',
    src: './res/shape/16doubledoor.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];
export const IndicatorsArrowsData = [
  {
    name: 'North Indicator 1',
    src: './res/shape/north-indicator.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];
export const LabelData = [
  {
    name: 'Main Street',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'Second Street',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];
export const MeasurementData = [
  {
    name: 'StationLine',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'TriangulationNetwork',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
  {
    name: 'XYMeasurement',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
  },
];

export const StationLineDirections = {
  NORTH: 'North_Station_Line',
  SOUTH: 'South_Station_Line',
  EAST: 'East_Station_Line',
  WEST: 'West_Station_Line',
};

export const StationLineData = {
  North_Station_Line: {
    name: 'StationLine',
    type: 'North_Station_Line',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
    dropDownList: [{ name: 'west', title: 'Left(W)' }, { name: 'east', title: 'Right(E)' }],
    defaultDropDownValue: 'west',
  },
  South_Station_Line: {
    name: 'StationLine',
    type: 'South_Station_Line',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
    dropDownList: [{ name: 'west', title: 'Left(W)' }, { name: 'east', title: 'Right(E)' }],
    defaultDropDownValue: 'east',
  },
  East_Station_Line: {
    name: 'StationLine',
    type: 'East_Station_Line',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
    dropDownList: [{ name: 'north', title: 'Up(N)' }, { name: 'south', title: 'Down(S)' }],
    defaultDropDownValue: 'north',
  },
  West_Station_Line: {
    name: 'StationLine',
    type: 'West_Station_Line',
    src: './res/shape/labelstreet.svg',
    text: 'drag and drop the paved 1 tool on the drawing to add the vertical street',
    dropDownList: [{ name: 'north', title: 'Up(N)' }, { name: 'south', title: 'Down(S)' }],
    defaultDropDownValue: 'south',
  },
};

export const TriangulationNetworkDropDownList = {
  unshaded: 'Unshaded',
  shaded: 'Shaded',
};
